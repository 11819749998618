import { PopoverProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { CheckIcon, PlusIcon } from 'assets/icons';
import {
  CfgnInsightsFilterInputProps,
  CfgnInsightsNumberRangeQueryValue,
  NoSelectionChipValue,
} from 'components/cfgrs/cfgn-insights/cfgn-insights-filter-input';
import { Button } from 'controls/button/button';
import { CbnPopover } from 'controls/cbn-popover/cbn-popover';
import { ChipValue, LargeFontChip } from 'controls/chip/chip';
import { NumberInput } from 'controls/input/number-input';
import { getTypedTranslation, useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type NumberRangeFilterInputProps = CfgnInsightsFilterInputProps<CfgnInsightsNumberRangeQueryValue> & {
  /** Text which will be shown in the UI like "{subjectRange} range: 123 - 456" */
  subjectName: string;
};

/**
 * The resulting array respresents `[min, max]` which will be queried like `min <= x <= max`. Empty means "not set".
 * See docs of `CfgnInsightsNumberRangeQueryValue` for more details.
 */
export const NumberRangeFilterInput: React.FC<NumberRangeFilterInputProps> = ({ value, onCommit, subjectName }) => {
  const { t } = useTypedTranslation();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const showPopoverBtnRef = useRef<HTMLDivElement>(null);
  const [quickOptions, setQuickOptions] = useState([value]);

  const onChipClicked = (x: ChipValue): void => {
    const clickedValue = _valueStringToNumberRange(x as string);
    const selectedChipClicked = clickedValue[0] === value[0] && clickedValue[1] === value[1];
    const newValue =
      x === NoSelectionChipValue || selectedChipClicked ? ([] as CfgnInsightsNumberRangeQueryValue) : clickedValue;

    onCommit(newValue);
  };

  const commitRange = (newValue: CfgnInsightsNumberRangeQueryValue): void => {
    setPopoverOpen(false);

    const [minValue, maxValue] = newValue;
    if (minValue === undefined && maxValue === undefined) return;

    onCommit([minValue, maxValue]);

    const quickOptionAlreadyExists = quickOptions.some(x => x[0] === minValue && x[1] === maxValue);
    if (!quickOptionAlreadyExists) {
      setQuickOptions([...quickOptions, [minValue, maxValue]]);
    }
  };

  return (
    <div className="flex flex-wrap items-center gap-3">
      <AddRangePopover
        subjectName={subjectName}
        onSubmit={commitRange}
        open={popoverOpen}
        onClose={(): void => setPopoverOpen(false)}
        anchorEl={showPopoverBtnRef.current}
      />

      <LargeFontChip
        value={NoSelectionChipValue}
        text={t('Any')}
        variant="Primary"
        selected={!value.length}
        onClick={onChipClicked}
      />
      <span className="self-stretch border-r border-neutral-50" />

      <div ref={showPopoverBtnRef}>
        <Button
          variant="ChipMenu"
          text={t('Select range...')}
          Svg={PlusIcon}
          onClick={(): void => setPopoverOpen(x => !x)}
        />
      </div>

      {quickOptions
        .filter(x => x.length > 0)
        .map(x => {
          const valueStr = _numberRangeToValueString(x);

          return (
            <LargeFontChip
              key={valueStr}
              value={valueStr}
              text={_numberRangeToDisplayText(x)}
              variant="Primary"
              selected={value[0] === x[0] && value[1] === x[1]}
              onClick={onChipClicked}
            />
          );
        })}
    </div>
  );
};

type AddRangePopoverProps = Omit<PopoverProps, 'onSubmit' | 'onClose'> & {
  onClose?: () => void;
  subjectName: string;
  onSubmit: (value: CfgnInsightsNumberRangeQueryValue) => void;
};

const AddRangePopover: React.FC<AddRangePopoverProps> = ({ open, onClose, anchorEl, subjectName, onSubmit }) => {
  const { t } = useTypedTranslation();

  const [minValue, setMinValue] = useState<number>();
  const [maxValue, setMaxValue] = useState<number>();
  const valueStr = _numberRangeToDisplayText([minValue, maxValue]);

  useEffect(() => {
    setMinValue(undefined);
    setMaxValue(undefined);
  }, [open]);

  const submitForm = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    onSubmit([minValue, maxValue]);
    onClose?.();
  };

  return (
    <CbnPopover
      data-cmptype="OptionsPopover"
      disableRestoreFocus
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <div className="flex flex-col gap-4 p-4 text-s-regular text-neutral-90">
        <span>{t(`${subjectName} range ${valueStr}`)}</span>

        <form className="flex flex-col gap-2 text-m-regular" onSubmit={submitForm}>
          <div className="grid grid-cols-2 gap-x-6 gap-y-1.5">
            <div>{t('Minimum')}</div>
            <div>{t('Maximum')}</div>

            <NumberInput inputMode="decimal" onChange={(x): void => setMinValue(x ?? undefined)} autoFocus />
            <NumberInput inputMode="decimal" onChange={(x): void => setMaxValue(x ?? undefined)} />
          </div>

          {/*
          TODO Now ORT: Keep the explicit submit button or not?
                        Considerations FAL: https://github.com/Combeenation/Combeenation/pull/1535#discussion_r1719587313
                        If yes: What text & icons and where to place the button?
          */}
          <Button type="submit" text={t('Select')} Svg={CheckIcon} />
        </form>
      </div>
    </CbnPopover>
  );
};

function _numberRangeToDisplayText(value: CfgnInsightsNumberRangeQueryValue): string {
  const { t } = getTypedTranslation();
  const [min, max] = value;
  return `${min ?? t('min')} - ${max ?? t('max')}`;
}

function _numberRangeToValueString(value: CfgnInsightsNumberRangeQueryValue): string {
  const [min, max] = value;
  return `${min ?? ''}-${max ?? ''}`;
}

function _valueStringToNumberRange(value: string): CfgnInsightsNumberRangeQueryValue {
  const [min, max] = value.split('-');
  return [min ? Number(min) : undefined, max ? Number(max) : undefined];
}
