import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { OpenIcon } from 'assets/icons';
import { Icon } from 'controls/icon/icon';
import { Select, SelectValue } from 'controls/select/select';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { AssetBundleAssignment, fetchAssignedAssetBundles } from 'services/assets/assets.service';
import { buildAssetEditorPath } from 'services/routes/asset-editor-routes.service';

type SelectBundleProps = {
  companyId: string;
  cfgrId: string;
  value: string;
  paramIdx: number;
  disabled?: boolean;
  onChange: (value: string, idx: number) => void;
};

export const SelectBundleOfCfgr: React.FC<SelectBundleProps> = ({
  companyId,
  cfgrId,
  value,
  paramIdx,
  disabled,
  onChange,
}) => {
  const { t } = useTypedTranslation();
  const [bundles, setBundles] = useState<AssetBundleAssignment[]>([]);
  const [fetching, setFetching] = useState(true);
  const initialCfgrId = useRef(cfgrId);
  const initialDataChanged = useRef(false);

  useEffect(() => {
    if (!companyId || !cfgrId) {
      return;
    }

    if (initialCfgrId.current !== cfgrId) {
      initialDataChanged.current = true;
    }

    setFetching(true);
    setBundles([]);

    const fetchData = async (): Promise<void> => {
      const assignedAssetBundles = await fetchAssignedAssetBundles(companyId, cfgrId);
      setBundles(assignedAssetBundles.sort((a, b) => a.assetBundleName.localeCompare(b.assetBundleName)));
      setFetching(false);
    };
    fetchData();
  }, [companyId, cfgrId]);

  const valueUpperCase = value.toUpperCase();
  const bundleOptions = bundles.map(b => ({ value: b.assetBundleName.toUpperCase(), text: b.assetBundleName }));
  const selectedBundle = bundles.find(b => b.assetBundleName.toUpperCase() === valueUpperCase);
  const isValidOption = !!selectedBundle;
  const cfgrHasBundles = bundles.length > 0;

  useEffect(
    function updateSelectionOnOptionsChange() {
      if (!fetching && initialDataChanged.current && !isValidOption) {
        onChange(bundles.length > 0 ? bundles[0].assetBundleName : '', paramIdx);
      }
    },
    [bundles, fetching, isValidOption, onChange, paramIdx]
  );

  const onSelectChange = (newValue: SelectValue): void => {
    initialDataChanged.current = true;
    onChange(newValue as string, paramIdx);
  };

  const errorMsg = !cfgrHasBundles
    ? t('Published configurator has no assigned bundles!')
    : !isValidOption
      ? t('Selected bundle not found!')
      : '';

  return (
    <div className="flex w-full items-center gap-3">
      <div className="grow">
        <Select
          options={bundleOptions}
          value={valueUpperCase}
          onChange={onSelectChange}
          disabled={disabled || fetching || !cfgrHasBundles}
          error={!isValidOption || !cfgrHasBundles}
          tooltip={errorMsg}
        />
      </div>
      {isValidOption && (
        <Link to={buildAssetEditorPath(companyId, selectedBundle.assetBundleName, selectedBundle.version)}>
          <Icon Svg={OpenIcon} className="h-5" title={t('Go to selected bundle')} />
        </Link>
      )}
    </div>
  );
};
