import { Popover } from '@mui/material';
import { ColorResult, validHex } from '@uiw/color-convert';
import Sketch from '@uiw/react-color-sketch';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TaskListArrowRight } from 'assets/icons';
import { MaterialPropertyKey } from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor-properties-def';
import {
  MaterialPropBaseInputProps,
  MaterialPropFormControlLabel,
} from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor-standard-prop-inputs';
import { Button } from 'controls/button/button';
import { TextInput } from 'controls/input/text-input';
import { readClipboardText } from 'helper/clipboard/clipboard.helper';
import { gammaSpaceHexToLinearSpaceColor, linearSpaceColorToGammaSpaceHex, number3 } from 'helper/color/color.helper';
import { isEqualHexColor } from 'helper/color/color.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type MaterialPropColorInputProps = MaterialPropBaseInputProps & {
  defaultValue?: number3;
  onChange: (key: MaterialPropertyKey, value: number3 | undefined) => void;
};

export const MaterialPropColorInput: React.FC<MaterialPropColorInputProps> = ({
  inputObj,
  objKey,
  disabled,
  displayName,
  searchValue,
  defaultValue,
  onChange,
}) => {
  const { t } = useTypedTranslation();
  // there is a difference between the internally stored string and the data that is returned in the `onChange` function
  // => the internal string can probably not be parsed into a valid JSON object, that's why we have to differentiate.
  const [hexInput, setHexInput] = useState('#000000');
  const colorPickerRef = useRef<HTMLDivElement>(null);
  const [clipboardHex, setClipboardHex] = useState<string>();
  const isTypingRef = useRef(false);

  const valueOfInputObj = inputObj[objKey] ?? defaultValue;
  // if default value is given we can be sure that a value is set anyway
  const isValueSet = !!hexInput || defaultValue !== undefined;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isPickerOpen = Boolean(anchorEl);
  const handleClick = async (): Promise<void> => {
    const clipboardText = await readClipboardText();
    if (clipboardText) {
      if (validHex(clipboardText) && !isEqualHexColor(clipboardText, hexInput, true)) {
        setClipboardHex('#' + clipboardText.replace('#', ''));
      }
    }

    setAnchorEl(colorPickerRef.current);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const onChangeDebounced = useMemo(
    () => debounce((key: MaterialPropertyKey, value: number3) => onChange(key, value), 100),
    [onChange]
  );

  useEffect(
    function updateValueOnInputObjChange() {
      if (valueOfInputObj) {
        setHexInput(linearSpaceColorToGammaSpaceHex(valueOfInputObj));
      } else {
        setHexInput('');
      }
    },
    [valueOfInputObj]
  );

  const onInputChange = (value: string): void => {
    // Only keep the value local until the user leaves the input (`onBlur`)
    // Otherwise a manual input wouldn't be possible -> Typing #a0f would instantly change to #aa00ff
    setHexInput(value);
    isTypingRef.current = true;
  };

  const onColorPickerChange = (colorIn: ColorResult): void => {
    const newNumber3 = gammaSpaceHexToLinearSpaceColor(colorIn.hex);
    if (newNumber3) {
      // give UI feedback immediately, but return the value in a debounced way, to reduce amount of state updates
      // => very significant performance improvement for the color picker itself
      setHexInput(colorIn.hex);
      onChangeDebounced(objKey, newNumber3);
    }
  };

  const onInputFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
    event.target.select();
  };

  // "Commit" the value once the user leaves the input
  // Restore the last valid value in case of an invalid value
  const onInputBlur = (): void => {
    if (!isTypingRef.current) {
      // no change, don't overwrite the value as this would lead to an undesired "commitment" of the default value
      return;
    }

    if (hexInput.length === 0) {
      onChange(objKey, undefined);
      if (defaultValue) {
        // fallback to default value if no value has been set
        setHexInput(linearSpaceColorToGammaSpaceHex(defaultValue));
      }
    } else {
      const number3Val = gammaSpaceHexToLinearSpaceColor(hexInput);
      if (number3Val) {
        onChange(objKey, number3Val);
      } else if (!number3Val && valueOfInputObj) {
        // fallback on last valid value from input
        onChange(objKey, valueOfInputObj);
        setHexInput(linearSpaceColorToGammaSpaceHex(valueOfInputObj));
      } else if (!number3Val) {
        setHexInput('');
      }
    }
    isTypingRef.current = false;
  };

  const onPasteClipboardClicked = (): void => {
    if (!clipboardHex) {
      return;
    }
    const newNumber3 = gammaSpaceHexToLinearSpaceColor(clipboardHex);
    if (newNumber3) {
      onChange(objKey, newNumber3);
    }
    setClipboardHex(undefined);
  };

  return (
    <MaterialPropFormControlLabel
      data-cmptype="MaterialPropColorInput"
      displayName={displayName}
      searchValue={searchValue}
      setMaxWidth
      disabled={disabled}
      control={
        <div className="relative flex flex-row gap-2" tabIndex={-1}>
          <TextInput
            value={hexInput}
            onValueChange={onInputChange}
            onBlur={onInputBlur}
            disabled={disabled || isPickerOpen}
            onFocus={onInputFocus}
          />
          {isValueSet ? (
            <button
              type="button"
              className="h-9 w-9 rounded border border-neutral-50"
              style={{ backgroundColor: hexInput }}
              onClick={handleClick}
              disabled={disabled}
            />
          ) : (
            !disabled && <Button type="button" text="Pick color" variant="Outlined" onClick={handleClick} />
          )}
          <div ref={colorPickerRef} className="invisible"></div>
          <Popover open={isPickerOpen} anchorEl={anchorEl} onClose={handleClose}>
            <div className="m-1 flex flex-col items-center gap-1">
              {clipboardHex && (
                <Button
                  variant="Outlined"
                  Svg={TaskListArrowRight}
                  text={`${t('Paste')} ${clipboardHex}`}
                  onClick={onPasteClipboardClicked}
                />
              )}
              <Sketch
                presetColors={false}
                style={{ boxShadow: 'none', borderRadius: 0 }}
                color={hexInput}
                disableAlpha
                onChange={onColorPickerChange}
              />
            </div>
          </Popover>
        </div>
      }
    />
  );
};
