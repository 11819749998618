import * as HttpService from 'services/http/http.service';

type CfgrHistoryVersionDto = {
  version: string;
  originStage: string;
  publishedBy: string;
  publishedAt: number;
  basedOnVersion: string;
  draftDisplayName: string;
  customVersion: string;
  devComment?: string;
};

export type CfgrHistoryVersion = CfgrHistoryVersionDto;

type RestorableDraftDto = {
  id: string;
  displayName: string;
  parent: string;
  createdAt: number;
  createdBy: string;
  lastEditedAt: number;
  lastEditedBy: string;
  deletedAt: number;
  deletedBy: string;
  deleteAfter: number;
};

export type RestorableDraft = RestorableDraftDto;

export async function postConfiguratorHistoryPublish(
  companyId: string,
  configuratorName: string,
  version: string
): Promise<void> {
  const { url, params } = HttpService.endpoints.configuratorhistoryPublish(companyId, configuratorName, version);
  await HttpService.httpClient.post(url, params);
}

export async function postConfiguratorHistoryWithdraw(companyId: string, configuratorName: string): Promise<void> {
  const { url, params } = HttpService.endpoints.configuratorhistoryWithdraw(companyId, configuratorName);
  await HttpService.httpClient.post(url, params);
}

export async function postConfiguratorHistoryCreateDraft(
  sourceCompanyId: string,
  sourceConfiguratorName: string,
  sourceDraftId: string,
  draftDisplayName: string,
  destinationCfgrName: string
): Promise<void> {
  const { url, params } = HttpService.endpoints.configuratorhistoryCreateDraft(
    sourceCompanyId,
    sourceConfiguratorName,
    sourceDraftId,
    draftDisplayName,
    destinationCfgrName
  );
  await HttpService.httpClient.post(url, params);
}

export async function deleteConfiguratorHistoryDeleteDraft(
  companyId: string,
  configuratorName: string,
  draftId: string
): Promise<void> {
  const { url, params } = HttpService.endpoints.configuratorhistoryDeleteDraft(companyId, configuratorName, draftId);
  await HttpService.httpClient.delete(url, { data: params });
}

export async function postConfiguratorHistoryCreateStage(
  companyId: string,
  configuratorName: string,
  stageName: string,
  version: string
): Promise<void> {
  const { url, params } = HttpService.endpoints.configuratorhistoryCreateStage(
    companyId,
    configuratorName,
    stageName,
    version
  );
  await HttpService.httpClient.post(url, params);
}

export async function deleteConfiguratorHistoryDeleteStage(
  companyId: string,
  configuratorName: string,
  stageName: string
): Promise<void> {
  const { url, params } = HttpService.endpoints.configuratorhistoryDeleteStage(companyId, configuratorName, stageName);
  await HttpService.httpClient.delete(url, { data: params });
}

export async function postConfiguratorHistoryPublishStage(
  companyId: string,
  configuratorName: string,
  stageName: string
): Promise<void> {
  const { url, params } = HttpService.endpoints.configuratorhistoryPublishStage(companyId, configuratorName, stageName);
  await HttpService.httpClient.post(url, params);
}

export async function getConfiguratorHistoryGetFullHistory(
  companyId: string,
  configuratorName: string
): Promise<CfgrHistoryVersion[]> {
  const { url, params } = HttpService.endpoints.configuratorhistoryGetFullHistory(companyId, configuratorName);
  const response = await HttpService.httpClient.get<CfgrHistoryVersionDto[]>(url, { params: params });
  return response.data;
}

export async function getConfiguratorHistoryGetRestorableDrafts(
  companyId: string,
  configuratorName: string
): Promise<RestorableDraft[]> {
  const { url, params } = HttpService.endpoints.configuratorhistoryGetRestorableDrafts(companyId, configuratorName);
  const response = await HttpService.httpClient.get<RestorableDraftDto[]>(url, { params: params });
  return response.data;
}

export async function postConfiguratorHistoryRestoreDraft(
  companyId: string,
  configuratorName: string,
  draftId: string
): Promise<RestorableDraft[]> {
  const { url, params } = HttpService.endpoints.configuratorhistoryPostRestoreDraft(
    companyId,
    configuratorName,
    draftId
  );
  const response = await HttpService.httpClient.post(url, params);
  return response.data;
}
