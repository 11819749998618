import { useState } from 'react';
import { InfoIcon } from 'assets/icons';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { FormItem } from 'controls/form-item/form-item';
import { Icon } from 'controls/icon/icon';
import { TextInput } from 'controls/input/text-input';
import { isUnusedAssetKey } from 'helper/assets/assets.helper';
import { isValidHiveIdentifier } from 'helper/hive/hive.helper';
import { useCreateFolder } from 'hooks/assets/assets.hooks';
import { useAlertDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectAssetKeys, selectSelectedAssetPath } from 'slices/assets/assets.slice';

type CreateFolderAssetModalProps = {
  onConfirm?: () => void;
  onCancel: () => void;
};

export const CreateFolderAssetModal: React.FC<CreateFolderAssetModalProps> = ({ onConfirm, onCancel }) => {
  const { t } = useTypedTranslation();
  const createFolder = useCreateFolder();
  const alert = useAlertDialog();

  const selectedAssetPath = useAppSelector(selectSelectedAssetPath);
  const assetKeys = useAppSelector(selectAssetKeys);

  const [newFolderName, setNewFolderName] = useState('');
  const [confirmDisabled, setConfirmDisabled] = useState(true);

  const onFolderConfirm = (): void => {
    if (!isValidHiveIdentifier(newFolderName)) {
      // TODO: proper error handling
      alert(t('Invalid folder name!'), { variant: 'Error' });
      return;
    } else if (!isUnusedAssetKey({ ...selectedAssetPath, assetId: newFolderName, folderId: '' }, assetKeys)) {
      // TODO: proper error handling
      alert(t('Folder name already used!'), { headerText: t('Duplicate name'), variant: 'Warning' });
      return;
    }

    onConfirm?.();
    createFolder(newFolderName);
  };

  const newNameChanged = (value: string): void => {
    setNewFolderName(value);
    const nameIsEmpty = value.length === 0;
    setConfirmDisabled(nameIsEmpty);
  };

  return (
    <ModalDialog
      variant="NoIcon"
      data-cmptype="CreateAssetFolder"
      header={t('New folder')}
      actions={{ onConfirm: onFolderConfirm, onCancel: onCancel }}
      confirmDisabled={confirmDisabled}
      confirmText={t('Create folder')}
    >
      <div className="flex grow flex-col gap-3">
        <FormItem labelContent={t('Folder name')}>
          <TextInput value={newFolderName} onValueChange={newNameChanged} autoFocus />
        </FormItem>
        {selectedAssetPath.assetId && (
          <div className="flex items-center gap-2 text-s-regular">
            <Icon Svg={InfoIcon} className="w-5" />
            <span>{t('The folder will be created at the top level')}</span>
          </div>
        )}
      </div>
    </ModalDialog>
  );
};
