import { IdentityTypes } from 'generated/identity-types';
import { EndpointDescription } from 'services/http/http.service';

export const ASSETBUNDLES_ENDPOINTS = {
  createEmptyAssetBundleDraft: '/assetbundles/createEmptyDraft',
  createAssetBundleDraftFrom: '/assetbundles/createDraft',
  deleteAssetBundles: '/assetbundles',
  deleteAssetBundleVersion: '/assetbundles/deleteDraft',
  publishAssetBundleDraft: '/assetbundles/publish',
  assetbundlesGetAllBundlesForCompany: (companyId: string): EndpointDescription => ({
    url: '/assetbundles/getAllBundlesForCompany',
    params: { companyName: companyId },
  }),
  assetbundlesGetRelevantVersionsForBundle: (
    companyId: string,
    bundleId: string,
    limit: number
  ): EndpointDescription => ({
    url: '/assetbundles/getRelevantVersionsForBundle',
    params: { companyName: companyId, assetBundleName: bundleId, limit },
  }),
  assetbundlesGetAllPublishedVersionsForBundle: (
    companyId: string,
    bundleId: string,
    continuationToken?: string,
    pageSize?: number,
    publishedByType?: IdentityTypes,
    publishedBy?: string
  ): EndpointDescription => ({
    url: '/assetbundles/getAllPublishedVersionsForBundle',
    params: {
      companyName: companyId,
      assetBundleName: bundleId,
      continuationToken,
      pageSize,
      filter: { publishedByType, publishedBy },
    },
  }),
  assetbundlesGetAllDraftVersionsForBundle: (
    companyId: string,
    bundleId: string,
    continuationToken?: string,
    pageSize?: number,
    createdByType?: IdentityTypes,
    createdBy?: string
  ): EndpointDescription => ({
    url: '/assetbundles/getAllDraftVersionsForBundle',
    params: {
      companyName: companyId,
      assetBundleName: bundleId,
      continuationToken,
      pageSize,
      filter: { createdByType, createdBy },
    },
  }),
  assetbundlesGetVersion: (companyId: string, bundleId: string, bundleVersion: string): EndpointDescription => ({
    url: '/assetbundles/get',
    params: { companyName: companyId, assetBundleName: bundleId, assetBundleVersion: bundleVersion },
  }),
  assetbundlesGetCfgrVersionsAssignedToBundleDraft: (
    companyId: string,
    bundleId: string,
    bundleVersion: string
  ): EndpointDescription => ({
    url: '/assetbundles/getCfgrVersionsAssignedToBundleDraft',
    params: { companyName: companyId, assetBundleName: bundleId, assetBundleDraftVersion: bundleVersion },
  }),
  assetbundlesGetBundleVersionsAssignedToCfgr: (
    companyId: string,
    cfgrId: string,
    cfgrVersion?: string
  ): EndpointDescription => ({
    url: '/assetbundles/getBundleVersionsAssignedToCfgr',
    params: { companyName: companyId, configuratorName: cfgrId, configuratorVersion: cfgrVersion },
  }),
};
