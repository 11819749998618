import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button } from 'controls/button/button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { FormItem } from 'controls/form-item/form-item';
import { FormControlLabel } from 'controls/form/form-control-label';
import { TextInput } from 'controls/input/text-input';
import { Switch } from 'controls/switch/switch';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { CompanyDetails, postUpdateCompanyDetails } from 'services/companies/companies.service';
import {
  getCompanyDetailsThunk,
  selectCompanyDetails,
  selectSelectedCompany,
} from 'slices/company-data/company-data.slice';

const TextFormItem = ({
  labelText,
  value,
  readOnly,
  isLoading,
  onValueChange,
}: {
  labelText: string;
  value: string;
  readOnly: boolean;
  isLoading: boolean;
  onValueChange: (value: string) => void;
}): JSX.Element => {
  const { t } = useTypedTranslation();

  return (
    <FormItem labelContent={labelText} className="grow-0">
      {readOnly ? (
        // `h-9` = Same height as our text inputs so there's no layout shift when toggling between edit & read only mode
        <div className="flex h-9 items-center text-m-medium text-neutral-100">{value || t('Not set')}</div>
      ) : (
        <TextInput value={value} onValueChange={onValueChange} disabled={isLoading} />
      )}
    </FormItem>
  );
};

export const CompanySettingsDetailsCard: React.FC = () => {
  const { t, tCompanyDetailsCardSubHeader } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const selectedCompany = useAppSelector(selectSelectedCompany);
  const selectedCompanyDetails = useAppSelector(selectCompanyDetails);
  const companyId = selectedCompany?.id ?? '';
  const companyDisplayName = selectedCompany?.displayName ?? companyId;

  const [isLoading, setIsLoading] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);

  const [companyName, setCompanyName] = useState(companyDisplayName);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [vatId, setVatId] = useState('');
  const [invoiceEmail, setInvoiceEmail] = useState('');
  const [invoicePhone, setInvoicePhone] = useState('');
  const [invoiceStreet, setInvoiceStreet] = useState('');
  const [invoicePostalCode, setInvoicePostalCode] = useState('');
  const [invoiceCity, setInvoiceCity] = useState('');
  const [invoiceCountry, setInvoiceCountry] = useState('');

  const [usePostalAddress, setUsePostalAddress] = useState(true);

  /**
   * @param companyDetails Resets form to empty values if not given
   */
  const updateFormValues = (companyName: string, companyDetails?: CompanyDetails): void => {
    setCompanyName(companyName);

    const postalAddress = companyDetails?.postalAddress;
    setEmail(postalAddress?.email ?? '');
    setPhone(postalAddress?.phone ?? '');
    setStreet(postalAddress?.street ?? '');
    setPostalCode(postalAddress?.postalCode ?? '');
    setCity(postalAddress?.city ?? '');
    setCountry(postalAddress?.country ?? '');
    setVatId(companyDetails?.billingInformation.vatId ?? '');

    const invoiceAddress = companyDetails?.billingInformation.invoiceAddress;
    setInvoiceEmail(invoiceAddress?.email ?? '');
    setInvoicePhone(invoiceAddress?.phone ?? '');
    setInvoiceStreet(invoiceAddress?.street ?? '');
    setInvoicePostalCode(invoiceAddress?.postalCode ?? '');
    setInvoiceCity(invoiceAddress?.city ?? '');
    setInvoiceCountry(invoiceAddress?.country ?? '');

    const invoiceAddressEqualsPostalAddress = isEqual(postalAddress, invoiceAddress);
    setUsePostalAddress(invoiceAddressEqualsPostalAddress);
  };

  useEffect(() => {
    updateFormValues(companyDisplayName, selectedCompanyDetails);
  }, [selectedCompanyDetails, companyDisplayName]);

  const toggleEditMode = (): void => {
    updateFormValues(companyDisplayName, selectedCompanyDetails);
    setInEditMode(value => !value);
  };

  const onSubmitForm = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    setIsLoading(true);

    const name = companyId;
    const displayName = companyName;
    const defaultCulture = selectedCompanyDetails?.defaultCulture ?? '';
    const postalAddress = { street, postalCode, city, country, phone, email };
    const invoiceAddress = usePostalAddress
      ? postalAddress
      : {
          street: invoiceStreet,
          postalCode: invoicePostalCode,
          city: invoiceCity,
          country: invoiceCountry,
          phone: invoicePhone,
          email: invoiceEmail,
        };

    await postUpdateCompanyDetails({ name, displayName, vatId, defaultCulture, postalAddress, invoiceAddress });
    dispatch(getCompanyDetailsThunk({ companyId }));

    setIsLoading(false);
    setInEditMode(false);
  };

  return (
    <CbnCard data-cmptype="CompanySettingsDetailsCard">
      <CbnCardHeader
        title={t('Company Details')}
        subText={tCompanyDetailsCardSubHeader(companyDisplayName)}
        actions={
          <Button
            text={inEditMode ? t('Cancel') : t('Edit')}
            variant="Secondary"
            onClick={toggleEditMode}
            disabled={isLoading}
          />
        }
      />

      <CbnCardBody>
        <form className="flex flex-row gap-10" onSubmit={onSubmitForm}>
          <div className="flex flex-1 flex-col gap-4">
            <TextFormItem
              labelText={t('Company name')}
              value={companyName}
              onValueChange={setCompanyName}
              readOnly={!inEditMode}
              isLoading={isLoading}
            />
            <TextFormItem
              labelText={t('Email address')}
              value={email}
              onValueChange={setEmail}
              readOnly={!inEditMode}
              isLoading={isLoading}
            />
            <TextFormItem
              labelText={t('Phone number')}
              value={phone}
              onValueChange={setPhone}
              readOnly={!inEditMode}
              isLoading={isLoading}
            />
            <TextFormItem
              labelText={t('Street')}
              value={street}
              onValueChange={setStreet}
              readOnly={!inEditMode}
              isLoading={isLoading}
            />
            <TextFormItem
              labelText={t('Postal code')}
              value={postalCode}
              onValueChange={setPostalCode}
              readOnly={!inEditMode}
              isLoading={isLoading}
            />
            <TextFormItem
              labelText={t('City')}
              value={city}
              onValueChange={setCity}
              readOnly={!inEditMode}
              isLoading={isLoading}
            />
            <TextFormItem
              labelText={t('Country')}
              value={country}
              onValueChange={setCountry}
              readOnly={!inEditMode}
              isLoading={isLoading}
            />
            <TextFormItem
              labelText={t('Tax number')}
              value={vatId}
              onValueChange={setVatId}
              readOnly={!inEditMode}
              isLoading={isLoading}
            />
          </div>
          <div className="flex h-auto flex-1 flex-col gap-4">
            <FormItem
              labelContent={t('Invoice address')}
              className="grow-0"
              labelClassName="text-neutral-100 text-m-medium"
            >
              {!inEditMode && (
                <div className="flex h-9 items-center text-m-regular">
                  {t(usePostalAddress ? 'Postal address is used.' : 'Your invoice is sent to:')}
                </div>
              )}
              {inEditMode && (
                <FormControlLabel
                  label={t('Use postal address')}
                  labelPlacement="start"
                  // Same height as our text inputs so that all form items on the left & right nicely align horizontally
                  className="h-9"
                  control={
                    <Switch
                      checked={usePostalAddress}
                      disabled={isLoading}
                      onValueChanged={(): void => setUsePostalAddress(value => !value)}
                    />
                  }
                />
              )}
            </FormItem>
            {!usePostalAddress && (
              <>
                <TextFormItem
                  labelText={t('Email address')}
                  value={invoiceEmail}
                  onValueChange={setInvoiceEmail}
                  readOnly={!inEditMode}
                  isLoading={isLoading}
                />
                <TextFormItem
                  labelText={t('Phone number')}
                  value={invoicePhone}
                  onValueChange={setInvoicePhone}
                  readOnly={!inEditMode}
                  isLoading={isLoading}
                />
                <TextFormItem
                  labelText={t('Street')}
                  value={invoiceStreet}
                  onValueChange={setInvoiceStreet}
                  readOnly={!inEditMode}
                  isLoading={isLoading}
                />
                <TextFormItem
                  labelText={t('Postal code')}
                  value={invoicePostalCode}
                  onValueChange={setInvoicePostalCode}
                  readOnly={!inEditMode}
                  isLoading={isLoading}
                />
                <TextFormItem
                  labelText={t('City')}
                  value={invoiceCity}
                  onValueChange={setInvoiceCity}
                  readOnly={!inEditMode}
                  isLoading={isLoading}
                />
                <TextFormItem
                  labelText={t('Country')}
                  value={invoiceCountry}
                  onValueChange={setInvoiceCountry}
                  readOnly={!inEditMode}
                  isLoading={isLoading}
                />
              </>
            )}
            {inEditMode && (
              <div className="flex flex-1 items-end justify-end">
                <Button text={t('Save')} variant="Primary" type="submit" isLoading={isLoading} />
              </div>
            )}
          </div>
        </form>
      </CbnCardBody>
    </CbnCard>
  );
};
