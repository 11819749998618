import React from 'react';
import { useParams } from 'react-router';
import { ErrorBoundary } from 'components/error-handling/cbn-error-boundary';
import { WorkflowConfig } from 'components/workflows/details/config/workflow-config';
import { WorkflowDetails } from 'components/workflows/details/workflow-details';
import { WorkflowRunLog } from 'components/workflows/details/workflow-run-log';
import { WorkflowsOverview } from 'components/workflows/workflows-overview';
import { useAppSelector } from 'hooks/store/store.hooks';
import { useWorkflowFetcher, useWorkflowRouteSync } from 'hooks/workflows/workflows.hooks';
import { WorkflowsRoutePages, WorkflowsRouteParams } from 'services/routes/workflows-routes.service';
import { aiInstance } from 'services/telemetry/telemetry.service';
import { selectSelectedWorkflow, selectSelectedWorkflowRun } from 'slices/workflows/workflows.slice';

export const Workflows: React.FC = () => {
  useWorkflowRouteSync();
  useWorkflowFetcher();

  const routeParams = useParams<WorkflowsRouteParams>();
  const routePage: WorkflowsRoutePages = routeParams.workflowPage
    ? (routeParams.workflowPage as WorkflowsRoutePages)
    : 'detail';

  const selectedWorkflow = useAppSelector(selectSelectedWorkflow);
  const selectedWorkflowRun = useAppSelector(selectSelectedWorkflowRun);

  // The wrapper divs with `p-0.5` for details & logs are required so that they align the same as the config & overview
  // cards. They're initially required to not "cut" the shadows of the cards within the `WorkflowConfig`.
  const WorkflowsOutlet = selectedWorkflowRun ? (
    <div className="grid p-0.5">
      <WorkflowRunLog />
    </div>
  ) : routePage === 'config' ? (
    <WorkflowConfig />
  ) : (
    <div className="grid p-0.5">
      <WorkflowDetails />
    </div>
  );

  return (
    <div data-cmptype="Workflows" className="grid h-full grid-cols-[2fr_6fr] gap-6 p-6">
      {/*
      The wrapper div with `p-0.5` is required so that the overview card aligns the same as its neighbour container (the
      parent for the "config header" and the parameters card), which also needs `p-0.5` to not "cut" the cards shadows
      at the edges.
      */}
      <div className="grid p-0.5">
        <WorkflowsOverview />
      </div>
      {selectedWorkflow && (
        <ErrorBoundary
          key={selectedWorkflow.workflowName}
          appInsights={aiInstance}
          hideLogoutBtn
          resetKeys={selectedWorkflow ? [selectedWorkflow.workflowName, selectedWorkflow.companyId] : []}
        >
          {WorkflowsOutlet}
        </ErrorBoundary>
      )}
    </div>
  );
};
