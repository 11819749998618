/**
 * Read clipboard text in a safe manner (check if it's supported by the browser & permission is granted)
 */
export async function readClipboardText(): Promise<string | null> {
  // E.g.: Firefox doesn't support this in general
  if (!navigator.clipboard.readText || !document.hasFocus()) {
    return null;
  }

  try {
    const permission = await window.navigator.permissions.query({
      // @ts-ignore typing of `PermissionName` doesn't contain this name
      name: 'clipboard-read',
    });

    if (permission.state === 'denied') {
      return null;
    }

    return await navigator.clipboard.readText();
  } catch {
    return null;
  }
}
