import { TexturePropertyKey } from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor-properties-def';
import {
  MaterialPropBaseInputProps,
  MaterialPropFormControlLabel,
} from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor-standard-prop-inputs';
import { MaterialPropTextureImage } from 'components/asset-editor/details/asset-body/material-assets/texture-input/material-prop-texture-image';
import { MaterialPropTextureSettings } from 'components/asset-editor/details/asset-body/material-assets/texture-input/material-prop-texture-settings';
import { Switch } from 'controls/switch/switch';

type MaterialPropTextureInputProps = MaterialPropBaseInputProps & {
  linkedTexture?: string;
  onLinkedTextureChange: (value: string) => void;
  onTexturePropChange: (key: TexturePropertyKey, value: boolean | number | undefined) => void;
  onToggleTexture: (value: boolean) => void;
  onUploadImageAssetStarted: () => void;
  onUploadImageAssetFinished: () => void;
};

export const MaterialPropTextureInput: React.FC<MaterialPropTextureInputProps> = ({
  inputObj,
  objKey,
  disabled,
  displayName,
  searchValue,
  linkedTexture,
  onLinkedTextureChange,
  onTexturePropChange,
  onToggleTexture,
  onUploadImageAssetStarted,
  onUploadImageAssetFinished,
}) => {
  // textures may be nested (eg: anisotropic.texture), so we have to consider sub keys as well
  const [baseKey, subKey] = objKey.split('.');
  const textureObj = subKey ? inputObj[baseKey]?.[subKey] : inputObj[baseKey];
  // texture UI should get activated if either an image asset is linked or if there are entries in the texture object
  const textureDataAvailable = !!linkedTexture || !!textureObj;

  return (
    // Use "group" to indicate last texture input of material property group, since it has some special border treatment
    <div data-cmptype="MaterialPropTextureInput" className="group">
      <MaterialPropFormControlLabel
        displayName={displayName}
        searchValue={searchValue}
        disabled={disabled}
        control={<Switch checked={textureDataAvailable} onValueChanged={onToggleTexture} disabled={disabled} />}
      />
      {textureDataAvailable && (
        <>
          <MaterialPropTextureImage
            disabled={disabled}
            linkedTexture={linkedTexture}
            onLinkedTextureChange={onLinkedTextureChange}
            onUploadImageAssetStarted={onUploadImageAssetStarted}
            onUploadImageAssetFinished={onUploadImageAssetFinished}
          />
          <MaterialPropTextureSettings
            textureName={objKey}
            inputObj={textureObj ?? {}}
            disabled={disabled}
            onTexturePropChange={onTexturePropChange}
          />
        </>
      )}
    </div>
  );
};
