import { UserProfileName } from 'components/user-profile/user-profile-name';
import { UserProfilePassword } from 'components/user-profile/user-profile-password';

export const UserProfile: React.FC = () => {
  return (
    <div data-cmptype="UserProfile" className="flex w-[500px] flex-col gap-6 p-6">
      <UserProfileName />
      <UserProfilePassword />
    </div>
  );
};
