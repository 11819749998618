import { styled } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridFooter,
  GridValidRowModel,
  UncapitalizedGridProSlotsComponent,
  gridClasses,
} from '@mui/x-data-grid-pro';
import { EyeHiddenIcon, HorizontalDotsMenuIcon, PinBigIcon, SearchIcon, ThreeColumns } from 'assets/icons';
import { Checkbox } from 'controls/checkbox/checkbox';
import { CustomFooterProps, DataGridCustomFooter } from 'controls/datagrid/data-grid-custom-footer';
import { CustomPagination } from 'controls/datagrid/data-grid-custom-pagination';
import { CustomToolbarProps, DataGridCustomToolbar } from 'controls/datagrid/data-grid-custom-toolbar';
import { Icon } from 'controls/icon/icon';
import { Input } from 'controls/input/input';
import { StyledMenuItem, StyledSelectBase } from 'controls/select/select';
import { Tooltip } from 'controls/tooltip/tooltip';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

export const UNKNOWN_ROW_COUNT = Number.MAX_VALUE;

// - Reuse generic `R` from `DataGridProProps`
// - Omit props we specifically override (in case of changes ensure that no props "get lost")
// - Add Cbn specific props
type DataGridProps<R extends GridValidRowModel = { [key: string | symbol]: any }> = Omit<
  DataGridProProps<R>,
  'slots' | 'slotProps' | 'rowHeight' | 'localeText'
> & {
  customToolbarProps?: CustomToolbarProps;
  customFooterProps?: CustomFooterProps;
  customColumnMenu?: UncapitalizedGridProSlotsComponent['columnMenu'];
};

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  fontSize: theme.typography.sRegular.fontSize,
  lineHeight: theme.typography.sRegular.lineHeight,
  fontWeight: theme.typography.sRegular.fontWeight,
  border: 'none',
  [`& .${gridClasses.main}`]: {
    border: 'none',
  },

  [`& .${gridClasses.columnHeaders}`]: {
    background: theme.palette.neutral[30],
    fontSize: theme.typography.tableHeading.fontSize,
    lineHeight: `${theme.typography.tableHeading.lineHeight} !important`,
    fontWeight: theme.typography.tableHeading.fontWeight,
    letterSpacing: theme.typography.tableHeading.letterSpacing,
    // `!important` is necessary because the header height is tightly bound to the "density" which
    // sets values directly on the element and this is probably the easiest solution to overwrite it
    minHeight: '56px !important',
  },

  [`& .${gridClasses.pinnedColumnHeaders}`]: {
    background: theme.palette.neutral[30],
  },

  [`& .${gridClasses.columnHeader}:focus,
    & .${gridClasses.columnHeader}:focus-within
    & .${gridClasses.cell}:focus,
    & .${gridClasses.cell}:focus-within`]: {
    outlineColor: theme.palette.neutral[50],
  },

  [`& .${gridClasses.cell}:not(.${gridClasses.rowReorderCellContainer}), & .${gridClasses.columnHeader}`]: {
    padding: theme.spacing(0, 4),
  },

  [`& .${gridClasses.footerContainer}`]: {
    justifyContent: 'center',
  },

  [`& .${gridClasses.cell}.${gridClasses['cell--editing']}`]: {
    outline: '0 !important',
    padding: theme.spacing(2, 2),
  },
}));

export const DataGrid: React.FC<DataGridProps> = props => {
  const { t } = useTypedTranslation();
  const { customToolbarProps, customFooterProps, customColumnMenu, ...rest } = props;

  return (
    <StyledDataGridPro
      {...rest}
      rowHeight={40}
      slots={{
        baseTooltip: Tooltip,
        toolbar: customToolbarProps ? DataGridCustomToolbar : undefined,
        footer: customFooterProps ? DataGridCustomFooter : GridFooter,
        columnMenu: customColumnMenu ? customColumnMenu : undefined,
        columnMenuIcon: () => <Icon Svg={HorizontalDotsMenuIcon} className="h-6 text-primary-main" />,
        columnMenuPinLeftIcon: () => <Icon Svg={PinBigIcon} className="h-5" />,
        columnMenuPinRightIcon: () => <Icon Svg={PinBigIcon} className="h-5 -scale-x-100" />,
        columnMenuHideIcon: () => <Icon Svg={EyeHiddenIcon} className="h-5" />,
        columnMenuManageColumnsIcon: () => <Icon Svg={ThreeColumns} className="h-5" />,
        pagination: CustomPagination,
        baseCheckbox: Checkbox,
        baseSelect: StyledSelectBase,
        /** Omit the prop `native` as it throws an error otherwise. MUI issue? */
        baseSelectOption: ({ native, ...props }) => <StyledMenuItem {...props} />,
        baseTextField: ({ InputProps, ...props }) => (
          <Input {...props} startAdornment={InputProps?.startAdornment} endAdornment={InputProps?.endAdornment} />
        ),
        quickFilterIcon: () => <Icon Svg={SearchIcon} className="h-5" />,
      }}
      slotProps={{
        toolbar: customToolbarProps,
        footer: customFooterProps,
      }}
      localeText={{
        columnMenuLabel: '', // don't show tooltip for "Menu"
        noRowsLabel: t('No rows'),
      }}
    />
  );
};
