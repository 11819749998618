import { throttle } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

/**
 * Get the size of a ref element
 *
 * @param throttleWait throttle the resize-observer
 * @returns a mutable ref object,  the `DOMRect` of the element & the element itself
 */
export const useElementSize = <T extends HTMLElement | null>(
  throttleWait = 100
): {
  ref: (node: T) => void;
  size: DOMRectReadOnly | undefined;
  element: T | undefined;
} => {
  const [element, setElement] = useState<T>();
  const [rect, setRect] = useState<DOMRectReadOnly>();

  // a "callback ref" is the recommended way to register when a ref is changing
  const ref = useCallback((node: T) => {
    if (node !== null) {
      setElement(node);
    }
  }, []);

  useEffect(() => {
    if (!element) {
      return;
    }

    const obs = new ResizeObserver(
      throttle((entries: ResizeObserverEntry[]) => setRect(entries[0].contentRect), throttleWait)
    );
    obs.observe(element);
    return (): void => obs.disconnect();
  }, [element, throttleWait]);

  return { ref, size: rect, element };
};
