import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect } from 'react';
import { SaveIcon } from 'assets/icons';
import { Button } from 'controls/button/button';
import { CbnToggleBoolean } from 'controls/cbn-toggle-button/cbn-toggle-boolean';
import { useMinEditWidth } from 'controls/datagrid/column-types/column-types.hooks';

/**
 * Edit boolean toggle cell based on the default from MUI
 *
 * The core logic is taken from `packages\grid\x-data-grid\src\components\cell\GridEditBooleanCell.tsx`
 */
export const GridEditBooleanToggleCell: React.FC<GridRenderEditCellParams> = ({
  id,
  value,
  field,
  hasFocus,
  colDef,
  api,
}) => {
  const inputRef = React.useRef<HTMLElement>(null);
  const [valueState, setValueState] = React.useState(value);

  useMinEditWidth(field, colDef);

  const handleChange = React.useCallback(
    async (newValue: boolean) => {
      setValueState(newValue);
      await api.setEditCellValue({ id: id, field, value: newValue });
    },
    [api, field, id]
  );

  useEffect(() => {
    setValueState(value);
  }, [value]);

  useEnhancedEffect(() => {
    if (hasFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [hasFocus]);

  const handleSubmit = useCallback(() => {
    api.stopCellEditMode({ id, field });
  }, [api, id, field]);

  return (
    <div className="flex items-center gap-2">
      <CbnToggleBoolean value={valueState} onChange={handleChange} ref={inputRef} />
      <Button Svg={SaveIcon} onClick={handleSubmit} />
    </div>
  );
};
