import { DistributiveOmit } from 'react-redux';
import { IdentityTypes } from 'generated/identity-types';
import { HttpStatusCode } from 'helper/http/http-status.helper';
import { mapObject } from 'helper/object/object.helper';
import * as HttpService from 'services/http/http.service';

export function isUserIdentity(identity: Identity): identity is UserIdentity {
  return identity.$type === IdentityTypes.User;
}
export function isWorkflowIdentity(identity: Identity): identity is WorkflowIdentity {
  return identity.$type === IdentityTypes.Workflow;
}
export function isApiTokenIdentity(identity: Identity): identity is ApiTokenIdentity {
  return identity.$type === IdentityTypes.ApiToken;
}
export function isServiceIdentity(identity: Identity): identity is ServiceIdentity {
  return identity.$type === IdentityTypes.Service;
}

type IdentityBase = {
  $type: IdentityTypes;
  identityId: string;
};

export type UserIdentity = IdentityBase & {
  $type: IdentityTypes.User;
  firstname: string;
  lastname: string;
  email: string;
};

export type WorkflowIdentity = IdentityBase & {
  $type: IdentityTypes.Workflow;
  name: string;
  displayName: string;
};

export type ApiTokenIdentity = IdentityBase & {
  $type: IdentityTypes.ApiToken;
  name: string;
  displayName: string;
};

export type ServiceIdentity = IdentityBase & {
  $type: IdentityTypes.Service;
};

export type Identity = UserIdentity | WorkflowIdentity | ApiTokenIdentity | ServiceIdentity;

// omit the properties which aren't present in the response dto
type IdentityDto = DistributiveOmit<Identity, 'identityId'>;

export type Identities = { [identityId: string]: Identity };
type CompanyIdentitiesResponse = { [identityId: string]: IdentityDto };

function _identityResponseToIdentity(data: IdentityDto, identityId: string): Identity {
  return { ...data, identityId: identityId };
}

function _companyIdentityResponseToIdentities(data: CompanyIdentitiesResponse, companyName: string): Identities {
  return mapObject(data, (identity, key) => ({ ...identity, identityId: key }));
}

export async function getIdentityGet(identityId: string): Promise<Identity | null> {
  const { url, params } = HttpService.endpoints.identityGet(identityId);

  const response = await HttpService.httpClient.get<IdentityDto>(url, { params, validateStatus: () => true });
  if (response.status === HttpStatusCode.Ok_200) {
    return _identityResponseToIdentity(response.data, identityId);
  } else {
    return null;
  }
}

export async function getIdentityGetRelevantForCompany(companyName: string): Promise<Identities> {
  const { url, params } = HttpService.endpoints.identityGetRelevantForCompany(companyName);

  const response = await HttpService.httpClient.get<CompanyIdentitiesResponse>(url, { params });
  return _companyIdentityResponseToIdentities(response.data, companyName);
}
