import { PopoverProps } from '@mui/material';
import { useId, useState } from 'react';
import { SearchIcon } from 'assets/icons';
import { CbnPopover } from 'controls/cbn-popover/cbn-popover';
import { Checkbox } from 'controls/checkbox/checkbox';
import { Icon } from 'controls/icon/icon';
import { TextInput } from 'controls/input/text-input';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type OptionsPopoverProps = PopoverProps & {
  options: { value: string; displayName: string }[];
  selectedValues: string[];
  /**
   * Triggered when the value of a individual checkbox changes (i.e. the user clicks on it).
   * The consumer needs to update the `selectedValues` in order to reflect changes in the cmp etc.
   */
  onValueSelectionChange: (value: string, selected: boolean) => void;
  onToggleAll: () => void;
};

/**
 * Popover which shows some options with checkboxes and a search input.
 * `selectedValues` are controlled input. The consumer needs to handle updates to it in order to reflect changes in the
 * cmp etc.
 */
export const OptionsPopover: React.FC<OptionsPopoverProps> = ({
  open,
  anchorEl,
  onClose,
  options,
  selectedValues,
  onValueSelectionChange,
  onToggleAll,
}) => {
  const { t } = useTypedTranslation();
  const baseCheckboxId = useId();
  const selectAllId = useId();

  const [searchValue, setSearchValue] = useState('');
  const matchingOptions = options
    .filter(x => x.displayName.toLowerCase().includes(searchValue.toLowerCase()))
    .sort((a, b) => a.displayName.localeCompare(b.displayName));

  const allOptionsSelected = options.every(option => selectedValues.includes(option.value));

  return (
    <CbnPopover
      data-cmptype="OptionsPopover"
      disableRestoreFocus
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <div className="px-2 py-3">
        <TextInput
          type="search"
          autoFocus
          autoComplete="off"
          placeholder={t('Search...')}
          startAdornment={<Icon Svg={SearchIcon} className="w-6" />}
          value={searchValue}
          onValueChange={setSearchValue}
        />
      </div>

      <div className="flex flex-col gap-1 px-3 pb-3 pt-1 text-m-regular text-neutral-90">
        <div className="flex items-center gap-2.5">
          <Checkbox id={selectAllId} checked={allOptionsSelected} onValueChanged={(x): void => onToggleAll()} />
          <label htmlFor={selectAllId}>{t('Select/deselect all')}</label>
        </div>

        <span className="self-stretch border-b border-neutral-50" />

        {matchingOptions.map(option => (
          <div key={option.value} className="flex items-center gap-2.5">
            <Checkbox
              id={`${baseCheckboxId}-${option.value}`}
              checked={selectedValues.includes(option.value)}
              onValueChanged={(selected: boolean): void => {
                onValueSelectionChange(option.value, selected);
              }}
            />
            <label htmlFor={`${baseCheckboxId}-${option.value}`}>{option.displayName}</label>
          </div>
        ))}
      </div>
    </CbnPopover>
  );
};
