/**
 * Checks if given SAS URIs is valid by evaluating it's expiration date.
 * This date is provided via UTC time string in the `se` & `ske` query parameter.
 *
 * se = Expiration date for sas token
 * ske = Expiration date for user delegation key
 *
 * Ask backend dev or consult Azure docs for more details about those tokens.
 * E.g. https://learn.microsoft.com/en-us/rest/api/storageservices/create-user-delegation-sas#construct-a-user-delegation-sas
 *
 * If either one of both, `se` or `ske` is expired, the whole URI is expired as well
 */
export function isSasUriExpired(sasUri: string): boolean {
  let url: URL;
  try {
    url = new URL(sasUri);
  } catch (err) {
    // no valid URL could be retrieved from the input string
    return true;
  }

  const seExpirationDateStr = url.searchParams.get('se') || '';
  const seExpirationDate = new Date(decodeURIComponent(seExpirationDateStr));

  const skeExpirationDateStr = url.searchParams.get('ske') || '';
  const skeExpirationDate = new Date(decodeURIComponent(skeExpirationDateStr));

  if (isNaN(seExpirationDate.getTime()) || isNaN(skeExpirationDate.getTime())) {
    // no valid date, most likely due to missing `se` or `ske` query parameter or wrong format
    return true;
  }

  // add an offset to avoid expiration in the very next seconds, for example while fetching
  // 10 seconds in this case
  const bufferTimeMs = 10000;
  const targetDate = new Date(Date.now() + bufferTimeMs);

  const seIsExpired = targetDate > seExpirationDate;
  const skeIsExpired = targetDate > skeExpirationDate;

  // The given URI is expired if one of those 2 tokens is expired
  return seIsExpired || skeIsExpired;
}
