import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { globalCompanySwitch } from 'actions/general.actions';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { CfgrRouteParams } from 'services/routes/common-routeparams.service';
import { getRouteTitle } from 'services/routes/paths.service';
import { selectUploadingAssets } from 'slices/assets/assets.slice';
import {
  selectSelectedCompany,
  selectSelectedConfigurator,
  setCfgrSelection,
} from 'slices/company-data/company-data.slice';
import { fetchIdentitiesOfCompany } from 'slices/identity/identity.slice';

let _isBeforeUnloadRegistered = false;

/**
 * There is no consistent behaviour across browsers
 * so everything which can "activate" the prompt is implemented
 */
const _beforeUnloadListener = (event: BeforeUnloadEvent): string => {
  // This message isn't shown in modern browsers (controlled by browser itself)
  const returnVal = 'Unsaved changes detected! Are you sure you want to exit?';
  event.preventDefault();
  event.returnValue = returnVal;
  return returnVal;
};

const _registerBeforeUnloadListener = (): void => {
  if (!_isBeforeUnloadRegistered) {
    window.addEventListener('beforeunload', _beforeUnloadListener);
    _isBeforeUnloadRegistered = true;
  }
};

const _removeBeforeUnloadListener = (): void => {
  if (_isBeforeUnloadRegistered) {
    window.removeEventListener('beforeunload', _beforeUnloadListener);
    _isBeforeUnloadRegistered = false;
  }
};

export const usePromptOnUnsavedChanges = (): void => {
  const uploadingAssets = useAppSelector(selectUploadingAssets);

  const hasUnsavedChanges = useMemo(() => {
    // If the prompt is required for other cases, it should be sufficient to add additional conditions
    const assetUploadsInProgress = uploadingAssets.length > 0;
    return assetUploadsInProgress;
  }, [uploadingAssets.length]);

  useEffect(
    function registerListenerIfRequired() {
      if (hasUnsavedChanges) {
        _registerBeforeUnloadListener();
      } else {
        _removeBeforeUnloadListener();
      }
    },
    [hasUnsavedChanges]
  );

  useEffect(function cleanupOnUnmount() {
    return () => _removeBeforeUnloadListener();
  }, []);
};

/**
 * Keeps track of route changes which have to be dispatched to redux
 */
export const useDispatchGlobalRouteParamsToRedux = (): void => {
  const dispatch = useAppDispatch();
  const routeParams = useParams<CfgrRouteParams>();
  const companiesFetched = useAppSelector(state => state.companyData.companiesFetched);
  const cfgrsFetched = useAppSelector(state => state.companyData.cfgrsFetched);
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const selectedCfgr = useAppSelector(selectSelectedConfigurator);

  useEffect(() => {
    if (routeParams.companyId && companiesFetched && routeParams.companyId !== selectedCompany?.id) {
      dispatch(globalCompanySwitch(routeParams.companyId));
    }
  }, [routeParams.companyId, companiesFetched, selectedCompany, dispatch]);

  useEffect(() => {
    if (routeParams.cfgrId && cfgrsFetched && routeParams.cfgrId !== selectedCfgr?.id) {
      dispatch(setCfgrSelection(routeParams.cfgrId));
    }
  }, [routeParams.cfgrId, cfgrsFetched, selectedCfgr, dispatch]);
};

/**
 * Updates `document.title` based on the current route.
 * See `getRouteTitle` for more details on how the title is determined.
 */
export const useRouteTitle = (): void => {
  const { pathname } = useLocation();
  const { companyId, cfgrId } = useParams();
  const companyData = useAppSelector(state => state.companyData);

  const company = companyId && companyData.companies.find(x => x.id === companyId);
  const companyDisplayName = company && company.displayName;

  const cfgr = companyId && cfgrId && companyData.cfgrs[companyId]?.find(x => x.id === cfgrId);
  const cfgrDisplayName = cfgr && cfgr.displayName;

  const title = getRouteTitle(pathname, companyDisplayName, cfgrDisplayName);

  useEffect(() => {
    document.title = title;
  }, [title]);
};

export const useSyncIdentity = (): void => {
  const dispatch = useAppDispatch();
  const selectedCompany = useAppSelector(selectSelectedCompany);

  useEffect(() => {
    if (!selectedCompany?.id) {
      return;
    }

    dispatch(fetchIdentitiesOfCompany(selectedCompany.id));
  }, [dispatch, selectedCompany?.id]);
};
