export const WORKFLOWS_JINT_DEFINITIONS = `/** A blob of data, often obtained from an internal or external file or url. */
interface Data {
  /** Url of the downloaded data content. Not the same as the url from which data is loaded! */
  url: string;

  /** RAW string of the blob data, if the size is smaller than 1MB */
  raw: string;
}

/** A read-only, published version of an asset bundle */
interface AssetBundle {
  /** Name of the asset bundle */ 
  name: string;

  /** Version of the asset bundle */
  version: string;

  /**
   *  Creates an {@link AssetBundleDraft} that allows you to modify assets
   *  @param displayName the name to assign to the generated asset bundle draft. If omitted, a name will be generated.
   */
  createDraft: (displayName?: string) => Promise<AssetBundleDraft>;

  toString: () => string;
}

/** An asset bundle draft allows you to modify its assets */
interface AssetBundleDraft {
  /** Name of the asset bundle to which the draft belongs. */
  bundleName: string;

  /** Version of the asset bundle draft. */
  version: string;

  /** Name of the asset bundle draft. */
  name: string;

  /**
   * Returns an {@link Asset}, or throws if no such asset exists.
   * @param assetName name of the asset. 
   * @param folder (optional) name of the folder to which the asset belongs. Omit for top-level assets.
   * 
   * @remarks
   * You can also pass a combination of folder and asset name as the first argument: 'myFolder.myAsset'
   * In that case, you must not pass a value for the folder parameter.
   */
  getAsset: (assetName: string, folder?: string) => Asset;

  /**
   * Returns an {@link Asset}, or undefined if no such asset exists.
   * @param assetName name of the asset.
   * @param folder (optional) name of the folder to which the asset belongs. Omit for top-level assets.
   *
   * @remarks
   * You can also pass a combination of folder and asset name as the first argument: 'myFolder.myAsset'
   * In that case, you must not pass a value for the folder parameter.
   */
  tryGetAsset: (assetName: string, folder?: string) => Asset;

  /**
   * Publishes the asset bundle draft, thus allowing it to be used in published configurators.
   * 
   * @param callback a callback function that will be called with each configurator (draft) version to which this
   *   draft has been assigned, allowing you to update and optionally publish that configurator (draft).
   *   See {@link PublishCallback}.
   *   Alternatively, you can pass true to update and publish all assigned configurator (draft) versions,
   *   or false to not update any.
   */
  publish: (callback?: PublishCallback | boolean) => void;

  toString: () => string;
}

/**
 * A function that indicates whether a configurator (draft) version should be updated to use a specific asset bundle
 * and whether to automatically publish that configurator.
 * 
 * @param version the {@link CfgrVersion} of the configurator (draft) to evaluate
 * 
 * @returns true to update and publish the version; false to neither update or publish it. 
 *   Alternatively, an object with an update and publish property that indicate whether to update/publish the version.
 */
type PublishCallback = (version: CfgrVersion) => boolean | { update: boolean; publish: boolean };

interface CfgrVersion {
  /** Name of the company to which the configurator (draft) belongs */
  company: string;

  /** Name of the configurator */
  cfgr: string;

  /** Version of the configurator (draft) */
  version: string;

  /** Display name of the configurator or draft. */
  name: string;

  /** True if this version represents a configurator draft, false for a published configurator */ 
  isDraft: boolean;
}

/**
 * Assets belong to an {@link AssetBundle} or {@link AssetBundleDraft}.
 * There are many types of assets that provide different functionality.
 */
declare class Asset {
  /** Name of the asset */
  name: string;

  /** Folder to which the asset belongs, or an empty string if it is a top-level asset that belongs to no folder. */
  folder: string;

  toString(): string;
}

/** A data source asset contains tabular data */
declare class DataSourceAsset extends Asset {
  /**
   * Replaces the contents of the data source by importing new data.
   * @param data the data to import
   */
  update: (data: Data) => Promise<void>;
}

/** A file asset can contain arbitrary data */
declare class FileAsset extends Asset {
  /** Size of the file in bytes */
  size: number;

  /** Public url of the file */
  url: string;

  /**
   * Replaces the contents of file with the given data.
   * @param data the new file data
   * 
   * @remarks 
   * If the file asset is linked to one or more data source assets, those will be updated automatically as well
   */
  update: (data: Data) => Promise<void>;
}

/** A response returned by a http request */
declare class HttpResponse {
  /** A blob of data received with the response */
  data: Data;

  /** Status code of the HTTP response */
  status: number;

  /** HTTP headers received with the response. */
  headers: object;
}

/** Performs requests to an external endpoint */
interface HttpClient {
  /** 
   * Method is used to retrieve information from a server using a URL 
   * @param url URL of the request 
   * @param requestConfig optional configuration of the request
   */
  get: (url: string, requestConfig: object) => Promise<HttpResponse>;

  /** 
   * Only transfers the status and header section
   * @param url URL of the request
   * @param requestConfig optional configuration of the request
   */
  head: (url: string, requestConfig: object) => Promise<HttpResponse>;

  /** 
   * Describes the communication options for the target resource
   * @param url URL of the request
   * @param requestConfig optional configuration of the request
   */
  options: (url: string, requestConfig: object) => Promise<HttpResponse>;
  
  /**
   * Removes all current representations of the target resource given by a URL
   * @param url URL of the request
   * @param requestConfig optional configuration of the request
   */
  delete: (url: string, requestConfig: object) => Promise<HttpResponse>;

  /** 
   * Method to send data to the server
   * @param url URL of the request
   * @param data data represented as string
   * @param requestConfig optional configuration of the request
   */
  post: (url: string, data: string, requestConfig: object) => Promise<HttpResponse>;

  /** 
   * Replaces all current representations of the target resource with the uploaded content
   * @param url URL of the request
   * @param data data represented as string
   * @param requestConfig optional configurator of the request
   * */
  put: (url: string, data: string, requestConfig: object) => Promise<HttpResponse>;

  /** 
   * Method which applies partial modifications to a resource (i.e. updates a resource)
   * @param url URL of the request
   * @param data data represented as string
   * @param requestConfig optional configuration of the request
   */
  patch: (url: string, data: string, requestConfig: object) => Promise<HttpResponse>;
}

declare var http: HttpClient;`;