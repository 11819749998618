import { useState } from 'react';
import { SchemaNameInput, SchemaNameInputResult } from 'components/common/schema-name-input';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch } from 'hooks/store/store.hooks';
import { CfgrTemplate } from 'services/cfgrs/cfgrs.service';
import { createCfgrFromTemplate } from 'services/replicator/replicator.service';
import { getAssetBundles } from 'slices/assets/assets.slice';
import { getCompanyCfgrs } from 'slices/company-data/company-data.slice';

type CreateCfgrModalProps = {
  targetCompanyName: string;
  template: CfgrTemplate;
  existingCfgrsAndBundles: {
    cfgrNames: string[];
    cfgrDisplayNames: string[];
    assetBundleNames: string[];
    assetBundleDisplayNames: string[];
  };
  onCfgrCreated: (createdCfgrName: string) => void;
  onCancel: () => void;
};

export const CreateCfgrModal: React.FC<CreateCfgrModalProps> = ({
  targetCompanyName,
  template,
  existingCfgrsAndBundles,
  onCfgrCreated,
  onCancel,
}) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const [targetCfgrName, setTargetCfgrName] = useState<SchemaNameInputResult>({
    uniqueId: '',
    displayName: '',
    hasErrors: false,
  });
  const [isProcessing, setIsProcessing] = useState(false);

  const initialTargetAssetBundleNames = Object.entries(template.assetBundles).map(([key, value]) => ({
    templateBundleKey: key,
    targetDisplayName: value.bundleDisplayName,
    targetName: value.bundleName,
    isValid: true,
  }));
  const [targetAssetBundleNames, setTargetAssetBundleNames] = useState(initialTargetAssetBundleNames);

  const hasUnresolvedBundleNameConflicts = targetAssetBundleNames.some(
    x =>
      existingCfgrsAndBundles.assetBundleNames.includes(x.targetName) ||
      existingCfgrsAndBundles.assetBundleDisplayNames.includes(x.targetDisplayName)
  );

  const hasInvalidAssetBundleNames = targetAssetBundleNames.some(x => !x.isValid);
  const hasInvalidInputs = targetCfgrName.hasErrors || hasUnresolvedBundleNameConflicts || hasInvalidAssetBundleNames;

  const createCfgr = async (): Promise<void> => {
    setIsProcessing(true);

    await createCfgrFromTemplate(targetCompanyName, targetCfgrName, targetAssetBundleNames, template);
    await Promise.all([
      dispatch(getCompanyCfgrs({ companyId: targetCompanyName })),
      dispatch(getAssetBundles({ companyId: targetCompanyName, silentBundleUpdate: true })),
    ]);

    setIsProcessing(false);
    onCfgrCreated(targetCfgrName.uniqueId);
  };

  return (
    <ModalDialog
      data-cmptype="CreateCfgr"
      header={t('Create configurator')}
      variant={'NoIcon'}
      actions={{ onConfirm: createCfgr, onCancel: onCancel }}
      confirmText={t('Create configurator')}
      confirmDisabled={hasInvalidInputs}
      isProcessingConfirm={isProcessing}
    >
      <div className="flex grow flex-col gap-4">
        <SchemaNameInput
          input={targetCfgrName}
          onChange={(x): void => setTargetCfgrName(x)}
          unavailableIds={existingCfgrsAndBundles.cfgrNames}
          unavailableNames={existingCfgrsAndBundles.cfgrDisplayNames}
        />

        {/* TODO: Implement UX refinement once provided by ORT:
                  CB-9498 UX refinement for asset bundles which are created with new configurator
        */}
        {targetAssetBundleNames.length > 0 && (
          <>
            <div className="text-m-regular">{t('Asset bundles:')}</div>
            {targetAssetBundleNames.map(bundleNames => (
              <div key={bundleNames.templateBundleKey} className="flex gap-2">
                <SchemaNameInput
                  autoFocus={false}
                  showErrorsFromStart
                  input={{
                    uniqueId: bundleNames.targetName,
                    displayName: bundleNames.targetDisplayName,
                    hasErrors: true,
                  }}
                  customLabels={{ id: '', name: '', idLabelInline: true }}
                  onChange={(input): void => {
                    const newTargetAssetBundleNames = targetAssetBundleNames.map(x =>
                      x.templateBundleKey === bundleNames.templateBundleKey
                        ? {
                            ...x,
                            targetName: input.uniqueId,
                            targetDisplayName: input.displayName,
                            isValid: !input.hasErrors,
                          }
                        : x
                    );
                    setTargetAssetBundleNames(newTargetAssetBundleNames);
                  }}
                  unavailableIds={[
                    ...existingCfgrsAndBundles.assetBundleNames,
                    ...targetAssetBundleNames
                      .filter(x => x.templateBundleKey !== bundleNames.templateBundleKey)
                      .map(x => x.targetName),
                  ]}
                  unavailableNames={[
                    ...existingCfgrsAndBundles.assetBundleDisplayNames,
                    ...targetAssetBundleNames
                      .filter(x => x.templateBundleKey !== bundleNames.templateBundleKey)
                      .map(x => x.targetDisplayName),
                  ]}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </ModalDialog>
  );
};
