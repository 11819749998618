import { Link } from 'react-router-dom';
import { ASSETLINK_TYPE_MAP } from 'components/asset-editor/assetlink-type-map';
import { Icon } from 'controls/icon/icon';
import { AssetLinkTypes } from 'generated/asset-link-types';
import { splitFolderAndAssetIdFromSourceString } from 'helper/assets/assets.helper';
import { useAppSelector } from 'hooks/store/store.hooks';
import { buildAssetEditorPath } from 'services/routes/asset-editor-routes.service';
import { MaterialAsset, selectSelectedAsset, selectUsagesOfSelectedMaterialAsset } from 'slices/assets/assets.slice';

export const MaterialAssetUsages: React.FC = () => {
  const usages = useAppSelector(selectUsagesOfSelectedMaterialAsset);
  const selectedAsset = useAppSelector(selectSelectedAsset) as MaterialAsset;
  const { companyId, bundleId, bundleVersion } = selectedAsset.path;

  return (
    <ul data-cmptype="MaterialAssetUsages" className="flex flex-col gap-1">
      {usages.map(assetKey => {
        const { folderId, assetId } = splitFolderAndAssetIdFromSourceString(assetKey);
        const displayName = folderId ? `${assetId} (${folderId})` : assetId;
        return (
          <li key={assetKey} className="flex items-center gap-2" title={displayName}>
            <Icon
              Svg={ASSETLINK_TYPE_MAP[AssetLinkTypes.BabylonJsToMaterial].SourceIcon}
              className="h-[20px] w-[20px]"
            />
            <div className="overflow-hidden text-ellipsis whitespace-nowrap">
              <Link
                to={buildAssetEditorPath(companyId, bundleId, bundleVersion, folderId, assetId)}
                className="underline hover:no-underline"
              >
                {displayName}
              </Link>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
