import { useState } from 'react';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { useNavigateToAssetEditor } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch } from 'hooks/store/store.hooks';
import { Asset, deleteAsset, isFolderAsset, setSelectedTreeViewNodeIds } from 'slices/assets/assets.slice';

type AssetBulkDeleteModalProps = {
  assets: Asset[];
  onClose: () => void;
};

export const AssetBulkDeleteModal: React.FC<AssetBulkDeleteModalProps> = ({ assets, onClose }) => {
  const dispatch = useAppDispatch();
  const { t, tBulkDeleteAssets } = useTypedTranslation();
  const navigateToAssetEditor = useNavigateToAssetEditor();
  const [isProcessingAction, setIsProcessingAction] = useState(false);
  const onDeleteConfirmed = async (): Promise<void> => {
    if (!assets.length) {
      return;
    }
    // Root must be the same for all assets so simply get it from the first one
    const { companyId, bundleId, bundleVersion } = assets[0].path;
    setIsProcessingAction(true);

    const result = await dispatch(
      deleteAsset({
        companyId,
        bundleId,
        bundleVersion,
        deleteItems: assets.map(asset => ({
          assetName: asset.path.assetId,
          folderName: asset.path.folderId,
        })),
      })
    ).unwrap();

    if (result.success) {
      navigateToAssetEditor(
        { companyId, bundleId, bundleVersion },
        // The current asset doesn't exist anymore -> Remove the current route from the history...
        true
      );
      dispatch(setSelectedTreeViewNodeIds([]));
      onClose();
    }

    setIsProcessingAction(false);
  };

  const folderCount = assets.filter(a => isFolderAsset(a)).length;
  const assetCount = assets.length - folderCount;

  return (
    <ModalDialog
      variant="Danger"
      confirmText={t('Delete assets')}
      isProcessingConfirm={isProcessingAction}
      actions={{ onConfirm: onDeleteConfirmed, onCancel: () => onClose() }}
    >
      {tBulkDeleteAssets(folderCount, assetCount)}
    </ModalDialog>
  );
};
