import React from 'react';
import { ASSETLINK_TYPE_MAP } from 'components/asset-editor/assetlink-type-map';
import { MaterialAssetEditor } from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor';
import { MaterialAssetUsages } from 'components/asset-editor/details/asset-body/material-assets/material-asset-usages';
import { AssetDefaultActions } from 'components/asset-editor/details/asset-details-actions';
import { AssetDetailsHeader } from 'components/asset-editor/details/asset-details-header';
import { AssetMetadataEntry } from 'components/asset-editor/details/asset-metadata';
import { Suspense } from 'components/common/suspense';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { Icon } from 'controls/icon/icon';
import { Tooltip } from 'controls/tooltip/tooltip';
import { AssetLinkTypes } from 'generated/asset-link-types';
import { getReadableFileSize } from 'helper/file/file.helper';
import { lazyWithFallback } from 'helper/import/import.helper';
import { useIsAssetBundleEditable } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { MaterialAsset, selectSelectedAsset, selectUsagesOfSelectedMaterialAsset } from 'slices/assets/assets.slice';

const MaterialAssetPreview = lazyWithFallback(
  () =>
    import(
      /* webpackChunkName: "material-asset-preview" */ 'components/asset-editor/details/asset-body/material-assets/material-asset-preview'
    ),
  'Material asset preview'
);

export const AssetBodyMaterial: React.FC = () => {
  const { t } = useTypedTranslation();
  const isAssetBundleEditable = useIsAssetBundleEditable();

  const { size } = useAppSelector(selectSelectedAsset) as MaterialAsset;
  const fileSize = getReadableFileSize(size);

  const actions: (AssetDefaultActions | React.ReactElement)[] = isAssetBundleEditable
    ? ['Rename', 'Copy', 'Delete']
    : [];

  const usages = useAppSelector(selectUsagesOfSelectedMaterialAsset);
  const usagesInfo = usages.length ? (
    <Tooltip title={<MaterialAssetUsages />} interactive>
      <div className="flex cursor-pointer flex-row items-center gap-1 underline">
        <Icon Svg={ASSETLINK_TYPE_MAP[AssetLinkTypes.BabylonJsToMaterial].SourceIcon} className="h-[20px] w-[20px]" />
        <span>{usages.length}</span>
      </div>
    </Tooltip>
  ) : (
    <span>0</span>
  );

  const metadata: AssetMetadataEntry[] = [
    { text: t('Size'), value: fileSize },
    { text: t('Usages'), value: usagesInfo },
  ];

  return (
    <section data-cmptype="AssetBodyMaterial" className="flex h-[100vh] flex-col gap-6">
      <CbnCard>
        <AssetDetailsHeader actions={actions} uploadMode="None" metadata={metadata} />
      </CbnCard>

      <div className="flex grow flex-row gap-6">
        <MaterialAssetEditor />
        <div className="max-h-[350px] shrink-0 basis-[350px] self-end">
          <Suspense fallbackText={t('Initializing preview...')}>
            <MaterialAssetPreview />
          </Suspense>
        </div>
      </div>
    </section>
  );
};
