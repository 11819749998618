import { ErrorIcon, HintIcon, WarningIcon } from 'assets/icons';
import { ASSET_TYPE_MAP } from 'components/asset-editor/asset-type-map';
import { CbnTreeItem } from 'controls/cbn-tree-view/cbn-tree-item';
import { CbnTreeViewItemRendererParams } from 'controls/cbn-tree-view/cbn-tree-view';
import { Icon } from 'controls/icon/icon';
import { PercentageLoader } from 'controls/loader/percentage-loader';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { assetPathToKey } from 'helper/assets/assets.helper';
import { AssetStats, useGetAssetStats } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { PATHS } from 'services/routes/paths.service';
import { Asset } from 'slices/assets/assets.slice';

export const AssetTreeItem: React.FC<CbnTreeViewItemRendererParams<Asset>> = ({ item, children }) => {
  const asset = item.nodeData;
  const key = assetPathToKey(asset.path);
  const icon = ASSET_TYPE_MAP[asset.type].Icon;
  const assetStats = useGetAssetStats(asset);

  const href = PATHS.buildAssetEditorPath(
    asset.path.companyId,
    asset.path.bundleId,
    asset.path.bundleVersion,
    asset.path.folderId,
    asset.path.assetId
  );

  return (
    <CbnTreeItem
      nodeId={key}
      text={asset.path.assetId}
      href={href}
      actions={[assetStats && <AssetStatsIcon assetStats={assetStats} />]}
      endIcon={<Icon Svg={icon} className="h-5" />}
    >
      {children}
    </CbnTreeItem>
  );
};

const AssetStatsIcon: React.FC<{ assetStats: AssetStats }> = ({ assetStats }) => {
  const { t } = useTypedTranslation();
  const uploadStats = assetStats.uploadStats;
  const showUploadLoader = uploadStats.uploadPercentage || uploadStats.uploadInfinite;
  const detailText = assetStats.isAggregatedStatOfChilds
    ? t('Check assets of the folder for more details')
    : t('Select the asset for more details');

  return showUploadLoader ? (
    <PercentageLoader
      percentage={uploadStats.uploadPercentage}
      infinite={uploadStats.uploadInfinite}
      size="small"
      hoverText={uploadStats.uploadText}
    />
  ) : assetStats.hasErrors ? (
    <Icon
      Svg={ErrorIcon}
      className="h-5 text-danger-main"
      title={<TooltipContent header={t('Errors detected')} detail={detailText} />}
    />
  ) : assetStats.hasWarnings ? (
    <Icon
      Svg={WarningIcon}
      className="h-5 text-warning-main"
      title={<TooltipContent header={t('Warnings detected')} detail={detailText} />}
    />
  ) : assetStats.hasHints ? (
    <Icon
      Svg={HintIcon}
      className="h-5 text-primary-main"
      title={<TooltipContent header={t('Hints detected')} detail={detailText} />}
    />
  ) : null;
};
