import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HomeIcon } from 'assets/icons';
import { RequestLoginCard } from 'components/login/common/request-login-card';
import { ResendActivation } from 'components/login/common/resend-activation';
import { LinkButton } from 'controls/button/link-button';
import { Card } from 'controls/card/card';
import { StateIcon } from 'controls/icon/state-icon';
import { LoadMask } from 'controls/load-mask/load-mask';
import { UserStates } from 'generated/user-states';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { ActivateResponse, accountActivate } from 'services/auth/auth.service';
import { PATHS } from 'services/routes/paths.service';
import { getUserInfo, selectLoggedIn, selectUserIsActivated } from 'slices/auth/auth.slice';

const _RESPONSE_MESSAGE: Record<ActivateResponse, string> = {
  Success: 'Successfully activated',
  TokenConsumed: 'The activation token has already been consumed',
  TokenRevoked: 'The activation token has been revoked',
  TokenNoLongerValid: 'The activation token is no longer valid',
  TokenNotFound: 'Invalid validation token',
  UnknownResponse: 'Error occurred during user activation.',
};

type ActivateParams = {
  token: string;
};

/** See usage below for details */
let handleStrictMode = process.env.NODE_ENV === 'development';

export const UserActivation: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const routeParams = useParams<ActivateParams>();
  const token = routeParams.token ?? null;
  const loggedIn = useAppSelector(selectLoggedIn);
  const userIsActivated = useAppSelector(selectUserIsActivated);
  const [activationResponse, setActivationResponse] = useState<ActivateResponse>();
  const isProcessing = !userIsActivated && !activationResponse;

  const isSuccessfulState = userIsActivated || activationResponse === 'Success';
  const userMessage = activationResponse
    ? t(_RESPONSE_MESSAGE[activationResponse])
    : userIsActivated
      ? t('User is already activated!')
      : '';

  useEffect(
    function activateOnMount() {
      if (handleStrictMode) {
        // Dev env + strict mode: Prevent activation-request on very first mount
        // Without this, activation would be done twice and always lead to a `TokenConsumed`
        // Doesn't cover cases where navigation happens in between (e.g. login)
        handleStrictMode = false;
        return;
      }

      if (!token || !loggedIn) {
        return;
      }

      const activateOnMount = async (): Promise<void> => {
        // get the latest state (don't rely on value from redux-store)
        const userInfo = await dispatch(getUserInfo()).unwrap();
        if (userInfo.state === UserStates.NotActivated && token) {
          const result = await accountActivate(token);
          setActivationResponse(result);
          dispatch(getUserInfo());
        }
      };
      activateOnMount();
    },

    [dispatch, token, loggedIn]
  );

  if (!loggedIn) {
    return <RequestLoginCard header={t('Activation')} text={t('Please login before activation')} />;
  }

  return (
    <LoadMask active={isProcessing} transparent="semi" text={t('Activating user...')} spinner>
      {!isProcessing && (
        <section data-cmptype="UserActivation" className="flex grow items-center justify-center">
          <Card headerText="Activation">
            <div className="flex flex-col gap-8">
              <div className="flex items-center gap-2 text-m-regular">
                <StateIcon variant={isSuccessfulState ? 'Success' : 'Error'} />
                <span>{userMessage}</span>
              </div>
              {isSuccessfulState ? (
                <div className="self-center">
                  <LinkButton text={t('Go to main page')} Svg={HomeIcon} href={PATHS.root} />
                </div>
              ) : (
                <ResendActivation />
              )}
            </div>
          </Card>
        </section>
      )}
    </LoadMask>
  );
};
