import React from 'react';
import { Input, InputProps } from 'controls/input/input';

type TextInputType = Extract<React.HTMLInputTypeAttribute, 'text' | 'search' | 'password'>;

export type TextInputProps = Pick<
  InputProps,
  | 'warning'
  | 'error'
  | 'placeholder'
  | 'disabled'
  | 'autoFocus'
  | 'autoComplete'
  | 'onFocus'
  | 'onBlur'
  | 'title'
  | 'id'
  | 'startAdornment'
  | 'endAdornment'
  | 'helperText'
  | 'selectTextOnAutoFocus'
  | 'inputRef'
> & {
  value?: string;
  type?: TextInputType;
  onValueChange?: (value: string) => void;
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ type = 'text', onValueChange, ...props }, ref) => {
    return (
      <Input
        ref={ref}
        type={type}
        onChange={(evt): void => {
          onValueChange?.(evt.target.value);
        }}
        {...props}
      />
    );
  }
);
