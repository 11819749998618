import React, { createContext, useState } from 'react';
import { ASSET_TYPE_MAP } from 'components/asset-editor/asset-type-map';
import { AssetBodyUploadError } from 'components/asset-editor/details/asset-body/asset-body-upload-error';
import { AssetBodyUploading } from 'components/asset-editor/details/asset-body/asset-body-uploading';
import { AssetUploadSettingsModal } from 'components/asset-editor/overview/modals/asset-upload-settings-modal';
import { DragDropZone } from 'components/dragdrop/drag-drop-zone';
import { AssetTypes } from 'generated/asset-types';
import {
  AssetFile,
  OverwrittenAssetNames,
  createAssetUploadBag,
  getExtensionFromAssetFilenameOrUrl,
  isAssetFileValidityError,
  isAssetFileValidityWarning,
} from 'helper/assets/assets.helper';
import { useGetAssetStats, useIsAssetBundleEditable, useUploadAssets } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import {
  Asset,
  isFileAsset,
  isFolderAsset,
  isTextAsset,
  selectFileLinkKeyOfSelectedDataSourceAsset,
  selectSelectedAsset,
  selectSelectedAssetFolderId,
  selectSelectedAssetPath,
} from 'slices/assets/assets.slice';

// upload is done deep within asset details (see <AssetDetailsActions>)
// use context to avoid heavy prop drilling
type AssetDetailsContextState = {
  onUploadFilesSelected: (files: File[]) => void;
};

const contextState: AssetDetailsContextState = {
  onUploadFilesSelected: () => {},
};

export const AssetDetailsContext = createContext(contextState);

export const AssetDetails: React.FC = () => {
  const { tDropReplaceAsset } = useTypedTranslation();
  const uploadAssets = useUploadAssets();
  const isAssetBundleEditable = useIsAssetBundleEditable();

  const selectedAssetPath = useAppSelector(selectSelectedAssetPath);
  const selectedAsset = useAppSelector(selectSelectedAsset);
  const selectedAssetFolderId = useAppSelector(selectSelectedAssetFolderId);
  const assets = useAppSelector(state => state.assets.assets);
  const assetLinks = useAppSelector(state => state.assets.assetLinks);
  const isDataSourceBasedOnFileAsset = !!useAppSelector(selectFileLinkKeyOfSelectedDataSourceAsset);
  const assetStats = useGetAssetStats(selectedAsset);

  const allowDragDropUpload = !isDataSourceBasedOnFileAsset && isAssetBundleEditable;

  const isRootOrFolder = !selectedAsset || selectedAsset.type === AssetTypes.Folder;
  const dragDropInfoText = isRootOrFolder
    ? 'Drop file(s) to add new assets or replace existing ones'
    : tDropReplaceAsset(selectedAsset.path.assetId);

  const [assetUploadSettingsModal, setAssetUploadSettingsModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [predefinedAssetNames, setPredefinedAssetNames] = useState<OverwrittenAssetNames>({});

  const onUploadFilesSelected = (files: File[]): void => {
    const overwrittenAssetNames: OverwrittenAssetNames =
      selectedAsset && selectedAsset.type !== AssetTypes.Folder ? { [files[0].name]: selectedAsset.path.assetId } : {};

    const singleOverwriteFile =
      files.length === 1 && selectedAsset?.type !== AssetTypes.Folder
        ? createAssetUploadBag(
            files,
            selectedAssetPath,
            assets,
            assetLinks,
            selectedAssetFolderId,
            overwrittenAssetNames
          )[0]
        : undefined;

    if (singleOverwriteFile) {
      setPredefinedAssetNames(overwrittenAssetNames);
    }

    // allow direct upload (no dialog) when there aren't any "critical" changes to the asset
    if (
      singleOverwriteFile &&
      !isAssetFileValidityError(singleOverwriteFile.validity) &&
      !isAssetFileValidityWarning(singleOverwriteFile.validity) &&
      selectedAsset &&
      _isMatchingAssetOrFiletype(singleOverwriteFile, selectedAsset)
    ) {
      uploadAssets([singleOverwriteFile]);
    } else {
      // show upload settings modal for all other cases
      setSelectedFiles(files);
      setAssetUploadSettingsModal(true);
    }
  };

  // gather upload stats from selected asset
  const isUploading = assetStats
    ? assetStats.uploadStats.uploadPercentage || assetStats.uploadStats.uploadInfinite
    : false;

  const typeDef = selectedAsset?.type ? ASSET_TYPE_MAP[selectedAsset.type] : ASSET_TYPE_MAP[AssetTypes.Folder];
  // prio 1: error state
  // prio 2: uploading state
  // prio 3: show dedicated type details page of "server" assets
  const showErrorBody = selectedAsset && !isFolderAsset(selectedAsset) && assetStats?.uploadStats.hasError;
  const showUploadBody = selectedAsset && !isFolderAsset(selectedAsset) && isUploading;
  const assetBody = showErrorBody ? (
    <AssetBodyUploadError />
  ) : showUploadBody && assetStats ? (
    <AssetBodyUploading uploadStats={assetStats.uploadStats} />
  ) : (
    typeDef.BodyComponent
  );

  return (
    <AssetDetailsContext.Provider value={{ onUploadFilesSelected }}>
      {assetBody}

      {/* Add drag drop zone afterwards, so that it will be shown in front */}
      {allowDragDropUpload && <DragDropZone onFilesDrop={onUploadFilesSelected} infoText={dragDropInfoText} />}

      {assetUploadSettingsModal && (
        <AssetUploadSettingsModal
          selectedFiles={selectedFiles}
          preSelectedFolderId={selectedAssetFolderId}
          predefinedAssetNames={predefinedAssetNames}
          onUploadStarted={(): void => setAssetUploadSettingsModal(false)}
          onCancel={(): void => setAssetUploadSettingsModal(false)}
        />
      )}
    </AssetDetailsContext.Provider>
  );
};

/**
 * Check if the type of the uploaded file fits to the existing asset
 * - Special handling for `AssetTypes.File` (general 'fallback' type) where the actual extension gets compared
 */
function _isMatchingAssetOrFiletype(newAssetFile: AssetFile, existingAsset: Asset): boolean {
  if (!isFileAsset(existingAsset) && !isTextAsset(existingAsset)) {
    return newAssetFile.assetType === existingAsset.type;
  } else {
    const isMatch =
      getExtensionFromAssetFilenameOrUrl(newAssetFile.file.name) ===
      getExtensionFromAssetFilenameOrUrl(existingAsset.url);
    return isMatch;
  }
}
