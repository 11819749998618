import { buildPath } from 'services/routes/routes.service';

const _BASE_PATH = '/acceptinvitation';

const _INVITATION_SUB_PAGE = {
  login: 'login',
  register: 'register',
};
export type InvitationRouteSubPage = keyof typeof _INVITATION_SUB_PAGE;

export const COMPANY_INVITATION_PATH_VARIANTS = {
  acceptCompanyInvitation: `${_BASE_PATH}/:companyName/:inviteToken`,
  acceptCompanyInvitationLogin: `${_BASE_PATH}/:companyName/:inviteToken/:inviteRecipient?/login`,
  acceptCompanyInvitationRegister: `${_BASE_PATH}/:companyName/:inviteToken/:inviteRecipient?/register`,
  acceptCompanyInvitationInvalid: `/invalidinvitation`,
};

export function buildCompanyInvitationSubPagePath(
  companyId: string,
  token: string,
  page: InvitationRouteSubPage,
  recipient?: string
): string {
  const path = recipient
    ? buildPath(_BASE_PATH, companyId, token, recipient, page)
    : buildPath(_BASE_PATH, companyId, token, page);
  return path;
}
