import { useState } from 'react';
import { PlusIcon } from 'assets/icons';
import { AssetBundleMenu } from 'components/asset-editor/header/asset-bundle-menu';
import { NewBundleModal } from 'components/asset-editor/header/modals/new-bundle-modal';
import { Autocomplete } from 'controls/autocomplete/autocomplete';
import { Button } from 'controls/button/button';
import { CbnTabNavigation, CbnTabNavigationValue } from 'controls/cbn-tab-navigation/cbn-tab-navigation';
import { FormControlLabel } from 'controls/form/form-control-label';
import { SelectValue } from 'controls/select/select';
import { Tooltip } from 'controls/tooltip/tooltip';
import { useNavigateToAssetEditor } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectAllBundles, selectSelectedBundle } from 'slices/assets/assets.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

export const AssetBundles: React.FC = () => {
  const { t } = useTypedTranslation();
  const navigateToAssetEditor = useNavigateToAssetEditor();

  const companyId = useAppSelector(state => state.companyData.selection.companyId);
  const assetBundles = useAppSelector(selectAllBundles);
  const sortedAssetBundles = [...assetBundles].sort((a, b) => a.name.localeCompare(b.name));
  const selectedBundle = useAppSelector(selectSelectedBundle);

  const hasManageAssetsPermission = usePermission(CompanyPermissions.ManageAssets, companyId);

  const [newBundleDialogActive, setNewBundleDialogActive] = useState(false);

  const onAssetBundleSelectionChanged = (value: CbnTabNavigationValue | SelectValue): void => {
    navigateToAssetEditor({ companyId, bundleId: value.toString() });
  };

  return (
    <>
      {/* h-[60px] for having space for the horizontal scroll bar
          also this is the reaons why there is no flex gap in the parent container */}
      <div data-cmptype="AssetBundles" className="flex h-[60px] flex-row items-center justify-between gap-3">
        {/* Switch UI if more than 7 bundles are available */}
        {assetBundles.length > 7 ? (
          <FormControlLabel
            label={t('Select bundle:')}
            labelPlacement="start"
            className="gap-3 text-nowrap"
            control={
              <Autocomplete
                options={sortedAssetBundles.map(bundle => ({
                  value: bundle.id,
                  text: bundle.displayName,
                }))}
                value={selectedBundle?.id}
                onChange={onAssetBundleSelectionChanged}
                disableClearable
                asSelectedChip
              />
            }
          />
        ) : (
          <div className="relative h-full w-full">
            <div className="absolute inset-0 flex flex-row items-center overflow-x-auto p-0.5">
              <CbnTabNavigation
                entries={sortedAssetBundles.map(bundle => ({
                  value: bundle.id,
                  text: bundle.displayName,
                }))}
                value={selectedBundle?.id}
                onValueChange={onAssetBundleSelectionChanged}
              />
            </div>
          </div>
        )}

        <div className="flex flex-row items-center gap-3">
          <Tooltip
            title={t(
              'A bundle combines different types of data and media to a set that can be accessed and used by a configurator.'
            )}
          >
            <a
              href="https://docs.combeenation.com/docs/getting-started-with-assets#asset-bundles"
              className="cursor-pointer whitespace-nowrap text-s-regular text-primary-main"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('What is a bundle?')}
            </a>
          </Tooltip>
          {hasManageAssetsPermission && (
            <>
              <AssetBundleMenu />
              <div className="flex w-[170px]">
                <Button
                  variant="Outlined"
                  text={t('Add new bundle')}
                  Svg={PlusIcon}
                  onClick={(): void => setNewBundleDialogActive(true)}
                  grow
                />
              </div>
            </>
          )}
        </div>
      </div>

      {newBundleDialogActive && <NewBundleModal onClose={(): void => setNewBundleDialogActive(false)} />}
    </>
  );
};
