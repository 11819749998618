import { Chip as MuiChip, chipClasses, styled } from '@mui/material';
import React from 'react';
import { CheckIcon } from 'assets/icons';
import { Icon, SvgComponent } from 'controls/icon/icon';
import { cn, tw } from 'helper/css.helper';

export type ChipValue = number | string;

const StyledChip = styled(MuiChip)(({ theme }) => ({
  [`& .${chipClasses.icon}`]: {
    color: 'inherit',
  },
}));

export type ChipVariants = 'Primary' | 'Secondary' | 'Neutral';

const _chipVariantStateMap: Record<ChipVariants, { default: string; selected: string; deselected: string }> = {
  Neutral: {
    default: tw`border-neutral-50 hover:border-primary-hover  focus:border-primary-focus `,
    selected: tw`bg-neutral-40 focus:outline focus:outline-2 focus:outline-primary-focus`,
    deselected: tw`focus:border-primary-focus focus:bg-transparent focus:outline focus:outline-1 focus:outline-primary-focus`,
  },
  Primary: {
    default: tw`border-neutral-50 bg-neutral-10 hover:border-primary-hover hover:bg-primary-hover`,
    selected: tw`bg-primary-main text-neutral-10 focus:bg-primary-main focus:outline focus:outline-2 focus:outline-primary-focus`,
    deselected: tw`focus:border-primary-focus focus:bg-transparent focus:outline focus:outline-1 focus:outline-primary-focus`,
  },
  Secondary: {
    default: tw`border-neutral-50 hover:border-primary-focus hover:bg-primary-surface`,
    selected: tw`bg-primary-surface focus:bg-primary-surface focus:outline focus:outline-2 focus:outline-primary-focus`,
    deselected: tw`focus:border-primary-focus focus:bg-transparent focus:outline focus:outline-1 focus:outline-primary-focus`,
  },
};

type ChipProps = {
  value: ChipValue;
  text?: string;
  selected?: boolean;
  disabled?: boolean;
  Svg?: SvgComponent;
  variant?: ChipVariants;
  onClick?: (value: ChipValue) => void;
  onDelete?: (value: ChipValue) => void;
  className?: string;
};

export const Chip: React.FC<ChipProps> = ({
  value,
  text,
  selected = false,
  disabled = false,
  variant = 'Neutral',
  onClick,
  onDelete,
  ...props
}) => {
  const variantClasses = _chipVariantStateMap[variant];

  return (
    <StyledChip
      {...props}
      label={text ?? value}
      disabled={disabled}
      variant={selected ? 'filled' : 'outlined'}
      size="small"
      className={cn('text-s-medium', props.className, variantClasses.default, {
        [variantClasses.selected]: selected,
        [variantClasses.deselected]: !selected,
      })}
      onClick={(): void => onClick?.(value)}
      icon={selected ? <Icon Svg={CheckIcon} className="h-4 w-4" /> : undefined}
      onDelete={onDelete ? (): void => onDelete(value) : undefined}
    />
  );
};

// TODO Now ORT: Our `Chip` cmp is having different font size than design (14px vs 12px).
//               What is correct and should we change it everywhere?
//               Solved temporarily using this wrapper cmp for the cfgn insights inputs.
//               Get rid of this cmp once we decided upon what to do.
export const LargeFontChip: React.FC<ChipProps> = ({ text, ...props }) => {
  return <Chip {...props} className={cn('text-m-regular', props.className)} text={text} />;
};
