import { useEffect, useId, useState } from 'react';
import { InfoIcon } from 'assets/icons';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { CronRangeInput } from 'controls/cron/cron-range-input';
import { CronWeekDaysSelection } from 'controls/cron/cron-weekdays-selection';
import { Icon } from 'controls/icon/icon';
import { StateIcon } from 'controls/icon/state-icon';
import { NumberInput, NumberOrNull } from 'controls/input/number-input';
import { TextInput } from 'controls/input/text-input';
import { Switch } from 'controls/switch/switch';
import { Tooltip } from 'controls/tooltip/tooltip';
import { getFormattedDateAndTime } from 'helper/date-and-time/date-and-time.helper';
import { useCronExpression, useCronScheduler } from 'hooks/common/cron-expression.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type CronGeneratorModalProps = {
  initialValue: string;
  /** The minimum interval between runs */
  minIntervalInMinutes?: number;
  onConfirm: (cronExpression: string) => void;
  onCancel: () => void;
};

export const CronGeneratorModal: React.FC<CronGeneratorModalProps> = ({
  initialValue,
  minIntervalInMinutes,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTypedTranslation();
  const [rawCronExp, parsedCronExp, isValidCronExp, setCronExpString] = useCronExpression();
  const [useUtcTime, setUseUtcTime] = useState(true);
  const utcSwitchId = useId();

  const [, nextIntervals] = useCronScheduler(rawCronExp, 8, useUtcTime);

  //const cronParts = _getValidCronParts(rawCronExp);
  const cronParts = initialValue.length > 0 ? initialValue.split(' ') : undefined;
  const parsedMinute = cronParts?.[0] ? parseInt(cronParts[0]) : 0;
  const [minuteInput, setMinuteInput] = useState<NumberOrNull>(isNaN(parsedMinute) ? 0 : parsedMinute);
  const [hourInput, setHourInput] = useState(cronParts?.[1] ?? '0');
  const [dayOfMonthInput, setDayOfMonthInput] = useState(cronParts?.[2] ?? '*');
  const [monthInput, setMonthInput] = useState(cronParts?.[3] ?? '*');
  const [dayOfWeekInput, setDayOfWeekInput] = useState(cronParts?.[4] ?? '*');

  useEffect(() => {
    setCronExpString(`${minuteInput ?? 0} ${hourInput} ${dayOfMonthInput} ${monthInput} ${dayOfWeekInput}`);
  }, [minuteInput, hourInput, dayOfMonthInput, monthInput, dayOfWeekInput, setCronExpString]);

  const isTooShortInterval = _isBeneathMinInterval(nextIntervals, minIntervalInMinutes);

  const cronErrorStr = isTooShortInterval
    ? t('The current interval is below the minimum allowed interval of one hour!')
    : parsedCronExp && Object.values(parsedCronExp.errors).reduce((acc, error) => acc + error + '\n', '');

  const onModalConfirm = (): void => {
    onConfirm(rawCronExp);
  };

  return (
    <ModalDialog
      header={t('Scheduled execution')}
      data-cmptype="CronGeneratorModal"
      size="Medium"
      variant="NoIcon"
      confirmText={t('Save scheduling')}
      actions={{ onConfirm: onModalConfirm, onCancel: () => onCancel() }}
      confirmDisabled={!isValidCronExp || isTooShortInterval}
    >
      <div className="flex flex-col">
        <div className="mb-5 flex items-center gap-2 text-s-regular">
          <Icon Svg={InfoIcon} className="h-6" />
          <span>
            {t('All entered values are in UTC time.')}
            <br />
            {t('The minimum interval is one hour!')}
          </span>
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col gap-5">
            <div className="grid grid-cols-[210px_230px_100px] items-center gap-2">
              <div className="ml-8 text-m-regular">{t('At minute')}</div>
              <NumberInput
                inputMode="numeric"
                value={minuteInput}
                placeholder={'0'}
                onChange={(value): void => setMinuteInput(value)}
              />
              <div>{minuteInput}</div>
            </div>
            <CronRangeInput unit="hours" value={hourInput} onChange={(value): void => setHourInput(value)} />
            <CronRangeInput
              unit="days of month"
              value={dayOfMonthInput}
              onChange={(value): void => setDayOfMonthInput(value)}
            />
            <CronRangeInput unit="months" value={monthInput} onChange={(value): void => setMonthInput(value)} />
            <div>
              <div className="mb-2">{t('Only at specific weekdays:')}</div>
              <CronWeekDaysSelection
                initialValue={dayOfWeekInput}
                onChange={(value): void => setDayOfWeekInput(value)}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-l-medium">{t('CRON expression')}</div>
            <TextInput
              value={rawCronExp}
              disabled
              error={!isValidCronExp || isTooShortInterval}
              title={cronErrorStr}
              endAdornment={!isValidCronExp && <StateIcon variant="Error" noBckgr />}
            />
            <div className="mt-4 text-l-medium">{t('Interval preview')}</div>
            <div className="flex items-center">
              <Switch checked={useUtcTime} onValueChanged={(value): void => setUseUtcTime(value)} id={utcSwitchId} />
              <label htmlFor={utcSwitchId} className="ml-2 text-m-regular">
                {t('Local time')}
              </label>
              <Tooltip
                arrow
                placement="right-start"
                title={
                  <p className="text-s-medium">
                    {t(
                      'This does not affect when & how the workflow is run ' +
                        'but only changes the time zone of the preview dates shown below.' +
                        'When disabled, the dates are in UTC time. '
                    )}
                  </p>
                }
              >
                <span className="cursor-pointer">
                  <StateIcon variant="Info" noBckgr />
                </span>
              </Tooltip>
            </div>

            <div className="flex flex-col gap-1">
              {isValidCronExp && (
                <>
                  {nextIntervals.map(interval => (
                    <div key={interval.getTime()} className={'text-m-regular'}>
                      {getFormattedDateAndTime(interval)}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};

function _isBeneathMinInterval(intervals: Date[], minIntervalInMinutes?: number): boolean {
  if (!minIntervalInMinutes) {
    return false;
  }

  const minIntervalInMS = minIntervalInMinutes * 60 * 1000;
  const shortestInterval = intervals.reduce((acc, currInterval, idx, arr) => {
    const nextInterval = arr[idx + 1];
    if (!nextInterval) {
      return acc;
    }

    const delta = nextInterval.getTime() - currInterval.getTime();
    return Math.min(delta, acc);
  }, minIntervalInMS);

  return shortestInterval < minIntervalInMS;
}
