import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as LocalStorageService from 'services/local-storage/local-storage.service';

const _STORAGE_KEY = 'storedHookStates';
/**
 * Extend as needed
 * The type solely exists to keep track of existing keys (and reduce the risk of duplicates)
 */
type StorageKeys = 'assetHeaderCollapsed' | 'assetTreeCollapsed' | 'sidebarCollapsed' | `viewMode__${string}`;

const _getStorageObj = <T>(): { [key: string]: T } => {
  const storage = LocalStorageService.getItem(_STORAGE_KEY);
  return storage ? JSON.parse(storage) : {};
};

const _findStoredItem = <T>(key: string): T | undefined => {
  const storageObj = _getStorageObj<T>();
  const lsValue = storageObj[key];

  return lsValue;
};

/**
 * Automatically store every change of the state and restore on mount
 *
 * @param key key which is used inside the storage object => extend type as needed
 * @param initialValue use this value if there is no item in the local storage yet
 */
export const useLocalStorageState = <TState>(
  key: StorageKeys,
  initialValue: TState | (() => TState)
): [TState, Dispatch<SetStateAction<TState>>] => {
  const lsValue = _findStoredItem<TState>(key);

  const [value, setValue] = useState(lsValue ?? initialValue);

  useEffect(
    function setLocalStorageValue() {
      const storageObj = _getStorageObj();
      storageObj[key] = value;
      LocalStorageService.setItem(_STORAGE_KEY, JSON.stringify(storageObj));
    },
    [value, key]
  );

  return [value, setValue];
};
