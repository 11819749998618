import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert';
import MuiAlertTitle from '@mui/material/AlertTitle';
import { StateIcon } from 'controls/icon/state-icon';
import { cn } from 'helper/css.helper';

type CbnAlertProps = Pick<MuiAlertProps, 'severity' | 'icon' | 'color' | 'className'> & {
  caption?: string;
  content: React.ReactNode;
};

export const CbnAlert: React.FC<CbnAlertProps> = ({ caption, content, className, ...props }) => {
  return (
    <MuiAlert
      className={cn('w-[480px]', className)}
      variant="outlined"
      iconMapping={{
        success: <StateIcon variant="Success" />,
        info: <StateIcon variant="Info" />,
        error: <StateIcon variant="Error" />,
        warning: <StateIcon variant="Warning" />,
      }}
      {...props}
    >
      {caption && <MuiAlertTitle className="text-l-medium text-type-primary">{caption}</MuiAlertTitle>}
      <span className="text-m-regular text-type-secondary">{content}</span>
    </MuiAlert>
  );
};
