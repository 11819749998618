import { useState } from 'react';
import { DeleteIcon, OpenIcon, VerticalDotsMenuIcon, ViewDraftInEditorIcon } from 'assets/icons';
import { hoverIconStyleFactory } from 'components/cfgrs/drafts/draft-actions';
import { DraftMenu } from 'components/cfgrs/drafts/draft-menu';
import { DraftMenuItem } from 'components/cfgrs/drafts/draft-menu-item';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { Icon } from 'controls/icon/icon';
import { getCfgrStagingUrl, getEditorUrl } from 'helper/url/cfgr-url.helper';
import { useConfirmDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { Stage } from 'services/company-data/company-data.service';
import { deleteStage as deleteStageThunk, selectSelectedConfigurator } from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

type StageActionKeys = 'ViewStageEditor' | 'PreviewStage' | 'Delete';
type StageActionStates = Record<StageActionKeys, { isVisible: boolean; isDisabled: boolean }>;

type StageActionsProps = {
  stage: Stage;
  visible: boolean;
  showAsMenu: boolean;
};

export const StageActions: React.FC<StageActionsProps> = ({ stage, visible, showAsMenu }) => {
  const { t } = useTypedTranslation();
  const confirm = useConfirmDialog();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const dispatch = useAppDispatch();

  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const selectedCompanyId = selectedCfgr?.companyId || '';
  const selectedCfgrId = selectedCfgr?.id || '';

  const hasViewStagePermission = usePermission(CompanyPermissions.ViewConfigurator, selectedCfgr?.companyId);
  const hasManageDraftPermission = usePermission(CompanyPermissions.ManageDraft, selectedCfgr?.companyId);

  const deleteStage = async (): Promise<void> => {
    const deleteConfirmed = await confirm(t(`Delete "${stage.name}"?`), {
      confirmBtnText: 'Delete stage',
      variant: 'Danger',
    });

    if (deleteConfirmed) {
      await dispatch(
        deleteStageThunk({
          companyId: selectedCompanyId,
          configuratorName: selectedCfgrId,
          stageName: stage.name,
        })
      );
    }
  };

  const stageLink = getCfgrStagingUrl(selectedCompanyId, selectedCfgrId, stage.name);
  const viewStageInEditorLink = getEditorUrl(selectedCompanyId, selectedCfgrId, stage.version, 'view');

  const actionStates: StageActionStates = {
    PreviewStage: { isVisible: true, isDisabled: false },
    ViewStageEditor: { isVisible: hasViewStagePermission, isDisabled: false },
    Delete: {
      isVisible: hasManageDraftPermission,
      isDisabled: !hasManageDraftPermission,
    },
  };

  return (
    <>
      {showAsMenu && (
        <>
          <button
            type="button"
            className={hoverIconStyleFactory(visible, !!menuAnchorEl)}
            onClick={(event): void => setMenuAnchorEl(event.currentTarget)}
          >
            <Icon Svg={VerticalDotsMenuIcon} className="h-8 text-primary-main" />
          </button>

          <DraftMenu
            open={!!menuAnchorEl}
            anchorEl={menuAnchorEl}
            onClose={(): void => setMenuAnchorEl(null)}
            closeOnClick
          >
            {actionStates.ViewStageEditor.isVisible && (
              <DraftMenuItem
                svg={ViewDraftInEditorIcon}
                text={t('View stage in editor')}
                to={viewStageInEditorLink}
                linkProps={{ reloadDocument: true }}
                disabled={actionStates.ViewStageEditor.isDisabled}
              />
            )}

            {actionStates.PreviewStage.isVisible && (
              <DraftMenuItem
                svg={OpenIcon}
                text={t('View stage')}
                to={stageLink}
                linkProps={{ target: '_blank' }}
                disabled={actionStates.PreviewStage.isDisabled}
              />
            )}

            {actionStates.Delete.isVisible && (
              <DraftMenuItem
                svg={DeleteIcon}
                text={t('Delete stage')}
                onClick={deleteStage}
                variant="danger"
                disabled={actionStates.Delete.isDisabled}
              />
            )}
          </DraftMenu>
        </>
      )}

      {!showAsMenu && (
        <div className="flex items-center gap-3">
          {actionStates.ViewStageEditor.isVisible && (
            <LinkButton
              variant="TextInline"
              href={viewStageInEditorLink}
              reloadDocument
              Svg={ViewDraftInEditorIcon}
              title={t('View stage in editor')}
              disabled={actionStates.ViewStageEditor.isDisabled}
            />
          )}

          {actionStates.PreviewStage.isVisible && (
            <LinkButton
              variant="TextInline"
              href={stageLink}
              target="_blank"
              Svg={OpenIcon}
              title={t('View stage')}
              disabled={actionStates.PreviewStage.isDisabled}
            />
          )}

          {actionStates.Delete.isVisible && (
            <Button
              variant="TextInline"
              hoverColor="Danger"
              Svg={DeleteIcon}
              title={t('Delete stage')}
              disabled={actionStates.Delete.isDisabled}
              onClick={deleteStage}
            />
          )}
        </div>
      )}
    </>
  );
};
