import {
  GridDensity,
  GridToolbarContainer,
  gridDensityValueSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { DensityCompactIcon, DensityStandardIcon } from 'assets/icons';
import {
  CbnToggleButtonGroup,
  CbnToggleButtonOption,
  CbnToggleButtonValue,
} from 'controls/cbn-toggle-button/cbn-toggle-button-group';
import { SvgComponent } from 'controls/icon/icon';

type SimpleGridDensity = Exclude<GridDensity, 'comfortable'>;
const _DENSITY_DEF: Record<SimpleGridDensity, { title: string; icon: SvgComponent }> = {
  compact: { title: 'Compact', icon: DensityCompactIcon },
  standard: { title: 'Standard', icon: DensityStandardIcon },
};

export type CustomToolbarProps = {
  headerText?: string;
  actions?: React.ReactNode;
  showDensitySelector?: boolean;
};

export const DataGridCustomToolbar: React.FC<CustomToolbarProps> = ({ headerText, actions, showDensitySelector }) => {
  const apiRef = useGridApiContext();
  const densityValue = useGridSelector(apiRef, gridDensityValueSelector);

  const densityEntries: CbnToggleButtonOption[] = Object.entries(_DENSITY_DEF).map<CbnToggleButtonOption>(
    ([densityKey, value]) => ({
      value: densityKey,
      icon: value.icon,
      title: value.title,
      titleProps: { arrow: true, placement: 'top', delay: 500 },
    })
  );

  const onDensityChange = (newDensity: CbnToggleButtonValue): void => {
    apiRef.current.setDensity(newDensity as GridDensity);
  };

  return (
    <GridToolbarContainer>
      <div className="mx-4 my-2 flex grow items-center justify-between">
        {headerText && <div className="text-m-medium">{headerText}</div>}
        <div className="flex grow items-center justify-end gap-2">
          {actions}
          {showDensitySelector && (
            <CbnToggleButtonGroup
              exclusive
              entries={densityEntries}
              value={densityValue}
              onValueChange={onDensityChange}
            />
          )}
        </div>
      </div>
    </GridToolbarContainer>
  );
};
