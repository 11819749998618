import MuiTableCell, { TableCellProps as MuiTableCellProps, tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(MuiTableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.neutral[30],
    color: theme.palette.neutral[100],
    padding: theme.spacing(2, 4),
    fontSize: theme.typography.sMedium.fontSize,
    fontWeight: theme.typography.sMedium.fontWeight,
    lineHeight: theme.typography.sMedium.lineHeight,
    letterSpacing: '0.06em',
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.neutral[90],
    padding: theme.spacing(2, 4),
    fontSize: theme.typography.mRegular.fontSize,
    fontWeight: theme.typography.mRegular.fontWeight,
    lineHeight: theme.typography.mRegular.lineHeight,
    minHeight: theme.spacing(13),
    border: 0,
  },
}));

export type TableCellProps = Pick<MuiTableCellProps, 'className' | 'width' | 'children' | 'colSpan'>;

export const TableCell: React.FC<TableCellProps> = ({ children, ...props }) => {
  return (
    <StyledTableCell data-cmptype="TableCell" {...props}>
      {children}
    </StyledTableCell>
  );
};
