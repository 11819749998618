import { useAppSelector } from 'hooks/store/store.hooks';
import { CompanyFeatures } from 'services/companies/companies.service';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';

export const useCompanyFeature = (feature: keyof CompanyFeatures): boolean => {
  const selectedCompany = useAppSelector(selectSelectedCompany);

  if (!selectedCompany) {
    return false;
  }

  return selectedCompany.companyFeatures[feature] === true;
};
