import { OpenIcon } from 'assets/icons';
import { AdminCompanyFeatures } from 'components/admin/admin-company-features';
import { TelemetryTesting } from 'components/admin/admin-telemetry-testing';
import { LinkButton } from 'controls/button/link-button';
import { Card } from 'controls/card/card';
import { EXTJS_ROUTE_PREFIX } from 'helper/extjs-route/extjs-route.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { SystemPermissions } from 'slices/permission/permission.slice';

export const Admin: React.FC = () => {
  const { t } = useTypedTranslation();
  const inDevEnv = useAppSelector(state => state.debug.inDevEnv);

  const hasManageCompaniesAndUsersPermission = usePermission(SystemPermissions.ManageCompaniesAndUsers);

  return (
    <div data-cmptype="Admin" className="flex flex-wrap gap-6 p-6">
      <div>
        <Card headerText={t('Admin')}>
          <LinkButton
            text={t('Open admin page')}
            href={`${EXTJS_ROUTE_PREFIX}cbnadmin`}
            target={'_blank'}
            Svg={OpenIcon}
          />
        </Card>
      </div>
      {hasManageCompaniesAndUsersPermission && <AdminCompanyFeatures />}
      {inDevEnv && <TelemetryTesting />}
    </div>
  );
};
