import { PayloadAction, createAsyncThunk, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { globalCompanySwitch } from 'actions/general.actions';
import { hasDuplicates } from 'helper/array/array.helper';
import { getSchemaNamePart, sortBundlesByLastEditedDesc } from 'helper/assets/assets.helper';
import { mapObject } from 'helper/object/object.helper';
import {
  AssetBundleAssignment,
  fetchAssignedAssetBundles,
  fetchBundleVersionData,
  fetchPublishedAssetBundleVersions,
} from 'services/assets/assets.service';
import {
  AssetBundleIdRequest,
  AssetBundleMappingDto,
  CfgrMappingDto,
  ReplicaMappingDto,
} from 'services/http/endpoints/replicator.endpoints';
import {
  ReplicaMapping,
  getReplicatorGenerateMapping,
  getReplicatorGenerateMappingCrossEnv,
  postCreateBlueprint,
  postCreateBlueprintCrossEnv,
  postReplicatorReplicate,
  postReplicatorReplicateCrossEnv,
} from 'services/replicator/replicator.service';
import * as PersistenceMiddleware from 'services/store/persistence.middleware';
import { RootState } from 'services/store/store.service';
import { AssetBundleVersions, AssetBundles, getAssetBundles } from 'slices/assets/assets.slice';
import { getCompanyCfgrs } from 'slices/company-data/company-data.slice';

export type BlueprintCfgr = {
  id: string;
  version: string;
  assignedAssetBundles: AssetBundleAssignment[];
};

export type BlueprintAssetBundle = {
  selected: boolean;
  name: string;
  versions: { [key: string]: { id: string; displayName: string; selected: boolean } };
};

export type CfgrMappingTarget = CfgrMappingDto & {
  /** The unmodified cfgr name exists at the target */
  readonly isExistingCfgr: boolean;
  /** Indicator that it's user edited (and therefor results in a new cfgr) */
  inEditMode: boolean;
  hasInputError: boolean;
};

export type AssetBundleMappingTarget = AssetBundleMappingDto & {
  /** The unmodified bundle name exists at the target */
  readonly isExistingBundle: boolean;
  /** Indicator that it's user edited (and therefor results in a new bundle) */
  inEditMode: boolean;
  hasInputError: boolean;
  /** Reference to a sibling of the same bundle but different version (user changes will apply to all versions) */
  mainVersionSibling?: string;
};

export type BlueprintTarget = {
  companyId: string;
  cfgrs: { [schemaName: string]: CfgrMappingTarget };
  assetBundles: { [schemaName: string]: AssetBundleMappingTarget };
  existingNames: {
    assetBundleNames: string[];
    assetBundleDisplayNames: string[];
    cfgrNames: string[];
    cfgrDisplayNames: string[];
  };
  originalMapping: ReplicaMappingDto;
};

type ReplicationBlueprint = {
  blueprintId?: string;
  recentReplicationId?: string;
  blueprintSasUri?: string;
  companyId: string;
  cfgrs: { [key: string]: BlueprintCfgr };
  assetBundles: { [key: string]: BlueprintAssetBundle };
  target: BlueprintTarget;
};

type BlueprintPreselection = {
  cfgrId?: string;
  bundleId?: string;
  bundleVersion?: string;
};

export type ReplicatorState = {
  currentBlueprint: ReplicationBlueprint;
  stashedBlueprints: { [key: string]: ReplicationBlueprint };
  preselection?: BlueprintPreselection;
};

const initialState: ReplicatorState = {
  currentBlueprint: {
    companyId: '',
    cfgrs: {},
    assetBundles: {},
    target: {
      companyId: '',
      cfgrs: {},
      assetBundles: {},
      existingNames: {
        assetBundleDisplayNames: [],
        assetBundleNames: [],
        cfgrDisplayNames: [],
        cfgrNames: [],
      },
      originalMapping: {
        configurators: {},
        assetBundles: {},
        company: '',
      },
    },
  },
  stashedBlueprints: {},
};

const SLICE_NAME = 'replicator';

PersistenceMiddleware.registerState<ReplicatorState, ReplicatorState>({
  state: SLICE_NAME,
  version: 1,
  key: 'replicator',
  selector: state => state,
});

const getRehydratedState = (): ReplicatorState => {
  const rehydratedState = PersistenceMiddleware.rehydrateState<ReplicatorState>(SLICE_NAME, initialState);
  return rehydratedState;
};

export const addCfgrToBlueprint = createAsyncThunk<
  AssetBundleAssignment[],
  {
    cfgrId: string;
    cfgrVersion: string;
  }
>('replicator/addCfgrToBlueprint', async ({ cfgrId, cfgrVersion }, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const assetBundleAssignment = await fetchAssignedAssetBundles(
    state.replicator.currentBlueprint.companyId,
    cfgrId,
    cfgrVersion
  );

  // there might be broken assignments
  const availableBundleAssignments = assetBundleAssignment.filter(bundleAssignment =>
    Object.keys(state.replicator.currentBlueprint.assetBundles).some(b => bundleAssignment.assetBundleName === b)
  );

  await Promise.all(
    availableBundleAssignments.map(async assignment => {
      await thunkApi.dispatch(
        selectBlueprintAssetBundle({
          bundleId: assignment.assetBundleName,
          version: assignment.version,
          preventVersionSelection: true,
        })
      );
      thunkApi.dispatch(
        selectBlueprintAssetBundleVersion({
          bundleName: assignment.assetBundleName,
          version: assignment.version,
          selected: true,
        })
      );
    })
  );

  return assetBundleAssignment;
});

export const loadBlueprintSelections = createAsyncThunk<AssetBundles, string>(
  'replicator/loadBlueprintSelections',
  async (companyId, thunkApi) => {
    await thunkApi.dispatch(getCompanyCfgrs({ companyId }));
    const bundles = await thunkApi.dispatch(getAssetBundles({ companyId, silentBundleUpdate: true })).unwrap();
    return bundles;
  }
);

export const createBlueprint = createAsyncThunk<string>('replicator/createBlueprint', async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const blueprint = state.replicator.currentBlueprint;
  const companyId = blueprint.companyId;

  const blueprintCfgrs = Object.values(blueprint.cfgrs).map(c => ({
    companyName: companyId,
    configuratorName: c.id,
  }));

  const blueprintAssets = Object.values(blueprint.assetBundles)
    .filter(b => b.selected)
    .reduce<AssetBundleIdRequest[]>((acc, bundleDef) => {
      return acc.concat(
        Object.values(bundleDef.versions)
          .filter(v => v.selected)
          .map(v => ({
            companyName: companyId,
            assetBundleName: bundleDef.name,
            assetBundleVersion: v.id,
          }))
      );
    }, []);

  const createdBlueprintId = await postCreateBlueprint(companyId, blueprintCfgrs, blueprintAssets);
  return createdBlueprintId;
});

export const createBlueprintCrossEnv = createAsyncThunk<string>(
  'replicator/createBlueprintCrossEnv',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const blueprint = state.replicator.currentBlueprint;
    const blueprintSasUri = await postCreateBlueprintCrossEnv(
      blueprint.companyId,
      Object.values(blueprint.cfgrs),
      Object.values(blueprint.assetBundles).filter(b => b.selected)
    );

    return blueprintSasUri;
  }
);

export const startReplicate = createAsyncThunk<string>('replicator/startReplicate', async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const blueprint = state.replicator.currentBlueprint;

  if (!blueprint.blueprintId) {
    return thunkApi.rejectWithValue(undefined);
  }
  const replicationId = await postReplicatorReplicate(blueprint.blueprintId, {
    company: blueprint.target.companyId,
    configurators: blueprint.target.cfgrs,
    assetBundles: blueprint.target.assetBundles,
  });
  return replicationId;
});

export const startReplicateCrossEnv = createAsyncThunk<string>(
  'replicator/startReplicateCrossEnv',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const blueprint = state.replicator.currentBlueprint;

    if (!blueprint.blueprintId) {
      return thunkApi.rejectWithValue(undefined);
    }
    const replicationId = await postReplicatorReplicateCrossEnv(blueprint.blueprintId, {
      company: blueprint.target.companyId,
      configurators: blueprint.target.cfgrs,
      assetBundles: blueprint.target.assetBundles,
    });
    return replicationId;
  }
);

export const loadBlueprintTargetMapping = createAsyncThunk<ReplicaMapping, string>(
  'replicator/loadBlueprintTargetMapping',
  async (targetCompanyId, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const blueprintId = state.replicator.currentBlueprint.blueprintId;

    if (!blueprintId || !targetCompanyId) {
      return thunkApi.rejectWithValue(undefined);
    }

    const mapping = await getReplicatorGenerateMapping(blueprintId, targetCompanyId);
    return mapping;
  }
);

export const loadBlueprintTargetMappingCrossEnv = createAsyncThunk<ReplicaMapping, string>(
  'replicator/loadBlueprintTargetMappingCrossEnv',
  async (targetCompanyId, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const blueprintSasUri = state.replicator.currentBlueprint.blueprintSasUri;

    if (!blueprintSasUri || !targetCompanyId) {
      return thunkApi.rejectWithValue(undefined);
    }

    const mapping = await getReplicatorGenerateMappingCrossEnv(blueprintSasUri, targetCompanyId);
    return mapping;
  }
);

export const applyBlueprintPreselection = createAsyncThunk(
  'replicator/applyBlueprintPreselection',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const preselection = state.replicator.preselection;

    if (!preselection) {
      return;
    }

    thunkApi.dispatch(resetBlueprint());

    if (preselection.cfgrId) {
      const publishedCfgr = state.companyData.cfgrs[state.companyData.selection.companyId].find(
        c => c.isPublished && c.id === preselection.cfgrId
      );
      if (!publishedCfgr) {
        return thunkApi.rejectWithValue(undefined);
      }
      thunkApi.dispatch(addCfgrToBlueprint({ cfgrId: publishedCfgr.id, cfgrVersion: publishedCfgr.version }));
    }

    if (preselection.bundleId && preselection.bundleVersion) {
      await thunkApi.dispatch(
        selectBlueprintAssetBundle({
          bundleId: preselection.bundleId,
          version: preselection.bundleVersion,
          preventVersionSelection: true,
        })
      );
      thunkApi.dispatch(
        selectBlueprintAssetBundleVersion({
          bundleName: preselection.bundleId,
          version: preselection.bundleVersion,
          selected: true,
        })
      );
    }
  }
);

export const selectBlueprintAssetBundle = createAsyncThunk<
  AssetBundleVersions,
  {
    bundleId: string;
    version?: string;
    preventVersionSelection?: boolean;
  }
>('replicator/selectBlueprintAssetBundle', async ({ bundleId, version }, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const versionsOfBundle = await fetchPublishedAssetBundleVersions(
    state.replicator.currentBlueprint.companyId,
    bundleId
  );

  // try to fetch certain bundle version if not already available in the "fetchPublishedAssetBundleVersions" call
  // this can happen if the requested bundle version is not set in the most relevant published bundle versions
  if (version && !versionsOfBundle.assetBundleVersions[version]) {
    const bundleVersionData = await fetchBundleVersionData(
      state.replicator.currentBlueprint.companyId,
      bundleId,
      version
    );
    if (bundleVersionData) {
      versionsOfBundle.assetBundleVersions[bundleVersionData.bundleVersionInfo.id] =
        bundleVersionData.bundleVersionInfo;
    }
  }

  return versionsOfBundle.assetBundleVersions;
});

const replicatorSlice = createSlice({
  name: SLICE_NAME,
  initialState: getRehydratedState,
  reducers: {
    setBlueprintPreselection: (state, { payload }: PayloadAction<BlueprintPreselection>) => {
      state.preselection = payload;
    },
    setBlueprintTargetCfgr: (
      state,
      { payload }: PayloadAction<{ cfgrId: string; name: string; displayName: string; hasErrors?: boolean }>
    ) => {
      state.currentBlueprint.target.cfgrs[payload.cfgrId] = {
        ...state.currentBlueprint.target.cfgrs[payload.cfgrId],
        name: payload.name,
        displayName: payload.displayName,
        hasInputError: !!payload.hasErrors,
      };
    },
    setBlueprintTargetBundle: (
      state,
      { payload }: PayloadAction<{ schemaName: string; name: string; displayName: string; hasErrors?: boolean }>
    ) => {
      Object.entries(state.currentBlueprint.target.assetBundles).forEach(([key, bundle]) => {
        if (payload.schemaName === key || payload.schemaName === bundle.mainVersionSibling) {
          bundle.bundleName = payload.name;
          bundle.bundleDisplayName = payload.displayName;
          bundle.hasInputError = !!payload.hasErrors;
        }
      });
    },
    removeCfgrFromBlueprint: (state, { payload }: PayloadAction<string>) => {
      const assignedBundles = state.currentBlueprint.cfgrs[payload].assignedAssetBundles;

      // remove bundle selections too if they don't have any link to another cfgr
      assignedBundles.forEach(cfgrAssignment => {
        const bundle = state.currentBlueprint.assetBundles[cfgrAssignment.assetBundleName];
        if (!bundle) {
          return;
        }

        const selectedVersionIds = Object.values(bundle.versions)
          .filter(b => b.selected)
          .map(v => v.id);

        // Same version can be deselected if there is no other link to it
        const hasOtherLinks =
          selectedVersionIds.includes(cfgrAssignment.version) &&
          Object.values(state.currentBlueprint.cfgrs).some(
            c => c.id !== payload && c.assignedAssetBundles.some(b => b.version === cfgrAssignment.version)
          );

        if (!hasOtherLinks) {
          bundle.versions[cfgrAssignment.version].selected = false;
          if (selectedVersionIds.length === 1) {
            bundle.selected = false;
          }
        }
      });
      delete state.currentBlueprint.cfgrs[payload];
    },
    deselectBlueprintAssetBundle: (state, { payload: bundleId }: PayloadAction<string>) => {
      if (!state.currentBlueprint.assetBundles[bundleId]) {
        return;
      }

      state.currentBlueprint.assetBundles[bundleId].selected = false;
      Object.values(state.currentBlueprint.assetBundles[bundleId].versions).forEach(v => {
        v.selected = false;
      });
    },
    selectBlueprintAssetBundleVersion: (
      state,
      { payload }: PayloadAction<{ bundleName: string; version: string; selected: boolean }>
    ) => {
      state.currentBlueprint.assetBundles[payload.bundleName].versions[payload.version].selected = payload.selected;
    },
    overwriteBlueprintAssetBundleSelections: (
      state,
      { payload }: PayloadAction<{ bundleName: string; selectedVersions: string[] }>
    ) => {
      Object.values(state.currentBlueprint.assetBundles[payload.bundleName].versions).forEach(v => {
        v.selected = payload.selectedVersions.includes(v.id);
      });
    },
    resetBlueprint: state => {
      state.currentBlueprint = {
        ...initialState.currentBlueprint,
        companyId: state.currentBlueprint.companyId,
        assetBundles: state.currentBlueprint.assetBundles,
      };

      Object.values(state.currentBlueprint.assetBundles).forEach(b => {
        b.selected = false;
        Object.values(b.versions).forEach(v => {
          v.selected = false;
        });
      });
    },
    changeTargetCfgrEditMode: (state, { payload }: PayloadAction<{ schemaName: string; inEditMode: boolean }>) => {
      const cfgr = state.currentBlueprint.target.cfgrs[payload.schemaName];

      cfgr.inEditMode = payload.inEditMode;
      cfgr.targetExistingCfgr = !payload.inEditMode;

      const originalEntry = state.currentBlueprint.target.originalMapping.configurators[payload.schemaName];
      cfgr.name = originalEntry.name;
      cfgr.displayName = originalEntry.displayName;
    },
    changeTargetBundleEditMode: (state, { payload }: PayloadAction<{ schemaName: string; inEditMode: boolean }>) => {
      Object.entries(state.currentBlueprint.target.assetBundles).forEach(([key, bundle]) => {
        if (payload.schemaName === key || payload.schemaName === bundle.mainVersionSibling) {
          bundle.inEditMode = payload.inEditMode;

          const originalEntry = state.currentBlueprint.target.originalMapping.assetBundles[key];
          bundle.bundleName = originalEntry.bundleName;
          bundle.bundleDisplayName = originalEntry.bundleDisplayName;
        }
      });
    },
    setTargetCfgrAutoPublish: (state, { payload }: PayloadAction<{ schemaName: string; value: boolean }>) => {
      const cfgr = state.currentBlueprint.target.cfgrs[payload.schemaName];
      cfgr.AutoPublish = payload.value;

      const sourceCfgr = state.currentBlueprint.cfgrs[payload.schemaName];
      if (sourceCfgr?.assignedAssetBundles.length) {
        sourceCfgr.assignedAssetBundles.forEach(assignment => {
          const matchingTargets = Object.keys(state.currentBlueprint.target.assetBundles).filter(
            b => getSchemaNamePart(b, 'bundle') === assignment.assetBundleName
          );
          matchingTargets.forEach(targetSchemaId => {
            state.currentBlueprint.target.assetBundles[targetSchemaId].AutoPublish = payload.value;
          });
        });
      }
    },
    setTargetBundleAutoPublish: (state, { payload }: PayloadAction<{ schemaName: string; value: boolean }>) => {
      Object.entries(state.currentBlueprint.target.assetBundles).forEach(([key, bundle]) => {
        if (payload.schemaName === key || payload.schemaName === bundle.mainVersionSibling) {
          bundle.AutoPublish = payload.value;
        }
      });
    },
    setBlueprintSasUri: (state, { payload: sasUri }: PayloadAction<string>) => {
      state.currentBlueprint.blueprintSasUri = sasUri;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(globalCompanySwitch, (state, { payload }) => {
        if (payload === state.currentBlueprint.companyId) {
          // do nothing if the company didn't change (e.g. page reload)
          return;
        }

        const stashedBp = state.stashedBlueprints[payload];
        state.stashedBlueprints[state.currentBlueprint.companyId] = state.currentBlueprint;
        if (stashedBp) {
          state.currentBlueprint = stashedBp;
        } else {
          state.currentBlueprint = { ...initialState.currentBlueprint, companyId: payload };
        }
      })
      .addCase(addCfgrToBlueprint.fulfilled, (state, { payload, meta }) => {
        state.currentBlueprint.cfgrs[meta.arg.cfgrId] = {
          id: meta.arg.cfgrId,
          version: meta.arg.cfgrVersion,
          assignedAssetBundles: payload,
        };
      })
      .addCase(loadBlueprintSelections.fulfilled, (state, { payload, meta }) => {
        const bundles = Object.values(payload);
        const updatedBundles: ReplicationBlueprint['assetBundles'] = {};

        bundles.forEach(bundle => {
          updatedBundles[bundle.id] = state.currentBlueprint.assetBundles[bundle.id] ?? {
            name: bundle.id,
            selected: false,
            versions: {},
          };
        });
        state.currentBlueprint.assetBundles = updatedBundles;
      })
      .addCase(selectBlueprintAssetBundle.fulfilled, (state, { payload, meta }) => {
        const bundleId = meta.arg.bundleId;

        const publishedVersions = Object.values(payload)
          .filter(v => !v.deleteAfter)
          .sort(sortBundlesByLastEditedDesc);

        if (!publishedVersions.length) {
          return;
        }

        const previouslySelectedVersions = state.currentBlueprint.assetBundles[bundleId]
          ? Object.values(state.currentBlueprint.assetBundles[bundleId].versions)
              .filter(v => v.selected)
              .map(v => v.id)
          : [];

        const newVersionSelection = meta.arg.preventVersionSelection ? undefined : publishedVersions[0].id;
        const versionsToSelect = [...previouslySelectedVersions, newVersionSelection];

        const blueprintVersions = publishedVersions.reduce<BlueprintAssetBundle['versions']>((arr, version) => {
          arr[version.id] = {
            id: version.id,
            displayName: version.displayName,
            selected: versionsToSelect.includes(version.id),
          };
          return arr;
        }, {});

        state.currentBlueprint.assetBundles[bundleId] = {
          name: bundleId,
          selected: true,
          versions: blueprintVersions,
        };
      })
      .addCase(createBlueprint.fulfilled, (state, { payload }) => {
        state.currentBlueprint.blueprintId = payload;
      })
      .addCase(loadBlueprintTargetMapping.rejected, (state, { payload, meta }) => {
        state.currentBlueprint.target = {
          ...initialState.currentBlueprint.target,
          companyId: meta.arg,
        };
      })
      .addCase(applyBlueprintPreselection.fulfilled, state => {
        state.preselection = undefined;
      })
      .addCase(startReplicate.fulfilled, (state, { payload }) => {
        state.currentBlueprint.recentReplicationId = payload;
      })
      .addCase(startReplicateCrossEnv.fulfilled, (state, { payload }) => {
        state.currentBlueprint.recentReplicationId = payload;
      })
      .addMatcher(
        isAnyOf(loadBlueprintTargetMapping.fulfilled, loadBlueprintTargetMappingCrossEnv.fulfilled),
        (state, { payload }) => {
          if (payload.blueprintId) {
            // blueprintId exists only for cross-env requests
            state.currentBlueprint.blueprintId = payload.blueprintId;
          }

          state.currentBlueprint.target.companyId = payload.mapping.company;
          state.currentBlueprint.target.originalMapping = payload.mapping;

          state.currentBlueprint.target.cfgrs = mapObject(payload.mapping.configurators, (c, key) => {
            const isExistingCfgr = payload.existingCfgrNames.some(
              existing => existing.toLowerCase() === c.name.toLowerCase()
            );

            return {
              ...c,
              isExistingCfgr: isExistingCfgr, // readonly value to be aware of the "initial state"
              targetExistingCfgr: isExistingCfgr, // can be toggled and will be passed to the API
              inEditMode: !isExistingCfgr,
              hasInputError: false,
            };
          });

          state.currentBlueprint.target.assetBundles = Object.entries(payload.mapping.assetBundles).reduce<
            BlueprintTarget['assetBundles']
          >((arr, [key, value]) => {
            const isExistingBundle = payload.existingAssetBundleNames.some(
              existing => existing.toLowerCase() === value.bundleName.toLowerCase()
            );
            const mainVersionSibling = Object.keys(arr).find(
              b => getSchemaNamePart(b, 'bundle') === getSchemaNamePart(key, 'bundle')
            );

            arr[key] = {
              bundleName: value.bundleName,
              bundleDisplayName: value.bundleDisplayName,
              displayName: value.displayName,
              isExistingBundle: isExistingBundle,
              inEditMode: !isExistingBundle,
              hasInputError: false,
              mainVersionSibling: mainVersionSibling,
            };

            return arr;
          }, {});

          state.currentBlueprint.target.existingNames = {
            assetBundleDisplayNames: payload.existingAssetBundleDisplayNames,
            assetBundleNames: payload.existingAssetBundleNames,
            cfgrNames: payload.existingCfgrNames,
            cfgrDisplayNames: payload.existingCfgrDisplayNames,
          };
        }
      );
  },
});

export const selectCurrentBlueprint = createSelector(
  (state: RootState) => state.replicator.currentBlueprint,
  blueprint => blueprint
);

export const selectCurrentBlueprintHasSelections = createSelector(
  (state: RootState) => state.replicator.currentBlueprint,
  blueprint => Object.keys(blueprint.cfgrs).length > 0 || Object.values(blueprint.assetBundles).some(b => b.selected)
);

export const selectTargetHasErrors = createSelector(
  (state: RootState) => state.replicator.currentBlueprint.target,
  target =>
    Object.values(target.cfgrs).some(c => c.inEditMode && c.hasInputError) ||
    Object.values(target.assetBundles).some(b => b.inEditMode && b.hasInputError)
);

export const selectTargetHasAutoPublishes = createSelector(
  (state: RootState) => state.replicator.currentBlueprint.target,
  target =>
    Object.values(target.cfgrs).some(c => c.AutoPublish) || Object.values(target.assetBundles).some(b => b.AutoPublish)
);

/** Check if the user used the same name or ID twice */
export const selectTargetHasNamingClash = createSelector(
  (state: RootState) => state.replicator.currentBlueprint.target,
  target =>
    hasDuplicates(
      Object.values(target.cfgrs),
      (a, b) =>
        a.name.toLowerCase() === b.name.toLowerCase() || a.displayName.toLowerCase() === b.displayName.toLowerCase()
    ) ||
    hasDuplicates(
      Object.values(target.assetBundles).filter(b => !b.mainVersionSibling),
      (a, b) =>
        a.bundleName.toLowerCase() === b.bundleName.toLowerCase() ||
        a.bundleDisplayName.toLowerCase() === b.bundleDisplayName.toLowerCase()
    )
);

export const {
  setBlueprintPreselection,
  removeCfgrFromBlueprint,
  setBlueprintTargetCfgr,
  setBlueprintTargetBundle,
  resetBlueprint,
  deselectBlueprintAssetBundle,
  selectBlueprintAssetBundleVersion,
  overwriteBlueprintAssetBundleSelections,
  changeTargetCfgrEditMode,
  changeTargetBundleEditMode,
  setTargetCfgrAutoPublish,
  setTargetBundleAutoPublish,
  setBlueprintSasUri,
} = replicatorSlice.actions;
export default replicatorSlice.reducer;
