import { ReactElement } from 'react';

export type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: (children: ReactElement) => ReactElement;
  children: ReactElement;
};
/**
 * @example
 * ```tsx
 * <ConditionalWrapper
 *   condition={!!title}
 *   wrapper={(children): ReactElement => (
 *     <Tooltip title={title} {...titleProps}>{children}</Tooltip>
 *   )}
 * >
 *   <div>Content which is either inside the tooltip or not</div>
 * </ConditionalWrapper>
 * ```
 */
export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
