import { styled } from '@mui/material';
import MuiInput, { InputProps as MuiInputProps } from '@mui/material/Input';
import { inputBaseClasses } from '@mui/material/InputBase';
import classNames from 'classnames';
import React, { useRef } from 'react';

export const STYLED_INPUT_PADDING = '5px';

export type InputProps = Omit<MuiInputProps, 'className' | 'sx'> & {
  warning?: boolean;
  helperText?: string;
  selectTextOnAutoFocus?: boolean;
  textAlignRight?: boolean;
};

const CustomMuiInput = React.forwardRef<unknown, InputProps>(({ warning, textAlignRight, ...props }, ref) => (
  <MuiInput disableUnderline {...props} ref={ref} />
));

const StyledInput = styled(CustomMuiInput)(({ warning, textAlignRight, theme }) => ({
  'fontSize': theme.typography.mRegular.fontSize,
  'color': theme.palette.neutral[90],
  'background': theme.palette.neutral[10],
  'height': '32px',
  'margin': '2px',

  'borderWidth': '1px',
  'borderStyle': 'solid',
  'borderColor': warning ? theme.palette.warning.main : theme.palette.neutral[40],
  'borderRadius': theme.shape.borderRadiusCtrls,

  '& input': {
    padding: theme.spacing(STYLED_INPUT_PADDING, 4, STYLED_INPUT_PADDING, 4),
    borderRadius: theme.shape.borderRadiusCtrls,
    textAlign: textAlignRight ? 'right' : 'left',

    [`&.${inputBaseClasses.inputAdornedStart}`]: {
      paddingLeft: theme.spacing(2),
    },
    [`&.${inputBaseClasses.inputAdornedEnd}`]: {
      paddingRight: theme.spacing(2),
    },
  },

  '&::placeholder': {
    color: theme.palette.neutral[70],
  },
  '&:hover': {
    borderColor: warning ? theme.palette.warning.hover : theme.palette.neutral[50],
  },
  '&:active': {
    borderColor: warning ? theme.palette.warning.pressed : theme.palette.neutral[60],
  },
  '&:focus': {
    borderRadius: theme.shape.borderRadiusCtrls,
  },

  [`&.${inputBaseClasses.adornedStart}`]: {
    paddingLeft: theme.spacing(2),
  },
  [`&.${inputBaseClasses.adornedEnd}`]: {
    paddingRight: theme.spacing(2),
  },

  '&.Mui-disabled': {
    borderColor: theme.palette.neutral[40],
    backgroundColor: theme.palette.neutral[30],
    color: theme.palette.neutral[60],
    WebkitTextFillColor: theme.palette.neutral[60],
  },
  '&.Mui-focused': {
    'outline': warning ? theme.outlines.defaultWarning : theme.outlines.default,
    'borderColor': theme.palette.neutral[10],

    '&:active': {
      borderColor: warning ? theme.palette.warning.pressed : theme.palette.neutral[60],
    },
  },
  '&.Mui-focused.Mui-error': {
    outline: theme.outlines.defaultError,
    borderColor: theme.palette.neutral[10],
  },
  '&.Mui-error': {
    'borderColor': theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.hover,
    },
  },
}));

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ fullWidth = true, helperText, selectTextOnAutoFocus, onFocus, ...props }, ref) => {
    const isInitialFocus = useRef(true);

    const onInputFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
      if (isInitialFocus.current && selectTextOnAutoFocus) {
        event.target.select();
        isInitialFocus.current = false;
      }
      onFocus?.(event);
    };

    return (
      <div className={classNames('flex flex-col', { 'w-full': fullWidth })}>
        <StyledInput ref={ref} fullWidth={fullWidth} {...props} onFocus={onInputFocus} />
        {helperText && <span className="mx-1 text-s-regular text-danger-main">{helperText}</span>}
      </div>
    );
  }
);
