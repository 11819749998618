import classNames from 'classnames';
import { ReactElement } from 'react';
import {
  DashboardIcon,
  DraftsIcon,
  EmbeddingIcon,
  FilesIcon,
  GearIcon,
  ReplicatorIcon,
  UsersIcon,
  WorkflowsIcon,
} from 'assets/icons';
import { AppSidebarLink } from 'components/app-sidebar/app-sidebar-link';
import { ConditionalWrapper } from 'components/common/conditional-wrapper';
import { GlobalCompanyPicker } from 'components/global/global-company-picker';
import { CbnCollapseIcon } from 'controls/icon/cbn-collapse-icon';
import { Tooltip } from 'controls/tooltip/tooltip';
import { useAssetEditorRouteToStateSelection } from 'hooks/assets/assets.hooks';
import { useLocalStorageState } from 'hooks/common/local-storage.hooks';
import { useCompanyFeature } from 'hooks/company/company-feature.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { useWorkflowsRouteToStateSelection } from 'hooks/workflows/workflows.hooks';
import { buildCfgrsPath } from 'services/routes/cfgrs-routes.service';
import { buildCompanySettingsPath } from 'services/routes/company-settings-routes.service';
import { buildReplicatorPath } from 'services/routes/replicator-routes.service';
import { buildStaffSettingsPath } from 'services/routes/staff-settings-routes.service';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';
import { CompanyPermissions, PermissionBundles } from 'slices/permission/permission.slice';

export const AppSidebar: React.FC = () => {
  const { t } = useTypedTranslation();

  const selectedCompany = useAppSelector(selectSelectedCompany);
  const { companyId, cfgrId } = useAppSelector(state => state.companyData.selection);
  const [collapsed, setCollapsed] = useLocalStorageState('sidebarCollapsed', false);

  const hasWorkflowFeature = useCompanyFeature('workflows');

  const hasAssetsPermission = usePermission(CompanyPermissions.ViewAssets, selectedCompany?.id);
  const hasWorkflowsPermission = usePermission(CompanyPermissions.RunWorkflows, selectedCompany?.id);
  const hasCompanySettingsPermission = usePermission(CompanyPermissions.ModifyCompanySettings, selectedCompany?.id);
  const hasStaffSettingsPermission = usePermission(CompanyPermissions.ManageUserRole, selectedCompany?.id);
  const hasExportPermission = usePermission(PermissionBundles.ShowExportPage, selectedCompany?.id, 'All');

  const showCompanySection =
    hasAssetsPermission ||
    (hasWorkflowsPermission && hasWorkflowFeature) ||
    hasStaffSettingsPermission ||
    hasCompanySettingsPermission ||
    hasExportPermission;

  // if the global company id fits, the routes will be restored from the dedicated slice
  // eg: `asseteditor/AssetCompany/TestHOF/DRAFT-0C8M4xtkbFg/malibustovespacerepair`
  // if a different company is selected the route will only be built until the `companyId` parameter
  // eg: `asseteditor/AssetCompany`
  const assetPath = useAssetEditorRouteToStateSelection();
  const workflowPath = useWorkflowsRouteToStateSelection();

  return (
    <nav
      data-cmptype="AppSidebar"
      className={classNames(
        'relative flex shrink-0 flex-col overflow-y-auto overflow-x-hidden bg-neutral-10 transition-[width]',
        {
          'w-[250px]': !collapsed,
          'w-[75px]': collapsed,
        }
      )}
    >
      <div className="absolute right-0 top-0">
        <CbnCollapseIcon
          collapsed={collapsed}
          titleExpand={t('Expand sidebar')}
          titleCollapse={t('Collapse sidebar')}
          onClick={(): void => setCollapsed(!collapsed)}
        />
      </div>

      <div className="flex grow flex-col pb-5">
        <div className="grow">
          <div className="mx-3 my-4 flex">
            <ConditionalWrapper
              condition={collapsed}
              wrapper={(children): ReactElement => (
                <Tooltip title={selectedCompany?.displayName} placement="right" arrow>
                  <span className="w-full">{children}</span>
                </Tooltip>
              )}
            >
              <GlobalCompanyPicker />
            </ConditionalWrapper>
          </div>
          {!collapsed && <div className="mx-5 mb-1 text-s-medium uppercase text-neutral-60">{t('Configurator')}</div>}
          <div className="mx-5 h-[1px] shrink-0 bg-neutral-50"></div>
          <AppSidebarLink
            text={t('Dashboard')}
            icon={DashboardIcon}
            to={buildCfgrsPath('dashboard', companyId, cfgrId)}
            collapsed={collapsed}
          />
          <AppSidebarLink
            text={t('Drafts')}
            icon={DraftsIcon}
            to={buildCfgrsPath('drafts', companyId, cfgrId)}
            collapsed={collapsed}
          />
          <AppSidebarLink
            text={t('Embedding')}
            icon={EmbeddingIcon}
            to={buildCfgrsPath('embedding', companyId, cfgrId)}
            collapsed={collapsed}
          />
        </div>
        {showCompanySection && (
          <div>
            {!collapsed && <div className="mx-5 mb-1 text-s-medium uppercase text-neutral-60">{t('Company')}</div>}
            <div className="mx-5 h-[1px] shrink-0 bg-neutral-50"></div>
            {hasAssetsPermission && (
              <AppSidebarLink
                text={t('Assets')}
                icon={FilesIcon}
                to={assetPath}
                disabled={!selectedCompany}
                disabledTooltip={!selectedCompany ? t('No company selected') : ''}
                collapsed={collapsed}
              />
            )}
            {hasWorkflowsPermission && hasWorkflowFeature && (
              <AppSidebarLink
                text={t('Workflows')}
                icon={WorkflowsIcon}
                to={workflowPath}
                disabled={!selectedCompany}
                disabledTooltip={!selectedCompany ? t('No company selected') : ''}
                collapsed={collapsed}
              />
            )}
            {hasStaffSettingsPermission && (
              <AppSidebarLink
                text={t('Users')}
                icon={UsersIcon}
                to={buildStaffSettingsPath(companyId)}
                collapsed={collapsed}
              />
            )}
            {hasCompanySettingsPermission && (
              <AppSidebarLink
                text={t('Settings')}
                icon={GearIcon}
                to={buildCompanySettingsPath(companyId)}
                collapsed={collapsed}
              />
            )}
            {hasExportPermission && (
              <AppSidebarLink
                text={t('Export')}
                icon={ReplicatorIcon}
                to={buildReplicatorPath(companyId)}
                collapsed={collapsed}
                disabled={!selectedCompany}
                disabledTooltip={!selectedCompany ? t('No company selected') : ''}
              />
            )}
          </div>
        )}
      </div>
    </nav>
  );
};
