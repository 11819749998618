import classNames from 'classnames';
import debounce from 'lodash.debounce';
import { useCallback, useMemo, useReducer, useState } from 'react';
import {
  DSConfigNumberInput,
  DSConfigSwitch,
  DSConfigTextInput,
} from 'components/asset-editor/details/modals/datasource-edit/asset-datasource-edit-base-components';
import { importConfigReducer } from 'components/asset-editor/details/modals/datasource-edit/asset-datasource-edit-reducer';
import { AssetDatasourceEditGrid } from 'components/asset-editor/details/modals/datasource-edit/edit-grid/asset-datasource-edit-grid';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { StateIcon } from 'controls/icon/state-icon';
import { NumberOrNull } from 'controls/input/number-input';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { isValidHiveIdentifier } from 'helper/hive/hive.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import {
  DataSourceColumnConfigDto,
  isCsvDataSourceConfigDto,
  isExcelDataSourceConfigDto,
} from 'services/assets/assets.service';
import { DataSourceAsset, changeDataSourceOfAsset, selectSelectedAsset } from 'slices/assets/assets.slice';

const _DEBOUNCE_TIME_DATAGRID_CHANGES = 100;

type AssetDatasourceEditModalProps = {
  onClose: () => void;
};

export const AssetDatasourceEditModal: React.FC<AssetDatasourceEditModalProps> = ({ onClose }) => {
  // dispatchApp => dispatch app wide actions for redux store
  const dispatchApp = useAppDispatch();
  const { t } = useTypedTranslation();

  const selectedAsset = useAppSelector(selectSelectedAsset) as DataSourceAsset;

  // dispatchIC => dispatch "Import Config" action
  const [importConfig, dispatchIC] = useReducer(importConfigReducer, selectedAsset.importConfig);
  const { defaults, columnConfigs } = importConfig;

  const [isProcessing, setIsProcessing] = useState(false);
  const [useConfigAutoDetection, setUseConfigAutoDetection] = useState(false);
  const [uncommitedEditRows, setUncommitedEditRows] = useState(0);

  const isExcelDS = isExcelDataSourceConfigDto(importConfig);
  const isCsvDS = isCsvDataSourceConfigDto(importConfig);
  const headerText = isExcelDS
    ? t('Excel Data Source Import Config')
    : isCsvDS
      ? t('CSV Data Source Import Config')
      : '';

  const emptyStartRow = isExcelDS && importConfig.headerRow !== undefined && importConfig.startRow === undefined;
  const invalidRowOrder =
    isExcelDS &&
    importConfig.headerRow !== undefined &&
    importConfig.startRow !== undefined &&
    importConfig.headerRow >= importConfig.startRow;

  const emptySeparator = isCsvDS && !importConfig.separator;
  const emptyListSeparator = !defaults.listSeparator;
  const sameSeparators = isCsvDS && importConfig.separator === defaults.listSeparator;

  const colIndices = columnConfigs.map(colConfig => colConfig.index);
  const invalidColIndices = colIndices.map(value => value === undefined);
  const hasInvalidColIndex = invalidColIndices.includes(true);

  const invalidColNames = columnConfigs.map(
    colConfig =>
      !isValidHiveIdentifier(colConfig.name) ||
      columnConfigs.filter(c => c !== colConfig).some(c => c.name.toLowerCase() === colConfig.name.toLowerCase())
  );
  const hasInvalidColName = invalidColNames.includes(true);
  const hasMissingLink = columnConfigs.some(
    colConfig => colConfig.linkedAssetConfig && !colConfig.linkedAssetConfig.assetFolderName
  );

  const hasBaseConfigError = emptyStartRow || invalidRowOrder || emptySeparator || emptyListSeparator || sameSeparators;
  const hasColumnsConfigError = hasInvalidColIndex || hasInvalidColName || hasMissingLink;
  const hasError = hasBaseConfigError || hasColumnsConfigError;
  const hasUncommitedChanges = uncommitedEditRows > 0;

  const changeSheetName = (value: string): void => {
    dispatchIC({ type: 'CHANGE_SHEET_NAME', payload: value });
    // also activate config auto detection if sheet name changes
    changeConfigAutoDetection(true);
  };

  const changeConfigAutoDetection = (value: boolean): void => {
    setUseConfigAutoDetection(value);
  };

  const changeHeaderRow = (value: NumberOrNull): void => {
    dispatchIC({ type: 'CHANGE_HEADER_ROW', payload: value });
  };

  const changeStartRow = (value: NumberOrNull): void => {
    dispatchIC({ type: 'CHANGE_START_ROW', payload: value });
  };

  const changeLocale = (value: string): void => {
    dispatchIC({ type: 'CHANGE_LOCALE', payload: value });
  };

  const changeSeparator = (value: string): void => {
    dispatchIC({ type: 'CHANGE_SEPARATOR', payload: value });
  };

  const changeSkipFirstRow = (value: boolean): void => {
    dispatchIC({ type: 'CHANGE_SKIP_FIRST_ROW', payload: value });
  };

  const changeStrictReadMode = (value: boolean): void => {
    dispatchIC({ type: 'CHANGE_STRICT_READ_MODE', payload: !value });
  };

  const changeListSeparator = (value: string): void => {
    dispatchIC({ type: 'CHANGE_LIST_SEPARATOR', payload: value });
  };

  const changeTrueValues = (value: string): void => {
    const parsedStrArr = value.split(',');

    dispatchIC({ type: 'CHANGE_TRUE_VALUES', payload: parsedStrArr });
  };

  const changeFalseValues = (value: string): void => {
    const parsedStrArr = value.split(',');

    dispatchIC({ type: 'CHANGE_FALSE_VALUES', payload: parsedStrArr });
  };

  const onConfigConfirmed = async (): Promise<void> => {
    setIsProcessing(true);

    const { companyId, bundleId, bundleVersion, folderId, assetId } = selectedAsset.path;

    let success = false;
    try {
      success = await dispatchApp(
        changeDataSourceOfAsset({
          companyId,
          bundleId,
          bundleVersion,
          folderId,
          assetId,
          config: importConfig,
          useConfigAutoDetection,
        })
      ).unwrap();
    } catch {
      // The error from the slice itself contains no meaningful content so it gets catched
      // The 500 error from the API is still thrown
      success = false;
    }

    setIsProcessing(false);
    // Keep the dialog open so that the modified settings aren't lost
    if (success) {
      onClose();
    }
  };

  const onColumnConfigChange = useCallback((updatedConfig: DataSourceColumnConfigDto[]): void => {
    dispatchIC({ type: 'UPDATE_COLUMNS_CONFIG', payload: updatedConfig });
  }, []);

  const onColumnConfigEditModeChange = useMemo(
    () =>
      debounce((uncommitedRowCount: number): void => {
        setUncommitedEditRows(uncommitedRowCount);
      }, _DEBOUNCE_TIME_DATAGRID_CHANGES),
    []
  );

  const disabledInfo = hasBaseConfigError
    ? t('Please check the "General" section for errors!')
    : !useConfigAutoDetection && !hasUncommitedChanges && hasColumnsConfigError
      ? t('Please check the "Columns" section for errors!')
      : undefined;

  return (
    <ModalDialog
      size="Large"
      suppressConfirmOnEnter
      suppressCancelOnEsc
      data-cmptype="AssetDatasourceEditModal"
      header={headerText}
      variant="NoIcon"
      isProcessingConfirm={isProcessing}
      // validation errors of columns config is ok when overwritten by the auto config detection
      confirmDisabled={useConfigAutoDetection ? hasBaseConfigError : hasError || hasUncommitedChanges}
      confirmDisabledInfo={
        disabledInfo && (
          <div className="flex items-center gap-1 text-m-regular text-danger-main">
            <StateIcon variant="Error" noBckgr />
            {disabledInfo}
          </div>
        )
      }
      confirmText={t('Save config')}
      childrenScrollable
      actions={{
        onConfirm: () => onConfigConfirmed(),
        onCancel: () => onClose(),
      }}
    >
      <form className="mt-3 flex flex-grow flex-col gap-4">
        <div className="grid grid-cols-[1fr_1fr]">
          <fieldset className="flex flex-col gap-2">
            <legend className="text-heading-s">{t('General')}</legend>
            <div className="grid grid-cols-[160px_250px] gap-y-1">
              {isExcelDS && (
                <>
                  <DSConfigTextInput name={t('Sheet Name')} value={importConfig.sheetName} onChange={changeSheetName} />
                  <DSConfigSwitch
                    name={t('Auto Config Detection')}
                    value={useConfigAutoDetection}
                    helpText={
                      <TooltipContent
                        header={t('Auto detect the config of the entered sheet')}
                        detail={t(
                          'When activated, the "Columns" config will be overwritten! This is only temporary active and all fields can be edited again afterwards.'
                        )}
                      />
                    }
                    onChange={changeConfigAutoDetection}
                  />
                  <DSConfigNumberInput
                    name={t('Header Row')}
                    value={importConfig.headerRow}
                    onlyPositive
                    error={invalidRowOrder}
                    errorText={t(
                      'Invalid row order!\nIf both "Header Row" and "Start Row" are set, "Header Row" has to be smaller than "Start Row"'
                    )}
                    helpText={t('Index of row that contains the captions for the data source. Index is 0-based.')}
                    onChange={changeHeaderRow}
                  />
                  <DSConfigNumberInput
                    name={t('Start Row')}
                    value={importConfig.startRow}
                    onlyPositive
                    error={emptyStartRow || invalidRowOrder}
                    errorText={
                      emptyStartRow
                        ? t('Invalid start row!\nIf "Header Row" is set, "Start Row" has to be set as well')
                        : undefined
                    }
                    helpText={t('Index of first data row. Index is 0-based.')}
                    onChange={changeStartRow}
                  />
                </>
              )}
              {isCsvDS && (
                <>
                  <DSConfigTextInput name={t('Locale')} value={importConfig.locale} onChange={changeLocale} />
                  <DSConfigTextInput
                    name={t('Separator')}
                    value={importConfig.separator}
                    error={emptySeparator || sameSeparators}
                    errorText={t(
                      'Invalid "Separator" value!\n"Separator" must not be empty and different from "List Separator" value'
                    )}
                    onChange={changeSeparator}
                  />
                  <DSConfigSwitch
                    name={t('Skip First Row')}
                    value={importConfig.skipFirstRow}
                    onChange={changeSkipFirstRow}
                  />
                </>
              )}
            </div>
          </fieldset>
          <fieldset className="flex flex-col gap-2">
            <legend className="text-heading-s">{t('Defaults')}</legend>
            <div className="grid grid-cols-[160px_250px] gap-y-1">
              <DSConfigSwitch
                name={t('Ignore Invalid Values')}
                value={!defaults.strictReadMode}
                helpText={
                  <>
                    {t('Enabled: Any invalid value will be replaced with the default value of the column')}
                    <br />
                    {t(
                      "Disabled: Data source will return an error if there's an invalid value (e.g. string in a number column)"
                    )}
                  </>
                }
                onChange={changeStrictReadMode}
              />
              <DSConfigTextInput
                name={t('List Separator')}
                value={defaults.listSeparator}
                error={emptyListSeparator || sameSeparators}
                errorText={t(
                  'Invalid "List Separator" value!\n"List Separator" must not be empty and different from "Separator" value'
                )}
                onChange={changeListSeparator}
              />
              <DSConfigTextInput
                name={t('True Values')}
                value={defaults.boolTrueValues.join()}
                onChange={changeTrueValues}
              />
              <DSConfigTextInput
                name={t('False Values')}
                value={defaults.boolFalseValues.join()}
                onChange={changeFalseValues}
              />
            </div>
          </fieldset>
        </div>
        <div
          className={classNames('flex flex-col gap-2 overflow-y-auto', {
            'pointer-events-none select-none opacity-50': useConfigAutoDetection,
          })}
        >
          <AssetDatasourceEditGrid
            configData={columnConfigs}
            onChange={onColumnConfigChange}
            onRowEditModeChange={onColumnConfigEditModeChange}
          />
        </div>
      </form>
    </ModalDialog>
  );
};
