import { Collapse } from '@mui/material';
import { HistoryTable } from 'components/cfgrs/drafts/history-table';
import { useCbnCard } from 'controls/cbn-card/cbn-card-provider';

type CfgrVersionsCardBodyProps = {};

export const CfgrVersionsCardBody: React.FC<CfgrVersionsCardBodyProps> = () => {
  const { collapsed } = useCbnCard();

  return (
    <Collapse data-cmptype="CfgrVersionsCardBody" in={!collapsed}>
      <HistoryTable suspendLoad={collapsed} />
    </Collapse>
  );
};
