import { useEffect, useRef, useState } from 'react';
import { IdentityUser } from 'components/identity/identity-user';
import { ReplicationStateIcon } from 'components/replicator/replication-state-icon';
import { LockStatusTypes } from 'generated/lock-status-types';
import { getFormattedDateAndTimeFromSeconds } from 'helper/date-and-time/date-and-time.helper';
import { useNavigateToAssetEditor } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { ReplicatorLockStatus, isReplicatorLockStatus } from 'services/company-data/company-data.service';
import { ReplicationStatus, getReplicationStatus } from 'services/replicator/replicator.service';
import {
  getBundleVersionData,
  getRelevantAssetBundleVersions,
  selectSelectedBundleVersion,
} from 'slices/assets/assets.slice';
import { getCompanyCfgrs, selectSelectedCompany } from 'slices/company-data/company-data.slice';

type LockStatusProps = {
  lockedEntity: 'Configurator' | 'BundleVersion';
  lockStatus: ReplicatorLockStatus;
};

export const ReplicatorLockStatusInfo: React.FC<LockStatusProps> = ({ lockStatus, lockedEntity }) => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();
  const company = useAppSelector(selectSelectedCompany);
  const bundleVersion = useAppSelector(selectSelectedBundleVersion);
  const navigateToAssetEditor = useNavigateToAssetEditor();
  const companyId = company?.id ?? '';
  const replicationId = lockStatus.replicationId ?? '';
  const [failedRequest, setFailedRequest] = useState(false);
  const didResolve = useRef(false); // prevent endless resolve loops, if it's locked forever

  const [replicationStatus, setReplicationStatus] = useState<ReplicationStatus>();

  useEffect(
    function startPolling() {
      if (!replicationId || !companyId) {
        return;
      }

      let intervalId = 0;
      const fetchStatus = async (): Promise<void> => {
        let result: ReplicationStatus | undefined;
        try {
          result = (await getReplicationStatus(replicationId, companyId)).result;
        } catch {
          setFailedRequest(true);
        }

        if (result) {
          setReplicationStatus(result);
          if (result.completedAt) {
            window.clearInterval(intervalId);
          }
        } else {
          setFailedRequest(true);
          window.clearInterval(intervalId);
        }
      };
      fetchStatus();

      intervalId = window.setInterval(fetchStatus, 2000);
      return () => window.clearInterval(intervalId);
    },
    [dispatch, replicationId, companyId]
  );

  useEffect(() => {
    const resolveLockStateAfterCompletion = async (): Promise<void> => {
      if (didResolve.current || (!replicationStatus?.completedAt && !failedRequest)) {
        return;
      }

      switch (lockedEntity) {
        case 'Configurator':
          await dispatch(getCompanyCfgrs({ companyId: companyId }));
          break;
        case 'BundleVersion': {
          if (!bundleVersion?.id) {
            return;
          }

          try {
            await dispatch(
              getBundleVersionData({ companyId, bundleId: bundleVersion.bundleId, bundleVersion: bundleVersion.id })
            ).unwrap();
            return;
          } catch (e) {}
          // bundle version is not available (anymore)
          // try navigating to "based on" version or go to fallback version (see `useAssetEditorRouteSync` hook)

          const latestVersions = await dispatch(
            getRelevantAssetBundleVersions({ companyId, bundleId: bundleVersion.bundleId })
          ).unwrap();

          const newVersion = Object.values(latestVersions).find(v => v.basedOnVersion === bundleVersion.id);

          navigateToAssetEditor({
            companyId: companyId,
            bundleId: bundleVersion.bundleId,
            bundleVersion: newVersion?.version,
          });
          break;
        }
        default:
          throw new Error(`Lock state can't be resolved: Missing case for "${lockedEntity}"`);
      }
      didResolve.current = true;
    };
    resolveLockStateAfterCompletion();
  }, [
    dispatch,
    navigateToAssetEditor,
    replicationStatus?.completedAt,
    failedRequest,
    companyId,
    bundleVersion?.bundleId,
    bundleVersion?.id,
    lockedEntity,
  ]);

  return (
    <div data-cmptype="LockStatusInfo">
      {!failedRequest && isReplicatorLockStatus(lockStatus) ? (
        replicationStatus && (
          <div>
            <table>
              <tbody>
                <tr>
                  <td className="py-1 pr-4">{t('State')}:</td>
                  <td>
                    <ReplicationStateIcon status={replicationStatus} />
                  </td>
                </tr>
                <tr>
                  <td className="py-1 pr-4">{t('Started by')}:</td>
                  <td>
                    <IdentityUser userId={replicationStatus.startedBy} />
                  </td>
                </tr>
                <tr>
                  <td className="py-1 pr-4">{t('Started at')}:</td>
                  <td>{getFormattedDateAndTimeFromSeconds(replicationStatus.startedAt)}</td>
                </tr>
                {replicationStatus.completedAt && (
                  <tr>
                    <td className="py-1 pr-4">{t('Completed at')}:</td>
                    <td>{getFormattedDateAndTimeFromSeconds(replicationStatus.completedAt)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )
      ) : (
        <div>
          {lockedEntity === 'BundleVersion'
            ? t('The asset bundle version is locked.')
            : lockedEntity === 'Configurator'
              ? t('The configurator is locked.')
              : ''}
          {lockStatus && <div>{t('Status: ') + LockStatusTypes[lockStatus.$type]}</div>}
          <br />
          {t('If this might be a mistake, please refresh the page or contact an admin.')}
        </div>
      )}
    </div>
  );
};
