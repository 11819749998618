import React, { useContext, useState } from 'react';
import { CloudUploadIcon, CopyIcon, DeleteIcon, HorizontalDotsMenuIcon, MoveIcon, RenameIcon } from 'assets/icons';
import { AssetDownloadButton } from 'components/asset-editor/details/asset-body/asset-download-button';
import { AssetDetailsContext } from 'components/asset-editor/details/asset-details';
import { AssetDeleteModal } from 'components/asset-editor/details/modals/asset-delete-modal';
import { AssetRelocateActions, AssetRelocateModal } from 'components/asset-editor/details/modals/asset-relocate-modal';
import { NewAssetMenu } from 'components/asset-editor/overview/new-asset-menu';
import { Button } from 'controls/button/button';
import { FileSelectButton } from 'controls/button/file-select-button';
import { CbnMenu } from 'controls/cbn-menu/cbn-menu';
import { CbnMenuItem } from 'controls/cbn-menu/cbn-menu-item';
import { useIsAssetBundleEditable } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import {
  MATERIALS_3D_FOLDER_NAME,
  selectFileLinkKeyOfSelectedDataSourceAsset,
  selectSelectedAsset,
} from 'slices/assets/assets.slice';

export type AssetDefaultActions = AssetRelocateActions | 'Download' | 'Delete';
export type AssetUploadMode = 'None' | 'Add' | 'Replace';

type AssetDetailsActionsProps = {
  /**
   * Default actions are only allowed if the asset bundle is editable (except for download).\
   * In case of custom actions ensure that permissions are properly handled!
   */
  actions?: (AssetDefaultActions | React.ReactElement)[];
  uploadMode?: AssetUploadMode;
};

export const AssetDetailsActions: React.FC<AssetDetailsActionsProps> = ({ actions, uploadMode = 'Replace' }) => {
  const { t } = useTypedTranslation();
  const isAssetBundleEditable = useIsAssetBundleEditable();

  const selectedAsset = useAppSelector(selectSelectedAsset);
  const isDataSourceBasedOnFileAsset = !!useAppSelector(selectFileLinkKeyOfSelectedDataSourceAsset);

  const { onUploadFilesSelected } = useContext(AssetDetailsContext);

  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // "main actions": directly visible in asset header
  // "secondary actions": hidden in menu
  // custom actions from component input are always main actions
  const defaultMainActions: Partial<Record<AssetDefaultActions, React.ReactNode>> = {
    Delete: (
      <Button
        variant="Outlined"
        hoverColor="Danger"
        Svg={DeleteIcon}
        title={t('Delete asset')}
        onClick={(): void => setShowDeleteModal(true)}
      />
    ),
    Download: <AssetDownloadButton asset={selectedAsset} variant="Outlined" text="" title={t('Download asset')} />,
  };
  const defaultSecondaryActions: Partial<Record<AssetDefaultActions, React.ReactNode>> = {
    Rename: <CbnMenuItem text={t('Rename')} icon={RenameIcon} handleClick={(): void => setShowRenameModal(true)} />,
    Copy: <CbnMenuItem text={t('Copy')} icon={CopyIcon} handleClick={(): void => setShowCopyModal(true)} />,
    Move: <CbnMenuItem text={t('Move to...')} icon={MoveIcon} handleClick={(): void => setShowMoveModal(true)} />,
  };

  let mainActions: React.ReactNode[] = [];
  let secondaryActions: React.ReactNode[] = [];

  if (!actions) {
    if (isAssetBundleEditable) {
      mainActions = Object.values(defaultMainActions);
      secondaryActions = Object.values(defaultSecondaryActions);
    } else {
      mainActions = [defaultMainActions.Download];
    }
  } else {
    actions.forEach(action => {
      if (React.isValidElement(action)) {
        mainActions.push(action);
      } else if (defaultMainActions[action as AssetDefaultActions]) {
        mainActions.push(defaultMainActions[action as AssetDefaultActions]);
      } else if (defaultSecondaryActions[action as AssetDefaultActions]) {
        secondaryActions.push(defaultSecondaryActions[action as AssetDefaultActions]);
      }
    });
  }

  let uploadAction: React.ReactNode;
  if (isAssetBundleEditable) {
    if (uploadMode === 'Replace') {
      uploadAction = (
        <FileSelectButton
          variant="Secondary"
          text={t('Replace')}
          Svg={CloudUploadIcon}
          onSelect={onUploadFilesSelected}
          disabled={isDataSourceBasedOnFileAsset}
          title={isDataSourceBasedOnFileAsset ? t("Can't replace data source that is based on a file asset!") : ''}
        />
      );
    } else if (uploadMode === 'Add') {
      uploadAction = (
        <NewAssetMenu
          menuBtnText={t('Add data')}
          onUploadFilesSelected={onUploadFilesSelected}
          // only allow creation of new materials if the "Materials3d" folder is selected
          hideNewMaterialEntry={selectedAsset?.path.assetId !== MATERIALS_3D_FOLDER_NAME}
        />
      );
    }
  }

  const onMenuBtnClicked = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <div data-cmptype="AssetDetailsAction" className="flex h-9 gap-2">
        {mainActions.map((action, idx) => (
          <React.Fragment key={idx}>{action}</React.Fragment>
        ))}
        {!!secondaryActions.length && (
          <>
            <Button Svg={HorizontalDotsMenuIcon} variant="Outlined" onClick={onMenuBtnClicked} />
            <CbnMenu anchor={anchorEl} open={open} onClose={handleClose} placement="bottom">
              {secondaryActions.map((action, idx) => (
                <React.Fragment key={idx}>{action}</React.Fragment>
              ))}
            </CbnMenu>
          </>
        )}
        {uploadAction}
      </div>
      {showRenameModal && <AssetRelocateModal action="Rename" onClose={(): void => setShowRenameModal(false)} />}
      {showCopyModal && <AssetRelocateModal action="Copy" onClose={(): void => setShowCopyModal(false)} />}
      {showMoveModal && <AssetRelocateModal action="Move" onClose={(): void => setShowMoveModal(false)} />}
      {showDeleteModal && <AssetDeleteModal onClose={(): void => setShowDeleteModal(false)} />}
    </>
  );
};
