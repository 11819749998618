import { useEffect, useState } from 'react';
import { UserProfileInput } from 'components/user-profile/user-profile-input';
import { Button } from 'controls/button/button';
import { Card } from 'controls/card/card';
import { getTypedTranslation, useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { putUpdateUserInfo } from 'services/auth/auth.service';
import { getUserInfo } from 'slices/auth/auth.slice';

const _MAX_LENGTH = 128;

export const UserProfileName: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const { firstname, lastname } = useAppSelector(state => state.auth.userInfo);
  const [curFirstName, setCurFirstName] = useState(firstname);
  const [curLastName, setCurLastName] = useState(lastname);

  const [firstNameErrorText, setFirstNameErrorText] = useState('');
  const [lastNameErrorText, setLastNameErrorText] = useState('');

  const namesDidNotChange = curFirstName === firstname && curLastName === lastname;
  const disableUpdateName =
    !curFirstName.length || !curLastName.length || !!firstNameErrorText || !!lastNameErrorText || namesDidNotChange;

  useEffect(() => {
    setCurFirstName(firstname);
  }, [firstname]);
  useEffect(() => {
    setCurLastName(lastname);
  }, [lastname]);

  const onChangeFirstName = (value: string): void => {
    setCurFirstName(value);
    setFirstNameErrorText('');
  };

  const onChangeLastName = (value: string): void => {
    setCurLastName(value);
    setLastNameErrorText('');
  };

  const onUpdateName = async (): Promise<void> => {
    const firstNameErrorText = _validateName(curFirstName);
    setFirstNameErrorText(firstNameErrorText);

    const lastNameErrorText = _validateName(curLastName);
    setLastNameErrorText(lastNameErrorText);

    if (firstNameErrorText || lastNameErrorText) {
      return;
    }

    // execute update user info call if validation was successful
    // this call is not expected to fail after doing the validation on the client, so there's no explicit error handling
    await putUpdateUserInfo(curFirstName, curLastName);
    // immediatly get new user info to sync the changes into the store
    dispatch(getUserInfo());
  };

  return (
    <Card data-cmptype="UserProfileName" headerText={t('Name')}>
      <form className="flex flex-col gap-2">
        <UserProfileInput
          labelText={t('First name')}
          value={curFirstName}
          onValueChange={onChangeFirstName}
          error={!!firstNameErrorText}
          errorText={firstNameErrorText}
        />
        <UserProfileInput
          labelText={t('Last name')}
          value={curLastName}
          onValueChange={onChangeLastName}
          error={!!lastNameErrorText}
          errorText={lastNameErrorText}
        />

        {/* Not using "submit" button type as it re-loads the page */}
        <Button variant="Primary" text={t('Update name')} onClick={onUpdateName} disabled={disableUpdateName} />
      </form>
    </Card>
  );
};

/**
 * Help function for validating "name" inputs.
 * This only considers text length ATM.
 *
 * @returns error text if validation is not successful
 */
function _validateName(name: string): string {
  const { t } = getTypedTranslation();

  if (!name.length) {
    return t('Name must not be empty!');
  } else if (name.length > _MAX_LENGTH) {
    return t(`Name must not be longer than ${_MAX_LENGTH} characters!`);
  }

  return '';
}
