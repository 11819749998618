import { COMPANYID_ROUTE_PARAMS, CompanyIdRouteParams } from 'services/routes/common-routeparams.service';
import { buildPath } from 'services/routes/routes.service';

export const REPLICATOR_BASE_PATH = '/export';

export const REPLICATOR_PAGE = {
  copy: 'copy',
  progress: 'progress',
};
export type ReplicatorRoutePage = keyof typeof REPLICATOR_PAGE;

export type ReplicatorRouteParams = CompanyIdRouteParams;

export const REPLICATOR_PATH_VARIANTS = {
  replicator: `${REPLICATOR_BASE_PATH}/:${COMPANYID_ROUTE_PARAMS.companyId}`,
  replicatorCopy: `${REPLICATOR_BASE_PATH}/:${COMPANYID_ROUTE_PARAMS.companyId}/${REPLICATOR_PAGE.copy}`,
  replicatorProgress: `${REPLICATOR_BASE_PATH}/:${COMPANYID_ROUTE_PARAMS.companyId}/${REPLICATOR_PAGE.progress}`,
};

export function buildReplicatorPath(companyId: string, page?: ReplicatorRoutePage): string {
  return buildPath(REPLICATOR_BASE_PATH, companyId, page);
}
