import React from 'react';
import { MetadataCol } from 'components/metadata/metadata-col';

type MetadataProps = {
  children: React.ReactElement<typeof MetadataCol> | React.ReactElement<typeof MetadataCol>[];
};

export const Metadata: React.FC<MetadataProps> = ({ children }) => {
  return (
    <div data-cmptype="Metadata" className="flex gap-28 pb-3">
      {children}
    </div>
  );
};
