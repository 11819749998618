import { Location } from 'react-router-dom';

export type LocationState = {
  referrer?: string;
  // extend with custom properties as needed
};

export type LocationProps = Location & {
  state: LocationState;
};

/**
 * Builds a path by encoding all given `params` and appending them to the `basePath` with a `/` separator.
 * Stops at the first `param` which is `undefined` or empty as appending `params` which come afterwards would alter the
 * param order which is usually important with local routing paths.
 */
export function buildPath(basePath: string, ...params: (string | undefined)[]): string {
  const emptyParamIdx = params.findIndex(p => p === undefined || p === '');
  const basePathWithoutLeadingSlash = basePath.replace(/\/$/, '');
  const trimmedParams = (emptyParamIdx < 0 ? params : params.slice(0, emptyParamIdx)) as string[];
  const path = trimmedParams.reduce((acc, param) => `${acc}/${encodeURIComponent(param)}`, basePathWithoutLeadingSlash);
  return path;
}
