// Generated by EnumsBuilderTypeScript (DO NOT change this file manually, change the builder if required)
export enum FilterOperators {
  Equals = 1,
  NotEquals = 2,
  GreaterThan = 3,
  GreaterThanOrEqual = 4,
  LessThan = 5,
  LessThanOrEqual = 6,
  HasValue = 7,
  HasNoValue = 8,
  In = 9
}