import { useState } from 'react';
import { PackageIcon, PlusIcon } from 'assets/icons';
import { NewBundleModal } from 'components/asset-editor/header/modals/new-bundle-modal';
import { Button } from 'controls/button/button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { CompanyPermissions } from 'slices/permission/permission.slice';

export const NoAssetBundlesFallback: React.FC = () => {
  const { t } = useTypedTranslation();

  const companyId = useAppSelector(state => state.companyData.selection.companyId);
  const hasManageAssetsPermission = usePermission(CompanyPermissions.ManageAssets, companyId);

  const [newBundleDialogActive, setNewBundleDialogActive] = useState(false);

  return (
    <>
      <CbnCard data-cmptype="NoAssetBundlesFallback">
        <CbnCardHeader
          variant="large-title"
          Svg={PackageIcon}
          title={t('Asset bundles')}
          subText={t('There are no asset bundles present. Create a bundle to add data.')}
          actions={
            hasManageAssetsPermission && (
              <Button Svg={PlusIcon} text={t('New bundle')} onClick={(): void => setNewBundleDialogActive(true)} />
            )
          }
        />
        <CbnCardBody>
          <div className="flex flex-col">
            <span>{t('A bundle combines different types of data and media to a package.')}</span>
            <span>
              {t('A configurator can access and use the data of assigned asset bundles. See ')}
              <a
                href="https://docs.combeenation.com/docs/getting-started-with-assets#asset-bundles"
                className="cursor-pointer whitespace-nowrap text-m-regular text-primary-main underline hover:no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('docs')}
              </a>
              {t(' for further info.')}
            </span>
          </div>
        </CbnCardBody>
      </CbnCard>

      {newBundleDialogActive && <NewBundleModal onClose={(): void => setNewBundleDialogActive(false)} />}
    </>
  );
};
