/**
 * Map function for objects\
 * Maps the values inside the given `obj` to any new value
 *
 * @example
 * const myObj = { key1: { foo: 'bar' } };
 * mapObject(myObj, o => ({ ...o, newProp: 'foobar' }));
 */
export const mapObject = <TInput, TResult>(
  obj: { [key: string]: TInput },
  mapFn: (value: TInput, key: string, index: number) => TResult
): { [k: string]: TResult } => Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, mapFn(v, k, i)]));
