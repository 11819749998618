import { createContext, useContext } from 'react';

export type CbnCardContextState = {
  isCollapsible: boolean;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
};

const CBN_CARD_CONTEXT_INITIAL_STATE: CbnCardContextState = {
  isCollapsible: false,
  collapsed: false,
  setCollapsed: () => {},
};

const CbnCardContext = createContext(CBN_CARD_CONTEXT_INITIAL_STATE);
export const useCbnCard = (): CbnCardContextState => useContext(CbnCardContext);

type CbnCardProviderProps = {
  value: CbnCardContextState;
  children: React.ReactNode;
};

export const CbnCardProvider: React.FC<CbnCardProviderProps> = ({ value, children }) => {
  return <CbnCardContext.Provider value={value}>{children}</CbnCardContext.Provider>;
};
