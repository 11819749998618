import { GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { GridRowId } from '@mui/x-data-grid-pro';
import { GridBaseColDef } from '@mui/x-data-grid/internals';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorIcon, HintIcon } from 'assets/icons';
import { DataGrid, UNKNOWN_ROW_COUNT } from 'controls/datagrid/data-grid';
import { Icon } from 'controls/icon/icon';
import { Tooltip } from 'controls/tooltip/tooltip';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { AssetErrorTypes } from 'generated/asset-error-types';
import { AssetHintTypes } from 'generated/asset-hint-types';
import { DataSourceColumnTypes } from 'generated/datasource-column-types';
import { getTypeOptionLabel } from 'helper/assets/assets-datasource.helper';
import { useMinTimeActive } from 'hooks/common/timing.hooks';
import { getTypedTranslation, useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import {
  AssetDataSourceParseError,
  AssetError,
  AssetHint,
  DataSourceColumnConfigDto,
  DataSourceContentDto,
  MissingLinkedAssetHint,
  isDataSourceParseError,
  postAssetsGetDataSourceContent,
} from 'services/assets/assets.service';
import { DataSourceAsset, selectSelectedAsset } from 'slices/assets/assets.slice';

const _PAGE_SIZE = 25;
/** Default is 'id' which could overlap with a non-unique column of the data source */
const _ROW_ID_KEY = 'internal-mui-id';

export const DatasourceAssetContent: React.FC = () => {
  const { t } = useTypedTranslation();
  const {
    path: { companyId, bundleId, bundleVersion, folderId, assetId },
    rowCount: totalRowCount,
    errorBag: { items: errors },
    hintBag: { items: hints },
    config,
  } = useAppSelector(selectSelectedAsset) as DataSourceAsset;

  const [content, setContent] = useState<DataSourceContentDto>();
  const [startRow, setStartRow] = useState(0);
  const [endRow, setEndRow] = useState(_getInitialEndRow(totalRowCount));
  const [rowCount, setRowCount] = useState(UNKNOWN_ROW_COUNT);
  const [isLoading, setIsLoading] = useState(false);
  const showLoader = useMinTimeActive(isLoading, 500);
  const [currentPage, setCurrentPage] = useState(0);
  // see corresponding useEffect why this ref is required
  const pendingRowCount = useRef<number | null>(null);
  const datagridCtRef = useRef<HTMLDivElement>(null);

  const { columns, rows } = useMemo(() => {
    if (!content) {
      return { columns: [], rows: [] };
    }

    const columns = config.columnConfigs.map<GridColDef>(colConfig => {
      const definition: GridColDef = {
        field: colConfig.name,
        sortable: false,
        minWidth: 80,
        renderHeader: () => _getColumnHeaderCmp(errors, hints, colConfig),
      };

      // extract data source issues
      const parseErrors = errors.filter(
        e => isDataSourceParseError(e) && e.columnName === colConfig.name
      ) as AssetDataSourceParseError[];

      const missingLinkHints = hints.filter(
        h => h.columnName === colConfig.name && h.$type === AssetHintTypes.MissingLinkedAsset
      ) as MissingLinkedAssetHint[];

      _setColumnTypeAndRendering(definition, colConfig, parseErrors, missingLinkHints);

      return definition;
    });

    const rows = content.rows.map((row, idx) => {
      const dataRow = new Map<string, any>();
      dataRow.set(_ROW_ID_KEY, idx + currentPage * _PAGE_SIZE + 1);
      columns.forEach((col, idx) => {
        dataRow.set(col.field, row[idx]);
      });
      return Object.fromEntries(dataRow);
    });
    return { columns, rows };
  }, [config, content, currentPage, errors, hints]);

  useEffect(
    function fetchOnChanges() {
      const abortCtrlr = new AbortController();

      const fetch = async (): Promise<void> => {
        setIsLoading(true);

        const result = await postAssetsGetDataSourceContent(
          companyId,
          bundleId,
          bundleVersion,
          folderId,
          assetId,
          startRow,
          endRow,
          abortCtrlr.signal
        );

        if (result) {
          setContent(result);
          setIsLoading(false);
        }
      };

      fetch();

      return () => {
        abortCtrlr.abort();
      };
    },
    // also fetch after a "config" change, as the user might have changed the sheet name
    [startRow, endRow, companyId, bundleId, bundleVersion, folderId, assetId, config]
  );

  useEffect(
    function loadRowCount() {
      setRowCount(totalRowCount !== undefined ? totalRowCount : UNKNOWN_ROW_COUNT);
    },
    [totalRowCount]
  );

  // `rowCount` was introduced with the "datasource preview" feature
  // For older datasources we only know the final count once the user fetched a row that doesn't exist
  // E.g.: we fetch 25 rows -> only 10 get returned -> end of rows reached
  useEffect(
    function handleUnknownRowCount() {
      if (rowCount !== UNKNOWN_ROW_COUNT || !content) {
        // the rowCount is known
        return;
      }

      if (pendingRowCount.current !== null) {
        setRowCount(pendingRowCount.current);
        setStartRow(currentPage * _PAGE_SIZE);
        setEndRow(currentPage * _PAGE_SIZE + _PAGE_SIZE);
        pendingRowCount.current = null;
        return;
      }

      if (content.rows.length < _PAGE_SIZE) {
        if (content.rows.length === 0 && currentPage > 0) {
          // When we can switch to "known rows" but the latest fetch returns no rows, we automatically navigate back.
          // Setting the `rowCount` directly results in errors from the MUI pagination
          // -> updating the values in the next "render cycle" fixes this
          setCurrentPage(page => page - 1);
          pendingRowCount.current = currentPage * _PAGE_SIZE + content.rows.length;
        } else {
          setRowCount(currentPage * _PAGE_SIZE + content.rows.length);
        }
      }
    },
    [rowCount, content, currentPage]
  );

  const onPaginationModelChange = (model: GridPaginationModel): void => {
    setCurrentPage(model.page);
    setStartRow(model.page * _PAGE_SIZE);
    const endRow = model.page * _PAGE_SIZE + _PAGE_SIZE;
    setEndRow(Math.min(endRow, totalRowCount ?? Number.MAX_VALUE) - 1);
  };

  return (
    <div
      data-cmptype="DatasourceAssetContent"
      className="absolute inset-0 flex flex-col overflow-y-auto"
      ref={datagridCtRef}
    >
      <div className="absolute inset-0 flex flex-col overflow-y-auto">
        <DataGrid
          customToolbarProps={{ headerText: t('Content'), showDensitySelector: true }}
          key={assetId}
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            outliersFactor: 5,
          }}
          columns={columns}
          rows={rows}
          rowSelection={false}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={[_PAGE_SIZE]}
          paginationModel={{ page: currentPage, pageSize: _PAGE_SIZE }}
          onPaginationModelChange={onPaginationModelChange}
          disableColumnFilter
          disableColumnReorder
          getRowId={(row): GridRowId => row[_ROW_ID_KEY]}
          loading={showLoader}
        />
      </div>
    </div>
  );
};

function _getInitialEndRow(rowCount?: number): number {
  if (rowCount === undefined) {
    // data source with unknown `rowCount`
    return _PAGE_SIZE - 1;
  } else {
    // consider paging and a possible `rowCount` of 0
    return Math.max(Math.min(rowCount, _PAGE_SIZE) - 1, 0);
  }
}

/**
 * Some definitions can have impact on others due to the order of their execution:
 * @see {@link https://mui.com/x/react-data-grid/column-definition/#rendering-cells |MUI Rendering cells}
 */
function _setColumnTypeAndRendering(
  definition: GridBaseColDef<any, any, any>,
  colConfig: DataSourceColumnConfigDto,
  parseErrors: AssetDataSourceParseError[],
  missingLinkHints: MissingLinkedAssetHint[]
): void {
  const { t } = getTypedTranslation();

  // render error icon with according toolip
  definition.renderCell = (params): ReactNode => {
    const parseError = parseErrors.find(e => e.row === (params.id as number) - 1);
    const missingLinkHint = missingLinkHints.find(h => h.row === (params.id as number) - 1);
    if (parseError) {
      return (
        <div className="flex items-center gap-1">
          <span className="text-danger-main">{`"${parseError.rawValue}"`}</span>
          <Tooltip
            interactive
            title={
              <TooltipContent
                header={<span className="text-danger-main">{t('Error')}</span>}
                detail={
                  <>
                    <span>{t("Can't parse raw value")}</span>
                  </>
                }
              />
            }
          >
            <Icon Svg={ErrorIcon} className="w-5 text-danger-main" />
          </Tooltip>
        </div>
      );
    } else if (missingLinkHint) {
      return (
        <div className="flex items-center gap-1">
          <span className="text-primary-main">{missingLinkHint.assetName}</span>
          <Tooltip
            interactive
            title={
              <TooltipContent
                header={<span className="text-primary-main">{t('Hint')}</span>}
                detail={
                  <>
                    <span>{t('Linked asset is missing')}</span>
                  </>
                }
              />
            }
          >
            <Icon Svg={HintIcon} className="w-5 text-primary-main" />
          </Tooltip>
        </div>
      );
    }
  };

  // render as desired by column type if no error is present
  switch (colConfig.default.type) {
    case DataSourceColumnTypes.Bool:
      definition.type = 'boolean';
      break;
    case DataSourceColumnTypes.FileUrl:
      definition.renderCell = (params): ReactNode => (
        <a href={params.value} className="cursor-pointer underline" target="_blank" rel="noreferrer">
          {params.value}
        </a>
      );
      break;
    case DataSourceColumnTypes.ListString:
    case DataSourceColumnTypes.ListDouble:
    case DataSourceColumnTypes.ListBool:
      definition.valueFormatter = (params): string => `[${params.value}]`;
      break;
    default:
      definition.type = 'string';
      break;
  }
}

function _getColumnHeaderCmp(
  errors: AssetError[],
  hints: AssetHint[],
  colConfig: DataSourceColumnConfigDto
): ReactNode {
  const { t, tDataSourceParseErrorsInRows, tLinkedAssetIsMissingInRows } = getTypedTranslation();
  const typeName = getTypeOptionLabel(colConfig.default.type, colConfig.linkedAssetConfig?.assetType);

  const listItems: [string, React.ReactNode][] = [[t('Type'), typeName]];

  if (colConfig.linkedAssetConfig) {
    listItems.push([t('Linked asset folder'), colConfig.linkedAssetConfig.assetFolderName]);
  }

  if (colConfig.default.value !== '') {
    const defaultVal = colConfig.default.value;

    listItems.push([
      t('Default value'),
      typeof defaultVal === 'boolean'
        ? defaultVal.toString()
        : Array.isArray(defaultVal)
          ? `[${defaultVal.join(',')}]`
          : defaultVal,
    ]);
  }

  const isFolderError =
    colConfig.linkedAssetConfig &&
    errors.some(
      e => e.$type === AssetErrorTypes.MissingLinkedFolder && e.name === colConfig.linkedAssetConfig?.assetFolderName
    );
  const isDefaultAssetError =
    colConfig.linkedAssetConfig &&
    errors.some(
      e =>
        e.$type === AssetErrorTypes.MissingLinkedDefaultAsset &&
        e.folderName === colConfig.linkedAssetConfig?.assetFolderName &&
        e.assetName === colConfig.default.value
    );
  const dataSourceParseErrors = errors.filter(
    e => isDataSourceParseError(e) && e.columnName === colConfig.name
  ) as AssetDataSourceParseError[];
  const hasError = !!(isFolderError || isDefaultAssetError || dataSourceParseErrors.length);

  const missingLinkedAssetHints = hints.filter(
    h => h.$type === AssetHintTypes.MissingLinkedAsset && h.columnName === colConfig.name
  );
  const hasHint = missingLinkedAssetHints.length;

  if (isFolderError) {
    listItems.push([t('Error'), <span className="text-danger-main">{t('Linked asset folder is missing!')}</span>]);
  } else if (isDefaultAssetError) {
    listItems.push([t('Error'), <span className="text-danger-main">{t('Default asset is missing!')}</span>]);
  } else if (dataSourceParseErrors.length) {
    const issueRows = dataSourceParseErrors.map(issue => issue.row + 1);
    listItems.push([t('Error'), <span className="text-danger-main">{tDataSourceParseErrorsInRows(issueRows)}</span>]);
  } else if (missingLinkedAssetHints.length) {
    const issueRows = missingLinkedAssetHints.map(issue => issue.row + 1);
    listItems.push([t('Hint'), <span className="text-primary-main">{tLinkedAssetIsMissingInRows(issueRows)}</span>]);
  }

  return (
    <Tooltip
      interactive
      title={
        <TooltipContent
          header={colConfig.name}
          detail={
            <ul>
              {listItems.map(([title, value]) => (
                <li key={title} className="text-neutral-70">
                  {title}: <span className="text-s-medium text-neutral-100">{value}</span>
                </li>
              ))}
            </ul>
          }
        />
      }
    >
      <div className="flex items-center gap-2">
        {hasError ? (
          <Icon Svg={ErrorIcon} className="w-5 text-danger-main" />
        ) : hasHint ? (
          <Icon Svg={HintIcon} className="w-5 text-primary-main" />
        ) : (
          <></>
        )}
        <div>
          <span className="text-table-heading">{colConfig.name}</span>
          <br />
          <span className="text-table-heading-sub">{typeName}</span>
        </div>
      </div>
    </Tooltip>
  );
}
