import { GridFooterContainer, GridFooterContainerProps } from '@mui/x-data-grid-pro';
import React from 'react';

export type CustomFooterProps = GridFooterContainerProps & {
  fullContent?: React.ReactNode;
  actions?: React.ReactNode;
};

export const DataGridCustomFooter: React.FC<CustomFooterProps> = ({ fullContent, actions, ...containerProps }) => {
  return (
    <GridFooterContainer {...containerProps}>
      {fullContent ?? (
        <div className="mx-4 my-2 flex grow items-center justify-between">
          <div className="flex items-center gap-2">{actions}</div>
        </div>
      )}
    </GridFooterContainer>
  );
};
