import React, { useEffect, useState } from 'react';
import { PublishedCfgrIcon, StageIcon } from 'assets/icons';
import { HistoryMenuButton } from 'components/cfgrs/drafts/history-menu-button';
import { IdentityUser } from 'components/identity/identity-user';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { Icon } from 'controls/icon/icon';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'controls/table';
import { TableSkeletonRows } from 'controls/table/table-skeleton-rows';
import { cn, tw } from 'helper/css.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import {
  CfgrHistoryVersion,
  getConfiguratorHistoryGetFullHistory,
} from 'services/configuratorhistory/configuratorhistory.service';
import { selectSelectedConfigurator } from 'slices/company-data/company-data.slice';

type HistoryTableProps = {
  suspendLoad: boolean;
};

export const SuccessBadge: React.FC<{ text: string }> = ({ text }) => (
  <div className="rounded-full border border-success-border bg-success-surface px-2 py-0.5 text-success-pressed">
    {text}
  </div>
);

export const HistoryTable: React.FC<HistoryTableProps> = ({ suspendLoad }) => {
  const { t } = useTypedTranslation();
  const [hoveredVersionId, setHoveredVersionId] = useState<string>();
  const [isFetching, setIsFetching] = useState(true);
  const [cfgrHistory, setCfgrHistory] = useState<CfgrHistoryVersion[]>([]);
  const sortedCfgrHistory = [...cfgrHistory].sort((a, b) => b.publishedAt - a.publishedAt);

  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const companyId = selectedCfgr?.companyId || '';
  const cfgrId = selectedCfgr?.id || '';

  useEffect(() => {
    if (!companyId || !cfgrId || suspendLoad) {
      return;
    }

    const fetchHistory = async (): Promise<void> => {
      setIsFetching(true);
      setCfgrHistory([]);

      const versions = await getConfiguratorHistoryGetFullHistory(companyId, cfgrId);
      setCfgrHistory(versions);
      setIsFetching(false);
    };
    fetchHistory();
  }, [cfgrId, companyId, suspendLoad]);

  return (
    <>
      <Table data-cmptype="HistoryTable" stickyHeader containerClassName={tw`max-h-[500px] overflow-y-auto`}>
        <TableHead>
          <TableRow>
            <TableCell>{t('Draft name')}</TableCell>
            <TableCell>{t('Target')}</TableCell>
            <TableCell>{t('Published at')}</TableCell>
            <TableCell>{t('Version')}</TableCell>
            <TableCell>{t('Remarks')}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <>
            {isFetching && <TableSkeletonRows numberOfCols={5} />}

            {!isFetching &&
              sortedCfgrHistory.map(cfgrVersion => {
                const isCurrentLiveVersion = cfgrVersion.version === selectedCfgr?.version && selectedCfgr.isPublished;
                return (
                  <TableRow
                    key={cfgrVersion.version}
                    className="hover:bg-neutral-20"
                    onMouseEnter={(): void => {
                      setHoveredVersionId(cfgrVersion.version);
                    }}
                    onMouseLeave={(): void => {
                      setHoveredVersionId(undefined);
                    }}
                  >
                    <TableCell
                      className={cn(isCurrentLiveVersion && 'shadow-[inset_2px_0_0_0_theme(colors.success-main)]')}
                    >
                      <div className="flex items-center justify-between gap-2 text-m-medium text-neutral-100">
                        <div className="flex items-center gap-2">
                          {cfgrVersion.draftDisplayName}
                          {isCurrentLiveVersion && <SuccessBadge text={t('Live')} />}
                        </div>
                        <HistoryMenuButton
                          cfgrVersion={cfgrVersion}
                          visible={hoveredVersionId === cfgrVersion.version}
                        />
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className="flex gap-1">
                        <Icon
                          Svg={cfgrVersion.originStage ? StageIcon : PublishedCfgrIcon}
                          className="size-5 text-neutral-90"
                        />
                        {cfgrVersion.originStage ? t(`Stage "${cfgrVersion.originStage}"`) : t('Live version')}
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className="just-center flex whitespace-pre">
                        <RelativeDateTime unixTime={cfgrVersion.publishedAt} />
                        <span> {t('by')} </span>
                        <IdentityUser userId={cfgrVersion.publishedBy} />
                      </div>
                    </TableCell>

                    <TableCell>{cfgrVersion.customVersion}</TableCell>
                    <TableCell className="whitespace-pre-wrap">{cfgrVersion.devComment?.trim()}</TableCell>
                  </TableRow>
                );
              })}
          </>
        </TableBody>
      </Table>

      {cfgrHistory.length === 0 && !isFetching && (
        <div className="my-6 flex justify-center text-m-regular text-neutral-90">
          {t('There are no versions for this configurator yet')}
        </div>
      )}
    </>
  );
};
