import { getTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { AssignedCfgrVersions } from 'services/assets/assets.service';

type PublishConfigAction =
  | { type: 'INIT'; payload: { cfgrVersions: AssignedCfgrVersions; selectByDefault: boolean } }
  | { type: 'CHANGE_SELECTION'; payload: { select: boolean; idx: number } }
  | { type: 'CHANGE_SELECTION_FOR_ALL'; payload: boolean }
  | { type: 'CHANGE_AUTO_PUBLISH'; payload: { autoPublish: boolean; idx: number } }
  | { type: 'ADD_ERRONEOUS_CFGRS'; payload: string[] };

type PublishAssetBundleConfigEntry = {
  select: boolean;
  companyName: string;
  cfgrName: string;
  cfgrVersion: string;
  displayName: string;
  fullDisplayName: string;
  isDraft: boolean;
  autoPublish: boolean;
  hasError: boolean;
};
type PublishAssetBundleConfig = PublishAssetBundleConfigEntry[];

export function publishConfigReducer(
  state: PublishAssetBundleConfig,
  action: PublishConfigAction
): PublishAssetBundleConfig {
  switch (action.type) {
    case 'INIT': {
      const { t } = getTypedTranslation();

      // fill state with initial data from the dedicated server call
      const initState = action.payload.cfgrVersions.map(assignedDraft => ({
        select: action.payload.selectByDefault,
        companyName: assignedDraft.companyName,
        cfgrName: assignedDraft.cfgrName,
        cfgrVersion: assignedDraft.version,
        displayName: assignedDraft.displayName,
        // full display name is needed in multiple places, so it makes sense to create it already here
        fullDisplayName: assignedDraft.isDraft
          ? `${assignedDraft.cfgrName} - ${t('Draft')} "${assignedDraft.displayName}"`
          : assignedDraft.cfgrName,
        isDraft: assignedDraft.isDraft,
        autoPublish: !!assignedDraft.isReadOnly,
        hasError: false,
      }));

      return initState;
    }

    case 'CHANGE_SELECTION': {
      const newState = [...state];
      newState[action.payload.idx].select = action.payload.select;
      return newState;
    }

    case 'CHANGE_SELECTION_FOR_ALL': {
      const newState = state.map(configEntry => ({ ...configEntry, select: action.payload }));
      return newState;
    }

    case 'CHANGE_AUTO_PUBLISH': {
      const newState = [...state];
      newState[action.payload.idx].autoPublish = action.payload.autoPublish;
      return newState;
    }

    case 'ADD_ERRONEOUS_CFGRS': {
      const newState = state.map(configEntry => ({
        ...configEntry,
        // overwrite `hasError` property, if schema id of erroneous cfgrs fits
        hasError: configEntry.hasError || _fitsCfgrSchemaIds(configEntry, action.payload),
      }));
      return newState;
    }

    default:
      return state;
  }
}

/**
 * Help function for checking if the config entry fits any of the provided schema ids
 */
function _fitsCfgrSchemaIds(configEntry: PublishAssetBundleConfigEntry, schemaIds: string[]): boolean {
  const configEntrySchemaId = `${configEntry.companyName}.${configEntry.cfgrName}.${configEntry.cfgrVersion}`;
  const fitsSchemaId = schemaIds.includes(configEntrySchemaId);

  return fitsSchemaId;
}
