import { FailedAssetsInfo } from 'services/assets/assets.service';

type AssetDetailModalsErrorInfoProps = {
  errorInfo: FailedAssetsInfo;
};

export const AssetDetailModalsErrorInfo: React.FC<AssetDetailModalsErrorInfoProps> = ({ errorInfo }) => {
  return (
    <div data-cmptype="AssetDetailModalsErrorInfo">
      <h3 className="text-warning-main">Error</h3>
      <ul>
        {errorInfo.map(error => (
          <li key={error.name}>
            {error.name}: {error.failureReason}
          </li>
        ))}
      </ul>
    </div>
  );
};
