import { AssetBundleTypes } from 'generated/asset-bundle-types';
import { AssetErrorTypes } from 'generated/asset-error-types';
import { AssetHintTypes } from 'generated/asset-hint-types';
import { AssetTypes } from 'generated/asset-types';
import { AssetWarningTypes } from 'generated/asset-warning-types';
import { DataSourceColumnTypes } from 'generated/datasource-column-types';
import { DataSourceConfigTypes } from 'generated/datasource-config-types';
import { IdentityTypes } from 'generated/identity-types';
import {
  assetPathToKey,
  createSourceStringFromFolderAndAssetId,
  getAssetParentFolderPath,
} from 'helper/assets/assets.helper';
import { HttpStatusCode } from 'helper/http/http-status.helper';
import { abortableRequest } from 'helper/http/http.helper';
import { getTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { MaterialObject, MaterialType } from 'services/3d/materials.service';
import { LockStatus } from 'services/company-data/company-data.service';
import * as HttpService from 'services/http/http.service';
import {
  Asset,
  AssetBundleVersion,
  AssetBundleVersionCommon,
  AssetBundleVersionData,
  AssetBundleVersions,
  AssetBundles,
  AssetLink,
  AssetPath,
  AssetShortPath,
  Assets,
  isBabylonAsset,
  isDataSourceAsset,
  isFileAsset,
  isFolderAsset,
  isImageAsset,
  isMaterialAsset,
  isTextAsset,
} from 'slices/assets/assets.slice';

export type AssetBundlesResponse = {
  name: string;
  displayName: string;
  hasPublishedVersions: boolean;
  hasLockedVersions: boolean;
}[];

type AssetBundleVersionResponseCommon = {
  $type?: AssetBundleTypes;
  assetBundleName: string;
  version: string;
  basedOnVersion: string;
  displayName: string;
  deleteAfter?: string;
  lockStatus?: LockStatus;
};
type AssetBundleVersionDraftResponse = AssetBundleVersionResponseCommon & {
  createdBy: string;
  createdAt: number;
  lastEditedBy: string;
  lastEditedAt: number;
};
type AssetBundleVersionPublishedResponse = AssetBundleVersionResponseCommon & {
  publishedBy: string;
  publishedAt: number;
};
type AssetBundleVersionResponse = AssetBundleVersionDraftResponse | AssetBundleVersionPublishedResponse;

export type RelevantAssetBundleVersionsResponse = {
  assetBundleVersion: AssetBundleVersionResponse;
  // currently unused
  assignedPublishedCfgrNames?: string[];
}[];

type PagedAssetBundleVersionsResponse = {
  continuationToken: string;
  value: AssetBundleVersionResponse[];
};

type AssignedCfgrVersionsResponse = {
  companyName: string;
  cfgrName: string;
  version: string;
  displayName: string;
  isDraft: boolean;
  isReadOnly: boolean | null;
}[];

export type AssetBundleVersionDataResponse = {
  version: AssetBundleVersionResponse;
  // content is not available if bundle version is locked
  content?: {
    assets: Record<string, AssetInfoResponse>;
    links: Record<string, AssetLinksInfoResponse>;
  };
};

export type AssignedCfgrVersions = AssignedCfgrVersionsResponse;

export type CfgrsToUpdateAfterPublish = {
  companyName: string;
  cfgrName: string;
  cfgrVersion: string;
  autoPublish: boolean;
}[];

export type AssetBundlePublishResponse = {
  assetBundleVersion: string;
  failedConfiguratorUpdates?: string[];
};

export type DataSourceColumnDefaultValue = boolean | number | string | [] | null;

export type DataSourceColumnConfigDto = {
  index?: number;
  name: string;
  default: {
    type: DataSourceColumnTypes;
    value: DataSourceColumnDefaultValue;
  };
  linkedAssetConfig?: {
    assetType: AssetTypes;
    assetFolderName: string;
  };
};

export type DataSourceDefaultsConfigDto = {
  strictReadMode: boolean;
  listSeparator: string;
  boolTrueValues: string[];
  boolFalseValues: string[];
};

export type DataSourceConfigDtoBase = {
  type: DataSourceConfigTypes;
  defaults: DataSourceDefaultsConfigDto;
  columnConfigs: DataSourceColumnConfigDto[];
};

export type CsvDataSourceConfigDto = DataSourceConfigDtoBase & {
  locale: string;
  separator: string;
  skipFirstRow: boolean;
};

export type ExcelDataSourceConfigDto = DataSourceConfigDtoBase & {
  sheetName: string;
  headerRow?: number;
  startRow?: number;
};

export type ProtobufSourceConfigDto = {
  columnConfigs: {
    field: number;
    name: string;
    default: {
      type: DataSourceColumnTypes;
      value: any;
    };
    linkedAssetConfig: {
      assetType: AssetTypes;
      assetFolderName: string;
    };
  }[];
  type: DataSourceConfigTypes;
};

// Combined type for CSV and Excel data source configs
export type DataSourceConfigDto = CsvDataSourceConfigDto | ExcelDataSourceConfigDto;

type TableRowDto = unknown[];
export type DataSourceContentDto = { config: DataSourceConfigDto; rows: TableRowDto[] };

export type BabylonNodeTagAssignmentsDTO = { [nodeName: string]: string[] };

type IAssetDataSourceParseErrorDto = {
  $type:
    | AssetErrorTypes.InvalidDataSourceValue
    | AssetErrorTypes.EmptyDataSourceListValue
    | AssetErrorTypes.InvalidDataSourceListValue;
  columnName: string;
  row: number;
  rawValue: string;
};
type IAssetErrorDto =
  | {
      $type: AssetErrorTypes.MissingLinkedFolder;
      name: string;
    }
  | {
      $type: AssetErrorTypes.MissingLinkedDefaultAsset;
      folderName: string;
      assetName: string;
    }
  | IAssetDataSourceParseErrorDto;
type IAssetWarningDto = {
  $type: AssetWarningTypes;
  name: string;
};
type IMissingLinkedAssetHintDto = {
  $type: AssetHintTypes.MissingLinkedAsset;
  columnName: string;
  row: number;
  folderName: string;
  assetName: string;
};
type IAssetHintDto = IMissingLinkedAssetHintDto;
type IAssetErrorBagDto = {
  items: IAssetErrorDto[];
  totalItems: number;
};
type IAssetWarningBagDto = {
  items: IAssetWarningDto[];
  totalItems: number;
};
type IAssetHintBagDto = {
  items: IAssetHintDto[];
  totalItems: number;
};

export type AssetDataSourceParseError = IAssetDataSourceParseErrorDto;
export type AssetError = IAssetErrorDto;
export type AssetWarning = IAssetWarningDto;
export type MissingLinkedAssetHint = IMissingLinkedAssetHintDto;
export type AssetHint = IAssetHintDto;
export type AssetErrorBag = IAssetErrorBagDto;
export type AssetWarningBag = IAssetWarningBagDto;
export type AssetHintBag = IAssetHintBagDto;

export function isDataSourceParseError(error: AssetError): error is AssetDataSourceParseError {
  return (
    error.$type === AssetErrorTypes.InvalidDataSourceValue ||
    error.$type === AssetErrorTypes.EmptyDataSourceListValue ||
    error.$type === AssetErrorTypes.InvalidDataSourceListValue
  );
}

export type AssetInfoResponse = {
  type: AssetTypes;
  errorBag?: IAssetErrorBagDto;
  warningBag?: IAssetWarningBagDto;
  hintBag?: IAssetHintBagDto;
  lastEditedAt?: number;
  lastEditedBy?: string;

  // All except folder
  size?: number;

  // All except folder and Materials
  url: string;

  // DataSource
  dataSourceConfig?: ProtobufSourceConfigDto;
  importConfig?: DataSourceConfigDto;
  rowCount?: number;

  // Images
  imageType: number;
  width: number;
  height: number;

  // Folders
  assets: Record<string, AssetInfoResponse>;

  // Babylon
  tags: BabylonNodeTagAssignmentsDTO;

  // Material
  materialType: MaterialType;
};

export type AssetLinksInfoResponse = AssetLink[];

export type AssetsInitiateResponse = Record<string, string>;
export type AssetUploadUris = Record<string, URL>;

export type CompleteAssetDto = {
  assetName: string;
  fileExtension: string;
  folderName: string;
  overwrite: boolean;
  rawFileId: string;
  assetType: AssetTypes;
};

export type FailedAssetsInfo = {
  folderName: string;
  name: string;
  failureReason: string;
}[];

export type AssetsCompleteResponse = { success: boolean; failedAssets: FailedAssetsInfo };

export type RelocateAssetRequestItems = {
  sourceFolder: string;
  targetFolder: string;
  sourceName: string;
  targetName: string;
  overwrite: boolean;
};

export type DeleteAssetRequestItems = {
  assetName: string;
  folderName: string;
};

export type AssetBundleAssignmentDto = {
  configuratorVersion: string;
  aliasName: string;
  displayName: string;
  assetBundleName: string;
  version: string;
  order: number;
  assignedBy: string;
  assignedAt: string;
  assetBundleDeleted: boolean;
  assetBundleIsDraft: boolean;
};

export type AssetBundleAssignment = Omit<AssetBundleAssignmentDto, 'configuratorVersion'> & {
  companyId: string;
  cfgrId: string;
  cfgrVersion: string;
};

export type EmptyAssetItem = {
  assetName: string;
  folderName: string;
  fileExtension: string;
  assetType: AssetTypes;
};

export type GetMaterialItem = {
  folderName: string;
  assetName: string;
};

export type BabylonNodeUpdateData = {
  materialId?: string;
  tags?: string[];
};
export type BabylonNodeUpdateDataMap = { [nodeName: string]: BabylonNodeUpdateData };

interface UploadProgressCallback {
  (percentage: number): void;
}

// Type predicates to evaluate exact type of DataSourceConfigDto (CSV or Excel)
export function isCsvDataSourceConfigDto(config: DataSourceConfigDto): config is CsvDataSourceConfigDto {
  return config.type === DataSourceConfigTypes.Csv;
}
export function isExcelDataSourceConfigDto(config: DataSourceConfigDto): config is ExcelDataSourceConfigDto {
  return config.type === DataSourceConfigTypes.Excel;
}

function _isAssetBundleVersionDraftResponse(
  version: AssetBundleVersionResponse
): version is AssetBundleVersionDraftResponse {
  return (version as AssetBundleVersionDraftResponse).createdAt !== undefined;
}

/**
 * Get bundles for a certain company.\
 * Only the name strings are returned, for detailed information about the bundle versions or assets additional requests
 * have to be executed.
 */
export async function fetchAssetBundles(companyId: string): Promise<AssetBundles> {
  const { url, params } = HttpService.endpoints.assetbundlesGetAllBundlesForCompany(companyId);
  const res = await HttpService.httpClient.get<AssetBundlesResponse>(url, { params });
  const assetBundles = _assetBundlesResponseToAssetBundles(companyId, res.data);

  return assetBundles;
}

/**
 * Get "relevant" bundle versions for a certain bundle.
 * This is just a subset of all bundles versions, as the number of versions can become very large, especially with
 * automated bundle creation (via workflows).
 * Data don't contain the individual assets yet => use `fetchAssets` for that.
 */
export async function fetchRelevantAssetBundleVersions(
  companyId: string,
  bundleId: string,
  limit: number
): Promise<AssetBundleVersions> {
  const { url, params } = HttpService.endpoints.assetbundlesGetRelevantVersionsForBundle(companyId, bundleId, limit);
  const res = await HttpService.httpClient.get<RelevantAssetBundleVersionsResponse>(url, { params });
  const assetBundleVersionsResponse = res.data.map(entry => entry.assetBundleVersion);
  const assetBundleVersions = _assetBundleVersionsRespToAssetBundleVersions(
    companyId,
    bundleId,
    assetBundleVersionsResponse
  );

  return assetBundleVersions;
}

/**
 * Get certain amount of published asset bundle versions, starting from a defined position (continuationToken).
 * This call can be used for implementing a paged solution for iterating through all published versions.
 */
export async function fetchPublishedAssetBundleVersions(
  companyId: string,
  bundleId: string,
  continuationToken?: string,
  pageSize?: number,
  publishedByType?: IdentityTypes,
  publishedBy?: string
): Promise<{ assetBundleVersions: AssetBundleVersions; continuationToken: string | null }> {
  const { url, params } = HttpService.endpoints.assetbundlesGetAllPublishedVersionsForBundle(
    companyId,
    bundleId,
    continuationToken,
    pageSize,
    publishedByType,
    publishedBy
  );
  const res = await HttpService.httpClient.post<PagedAssetBundleVersionsResponse>(url, params);
  const assetBundleVersions = _assetBundleVersionsRespToAssetBundleVersions(companyId, bundleId, res.data.value);

  return { assetBundleVersions, continuationToken: res.data.continuationToken };
}

/**
 * Get certain amount of draft asset bundle versions, starting from a defined position (continuationToken).
 * This call can be used for implementing a paged solution for iterating through all draft versions.
 */
export async function fetchDraftAssetBundleVersions(
  companyId: string,
  bundleId: string,
  continuationToken?: string,
  pageSize?: number,
  createdByType?: IdentityTypes,
  createdBy?: string
): Promise<{ assetBundleVersions: AssetBundleVersions; continuationToken: string | null }> {
  const { url, params } = HttpService.endpoints.assetbundlesGetAllDraftVersionsForBundle(
    companyId,
    bundleId,
    continuationToken,
    pageSize,
    createdByType,
    createdBy
  );
  const res = await HttpService.httpClient.post<PagedAssetBundleVersionsResponse>(url, params);
  const assetBundleVersions = _assetBundleVersionsRespToAssetBundleVersions(companyId, bundleId, res.data.value);

  return { assetBundleVersions, continuationToken: res.data.continuationToken };
}

/**
 * Gets bundle version data (displayName, lockStatus, etc...) of a certain bundle version AND assets & asset links of
 * this bundle version.
 * Getting dedicated bundle version data is required if a requested bundle version is not available in the relevant
 * bundles data (see `fetchRelevantAssetBundleVersions`)
 */
export async function fetchBundleVersionData(
  companyId: string,
  bundleId: string,
  bundleVersion: string
): Promise<AssetBundleVersionData | null> {
  const { url, params } = HttpService.endpoints.assetbundlesGetVersion(companyId, bundleId, bundleVersion);

  const res = await HttpService.httpClient.get<AssetBundleVersionDataResponse>(url, {
    params,
    // it's ok if this call fails, as it is also used for checking if a certain bundle version is available
    // in this case we don't want to throw, but rather return a falsy value
    validateStatus: () => true,
  });
  if (res.status === HttpStatusCode.Ok_200) {
    const assetBundleVersionData = _assetBundleVersionDataRespToAssetBundleVersionData(
      companyId,
      bundleId,
      bundleVersion,
      res.data
    );

    return assetBundleVersionData;
  } else {
    return null;
  }
}

/**
 * Get all configurators and drafts that are assigned to a certain bundle version
 */
export async function fetchCfgrVersionsAssignedToBundleDraft(
  companyId: string,
  assetBundleId: string,
  assetBundleVersion: string
): Promise<AssignedCfgrVersions> {
  const { url, params } = HttpService.endpoints.assetbundlesGetCfgrVersionsAssignedToBundleDraft(
    companyId,
    assetBundleId,
    assetBundleVersion
  );
  const res = await HttpService.httpClient.get<AssignedCfgrVersionsResponse>(url, { params });

  // ATM no further conversion
  return res.data;
}

/**
 * Creates an empty bundle draft.\
 * If the "parent bundle" doesn't exist yet, a new one will be created as well.
 */
export async function createNewAssetBundleDraft(
  companyId: string,
  assetBundleId: string,
  bundleDisplayName: string,
  draftDisplayName: string
): Promise<string> {
  const res = await HttpService.httpClient.post(HttpService.endpoints.createEmptyAssetBundleDraft, {
    companyName: companyId,
    assetBundleName: assetBundleId,
    bundleDisplayName: bundleDisplayName,
    draftDisplayName: draftDisplayName,
  });

  return res.data;
}

/**
 * Creates a draft from an existing version
 */
export async function createAssetBundleDraftFrom(
  companyId: string,
  assetBundleId: string,
  assetBundleVersion: string,
  draftDisplayName: string
): Promise<string> {
  const res = await HttpService.httpClient.post(HttpService.endpoints.createAssetBundleDraftFrom, {
    companyName: companyId,
    assetBundleName: assetBundleId,
    assetBundleVersion: assetBundleVersion,
    draftDisplayName: draftDisplayName,
  });

  return res.data;
}

/**
 * Deletes a whole asset bundle.\
 * General deletion strategy is not 100% clear yet, so this call will mostly likely not do to much in the first
 * instance.
 */
export async function deleteAssetBundle(companyId: string, assetBundleId: string): Promise<void> {
  await HttpService.httpClient.delete(HttpService.endpoints.deleteAssetBundles, {
    headers: { 'Content-Type': 'application/json' },
    data: {
      companyName: companyId,
      assetBundleName: assetBundleId,
    },
  });
}

/**
 * Deletes the asset version by setting a delete date.
 */
export async function deleteAssetBundleVersion(
  companyId: string,
  assetBundleId: string,
  assetBundleVersion: string
): Promise<void> {
  await HttpService.httpClient.delete(HttpService.endpoints.deleteAssetBundleVersion, {
    data: {
      companyName: companyId,
      assetBundleName: assetBundleId,
      assetBundleVersion: assetBundleVersion,
    },
  });
}

/**
 * "Publish" the draft by converting the draft into a version
 */
export async function publishAssetBundleDraft(
  companyId: string,
  assetBundleId: string,
  assetBundleVersion: string,
  configuratorsToUpdate: CfgrsToUpdateAfterPublish
): Promise<AssetBundlePublishResponse> {
  const res = await HttpService.httpClient.post(HttpService.endpoints.publishAssetBundleDraft, {
    companyName: companyId,
    assetBundleName: assetBundleId,
    assetBundleVersion: assetBundleVersion,
    configuratorsToUpdate: configuratorsToUpdate,
  });

  return res.data;
}

function _assetBundlesResponseToAssetBundles(companyId: string, resp: AssetBundlesResponse): AssetBundles {
  const assetBundles = resp.reduce<AssetBundles>((acc, bundleResp) => {
    acc[bundleResp.name] = {
      id: bundleResp.name,
      companyId,
      name: bundleResp.name,
      displayName: bundleResp.displayName,
      hasPublishedVersions: bundleResp.hasPublishedVersions,
      hasLockedVersions: bundleResp.hasLockedVersions,
    };
    return acc;
  }, {});

  return assetBundles;
}

function _assetBundleVersionRespToAssetBundleVersion(
  companyId: string,
  bundleId: string,
  resp: AssetBundleVersionResponse
): AssetBundleVersion {
  const bundleVersionCommon: AssetBundleVersionCommon = {
    id: resp.version,
    companyId,
    bundleId,
    version: resp.version,
    isDraft: resp.$type === AssetBundleTypes.Draft,
    displayName: resp.displayName,
    deleteAfter: resp.deleteAfter,
    lockStatus: resp.lockStatus,
    basedOnVersion: resp.basedOnVersion,
  };

  if (_isAssetBundleVersionDraftResponse(resp)) {
    return {
      ...bundleVersionCommon,
      createdBy: resp.createdBy,
      createdAt: resp.createdAt,
      lastEditedBy: resp.lastEditedBy,
      lastEditedAt: resp.lastEditedAt,
    };
  } else {
    return {
      ...bundleVersionCommon,
      publishedBy: resp.publishedBy,
      publishedAt: resp.publishedAt,
    };
  }
}

/**
 * Conversion function from raw HTTP response to asset bundle version state object.\
 * Currently the data is more or less just forwarded.
 */
function _assetBundleVersionsRespToAssetBundleVersions(
  companyId: string,
  bundleId: string,
  resp: AssetBundleVersionResponse[]
): AssetBundleVersions {
  const assetBundleVersions = resp.reduce<AssetBundleVersions>((acc, version) => {
    acc[version.version] = _assetBundleVersionRespToAssetBundleVersion(companyId, bundleId, version);
    return acc;
  }, {});

  return assetBundleVersions;
}

/**
 * Conversion function from raw HTTP response to asset object.\
 * Currently only the name is taken into account, but this will be changed if some more asset functionality will be
 * introduced.
 */
function _assetBundleVersionDataRespToAssetBundleVersionData(
  companyId: string,
  bundleId: string,
  bundleVersion: string,
  resp: AssetBundleVersionDataResponse
): AssetBundleVersionData {
  const convertInfoResponseRecursively = (assets: Record<string, AssetInfoResponse>, folderId: string): Assets =>
    Object.keys(assets).reduce<Assets>((acc, assetId) => {
      const assetResponseInfo = assets[assetId];
      const path: AssetPath = {
        companyId,
        bundleId,
        bundleVersion,
        folderId,
        assetId,
      };
      const key = assetPathToKey(path);

      const asset: Asset = {
        path: path,
        type: assetResponseInfo.type,
        uploadState: 'FromServer',
        existsOnServer: true,
        errorBag: assetResponseInfo.errorBag ?? { items: [], totalItems: 0 },
        warningBag: assetResponseInfo.warningBag ?? { items: [], totalItems: 0 },
        hintBag: assetResponseInfo.hintBag ?? { items: [], totalItems: 0 },
        lastEditedAt: assetResponseInfo.lastEditedAt,
        lastEditedBy: assetResponseInfo.lastEditedBy,
      };

      if (folderId) {
        // add path to parent folder if this is a child asset
        const parentFolderPath = getAssetParentFolderPath(path);
        asset.parentFolderKey = assetPathToKey(parentFolderPath);
      }

      if (isDataSourceAsset(asset)) {
        if (assetResponseInfo.dataSourceConfig) {
          asset.config = assetResponseInfo.dataSourceConfig;
        }
        if (assetResponseInfo.importConfig) {
          asset.importConfig = assetResponseInfo.importConfig;
        }
        if (assetResponseInfo.rowCount !== undefined) {
          asset.rowCount = assetResponseInfo.rowCount;
        }

        asset.size = assetResponseInfo.size ?? 0;
      } else if (isImageAsset(asset)) {
        asset.imageType = assetResponseInfo.imageType;
        asset.height = assetResponseInfo.height;
        asset.width = assetResponseInfo.width;
        asset.size = assetResponseInfo.size ?? 0;
        asset.url = assetResponseInfo.url;
      } else if (isFileAsset(asset)) {
        asset.url = assetResponseInfo.url;
        asset.size = assetResponseInfo.size ?? 0;
      } else if (isTextAsset(asset)) {
        asset.url = assetResponseInfo.url;
        asset.size = assetResponseInfo.size ?? 0;
      } else if (isBabylonAsset(asset)) {
        asset.url = assetResponseInfo.url;
        asset.size = assetResponseInfo.size ?? 0;
        asset.tagAssignments = assetResponseInfo.tags;
      } else if (isMaterialAsset(asset)) {
        asset.size = assetResponseInfo.size ?? 0;
        asset.materialType = assetResponseInfo.materialType;
      } else if (isFolderAsset(asset)) {
        asset.childKeys = [];

        // add all child assets in the flat asset map
        Object.keys(assetResponseInfo.assets).forEach(childId => {
          const childKey = assetPathToKey({
            companyId,
            bundleId,
            bundleVersion,
            // consider current folder name for the creation of the dedicated key to the child asset
            folderId: assetId,
            assetId: childId,
          });

          asset.childKeys.push(childKey);
        });

        acc = {
          ...acc,
          ...convertInfoResponseRecursively(assetResponseInfo.assets, assetId),
        };
      }

      acc[key] = asset;

      return acc;
    }, {});

  const assets = resp.content ? convertInfoResponseRecursively(resp.content.assets, '') : {};

  // currently no asset link conversion, just forward them as they are
  const assetLinks = resp.content?.links ?? {};

  const bundleVersionInfo = _assetBundleVersionRespToAssetBundleVersion(companyId, bundleId, resp.version);

  return { assets, assetLinks, bundleVersionInfo };
}

function _assetsInitiateResponseToUploadUris(resp: AssetsInitiateResponse): AssetUploadUris {
  return Object.keys(resp).reduce<AssetUploadUris>((acc, key) => {
    acc[key] = new URL(resp[key]);
    return acc;
  }, {});
}

export async function createAssetUploadUris(companyId: string, numOfAssets: number): Promise<AssetUploadUris> {
  const { t } = getTypedTranslation();
  const { url, params } = HttpService.endpoints.assetsInitiate(companyId, numOfAssets);

  const res = await HttpService.httpClient.post<AssetsInitiateResponse>(url, params);

  if (Object.keys(res.data).length !== numOfAssets) {
    throw new Error(t('Invalid number or asset uris returned by server'));
  }

  return _assetsInitiateResponseToUploadUris(res.data);
}

export async function uploadAssetFile(
  sasUri: URL,
  file: File,
  onUploadProgess?: UploadProgressCallback
): Promise<void> {
  const fileBuffer = await file.arrayBuffer();
  await HttpService.httpClient.put(sasUri.toString(), fileBuffer, {
    headers: {
      'x-ms-blob-type': 'BlockBlob',
      'x-ms-blob-content-type': file.type,
    },
    onUploadProgress: (progEv: ProgressEvent) => {
      // round one digit
      const percentage = Math.round((progEv.loaded / progEv.total) * 1000) / 10;
      onUploadProgess?.(percentage);
    },
  });
}

export async function postAssetsComplete(
  companyId: string,
  bundleId: string,
  bundleVersion: string,
  items: CompleteAssetDto[]
): Promise<AssetsCompleteResponse> {
  const { url, params } = HttpService.endpoints.assetsComplete(companyId, bundleId, bundleVersion, items);
  const result = await HttpService.httpClient.post<FailedAssetsInfo>(url, params);
  const failedAssets = (result.data as FailedAssetsInfo).filter(a => a.failureReason);
  return { success: failedAssets.length === 0, failedAssets: failedAssets };
}

export async function postCreateFolderAsset(
  companyId: string,
  bundleId: string,
  bundleVersion: string,
  folderNames: string[]
): Promise<AssetsCompleteResponse> {
  const { url, params } = HttpService.endpoints.assetsCreateFolder(companyId, bundleId, bundleVersion, folderNames);
  const result = await HttpService.httpClient.post<FailedAssetsInfo>(url, params);
  return { success: result.data.length === 0, failedAssets: result.data };
}

export async function postAssetMove(
  companyId: string,
  bundleId: string,
  bundleVersion: string,
  moveItems: RelocateAssetRequestItems[]
): Promise<AssetsCompleteResponse> {
  const { url, params } = HttpService.endpoints.assetsMove(companyId, bundleId, bundleVersion, moveItems);
  const result = await HttpService.httpClient.post<FailedAssetsInfo>(url, params);
  return { success: result.data.length === 0, failedAssets: result.data };
}

export async function postAssetCopy(
  companyId: string,
  bundleId: string,
  bundleVersion: string,
  copyItems: RelocateAssetRequestItems[]
): Promise<AssetsCompleteResponse> {
  const { url, params } = HttpService.endpoints.assetsCopy(companyId, bundleId, bundleVersion, copyItems);
  const result = await HttpService.httpClient.post<FailedAssetsInfo>(url, params);
  return { success: result.data.length === 0, failedAssets: result.data };
}

export async function postDeleteAsset(
  companyId: string,
  bundleId: string,
  bundleVersion: string,
  deleteItems: DeleteAssetRequestItems[]
): Promise<AssetsCompleteResponse> {
  const { url, params } = HttpService.endpoints.assetsDelete(companyId, bundleId, bundleVersion, deleteItems);
  const result = await HttpService.httpClient.post<FailedAssetsInfo>(url, params);
  return { success: result.data.length === 0, failedAssets: result.data };
}

export async function postAssetChangeCsvDataSource(
  companyId: string,
  bundleId: string,
  bundleVersion: string,
  folderName: string,
  assetId: string,
  config: CsvDataSourceConfigDto
): Promise<boolean> {
  const { url, params } = HttpService.endpoints.assetsChangeCsvDataSource(
    companyId,
    bundleId,
    bundleVersion,
    folderName,
    assetId,
    config
  );
  const result = await HttpService.httpClient.post(url, params);
  return result.status === HttpStatusCode.Ok_200;
}

/**
 * Receive JSON content for a certain material.
 * The material data is not downloaded from the server directly, but instead the server provides a SAS uri from where
 * the client can access the data.
 * NOTE: Theoretically the SAS uri can be stored inside the material asset, so that it doesn't always has to be
 * re-fetched, as long as it is not expired. But that would complicate things quite a bit, whereas the speed gain for
 * switching materials in the editor doesn't seem to be worth it IMHO.
 */
export async function fetchAssetMaterialContent(
  companyId: string,
  bundleId: string,
  bundleVersion: string,
  folderName: string,
  assetId: string
): Promise<MaterialObject> {
  const sasUris = await fetchSasUrisOfMaterials(companyId, bundleId, bundleVersion, [
    { folderId: folderName, assetId: assetId },
  ]);

  const dottedPath = createSourceStringFromFolderAndAssetId(folderName, assetId);
  // dotted path entry should definitely be available in the result json, as it fits the input params of this function
  // therefore no error handling should be needed
  const sasUri = sasUris[dottedPath];

  // url may still be `undefined` when executing tests
  const res = await HttpService.httpClient.get<MaterialObject>(sasUri ?? '');
  return res.data;
}

export async function fetchSasUrisOfMaterials(
  companyId: string,
  bundleId: string,
  bundleVersion: string,
  materialPaths: AssetShortPath[]
): Promise<{ [key: string]: string }> {
  const items: GetMaterialItem[] = materialPaths.map(path => ({
    folderName: path.folderId,
    assetName: path.assetId,
  }));
  const { url, params } = HttpService.endpoints.assetsGetMaterialContents(companyId, bundleId, bundleVersion, items);
  const sasUriResult = await HttpService.httpClient.post<{ [key: string]: string }>(url, { ...params });

  return sasUriResult.data;
}

export async function postAssetMaterialContent(
  companyId: string,
  bundleId: string,
  bundleVersion: string,
  folderName: string,
  assetId: string,
  content: object
): Promise<boolean> {
  const { url, params } = HttpService.endpoints.assetsChangeMaterialContent(
    companyId,
    bundleId,
    bundleVersion,
    folderName,
    assetId,
    JSON.stringify(content)
  );
  const result = await HttpService.httpClient.post(url, params);
  return result.status === HttpStatusCode.Ok_200;
}

export async function postAssetChangeBabylonJsNodes(
  companyId: string,
  assetBundleId: string,
  assetBundleVersion: string,
  folderId: string,
  assetId: string,
  nodes: BabylonNodeUpdateDataMap
): Promise<boolean> {
  const { url, params } = HttpService.endpoints.assetsChangeBabylonJsNodes(
    companyId,
    assetBundleId,
    assetBundleVersion,
    folderId,
    assetId,
    nodes
  );
  const result = await HttpService.httpClient.post(url, params);
  return result.status === HttpStatusCode.Ok_200;
}

export async function postAssetChangeExcelDataSource(
  companyId: string,
  bundleId: string,
  bundleVersion: string,
  folderName: string,
  assetId: string,
  config: ExcelDataSourceConfigDto,
  useConfigAutoDetection: boolean
): Promise<boolean> {
  const { url, params } = HttpService.endpoints.assetsChangeExcelDataSource(
    companyId,
    bundleId,
    bundleVersion,
    folderName,
    assetId,
    config,
    useConfigAutoDetection
  );
  const result = await HttpService.httpClient.post(url, params);
  return result.status === HttpStatusCode.Ok_200;
}

export async function postAssetLinksBatchLinkMaterialFromImage(
  companyId: string,
  bundleId: string,
  bundleVersion: string,
  source: string,
  assetsToLink: AssetLink[],
  assetsToUnlink: AssetLink[]
): Promise<boolean> {
  const { url, params } = HttpService.endpoints.assetLinksBatchLinkMaterialToImage(
    companyId,
    bundleId,
    bundleVersion,
    source,
    assetsToLink,
    assetsToUnlink
  );
  const result = await HttpService.httpClient.post(url, params);
  return result.status === HttpStatusCode.Ok_200;
}

export async function postAssetsCreateExcelDataSourceBasedOnFileAsset(
  companyId: string,
  bundleId: string,
  bundleVersion: string,
  sourceAsset: string,
  targetAsset: string
): Promise<boolean> {
  const { url, params } = HttpService.endpoints.assetsCreateExcelDataSourceBasedOnFileAsset(
    companyId,
    bundleId,
    bundleVersion,
    sourceAsset,
    targetAsset
  );
  const result = await HttpService.httpClient.post(url, params);
  return result.status === HttpStatusCode.Ok_200;
}

export async function fetchAssignedAssetBundles(
  companyId: string,
  cfgrId: string,
  cfgrVersion?: string
): Promise<AssetBundleAssignment[]> {
  const { url, params } = HttpService.endpoints.assetbundlesGetBundleVersionsAssignedToCfgr(
    companyId,
    cfgrId,
    cfgrVersion
  );

  const res = await HttpService.httpClient.get<AssetBundleAssignmentDto[]>(url, { params });
  const result = _bundleAssignmentDtoToAssetBundleAssignment(companyId, cfgrId, res.data);
  return result;
}

function _bundleAssignmentDtoToAssetBundleAssignment(
  companyId: string,
  cfgrId: string,
  resp: AssetBundleAssignmentDto[]
): AssetBundleAssignment[] {
  return resp.map<AssetBundleAssignment>(assignmentDto => {
    const { configuratorVersion, ...rest } = assignmentDto;

    return {
      ...rest,
      companyId,
      cfgrId,
      cfgrVersion: configuratorVersion,
    };
  });
}

export async function postAssetsChangeTextContent(
  companyId: string,
  assetBundleId: string,
  assetBundleVersion: string,
  folderName: string,
  assetId: string,
  content: string
): Promise<void> {
  const { url, params } = HttpService.endpoints.assetsChangeTextContent(
    companyId,
    assetBundleId,
    assetBundleVersion,
    folderName,
    assetId,
    content
  );

  await HttpService.httpClient.post(url, params);
}

export async function postAssetsCreateEmpty(
  companyId: string,
  assetBundleId: string,
  assetBundleVersion: string,
  emptyAssetItems: EmptyAssetItem[]
): Promise<void> {
  const { url, params } = HttpService.endpoints.assetsCreateEmpty(
    companyId,
    assetBundleId,
    assetBundleVersion,
    emptyAssetItems
  );

  await HttpService.httpClient.post(url, params);
}

export async function postAssetsGetDataSourceContent(
  companyId: string,
  assetBundleId: string,
  assetBundleVersion: string,
  assetFolderName: string,
  assetName: string,
  startRow: number,
  endRow: number,
  abortSignal?: AbortSignal
): Promise<DataSourceContentDto | null> {
  const { url, params } = HttpService.endpoints.getDataSourceContent(
    companyId,
    assetBundleId,
    assetBundleVersion,
    assetFolderName,
    assetName,
    startRow,
    endRow
  );

  const response = await abortableRequest(
    HttpService.httpClient.post<DataSourceContentDto>(url, params, { signal: abortSignal }),
    abortSignal
  );
  return response;
}

export async function postAssetsRequestDownloadSharedAccessForAsset(
  companyId: string,
  assetBundleId: string,
  assetBundleVersion: string,
  assetFolderName: string,
  assetName: string,
  friendlyFileName: string
): Promise<string> {
  const { url, params } = HttpService.endpoints.requestDownloadSharedAccessForAsset(
    companyId,
    assetBundleId,
    assetBundleVersion,
    assetFolderName,
    assetName,
    friendlyFileName
  );

  const response = await HttpService.httpClient.post<string>(url, params);
  return response.data;
}
