import { configureStore } from '@reduxjs/toolkit';
import * as HttpService from 'services/http/http.service';
import loggerMiddleware from 'services/store/logger.middleware';
import persistenceMiddleware from 'services/store/persistence.middleware';
import assetReducer from 'slices/assets/assets.slice';
import authReducer from 'slices/auth/auth.slice';
import babylonAssetReducer from 'slices/babylon-asset/babylon-asset.slice';
import cfgnInsightsReducer from 'slices/cfgn-insisghts/cfgn-insights.slice';
import cfgrWhitelistDomainsReducer from 'slices/cfgr-whitelist-domains.slice';
import companyDataReducer from 'slices/company-data/company-data.slice';
import dashboardReducer from 'slices/dashboard/dashboard.slice';
import debugReducer, { debugActions } from 'slices/debug/debug.slice';
import httpReducer from 'slices/http/http.slice';
import identityReducer from 'slices/identity/identity.slice';
import materialAssetReducer from 'slices/material-asset/material-asset.slice';
import notificationsReducer from 'slices/notifications/notifications.slice';
import permissionReducer from 'slices/permission/permission.slice';
import replicatorReducer from 'slices/replicator/replicator.slice';
import workflowsReducer from 'slices/workflows/workflows.slice';

const rootReducer = {
  auth: authReducer,
  dashboard: dashboardReducer,
  companyData: companyDataReducer,
  permissions: permissionReducer,
  http: httpReducer,
  debug: debugReducer,
  assets: assetReducer,
  babylonAsset: babylonAssetReducer,
  materialAsset: materialAssetReducer,
  workflows: workflowsReducer,
  replicator: replicatorReducer,
  notifications: notificationsReducer,
  identity: identityReducer,
  cfgrWhitelistDomains: cfgrWhitelistDomainsReducer,
  cfgnInsights: cfgnInsightsReducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(loggerMiddleware, persistenceMiddleware),
  devTools: { actionCreators: { ...debugActions } },
});

HttpService.setupHttpInterceptor(store);

export type RootReducer = typeof rootReducer;
export type RootState = ReturnType<typeof store.getState>;
export type RootStateKeys = keyof RootState;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
