import React from 'react';

type SuspenseTypes = {
  fallbackText: string;
  children: React.ReactNode;
};

export const Suspense: React.FC<SuspenseTypes> = ({ fallbackText, children }) => {
  // forwards Reacts default <Suspense> with a styled fallback text
  return (
    <React.Suspense
      fallback={
        <div className="center flex h-full grow items-center justify-center">
          <h3>{fallbackText}</h3>
        </div>
      }
    >
      {children}
    </React.Suspense>
  );
};
