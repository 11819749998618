import { useRef, useState } from 'react';
import { OpenIcon } from 'assets/icons';
import { CopyToClipboardButton } from 'controls/button/copy-to-clipboard-button';
import { LinkButton } from 'controls/button/link-button';
import { Card } from 'controls/card/card';
import { FormControlLabel } from 'controls/form/form-control-label';
import { Switch } from 'controls/switch/switch';
import { Textarea } from 'controls/textarea/textarea';
import { getCbnPluginUrl, getEmbedExampleUrl, getPublishedCfgrLink } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectSelectedConfigurator } from 'slices/company-data/company-data.slice';

const _IFRAME_PERMISSION_POLICIES = ['clipboard-write', 'fullscreen'] as const;
type IframePermissionPolicy = (typeof _IFRAME_PERMISSION_POLICIES)[number];

export const EmbeddingCode: React.FC = () => {
  const { t } = useTypedTranslation();

  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const pluginUrl = selectedCfgr ? getCbnPluginUrl(selectedCfgr.companyId, selectedCfgr.id) : '';
  const cfgrUrl = selectedCfgr ? getPublishedCfgrLink(selectedCfgr.companyId, selectedCfgr.id, null, false) : '';

  const [allowValues, setAllowValues] = useState<IframePermissionPolicy[]>(['clipboard-write']);
  const allowValuesStr = allowValues.length > 0 ? ` allow="${allowValues.join('; ')}"` : '';

  const toggleAllowValue = (value: IframePermissionPolicy): void => {
    setAllowValues(currentValues => {
      if (currentValues.includes(value)) {
        return currentValues.filter(v => v !== value);
      } else {
        return [...currentValues, value];
      }
    });
  };

  const embeddingScript = `<script src="${pluginUrl}"></script>\n<iframe data-cfgrurl="${cfgrUrl}"${allowValuesStr} frameborder="0"></iframe>`;
  const embedExampleUrl = selectedCfgr ? getEmbedExampleUrl(selectedCfgr.companyId, selectedCfgr.id) : '';

  return (
    <Card
      headerText={t('Embedding')}
      subText={t('Prepare and embed your configurator in your own page')}
      data-cmptype="EmbeddingCode"
      grow
      actions={
        <LinkButton
          variant="Secondary"
          text={t('Open example')}
          href={embedExampleUrl}
          target="_blank"
          Svg={OpenIcon}
        />
      }
    >
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-4">
          <div className="text-m-regular">{t('Permissions Policies')}:</div>
          {_IFRAME_PERMISSION_POLICIES.map(policy => (
            <FormControlLabel
              key={policy}
              label={t(policy)}
              control={
                <Switch checked={allowValues.includes(policy)} onValueChanged={(): void => toggleAllowValue(policy)} />
              }
            />
          ))}
        </div>
        <div className="flex h-[95px]">
          <Textarea value={embeddingScript} readOnly preventTextWrap ref={textareaRef} />
        </div>
        <CopyToClipboardButton text={embeddingScript} buttonText={t('Copy')} />
      </div>
    </Card>
  );
};
