import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PlusIcon, ScheduledIcon } from 'assets/icons';
import { CreateWorkflowModal } from 'components/workflows/modals/create-workflow-modal';
import { Button } from 'controls/button/button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { Icon } from 'controls/icon/icon';
import { StateIcon } from 'controls/icon/state-icon';
import { List } from 'controls/list/list';
import { ListItem } from 'controls/list/list-item';
import { WorkflowStates } from 'generated/workflow-states';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { PATHS } from 'services/routes/paths.service';
import { WorkflowsRouteParams } from 'services/routes/workflows-routes.service';
import { CompanyPermissions } from 'slices/permission/permission.slice';
import { selectSelectedWorkflow, selectWorkflowsInSelectedCompany } from 'slices/workflows/workflows.slice';

export const WorkflowsOverview: React.FC = () => {
  const { t } = useTypedTranslation();

  const routeParams = useParams<WorkflowsRouteParams>();

  const companyId = useAppSelector(state => state.companyData.selection.companyId);
  const workflows = useAppSelector(selectWorkflowsInSelectedCompany);
  const sortedWorkflows = [...workflows].sort((a, b) => a.displayName.localeCompare(b.displayName));
  const selectedWorkflow = useAppSelector(selectSelectedWorkflow);
  const hasManageWorkflowPermission = usePermission(CompanyPermissions.ManageWorkflows, companyId);

  const [createWorkflowModal, setCreateWorkflowModal] = useState(false);

  return (
    <CbnCard>
      {/* TODO: Same header as in the assets tree panel.
                Maybe make this some kind of reusable panel, header etc. or add a dedicated `CbnCardHeader` variant?
      */}
      <div className="flex items-center gap-5 border-b border-neutral-40 px-6 py-3">
        <div className="grow text-m-medium">{t('Workflows')}</div>
        {hasManageWorkflowPermission && (
          <Button
            variant="Secondary"
            Svg={PlusIcon}
            text={t('New')}
            onClick={(): void => setCreateWorkflowModal(true)}
          />
        )}
      </div>

      <List>
        {sortedWorkflows.map(wf => (
          <ListItem
            key={wf.workflowName}
            selected={selectedWorkflow && selectedWorkflow.workflowName === wf.workflowName}
          >
            <Link
              to={PATHS.buildWorkflowsPath(companyId, wf.workflowName, routeParams.workflowPage)}
              className="flex h-full w-full items-center gap-2"
            >
              <StateIcon variant={wf.state === WorkflowStates.Ready ? 'Success' : 'Error'} noBckgr />
              <span className="grow">{wf.displayName}</span>
              {!!wf.cronExpression && (
                <Icon Svg={ScheduledIcon} title={t('Scheduled execution')} className="h-5 text-neutral-100" />
              )}
            </Link>
          </ListItem>
        ))}
      </List>

      {createWorkflowModal && (
        <CreateWorkflowModal
          onConfirm={(): void => setCreateWorkflowModal(false)}
          onCancel={(): void => setCreateWorkflowModal(false)}
        />
      )}
    </CbnCard>
  );
};
