import { UserRegistrationCard } from 'components/login/user-registration-card';
import { PATHS } from 'services/routes/paths.service';

export const UserRegistration: React.FC = () => {
  return (
    <div data-cmptype="UserRegistration">
      <UserRegistrationCard loginLinkPath={PATHS.login} />
    </div>
  );
};
