import { useState } from 'react';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { Card } from 'controls/card/card';
import { FormItem } from 'controls/form-item/form-item';
import { TextInput } from 'controls/input/text-input';
import { CBN_MAIL_ADRESSES } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { postAccountLostPassword as lostPassword } from 'services/auth/auth.service';
import { PATHS } from 'services/routes/paths.service';

export const ForgotPassword: React.FC = () => {
  const { t } = useTypedTranslation();
  const [emailInput, setEmailInput] = useState('');
  const [sent, setSent] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSend = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    setIsSubmitting(true);
    const success = await lostPassword(emailInput);
    setIsSubmitting(false);

    if (success) {
      setSent(true);
      return;
    }
    // This should not happen as the server always returns success
    setIsFail(true);
  };

  return (
    <div className="flex flex-grow flex-col items-center gap-6">
      <Card>
        <div className="flex w-100 flex-col gap-6 pt-3">
          <div className="flex flex-col gap-2">
            <h3>{t('Forgot password?')}</h3>
            {!sent && <span className="text-m-regular text-neutral-70">{t('Enter email to reset your password')}</span>}
          </div>

          {!sent && (
            <form onSubmit={onSend} className="flex w-100 flex-col gap-6">
              <FormItem labelContent={t('Your email')}>
                <TextInput
                  type="text"
                  autoFocus
                  autoComplete="username"
                  placeholder={CBN_MAIL_ADRESSES.placeholderMail}
                  value={emailInput}
                  onValueChange={(val): void => setEmailInput(val)}
                ></TextInput>
              </FormItem>

              {isFail && (
                //This should not happen as the server always returns success
                <span className=" text-s-regular text-danger-main">{t('Something went wrong!')}</span>
              )}

              <div className="flex flex-grow">
                <Button
                  type="submit"
                  variant="Primary"
                  big
                  grow
                  text={t('Reset password')}
                  disabled={!emailInput}
                  isLoading={isSubmitting}
                ></Button>
              </div>
            </form>
          )}

          {sent && (
            <div className="flex flex-col gap-5 text-s-regular">
              {t(
                'Thank you for your request. To create a new password, please follow the instructions in the email, which you just received.'
              )}
              <LinkButton href={PATHS.login} variant="Primary" big text={t('Back to log in')}></LinkButton>
            </div>
          )}
        </div>
      </Card>

      <div>
        {!sent && (
          <div className="flex flex-row items-center gap-1 text-m-regular text-neutral-90">
            <span>{t('Remember your password?')}</span>
            <LinkButton href={PATHS.login} variant="TextInline" text={t('Back to log in')}></LinkButton>
          </div>
        )}

        <div className="flex flex-row items-center gap-1 text-m-regular text-neutral-90">
          <span>{t("Don't have an account?")}</span>
          <LinkButton href={PATHS.registerUser} variant="TextInline" text={t('Create new account')}></LinkButton>
        </div>
      </div>
    </div>
  );
};
