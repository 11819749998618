import { useCallback, useState } from 'react';
import { SchemaNameInput, SchemaNameInputResult } from 'components/common/schema-name-input';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { useCreateWorkflow } from 'hooks/workflows/workflows.hooks';
import { selectWorkflowNames } from 'slices/workflows/workflows.slice';

type CreateWorkflowModalProps = {
  onConfirm?: () => void;
  onCancel: () => void;
};

export const CreateWorkflowModal: React.FC<CreateWorkflowModalProps> = ({ onConfirm, onCancel }) => {
  const { t } = useTypedTranslation();
  const createWorkflow = useCreateWorkflow();
  const [isCreating, setIsCreating] = useState(false);

  const workflowNames = useAppSelector(selectWorkflowNames);

  const [input, setInput] = useState<SchemaNameInputResult>({ uniqueId: '', displayName: '', hasErrors: false });

  const onSchemaInputChange = useCallback((result: SchemaNameInputResult): void => {
    setInput(result);
  }, []);

  const onWorkflowConfirm = async (): Promise<void> => {
    if (input && !input.hasErrors) {
      setIsCreating(true);
      await createWorkflow(input?.uniqueId, input?.displayName);
      onConfirm?.();
      setIsCreating(false);
    }
  };

  return (
    <ModalDialog
      data-cmptype="CreateWorkflow"
      header={t('Create workflow')}
      variant={'NoIcon'}
      actions={{ onConfirm: onWorkflowConfirm, onCancel: onCancel }}
      confirmDisabled={input?.hasErrors}
      isProcessingConfirm={isCreating}
      confirmText={t('Create workflow')}
    >
      <div className="flex grow flex-col gap-4">
        <SchemaNameInput input={input} unavailableIds={workflowNames} onChange={onSchemaInputChange} />
      </div>
    </ModalDialog>
  );
};
