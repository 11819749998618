import React, { useCallback, useEffect } from 'react';
import { useInterval } from 'hooks/common/timing.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { getAssetBundles } from 'slices/assets/assets.slice';
import { getCompanyCfgrs, selectSelectedCompany } from 'slices/company-data/company-data.slice';
import {
  clearOldNotifications,
  selectReplicatorNotifications,
  setNotificationToFinished,
  updateReplicationStatus,
} from 'slices/notifications/notifications.slice';

type NotificationProviderProps = {
  children: React.ReactNode;
};

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const replicatorNotifications = useAppSelector(selectReplicatorNotifications);
  const replicatorNotificationsToPoll = replicatorNotifications.filter(r => r.updateState === 'POLLING');
  const replicatorNotificationsToFinish = replicatorNotifications.filter(r => r.updateState === 'FINISHING');
  const isMenuOpen = useAppSelector(state => state.notifications.ui.isMenuOpen);
  const selectedCompanyId = useAppSelector(selectSelectedCompany)?.id;

  const hasNothingToPoll = replicatorNotificationsToPoll.length === 0;
  const intervalTime = hasNothingToPoll ? null : isMenuOpen ? 2000 : 8000;

  const requestAllReplicatorStatus = useCallback(() => {
    replicatorNotificationsToPoll.forEach(n => {
      dispatch(updateReplicationStatus(n.uid));
    });
  }, [dispatch, replicatorNotificationsToPoll]);

  useInterval(requestAllReplicatorStatus, intervalTime);

  useEffect(() => {
    dispatch(clearOldNotifications());
  }, [dispatch]);

  useEffect(
    function finishReplicatorNotifications() {
      replicatorNotificationsToFinish.forEach(async n => {
        dispatch(setNotificationToFinished(n.uid));

        if (Object.keys(n.cfgrIds).length && n.targetCompany === selectedCompanyId) {
          await dispatch(getCompanyCfgrs({ companyId: n.targetCompany }));
        }
        if (Object.keys(n.assetBundleIds).length && n.targetCompany === selectedCompanyId) {
          await dispatch(getAssetBundles({ companyId: n.targetCompany, silentBundleUpdate: true }));
        }
      });
    },
    [dispatch, replicatorNotificationsToFinish, selectedCompanyId]
  );

  useEffect(
    function pollOnMenuOpen() {
      if (!isMenuOpen) {
        return;
      }
      requestAllReplicatorStatus();
    },
    [isMenuOpen, requestAllReplicatorStatus]
  );

  return <>{children}</>;
};
