import { Divider as MuiDivider, styled } from '@mui/material';
import { dividerClasses } from '@mui/material/Divider';

const StyledMenuDivider = styled(MuiDivider)(() => ({
  [`&.${dividerClasses.root}`]: {
    marginTop: '8px',
    marginBottom: '8px',
  },
}));

export const CbnMenuDivider: React.FC = () => {
  return <StyledMenuDivider data-cmptype="CbnMenuDivider" />;
};
