import { WorkflowPath } from 'slices/workflows/workflows.slice';

export function workflowPathToKey(path: WorkflowPath): string {
  let key = `${path.companyId}.${path.workflowName}`;

  if (path.workflowRunId) {
    key += `.${path.workflowRunId}`;
  }

  return key.toLowerCase();
}

export function isUnusedWorkflowsKey(path: WorkflowPath, workflowKeys: string[]): boolean {
  const key = workflowPathToKey(path);

  return !!path.workflowName && !workflowKeys.includes(key);
}

export function isWorkflowOfSamePath(path1: WorkflowPath, path2: WorkflowPath): boolean {
  const workflowRunEqual =
    path1.companyId === path2.companyId &&
    path1.workflowName === path2.workflowName &&
    path1.workflowRunId === path2.workflowRunId;

  return workflowRunEqual;
}
