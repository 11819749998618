import classNames from 'classnames';
import { ErrorIcon, SearchLoupQuestionIcon, StopIcon, SuccessIcon } from 'assets/icons';
import { Icon, SvgComponent } from 'controls/icon/icon';
import { PercentageLoader } from 'controls/loader/percentage-loader';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { ReplicationStates } from 'generated/replication-states';
import { tw } from 'helper/css.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { ReplicationStatus } from 'services/replicator/replicator.service';

const _REPLICATIONSTATE_ICON_DEF: Record<
  ReplicationStates,
  { icon?: SvgComponent; text: string; colorClass?: string }
> = {
  [ReplicationStates.Uninitialized]: { text: 'Copy in progress...' },
  [ReplicationStates.Preparing]: {
    text: 'Copy in progress...',
  },
  [ReplicationStates.Ready]: {
    text: 'Copy in progress...',
  },
  [ReplicationStates.Replicating]: {
    text: 'Copy in progress...',
  },
  [ReplicationStates.Finishing]: {
    text: 'Copy in progress...',
  },
  [ReplicationStates.Finished]: {
    icon: SuccessIcon,
    text: 'Copy successful',
    colorClass: tw`text-success-main`,
  },
  [ReplicationStates.Failed]: {
    icon: ErrorIcon,
    text: 'Copy failed',
    colorClass: tw`text-danger-main`,
  },
  [ReplicationStates.UndoPreparation]: {
    icon: ErrorIcon,
    text: 'Undo copy process...',
    colorClass: tw`text-warning-main`,
  },
  [ReplicationStates.UndoReplication]: {
    icon: ErrorIcon,
    text: 'Undo copy process...',
    colorClass: tw`text-warning-main`,
  },
};

export const ReplicationStateIcon: React.FC<{ status: ReplicationStatus }> = ({ status }) => {
  const { t } = useTypedTranslation();

  const def = _REPLICATIONSTATE_ICON_DEF[status.state];
  const showPercentageLoader = !status.completedAt;

  // Percentage based on the `completedSize` isn't an indicator for the "whole progress".
  // The auto-publish is an individual step which we can't express in numbers,
  // so we simply give the impression that it's still in progress
  const copyPercentageGoal = 90;
  const hasValidSizes = status.totalSize > 0 && status.completedSize > 0;
  const percentage = status.completedAt
    ? 100
    : hasValidSizes
      ? (status.completedSize / status.totalSize) * copyPercentageGoal
      : 0;

  const isCancelledAndCompleted = status.isCancelled && status.completedAt;

  if (!def) {
    return null;
  }

  const statusText = isCancelledAndCompleted
    ? t('Cancelled')
    : status.isCancelled
      ? `${t('Cancelled')}: ${t(def.text)}`
      : t(def.text);

  const iconSvg = isCancelledAndCompleted ? StopIcon : def.icon;

  return (
    <div data-cmptype="ReplicationStateIcon" className={classNames('flex items-center gap-2', def.colorClass)}>
      {showPercentageLoader ? (
        <PercentageLoader size="small" percentage={percentage} />
      ) : (
        iconSvg && <Icon Svg={iconSvg} className="w-5" />
      )}
      <span>{statusText}</span>
      {status.failureReason && !status.isCancelled && (
        <Icon
          Svg={SearchLoupQuestionIcon}
          className="w-5 hover:text-danger-hover"
          title={<TooltipContent detail={status.failureReason.Message} />}
        />
      )}
    </div>
  );
};
