import { useEffect, useRef, useState } from 'react';
import { PublishedCfgrIcon, StageIcon } from 'assets/icons';
import { Button } from 'controls/button/button';
import { CbnMenu } from 'controls/cbn-menu/cbn-menu';
import { CbnMenuItem } from 'controls/cbn-menu/cbn-menu-item';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { getCfgrCfgnInsights } from 'slices/cfgn-insisghts/cfgn-insights.slice';
import { getCompanyCfgr, selectCfgrStages, selectSelectedConfigurator } from 'slices/company-data/company-data.slice';

type CfgnInsishgtsVersionSelectorBtnProps = {};

export const CfgnInsishgtsVersionSelectorBtn: React.FC<CfgnInsishgtsVersionSelectorBtnProps> = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const btnRef = useRef<HTMLDivElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const { queryValues, stageName } = useAppSelector(state => state.cfgnInsights);
  const cfgrStages = useAppSelector(selectCfgrStages);

  useEffect(() => {
    if (selectedCfgr?.companyId && selectedCfgr?.id) {
      dispatch(getCompanyCfgr({ companyId: selectedCfgr.companyId, cfgrId: selectedCfgr.id }));
    }
  }, [dispatch, selectedCfgr?.companyId, selectedCfgr?.id]);

  /**
   * @param stageName Undefined for live version
   */
  async function loadInsightsForVersion(stageName?: string): Promise<void> {
    if (selectedCfgr) {
      dispatch(
        getCfgrCfgnInsights({
          companyId: selectedCfgr.companyId,
          cfgrId: selectedCfgr.id,
          queryValues,
          ...(stageName && { stageName: stageName }),
        })
      );
    }
  }

  return (
    <div data-cmptype="CfgnInsishgtsVersionSelectorBtn">
      <div ref={btnRef}>
        <Button
          text={stageName ? t(`Stage ${stageName}`) : t('Live version')}
          Svg={stageName ? StageIcon : PublishedCfgrIcon}
          isDropdownButton
          variant="Primary"
          onClick={(): void => setPopoverOpen(true)}
        />
      </div>
      {/*
      TODO Now ORT: Is the difference in implementation and design worth it to introduce another cmp/styleguide element?
      */}
      <CbnMenu
        data-cmptype="CfgnInsishgtsVersionSelectorBtn-menu"
        anchor={btnRef.current}
        open={popoverOpen}
        placement="bottom-end"
        onClose={(): void => setPopoverOpen(false)}
        className="max-w-[176px]"
      >
        <div className="flex flex-col gap-4 pb-3">
          <div className="px-3 pt-3 text-s-regular text-neutral-70">
            {t('Select the version for which to show the insights.')}
          </div>

          <div className="flex flex-col gap-2">
            <CbnMenuItem
              text={t('Live version')}
              icon={PublishedCfgrIcon}
              handleClick={(): Promise<void> => loadInsightsForVersion()}
              selected={!stageName}
            />

            {cfgrStages.map(x => (
              <CbnMenuItem
                icon={StageIcon}
                handleClick={(): Promise<void> => loadInsightsForVersion(x.name)}
                selected={x.name === stageName}
                key={x.version}
                text={x.name}
              />
            ))}
          </div>
        </div>
      </CbnMenu>
    </div>
  );
};
