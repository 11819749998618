import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { globalCompanySwitch, globalReset } from 'actions/general.actions';
import { WorkflowRunStates } from 'generated/workflow-run-states';
import { FetchedState, updateFetchedStatesOnSelection } from 'helper/route/route-sync.helper';
import { workflowPathToKey } from 'helper/workflows/workflows.helper';
import { ActionResult } from 'services/http/http.service';
import * as PersistenceMiddleware from 'services/store/persistence.middleware';
import { RootState } from 'services/store/store.service';
import {
  EditWorkflowConfigErrorResponse,
  UpdateWorkflowContent,
  Workflow,
  WorkflowNotificationConfig,
  WorkflowParameters,
  WorkflowRun,
  WorkflowRunId,
  WorkflowRuns,
  Workflows,
  fetchAllWorkflowRunsForWorkflow,
  fetchAllWorkflows,
  fetchWorkflow,
  fetchWorkflowRun,
  fetchWorkflowRunLog,
  postCreateWorkflow,
  postDeleteWorkflow,
  postUpdateWorkflowCode,
  postUpdateWorkflowConfig,
} from 'services/workflows/workflows.service';

export const UNFINISHED_WORKFLOW_STATES = [WorkflowRunStates.Queued, WorkflowRunStates.Running];

export type WorkflowPath = {
  companyId: string;
  workflowName: string;
  workflowRunId?: string;
};

type WorkflowSelection = Omit<WorkflowPath, 'companyId'>;
export type WorkflowsState = {
  workflows: Workflows;
  workflowRuns: WorkflowRuns;
  fetchState: {
    workflows: FetchedState;
    workflowRuns: FetchedState;
    workflowRunLog: FetchedState;
  };
  selection: WorkflowSelection;
};

const initialState: WorkflowsState = {
  workflows: {},
  workflowRuns: {},
  fetchState: {
    workflows: 'None',
    workflowRuns: 'None',
    workflowRunLog: 'None',
  },
  selection: {
    workflowName: '',
    workflowRunId: '',
  },
};

export const getWorkflow = createAsyncThunk<Workflow, { companyId: string; workflowName: string }>(
  'workflows/getWorkflow',
  async ({ companyId, workflowName }, thunkApi) => {
    if (!companyId || !workflowName) {
      return thunkApi.rejectWithValue(undefined);
    }

    const workflow = await fetchWorkflow(companyId, workflowName);
    return workflow;
  }
);

/**
 * Fetches all workflows for a certain company
 */
export const getAllWorkflows = createAsyncThunk<Workflows, { companyId: string }>(
  'workflows/getAllWorkflows',
  async ({ companyId }, thunkApi) => {
    if (!companyId) {
      return thunkApi.rejectWithValue(undefined);
    }

    const workflows = await fetchAllWorkflows(companyId);
    return workflows;
  }
);

export const createWorkflow = createAsyncThunk<void, { companyId: string; workflowName: string; displayName: string }>(
  'workflows/createWorkflow',
  async ({ companyId, workflowName, displayName }, thunkApi) => {
    if (!companyId || !workflowName || !displayName) {
      return thunkApi.rejectWithValue(undefined);
    }

    await postCreateWorkflow(companyId, workflowName, displayName);

    // re-fetch all workflows after creating one
    await thunkApi.dispatch(getAllWorkflows({ companyId }));
  }
);

export const deleteWorkflow = createAsyncThunk<void, { companyId: string; workflowName: string }>(
  'workflows/deleteWorkflow',
  async ({ companyId, workflowName }, thunkApi) => {
    if (!companyId || !workflowName) {
      return thunkApi.rejectWithValue(undefined);
    }

    await postDeleteWorkflow(companyId, workflowName);
  }
);

export const changeWorkflowConfig = createAsyncThunk<
  ActionResult<EditWorkflowConfigErrorResponse> | true,
  {
    companyId: string;
    workflowName: string;
    displayName: string;
    parameters: WorkflowParameters;
    notificationConfig: WorkflowNotificationConfig;
    cronExpression?: string;
  }
>(
  'workflows/changeWorkflowConfig',
  async ({ companyId, workflowName, displayName, parameters, notificationConfig, cronExpression }, thunkApi) => {
    if (!companyId || !workflowName || !displayName || !parameters) {
      return thunkApi.rejectWithValue(undefined);
    }

    const response = await postUpdateWorkflowConfig(
      companyId,
      workflowName,
      displayName,
      parameters,
      notificationConfig,
      cronExpression
    );

    if (response === true) {
      // re-fetch workflow to update slice content
      await thunkApi.dispatch(getWorkflow({ companyId, workflowName }));
    }

    return response;
  }
);

export const getAllWorkflowRuns = createAsyncThunk<WorkflowRuns, { companyId: string; workflowName: string }>(
  'workflows/getAllWorkflowRuns',
  async ({ companyId, workflowName }, thunkApi) => {
    if (!companyId || !workflowName) {
      return thunkApi.rejectWithValue(undefined);
    }

    const workflowRuns = await fetchAllWorkflowRunsForWorkflow(companyId, workflowName);
    return workflowRuns;
  }
);

export const getWorkflowRun = createAsyncThunk<
  WorkflowRun,
  { companyId: string; workflowName: string; workflowRunId: WorkflowRunId }
>('workflows/getWorkflowRun', async ({ companyId, workflowName, workflowRunId }, thunkApi) => {
  if (!companyId || !workflowName || !workflowRunId) {
    return thunkApi.rejectWithValue(undefined);
  }

  const workflowRuns = await fetchWorkflowRun(companyId, workflowName, workflowRunId);
  if (!workflowRuns) {
    return thunkApi.rejectWithValue(undefined);
  }

  return workflowRuns;
});

export const getWorkflowRunLog = createAsyncThunk<
  string,
  { companyId: string; workflowName: string; workflowRunId: WorkflowRunId }
>('workflows/getWorkflowRunLog', async ({ companyId, workflowName, workflowRunId }, thunkApi) => {
  if (!companyId || !workflowName || !workflowRunId) {
    return thunkApi.rejectWithValue(undefined);
  }

  const log = await fetchWorkflowRunLog(companyId, workflowName, workflowRunId);
  return log;
});

export const updateWorkflowCode = createAsyncThunk<
  UpdateWorkflowContent,
  { companyId: string; workflowName: string; codeContent: string }
>('workflows/updateWorkflowCode', async ({ companyId, workflowName, codeContent }, thunkApi) => {
  if (!companyId || !workflowName) {
    return thunkApi.rejectWithValue(undefined);
  }

  const updateResponse = await postUpdateWorkflowCode(companyId, workflowName, codeContent);
  return updateResponse;
});

const _SLICE_NAME = 'workflows';

PersistenceMiddleware.registerState<WorkflowsState, WorkflowsState>({
  state: _SLICE_NAME,
  key: 'selection',
  selector: state => state,
  props: ['selection'],
});

function _getRehydratedState(): WorkflowsState {
  const rehydratedState = PersistenceMiddleware.rehydrateState<WorkflowsState>(_SLICE_NAME, initialState);
  return rehydratedState;
}

const workflowsSlice = createSlice({
  name: _SLICE_NAME,
  initialState: _getRehydratedState,
  reducers: {
    setWorkflowSelection: (state, { payload }: PayloadAction<Partial<WorkflowSelection>>) => {
      state.selection = { ...state.selection, ...payload };

      updateFetchedStatesOnSelection(state.fetchState, payload, [
        { selectionKey: 'companyId', fetchedStateKey: 'workflows' },
        { selectionKey: 'workflowName', fetchedStateKey: 'workflowRuns' },
        { selectionKey: 'workflowRunId', fetchedStateKey: 'workflowRunLog' },
      ]);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(globalReset, () => initialState)
      .addCase(globalCompanySwitch, state => {
        state.selection = { workflowName: '', workflowRunId: '' };
        state.fetchState = { workflows: 'Reset', workflowRuns: 'Reset', workflowRunLog: 'Reset' };
      })
      .addCase(getAllWorkflows.pending, state => {
        state.fetchState.workflows = 'Pending';
      })
      .addCase(getAllWorkflows.fulfilled, (state, { payload, meta }) => {
        // store sas uri of selected workflow

        const selKey = workflowPathToKey({ companyId: meta.arg.companyId, ...state.selection });
        const selSasUri = state.workflows[selKey]?.blobContentSasUri;

        // overwrite all workflows, sas uri will get removed
        state.workflows = payload;
        state.fetchState.workflows = 'Fetched';

        // restore sas uri of selected workflow if available
        if (selSasUri && state.workflows[selKey]) {
          state.workflows[selKey].blobContentSasUri = selSasUri;
        }
      })
      .addCase(getWorkflow.fulfilled, (state, { payload }) => {
        const key = workflowPathToKey({ companyId: payload.companyId, workflowName: payload.workflowName });

        state.workflows[key] = payload;
      })
      .addCase(getAllWorkflowRuns.fulfilled, (state, { payload, meta }) => {
        // keep sas uri if available => same procedure as for workflows
        const selKey = workflowPathToKey({ companyId: meta.arg.companyId, ...state.selection });
        const selSasUri = state.workflowRuns[selKey]?.runLogSasUri;

        state.workflowRuns = payload;
        state.fetchState.workflowRuns = 'Fetched';

        if (selSasUri && state.workflowRuns[selKey]) {
          state.workflowRuns[selKey].runLogSasUri = selSasUri;
        }
      })
      .addCase(getWorkflowRun.fulfilled, (state, { payload, meta }) => {
        const key = workflowPathToKey({
          companyId: payload.companyId,
          workflowName: payload.workflowName,
          workflowRunId: payload.workflowRunId,
        });
        const prevSasUri = state.workflowRuns[key]?.runLogSasUri;
        state.workflowRuns[key] = payload;

        if (prevSasUri) {
          state.workflowRuns[key].runLogSasUri = prevSasUri;
        }
      })
      .addCase(getWorkflowRunLog.fulfilled, (state, { payload, meta }) => {
        const key = workflowPathToKey(meta.arg);

        state.workflowRuns[key].runLogSasUri = payload;
        state.fetchState.workflowRunLog = 'Fetched';
      })
      .addCase(deleteWorkflow.fulfilled, (state, { meta }) => {
        state.selection = { workflowName: '', workflowRunId: '' };
        const key = workflowPathToKey({ companyId: meta.arg.companyId, workflowName: meta.arg.workflowName });
        delete state.workflows[key];
      })
      .addCase(updateWorkflowCode.fulfilled, (state, { payload, meta }) => {
        const key = workflowPathToKey({ companyId: meta.arg.companyId, workflowName: meta.arg.workflowName });
        state.workflows[key].state = payload.workflowState;
        state.workflows[key].parseError = payload.parseError;
      });
  },
});

export const selectWorkflows = createSelector(
  (state: RootState) => state.workflows.workflows,
  workflows => Object.values(workflows)
);

// Selectors for list entries (bundles, bundle names, assets)
export const selectWorkflowsInSelectedCompany = createSelector(
  selectWorkflows,
  (state: RootState) => state.companyData.selection.companyId,
  (workflows, companyId) => workflows.filter(wf => wf.companyId === companyId)
);

export const selectSelectedWorkflow = createSelector(
  selectWorkflows,
  (state: RootState) => state.companyData.selection.companyId,
  (state: RootState) => state.workflows.selection,
  (workflows, companyId, selection) =>
    workflows.find(wf => wf.companyId === companyId && wf.workflowName === selection.workflowName)
);

export const selectWorkflowNames = createSelector(
  (state: RootState) => state.workflows.workflows,
  workflows => Object.values(workflows).map(w => w.workflowName)
);

export const selectWorkflowRuns = createSelector(
  (state: RootState) => state.workflows.workflowRuns,
  workflowRuns => Object.values(workflowRuns)
);

export const selectWorkflowRunOfSelectedWorkflow = createSelector(
  selectWorkflowRuns,
  (state: RootState) => state.companyData.selection.companyId,
  (state: RootState) => state.workflows.selection,
  (workflowRuns, companyId, selection) =>
    workflowRuns.filter(wfRun => wfRun.companyId === companyId && wfRun.workflowName === selection.workflowName)
);

export const selectSelectedWorkflowRun = createSelector(
  selectWorkflowRuns,
  (state: RootState) => state.companyData.selection.companyId,
  (state: RootState) => state.workflows.selection,
  (workflowRuns, companyId, selection) =>
    workflowRuns.find(
      wfRun =>
        wfRun.companyId === companyId &&
        wfRun.workflowName === selection.workflowName &&
        wfRun.workflowRunId === selection.workflowRunId
    )
);

export const selectSelectedWorkflowsPath = createSelector(
  (state: RootState) => state.companyData.selection.companyId,
  (state: RootState) => state.workflows.selection,
  (companyId, selectedPath) => {
    const fullPath: WorkflowPath = { companyId, ...selectedPath };
    return fullPath;
  }
);

export const { setWorkflowSelection } = workflowsSlice.actions;
export default workflowsSlice.reducer;
