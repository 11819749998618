import { StateIcon } from 'controls/icon/state-icon';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectAllCompanyCfgrs } from 'slices/company-data/company-data.slice';
import { selectCurrentBlueprint } from 'slices/replicator/replicator.slice';

export const ReplicatorBlueprintCfgrs: React.FC = () => {
  const { t } = useTypedTranslation();
  const blueprint = useAppSelector(selectCurrentBlueprint);
  const allCfgrs = useAppSelector(selectAllCompanyCfgrs);

  return (
    <>
      {Object.values(blueprint.cfgrs).map(blueprintCfgr => {
        const cfgr = allCfgrs.find(c => c.id === blueprintCfgr.id);
        return (
          <div
            key={blueprintCfgr.id}
            className="rounded-lg border border-neutral-50 px-6 py-4"
            data-cmptype="ReplicatorBlueprintCfgrs-item"
          >
            <h3>{cfgr?.displayName}</h3>

            <div className="flex items-center gap-2">
              <span>{t('Published version')}</span>
              <StateIcon
                variant="Info"
                noBckgr
                title={t(
                  'Currently only published versions can be copied to different companies!\n' +
                    'Copying inside the same company can be done in the drafts view.'
                )}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};
