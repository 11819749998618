import { useContext, useEffect, useId, useState } from 'react';
import { PanelResizeHandle as BasePanelResizeHandle } from 'react-resizable-panels';
import {
  ArrowNoMarginDownIcon,
  ArrowNoMarginLeftIcon,
  ArrowNoMarginRightIcon,
  ArrowNoMarginUpIcon,
} from 'assets/icons';
import { CbnResizeablePanelGroupContext } from 'controls/cbn-resizeable-panel/cbn-resizeable-panel-group';
import { Icon } from 'controls/icon/icon';
import { cn, tw } from 'helper/css.helper';

export const CbnResizablePanelSeparator: React.FC = () => {
  const { direction, addSeparator, removeSeparator } = useContext(CbnResizeablePanelGroupContext);
  const id = useId();

  const [isDragging, setIsDragging] = useState(false);
  const isHorizontal = direction === 'horizontal';

  // register separator in parent panel group
  useEffect(() => {
    addSeparator(id);

    return () => removeSeparator(id);
  }, [id, addSeparator, removeSeparator]);

  const className = cn(
    tw`flex items-center hover:border-neutral-50 hover:text-neutral-50`,
    isHorizontal ? tw`flex-row` : tw`flex-col`,
    isDragging ? tw`border-neutral-60 text-neutral-50` : tw`border-transparent text-transparent`
  );

  return (
    <BasePanelResizeHandle data-cmptype="CbnResizablePanelSeparator" className={className} onDragging={setIsDragging}>
      <Icon
        Svg={isHorizontal ? ArrowNoMarginLeftIcon : ArrowNoMarginUpIcon}
        className={isHorizontal ? 'mr-0.5 w-2' : 'mb-0.5 h-2'}
      />
      <div className={isHorizontal ? 'h-full border-l' : 'w-full border-b'}></div>
      <Icon
        Svg={isHorizontal ? ArrowNoMarginRightIcon : ArrowNoMarginDownIcon}
        className={isHorizontal ? 'ml-0.5 w-2' : 'mt-0.5 h-2'}
      />
    </BasePanelResizeHandle>
  );
};
