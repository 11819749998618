import { Fragment, useId, useRef, useState } from 'react';
import { GearIcon } from 'assets/icons';
import { Button } from 'controls/button/button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnPopover } from 'controls/cbn-popover/cbn-popover';
import { CbnSearchInput } from 'controls/input/search-input';
import { Switch } from 'controls/switch/switch';
import { distinctFilter } from 'helper/array/array.helper';
import { AllInsightsProperties } from 'helper/cfgn-insights.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import {
  selectCfgrCfgnInsightsPropertyDefinitions,
  selectShownColumns,
  setShownColumns,
} from 'slices/cfgn-insisghts/cfgn-insights.slice';

type CfgnInsightsViewSettingsBtnProps = {};

export const CfgnInsightsViewSettingsBtn: React.FC<CfgnInsightsViewSettingsBtnProps> = () => {
  const { t } = useTypedTranslation();
  const prefixId = useId();
  const dispatch = useAppDispatch();

  const btnRef = useRef<HTMLDivElement>(null);
  const [viewSettingsOpen, setViewSettingsOpen] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const shownColumns = useAppSelector(selectShownColumns);

  const insightsPropertyDefinitions = useAppSelector(selectCfgrCfgnInsightsPropertyDefinitions);
  const allPropertyGroups = insightsPropertyDefinitions.map(x => x.group).filter(distinctFilter);

  const groupedProperties = allPropertyGroups
    .map(group => {
      const properties = insightsPropertyDefinitions.filter(col => {
        const matchesGroup = col.group === group;
        const matchesFilter = !searchValue || col.label.toLowerCase().includes(searchValue.toLowerCase());
        return matchesGroup && matchesFilter;
      });
      return { label: group, properties };
    })
    .filter(group => group.properties.length > 0);

  /** Updates `shownColumns` in cfgn insights Redux store */
  function onShownColumnsCheckChange(propertyName: string, isChecked: boolean): void {
    const updatedShownColumns = (
      isChecked ? [...shownColumns, propertyName] : shownColumns.filter(x => x !== propertyName)
    ) as AllInsightsProperties[];

    dispatch(setShownColumns({ shownColumns: updatedShownColumns }));
  }

  return (
    <div data-cmptype="CfgnInsightsViewSettingsBtn">
      <div ref={btnRef}>
        <Button
          text={t('View settings')}
          Svg={GearIcon}
          isDropdownButton
          variant="Outlined"
          onClick={(): void => setViewSettingsOpen(x => !x)}
        />
      </div>

      <CbnPopover
        open={viewSettingsOpen}
        anchorEl={btnRef.current}
        data-cmptype="CfgnInsightsViewSettingsBtn-popover"
        onClose={(): void => setViewSettingsOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <CbnCard>
          <CbnCardBody>
            <div className="flex flex-col gap-2">
              <span>{t('Select displayed configuration properties.')}</span>
              <CbnSearchInput
                placeholder={t('Find property name')}
                value={searchValue}
                onValueChange={(newValue): void => setSearchValue(newValue)}
              />

              <hr className="border-neutral-50" />

              <div className="grid grid-cols-[1fr_auto] items-center gap-x-2">
                {groupedProperties.map(group => (
                  <Fragment key={group.label}>
                    <div className="col-span-2 py-4 text-s-regular uppercase text-neutral-60">{t(group.label)}</div>

                    {group.properties.map(property => (
                      <Fragment key={property.name}>
                        <label className="cursor-pointer" htmlFor={`${prefixId}${property.name}`}>
                          {t(property.label)}
                        </label>

                        <Switch
                          id={`${prefixId}${property.name}`}
                          checked={shownColumns.includes(property.name)}
                          onValueChanged={(x): void => onShownColumnsCheckChange(property.name, x)}
                        />
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </div>
            </div>
          </CbnCardBody>
        </CbnCard>
      </CbnPopover>
    </div>
  );
};
