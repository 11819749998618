import { Skeleton } from '@mui/material';
import { EyeShowVisibleIcon, PlusIcon } from 'assets/icons';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { getPublishedCfgrLink } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { CfgrTemplate } from 'services/cfgrs/cfgrs.service';

type CreateCfgrTemplatePickerCardProps = {
  template: CfgrTemplate;
  onCreateClick?: (id: string) => void;
};

export const CreateCfgrTemplatePickerCard: React.FC<CreateCfgrTemplatePickerCardProps> = ({
  template,
  onCreateClick,
}) => {
  const { t } = useTypedTranslation();

  return (
    <CbnCard data-cmptype="CreateCfgrTemplatePickerCard" variant="nested">
      <div className="overflow-hidden">
        <img
          className="h-[12.375rem] object-contain"
          src={template.imageUrl}
          alt={t(`Preview image of configurator template "${template.name}"`)}
        />
        <div className="flex flex-1 flex-col pb-3 pl-3 pr-4">
          <div className="text-m-medium">{template.name}</div>
          <div className="flex-1 text-m-regular text-neutral-90">{template.description}</div>
          <div className="mt-2 flex justify-between">
            <LinkButton
              variant="Outlined"
              Svg={EyeShowVisibleIcon}
              href={getPublishedCfgrLink(template.companyName, template.cfgrName, null, false)}
              target="_blank"
              title={t('Preview template')}
            />
            <Button
              variant="Secondary"
              Svg={PlusIcon}
              text={t('Create')}
              onClick={(): void => onCreateClick?.(template.id)}
            />
          </div>
        </div>
      </div>
    </CbnCard>
  );
};

export const CreateCfgrTemplatePickerCardSkeleton: React.FC = () => {
  return (
    <CbnCard variant="nested">
      <div className="flex h-[19.625rem] flex-col">
        <Skeleton className="m-2 grow transform-none" />
        <div className="flex flex-col pb-3 pl-3 pr-4">
          <Skeleton className="h-5" />
          <Skeleton className="h-10" />
          <div className="mt-2 flex justify-between">
            <Skeleton className="size-9 transform-none" />
            <Skeleton className="h-9 w-24 transform-none" />
          </div>
        </div>
      </div>
    </CbnCard>
  );
};
