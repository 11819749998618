import { useParams } from 'react-router-dom';
import { AcceptInvitationParams } from 'components/invitation/accept-company-invitation-wrapper';
import { LoginCard } from 'components/login/login-card';
import { buildCompanyInvitationSubPagePath } from 'services/routes/company-invitation-routes.service';

export const AcceptCompanyInvitationLogin: React.FC = () => {
  const { companyName, inviteToken, inviteRecipient } = useParams() as AcceptInvitationParams;

  return (
    <div data-cmptype="AcceptCompanyInvitationLogin">
      <LoginCard
        registerLinkPath={buildCompanyInvitationSubPagePath(companyName, inviteToken, 'register', inviteRecipient)}
        inviteData={{ companyName, inviteToken, inviteRecipient }}
      />
    </div>
  );
};
