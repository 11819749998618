import reportWebVitals from './report-web-vitals';
import './styles/index.css';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { LicenseInfo } from '@mui/x-date-pickers-pro';
import { App } from 'app';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { DialogContextProvider } from 'components/common/dialog-context';
import { NotificationProvider } from 'components/notifications/notification-provider';
import { mapOrRedirectExtJSRoute } from 'helper/extjs-route/extjs-route.helper';
import { getMaterial } from 'services/3d/materials.service';
import { store } from 'services/store/store.service';
import { aiReactPlugin } from 'services/telemetry/telemetry.service';

// activate MUI x-date-pickers pro license
LicenseInfo.setLicenseKey(
  '63048a91a9700037241811e936c86680Tz04OTAwOSxFPTE3NDU0OTI2MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

// Call this as soon as possible
// If a redirect to ExtJS happens it shouldn't load anything from React
mapOrRedirectExtJSRoute();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <AppInsightsContext.Provider value={aiReactPlugin}>
      <Provider store={store}>
        <DialogContextProvider>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </DialogContextProvider>
      </Provider>
    </AppInsightsContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.Cbn = {
  // Injected asset functions are required for the 3d viewer to work properly
  // The implementations are different, depending on the environment the 3d viewer is used
  // => asset editor vs configurator
  Assets: {
    // receive material from the associated material asset
    getMaterial,

    // currently unused
    assertMaterialExists(materialId: string): boolean {
      return true;
    },
  },
  utils: {
    Viewer3dHelper: {
      // currently unused
      getCfgrParentDomElementForInspector(): HTMLElement | undefined {
        return undefined;
      },
    },
  },
};
