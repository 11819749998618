import { ReactElement, useCallback } from 'react';
import React from 'react';
import { InfoIcon } from 'assets/icons';
import { SchemaNameInput, SchemaNameInputResult } from 'components/common/schema-name-input';
import { LinkButton } from 'controls/button/link-button';
import { Checkbox } from 'controls/checkbox/checkbox';
import { Icon } from 'controls/icon/icon';
import { RadioButtonEntry, RadioButtonGroup, RadioButtonValue } from 'controls/radio-button/radio-button-group';
import { TableCell, TableRow } from 'controls/table';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { getSchemaNamePart } from 'helper/assets/assets.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { selectAllBundles } from 'slices/assets/assets.slice';
import {
  AssetBundleMappingTarget,
  changeTargetBundleEditMode,
  selectCurrentBlueprint,
  setBlueprintTargetBundle,
  setTargetBundleAutoPublish,
} from 'slices/replicator/replicator.slice';

type ReplicatorTargetsBundleRowsProps = {
  schemaName: string;
  targetBundle: AssetBundleMappingTarget;
};

export const ReplicatorTargetsBundleRows: React.FC<ReplicatorTargetsBundleRowsProps> = ({
  schemaName,
  targetBundle,
}) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const bundles = useAppSelector(selectAllBundles);

  const blueprint = useAppSelector(selectCurrentBlueprint);
  const idFromSchema = getSchemaNamePart(schemaName, 'bundle');
  const bundleRef = bundles.find(b => b.id === idFromSchema);

  const isAssignedToCfgr = Object.values(blueprint.cfgrs).some(c =>
    c.assignedAssetBundles.some(b => b.assetBundleName === idFromSchema)
  );
  const bundleNameChanged =
    blueprint.target.originalMapping.assetBundles[schemaName].bundleName !== targetBundle.bundleName;

  // when coming from a cross-env the assignments are unknown, so we show the info too
  const showAliasNameInfo = isAssignedToCfgr || blueprint.blueprintSasUri;

  const onTargetBundleChange = useCallback(
    (schemaName: string, result: SchemaNameInputResult): void => {
      dispatch(
        setBlueprintTargetBundle({
          schemaName: schemaName,
          name: result.uniqueId,
          displayName: result.displayName,
          hasErrors: result.hasErrors,
        })
      );
    },
    [dispatch]
  );

  const onCreateNewBundleClicked = (value: RadioButtonValue | undefined): void => {
    dispatch(changeTargetBundleEditMode({ schemaName: schemaName, inEditMode: Boolean(value) }));
  };

  const onBundleAutoPublishChange = (value: boolean): void => {
    dispatch(setTargetBundleAutoPublish({ schemaName, value }));
  };

  const userSelectedNewBundle = targetBundle.isExistingBundle && targetBundle.inEditMode;
  const radioButtonEntries: RadioButtonEntry[] = [
    { value: 0, text: t('Add version to existing bundle') },
    { value: 1, text: t('Create new asset bundle') },
  ];

  const bundleInput = (
    <SchemaNameInput
      input={{ displayName: targetBundle.bundleDisplayName, uniqueId: targetBundle.bundleName }}
      unavailableIds={blueprint.target.existingNames.assetBundleNames}
      unavailableNames={blueprint.target.existingNames.assetBundleDisplayNames}
      onChange={(result): void => onTargetBundleChange(schemaName, result)}
      customWrapper={(children): ReactElement => <TableCell>{children}</TableCell>}
    />
  );

  const aliasNameInfo = (
    <Icon
      Svg={InfoIcon}
      className="w-5"
      title={
        <TooltipContent
          header={t('Alias names in configurator will remain unchanged')}
          detail={
            <p className="flex flex-col gap-2">
              {t(
                "Please keep in mind that the new bundle name won't be applied as alias name inside the copied configurators."
              )}
              <LinkButton
                variant="Secondary"
                text={t('Open docs for details')}
                href="https://docs.combeenation.com/docs/copying-configurators-and-asset-bundles#asset-bundle-aliases"
              />
            </p>
          }
        />
      }
      titleProps={{ interactive: true }}
    />
  );

  return (
    <>
      <TableRow className={userSelectedNewBundle ? 'shadow-none' : undefined}>
        <TableCell className={targetBundle.isExistingBundle ? 'align-top' : undefined}>
          <div className="flex items-center gap-2">
            {bundleRef ? bundleRef.displayName : idFromSchema}
            {showAliasNameInfo && bundleNameChanged && aliasNameInfo}
          </div>
        </TableCell>
        {targetBundle.isExistingBundle ? (
          <TableCell colSpan={2}>
            <div className="flex flex-col gap-2">
              <span>{t('This bundle name already exists in the target.')}</span>
              <RadioButtonGroup
                entries={radioButtonEntries}
                value={Number(targetBundle.inEditMode)}
                onValueChange={onCreateNewBundleClicked}
              />
            </div>
          </TableCell>
        ) : (
          bundleInput
        )}
        <TableCell className="text-center">
          <Checkbox checked={targetBundle.AutoPublish ?? false} onValueChanged={onBundleAutoPublishChange} />
        </TableCell>
      </TableRow>
      {userSelectedNewBundle && (
        <TableRow>
          <TableCell></TableCell>
          {bundleInput}
          <TableCell></TableCell>
        </TableRow>
      )}
    </>
  );
};
