import { useCallback, useEffect, useRef, useState } from 'react';
import { AssetBundleVersionsTableDrafts } from 'components/asset-editor/bundle-version-management/asset-bundle-versions-table-drafts';
import { AssetBundleVersionsTableFilter } from 'components/asset-editor/bundle-version-management/asset-bundle-versions-table-filter';
import { AssetBundleVersionsTablePublished } from 'components/asset-editor/bundle-version-management/asset-bundle-versions-table-published';
import { Button } from 'controls/button/button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { CbnTabPanel } from 'controls/cbn-tab-panel/cbn-tab-panel';
import { IdentityTypes } from 'generated/identity-types';
import { useNavigateToAssetEditor } from 'hooks/assets/assets.hooks';
import { useConfirmDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { fetchDraftAssetBundleVersions, fetchPublishedAssetBundleVersions } from 'services/assets/assets.service';
import {
  AssetBundleVersionDraft,
  AssetBundleVersionPublished,
  deleteAssetBundleVersion,
  selectRelevantBundleVersions,
  selectSelectedAssetPath,
} from 'slices/assets/assets.slice';

type BundleVersionTypeTabPanelKey = 'Drafts' | 'Published';

const _LOAD_VERSIONS_PAGE_SIZE = 50;

export const ManageAssetBundleVersions: React.FC = () => {
  const { t, tDeleteBundleVersion } = useTypedTranslation();
  const navigateToAssetEditor = useNavigateToAssetEditor();
  const dispatch = useAppDispatch();
  const confirm = useConfirmDialog();

  const { companyId, bundleId, bundleVersion: selectedBundleVersion } = useAppSelector(selectSelectedAssetPath);
  const fetchStateBundleVersions = useAppSelector(state => state.assets.fetchState.bundleVersions);
  const relevantBundleVersions = useAppSelector(selectRelevantBundleVersions);
  // "last version" check can't be done with published/draft version data shown on this page as filters might be set
  // use "relevant bundles" from store instead
  const isLastVersion = relevantBundleVersions.length === 1;

  const [tabPanelKey, setTabPanelKey] = useState<BundleVersionTypeTabPanelKey>('Drafts');
  const [draftVersions, setDraftVersions] = useState<AssetBundleVersionDraft[]>([]);
  const [publishedVersions, setPublishedVersions] = useState<AssetBundleVersionPublished[]>([]);
  const [isLoadingDrafts, setIsLoadingDrafts] = useState(true);
  const [isLoadingPublished, setIsLoadingPublished] = useState(true);
  const [draftsFilter, setDraftsFilter] = useState<IdentityTypes | undefined>(undefined);
  const [publishedFilter, setPublishedFilter] = useState<IdentityTypes | undefined>(undefined);
  const [draftsFilterApplied, setDraftsFilterApplied] = useState(true);
  const [publishedFilterApplied, setPublishedFilterApplied] = useState(true);
  const [hasMoreDraftVersions, setHasMoreDraftVersions] = useState(false);
  const [hasMorePublishedVersions, setHasMorePublishedVersions] = useState(false);

  // continuation tokens indicate from where to start the next fetch request
  // if token is null it means that there are no more versions to fetch
  const draftsContTokenRef = useRef<string | null>(null);
  const publishedContTokenRef = useRef<string | null>(null);

  const onLoadDraftVersion = useCallback(
    async (reset?: boolean) => {
      setIsLoadingDrafts(true);

      const usedToken = reset || !draftsContTokenRef.current ? undefined : draftsContTokenRef.current;

      const response = await fetchDraftAssetBundleVersions(
        companyId,
        bundleId,
        usedToken,
        _LOAD_VERSIONS_PAGE_SIZE,
        draftsFilter
      );
      setDraftVersions(versions => {
        const newVersions = Object.values(response.assetBundleVersions) as AssetBundleVersionDraft[];
        if (reset) {
          return newVersions;
        } else {
          return [...versions, ...newVersions];
        }
      });

      draftsContTokenRef.current = response.continuationToken;
      setHasMoreDraftVersions(!!draftsContTokenRef.current);
      setIsLoadingDrafts(false);
    },
    [bundleId, companyId, draftsFilter]
  );

  useEffect(() => {
    if (fetchStateBundleVersions === 'Fetched') {
      // fetch version on mount and whenever fetching "relevant bundles" has been finished, as this indicates that
      // bundles versions have changed and the table is probably not up-to-date anymore
      onLoadDraftVersion(true);
    }
  }, [onLoadDraftVersion, fetchStateBundleVersions]);

  const onLoadPublishedVersion = useCallback(
    async (reset?: boolean) => {
      setIsLoadingPublished(true);

      const usedToken = reset || !publishedContTokenRef.current ? undefined : publishedContTokenRef.current;

      const response = await fetchPublishedAssetBundleVersions(
        companyId,
        bundleId,
        usedToken,
        _LOAD_VERSIONS_PAGE_SIZE,
        publishedFilter
      );
      setPublishedVersions(versions => {
        const newVersions = Object.values(response.assetBundleVersions) as AssetBundleVersionPublished[];
        if (reset) {
          return newVersions;
        } else {
          return [...versions, ...newVersions];
        }
      });

      publishedContTokenRef.current = response.continuationToken;
      setHasMorePublishedVersions(!!publishedContTokenRef.current);
      setIsLoadingPublished(false);
    },
    [bundleId, companyId, publishedFilter]
  );

  useEffect(() => {
    if (fetchStateBundleVersions === 'Fetched') {
      onLoadPublishedVersion(true);
    }
  }, [onLoadPublishedVersion, fetchStateBundleVersions]);

  const onDeleteBundleVersionClicked = async (draftToDelete: AssetBundleVersionDraft): Promise<void> => {
    const deleteConfirmed = await confirm(tDeleteBundleVersion(draftToDelete.displayName), {
      variant: 'Danger',
      confirmBtnText: t('Delete draft'),
    });

    if (deleteConfirmed) {
      await dispatch(deleteAssetBundleVersion({ companyId, bundleId, bundleVersion: draftToDelete.id }));

      if (draftToDelete.id === selectedBundleVersion) {
        // selected draft not available anymore, navigate away from this version
        navigateToAssetEditor({ companyId, bundleId }, true);
      }

      setDraftVersions(draftVersions => draftVersions.filter(version => version.id !== draftToDelete.id));
    }
  };

  return (
    <div data-cmptype="ManageAssetBundleVersions" className="flex h-full flex-col">
      <CbnCard>
        <CbnCardHeader
          title={t('Manage bundle versions')}
          subText={t('Select, view information and delete asset bundle versions.')}
        />
        <CbnTabPanel
          entries={[
            {
              key: 'Drafts',
              name: t('Draft versions'),
              component: (
                <div className="flex w-full flex-col">
                  <AssetBundleVersionsTableFilter
                    filter={draftsFilter}
                    filterTypeText={t('created by')}
                    onApplyFilter={setDraftsFilter}
                    onChangeFilterApplied={setDraftsFilterApplied}
                  />
                  <AssetBundleVersionsTableDrafts
                    draftVersions={draftVersions}
                    isLoading={isLoadingDrafts}
                    isLastVersion={isLastVersion}
                    onDeleteDraftVersionClicked={onDeleteBundleVersionClicked}
                  />
                  <div className="self-end px-5 py-2">
                    <Button
                      variant="TextInline"
                      text={t('Show more...')}
                      onClick={(): Promise<void> => onLoadDraftVersion()}
                      disabled={!draftsFilterApplied || !hasMoreDraftVersions}
                      title={
                        !draftsFilterApplied
                          ? t('Filter is not applied')
                          : !hasMoreDraftVersions
                            ? t('No more versions available')
                            : ''
                      }
                    />
                  </div>
                </div>
              ),
            },
            {
              key: 'Published',
              name: t('Published versions'),
              component: (
                <div className="flex w-full flex-col">
                  <AssetBundleVersionsTableFilter
                    filter={publishedFilter}
                    filterTypeText={t('published by')}
                    onApplyFilter={setPublishedFilter}
                    onChangeFilterApplied={setPublishedFilterApplied}
                  />
                  <AssetBundleVersionsTablePublished
                    publishedVersions={publishedVersions}
                    isLoading={isLoadingPublished}
                  />
                  <div className="self-end px-5 py-2">
                    <Button
                      variant="TextInline"
                      text={t('Show more...')}
                      onClick={(): Promise<void> => onLoadPublishedVersion()}
                      disabled={!publishedFilterApplied || !hasMorePublishedVersions}
                      title={
                        !publishedFilterApplied
                          ? t('Filter is not applied')
                          : !hasMorePublishedVersions
                            ? t('No more versions available')
                            : ''
                      }
                    />
                  </div>
                </div>
              ),
            },
          ]}
          value={tabPanelKey}
          onTabValueChange={(key): void => setTabPanelKey(key as BundleVersionTypeTabPanelKey)}
          addHorizontalHeaderMargin
        />
      </CbnCard>
    </div>
  );
};
