import { Card } from 'controls/card/card';
import { Icon, SvgComponent } from 'controls/icon/icon';

export type CbnCaptionCardProps = {
  headerText: string;
  // TODO: maybe restrict that to a string only, problem seems to be manual line breaks
  subText: React.ReactNode;
  Svg: SvgComponent;
  /**
   * This can be any React node, whereas the idea is that these are "action" buttons
   * It will be located on the right bottom side of the card
   */
  action: React.ReactNode;
  children?: React.ReactNode;
};

export const CbnCaptionCard: React.FC<CbnCaptionCardProps> = ({ headerText, subText, Svg, action, children }) => {
  return (
    // TODO: try to build this based on `CbnCard` => consistent styling etc...
    <Card data-cmptype="CaptionCard" noBodyMargin>
      <div className="m-6 flex grow flex-col">
        <Icon className="mb-5 h-10 w-10 text-brand-green2" Svg={Svg} />
        {/* header and sub text should be aligned on top, but the action button should be located on the right
            bottom side of the card. This will get important if multiple caption cards are used with different amounts
            of lines in the sub text */}
        <div className="flex grow flex-row items-start justify-between gap-4">
          <div className="flex flex-col">
            <span className="text-heading-s text-neutral-100">{headerText}</span>
            <span className="text-m-regular text-neutral-70">{subText}</span>
          </div>
          {action && <div className="flex self-end">{action}</div>}
        </div>
      </div>
      {children}
    </Card>
  );
};
