import React, { useState } from 'react';
import { AssetDetailModalsErrorInfo } from 'components/asset-editor/details/modals/asset-detail-modals-error-info';
import { AssetRelocateActions } from 'components/asset-editor/details/modals/asset-relocate-modal';
import { AssetFolderSelection } from 'components/asset-editor/folders/asset-folder-selection';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { FormItem } from 'controls/form-item/form-item';
import { isUnusedAssetKey } from 'helper/assets/assets.helper';
import { isValidHiveIdentifier } from 'helper/hive/hive.helper';
import { NEW_FOLDER_KEY, useNavigateToAssetEditor } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { FailedAssetsInfo, RelocateAssetRequestItems } from 'services/assets/assets.service';
import { Asset, AssetPath, copyAsset, moveAsset } from 'slices/assets/assets.slice';

type BulkRelocateActions = Exclude<AssetRelocateActions, 'Rename'>;

type AssetBulkRelocateModalProps = {
  assets: Asset[];
  action: BulkRelocateActions;
  onClose: () => void;
};

export const AssetBulkRelocateModal: React.FC<AssetBulkRelocateModalProps> = ({ assets, action, onClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();
  const [errorInfo, setErrorInfo] = useState<FailedAssetsInfo>();

  const navigateToAssetEditor = useNavigateToAssetEditor();

  const existingAssets = useAppSelector(state => state.assets.assets);

  const [selectedFolderOfDD, setSelectedFolderOfDD] = useState('');
  const [newFolderName, setNewFolderName] = useState('');
  const selectedFolder = selectedFolderOfDD === NEW_FOLDER_KEY ? newFolderName : selectedFolderOfDD;
  const [isProcessingAction, setIsProcessingAction] = useState(false);

  const textMapping: Record<BulkRelocateActions, { header: string; confirmBtn: string }> = {
    Copy: { header: t('Copy assets'), confirmBtn: t('Copy assets') },
    Move: { header: t('Move assets'), confirmBtn: t('Move assets') },
  };

  const onActionConfirmed = async (): Promise<void> => {
    setIsProcessingAction(true);
    if (errorInfo !== undefined) {
      // reset errors if still pending from previous confirm clicks
      setErrorInfo(undefined);
    }

    const { companyId, bundleId, bundleVersion } = assets[0].path;
    const relocateItems: RelocateAssetRequestItems[] = assets.map(a => ({
      sourceFolder: a.path.folderId,
      sourceName: a.path.assetId,
      targetFolder: selectedFolder,
      targetName: a.path.assetId,
      overwrite: false,
    }));

    const baseParams = { companyId, bundleId, bundleVersion };
    let result;
    if (action === 'Copy') {
      const copyParams = { ...baseParams, copyItems: relocateItems };
      result = await dispatch(copyAsset(copyParams)).unwrap();
    } else {
      const moveParams = { ...baseParams, moveItems: relocateItems };
      result = await dispatch(moveAsset(moveParams)).unwrap();
    }

    if (result.success) {
      // Remove the current route from the history if the current asset doesn't exist anymore after move or rename...
      const removeCurrentRouteFromHistory = action !== 'Copy';
      navigateToAssetEditor(
        {
          companyId,
          bundleId,
          bundleVersion: bundleVersion,
          folderId: selectedFolder,
        },
        removeCurrentRouteFromHistory
      );
      onClose();
    } else {
      setErrorInfo(result.failedAssets);
    }

    setIsProcessingAction(false);
  };

  const targetNameClashes = assets.filter(a => {
    const assetPath: AssetPath = { ...a.path, folderId: selectedFolder };
    const assetKeys = Object.keys(existingAssets);
    return !isUnusedAssetKey(assetPath, assetKeys);
  });

  const isInvalidInput = selectedFolderOfDD === NEW_FOLDER_KEY && !isValidHiveIdentifier(selectedFolder);
  const hasErrors = isInvalidInput || targetNameClashes.length > 0;
  const inputErrorMsg =
    !isInvalidInput && targetNameClashes.length > 0
      ? t('One or more assets with the same name already exist in the target folder!')
      : undefined;

  const onAssetFolderSelectionChange = (selectValue: string, newFolderInputValue: string): void => {
    setSelectedFolderOfDD(selectValue);
    setNewFolderName(newFolderInputValue);
  };

  return (
    <ModalDialog
      data-cmptype="RelocateAsset"
      variant="NoIcon"
      header={textMapping[action].header}
      confirmText={textMapping[action].confirmBtn}
      actions={{ onConfirm: () => onActionConfirmed(), onCancel: () => onClose() }}
      confirmDisabled={hasErrors}
      isProcessingConfirm={isProcessingAction}
    >
      <div className="flex grow flex-col gap-4">
        <FormItem labelContent={t('Folder')}>
          <AssetFolderSelection showNewFolderOption onChange={onAssetFolderSelectionChange} />
        </FormItem>
        {inputErrorMsg && <p className="text-s-regular">{inputErrorMsg}</p>}
        {errorInfo && <AssetDetailModalsErrorInfo errorInfo={errorInfo} />}
      </div>
    </ModalDialog>
  );
};
