import React, { HTMLAttributes, useEffect, useState } from 'react';
import { Input, InputProps } from 'controls/input/input';

export type NumberOrNull = number | null;

type NumberInputModes = Extract<HTMLAttributes<HTMLInputElement>['inputMode'], 'decimal' | 'numeric'>;

export type NumberInputProps = Pick<
  InputProps,
  'placeholder' | 'onBlur' | 'disabled' | 'id' | 'error' | 'endAdornment' | 'helperText' | 'onKeyDown' | 'autoFocus'
> & {
  value?: NumberOrNull;
  onChange?: (value: NumberOrNull, isInvalid: boolean) => void;
  inputMode: NumberInputModes;
  onlyPositive?: boolean;
  restoreValidValueOnBlur?: boolean;
};

export const NumberInput: React.FC<NumberInputProps> = ({
  inputMode,
  value,
  onChange,
  onBlur,
  error,
  id,
  onlyPositive,
  restoreValidValueOnBlur,
  ...props
}) => {
  /**
   * The input prop `value` should still be seen as "source of truth"
   * The string state solely exists to handle in-between states while the users edits a value
   * Should prevent:
   * - User types "1." => the dot instantly vanishes due to number parsing
   * - User deletes whole content to write a new number -> instantly changes to "0"
   * - etc.
   */
  const [stringVal, setStringVal] = useState('');
  const [isInputError, setIsInputError] = useState(false);

  useEffect(() => {
    if (!isInputError) {
      setStringVal(value !== undefined && value !== null ? value.toString() : '');
    }
  }, [value, isInputError]);

  let pattern = inputMode === 'decimal' ? '[0-9]*[.,]?[0-9]*' : '\\d*';
  pattern = onlyPositive ? pattern : '-?' + pattern;

  const onChangeInput = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    const isInvalidInput = !evt.target.validity.valid;
    const curValue = evt.target.value;

    setStringVal(curValue);
    setIsInputError(isInvalidInput);

    if (isInvalidInput) {
      onChange?.(null, isInvalidInput);
      return;
    }

    const normalizedVal = curValue.replace(',', '.');
    const valAsNum = inputMode === 'decimal' ? parseFloat(normalizedVal) : parseInt(normalizedVal);
    onChange?.(isNaN(valAsNum) ? null : valAsNum, isInvalidInput);
  };

  const onBlurInput = (evt: React.FocusEvent<HTMLInputElement>): void => {
    if (restoreValidValueOnBlur) {
      // the input prop `value` is the main "controlled & valid state"
      // if the user leaves the control it gets restored accordingly
      const inputAsStr = value?.toString() ?? '';
      if (inputAsStr !== stringVal) {
        setStringVal(inputAsStr);
        setIsInputError(false);
      }
    }

    onBlur?.(evt);
  };

  return (
    <Input
      type="text" /* type `number` comes with potential usability issues like increment/decrement on scroll */
      inputProps={{
        id: id,
        inputMode: inputMode,
        pattern: pattern,
      }}
      value={stringVal}
      onChange={onChangeInput}
      onBlur={onBlurInput}
      error={isInputError || error}
      textAlignRight
      {...props}
    />
  );
};
