import { Navigate, Outlet, useParams } from 'react-router-dom';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { CompanyIdRouteParams } from 'services/routes/common-routeparams.service';
import { PATHS } from 'services/routes/paths.service';
import { CompanyPermissions, PermissionBundles, SystemPermissions } from 'slices/permission/permission.slice';

type AuthorizedRouteProps = {
  permission: CompanyPermissions | SystemPermissions | PermissionBundles;
  /**
   * Default is `Dashboard`
   */
  fallbackRoute?: string;
  fallbackCmp?: React.ReactNode;
};

export const AuthorizedRouteWrapper: React.FC<AuthorizedRouteProps> = ({
  permission,
  fallbackRoute = PATHS.configurators,
  fallbackCmp,
}) => {
  const companiesFetched = useAppSelector(state => state.companyData.companiesFetched);
  const { companyId } = useParams<CompanyIdRouteParams>();

  const isAuthorized = usePermission(permission, companyId);

  const fallback = fallbackCmp ? <>{fallbackCmp}</> : <Navigate to={fallbackRoute} />;

  // Don't redirect/navigate as long as the companies aren't fetched, which contain the permissions
  // Otherwise the redirect would happen too early
  return companiesFetched !== 'Fetched' ? <span>Loading...</span> : isAuthorized ? <Outlet /> : fallback;
};
