import { useGridApiContext } from '@mui/x-data-grid-pro';
import { GridStateColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { useEffect, useRef } from 'react';

/**
 * Guarantee a minimum width when the edit control gets mounted
 */
export const useMinEditWidth = (field: string, colDef: GridStateColDef, minWidth = 180): void => {
  const apiRef = useGridApiContext();
  const storedWidth = useRef(colDef.computedWidth);

  useEffect(() => {
    // NOTE: `colDef.minWidth` could also be set, if we want to fully prevent resizing below the width during edit
    const width = storedWidth.current;
    const api = apiRef.current;

    if (api && width < minWidth) {
      api.setColumnWidth(field, minWidth);
      return (): void => api.setColumnWidth(field, storedWidth.current);
    }
  }, [apiRef, field, minWidth]);

  useEffect(() => {
    const cleanupFn = apiRef.current.subscribeEvent('columnWidthChange', ({ width }) => {
      storedWidth.current = width;
    });

    return cleanupFn;
  }, [apiRef, colDef, minWidth]);
};
