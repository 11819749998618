import { ValueOptions } from '@mui/x-data-grid-pro';
import {
  ConfigRow,
  EDIT_GRID_FIELD_KEYS,
} from 'components/asset-editor/details/modals/datasource-edit/edit-grid/asset-datasource-edit-grid';
import { SelectOption } from 'controls/select/select';
import { AssetTypes } from 'generated/asset-types';
import { DataSourceColumnTypes } from 'generated/datasource-column-types';
import { getTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { Assets, MATERIALS_3D_FOLDER_NAME } from 'slices/assets/assets.slice';

const _LINKED_ASSETS_KEY_PREFIX = `${DataSourceColumnTypes.LinkedAsset}|`;

const _LINKABLE_ASSET_TYPES = [
  AssetTypes.Image,
  AssetTypes.File,
  AssetTypes.Text,
  AssetTypes.BabylonJs,
  AssetTypes.Material,
] as const;

const _DATA_SOURCE_COLUMN_TO_HIVE_TYPES_MAP: Record<DataSourceColumnTypes, string> = {
  [DataSourceColumnTypes.String]: 'Text',
  [DataSourceColumnTypes.Double]: 'Number',
  [DataSourceColumnTypes.Bool]: 'Logic',
  [DataSourceColumnTypes.ListString]: 'List<Text>',
  [DataSourceColumnTypes.ListDouble]: 'List<Number>',
  [DataSourceColumnTypes.ListBool]: 'List<Logic>',
  // there is no Hive type for this, so we use the column type name itself
  [DataSourceColumnTypes.FileUrl]: 'FileUrl',
  // this will be overwritten by the `tAssetLink` translation
  [DataSourceColumnTypes.LinkedAsset]: 'LinkedAsset',
};

export type LinkableAssetTypes = (typeof _LINKABLE_ASSET_TYPES)[number];

export type DataSourceColumnTypesExtended<T extends DataSourceColumnTypes = DataSourceColumnTypes> =
  T extends DataSourceColumnTypes.LinkedAsset ? { type: T; assetType: LinkableAssetTypes } : { type: T };

export const getUpdatedFolderSelection = (
  type: DataSourceColumnTypesExtended,
  currentFolder: string | undefined,
  availableFolders: SelectOption[]
): string | undefined => {
  if (type.type !== DataSourceColumnTypes.LinkedAsset) {
    return undefined;
  } else if (type.assetType === AssetTypes.Material) {
    const materialFolder = availableFolders.find(folder => folder.value === MATERIALS_3D_FOLDER_NAME);
    return (materialFolder?.value as string) ?? undefined;
  } else if (currentFolder) {
    return currentFolder;
  } else {
    return (availableFolders[0]?.value as string) ?? undefined;
  }
};

export const getColumnTypeOptions = (): DataSourceColumnTypesExtended[] => {
  // convert the enumerations into type options
  // "LinkedAsset" column type will be spread into its dedicated asset types
  let colTypes = Object.entries(DataSourceColumnTypes) as [string, DataSourceColumnTypes][];
  colTypes.splice(0, colTypes.length / 2);
  colTypes = colTypes.filter(colType => colType[1] !== DataSourceColumnTypes.LinkedAsset);

  const typeOptions: DataSourceColumnTypesExtended[] = colTypes.map(colType => ({ type: Number(colType[1]) }));
  _LINKABLE_ASSET_TYPES.forEach(assetType =>
    typeOptions.push({
      type: DataSourceColumnTypes.LinkedAsset,
      assetType: assetType,
    })
  );
  return typeOptions;
};

export const getFolderOptions = (row: ConfigRow, availableFolders: SelectOption[]): ValueOptions[] => {
  const currentType = row?.[EDIT_GRID_FIELD_KEYS.TypeDef];
  const materialFolderOnly =
    currentType?.type === DataSourceColumnTypes.LinkedAsset && currentType?.assetType === AssetTypes.Material;

  const folderOptions: ValueOptions[] = availableFolders
    .filter(f => (materialFolderOnly ? f.value === MATERIALS_3D_FOLDER_NAME : true))
    .map(f => ({ label: f.text ?? f.value, value: f.value }));
  const currentFolder = row?.[EDIT_GRID_FIELD_KEYS.LinkedAssetFolder];

  if (!currentFolder || availableFolders.find(f => f.value === currentFolder)) {
    return folderOptions;
  } else {
    return [{ value: currentFolder, label: currentFolder }, ...folderOptions];
  }
};

/**
 * Receive all assets that are located in the current folder selection.
 * Already creates options for data grid component.
 */
export const getDefaultAssetOptions = (row: ConfigRow, availableAssets: Assets): ValueOptions[] => {
  const currentFolder = row?.[EDIT_GRID_FIELD_KEYS.LinkedAssetFolder];
  if (!currentFolder) {
    return [];
  }

  const assetsInFolder = Object.values(availableAssets).filter(asset => asset.path.folderId === currentFolder);
  const valueOptions: ValueOptions[] = assetsInFolder
    .sort((assetA, assetB) => assetA.path.assetId.localeCompare(assetB.path.assetId))
    .map(asset => ({
      value: asset.path.assetId,
      label: asset.path.assetId,
    }));

  return valueOptions;
};

export function createTypeSelectionKey(typeDef: DataSourceColumnTypesExtended): string {
  return typeDef.type === DataSourceColumnTypes.LinkedAsset
    ? `${_LINKED_ASSETS_KEY_PREFIX}${typeDef.assetType}`
    : typeDef.type.toString();
}

export function getTypeFromSelectionKey(key: string): DataSourceColumnTypesExtended {
  if (key.startsWith(_LINKED_ASSETS_KEY_PREFIX)) {
    const assetType = Number(key.replace(_LINKED_ASSETS_KEY_PREFIX, ''));
    return { type: DataSourceColumnTypes.LinkedAsset, assetType: assetType };
  } else {
    return { type: Number(key) };
  }
}

/**
 * Returns meaningful UI text for data source column type options.
 * Considers asset links and associated hive types.
 */
export function getTypeOptionLabel(columnType: DataSourceColumnTypes, linkedAssetType?: AssetTypes): string {
  const { t, tLinkToAsset } = getTypedTranslation();

  const label =
    columnType === DataSourceColumnTypes.LinkedAsset && linkedAssetType
      ? // dedicated string for linked asset
        tLinkToAsset(AssetTypes[linkedAssetType])
      : // exchange by hive name
        t(_DATA_SOURCE_COLUMN_TO_HIVE_TYPES_MAP[columnType]);

  return label;
}
