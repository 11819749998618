import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { CopyIcon, DeleteIcon, InfoIcon, PlusIcon } from 'assets/icons';
import { CfgrLockInfo } from 'components/cfgrs/cfgr-lock-info';
import { CfgrPicker } from 'components/cfgrs/cfgr-picker';
import { CopyCfgrModal } from 'components/cfgrs/copy-cfgr-modal';
import { CreateCfgrTemplatePicker } from 'components/create-cfgr-template-picker/create-cfgr-template-picker';
import { CreateCfgrTemplatePickerHeader } from 'components/create-cfgr-template-picker/create-cfgr-template-picker-header';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnCaptionCard } from 'controls/cbn-card/implementations/cbn-caption-card';
import { CbnPopover } from 'controls/cbn-popover/cbn-popover';
import { useConfirmDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { deleteConfigurator } from 'services/cfgrs/cfgrs.service';
import { CFGN_INSIGHTS_BASE_PATH } from 'services/routes/cfgrs-routes.service';
import { PATHS } from 'services/routes/paths.service';
import {
  getCompanyCfgrs,
  selectAllCompanyCfgrs,
  selectSelectedCompany,
  selectSelectedConfigurator,
} from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

export const CfgrWrapper: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const confirm = useConfirmDialog();

  const { pathname } = useLocation();
  const showCfgrHeader = !pathname.includes(CFGN_INSIGHTS_BASE_PATH);

  const newCfgrBtnRef = useRef<HTMLDivElement>(null);
  const [cfgrTemplatesPopoverOpen, setCfgrTemplatesPopoverOpen] = useState(false);
  const [showCopyCfgrModal, setShowCopyCfgrModal] = useState(false);

  const selectedCompany = useAppSelector(selectSelectedCompany);
  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const hasManageCfgrPermission = usePermission(CompanyPermissions.ManageConfigurator, selectedCompany?.id);

  const isCfgrDeleted = !!selectedCfgr?.deleteAfter;
  const isReadOnlyCfgr = !!selectedCfgr?.cfgrFeatures.readonly;

  const comapnyCfgrs = useAppSelector(selectAllCompanyCfgrs);
  const companyCfgrsFetched = useAppSelector(state => state.companyData.cfgrsFetched);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getCompanyCfgrs({ companyId: selectedCompany.id }));
    }
  }, [dispatch, selectedCompany]);

  const onDeleteCfgrClicked = async (): Promise<void> => {
    if (!selectedCompany || !selectedCfgr) {
      throw new Error("Configurator can't be deleted. No selected configurator found!");
    }

    const confirmedForceDelete = await confirm(
      t(`Delete configurator "${selectedCfgr.displayName || selectedCfgr.id}"?`),
      {
        variant: 'Danger',
        confirmBtnText: t('Delete configurator'),
      }
    );
    if (confirmedForceDelete) {
      await deleteConfigurator(selectedCompany.id, selectedCfgr.id);
      dispatch(getCompanyCfgrs({ companyId: selectedCompany.id }));
    }
  };

  return (
    <>
      <div data-cmptype="CfgrWrapper" className="flex flex-col p-6">
        {showCfgrHeader && (
          <div className="mb-2 flex items-center">
            <CfgrPicker />
            <div className="flex grow justify-end">
              {hasManageCfgrPermission && (
                <div className="flex flex-row gap-2">
                  <Button
                    variant="Secondary"
                    Svg={CopyIcon}
                    title={
                      isReadOnlyCfgr
                        ? t(`A read-only configurator can't be copied!`)
                        : !selectedCfgr?.isPublished
                          ? t('Only published configurators can be copied!')
                          : t('Copy configurator')
                    }
                    disabled={!selectedCfgr?.isPublished || isReadOnlyCfgr}
                    onClick={(): void => setShowCopyCfgrModal(true)}
                  />

                  {selectedCfgr && (
                    <Button
                      variant="Secondary"
                      hoverColor="Danger"
                      Svg={DeleteIcon}
                      title={
                        selectedCfgr.lockStatus
                          ? t("A locked configurator can't be deleted!")
                          : selectedCfgr.isPublished && !isReadOnlyCfgr
                            ? t("A published configurator can't be deleted!")
                            : isCfgrDeleted
                              ? t('The configurator is already marked to be deleted!')
                              : t('Delete configurator')
                      }
                      onClick={onDeleteCfgrClicked}
                      disabled={
                        isCfgrDeleted || (selectedCfgr.isPublished && !isReadOnlyCfgr) || !!selectedCfgr.lockStatus
                      }
                    />
                  )}
                  {selectedCompany && (
                    <div ref={newCfgrBtnRef}>
                      <Button
                        variant="Primary"
                        text={t('New configurator')}
                        Svg={PlusIcon}
                        onClick={(): void => setCfgrTemplatesPopoverOpen(true)}
                      ></Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {companyCfgrsFetched === 'Fetched' && !comapnyCfgrs.length && (
          <div className="max-w-[42rem]">
            <CbnCaptionCard
              headerText={t('No configurator')}
              subText={
                <>
                  <div>{t('Your company has no configurators yet.')}</div>
                  <div>{t('The home page offers guidance on how to create your first configurator.')}</div>
                </>
              }
              Svg={InfoIcon}
              action={<LinkButton text={t('Go to home')} href={PATHS.home} />}
            />
          </div>
        )}

        <div className="grow overflow-y-auto p-1">{selectedCfgr?.lockStatus ? <CfgrLockInfo /> : <Outlet />}</div>
      </div>

      {selectedCompany && (
        <CbnPopover
          open={cfgrTemplatesPopoverOpen}
          anchorEl={newCfgrBtnRef.current}
          onClose={(): void => setCfgrTemplatesPopoverOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <CreateCfgrTemplatePickerHeader />
          <CbnCardBody>
            {/*
            The popover should always show exactly 4 cards per row which is achieved by the `calc(...)` here.
            The explicit tailwind configs ensure that this is kept in sync if the card width or gaps change sometime.
            */}
            <div className="max-h-[60vh] w-[calc(4*theme(spacing.cfgr-template-card-width)+3*theme(spacing.cfgr-template-cards-x-gap))] max-w-[90vw] overflow-auto">
              <CreateCfgrTemplatePicker
                companyId={selectedCompany.id}
                onCfgrCreated={(): void => setCfgrTemplatesPopoverOpen(false)}
              />
            </div>
          </CbnCardBody>
        </CbnPopover>
      )}

      {showCopyCfgrModal && (
        <CopyCfgrModal
          onConfirm={(): void => setShowCopyCfgrModal(false)}
          onCancel={(): void => setShowCopyCfgrModal(false)}
        />
      )}
    </>
  );
};
