import { useState } from 'react';
import { LogoutIcon, MailIcon } from 'assets/icons';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { Card } from 'controls/card/card';
import { FormItem } from 'controls/form-item/form-item';
import { StateIcon } from 'controls/icon/state-icon';
import { TextInput } from 'controls/input/text-input';
import { LoadMask } from 'controls/load-mask/load-mask';
import { CBN_MAIL_ADRESSES } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { logout, selectFullUsername } from 'slices/auth/auth.slice';

export const UserWithoutCompany: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const companiesFetchState = useAppSelector(state => state.companyData.companiesFetched);
  const username = useAppSelector(selectFullUsername);
  const isFetched = companiesFetchState === 'Fetched';
  const isAborted = companiesFetchState === 'Aborted';

  const [companyName, setCompanyName] = useState('');

  const headerText = t('Welcome') + (username ? ` ${username}` : '') + '!';

  const onLogout = async (): Promise<void> => {
    await dispatch(logout({}));
  };

  return (
    <LoadMask active={!isFetched && !isAborted} transparent="semi" spinner text={t('Loading companies...')}>
      <div data-cmptype="UserWithoutCompany" className="flex flex-grow flex-col items-center justify-center gap-6">
        {isFetched && (
          <Card headerText={headerText}>
            {!isAborted ? (
              <div className="flex flex-col gap-4">
                <p>
                  {t("It seems like you aren't assigned to any company yet.")}
                  <br />
                  {'Contact us to create your first company.'}
                </p>
                <FormItem labelContent={t('Company name')}>
                  <TextInput value={companyName} onValueChange={(value): void => setCompanyName(value)} />
                </FormItem>
                <LinkButton
                  text={t('Send email request')}
                  Svg={MailIcon}
                  href={`mailto:${CBN_MAIL_ADRESSES.info}?${
                    companyName ? `subject=Request to create company "${companyName}"` : ''
                  }`}
                />
              </div>
            ) : (
              <div className="flex flex-col gap-4">
                <StateIcon variant="Error" />
                <p>
                  {t('It seems like your user is in an invalid or unkown state.')}
                  <br />
                  {t('Please try to login again or contact us if the issue continuous!')}
                </p>
                <Button text="Logout" Svg={LogoutIcon} onClick={onLogout} />
              </div>
            )}
          </Card>
        )}
      </div>
    </LoadMask>
  );
};
