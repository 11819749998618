import { GRID_STRING_COL_DEF, GridColTypeDef, GridEditInputCellProps, useGridApiContext } from '@mui/x-data-grid-pro';
import React, { useEffect, useLayoutEffect } from 'react';
import { StateIcon } from 'controls/icon/state-icon';
import { Input } from 'controls/input/input';

/**
 * Custom `DataGrid` column type based on our own Input control
 *
 * Reference: https://mui.com/x/react-data-grid/custom-columns/
 */
export const inputColumnType: GridColTypeDef<string> = {
  ...GRID_STRING_COL_DEF,
  renderEditCell: params => <CbnGridEditInputCell {...params} />,
};

/**
 * Edit input cell based on the default from MUI
 * Changes:
 * - Use our own input field
 * - Handle error property (which contains text)
 *
 * The core logic is taken from `packages\grid\x-data-grid\src\components\cell\GridEditInputCell.tsx`
 */
export const CbnGridEditInputCell = React.forwardRef<HTMLInputElement, GridEditInputCellProps>((props, ref) => {
  const {
    id,
    value,
    formattedValue,
    api,
    field,
    row,
    rowNode,
    colDef,
    cellMode,
    isEditable,
    tabIndex,
    hasFocus,
    isValidating,
    debounceMs = 200,
    isProcessingProps,
    onValueChange,
    error,
    ...other
  } = props;

  const apiRef = useGridApiContext();
  const inputRef = React.useRef<HTMLInputElement>();
  const [valueState, setValueState] = React.useState(value);

  const handleChange = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      if (onValueChange) {
        await onValueChange(event, newValue);
      }

      const column = apiRef.current.getColumn(field);

      let parsedValue = newValue;
      if (column.valueParser) {
        parsedValue = column.valueParser(newValue, apiRef.current.getCellParams(id, field));
      }

      setValueState(parsedValue);
      apiRef.current.setEditCellValue(
        { id, field, value: parsedValue, debounceMs, unstable_skipValueParser: true },
        event
      );
    },
    [apiRef, debounceMs, field, id, onValueChange]
  );

  const meta = apiRef.current.unstable_getEditCellMeta(id, field);

  useEffect(() => {
    if (meta?.changeReason !== 'debouncedSetEditCellValue') {
      setValueState(value);
    }
  }, [meta, value]);

  useLayoutEffect(() => {
    if (hasFocus) {
      inputRef.current!.focus();
    }
  }, [hasFocus]);

  const hasError = !!error;
  return (
    <Input
      ref={ref}
      inputRef={inputRef}
      fullWidth
      value={valueState ?? ''}
      onChange={handleChange}
      {...other}
      error={hasError}
      endAdornment={error && <StateIcon variant="Error" title={error} noBckgr />}
    />
  );
});
