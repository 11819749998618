import { createSelector, createSlice } from '@reduxjs/toolkit';
import { globalReset } from 'actions/general.actions';
import { RootState } from 'services/store/store.service';

export type HttpState = {
  // holds the information about the pending http requests => can be used for load masks
  cntPendingRequests: number;
};

const initialState: HttpState = {
  cntPendingRequests: 0,
};

const httpSlice = createSlice({
  name: 'http',
  initialState,
  reducers: {
    addRequest: state => ({ cntPendingRequests: state.cntPendingRequests + 1 }),
    finishRequest: state => ({ cntPendingRequests: state.cntPendingRequests - 1 }),
  },
  extraReducers: builder => {
    builder.addCase(globalReset, () => initialState);
  },
});

const httpState = (state: RootState): HttpState => state.http;
export const selectHttpRequestPending = createSelector(httpState, http => http.cntPendingRequests > 0);

export const { addRequest, finishRequest } = httpSlice.actions;

export default httpSlice.reducer;
