import { useEffect } from 'react';
import { AssetBundleVersions } from 'components/asset-editor/header/asset-bundle-versions';
import { AssetBundles } from 'components/asset-editor/header/asset-bundles';
import { AssetEditorBreadcrumbs } from 'components/asset-editor/header/asset-editor-breadcrumbs';
import { CbnCollapseIcon } from 'controls/icon/cbn-collapse-icon';
import { cn } from 'helper/css.helper';
import { useLocalStorageState } from 'hooks/common/local-storage.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectAllBundles, selectSelectedBundle } from 'slices/assets/assets.slice';

export const AssetEditorHeader: React.FC = () => {
  const { t } = useTypedTranslation();

  const bundles = useAppSelector(selectAllBundles);
  const bundlesFetchState = useAppSelector(state => state.assets.fetchState.bundles);
  // NOTE: there can be intermediate states while bundles are loaded
  // ATM the UI may be a bit "flippy" in this scenario => think of introducing skeleonts or load masks if this will get
  // a problem
  // the same applies for bundle and bundle version switching!
  const hasBundles = bundlesFetchState === 'Fetched' && !!bundles.length;
  const hasNoBundles = bundlesFetchState === 'Fetched' && !bundles.length;
  const selectedBundle = useAppSelector(selectSelectedBundle);

  const [collapsed, setCollapsed] = useLocalStorageState('assetHeaderCollapsed', false);

  useEffect(() => {
    if (hasNoBundles) {
      setCollapsed(false);
    }
  }, [hasNoBundles, setCollapsed]);

  return (
    <div data-cmptype="AssetEditorHeader" className="relative flex flex-col">
      {hasBundles && (
        <>
          <AssetEditorBreadcrumbs onRootClick={(): void => setCollapsed(false)} />

          <div className="absolute -right-6 bottom-1">
            <CbnCollapseIcon
              collapsed={collapsed}
              direction="top"
              titleProps={{ placement: 'left' }}
              titleExpand={t('Expand header')}
              titleCollapse={t('Collapse header')}
              onClick={(): void => setCollapsed(!collapsed)}
            />
          </div>
        </>
      )}

      {/* asset header is resized to 0 but still rendered, otherwise animation wouldn't be possible */}
      <div
        className={cn(
          'flex flex-col overflow-hidden transition-[max-height]',
          collapsed ? 'max-h-0' : 'mt-6 max-h-[300px]'
        )}
      >
        <div>
          <h2>{t('Assets')}</h2>
          <span className="text-m-regular text-neutral-90">
            {t('Manage all your data and media provided to be used for your configurators. ')}
          </span>
        </div>
        {hasBundles && (
          <>
            <AssetBundles />
            {selectedBundle && <AssetBundleVersions />}
          </>
        )}
      </div>
    </div>
  );
};
