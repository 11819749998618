import * as LocalStorageService from 'services/local-storage/local-storage.service';
import { UserInfo } from 'slices/auth/auth.slice';

const _LOGIN_DATA_KEYS = {
  loginData: 'loginData',
  loginData1: 'loginData-1',
};

export type ExtJSLoginData = Omit<UserInfo, 'id'> & {
  loggedIn: boolean;
  userId: string;
};

export function setExtJSLoginDataInLocalStorage(userInfo: UserInfo): void {
  const loginData: ExtJSLoginData = {
    loggedIn: true,
    userId: userInfo.id,
    email: userInfo.email,
    firstname: userInfo.firstname,
    lastname: userInfo.lastname,
    isCbnAdmin: userInfo.isCbnAdmin,
    isSuperAdmin: userInfo.isSuperAdmin,
    state: userInfo.state,
  };
  LocalStorageService.setItem(_LOGIN_DATA_KEYS.loginData, '1');
  LocalStorageService.setItem(_LOGIN_DATA_KEYS.loginData1, JSON.stringify(loginData));
}
