import React from 'react';
import { ChunkLoadErrorFallback } from 'components/error-handling/chunk-load-error-fallback';
import { addError } from 'services/store/logger.service';

/**
 * Wrapper around "React.lazy" but shows fallback component if chunk couldn't be loaded.
 * This typically occurs after a new deployment as described in ticket
 * (CB-9453)[https://combeenation.youtrack.cloud/issue/CB-9453/Chunk-error-when-opening-BabylonJS-asset]
 */
export function lazyWithFallback<T>(
  componentImport: () => Promise<{ default: React.FC<T> }>,
  missingComponentText: string
): React.LazyExoticComponent<React.FC<T>> {
  const callback = async (): Promise<{ default: React.FC<T> }> => {
    let component: { default: React.FC<T> };
    try {
      component = await componentImport();
    } catch (e) {
      // overwrite display name so that we know the origin of the fallback component
      ChunkLoadErrorFallback.displayName = missingComponentText;
      component = {
        default: ChunkLoadErrorFallback as React.FC<T>,
      };
      // silently re-throw error so that we can track it in AppInsights
      addError('Loading component chunk failed!', {
        preventDialog: true,
        customProperties: { stack: (e as Error).stack },
      });
    }

    return component;
  };

  return React.lazy(() => callback());
}
