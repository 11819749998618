import { useState } from 'react';
import {
  CollapseAllIcon,
  DensityCompactIcon,
  DensityStandardIcon,
  ExpandAllIcon,
  VerticalDotsMenuIcon,
} from 'assets/icons';
import { Button } from 'controls/button/button';
import { CbnMenu } from 'controls/cbn-menu/cbn-menu';
import { CbnMenuDivider } from 'controls/cbn-menu/cbn-menu-divider';
import { CbnMenuItem } from 'controls/cbn-menu/cbn-menu-item';
import { CbnTreeViewApiRef } from 'controls/cbn-tree-view/cbn-tree-view';
import { CbnSearchInput } from 'controls/input/search-input';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type CbnTreeViewCtrlMenuProps = {
  treeViewApiRef: React.RefObject<CbnTreeViewApiRef>;
};

/**
 * This component can be used as an additional control instance for a `CbnTreeView`, eg: collapse/expand all nodes
 * The api ref object has to be passed to create the according link.
 */
export const CbnTreeViewCtrlMenu: React.FC<CbnTreeViewCtrlMenuProps> = ({ treeViewApiRef }) => {
  const { t } = useTypedTranslation();

  const isCompactView = treeViewApiRef.current?.getViewMode() === 'compact';

  // state has to be held locally as well, as api ref doesn't trigger re-renders
  const [localSearchInput, setLocalSearchInput] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenuClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = (): void => {
    setAnchorEl(null);
  };

  const onSearchInputChange = (newValue: string): void => {
    treeViewApiRef.current?.setSearchInput(newValue);
    setLocalSearchInput(newValue);
  };

  const onExpandAllBtnClick = (): void => {
    treeViewApiRef.current?.expandAll();
    closeMenu();
  };
  const onCollapseAllBtnClick = (): void => {
    treeViewApiRef.current?.collapseAll();
    closeMenu();
  };

  const onCompactViewBtnClick = (): void => {
    treeViewApiRef.current?.setViewMode('compact');
    closeMenu();
  };

  const onExtendedViewBtnClick = (): void => {
    treeViewApiRef.current?.setViewMode('extended');
    closeMenu();
  };

  return (
    <>
      <div data-cmptype="CbnTreeViewCtrlMenu-inputs" className="flex gap-1">
        <CbnSearchInput
          debounceMs={300} // debounce by default due to potentially large data
          expandableButton
          value={localSearchInput}
          onValueChange={onSearchInputChange}
          searchBtnVariant="OutlinedOnHover"
        />
        <Button Svg={VerticalDotsMenuIcon} variant="OutlinedOnHover" onClick={openMenuClick} />
      </div>
      <CbnMenu
        data-cmptype="CbnTreeViewCtrlMenu-menu"
        anchor={anchorEl}
        open={open}
        placement="bottom-end"
        onClose={closeMenu}
      >
        <CbnMenuItem text={t('Expand all')} icon={ExpandAllIcon} handleClick={onExpandAllBtnClick} />
        <CbnMenuItem text={t('Collapse all')} icon={CollapseAllIcon} handleClick={onCollapseAllBtnClick} />
        <CbnMenuDivider />
        <CbnMenuItem
          text={t('Compact view')}
          icon={DensityCompactIcon}
          handleClick={onCompactViewBtnClick}
          selected={isCompactView}
        />
        <CbnMenuItem
          text={t('Extended view')}
          icon={DensityStandardIcon}
          handleClick={onExtendedViewBtnClick}
          selected={!isCompactView}
        />
      </CbnMenu>
    </>
  );
};
