import { useState } from 'react';
import { MailIcon } from 'assets/icons';
import { Button } from 'controls/button/button';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { postAccountResendActivation } from 'services/auth/auth.service';

export const ResendActivation: React.FC = () => {
  const { t } = useTypedTranslation();
  const [processingResend, setProcessingResend] = useState(false);
  const [resendDone, setResendDone] = useState(false);

  const resendActivationMail = async (): Promise<void> => {
    setResendDone(false);
    setProcessingResend(true);
    await postAccountResendActivation();
    setResendDone(true);
    setProcessingResend(false);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        {!resendDone ? (
          <Button
            text={processingResend ? t('Sending...') : t('Resend activation link')}
            Svg={MailIcon}
            variant={'Secondary'}
            onClick={resendActivationMail}
            isLoading={processingResend}
          />
        ) : (
          <span className="text-m-regular text-success-main">{t('Email has been sent!')}</span>
        )}
      </div>
    </div>
  );
};
