import { useEffect, useMemo, useState } from 'react';
import { CbnCardContextState, CbnCardProvider } from 'controls/cbn-card/cbn-card-provider';
import { cn } from 'helper/css.helper';

type CbnCollapsibleCardProps = {
  isCollapsible: true;
  collapsed?: boolean;
};

type CbnNonCollapsibleCardProps = {
  isCollapsible?: false;
  collapsed?: never;
};

export type CbnCardProps = (CbnCollapsibleCardProps | CbnNonCollapsibleCardProps) & {
  'children': React.ReactNode;
  'data-cmptype'?: string;
  'grow'?: boolean;
  'variant'?: 'default' | 'nested';
};

/**
 * Use with `CbnCardHeader` and `CbnCardBody` to get default card spacings etc.
 *
 * @example
 * ```tsx
 * // Default card
 * <CbnCard>
 *   <CbnCardHeader
 *     title="Card title"
 *     subText="Card subtext"
 *     actions={<Button text="Submit" />}
 *   />
 *   <CbnCardBody>Card content</CbnCardBody>
 * </CbnCard>
 * ```
 *
 * @example
 * ```tsx
 * // Default collapsible card
 * <CbnCard isCollapsible>
 *   <CbnCollapsibleCardHeader title="Card title" subText="Card subtext" />
 *   <CbnCardBody>Collapsible card content</CbnCardBody>
 * </CbnCard>
 * ```
 *
 * @example
 * ```tsx
 * // Collapsible card with custom header & body which manually handle collapse
 * // state using the `CbnCard` context via its `useCbnCard` hook
 * export const CustomHeader: React.FC = () => {
 *   const { collapsed, setCollapsed } = useCbnCard();
 *   return (
 *     <div onClick={(): void => setCollapsed(!collapsed)}>
 *       Custom header
 *     </div>
 *   )
 * }
 *
 * export const CustomBody: React.FC = () => {
 *   const { collapsed } = useCbnCard();
 *   return (
 *     // `Collapse` from `@mui/material`
 *     <Collapse in={!collapsed}>
 *       Custom collapsible card content
 *     </Collapse>
 *   )
 * }
 *
 * <CbnCard isCollapsible>
 *   <CustomHeader />
 *   <CustomBody />
 * </CbnCard>
 * ```
 */
export const CbnCard: React.FC<CbnCardProps> = ({
  children,
  'data-cmptype': dataCmpType,
  grow = false,
  isCollapsible = false,
  variant = 'default',
  'collapsed': collapsedInput = false,
}) => {
  const [collapsed, setCollapsed] = useState(collapsedInput);
  const ctxState = useMemo<CbnCardContextState>(
    () => ({ isCollapsible, collapsed, setCollapsed }),
    [collapsed, isCollapsible]
  );

  useEffect(() => {
    setCollapsed(collapsedInput);
  }, [collapsedInput]);

  return (
    <CbnCardProvider value={ctxState}>
      <div
        data-cmptype={'CbnCard' + (dataCmpType ? ` ${dataCmpType}` : '')}
        className={cn(
          'relative flex flex-col overflow-auto rounded-lg bg-neutral-10 text-m-regular shadow-sm',
          variant === 'nested' && 'border border-neutral-40 shadow-none',
          grow && 'grow'
        )}
      >
        {children}
      </div>
    </CbnCardProvider>
  );
};
