import { MenuItem, MenuItemProps } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ConditionalWrapper } from 'components/common/conditional-wrapper';
import { Icon, SvgComponent } from 'controls/icon/icon';

type DraftMenuItemProps = {
  text: string;
  svg: SvgComponent;
  to?: string;
  linkProps?: Omit<LinkProps, 'to'>;
  onClick?: MenuItemProps['onClick'];
  variant?: 'danger';
  disabled?: boolean;
};

export const DraftMenuItem: React.FC<DraftMenuItemProps> = ({
  text,
  svg,
  to,
  linkProps,
  onClick,
  variant,
  disabled,
}) => {
  const hasLink = !!to;

  return (
    /** Remove the padding and apply it on the Link inside.
     *  Otherwise the padding-area would register the click but simply closes the menu
     */
    <MenuItem
      onClick={onClick}
      disableGutters
      sx={{ padding: hasLink ? 0 : undefined }}
      className="group"
      disabled={disabled}
    >
      <ConditionalWrapper
        condition={hasLink}
        wrapper={(children): ReactElement => (
          <Link {...linkProps} to={to ?? ''} className="grow py-[6px]">
            {children}
          </Link>
        )}
      >
        <div className="flex flex-row items-center gap-3 px-4">
          <Icon
            Svg={svg}
            className={classNames('h-5', {
              'group-hover:text-danger-main': variant === 'danger',
            })}
          />
          <span
            className={classNames('text-m-regular text-neutral-100', {
              'group-hover:text-danger-main': variant === 'danger',
            })}
          >
            {text}
          </span>
        </div>
      </ConditionalWrapper>
    </MenuItem>
  );
};
