import { CfgnInsightsQuery } from 'helper/cfgn-insights.helper';
import { EndpointDescription } from 'services/http/http.service';
import { DEFAULT_PAGE_SIZE } from 'slices/cfgn-insisghts/cfgn-insights.slice';

export const CFGN_ENDPOINTS = {
  getCfgn: (
    companyName: string,
    cfgrName: string,
    configurationId: string,
    stageName?: string
  ): EndpointDescription => ({
    url: '/cfgn/getCfgn',
    params: { companyName, cfgrName, stageName, configurationId },
  }),

  getCfgnInsights: (
    companyName: string,
    configuratorName: string,
    stageName?: string,
    query?: CfgnInsightsQuery,
    pageSize = DEFAULT_PAGE_SIZE,
    continuationToken?: string
  ): EndpointDescription => ({
    url: '/cfgn/getCfgnInsights',
    params: { companyName, configuratorName, stageName, query, pageSize, continuationToken },
  }),

  getCfgnInsightsValues: (companyName: string, cfgrName: string, stageName?: string): EndpointDescription => ({
    url: '/cfgn/getCfgnInsightsValues',
    params: { companyName, cfgrName, stageName },
  }),

  getCfgnMetrics: (companyId: string, configuratorName: string, start: number, end: number): EndpointDescription => ({
    url: '/cfgn/getCfgnMetrics',
    params: { companyName: companyId, configuratorName: configuratorName, start: start, end: end },
  }),
};
