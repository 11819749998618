import { useCallback, useState } from 'react';
import { SchemaNameInput, SchemaNameInputResult } from 'components/common/schema-name-input';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { useNavigateToAssetEditor } from 'hooks/assets/assets.hooks';
import { useAlertDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { createNewAssetBundleDraft } from 'services/assets/assets.service';
import { getAssetBundles, selectAllBundles } from 'slices/assets/assets.slice';

type NewBundleModalProps = {
  onClose: () => void;
};

export const NewBundleModal: React.FC<NewBundleModalProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();
  const navigateToAssetEditor = useNavigateToAssetEditor();
  const alert = useAlertDialog();

  const [input, setInput] = useState<SchemaNameInputResult>({ uniqueId: '', displayName: '', hasErrors: false });

  const onSchemaInputChange = useCallback((result: SchemaNameInputResult): void => {
    setInput(result);
  }, []);

  const assetBundles = useAppSelector(selectAllBundles);
  const assetBundleNames = assetBundles.map(b => b.name);
  const selectedCompanyId = useAppSelector(state => state.companyData.selection.companyId);

  const onConfirmNewBundle = async (): Promise<void> => {
    if (input && !input.hasErrors) {
      // create the new bundle version on the server
      // TODO: create async thunk which calls "getAssetBundles" right away
      const newBundleVersion = await createNewAssetBundleDraft(
        selectedCompanyId,
        input.uniqueId,
        input.displayName,
        'v1'
      );

      await dispatch(getAssetBundles({ companyId: selectedCompanyId }));

      // immediately navigate to the new bundle version
      navigateToAssetEditor({
        companyId: selectedCompanyId,
        bundleId: input.uniqueId,
        bundleVersion: newBundleVersion,
      });

      onClose();
    } else {
      // TODO: proper error handling
      alert(t('Invalid bundle name!'), { variant: 'Error' });
    }
  };

  return (
    <ModalDialog
      data-cmptype="CreateNewBundle"
      variant="NoIcon"
      header={t('Create new bundle')}
      confirmDisabled={input?.hasErrors}
      confirmText={t('Create bundle')}
      actions={{ onConfirm: onConfirmNewBundle, onCancel: (): void => onClose() }}
    >
      <div className="flex grow flex-col gap-4">
        <SchemaNameInput input={input} onChange={onSchemaInputChange} unavailableIds={assetBundleNames} />
      </div>
    </ModalDialog>
  );
};
