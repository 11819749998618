import { styled } from '@mui/material';
import {
  TreeItem as MuiTreeItem,
  TreeItemContent as MuiTreeItemContent,
  TreeItemContentProps as MuiTreeItemContentProps,
  TreeItemProps as MuiTreeItemProps,
  treeItemClasses,
  useTreeItem,
} from '@mui/x-tree-view';
import React, { ReactElement, useEffect, useImperativeHandle, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ConditionalWrapper } from 'components/common/conditional-wrapper';
import { useCbnTreeView } from 'controls/cbn-tree-view/cbn-tree-view-provider';
import { HighlightedText } from 'controls/text/highlighted-text';
import { cn } from 'helper/css.helper';

type AdditionalContentProps = {
  text: string;
  textAdornment?: React.ReactNode;
  href?: string;
  textClassName?: string;
  hoverActions?: React.ReactNode[];
  actions?: React.ReactNode[];
};
export type CbnTreeItemProps = Pick<MuiTreeItemProps, 'nodeId' | 'ContentProps' | 'children' | 'endIcon'> &
  AdditionalContentProps;
type TreeItemContentProps = MuiTreeItemContentProps & AdditionalContentProps;

const _IS_COMPACT_VIEW_PROPNAME = 'isCompactView';
type StyledTreeItemProps = {
  [_IS_COMPACT_VIEW_PROPNAME]?: boolean;
};

/**
 * Note: Code base is based on MUI `TreeItemContent.tsx` + example code regarding custom `ContentComponent`
 * - https://github.com/mui/mui-x/blob/next/packages/x-tree-view/src/TreeItem/TreeItemContent.tsx
 * - https://mui.com/x/react-tree-view/#contentcomponent-prop
 */
const TreeItemContent = React.forwardRef(function TreeItemContent(
  props: TreeItemContentProps,
  ref: React.Ref<HTMLDivElement>
) {
  const {
    classes,
    className,
    displayIcon,
    expansionIcon,
    icon: iconProp,
    label,
    nodeId,
    onClick,
    onMouseDown,
    text,
    textAdornment,
    href,
    textClassName,
    hoverActions,
    actions,
    ...other
  } = props;
  const innerRef = useRef<HTMLDivElement>(null);
  const tvContext = useCbnTreeView();
  const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
    useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;
  const isCompactView = tvContext.viewMode === 'compact';

  // imperative handle is used, because we need access to the `innerRef` in here too
  useImperativeHandle(ref, () => innerRef.current!, []);
  useEffect(
    function autoScrollToSelected() {
      if (selected && innerRef.current) {
        innerRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    },
    [selected]
  );

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>): void => {
    preventSelection(event);

    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.preventDefault(); // don't select when expanding
    event.stopPropagation();
    handleExpansion(event);
  };

  const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    handleSelection(event);

    if (onClick) {
      onClick(event);
    }
  };

  /**
   * To support multi-selection the default behavior of links must be prevented
   */
  const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    if (tvContext.multiSelect && (event.ctrlKey || event.shiftKey)) {
      event.preventDefault();
    }
  };

  const hasHoverActions = !!hoverActions && hoverActions.filter(Boolean).length > 0;
  const hasActions = !!actions && actions.filter(Boolean).length > 0;

  return (
    <ConditionalWrapper
      condition={!!href}
      wrapper={(children): ReactElement => (
        <Link to={href ?? ''} onClick={onLinkClick}>
          {children}
        </Link>
      )}
    >
      <div
        {...other}
        className={cn(
          className,
          classes.root,
          'group/treeitem',
          // pseudo-element so that the shadow & background are applied on the whole treeview width
          'before:absolute before:left-0 before:right-0 before:content-[""]',
          isCompactView ? 'before:h-[36px]' : 'before:h-[52px] before:shadow-table-row',
          {
            [classes.expanded]: expanded,
            [classes.selected]: selected,
            [classes.focused]: focused,
            [classes.disabled]: disabled,
          }
        )}
        onMouseDown={handleMouseDown}
        onClick={handleSelectionClick}
        onDoubleClick={tvContext.expandOnDoubleClick ? handleExpansionClick : undefined}
        ref={innerRef}
      >
        {/** z-0 is necessary so that the pseudo-element doesn't overlap it */}
        <div className={cn(classes.iconContainer, 'z-0')} onClick={handleExpansionClick}>
          {icon}
        </div>
        <div
          className={cn(
            classes.label,
            'flex h-[52px] min-w-0 items-center justify-between gap-1',
            isCompactView && 'h-[36px]'
          )}
        >
          <div className="flex min-w-0 items-center">
            <span
              title={text}
              className={cn(
                'overflow-hidden text-ellipsis whitespace-nowrap text-m-regular text-type-secondary',
                textClassName,
                isCompactView && 'text-s-regular',
                selected && 'font-medium text-type-primary'
              )}
            >
              <HighlightedText text={text} highlight={tvContext.searchInput} className="bg-transparent font-bold" />
            </span>
            {textAdornment && <span className="ml-2">{textAdornment}</span>}
          </div>
          {(hasActions || hasHoverActions) && (
            <div className="flex items-center gap-2">
              {hasHoverActions && (
                <div
                  className={cn('hidden items-center gap-1 group-hover/treeitem:flex')}
                  onClick={(evt): void => evt.stopPropagation()}
                  onDoubleClick={(evt): void => evt.stopPropagation()}
                >
                  {hoverActions.map((a, idx) => (
                    <React.Fragment key={idx}>{a}</React.Fragment>
                  ))}
                </div>
              )}
              {hasActions && (
                <div className="flex items-center gap-2">
                  {actions.map((a, idx) => (
                    <React.Fragment key={idx}>{a}</React.Fragment>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </ConditionalWrapper>
  );
}) as typeof MuiTreeItemContent;

const StyledTreeItem = styled(MuiTreeItem, {
  shouldForwardProp: prop => prop !== _IS_COMPACT_VIEW_PROPNAME,
})<StyledTreeItemProps>(({ isCompactView, theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    marginRight: 8,
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: isCompactView ? 8 : 28,
  },
  [`& .${treeItemClasses.content}`]: {
    [`&.${treeItemClasses.focused}`]: {
      backgroundColor: 'transparent',
    },

    [`&.${treeItemClasses.selected},
      &.${treeItemClasses.selected}.${treeItemClasses.focused},
      &.${treeItemClasses.selected}:hover`]: {
      'backgroundColor': theme.palette.neutral[20],

      '&:before': {
        backgroundColor: theme.palette.neutral[20],
      },
    },

    [`&:hover`]: {
      'backgroundColor': theme.palette.neutral[20],
      '&:before': {
        backgroundColor: theme.palette.neutral[20],
      },
    },
  },
}));

export const CbnTreeItem = React.forwardRef(
  (
    { text, textAdornment, href, textClassName, hoverActions, actions, ContentProps, ...props }: CbnTreeItemProps,
    ref: React.Ref<HTMLLIElement>
  ) => {
    const tvContext = useCbnTreeView();
    const isCompactView = tvContext.viewMode === 'compact';

    const mergedContentProps: MuiTreeItemProps['ContentProps'] & AdditionalContentProps = {
      ...ContentProps,
      text,
      textAdornment,
      href,
      textClassName,
      hoverActions,
      actions,
    };

    return (
      <StyledTreeItem
        data-cmptype="CbnTreeItem"
        ContentComponent={TreeItemContent}
        ContentProps={mergedContentProps}
        isCompactView={isCompactView}
        {...props}
        ref={ref}
      />
    );
  }
);
