import { Collapse } from '@mui/material';
import { useEffect, useId, useMemo, useState } from 'react';
import { SelectCfgrOfCompany } from 'components/cfgrs/drafts/select-cfgr-of-company';
import { SchemaNameInput, SchemaNameInputResult } from 'components/common/schema-name-input';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { Autocomplete } from 'controls/autocomplete/autocomplete';
import { Checkbox } from 'controls/checkbox/checkbox';
import { StateIcon } from 'controls/icon/state-icon';
import { TextInput } from 'controls/input/text-input';
import { getEditorUrl } from 'helper/url/cfgr-url.helper';
import { useAlertDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { postConfiguratorsCopy } from 'services/cfgrs/cfgrs.service';
import { postConfiguratorHistoryCreateDraft } from 'services/configuratorhistory/configuratorhistory.service';
import {
  getCompanyCfgr,
  selectAllCompanyCfgrs,
  selectCfgrDrafts,
  selectCfgrStages,
  selectSelectedConfigurator,
} from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

export const MAX_DRAFTS_PER_CFGR = 10;

export type CreateDraftDialogProps = {
  /**
   * ID of the cfgr draft, stage or other cfgr version (history or published) which should be initially selected in the
   * "Based on" dropdown.
   *
   * If undefined, the first option in the dropdown will be selected.
   */
  initialSourceCfgrVersionId?: string;

  /**
   * Shall be passed if the initial source cfgr version is a history version (i.e. not published, no stage & no draft)
   * as we're not showing history versions in the dialogs "based on" dropdown and therefore have no info about display
   * names etc.
   *
   * !!! Don't pass when `initialSourceCfgrVersionId` is not a history version !!!
   */
  initialSourceCfgrVersionDisplayName?: string;

  draftNameSuggestion?: string;
  onConfirm?: () => void;
  onCancel: () => void;
};

export const CreateDraftDialog: React.FC<CreateDraftDialogProps> = ({
  initialSourceCfgrVersionId,
  initialSourceCfgrVersionDisplayName,
  draftNameSuggestion,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const alert = useAlertDialog();

  const createDraftInAnotherCfgrCheckboxId = useId();
  const createNewCfgrCheckboxId = useId();

  const sourceCfgr = useAppSelector(selectSelectedConfigurator);
  const sourceCompanyId = sourceCfgr?.companyId ?? '';
  const [sourceCfgrVersionId, setSourceCfgrVersionId] = useState(initialSourceCfgrVersionId);

  const targetCompanyId = sourceCompanyId;
  const [targetCfgrId, setTargetCfgrId] = useState(sourceCfgr?.id ?? '');
  const targetCfgrDrafts = useAppSelector(state => state.companyData.drafts[targetCompanyId]?.[targetCfgrId]) ?? [];

  const [targetDraftName, setTargetDraftName] = useState(draftNameSuggestion ?? '');
  const [showTargetDraftNameError, setShowTargetDraftNameError] = useState(false);

  const [newCfgrSchema, setNewCfgrSchema] = useState<SchemaNameInputResult>({
    uniqueId: '',
    displayName: sourceCfgr?.displayName ?? '',
  });

  const cfgrs = useAppSelector(selectAllCompanyCfgrs);
  const cfgrDrafts = useAppSelector(selectCfgrDrafts);
  const cfgrStages = useAppSelector(selectCfgrStages);

  // Options for "Based on" dropdown
  const sourceCfgrVersionDropdownOptions = useMemo(
    () => [
      ...(sourceCfgr?.version
        ? [{ value: sourceCfgr.version, text: sourceCfgr.isPublished ? t('Live version') : t('Last live version') }]
        : []),

      // If `initialSourceCfgrVersionDisplayName` is given, this means that the ID is a history version which we're not
      // showing otherwise in the dialog.
      ...(initialSourceCfgrVersionId && initialSourceCfgrVersionDisplayName
        ? [{ value: initialSourceCfgrVersionId, text: t(`Version "${initialSourceCfgrVersionDisplayName}"`) }]
        : []),

      ...cfgrStages.map(x => ({ value: x.version, text: t(`Stage "${x.name}"`) })),
      ...cfgrDrafts.map(x => ({ value: x.id, text: t(`Draft "${x.displayName}"`) })),
    ],
    [
      cfgrDrafts,
      cfgrStages,
      initialSourceCfgrVersionDisplayName,
      initialSourceCfgrVersionId,
      sourceCfgr?.version,
      sourceCfgr?.isPublished,
      t,
    ]
  );

  useEffect(() => {
    // Select first item in "Based on" dropdown if no initial value was given.
    // Eg. when pressing on the `New draft` button in the drafts collapsible card when the cfgr is not published.
    if (sourceCfgrVersionDropdownOptions.length > 0 && !sourceCfgrVersionId) {
      setSourceCfgrVersionId(sourceCfgrVersionDropdownOptions[0].value);
    }
  }, [sourceCfgrVersionDropdownOptions, sourceCfgrVersionId]);

  const hasManageCfgrPermission = usePermission(CompanyPermissions.ManageConfigurator, sourceCompanyId);

  const [isProcessingCreate, setIsProcessingCreate] = useState(false);
  const [createNewCfgr, setCreateNewCfgr] = useState(false);
  const [createInOtherCfgrActive, setCreateInOtherCfgrActive] = useState(false);

  const onCreateDraftConfirm = async (): Promise<void> => {
    if (!sourceCfgr || !sourceCfgrVersionId) return;

    if (!createNewCfgr && targetCfgrDrafts.length >= MAX_DRAFTS_PER_CFGR) {
      alert(t(`Maximum number of ${MAX_DRAFTS_PER_CFGR} drafts in configurator "${targetCfgrId}" reached!`), {
        variant: 'Warning',
      });
      return;
    }

    const sourceCfgrId = sourceCfgr.id;

    setIsProcessingCreate(true);
    const newDraftCfgrId = createNewCfgr ? newCfgrSchema.uniqueId : targetCfgrId;
    if (createNewCfgr) {
      await postConfiguratorsCopy(
        sourceCompanyId,
        sourceCfgrId,
        sourceCfgrVersionId,
        targetCompanyId,
        newDraftCfgrId,
        newCfgrSchema.displayName,
        targetDraftName
      );
    } else {
      await postConfiguratorHistoryCreateDraft(
        sourceCompanyId,
        sourceCfgrId,
        sourceCfgrVersionId,
        targetDraftName,
        newDraftCfgrId
      );
    }

    const companyCfgrs = await dispatch(
      getCompanyCfgr({ companyId: targetCompanyId, cfgrId: newDraftCfgrId })
    ).unwrap();
    const createdDraft = companyCfgrs.drafts[newDraftCfgrId]?.find(d => d.displayName === targetDraftName);
    if (createdDraft) {
      window.location.href = getEditorUrl(targetCompanyId, newDraftCfgrId, createdDraft.id, 'edit');
    }

    setIsProcessingCreate(false);
    onConfirm?.();
  };

  const applyCreateNewCfgrValue = (value: boolean): void => {
    setCreateNewCfgr(value);
    if (value) {
      setCreateInOtherCfgrActive(false);
    }
    if (value && !targetDraftName) {
      setTargetDraftName('default');
    }
  };

  const draftNameAlreadyExists =
    !createNewCfgr && targetCfgrDrafts.some(d => d.displayName.toLowerCase() === targetDraftName.toLowerCase());
  const isDraftNameError = targetDraftName.length === 0 || draftNameAlreadyExists;
  const hasErroneousInput = isDraftNameError || (createNewCfgr && newCfgrSchema.hasErrors);

  return (
    <ModalDialog
      header={t('New draft')}
      variant="Info"
      actions={{ onConfirm: onCreateDraftConfirm, onCancel }}
      confirmDisabled={hasErroneousInput}
      isProcessingConfirm={isProcessingCreate}
      confirmText={createNewCfgr ? t('Create new configurator and edit') : t('Create draft and edit')}
    >
      <div className="flex flex-col gap-6 pt-3">
        <div className="grid grid-cols-[auto,1fr] items-center gap-x-3 gap-y-4">
          <label className="text-m-regular text-neutral-90">{t('Draft name:')}</label>
          <TextInput
            value={targetDraftName}
            onBlur={(): void => setShowTargetDraftNameError(true)}
            onValueChange={(value): void => setTargetDraftName(value)}
            error={isDraftNameError}
            autoFocus
            selectTextOnAutoFocus
            endAdornment={
              showTargetDraftNameError &&
              isDraftNameError && (
                <StateIcon
                  variant="Error"
                  noBckgr
                  title={draftNameAlreadyExists ? t('Draft name already exists!') : t('Please enter a draft name')}
                />
              )
            }
          />

          <label className="text-m-regular text-neutral-90">{t('Based on:')}</label>
          <Autocomplete
            options={sourceCfgrVersionDropdownOptions}
            value={sourceCfgrVersionId}
            onChange={(x): void => setSourceCfgrVersionId(x as string)}
            disableClearable
          />
        </div>

        <div className="border-b border-neutral-40" />

        <div className="grid grid-cols-[auto,1fr] items-center gap-x-2 gap-y-3">
          <Checkbox
            id={createDraftInAnotherCfgrCheckboxId}
            checked={createInOtherCfgrActive}
            onValueChanged={(value): void => {
              setCreateInOtherCfgrActive(value);
              if (value) {
                applyCreateNewCfgrValue(false);
              } else {
                setTargetCfgrId(sourceCfgr?.id ?? '');
              }
            }}
          />
          <label className="text-m-regular text-neutral-90" htmlFor={createDraftInAnotherCfgrCheckboxId}>
            {t('Create draft in another configurator')}
          </label>

          <Collapse className="col-span-2 flex items-center" in={createInOtherCfgrActive && !createNewCfgr}>
            <SelectCfgrOfCompany
              companyId={sourceCompanyId}
              value={targetCfgrId}
              onChange={(val: string): void => setTargetCfgrId(val)}
              excludeReadOnly
            />
          </Collapse>

          {hasManageCfgrPermission && (
            <>
              <Checkbox id={createNewCfgrCheckboxId} checked={createNewCfgr} onValueChanged={applyCreateNewCfgrValue} />
              <label className="text-m-regular text-neutral-90" htmlFor={createNewCfgrCheckboxId}>
                {t('Create new configurator')}
              </label>
            </>
          )}
        </div>

        <Collapse className="flex flex-col gap-2" in={createNewCfgr}>
          <SchemaNameInput
            input={newCfgrSchema}
            onChange={setNewCfgrSchema}
            unavailableIds={cfgrs.map(c => c.id)}
            unavailableNames={cfgrs.map(c => c.displayName)}
          />
        </Collapse>
      </div>
    </ModalDialog>
  );
};
