import { Link } from 'react-router-dom';
import { ReactComponent as CbnLogoTxtWhite } from 'assets/cbn-logo-text-white.svg';
import { MailIcon } from 'assets/icons';
import { AppHeaderUserMenu } from 'components/app-header/app-header-user-menu';
import { NotificationCenter } from 'components/notifications/notification-center';
import { Icon } from 'controls/icon/icon';
import { LoadMask } from 'controls/load-mask/load-mask';
import { Tooltip } from 'controls/tooltip/tooltip';
import { CBN_MAIL_ADRESSES } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { buildCompanyHomePath } from 'services/routes/home-routes.service';
import { PATHS } from 'services/routes/paths.service';
import { selectLoggedIn } from 'slices/auth/auth.slice';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';
import { selectHttpRequestPending } from 'slices/http/http.slice';

export const AppHeader: React.FC = () => {
  const { t } = useTypedTranslation();

  const loggedIn = useAppSelector(selectLoggedIn);
  const httpRequestPending = useAppSelector(selectHttpRequestPending);
  const selectedCompany = useAppSelector(selectSelectedCompany);

  // useEffect(() => {
  //   _initDocs();
  // }, []);

  // const onDocsBtnClick = (): void => {
  //   // simulate click on the hidden doc360 button in the iFrame
  //   const iDoc360IFrame = document.getElementById('document360-widget-iframe') as HTMLIFrameElement;
  //   const widgetBtn = iDoc360IFrame?.contentDocument?.getElementById('doc360-button');

  //   if (widgetBtn) {
  //     widgetBtn.click();
  //   } else {
  //     // maybe Doc360 has changed something in the implementation, eg: different ids for the iFrame or button
  //     addError("Doc360 knowledge base widget button couldn't be found!", { preventDialog: true });
  //   }
  // };

  return (
    <header
      data-cmptype="AppHeader"
      role="heading"
      aria-level={1}
      className="flex h-14 shrink-0 items-center justify-between bg-primary-main px-6 py-1 text-neutral-10"
    >
      <div className="flex gap-4">
        <Link to={selectedCompany ? buildCompanyHomePath(selectedCompany.id) : PATHS.home}>
          <CbnLogoTxtWhite width={140} className="overflow-hidden" />
        </Link>
        <LoadMask active={httpRequestPending} spinner small transparent>
          <div className="h-10 w-10"></div>
        </LoadMask>
      </div>
      <div className="flex flex-row items-center gap-5">
        {loggedIn && <NotificationCenter />}
        {!loggedIn && (
          <Tooltip title={t('Support')}>
            <a href={`mailto:${CBN_MAIL_ADRESSES.info}`}>
              <Icon className="h-6 w-6 cursor-pointer" Svg={MailIcon} />
            </a>
          </Tooltip>
        )}
        {/* <Tooltip title={t('Docs')}>
          <Icon className="h-6 w-6 cursor-pointer" Svg={DocsIcon} onClick={onDocsBtnClick} />
        </Tooltip> */}
        {loggedIn && <AppHeaderUserMenu />}
      </div>
    </header>
  );
};

// function _initDocs(): void {
//   const scriptId = 'doc360-loader';
//   const isInitialized = !!document.getElementById(scriptId);
//   if (!isInitialized) {
//     const script = document.createElement('script');
//     const url = `${process.env.PUBLIC_URL}/doc360-knowledge-base-widget.js`;
//     script.src = url;
//     script.id = scriptId;

//     document.head.appendChild(script);
//   }
// }
