import { HttpStatusCode } from '../../helper/http/http-status.helper';
import { AsyncThunkAction, SerializedError } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ACCOUNT_ENDPOINTS } from 'services/http/endpoints/account.endpoints';
import { ASSETBUNDLES_ENDPOINTS } from 'services/http/endpoints/assetbundles.endpoints';
import { ASSETLINKS_ENDPOINTS } from 'services/http/endpoints/assetlinks.endpoints';
import { ASSETS_ENDPOINTS } from 'services/http/endpoints/assets.endpoints';
import { CFGN_ENDPOINTS } from 'services/http/endpoints/cfgn.endpoints';
import { COMPANIES_ENDPOINTS } from 'services/http/endpoints/companies.endpoints';
import { CONFIGURATORHISTORY_ENDPOINTS } from 'services/http/endpoints/configuratorhistory.endpoints';
import { CONFIGURATORS_ENDPOINTS } from 'services/http/endpoints/configurators.endpoints';
import { IDENTITY_ENDPOINTS } from 'services/http/endpoints/identity.endpoints';
import { REPLICATOR_ENDPOINTS } from 'services/http/endpoints/replicator.endpoints';
import { USERS_ENDPOINTS } from 'services/http/endpoints/users.endpoints';
import { WORKFLOWRUNS_ENDPOINTS } from 'services/http/endpoints/workflowruns.endpoints';
import { WORKFLOWS_ENDPOINTS } from 'services/http/endpoints/workflows.endpoints';
import { AppStore } from 'services/store/store.service';
import { updateBundleVersionLocks } from 'slices/assets/assets.slice';
import { getUserInfo, logout } from 'slices/auth/auth.slice';
import { getCompaniesAndPermissionsIfNeeded } from 'slices/company-data/company-data.slice';
import { addRequest, finishRequest } from 'slices/http/http.slice';

export const httpRejectResponses = ['InvalidUserState', 'NoSuchCompany'] as const;
export type HttpRejectResponse = (typeof httpRejectResponses)[number];

export type ActionResultCause = {
  Id: string;
  Message: string;
  Causes?: ActionResultCause[];
};

/**
 * Generic response from our Cbn API
 * Always ensure that the response is actually of this type with the type guard {@link isGenericActionResult}
 */
export type ActionResult<TId extends unknown = string> = {
  $typ: string;
  Id: TId;
  Message?: string;
  Causes?: ActionResultCause[];
};

/**
 * Ensure that it's a generic response action result from the Cbn API
 * Optionally it can be limited to expected IDs
 *
 * @example
 * const responses = ['Success', 'Error', 'InvalidInput'] as const;
 * type Response = (typeof responses)[number];
 *
 * if (isGenericActionResult(myResponse, responses)) {
 *   // return myResponse.Id === 'wrongval'; -> ERROR
 *   return myResponse.Id === 'Success';
 * }
 */
export function isGenericActionResult<TPossibleIds extends readonly TId[], TId = string>(
  result: ActionResult<TId> | any,
  allowedIds?: TPossibleIds
): result is ActionResult<TPossibleIds[number]> {
  if (result && result.$typ !== undefined && result.Id !== undefined) {
    return allowedIds ? allowedIds.includes(result.Id) : true;
  }
  return false;
}

/**
 * Return the given string as `ActionResult` if it's a JSON and passes the type-guard.\
 * The given json-string can have any kind of prefix, because some libraries or error-objects add this automatically.\
 * E.g.: `PromiseRejectionEvent: { "$typ": "Error", ... }`
 */
export function tryActionResultParsing(text: string): ActionResult | undefined {
  // Trim everything until the first open bracket '{'
  const trimmedText = text.replace(/^[^{]+/g, '');
  let obj;
  try {
    obj = JSON.parse(trimmedText);
  } catch {
    return undefined;
  }

  return isGenericActionResult(obj) ? obj : undefined;
}

export type RequestConfig = {
  isLogoutRequest?: boolean;
} & AxiosRequestConfig;

export type EndpointDescription = {
  url: string;
  params: any;
  data?: any;
};

export const endpoints = {
  ...ACCOUNT_ENDPOINTS,
  ...USERS_ENDPOINTS,
  ...CONFIGURATORS_ENDPOINTS,
  ...CONFIGURATORHISTORY_ENDPOINTS,
  ...ASSETBUNDLES_ENDPOINTS,
  ...ASSETS_ENDPOINTS,
  ...ASSETLINKS_ENDPOINTS,
  ...WORKFLOWS_ENDPOINTS,
  ...WORKFLOWRUNS_ENDPOINTS,
  ...COMPANIES_ENDPOINTS,
  ...REPLICATOR_ENDPOINTS,
  ...IDENTITY_ENDPOINTS,
  ...CFGN_ENDPOINTS,
};

// TODO: IMHO we shouldn't expose the `axios` instance to the whole app as this makes us very dependant on that lib.
//       If we'd ever like to use some other library it would be very hard to adjust the code if "everything" can rely
//       on internal "axios stuff".
//       I'd recommend the following:
//         * `http.service.ts` should be our "gateway service" between the axios lib & "the whole app"
//         * Only `http.service.ts` should use `axios` directly
//         * `http.service.ts` should expose all needed functionality via some wrappers
//         * Con: More code which partially might seem unnecessary as it just "relays" some axios functionality without
//                adding any extra functionality
//         * Pro: Easier to replace the external `axios` lib one day if we have or want to
export const httpClient: AxiosInstance = axios.create({});

/**
 * Global HTTP request fulfilled handler
 */
function _onRequestFulfilled(store: AppStore, res: RequestConfig): Promise<AxiosRequestConfig> {
  store.dispatch(addRequest());
  return Promise.resolve(res);
}

/**
 * Global HTTP response fulfilled handler
 */
function _onResponseFulfilled(store: AppStore, res: AxiosResponse): Promise<AxiosResponse> {
  store.dispatch(finishRequest());
  return Promise.resolve(res);
}

/**
 * Errors in thunks get serialized and would omit relevant info (e.g. response data)
 * so the available properties of {@link SerializedError} are populated with meaningful data
 */
function _getPropertiesForSerializedError(err: AxiosError): SerializedError {
  const serializedError: SerializedError = {};
  const responseData = err.response?.data as any;

  if (isGenericActionResult(responseData)) {
    serializedError.name = responseData.$typ;
    serializedError.message = JSON.stringify(responseData);
  } else {
    serializedError.message = responseData?.toString() || err.message || err.toString();
  }

  if (err.response?.status) {
    serializedError.code = err.response?.status.toString();
  }

  return serializedError;
}

/**
 * Global HTTP response rejected/error handler
 */
function _onResponseRejected(store: AppStore, err: AxiosError): Promise<AxiosError> {
  store.dispatch(finishRequest());

  const status = err.response?.status;
  const config = (err.response?.config as RequestConfig) || {};
  const data = err.response?.data;
  if (status === HttpStatusCode.Unauthorized_401 && !config.isLogoutRequest) {
    store.dispatch(logout({ preventLogoutRequest: true }));
  }

  if (
    status === HttpStatusCode.Forbidden_403 &&
    isGenericActionResult(data, httpRejectResponses) &&
    data.Id === 'InvalidUserState'
  ) {
    // User state might have changed so we update it
    store.dispatch(getUserInfo());
  }

  if (
    status === HttpStatusCode.InternalServerError_500 &&
    isGenericActionResult(data, httpRejectResponses) &&
    data.Id === 'NoSuchCompany'
  ) {
    store.dispatch(getCompaniesAndPermissionsIfNeeded({ forceFetch: true }));
  }

  if (isGenericActionResult(data) && data.Id === 'AssetBundleVersionIsLocked') {
    registerRecoveryAttempt(store, data.Id, updateBundleVersionLocks());
    return Promise.reject();
  }

  const extendedErrProps = _getPropertiesForSerializedError(err);
  err.message = extendedErrProps.message ?? err.message;
  err.name = extendedErrProps.name ?? err.name;
  err.stack = extendedErrProps.stack ?? err.stack;

  return Promise.reject(err);
}

export function setupHttpInterceptor(store: AppStore): void {
  httpClient.interceptors.request.use(_onRequestFulfilled.bind(null, store));
  httpClient.interceptors.response.use(_onResponseFulfilled.bind(null, store), _onResponseRejected.bind(null, store));
}

/** TODO:
 *  Crude implementation to prevent "possible endless recovery attempts"
 *  Right now this only works if the given async-thunk also awaits something.
 *  To think about:
 *  - Can we even detect this safely, besides counting errors or timers related to the specific error ID?
 *  - By default it's an expected error -> Do we want to report it in some cases?
 *  - Should we tackle this at another level? (reload asset-editor cmp, navigate, etc.)
 */
const _recoveryAttempts: { [key: string]: { count: number; attemptFinished: boolean } } = {};
async function registerRecoveryAttempt(
  store: AppStore,
  Id: string,
  dispatchFn: AsyncThunkAction<any, any, any>
): Promise<void> {
  if (_recoveryAttempts[Id] && _recoveryAttempts[Id].attemptFinished === false) {
    return;
  }

  const existingAttempt = _recoveryAttempts[Id];
  if (existingAttempt) {
    existingAttempt.count++;
  } else {
    _recoveryAttempts[Id] = { count: 1, attemptFinished: false };
  }

  await store.dispatch(dispatchFn);
  _recoveryAttempts[Id].attemptFinished = true;
}
