import {
  CfgnInsightsFilterInputValue,
  CfgnInsightsListQueryValue,
  CfgnInsightsNumberRangeQueryValue,
  CfgnInsightsSingleValueQueryValue,
} from 'components/cfgrs/cfgn-insights/cfgn-insights-filter-input';
import { CfgnInsightsCheckoutQueryableProperties } from 'generated/cfgn-insights-checkout-queryable-properties';
import { CfgnInsightsMetadataQueryableProperties } from 'generated/cfgn-insights-metadata-queryable-properties';
import { CfgnInsightsQueryableProperties } from 'generated/cfgn-insights-queryable-properties';
import { FilterGroupOperators } from 'generated/filter-group-operators';
import { FilterItemsType } from 'generated/filter-item-type';
import { FilterItemValueType } from 'generated/filter-item-value-type';
import { FilterOperators } from 'generated/filter-operators';

type Prefix<P extends string, S extends string> = `${P}.${S}`;

// `metadata` and `checkoutData` are not "standalone queryable" but consist of multiple properties which are queryable
// by prefixing `metadata.${subPropertyName}` or `checkoutData.${subPropertyName}`.
// Those properties are added to `AllQueryProperties` by `MetadataQueryProperties` & `CheckoutDataQueryProperties`.
type BaseQueryProperties = Exclude<
  `${CfgnInsightsQueryableProperties}`,
  `${CfgnInsightsQueryableProperties.METADATA}` | `${CfgnInsightsQueryableProperties.CHECKOUT_DATA}`
>;

type MetadataQueryProperties = Prefix<
  CfgnInsightsQueryableProperties.METADATA,
  `${CfgnInsightsMetadataQueryableProperties}`
>;

type CheckoutDataQueryProperties = Prefix<
  CfgnInsightsQueryableProperties.CHECKOUT_DATA,
  `${CfgnInsightsCheckoutQueryableProperties}`
>;

type AllQueryProperties = BaseQueryProperties | MetadataQueryProperties | CheckoutDataQueryProperties;

/**
 * All insights properties which we can show the user (i.e. all properties for which we have columns in the table).
 *
 * 'metadata.width' | 'metadata.height' are not queryable on purpose as we don't see no use case ATM but they can still
 * be shown.
 */
export type AllInsightsProperties = AllQueryProperties | 'metadata.width' | 'metadata.height';

/**
 * The excluded properties either make no sense to show or are not fully implemented yet and will (probably) be added
 * later.
 */
export type AllShownInsightsProperties = Exclude<
  AllInsightsProperties,
  'assignedTo' | 'comment' | 'totalTimeSpent' | 'insightsValues'
>;

/**
 * This basically holds all properties from `CfgnInsightDto`. The excluded properties either make no sense to show or
 * are not fully supported by the system yet and will be added later.
 */
export type AllQueryableInsightsProperties = Exclude<
  AllQueryProperties,
  'assignedTo' | 'comment' | 'totalTimeSpent' | 'metadata.location' | 'insightsValues'
>;

export type CfgnInsightsQueryItem = {
  type: FilterItemsType.Item;
  operator: FilterOperators;
  /** Can be a generic `string` when querying a cfgn insights value */
  propertyName: AllQueryProperties | string;
  /** Not required for some operators like `HasValue` or `HasNoValue` */
  value?: {
    type: FilterItemValueType;
    value: string | number | boolean | Date | string[] | number[] | boolean[];
  };
};

export type CfgnInsightsQueryGroup = {
  type: FilterItemsType.Group;
  operator: FilterGroupOperators;
  items: (CfgnInsightsQueryItem | CfgnInsightsQueryGroup)[];
};

export type CfgnInsightsQuery = {
  filter?: CfgnInsightsQueryItem | CfgnInsightsQueryGroup;

  orderByProperties: {
    propertyName: AllQueryProperties;
    ascendingOrder: boolean;
  }[];
};

export type QueryValues = {
  /** Query values for all statically known queryable properties which all cfgr have in common) */
  builtIn: Record<AllQueryableInsightsProperties, CfgnInsightsFilterInputValue>;

  /** Query values for all insights values which can differ for every cfgr */
  insightsValues: { [fieldName: string]: CfgnInsightsFilterInputValue | undefined };
};

export const isListQueryValue = (item: CfgnInsightsFilterInputValue): item is CfgnInsightsListQueryValue => {
  const isArray = Array.isArray(item);
  const isEmptyArray = item.length === 0;
  const firstItemIsOfCorrecType = typeof item[0] === 'string' || typeof item[0] === 'number';
  const allItemsAreOfSameType = (item as Array<unknown>).every((x, idx, arr) => typeof x === typeof arr[0]);
  return isArray && (isEmptyArray || (firstItemIsOfCorrecType && allItemsAreOfSameType));
};

export const isStringListQueryValue = (items: CfgnInsightsFilterInputValue): items is string[] => {
  return isListQueryValue(items) && (items.length <= 0 || typeof items[0] === 'string');
};

export const isNumberListQueryValue = (items: CfgnInsightsFilterInputValue): items is number[] => {
  return isListQueryValue(items) && (items.length <= 0 || typeof items[0] === 'number');
};

export const isSingleValueQueryValue = (
  items: CfgnInsightsFilterInputValue
): items is CfgnInsightsSingleValueQueryValue => {
  const isArray = Array.isArray(items);
  const isEmptyArray = items.length === 0;
  const hasOneItem = items.length === 1;
  const firstItemIsOfCorrecType =
    typeof items[0] === 'string' ||
    typeof items[0] === 'number' ||
    typeof items[0] === 'boolean' ||
    items[0] === null ||
    items[0] === undefined;

  return isArray && (isEmptyArray || (hasOneItem && firstItemIsOfCorrecType));
};

export const isSingleBoolValueQueryValue = (
  items: CfgnInsightsFilterInputValue
): items is [boolean | undefined] | [] => {
  const itemIsUndefinedOrBoolean = items[0] === undefined || items[0] === null || typeof items[0] === 'boolean';
  return isSingleValueQueryValue(items) && itemIsUndefinedOrBoolean;
};

export const isNumberRangeQueryValue = (
  items: CfgnInsightsFilterInputValue
): items is CfgnInsightsNumberRangeQueryValue => {
  const isArray = Array.isArray(items);
  const isEmptyArray = items.length === 0;
  const hasTwoItems = items.length === 2;
  const [min, max] = items;
  const minIsNumber = typeof min === 'number';
  const minIsUndefined = min === undefined;
  const maxIsNumber = typeof max === 'number';
  const maxIsUndefined = max === undefined;

  const minAndMaxOfCorrectValue =
    (minIsUndefined && maxIsNumber) || (minIsNumber && maxIsUndefined) || (minIsNumber && maxIsNumber);

  return isArray && (isEmptyArray || (hasTwoItems && minAndMaxOfCorrectValue));
};

export type CfgnCmpValueImage = { $typ: 'UrlImage'; Value: string };
export type CfgnCmpValue = string | number | boolean | CfgnCmpValueImage | unknown;

export function isCfgnCmpValueImage(x: CfgnCmpValue): x is CfgnCmpValueImage {
  const hasCorrectTypeProperty = (x as any)?.$typ === 'UrlImage';
  const hasCorrectValueProperty = typeof (x as any)?.Value === 'string';
  return hasCorrectTypeProperty && hasCorrectValueProperty;
}
