import { Skeleton } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BackwardIcon, EditIcon } from 'assets/icons';
import { ConditionalWrapperProps } from 'components/common/conditional-wrapper';
import { Button } from 'controls/button/button';
import { FormItem } from 'controls/form-item/form-item';
import { StateIcon } from 'controls/icon/state-icon';
import { TextInput } from 'controls/input/text-input';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import {
  COMPONENT_MAX_LENGTH,
  INVALID_SCHEMA_INFO,
  SCHEMA_NAME_MIN_LENGTH,
  generateValidSchemaName,
  isValidSchemaName,
} from 'helper/hive/hive.helper';
import { getTypedTranslation, useTypedTranslation } from 'hooks/i18n/i18n.hooks';

export type SchemaNameInputResult = {
  uniqueId: string;
  displayName: string;
  hasErrors?: boolean;
};

type SchemaNameInputProps = {
  input: SchemaNameInputResult;
  placeholder?: SchemaNameInputResult;
  unavailableIds: string[];
  unavailableNames?: string[];
  /** start with a valid suggestion (add index at end of name if unavailable) */
  startWithValidNameSuggestion?: boolean;
  isPreloading?: boolean;
  customLabels?: {
    name: React.ReactNode;
    id: React.ReactNode;
    /** Displays the ID label in 1 line with the input box & makes the text a bit smaller */
    idLabelInline?: boolean;
  };
  customWrapper?: ConditionalWrapperProps['wrapper'];
  onChange: (result: SchemaNameInputResult) => void;
  autoFocus?: boolean;
  /** If false (default), errors are only shown after the user first entered & left the input */
  showErrorsFromStart?: boolean;
};

export const SchemaNameInput: React.FC<SchemaNameInputProps> = ({
  input,
  placeholder,
  unavailableIds,
  unavailableNames = [],
  startWithValidNameSuggestion,
  isPreloading,
  customLabels,
  customWrapper,
  onChange,
  autoFocus = true,
  showErrorsFromStart = false,
}) => {
  const { t } = useTypedTranslation();
  const isInitialLoad = useRef(true);

  // don't show validation errors initially but only after user first left the input field
  const [showErrors, setShowErrors] = useState(showErrorsFromStart);
  const [isEditableId, setIsEditableId] = useState(false);

  const onUniqueIdChange = (value: string): void => {
    postChanges(input.displayName, value.toUpperCase());
  };

  const onDisplayNameChange = (value: string): void => {
    // as long as the (generated) `uniqueId` isn't different from the `displayName` it's kept in sync
    const previousUniqueId = generateValidSchemaName(input.displayName);
    if (input.uniqueId.length === 0 || previousUniqueId.toUpperCase() === input.uniqueId.toUpperCase()) {
      postChanges(value, generateValidSchemaName(value).toUpperCase());
    } else {
      postChanges(value, input.uniqueId);
    }
  };

  const onRevertEditableIdClicked = (): void => {
    postChanges(input.displayName, generateValidSchemaName(input.displayName).toUpperCase());
    setIsEditableId(false);
  };

  const uniqueIdError = useMemo(
    () => _getUniqueIdError(input.uniqueId, unavailableIds),
    [input.uniqueId, unavailableIds]
  );

  const displayNameError = _getDisplayNameError(input.displayName, unavailableNames);

  const postChanges = useCallback(
    (displayName: string, uniqueId: string): void => {
      const uniqueIdError = _getUniqueIdError(uniqueId, unavailableIds);
      const nameError = _getDisplayNameError(displayName, unavailableNames);
      const hasErrors = !!nameError || !!uniqueIdError;

      onChange({
        displayName: displayName,
        uniqueId: uniqueId,
        hasErrors: hasErrors,
      });
    },
    [onChange, unavailableIds, unavailableNames]
  );

  // handle initial values and check for errors
  useEffect(
    function initialLoad() {
      if (isPreloading) {
        return;
      }

      if (isInitialLoad.current) {
        const { initialId, initialDisplayName } = _getInitialValues(
          input.uniqueId,
          input.displayName,
          startWithValidNameSuggestion,
          unavailableIds,
          unavailableNames
        );
        postChanges(initialDisplayName, initialId);
        isInitialLoad.current = false;
      }
    },
    [
      isPreloading,
      input.displayName,
      input.uniqueId,
      startWithValidNameSuggestion,
      unavailableIds,
      unavailableNames,
      postChanges,
    ]
  );

  const enableErrors = (): void => {
    setShowErrors(true);
  };

  const inputDisplayName = isPreloading ? (
    <Skeleton width="100%" height={36} variant="rounded" />
  ) : (
    <TextInput
      value={input.displayName}
      placeholder={placeholder?.displayName}
      onValueChange={onDisplayNameChange}
      error={showErrors && !!displayNameError}
      title={showErrors ? displayNameError : ''}
      endAdornment={showErrors && displayNameError && <StateIcon variant="Error" noBckgr />}
      autoFocus={autoFocus}
      onBlur={enableErrors}
    />
  );

  const inputId = isPreloading ? (
    <Skeleton width="100%" height={36} variant="rounded" />
  ) : (
    <div className="flex grow items-center gap-2">
      <TextInput
        value={input.uniqueId}
        placeholder={placeholder?.uniqueId}
        onValueChange={onUniqueIdChange}
        error={showErrors && !!uniqueIdError}
        title={showErrors ? uniqueIdError : ''}
        disabled={!isEditableId}
        endAdornment={showErrors && uniqueIdError && <StateIcon variant="Error" noBckgr />}
        onBlur={enableErrors}
      />
      {isEditableId ? (
        <Button
          variant="Outlined"
          Svg={BackwardIcon}
          tooltipCmp={
            <TooltipContent
              header={t('Reset ID')}
              detail={t('Reset to the automatically generated ID based on the name')}
            />
          }
          titleProps={{ placement: 'right' }}
          onClick={onRevertEditableIdClicked}
        />
      ) : (
        <Button
          variant="Outlined"
          Svg={EditIcon}
          title={t('Enter custom ID')}
          titleProps={{ placement: 'right' }}
          onClick={(): void => setIsEditableId(true)}
        />
      )}
    </div>
  );

  return customWrapper ? (
    <>
      {customWrapper(inputDisplayName)}
      {customWrapper(inputId)}
    </>
  ) : (
    <>
      <FormItem labelContent={customLabels?.name ?? t('Name')}>{inputDisplayName}</FormItem>
      <FormItem labelContent={customLabels?.id ?? t('ID')} labelInline={customLabels?.idLabelInline}>
        {inputId}
      </FormItem>
    </>
  );
};

function _getUniqueIdError(inputId: string, unavailableIds: string[]): string {
  const { t } = getTypedTranslation();

  if (!isValidSchemaName(inputId)) {
    return t(INVALID_SCHEMA_INFO);
  } else if (unavailableIds.some(id => id.toLowerCase() === inputId.toLowerCase())) {
    return t('ID already used!');
  }
  return '';
}

function _getDisplayNameError(inputName: string, unavailableNames: string[]): string {
  const { t } = getTypedTranslation();
  const normalizedName = inputName.trim().toLowerCase();

  if (inputName.length < SCHEMA_NAME_MIN_LENGTH) {
    return t(`The minimum length is ${SCHEMA_NAME_MIN_LENGTH} characters!`);
  } else if (unavailableNames.some(name => name.toLowerCase() === normalizedName)) {
    return t('Name already used!');
  }
  return '';
}

function _getInitialValues(
  uniqueId: string,
  displayName: string,
  startWithValidNameSuggestion: boolean | undefined,
  unavailableIds: string[],
  unavailableNames: string[]
): { initialId: string; initialDisplayName: string } {
  let newName = displayName;
  let newId = uniqueId ? uniqueId : generateValidSchemaName(displayName).toUpperCase();
  if (startWithValidNameSuggestion && newName.length > 0) {
    let idx = 2;
    let loopCnt = 0;
    while (_getUniqueIdError(newId, unavailableIds) || _getDisplayNameError(newName, unavailableNames)) {
      newName = `${displayName} ${idx}`;

      // ensure that id doesn't exceed the max length when adding the idx
      newId = generateValidSchemaName(displayName)
        .toUpperCase()
        .substring(0, COMPONENT_MAX_LENGTH - idx.toString().length);
      // add increasing index until the cfgr name is unique
      newId += idx.toString();
      idx++;

      if (loopCnt++ > 1000) {
        return { initialDisplayName: displayName, initialId: generateValidSchemaName(displayName).toUpperCase() };
      }
    }
  }

  return {
    initialDisplayName: newName,
    initialId: newId,
  };
}
