import { RgbaColor, hexToRgba, rgbaToHex, validHex } from '@uiw/color-convert';

export type number3 = [number, number, number];

const _TO_GAMMA_SPACE_EXPONENT = 1 / 2.2;
const _TO_LINEAR_SPACE_EXPONENT = 2.2;

/**
 * Map rgb colors from range 0-255 to 0-1
 */
function _color255To1(color: RgbaColor): number3 {
  return [color.r / 255.0, color.g / 255.0, color.b / 255.0];
}

/**
 * Map the colors ranging from 0-1 to an rgb value ranging from 0-255
 */
function _color1To255(number3Val: number3): RgbaColor {
  return {
    r: Math.round(number3Val[0] * 255),
    g: Math.round(number3Val[1] * 255),
    b: Math.round(number3Val[2] * 255),
    a: 1,
  };
}

/**
 * Convert a hex color to its "linear space color" as its stored for PBR materials
 * This is related to how color is displayed on monitors and how it's perceived by the human eye
 *
 * Conversions taken from: Babylon.js -> math.color.ts
 *
 * Resources:
 * - {@link https://forum.babylonjs.com/t/light-color-in-pbr-in-linear-or-gamma-space/13832}
 * - {@link https://doc.babylonjs.com/preparingArtForBabylon/controllingColorSpace}
 */
export function gammaSpaceHexToLinearSpaceColor(hex: string): number3 | null {
  if (!validHex(hex)) {
    return null;
  }

  const rgbColor = hexToRgba(hex);
  const color3 = _color255To1(rgbColor);
  return [
    Math.pow(color3[0], _TO_LINEAR_SPACE_EXPONENT),
    Math.pow(color3[1], _TO_LINEAR_SPACE_EXPONENT),
    Math.pow(color3[2], _TO_LINEAR_SPACE_EXPONENT),
  ];
}

/**
 * Convert a "linear space color" to it's rgb gamma representation (how we perceive it on monitor displays).
 *
 * For details why this is necessary see {@link gammaSpaceHexToLinearSpaceColor}.
 */
export function linearSpaceColorToGammaSpaceHex(number3Val: number3): string {
  const gammaSpaceNumber3: number3 = [
    Math.pow(number3Val[0], _TO_GAMMA_SPACE_EXPONENT),
    Math.pow(number3Val[1], _TO_GAMMA_SPACE_EXPONENT),
    Math.pow(number3Val[2], _TO_GAMMA_SPACE_EXPONENT),
  ];
  const rgbColor = _color1To255(gammaSpaceNumber3);

  return rgbaToHex(rgbColor);
}

/**
 * Compare two hex strings if they hold the same color info
 * @param hexA
 * @param hexB
 * @param ignoreAlpha
 * @returns
 */
export function isEqualHexColor(hexA: string, hexB: string, ignoreAlpha: boolean): boolean {
  if (validHex(hexA) && validHex(hexB)) {
    const colorA = hexToRgba(hexA);
    const colorB = hexToRgba(hexB);

    return (
      colorA.r === colorB.r && colorA.g === colorB.g && colorA.b === colorB.b && (ignoreAlpha || colorA.a === colorB.a)
    );
  }

  return false;
}
