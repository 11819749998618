import { CFGR_ROUTE_PARAMS } from 'services/routes/common-routeparams.service';
import { buildPath } from 'services/routes/routes.service';

const _BASE_PATH = '/configurators';

// !!! Keys & values must always be the same to work properly !!!
export const CFGRS_PAGE = {
  dashboard: 'dashboard',
  cfgninsights: 'cfgninsights',
  drafts: 'drafts',
  embedding: 'embedding',
};
export type CfgrsRoutePage = keyof typeof CFGRS_PAGE;

export const DASHBOARD_BASE_PATH = `${_BASE_PATH}/${CFGRS_PAGE.dashboard}`;
export const CFGN_INSIGHTS_BASE_PATH = `${_BASE_PATH}/${CFGRS_PAGE.cfgninsights}`;
export const DRAFTS_BASE_PATH = `${_BASE_PATH}/${CFGRS_PAGE.drafts}`;
export const EMBEDDING_BASE_PATH = `${_BASE_PATH}/${CFGRS_PAGE.embedding}`;

const _PARAMS = {
  ...CFGR_ROUTE_PARAMS,
};

export const CFGN_INSIGHTS_ROUTE_PARAMS = {
  ..._PARAMS,
  cfgnId: 'cfgnId',
};
export type CfgnInsightsRouteParams = keyof typeof CFGN_INSIGHTS_ROUTE_PARAMS;

export const CFGRS_PATH_VARIANTS = {
  configurators: `${_BASE_PATH}`,

  dashboard: `${DASHBOARD_BASE_PATH}`,
  dashboardCompany: `${DASHBOARD_BASE_PATH}/:${_PARAMS.companyId}`,
  dashboardCompanyCfgr: `${DASHBOARD_BASE_PATH}/:${_PARAMS.companyId}/:${_PARAMS.cfgrId}`,

  cfgnInsightsCompany: `${CFGN_INSIGHTS_BASE_PATH}/:${_PARAMS.companyId}`,
  cfgnInsightsCompanyCfgr: `${CFGN_INSIGHTS_BASE_PATH}/:${_PARAMS.companyId}/:${_PARAMS.cfgrId}`,
  cfgnInsightsCompanyCfgn: `${CFGN_INSIGHTS_BASE_PATH}/:${_PARAMS.companyId}/:${_PARAMS.cfgrId}/:${CFGN_INSIGHTS_ROUTE_PARAMS.cfgnId}`,

  draftsCompany: `${DRAFTS_BASE_PATH}/:${_PARAMS.companyId}`,
  draftsCompanyCfgr: `${DRAFTS_BASE_PATH}/:${_PARAMS.companyId}/:${_PARAMS.cfgrId}`,

  embeddingCompany: `${EMBEDDING_BASE_PATH}/:${_PARAMS.companyId}`,
  embeddingCompanyCfgr: `${EMBEDDING_BASE_PATH}/:${_PARAMS.companyId}/:${_PARAMS.cfgrId}`,
};

export function buildCfgrsPath(page: CfgrsRoutePage, companyId: string, cfgrId?: string): string {
  return buildPath(_BASE_PATH, page, companyId, cfgrId);
}

export function buildCfgnInsightDetailsPath(companyId: string, cfgrId: string, cfgnId: string): string {
  return buildPath(_BASE_PATH, CFGRS_PAGE.cfgninsights, companyId, cfgrId, cfgnId);
}
