import classNames from 'classnames';
import { LogoutIcon, ReloadIcon } from 'assets/icons';
import { GlobalErrorBody } from 'components/error-handling/global-error-body';
import { Button } from 'controls/button/button';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch } from 'hooks/store/store.hooks';
import * as LocalStorageService from 'services/local-storage/local-storage.service';
import { logout } from 'slices/auth/auth.slice';

function _clearUserData(): void {
  LocalStorageService.clear();
  LocalStorageService.clear(true);
  LocalStorageService.clearCookies(false);
}

type GenericErrorBoundaryFallbackProps = {
  error: Error;
  errorId?: string;
  /** The size of the fallback component is set to the window size */
  scaleToWindowSize?: boolean;
  hideLogoutBtn?: boolean;
  /** Adds a 'Try again' button to the fallback component, which calls the given function */
  resetErrorBoundary?: () => void;
};

export const GenericErrorBoundaryFallback: React.FC<GenericErrorBoundaryFallbackProps> = ({
  error,
  errorId,
  hideLogoutBtn,
  scaleToWindowSize,
  resetErrorBoundary,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();
  const reloadPage = (): void => window.location.reload();

  const logoutClicked = async (): Promise<void> => {
    await dispatch(logout({}));
    _clearUserData();
    reloadPage();
  };

  return (
    <div
      data-cmptype="GenericErrorBoundaryFallback"
      className={classNames('flex flex-col items-center justify-center', {
        'h-screen w-screen': scaleToWindowSize,
      })}
    >
      <article className="flex max-w-md flex-col gap-6">
        <div className="mb-4">
          <h1>{t('Ooops...')}</h1>
          <p>{t('I believe something went wrong')}</p>
        </div>
        <div>
          <GlobalErrorBody header={error.name} text={error.message} errorId={errorId} />
        </div>
        <div className="flex gap-2">
          <Button text="Reload page" Svg={ReloadIcon} onClick={reloadPage}></Button>
          {resetErrorBoundary && (
            <Button text="Try again" Svg={ReloadIcon} onClick={(): void => resetErrorBoundary()}></Button>
          )}
          {!hideLogoutBtn && <Button text="Logout" Svg={LogoutIcon} onClick={logoutClicked}></Button>}
        </div>
      </article>
    </div>
  );
};
