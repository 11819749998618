import { EndpointDescription } from 'services/http/http.service';
import { AssetLink } from 'slices/assets/assets.slice';

export const ASSETLINKS_ENDPOINTS = {
  assetLinksBatchLinkMaterialToImage: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    source: string,
    assetsToLink: AssetLink[],
    assetsToUnlink: AssetLink[]
  ): EndpointDescription => ({
    url: '/assetlinks/batchLinkMaterialToImage',
    params: {
      companyName: companyId,
      assetBundleName: assetBundleId,
      assetBundleVersion: assetBundleVersion,
      source: source,
      assetsToLink: assetsToLink,
      assetsToUnlink: assetsToUnlink,
    },
  }),
};
