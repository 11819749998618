import { ReactComponent as LogoIcon } from 'assets/cbn-logo.svg';
import { LinkButton } from 'controls/button/link-button';
import { Icon } from 'controls/icon/icon';
import { CBN_MAIL_ADRESSES } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

export const AnyQuestionsBee: React.FC = () => {
  const { t } = useTypedTranslation();

  return (
    <div data-cmptype="AnyQuestionsBee" className="flex flex-col items-center gap-4">
      <div className="text-s-regular">
        {t('Any questions? ')}
        <LinkButton variant="TextInline" href={`mailto:${CBN_MAIL_ADRESSES.info}`} text={t('Contact us')} />
      </div>
      <Icon className="h-12 w-12 text-primary-main" Svg={LogoIcon}></Icon>
    </div>
  );
};
