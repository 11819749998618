/**
 * - None:    Initial state, no data has been fetched yet
 * - Reset:   Enforce a new fetch, regardless of other conditions (companyId change, etc.)
 * - Pending: Fetch is pending
 * - Fetched: Corresponding data is fetched and valid for the associated parent selection.\
 *              Eg: If `fetched.bundles` in asset slice is `Fetched` it means, that the bundles are valid for the
 *              current company selection (`selection.companyId`).
 * - Aborted: Fetch has been aborted (e.g. by user) or failed
 */
export type FetchedState = 'None' | 'Reset' | 'Pending' | 'Fetched' | 'Aborted';

export type FetchedStates = Record<string, FetchedState>;

type FetchedStateKeyConfig = { selectionKey: string; fetchedStateKey: string };

/**
 * Resets fetched states to "Pending" if the corresponding selection key is set.
 * Also resets all child fetched states if they have already been fetched once.
 *
 * @param fetchedStates data of this object will be overwritten
 * @param newSelections new selected data, this is most likely coming from the payload of a `setSelection` reducer
 * @param keyConfig mapping of selection keys to fetched state keys, this is an array since the order of entries is
 *                  important for creating parent-child relations
 */
export function updateFetchedStatesOnSelection(
  fetchedStates: FetchedStates,
  newSelections: Record<string, string>,
  keyConfig: FetchedStateKeyConfig[]
): void {
  let resetChilds = false;

  keyConfig.forEach(({ selectionKey, fetchedStateKey }) => {
    if (resetChilds && fetchedStates[fetchedStateKey] === 'Fetched') {
      // parent has been selected, reset childs too if they have already been fetched once
      fetchedStates[fetchedStateKey] = 'Pending';
      return;
    }

    // check if a new selection is set, the exact value is not important
    const selectionVal = newSelections[selectionKey];
    if (selectionVal) {
      fetchedStates[fetchedStateKey] = 'Pending';
      resetChilds = true;
    }
  });
}
