import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import { unstable_usePrompt } from 'react-router-dom';
import { DeleteIcon, EditIcon, PlusIcon, SaveIcon, ScheduledIcon, UpArrowIcon } from 'assets/icons';
import { MoveDownIcon } from 'assets/icons';
import { WorkflowConfigNotifications } from 'components/workflows/details/config/workflow-config-notifications';
import { WorkflowConfigParameter } from 'components/workflows/details/config/workflow-config-parameter';
import {
  WorkflowParameterArray,
  workflowParamsReducer,
} from 'components/workflows/details/config/workflow-param-reducer';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { CronGeneratorModal } from 'controls/cron/cron-generator-modal';
import { Icon } from 'controls/icon/icon';
import { StateIcon } from 'controls/icon/state-icon';
import { TextInput } from 'controls/input/text-input';
import { getFormattedDateAndTime } from 'helper/date-and-time/date-and-time.helper';
import { useCronExpression, useCronScheduler } from 'hooks/common/cron-expression.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { isGenericActionResult } from 'services/http/http.service';
import { buildWorkflowsPath } from 'services/routes/workflows-routes.service';
import {
  EDIT_WORKFLOW_CONFIG_ERROR_RESPONSES,
  EditWorkflowConfigErrorResponse,
  WorkflowNotificationConfig,
  WorkflowParameters,
} from 'services/workflows/workflows.service';
import { getCompanyCfgrs } from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';
import { changeWorkflowConfig, selectSelectedWorkflow } from 'slices/workflows/workflows.slice';

export const WorkflowConfig: React.FC = () => {
  const dispatchApp = useAppDispatch();
  const { t } = useTypedTranslation();

  const { companyId, workflowName, ...selectedWorkflow } = useAppSelector(selectSelectedWorkflow)!;

  const [isProcessing, setIsProcessing] = useState(false);
  const [showCronGenerator, setShowCronGenerator] = useState(false);
  const [showAllCronIntervals, setShowAllCronIntervals] = useState(false);
  const [displayName, setDisplayName] = useState(selectedWorkflow.displayName);
  const [cronExp, parsedCronExp, isValidCronExp, setCronExpString] = useCronExpression(selectedWorkflow.cronExpression);
  const [notificationConfig, setNotificationConfig] = useState<WorkflowNotificationConfig>(
    selectedWorkflow.notificationConfig ?? {}
  );
  // error from 'changeWorkflowConfig' call
  const [errorId, setErrorId] = useState<EditWorkflowConfigErrorResponse>();

  const hasWorkflowsManagePermission = usePermission(CompanyPermissions.ManageWorkflows, companyId);
  const [nextCronTime, nextCronIntervals] = useCronScheduler(cronExp, 5);

  const initialState: WorkflowParameterArray = Object.entries(selectedWorkflow.parameters).map(([name, param]) => ({
    ...param,
    name,
    errors: {},
  }));
  const [parameters, dispatchParams] = useReducer(
    workflowParamsReducer,
    // initialize with current parameters value, and transform it into an array for easier handling in the reducer
    initialState
  );

  const hasParamChanges = !isEqual(
    parameters.map(p => ({ ...p, errors: {} })),
    initialState
  );
  const hasNotificationConfigChanges = !isEqual(notificationConfig, selectedWorkflow.notificationConfig ?? {});
  const cronExpChanged = (selectedWorkflow?.cronExpression ?? '') !== cronExp;
  const hasChanges =
    displayName !== selectedWorkflow.displayName || cronExpChanged || hasParamChanges || hasNotificationConfigChanges;

  unstable_usePrompt({ when: hasChanges, message: t('Discard unsaved changes?') });

  useEffect(() => {
    // make sure that cfgrs are up-to-date
    dispatchApp(getCompanyCfgrs({ companyId }));
  }, [companyId, dispatchApp]);

  const onConfigSave = async (): Promise<void> => {
    setIsProcessing(true);

    // transform updated parameters array back into object form
    const updatedParameters = parameters.reduce<WorkflowParameters>((accParamObj, curParam) => {
      const { name, errors, ...restParam } = curParam;
      return { ...accParamObj, [name]: restParam };
    }, {});

    const result = await dispatchApp(
      changeWorkflowConfig({
        companyId,
        workflowName,
        displayName,
        cronExpression: parsedCronExp ? cronExp : undefined,
        notificationConfig,
        parameters: updatedParameters,
      })
    ).unwrap();
    if (isGenericActionResult(result, EDIT_WORKFLOW_CONFIG_ERROR_RESPONSES)) {
      setErrorId(result.Id);
    } else {
      setErrorId(undefined);
    }

    setIsProcessing(false);
  };

  const onConfigDiscard = (): void => {
    setDisplayName(selectedWorkflow.displayName);
    setCronExpString(selectedWorkflow.cronExpression || '');
    setNotificationConfig(selectedWorkflow.notificationConfig ?? {});
    dispatchParams({ type: 'RESET', payload: initialState });
  };

  const onCronGeneratorConfirm = (newExpression: string): void => {
    setCronExpString(newExpression);
    setShowCronGenerator(false);
  };

  // display name may not be empty
  const displayNameError = !displayName.length;
  const hasCronSetting = isValidCronExp && nextCronTime;
  const someParamsHaveError = parameters.some(p => Object.values(p.errors).some(errorFlag => errorFlag === true));
  const hasErroneousInput = displayNameError || !isValidCronExp || someParamsHaveError || !!errorId;

  return (
    <>
      <div className="flex flex-col gap-4 overflow-auto p-0.5">
        {/*
        The wrapper div is required so that the config card does not shrink once the parameters card body starts growing
        larger than the available space (i.e. when the params card shows its scrollbar)
        */}
        <div className="flex flex-col gap-4">
          <CbnCard data-cmptype="WorkflowConfig">
            <CbnCardHeader
              title={
                <div className="flex grow items-center gap-6">
                  <LinkButton
                    variant="Outlined"
                    Svg={UpArrowIcon}
                    href={buildWorkflowsPath(companyId, workflowName, 'detail')}
                    title={t('Navigate up')}
                  />
                  <h4>
                    {selectedWorkflow.displayName} | {t('Config')}
                  </h4>
                  {hasWorkflowsManagePermission && (
                    <div className="flex gap-2">
                      <Button
                        text={t('Save')}
                        Svg={SaveIcon}
                        disabled={hasErroneousInput || isProcessing || !hasChanges}
                        title={hasErroneousInput ? t('Errors detected. Please check your config!') : ''}
                        onClick={onConfigSave}
                      />
                      <Button
                        text={t('Discard')}
                        disabled={!hasChanges}
                        variant="Secondary"
                        onClick={onConfigDiscard}
                      />
                    </div>
                  )}
                </div>
              }
            />

            <CbnCardBody>
              <form data-cmptype="WorkflowConfig-editForm" className="grid grid-cols-[1fr_1fr]">
                <fieldset className="flex flex-col gap-2">
                  <h3>{t('General')}</h3>
                  <div className="grid grid-cols-[120px_250px] gap-y-1">
                    <label className={'flex items-center text-m-regular'}>{t('Display name')}</label>
                    <TextInput
                      value={displayName}
                      onValueChange={(value): void => setDisplayName(value)}
                      error={displayNameError}
                      endAdornment={
                        displayNameError && (
                          <StateIcon variant="Error" title={t('Display name must not be empty!')} noBckgr />
                        )
                      }
                    />
                  </div>
                </fieldset>
                <fieldset className="flex flex-col gap-2">
                  <h3 className="flex items-center gap-2">
                    {t('Scheduled execution')}
                    <Icon Svg={ScheduledIcon} />
                  </h3>
                  <div className="flex flex-col gap-2">
                    {hasWorkflowsManagePermission && (
                      <div className="flex">
                        <Button
                          text={hasCronSetting ? t('Edit') : t('Add scheduling')}
                          variant="Secondary"
                          Svg={hasCronSetting ? EditIcon : PlusIcon}
                          onClick={(): void => setShowCronGenerator(true)}
                        />
                        {hasCronSetting && (
                          <Button
                            variant="Secondary"
                            Svg={DeleteIcon}
                            text={t('Remove')}
                            title={t('Clear CRON setting')}
                            titleProps={{ placement: 'right' }}
                            onClick={(): void => setCronExpString('')}
                          />
                        )}
                      </div>
                    )}
                    {hasCronSetting && (
                      <div className="flex gap-2">
                        <div
                          className={classNames('self-start text-m-regular', { 'text-neutral-60': !hasCronSetting })}
                        >
                          {t('Next run')}:
                        </div>
                        <div>
                          <div className="flex items-center gap-2">
                            <label className={'text-m-regular'}>{getFormattedDateAndTime(nextCronTime)}</label>
                            <Icon
                              Svg={MoveDownIcon}
                              className={classNames('h-4 cursor-pointer select-none transition-transform', {
                                'rotate-180': showAllCronIntervals,
                              })}
                              title="Show more intervals"
                              titleProps={{ placement: 'right' }}
                              onClick={(): void => {
                                setShowAllCronIntervals(!showAllCronIntervals);
                              }}
                            />
                          </div>
                          <div
                            className={classNames('flex flex-col overflow-hidden transition-[max-height]', {
                              'max-h-0': !showAllCronIntervals,
                              'max-h-[200px]': showAllCronIntervals, // dynamic height wouldn't work with transitions
                            })}
                          >
                            {nextCronIntervals.slice(1).map(interval => (
                              <div key={interval.getTime()} className={'text-m-regular'}>
                                {getFormattedDateAndTime(interval)}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </fieldset>
              </form>
            </CbnCardBody>
          </CbnCard>

          <WorkflowConfigNotifications
            curNotificationConfig={notificationConfig}
            syncedNotificationConfig={selectedWorkflow.notificationConfig}
            onNotificationConfigChanged={setNotificationConfig}
            errorId={errorId}
            onResetError={(): void => setErrorId(undefined)}
          />
        </div>

        <WorkflowConfigParameter parameters={parameters} dispatchParams={dispatchParams} />
      </div>

      {showCronGenerator && (
        <CronGeneratorModal
          initialValue={cronExp}
          minIntervalInMinutes={60}
          onConfirm={onCronGeneratorConfirm}
          onCancel={(): void => setShowCronGenerator(false)}
        />
      )}
    </>
  );
};
