import { useCallback, useEffect, useRef, useState } from 'react';
import { RefreshIcon, UpArrowIcon } from 'assets/icons';
import { Metadata } from 'components/metadata/metadata';
import { MetadataCol } from 'components/metadata/metadata-col';
import { MetadataRow } from 'components/metadata/metadata-row';
import { WorkflowRunStateIcon } from 'components/workflows/details/workflow-run-state-icon';
import { LinkButton } from 'controls/button/link-button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { Icon } from 'controls/icon/icon';
import { MonacoEditor } from 'controls/monaco-editor/monaco-editor';
import {
  getFormattedDateAndTimeFromSeconds,
  getFormattedTimeFromSeconds,
} from 'helper/date-and-time/date-and-time.helper';
import { getIfETagChanged } from 'helper/http/http-get.helper';
import { isSasUriExpired } from 'helper/sas-uri/sas-uri.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { buildWorkflowsPath } from 'services/routes/workflows-routes.service';
import {
  UNFINISHED_WORKFLOW_STATES,
  getWorkflowRun,
  getWorkflowRunLog,
  selectSelectedWorkflow,
  selectSelectedWorkflowRun,
} from 'slices/workflows/workflows.slice';

export const WorkflowRunLog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();

  const { displayName } = useAppSelector(selectSelectedWorkflow)!;
  const {
    companyId,
    workflowName,
    workflowRunId,
    startedAt,
    finishedAt,
    runLogSasUri,
    state: workflowState,
  } = useAppSelector(selectSelectedWorkflowRun)!;

  const hasRunFinished = !UNFINISHED_WORKFLOW_STATES.includes(workflowState);

  const [runLog, setRunLog] = useState('');
  const previousETag = useRef('');

  const startDate = getFormattedDateAndTimeFromSeconds(startedAt);
  const duration = getFormattedTimeFromSeconds(finishedAt - startedAt);

  const fetchLog = useCallback(async (): Promise<void> => {
    if (runLogSasUri) {
      if (isSasUriExpired(runLogSasUri)) {
        // re-fetch a new SAS URI, as the current one has timed out
        dispatch(getWorkflowRunLog({ companyId, workflowName, workflowRunId }));
      } else {
        const response = await getIfETagChanged<string>(runLogSasUri, previousETag.current);

        if (response) {
          setRunLog(response.data);
          previousETag.current = response.headers['etag'];
        }
      }
    }
  }, [companyId, dispatch, runLogSasUri, workflowName, workflowRunId]);

  useEffect(
    function fetchLogsAndStartPolling() {
      fetchLog();
      let logIntervalId = 0;
      let stateIntervalId = 0;
      if (!hasRunFinished) {
        logIntervalId = window.setInterval(() => fetchLog(), 2000);
        stateIntervalId = window.setInterval(
          () => dispatch(getWorkflowRun({ companyId, workflowName, workflowRunId })),
          5000
        );
      }
      return () => {
        window.clearInterval(logIntervalId);
        window.clearInterval(stateIntervalId);
      };
    },
    [dispatch, fetchLog, hasRunFinished, companyId, workflowName, workflowRunId]
  );

  const pathToParent = buildWorkflowsPath(companyId, workflowName);

  return (
    <CbnCard data-cmptype="WorkflowRunLog">
      <CbnCardHeader
        title={
          <div className="flex gap-6">
            <LinkButton variant="Outlined" Svg={UpArrowIcon} href={pathToParent} title={t('Navigate up')} />
            <div className="flex items-center gap-2">
              <h4>
                {displayName} | {t('Run')} {workflowRunId}
              </h4>
              {hasRunFinished ? (
                <WorkflowRunStateIcon state={workflowState} />
              ) : (
                <Icon
                  Svg={RefreshIcon}
                  className="h-5 animate-spin-slow"
                  title={t('Automatic refresh')}
                  titleProps={{ delay: 500 }}
                />
              )}
            </div>
          </div>
        }
      />

      <div data-cmptype="WorkflowRunLog-actions" className="flex flex-col gap-3 border-b border-neutral-40 px-6 pt-4">
        <Metadata>
          <MetadataCol>
            <MetadataRow name={t('Started')} value={startDate}></MetadataRow>
            <MetadataRow name={t('Duration')} value={duration}></MetadataRow>
          </MetadataCol>
        </Metadata>
      </div>

      <section data-cmptype="WorkflowRunLog-log" className="flex grow flex-col gap-2 py-4">
        <MonacoEditor value={runLog} options={{ readOnly: true, minimap: { enabled: false } }} language={'CbnLog'} />
      </section>
    </CbnCard>
  );
};
