import React from 'react';
import { ReplicatorTargetsBundleRows } from 'components/replicator/replicator-targets-bundle-rows';
import { ReplicatorTargetsCfgrRows } from 'components/replicator/replicator-targets-cfgr-rows';
import { Autocomplete } from 'controls/autocomplete/autocomplete';
import { SelectValue } from 'controls/select/select';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'controls/table';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useCompaniesWithPermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { CompanyPermissions } from 'slices/permission/permission.slice';
import {
  loadBlueprintTargetMapping,
  loadBlueprintTargetMappingCrossEnv,
  selectCurrentBlueprint,
} from 'slices/replicator/replicator.slice';

export const ReplicatorTargets: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const companies = useCompaniesWithPermission(
    CompanyPermissions.ManageConfigurator | CompanyPermissions.ManageAssets,
    'All'
  );
  const companiesSorted = [...companies].sort((a, b) => a.displayName.localeCompare(b.displayName));

  const blueprint = useAppSelector(selectCurrentBlueprint);

  const onCompanySelectionChange = (value: SelectValue): void => {
    if (blueprint.blueprintSasUri) {
      dispatch(loadBlueprintTargetMappingCrossEnv(value as string));
    } else {
      dispatch(loadBlueprintTargetMapping(value as string));
    }
  };

  return (
    <div data-cmptype="ReplicatorTargets" className="grid grid-cols-2 gap-6">
      <div className="flex items-center gap-3">
        <span className="whitespace-nowrap">{t('Destination company')}:</span>
        <Autocomplete
          options={companiesSorted.map(c => ({ value: c.id, text: c.displayName }))}
          value={blueprint.target.companyId}
          onChange={onCompanySelectionChange}
          placeholder={t('Select destination company...')}
        />
      </div>
      <div></div>
      {blueprint.target.companyId && (
        <>
          <div className="flex flex-col gap-4">
            <h4>{t('Configurators')}</h4>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Source name')}</TableCell>
                  <TableCell>{t('Target name')}</TableCell>
                  <TableCell>{t('ID')}</TableCell>
                  <TableCell className="w-0">{t('Publish')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(blueprint.target.cfgrs).map(([schemaId, targetCfgr]) => (
                  <ReplicatorTargetsCfgrRows key={schemaId} schemaId={schemaId} targetCfgr={targetCfgr} />
                ))}
              </TableBody>
            </Table>
          </div>
          <div className="flex flex-col gap-4">
            <h4>{t('Asset bundles')}</h4>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Source name')}</TableCell>
                  <TableCell>{t('Target name')}</TableCell>
                  <TableCell>{t('ID')}</TableCell>
                  <TableCell className="w-0">{t('Publish')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(blueprint.target.assetBundles)
                  .filter(([_, b]) => !b.mainVersionSibling)
                  .map(([schemaName, targetBundle]) => (
                    <ReplicatorTargetsBundleRows key={schemaName} schemaName={schemaName} targetBundle={targetBundle} />
                  ))}
              </TableBody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
};
