import { useState } from 'react';
import { DownloadArrowIcon } from 'assets/icons';
import { Button, ButtonVariants } from 'controls/button/button';
import { getExtensionFromAssetFilenameOrUrl } from 'helper/assets/assets.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { postAssetsRequestDownloadSharedAccessForAsset } from 'services/assets/assets.service';
import { Asset, isBabylonAsset, isFileAsset, isImageAsset, isTextAsset } from 'slices/assets/assets.slice';

const _GZ_FILE_ENDING = '.gz';

type AssetDownloadButtonProps = {
  asset: Asset | undefined;
  variant?: ButtonVariants;
  text?: string;
  title?: string;
};

export const AssetDownloadButton: React.FC<AssetDownloadButtonProps> = ({ asset, variant = 'Text', text, title }) => {
  const { t } = useTypedTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const buttonText = text === undefined ? t('Download') : text;

  const isAssetWithUrl =
    asset && (isImageAsset(asset) || isFileAsset(asset) || isTextAsset(asset) || isBabylonAsset(asset));

  const onDownloadClicked = async (): Promise<void> => {
    if (!isAssetWithUrl) {
      return;
    }

    setIsLoading(true);
    const { companyId, bundleId, bundleVersion, folderId, assetId } = asset.path;

    let extension = getExtensionFromAssetFilenameOrUrl(asset.url, 'AtFirstDot');
    // Special handling for Chrome (based on a very basic detection)
    if (extension.endsWith(_GZ_FILE_ENDING) && window.chrome) {
      // When downloading a compressed file (e.g. *.babylon.gz) Chrome will automatically decompress it,
      // so we remove the gz file ending
      // (further details can be found here #CB-8873)
      extension = extension.slice(0, -_GZ_FILE_ENDING.length);
    }

    const friendlyName = `${assetId}.${extension}`;

    const requestedUrl = await postAssetsRequestDownloadSharedAccessForAsset(
      companyId,
      bundleId,
      bundleVersion,
      folderId,
      assetId,
      friendlyName
    );
    _triggerDownload(requestedUrl);

    setIsLoading(false);
  };

  return (
    <Button
      variant={variant}
      text={buttonText}
      title={title}
      Svg={DownloadArrowIcon}
      onClick={onDownloadClicked}
      disabled={!isAssetWithUrl}
      isLoading={isLoading}
    />
  );
};

function _triggerDownload(url: string): void {
  const a = document.createElement('a');
  a.href = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
