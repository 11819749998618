import { useState } from 'react';
import { Outlet, useMatches, useNavigate } from 'react-router-dom';
import { AdminIcon, ReplicatorCopyIcon } from 'assets/icons';
import { ReplicatorCrossEnvModal } from 'components/replicator/replicator-cross-env-modal';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { CbnCaptionCard } from 'controls/cbn-card/implementations/cbn-caption-card';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { REPLICATOR_PAGE, buildReplicatorPath } from 'services/routes/replicator-routes.service';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';
import { SystemPermissions } from 'slices/permission/permission.slice';
import { resetBlueprint, selectCurrentBlueprint } from 'slices/replicator/replicator.slice';

export const Replicator: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const matches = useMatches();
  const inCopyPage = matches.some(m => m.pathname.endsWith(REPLICATOR_PAGE.copy));
  const inProgressPage = matches.some(m => m.pathname.endsWith(REPLICATOR_PAGE.progress));

  const companyId = useAppSelector(selectSelectedCompany)?.id ?? '';
  const blueprint = useAppSelector(selectCurrentBlueprint);
  const hasReplicateCrossEnvPermission = usePermission(SystemPermissions.ReplicateCrossEnv, companyId);
  const [showCrossEnvModal, setShowCrossEnvModal] = useState(false);

  const onNewCopyClicked = async (): Promise<void> => {
    dispatch(resetBlueprint());
    if (!inCopyPage) {
      navigate(buildReplicatorPath(blueprint.companyId, 'copy'));
    }
  };

  const onCancelCopyClicked = async (): Promise<void> => {
    dispatch(resetBlueprint());
    navigate(buildReplicatorPath(blueprint.companyId));
  };

  return (
    <>
      <div data-cmptype="Replicator" className="flex flex-col gap-4 p-6">
        <CbnCaptionCard
          Svg={ReplicatorCopyIcon}
          headerText={t('Copy')}
          subText={t('Copy configurators and asset bundles to other companies.')}
          action={
            <div className="flex gap-2">
              {hasReplicateCrossEnvPermission && (
                <Button
                  Svg={AdminIcon}
                  text={t('Cross-Env Import')}
                  variant={'Outlined'}
                  onClick={(): void => setShowCrossEnvModal(true)}
                />
              )}
              {!inCopyPage && (
                <LinkButton
                  variant="Secondary"
                  text={inProgressPage ? t('Hide progress') : t('View progress')}
                  href={inProgressPage ? buildReplicatorPath(companyId) : buildReplicatorPath(companyId, 'progress')}
                />
              )}
              {inCopyPage && <Button text={t('Cancel')} variant={'Secondary'} onClick={onCancelCopyClicked} />}

              {!inCopyPage && <Button text={t('Select source')} onClick={onNewCopyClicked} />}
            </div>
          }
        >
          <Outlet key={companyId} />
        </CbnCaptionCard>
      </div>
      {showCrossEnvModal && <ReplicatorCrossEnvModal onClose={(): void => setShowCrossEnvModal(false)} />}
    </>
  );
};
