import { useState } from 'react';
import { LinkButton } from 'controls/button/link-button';
import { FormItem } from 'controls/form-item/form-item';
import { PasswordInput } from 'controls/input/password-input';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type UserPasswordFormItemProps = {
  onValueChanged?: (val: string) => void;
  hasError?: boolean;
};

/**
 * `FormItem` which includes detail text about user password requirements (min length, ...).
 */
export const UserPasswordFormItem: React.FC<UserPasswordFormItemProps> = ({ onValueChanged, hasError = false }) => {
  const { t } = useTypedTranslation();
  const [passwordInput, setPasswordInput] = useState('');

  return (
    <FormItem
      labelContent={
        <div>
          <div>{t('Choose password') + ' *'}</div>
          <ul className="list-inside list-disc pl-1 text-s-regular text-neutral-70">
            <li>{t('longer than 10 chars')}</li>
            <li>{t('at least 6 unique chars')}</li>
            <li>{t("doesn't contain the email address")}</li>
            <li>
              {t('not listed on ')}
              <LinkButton
                variant="TextInline"
                href="https://haveibeenpwned.com/Passwords"
                target="_blank"
                text="haveibeenpwned"
              />
            </li>
          </ul>
        </div>
      }
    >
      <PasswordInput
        value={passwordInput}
        onValueChange={(val): void => {
          setPasswordInput(val);
          onValueChanged?.(val);
        }}
        error={hasError}
        autoComplete="new-password"
      />
    </FormItem>
  );
};
