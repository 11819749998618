import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { ArrowNoMarginRightIcon } from 'assets/icons';
import { Icon } from 'controls/icon/icon';
import { cn, tw } from 'helper/css.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { buildAssetEditorPath } from 'services/routes/asset-editor-routes.service';
import { selectSelectedAssetPath, selectSelectedBundleVersion } from 'slices/assets/assets.slice';

type AssetEditorBreadcrumbsProps = {
  onRootClick: () => void;
};

export const AssetEditorBreadcrumbs: React.FC<AssetEditorBreadcrumbsProps> = ({ onRootClick }) => {
  const { t } = useTypedTranslation();

  const { companyId, bundleId, bundleVersion, folderId, assetId } = useAppSelector(selectSelectedAssetPath);
  const selectedBundleVersion = useAppSelector(selectSelectedBundleVersion);

  const linkClasses = tw`block text-s-regular text-neutral-90 hover:text-primary-hover`;

  return (
    <div data-cmptype="AssetEditorBreadcrumbs" className="sticky top-0 z-app-bar bg-neutral-20 pt-6">
      <Breadcrumbs separator={<Icon Svg={ArrowNoMarginRightIcon} className="w-2 grow" />}>
        {/* Doesn't re-navigate but opens the asset editor header instead */}
        <span onClick={onRootClick} className={cn(linkClasses, 'cursor-pointer')}>
          {t('Assets')}
        </span>
        {bundleId && (
          <Link
            to={buildAssetEditorPath(companyId, bundleId)}
            className={cn(linkClasses, !selectedBundleVersion && 'text-primary-main')}
          >
            {bundleId}
          </Link>
        )}
        {selectedBundleVersion && (
          <Link
            to={buildAssetEditorPath(companyId, bundleId, bundleVersion)}
            className={cn(linkClasses, !assetId && 'text-primary-main')}
          >
            {selectedBundleVersion.displayName}
            {selectedBundleVersion.isDraft && ` - ${t('Draft')}`}
          </Link>
        )}
        {folderId && (
          <Link to={buildAssetEditorPath(companyId, bundleId, bundleVersion, folderId)} className={linkClasses}>
            {folderId}
          </Link>
        )}
        {assetId && (
          <Link
            to={buildAssetEditorPath(companyId, bundleId, bundleVersion, folderId, assetId)}
            className={cn(linkClasses, 'text-primary-main')}
          >
            {assetId}
          </Link>
        )}
      </Breadcrumbs>
    </div>
  );
};
