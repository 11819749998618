import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { LoadingSpinner } from 'controls/load-mask/loading-spinner/loading-spinner';
import { tw } from 'helper/css.helper';

type LoadMaskProps = {
  active: boolean;
  children: React.ReactNode;
  spinner?: boolean;
  text?: string;
  small?: boolean;
  transparent?: true | false | 'semi';
  disableFadeIn?: boolean;
};

export const LoadMask: React.FC<LoadMaskProps> = ({
  active,
  children,
  spinner,
  text,
  small,
  transparent,
  disableFadeIn,
}) => {
  const [fadeInPending, setFadeInPending] = useState(!disableFadeIn);
  const opacityCls = fadeInPending ? tw`opacity-0` : tw`opacity-100`;
  const bgCls =
    transparent === 'semi'
      ? tw`bg-[#0000007f] shadow-[0_0_2px_4px_#0000007f]`
      : transparent === true
        ? tw`bg-transparent`
        : tw`bg-neutral-100`;

  useEffect(
    function doAnimate() {
      if (!disableFadeIn) {
        setTimeout(() => {
          setFadeInPending(!active);
        }, 0);
      }
    },
    [active, disableFadeIn]
  );

  return (
    <div data-cmptype="LoadMask" className="relative flex grow">
      {/* show the children in any case */}
      {children}
      {/* optionally show the load mask, it will be inserted with an absolute position so it doesn't interact with the
      layout of the children */}
      {active && (
        <div
          data-cmptype="LoadMask-overlay"
          role="dialog"
          className={classNames(
            'absolute inset-0 flex flex-col items-center justify-center gap-2 transition-opacity duration-500',
            opacityCls,
            bgCls
          )}
        >
          {spinner && <LoadingSpinner small={small} />}
          {text?.length && (
            <span
              className={classNames('text-neutral-10', {
                'text-heading-m': !small,
                'text-heading-s': small,
              })}
            >
              {text}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
