import { useId, useState } from 'react';
import { Checkbox } from 'controls/checkbox/checkbox';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

const _CRON_WEEKDAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

type CronWeekDaysSelectionProps = {
  initialValue: string;
  onChange: (value: string) => void;
};

/**
 * Comma separated numbers are the only valid case
 * As soon as there's an invalid number we return an empty value
 */
function _parseInitialValue(value: string): number[] {
  const numberValues = value.split(',').map(v => parseInt(v));
  if (numberValues.some(v => isNaN(v))) {
    return [];
  } else {
    return numberValues;
  }
}

export const CronWeekDaysSelection: React.FC<CronWeekDaysSelectionProps> = ({ initialValue, onChange }) => {
  const { t } = useTypedTranslation();
  const [selections, setSelections] = useState<number[]>(_parseInitialValue(initialValue));

  const lblId = useId();

  const onValueChanged = (value: boolean, idx: number): void => {
    const newSelections = value ? [...selections, idx] : selections.filter(s => s !== idx);
    newSelections.sort();
    setSelections(newSelections);
    if (newSelections.length > 0 && newSelections.length < _CRON_WEEKDAYS.length) {
      onChange(newSelections.join(','));
    } else {
      onChange('*');
    }
  };

  return (
    <div className="flex gap-3" data-cmptype="CronWeekDaysSelection">
      {_CRON_WEEKDAYS.map((day, idx) => {
        const cbId = `${lblId}_${idx}`;
        return (
          <div key={idx} className="flex items-center gap-1">
            <label className="cursor-pointer select-none" htmlFor={cbId}>
              {t(day)}
            </label>
            <Checkbox
              id={cbId}
              checked={selections.includes(idx)}
              onValueChanged={(value): void => {
                onValueChanged(value, idx);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
