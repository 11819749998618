import { useLayoutEffect, useRef, useState } from 'react';
import { Autocomplete } from 'controls/autocomplete/autocomplete';
import { StateIcon } from 'controls/icon/state-icon';
import { TextInput } from 'controls/input/text-input';
import { SelectValue } from 'controls/select/select';
import { INVALID_HIVE_IDENTIFIER_INFO, isValidHiveIdentifier } from 'helper/hive/hive.helper';
import { NEW_FOLDER_KEY, NO_FOLDER_KEY, useGetFolderAssetSelectOptions } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type AssetFolderSelectionProps = {
  preSelectedFolderId?: string;
  showNewFolderOption?: boolean;
  onChange: (selectValue: string, newFolderInputValue: string) => void;
};

export const AssetFolderSelection: React.FC<AssetFolderSelectionProps> = ({
  preSelectedFolderId,
  showNewFolderOption,
  onChange,
}) => {
  const { t } = useTypedTranslation();
  const newFolderInputRef = useRef<HTMLInputElement>(null);
  const [showError, setShowError] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(preSelectedFolderId || NO_FOLDER_KEY);
  const [selectedFolderFromInput, setSelectedFolderFromInput] = useState('');
  const isNewFolder = selectedFolder === NEW_FOLDER_KEY;
  const isInvalidFolderInput = isNewFolder && !isValidHiveIdentifier(selectedFolderFromInput);

  const folderOptions = useGetFolderAssetSelectOptions(true, showNewFolderOption);

  const onFolderSelectionChange = (value: SelectValue): void => {
    const folder = value as string;
    setSelectedFolder(folder);
    onChange?.(folder, selectedFolderFromInput);
  };

  useLayoutEffect(
    function focusNewFolderInput() {
      if (selectedFolder === NEW_FOLDER_KEY) {
        newFolderInputRef.current?.focus();
      }
    },
    [selectedFolder]
  );

  const onFolderFromInputChange = (value: string): void => {
    setSelectedFolderFromInput(value);
    onChange?.(selectedFolder, value);
  };

  return (
    <div data-cmptype="AssetFolderSelection" className="flex flex-grow flex-col gap-2">
      <Autocomplete
        options={folderOptions}
        value={selectedFolder}
        onChange={onFolderSelectionChange}
        disableClearable
      />
      {isNewFolder && (
        <TextInput
          inputRef={newFolderInputRef}
          value={selectedFolderFromInput}
          onValueChange={onFolderFromInputChange}
          placeholder={t('Folder name')}
          onBlur={(): void => setShowError(true)}
          error={showError && isInvalidFolderInput}
          endAdornment={
            showError &&
            isInvalidFolderInput && <StateIcon variant="Error" noBckgr title={t(INVALID_HIVE_IDENTIFIER_INFO)} />
          }
        />
      )}
    </div>
  );
};
