import { GridEditSingleSelectCellProps } from '@mui/x-data-grid/components/cell/GridEditSingleSelectCell';
import { isSingleSelectColDef } from '@mui/x-data-grid/components/panel/filterPanel/filterPanelUtils';
import { useGridApiContext } from '@mui/x-data-grid/hooks/utils/useGridApiContext';
import { ValueOptions } from '@mui/x-data-grid/models/colDef/gridColDef';
import { useLayoutEffect, useRef } from 'react';
import { Autocomplete } from 'controls/autocomplete/autocomplete';
import { SelectOption, SelectValue } from 'controls/select/select';

/**
 * Autocomplete implementation for data grid cell.
 * Some of the basics are taken from packages\grid\x-data-grid\src\components\cell\GridEditSingleSelectCell.tsx,
 * but most of the code wasn't reusable as the interfaces differ too much.
 * => Enhance this component if functionalities are missing!
 */
export const GridEditAutocompleteCell: React.FC<GridEditSingleSelectCellProps> = props => {
  const { id, value: valueProp, field, row, colDef, hasFocus } = props;

  const apiRef = useGridApiContext();
  const inputRef = useRef<HTMLInputElement>(null);

  // forward focus to input sub component of autocomplete
  useLayoutEffect(() => {
    if (hasFocus) {
      inputRef.current?.focus();
    }
  }, [hasFocus]);

  // create and convert options for our autocomplete implementation
  if (!isSingleSelectColDef(colDef)) {
    return null;
  }
  let valueOptions: Array<ValueOptions> | undefined;
  if (typeof colDef?.valueOptions === 'function') {
    valueOptions = colDef?.valueOptions({ id, row, field });
  } else {
    valueOptions = colDef?.valueOptions;
  }
  const selectOptions: SelectOption[] = (
    valueOptions as {
      value: string;
      label: string;
    }[]
  ).map(option => ({ value: option.value, text: option.label }));

  const handleChange = async (value: SelectValue): Promise<void> => {
    // NOTE: seems like "SelectValue" can be "undefined", which happens when using the "x" button within the
    // Autocomplete
    // typing may be adjusted, but only for Autocomplete instances that have "disableClearable" unset
    await apiRef.current.setEditCellValue({ id, field, value: value ?? '' });
  };

  return <Autocomplete value={valueProp} onChange={handleChange} options={selectOptions} inputRef={inputRef} />;
};
