module.exports = {
  shadows: {
    'none': 'none',
    'xs': '0px 1px 2px rgba(16, 24, 40, 0.05)',
    'sm': '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    'md': '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
    'lg': '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    'xl': '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
    '2xl': '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '3xl': '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',

    'sm-symmetric': '0px 0.5px 3px 0px rgba(16, 24, 40, 0.10), 0px 0px 2px 0px rgba(16, 24, 40, 0.06)',
    'ctrl-disabled': 'inset 0 0 0 1px #000',
    'select-menu': '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
    'table-row': '0 -1px 0px 0px #EDF2F7 inset',

    /**
     * color neutral[40], can be used instead of border to keep the line "inside" the elements box.
     */
    'divider-line': 'inset 0 -1px #EAECF0',

    /**
     * Draws a line inside the element at its bottom.
     *
     * No explicit color defined -> uses the `color` from the element (same as text)
     * Custom color could be applied using something like this:
     * ``` boxShadow: `${theme.shadows[NAMED_SHADOW.UnderlineThick]} ${theme.palette.primary.surface}` ```
     */
    'underline-thick': 'inset 0 -2px',

    /**
     * [ELEVATION_SHADOW.LG] with inverted & adjusted value of the first Y-offset
     * This is done so that the arrow has better visibility
     */
    'tooltip-inverted': '0px -8px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  },
};
