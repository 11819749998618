import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as CbnLogoTxtWhite } from 'assets/cbn-logo-text-white.svg';
import { ArrowNoMarginLeftIcon } from 'assets/icons';
import { ErrorBoundary } from 'components/error-handling/cbn-error-boundary';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { FormItem } from 'controls/form-item/form-item';
import { PasswordInput } from 'controls/input/password-input';
import { TextInput } from 'controls/input/text-input';
import { CBN_MAIL_ADRESSES } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch } from 'hooks/store/store.hooks';
import { loginFailedResponses } from 'services/auth/auth.service';
import { isGenericActionResult } from 'services/http/http.service';
import { PATHS } from 'services/routes/paths.service';
import { aiInstance } from 'services/telemetry/telemetry.service';
import { login } from 'slices/auth/auth.slice';

/**
 * Login page where a redirect url via query-parameter is mandatory
 */
export const EmbeddedLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const { t } = useTypedTranslation();

  const redirectUrl = decodeURIComponent(params.get('redirectTo') ?? '');

  const genericLoginFailedMsg = t('Login failed! Please check your credentials!');

  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [failedLoginMsg, setFailedLoginMsg] = useState('');
  const isNotAllEntered = !emailInput || !passwordInput;

  const onLogin = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    setIsProcessing(true);
    setFailedLoginMsg('');

    try {
      const result = await dispatch(
        login({
          email: emailInput,
          password: passwordInput,
        })
      ).unwrap();
      console.log(result);
    } catch (res: unknown) {
      const msg = (isGenericActionResult(res, loginFailedResponses) && res.Message) || genericLoginFailedMsg;
      setFailedLoginMsg(msg);

      setIsProcessing(false);
      setPasswordInput('');
      return;
    }

    if (redirectUrl) {
      // Replace the url so that the login can't be reached with a "browser back"
      window.location.replace(redirectUrl);
    }
  };

  return (
    <ErrorBoundary appInsights={aiInstance} scaleToWindowSize>
      <div className="flex grow flex-col overflow-y-auto">
        <header
          role="heading"
          aria-level={1}
          className="flex h-14 shrink-0 items-center justify-between bg-primary-main px-6 py-1 text-neutral-10"
        >
          <CbnLogoTxtWhite width={140} className="overflow-hidden" />
        </header>
        <div className="relative flex grow items-center justify-center bg-neutral-20">
          {redirectUrl && (
            <div className="absolute left-5 top-5">
              <LinkButton
                href={redirectUrl}
                Svg={ArrowNoMarginLeftIcon}
                text={t('back')}
                variant="TextInline"
                reloadDocument
              />
            </div>
          )}
          <div className="m-6 max-w-[400px] grow">
            {redirectUrl && (
              <div className="flex flex-col gap-6">
                <CbnCard>
                  <CbnCardBody>
                    <div className="flex flex-col gap-6 pt-3">
                      <div className="flex w-full flex-col gap-2">
                        <h3>{t('Log in')}</h3>
                        <span className="text-m-regular text-neutral-70">{t('Please enter your credentials.')}</span>
                      </div>
                      <form onSubmit={onLogin} className="flex flex-col gap-6">
                        <FormItem labelContent={t('Your email')}>
                          <TextInput
                            type="text"
                            autoComplete="username"
                            error={!!failedLoginMsg}
                            placeholder={CBN_MAIL_ADRESSES.placeholderMail}
                            value={emailInput}
                            onValueChange={(val): void => {
                              setEmailInput(val);
                            }}
                            autoFocus
                          ></TextInput>
                        </FormItem>
                        <FormItem
                          labelContent={
                            <div className="flex w-full">
                              <span className="flex-1">{t('Your password')}</span>
                              <LinkButton
                                variant="TextInline"
                                href={PATHS.forgotPassword}
                                target="_blank"
                                text={t('Forgot password?')}
                              />
                            </div>
                          }
                        >
                          <PasswordInput
                            error={!!failedLoginMsg}
                            value={passwordInput}
                            onValueChange={(val): void => {
                              setPasswordInput(val);
                            }}
                          />
                        </FormItem>
                        {failedLoginMsg && <span className=" text-s-regular text-danger-main">{failedLoginMsg}</span>}
                        <div className="flex flex-grow">
                          <Button
                            type="submit"
                            variant="Primary"
                            big
                            grow
                            text={t('Login')}
                            disabled={isProcessing || isNotAllEntered}
                            isLoading={isProcessing}
                          />
                        </div>
                      </form>
                    </div>
                  </CbnCardBody>
                </CbnCard>
                <div className="flex flex-row items-center gap-1 self-center text-m-regular text-neutral-90">
                  <span>{t("Don't have an account?")}</span>
                  <LinkButton
                    variant="TextInline"
                    href={redirectUrl}
                    text={t('Get back to the configurator')}
                    reloadDocument
                  />
                </div>
              </div>
            )}
            {!redirectUrl && (
              <CbnCard>
                <CbnCardHeader title={t('Something went wrong')} />
                <CbnCardBody>
                  <div className="mb-4">
                    {t('Please try to go back and login again or contact us if the issue persists!')}
                  </div>
                  <Button
                    text="Go back"
                    onClick={(): void => {
                      // we have nowhere to redirect to, so simply execute a "browser back"
                      window.history.back();
                    }}
                  />
                </CbnCardBody>
              </CbnCard>
            )}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
