import classNames from 'classnames';
import React from 'react';
import { CtrlStateClassDefinition } from 'helper/controls/controls.helper';
import { cn, tw } from 'helper/css.helper';

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  resizeable?: boolean;
  preventTextWrap?: boolean;
};

const _textareaStateMap: CtrlStateClassDefinition = {
  default: tw`border-neutral-40 bg-neutral-10 text-neutral-100 placeholder:text-neutral-70`,
  hover: tw`hover:border-neutral-50`,
  focus: tw`focus:border-neutral-10 focus:outline focus:outline-2 focus:outline-primary-focus`,
  active: tw`active:border-neutral-60`,
  disabled: tw`placeholder:text-neutral-60 disabled:border-neutral-40 disabled:bg-neutral-30 disabled:text-neutral-60`,
  invalid: tw`invalid:border-danger-main`,
};

function _textareaStyleFactory(resizeable: boolean, preventTextWrap: boolean): string {
  const defaultClassName = classNames(
    tw`m-outline-2 h-full flex-grow rounded border p-4 text-m-regular`,
    !resizeable && tw`resize-none`,
    preventTextWrap && tw`whitespace-pre`
  );

  const stateClassName = Object.values(_textareaStateMap).join(' ');

  return `${defaultClassName} ${stateClassName}`;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ resizeable = false, preventTextWrap = false, className = '', ...props }, ref) => {
    return (
      <textarea
        className={cn(_textareaStyleFactory(resizeable, preventTextWrap), className)}
        ref={ref}
        {...props}
      ></textarea>
    );
  }
);
