import classNames from 'classnames';
import { useCallback } from 'react';
import { DeleteIcon, PlusIcon } from 'assets/icons';
import { SelectBundleOfCfgr } from 'components/workflows/details/config/workflow-config-parameter-asset-bundle-selection';
import {
  WorkflowParameterArray,
  WorkflowParamsAction,
} from 'components/workflows/details/config/workflow-param-reducer';
import { Button } from 'controls/button/button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { Checkbox } from 'controls/checkbox/checkbox';
import { StateIcon } from 'controls/icon/state-icon';
import { NumberInput, NumberOrNull } from 'controls/input/number-input';
import { TextInput } from 'controls/input/text-input';
import { Select, SelectOption } from 'controls/select/select';
import { Tooltip } from 'controls/tooltip/tooltip';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { WorkflowParameterTypes } from 'generated/workflow-parameter-types';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectAllCompanyCfgrs } from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';
import { selectSelectedWorkflow } from 'slices/workflows/workflows.slice';

type WorkflowConfigParameterProps = {
  parameters: WorkflowParameterArray;
  dispatchParams: React.Dispatch<WorkflowParamsAction>;
};

const _PARAMETER_TYPE_OPTIONS: Record<WorkflowParameterTypes, SelectOption<string>> = {
  [WorkflowParameterTypes.Bool]: {
    value: WorkflowParameterTypes.Bool,
    text: 'Bool',
    group: 'Simple',
  },
  [WorkflowParameterTypes.Number]: {
    value: WorkflowParameterTypes.Number,
    text: 'Number',
    group: 'Simple',
  },
  [WorkflowParameterTypes.String]: {
    value: WorkflowParameterTypes.String,
    text: 'String',
    group: 'Simple',
  },
  [WorkflowParameterTypes.File]: {
    value: WorkflowParameterTypes.File,
    text: 'File',
    group: 'Advanced',
  },
  [WorkflowParameterTypes.AssetBundleAssignedToCfgr]: {
    value: WorkflowParameterTypes.AssetBundleAssignedToCfgr,
    text: 'Asset Bundle',
    group: 'Advanced',
  },
};

export const WorkflowConfigParameter: React.FC<WorkflowConfigParameterProps> = ({ parameters, dispatchParams }) => {
  const { t } = useTypedTranslation();

  const { companyId } = useAppSelector(selectSelectedWorkflow)!;

  const hasWorkflowsManagePermission = usePermission(CompanyPermissions.ManageWorkflows, companyId);
  // get all cfgrs for the selected company
  const cfgrsOfSelectedCompany = useAppSelector(selectAllCompanyCfgrs);
  const sortedCfgrs = [...cfgrsOfSelectedCompany].sort((a, b) => a.displayName.localeCompare(b.displayName));

  const cfgrOptions: SelectOption<string>[] = sortedCfgrs.map(cfgr => ({
    // NOTE: generally ignore casing for asset bundles and cfgrs, so that the target value can be recognized in the
    // select options
    value: cfgr.id.toUpperCase(),
    text: cfgr.displayName,
  }));

  const paramTypeOptions: SelectOption<string>[] = Object.values(_PARAMETER_TYPE_OPTIONS).sort((a, b) =>
    a.text!.localeCompare(b.text!)
  );

  const onAddParam = (): void => {
    dispatchParams({ type: 'ADD_PARAMETER' });
  };

  const onDeleteParam = (idx: number): void => {
    dispatchParams({ type: 'DELETE_PARAMETER', payload: idx });
  };

  const onChangeParamName = (value: string, idx: number): void => {
    dispatchParams({ type: 'CHANGE_NAME', payload: { name: value, idx: idx } });
  };

  const onChangeParamType = (value: WorkflowParameterTypes, idx: number): void => {
    dispatchParams({ type: 'CHANGE_TYPE', payload: { type: value, idx: idx } });
  };

  const onChangeDefaultValue = (value: boolean | NumberOrNull | string, idx: number): void => {
    dispatchParams({ type: 'CHANGE_DEFAULT_VALUE', payload: { defaultValue: value, idx: idx } });
  };

  const onChangeCfgr = (value: string, idx: number): void => {
    dispatchParams({ type: 'CHANGE_BUNDLE_PARAMS', payload: { params: { cfgrName: value }, idx: idx } });
  };

  const onChangeBundle = useCallback(
    (value: string, idx: number): void => {
      dispatchParams({ type: 'CHANGE_BUNDLE_PARAMS', payload: { params: { bundleName: value }, idx: idx } });
    },
    [dispatchParams]
  );

  return (
    <CbnCard data-cmptype="WorkflowConfigParameter">
      <CbnCardHeader
        title={
          <div className="flex items-center gap-2">
            <h3>{t('Parameter')}</h3>
            {hasWorkflowsManagePermission && (
              <Button
                variant="Secondary"
                text={t('Add parameter')}
                Svg={PlusIcon}
                onClick={onAddParam}
                disabled={parameters.length >= 20}
              />
            )}
          </div>
        }
      />

      <CbnCardBody>
        <section className="grid grid-cols-[repeat(auto-fill,_minmax(500px,_1fr))] gap-4">
          {parameters.map((param, idx) => {
            return (
              <CbnCard key={idx} variant="nested">
                <div className="grid grid-cols-[1fr_2fr] items-center gap-1 border-b border-neutral-40 px-6 py-2">
                  <span className="text-m-regular">{t('Name')}</span>
                  <TextInput
                    value={param.name}
                    onValueChange={(value): void => {
                      onChangeParamName(value, idx);
                    }}
                    error={param.errors.duplicateName}
                    disabled={!hasWorkflowsManagePermission}
                  />
                  <span className="text-m-regular">{t('Type')}</span>
                  <Select
                    options={paramTypeOptions}
                    value={param.type}
                    onChange={(value): void => onChangeParamType(value as WorkflowParameterTypes, idx)}
                    disabled={!hasWorkflowsManagePermission}
                  />
                </div>

                <div className="flex flex-col p-6">
                  <div className="grid grid-cols-[1fr_2fr] items-center justify-items-start gap-1">
                    {param.type === WorkflowParameterTypes.AssetBundleAssignedToCfgr ? (
                      <>
                        <span className="text-m-regular">{t('Configurator')}</span>
                        <Select
                          options={cfgrOptions}
                          value={param.cfgrName.toUpperCase()}
                          onChange={(value): void => onChangeCfgr(value as string, idx)}
                          error={sortedCfgrs.every(cfgr => cfgr.id.toUpperCase() !== param.cfgrName.toUpperCase())}
                          disabled={!hasWorkflowsManagePermission}
                        />
                        <span className="text-m-regular">{t('Bundle')}</span>
                        <SelectBundleOfCfgr
                          companyId={companyId}
                          cfgrId={param.cfgrName}
                          value={param.bundleName}
                          paramIdx={idx}
                          onChange={onChangeBundle}
                          disabled={!hasWorkflowsManagePermission}
                        />
                      </>
                    ) : param.type === WorkflowParameterTypes.File ? (
                      <>
                        <span className="text-m-regular">{t('Url')}</span>
                        <TextInput
                          value={param.url}
                          error={param.url.length === 0}
                          onValueChange={(value): void => {
                            dispatchParams({
                              type: 'CHANGE_FILE_PARAMS',
                              payload: { idx, params: { url: value } },
                            });
                          }}
                          disabled={!hasWorkflowsManagePermission}
                        />
                        <span className="col-span-2 mb-1 mt-3 text-l-medium">{t('Optional')}</span>
                        <span
                          className={classNames('text-m-regular', {
                            'text-neutral-60': !param.authorizationScheme,
                          })}
                        >
                          {t('Authorization scheme')}
                        </span>
                        <TextInput
                          value={param.authorizationScheme ?? ''}
                          onValueChange={(value): void => {
                            dispatchParams({
                              type: 'CHANGE_FILE_PARAMS',
                              payload: { idx, params: { authorizationScheme: value } },
                            });
                          }}
                          disabled={!hasWorkflowsManagePermission}
                        />
                        <span
                          className={classNames('text-m-regular', {
                            'text-neutral-60': !param.authorizationParameter,
                          })}
                        >
                          {t('Authorization parameter')}
                        </span>
                        <TextInput
                          value={param.authorizationParameter ?? ''}
                          onValueChange={(value): void => {
                            dispatchParams({
                              type: 'CHANGE_FILE_PARAMS',
                              payload: { idx, params: { authorizationParameter: value } },
                            });
                          }}
                          disabled={!hasWorkflowsManagePermission}
                        />
                        <span
                          className={classNames('text-m-regular', {
                            'text-neutral-60': !param.overwriteContentType,
                          })}
                        >
                          {t('Overwrite content type')}
                        </span>
                        <TextInput
                          value={param.overwriteContentType ?? ''}
                          onValueChange={(value): void => {
                            dispatchParams({
                              type: 'CHANGE_FILE_PARAMS',
                              payload: { idx, params: { overwriteContentType: value } },
                            });
                          }}
                          disabled={!hasWorkflowsManagePermission}
                        />
                      </>
                    ) : (
                      <>
                        <span className="text-m-regular">{t('Default value')}</span>
                        <WorkflowParamDefaultValue
                          type={param.type}
                          value={param.defaultValue}
                          disabled={!hasWorkflowsManagePermission}
                          onValueChange={(value): void => onChangeDefaultValue(value, idx)}
                        />
                      </>
                    )}
                  </div>
                  <div className="flex items-center pt-2">
                    {param.type === WorkflowParameterTypes.AssetBundleAssignedToCfgr && (
                      <Tooltip
                        arrow
                        placement="bottom-start"
                        interactive
                        title={
                          <TooltipContent
                            header={t('Choose an asset bundle that is assigned to a specific configurator.')}
                            detail={
                              <>
                                <p>
                                  {t(
                                    'The workflow will always use the asset bundle version which ' +
                                      'is also used by the configurator. Only works with published configurators.'
                                  )}
                                </p>
                                <p className="mt-2">
                                  {t(
                                    '💡 You can use this parameter to make changes to an asset bundle, ' +
                                      'publish it and also publish configurators that use it.'
                                  )}
                                </p>
                              </>
                            }
                          />
                        }
                      >
                        <span className="cursor-pointer">
                          <StateIcon variant="Info" noBckgr />
                        </span>
                      </Tooltip>
                    )}
                    {hasWorkflowsManagePermission && (
                      <div className="flex grow justify-end">
                        <Button
                          variant="Secondary"
                          text={t('Delete')}
                          hoverColor="Danger"
                          Svg={DeleteIcon}
                          onClick={(): void => {
                            onDeleteParam(idx);
                          }}
                          disabled={parameters.length >= 20}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </CbnCard>
            );
          })}
        </section>
      </CbnCardBody>
    </CbnCard>
  );
};

type WorkflowParamDefaultValueProps = {
  type: WorkflowParameterTypes;
  value: boolean | NumberOrNull | string;
  disabled?: boolean;
  onValueChange: (value: boolean | NumberOrNull | string) => void;
};

const WorkflowParamDefaultValue: React.FC<WorkflowParamDefaultValueProps> = ({
  type,
  value,
  disabled,
  onValueChange,
}) => {
  // generate different components, depending on the type of parameter
  switch (type) {
    case WorkflowParameterTypes.Bool:
      return <Checkbox checked={value as boolean} onValueChanged={onValueChange} disabled={disabled} />;

    case WorkflowParameterTypes.Number:
      return (
        <NumberInput inputMode="decimal" value={value as NumberOrNull} onChange={onValueChange} disabled={disabled} />
      );

    case WorkflowParameterTypes.String:
      return <TextInput value={value as string} onValueChange={onValueChange} disabled={disabled} />;

    default:
      return <span>-</span>;
  }
};
