import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { useMemo } from 'react';
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { muiTheme } from 'styles/mui-theme';
import { InfoIcon } from 'assets/icons';
import { Admin } from 'components/admin/admin';
import { AppBody } from 'components/app-body/app-body';
import { AssetEditor } from 'components/asset-editor/asset-editor';
import { CfgnInsightDetailsDrawer } from 'components/cfgrs/cfgn-insights/cfgn-insight-details-drawer';
import { CfgnInsights } from 'components/cfgrs/cfgn-insights/cfgn-insights';
import { CfgrWrapper } from 'components/cfgrs/cfgr-wrapper';
import { Dashboard } from 'components/cfgrs/dashboard/dashboard';
import { Drafts } from 'components/cfgrs/drafts/drafts';
import { Embedding } from 'components/cfgrs/embedding/embedding';
import { NavigateToCfgr } from 'components/cfgrs/routing/navigate-to-cfgr';
import { CompanySettings } from 'components/company/company-settings';
import { CompanySettingsStaff } from 'components/company/company-settings-staff';
import { CompanySettingsStaffProvider } from 'components/company/company-settings-staff-provider';
import { ControlOverview } from 'components/debug/control-overview';
import { Home } from 'components/home/home';
import { AcceptCompanyInvitationIndex } from 'components/invitation/accept-company-invitation-index';
import { AcceptCompanyInvitationInvalid } from 'components/invitation/accept-company-invitation-invalid';
import { AcceptCompanyInvitationLogin } from 'components/invitation/accept-company-invitation-login';
import { AcceptCompanyInvitationRegister } from 'components/invitation/accept-company-invitation-register';
import { AcceptCompanyInvitationWrapper } from 'components/invitation/accept-company-invitation-wrapper';
import { ForgotPassword, Login, ResetPassword, UserActivation, UserRegistration } from 'components/login';
import { EmbeddedLogin } from 'components/login/embedded/embedded-login';
import { Replicator } from 'components/replicator/replicator';
import { ReplicatorCopy } from 'components/replicator/replicator-copy';
import { ReplicatorProgress } from 'components/replicator/replicator-progress';
import { AnonymousUserRouteWrapper } from 'components/routes/anonymous-user-route-wrapper';
import { AuthorizedRouteWrapper } from 'components/routes/authorized-route-wrapper';
import { CompanyFeatureRouteWrapper } from 'components/routes/company-feature-route-wrapper';
import { CompanyStateWrapper } from 'components/routes/company-state-wrapper';
import {
  ActivationRouteHandle,
  LoggedInRequiredRouteWrapper,
} from 'components/routes/logged-in-required-route-wrapper';
import { UserProfile } from 'components/user-profile/user-profile';
import { Workflows } from 'components/workflows/workflows';
import { LinkButton } from 'controls/button/link-button';
import { CbnCaptionCard } from 'controls/cbn-card/implementations/cbn-caption-card';
import { CookieBanner } from 'hooks/app/cookie-banner';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { ASSET_EDITOR_PATH_VARIANTS } from 'services/routes/asset-editor-routes.service';
import { CFGN_INSIGHTS_ROUTE_PARAMS, CFGRS_PAGE } from 'services/routes/cfgrs-routes.service';
import { CFGR_ROUTE_PARAMS } from 'services/routes/common-routeparams.service';
import { COMPANY_SETTINGS_PATH_VARIANTS } from 'services/routes/company-settings-routes.service';
import { PATHS } from 'services/routes/paths.service';
import { REPLICATOR_PAGE } from 'services/routes/replicator-routes.service';
import { STAFF_SETTINGS_PATH_VARIANTS } from 'services/routes/staff-settings-routes.service';
import { WORKFLOWS_PATH_VARIANTS } from 'services/routes/workflows-routes.service';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';
import { CompanyPermissions, PermissionBundles } from 'slices/permission/permission.slice';

export const App: React.FC = () => {
  const { t, tMissingFeature, tUnauthorized } = useTypedTranslation();

  const controlOverviewPageEnabled = useAppSelector(state => state.debug.inDevEnv);
  const companyDisplayName = useAppSelector(selectSelectedCompany)?.displayName || '';

  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <Route path={PATHS.root}>
            <Route element={<AppBody />}>
              <Route element={<LoggedInRequiredRouteWrapper />}>
                {/* Be careful when changing this route (location, ...). Search for CB-9287 for more info */}
                <Route element={<CompanyStateWrapper />}>
                  <Route index element={<Navigate to={PATHS.home} replace />} />

                  <Route path={PATHS.home} element={<Home />} />
                  <Route path={PATHS.companyHome} element={<Home />} />

                  <Route path={PATHS.configurators} element={<CfgrWrapper />}>
                    <Route index element={<NavigateToCfgr page="dashboard" />} />

                    <Route path={`${CFGRS_PAGE.dashboard}/:${CFGR_ROUTE_PARAMS.companyId}`}>
                      <Route index element={<NavigateToCfgr page="dashboard" />} />
                      <Route path={`:${CFGR_ROUTE_PARAMS.cfgrId}`} element={<Dashboard />} />
                    </Route>

                    <Route
                      element={
                        <AuthorizedRouteWrapper
                          permission={CompanyPermissions.ModifyCompanySettings}
                          fallbackCmp={
                            <FallbackWithDashboardLink
                              title={t('Unauthorized')}
                              detailText={tUnauthorized('Configuration insights', companyDisplayName)}
                            />
                          }
                        />
                      }
                    >
                      <Route path={`${CFGRS_PAGE.cfgninsights}/:${CFGR_ROUTE_PARAMS.companyId}`}>
                        <Route index element={<NavigateToCfgr page="cfgninsights" />} />

                        <Route path={`:${CFGR_ROUTE_PARAMS.cfgrId}`} element={<CfgnInsights />}>
                          <Route
                            path={`:${CFGN_INSIGHTS_ROUTE_PARAMS.cfgnId}`}
                            element={<CfgnInsightDetailsDrawer />}
                          />
                        </Route>
                      </Route>
                    </Route>

                    <Route path={`${CFGRS_PAGE.drafts}/:${CFGR_ROUTE_PARAMS.companyId}`}>
                      <Route index element={<NavigateToCfgr page="drafts" />} />
                      <Route path={`:${CFGR_ROUTE_PARAMS.cfgrId}`} element={<Drafts />} />
                    </Route>

                    <Route path={`${CFGRS_PAGE.embedding}/:${CFGR_ROUTE_PARAMS.companyId}`}>
                      <Route index element={<NavigateToCfgr page="embedding" />} />
                      <Route path={`:${CFGR_ROUTE_PARAMS.cfgrId}`} element={<Embedding />} />
                    </Route>

                    <Route path="*" element={<Navigate to={PATHS.configurators} replace />} />
                  </Route>

                  <Route
                    element={
                      <AuthorizedRouteWrapper
                        permission={CompanyPermissions.ModifyCompanySettings}
                        fallbackCmp={
                          <FallbackWithDashboardLink
                            title={t('Unauthorized')}
                            detailText={tUnauthorized('Company settings', companyDisplayName)}
                          />
                        }
                      />
                    }
                  >
                    {Object.values(COMPANY_SETTINGS_PATH_VARIANTS).map((paths, idx) => (
                      <Route key={idx} path={paths} element={<CompanySettings />} />
                    ))}
                  </Route>

                  <Route
                    element={
                      <AuthorizedRouteWrapper
                        permission={CompanyPermissions.ManageUserRole}
                        fallbackCmp={
                          <FallbackWithDashboardLink
                            title={t('Unauthorized')}
                            detailText={tUnauthorized('Staff settings', companyDisplayName)}
                          />
                        }
                      />
                    }
                  >
                    {Object.values(STAFF_SETTINGS_PATH_VARIANTS).map((paths, idx) => (
                      <Route
                        key={idx}
                        path={paths}
                        element={
                          <CompanySettingsStaffProvider>
                            <CompanySettingsStaff />
                          </CompanySettingsStaffProvider>
                        }
                      />
                    ))}
                  </Route>

                  <Route
                    element={
                      <AuthorizedRouteWrapper
                        permission={CompanyPermissions.RunWorkflows}
                        fallbackCmp={
                          <FallbackWithDashboardLink
                            title={t('Unauthorized')}
                            detailText={tUnauthorized('Workflows', companyDisplayName)}
                          />
                        }
                      />
                    }
                  >
                    <Route
                      element={
                        <CompanyFeatureRouteWrapper
                          feature="workflows"
                          fallbackCmp={
                            <FallbackWithDashboardLink
                              title={t('Missing feature')}
                              detailText={tMissingFeature('Workflows', companyDisplayName)}
                            />
                          }
                        />
                      }
                    >
                      {Object.values(WORKFLOWS_PATH_VARIANTS).map((workflowPaths, idx) => (
                        <Route key={idx} path={workflowPaths} element={<Workflows />} />
                      ))}
                    </Route>
                  </Route>

                  <Route
                    element={
                      <AuthorizedRouteWrapper
                        permission={CompanyPermissions.ViewAssets}
                        fallbackCmp={
                          <FallbackWithDashboardLink
                            title={t('Unauthorized')}
                            detailText={tUnauthorized('Assets', companyDisplayName)}
                          />
                        }
                      />
                    }
                  >
                    {Object.values(ASSET_EDITOR_PATH_VARIANTS).map((assetPaths, idx) => (
                      <Route key={idx} path={assetPaths} element={<AssetEditor />} />
                    ))}
                  </Route>

                  <Route
                    element={
                      <AuthorizedRouteWrapper
                        permission={PermissionBundles.ShowExportPage}
                        fallbackCmp={
                          <FallbackWithDashboardLink
                            title={t('Unauthorized')}
                            detailText={tUnauthorized('Export', companyDisplayName)}
                          />
                        }
                      />
                    }
                  >
                    <Route path={PATHS.replicator} element={<Replicator />}>
                      <Route path={`${REPLICATOR_PAGE.copy}`} element={<ReplicatorCopy />} />
                      <Route path={`${REPLICATOR_PAGE.progress}`} element={<ReplicatorProgress />} />
                    </Route>
                  </Route>
                </Route>

                <Route
                  path={PATHS.userProfile}
                  element={<UserProfile />}
                  handle={{ allowForInactiveUsers: true } as ActivationRouteHandle}
                />

                <Route element={<AuthorizedRouteWrapper permission={PermissionBundles.ShowAdminPage} />}>
                  <Route path={PATHS.admin} element={<Admin />} />
                </Route>

                {controlOverviewPageEnabled && <Route path={PATHS.controlOverview} element={<ControlOverview />} />}

                <Route path="*" element={<Navigate replace to={PATHS.configurators} />} />
              </Route>

              <Route path={PATHS.activateUser} element={<UserActivation />} />

              <Route element={<AnonymousUserRouteWrapper />}>
                <Route path={PATHS.login} element={<Login />} />
                <Route path={PATHS.forgotPassword} element={<ForgotPassword />} />
                <Route path={PATHS.resetPassword} element={<ResetPassword />} />
                <Route path={PATHS.registerUser} element={<UserRegistration />} />
                <Route path={PATHS.acceptCompanyInvitationInvalid} element={<AcceptCompanyInvitationInvalid />} />

                <Route path={PATHS.acceptCompanyInvitation} element={<AcceptCompanyInvitationWrapper />}>
                  <Route index element={<AcceptCompanyInvitationIndex />} />
                  <Route path={PATHS.acceptCompanyInvitationLogin} element={<AcceptCompanyInvitationLogin />} />
                  <Route path={PATHS.acceptCompanyInvitationRegister} element={<AcceptCompanyInvitationRegister />} />
                </Route>
              </Route>
            </Route>
            <Route path={PATHS.embeddedLogin} element={<EmbeddedLogin />} />
          </Route>
        )
      ),
    [companyDisplayName, controlOverviewPageEnabled, t, tMissingFeature, tUnauthorized]
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <div className="relative flex h-screen flex-col overflow-hidden text-neutral-100">
          <RouterProvider router={router} />
          <CookieBanner />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const FallbackWithDashboardLink: React.FC<{ title: string; detailText: string }> = ({
  title,
  detailText,
}): JSX.Element => {
  const { t } = useTypedTranslation();

  return (
    <div className="max-w-[32rem] p-6">
      <CbnCaptionCard
        headerText={title}
        subText={detailText}
        Svg={InfoIcon}
        action={<LinkButton text={t('Go to dashboard')} href={PATHS.dashboard} />}
      />
    </div>
  );
};
