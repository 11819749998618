import { useMemo } from 'react';
import { useAppSelector } from 'hooks/store/store.hooks';
import { store } from 'services/store/store.service';

export type DefaultTranslationFn = (text: string) => string;

type TypedTranslation = {
  t: DefaultTranslationFn;
  tGreeting: (name: string) => string;
  tDeleteBundle: (bundleDisplayName: string) => string;
  tDeleteBundleVersion: (bundleVersionDisplayName: string) => string;
  tDeleteAsset: (assetId: string) => string;
  tBulkDeleteAssets: (folderCnt: number, assetCnt: number) => string;
  tDropReplaceAsset: (assetId: string) => string;
  tNewAssets: (cnt: number) => string;
  tOverwrittenAssets: (cnt: number) => string;
  tWarningAssets: (cnt: number) => string;
  tErroneousAssets: (cnt: number) => string;
  tSelectedAssets: (cnt: number) => string;
  tLinkToAsset: (assetType: string) => string;
  tMissingFeature: (featureName: string, companyName: string) => string;
  tUnauthorized: (featureName: string, companyName: string) => string;
  tNotRestorable: (date: string) => string;
  tInstancedMeshMaterialInfo: (sourceMeshName: string) => string;
  tRestoreUntil: (date: string) => string;
  tCopyBundleInfo: (version: string, bundle: string, company: string) => string;
  tCopyCfgrInfo: (cfgr: string, company: string) => string;
  tOverruledByStoredMaterialTypeTooltip: (materialType: string) => string;
  tOverruledByMaterialAssetNameTooltip: (assetName: string) => string;
  tOverruledByLinkedImageAssetTooltip: (assetName: string) => string;
  tCompanyDetailsCardSubHeader: (companyName: string) => string;
  tSubscriptionExtendInfo: (daysRunning: number) => string;
  tMaxApiTokensReached: (maxNoOfTokens: number) => string;
  tEditRows: (cnt: number) => string;
  tTags: (cnt: number) => string;
  tChunkLoadErrorDescription: (componentName: string) => string;
  tDataSourceParseErrorsInRows: (rowNrs: number[]) => string;
  tLinkedAssetIsMissingInRows: (rowNrs: number[]) => string;
};

/**
 * This hook is providing i18n functionalities, which currently just forwards the input text.\
 * However if i18n is introduced in the future it should be very easy to integrate because this hook is
 * already designed to fit into the interface of the solution in the preferred i18n library "react-i18next".
 *
 * The i18n spike commit is tagged with `spike-react-i18next`, which can be used as a basis for the i18n integration.\
 * Further infos and usefull discussions can also be found in the PR: https://github.com/Combeenation/cbn-client/pull/8
 *
 * In the meantime this hook can also be used to check for missing translations.\
 * For this check it is required to set the `translationCheckModeActive` variable in the debug redux slice.\
 * This has to be done manually (eg with Redux Chrome DevTools extension) at the moment.\
 * However if `translationCheckModeActive` is set, each text that is set via the translation function (`t`)
 * is exchanged with the `placeholder` key, so that the user can manually check for original texts, which have been
 * forgotten to wrap in the translation function.
 */
export const useTypedTranslation = (): TypedTranslation => {
  const replaceTexts = useAppSelector(state => state.debug.translationCheckModeActive);
  const translationFn = useMemo(() => _createTranslationFunctions(replaceTexts), [replaceTexts]);
  return translationFn;
};

/**
 * Does the same as the `useTypedTranslation` hook, but is not implemented as a hook.\
 * Can therefore be called from outside of components and hooks.
 */
export const getTypedTranslation = (): TypedTranslation => {
  const state = store.getState();
  // Using `state?.` as `state` is undefined in tests and would throw there without the `?`...
  const replaceTexts = state?.debug.translationCheckModeActive;

  return _createTranslationFunctions(replaceTexts);
};

function _createTranslationFunctions(replaceTexts: boolean): TypedTranslation {
  const placeholder = '$translated';

  // basic translation function, forwards the input text or returns the placeholder in "translation check" mode
  const t = (text: string): string => (replaceTexts ? placeholder : text);
  // dynamic text that contains the name for the person to greet as parameter
  const tGreeting = (name: string): string => t(`Hi ${name}, how are you today?`);
  const tDeleteBundle = (bundleDisplayName: string): string => t(`Delete the bundle "${bundleDisplayName}"?`);
  const tDeleteBundleVersion = (bundleVersionDisplayName: string): string =>
    t(`Delete the draft "${bundleVersionDisplayName}"?`);
  const tDeleteAsset = (assetId: string): string => t(`Delete the asset "${assetId}"?`);
  const tBulkDeleteAssets = (folderCnt: number, assetCnt: number): string => {
    const folderWord = folderCnt > 1 ? t('folders') : folderCnt === 1 ? t('folder') : null;
    const assetWord = assetCnt > 1 ? t('assets') : assetCnt === 1 ? t('asset') : null;
    return folderWord && assetWord
      ? t(`Delete ${folderCnt} ${folderWord} with all containing assets and ${assetCnt} individual ${assetWord}?`)
      : folderWord
        ? t(`Delete ${folderCnt} ${folderWord} with all containing assets?`)
        : t(`Delete ${assetCnt} ${assetWord}?`);
  };
  const tDropReplaceAsset = (assetId: string): string => t(`Drop file to replace "${assetId}"`);
  const tNewAssets = (cnt: number): string => t(`${cnt} new ${cnt === 1 ? 'asset' : 'assets'}`);
  const tOverwrittenAssets = (cnt: number): string => t(`${cnt} overwritten ${cnt === 1 ? 'asset' : 'assets'}`);
  const tWarningAssets = (cnt: number): string => t(`${cnt} ${cnt === 1 ? 'warning' : 'warnings'}`);
  const tErroneousAssets = (cnt: number): string => t(`${cnt} ${cnt === 1 ? 'error' : 'errors'}`);
  const tSelectedAssets = (cnt: number): string => t(`${cnt} assets selected`);
  const tLinkToAsset = (assetType: string): string => t(`Link to ${assetType}`);
  const tMissingFeature = (featureName: string, companyName: string): string =>
    t(`"${featureName}" feature not activated for company "${companyName}"`);
  const tUnauthorized = (featureName: string, companyName: string): string =>
    t(`You are not authorized to view the page "${featureName}" of the company "${companyName}"`);
  const tNotRestorable = (date: string): string =>
    t(`It can't be restored anymore, because the due date (${date}) has passed.`);
  const tInstancedMeshMaterialInfo = (sourceMeshName: string): string =>
    t(
      `Nodes of type "InstancedMesh" share the geometry and material with their source mesh (${sourceMeshName}). Setting a material on this instance has no effect.`
    );
  const tRestoreUntil = (date: string): string => t(`It can be restored until ${date}`);
  const tCopyBundleInfo = (version: string, bundle: string, company: string): string =>
    t(`The version "${version}" of the bundle "${bundle}" will be duplicated within ${company}.`);
  const tCopyCfgrInfo = (cfgr: string, company: string): string =>
    t(`The published version of the configurator "${cfgr}" will be duplicated within ${company}.`);
  const tOverruledByStoredMaterialTypeTooltip = (materialType: string): string =>
    t(`Material type (${materialType}) cannot be overwritten. Manual changes will be discarded when saving them.`);
  const tOverruledByMaterialAssetNameTooltip = (assetName: string): string =>
    t(
      `Entry has no effect on the material as the value will be synced with the material assets name (${assetName}) once the material is used in a configurator`
    );
  const tOverruledByLinkedImageAssetTooltip = (assetName: string): string =>
    t(
      `Entry has no effect on the material as the data from the assigned image asset "${assetName}" will be used instead.\nYou can change or remove the assignment in the "Basic" tab.`
    );
  const tCompanyDetailsCardSubHeader = (companyName: string): string =>
    t(`Manage and change settings for ${companyName}`);
  const tSubscriptionExtendInfo = (daysRunning: number): string =>
    `Your subscription will be extended automatically for another ${daysRunning} days on:`;
  const tMaxApiTokensReached = (maxNoOfTokens: number): string =>
    `You have reached the maximum of ${maxNoOfTokens} API tokens.`;
  const tEditRows = (cnt: number): string => t(`Editing ${cnt} ${cnt === 1 ? 'row' : 'rows'}`);
  const tTags = (cnt: number): string => (cnt === 1 ? 'Tag' : 'Tags');
  const tChunkLoadErrorDescription = (componentName: string): string =>
    t(
      `Could not load "${componentName}". This is most likely caused by a platform update. Please reload the page to resolve this issue.`
    );
  const tDataSourceParseErrorsInRows = (rowNrs: number[]): string =>
    t(`Value(s) can't be parsed in rows: ${rowNrs.join(', ')}`);
  const tLinkedAssetIsMissingInRows = (rowNrs: number[]): string =>
    t(`Linked assets are missing in rows: ${rowNrs.join(', ')}`);

  return {
    t,
    tGreeting,
    tDeleteBundle,
    tDeleteBundleVersion,
    tDeleteAsset,
    tBulkDeleteAssets,
    tDropReplaceAsset,
    tNewAssets,
    tOverwrittenAssets,
    tWarningAssets,
    tErroneousAssets,
    tSelectedAssets,
    tLinkToAsset,
    tMissingFeature,
    tUnauthorized,
    tNotRestorable,
    tInstancedMeshMaterialInfo,
    tRestoreUntil,
    tCopyBundleInfo,
    tCopyCfgrInfo,
    tOverruledByStoredMaterialTypeTooltip,
    tOverruledByMaterialAssetNameTooltip,
    tOverruledByLinkedImageAssetTooltip,
    tCompanyDetailsCardSubHeader,
    tSubscriptionExtendInfo,
    tMaxApiTokensReached,
    tEditRows,
    tTags,
    tChunkLoadErrorDescription,
    tDataSourceParseErrorsInRows,
    tLinkedAssetIsMissingInRows,
  };
}
