import { CheckIcon, EditIcon, PlusIcon } from 'assets/icons';
import { Button } from 'controls/button/button';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type AssetDatasourceEditGridFooterProps = {
  /** The number of rows which are currently in edit mode */
  editModeCount: number;
  onAddColumnClicked: () => void;
  onBulkEditClicked: () => void;
  onEndBulkEditClicked: () => void;
};

export const AssetDatasourceEditGridFooter: React.FC<AssetDatasourceEditGridFooterProps> = ({
  editModeCount,
  onAddColumnClicked,
  onBulkEditClicked,
  onEndBulkEditClicked,
}) => {
  const { t, tEditRows } = useTypedTranslation();
  return (
    <div data-cmptype="AssetDatasourceEditGridFooter" className="mx-4 flex grow items-center justify-between">
      <Button Svg={PlusIcon} text={t('Add column')} variant="Secondary" onClick={onAddColumnClicked} />
      {editModeCount > 0 ? (
        <div className="flex items-center gap-2">
          <span>{tEditRows(editModeCount)}</span>
          <Button text={t('Confirm all')} Svg={CheckIcon} variant="Primary" onClick={onEndBulkEditClicked} />
        </div>
      ) : (
        <Button
          Svg={EditIcon}
          text={t('Edit all')}
          variant="Outlined"
          onClick={onBulkEditClicked}
          titleProps={{ placement: 'left', delay: 500 }}
          tooltipCmp={
            <TooltipContent
              header={t('Change all rows into edit mode')}
              detail={t('For specific changes you can simply double-click any cell.')}
            />
          }
        />
      )}
    </div>
  );
};
