import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { globalReset } from 'actions/general.actions';
import { UserStates } from 'generated/user-states';
import * as AuthService from 'services/auth/auth.service';
import { LoginResult } from 'services/auth/auth.service';
import { isGenericActionResult } from 'services/http/http.service';
import * as LocalStorageService from 'services/local-storage/local-storage.service';
import { setExtJSLoginDataInLocalStorage } from 'services/local-storage/ls-extjs-login-data.service';
import * as PersistenceMiddleware from 'services/store/persistence.middleware';
import { RootState } from 'services/store/store.service';
import { aiInstance } from 'services/telemetry/telemetry.service';

export type UserInfo = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  isCbnAdmin: boolean;
  isSuperAdmin: boolean;
  state: UserStates;
};

type LoginData = {
  email: string;
  password: string;

  /** When given, we're calling the endpoint `loginAndJoinCompany` instead of plain `login` */
  inviteData?: {
    companyName: string;
    inviteToken: string;
  };
};

export type AuthState = {
  userInfo: UserInfo;
};

const initialState: AuthState = {
  userInfo: {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    isCbnAdmin: false,
    isSuperAdmin: false,
    state: UserStates.NotActivated,
  },
};

export const login = createAsyncThunk<LoginResult, LoginData>(
  'auth/login',
  async ({ email, password, inviteData }, thunkApi) => {
    const loginResult = await AuthService.login(email, password, inviteData);

    if (isGenericActionResult(loginResult, AuthService.loginFailedResponses)) {
      return thunkApi.rejectWithValue(loginResult);
    } else if (loginResult) {
      // Persist user info also in ExtJS local storage, so that "old" pages are also reachable
      // NOTE: local storage handling of the react app authentication done via PersistenceMiddleware
      setExtJSLoginDataInLocalStorage(loginResult.userInfo);
      aiInstance.setAuthenticatedUserContext(loginResult.userInfo.email);
      return loginResult;
    } else {
      return thunkApi.rejectWithValue(undefined);
    }
  }
);

/**
 * Register endpoint has the same response as a login
 */
export const register = createAsyncThunk<
  LoginResult,
  {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    companyName: string;

    /** Only needed when creating a new company, i.e. when `inviteToken` is not given */
    companyDisplayName?: string;

    /** Used to register & join an existing company from invite */
    inviteToken?: string;
  }
>(
  'auth/register',
  async ({ email, password, firstName, lastName, companyName, companyDisplayName, inviteToken }, thunkApi) => {
    if (!email || !password || !firstName || !lastName || !companyName || (!companyDisplayName && !inviteToken)) {
      return thunkApi.rejectWithValue(undefined);
    }

    const registerResult = await AuthService.putAccountRegister(
      email,
      password,
      firstName,
      lastName,
      companyName,
      companyDisplayName || companyName,
      inviteToken
    );

    if (isGenericActionResult(registerResult, AuthService.registerResponses)) {
      return thunkApi.rejectWithValue(registerResult);
    } else {
      // Persist user info to local storage, so that the user don't have to login again when re-entering the page
      setExtJSLoginDataInLocalStorage(registerResult.userInfo);
      aiInstance.setAuthenticatedUserContext(registerResult.userInfo.email);
      return registerResult;
    }
  }
);

/**
 * Performs all actions which are required when logging a user out (reset store, delete user info from local storage,
 * logging out on the server as well, ...).
 *
 * Shall be used when "manually" logging out as well as when "forcefully" logging out like in response to HTTP 401 etc.
 */
export const logout = createAsyncThunk<void, { preventLogoutRequest?: boolean }>(
  'auth/logout',
  async ({ preventLogoutRequest }, thunkAPI) => {
    // Dispatch the `reset` action in any case, also if logout fails
    thunkAPI.dispatch(globalReset());

    LocalStorageService.clear();
    LocalStorageService.clear(true);
    LocalStorageService.clearCookies(true);

    aiInstance.clearAuthenticatedUserContext();
    if (!preventLogoutRequest) {
      await AuthService.logout();
    }
  }
);

export const getUserInfo = createAsyncThunk<UserInfo>('auth/getUserInfo', async () => {
  const userInfo = await AuthService.fetchUserInfo();
  setExtJSLoginDataInLocalStorage(userInfo);

  return userInfo;
});

const SLICE_NAME = 'auth';

PersistenceMiddleware.registerState<AuthState, AuthState['userInfo']>({
  state: SLICE_NAME,
  key: 'userInfo',
  selector: state => state.userInfo,
});

const getRehydratedState = (): AuthState => {
  const rehydratedState = PersistenceMiddleware.rehydrateState<AuthState>(SLICE_NAME, initialState);
  return rehydratedState;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: getRehydratedState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(globalReset, state => ({ ...state, userInfo: initialState.userInfo }))
      // ToAsk: Is it ok to completely rely on our "internal API" (TS types...) and not add any dedicated handling and
      //        tests for invalid input data (undefined, empty object, object with missing or wrong properties, ...)?
      .addCase(login.fulfilled, (state, { payload }) => ({ ...state, userInfo: payload.userInfo }))
      .addCase(register.fulfilled, (state, { payload }) => ({ ...state, userInfo: payload.userInfo }))
      .addCase(register.rejected, state => ({ ...state, userInfo: initialState.userInfo }))
      .addCase(login.rejected, state => ({ ...state, userInfo: initialState.userInfo }))
      .addCase(getUserInfo.fulfilled, (state, { payload }) => ({ ...state, userInfo: payload }));
  },
});

const authState = (state: RootState): AuthState => state.auth;
export const selectLoggedIn = createSelector(authState, auth => !!auth.userInfo.id);
export const selectUserIsActivated = createSelector(authState, auth => auth.userInfo.state === UserStates.Activated);
export const selectFullUsername = createSelector(authState, auth =>
  auth.userInfo.firstname ? `${auth.userInfo.firstname} ${auth.userInfo.lastname}`.trim() : ''
);

export default authSlice.reducer;
