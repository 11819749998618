import { useState } from 'react';
import { unstable_usePrompt, useNavigate } from 'react-router-dom';
import { PublishedCfgrIcon } from 'assets/icons';
import { CreateViewerOnlyCfgrModal } from 'components/asset-editor/details/asset-body/babylon-assets/create-viewer-only-cfgr-modal';
import { AssetDefaultActions } from 'components/asset-editor/details/asset-details-actions';
import { AssetDetailsHeader } from 'components/asset-editor/details/asset-details-header';
import { AssetMetadataEntry } from 'components/asset-editor/details/asset-metadata';
import { Suspense } from 'components/common/suspense';
import { Button } from 'controls/button/button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnLoadMask } from 'controls/cbn-load-mask/cbn-load-mask';
import { Tooltip } from 'controls/tooltip/tooltip';
import { getReadableFileSize } from 'helper/file/file.helper';
import { lazyWithFallback } from 'helper/import/import.helper';
import { useIsAssetBundleEditable } from 'hooks/assets/assets.hooks';
import { useMinTimeActive } from 'hooks/common/timing.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { fetchCfgrVersionsAssignedToBundleDraft } from 'services/assets/assets.service';
import { buildCfgrsPath } from 'services/routes/cfgrs-routes.service';
import { BabylonAsset, selectSelectedAsset, selectSelectedBundleVersionIsDraft } from 'slices/assets/assets.slice';
import { selectNodesWithChanges } from 'slices/babylon-asset/babylon-asset.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

const BabylonAssetContent = lazyWithFallback(
  () =>
    import(
      /* webpackChunkName: "babylon-asset-content" */ 'components/asset-editor/details/asset-body/babylon-assets/babylon-asset-content'
    ),
  'Babylon asset content'
);

export const AssetBodyBabylon: React.FC = () => {
  const { t } = useTypedTranslation();
  const navigate = useNavigate();
  const isAssetBundleEditable = useIsAssetBundleEditable();

  const {
    path: { companyId, bundleId, bundleVersion },
    size,
  } = useAppSelector(selectSelectedAsset) as BabylonAsset;
  const selectedBundleVersionIsDraft = useAppSelector(selectSelectedBundleVersionIsDraft);
  const { isLoading } = useAppSelector(state => state.babylonAsset.ui);
  const { nodesWithChangedTags, nodesWithChangedMaterial } = useAppSelector(selectNodesWithChanges);
  const hasManageAssetsPermission = usePermission(CompanyPermissions.ManageAssets, companyId);

  const dataHasChanged = nodesWithChangedTags.length > 0 || nodesWithChangedMaterial.length > 0;
  const fileSize = getReadableFileSize(size);

  const [showCreateCfgrModal, setShowCreateCfgrModal] = useState(false);
  const [disableCreateCfgrBtn, setDisableCreateCfgrBtn] = useState(false);
  const [isPreCheckingViewer, setIsPreCheckingViewer] = useState(false);
  const showLoadMask = useMinTimeActive(isLoading, 500);

  // theoretically changes could be persisted when re-navigating to any route that is not a babylon asset
  // but this is a bit weird UX behaviour, so better reset the selection any time the user leaves the page
  unstable_usePrompt({
    when: dataHasChanged,
    message: t('Discard unsaved changes?'),
  });

  const onClickCreateViewerCfgrBtn = async (): Promise<void> => {
    // viewer cfgr can't be created if this is a bundle draft, which is already assigned to a different configurator,
    // since the auto publish process would harm other configurators
    let allowCreateViewerCfgr = true;

    if (selectedBundleVersionIsDraft) {
      setIsPreCheckingViewer(true);
      // only do this on demand, after the button has been clicked the first time
      // otherwise the call has to be made each time a babylon asset is shown, which can be a bit expensive
      const fetchedCfgrVersionsOfBundleDraft = await fetchCfgrVersionsAssignedToBundleDraft(
        companyId,
        bundleId,
        bundleVersion
      );

      allowCreateViewerCfgr = !fetchedCfgrVersionsOfBundleDraft.length;
    }

    if (allowCreateViewerCfgr) {
      setShowCreateCfgrModal(true);
    } else {
      // button will be disabled from now on
      // it can only be enabled again after the component gets reloaded, which is the case when publishing the asset
      // bundle
      setDisableCreateCfgrBtn(true);
    }
    setIsPreCheckingViewer(false);
  };

  const onCreateCfgrModalClose = async (cfgrName: string): Promise<void> => {
    setShowCreateCfgrModal(false);

    if (cfgrName) {
      // go directly to embedding page of the new configurator on success
      navigate(buildCfgrsPath('embedding', companyId, cfgrName));
    }
  };

  const create3dArViewerBtn = (
    <Tooltip
      title={
        disableCreateCfgrBtn ? (
          <div>
            <p className="mb-2 text-m-regular">{t('Cannot create 3D/AR viewer')}</p>
            <p className="mb-2">
              {t(
                'Auto publishing asset bundle draft is not possible anymore, since the asset bundle draft is already assigned to multiple configurators.'
              )}
            </p>
            <p>{t('Publish the asset bundle draft manually and try again!')}</p>
          </div>
        ) : undefined
      }
    >
      <Button
        text={t('Create 3D/AR Viewer')}
        Svg={PublishedCfgrIcon}
        onClick={onClickCreateViewerCfgrBtn}
        disabled={disableCreateCfgrBtn}
        isLoading={isPreCheckingViewer}
      />
    </Tooltip>
  );

  const actions: (AssetDefaultActions | React.ReactElement)[] = isAssetBundleEditable
    ? [create3dArViewerBtn, 'Delete', 'Download', 'Rename', 'Copy', 'Move']
    : hasManageAssetsPermission
      ? [create3dArViewerBtn, 'Download']
      : ['Download'];

  const metadata: AssetMetadataEntry[] = [{ text: t('Size'), value: fileSize }];

  return (
    <section data-cmptype="AssetBodyBabylon" className="flex h-[100vh] flex-col gap-6">
      <CbnCard>
        <AssetDetailsHeader actions={actions} metadata={metadata} />
      </CbnCard>

      <div className="relative flex grow flex-col">
        {/* this component contains import from @combeenation/3d-viewer and @babylonjs, therefore we want to lazy
            load it */}
        <Suspense fallbackText={t('Initializing 3d asset')}>
          <BabylonAssetContent />
        </Suspense>
        <CbnLoadMask active={showLoadMask} spinner small transparent="semi" text={t('Loading 3d model asset data')} />
      </div>
      {showCreateCfgrModal && <CreateViewerOnlyCfgrModal onClose={onCreateCfgrModalClose} />}
    </section>
  );
};
