import { LinkButton } from 'controls/button/link-button';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { CBN_MAIL_ADRESSES } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { CompanyBillingInformation } from 'services/companies/companies.service';

type CompanySettingsPlanCardHeaderProps = {
  billingInformation?: CompanyBillingInformation;
};

export const CompanySettingsPlanCardHeader: React.FC<CompanySettingsPlanCardHeaderProps> = ({ billingInformation }) => {
  const { t } = useTypedTranslation();
  const displayName = billingInformation?.subscriptionDisplayName ?? '';
  const price = billingInformation?.monthlySubscriptionFee ?? 0;
  const changePlanMailSubject = t('Change subscription plan');
  const changePlanMailBody = t(
    "I'd like to change my current subscription plan. Please contact me to discuss my changed requirements and possible options."
  );

  return (
    <CbnCardHeader
      variant="large-title"
      title={
        <>
          <div className="pt-4 text-s-regular text-neutral-70">{t('Your subscription:')}</div>
          <div className="flex items-center gap-4">
            <div className="flex-1 text-heading-m">{displayName}</div>
            <div className="flex items-baseline gap-1">
              <span className="text-heading-s">{`€ ${price},-`}</span>
              <span className="text-s-regular text-neutral-70">{t('/month')}</span>
            </div>
          </div>
        </>
      }
      actions={
        <LinkButton
          text={t('Change plan')}
          variant="Secondary"
          href={`mailto:${CBN_MAIL_ADRESSES.info}?subject=${changePlanMailSubject}&body=${changePlanMailBody}`}
        />
      }
    />
  );
};
