import {
  BabylonAssetIcon,
  DataSourceAssetIcon,
  FileAssetIcon,
  FolderAssetIcon,
  ImageAssetIcon,
  MaterialAssetIcon,
  TextAssetIcon,
} from 'assets/icons';
import { AssetBodyFile } from 'components/asset-editor/details/asset-body/asset-body-file';
import { AssetBodyImage } from 'components/asset-editor/details/asset-body/asset-body-image';
import { AssetBodyText } from 'components/asset-editor/details/asset-body/asset-body-text';
import { AssetBodyBabylon } from 'components/asset-editor/details/asset-body/babylon-assets/asset-body-babylon';
import { AssetBodyDataSource } from 'components/asset-editor/details/asset-body/datasource-assets/asset-body-datasource';
import { AssetBodyFolder } from 'components/asset-editor/details/asset-body/folder-assets/asset-body-folder';
import { AssetBodyMaterial } from 'components/asset-editor/details/asset-body/material-assets/asset-body-material';
import { SvgComponent } from 'controls/icon/icon';
import { AssetTypes } from 'generated/asset-types';

type AssetTypeDefs = {
  Icon: SvgComponent;
  BodyComponent: JSX.Element;
  displayName: string;
};

export const ASSET_TYPE_MAP: Record<AssetTypes, AssetTypeDefs> = {
  [AssetTypes.DataSource]: {
    Icon: DataSourceAssetIcon,
    BodyComponent: <AssetBodyDataSource />,
    displayName: 'Data source',
  },
  [AssetTypes.Image]: {
    Icon: ImageAssetIcon,
    BodyComponent: <AssetBodyImage />,
    displayName: 'Image',
  },
  [AssetTypes.File]: {
    Icon: FileAssetIcon,
    BodyComponent: <AssetBodyFile />,
    displayName: 'File',
  },
  [AssetTypes.Folder]: {
    Icon: FolderAssetIcon,
    BodyComponent: <AssetBodyFolder />,
    displayName: 'Folder',
  },
  [AssetTypes.BabylonJs]: {
    Icon: BabylonAssetIcon,
    BodyComponent: <AssetBodyBabylon />,
    displayName: '3d model',
  },
  [AssetTypes.Material]: {
    Icon: MaterialAssetIcon,
    BodyComponent: <AssetBodyMaterial />,
    displayName: 'Material',
  },
  [AssetTypes.Text]: {
    Icon: TextAssetIcon,
    BodyComponent: <AssetBodyText />,
    displayName: 'Text',
  },
};
