import { useEffect, useState } from 'react';
import { DeleteIcon, PlusInCircleIcon, WarningIcon } from 'assets/icons';
import { Button } from 'controls/button/button';
import { CopyToClipboardButton } from 'controls/button/copy-to-clipboard-button';
import { Card } from 'controls/card/card';
import { CbnAlert } from 'controls/cbn-alert/cbn-alert';
import { FormItem } from 'controls/form-item/form-item';
import { TextInput } from 'controls/input/text-input';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'controls/table';
import { TableSkeletonRows } from 'controls/table/table-skeleton-rows';
import { cn, tw } from 'helper/css.helper';
import { useCfgrWhitelistDomains } from 'hooks/cfgr-whitelist-domains/cfgr-whitelist-domains.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { addNewCfgrWhitelistDomain, removeCfgrWhitelistDomain } from 'slices/cfgr-whitelist-domains.slice';
import { selectSelectedConfigurator } from 'slices/company-data/company-data.slice';

export const EmbeddingWhiteListDomainsCard: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const [isAddingNewDomain, setIsAddingNewDomain] = useState(false);
  const [removingDomains, setRemovingDomains] = useState<string[]>([]);
  const [addDomainErrorMsg, setAddDomainErrorMsg] = useState('');

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newDomainInput, setNewDomainInput] = useState('');

  const selectedCfgr = useAppSelector(selectSelectedConfigurator);

  const {
    domains: whitelistedDomains,
    maxNoOfDomains,
    maxNoOfDomainsCreated,
    requiredDomainsMissing,
    isLoading,
  } = useCfgrWhitelistDomains(selectedCfgr?.companyId, selectedCfgr?.id);
  const domainToAddAlreadyExists = !!whitelistedDomains.find(x => x.toLowerCase() === newDomainInput.toLowerCase());

  useEffect(() => {
    if (maxNoOfDomainsCreated) {
      setShowCreateForm(false);
    }
  }, [maxNoOfDomainsCreated]);

  function showAddNewDomainForm(): void {
    setShowCreateForm(true);
    setNewDomainInput('');
    setAddDomainErrorMsg('');
  }

  async function addNewDomain(domain: string): Promise<void> {
    if (!selectedCfgr) return;

    setIsAddingNewDomain(true);
    const { companyId, id: cfgrId } = selectedCfgr;
    const data = { companyId, cfgrId, domain };
    const errorMsg = await dispatch(addNewCfgrWhitelistDomain(data)).unwrap();

    setAddDomainErrorMsg(errorMsg);
    setIsAddingNewDomain(false);

    if (!errorMsg) {
      setNewDomainInput('');
    }
  }

  async function removeDomain(domain: string): Promise<void> {
    if (!selectedCfgr) return;

    setRemovingDomains(x => [...x, domain]);
    const { companyId, id: cfgrId } = selectedCfgr;
    const data = { companyId, cfgrId, domain };
    await dispatch(removeCfgrWhitelistDomain(data));
    setRemovingDomains(x => x.filter(d => d !== domain));
  }

  return (
    <Card
      data-cmptype="EmbeddingWhiteListDomainsCard"
      headerText={t('Whitelist domains')}
      subText={t(`Specify domains under which your configurator can be embedded`)}
      noBodyMargin
      // 33rem is a random pick to keep the header & sub text below 4 lines as that would break the card header
      classNamesContent={tw`min-w-[33rem]`}
      actions={
        <div className={cn(showCreateForm && 'invisible')}>
          <Button
            text={t('Add new domain')}
            variant="Secondary"
            Svg={PlusInCircleIcon}
            onClick={showAddNewDomainForm}
            disabled={isLoading || maxNoOfDomainsCreated || showCreateForm}
            title={maxNoOfDomainsCreated ? t(`Maximum number of domains reached (${maxNoOfDomains})`) : undefined}
          />
        </div>
      }
    >
      {requiredDomainsMissing && !showCreateForm && (
        <div className="flex items-center gap-2 p-4 text-m-regular text-neutral-70">
          <CbnAlert
            severity="warning"
            caption={t('Domain missing')}
            content={t('Specify at least one domain to allow embedding of your configurator.')}
          />
        </div>
      )}

      {showCreateForm && (
        <form
          data-cmptype="CompanySettingsApiTokenCard-createForm"
          className="flex flex-col items-end gap-4 border-t border-neutral-40 px-6 py-4"
          onSubmit={async (event: React.FormEvent): Promise<void> => {
            event.preventDefault();
            await addNewDomain(newDomainInput);
          }}
        >
          <FormItem labelContent={t('New domain')} className="w-full">
            <TextInput
              placeholder="https://www.example.com"
              value={newDomainInput}
              onValueChange={setNewDomainInput}
              autoFocus
              error={!!addDomainErrorMsg}
              helperText={addDomainErrorMsg}
            />
          </FormItem>

          <div className="flex w-full justify-between">
            <Button
              text={t('Cancel')}
              variant="Secondary"
              onClick={(): void => setShowCreateForm(false)}
              disabled={isAddingNewDomain}
            />
            <Button
              text={t('Add domain')}
              Svg={PlusInCircleIcon}
              variant="Secondary"
              isLoading={isAddingNewDomain}
              type="submit"
              disabled={!newDomainInput || domainToAddAlreadyExists}
              title={domainToAddAlreadyExists ? t('Domain already exists') : undefined}
            />
          </div>
        </form>
      )}

      {(isLoading || whitelistedDomains.length > 0) && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Domain')}</TableCell>
              <TableCell className="w-[10rem]">{t('Remove domain')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {isLoading && <TableSkeletonRows numberOfRows={2} numberOfCols={2} />}

              {!isLoading &&
                whitelistedDomains.map((domain, idx) => (
                  <TableRow key={idx} className={'hover:bg-neutral-20'}>
                    <TableCell className="flex flex-wrap items-center gap-2">
                      <span className={cn(maxNoOfDomains && idx >= maxNoOfDomains && 'text-neutral-60 line-through')}>
                        {domain}
                      </span>
                      <CopyToClipboardButton text={domain} variant="Text" title={t('Copy domain to clipboard')} />
                      {maxNoOfDomains && idx >= maxNoOfDomains && (
                        <span className="flex gap-1">
                          <WarningIcon className="h-5 text-warning-main" />
                          <span>{t(`Exceeds maximum number of domains (${maxNoOfDomains})`)}</span>
                        </span>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="flex justify-center">
                        <Button
                          Svg={DeleteIcon}
                          hoverColor="Danger"
                          variant={'Text'}
                          onClick={async (): Promise<void> => removeDomain(domain)}
                          isLoading={removingDomains.includes(domain)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </>
          </TableBody>
        </Table>
      )}
    </Card>
  );
};
