import {
  gridPaginationModelSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { ArrowLeftIcon, ArrowRightIcon } from 'assets/icons';
import { Button } from 'controls/button/button';
import { UNKNOWN_ROW_COUNT } from 'controls/datagrid/data-grid';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

export const CustomPagination: React.FC = () => {
  const { t } = useTypedTranslation();
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const pageModel = useGridSelector(apiRef, gridPaginationModelSelector);
  // get rowCount from rootProps due to a MUI bug -> https://github.com/mui/mui-x/issues/8450
  const rowCount = rootProps.rowCount ?? UNKNOWN_ROW_COUNT;

  const from = Math.min(pageModel.page * pageModel.pageSize + 1, rowCount);
  const to = Math.min(from + pageModel.pageSize - 1, rowCount);

  const onPageBackClicked = (): void => {
    apiRef.current.setPage(pageModel.page - 1);
  };
  const onPageForwardClicked = (): void => {
    apiRef.current.setPage(pageModel.page + 1);
  };

  const pageCount = Math.ceil(rowCount / pageModel.pageSize);
  const isKnownRowCount = rowCount !== UNKNOWN_ROW_COUNT;
  const isFirstPage = pageModel.page === 0;
  const isLastPage = pageCount === 0 || pageCount === pageModel.page + 1;
  return (
    <div className="flex items-center gap-2 text-m-regular">
      <Button Svg={ArrowLeftIcon} variant="Outlined" onClick={onPageBackClicked} disabled={isFirstPage} />
      <span className="min-w-[100px] text-center">{`${from} – ${to}${
        isKnownRowCount ? ` ${t('of')} ${rowCount}` : ''
      }`}</span>
      <Button Svg={ArrowRightIcon} variant="Outlined" onClick={onPageForwardClicked} disabled={isLastPage} />
    </div>
  );
};
