import { ReactElement, useCallback } from 'react';
import React from 'react';
import { SchemaNameInput, SchemaNameInputResult } from 'components/common/schema-name-input';
import { Checkbox } from 'controls/checkbox/checkbox';
import { RadioButtonEntry, RadioButtonGroup, RadioButtonValue } from 'controls/radio-button/radio-button-group';
import { TableCell, TableRow } from 'controls/table';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { selectAllCompanyCfgrs } from 'slices/company-data/company-data.slice';
import {
  CfgrMappingTarget,
  changeTargetCfgrEditMode,
  selectCurrentBlueprint,
  setBlueprintTargetCfgr,
  setTargetCfgrAutoPublish,
} from 'slices/replicator/replicator.slice';

type ReplicatorTargetsCfgrRowsProps = {
  schemaId: string;
  targetCfgr: CfgrMappingTarget;
};

export const ReplicatorTargetsCfgrRows: React.FC<ReplicatorTargetsCfgrRowsProps> = ({ schemaId, targetCfgr }) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const cfgrs = useAppSelector(selectAllCompanyCfgrs);

  const blueprint = useAppSelector(selectCurrentBlueprint);
  const cfgrRef = cfgrs.find(c => c.id === schemaId);

  const onTargetCfgrChange = useCallback(
    (result: SchemaNameInputResult): void => {
      dispatch(
        setBlueprintTargetCfgr({
          cfgrId: schemaId,
          name: result.uniqueId,
          displayName: result.displayName,
          hasErrors: result.hasErrors,
        })
      );
    },
    [dispatch, schemaId]
  );

  const onCreateNewCfgrClicked = (value: RadioButtonValue | undefined): void => {
    dispatch(changeTargetCfgrEditMode({ schemaName: schemaId, inEditMode: Boolean(value) }));
  };

  const onAutoPublishChange = (value: boolean): void => {
    dispatch(setTargetCfgrAutoPublish({ schemaName: schemaId, value }));
  };

  const userSelectedNewCfgr = targetCfgr.isExistingCfgr && targetCfgr.inEditMode;
  const radioButtonEntries: RadioButtonEntry[] = [
    { value: 0, text: t('Add to existing configurator') },
    { value: 1, text: t('Create new configurator') },
  ];

  const cfgrInput = (
    <SchemaNameInput
      input={{ displayName: targetCfgr.displayName, uniqueId: targetCfgr.name }}
      unavailableIds={blueprint.target.existingNames.cfgrNames}
      unavailableNames={blueprint.target.existingNames.cfgrDisplayNames}
      onChange={(result): void => onTargetCfgrChange(result)}
      customWrapper={(children): ReactElement => <TableCell>{children}</TableCell>}
    />
  );

  return (
    <>
      <TableRow className={userSelectedNewCfgr ? 'shadow-none' : undefined}>
        <TableCell className={targetCfgr.isExistingCfgr ? 'align-top' : undefined}>
          {cfgrRef ? cfgrRef.displayName : schemaId}
        </TableCell>
        {targetCfgr.isExistingCfgr ? (
          <TableCell colSpan={2}>
            <div className="flex flex-col gap-2">
              <span>{t('This configurator name already exists in the target.')}</span>
              <RadioButtonGroup
                entries={radioButtonEntries}
                value={Number(targetCfgr.inEditMode)}
                onValueChange={onCreateNewCfgrClicked}
              />
            </div>
          </TableCell>
        ) : (
          cfgrInput
        )}
        <TableCell className="text-center">
          <Checkbox checked={targetCfgr.AutoPublish ?? false} onValueChanged={onAutoPublishChange} />
        </TableCell>
      </TableRow>
      {userSelectedNewCfgr && (
        <TableRow>
          <TableCell></TableCell>
          {cfgrInput}
          <TableCell></TableCell>
        </TableRow>
      )}
    </>
  );
};
