// Generated by EnumsBuilderTypeScript (DO NOT change this file manually, change the builder if required)
export enum ShopHiveTypes {
  DiyShop = 'DiyShop',
  FtpShop = 'FtpShop',
  MailShop = 'MailShop',
  RestShop = 'RestShop',
  ShopifyShop = 'ShopifyShop',
  Shopware6Shop = 'Shopware6Shop',
  ShopwareShop = 'ShopwareShop',
  WooCommerceShop = 'WooCommerceShop'
}