import { cloneDeep } from 'lodash';
import { NumberOrNull } from 'controls/input/number-input';
import { WorkflowParameterTypes } from 'generated/workflow-parameter-types';
import {
  BundleWorkflowParameter,
  FileWorkflowParameter,
  SimpleWorkflowParameter,
  WorkflowParameter,
  WritableWorkflowParameters as WritableWfParams,
} from 'services/workflows/workflows.service';

export type WorkflowParamsAction =
  | { type: 'ADD_PARAMETER' }
  | { type: 'DELETE_PARAMETER'; payload: number }
  | { type: 'CHANGE_NAME'; payload: { name: string; idx: number } }
  | { type: 'CHANGE_TYPE'; payload: { type: WorkflowParameterTypes; idx: number } }
  | { type: 'CHANGE_DEFAULT_VALUE'; payload: { defaultValue: boolean | NumberOrNull | string; idx: number } }
  | { type: 'CHANGE_BUNDLE_PARAMS'; payload: { params: WritableWfParams<BundleWorkflowParameter>; idx: number } }
  | { type: 'CHANGE_FILE_PARAMS'; payload: { params: WritableWfParams<FileWorkflowParameter>; idx: number } }
  | { type: 'RESET'; payload: WorkflowParameterArray };

type ValidationErrors = 'duplicateName' | 'requiredFieldsMissing';
type ErrorFlags = Partial<Record<ValidationErrors, boolean>>;

export type WorkflowParameterArray = (WorkflowParameter & {
  name: string;
  errors: ErrorFlags;
})[];

export function workflowParamsReducer(
  state: WorkflowParameterArray,
  action: WorkflowParamsAction
): WorkflowParameterArray {
  // the original state array must not be changed, that's why cloning it right away and applying the changes on the
  // clone makes life much easier here
  const clonedState = cloneDeep(state);

  switch (action.type) {
    case 'ADD_PARAMETER': {
      let newParamNr = 0;

      // find next free generic parameter name
      const doesNameExist = (existingName: string): boolean => `newParam_${newParamNr}` === existingName;
      while (state.some(param => doesNameExist(param.name))) {
        newParamNr++;
      }

      clonedState.push({
        name: `newParam_${newParamNr}`,
        type: WorkflowParameterTypes.String,
        defaultValue: '',
        errors: {},
      });
      return clonedState;
    }

    case 'DELETE_PARAMETER': {
      clonedState.splice(action.payload, 1);
      return clonedState;
    }

    case 'CHANGE_NAME': {
      clonedState[action.payload.idx].name = action.payload.name;
      clonedState.forEach((param, idx) => {
        param.errors.duplicateName = clonedState.some((p, innerIdx) => p.name === param.name && innerIdx !== idx);
      });
      return clonedState;
    }

    case 'CHANGE_TYPE': {
      const oldEntry = clonedState[action.payload.idx];

      // type changes require some additional handling for certain parameter properties
      switch (action.payload.type) {
        case WorkflowParameterTypes.AssetBundleAssignedToCfgr:
          clonedState[action.payload.idx] = {
            name: oldEntry.name,
            type: WorkflowParameterTypes.AssetBundleAssignedToCfgr,
            bundleName: '',
            cfgrName: '',
            errors: {},
          };
          break;

        case WorkflowParameterTypes.File:
          clonedState[action.payload.idx] = {
            name: oldEntry.name,
            type: WorkflowParameterTypes.File,
            url: '',
            errors: { requiredFieldsMissing: true },
          };
          break;

        case WorkflowParameterTypes.Bool:
          clonedState[action.payload.idx] = {
            name: oldEntry.name,
            type: WorkflowParameterTypes.Bool,
            defaultValue: false,
            errors: {},
          };
          break;

        case WorkflowParameterTypes.Number:
          clonedState[action.payload.idx] = {
            name: oldEntry.name,
            type: WorkflowParameterTypes.Number,
            defaultValue: 0,
            errors: {},
          };
          break;

        case WorkflowParameterTypes.String:
          clonedState[action.payload.idx] = {
            name: oldEntry.name,
            type: WorkflowParameterTypes.String,
            defaultValue: '',
            errors: {},
          };
          break;
      }

      return clonedState;
    }

    case 'CHANGE_DEFAULT_VALUE': {
      // `null` can also be set on the "defaultValue", even if the typing doesn't allow it.
      // This is the case if invalid data has been entered in a `NumberInput` component.
      // Anyway the value will not be applied in the state due to validation so it's fine if the value is applied here
      // in the reducer
      (clonedState[action.payload.idx] as SimpleWorkflowParameter).defaultValue = action.payload.defaultValue!;
      return clonedState;
    }

    case 'CHANGE_BUNDLE_PARAMS': {
      const clonedParam = clonedState[action.payload.idx] as BundleWorkflowParameter;
      Object.entries(action.payload.params).forEach(([key, value]) => {
        clonedParam[key as keyof WritableWfParams<BundleWorkflowParameter>] = value;
      });
      return clonedState;
    }

    case 'CHANGE_FILE_PARAMS': {
      const clonedParam = clonedState[action.payload.idx] as FileWorkflowParameter;
      Object.entries(action.payload.params).forEach(([key, value]) => {
        clonedParam[key as keyof WritableWfParams<FileWorkflowParameter>] = value;
      });

      const requiredMissing = clonedParam.url.length === 0;
      clonedState[action.payload.idx].errors = { requiredFieldsMissing: requiredMissing };

      return clonedState;
    }

    case 'RESET': {
      return cloneDeep(action.payload);
    }

    default:
      return state;
  }
}
