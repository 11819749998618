import { AxiosResponse } from 'axios';
import { HttpStatusCode } from 'helper/http/http-status.helper';
import * as HttpService from 'services/http/http.service';

/**
 * Only returns a response-object if the give ETag doesn't match anymore.\
 * Besides that it's a default GET request (Axios)
 */
export async function getIfETagChanged<T>(url: string, etag: string): Promise<AxiosResponse<T> | undefined> {
  const response = await HttpService.httpClient.get<T>(url, {
    headers: { 'If-None-Match': etag },
    validateStatus: status => {
      // this overrules the general axios validation, so the default check for status 2xx is required too
      if (status === HttpStatusCode.NotModified_304 || (status >= 200 && status < 300)) {
        return true;
      }
      return false;
    },
  });

  if (response.status !== HttpStatusCode.NotModified_304) {
    return response;
  }
}

/**
 * Return "unparsed" version of a string, as Axios automatically does that if the string comes in JSON format
 */
export async function getUnparsedText(url: string): Promise<string> {
  const res = await HttpService.httpClient.get<string>(url ?? '', {
    transitional: { forcedJSONParsing: false },
  });

  const textResponse = res.data;
  return textResponse;
}
