import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CopyLinkIcon,
  DeleteBinRefreshIcon,
  EyeShowVisibleIcon,
  GlobeCrossIcon,
  NewDraftIcon,
  PublishedCfgrIcon,
  ViewDraftInEditorIcon,
} from 'assets/icons';
import { CreateDraftDialog } from 'components/common/create-draft-dialog';
import { IdentityUser } from 'components/identity/identity-user';
import { Button } from 'controls/button/button';
import { CopyToClipboardButton } from 'controls/button/copy-to-clipboard-button';
import { LinkButton } from 'controls/button/link-button';
import { Card } from 'controls/card/card';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { Icon } from 'controls/icon/icon';
import { cn } from 'helper/css.helper';
import { getFormattedDateAndTimeFromSeconds } from 'helper/date-and-time/date-and-time.helper';
import { getEditorUrl, getPublishedCfgrLink } from 'helper/url/cfgr-url.helper';
import { useConfirmDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { postConfiguratorRestore } from 'services/cfgrs/cfgrs.service';
import { postConfiguratorHistoryWithdraw } from 'services/configuratorhistory/configuratorhistory.service';
import { buildCfgrsPath } from 'services/routes/cfgrs-routes.service';
import { getCompanyCfgr, selectSelectedConfigurator } from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

type CfgrMainStateCardProps = {
  variant: 'Dashboard' | 'Draft';
};

export const CfgrMainStateCard: React.FC<CfgrMainStateCardProps> = ({ variant }) => {
  const { t, tRestoreUntil, tNotRestorable } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const confirm = useConfirmDialog();
  const selectedCfgr = useAppSelector(selectSelectedConfigurator);

  const hasManageDraftPermission = usePermission(CompanyPermissions.ManageDraft, selectedCfgr?.companyId);
  const hasWithdrawCfgrPermission = usePermission(CompanyPermissions.WithdrawConfigurator, selectedCfgr?.companyId);

  const isPublished = !!selectedCfgr?.isPublished;
  const isDeleted = !!(selectedCfgr && selectedCfgr.deleteAfter);
  const isReadOnly = !!selectedCfgr?.cfgrFeatures.readonly;
  const publishedCfgrLink = selectedCfgr
    ? getPublishedCfgrLink(selectedCfgr.companyId, selectedCfgr.id, null, false)
    : '';

  const isPastRestoreDate = selectedCfgr?.deleteAfter ? _deleteTimeHasPassed(selectedCfgr.deleteAfter) : false;
  const deleteInfoText = isPastRestoreDate
    ? tNotRestorable(getFormattedDateAndTimeFromSeconds(selectedCfgr?.deleteAfter))
    : tRestoreUntil(getFormattedDateAndTimeFromSeconds(selectedCfgr?.deleteAfter));

  const [createDraftOpen, setCreateDraftOpen] = useState(false);

  const onUnpublishClicked = async (): Promise<void> => {
    if (!selectedCfgr) {
      throw new Error("Configurator can't be unpublished (withdrawn). No selected configurator found!");
    }

    const unpublishConfirmed = await confirm(
      t(
        `Withdraw the configurator "${selectedCfgr.displayName}"?\n` +
          'The configurator will no longer be publicly available.'
      ),
      {
        confirmBtnText: t('Unpublish configurator'),
        variant: 'Warning',
      }
    );

    if (unpublishConfirmed) {
      await postConfiguratorHistoryWithdraw(selectedCfgr.companyId, selectedCfgr.id);
      dispatch(getCompanyCfgr({ companyId: selectedCfgr.companyId, cfgrId: selectedCfgr.id }));
    }
  };

  const onRestoreCfgrClicked = async (): Promise<void> => {
    if (!selectedCfgr) {
      throw new Error("Configurator can't be restored. No selected configurator found!");
    }

    await postConfiguratorRestore(selectedCfgr.companyId, selectedCfgr.id);
    dispatch(getCompanyCfgr({ companyId: selectedCfgr.companyId, cfgrId: selectedCfgr.id }));
  };

  if (!selectedCfgr) {
    return null;
  }

  const stateInfo = isPublished ? (
    <PublishedHeader variant={variant} />
  ) : isDeleted ? (
    <>
      <div className="text-heading-s">{t('Configurator deleted')}</div>
      {!isReadOnly && <div>{deleteInfoText}</div>}
    </>
  ) : (
    <>
      <div className="text-heading-s">{t('Not published')}</div>
      <div>{t('To publish your product, edit a draft and press the button "Publish" at the top of the page')}</div>
    </>
  );

  return (
    <>
      {createDraftOpen && (
        <CreateDraftDialog
          initialSourceCfgrVersionId={selectedCfgr.version}
          onCancel={(): void => setCreateDraftOpen(false)}
          onConfirm={(): void => setCreateDraftOpen(false)}
        />
      )}

      <Card noBodyMargin data-cmptype="DashboardMainStateCard" grow>
        <div className="flex flex-col gap-5 border-b border-neutral-40 p-6">
          <div className="flex flex-row gap-2">
            <Icon
              className={cn(
                'h-10 w-10 text-neutral-50',
                isPublished && 'text-brand-green2',
                isDeleted && 'text-warning-main'
              )}
              Svg={isPublished ? PublishedCfgrIcon : isDeleted ? DeleteBinRefreshIcon : GlobeCrossIcon}
            />
            {isReadOnly && (
              <Icon
                Svg={EyeShowVisibleIcon}
                className="h-10 w-10"
                title={t('Read-only configurator')}
                titleProps={{ placement: 'right', delay: 500 }}
              />
            )}
          </div>
          <div className="flex items-end justify-between">
            <div className="flex flex-col">{stateInfo}</div>
            <div className="flex gap-2">
              {isPublished && hasWithdrawCfgrPermission && (
                <Button
                  text={t('Unpublish')}
                  variant="Secondary"
                  onClick={onUnpublishClicked}
                  disabled={isReadOnly}
                  Svg={GlobeCrossIcon}
                  title={isReadOnly ? t("Can't unpublish read-only configurator!") : ''}
                />
              )}

              {variant === 'Dashboard' && isPublished && (
                <LinkButton
                  href={getPublishedCfgrLink(selectedCfgr.companyId, selectedCfgr.id, null, true)}
                  target="_blank"
                  text={t('Open')}
                />
              )}

              {variant === 'Dashboard' && !isPublished && !isDeleted && (
                <LinkButton
                  href={buildCfgrsPath('drafts', selectedCfgr.companyId, selectedCfgr.id)}
                  text={t('Open drafts')}
                />
              )}

              {variant === 'Draft' && !isDeleted && selectedCfgr.version && hasManageDraftPermission && (
                <LinkButton
                  text={selectedCfgr.isPublished ? t('View in editor') : t('View last live version in editor')}
                  variant="Secondary"
                  href={getEditorUrl(selectedCfgr.companyId, selectedCfgr.id, selectedCfgr.version, 'view')}
                  reloadDocument
                  Svg={ViewDraftInEditorIcon}
                />
              )}

              {variant === 'Draft' && !isDeleted && hasManageDraftPermission && !selectedCfgr.cfgrFeatures.readonly && (
                <Button
                  variant="Secondary"
                  text={t('New draft')}
                  Svg={NewDraftIcon}
                  onClick={(): void => setCreateDraftOpen(true)}
                />
              )}

              {isDeleted && !isPastRestoreDate && hasWithdrawCfgrPermission && (
                <Button
                  text={t('Restore configurator')}
                  variant="Secondary"
                  onClick={onRestoreCfgrClicked}
                  disabled={isReadOnly}
                  title={isReadOnly ? t("Can't restore read-only configurator") : ''}
                />
              )}
            </div>
          </div>
        </div>

        {variant === 'Dashboard' && isPublished && selectedCfgr.lastPublishedAt && (
          <div className="flex grow flex-col justify-end gap-4 p-6">
            <div className="flex items-center whitespace-pre text-m-regular text-neutral-90">
              <span>{t('published')} </span>
              <RelativeDateTime unixTime={selectedCfgr.lastPublishedAt} />
              <span> | {t('by')} </span>
              <IdentityUser userId={selectedCfgr.lastPublishedBy} />
            </div>
            <div className="flex flex-row items-center gap-4">
              <Link to={publishedCfgrLink} target="_blank" className="text-primary-main hover:underline">
                {publishedCfgrLink}
              </Link>
              <CopyToClipboardButton text={publishedCfgrLink} title={t('Copy link')} icon={CopyLinkIcon} />
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

type PublishedHeaderProps = Pick<CfgrMainStateCardProps, 'variant'> & {};

const PublishedHeader: React.FC<PublishedHeaderProps> = ({ variant }) => {
  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const publishedCfgrLink = selectedCfgr
    ? getPublishedCfgrLink(selectedCfgr.companyId, selectedCfgr.id, null, false)
    : '';

  const { t } = useTypedTranslation();

  if (!selectedCfgr) {
    return null;
  }

  return (
    <>
      <div className="text-heading-s">{t('Live-Version')}</div>
      {variant === 'Draft' ? (
        <>
          <div className="flex items-center whitespace-pre text-m-regular text-neutral-90">
            <span>{t('published')} </span>
            <RelativeDateTime unixTime={selectedCfgr.lastPublishedAt!} />
            <span> | {t('by')} </span>
            <IdentityUser userId={selectedCfgr.lastPublishedBy} />
          </div>
          <div className="mt-5 flex flex-row items-center gap-4">
            <Link to={publishedCfgrLink} target="_blank" className="text-primary-main hover:underline">
              {publishedCfgrLink}
            </Link>
            <CopyToClipboardButton text={publishedCfgrLink} title={t('Copy link')} icon={CopyLinkIcon} />
          </div>
        </>
      ) : (
        <div>{t('Published configurator link')}</div>
      )}
    </>
  );
};

/**
 * @param deleteAfter Unix timestamp
 */
function _deleteTimeHasPassed(deleteAfter: number): boolean {
  return Date.now() > deleteAfter * 1000;
}
