import { GridColumnVisibilityModel, useGridApiContext } from '@mui/x-data-grid-pro';
import { useEffect, useId, useRef, useState } from 'react';
import React from 'react';
import { GearIcon } from 'assets/icons';
import { CUSTOM_FIELD_PREFIX } from 'components/company/company-settings-staff-active-users-card';
import { useCompanySettingsStaff } from 'components/company/company-settings-staff-provider';
import { Button } from 'controls/button/button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnPopover } from 'controls/cbn-popover/cbn-popover';
import { CbnSearchInput } from 'controls/input/search-input';
import { Switch } from 'controls/switch/switch';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

export const CompanySettingsStaffColumnFilterButton: React.FC = () => {
  const { t } = useTypedTranslation();
  const { customFields } = useCompanySettingsStaff();
  const btnRef = useRef<HTMLDivElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const apiRef = useGridApiContext();
  const prefixId = useId();
  // Only keep a local state for this UI and communicate with the `apiRef`.
  // This way we don't need to control the whole `ColumnVisibilityModel` ourselves
  const [localColumnVisibility, setLocalColumnVisibility] = useState<{ [key: string]: boolean }>({});
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const cleanupFn = apiRef.current.subscribeEvent('columnVisibilityModelChange', newModel => {
      setLocalColumnVisibility(newModel);
    });

    return cleanupFn;
  }, [apiRef]);

  const togglePopover = (): void => {
    setPopoverOpen(x => !x);
  };

  useEffect(() => {
    if (!popoverOpen) {
      setSearchValue('');
    }
  }, [popoverOpen]);

  const onVisibilityChange = (fieldName: string, isVisible: boolean): void => {
    apiRef.current.setColumnVisibility(fieldName, isVisible);
    setLocalColumnVisibility(oldData => ({ ...oldData, [fieldName]: isVisible }));
  };

  const staticColumns: { id: string; label: string }[] = [
    { id: 'firstname', label: 'First name' },
    { id: 'lastname', label: 'Last name' },
    { id: 'email', label: 'Email' },
    { id: 'state', label: 'State' },
    { id: 'role', label: 'Role' },
  ];

  const filteredSystemCols = staticColumns.filter(c => c.label.toLowerCase().includes(searchValue.toLowerCase()));
  const filteredCustomCols = Object.keys(customFields).filter(c => c.toLowerCase().includes(searchValue.toLowerCase()));

  return (
    <>
      <div data-cmptype="UserCustomFieldsNewButton" ref={btnRef}>
        <Button text={t('Manage columns')} Svg={GearIcon} variant="Outlined" onClick={togglePopover} />
      </div>
      <CbnPopover
        open={popoverOpen}
        anchorEl={btnRef.current}
        data-cmptype="UserCustomFieldsNewButton-popover"
        onClose={(): void => togglePopover()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <CbnCard>
          <CbnCardBody>
            <div className="flex flex-col gap-2">
              <span>{t('Select displayed user properties.')}</span>
              <CbnSearchInput
                placeholder={t('Find property name')}
                value={searchValue}
                onValueChange={(newValue): void => setSearchValue(newValue)}
              />
              <hr className="border-neutral-50" />
              <div className="grid grid-cols-[1fr_auto] items-center gap-x-2">
                <div className="col-span-2 py-4 text-s-regular uppercase text-neutral-60">{t('System')}</div>
                {filteredSystemCols.map(col => (
                  <React.Fragment key={col.id}>
                    <label className="cursor-pointer" htmlFor={`${prefixId}${col.id}`}>
                      {t(col.label)}
                    </label>
                    <div className="flex">
                      <Switch
                        id={`${prefixId}${col.id}`}
                        checked={_isColVisible(localColumnVisibility, col.id)}
                        onValueChanged={(isVisible): void => onVisibilityChange(col.id, isVisible)}
                      />
                    </div>
                  </React.Fragment>
                ))}
                <div className="col-span-2 py-4 text-s-regular uppercase text-neutral-60">
                  {t('Custom user fields')}
                </div>
                {filteredCustomCols.map(fieldName => (
                  <React.Fragment key={fieldName}>
                    <label className="cursor-pointer" htmlFor={`${prefixId}-${CUSTOM_FIELD_PREFIX}${fieldName}`}>
                      {t(fieldName)}
                    </label>
                    <div className="flex">
                      <Switch
                        id={`${prefixId}-${CUSTOM_FIELD_PREFIX}${fieldName}`}
                        checked={_isColVisible(localColumnVisibility, CUSTOM_FIELD_PREFIX + fieldName)}
                        onValueChanged={(isVisible): void =>
                          onVisibilityChange(CUSTOM_FIELD_PREFIX + fieldName, isVisible)
                        }
                      />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </CbnCardBody>
        </CbnCard>
      </CbnPopover>
    </>
  );
};

const _isColVisible = (columns: GridColumnVisibilityModel, id: string): boolean =>
  !Object.hasOwn(columns, id) || columns[id] === true;
