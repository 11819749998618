import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  Event,
  Exception,
  ITelemetryItem,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { showErrorDialog } from 'components/error-handling/global-error-dialog-manager';
import { tryActionResultParsing } from 'services/http/http.service';
import * as LocalStorageService from 'services/local-storage/local-storage.service';
import { LogSeverity, addLog, generateErrorGuid } from 'services/store/logger.service';
import type { PersistedLocalStorageState } from 'services/store/persistence.middleware';
import type { UserInfo } from 'slices/auth/auth.slice';

/**
 * Stores an optional message which gets displayed to the user to improve readability of the error
 */
export const USER_MESSAGE_KEY = '_userMsg';
/**
 * Indicator if the dialog was shown as dialog to the user (not only logged in the console)
 */
export const PREVENT_DIALOG_KEY = '_userDialogPrevented';
/**
 * Indicator if the error was catched by the error boundary (and might have broken the whole app)
 */
export const ERROR_BOUNDARY_KEY = '_errorBoundary';

/**
 * Expected causes returned by our API which will not be reported to AppInsights (and not shown to the user)
 */
const _EXPECTED_ERROR_CAUSES = ['NotLoggedIn', 'LoginFailed'];

const _EXPECTED_MONACO_RESIZE_ERROR_TEXTS = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
];

/**
 * References:
 * https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript
 * https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
 * https://github.com/microsoft/ApplicationInsights-JS/blob/master/API-reference.md
 */
const aiReactPlugin = new ReactPlugin();
const aiInstance = new ApplicationInsights({
  config: {
    enableResponseHeaderTracking: true,
    instrumentationKey: window.appInsightKey || '',
    enableAutoRouteTracking: true,
    enableUnhandledPromiseRejectionTracking: true,
    extensions: [aiReactPlugin],
    enableAjaxErrorStatusText: true,
    disableCookiesUsage: true,
  },
});

/**
 * Don't try to send when the Instrumentation Key is obviously invalid
 */
const _preventOnInvalidInstrumentationKey = (): boolean => {
  const isValidKey = window.appInsightKey && window.appInsightKey !== '00000000-0000-0000-0000-000000000000';
  return isValidKey;
};

const _handleSpecificErrors = (envelope: ITelemetryItem): boolean | void => {
  if (envelope.data?.message) {
    const trimmedError = trimErrorPrefixes(envelope.data.message);

    // Special handling of a resize errors related to the `monaco editor`
    // No easy fix was found to prevent this error from happening in our current layout, so it's only logged locally
    if (_EXPECTED_MONACO_RESIZE_ERROR_TEXTS.includes(trimmedError)) {
      addLog(envelope.data.message, 'Error', { sendToTracker: false });
      return false;
    }

    if (trimmedError === 'Network Error' && !window.navigator.onLine) {
      // ignore errors caused by being offline
      return false;
    }

    // check for the string itself because the pure http status code isn't available at this point
    if (trimmedError.includes('status code 401')) {
      return false;
    }

    // check for chunk load error (CB-9453)[https://combeenation.youtrack.cloud/issue/CB-9453/Chunk-error-when-opening-BabylonJS-asset]
    if (
      trimmedError === "Uncaught SyntaxError: Unexpected token '<'" &&
      (envelope.data.errorSrc as string)?.startsWith('window.onerror') &&
      (envelope.data.errorSrc as string)?.endsWith('.chunk.js:1:1')
    ) {
      return false;
    }

    const actionResult = tryActionResultParsing(envelope.data?.message);
    if (actionResult?.Causes?.some(cause => _EXPECTED_ERROR_CAUSES.includes(cause.Id))) {
      return false;
    }
  }
};

/**
 * Add any relevant data to the envelope which might help to
 * identify or filter the data in AI
 */
const _enhanceEnvelopeData = (envelope: ITelemetryItem): void => {
  const enhancedData = envelope.data || {};

  if (envelope.baseType === Exception.dataType && envelope.baseData) {
    envelope.baseData.id = envelope.baseData?.id || generateErrorGuid();
    enhancedData.errorGuid = envelope.baseData.id;
  }

  envelope.data = enhancedData;
};

const _processLogsAndErrors = (envelope: ITelemetryItem): void => {
  const severity: LogSeverity = (SeverityLevel[envelope.baseData?.severityLevel] as LogSeverity) || 'Information';

  switch (envelope.baseType) {
    case Exception.dataType:
      {
        const aiException = envelope.baseData as Exception;
        const excSeverity = SeverityLevel[aiException.severityLevel] as LogSeverity;
        aiException.exceptions.forEach(exception => {
          addLog(exception.message, excSeverity || severity, {
            envelope: envelope,
            sendToTracker: false,
          });

          if (envelope.data?.[PREVENT_DIALOG_KEY] !== true && envelope.data?.[ERROR_BOUNDARY_KEY] !== true) {
            // When it's handled by an ErrorBoundary there are two individual 'envelopes':
            // 1. The error from the global listener (window.onerror)
            // 2. The `trackException` coming from the ErrorBoundary
            // Only the fallback cmp of the ErrorBoundary should show the error
            // Solution:
            // - Prevent dialog when coming from the ErrorBoundary
            // - The ErrorBoundary tries to find & hide the dialog of [1.]
            const userMsg = envelope.data?.[USER_MESSAGE_KEY] || exception.message;
            showErrorDialog('Error', userMsg, aiException.id);
          }
        });
      }
      break;
    case Event.dataType:
      addLog(`Event "${envelope.baseData?.name}"`, severity, { customProperties: envelope, sendToTracker: false });
      break;
    default:
      addLog(envelope.baseData?.message || envelope.name, severity, {
        customProperties: envelope,
        sendToTracker: false,
      });
      break;
  }
};

if (process.env.NODE_ENV !== 'test') {
  aiInstance.loadAppInsights();
}

/**
 *
 *
 * @param userMail If undefined try to retrieve from persisted data in localstorage
 */
export function setUserForTelemetry(userMail?: string): void {
  if (userMail) {
    aiInstance.setAuthenticatedUserContext(userMail);
  } else {
    const strAuth = LocalStorageService.getItem('auth');
    const authState: PersistedLocalStorageState<'userInfo', UserInfo> = strAuth ? JSON.parse(strAuth) : undefined;
    if (!authState?.userInfo) {
      return;
    }

    try {
      const email = authState.userInfo.value.email;
      if (email === undefined) {
        throw new Error('Property `email` is missing!');
      }
      aiInstance.setAuthenticatedUserContext(email);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('[DEV] setUserForTelemetry():', error);
      }
    }
  }
}

/**
 * Remove prefixed errors which might be added by 3rd party libraries.\
 * E.g.: "PromiseRejectionEvent: AxiosError: Request failed ..."
 */
export function trimErrorPrefixes(text: string): string {
  // Multiple "text parts" can occur together
  return text.replace('PromiseRejectionEvent: ', '').replace('ErrorEvent: ', '').replace('AxiosError: ', '');
}

// Call instantly (based on localStorage) in case an error happens before react is fully loaded
setUserForTelemetry();

aiInstance.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

// multiple initializers possible
aiInstance.addTelemetryInitializer(_enhanceEnvelopeData);
aiInstance.addTelemetryInitializer(_handleSpecificErrors);
aiInstance.addTelemetryInitializer(_processLogsAndErrors);
aiInstance.addTelemetryInitializer(_preventOnInvalidInstrumentationKey);

export { aiInstance, aiReactPlugin };
