import React, { useCallback, useRef } from 'react';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { DialogSizes, DialogVariants, ModalDialog } from 'components/modal-dialog/modal-dialog';

type ResolveCallbackFn<T> = (value: T | PromiseLike<T>) => void;

export type ConfirmDialogOptions = Partial<{
  headerText: string;
  variant: DialogVariants;
  confirmBtnText: string;
  hideCancelBtn: boolean;
  size: DialogSizes;
}>;

export type ConfirmDialogFn = (content: React.ReactNode, options?: ConfirmDialogOptions) => Promise<boolean>;
export type AlertDialogFn = (content: React.ReactNode, options?: ConfirmDialogOptions) => Promise<void>;
export type ErrorDialogFn = (content: React.ReactNode, options?: ConfirmDialogOptions) => Promise<void>;

type DialogContextState = {
  showDialog: ConfirmDialogFn;
};

const initialState: DialogContextState = {
  showDialog: () => Promise.resolve(false),
};

export const DialogContext = React.createContext<DialogContextState>(initialState);

export const DialogContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const resolveCallback = useRef<ResolveCallbackFn<boolean>>();

  const [showDialog, setShowDialog] = useState(false);
  const [content, setContent] = useState<React.ReactNode>('');
  const [dialogOptions, setDialogOptions] = useState<ConfirmDialogOptions>({});

  const onShowDialog = useCallback((content: React.ReactNode, options?: ConfirmDialogOptions) => {
    setShowDialog(true);
    setContent(content);
    setDialogOptions(options ?? {});

    if (resolveCallback.current) {
      console.error(
        'ConfirmContextProvider: A confirm dialog was requested before the previous one got resolved!\n' +
          'The previous one will be resolved with `false`!'
      );
      resolveCallback.current(false);
    }

    const promise = new Promise<boolean>(res => {
      resolveCallback.current = res;
    });

    return promise;
  }, []);

  const onModalConfirm = useCallback(() => {
    setShowDialog(false);
    resolveCallback.current?.(true);
    resolveCallback.current = undefined;
  }, []);

  const onModalCancel = useCallback(() => {
    setShowDialog(false);
    resolveCallback.current?.(false);
    resolveCallback.current = undefined;
  }, []);

  return (
    <DialogContext.Provider value={{ showDialog: onShowDialog }}>
      {children}
      {showDialog &&
        createPortal(
          <ModalDialog
            header={dialogOptions.headerText ?? ''}
            variant={dialogOptions.variant ?? 'NoIcon'}
            actionTypes={dialogOptions.hideCancelBtn ? 'ConfirmOnly' : 'ConfirmCancel'}
            actions={{ onConfirm: onModalConfirm, onCancel: onModalCancel }}
            confirmText={dialogOptions.confirmBtnText}
            size={dialogOptions.size ?? 'Small'}
          >
            {/** whitespace-pre-line to consider line breaks (\n) in text */}
            {typeof content === 'string' ? <div className="whitespace-pre-line">{content}</div> : content}
          </ModalDialog>,
          document.body
        )}
    </DialogContext.Provider>
  );
};
