import { GridEditBooleanCell, GridEditInputCell, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import {
  ConfigRow,
  EDIT_GRID_FIELD_KEYS,
} from 'components/asset-editor/details/modals/datasource-edit/edit-grid/asset-datasource-edit-grid';
import { GridEditAutocompleteCell } from 'controls/datagrid/column-types/data-grid-edit-autocomplete-cell';
import { DataSourceColumnTypes } from 'generated/datasource-column-types';
import { isListColumnType } from 'helper/assets/assets.helper';

type AssetDatasourceEditGridDefaultValueCellProps = GridRenderEditCellParams<ConfigRow> & {};
export const AssetDatasourceEditGridDefaultValueCell: React.FC<
  AssetDatasourceEditGridDefaultValueCellProps
> = props => {
  const { row } = props;

  const typeDef = row[EDIT_GRID_FIELD_KEYS.TypeDef];
  if (typeDef.type === DataSourceColumnTypes.Bool) {
    return <GridEditBooleanCell {...props} />;
  } else if (typeDef.type === DataSourceColumnTypes.LinkedAsset) {
    return <GridEditAutocompleteCell {...props} />;
  } else if (isListColumnType(typeDef.type)) {
    return null;
  } else {
    return <GridEditInputCell {...props} />;
  }
};
