import { PostProcessingStates } from 'generated/post-processing-states';
import { ReplicationStates } from 'generated/replication-states';
import { getNextAssetVersionDisplayName, getSchemaNamePart } from 'helper/assets/assets.helper';
import { HttpStatusCode } from 'helper/http/http-status.helper';
import { mapObject } from 'helper/object/object.helper';
import { CfgrTemplate } from 'services/cfgrs/cfgrs.service';
import {
  AssetBundleIdRequest,
  CfgrDomainRequest,
  MappingDto,
  ReplicaMappingDto,
} from 'services/http/endpoints/replicator.endpoints';
import * as HttpService from 'services/http/http.service';
import { isGenericActionResult } from 'services/http/http.service';
import { BlueprintAssetBundle, BlueprintCfgr } from 'slices/replicator/replicator.slice';

export const replicationStatusResponses = ['Success', 'EntityNotFound', 'UnknownResponse'] as const;
export type ReplicationStatusResponse = (typeof replicationStatusResponses)[number];

export type PostProcessingResultDto = {
  configurators: { [key: string]: PostProcessingStates };
  assetBundles: { [key: string]: PostProcessingStates };
};

type ReplicationStatusDto = {
  state: ReplicationStates;
  startedBy: string;
  startedAt: number;
  completedAt: number;
  totalSize: number;
  completedSize: number;
  isCancelled: boolean;
  failureReason?: {
    $typ: string;
    Id: string;
    Message: string;
  };
  autoPublishResult: PostProcessingResultDto | null;
};

export type ReplicationStatus = ReplicationStatusDto & {
  replicationId: string;
  targetCompany: string;
};

export type ReplicaMapping = MappingDto;

/**
 * Get bundle version data for a certain bundle.\
 * This doesn't contain the individual assets yet => use `fetchAssets` for that.
 */
export async function getReplicationStatus(
  replicationId: string,
  targetCompany: string
): Promise<{ responseStatus: ReplicationStatusResponse; result?: ReplicationStatus }> {
  const { url, params } = HttpService.endpoints.replicatorGetReplicationStatus(replicationId, targetCompany);
  const res = await HttpService.httpClient.get<ReplicationStatusDto>(url, { params, validateStatus: () => true });

  if (res.status === HttpStatusCode.Ok_200) {
    const replicationStatus = _replicationStatusRespToReplicationStatus(replicationId, targetCompany, res.data);
    return { responseStatus: 'Success', result: replicationStatus };
  } else {
    return {
      responseStatus: isGenericActionResult(res.data, replicationStatusResponses) ? res.data.Id : 'UnknownResponse',
    };
  }
}

function _replicationStatusRespToReplicationStatus(
  replicationId: string,
  targetCompany: string,
  data: ReplicationStatusDto
): ReplicationStatus {
  return {
    replicationId: replicationId,
    targetCompany: targetCompany,

    state: data.state,
    startedBy: data.startedBy,
    startedAt: data.startedAt,
    completedAt: data.completedAt,
    totalSize: data.totalSize,
    completedSize: data.completedSize,
    isCancelled: data.isCancelled,
    failureReason: data.failureReason,
    autoPublishResult: data.autoPublishResult,
  };
}

/**
 * After a replication is started, we need to poll the replication status until it's finished.
 * This is basically a magic number. Shouldn't be too low to not DDOS the server, but also not too high to keep the UI
 * responsive.
 */
const _REPLICATION_STATUS_CHECK_POLLING_INTERVAL_MS = 500;

/**
 * Poll server for replication status until it's finished.
 * Throws if replication failed.
 */
const _waitForReplicationToFinish = async (
  replicationId: string,
  targetCompany: string,
  pollingIntervalMs: number
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const intervalId = window.setInterval(async () => {
      let status: Awaited<ReturnType<typeof getReplicationStatus>> | undefined;
      try {
        status = await getReplicationStatus(replicationId, targetCompany);
      } catch (e) {
        window.clearInterval(intervalId);
        reject(e);
        return;
      }

      if (status.result?.failureReason || status.responseStatus !== 'Success') {
        window.clearInterval(intervalId);
        reject(status.result?.failureReason?.Message ?? status.responseStatus);
      }

      if (status.result?.state === ReplicationStates.Finished) {
        window.clearInterval(intervalId);
        resolve();
      }
    }, pollingIntervalMs);
  });
};

/**
 * Difference to `postCreateBlueprint`:
 * Provides "direct" access to the low level create blueprint API by directly passing the inputs along without any
 * mapping etc.
 */
export async function postCreateBlueprint(
  companyId: string,
  configurators: CfgrDomainRequest[],
  assetBundles: AssetBundleIdRequest[]
): Promise<string> {
  const { url, params } = HttpService.endpoints.replicatorCreateBlueprint(companyId, configurators, assetBundles);
  const result = await HttpService.httpClient.post<string>(url, params);
  return result.data;
}

export async function postCreateBlueprintCrossEnv(
  companyId: string,
  configurators: BlueprintCfgr[],
  assetBundles: BlueprintAssetBundle[]
): Promise<string> {
  const { url, params } = HttpService.endpoints.replicatorCreateBlueprintCrossEnv(
    companyId,
    configurators.map(c => ({ companyName: companyId, configuratorName: c.id })),
    assetBundles.reduce<AssetBundleIdRequest[]>((acc, bundleDef) => {
      return acc.concat(
        Object.values(bundleDef.versions)
          .filter(v => v.selected)
          .map(v => ({
            companyName: companyId,
            assetBundleName: bundleDef.name,
            assetBundleVersion: v.id,
          }))
      );
    }, [])
  );

  const result = await HttpService.httpClient.post<string>(url, params);
  return result.data;
}

export async function postReplicatorReplicate(
  blueprintId: string,
  targetDefinition: ReplicaMappingDto
): Promise<string> {
  const { url, params, data } = HttpService.endpoints.replicatorReplicate(
    blueprintId,
    targetDefinition.company,
    targetDefinition.configurators,
    targetDefinition.assetBundles
  );

  const response = await HttpService.httpClient.post<string>(url, data, { params: params });
  return response.data;
}

export async function postReplicatorReplicateCrossEnv(
  blueprintId: string,
  targetDefinition: ReplicaMappingDto
): Promise<string> {
  const { url, params, data } = HttpService.endpoints.replicatorReplicateCrossEnv(
    blueprintId,
    targetDefinition.company,
    targetDefinition.configurators,
    targetDefinition.assetBundles
  );

  const response = await HttpService.httpClient.post<string>(url, data, { params: params });
  return response.data;
}

export async function getReplicatorGenerateMapping(blueprintId: string, companyName: string): Promise<ReplicaMapping> {
  const { url, params } = HttpService.endpoints.replicatorGenerateMapping(blueprintId, companyName);

  const response = await HttpService.httpClient.get<MappingDto>(url, { params });
  return response.data;
}

export async function getReplicatorGenerateMappingCrossEnv(
  blueprintSasUri: string,
  companyName: string
): Promise<ReplicaMapping> {
  const { url, params } = HttpService.endpoints.replicatorGenerateMappingCrossEnv(blueprintSasUri, companyName);

  const response = await HttpService.httpClient.get<MappingDto>(url, { params });
  return response.data;
}

export async function postReplicatorCancel(replicatorId: string, companyName: string): Promise<void> {
  const { url, params } = HttpService.endpoints.replicatorCancel(replicatorId, companyName);
  HttpService.httpClient.post(url, undefined, { params });
}

/**
 * "Fast lane" to replicate a cfgr with automatic blueprint & mapping check
 */
export async function startCfgrReplicateInSameCompany(
  companyName: string,
  configurator: CfgrDomainRequest,
  targetId: string,
  targetDisplayName: string
): Promise<string> {
  const newBlueprintId = await postCreateBlueprint(companyName, [configurator], []);

  const mapping = await getReplicatorGenerateMapping(newBlueprintId, companyName);
  const idAlreadyExists = mapping.existingCfgrNames.some(id => id.toLowerCase() === targetId.toLowerCase());
  const nameAlreadyExists = mapping.existingCfgrDisplayNames.some(
    name => name.toLowerCase() === targetDisplayName.toLowerCase()
  );

  if (idAlreadyExists || nameAlreadyExists) {
    throw new Error(
      `startCfgrReplicateInSameCompany: The target "${targetDisplayName} (ID: ${targetId})" already exists!`
    );
  }

  const schemaName = Object.keys(mapping.mapping.configurators)[0];

  if (!schemaName) {
    throw new Error(`startCfgrReplicateInSameCompany: Schema name for mapping is missing!`);
  }

  const replicationId = await postReplicatorReplicate(newBlueprintId, {
    company: companyName,
    configurators: { [schemaName]: { displayName: targetDisplayName, name: targetId } },
    assetBundles: {},
  });
  return replicationId;
}

/**
 * "Fast lane" to replicate a bundle with automatic blueprint & mapping check
 */
export async function startAssetBundleReplicateInSameCompany(
  companyName: string,
  bundle: AssetBundleIdRequest,
  targetId: string,
  targetDisplayName: string
): Promise<string> {
  const newBlueprintId = await postCreateBlueprint(companyName, [], [bundle]);

  const mapping = await getReplicatorGenerateMapping(newBlueprintId, companyName);
  const idAlreadyExists = mapping.existingAssetBundleNames.some(id => id.toLowerCase() === targetId.toLowerCase());
  const nameAlreadyExists = mapping.existingAssetBundleDisplayNames.some(
    name => name.toLowerCase() === targetDisplayName.toLowerCase()
  );
  if (idAlreadyExists || nameAlreadyExists) {
    throw new Error(
      `startAssetBundleReplicateInSameCompany: The target "${targetDisplayName} (ID: ${targetId})" already exists!`
    );
  }

  const bundleMapping = mapping.mapping.assetBundles;
  const schemaName = Object.keys(bundleMapping)[0];

  if (!schemaName) {
    throw new Error(`startAssetBundleReplicateInSameCompany: Schema name for mapping is missing!`);
  }

  const replicationId = await postReplicatorReplicate(newBlueprintId, {
    company: companyName,
    configurators: {},
    assetBundles: {
      [schemaName]: { ...bundleMapping[schemaName], bundleDisplayName: targetDisplayName, bundleName: targetId },
    },
  });
  return replicationId;
}

export async function createCfgrFromTemplate(
  targetCompanyName: string,
  targetCfgrName: { uniqueId: string; displayName: string },
  targetAssetBundleNames: { templateBundleKey: string; targetName: string; targetDisplayName: string }[],
  template: CfgrTemplate
): Promise<void> {
  const srcCompany = template.companyName;

  const blueprintCfgrs = [{ companyName: srcCompany, configuratorName: template.cfgrName }];

  const blueprintAssetBundles = Object.entries(template.assetBundles).map(([key, value]) => ({
    companyName: srcCompany,
    assetBundleName: value.bundleName,
    assetBundleVersion: getSchemaNamePart(key, 'version'), // The key is a schema id like `company.asset.version`
  }));

  const blueprintId = await postCreateBlueprint(srcCompany, blueprintCfgrs, blueprintAssetBundles);

  const replicationCfgrs = {
    [template.cfgrName]: {
      name: targetCfgrName.uniqueId,
      displayName: targetCfgrName.displayName,
    },
  };

  const replicationAssetBundles = mapObject(template.assetBundles, (value, key) => {
    const namesFromInput = targetAssetBundleNames.find(x => x.templateBundleKey === key)!;
    const bundleVersionDisplayName = getNextAssetVersionDisplayName();
    return {
      bundleName: namesFromInput.targetName,
      bundleDisplayName: namesFromInput.targetDisplayName,
      displayName: bundleVersionDisplayName,
    };
  });

  const replicationId = await postReplicatorReplicate(blueprintId, {
    company: targetCompanyName,
    configurators: replicationCfgrs,
    assetBundles: replicationAssetBundles,
  });

  await _waitForReplicationToFinish(replicationId, targetCompanyName, _REPLICATION_STATUS_CHECK_POLLING_INTERVAL_MS);
}
