import { LinkButton } from 'controls/button/link-button';
import { Card } from 'controls/card/card';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { buildCompanyInvitationSubPagePath } from 'services/routes/company-invitation-routes.service';
import { PATHS } from 'services/routes/paths.service';

type LoginOrRegisterPickerProps = {
  companyName?: string;
  inviteToken?: string;
  inviteRecipient?: string;
  signInTitle: string;
  signInDetailText: string;
  hideRegisterCard?: boolean;
  registerTitle?: string;
  registerDetailText?: string;
};

const TargetCard = ({
  type,
  title,
  detailText,
  companyName,
  inviteToken,
  inviteRecipient,
}: {
  type: 'log-in' | 'register';
  title?: string;
  detailText?: string;
  companyName?: string;
  inviteToken?: string;
  inviteRecipient?: string;
}): JSX.Element => {
  const { t } = useTypedTranslation();
  const buttonText = type === 'log-in' ? t('Log in') : t('Create account');
  const href =
    companyName && inviteToken && type === 'log-in'
      ? buildCompanyInvitationSubPagePath(companyName, inviteToken, 'login', inviteRecipient)
      : companyName && inviteToken && type === 'register'
        ? buildCompanyInvitationSubPagePath(companyName, inviteToken, 'register', inviteRecipient)
        : type === 'log-in'
          ? PATHS.login
          : PATHS.registerUser;

  return (
    <div className="w-[14rem]">
      <Card>
        <div className="flex flex-col items-center gap-4 pt-3">
          <div className="text-center text-heading-s">{title}</div>
          <div className="whitespace-pre-wrap text-m-regular">{detailText}</div>
          <LinkButton variant="Secondary" href={href} text={buttonText} />
        </div>
      </Card>
    </div>
  );
};

export const LoginOrRegisterPicker: React.FC<LoginOrRegisterPickerProps> = ({
  companyName,
  inviteToken,
  inviteRecipient,
  signInTitle,
  signInDetailText,
  hideRegisterCard = false,
  registerTitle,
  registerDetailText,
}) => {
  return (
    <div data-cmptype="LoginOrRegisterPicker" className="flex grow items-center justify-center">
      <div className="flex flex-row gap-5">
        <TargetCard
          type="log-in"
          title={signInTitle}
          detailText={signInDetailText}
          companyName={companyName}
          inviteToken={inviteToken}
          inviteRecipient={inviteRecipient}
        />
        {!hideRegisterCard && (
          <TargetCard
            type="register"
            title={registerTitle}
            detailText={registerDetailText}
            companyName={companyName}
            inviteToken={inviteToken}
            inviteRecipient={inviteRecipient}
          />
        )}
      </div>
    </div>
  );
};
