import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SchemaNameInput, SchemaNameInputResult } from 'components/common/schema-name-input';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { HighlightedText } from 'controls/text/highlighted-text';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { startCfgrReplicateInSameCompany } from 'services/replicator/replicator.service';
import { buildReplicatorPath } from 'services/routes/replicator-routes.service';
import {
  selectAllCompanyCfgrs,
  selectSelectedCompany,
  selectSelectedConfigurator,
} from 'slices/company-data/company-data.slice';
import { addReplicationNotification, showNotificationMenu } from 'slices/notifications/notifications.slice';
import { setBlueprintPreselection } from 'slices/replicator/replicator.slice';

type CopyCfgrModalProps = {
  onConfirm: (createdCfgrName: string) => void;
  onCancel: () => void;
};

export const CopyCfgrModal: React.FC<CopyCfgrModalProps> = ({ onConfirm, onCancel }) => {
  const { t, tCopyCfgrInfo } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const companyCfgrs = useAppSelector(selectAllCompanyCfgrs);

  const companyDisplayName = useAppSelector(selectSelectedCompany)?.displayName || '';

  const cfgrIds = companyCfgrs.map(cfgr => cfgr.id);
  const cfgrDisplayNames = companyCfgrs.map(cfgr => cfgr.displayName);

  const [input, setInput] = useState<SchemaNameInputResult>({
    uniqueId: '',
    displayName: selectedCfgr?.displayName ?? '',
  });

  const onSchemaInputChange = useCallback((result: SchemaNameInputResult): void => {
    setInput(result);
  }, []);

  const [isProcessing, setIsProcessing] = useState(false);

  const onCopyAcrossCompanyClicked = async (): Promise<void> => {
    if (!selectedCfgr) {
      return;
    }
    dispatch(setBlueprintPreselection({ cfgrId: selectedCfgr.id }));
    navigate(buildReplicatorPath(selectedCfgr.companyId, 'copy'));
  };

  const onConfiguratorConfirm = async (): Promise<void> => {
    if (!selectedCfgr) {
      throw new Error("Configurator can't be copied. No selected configurator found!");
    }
    if (input.hasErrors) {
      return;
    }

    setIsProcessing(true);

    const cfgr = { companyName: selectedCfgr.companyId, configuratorName: selectedCfgr.id };
    const { uniqueId, displayName } = input;
    const replicationId = await startCfgrReplicateInSameCompany(selectedCfgr.companyId, cfgr, uniqueId, displayName);

    dispatch(
      addReplicationNotification({
        replicationId: replicationId,
        sourceCompany: selectedCfgr.companyId,
        targetCompany: selectedCfgr.companyId,
        cfgrIds: { [input.uniqueId]: input.displayName },
        assetBundleIds: {},
      })
    );
    dispatch(showNotificationMenu(true));

    setIsProcessing(false);
    onConfirm(input.uniqueId);
  };

  return (
    <ModalDialog
      data-cmptype="CopyCfgr"
      header={t('Copy configurator')}
      variant={'NoIcon'}
      actions={{ onConfirm: onConfiguratorConfirm, onCancel: onCancel }}
      confirmText={t('Copy configurator')}
      confirmDisabled={!!input.hasErrors}
      isProcessingConfirm={isProcessing}
    >
      <div className="flex grow flex-col gap-4">
        <p className="text-m-regular text-neutral-70">
          <HighlightedText
            text={tCopyCfgrInfo(selectedCfgr?.displayName ?? '', companyDisplayName)}
            highlight={selectedCfgr?.displayName ?? ''}
            className="bg-transparent text-m-medium"
          />
        </p>
        <SchemaNameInput
          input={input}
          onChange={onSchemaInputChange}
          unavailableIds={cfgrIds}
          unavailableNames={cfgrDisplayNames}
          customLabels={{ name: t('Target name'), id: t('Target ID') }}
        />
        <p className="text-m-regular text-neutral-70">
          <span>{t('If you want to copy the configurator to another company, use the copy feature on the page')} </span>
          <button
            type="button"
            className="cursor-pointer text-primary-main underline"
            onClick={onCopyAcrossCompanyClicked}
          >
            {t('Export')}
          </button>
          .
        </p>
      </div>
    </ModalDialog>
  );
};
