import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { CartSimpleIcon, ChartPieIcon, ConfigurationsIcon, CurrencyDollarIcon, ShareIcon } from 'assets/icons';
import { MetricValue } from 'components/cfgrs/metrics/metric-value';
import { MetricsDateRange } from 'components/cfgrs/metrics/metrics-date-range';
import { Card } from 'controls/card/card';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { CfgnMetricResult } from 'services/cfgn/cfgn.service';

export const MetricsCard: React.FC = () => {
  const { t } = useTypedTranslation();

  // metrics are set from `MetricsDateRange` sub component
  const [metrics, setMetrics] = useState<CfgnMetricResult>();

  const onMetricsChange = useCallback((metricsVal: CfgnMetricResult | undefined) => setMetrics(metricsVal), []);

  return (
    <Card
      headerCmp={
        <div className="flex w-full shrink-0 flex-row items-center justify-between">
          <span className="text-heading-s font-semibold">{t('Metrics')}</span>
          <MetricsDateRange onMetricsChange={onMetricsChange} />
        </div>
      }
    >
      <div className={classNames('flex flex-row justify-evenly transition-opacity', { 'opacity-20': !metrics })}>
        <MetricValue
          Svg={ConfigurationsIcon}
          label={t('Configurations')}
          value={metrics ? _formatCount(_calculateCfgnCount(metrics)) : '-'}
        />
        <MetricValue Svg={CartSimpleIcon} label={t('Finished')} value={_formatCount(metrics?.FinishCount)} />
        <MetricValue Svg={ShareIcon} label={t('Shared')} value={_formatCount(metrics?.SharedCfgnCount)} />
        <MetricValue
          Svg={CurrencyDollarIcon}
          label={t('Pot. Revenue')}
          value={metrics ? _getPotentialRevenue(metrics) : '-'}
        />
        <MetricValue Svg={ChartPieIcon} label={t('Conv. Rate')} value={metrics ? _getConversionRate(metrics) : '-'} />
      </div>
    </Card>
  );
};

function _calculateCfgnCount(metrics: CfgnMetricResult): number {
  /*  On the server a 'Configuration' gets converted to a 'Finish' when a checkout happens
   *  Therefore those two values are summed up for the 'Configurations'
   *  From a user-perspective it also makes more sense in relation to the 'Conversion rate'
   *
   *  A 'Shared Configuration' is only created as clone of the active 'Configuration' (when
   *  the share-button gets clicked), so it's not added to the configurations
   */
  return metrics.CfgnCount + metrics.FinishCount;
}

function _getPotentialRevenue(metrics: CfgnMetricResult): string {
  if (!metrics.PriceSum) {
    return '-';
  }

  const formattedValue = metrics.PriceSum.toLocaleString(undefined, {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

  return formattedValue;
}

function _formatCount(count: number | undefined): string {
  if (count === undefined) {
    return '-';
  }

  // automatically adds a dot after 3 digits, since the `useGrouping` option is set by default
  const formattedValue = count.toLocaleString();

  return formattedValue;
}

function _getConversionRate(metrics: CfgnMetricResult): string {
  const cfgnSum = _calculateCfgnCount(metrics);
  if (cfgnSum === 0) {
    return '-';
  }

  const percentage = metrics.FinishCount / cfgnSum;

  const formattedValue = percentage.toLocaleString(undefined, {
    style: 'percent',
    maximumFractionDigits: 1,
  });

  return formattedValue;
}
