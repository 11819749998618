/**
 * Can be used to remove duplicate entries from arrays with primitive values, i.e. get an array with distinct/unique
 * values.
 *
 * Usage:
 * ```
 * const uniqueValues = arr.filter(distinctFilter);
 * ```
 */
export function distinctFilter<T extends string | number | boolean | undefined>(x: T, idx: number, self: T[]): boolean {
  return self.indexOf(x) === idx;
}

type Primitives = string | number | boolean;
type PrimitivesArray<T extends Primitives> = T[];

/**
 * Helper function to compare arrays with primitive values.
 * Providing the same for complex values like objects etc. is much more complicated and there are enough specialized
 * libs like lodash etc. out there which provide this kind of functionality...
 */
export function compareFlat<T extends Primitives>(a: PrimitivesArray<T>, b: PrimitivesArray<T>): boolean {
  const allSame = a.length === b.length && a.every((x, idx) => x === b[idx]);
  return allSame;
}

/**
 * Compare an array against all other items of the array to check for any duplicates
 *
 * @param input
 * @param predicate
 * @returns
 */
export function hasDuplicates<T>(input: T[], predicate: (a: T, b: T) => boolean): boolean {
  return input.some((a, aIdx) => input.some((b, bIdx) => aIdx !== bIdx && predicate(a, b)));
}
