import { SelectOption } from 'controls/select/select';
import { AssetLinkTypes } from 'generated/asset-link-types';
import { AssetTypes } from 'generated/asset-types';
import { DataSourceColumnTypes } from 'generated/datasource-column-types';
import { FileExtensionGetterStrategy, getFileExtension } from 'helper/file/file.helper';
import { generateValidHiveName, isValidHiveIdentifier } from 'helper/hive/hive.helper';
import { DataSourceColumnDefaultValue } from 'services/assets/assets.service';
import {
  Asset,
  AssetBundleVersion,
  AssetLink,
  AssetLinks,
  AssetPath,
  Assets,
  UPLOAD_IN_PROGRESS_STATES,
  isAssetBundleVersionDraft,
  isDataSourceAsset,
  isFolderAsset,
} from 'slices/assets/assets.slice';

export type UploadStats = {
  hasError: boolean;
  uploadPercentage: number;
  uploadInfinite: boolean;
  uploadText: string;
};

export type AssetFileValidity =
  | 'valid'
  | 'info_overwrite'
  | 'warning_typechange'
  | 'error_overwriteFolder'
  | 'error_invalidHiveIdentifier'
  | 'error_duplicate'
  | 'error_breaksDatasourceLink'
  | 'error_breaksAssetLink';

export function isAssetFileValidityError(asset: AssetFileValidity): boolean {
  return asset.startsWith('error_');
}

export function isAssetFileValidityWarning(asset: AssetFileValidity): boolean {
  return asset.startsWith('warning_');
}

export function isAssetFileValidityInfo(asset: AssetFileValidity): boolean {
  return asset.startsWith('info_');
}

export type AssetFile = {
  file: File;
  assetName: string;
  folderName: string;
  assetType: AssetTypes;
  assetTypeOptions: SelectOption[];
  validity: AssetFileValidity;
};

export type OverwrittenAssetNames = { [key: string]: string };
export type OverwrittenFolders = { [key: string]: string };
export type OverwrittenAssetTypes = { [key: string]: AssetTypes };

// "isMainType" means that the enclosing asset type is the main type for this file ending, not the other way around
type AssetTypeFileEndingEntry = { key: string; isMainType?: boolean };
type AssetTypeSelectionEntry = { assetType: AssetTypes; isMainType?: boolean };
type AssetTypeSuggestion = { mainType: AssetTypes; availableTypes: AssetTypes[] };

export const ASSET_TYPE_FILE_ENDINGS: Record<
  AssetTypes.Image | AssetTypes.DataSource | AssetTypes.BabylonJs | AssetTypes.Text | AssetTypes.Material,
  AssetTypeFileEndingEntry[]
> = {
  [AssetTypes.Image]: [{ key: 'jpg' }, { key: 'jpeg' }, { key: 'png' }, { key: 'gif' }, { key: 'bmp' }],
  [AssetTypes.DataSource]: [{ key: 'csv' }, { key: 'xls' }, { key: 'xlsx' }],
  [AssetTypes.BabylonJs]: [{ key: 'babylon' }, { key: 'glb' }],
  [AssetTypes.Text]: [{ key: 'html' }, { key: 'json', isMainType: true }, { key: 'xml' }, { key: 'txt' }],
  [AssetTypes.Material]: [{ key: 'json' }],
};

export const EXCEL_DATASOURCE_FILE_ENDINGS = ['xls', 'xlsx'];

export function defaultValueToString(
  value: string | number | boolean | string[] | number[] | boolean[],
  type: DataSourceColumnTypes
): string {
  switch (type) {
    case DataSourceColumnTypes.ListString:
    case DataSourceColumnTypes.ListBool:
    case DataSourceColumnTypes.ListDouble:
      return '[' + value.toString() + ']';
    default:
      return value.toString();
  }
}

export function isUnusedAssetKey(path: AssetPath, assetKeys: string[]): boolean {
  const key = assetPathToKey(path);

  return !!path.assetId && !assetKeys.includes(key);
}

/**
 * Converts the parts of the asset path into a unique key.\
 * CompanyId, bundleId, bundleVersion and assetId are mandatory, folderId is added optionally.\
 * Key is always lower case!
 */
export function assetPathToKey(path: AssetPath): string {
  let key = `${path.companyId}.${path.bundleId}.${path.bundleVersion}.`;

  if (path.folderId) {
    key += `${path.folderId}.`;
  }
  key += path.assetId;

  return key.toLowerCase();
}

export function getAssetParentFolderPath(path: AssetPath): AssetPath {
  const folderPath = { ...path, folderId: '', assetId: path.folderId };

  return folderPath;
}

/**
 * Compares asset paths up to bundle version (companyId, bundleId, bundleVersion)
 */
export function isAssetOfSameBundleVersion(path1: AssetPath, path2: AssetPath): boolean {
  const bundleVersionEqual =
    path1.companyId === path2.companyId &&
    path1.bundleId === path2.bundleId &&
    path1.bundleVersion === path2.bundleVersion;

  return bundleVersionEqual;
}

/**
 * Compares asset paths up to folder id (companyId, bundleId, bundleVersion, folderId)
 */
export function isAssetOfSameFolder(path1: AssetPath, path2: AssetPath): boolean {
  const bundleVersionEqual = isAssetOfSameBundleVersion(path1, path2);
  const folderEqual = path1.folderId === path2.folderId;

  return bundleVersionEqual && folderEqual;
}

/**
 * Compares full asset path, including assetId
 */
export function isAssetOfSamePath(path1: AssetPath, path2: AssetPath): boolean {
  const folderEqual = isAssetOfSameFolder(path1, path2);
  const assetIdEqual = path1.assetId === path2.assetId;

  return folderEqual && assetIdEqual;
}

export function createAssetUploadBag(
  files: File[],
  selectedAssetPath: AssetPath,
  assets: Assets,
  assetLinks: AssetLinks,
  preselectedFolderId: string,
  overwrittenAssetNames?: OverwrittenAssetNames,
  overwrittenFolderSelection?: OverwrittenFolders,
  overwrittenAssetType?: OverwrittenAssetTypes
): AssetFile[] {
  const uploadFileBag: AssetFile[] = files.map(file => {
    const assetName = overwrittenAssetNames?.[file.name] ?? generateValidHiveName(file.name, 'AtFirstDot');
    const folderName = overwrittenFolderSelection?.[file.name] ?? preselectedFolderId;
    const assetPath = { ...selectedAssetPath, folderId: folderName, assetId: assetName };

    const key = assetPathToKey(assetPath);
    const existingAssetToOverwrite: Asset | undefined = assets[key];

    const { assetType, assetTypeOptions } = _determineAssetTypeAndOptions(
      file.name,
      overwrittenAssetType?.[file.name],
      existingAssetToOverwrite?.type
    );

    const isValidHiveName = isValidHiveIdentifier(assetName);

    let validity: AssetFileValidity = 'valid';
    if (!isValidHiveName) {
      validity = 'error_invalidHiveIdentifier';
    } else if (existingAssetToOverwrite) {
      const isTypeChange = existingAssetToOverwrite.type !== assetType;
      const isLinkedDatasource = !!findFileLinkKeyOfDatasourceAsset(existingAssetToOverwrite, assetLinks);
      const wouldBreakLinks = _hasBreakingLinksOnTypeChange(file.name, assetType, existingAssetToOverwrite, assetLinks);

      validity = isFolderAsset(existingAssetToOverwrite)
        ? 'error_overwriteFolder'
        : isLinkedDatasource
          ? 'error_breaksDatasourceLink'
          : wouldBreakLinks
            ? 'error_breaksAssetLink'
            : isTypeChange
              ? 'warning_typechange'
              : 'info_overwrite';
    }

    return { file, assetName, folderName, assetType, assetTypeOptions, validity };
  });

  // Find duplicate asset names in file bag & tag them as such
  uploadFileBag.forEach((bag, bagIdx) => {
    const isDuplicateAssetName = uploadFileBag.some(
      (fileInBag, idx) =>
        idx !== bagIdx &&
        fileInBag.assetName.toLowerCase() === bag.assetName.toLowerCase() &&
        fileInBag.folderName.toLowerCase() === bag.folderName.toLowerCase()
    );

    if (isDuplicateAssetName) {
      // duplicate error has priority, but this should be fine as it is an error and should overrule any other state
      bag.validity = 'error_duplicate';
    }
  });

  return uploadFileBag;
}

/**
 * Determine all valid select options and the pre-defined type
 *
 * @param name name of the new asset
 * @param overwrittenType Manually selected type (overrules everything)
 * @param existingAssetType Type of the existing asset which would be overwritten
 * @returns
 */
function _determineAssetTypeAndOptions(
  name: string,
  overwrittenType?: AssetTypes,
  existingAssetType?: AssetTypes
): { assetType: AssetTypes; assetTypeOptions: SelectOption[] } {
  const { mainType, availableTypes } = _getAssetTypeSuggestionFromFilename(name);
  const assetTypeOptions: SelectOption[] = availableTypes.map(type => ({
    value: type,
    text: AssetTypes[type],
  }));

  const existingTypeIsAllowed = assetTypeOptions.some(o => o.value === existingAssetType);
  const typeFromExistingAsset = existingAssetType && existingTypeIsAllowed ? existingAssetType : mainType;
  const assetType = overwrittenType ?? typeFromExistingAsset;

  return { assetType, assetTypeOptions };
}

function _getAssetTypeSuggestionFromFilename(filename: string): AssetTypeSuggestion {
  const fileEnding = getExtensionFromAssetFilenameOrUrl(filename);

  // get asset types that are associated with this file ending
  const foundAssetTypes = Object.entries(ASSET_TYPE_FILE_ENDINGS).reduce<AssetTypeSelectionEntry[]>(
    (accAssetTypes, [curType, curFileEndings]) => {
      const foundEntry = curFileEndings.find(entry => entry.key === fileEnding);
      if (foundEntry) {
        accAssetTypes.push({ assetType: parseInt(curType), isMainType: foundEntry.isMainType });
      }

      return accAssetTypes;
    },
    []
  );

  const typeSuggestion: AssetTypeSuggestion = {
    mainType: AssetTypes.File,
    availableTypes: foundAssetTypes.map(type => type.assetType),
  };
  if (foundAssetTypes.length === 1) {
    typeSuggestion.mainType = foundAssetTypes[0].assetType;
  } else if (foundAssetTypes.length > 1) {
    // multiple asset types fit for this file ending, search for "isMainType" flag to evaluate the asset type with the
    // highest priority
    typeSuggestion.mainType = foundAssetTypes.reduce((accMainType, curType) =>
      curType.isMainType ? curType : accMainType
    ).assetType;
  }

  // "Text" and "File" assets should always be selectable, even if the file is binary
  if (!typeSuggestion.availableTypes.includes(AssetTypes.Text)) {
    typeSuggestion.availableTypes.push(AssetTypes.Text);
  }
  if (!typeSuggestion.availableTypes.includes(AssetTypes.File)) {
    typeSuggestion.availableTypes.push(AssetTypes.File);
  }

  return typeSuggestion;
}

export function findFileLinkKeyOfDatasourceAsset(asset: Asset, assetLinks: AssetLinks): string | undefined {
  if (!isDataSourceAsset(asset)) {
    return undefined;
  }

  const { folderId, assetId } = asset.path;
  const key = createSourceStringFromFolderAndAssetId(folderId, assetId);

  const linkedEntries = Object.entries(assetLinks).filter(([, link]) => {
    return link.some(l => l.type === AssetLinkTypes.FileAssetToExcelDataSource && l.target === key);
  });

  return linkedEntries.length === 1 ? linkedEntries[0][0] : undefined;
}

export function getAssetLinksOfAsset(asset: Asset, assetLinks: AssetLinks): AssetLink[] {
  const { folderId, assetId } = asset.path;
  const key = createSourceStringFromFolderAndAssetId(folderId, assetId);

  return assetLinks[key] ?? [];
}

/**
 * More general function to detect and prevent changes which would break links
 */
function _hasBreakingLinksOnTypeChange(
  filename: string,
  newAssetType: AssetTypes,
  existingAsset: Asset,
  assetLinks: AssetLinks
): boolean {
  const linksOfAsset = getAssetLinksOfAsset(existingAsset, assetLinks);
  const isLinkedExcelDatasource = linksOfAsset.some(l => l.type === AssetLinkTypes.FileAssetToExcelDataSource);
  if (isLinkedExcelDatasource) {
    // only allow changes between excel filetypes (e.g. xls -> xlsx)
    const newExtension = getExtensionFromAssetFilenameOrUrl(filename);
    if (newAssetType !== existingAsset.type || !EXCEL_DATASOURCE_FILE_ENDINGS.includes(newExtension)) {
      return true;
    }
  }

  return false;
}

export function isUploadInProgress(asset: Asset): boolean {
  return UPLOAD_IN_PROGRESS_STATES.includes(asset.uploadState);
}

/**
 * Implements sorting algorithm for assets.\
 * Folder assets are prioritized, afterwards they are sorted alphabetically.
 *
 * @param sortItems will be sorted in place
 */
export function sortAssets(sortItems: Asset[]): void {
  sortItems.sort((a, b) => {
    // use type predicates to extract the data from the correct properties
    const sortDataA = {
      isFolder: isFolderAsset(a),
      assetId: a.path.assetId,
    };
    const sortDataB = {
      isFolder: isFolderAsset(b),
      assetId: b.path.assetId,
    };

    if (sortDataA.isFolder && !sortDataB.isFolder) {
      return -1;
    } else if (!sortDataA.isFolder && sortDataB.isFolder) {
      return 1;
    } else {
      return sortDataA.assetId.localeCompare(sortDataB.assetId);
    }
  });
}

export function sortBundlesByLastEditedDesc(v1: AssetBundleVersion, v2: AssetBundleVersion): number {
  const v1SignificantTime = isAssetBundleVersionDraft(v1) ? v1.lastEditedAt : v1.publishedAt;
  const v2SignificantTime = isAssetBundleVersionDraft(v2) ? v2.lastEditedAt : v2.publishedAt;

  return v2SignificantTime - v1SignificantTime;
}

export function getExtensionFromAssetFilenameOrUrl(
  filename: string,
  strategy: FileExtensionGetterStrategy = 'AtLastDot'
): string {
  // get last part after '/' in case of an url
  const parts = filename.split('/');
  const baseName = parts[parts.length - 1];
  return getFileExtension(baseName, strategy).toLowerCase();
}

/**
 * Counterpart to {@link splitFolderAndAssetIdFromSourceString}
 * @returns in the format `'folderId.assetId'` if a folder exists
 */
export function createSourceStringFromFolderAndAssetId(folderId: string, assetId: string): string {
  const dottedPath = folderId ? `${folderId}.${assetId}` : assetId;

  return dottedPath;
}

/**
 * Counterpart to {@link createSourceStringFromFolderAndAssetId}
 * @param sourceString Expected format `folderId.assetId`
 */
export function splitFolderAndAssetIdFromSourceString(sourceString: string): { folderId: string; assetId: string } {
  const parts = sourceString.split('.');
  return parts.length > 1 ? { folderId: parts[0], assetId: parts[1] } : { folderId: '', assetId: sourceString };
}

const schemaPartIndices = { company: 0, bundle: 1, version: 2 } as const;

export function getSchemaNamePart(schemaName: string, part: keyof typeof schemaPartIndices): string {
  const parts = schemaName.split('.');
  if (parts.length !== 3) {
    return '';
  }

  return parts[schemaPartIndices[part]];
}

export const isListColumnType = (type: DataSourceColumnTypes): boolean =>
  type === DataSourceColumnTypes.ListBool ||
  type === DataSourceColumnTypes.ListDouble ||
  type === DataSourceColumnTypes.ListString;

export const getDefaultValueForDataSourceColumnType = (type: DataSourceColumnTypes): DataSourceColumnDefaultValue => {
  switch (type) {
    case DataSourceColumnTypes.Bool:
      return false;

    case DataSourceColumnTypes.Double:
      return 0;

    case DataSourceColumnTypes.String:
    case DataSourceColumnTypes.FileUrl:
    case DataSourceColumnTypes.LinkedAsset:
      return '';

    case DataSourceColumnTypes.ListBool:
    case DataSourceColumnTypes.ListDouble:
    case DataSourceColumnTypes.ListString:
      return [];
  }
};

/**
 * @param activeBundleVersions All versions to consider when generating the next version name.
 * @returns Next version name based on the existing versions ('v1', 'v2', ...).\
 *          'v1' if no versions exist yet or `activeBundleVersions` is not given.
 */
export const getNextAssetVersionDisplayName = (activeBundleVersions?: AssetBundleVersion[]): string => {
  // get version number from first group inside match
  // Example: "v12" -> first group = "12"
  const versionPattern = new RegExp(/^v(\d+)/);

  // Starts with v1 if a custom `displayName` exists (for backwards compatibility) or if no version exists yet
  const highestVersion = !activeBundleVersions?.length
    ? 0
    : activeBundleVersions.reduce((acc, version) => {
        const match = version.displayName.match(versionPattern);
        const foundVersion = match ? parseInt(match[1], 10) : 0;
        return foundVersion > acc ? foundVersion : acc;
      }, 0);

  return `v${highestVersion + 1}`;
};
