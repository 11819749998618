import { ReactElement, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BridgeIcon, ReplicatorCopyIcon, StopIcon } from 'assets/icons';
import { ConditionalWrapper } from 'components/common/conditional-wrapper';
import { ReplicationStateIcon } from 'components/replicator/replication-state-icon';
import { ReplicationStateIconPublish } from 'components/replicator/replication-state-icon-publish';
import { Button } from 'controls/button/button';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { Icon } from 'controls/icon/icon';
import { TableCell, TableRow } from 'controls/table';
import { TableSkeletonRows } from 'controls/table/table-skeleton-rows';
import { useInterval } from 'hooks/common/timing.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { postReplicatorCancel } from 'services/replicator/replicator.service';
import { buildAssetEditorPath } from 'services/routes/asset-editor-routes.service';
import { buildCfgrsPath } from 'services/routes/cfgrs-routes.service';
import { buildReplicatorPath } from 'services/routes/replicator-routes.service';
import {
  ReplicationNotification,
  markNotificationAsRead,
  updateReplicationStatus,
} from 'slices/notifications/notifications.slice';
import { loadBlueprintTargetMapping, selectCurrentBlueprint } from 'slices/replicator/replicator.slice';

type ReplicatorProgressTableRowProps = {
  notification: ReplicationNotification;
};

export const ReplicatorProgressTableRow: React.FC<ReplicatorProgressTableRowProps> = ({ notification }) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allPolledStatus = useAppSelector(state => state.notifications.cachedRequests.replications);
  const polledItem = allPolledStatus[notification.uid];

  const blueprint = useAppSelector(selectCurrentBlueprint);

  const pollingInterval = notification.updateState === 'POLLING' || !polledItem ? 2000 : null;
  const [isCancelling, setIsCancelling] = useState(false);

  useEffect(() => {
    if (!notification.markedAsRead) {
      dispatch(markNotificationAsRead(notification.uid));
    }
  }, [dispatch, notification.uid, notification.markedAsRead]);

  useInterval(() => {
    dispatch(updateReplicationStatus(notification.uid));
  }, pollingInterval);

  const cancelReplicationClicked = async (): Promise<void> => {
    setIsCancelling(true);
    await postReplicatorCancel(notification.replicationId, notification.targetCompany);
    dispatch(updateReplicationStatus(notification.uid));
  };

  const onNewTargetClicked = async (): Promise<void> => {
    await dispatch(loadBlueprintTargetMapping(''));
    navigate(buildReplicatorPath(blueprint.companyId, 'copy'));
  };

  if (!polledItem) {
    return <TableSkeletonRows numberOfRows={1} numberOfCols={7} allColsSkeletonProps={{ width: '50%' }} />;
  }

  const targetPath =
    notification.updateState !== 'FINISHED'
      ? undefined
      : Object.keys(notification.cfgrIds).length > 0
        ? buildCfgrsPath('dashboard', notification.targetCompany)
        : buildAssetEditorPath(notification.targetCompany);

  return (
    <TableRow key={notification.uid}>
      <TableCell>
        <div className="flex items-center gap-2 text-m-medium">
          <ConditionalWrapper
            condition={!!targetPath}
            wrapper={(children): ReactElement => (
              <Link to={targetPath ?? ''} className="text-primary-main">
                {children}
              </Link>
            )}
          >
            <span>{notification.targetCompany}</span>
          </ConditionalWrapper>
          {notification.isCrossEnv && <Icon Svg={BridgeIcon} className="w-8" title={t('Cross-Env')} />}
        </div>
      </TableCell>
      <TableCell>
        <RelativeDateTime unixTime={polledItem.startedAt} />
      </TableCell>
      <TableCell>
        <ul className="text-s-regular">{Object.values(notification.cfgrIds).join(', ')}</ul>
      </TableCell>
      <TableCell>
        <ul className="text-s-regular">{Object.values(notification.assetBundleIds).join(', ')}</ul>
      </TableCell>
      <TableCell>
        <ReplicationStateIcon status={polledItem} />
      </TableCell>
      <TableCell>
        <ReplicationStateIconPublish status={polledItem} hasAutoPublish={notification.hasAutoPublish} />
      </TableCell>
      <TableCell className="flex gap-2">
        {notification.updateState === 'POLLING' && !polledItem.isCancelled && (
          <Button
            Svg={StopIcon}
            onClick={cancelReplicationClicked}
            variant="Secondary"
            color="Danger"
            text={t('Cancel copy')}
            disabled={polledItem.isCancelled || isCancelling}
          />
        )}
        {notification.replicationId === blueprint.recentReplicationId && (
          <Button
            text={t('Copy to another target')}
            Svg={ReplicatorCopyIcon}
            variant="Secondary"
            onClick={onNewTargetClicked}
          />
        )}
      </TableCell>
    </TableRow>
  );
};
