import { ToggleButton as MuiToggleButton, styled, toggleButtonClasses } from '@mui/material';

const StyledTabNavButton = styled(MuiToggleButton)(({ theme }) => ({
  [`&.${toggleButtonClasses.root}`]: {
    backgroundColor: theme.palette.neutral[10],
    color: theme.palette.neutral[90],
    borderColor: theme.palette.neutral[50],
    borderRadius: theme.shape.borderRadiusRoundedLg,
    height: '32px',
    fontSize: theme.typography.mMedium.fontSize,
    fontWeight: theme.typography.mMedium.fontWeight,
    textWrap: 'nowrap',

    [`&:hover`]: {
      borderColor: theme.palette.primary.main,
    },

    [`&.${toggleButtonClasses.selected}`]: {
      backgroundColor: theme.palette.primary.surface,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
    },
    [`:not(&.${toggleButtonClasses.selected})`]: {
      margin: '1px',
    },
  },
}));

export type CbnTabNavigationValue = string | number;

export type CbnTabNavigationOption = {
  value: CbnTabNavigationValue;
  text?: string;
};

type CbnTabNavigationProps = {
  entries: CbnTabNavigationOption[];
  value?: CbnTabNavigationValue;
  onValueChange?: (value: CbnTabNavigationValue) => void;
};

/**
 * This UI element can/should be used for navigating between certain instances (eg: bundles).
 * Technically it's very similar to a toggle button group or the header of a tab panel.
 * However the look & feel is closer to `Chips`, whereas the color theme is taken from the button style factory
 */
export const CbnTabNavigation: React.FC<CbnTabNavigationProps> = ({ entries, value, onValueChange }) => {
  const onTabNavButtonClick = (event: React.MouseEvent<HTMLElement>, clickValue: CbnTabNavigationValue): void => {
    if (value !== clickValue) {
      onValueChange?.(clickValue);
    }
  };

  return (
    <div data-cmptype="CbnTabNavigation" className="flex flex-row items-center gap-3">
      {entries.map(entry => {
        const text = entry.text ?? entry.value.toString();

        return (
          <StyledTabNavButton
            key={entry.value}
            value={entry.value}
            aria-label={text}
            selected={value === entry.value}
            onClick={onTabNavButtonClick}
          >
            {text}
          </StyledTabNavButton>
        );
      })}
    </div>
  );
};
