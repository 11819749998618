import { Navigate, Outlet } from 'react-router-dom';
import { useCompanyFeature } from 'hooks/company/company-feature.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { CompanyFeatures } from 'services/companies/companies.service';
import { PATHS } from 'services/routes/paths.service';

type CompanyFeatureRouteProps = {
  feature: keyof CompanyFeatures;
  /**
   * Default is `Dashboard`
   */
  fallbackRoute?: string;
  fallbackCmp?: React.ReactNode;
};

export const CompanyFeatureRouteWrapper: React.FC<CompanyFeatureRouteProps> = ({
  feature,
  fallbackRoute = PATHS.configurators,
  fallbackCmp,
}) => {
  const companiesFetched = useAppSelector(state => state.companyData.companiesFetched);
  const featureActive = useCompanyFeature(feature);

  const fallback = fallbackCmp ? <>{fallbackCmp}</> : <Navigate to={fallbackRoute} />;

  // Don't redirect/navigate as long as the companies aren't fetched, which contain the permissions
  // Otherwise the redirect would happen too early
  return companiesFetched !== 'Fetched' ? <span>Loading...</span> : featureActive ? <Outlet /> : fallback;
};
