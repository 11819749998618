import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
  formControlLabelClasses,
  styled,
} from '@mui/material';

type FormControlLabelProps = Pick<
  MuiFormControlLabelProps,
  'control' | 'label' | 'labelPlacement' | 'value' | 'disabled' | 'className'
> & {
  'data-cmptype'?: string;
};

const StyledFormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  fontSize: theme.typography.mRegular.fontSize,
  color: theme.palette.neutral[90],
  margin: 0,

  [`&.Mui-disabled .${formControlLabelClasses.label}`]: {
    color: theme.palette.neutral[60],
  },
}));

export const FormControlLabel: React.FC<FormControlLabelProps> = ({ 'data-cmptype': dataCmpType, ...props }) => {
  return (
    <StyledFormControlLabel data-cmptype={'FormControlLabel' + (dataCmpType ? ` ${dataCmpType}` : '')} {...props} />
  );
};
