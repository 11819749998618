import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { flatten, unflatten } from 'flat';
import { cloneDeep } from 'lodash';
import { globalReset } from 'actions/general.actions';
import { MaterialEditorState } from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor';
import {
  MaterialGroups,
  MaterialPropertyKey,
  getAllPbrMaterialPropertyKeys,
} from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor-properties-def';
import { AssetLinkTypes } from 'generated/asset-link-types';
import { assetPathToKey, getAssetLinksOfAsset } from 'helper/assets/assets.helper';
import { MaterialObject, MaterialType } from 'services/3d/materials.service';
import { fetchAssetMaterialContent } from 'services/assets/assets.service';
import * as PersistenceMiddleware from 'services/store/persistence.middleware';
import { RootState } from 'services/store/store.service';
import { AssetLink, MaterialAsset } from 'slices/assets/assets.slice';

type Base64Cache = { [key: MaterialPropertyKey]: string };

const _BASE64_STRING_PLACEHOLDER = '-';

export type MaterialAssetState = {
  // state from server
  syncedMaterialData: MaterialObject;
  syncedLinkedTextures: AssetLink[];
  syncedMaterialType: MaterialType;
  // current state from UI
  curMaterialData: MaterialObject;
  curLinkedTextures: AssetLink[];
  base64Cache: Base64Cache;
  ui: {
    isLoading: boolean;
    isSaving: boolean;
    expandedMaterialGroups: MaterialGroups[];
    expandedTextureSettings: string[];
    searchValueMaterial: string;
    editorState: MaterialEditorState;
  };
};

const initialState: MaterialAssetState = {
  syncedMaterialData: {},
  syncedLinkedTextures: [],
  syncedMaterialType: 'BABYLON.PBRMaterial',
  curMaterialData: {},
  curLinkedTextures: [],
  base64Cache: {},
  ui: {
    isLoading: false,
    isSaving: false,
    expandedMaterialGroups: ['General', 'Channels'],
    expandedTextureSettings: [],
    searchValueMaterial: '',
    editorState: 'Valid',
  },
};

/**
 * Search for textures in the JSON object, remove them from the object and store them in a dedicated map
 */
function _extractBase64Strings(obj: MaterialObject): Base64Cache {
  const cache: Base64Cache = {};

  // TODO: this is hardcoded for PBR materials ATM, use different configuration, once asset links are supported for
  // other material types as well
  const textureKeys = getAllPbrMaterialPropertyKeys('BaseTexture');
  textureKeys.forEach(key => {
    const [baseKey, subKey] = key.split('.');

    const baseObj: MaterialObject & { base64String: string } = obj[baseKey];
    const subObj: { base64String: string } = subKey && baseObj?.[subKey];

    if (subObj?.base64String) {
      cache[key] = subObj.base64String;
      subObj.base64String = _BASE64_STRING_PLACEHOLDER;
    } else if (obj[baseKey]?.base64String) {
      cache[key] = obj[baseKey].base64String;
      baseObj.base64String = _BASE64_STRING_PLACEHOLDER;
    }
  });

  return cache;
}

export const getSyncedMaterialDataAndLinkedTextures = createAsyncThunk<
  { materialData: MaterialObject; materialType: MaterialType; assetLinks: AssetLink[]; base64Cache: Base64Cache },
  {
    companyId: string;
    bundleId: string;
    bundleVersion: string;
    folderId: string;
    assetId: string;
  }
>(
  'material-asset/getSyncedMaterialDataAndLinkedTextures',
  async ({ companyId, bundleId, bundleVersion, folderId, assetId }, thunkApi) => {
    const rawMatData = await fetchAssetMaterialContent(companyId, bundleId, bundleVersion, folderId, assetId);
    // crop base 64 strings to reduce amount of data in store
    // also the monaco editor would crash if that large amount of (unnecessary) data gets loaded
    const croppedMatData = cloneDeep(rawMatData);
    const base64Cache = _extractBase64Strings(croppedMatData);

    // get assets links (texture => image asset) as well
    const state = thunkApi.getState() as RootState;
    const assetKey = assetPathToKey({ companyId, bundleId, bundleVersion, folderId, assetId });
    const asset = state.assets.assets[assetKey] as MaterialAsset;
    const assetLinks = getAssetLinksOfAsset(asset, state.assets.assetLinks);

    return { materialData: croppedMatData, materialType: asset.materialType, assetLinks, base64Cache };
  }
);

const SLICE_NAME = 'materialAsset';

PersistenceMiddleware.registerState<MaterialAssetState, MaterialAssetState['ui']>({
  state: SLICE_NAME,
  key: 'ui',
  selector: state => state.ui,
  props: ['expandedMaterialGroups', 'expandedTextureSettings'],
});

const getRehydratedState = (): MaterialAssetState => {
  const rehydratedState = PersistenceMiddleware.rehydrateState<MaterialAssetState>(SLICE_NAME, initialState);
  return rehydratedState;
};

const materialAssetSlice = createSlice({
  name: SLICE_NAME,
  initialState: getRehydratedState,
  reducers: {
    clearMaterialAssetState: () => initialState,
    setMaterialAssetUIState: (state, { payload }: PayloadAction<Partial<MaterialAssetState['ui']>>) => {
      state.ui = { ...state.ui, ...payload };
    },
    toggleExpandedMaterialGroup: (state, { payload }: PayloadAction<MaterialGroups>) => {
      const groups = state.ui.expandedMaterialGroups;
      state.ui.expandedMaterialGroups = groups.includes(payload)
        ? groups.filter(g => g !== payload)
        : [...groups, payload];
    },
    toggleExpandedTextureSetting: (state, { payload }: PayloadAction<string>) => {
      const settings = state.ui.expandedTextureSettings;
      state.ui.expandedTextureSettings = settings.includes(payload)
        ? settings.filter(g => g !== payload)
        : [...settings, payload];
    },
    collapseTextureSetting: (state, { payload }: PayloadAction<string>) => {
      state.ui.expandedTextureSettings = state.ui.expandedTextureSettings.filter(g => g !== payload);
    },
    changeMaterialData: (state, { payload }: PayloadAction<MaterialObject>) => {
      state.curMaterialData = payload;
    },
    changeMaterialProperty: (
      state,
      { payload: { key, value } }: PayloadAction<{ key: MaterialPropertyKey; value: any }>
    ) => {
      // creates a flat list of all entries in the object
      let entries = Object.entries(flatten(state.curMaterialData) as object);

      const removeProperty = value === undefined || value === null;
      if (removeProperty) {
        entries = entries.filter(([entryKey]) => entryKey !== key && !entryKey.startsWith(`${key}.`));
      } else {
        // last entry has priority, so we don't have to check if the key is already available
        entries.push([key, value]);
      }

      const newObj: MaterialObject = unflatten(Object.fromEntries(entries));
      state.curMaterialData = newObj;
    },
    changeLinkedTexture: (
      state,
      { payload: { key, assetPath } }: PayloadAction<{ key: MaterialPropertyKey; assetPath: string }>
    ) => {
      const updatedTextures = [...state.curLinkedTextures];
      const validAssetPath = assetPath.length > 0;

      const idx = updatedTextures.findIndex(linkedTex => linkedTex.texture === key);
      if (idx > -1) {
        if (validAssetPath) {
          updatedTextures[idx].target = assetPath;
        } else {
          updatedTextures.splice(idx, 1);
        }
      } else if (validAssetPath) {
        updatedTextures.push({ texture: key, target: assetPath, type: AssetLinkTypes.MaterialToImage });
      }

      state.curLinkedTextures = updatedTextures;
    },
    applyMaterialDataAndLinkedTexturesChanges: state => {
      state.syncedMaterialData = cloneDeep(state.curMaterialData);
      state.syncedLinkedTextures = [...state.curLinkedTextures];
    },
    resetMaterialDataAndLinkedTexturesChanges: state => {
      state.curMaterialData = cloneDeep(state.syncedMaterialData);
      state.curLinkedTextures = [...state.syncedLinkedTextures];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(globalReset, () => initialState)
      .addCase(getSyncedMaterialDataAndLinkedTextures.pending, state => {
        state.ui.isLoading = true;
      })
      .addCase(getSyncedMaterialDataAndLinkedTextures.fulfilled, (state, { payload }) => {
        state.syncedMaterialData = payload.materialData;
        state.syncedLinkedTextures = payload.assetLinks;
        state.syncedMaterialType = payload.materialType;
        state.curMaterialData = cloneDeep(state.syncedMaterialData);
        state.curLinkedTextures = [...state.syncedLinkedTextures];
        state.base64Cache = payload.base64Cache;

        state.ui.isLoading = false;
      });
  },
});

export const selectHasMaterialDataChanges = createSelector(
  (state: RootState) => state.materialAsset,
  materialAsset => {
    const { syncedMaterialData, curMaterialData } = materialAsset;
    const hasChanges = JSON.stringify(syncedMaterialData) !== JSON.stringify(curMaterialData);

    return hasChanges;
  }
);

export const selectHasLinkedTexturesChanges = createSelector(
  (state: RootState) => state.materialAsset,
  materialAsset => {
    const { syncedLinkedTextures, curLinkedTextures } = materialAsset;

    if (syncedLinkedTextures.length !== curLinkedTextures.length) {
      return true;
    } else if (syncedLinkedTextures.length === 0) {
      return false;
    }

    const matchingTextures = syncedLinkedTextures.every(value => {
      const foundItem = curLinkedTextures.find(a => a.texture === value.texture);
      return foundItem && foundItem.target === value.target;
    });

    return !matchingTextures;
  }
);

export const selectGetChangedLinkedTextures = createSelector(
  (state: RootState) => state.materialAsset,
  materialAsset => {
    const { syncedLinkedTextures, curLinkedTextures } = materialAsset;

    const texturesToLink = curLinkedTextures.filter(curLink => {
      const foundOriginal = syncedLinkedTextures.find(l => l.texture === curLink.texture);
      const matchingTarget = foundOriginal && foundOriginal.target === curLink.target;

      return !foundOriginal || !matchingTarget;
    });

    const texturesToUnlink = syncedLinkedTextures.filter(
      curOriginalLink => !curLinkedTextures.some(curChangedLink => curOriginalLink.texture === curChangedLink.texture)
    );

    // this format is already compatible to the `postAssetLinksBatchLinkMaterialFromImage` call
    return { texturesToLink, texturesToUnlink };
  }
);

export const selectGetCurMaterialDataWithBase64Strings = createSelector(
  (state: RootState) => state.materialAsset,
  materialAsset => {
    const materialWithBase64 = cloneDeep(materialAsset.curMaterialData);

    Object.entries(materialAsset.base64Cache).forEach(([key, storedVal]) => {
      const [baseKey, subKey] = key.split('.');

      const baseObj: MaterialObject & { base64String: string } = materialWithBase64[baseKey];
      const subObj: { base64String: string } = subKey && baseObj?.[subKey];

      // only restore if the value has not been changed by the user
      // this way, the user can still remove or change the base64 string in the editor
      if (subObj?.base64String === _BASE64_STRING_PLACEHOLDER) {
        subObj.base64String = storedVal;
      } else if (baseObj?.base64String === _BASE64_STRING_PLACEHOLDER) {
        baseObj.base64String = storedVal;
      }
    });

    return materialWithBase64;
  }
);

export const {
  clearMaterialAssetState,
  setMaterialAssetUIState,
  toggleExpandedMaterialGroup,
  toggleExpandedTextureSetting,
  collapseTextureSetting,
  changeMaterialData,
  changeMaterialProperty,
  changeLinkedTexture,
  applyMaterialDataAndLinkedTexturesChanges,
  resetMaterialDataAndLinkedTexturesChanges,
} = materialAssetSlice.actions;

export default materialAssetSlice.reducer;
