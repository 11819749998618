import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

export const CreateCfgrTemplatePickerHeader: React.FC = () => {
  const { t } = useTypedTranslation();

  return (
    <CbnCardHeader
      title={t('Create a new configurator')}
      subText={t('Select template to start with or create a configurator from scratch.')}
      variant="large-title"
    />
  );
};
