import { ReactElement, useCallback, useRef } from 'react';
import {
  NULL_VALUE,
  TexturePropertyKey,
  getValueByMaterialPropertyKey,
} from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor-properties-def';
import { ConditionalWrapper } from 'components/common/conditional-wrapper';
import { Checkbox } from 'controls/checkbox/checkbox';
import { FormControlLabel } from 'controls/form/form-control-label';
import { NumberInput, NumberOrNull } from 'controls/input/number-input';
import { Select, SelectOption, SelectValue } from 'controls/select/select';
import { Tooltip } from 'controls/tooltip/tooltip';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { cn } from 'helper/css.helper';
import { TextureObject } from 'services/3d/materials.service';

type TexturePropBaseInputProps = {
  inputObj: TextureObject;
  objKey: TexturePropertyKey;
  disabled: boolean;
  displayName: string;
  info?: string;
};
type TexturePropBooleanInputProps = TexturePropBaseInputProps & {
  defaultValue: boolean;
  onChange: (key: TexturePropertyKey, value: boolean) => void;
};
type TexturePropNumberInputProps = TexturePropBaseInputProps & {
  defaultValue?: number;
  onChange: (key: TexturePropertyKey, value: number | undefined) => void;
};
type TexturePropSelectInputProps = TexturePropBaseInputProps & {
  defaultValue: number;
  options: SelectOption[];
  onChange: (key: TexturePropertyKey, value: number | undefined) => void;
};

/**
 * Input component for boolean properties with a checkbox
 */
export const TexturePropBooleanInput: React.FC<TexturePropBooleanInputProps> = ({
  inputObj,
  objKey,
  disabled,
  displayName,
  defaultValue,
  info,
  onChange,
}) => {
  const currentValue = getValueByMaterialPropertyKey<boolean>(inputObj, objKey);

  return (
    <TexturePropTooltip displayName={displayName} title={info}>
      <div data-cmptype="TexturePropBooleanInput" className="w-fit">
        <FormControlLabel
          className="flex h-8 w-full items-center justify-end gap-4"
          labelPlacement="start"
          label={<TexturePropLabel displayName={displayName} disabled={disabled} />}
          control={
            <Checkbox
              checked={currentValue ?? defaultValue}
              onValueChanged={(value: boolean): void => onChange(objKey, value)}
              disabled={disabled}
            />
          }
        ></FormControlLabel>
      </div>
    </TexturePropTooltip>
  );
};

/**
 * Input component for number properties with a html input element
 */
export const TexturePropNumberInput: React.FC<TexturePropNumberInputProps> = ({
  inputObj,
  objKey,
  disabled,
  displayName,
  defaultValue,
  info,
  onChange,
}) => {
  const isEditing = useRef(false);
  const propValue = getValueByMaterialPropertyKey<number>(inputObj, objKey);
  const displayedValue = isEditing.current ? propValue : propValue ?? defaultValue;

  const onChangeInput = useCallback(
    (valueAsNumber: NumberOrNull, isInvalid: boolean): void => {
      if (!isInvalid) {
        isEditing.current = true;
        onChange(objKey, valueAsNumber ?? undefined);
      }
    },
    [objKey, onChange]
  );

  const onBlur = (): void => {
    if (!isEditing.current) {
      return;
    }

    if (defaultValue !== undefined && propValue === undefined) {
      onChange(objKey, defaultValue);
    }
    isEditing.current = false;
  };

  return (
    <TexturePropTooltip displayName={displayName} title={info}>
      <div data-cmptype="TexturePropNumberInput">
        <FormControlLabel
          className="flex h-8 w-full items-center justify-end gap-4"
          labelPlacement="start"
          label={<TexturePropLabel displayName={displayName} disabled={disabled} />}
          control={
            <NumberInput
              inputMode="decimal"
              value={displayedValue}
              onChange={onChangeInput}
              onBlur={onBlur}
              restoreValidValueOnBlur
              disabled={disabled}
            />
          }
        ></FormControlLabel>
      </div>
    </TexturePropTooltip>
  );
};

export const TexturePropSelectInput: React.FC<TexturePropSelectInputProps> = ({
  inputObj,
  objKey,
  disabled,
  displayName,
  defaultValue,
  options,
  info,
  onChange,
}) => {
  const currentValue = getValueByMaterialPropertyKey<number>(inputObj, objKey);

  const onInputChange = (value: SelectValue): void => {
    // exchange "NULL_VALUE" with undefined to remove the value from the object
    const nullableValue = value === NULL_VALUE ? undefined : (value as number);
    onChange(objKey, nullableValue);
  };

  return (
    <TexturePropTooltip displayName={displayName} title={info}>
      <div data-cmptype="TexturePropSelectInput" className="flex h-8 w-full items-center justify-end gap-4">
        <TexturePropLabel displayName={displayName} disabled={disabled} />
        <Select options={options} value={currentValue ?? defaultValue} onChange={onInputChange} disabled={disabled} />
      </div>
    </TexturePropTooltip>
  );
};

type TexturePropTooltipProps = {
  displayName: string;
  title?: string;
  children: ReactElement;
};

const TexturePropTooltip: React.FC<TexturePropTooltipProps> = ({ displayName, title, children }) => {
  return (
    <ConditionalWrapper
      condition={!!title}
      wrapper={(wrapperChildren): ReactElement => (
        <Tooltip title={<TooltipContent header={displayName} detail={title} />} arrow placement="bottom-start">
          {wrapperChildren}
        </Tooltip>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};

type TexturePropLabelProps = {
  displayName: string;
  disabled?: boolean;
};

const TexturePropLabel: React.FC<TexturePropLabelProps> = ({ displayName, disabled }) => {
  return (
    <span className={cn('flex min-w-[80px] whitespace-nowrap text-m-regular', disabled && 'text-neutral-60')}>
      {displayName}
    </span>
  );
};
