import { PopoverProps } from '@mui/material';
import { useRef, useState } from 'react';
import { PlusIcon } from 'assets/icons';
import {
  CfgnInsightsFilterInputProps,
  NoSelectionChipValue,
} from 'components/cfgrs/cfgn-insights/cfgn-insights-filter-input';
import { Button } from 'controls/button/button';
import { CbnPopover } from 'controls/cbn-popover/cbn-popover';
import { ChipValue, LargeFontChip } from 'controls/chip/chip';
import { TextInput } from 'controls/input/text-input';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type StringFilterInputProps = CfgnInsightsFilterInputProps<string[]> & {
  /** Text which will be shown as placeholder on the input field in the "add new option" dialog */
  subjectName: string;
};

/**
 * All resulting values in the `string[]` will be queried with an `OR` operation.
 * See docs of `CfgnInsightsListQueryValue` for more details.
 */
export const StringFilterInput: React.FC<StringFilterInputProps> = ({ value, onCommit, subjectName }) => {
  const { t } = useTypedTranslation();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const openPopoverBtnRef = useRef<HTMLDivElement>(null);

  const [quickOptions, setQuickOptions] = useState(value);

  const onChipClicked = (x: ChipValue): void => {
    const updatedValue =
      x === NoSelectionChipValue ? [] : value.includes(x as string) ? value.filter(y => y !== x) : [...value, x];

    onCommit(updatedValue as string[]);
  };

  const addOption = (newValue: string): void => {
    const newValues = value.includes(newValue) ? value : [...value, newValue];
    onCommit(newValues);

    const alreadyHasQuickOption = quickOptions.includes(newValue);
    if (!alreadyHasQuickOption) {
      setQuickOptions([...quickOptions, newValue]);
    }
  };

  return (
    <div className="flex flex-wrap items-center gap-3">
      <AddOptionPopover
        subjectName={subjectName}
        onSubmit={addOption}
        open={popoverOpen}
        onClose={(): void => setPopoverOpen(false)}
        anchorEl={openPopoverBtnRef.current}
      />

      <LargeFontChip
        value={NoSelectionChipValue}
        text={t('Any')}
        variant="Primary"
        selected={!value.length}
        onClick={onChipClicked}
      />
      <span className="self-stretch border-r border-neutral-50" />

      <div ref={openPopoverBtnRef}>
        <Button
          variant="ChipMenu"
          // TODO: Simply making the subject lower case surely won't work in all languages.
          //       Consider when implementing actual i18n...
          text={t(`Select ${subjectName.toLowerCase()}...`)}
          Svg={PlusIcon}
          onClick={(): void => setPopoverOpen(x => !x)}
        />
      </div>

      {quickOptions.map(x => {
        return (
          <LargeFontChip
            key={x}
            value={x}
            text={x}
            variant="Primary"
            selected={value.includes(x)}
            onClick={onChipClicked}
          />
        );
      })}
    </div>
  );
};

type AddOptionPopoverProps = Omit<PopoverProps, 'onSubmit' | 'onClose'> & {
  onClose?: () => void;
  subjectName: string;
  onSubmit: (value: string) => void;
};

const AddOptionPopover: React.FC<AddOptionPopoverProps> = ({ open, onClose, anchorEl, subjectName, onSubmit }) => {
  const { t } = useTypedTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const onSubmitForm = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    const value = inputRef.current?.value;
    if (value) {
      onSubmit(value);
      onClose?.();
    }
  };

  return (
    <CbnPopover
      data-cmptype="OptionsPopover"
      disableRestoreFocus
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <form className="flex gap-2 px-2 py-3" onSubmit={onSubmitForm}>
        <TextInput inputRef={inputRef} autoFocus autoComplete="off" placeholder={subjectName} />

        <Button type="submit" text={t('Add')} Svg={PlusIcon} />
      </form>
    </CbnPopover>
  );
};
