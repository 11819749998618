import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { CbnLoadingSpinner } from 'controls/cbn-load-mask/cbn-loading-spinner/cbn-loading-spinner';
import { cn, tw } from 'helper/css.helper';

type CbnLoadMaskProps = {
  active: boolean;
  spinner?: boolean;
  text?: string;
  small?: boolean;
  transparent?: true | false | 'semi';
  disableFadeIn?: boolean;
};

export const CbnLoadMask: React.FC<CbnLoadMaskProps> = ({
  active,
  spinner,
  text,
  small,
  transparent,
  disableFadeIn,
}) => {
  const [fadeInPending, setFadeInPending] = useState(!disableFadeIn);
  const opacityCls = fadeInPending ? tw`opacity-0` : tw`opacity-100`;
  const bgCls =
    transparent === 'semi'
      ? tw`bg-[#0000007f] shadow-[0_0_2px_4px_#0000007f]`
      : transparent === true
        ? tw`bg-transparent`
        : tw`bg-neutral-100`;

  useEffect(
    function doAnimate() {
      if (!disableFadeIn) {
        setTimeout(() => {
          setFadeInPending(!active);
        }, 0);
      }
    },
    [active, disableFadeIn]
  );

  return (
    <div
      data-cmptype="CbnLoadMask"
      role="dialog"
      className={cn(
        'absolute inset-0 flex flex-col items-center justify-center gap-2 transition-opacity duration-500',
        !active && 'hidden',
        opacityCls,
        bgCls
      )}
    >
      {spinner && <CbnLoadingSpinner small={small} />}
      {text?.length && (
        <span
          className={classNames('text-neutral-10', {
            'text-heading-m': !small,
            'text-heading-s': small,
          })}
        >
          {text}
        </span>
      )}
    </div>
  );
};
