import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SelectOption } from 'controls/select/select';
import { AssetTypes } from 'generated/asset-types';
import {
  AssetFile,
  UploadStats,
  assetPathToKey,
  getExtensionFromAssetFilenameOrUrl,
  sortBundlesByLastEditedDesc,
} from 'helper/assets/assets.helper';
import { getFileExtension } from 'helper/file/file.helper';
import { useHasPendingGlobalRouteChange } from 'hooks/app/route.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import {
  AssetsCompleteResponse,
  CfgrsToUpdateAfterPublish,
  CompleteAssetDto,
  FailedAssetsInfo,
  createAssetUploadUris,
  publishAssetBundleDraft,
  uploadAssetFile,
} from 'services/assets/assets.service';
import { ASSETS_BASE_PATH, AssetEditorRouteParams } from 'services/routes/asset-editor-routes.service';
import { PATHS } from 'services/routes/paths.service';
import {
  Asset,
  AssetPath,
  AssetUploadState,
  addLocalAssets,
  createFolderAsset,
  finishAssetUpload,
  getAssetBundles,
  getBundleVersionData,
  getRelevantAssetBundleVersions,
  isFolderAsset,
  selectAllBundles,
  selectAssetKeys,
  selectAssetsInSelectedBasePath,
  selectRelevantBundleVersions,
  selectSelectedAssetPath,
  selectSelectedBundleVersionIsDraft,
  setAssetUploadState,
  setSelection,
  setShowManageBundleVersionsPage,
} from 'slices/assets/assets.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

interface AssetEditorNavigateFunction {
  (assetPath: Partial<AssetPath>, replace?: boolean): void;
}

interface UploadAssetsFunction {
  (uploadFileBag: AssetFile[]): Promise<AssetsCompleteResponse>;
}
interface CreateFolderFunction {
  (folderName: string): Promise<AssetsCompleteResponse>;
}
interface PublishAssetBundleFunction {
  (
    selectedCompanyId: string,
    selectedBundleId: string,
    selectedBundleVersion: string,
    cfgrsToUpdate: CfgrsToUpdateAfterPublish
  ): Promise<string[]>;
}

export type AssetStats = {
  /**
   * The stats of a folder are based on the childs.
   * Folders don't have their own stat (although this could change in the future)
   */
  isAggregatedStatOfChilds: boolean;
  hasErrors: boolean;
  hasWarnings: boolean;
  hasHints: boolean;
  uploadStats: UploadStats;
};

/** Add "No folder" option to put the new asset into the root of the bundle */
export const NO_FOLDER_KEY = '';
export const NEW_FOLDER_KEY = '__newfolder__';

// enable this flag for advanced debugging console entries for the `useAssetEditorRouteSync` hook
const _SHOW_ASSET_EDITOR_SYNC_EVENT_LOG = false;
function _logAssetEditorSyncEvent(type: 'Fetch' | 'Select' | 'Deselect' | 'Navigate', message: string): void {
  if (_SHOW_ASSET_EDITOR_SYNC_EVENT_LOG && process.env.NODE_ENV === 'development') {
    const fullMessage = `${type} ==> ${message}`;
    console.log(fullMessage);
  }
}

/**
 * Synchronizes assets slice according to route.
 * Makes sure that correct bundles, bundle versions and assets are fetched and selected.
 * Detailed description in the code itself.
 */
export const useAssetEditorRouteSync = (): void => {
  const dispatch = useAppDispatch();
  const navigateToAssetEditor = useNavigateToAssetEditor();
  const hasPendingRouteChange = useHasPendingGlobalRouteChange();

  // create help variables for faster access in the hook logic
  const {
    companyId: routeCompanyId,
    bundleId: routeBundleId,
    bundleVersion: routeVersionId,
    folderId: routeFolderId,
    assetId: routeAssetId,
  } = useParams<AssetEditorRouteParams>();
  const {
    companyId: selCompanyId,
    bundleId: selBundleId,
    bundleVersion: selVersionId,
    folderId: selFolderId,
    assetId: selAssetId,
  } = useAppSelector(selectSelectedAssetPath);

  const bundles = useAppSelector(selectAllBundles);
  const availableBundleIds = [...bundles].sort((a, b) => a.name.localeCompare(b.name)).map(bundle => bundle.id);
  const bundleVersions = useAppSelector(selectRelevantBundleVersions);
  const availableBundleVersionIds = bundleVersions.sort(sortBundlesByLastEditedDesc).map(version => version.id);
  const availableAssetKeys = useAppSelector(selectAssetKeys);

  const bundlesUpToDateRef = useRef(false);
  const bundlesVersionsUpToDateRef = useRef(false);
  const assetsUpToDateRef = useRef(false);

  const companySelInSync = !hasPendingRouteChange;
  const bundleSelInSync = routeBundleId === selBundleId;
  const bundleVersionSelInSync = routeVersionId === selVersionId;
  const assetSelInSync = routeFolderId === selFolderId && routeAssetId === selAssetId;

  // unfortunately we can't rely on the assets slices "fetch" state to indicate loading scenarios, as the selector
  // returns the state one cycle later
  // we use fast `useRef` variables instead for fast updates
  useEffect(() => {
    // if bundles get updated we know that they are up-to-date again
    bundlesUpToDateRef.current = true;
  }, [bundles]);

  useEffect(() => {
    bundlesVersionsUpToDateRef.current = true;
  }, [bundleVersions]);

  useEffect(() => {
    assetsUpToDateRef.current = true;
  }, [availableAssetKeys]);

  // executed as soon as company selection becomes synced (company from route = company from store)
  // fetches bundles from this company
  useEffect(
    function switchCompany() {
      if (companySelInSync) {
        _logAssetEditorSyncEvent('Fetch', 'asset bundles');
        // invalidate bundles to immediately invalidate `selectBundle` effect (right below)
        // this hook should only be executed when fetching bundles is finished
        bundlesUpToDateRef.current = false;
        dispatch(getAssetBundles({ companyId: selCompanyId }));
      }
    },
    [dispatch, companySelInSync, selCompanyId]
  );

  // executed if new bundles got fetched
  // select bundle from route if necessary
  useEffect(
    function selectBundle() {
      if (companySelInSync && !bundleSelInSync && bundlesUpToDateRef.current) {
        const routeBundleAvailable = availableBundleIds.find(id => id.toUpperCase() === routeBundleId?.toUpperCase());
        if (routeBundleAvailable) {
          // requested bundle available in store => just select this one
          _logAssetEditorSyncEvent('Select', 'bundle from route');
          dispatch(setSelection({ bundleId: routeBundleId }));
          dispatch(setShowManageBundleVersionsPage(false));
        } else if (availableBundleIds.length) {
          // requested bundle not available (invalid route) => navigate to first bundle if there is one
          _logAssetEditorSyncEvent('Navigate', 'to fallback bundle as no bundle is requested from route');
          navigateToAssetEditor({ companyId: routeCompanyId, bundleId: availableBundleIds[0] });
        }
      }
    },
    [
      dispatch,
      navigateToAssetEditor,
      companySelInSync,
      bundleSelInSync,
      routeCompanyId,
      routeBundleId,
      availableBundleIds,
    ]
  );

  useEffect(
    function switchBundle() {
      if (companySelInSync && bundleSelInSync) {
        _logAssetEditorSyncEvent('Fetch', 'asset bundle versions');

        bundlesVersionsUpToDateRef.current = false;
        dispatch(getRelevantAssetBundleVersions({ companyId: selCompanyId, bundleId: selBundleId }));
      }
    },
    [dispatch, companySelInSync, bundleSelInSync, selCompanyId, selBundleId]
  );

  useEffect(() => {
    async function selectBundleVersion(): Promise<void> {
      if (companySelInSync && bundleSelInSync && !bundleVersionSelInSync && bundlesVersionsUpToDateRef.current) {
        const routeBundleVersionAvailable = availableBundleVersionIds.find(
          version => version.toUpperCase() === routeVersionId?.toUpperCase()
        );
        if (routeBundleVersionAvailable) {
          _logAssetEditorSyncEvent('Select', 'bundle version from route');
          dispatch(setSelection({ bundleVersion: routeVersionId }));
        } else if (routeCompanyId && routeBundleId && routeVersionId) {
          // bundle version not available, try to fetch it directly as it probably just was not included in the
          // "relevant bundles" call
          try {
            _logAssetEditorSyncEvent('Fetch', 'bundle version data (assets) & info');
            bundlesVersionsUpToDateRef.current = false;
            await dispatch(
              getBundleVersionData({
                companyId: routeCompanyId,
                bundleId: routeBundleId,
                bundleVersion: routeVersionId,
              })
            ).unwrap();
          } catch (e) {
            // bundle version also not available from direct call, navigate away from this route
            _logAssetEditorSyncEvent('Navigate', 'away from requested bundle version as it is not available');
            navigateToAssetEditor({
              companyId: routeCompanyId,
              bundleId: routeBundleId,
              bundleVersion: availableBundleVersionIds[0] ?? '',
            });
          } finally {
            bundlesVersionsUpToDateRef.current = true;
          }
        } else if (availableBundleVersionIds.length) {
          _logAssetEditorSyncEvent('Navigate', 'to fallback bundle version as no version is requested from route');
          navigateToAssetEditor({
            companyId: routeCompanyId,
            bundleId: routeBundleId,
            bundleVersion: availableBundleVersionIds[0],
          });
        }
      }
    }
    selectBundleVersion();
  }, [
    dispatch,
    navigateToAssetEditor,
    companySelInSync,
    bundleSelInSync,
    bundleVersionSelInSync,
    routeCompanyId,
    routeBundleId,
    routeVersionId,
    availableBundleVersionIds,
  ]);

  useEffect(
    function switchBundleVersion() {
      if (companySelInSync && bundleSelInSync && bundleVersionSelInSync) {
        _logAssetEditorSyncEvent('Fetch', 'assets');
        assetsUpToDateRef.current = false;
        dispatch(getBundleVersionData({ companyId: selCompanyId, bundleId: selBundleId, bundleVersion: selVersionId }));
      }
    },
    [dispatch, companySelInSync, bundleSelInSync, bundleVersionSelInSync, selCompanyId, selBundleId, selVersionId]
  );

  useEffect(
    function selectAsset() {
      if (
        companySelInSync &&
        bundleSelInSync &&
        bundleVersionSelInSync &&
        !assetSelInSync &&
        assetsUpToDateRef.current
      ) {
        const assetKey = assetPathToKey({
          companyId: routeCompanyId ?? '',
          bundleId: routeBundleId ?? '',
          bundleVersion: routeVersionId ?? '',
          folderId: routeFolderId ?? '',
          assetId: routeAssetId ?? '',
        });
        const assetAvailable = availableAssetKeys.includes(assetKey);

        if (assetAvailable) {
          _logAssetEditorSyncEvent('Select', 'asset and folder from route');
          dispatch(setSelection({ assetId: routeAssetId, folderId: routeFolderId }));
        } else if (routeAssetId && !assetAvailable) {
          _logAssetEditorSyncEvent('Navigate', 'to base route as asset is not available');
          navigateToAssetEditor({
            companyId: routeCompanyId,
            bundleId: routeBundleId,
            bundleVersion: routeVersionId,
          });
        } else if (!routeAssetId && selAssetId) {
          _logAssetEditorSyncEvent('Deselect', 'asset from route');
          dispatch(setSelection({ assetId: '', folderId: '' }));
        }
      }
    },
    [
      dispatch,
      navigateToAssetEditor,
      companySelInSync,
      bundleSelInSync,
      bundleVersionSelInSync,
      assetSelInSync,
      routeCompanyId,
      routeBundleId,
      routeVersionId,
      routeFolderId,
      routeAssetId,
      selAssetId,
      availableAssetKeys,
    ]
  );
};

/**
 * @returns A route pointing to the asset defined via `selectedPath` in the assets store
 */
export const useAssetEditorRouteToStateSelection = (): string => {
  const { pathname } = useLocation();

  const companyId = useAppSelector(state => state.companyData.selection.companyId);
  const { bundleId, bundleVersion, folderId, assetId } = useAppSelector(selectSelectedAssetPath);

  const [lastValidAssetEditorPath, setLastValidAssetEditorPath] = useState(PATHS.buildAssetEditorPath(companyId));
  const lastValidAssetEditorCompanyRef = useRef(companyId);

  useEffect(() => {
    if (pathname.startsWith(ASSETS_BASE_PATH)) {
      // only update the path if we are inside the asset editor as slice state will be reset on unmount
      const editorPath = PATHS.buildAssetEditorPath(companyId, bundleId, bundleVersion, folderId, assetId);
      setLastValidAssetEditorPath(editorPath);
      lastValidAssetEditorCompanyRef.current = companyId;
    } else if (companyId !== lastValidAssetEditorCompanyRef.current) {
      // company switched, stored asset editor route is not valid anymore
      // just move to the root route of the new selected company
      const editorPath = PATHS.buildAssetEditorPath(companyId);
      setLastValidAssetEditorPath(editorPath);
      lastValidAssetEditorCompanyRef.current = companyId;
    }
  }, [companyId, bundleId, bundleVersion, folderId, assetId, pathname]);

  return lastValidAssetEditorPath;
};

/**
 * This hook returns a helper function for easier navigation in the asset editor
 */
export const useNavigateToAssetEditor = (): AssetEditorNavigateFunction => {
  const navigate = useNavigate();

  /**
   * @param assetPath
   * @param replace True to replace the current entry in the history stack instead of adding a new one.
   *                I.e. remove the current route from the stack.
   */
  const customNav: AssetEditorNavigateFunction = (assetPath, replace = false) => {
    const path = PATHS.buildAssetEditorPath(
      assetPath.companyId || '',
      assetPath.bundleId,
      assetPath.bundleVersion,
      assetPath.folderId,
      assetPath.assetId
    );

    navigate(path, { replace });
  };

  return customNav;
};

/**
 * Provides helper functions for uploading the asset files.
 */
export const useUploadAssets = (): UploadAssetsFunction => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();

  const selectedCompanyId = useAppSelector(state => state.companyData.selection.companyId);
  const selectedBundleId = useAppSelector(state => state.assets.selection.bundleId);
  const selectedBundleVersion = useAppSelector(state => state.assets.selection.bundleVersion);

  const uploadAssets: UploadAssetsFunction = async rawUploadFileBag => {
    const assetPath: AssetPath = {
      companyId: selectedCompanyId,
      bundleId: selectedBundleId,
      bundleVersion: selectedBundleVersion,
      folderId: '',
      assetId: '',
    };

    // assets will be processed, at this point they can be added as "local assets"
    const newLocalAssets = rawUploadFileBag.map(assetFile => ({
      path: { ...assetPath, folderId: assetFile.folderName, assetId: assetFile.assetName },
      type: assetFile.assetType,
      fileSize: assetFile.file.size,
    }));
    dispatch(addLocalAssets(newLocalAssets));

    // some files might need conversion, ATM this is only done for GLB => BABYLON
    const convertedUploadFileBag = [];
    const assetCreateInfos: CompleteAssetDto[] = [];
    const uploadFailedAssets: FailedAssetsInfo = [];

    for (const assetFile of rawUploadFileBag) {
      const fileExtension = getFileExtension(assetFile.file.name);
      let conversionSuccessful = true;

      if (assetFile.assetType === AssetTypes.BabylonJs && fileExtension === 'babylon') {
        // this is a standard babylon file, do the gzipping to decrease upload size
        dispatch(
          setAssetUploadState([
            {
              path: { ...assetPath, folderId: assetFile.folderName, assetId: assetFile.assetName },
              uploadState: 'Converting',
            },
          ])
        );

        // we don't expect any errors in this procedure, therefore error handling is not implemented (yet)
        const { gzipBabylon } = await import('services/3d/3d-conversion.service');
        assetFile.file = await gzipBabylon(assetFile.file);
      } else if (assetFile.assetType === AssetTypes.BabylonJs && fileExtension === 'glb') {
        // this is a GLB file, that should be used as a 3D asset, therefore a conversion to babylon has to be made
        // set upload state to "converting" for better user feedback, since the conversion can take some time
        dispatch(
          setAssetUploadState([
            {
              path: { ...assetPath, folderId: assetFile.folderName, assetId: assetFile.assetName },
              uploadState: 'Converting',
            },
          ])
        );

        const { convertGLBToBabylon } = await import('services/3d/3d-conversion.service');
        const conversionResult = await convertGLBToBabylon(assetFile.file, assetFile.assetName);
        if (conversionResult instanceof Error) {
          uploadFailedAssets.push({
            name: assetFile.assetName,
            folderName: assetFile.folderName,
            failureReason:
              t('Could not upload GLB file as 3d model asset, GLB file is most likely broken.\nDetails: ') +
              conversionResult.message,
          });
          // mark as failed immediately, don't process the file further
          dispatch(
            setAssetUploadState([
              {
                path: { ...assetPath, folderId: assetFile.folderName, assetId: assetFile.assetName },
                uploadState: 'Failed',
              },
            ])
          );

          conversionSuccessful = false;
        } else {
          assetFile.file = conversionResult;
        }
      }

      if (conversionSuccessful) {
        convertedUploadFileBag.push(assetFile);
      }
    }

    const numFiles = convertedUploadFileBag.length;
    if (numFiles > 0) {
      const uris = await createAssetUploadUris(assetPath.companyId, numFiles);

      const uploadPromises = convertedUploadFileBag.map(async (assetFile, idx) => {
        const rawFileId = Object.keys(uris)[idx];
        const uri = Object.values(uris)[idx];

        // set uploading state for the corresponding asset
        dispatch(
          setAssetUploadState([
            {
              path: { ...assetPath, folderId: assetFile.folderName, assetId: assetFile.assetName },
              uploadState: 'Uploading',
            },
          ])
        );

        try {
          await uploadAssetFile(uri, assetFile.file, percentage =>
            updateUploadProgress(
              { ...assetPath, folderId: assetFile.folderName, assetId: assetFile.assetName },
              percentage
            )
          );
        } catch (e: any) {
          // mark as failed asset and continue with the pending assets
          uploadFailedAssets.push({
            name: assetFile.assetName,
            folderName: assetFile.folderName,
            failureReason: (e as Error).message,
          });
          // upload failed, mark the asset as failed immediately
          dispatch(
            setAssetUploadState([
              {
                path: { ...assetPath, folderId: assetFile.folderName, assetId: assetFile.assetName },
                uploadState: 'Failed',
              },
            ])
          );
          return;
        }

        // upload was successful, mark the asset accordingly
        dispatch(
          setAssetUploadState([
            {
              path: { ...assetPath, folderId: assetFile.folderName, assetId: assetFile.assetName },
              uploadState: 'ToBeFinished',
              uploadPercentage: 100,
            },
          ])
        );

        assetCreateInfos.push({
          rawFileId: rawFileId,
          assetName: assetFile.assetName,
          folderName: assetFile.folderName,
          fileExtension: getExtensionFromAssetFilenameOrUrl(assetFile.file.name),
          overwrite: true,
          assetType: assetFile.assetType,
        });
      });

      await Promise.all(uploadPromises);
    }

    // call complete functions individually for all asset types
    const completeFileAssets: AssetsCompleteResponse = assetCreateInfos.length
      ? await dispatch(
          finishAssetUpload({
            companyId: assetPath.companyId,
            assetBundleId: assetPath.bundleId,
            bundleVersion: assetPath.bundleVersion,
            items: assetCreateInfos,
          })
        ).unwrap()
      : { success: true, failedAssets: [] };

    // combine results from upload and complete calls
    const resCompleteResponse: AssetsCompleteResponse = {
      success: !uploadFailedAssets.length && completeFileAssets.success,
      failedAssets: [...uploadFailedAssets, ...completeFileAssets.failedAssets],
    };

    const uploadStateFailedPayload = resCompleteResponse.failedAssets.map(failedAsset => ({
      path: { ...assetPath, folderId: failedAsset.folderName, assetId: failedAsset.name },
      uploadState: 'Failed' as AssetUploadState,
      errorText: failedAsset.failureReason,
    }));

    // collect all valid files which are not in `uploadStateFailedPayload`
    const validFiles = convertedUploadFileBag.filter(assetFile => {
      const assetFileKey = assetPathToKey({
        ...assetPath,
        folderId: assetFile.folderName,
        assetId: assetFile.assetName,
      });
      const isFailedFile = uploadStateFailedPayload.some(entry => assetPathToKey(entry.path) === assetFileKey);
      return !isFailedFile;
    });
    const uploadStateFinishedPayload = validFiles.map(assetFile => ({
      path: { ...assetPath, folderId: assetFile.folderName, assetId: assetFile.assetName },
      uploadState: 'Finished' as AssetUploadState,
    }));

    // update state for all failed and finished assets
    dispatch(setAssetUploadState([...uploadStateFailedPayload, ...uploadStateFinishedPayload]));

    // state of local assets is up to date here, now the server assets can be fetched again
    await dispatch(
      getBundleVersionData({
        companyId: assetPath.companyId,
        bundleId: assetPath.bundleId,
        bundleVersion: assetPath.bundleVersion,
      })
    );

    return resCompleteResponse;
  };

  const updateUploadProgress = (assetPath: AssetPath, percentage: number): void => {
    dispatch(
      setAssetUploadState([
        {
          path: assetPath,
          uploadPercentage: percentage,
        },
      ])
    );
  };

  return uploadAssets;
};

export const useCreateFolder = (): CreateFolderFunction => {
  const dispatch = useAppDispatch();
  const navigateToAssetEditor = useNavigateToAssetEditor();

  const selectedCompanyId = useAppSelector(state => state.companyData.selection.companyId);
  const selectedBundleId = useAppSelector(state => state.assets.selection.bundleId);
  const selectedBundleVersion = useAppSelector(state => state.assets.selection.bundleVersion);

  const createFolder: CreateFolderFunction = async folderName => {
    const assetPath: AssetPath = {
      companyId: selectedCompanyId,
      bundleId: selectedBundleId,
      bundleVersion: selectedBundleVersion,
      folderId: '',
      assetId: folderName,
    };

    dispatch(
      addLocalAssets([
        {
          path: assetPath,
          type: AssetTypes.Folder,
          fileSize: 0,
        },
      ])
    );

    const createFolderAssetResponse = await dispatch(
      createFolderAsset({
        companyId: selectedCompanyId,
        assetBundleId: selectedBundleId,
        bundleVersion: selectedBundleVersion,
        folderNames: [folderName],
      })
    ).unwrap();

    if (createFolderAssetResponse.success) {
      const uploadStateFinishedPayload = [
        {
          path: { ...assetPath, assetId: folderName },
          uploadState: 'Finished' as AssetUploadState,
        },
      ];

      dispatch(setAssetUploadState(uploadStateFinishedPayload));
    } else {
      const uploadStateFailedPayload = createFolderAssetResponse.failedAssets.map(failedAsset => ({
        path: { ...assetPath, assetId: failedAsset.name },
        uploadState: 'Failed' as AssetUploadState,
        errorText: failedAsset.failureReason,
      }));

      dispatch(setAssetUploadState(uploadStateFailedPayload));
    }

    // TODO: create async thunk for "createFolderAsset" which calls "getBundleVersionData" right away
    await dispatch(
      getBundleVersionData({
        companyId: assetPath.companyId,
        bundleId: assetPath.bundleId,
        bundleVersion: assetPath.bundleVersion,
      })
    );

    if (createFolderAssetResponse.success) {
      navigateToAssetEditor({
        companyId: selectedCompanyId,
        bundleId: selectedBundleId,
        bundleVersion: selectedBundleVersion,
        folderId: '',
        assetId: folderName,
      });
    }

    return createFolderAssetResponse;
  };

  return createFolder;
};

export const usePublishAssetBundle = (): PublishAssetBundleFunction => {
  const dispatch = useAppDispatch();
  const navigateToAssetEditor = useNavigateToAssetEditor();

  const publishAssetBundle: PublishAssetBundleFunction = async (
    selectedCompanyId,
    selectedBundleId,
    selectedBundleVersion,
    cfgrsToUpdate
  ) => {
    // TODO: create async thunk which calls "getAssetBundles" and "getRelevantAssetBundleVersions" right away
    const { assetBundleVersion: newBundleVersion, failedConfiguratorUpdates } = await publishAssetBundleDraft(
      selectedCompanyId,
      selectedBundleId,
      selectedBundleVersion,
      cfgrsToUpdate
    );

    // actually only update the data of the current bundle, as "hasPublishedVersions" state could have been changed
    // maybe there will be a dedicated call in the future
    await dispatch(getAssetBundles({ companyId: selectedCompanyId, silentBundleUpdate: true }));

    // bundle version names have changed after the draft got published, so fetch them again
    await dispatch(
      getRelevantAssetBundleVersions({
        companyId: selectedCompanyId,
        bundleId: selectedBundleId,
      })
    );

    // re-select the new bundle version
    navigateToAssetEditor({
      companyId: selectedCompanyId,
      bundleId: selectedBundleId,
      bundleVersion: newBundleVersion,
    });

    return failedConfiguratorUpdates ?? [];
  };

  return publishAssetBundle;
};

// Only when the input is possibly undefined then the output should be too
// Reference: https://stackoverflow.com/a/75135211/18494261
type OptionalUndefinedState<T> = T extends Asset ? AssetStats : T;

export const useGetAssetStats = <T extends Asset | undefined>(asset: T): OptionalUndefinedState<T> => {
  const { t } = useTypedTranslation();
  const assets = useAppSelector(state => state.assets.assets);

  if (!asset) {
    return undefined as OptionalUndefinedState<T>;
  }

  const infiniteUploadStates = ['Converting', 'ToBeUploaded', 'ToBeFinished', 'Finished'];
  const infiniteUploadValues = [0, 100];

  const assetUploadStateUiTexts: Record<AssetUploadState, string> = {
    Converting: t('Converting'),
    ToBeUploaded: t('Waiting for upload'),
    Uploading: t('Uploading'),
    ToBeFinished: t('Waiting for final processing'),
    Finished: t('Finalized'),
    // unused
    FromServer: t(''),
    Failed: t(''),
  };
  // 4 => highest priority, 0 => lowest (no) priority
  const folderUploadStatesPrio: Record<AssetUploadState, number> = {
    Converting: 3,
    ToBeUploaded: 4,
    Uploading: 5,
    ToBeFinished: 2,
    Finished: 1,
    // unused
    FromServer: 0,
    Failed: 0,
  };

  if (isFolderAsset(asset)) {
    const childAssets = asset.childKeys.map(childKey => assets[childKey]).filter(Boolean);

    // if any child has an error the folder will also be marked with an error
    const hasUploadError =
      asset.uploadState === 'Failed' || childAssets.some(childAsset => childAsset.uploadState === 'Failed');

    // total progess is calculated with the following forumula:
    // progress = (fileA.progress*fileA.size + fileB.progress*fileB.size) / (fileA.size + fileB.size)
    const { progress, total, resultingUploadState } = childAssets.reduce(
      (accUploadData, curAsset) => {
        const fileSize = curAsset.fileSize ?? 0;
        const uploadPercentage = curAsset.uploadPercentage ?? 0;

        switch (curAsset.uploadState) {
          case 'ToBeUploaded':
            // not uploaded yet
            accUploadData.progress += 0;
            accUploadData.total += fileSize;
            break;

          case 'Uploading':
            accUploadData.progress += (fileSize * uploadPercentage) / 100;
            accUploadData.total += fileSize;
            break;

          case 'ToBeFinished':
          case 'Finished':
            // already uploaded
            accUploadData.progress += fileSize;
            accUploadData.total += fileSize;
            break;
        }

        // update resulting upload state depending on priority
        if (folderUploadStatesPrio[curAsset.uploadState] > folderUploadStatesPrio[accUploadData.resultingUploadState]) {
          accUploadData.resultingUploadState = curAsset.uploadState;
        }

        return accUploadData;
      },
      { progress: 0, total: 0, resultingUploadState: 'FromServer' as AssetUploadState }
    );
    const uploadPercentage = total === 0 ? 0 : (progress / total) * 100;

    const isInfiniteUploadState = infiniteUploadStates.includes(resultingUploadState);
    const isInfiniteUploadValues =
      resultingUploadState === 'Uploading' && infiniteUploadValues.includes(uploadPercentage);
    const uploadInfinite = isInfiniteUploadState || isInfiniteUploadValues;
    const uploadText = assetUploadStateUiTexts[resultingUploadState];

    return {
      isAggregatedStatOfChilds: true,
      hasErrors: childAssets.some(c => c.errorBag.totalItems),
      hasWarnings: hasUploadError || childAssets.some(c => c.warningBag.totalItems),
      hasHints: childAssets.some(c => c.hintBag.totalItems),
      uploadStats: { hasError: hasUploadError, uploadPercentage, uploadInfinite, uploadText },
    } as OptionalUndefinedState<T>;
  } else {
    const hasUploadError = asset.uploadState === 'Failed';

    // don't show percentage value if an error is pending
    const uploadPercentage = !hasUploadError && asset.uploadPercentage ? asset.uploadPercentage : 0;

    // spinner should be infinite for certain upload states or if the asset is uploading and the upload is stuck on 0 or
    // 100%
    const isInfiniteUploadState = infiniteUploadStates.includes(asset.uploadState);
    const isInfiniteUploadValues =
      asset.uploadState === 'Uploading' && infiniteUploadValues.includes(asset.uploadPercentage ?? 0);
    const uploadInfinite = isInfiniteUploadState || isInfiniteUploadValues;
    const uploadText = assetUploadStateUiTexts[asset.uploadState];

    return {
      isAggregatedStatOfChilds: false,
      hasErrors: asset.errorBag.totalItems > 0,
      hasWarnings: hasUploadError || asset.warningBag.totalItems > 0,
      hasHints: asset.hintBag.totalItems > 0,
      uploadStats: { hasError: hasUploadError, uploadPercentage, uploadInfinite, uploadText },
    } as OptionalUndefinedState<T>;
  }
};

/**
 * Get all folder assets from the current base path (company, bundle, bundle version) as options for a <Select>
 * component.\
 * Also includes the "root" folder and optionally the possibility to create a new one.
 */
export const useGetFolderAssetSelectOptions = (
  showNoFolderOption = true,
  showNewFolderOption = false
): SelectOption[] => {
  const { t } = useTypedTranslation();

  const assets = useAppSelector(selectAssetsInSelectedBasePath);
  const availableFolders = useMemo(
    () =>
      Object.values(assets)
        .filter(asset => isFolderAsset(asset))
        .sort((a, b) => a.path.assetId.localeCompare(b.path.assetId)),
    [assets]
  );

  const folderOptions: SelectOption[] = [
    ...(showNoFolderOption ? [{ value: NO_FOLDER_KEY, text: t('No folder') }] : []),
    ...(showNewFolderOption ? [{ value: NEW_FOLDER_KEY, text: `+ ${t('New folder')}` }] : []),
    ...availableFolders.map(folder => ({ value: folder.path.assetId })),
  ];

  return folderOptions;
};

/**
 * Checks if bundle can be edited, this includes:
 * - user has ManageAssets permission for the selected company
 * - asset bundle is a draft
 */
export const useIsAssetBundleEditable = (): boolean => {
  const isBundleDraft = useAppSelector(selectSelectedBundleVersionIsDraft);
  const companyId = useAppSelector(state => state.companyData.selection.companyId);
  const hasManageAssetPermission = usePermission(CompanyPermissions.ManageAssets, companyId);

  return isBundleDraft && hasManageAssetPermission;
};
