import MuiToggleButton, { ToggleButtonProps as MuiToggleButtonProps } from '@mui/material/ToggleButton';
import React, { forwardRef } from 'react';
import { Tooltip, TooltipProps } from 'controls/tooltip/tooltip';

type CbnToggleButtonProps = MuiToggleButtonProps & {
  titleProps?: Omit<TooltipProps, 'children' | 'title'>;
};

/**
 * Internal button control that is used in "CbnToggleButtonGroup". Use "CbnToggleButtonStandalone" for an independent
 * and ready-to-use toggle button with a boolean "selected" state instead.
 */
export const CbnToggleButton = forwardRef<HTMLButtonElement, CbnToggleButtonProps>(
  ({ titleProps, title, ...props }, ref) => {
    return (
      <Tooltip title={title} {...titleProps}>
        <MuiToggleButton data-cmptype="CbnToggleButton" ref={ref} {...props} />
      </Tooltip>
    );
  }
);
