import classNames from 'classnames';
import { ReactElement } from 'react';
import { ConditionalWrapper } from 'components/common/conditional-wrapper';
import { ForwardableTooltipProps, Tooltip, TooltipProps } from 'controls/tooltip/tooltip';

export type SvgComponent = React.FC<React.SVGProps<SVGSVGElement>>;

export interface IconProps {
  Svg: SvgComponent;
  className?: string;
  wrapperClassName?: string;
  ariaHidden?: boolean;
  title?: TooltipProps['title'];
  titleProps?: ForwardableTooltipProps;
  tabIndex?: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * Icon component, that forwards a defined svg and adjusts size and color according to the properties.\
 * In order to adjust the color of the svg use the `text` tailwind class, eg: `text-signal`.\
 * Depending on the implementation of the SVG the `fill` or `stroke` attribute will be overwritten with
 * the `currentColor` keyword.
 */
export const Icon: React.FC<IconProps> = ({
  Svg,
  className,
  wrapperClassName,
  title,
  titleProps,
  ariaHidden = true,
  onClick,
  tabIndex,
}) => {
  // TODO: ATM, we have to set an explicit icon width in some situations e.g. by giving a `className="w-[24px]"` etc.
  //       Get rid of those explicit classes once our `Icon` allows usage without it by automatically adjusting to the
  //       containers font size.
  const wrapInTooltip = !!title;

  const icon = (
    <ConditionalWrapper
      condition={!!onClick}
      wrapper={(children): ReactElement => (
        <button
          type="button"
          tabIndex={tabIndex}
          className={classNames('block outline-none', wrapperClassName)}
          onClick={onClick}
        >
          {children}
        </button>
      )}
    >
      <i
        className={!onClick ? wrapperClassName : undefined}
        tabIndex={onClick ? -1 : tabIndex}
        aria-hidden={ariaHidden}
      >
        <Svg data-cmptype="Icon" className={className}></Svg>
      </i>
    </ConditionalWrapper>
  );

  return wrapInTooltip ? (
    <Tooltip title={title} arrow {...titleProps}>
      {icon}
    </Tooltip>
  ) : (
    icon
  );
};
