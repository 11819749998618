import { ActionResult, isGenericActionResult } from '../http/http.service';
import { HttpStatusCode } from 'helper/http/http-status.helper';
import * as HttpService from 'services/http/http.service';
import { AssetPath } from 'slices/assets/assets.slice';

const deleteResponses = ['Success', 'DeleteFailedBecauseFinishesOrSharesExists', 'UnknownResponse'] as const;
export type DeleteResponse = (typeof deleteResponses)[number];
export async function postConfiguratorsCopy(
  sourceCompanyName: string,
  sourceCfgrName: string,
  sourceCfgrVersion: string,
  destinationCompanyName: string,
  destinationCfgrName: string,
  destinationCfgrDisplayName: string,
  destinationDraftDisplayName?: string
): Promise<void> {
  const { url, params } = HttpService.endpoints.configuratorsCopy(
    sourceCompanyName,
    sourceCfgrName,
    sourceCfgrVersion,
    destinationCompanyName,
    destinationCfgrName,
    destinationCfgrDisplayName,
    destinationDraftDisplayName
  );

  await HttpService.httpClient.post(url, params);
}

export async function deleteConfigurator(
  companyId: string,
  configuratorName: string
): Promise<Exclude<DeleteResponse, 'UnknownResponse'>> {
  const { url, params } = HttpService.endpoints.configuratorsDelete(companyId, configuratorName);
  const response = await HttpService.httpClient.delete<ActionResult<DeleteResponse>>(url, {
    data: params,
    validateStatus: () => true,
  });

  if (response.status === HttpStatusCode.Ok_200) {
    return 'Success';
  }

  const responseId = isGenericActionResult(response.data, deleteResponses) ? response.data.Id : 'UnknownResponse';
  if (responseId === 'UnknownResponse') {
    throw new Error(JSON.stringify(response.data));
  }
  return responseId;
}

export async function postConfiguratorRestore(companyId: string, configuratorName: string): Promise<void> {
  const { url, params } = HttpService.endpoints.configuratorsRestore(companyId, configuratorName);
  await HttpService.httpClient.post(url, params);
}

export async function postConfiguratorsRehauUploadDataPackage(
  companyId: string,
  configuratorName: string,
  draftId: string,
  dataPackage: File,
  mergeMode: number
): Promise<void> {
  const { url, params } = HttpService.endpoints.configuratorsUploadRehauWindowDataPackage(
    companyId,
    configuratorName,
    draftId,
    dataPackage,
    mergeMode
  );

  const fd = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    fd.append(key, value as string | Blob);
  });

  await HttpService.httpClient.post(url, fd);
}

export async function postConfiguratorsCreateViewerOnlyCfgr(
  assetPath: AssetPath,
  cfgrName: string,
  cfgrDisplayName: string
): Promise<void> {
  const { url, params } = HttpService.endpoints.configuratorsCreateViewerOnlyCfgr(assetPath, cfgrName, cfgrDisplayName);

  await HttpService.httpClient.post(url, params);
}

type GetCfgrTemplateDTO = {
  templates: {
    id: string;
    name: string;
    description: string;
    companyName: string;
    imageUrl: string;
    cfgrName: string;
    cfgrDisplayName: string;
    assetBundles: {
      [bundleSchemaId: string]: {
        bundleName: string;
        bundleDisplayName: string;
        displayName: string;
      };
    };
  }[];
  existingAssetBundleNames: string[];
  existingAssetBundleDisplayNames: string[];
  existingCfgrNames: string[];
  existingCfgrDisplayNames: string[];
};

export type GetCfgrTemplateResponse = GetCfgrTemplateDTO;
export type CfgrTemplate = GetCfgrTemplateResponse['templates'][number];

export async function getCfgrTemplates(companyId: string): Promise<GetCfgrTemplateResponse> {
  const { url, params } = HttpService.endpoints.getCfgrTemplates(companyId);
  const response = await HttpService.httpClient.get<GetCfgrTemplateDTO>(url, { params });
  return response.data;
}

type WhitelistedDomainsDto = {
  domains: string[];
  /** Undefined/null means no limit */
  maxDomains?: number;
};
export type WhitelistedDomains = WhitelistedDomainsDto;

export async function getCfgrWhitelistedDomains(companyName: string, cfgrName: string): Promise<WhitelistedDomains> {
  const { url, params } = HttpService.endpoints.getWhitelistedDomains(companyName, cfgrName);
  const response = await HttpService.httpClient.get<WhitelistedDomainsDto>(url, { params });
  return response.data;
}

export const knownUpdateWhitelistDomainErrors = ['InvalidWhitelistedDomain'] as const;

/**
 * @param throwOnAllErrors false = Only throw on `errors != knownUpdateWhitelistDomainErrors`\
 *                         true = Also throw on `knownUpdateWhitelistDomainErrors`\
 *                         E.g. we're not expecting any errors when only removing domains -> Use `true` there
 */
export async function postUpdateWhitelistedDomains(
  companyName: string,
  configuratorName: string,
  domains: string[],
  throwOnAllErrors = false
): Promise<void> {
  const { url, params } = HttpService.endpoints.updateWhitelistedDomains(companyName, configuratorName, domains);
  const res = await HttpService.httpClient.post(url, params, {
    validateStatus: () => true,
  });

  if (
    res.status !== HttpStatusCode.Ok_200 &&
    (throwOnAllErrors || !isGenericActionResult(res.data, knownUpdateWhitelistDomainErrors))
  ) {
    throw new Error('Unexpected response status');
  }
  return res.data;
}
