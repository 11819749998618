import Collapse from '@mui/material/Collapse';
import { useState } from 'react';
import { EditIcon, SaveIcon, TrashIcon } from 'assets/icons';
import { useCompanySettingsStaff } from 'components/company/company-settings-staff-provider';
import {
  formatCustomFieldValue,
  resetFieldValuesByType,
} from 'components/company/user-custom-fields/user-custom-field.helper';
import { UserCustomFieldsInput } from 'components/company/user-custom-fields/user-custom-fields-input';
import { Button } from 'controls/button/button';
import { CbnAlert } from 'controls/cbn-alert/cbn-alert';
import { FormItem } from 'controls/form-item/form-item';
import { TextInput } from 'controls/input/text-input';
import { Select, SelectValue } from 'controls/select/select';
import { HighlightedText } from 'controls/text/highlighted-text';
import { useConfirmDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { deleteUserCustomField, updateUserCustomField } from 'services/companies/companies-user-custom-fields';
import {
  UserCustomFieldRequest,
  UserCustomFieldType,
  userCustomFieldTypes,
} from 'services/http/endpoints/companies.endpoints';

type UserCustomFieldEditProps = {
  customFieldKey: string;
};

export const UserCustomFieldEdit: React.FC<UserCustomFieldEditProps> = ({ customFieldKey }) => {
  const { t } = useTypedTranslation();
  const confirm = useConfirmDialog();
  const { customFields, companyId, fetchCustomFields } = useCompanySettingsStaff();
  const [isExpanded, setIsExpanded] = useState(false);
  const currentField = customFields[customFieldKey];

  const [editedField, setEditedField] = useState<UserCustomFieldRequest>({
    companyName: companyId,
    name: currentField.name,
    type: currentField.type,
    anonymous: currentField.anonymous,
    default: currentField.default,
  });

  const onSaveClicked = async (): Promise<void> => {
    await updateUserCustomField(editedField);
    await fetchCustomFields();
    setIsExpanded(false);
  };
  const onDeleteClicked = async (): Promise<void> => {
    const deleteConfirmed = await confirm(t(`Delete the custom field "${currentField.name}"?`), {
      confirmBtnText: t('Delete custom field'),
      variant: 'Danger',
    });

    if (deleteConfirmed) {
      await deleteUserCustomField(companyId, currentField.name);
      await fetchCustomFields();
    }
  };

  const onCancelClicked = (): void => {
    setEditedField(oldField => ({
      ...oldField,
      type: currentField.type,
      anonymous: currentField.anonymous,
      default: currentField.default,
    }));
    setIsExpanded(false);
  };

  const onTypeChange = (val: SelectValue): void => {
    setEditedField(oldData => {
      const newData = { ...oldData, type: val as UserCustomFieldType };
      resetFieldValuesByType(newData);
      return newData;
    });
  };

  const hasChanges =
    editedField.type !== currentField.type ||
    editedField.default !== currentField.default ||
    editedField.anonymous !== currentField.anonymous;

  return (
    <div data-cmptype="UserCustomFieldEdit" className="flex flex-col rounded-lg border border-neutral-40 px-4 py-5">
      <div className="flex h-8 items-center gap-2">
        <span className="text-l-medium">{currentField.name}</span>
        {!isExpanded && (
          <>
            <div className="text-s-regular">
              <span>{currentField.type}</span>
              <span className="text-neutral-70"> | {t('Default')}: </span>
              <span>{formatCustomFieldValue(currentField.default, currentField.type)}</span>
              <span className="text-neutral-70"> | {t('Anonymous')}: </span>
              <span>{formatCustomFieldValue(currentField.anonymous, currentField.type)}</span>
            </div>
            <div className="ml-auto">
              <Button
                Svg={EditIcon}
                text={t('Edit')}
                variant="Secondary"
                onClick={(): void => setIsExpanded(s => !s)}
              />
            </div>
          </>
        )}
      </div>
      <Collapse in={isExpanded} mountOnEnter>
        <div className="mt-4 flex flex-col gap-5">
          <div className="flex items-center justify-between gap-10">
            <div className="grid max-w-[800px] grow grid-cols-[360px_300px] gap-x-10 gap-y-5">
              <FormItem
                labelContent={t('Name')}
                subLabelContent={t('used as identifier in the Configurator Editor - user.fields.name')}
              >
                <TextInput value={editedField.name} disabled />
              </FormItem>
              <FormItem
                labelContent={t('Default value')}
                subLabelContent={t('used for users without explicitly set value')}
              >
                <UserCustomFieldsInput
                  fieldType={editedField.type}
                  value={editedField.default}
                  onChange={(val): void => setEditedField(oldData => ({ ...oldData, default: val }))}
                />
              </FormItem>
              <FormItem
                labelContent={t('Type')}
                subLabelContent={t('on change, all existing values will be replaced by default value')}
              >
                <Select
                  options={userCustomFieldTypes.map(c => ({ value: c }))}
                  value={editedField.type}
                  onChange={onTypeChange}
                />
              </FormItem>
              <FormItem labelContent={t('Anonymous value')} subLabelContent={t('used for non identified users')}>
                <UserCustomFieldsInput
                  fieldType={editedField.type}
                  value={editedField.anonymous}
                  onChange={(val): void => setEditedField(oldData => ({ ...oldData, anonymous: val }))}
                />
              </FormItem>
            </div>
            {hasChanges && (
              <CbnAlert
                className="w-[380px] self-end"
                severity="info"
                content={
                  <span>
                    <HighlightedText
                      className="bg-transparent text-m-medium"
                      text={t(
                        'Published Configurators using this property need to be republished for the changes to take effect.'
                      )}
                      highlight="need to be republished"
                    />
                  </span>
                }
                caption={t('Republish Configurators')}
              />
            )}
          </div>
          <div className="flex grow justify-between">
            <Button
              Svg={TrashIcon}
              text={t('Delete')}
              hoverColor="Danger"
              variant="Secondary"
              onClick={onDeleteClicked}
            />
            <div className="flex gap-3">
              <Button text={t('Cancel')} variant="Secondary" onClick={onCancelClicked} />
              <Button Svg={SaveIcon} text={t('Save')} onClick={onSaveClicked} disabled={!hasChanges} />
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
