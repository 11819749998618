import Collapse from '@mui/material/Collapse';
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridCellEditStartReasons,
  GridCellEditStopReasons,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridEditInputCell,
  GridEditSingleSelectCell,
  GridRowModel,
  GridToolbarQuickFilter,
  ValueOptions,
} from '@mui/x-data-grid-pro';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { unstable_usePrompt } from 'react-router-dom';
import { CheckIcon, EditIcon, UserDeleteMinusIcon } from 'assets/icons';
import { CompanySettingsStaffColumnFilterButton } from 'components/company/company-settings-staff-column-filter-button';
import { useCompanySettingsStaff } from 'components/company/company-settings-staff-provider';
import { UserCustomFieldEdit } from 'components/company/user-custom-fields/user-custom-field-edit';
import {
  convertTextToCustomFieldValue,
  formatCustomFieldValue,
  isCustomFieldListType,
} from 'components/company/user-custom-fields/user-custom-field.helper';
import { UserCustomFieldsNewButton } from 'components/company/user-custom-fields/user-custom-fields-new-button';
import { UserStateIcon } from 'components/company/user-state-icon';
import { Button } from 'controls/button/button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { ColumnMenuWithHideColumn } from 'controls/datagrid/column-menu/column-menu-with-hide-column';
import { GridEditBooleanToggleCell } from 'controls/datagrid/column-types/data-grid-edit-boolean-toggle-cell';
import { DataGridEditSubmitBtnWrapper } from 'controls/datagrid/column-types/data-grid-edit-submit-btn-wrapper';
import { renderCellWithEditButton } from 'controls/datagrid/column-types/data-grid-render-cell-with-edit-buttons';
import { DataGrid } from 'controls/datagrid/data-grid';
import { Icon } from 'controls/icon/icon';
import { UserRoles } from 'generated/user-role';
import { UserStates } from 'generated/user-states';
import { useConfirmDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { setUserCustomFieldValues } from 'services/companies/companies-user-custom-fields';
import { CompanyUser, postCompaniesSetRole } from 'services/companies/companies.service';
import { addError } from 'services/store/logger.service';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';

export const CUSTOM_FIELD_PREFIX = 'custom$$';
const _EDITABLE_USER_STATES = [UserStates.NotActivated, UserStates.Activated];

export const CompanySettingsStaffActiveUsersCard: React.FC = () => {
  const { t } = useTypedTranslation();
  const confirm = useConfirmDialog();
  const company = useAppSelector(selectSelectedCompany);
  const { users, customFields, currentUserRole, companyId, fetchUser, isCustomFieldsEnabled, revokeUser } =
    useCompanySettingsStaff();
  const companyName = company?.displayName ?? '';

  const [isEditFieldsExpanded, setIsEditFieldsExpanded] = useState(false);
  const [hasCellsInEditMode, setHasCellsInEditMode] = useState(false);
  unstable_usePrompt({
    when: hasCellsInEditMode,
    message: t('One or more cells are still in edit mode. Discard unsaved changes?'),
  });

  const userRoleOptions = useMemo<ValueOptions[]>(() => {
    const userRoles = Object.entries(UserRoles);
    userRoles.splice(0, userRoles.length / 2);
    return userRoles
      .filter(([_, roleIdx]) => roleIdx !== UserRoles.None && currentUserRole >= (roleIdx as number))
      .map(([role, roleIdx]) => ({
        value: roleIdx,
        label: role.toString(),
      }));
  }, [currentUserRole]);

  const onRevokeAssignmentClicked = useCallback(
    async (user: CompanyUser): Promise<void> => {
      const userStr = user.firstname ? `${user.firstname} ${user.lastname}` : user.email;

      const deleteConfirmed = await confirm(t(`Remove user "${userStr}" from company "${companyName}"?`), {
        confirmBtnText: t('Remove user'),
        variant: 'Danger',
      });

      if (deleteConfirmed) {
        await revokeUser(user.id);
      }
    },
    [companyName, confirm, t, revokeUser]
  );

  const customFieldCols = useMemo(
    (): GridColDef<CompanyUser>[] =>
      Object.entries(customFields).map(([key, fieldDef]) => ({
        field: CUSTOM_FIELD_PREFIX + key,
        editable: true,
        type: fieldDef.type === 'Logic' ? 'boolean' : fieldDef.type === 'Number' ? 'number' : undefined,
        headerName: key,
        renderHeader: () => (
          <div className="flex flex-col">
            <span>{key}</span>
            <span className="text-neutral-70">{t(`User field | ${fieldDef.type}`)}</span>
          </div>
        ),
        cellClassName: 'group',
        renderCell: params =>
          renderCellWithEditButton(
            params,
            formatCustomFieldValue(params.value !== undefined ? params.value : fieldDef.default, fieldDef.type)
          ),
        renderEditCell: params =>
          fieldDef.type === 'Logic' ? (
            <GridEditBooleanToggleCell {...params} />
          ) : (
            <DataGridEditSubmitBtnWrapper renderParams={params}>
              <GridEditInputCell {...params} />
            </DataGridEditSubmitBtnWrapper>
          ),
        valueGetter: ({ row: userRow }) =>
          userRow.fields[key] && isCustomFieldListType(fieldDef.type)
            ? (userRow.fields[key] as any[]).join(',')
            : userRow.fields[key],

        valueSetter: ({ row, value }): CompanyUser => {
          const parsedValue = convertTextToCustomFieldValue(value, fieldDef.type);
          return { ...row, fields: { ...row.fields, [key]: parsedValue } };
        },
      })),
    [customFields, t]
  );

  const columns = useMemo(
    (): GridColDef<CompanyUser>[] => [
      { field: 'lastname', headerName: 'Last name' },
      { field: 'firstname', headerName: 'First name' },
      { field: 'email', headerName: 'Email' },
      {
        field: 'state',
        headerName: 'State',
        renderCell: ({ row: userRow }) => <UserStateIcon userState={userRow.state} />,
      },
      {
        field: 'role',
        headerName: 'Role',
        editable: true,
        type: 'singleSelect',
        minWidth: 125,
        cellClassName: 'group',
        renderCell: params => renderCellWithEditButton(params, UserRoles[params.row.role]),
        renderEditCell: params => (
          <DataGridEditSubmitBtnWrapper renderParams={params} minWidth={200}>
            <GridEditSingleSelectCell {...params} />
          </DataGridEditSubmitBtnWrapper>
        ),
        valueOptions: () => userRoleOptions,
      },
      ...customFieldCols,
      {
        headerName: 'Remove user',
        type: 'actions',
        field: 'actions',
        getActions: ({ row: userRow }): ReactElement<GridActionsCellItemProps>[] => {
          return [
            <GridActionsCellItem
              label={t('Remove')}
              icon={<Icon Svg={UserDeleteMinusIcon} className="w-5 text-neutral-100" />}
              onClick={(): Promise<void> => onRevokeAssignmentClicked(userRow)}
            />,
          ];
        },
      },
    ],
    [customFieldCols, onRevokeAssignmentClicked, t, userRoleOptions]
  );

  const processRowUpdate = async (newRow: GridRowModel, oldRow: GridRowModel): Promise<GridRowModel> => {
    const newUser = newRow as CompanyUser;
    const oldUser = oldRow as CompanyUser;
    let hadChanges = false;

    try {
      if (newUser.role !== oldUser.role) {
        await postCompaniesSetRole(companyId, newUser.id, newUser.role);
        hadChanges = true;
      }
    } catch (error: any) {
      addError(error.toString());
      return oldUser;
    }

    const hasCustomFieldsChange = JSON.stringify(newUser.fields) !== JSON.stringify(oldUser.fields);
    if (hasCustomFieldsChange) {
      await setUserCustomFieldValues({ companyName: companyId, userId: newUser.id, fields: newUser.fields });
      hadChanges = true;
    }

    if (hadChanges) {
      fetchUser(newUser.id);
    }
    return newUser;
  };

  const processRowUpdateError = (error: any): void => {
    addError(error.toString());
  };

  const onCellModesModelChange = (model: GridCellModesModel): void => {
    const modelValues = Object.values(model);
    const cellsInEditMode =
      modelValues.length > 0 &&
      modelValues.some(row => Object.values(row).some(field => field.mode === GridCellModes.Edit));
    setHasCellsInEditMode(cellsInEditMode);
  };

  return (
    <CbnCard data-cmptype="CompanySettingsStaffActiveUsersCard">
      <CbnCardHeader
        title={t('Active users')}
        subText={t(`View and edit properties and custom user fields of users assigned to the company ${companyName}.`)}
        actions={
          isCustomFieldsEnabled && (
            <div className="flex items-center gap-2">
              <UserCustomFieldsNewButton />
              <Button
                text={t('Edit user fields')}
                Svg={isEditFieldsExpanded ? CheckIcon : EditIcon}
                variant="Outlined"
                onClick={(): void => setIsEditFieldsExpanded(s => !s)}
                disabled={Object.keys(customFields).length === 0}
              />
            </div>
          )
        }
      />
      <Collapse in={isEditFieldsExpanded} mountOnEnter>
        <CbnCardBody>
          <div className="flex flex-col gap-5">
            {Object.keys(customFields).map(fieldKey => (
              <UserCustomFieldEdit key={fieldKey} customFieldKey={fieldKey} />
            ))}
          </div>
        </CbnCardBody>
      </Collapse>
      <div className="flex min-h-52 flex-wrap gap-5">
        <DataGrid
          rowSelection={false}
          onCellEditStart={(params, event): void => {
            if (params.reason === GridCellEditStartReasons.cellDoubleClick) {
              event.defaultMuiPrevented = true;
            }
          }}
          onCellEditStop={(params, event): void => {
            // Simply leaving the cell shouldn't confirm it.
            // Only allow it with concrete confirm/cancel actions (e.g. Enter, Esc, Save-Button)
            if (params.reason === GridCellEditStopReasons.cellFocusOut) {
              if (params.colDef.type !== 'singleSelect') {
                event.defaultMuiPrevented = true;
              } else {
                // Special handling for select fields:
                // If we always prevent it, it would also block "common dropdown behavior"
                // which gives the impression of a frozen UI.
                // A minor improvement is, to prevent it when the user clicks outside after setting a different option
                event.defaultMuiPrevented = event instanceof MouseEvent && event.type === 'mouseup';
              }
            }
          }}
          initialState={{
            pinnedColumns: { right: ['actions'] },
            sorting: { sortModel: [{ field: 'lastname', sort: 'asc' }] },
          }}
          columns={columns as GridColDef[]}
          rows={users}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={processRowUpdateError}
          customToolbarProps={{
            actions: (
              <div className="flex grow justify-between">
                <GridToolbarQuickFilter fullWidth={false} />
                <CompanySettingsStaffColumnFilterButton />
              </div>
            ),
          }}
          customColumnMenu={ColumnMenuWithHideColumn}
          isCellEditable={(params: GridCellParams<CompanyUser>): boolean => {
            const userIsInEditableState = _EDITABLE_USER_STATES.includes(params.row.state);
            const loggedInUserHasPermission =
              params.field === 'role' ? (params.value as UserRoles) <= currentUserRole : true;

            return userIsInEditableState && loggedInUserHasPermission;
          }}
          onCellModesModelChange={onCellModesModelChange}
          disableColumnSelector
          disableColumnFilter
          disableColumnReorder
          autosizeOnMount
          autosizeOptions={{ includeHeaders: true }}
          hideFooter
          disableMultipleRowSelection
        />
      </div>
    </CbnCard>
  );
};
