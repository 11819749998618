import { useEffect, useMemo, useState } from 'react';
import { AssetUploadEntry } from 'components/asset-editor/overview/modals/asset-upload-entry';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { StateIcon, StateIconVariants } from 'controls/icon/state-icon';
import { AssetTypes } from 'generated/asset-types';
import {
  AssetFile,
  OverwrittenAssetNames,
  OverwrittenAssetTypes,
  OverwrittenFolders,
  createAssetUploadBag,
  isAssetFileValidityError,
  isAssetFileValidityInfo,
  isAssetFileValidityWarning,
} from 'helper/assets/assets.helper';
import { useGetFolderAssetSelectOptions, useUploadAssets } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { AssetsCompleteResponse } from 'services/assets/assets.service';
import { MATERIALS_3D_FOLDER_NAME, selectSelectedAssetPath } from 'slices/assets/assets.slice';

type AssetUploadSettingsModalProps = {
  selectedFiles: File[];
  preSelectedFolderId: string;
  predefinedAssetNames?: OverwrittenAssetNames;
  onUploadStarted?: (fileBag: AssetFile[]) => void;
  onUploadFinished?: (fileBag: AssetFile[], result: AssetsCompleteResponse) => void;
  onCancel: () => void;
};

export const AssetUploadSettingsModal: React.FC<AssetUploadSettingsModalProps> = ({
  selectedFiles,
  preSelectedFolderId,
  predefinedAssetNames,
  onUploadStarted,
  onUploadFinished,
  onCancel,
}) => {
  const { t, tNewAssets, tOverwrittenAssets, tWarningAssets, tErroneousAssets } = useTypedTranslation();

  const selectedAssetPath = useAppSelector(selectSelectedAssetPath);
  const assets = useAppSelector(state => state.assets.assets);
  const assetLinks = useAppSelector(state => state.assets.assetLinks);
  const uploadAssets = useUploadAssets();
  const folderOptions = useGetFolderAssetSelectOptions();

  // The following states are used to store the changes that have been made in the dialog, eg: adjusting the name or the
  // asset type. The changes are stored for each file (key = file name) individually and will be considered when
  // creating or updating the file bag.
  const [overwrittenAssetNames, setOverwrittenAssetNames] = useState<OverwrittenAssetNames>({});
  const [overwrittenFolderSelection, setOverwrittenFolderSelection] = useState<OverwrittenFolders>({});
  const [overwrittenAssetType, setOverwrittenAssetType] = useState<OverwrittenAssetTypes>({});
  const [removedFiles, setRemovedFiles] = useState<string[]>([]);

  useEffect(
    function setPredefinedAssetNames() {
      if (predefinedAssetNames) {
        setOverwrittenAssetNames(currentState => {
          return { ...predefinedAssetNames, ...currentState };
        });
      }
    },
    [predefinedAssetNames]
  );

  const onChangeAssetName = (key: string, assetName: string): void => {
    setOverwrittenAssetNames(assetNameObj => ({ ...assetNameObj, [key]: assetName }));
  };
  const onChangeFolderSelection = (key: string, folder: string): void => {
    setOverwrittenFolderSelection(folderSelectionObj => ({ ...folderSelectionObj, [key]: folder }));
  };
  const onChangeAssetType = (key: string, assetType: AssetTypes): void => {
    setOverwrittenAssetType(assetTypeObj => ({ ...assetTypeObj, [key]: assetType }));

    if (assetType === AssetTypes.Material) {
      // material assets are only allowed in "Materials3d" folder, therefore switch the folder selection right away
      setOverwrittenFolderSelection(folderSelectionObj => ({ ...folderSelectionObj, [key]: MATERIALS_3D_FOLDER_NAME }));
    } else if (
      overwrittenFolderSelection[key] === MATERIALS_3D_FOLDER_NAME &&
      !folderOptions.some(option => option.value === MATERIALS_3D_FOLDER_NAME)
    ) {
      // deselect "Materials3d" folder if not available yet
      // this can happen when temporarily selecting "PBRMaterial" as asset type
      setOverwrittenFolderSelection(folderSelectionObj => ({
        ...folderSelectionObj,
        [key]: folderOptions[0].value as string,
      }));
    }
  };
  const onDeleteFile = (key: string): void => {
    setRemovedFiles(removeFilesArr => [...removeFilesArr, key]);
  };

  const fileBag = useMemo(() => {
    // first filter the input files according to the entries that have manually been removed in the dialog
    const availableFiles = selectedFiles.filter(file => !removedFiles.includes(file.name));

    return createAssetUploadBag(
      availableFiles,
      selectedAssetPath,
      assets,
      assetLinks,
      preSelectedFolderId,
      overwrittenAssetNames,
      overwrittenFolderSelection,
      overwrittenAssetType
    );
  }, [
    selectedFiles,
    selectedAssetPath,
    assets,
    assetLinks,
    preSelectedFolderId,
    overwrittenAssetNames,
    overwrittenFolderSelection,
    overwrittenAssetType,
    removedFiles,
  ]);

  // create validity statistic (valid, info, error) from the resulting file bag
  const validityStatistic = useMemo(
    () =>
      fileBag.reduce(
        (accStats, curFile) => {
          if (curFile.validity === 'valid') {
            accStats.valid++;
          } else if (isAssetFileValidityInfo(curFile.validity)) {
            accStats.info++;
          } else if (isAssetFileValidityWarning(curFile.validity)) {
            accStats.warning++;
          } else if (isAssetFileValidityError(curFile.validity)) {
            accStats.error++;
          }

          return accStats;
        },
        { valid: 0, info: 0, warning: 0, error: 0 }
      ),
    [fileBag]
  );

  const onConfirm = async (): Promise<void> => {
    onUploadStarted?.(fileBag);
    const result = await uploadAssets(fileBag);
    onUploadFinished?.(fileBag, result);
  };

  // allow confirm if no error and at least one valid asset is available
  const confirmDisabled = validityStatistic.error > 0 || fileBag.length === 0;

  return (
    <ModalDialog
      data-cmptype="AssetUploadSettingsModal"
      variant="NoIcon"
      header={t('Asset upload settings')}
      actions={{ onConfirm: onConfirm, onCancel: onCancel }}
      size="Large"
      confirmText={t('Upload assets')}
      confirmDisabled={confirmDisabled}
      childrenScrollable
    >
      <div className=" flex grow flex-col gap-4">
        <div className="grid grow grid-cols-[1fr_1fr_240px_180px_80px] overflow-y-auto rounded border border-neutral-40">
          {/* CAUTION: We are in a GRID layout, so it's not possible to group the following components.
                       Every component has to be treated as an individual "cell" */}
          <AssetUploadHeaderEntry headerTitle={t('file name')} />
          <AssetUploadHeaderEntry headerTitle={t('asset name')} />
          <AssetUploadHeaderEntry headerTitle={t('folder')} />
          <AssetUploadHeaderEntry headerTitle={t('asset type')} />
          <AssetUploadHeaderEntry headerTitle={t('status')} />

          {fileBag.map((assetFile, idx) => (
            <AssetUploadEntry
              key={assetFile.file.name}
              fileName={assetFile.file.name}
              assetName={assetFile.assetName}
              selectedFolder={assetFile.folderName}
              folderOptions={folderOptions}
              selectedAssetType={assetFile.assetType}
              assetTypeOptions={assetFile.assetTypeOptions}
              validity={assetFile.validity}
              autoFocus={idx === 0}
              onChangeAssetName={(assetName): void => onChangeAssetName(assetFile.file.name, assetName)}
              onChangeFolder={(folderName): void => onChangeFolderSelection(assetFile.file.name, folderName)}
              onChangeAssetType={(assetType): void => onChangeAssetType(assetFile.file.name, assetType)}
              onDeleteFile={(): void => onDeleteFile(assetFile.file.name)}
            />
          ))}
        </div>
        <div className="flex flex-row justify-end gap-4">
          <AssetUploadStatisticEntry
            stateIconVariant="Success"
            count={validityStatistic.valid}
            text={tNewAssets(validityStatistic.valid)}
          />
          <AssetUploadStatisticEntry
            stateIconVariant="Info"
            count={validityStatistic.info}
            text={tOverwrittenAssets(validityStatistic.info)}
          />
          <AssetUploadStatisticEntry
            stateIconVariant="Warning"
            count={validityStatistic.warning}
            text={tWarningAssets(validityStatistic.warning)}
          />
          <AssetUploadStatisticEntry
            stateIconVariant="Error"
            count={validityStatistic.error}
            text={tErroneousAssets(validityStatistic.error)}
          />
        </div>
      </div>
    </ModalDialog>
  );
};

type AssetUploadHeaderEntryProps = {
  headerTitle: string;
};

const AssetUploadHeaderEntry: React.FC<AssetUploadHeaderEntryProps> = ({ headerTitle }) => {
  return (
    <span data-cmptype="AssetUploadHeaderEntry" className="bg-neutral-30 p-2 text-s-medium uppercase">
      {headerTitle}
    </span>
  );
};

type AssetUploadStatisticEntryProps = {
  stateIconVariant: StateIconVariants;
  count: number;
  text: string;
};

const AssetUploadStatisticEntry: React.FC<AssetUploadStatisticEntryProps> = ({ stateIconVariant, count, text }) => {
  if (count < 1) {
    return null;
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <StateIcon variant={stateIconVariant} />
      <span className="text-l-medium">{text}</span>
    </div>
  );
};
