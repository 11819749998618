import { AccordionDetails, Accordion as AccordionMui, AccordionSummary, accordionClasses, styled } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { ELEVATION_SHADOW } from 'styles/mui-theme';
import { MoveDownIcon } from 'assets/icons';
import { Icon } from 'controls/icon/icon';

export type AccordionProps = {
  'summary': React.ReactNode;
  'children': React.ReactNode;
  'expanded'?: boolean;
  'onChange'?: (isExpanded: boolean) => void;
  'disabled'?: boolean;
  'noBodyMargin'?: boolean;
  'data-cmptype'?: string;
  'className'?: string;
};

const StyledAccordion = styled(AccordionMui)(({ theme }) => ({
  [`&:before`]: {
    opacity: 0,
  },
  [`&.${accordionClasses.root}`]: {
    borderBottom: `1px solid ${theme.palette.neutral[40]}`,
    borderRadius: 0,
    boxShadow: theme.shadows[ELEVATION_SHADOW.None],
  },
  [`&.${accordionClasses.disabled}`]: {
    background: 'transparent',
  },
}));

export const Accordion: React.FC<AccordionProps> = ({
  summary,
  children,
  expanded,
  disabled,
  noBodyMargin,
  onChange,
  className,
  'data-cmptype': dataCmpType,
}) => {
  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean): void => {
    onChange?.(isExpanded);
  };

  return (
    <StyledAccordion
      data-cmptype={'Accordion' + (dataCmpType ? ` ${dataCmpType}` : '')}
      className={className}
      expanded={expanded}
      onChange={handleChange}
      disabled={disabled}
      disableGutters
    >
      <AccordionSummary>
        <div className="ml-4 flex grow flex-row items-center gap-3">
          <Icon Svg={MoveDownIcon} className={classNames('w-4 transition-transform', { 'rotate-180': expanded })} />
          <div className="grow text-m-medium text-neutral-100">{summary}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classNames('p-0', { 'mx-6 mb-6 mt-3': !noBodyMargin })}>{children}</AccordionDetails>
    </StyledAccordion>
  );
};
