import classNames from 'classnames';
import { useEffect } from 'react';
import { Checkbox } from 'controls/checkbox/checkbox';
import { StateIcon } from 'controls/icon/state-icon';
import { List } from 'controls/list/list';
import { ListItem } from 'controls/list/list-item';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { selectAllBundles } from 'slices/assets/assets.slice';
import { selectAllCompanyCfgrs, selectSelectedCompany } from 'slices/company-data/company-data.slice';
import {
  addCfgrToBlueprint,
  applyBlueprintPreselection,
  deselectBlueprintAssetBundle,
  loadBlueprintSelections,
  removeCfgrFromBlueprint,
  selectBlueprintAssetBundle,
  selectCurrentBlueprint,
} from 'slices/replicator/replicator.slice';

export const ReplicatorBlueprintSelection: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const companyCfgrs = useAppSelector(selectAllCompanyCfgrs);

  const bundles = useAppSelector(selectAllBundles);
  const blueprint = useAppSelector(selectCurrentBlueprint);
  const isSavedBlueprint = !!blueprint.blueprintId;

  const sortedAvailableCfgrs = companyCfgrs
    .filter(c => c.isPublished && !c.cfgrFeatures.readonly)
    .sort((a, b) => a.displayName.localeCompare(b.displayName));

  const sortedBlueprintBundles = Object.values(blueprint.assetBundles)
    .filter(blueprintBundle => bundles.find(b => b.id === blueprintBundle.name)?.hasPublishedVersions)
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(
    function loadCompanyRelatedData() {
      if (!selectedCompany?.id) {
        return;
      }

      const initialLoad = async (): Promise<void> => {
        await dispatch(loadBlueprintSelections(selectedCompany.id));
        await dispatch(applyBlueprintPreselection());
      };
      initialLoad();
    },
    [dispatch, selectedCompany?.id]
  );

  const onCfgrClicked = (cfgrId: string): void => {
    const cfgr = sortedAvailableCfgrs.find(c => c.id === cfgrId);
    if (!cfgr) {
      return;
    }

    if (!blueprint.cfgrs[cfgrId]) {
      dispatch(addCfgrToBlueprint({ cfgrId: cfgrId, cfgrVersion: cfgr.version }));
    } else {
      dispatch(removeCfgrFromBlueprint(cfgrId));
    }
  };

  const onBundleClicked = async (bundleName: string): Promise<void> => {
    const blueprintBundle = blueprint.assetBundles[bundleName];

    if (blueprintBundle?.selected) {
      dispatch(deselectBlueprintAssetBundle(bundleName));
    } else {
      dispatch(selectBlueprintAssetBundle({ bundleId: bundleName }));
    }
  };

  return (
    <div data-cmptype="ReplicatorBlueprintSelection" className="flex flex-col gap-6">
      <div>{t('Select the elements you want to copy.')}</div>
      <div
        className={classNames('grid grid-cols-2 gap-6', {
          'pointer-events-none select-none opacity-50': isSavedBlueprint,
        })}
      >
        {!!sortedAvailableCfgrs.length && (
          <div className="flex flex-col gap-4">
            <h4>{t('Published configurators')}</h4>
            <div>
              <List showBorder>
                {sortedAvailableCfgrs.map(cfgr => (
                  <ListItem key={cfgr.id} onClick={(): void => onCfgrClicked(cfgr.id)}>
                    <div className="flex items-center gap-2">
                      <Checkbox checked={!!blueprint.cfgrs[cfgr.id]} />
                      <span>{cfgr.displayName}</span>
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        )}
        {!!sortedBlueprintBundles.length && (
          <div className="flex flex-col gap-4">
            <h4>{t('Published asset bundles')}</h4>
            <div>
              <List showBorder>
                {sortedBlueprintBundles.map(blueprintBundle => {
                  const bundleRef = bundles.find(b => b.id === blueprintBundle.name);
                  if (!bundleRef) {
                    return null;
                  }

                  const hasBrokenAssignment =
                    !blueprintBundle.selected &&
                    Object.values(blueprint.cfgrs).some(cfgr =>
                      cfgr.assignedAssetBundles.some(b => b.assetBundleName === blueprintBundle.name)
                    );

                  return (
                    <ListItem key={bundleRef.id} onClick={(): Promise<void> => onBundleClicked(bundleRef.id)}>
                      <div className="flex items-center gap-2">
                        <Checkbox checked={blueprintBundle.selected} />
                        <span>{bundleRef.displayName}</span>
                        {hasBrokenAssignment && (
                          <StateIcon
                            noBckgr
                            variant="Warning"
                            title={t(
                              "The bundle is assigned to a selected configurator. If it isn't copied, the assignment has to be fixed in the configurator manually afterwards."
                            )}
                            titleProps={{ placement: 'right' }}
                          />
                        )}
                      </div>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
