import { CompanyStates } from 'generated/company-states';
import { UserRoles } from 'generated/user-role';
import { CompanyFeatures, UpdateCompanyDetailsDto } from 'services/companies/companies.service';
import { EndpointDescription } from 'services/http/http.service';

// Available hive types for custom fields
export const userCustomFieldTypes = ['Number', 'Text', 'Logic', 'List<Number>', 'List<Text>'] as const;
export type UserCustomFieldType = (typeof userCustomFieldTypes)[number];

export type UserCustomFieldInput = string | number | boolean | string[] | number[];

export type UserCustomFieldRequest = {
  companyName: string;
  name: string;
  type: UserCustomFieldType;
  default: UserCustomFieldInput;
  anonymous: UserCustomFieldInput;
};

export type CustomFieldValues = { [fieldId: string]: UserCustomFieldInput };
export type SetUserCustomFieldValuesRequest = {
  companyName: string;
  userId: string;
  fields: CustomFieldValues;
};

export const COMPANIES_ENDPOINTS = {
  companiesGetDetails: (companyId: string): EndpointDescription => ({
    url: '/companies/getCompanyDetails',
    params: { companyName: companyId },
  }),
  companiesUpdateDetails: (settings: UpdateCompanyDetailsDto): EndpointDescription => ({
    url: '/companies/updateCompanyDetails',
    params: settings,
  }),
  companiesCreateInvite: (companyId: string, userRole: UserRoles, email?: string): EndpointDescription => ({
    url: '/companies/createInvite',
    params: { inviteRecipient: email, companyName: companyId, userRole },
  }),
  companiesRevokeInvite: (inviteToken: string, companyId: string): EndpointDescription => ({
    url: '/companies/revokeInvite',
    params: { inviteToken, companyName: companyId },
  }),
  companiesGetInvites: (companyId: string): EndpointDescription => ({
    url: '/companies/getInvites',
    params: { companyName: companyId },
  }),
  companiesGetUsers: (companyId: string): EndpointDescription => ({
    url: '/companies/getusers',
    params: { companyName: companyId },
  }),
  companiesGetUser: (companyId: string, userId: string): EndpointDescription => ({
    url: '/companies/getuser',
    params: { companyName: companyId, userId: userId },
  }),
  companiesSetRole: (companyId: string, userId: string, userRole: UserRoles): EndpointDescription => ({
    url: '/companies/setrole',
    params: { companyName: companyId, userId: userId, userRole: userRole },
  }),
  companiesUpdateAdminDetails: (
    companyId: string,
    features: CompanyFeatures,
    state: CompanyStates,
    subscriptionDisplayName: string,
    monthlySubscriptionFee: number,
    invoiceDay: number
  ): EndpointDescription => ({
    url: '/companies/updateCompanyAdminDetails',
    params: {
      name: companyId,
      features,
      state,
      subscriptionDisplayName,
      monthlySubscriptionFee,
      invoiceDay,
    },
  }),
  companiesGetApiTokens: (companyId: string): EndpointDescription => ({
    url: '/companies/getapitokens',
    params: { companyName: companyId },
  }),
  companiesDeleteApiToken: (companyId: string, id: string): EndpointDescription => ({
    url: '/companies/deleteapitoken',
    params: { companyName: companyId, id },
  }),
  companiesCreateApiToken: (
    companyId: string,
    tokenDisplayName: string,
    tokenIdToReplace?: string
  ): EndpointDescription => ({
    url: '/companies/createapitoken',
    params: { companyName: companyId, displayName: tokenDisplayName, tokenIdToReplace },
  }),

  companiesUserCustomFieldsGetAll: (companyId: string): EndpointDescription => ({
    url: '/companies/user-custom-fields/getAll',
    params: { companyName: companyId },
  }),
  companiesUserCustomFieldsCreate: (request: UserCustomFieldRequest): EndpointDescription => ({
    url: '/companies/user-custom-fields/create',
    params: request,
  }),
  companiesUserCustomFieldsUpdate: (request: UserCustomFieldRequest): EndpointDescription => ({
    url: '/companies/user-custom-fields/update',
    params: request,
  }),
  companiesUserCustomFieldsDelete: (companyId: string, name: string): EndpointDescription => ({
    url: '/companies/user-custom-fields/delete',
    params: { companyName: companyId, name: name },
  }),
  companiesUserCustomFieldsSetValues: (request: SetUserCustomFieldValuesRequest): EndpointDescription => ({
    url: '/companies/user-custom-fields/setValues',
    params: request,
  }),
};
