import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { EditIcon, InfoIcon } from 'assets/icons';
import { ASSETLINK_TYPE_MAP } from 'components/asset-editor/assetlink-type-map';
import { DatasourceAssetContent } from 'components/asset-editor/details/asset-body/datasource-assets/datasource-asset-content';
import { AssetDefaultActions } from 'components/asset-editor/details/asset-details-actions';
import { AssetDetailsHeader } from 'components/asset-editor/details/asset-details-header';
import { AssetMetadataEntry } from 'components/asset-editor/details/asset-metadata';
import { AssetDatasourceEditModal } from 'components/asset-editor/details/modals/datasource-edit/asset-datasource-edit-modal';
import { Button } from 'controls/button/button';
import { CbnAlert } from 'controls/cbn-alert/cbn-alert';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { Icon } from 'controls/icon/icon';
import { StateIcon } from 'controls/icon/state-icon';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { AssetErrorTypes } from 'generated/asset-error-types';
import { AssetHintTypes } from 'generated/asset-hint-types';
import { AssetLinkTypes } from 'generated/asset-link-types';
import { assetPathToKey, splitFolderAndAssetIdFromSourceString } from 'helper/assets/assets.helper';
import { getReadableFileSize } from 'helper/file/file.helper';
import { useIsAssetBundleEditable } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { isDataSourceParseError } from 'services/assets/assets.service';
import { buildAssetEditorPath } from 'services/routes/asset-editor-routes.service';
import {
  DataSourceAsset,
  selectFileLinkKeyOfSelectedDataSourceAsset,
  selectSelectedAsset,
} from 'slices/assets/assets.slice';

export const AssetBodyDataSource: React.FC = () => {
  const { t } = useTypedTranslation();
  const isAssetBundleEditable = useIsAssetBundleEditable();

  const { path, size, rowCount, errorBag, hintBag } = useAppSelector(selectSelectedAsset) as DataSourceAsset;
  const { companyId, bundleId, bundleVersion } = path;
  const fileLinkKey = useAppSelector(selectFileLinkKeyOfSelectedDataSourceAsset);

  const [showEditConfigModal, setShowEditConfigModal] = useState(false);

  const fileSize = getReadableFileSize(size);
  const assetKey = assetPathToKey(path);

  const assetErrorText = errorBag.items.some(e => e.$type === AssetErrorTypes.MissingLinkedFolder)
    ? t("There are columns which are linked to folders that don't exist anymore.")
    : errorBag.items.some(e => e.$type === AssetErrorTypes.MissingLinkedDefaultAsset)
      ? t('There are linked columns with a missing default asset.')
      : errorBag.items.some(e => isDataSourceParseError(e))
        ? t("Some entries in the data source can't be parsed with the current import configuration.")
        : undefined;

  const assetHintText = hintBag.items.some(h => h.$type === AssetHintTypes.MissingLinkedAsset)
    ? t('There are columns with missing linked assets.')
    : undefined;

  const editConfigBtn = (
    <Button
      text={t('Edit import config')}
      variant="Outlined"
      Svg={EditIcon}
      onClick={(): void => setShowEditConfigModal(true)}
    />
  );

  const actions: (AssetDefaultActions | React.ReactElement)[] = isAssetBundleEditable
    ? [editConfigBtn, 'Delete', 'Rename', 'Copy', 'Move']
    : [];

  const basedOnLink = useMemo(() => {
    if (fileLinkKey) {
      const { folderId, assetId } = splitFolderAndAssetIdFromSourceString(fileLinkKey);
      return (
        <div className="flex items-center gap-2">
          <Icon
            Svg={ASSETLINK_TYPE_MAP[AssetLinkTypes.FileAssetToExcelDataSource].SourceIcon}
            className="h-[20px] w-[20px]"
          />
          <Link
            to={buildAssetEditorPath(companyId, bundleId, bundleVersion, folderId, assetId)}
            className="underline hover:no-underline"
          >
            {fileLinkKey}
          </Link>
        </div>
      );
    }
  }, [fileLinkKey, companyId, bundleId, bundleVersion]);

  const metadata: AssetMetadataEntry[] = [
    { text: t('Size'), value: fileSize },
    {
      text: t('Row count'),
      value: rowCount ?? (
        <span className="flex items-center gap-2">
          &ndash;
          <Icon
            Svg={InfoIcon}
            className="w-4 text-primary-main"
            title={
              <TooltipContent
                header={t('Unknown number of rows')}
                detail={t(
                  'The data source was created before this feature was added. To get the rows you can re-upload the data source.'
                )}
              />
            }
          />
        </span>
      ),
    },
  ];

  if (basedOnLink) {
    metadata.splice(1, 0, { text: t('Based on'), value: basedOnLink });
  }

  return (
    <>
      <CbnCard data-cmptype="AssetBodyDataSource">
        <AssetDetailsHeader actions={actions} metadata={metadata} />

        {!!(assetErrorText || assetHintText) && (
          <div className="p-4">
            {assetErrorText ? (
              <CbnAlert
                caption={t('Error')}
                content={
                  <div className="flex flex-col gap-5">
                    {assetErrorText}

                    {isAssetBundleEditable && (
                      <div className="flex justify-center">
                        <Button
                          grow
                          text={t('Edit import config')}
                          Svg={EditIcon}
                          onClick={(): void => setShowEditConfigModal(true)}
                        />
                      </div>
                    )}
                  </div>
                }
                severity={'error'}
              />
            ) : assetHintText ? (
              <CbnAlert
                caption={t('Hint')}
                icon={<StateIcon variant="Hint" />}
                content={
                  <div className="flex flex-col gap-5">
                    {assetHintText}

                    {isAssetBundleEditable && (
                      <div className="flex justify-center">
                        <Button
                          grow
                          text={t('Edit import config')}
                          Svg={EditIcon}
                          onClick={(): void => setShowEditConfigModal(true)}
                        />
                      </div>
                    )}
                  </div>
                }
                severity={'info'}
              />
            ) : (
              <></>
            )}
          </div>
        )}

        <div className="relative flex h-[100vh] flex-col">
          <DatasourceAssetContent key={assetKey} />
        </div>
      </CbnCard>

      {showEditConfigModal && <AssetDatasourceEditModal onClose={(): void => setShowEditConfigModal(false)} />}
    </>
  );
};
