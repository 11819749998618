import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowRightUpIcon } from 'assets/icons';
import { IdentityUser } from 'components/identity/identity-user';
import { LinkButton } from 'controls/button/link-button';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { Icon } from 'controls/icon/icon';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'controls/table';
import { TableSkeletonRows } from 'controls/table/table-skeleton-rows';
import { Tooltip } from 'controls/tooltip/tooltip';
import { CfgnInsightsBrowsers } from 'generated/cfgn-insights-browsers';
import { CfgnInsightsType } from 'generated/cfgn-insights-types';
import { FilterItemValueType } from 'generated/filter-item-value-type';
import { ShopHiveTypes } from 'generated/shop-hive-types';
import { cn } from 'helper/css.helper';
import { getRelativeDate } from 'helper/date-and-time/date-and-time.helper';
import { getTypedTranslation, useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { IdentityUserDisplayPartsMap, useIdentityUserDisplayParts } from 'hooks/identity/identity.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { CfgnInsight } from 'services/cfgn/cfgn.service';
import { CfgnInsightsRouteParams, buildCfgnInsightDetailsPath } from 'services/routes/cfgrs-routes.service';
import {
  BrowserDisplayNames,
  CfgnTypeDisplayNames,
  ShopTypeDisplayNames,
  getCfgrCfgnInsights,
  selectCfgrCfgnInsights,
  selectCfgrCfgnInsightsPropertyDefinitions,
  selectIsLoadingInitialData,
  selectShownColumns,
} from 'slices/cfgn-insisghts/cfgn-insights.slice';
import {
  CfgnInsightPropertyDefinition,
  CfgnInsightSpecificType,
  isQueryableBuiltInInsightsProperty,
} from 'slices/cfgn-insisghts/property-definitions';
import { selectSelectedConfigurator } from 'slices/company-data/company-data.slice';

type CfgnsTableProps = {};

export const CfgnsTable: React.FC<CfgnsTableProps> = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const routeParams = useParams<CfgnInsightsRouteParams>();
  const navigate = useNavigate();

  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const cfgrInsights = useAppSelector(selectCfgrCfgnInsights);
  const isLoadingInitialData = useAppSelector(selectIsLoadingInitialData);
  const { queryValues, stageName } = useAppSelector(state => state.cfgnInsights);
  const shownColumns = useAppSelector(selectShownColumns);

  const filterUsers = queryValues.builtIn.createdBy as string[];
  const filterUsersDisplayParts = useIdentityUserDisplayParts(filterUsers);

  const insightsPropertiesDefinitions = useAppSelector(selectCfgrCfgnInsightsPropertyDefinitions);

  // Filter the columns that are in the table + add `filterValueFormatted` property to show in the table header
  const columnsToShow = insightsPropertiesDefinitions
    .filter(x => shownColumns.includes(x.name))
    .map(property => {
      const queryValue = isQueryableBuiltInInsightsProperty(property.name)
        ? queryValues.builtIn[property.name]
        : queryValues.insightsValues[property.name];

      const valueType = property?.valueType ?? FilterItemValueType.String;
      const itemSeparator = property?.queryType === 'min-max-range' ? ' - ' : ', ';
      const filterValueFormatted = !queryValue?.length
        ? t('Any')
        : queryValue
            .map(x => _formatInsightFilterValue(x, valueType, filterUsersDisplayParts, property?.specificType))
            .filter(x => !!x)
            .join(itemSeparator);

      return { ...property, filterValueFormatted };
    });

  useEffect(
    function fetchInsights() {
      if (selectedCfgr?.companyId && selectedCfgr?.id) {
        dispatch(
          getCfgrCfgnInsights({
            companyId: selectedCfgr.companyId,
            cfgrId: selectedCfgr.id,
            stageName,
            queryValues,
          })
        );
      }
    },
    [dispatch, selectedCfgr?.companyId, selectedCfgr?.id, stageName, queryValues]
  );

  return (
    <div data-cmptype="CfgnsTable">
      <Table>
        <TableHead>
          <TableRow>
            <>
              {columnsToShow.map(col => (
                <TableCell key={col.name}>
                  <div className="relative h-[34px] pl-1">
                    <div>{t(col.label)}</div>
                    <Tooltip title={col.filterValueFormatted}>
                      <div className="absolute bottom-0 left-1 h-4 w-full overflow-hidden text-ellipsis whitespace-nowrap text-neutral-70">
                        {col.filterValueFormatted}
                      </div>
                    </Tooltip>
                  </div>
                </TableCell>
              ))}

              {/* Hover icon */}
              <TableCell className="w-0" />
            </>
          </TableRow>
        </TableHead>

        <TableBody>
          <>
            {isLoadingInitialData && <TableSkeletonRows numberOfRows={3} numberOfCols={columnsToShow.length} />}

            {!isLoadingInitialData &&
              cfgrInsights.map(insight => {
                const { companyId, id: cfgrId } = selectedCfgr!;
                const cfgnDetailsUrl = buildCfgnInsightDetailsPath(companyId, cfgrId, insight.id);
                const baseCfgnDetailsUrl =
                  insight.basedOnCfgnId && buildCfgnInsightDetailsPath(companyId, cfgrId, insight.basedOnCfgnId);

                return (
                  <TableRow
                    key={insight.id}
                    className={cn('group hover:bg-neutral-20', insight.id === routeParams.cfgnId && 'bg-neutral-20')}
                    onClick={(e): void => {
                      if (!(e.target instanceof HTMLAnchorElement)) navigate(cfgnDetailsUrl);
                    }}
                  >
                    <>
                      {columnsToShow.map(col => (
                        <TableCell key={col.name}>
                          <div className="py-2 pl-1">
                            {col.name === 'id' ? (
                              <LinkButton href={cfgnDetailsUrl} variant="TextInline" text={insight.id} />
                            ) : col.name === 'basedOnCfgnId' && insight.basedOnCfgnId ? (
                              // TODO Now ORT: Considerations FAL: https://github.com/Combeenation/Combeenation/pull/1535#discussion_r1719543539
                              <LinkButton href={baseCfgnDetailsUrl} variant="TextInline" text={insight.basedOnCfgnId} />
                            ) : (
                              _getInsightPropertyDisplayValue(insight, col.name, insightsPropertiesDefinitions)
                            )}
                          </div>
                        </TableCell>
                      ))}

                      <TableCell>
                        <Icon Svg={ArrowRightUpIcon} className="invisible size-5 group-hover:visible" />
                      </TableCell>
                    </>
                  </TableRow>
                );
              })}
          </>
        </TableBody>
      </Table>
    </div>
  );
};

// TODO Now ORT: How to display boolean values? Yes/No text or some static checkmark?
//               If checkmark: How to make it distinguishable from actually interactive checkboxes?
//               Also: Use the same for boolean values in datasource preview table?
function _boolToYesNo(value: boolean): string {
  return value ? 'Yes' : 'No';
}

function _boolToYesNoUnknown(value: boolean | undefined | null): string {
  return undefined === value || null === value ? 'Unknown' : value ? 'Yes' : 'No';
}

function _getCfgnInsightsTypeDisplayName(value: CfgnInsightsType): string {
  const { t } = getTypedTranslation();
  const name = t(CfgnTypeDisplayNames[value].displayName ?? 'Unknown');
  return name;
}

function _getCheckoutTypeDisplayName(value: ShopHiveTypes): string {
  const { t } = getTypedTranslation();
  const name = t(ShopTypeDisplayNames[value].displayName ?? 'Unknown');
  return name;
}

function _getBrowserDisplayName(value: CfgnInsightsBrowsers): string {
  const { t } = getTypedTranslation();
  const name = t(BrowserDisplayNames[value].displayName ?? 'Unknown');
  return name;
}

function _formatInsightTableValue(
  value: any,
  valueType: FilterItemValueType,
  specificValueType?: CfgnInsightSpecificType
): string | React.ReactNode {
  let formattedValue;

  if (specificValueType === 'identity') {
    formattedValue = <IdentityUser userId={value} />;
  } else if (specificValueType === 'cfgn-type') {
    formattedValue = _getCfgnInsightsTypeDisplayName(value);
  } else if (specificValueType === 'checkout-type') {
    formattedValue = _getCheckoutTypeDisplayName(value);
  } else if (specificValueType === 'browser') {
    formattedValue = _getBrowserDisplayName(value);
  } else if (valueType === FilterItemValueType.Double) {
    formattedValue = value.toFixed(2);
  } else if (valueType === FilterItemValueType.Boolean) {
    formattedValue = _boolToYesNo(value);
  } else if (valueType === FilterItemValueType.DateTime) {
    formattedValue = <RelativeDateTime unixTime={value} />;
  } else if (valueType === FilterItemValueType.ListOfDouble) {
    formattedValue = (value as number[]).map(x => x.toFixed(2)).join(', ');
  } else if (valueType === FilterItemValueType.ListOfString) {
    formattedValue = (value as string[]).join(', ');
  } else if (valueType === FilterItemValueType.ListOfBool) {
    formattedValue = value.map(_boolToYesNo).join(', ');
  } else {
    formattedValue = value.toString();
  }
  return formattedValue;
}

/**
 * Differences to `_formatInsightTableValue`:
 *   - Returns all values as strings, no React cmps
 *   - Also renders explicit text for `Unknown` boolean values
 *   - Renders `identity` as string, not as `IdentityUser` cmp
 *   - Renders `DateTime` as string, not as `RelativeDateTime` cmp
 */
function _formatInsightFilterValue(
  value: any,
  valueType: FilterItemValueType,
  usersDisplayParts: IdentityUserDisplayPartsMap,
  specificValueType?: CfgnInsightSpecificType
): string {
  const { t } = getTypedTranslation();
  let formattedValue;

  if (specificValueType === 'identity') {
    const usersDisplayPart = usersDisplayParts[value];
    formattedValue = !usersDisplayPart
      ? value
      : usersDisplayPart.isFetching
        ? '' // Don't show anything here while fetching (filtered from calling fn)
        : usersDisplayPart.displayParts.name;
  } else if (valueType === FilterItemValueType.Boolean) {
    formattedValue = _boolToYesNoUnknown(value);
  } else if (valueType === FilterItemValueType.DateTime) {
    formattedValue = getRelativeDate(value / 1000);
  } else if (valueType === FilterItemValueType.ListOfBool) {
    formattedValue = value.map(_boolToYesNoUnknown).join(', ');
  } else if (valueType === FilterItemValueType.Double) {
    // Undefined values in min-max-range filters can occur for the min or max value
    formattedValue = value?.toFixed?.(2) ?? t('Any');
  } else {
    formattedValue = _formatInsightTableValue(value, valueType, specificValueType);
  }
  return formattedValue;
}

/**
 * Extract the value of a property from an insight object and return it correctly formatted based on its `valueType`
 * or `specificType` from the given `propertyDefinitions`.
 */
function _getInsightPropertyDisplayValue(
  insight: CfgnInsight,
  propertyName: string,
  propertyDefinitions: CfgnInsightPropertyDefinition[]
): string | React.ReactNode {
  // E.g. `metadata.location` -> Group = 'metadata' and value is stored in `insight.metadata.location`
  const [beforeDot, afterDot] = propertyName.split('.') as [keyof CfgnInsight, keyof CfgnInsight[keyof CfgnInsight]];
  const value = (afterDot ? insight[beforeDot]?.[afterDot] : insight[beforeDot]) ?? undefined;

  if (value === undefined) return value;

  const propertyDefinition = propertyDefinitions.find(x => x.name === propertyName);
  const valueType = propertyDefinition?.valueType ?? FilterItemValueType.String;
  const formattedValue = _formatInsightTableValue(value, valueType, propertyDefinition?.specificType);
  return formattedValue;
}
