import { useCallback, useId, useState } from 'react';
import { TextInput } from 'controls/input/text-input';
import { RadioButtonGroup, RadioButtonValue } from 'controls/radio-button/radio-button-group';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type DefinitionType = 'Repeating' | 'Specific';

type CronRangeInputProps = {
  unit: string;
  value: string;
  onChange: (value: string) => void;
};

export const CronRangeInput: React.FC<CronRangeInputProps> = ({ unit, value, onChange }) => {
  const { t } = useTypedTranslation();
  const [selectedType, setSelectedType] = useState<DefinitionType>(value.includes('/') ? 'Repeating' : 'Specific');
  const [textInput, setTextInput] = useState(_parseInitialCronPart(value));
  const [isErroneous, setIsErroneous] = useState(false);

  const groupId = useId();

  const onChangeWithValidation = useCallback(
    (type: DefinitionType, value: string) => {
      const validatedValue = _getValidatedCronPart(value, type);

      onChange(validatedValue ?? '*');
      setIsErroneous(!validatedValue);
    },
    [onChange]
  );

  const onTypeChange = (value: RadioButtonValue | undefined): void => {
    const newType = value as DefinitionType;
    setSelectedType(newType);
    onChangeWithValidation(newType, textInput);
  };

  const onTextInputChange = (value: string): void => {
    setTextInput(value);
    onChangeWithValidation(selectedType, value);
  };

  const entries: { value: DefinitionType; text: string }[] = [
    { value: 'Specific', text: t(`At ${unit}`) },
    { value: 'Repeating', text: t(`Every [x] ${unit}`) },
  ];

  const infoText =
    selectedType === 'Repeating'
      ? t('Only one integer value is supported')
      : t('Single values: 1,2,3\nRange: 2-3,4-10\nMixed: 1,2-5,10');

  return (
    <div className="grid grid-cols-[210px_230px_100px] items-center gap-2" data-cmptype="CronRangeInput">
      <RadioButtonGroup entries={entries} name={groupId} value={selectedType} onValueChange={onTypeChange} />
      <TextInput
        value={textInput}
        onValueChange={onTextInputChange}
        title={infoText}
        placeholder="*"
        error={isErroneous}
      />
      <div>{value}</div>
    </div>
  );
};

function _parseInitialCronPart(value: string): string {
  if (value === '*') {
    return '';
  }
  return value.includes('/') ? value.split('/')[1] : value;
}

function _getValidatedCronPart(value: string, type: DefinitionType): string | undefined {
  if (value.length === 0 || value === '*') {
    return '*';
  }

  if (type === 'Repeating' && !value.match(/^[1-9]\d*$/)) {
    return undefined;
  } else if (type === 'Specific' && !value.match(/^\d+([,-]\d+)*$/)) {
    return undefined;
  }

  return type === 'Repeating' ? '*/' + value : value;
}
