import { useId } from 'react';
import { QuestionIcon } from 'assets/icons';
import { Icon } from 'controls/icon/icon';
import { StateIcon } from 'controls/icon/state-icon';
import { NumberInput, NumberOrNull } from 'controls/input/number-input';
import { TextInput } from 'controls/input/text-input';
import { Switch } from 'controls/switch/switch';
import { TooltipProps } from 'controls/tooltip/tooltip';

type DSConfigLabelProps = { name: string; htmlFor: string; disabled?: boolean };

/**
 * Implementation of "HTML forms label", which gets assigned to the corresponding forms element
 */
const DSConfigLabel: React.FC<DSConfigLabelProps> = ({ name, htmlFor, disabled }) => (
  <label
    data-cmptype="DSConfigLabel"
    className={`flex items-center text-m-regular ${disabled ? '' : 'cursor-pointer'}`}
    htmlFor={htmlFor}
  >
    {name}
  </label>
);

type DSConfigTextInputProps = {
  name?: string;
  value: string;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  helpText?: TooltipProps['title'];
  onChange: (value: string) => void;
};

export const DSConfigTextInput: React.FC<DSConfigTextInputProps> = ({
  name,
  value,
  disabled,
  error,
  errorText,
  helpText,
  onChange,
}) => {
  // unique id for assigning form control to it's label
  const id = useId();

  return (
    // The whole thing is meant to used in a "grid layout", that's why the parent node is just a fragment
    // When omitting the name prop, the control can be used standalone in other layout systems (eg: flex) as well
    <>
      {name && <DSConfigLabel name={name} htmlFor={id} disabled={disabled} />}
      <div data-cmptype="DSConfigTextInput-input" className="flex min-w-0 flex-grow flex-row items-center gap-2">
        <TextInput
          id={id}
          value={value}
          disabled={disabled}
          error={error}
          onValueChange={onChange}
          endAdornment={error && errorText && <StateIcon variant="Error" title={errorText} noBckgr />}
        />
        {helpText && (
          <Icon
            Svg={QuestionIcon}
            className="h-6 text-neutral-60"
            title={helpText}
            titleProps={{ placement: 'right' }}
          />
        )}
      </div>
    </>
  );
};

type DSConfigNumberInputProps = {
  name?: string;
  value?: number;
  disabled?: boolean;
  onlyPositive?: boolean;
  error?: boolean;
  errorText?: string;
  helpText?: TooltipProps['title'];
  onChange: (value: NumberOrNull) => void;
};

export const DSConfigNumberInput: React.FC<DSConfigNumberInputProps> = ({
  name,
  value,
  disabled,
  onlyPositive,
  error,
  errorText,
  helpText,
  onChange,
}) => {
  const id = useId();

  return (
    <>
      {name && <DSConfigLabel name={name} htmlFor={id} disabled={disabled} />}
      <div data-cmptype="DSConfigNumberInput-input" className="flex min-w-0 flex-grow flex-row items-center gap-2">
        <NumberInput
          id={id}
          inputMode="numeric"
          onlyPositive={onlyPositive}
          value={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
          endAdornment={error && errorText && <StateIcon variant="Error" title={errorText} noBckgr />}
        />
        {helpText && (
          <Icon
            Svg={QuestionIcon}
            className="h-6 text-neutral-60"
            title={helpText}
            titleProps={{ placement: 'right' }}
          />
        )}
      </div>
    </>
  );
};

type DSConfigSwitchProps = {
  name?: string;
  value: boolean;
  disabled?: boolean;
  helpText?: TooltipProps['title'];
  onChange: (value: boolean) => void;
};

export const DSConfigSwitch: React.FC<DSConfigSwitchProps> = ({ name, value, disabled, helpText, onChange }) => {
  const id = useId();

  return (
    <>
      {name && <DSConfigLabel name={name} htmlFor={id} disabled={disabled} />}
      <div data-cmptype="DSConfigSwitch-input" className="flex h-8 items-center gap-1">
        <Switch id={id} checked={value} disabled={disabled} onValueChanged={onChange} />
        {helpText && (
          <Icon
            Svg={QuestionIcon}
            className="h-6 text-neutral-60"
            title={helpText}
            titleProps={{ placement: 'right' }}
          />
        )}
      </div>
    </>
  );
};
