// Generated by EnumsBuilderTypeScript (DO NOT change this file manually, change the builder if required)
export enum DataSourceColumnTypes {
  String = 1,
  Double = 2,
  Bool = 3,
  FileUrl = 4,
  LinkedAsset = 5,
  ListString = 6,
  ListDouble = 7,
  ListBool = 8
}