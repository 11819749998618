import { MenuItem as BaseMenuItem } from '@mui/base/MenuItem';
import { styled } from '@mui/material';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { CheckIcon } from 'assets/icons';
import { ConditionalWrapper } from 'components/common/conditional-wrapper';
import { Icon, SvgComponent } from 'controls/icon/icon';
import { ForwardableTooltipProps, Tooltip } from 'controls/tooltip/tooltip';
import { cn } from 'helper/css.helper';

export type CbnMenuItemProps = {
  text: string;
  icon?: SvgComponent;
  to?: string;
  danger?: boolean;
  disabled?: boolean;
  selected?: boolean;
  title?: string;
  titleProps?: ForwardableTooltipProps;
  handleClick?: () => void;
};

const StyledMenuItem = styled(BaseMenuItem)(({ theme }) => ({
  '&.Mui-disabled > div': {
    'backgroundColor': theme.palette.neutral[30],
    'color': theme.palette.neutral[60],
    'cursor': 'default',

    '&:hover': {
      backgroundColor: theme.palette.neutral[30],
      color: theme.palette.neutral[60],
    },
  },
}));

export const CbnMenuItem: React.FC<CbnMenuItemProps> = ({
  text,
  icon,
  to,
  danger,
  selected,
  disabled,
  title,
  titleProps,
  handleClick,
}) => {
  return (
    <StyledMenuItem disabled={disabled} onClick={handleClick} data-cmptype="CbnMenuItem">
      <ConditionalWrapper condition={!!to} wrapper={(children): ReactElement => <Link to={to ?? ''}>{children}</Link>}>
        <Tooltip title={title} arrow {...titleProps}>
          <div
            className={cn(
              'flex cursor-pointer select-none flex-row items-center justify-between gap-3 px-3 py-1.5 text-m-regular text-neutral-90 hover:bg-neutral-20',
              danger && 'hover:text-danger-main'
            )}
          >
            <div className="flex flex-row items-center gap-3">
              {icon && <Icon Svg={icon} className="w-5" />}
              <span className={cn('text-m-regular', selected && 'text-m-medium')}>{text}</span>
            </div>
            {selected && <Icon Svg={CheckIcon} className="w-5" />}
          </div>
        </Tooltip>
      </ConditionalWrapper>
    </StyledMenuItem>
  );
};
