import { useState } from 'react';
import { LoginOrRegisterPicker } from 'components/common/login-or-register-picker';
import { useInviteData } from 'components/invitation/accept-company-invitation-wrapper';
import { AnyQuestionsBee } from 'controls/any-questions-bee/any-questions-bee';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { postAccountAcceptInvite } from 'services/companies/companies.service';
import { buildCompanyHomePath } from 'services/routes/home-routes.service';
import { PATHS } from 'services/routes/paths.service';
import { selectLoggedIn } from 'slices/auth/auth.slice';

export const AcceptCompanyInvitationIndex: React.FC = () => {
  const { t } = useTypedTranslation();
  const loggedIn = useAppSelector(selectLoggedIn);
  const [isLoading, setIsLoading] = useState(false);
  const {
    inviteData: { companyName, inviteToken, inviteRecipient },
  } = useInviteData();

  const onAcceptClicked = async (): Promise<void> => {
    setIsLoading(true);
    await postAccountAcceptInvite(companyName, inviteToken);
    setIsLoading(false);

    // WORKAROUND, `useNavigate` is not working as expected here.
    // See other places where `location.href` is assigned to fore more info...
    window.location.href = buildCompanyHomePath(companyName);
  };

  return (
    <div data-cmptype="AcceptCompanyInvitationIndex" className="flex grow items-center justify-center">
      {loggedIn && (
        <div className="flex flex-col items-center gap-3">
          <div className="mb-6 text-center">{t('If you want to join and collaborate accept the invitation.')}</div>

          <Button text={t('Accept invitation')} onClick={onAcceptClicked} isLoading={isLoading} />

          <div className="flex flex-row items-center gap-1 text-m-regular text-neutral-90">
            <span>{t('Not now?')}</span>
            <LinkButton variant="TextInline" href={PATHS.home} text={t('Go back to home')} />
          </div>
        </div>
      )}

      {!loggedIn && (
        <div className="flex flex-col items-center gap-6">
          <LoginOrRegisterPicker
            companyName={companyName}
            inviteToken={inviteToken}
            inviteRecipient={inviteRecipient}
            signInTitle={t('I already have an account.')}
            signInDetailText={t('Sign in to your account to accept the invitation.')}
            registerTitle={t("I'm new to Combeenation.")}
            registerDetailText={t('Create an account to start collaborating.')}
          />

          <AnyQuestionsBee />
        </div>
      )}
    </div>
  );
};
