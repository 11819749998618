import classNames from 'classnames';

export type ListProps = {
  // Optional header with text and React nodes for actions
  headerText?: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
  showBorder?: boolean;
};

export const List: React.FC<ListProps> = ({ headerText, actions, children, showBorder = false }) => {
  return (
    <div
      data-cmptype="List"
      className={classNames('flex max-h-full flex-grow basis-0 flex-col overflow-hidden', {
        'group rounded-lg border border-neutral-40': showBorder /* `group` is required for <ListItem> */,
      })}
    >
      {/* Optional header, only displayed if required */}
      {(headerText || actions) && (
        <div className="flex h-14 flex-shrink-0 items-center justify-between bg-neutral-30 px-6">
          {headerText && (
            <span className="text-s-regular font-semibold uppercase tracking-wider text-neutral-100">{headerText}</span>
          )}
          {actions}
        </div>
      )}
      {/* List items */}
      <div className="flex-grow overflow-y-auto">
        <ul>{children}</ul>
      </div>
    </div>
  );
};
