import { Avatar, Paper } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ELEVATION_SHADOW } from 'styles/mui-theme';
import { DropdownIcon, SearchIcon } from 'assets/icons';
import { Icon } from 'controls/icon/icon';
import { Input } from 'controls/input/input';
import { HighlightedText } from 'controls/text/highlighted-text';
import { Tooltip } from 'controls/tooltip/tooltip';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

interface AutocompletePopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const AutocompletePopperComponent: React.FC<AutocompletePopperComponentProps> = props => {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
};

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  width: '100% !important',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  boxShadow: 'none',
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.neutral[10],
    color: theme.palette.neutral[90],
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      'minHeight': 'auto',
      'alignItems': 'flex-start',
      'paddingLeft': '0px',
      'paddingRight': '0px',
      'marginLeft': theme.spacing(1),
      'marginRight': theme.spacing(1),
      'borderRadius': theme.shape.borderRadiusRoundedLg,
      'fontSize': theme.typography.mRegular.fontSize,
      '&[aria-selected="true"]': {
        backgroundColor: theme.palette.neutral[30],
        fontWeight: theme.typography.mMedium.fontWeight,
        color: theme.palette.neutral[100],
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.neutral[20],
      },
    },
  },
}));

const StyledPaper = styled(Paper)(() => ({
  boxShadow: 'none',
}));

const StyledDropdownPopper = styled(Popper)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusRoundedLg,
  boxShadow: theme.shadows[ELEVATION_SHADOW.XL],
  width: 258,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  backgroundColor: theme.palette.neutral[10],
}));

export type DropdownItem = {
  id: string;
  displayName?: string;
  displayDetail?: string;
  endAdornment?: React.ReactNode;
  route: string;
};

export type DropdownSearchProps = {
  'items': DropdownItem[];
  'fullWidth'?: boolean;
  'selected'?: string;
  'defaultButtonText'?: String;
  'dropdownHeader'?: string;
  'disabled'?: boolean;
  'showIcons'?: boolean;
  'onChange'?: (value: string) => void;
  'data-cmptype'?: string;
};

export const DropdownSearch = React.forwardRef<HTMLButtonElement, DropdownSearchProps>(
  (
    {
      items,
      selected,
      defaultButtonText,
      dropdownHeader,
      disabled,
      showIcons,
      fullWidth,
      onChange,
      'data-cmptype': dataCmpType,
    },
    ref
  ) => {
    const { t } = useTypedTranslation();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    if (!defaultButtonText) {
      defaultButtonText = t('[No selection]');
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
      setAnchorEl(event.currentTarget);

      // Auto scroll to selected item when opening the popper
      // Probably not the most elegant solution but seems to be working just fine. Worst thing which can happen is that
      // auto scroll doesn't work in some situations or scrolls incorrect item into view...
      setTimeout(() => {
        const selectedEl = document.querySelector(`[data-key="${selected}"]`);
        selectedEl?.scrollIntoView({ block: 'center' });
      }, 0);
    };

    const handleClose = (): void => {
      if (anchorEl) {
        anchorEl.focus();
      }
      setAnchorEl(undefined);
    };

    const open = Boolean(anchorEl);

    const handleChange = (newValue: DropdownItem): void => {
      handleClose();
      if (selected !== newValue.id) {
        onChange?.(newValue.id);
      }
    };

    const selectedItem = items.find(item => item.id === selected);

    const cmpName = 'DropdownSearch' + (dataCmpType ? ` ${dataCmpType}` : '');

    return (
      <div data-cmptype={cmpName} className={classNames('flex font-semibold', { 'w-full': fullWidth })}>
        <button
          type="button"
          data-cmptype="DropdownSearch-trigger-button"
          className={classNames(
            'flex w-full items-center gap-4 rounded-md px-2 py-2 text-left hover:bg-neutral-20 focus-visible:outline-primary-focus',
            {
              'text-neutral-50': disabled,
            }
          )}
          disabled={disabled}
          onClick={handleClick}
          ref={ref}
        >
          {showIcons && (
            <Avatar
              variant="square"
              aria-hidden={true}
              sx={{
                borderRadius: '8px',
                width: 35,
                height: 35,
              }}
            >
              {(selectedItem?.displayName &&
                selectedItem.displayName.length > 0 &&
                selectedItem.displayName[0].toUpperCase()) ??
                '?'}
            </Avatar>
          )}
          <div className="min-w-0 grow">
            {selectedItem ? (
              <>
                {selectedItem.displayDetail ? (
                  <>
                    <Tooltip title={selectedItem.displayName} arrow delay={500} placement="top">
                      <div className="overflow-hidden text-ellipsis whitespace-nowrap text-m-regular font-semibold">
                        {selectedItem.displayName}
                      </div>
                    </Tooltip>
                    <div className="overflow-hidden text-ellipsis whitespace-nowrap text-s-regular text-neutral-70">
                      {selectedItem.displayDetail}
                    </div>
                  </>
                ) : (
                  <div>{selectedItem.displayName || selectedItem.id}</div>
                )}
              </>
            ) : (
              <div>{defaultButtonText}</div>
            )}
          </div>
          <Icon Svg={DropdownIcon} className="h-4" />
        </button>
        <StyledDropdownPopper open={open} anchorEl={anchorEl} placement="bottom-start">
          <ClickAwayListener onClickAway={handleClose}>
            <div data-cmptype="DropdownSearch-popper">
              {dropdownHeader && (
                <div className="mx-2 mt-2 p-1">
                  <span className="text-m-regular font-semibold">{dropdownHeader}</span>
                </div>
              )}
              <Autocomplete
                open
                filterOptions={(options, state): DropdownItem[] =>
                  options.filter(o => _isDisplayNameMatch(o, state.inputValue) || _isIdMatch(o, state.inputValue))
                }
                onClose={(event, reason): void => {
                  if (reason === 'escape') {
                    handleClose();
                  }
                }}
                onChange={(event, newValue, reason): void => {
                  if (reason === 'selectOption' && newValue != null) {
                    if (event.type === 'keydown' && newValue.route) {
                      // keypress (Enter) doesn't click the link, so it's done programmatically
                      navigate(newValue.route);
                    }
                    handleChange(newValue);
                  }
                }}
                PopperComponent={AutocompletePopperComponent}
                renderTags={(): null => null}
                noOptionsText={t('No entries available')}
                renderOption={(props, option, state): React.ReactNode => {
                  // show the id if it's the one that matched (instead of the displayname)
                  const searchAddition =
                    state.inputValue.length > 0 && !_isDisplayNameMatch(option, state.inputValue)
                      ? ` (${option.id})`
                      : '';
                  const displayName = _getDisplayName(option) + searchAddition;

                  return (
                    <Link
                      to={option.route}
                      key={option.id}
                      onClick={handleClose}
                      data-key={option.id}
                      className="focus-visible:outline-primary-focus"
                    >
                      <li {...props} role="option" aria-selected={option.id === selected}>
                        <div className="flex w-full flex-row items-center justify-center gap-2 px-2 py-1.5">
                          {showIcons && (
                            <div>
                              <Avatar
                                variant="square"
                                aria-hidden={true}
                                sx={{ width: 32, height: 32, borderRadius: '8px', userSelect: 'none' }}
                              >
                                {displayName[0].toUpperCase()}
                              </Avatar>
                            </div>
                          )}
                          <div className="flex flex-1 items-center">
                            <span className="[overflow-wrap:anywhere]">
                              <HighlightedText
                                text={displayName}
                                highlight={state.inputValue}
                                className="bg-transparent font-bold"
                              />
                            </span>
                          </div>
                          {option.endAdornment && <div>{option.endAdornment}</div>}
                        </div>
                      </li>
                    </Link>
                  );
                }}
                options={items}
                getOptionLabel={_getDisplayName}
                renderInput={(params): React.ReactNode => (
                  <div ref={params.InputProps.ref} className="mx-3 my-1">
                    <Input
                      inputProps={params.inputProps}
                      autoFocus
                      placeholder={'Search ...'}
                      startAdornment={<Icon Svg={SearchIcon} className="ml-[-2px] mr-1 h-5 text-neutral-70" />}
                    />
                  </div>
                )}
                fullWidth
                PaperComponent={StyledPaper}
              />
            </div>
          </ClickAwayListener>
        </StyledDropdownPopper>
      </div>
    );
  }
);

const _getDisplayName = (option: DropdownItem): string => option.displayName ?? option.id;

const _isDisplayNameMatch = (option: DropdownItem, searchInput: string): boolean =>
  option.displayName?.toLowerCase().includes(searchInput.toLowerCase()) ?? false;

const _isIdMatch = (option: DropdownItem, searchInput: string): boolean =>
  option.id.toLowerCase().includes(searchInput.toLowerCase());
