import React, { useEffect, useState } from 'react';
import { DeleteIcon, PlusInCircleIcon, RefreshIcon } from 'assets/icons';
import { IdentityUser } from 'components/identity/identity-user';
import { Button } from 'controls/button/button';
import { CopyToClipboardButton } from 'controls/button/copy-to-clipboard-button';
import { LinkButton } from 'controls/button/link-button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { FormItem } from 'controls/form-item/form-item';
import { StateIcon } from 'controls/icon/state-icon';
import { TextInput } from 'controls/input/text-input';
import { Textarea } from 'controls/textarea/textarea';
import { Tooltip } from 'controls/tooltip/tooltip';
import { cn } from 'helper/css.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import {
  CompanyApiToken,
  createCompanyApiTokens,
  deleteCompanyApiToken,
  getCompanyApiTokens,
} from 'services/companies/companies.service';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';

type CardState = 'idle' | 'create-form';

const _MAX_ALLOWED_NO_OF_TOKENS = 2;

export const CompanySettingsApiTokenCard: React.FC = () => {
  const { t, tMaxApiTokensReached } = useTypedTranslation();

  const selectedCompany = useAppSelector(selectSelectedCompany);
  const companyId = selectedCompany?.id ?? '';

  const [isCreatingToken, setIsCreatingToken] = useState(false);
  const [regeneratingTokenId, setRegeneratingTokenId] = useState('');
  const [deletingTokenId, setDeletingTokenId] = useState('');
  const isLoading = isCreatingToken || !!regeneratingTokenId || !!deletingTokenId;

  const [newTokenDisplayName, setNewTokenDisplayName] = useState('');
  const [generatedTokenId, setGeneratedTokenId] = useState('');
  const [generatedTokenValue, setGeneratedTokenValue] = useState('');
  const [tokens, setTokens] = useState<CompanyApiToken[]>([]);

  const maxNoOfTokensCreated = tokens.length >= _MAX_ALLOWED_NO_OF_TOKENS;

  const [cardState, setCardState] = useState<CardState>('idle');
  const showCreateForm = cardState === 'create-form';

  useEffect(
    function fetchApiTokensOnMountAndCompanyChange() {
      async function doFetch(): Promise<void> {
        const tokens = await getCompanyApiTokens(companyId);
        setTokens(tokens);
      }
      doFetch();
    },
    [companyId]
  );

  const deleteToken = async (tokenId: string): Promise<void> => {
    setDeletingTokenId(tokenId);
    await deleteCompanyApiToken(companyId, tokenId);
    setTokens(tokens => tokens.filter(token => token.tokenId !== tokenId));
    setDeletingTokenId('');
  };

  const onRegenerateToken = async (token: CompanyApiToken): Promise<void> => {
    setRegeneratingTokenId(token.tokenId);

    const createdToken = await createCompanyApiTokens(companyId, token.displayName, token.tokenId);
    setTokens(tokens => [...tokens, createdToken].filter(x => x.tokenId !== token.tokenId));
    setGeneratedTokenId(createdToken.tokenId);
    setGeneratedTokenValue(createdToken.tokenValue);

    setRegeneratingTokenId('');
  };

  const onSubmitForm = async (event: React.FormEvent): Promise<void> => {
    setIsCreatingToken(true);
    event.preventDefault();

    const createdToken = await createCompanyApiTokens(companyId, newTokenDisplayName);
    setTokens(tokens => [...tokens, createdToken]);
    setGeneratedTokenId(createdToken.tokenId);
    setGeneratedTokenValue(createdToken.tokenValue);
    setCardState('idle');

    setNewTokenDisplayName('');
    setIsCreatingToken(false);
  };

  return (
    <CbnCard data-cmptype="CompanySettingsApiTokenCard">
      <CbnCardHeader
        title={t('API Token')}
        subText={
          <div className="flex-1 text-m-regular text-neutral-70">
            <span>{t('Manage your API tokens, which are required for the')}</span>
            <LinkButton
              href="https://docs.combeenation.com/docs/combeenation-api"
              variant="TextInline"
              text={` ${t('Combeenation API')} `}
            />
            <span>{t('authentication.')}</span>
          </div>
        }
        actionsPlacement="bottom"
        actions={
          <>
            {!showCreateForm && !maxNoOfTokensCreated && (
              <Button
                text={t('Create new token')}
                variant="Secondary"
                Svg={PlusInCircleIcon}
                onClick={(): void => {
                  setNewTokenDisplayName('');
                  setCardState('create-form');
                }}
                disabled={isLoading || maxNoOfTokensCreated}
              />
            )}

            {maxNoOfTokensCreated && (
              <div className="mt-2 w-full text-m-medium">{tMaxApiTokensReached(_MAX_ALLOWED_NO_OF_TOKENS)}</div>
            )}
          </>
        }
      />

      {showCreateForm && (
        <form
          data-cmptype="CompanySettingsApiTokenCard-createForm"
          className="flex flex-col items-end gap-4 border-b border-neutral-40 px-6 py-4"
          onSubmit={onSubmitForm}
        >
          <FormItem labelContent={t('Display name')} className="w-full">
            <TextInput
              value={newTokenDisplayName}
              onValueChange={setNewTokenDisplayName}
              disabled={isCreatingToken}
              autoFocus
            />
          </FormItem>
          <div className="flex w-full justify-between">
            <div className={cn(!showCreateForm && 'invisible')}>
              <Button
                text={t('Cancel')}
                variant="Secondary"
                onClick={(): void => setCardState('idle')}
                disabled={isCreatingToken}
              />
            </div>
            <Button
              text={t('Create token')}
              Svg={PlusInCircleIcon}
              variant="Secondary"
              isLoading={isCreatingToken}
              type="submit"
              disabled={!newTokenDisplayName}
            />
          </div>
        </form>
      )}

      {tokens.length > 0 && (
        <div data-cmptype="CompanySettingsApiTokenCard-tokenList">
          <div className="bg-neutral-30 px-6 py-4 text-s-medium uppercase">{t('Existing tokens')}</div>
          <div className="flex flex-col gap-2">
            {tokens
              // FYI, sorting by display name, as sorting by creation date would lead to "jumpy UI" when regenerating
              // tokens as regenerated tokens come with a new creation date.
              .sort((a, b) => (a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0))
              .map(token => {
                const isNewlyGeneratedToken = token.tokenId === generatedTokenId;

                return (
                  <div
                    data-cmptype="CompanySettingsApiTokenCard-existingTokenDetails"
                    key={token.tokenId}
                    className="flex flex-col gap-3 border-b border-neutral-40 px-6 py-4 last:border-0"
                  >
                    <div>
                      <div>
                        <span className="text-m-medium">{token.displayName}</span>
                        {!isNewlyGeneratedToken && (
                          <Tooltip
                            title={t(
                              'The full token is not shown for security reasons.\nRegenerate to see the full token.'
                            )}
                            delay={500}
                          >
                            {/*
                            Manually truncating the shown token value here, even though the server already gives us
                            us truncated values with the "get api tokens" API:
                      
                            When (re-)generating 2 tokens subsequently we have the full token value in store as that was
                            returned by the "create api token" API. In this scenario we would show the full token value
                            which wouldn't be an security issue but break the UI which is not built it...
                            */}
                            <span className="flex-1">&nbsp;({token.tokenValue.substring(0, 4)}...)</span>
                          </Tooltip>
                        )}
                      </div>
                      {!isNewlyGeneratedToken && (
                        <div className="flex whitespace-pre text-neutral-70">
                          <span>{t('Created')} </span>
                          <RelativeDateTime unixTime={token.createdAt} />
                          <span> {t('by')} </span>
                          <IdentityUser userId={token.createdBy} />
                        </div>
                      )}
                    </div>

                    {isNewlyGeneratedToken && (
                      <>
                        <div className="flex items-center gap-2">
                          <StateIcon variant="Success" />
                          <span className="text-m-medium">{t('API Token successfully created')}</span>
                        </div>
                        <div>
                          <div className="text-m-medium">{t('Copy your API key now.')}</div>
                          <div className="text-m-regular">{t("You won't be able to see and copy it again!")}</div>
                        </div>
                        <Textarea
                          className="h-auto px-2 py-1 text-neutral-90"
                          readOnly
                          rows={3}
                          value={generatedTokenValue}
                        />
                      </>
                    )}

                    <div className="flex justify-between gap-2">
                      <Button
                        text={t('Delete')}
                        Svg={DeleteIcon}
                        variant="Secondary"
                        hoverColor="Danger"
                        onClick={(): void => {
                          deleteToken(token.tokenId);
                        }}
                        isLoading={deletingTokenId === token.tokenId}
                      />
                      {isNewlyGeneratedToken && (
                        <CopyToClipboardButton text={generatedTokenValue} buttonText={t('Copy')} variant="Primary" />
                      )}
                      <Button
                        text={t('Regenerate')}
                        Svg={RefreshIcon}
                        variant="Secondary"
                        onClick={(): void => {
                          onRegenerateToken(token);
                        }}
                        isLoading={token.tokenId === regeneratingTokenId}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </CbnCard>
  );
};
