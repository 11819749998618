import { Popover, PopoverProps, styled } from '@mui/material';

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.shape.borderRadiusRoundedLg,
    boxShadow: theme.shadows[6],
    background: theme.palette.neutral[10],
  },
}));

/**
 * Thin wrapper around MUI's `Popover` component which adds some default Cbn styling.
 * The popovers width is defined by the contents width.
 *
 * Use with `CbnCardHeader` and `CbnCardBody` to get default card spacings etc.
 *
 * @example
 * ```tsx
 * <CbnPopover
 *   open={popoverOpen}
 *   anchorEl={triggerBtnRef.current}
 *   onClose={(): void => setPopoverOpen(false)}
 *   anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
 *   transformOrigin={{ vertical: 'top', horizontal: 'right' }}
 * >
 *   <CbnCardHeader
 *     title="Popover title"
 *     subText="Popover subtext"
 *     actions={<Button text="Submit" />}
 *   />
 *   <CbnCardBody>
 *     <p>Actual popover body content...</p>
 *   </CbnCardBody>
 * </CbnPopover>
 * ```
 */
export const CbnPopover: React.FC<PopoverProps> = ({ children, ...props }) => {
  return <StyledPopover {...props}>{children}</StyledPopover>;
};
