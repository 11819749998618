import { EndpointDescription } from 'services/http/http.service';

export const USERS_ENDPOINTS = {
  updateUserInfo: (firstname: string, lastname: string): EndpointDescription => ({
    url: '/users/update',
    params: { firstname, lastname },
  }),
  getUserInfo: '/users/get',
  getCompanies: '/users/getCompanies',
};
