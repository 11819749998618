import classNames from 'classnames';
import { extendTailwindMerge } from 'tailwind-merge';

/**
 * Without this, when using font style & text color classes together, the text color class would erroneously be
 * removed as twMerge does not know our theme classes and therefore thinks that both classes "touch" the same CSS styles
 * (which they don't)...
 *
 * Some resources:
 *   - https://github.com/dcastil/tailwind-merge/issues/297#issuecomment-1697023489
 *   - https://github.com/dcastil/tailwind-merge/blob/v1.14.0/docs/recipes.md#adding-custom-scale-from-tailwind-config-to-tailwind-merge-config
 *   - https://github.com/dcastil/tailwind-merge/blob/v1.14.0/docs/configuration.md#usage-with-custom-tailwind-config
 */
const twMergeExtended = extendTailwindMerge({
  classGroups: {
    'font-style': [
      'text-s-regular',
      'text-s-medium',
      'text-m-regular',
      'text-m-medium',
      'text-l-regular',
      'text-l-medium',
      'text-heading-xs',
      'text-heading-s',
      'text-heading-m',
      'text-heading-l',
      'text-table-heading',
      'text-table-heading-sub',
    ],
  },
});

export function cn(...inputs: classNames.ArgumentArray): string {
  return twMergeExtended(classNames(inputs));
}

/**
 * Tailwind CSS class string template literal function.
 * Provides the following:
 *   - Intellisense & "show underlying CSS on hover" in strings which are otherwhise not recognized as "string of
 *     Tailwind classes" by VSCode.
 *     E.g. Tailwind class names assigned to a variable or custom cmp properties which are called sthg != "className".
 *   - Prettier auto sorting of classes inside the string.
 *
 * @example
 * ```tsx
 * // Variable assignment
 * const className = tw`text-s-regular text-primary-500`;
 *
 * // In TSX:
 * <div className={tw`m-5 p-4`}>...</div>
 *
 * // Variable assignment in combination with `cn`:
 * const className = cn(tw`text-s-regular`, hasLargeText && tw`text-l-regular`);
 * ```
 *
 * FYI, c/p from comment on GitHub:
 * https://github.com/tailwindlabs/tailwindcss/discussions/7558#discussioncomment-4676228
 */
export function tw(strings: TemplateStringsArray, ...keys: any[]): string {
  const lastIndex = strings.length - 1;
  return (
    strings.slice(0, lastIndex).reduce((acc, str, i) => {
      return acc + str + keys[i];
    }, '') + strings[lastIndex]
  );
}
