import { LockIcon } from 'assets/icons';
import { IdentityUser } from 'components/identity/identity-user';
import { LinkButton } from 'controls/button/link-button';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { Icon } from 'controls/icon/icon';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'controls/table';
import { TableSkeletonRows } from 'controls/table/table-skeleton-rows';
import { useMinTimeActive } from 'hooks/common/timing.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch } from 'hooks/store/store.hooks';
import { buildAssetEditorPath } from 'services/routes/asset-editor-routes.service';
import { AssetBundleVersionPublished, setShowManageBundleVersionsPage } from 'slices/assets/assets.slice';

type AssetBundleVersionsTablePublishedProps = {
  publishedVersions: AssetBundleVersionPublished[];
  isLoading: boolean;
};

export const AssetBundleVersionsTablePublished: React.FC<AssetBundleVersionsTablePublishedProps> = ({
  publishedVersions,
  isLoading,
}) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const showLoadingSkeletons = useMinTimeActive(isLoading, 500);

  const hasNoVersions = !publishedVersions.length;

  const onGoToVersionClicked = (): void => {
    dispatch(setShowManageBundleVersionsPage(false));
  };

  return (
    <div data-cmptype="AssetBundleVersionsTablePublished" className="flex w-full flex-col overflow-y-auto">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{t('Version')}</TableCell>
            <TableCell width={200}>{t('published at')}</TableCell>
            <TableCell width={250}>{t('published by')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="">
          <>
            {showLoadingSkeletons && <TableSkeletonRows numberOfCols={3} numberOfRows={publishedVersions.length} />}

            {!showLoadingSkeletons && (
              <>
                {publishedVersions.map(publishedVersion => (
                  <TableRow key={publishedVersion.id}>
                    <TableCell>
                      <div className="flex flex-row items-center gap-1">
                        <LinkButton
                          variant="TextInline"
                          text={publishedVersion.displayName}
                          href={buildAssetEditorPath(
                            publishedVersion.companyId,
                            publishedVersion.bundleId,
                            publishedVersion.id
                          )}
                          title={t('Go to version')}
                          onClick={onGoToVersionClicked}
                        />
                        {!!publishedVersion.lockStatus && (
                          <Icon Svg={LockIcon} className="h-5 text-warning-main" title={t('Version is locked')} />
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{<RelativeDateTime unixTime={publishedVersion.publishedAt} />}</TableCell>
                    <TableCell>{<IdentityUser userId={publishedVersion.publishedBy} />}</TableCell>
                  </TableRow>
                ))}

                {hasNoVersions && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <span className="my-10 flex justify-center text-m-regular text-neutral-90">
                        {t('No bundle versions found')}
                      </span>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </>
        </TableBody>
      </Table>
    </div>
  );
};
