import { Collapse } from '@mui/material';
import { useState } from 'react';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { CbnAlert } from 'controls/cbn-alert/cbn-alert';
import { FormItem } from 'controls/form-item/form-item';
import { StateIcon } from 'controls/icon/state-icon';
import { TextInput } from 'controls/input/text-input';
import { INVALID_SCHEMA_INFO, isValidSchemaName } from 'helper/hive/hive.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { postConfiguratorHistoryCreateStage } from 'services/configuratorhistory/configuratorhistory.service';
import { Cfgr, getCompanyCfgr, selectCfgrStages } from 'slices/company-data/company-data.slice';

type CreateStageDialogProps = {
  srcAndTargetCfgr: Cfgr;
  srcVersion: string;
  /** The name shown in the dialog description text `Create a new stage from ${baseName}` */
  baseName: string;
  onConfirm?: () => void;
  onCancel: () => void;
};

export const CreateStageDialog: React.FC<CreateStageDialogProps> = ({
  srcAndTargetCfgr,
  srcVersion,
  baseName,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const [newStageName, setNewStageName] = useState('');
  const [showStageNameErrors, setShowStageNameErrors] = useState(false);
  const [isProcessingCreate, setIsProcessingCreate] = useState(false);

  const cfgrStages = useAppSelector(selectCfgrStages);
  const stageNameAlreadyExists = cfgrStages.some(x => x.name.toLowerCase() === newStageName.toLocaleLowerCase());
  const stageNameIsValid = isValidSchemaName(newStageName);

  const createStage = async (): Promise<void> => {
    setIsProcessingCreate(true);

    const { companyId, id: cfgrId } = srcAndTargetCfgr;
    await postConfiguratorHistoryCreateStage(companyId, cfgrId, newStageName, srcVersion);
    await dispatch(getCompanyCfgr({ companyId, cfgrId }));

    setIsProcessingCreate(false);
    onConfirm?.();
  };

  return (
    <ModalDialog
      header={t('New stage')}
      variant="Info"
      actions={{ onConfirm: createStage, onCancel }}
      isProcessingConfirm={isProcessingCreate}
      confirmText={t('Create stage')}
      confirmDisabled={!stageNameIsValid}
    >
      <div className="flex grow flex-col gap-4">
        <div className="text-m-regular text-neutral-70">{t(`Create a new stage from ${baseName}.`)}</div>

        <FormItem labelContent={t('Stage name')}>
          <TextInput
            autoFocus
            placeholder={t('MyStage')}
            value={newStageName}
            onValueChange={(x): void => {
              setShowStageNameErrors(true);
              setNewStageName(x);
            }}
            onBlur={(): void => setShowStageNameErrors(true)}
            error={showStageNameErrors && !stageNameIsValid}
            endAdornment={
              showStageNameErrors &&
              !stageNameIsValid && (
                <StateIcon
                  variant="Error"
                  noBckgr
                  title={<div className="whitespace-pre-line">{INVALID_SCHEMA_INFO}</div>}
                />
              )
            }
          />
        </FormItem>

        <Collapse in={stageNameAlreadyExists}>
          <CbnAlert
            severity="warning"
            className="w-auto"
            caption={t(`A stage named "${newStageName}" already exists.`)}
            content={
              <>
                <div>{t('Proceed to overwrite it or choose a different name to create a new stage.')}</div>
              </>
            }
          />
        </Collapse>
      </div>
    </ModalDialog>
  );
};
