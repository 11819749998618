/**
 * Decide where to split file name and extension
 *
 * - "AtFirstDot": `something.ext1.ext2.ext3` =>\
 * File name: something\
 * Extention: ext1.ext2.ext3
 *
 * - "AtLastDot" `something.ext1.ext2.ext3` =>\
 * File name: something.ext1.ext2\
 * Extention: ext3
 */
export type FileExtensionGetterStrategy = 'AtFirstDot' | 'AtLastDot';

export function getFileExtension(fileName: string, strategy: FileExtensionGetterStrategy = 'AtLastDot'): string {
  const arrPath = fileName.split('.');
  if (arrPath.length < 2) {
    // no dot found => no extension available
    return '';
  }

  let extension = '';
  if (strategy === 'AtFirstDot') {
    arrPath.splice(0, 1);
    extension = arrPath.join('.');
  } else if (strategy === 'AtLastDot') {
    extension = arrPath.pop() || '';
  }

  return extension;
}

export function getFileNameWithoutExtension(
  fileName: string,
  strategy: FileExtensionGetterStrategy = 'AtFirstDot'
): string {
  const extension = getFileExtension(fileName, strategy);
  const replaceRegex = new RegExp(`.${extension}$`);

  const name = extension ? fileName.replace(replaceRegex, '') : fileName;
  return name;
}

export function getReadableFileSize(bytes: number, decimalPlaces = 2): string {
  if (Math.abs(bytes) < 1024) {
    return bytes + ' B';
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  const r = 10 ** decimalPlaces;

  do {
    bytes /= 1024;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= 1024 && u < units.length - 1);

  return bytes.toFixed(decimalPlaces) + ' ' + units[u];
}

export function downloadBlob(blob: Blob, filename: string): void {
  // create file link in browser's memory
  const href = URL.createObjectURL(blob);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

/**
 * Show a file selection dialog programmatically
 *
 * @returns array of selected files
 */
export async function showFileUploadDialog(options?: { accept?: string; multiple?: boolean }): Promise<File[]> {
  return new Promise(resolve => {
    const inputEl = document.createElement('input');
    inputEl.type = 'file';
    inputEl.hidden = true;

    if (options) {
      if (options.accept) {
        inputEl.accept = options.accept;
      }
      inputEl.multiple = options.multiple ?? false;
    }

    inputEl.addEventListener('change', (e: any): void => {
      if (e.currentTarget.files) {
        resolve(Array.from(e.currentTarget.files));
      } else {
        resolve([]);
      }
    });
    inputEl.click();
  });
}
