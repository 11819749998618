import { Suspense } from 'components/common/suspense';
import { MonacoEditorFactoryProps } from 'controls/monaco-editor/monaco-editor-factory';
import { lazyWithFallback } from 'helper/import/import.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

export const DUMMY_MONACO_PATH = '_empty'; // empty string can lead to errors (related to monaco models)

const MonacoEditorFactory = lazyWithFallback(
  () => import(/* webpackChunkName: "monaco-editor" */ 'controls/monaco-editor/monaco-editor-factory'),
  'Monaco editor'
);

export const MonacoEditor: React.FC<MonacoEditorFactoryProps> = props => {
  const { t } = useTypedTranslation();

  /**
   * CAUTION: Lazy loading & bundle size
   * This is only a wrapper for lazy loading
   * Any logic specific to monaco should be done in the factory
   *
   * - If a type is required elsewhere it's recommended to use `import type ...` for better awareness
   * - TODO: How to use enums/objects outside of the factory without increasing the main bundle size?
   *         E.g. `monaco.MarkerSeverity.Error`
   *
   * @example `workflow-code-editor.tsx`
   */

  return (
    <div data-cmptype="MonacoEditor" className="relative h-full w-full">
      {/** ErrorEvent: ResizeObserver loop limit exceeded (see also `telemetry.service.ts`)
       *   There might be an issue related to the flex layout or working with `w-full` in general
       *   Even setting the width with a custom observer & throttling didn't solve the issue
       *   For now the message is hidden from the user
       */}
      <div className="absolute inset-0">
        <Suspense fallbackText={t('Initializing editor...')}>
          <MonacoEditorFactory {...props}></MonacoEditorFactory>
        </Suspense>
      </div>
    </div>
  );
};
