import { Collapse } from '@mui/material';
import { useState } from 'react';
import { PlusIcon } from 'assets/icons';
import { StagesTable } from 'components/cfgrs/stages/stages-table';
import { CreateStageDialog } from 'components/common/create-stage-dialog';
import { Button } from 'controls/button/button';
import { useCbnCard } from 'controls/cbn-card/cbn-card-provider';
import { cn } from 'helper/css.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { Stage } from 'services/company-data/company-data.service';
import {
  Cfgr,
  selectSelectedCfgrDetailsFetchState,
  selectSelectedCompany,
} from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

type StagesCardBodyProps = {
  stages: Stage[];
  selectedCfgr: Cfgr;
};

export const StagesCardBody: React.FC<StagesCardBodyProps> = ({ stages, selectedCfgr }) => {
  const { t } = useTypedTranslation();
  const { collapsed } = useCbnCard();
  const hasStages = stages.length > 0;
  const hasManageDraftPermission = usePermission(CompanyPermissions.ManageDraft, selectedCfgr.companyId);

  const selectedCompany = useAppSelector(selectSelectedCompany);
  const maxNoOfStagesReached = (selectedCompany?.companyFeatures.maxCfgrStages ?? 0) <= stages.length;

  const selectedCfgrDetailsFetchState = useAppSelector(selectSelectedCfgrDetailsFetchState);
  const isFetchingCfgrDetails = selectedCfgrDetailsFetchState === 'Pending';

  const [createStageOpen, setCreateStageOpen] = useState(false);

  return (
    <>
      <Collapse data-cmptype="StagesCardBody" in={!collapsed}>
        <div className="flex flex-col">
          {selectedCfgrDetailsFetchState === 'Fetched' && !hasStages && (
            <div className="flex flex-col items-center gap-5 px-5 pb-2 pt-5">
              <div>
                <div className="text-center">
                  {t('A stage version is a fully functional version of your configurator.')}
                </div>
                <div>{t('You can use a stage to test your configurator before you publish it live.')}</div>
              </div>

              <div className="text-center">{t('At present, there are no stage versions for this configurator.')}</div>
            </div>
          )}

          {(hasStages || isFetchingCfgrDetails) && (
            <StagesTable
              stages={stages}
              selectedCfgr={selectedCfgr}
              showLoadingSkeletons={isFetchingCfgrDetails && !hasStages}
            />
          )}

          {hasManageDraftPermission && selectedCfgr.isPublished && !selectedCfgr.cfgrFeatures.readonly && (
            <div className={cn('px-5 py-3', !hasStages && !isFetchingCfgrDetails && 'mb-2 self-center')}>
              <Button
                variant="Secondary"
                text={t('New stage')}
                disabled={maxNoOfStagesReached}
                tooltipCmp={
                  maxNoOfStagesReached
                    ? t('Maximum number of allowed stages reached')
                    : t('Create a new stage from published configurator')
                }
                Svg={PlusIcon}
                onClick={(): void => setCreateStageOpen(true)}
              />
            </div>
          )}
        </div>
      </Collapse>

      {selectedCfgr && createStageOpen && (
        <CreateStageDialog
          srcAndTargetCfgr={selectedCfgr}
          srcVersion={selectedCfgr.version}
          baseName={t('published configurator')}
          onCancel={(): void => setCreateStageOpen(false)}
          onConfirm={(): void => setCreateStageOpen(false)}
        />
      )}
    </>
  );
};
