import Paper from '@mui/material/Paper';
import MuiTable, { TableProps as MuiTableProps } from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';
import { TableBodyProps } from 'controls/table/table-body';
import { TableHeadProps } from 'controls/table/table-head';

// remove round corners
const StyledPaper = styled(props => <Paper {...props} square />)({});

type TableProps = Pick<MuiTableProps, 'stickyHeader'> & {
  containerClassName?: string;
  children:
    | React.ReactElement<TableHeadProps>
    | React.ReactElement<TableHeadProps>[]
    | React.ReactElement<TableBodyProps>
    | React.ReactElement<TableBodyProps>[];
};

/**
 * @example
 * ```tsx
 * <Table>
 *   <TableHead>
 *     <TableRow>
 *       // `w-0` on header cells is used for intrinsic sizing of the column based on the content.
 *       // All cells without explicit spacing share the remaining space equally.
 *       <TableCell className="w-0">First name</TableCell>
 *       <TableCell className="w-0">Second name</TableCell>
 *       <TableCell>Email</TableCell>
 *     </TableRow>
 *   </TableHead>
 *
 *   <TableBody>
 *     <TableRow>
 *       <TableCell>John</TableCell>
 *       <TableCell>Doe</TableCell>
 *       <TableCell>john.doe@big-corp.com</TableCell>
 *     </TableRow>
 *     ...
 *   </TableBody>
 * </Table>
 * ```
 */
export const Table: React.FC<TableProps> = ({ children, containerClassName, ...props }) => {
  return (
    <TableContainer component={StyledPaper} className={containerClassName}>
      <MuiTable {...props}>{children}</MuiTable>
    </TableContainer>
  );
};
