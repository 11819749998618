import { createContext, useCallback, useEffect, useId, useState } from 'react';
import {
  PanelGroup as BasePanelGroup,
  PanelGroupProps as BasePanelGroupProps,
  getPanelGroupElement,
} from 'react-resizable-panels';
import { Direction } from 'react-resizable-panels/dist/declarations/src/types';
import { distinctFilter } from 'helper/array/array.helper';
import { useElementSize } from 'hooks/common/sizing.hooks';

export type CbnResizeablePanelSizeMode = 'percentage' | 'pixel';

// NOTE: don't forget to update this constant if the size of the separator component changes for some reason!
// Still it's much easier to handle this as a constant instead of having to check size of child separator components.
const _SEPARATOR_SIZE_PX = 25;

type CbnResizeablePanelGroupContextState = {
  direction: Direction;
  /** defines unit of sizing props (percentage vs pixel) */
  sizeMode: CbnResizeablePanelSizeMode;
  /** required for pixel to percentage conversion of child panels */
  availablePanelGroupSizePx: number;
  /** required for counting the separators within the panel group => also used for px to perc calculation */
  addSeparator: (id: string) => void;
  removeSeparator: (id: string) => void;
};

const contextState: CbnResizeablePanelGroupContextState = {
  direction: 'horizontal',
  sizeMode: 'percentage',
  availablePanelGroupSizePx: 0,
  addSeparator: () => {},
  removeSeparator: () => {},
};

export const CbnResizeablePanelGroupContext = createContext(contextState);

type CbnResizablePanelGroupProps = Pick<BasePanelGroupProps, 'children' | 'direction' | 'autoSaveId'> & {
  sizeMode?: CbnResizeablePanelSizeMode;
};

export const CbnResizablePanelGroup: React.FC<CbnResizablePanelGroupProps> = ({
  children,
  direction,
  sizeMode = 'percentage',
  autoSaveId,
}) => {
  const { ref: htmlRef, size: totalPanelGroupSizeRect } = useElementSize();
  const uniqueId = useId();

  const [seperatorIds, setSeperatorIds] = useState<string[]>([]);

  useEffect(() => {
    // find this group panel HTML element, as the ref of the base component is overwritten with an API object
    const groupElement = getPanelGroupElement(uniqueId);
    htmlRef(groupElement);
  }, [htmlRef, uniqueId]);

  // total panel size from element resize listener
  const totalPanelGroupSizePx =
    (direction === 'horizontal' ? totalPanelGroupSizeRect?.width : totalPanelGroupSizeRect?.height) ?? 0;

  // subtract size of separators
  const availablePanelGroupSizePx = totalPanelGroupSizePx
    ? totalPanelGroupSizePx - _SEPARATOR_SIZE_PX * seperatorIds.length
    : 0;

  const addSeparator = useCallback((id: string): void => {
    setSeperatorIds(curIds => [...curIds, id].filter(distinctFilter));
  }, []);

  const removeSeparator = useCallback((id: string): void => {
    setSeperatorIds(curIds => curIds.filter(curId => curId !== id));
  }, []);

  return (
    <CbnResizeablePanelGroupContext.Provider
      value={{ direction, sizeMode, availablePanelGroupSizePx, addSeparator, removeSeparator }}
    >
      <BasePanelGroup direction={direction} autoSaveId={autoSaveId} id={uniqueId}>
        {children}
      </BasePanelGroup>
    </CbnResizeablePanelGroupContext.Provider>
  );
};
