import { styled } from '@mui/material';
import { Input } from 'controls/input/input';

export const InputAsSelectedChip = styled(Input)(({ theme }) => ({
  'color': theme.palette.primary.main,
  'background': theme.palette.primary.surface,
  'borderWidth': '2px',
  'borderColor': theme.palette.primary.main,
  'borderRadius': theme.shape.borderRadiusRoundedLg,

  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
  '&:active': {
    borderColor: theme.palette.primary.main,
  },
  '&.Mui-focused': {
    'outline': 'none',
    'borderColor': theme.palette.primary.main,

    '&:active': {
      borderColor: theme.palette.primary.main,
    },
  },
}));
