import { Navigate, Outlet, useMatch } from 'react-router-dom';
import { LinkButton } from 'controls/button/link-button';
import { CBN_MAIL_ADRESSES } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { PATHS } from 'services/routes/paths.service';
import { selectLoggedIn } from 'slices/auth/auth.slice';

export const AnonymousUserRouteWrapper: React.FC = () => {
  const { t } = useTypedTranslation();
  const loggedIn = useAppSelector(selectLoggedIn);
  const invitePathMatch = useMatch(PATHS.acceptCompanyInvitation);
  const invalidInvitePathMatch = useMatch(PATHS.acceptCompanyInvitationInvalid);

  if (loggedIn && !invitePathMatch && !invalidInvitePathMatch) {
    return <Navigate to={PATHS.home} />;
  }

  return (
    <div
      data-cmptype="AnonymousUserRouteWrapper"
      className="flex w-full flex-col items-center gap-10 overflow-auto p-6"
    >
      <div className="self-end text-s-regular">
        {t('Need help? ')}
        <LinkButton variant="TextInline" href={`mailto:${CBN_MAIL_ADRESSES.info}`} text={t('Contact us')} />
      </div>

      <Outlet />
    </div>
  );
};
