import { LinkButton } from 'controls/button/link-button';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { buildCfgrsPath } from 'services/routes/cfgrs-routes.service';
import { selectSelectedConfigurator } from 'slices/company-data/company-data.slice';

type CfgrDeletedInfoWrapperProps = {
  children: React.ReactNode;
};

/**
 * Checks if the currently selected cfgr is deleted and shows a message if so. Otherwise, renders the given children.
 */
export const CfgrDeletedInfoWrapper: React.FC<CfgrDeletedInfoWrapperProps> = ({ children }) => {
  const { t } = useTypedTranslation();
  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const cfgrIsDeleted = !!selectedCfgr?.deleteAfter;

  return (
    <>
      {cfgrIsDeleted && (
        <>
          <div>{t('The requested information is not available anymore as the configurator has been deleted.')}</div>
          <div>
            <span>{t('Go to the ')}</span>
            <LinkButton
              href={buildCfgrsPath('dashboard', selectedCfgr.companyId)}
              variant="TextInline"
              text={t('dashboard')}
            />
            <span>{t(' for more information.')}</span>
          </div>
        </>
      )}

      {!cfgrIsDeleted && children}
    </>
  );
};
