import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { PATHS } from 'services/routes/paths.service';
import { WorkflowsRoutePages, WorkflowsRouteParams } from 'services/routes/workflows-routes.service';
import {
  WorkflowPath,
  createWorkflow,
  getAllWorkflows,
  getWorkflow,
  getWorkflowRun,
  getWorkflowRunLog,
  selectSelectedWorkflowsPath,
  selectWorkflowRunOfSelectedWorkflow,
  selectWorkflows,
  setWorkflowSelection,
} from 'slices/workflows/workflows.slice';

interface WorkflowsNavigateFunction {
  (workflowPath: Partial<WorkflowPath>, page?: WorkflowsRoutePages, replace?: boolean): void;
}

interface CreateWorkflowFunction {
  (workflowName: string, displayName: string): Promise<void>;
}

/**
 * This hook returns a helper function for easier navigation in the workflows
 */
export const useNavigateToWorkflows = (): WorkflowsNavigateFunction => {
  const navigate = useNavigate();

  /**
   * @param workflowPath
   * @param replace True to replace the current entry in the history stack instead of adding a new one.
   *                I.e. remove the current route from the stack.
   */
  const customNav: WorkflowsNavigateFunction = useCallback(
    (workflowPath, page = 'detail', replace = false) => {
      const path = PATHS.buildWorkflowsPath(
        workflowPath.companyId || '',
        workflowPath.workflowName,
        page,
        workflowPath.workflowRunId
      );

      navigate(path, { replace });
    },
    [navigate]
  );

  return customNav;
};

export const useCreateWorkflow = (navigateToNewWorkflow: boolean = true): CreateWorkflowFunction => {
  const dispatch = useAppDispatch();
  const navigateToWorkflow = useNavigateToWorkflows();

  const selectedPath: WorkflowPath = useAppSelector(selectSelectedWorkflowsPath);

  const createWorkflowFn: CreateWorkflowFunction = async (workflowName, displayName) => {
    await dispatch(createWorkflow({ companyId: selectedPath.companyId, workflowName, displayName }));
    // `createWorkflow` doesn't have a return value so re-navigate in any case, if not aborted by input parameter
    if (navigateToNewWorkflow) {
      navigateToWorkflow({ companyId: selectedPath.companyId, workflowName });
    }
  };

  return createWorkflowFn;
};

/**
 * @returns A route pointing to the workflows defined via `selectedPath` in the assets store
 */
export const useWorkflowsRouteToStateSelection = (): string => {
  const companyId = useAppSelector(state => state.companyData.selection.companyId);
  const { workflowName, workflowRunId } = useAppSelector(state => state.workflows).selection;

  return PATHS.buildWorkflowsPath(companyId, workflowName, workflowRunId ? 'run' : 'detail', workflowRunId);
};

/**
 * Responsible for keeping the route and selection-state of the workflow-slice in sync
 * The route is the "source of truth" and navigation only happens if a dynamic param isn't valid
 */
export const useWorkflowRouteSync = (): void => {
  const dispatch = useAppDispatch();
  const routeParams = useParams<WorkflowsRouteParams>();
  const navigateToWorkflows = useNavigateToWorkflows();

  const fetchState = useAppSelector(state => state.workflows.fetchState);
  const selectionState = useAppSelector(state => state.workflows.selection);
  const workflows = useAppSelector(selectWorkflows);
  const wfRuns = useAppSelector(selectWorkflowRunOfSelectedWorkflow);

  const hasPendingWfNameChange =
    fetchState.workflows === 'Fetched' && (routeParams.workflowName ?? '') !== selectionState.workflowName;
  const validatedWfName = routeParams.workflowName
    ? workflows.find(wfName => wfName.workflowName.toLowerCase() === routeParams.workflowName?.toLowerCase())
        ?.workflowName
    : '';

  const hasPendingWfIdChange =
    fetchState.workflowRuns === 'Fetched' && (routeParams.workflowRunId ?? '') !== selectionState.workflowRunId;
  const isValidWfRunRoute =
    !routeParams.workflowRunId || !!wfRuns.find(wfRun => wfRun.workflowRunId === routeParams.workflowRunId);

  useEffect(
    function handleWfNameChange() {
      if (hasPendingWfNameChange) {
        if (validatedWfName || !routeParams.workflowName) {
          dispatch(setWorkflowSelection({ workflowName: validatedWfName }));
        } else {
          navigateToWorkflows({ companyId: routeParams.companyId });
        }
      }
    },
    [dispatch, navigateToWorkflows, routeParams, hasPendingWfNameChange, validatedWfName]
  );

  useEffect(() => {
    async function handleWfIdChange(): Promise<void> {
      if (hasPendingWfIdChange) {
        if (isValidWfRunRoute) {
          dispatch(setWorkflowSelection({ workflowRunId: routeParams.workflowRunId }));
        } else if (routeParams.companyId && routeParams.workflowName && routeParams.workflowRunId) {
          try {
            // workflow run is not available in the latest (20) entries, try to fetch it directly
            // this might fail, as the run id from the route can just be invalid
            await dispatch(
              getWorkflowRun({
                companyId: routeParams.companyId,
                workflowName: routeParams.workflowName,
                workflowRunId: routeParams.workflowRunId,
              })
            ).unwrap();
          } catch (e) {
            navigateToWorkflows({ companyId: routeParams.companyId, workflowName: routeParams.workflowName });
          }
        } else {
          navigateToWorkflows({ companyId: routeParams.companyId, workflowName: routeParams.workflowName });
        }
      }
    }
    handleWfIdChange();
  }, [dispatch, navigateToWorkflows, routeParams, hasPendingWfIdChange, isValidWfRunRoute]);
};

/**
 * Responsible for fetching new data whenever a selection changes
 */
export const useWorkflowFetcher = (): void => {
  const dispatch = useAppDispatch();

  const { companyId, workflowName, workflowRunId } = useAppSelector(selectSelectedWorkflowsPath);
  const fetchState = useAppSelector(state => state.workflows.fetchState);

  useEffect(() => {
    dispatch(getAllWorkflows({ companyId }));
  }, [dispatch, companyId]);

  useEffect(() => {
    if (fetchState.workflows === 'Fetched' && workflowName) {
      dispatch(getWorkflow({ companyId, workflowName: workflowName }));
    }
  }, [dispatch, companyId, workflowName, fetchState.workflows]);

  useEffect(() => {
    if (fetchState.workflowRuns === 'Fetched' && workflowRunId) {
      dispatch(getWorkflowRunLog({ companyId: companyId, workflowName: workflowName, workflowRunId: workflowRunId }));
    }
  }, [dispatch, companyId, workflowName, workflowRunId, fetchState.workflowRuns]);
};
