type TooltipContentProps = {
  header?: React.ReactNode;
  detail: React.ReactNode;
};
export const TooltipContent: React.FC<TooltipContentProps> = ({ header, detail }) => {
  return (
    <div className="my-1 flex flex-col gap-1">
      {header && <div className="text-s-medium text-neutral-100">{header}</div>}
      <div className="whitespace-pre-wrap text-s-regular text-neutral-90">{detail}</div>
    </div>
  );
};
