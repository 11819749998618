import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { unstable_usePrompt } from 'react-router-dom';
import { SaveIcon, WarningIcon } from 'assets/icons';
import { MaterialAssetEditorBasic } from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor-basic';
import { MaterialAssetEditorExpert } from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor-expert';
import { Button } from 'controls/button/button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { CbnLoadMask } from 'controls/cbn-load-mask/cbn-load-mask';
import { CbnTabPanel } from 'controls/cbn-tab-panel/cbn-tab-panel';
import { Icon } from 'controls/icon/icon';
import { AssetWarningTypes } from 'generated/asset-warning-types';
import { createSourceStringFromFolderAndAssetId } from 'helper/assets/assets.helper';
import { useIsAssetBundleEditable } from 'hooks/assets/assets.hooks';
import { useMinTimeActive } from 'hooks/common/timing.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { postAssetLinksBatchLinkMaterialFromImage, postAssetMaterialContent } from 'services/assets/assets.service';
import {
  MaterialAsset,
  getBundleVersionData,
  isPbrMaterialAsset,
  selectSelectedAsset,
} from 'slices/assets/assets.slice';
import {
  applyMaterialDataAndLinkedTexturesChanges,
  changeMaterialProperty,
  clearMaterialAssetState,
  getSyncedMaterialDataAndLinkedTextures,
  resetMaterialDataAndLinkedTexturesChanges,
  selectGetChangedLinkedTextures,
  selectGetCurMaterialDataWithBase64Strings,
  selectHasLinkedTexturesChanges,
  selectHasMaterialDataChanges,
  setMaterialAssetUIState,
} from 'slices/material-asset/material-asset.slice';

export type MaterialEditorState = 'Valid' | 'Invalid' | 'Editing';
export type MaterialEditorTabPanelKey = 'Basic' | 'Expert';

export const MaterialAssetEditor: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const isAssetBundleEditable = useIsAssetBundleEditable();

  const selectedAsset = useAppSelector(selectSelectedAsset) as MaterialAsset;
  const {
    warningBag: { items: warnings },
    path: { companyId, bundleId, bundleVersion, folderId, assetId },
  } = selectedAsset;
  const hasLinkedTextureWarnings = warnings.some(w => w.$type === AssetWarningTypes.MissingTextureImage);

  const {
    syncedMaterialType,
    ui: { isLoading, isSaving, editorState },
  } = useAppSelector(state => state.materialAsset);
  const curMaterialDataWithBase64Strings = useAppSelector(selectGetCurMaterialDataWithBase64Strings);
  const hasMaterialDataChanges = useAppSelector(selectHasMaterialDataChanges);
  const hasLinkedTexturesChanges = useAppSelector(selectHasLinkedTexturesChanges);
  const { texturesToLink, texturesToUnlink } = useAppSelector(selectGetChangedLinkedTextures);
  const isNonPbrMaterial = !isPbrMaterialAsset(selectedAsset);

  const showLoadMask = useMinTimeActive(isLoading, 500);
  const [tabPanelKey, setTabPanelKey] = useState<MaterialEditorTabPanelKey>('Basic');

  unstable_usePrompt({
    when: hasMaterialDataChanges || hasLinkedTexturesChanges || editorState !== 'Valid',
    message: t('Discard unsaved changes?'),
  });

  useEffect(
    function clearStateOnUnmount() {
      return (): void => {
        dispatch(clearMaterialAssetState());
      };
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getSyncedMaterialDataAndLinkedTextures({ companyId, bundleId, bundleVersion, folderId, assetId }));
    // NOTE: it's important to pass the individual path sub props instead of the path object to avoid undesired
    // executions of this useEffect
    // the path object may change (shallow copy), while the content actually remains the same
  }, [dispatch, companyId, bundleId, bundleVersion, folderId, assetId]);

  useEffect(() => setTabPanelKey(isNonPbrMaterial ? 'Expert' : 'Basic'), [isNonPbrMaterial]);

  const onDiscardBtnClicked = (): void => {
    dispatch(resetMaterialDataAndLinkedTexturesChanges());
  };

  const onSaveBtnClicked = async (): Promise<void> => {
    dispatch(setMaterialAssetUIState({ isSaving: true }));

    if (hasMaterialDataChanges) {
      // data may have changed in expert editor, set back to default values
      const finalMaterialContent = cloneDeep(curMaterialDataWithBase64Strings);

      // restore correct customType before saving as it could have been changed in the expert editor
      // also write back into the store
      dispatch(changeMaterialProperty({ key: 'customType', value: syncedMaterialType }));
      finalMaterialContent.customType = syncedMaterialType;

      await postAssetMaterialContent(companyId, bundleId, bundleVersion, folderId, assetId, finalMaterialContent);
    }

    if (hasLinkedTexturesChanges) {
      const source = createSourceStringFromFolderAndAssetId(folderId, assetId);

      await postAssetLinksBatchLinkMaterialFromImage(
        companyId,
        bundleId,
        bundleVersion,
        source,
        texturesToLink,
        texturesToUnlink
      );
    }

    // fetch new asset links
    dispatch(getBundleVersionData({ companyId, bundleId, bundleVersion }));

    // set the "syncedMaterialData" and "syncedLinkedTextures" manually instead of relying on the server update to do
    // it as this saves a lot of time
    dispatch(applyMaterialDataAndLinkedTexturesChanges());
    dispatch(setMaterialAssetUIState({ isSaving: false }));
  };

  return (
    <CbnCard data-cmptype="MaterialAssetEditor" grow>
      <CbnCardHeader
        variant="large-title"
        title={t('Material asset properties')}
        subText={t('Edit the properties of this material asset')}
        actions={
          isAssetBundleEditable && (
            <div className="flex items-center gap-2">
              <Button
                text={t('Discard changes')}
                variant="Secondary"
                disabled={
                  !(editorState === 'Invalid' || hasMaterialDataChanges || hasLinkedTexturesChanges) || isSaving
                }
                onClick={onDiscardBtnClicked}
              />
              <Button
                text={t('Save changes')}
                Svg={SaveIcon}
                disabled={
                  !(editorState === 'Valid') || (!hasMaterialDataChanges && !hasLinkedTexturesChanges) || isSaving
                }
                onClick={onSaveBtnClicked}
                title={editorState === 'Valid' ? '' : t('Cannot save because JSON content is erroneous!')}
                isLoading={isSaving}
              />
            </div>
          )
        }
      />

      <CbnTabPanel
        entries={[
          {
            name: (
              <div className="flex items-center gap-2">
                {t('Basic')}
                {hasLinkedTextureWarnings && <Icon Svg={WarningIcon} className="w-5 text-warning-main" />}
              </div>
            ),
            component: <MaterialAssetEditorBasic />,
            key: 'Basic',
            disabled: isNonPbrMaterial,
            title: isNonPbrMaterial ? t('Basic editor is only available for PBR materials') : '',
          },
          {
            name: t('Expert'),
            component: <MaterialAssetEditorExpert />,
            key: 'Expert',
          },
        ]}
        value={tabPanelKey}
        onTabValueChange={(key): void => setTabPanelKey(key as MaterialEditorTabPanelKey)}
      />

      <CbnLoadMask active={showLoadMask} spinner transparent="semi" text={t('Loading material asset data')} />
    </CbnCard>
  );
};
