import { PayloadAction, createSelector } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { globalReset } from 'actions/general.actions';
import { compareFlat, distinctFilter } from 'helper/array/array.helper';
import { RootState } from 'services/store/store.service';

/**
 * This slice holds data of the currently selected babylon asset or none if no babylon asset is selected.
 */

/**
 * Only auto-expand all the tree nodes if the 3d model has a "reasonable" node count.
 * This is more or less an arbitrary value based on two factors:
 * - Does it make sense for the user to have so many nodes expanded at once
 * - Performance of TreeView (not very good yet)
 */
const _FULL_TREE_EXPAND_MAX_COUNT = 100;

export type BabylonAssetNode = {
  name: string;
  isMesh: boolean;
  // state from server
  syncedMaterialId: string;
  syncedTags: string[];
  // current state from UI
  curMaterialId: string;
  curTags: string[];
  // hierarchy
  childs: BabylonAssetNode[];
  parentNodeName?: string;
  // instanced mesh relation
  instancedMeshNames: string[];
  sourceMeshName?: string;
};

export type BabylonAssetState = {
  // Possible future refactoring:
  //
  // I'm not sure if this is good state design. We're saving a list of deeply nested objects, where the ID is inside the
  // objects.
  // I remember that we sometime talked about structures where there should be flat lists with IDs to simplify
  // findability etc. but I'm not sure if we explicitly decided on a pattern here or not. ATM I don't find the current
  // structure too complicated but I have to use some helper functions to get to the deeply nested data
  // (`_getNodesFlat`, `_getFlatIdsFromNodes` etc.) which is ok for me.
  nodes: BabylonAssetNode[];
  ui: {
    isLoading: boolean;
    isSaving: boolean;
    selectedNodeName: string;
    enabledNodeNames: string[];
    expandedNodeNames: string[];
    nodeTreeTagFilter: string;
    showNodeSettings: boolean;
    expandedNodeSettingsAccordions: string[];
  };
};

const initialState: BabylonAssetState = {
  nodes: [],
  ui: {
    isLoading: false,
    isSaving: false,
    selectedNodeName: '',
    enabledNodeNames: [],
    expandedNodeNames: [],
    nodeTreeTagFilter: '',
    showNodeSettings: false,
    expandedNodeSettingsAccordions: [],
  },
};

/**
 * @returns A flat list of all nodes within the given deeply nested `nodes` array
 */
export function getBabylonNodesFlat(nodes: BabylonAssetNode[]): BabylonAssetNode[] {
  const getNodesRecursive = (x: BabylonAssetNode): BabylonAssetNode[] => {
    const childs = x.childs.length ? x.childs.flatMap(getNodesRecursive) : [];
    return [...childs, x];
  };
  const flatNodes = nodes.flatMap(getNodesRecursive);
  return flatNodes;
}

function _getAllParentNodeNames(node: BabylonAssetNode, allNodes: BabylonAssetNode[]): string[] {
  const flatNodes = getBabylonNodesFlat(allNodes);

  const parentNodeNames: string[] = [];
  const addToParentNodeNames = (curNode: BabylonAssetNode): void => {
    const parent = flatNodes.find(node => node.name === curNode.parentNodeName);
    if (parent) {
      parentNodeNames.push(parent.name);
      addToParentNodeNames(parent);
    }
  };
  addToParentNodeNames(node);

  return parentNodeNames;
}

const SLICE_NAME = 'babylonAsset';

const babylonAssetSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    setNodes: (state, { payload: nodes }: PayloadAction<BabylonAssetNode[]>) => {
      const flatNodes = getBabylonNodesFlat(nodes);

      state.ui.selectedNodeName = '';
      state.ui.nodeTreeTagFilter = '';
      state.ui.expandedNodeNames = _getInitialExpandedNodes(flatNodes);
      state.ui.enabledNodeNames = flatNodes.map(x => x.name);

      state.nodes = nodes;
    },
    selectNode: (state, { payload: name }: PayloadAction<string>) => {
      const flatNodes = getBabylonNodesFlat(state.nodes);
      const selectedNode = flatNodes.find(node => node.name === name);
      if (selectedNode) {
        // makes sure that the path to the new selected node is expanded
        const parentNodeNames = _getAllParentNodeNames(selectedNode, state.nodes);
        state.ui.expandedNodeNames = state.ui.expandedNodeNames.concat(parentNodeNames).filter(distinctFilter);
        state.ui.selectedNodeName = name;
      } else {
        // selected node not found, reset
        state.ui.selectedNodeName = initialState.ui.selectedNodeName;
      }
    },
    setBabylonAssetUIState: (state, { payload }: PayloadAction<Partial<BabylonAssetState['ui']>>) => {
      state.ui = { ...state.ui, ...payload };
    },
    toggleNodeVisibilityInPreview: (state, { payload: nodeName }: PayloadAction<string>) => {
      // finds the node with the given name in the `nodes` structure of this slice
      const nodesFlat = getBabylonNodesFlat(state.nodes);
      const node = nodesFlat.find(x => x.name === nodeName);
      if (!node) {
        return;
      }

      // evaluate new enabled state
      const parentNodeNames = _getAllParentNodeNames(node, state.nodes);
      const enable = !state.ui.enabledNodeNames.includes(nodeName);
      const childNodeNames = getBabylonNodesFlat([node]).map(x => x.name);

      if (enable) {
        state.ui.enabledNodeNames = [...state.ui.enabledNodeNames, ...parentNodeNames, ...childNodeNames].filter(
          distinctFilter
        );
      } else {
        state.ui.enabledNodeNames = state.ui.enabledNodeNames.filter(
          enabledNodeName => !childNodeNames.includes(enabledNodeName)
        );
      }
    },
    setMaterialOfNode: (
      state,
      { payload: { nodeName, materialId } }: PayloadAction<{ nodeName: string; materialId: string }>
    ) => {
      const nodesFlat = getBabylonNodesFlat(state.nodes);
      const node = nodesFlat.find(x => x.name === nodeName);
      if (!node) {
        return;
      }

      node.curMaterialId = materialId;
      // set for instanced meshes as well
      node.instancedMeshNames.forEach(instancedMeshName => {
        const instancedMesh = nodesFlat.find(node => node.name === instancedMeshName);
        instancedMesh!.curMaterialId = materialId;
      });
    },
    resetMaterialOfNode: (state, { payload: nodeName }: PayloadAction<string>) => {
      const nodesFlat = getBabylonNodesFlat(state.nodes);
      const node = nodesFlat.find(x => x.name === nodeName);
      if (!node) {
        return;
      }

      // re-use `setMaterialOfNode` case reducer to reduce code duplication
      babylonAssetSlice.caseReducers.setMaterialOfNode(state, {
        type: 'setMaterialOfNode',
        payload: { nodeName, materialId: node.syncedMaterialId },
      });
    },
    toggleTagOfNode: (
      state,
      { payload: { nodeName, tagName } }: PayloadAction<{ nodeName: string; tagName: string }>
    ) => {
      const nodesFlat = getBabylonNodesFlat(state.nodes);
      const node = nodesFlat.find(x => x.name === nodeName);
      if (!node) {
        return;
      }

      // add or remove tag, depending on current tag state
      const add = !node.curTags.includes(tagName);
      if (add) {
        node.curTags = [...node.curTags, tagName];
      } else {
        node.curTags = node.curTags.filter(curTag => curTag !== tagName);
      }
    },
    resetTagsOfNode: (state, { payload: nodeName }: PayloadAction<string>) => {
      const nodesFlat = getBabylonNodesFlat(state.nodes);
      const node = nodesFlat.find(x => x.name === nodeName);
      if (!node) {
        return;
      }

      node.curTags = [...node.syncedTags];
    },
    applyChangesOfNode: state => {
      // overwrite server material and tag state with the current UI state
      // this can be used a short to avoid bootstrapping the babylon file again
      // => "optimistic update"
      const nodesFlat = getBabylonNodesFlat(state.nodes);
      nodesFlat.forEach(node => {
        node.syncedMaterialId = node.curMaterialId;
        node.syncedTags = [...node.curTags];
      });
    },
    resetChangesOfNode: state => {
      // overwrite current UI material and tag state of all nodes with server tag state
      const nodesFlat = getBabylonNodesFlat(state.nodes);
      nodesFlat.forEach(node => {
        node.curMaterialId = node.syncedMaterialId;
        node.curTags = [...node.syncedTags];
      });
    },
    setNodeSettingsVisibility: (state, { payload: isVisible }: PayloadAction<boolean>) => {
      state.ui.showNodeSettings = isVisible;
    },
    setExpandedNodeSettingsAccordions: (state, { payload }: PayloadAction<string[]>) => {
      state.ui.expandedNodeSettingsAccordions = payload;
    },
    clearBabylonAssetState: () => {
      // right now there is no state that's persisted across different babylon assets
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(globalReset, () => initialState);
  },
});

export const selectBabylonAssetAllNodesFlat = createSelector(
  (state: RootState) => state.babylonAsset.nodes,
  nodes => getBabylonNodesFlat(nodes)
);

export const selectBabylonAssetAllNodeTagsFlat = createSelector(selectBabylonAssetAllNodesFlat, flatNodes => {
  const allTags = flatNodes.flatMap(x => x.curTags).filter(distinctFilter);
  return allTags;
});

export const selectBabylonAssetAllAssignedMaterialIds = createSelector(selectBabylonAssetAllNodesFlat, flatNodes => {
  const allMaterialIds = flatNodes
    .map(x => x.curMaterialId)
    .filter(Boolean)
    .filter(distinctFilter);
  return allMaterialIds;
});

export const selectSelectedNode = createSelector(
  selectBabylonAssetAllNodesFlat,
  (state: RootState) => state.babylonAsset.ui.selectedNodeName,
  (allNodes, selectedNodeName) => allNodes.find(node => node.name === selectedNodeName)
);

export const selectNodesWithChanges = createSelector(selectBabylonAssetAllNodesFlat, flatNodes => {
  const nodesWithChangedTags = flatNodes.filter(
    node => !compareFlat([...node.curTags].sort(), [...node.syncedTags].sort())
  );
  const nodesWithChangedMaterial = flatNodes.filter(node => node.curMaterialId !== node.syncedMaterialId);
  return { nodesWithChangedTags, nodesWithChangedMaterial };
});

/**
 * Expand nodes based on the node count and a fixed threshold
 * - Below threshhold: Expand all
 * - Above threshold: Only expand top level nodes
 */
function _getInitialExpandedNodes(flatNodes: BabylonAssetNode[]): string[] {
  const nodesToExpand =
    flatNodes.length > _FULL_TREE_EXPAND_MAX_COUNT
      ? flatNodes.filter(x => !x.parentNodeName)
      : flatNodes.filter(x => x.childs.length > 0);
  return nodesToExpand.map(x => x.name);
}

export const {
  setNodes,
  selectNode,
  setBabylonAssetUIState,
  toggleNodeVisibilityInPreview,
  setMaterialOfNode,
  resetMaterialOfNode,
  toggleTagOfNode,
  resetTagsOfNode,
  applyChangesOfNode,
  resetChangesOfNode,
  setNodeSettingsVisibility,
  clearBabylonAssetState,
  setExpandedNodeSettingsAccordions,
} = babylonAssetSlice.actions;

export default babylonAssetSlice.reducer;
