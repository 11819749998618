import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { useCallback } from 'react';
import { SaveIcon } from 'assets/icons';
import { Button } from 'controls/button/button';
import { useMinEditWidth } from 'controls/datagrid/column-types/column-types.hooks';

type DataGridEditSubmitBtnWrapperProps = {
  children: React.ReactNode;
  renderParams: GridRenderEditCellParams;
  minWidth?: number;
};

export const DataGridEditSubmitBtnWrapper: React.FC<DataGridEditSubmitBtnWrapperProps> = ({
  children,
  renderParams,
  minWidth,
}) => {
  const { id, field, api, colDef } = renderParams;

  useMinEditWidth(field, colDef, minWidth);
  const handleSubmit = useCallback(() => {
    api.stopCellEditMode({ id, field });
  }, [api, id, field]);
  return (
    <div data-cmptype="DataGridEditSubmitBtnWrapper" className="flex grow items-center gap-2">
      {children}
      <Button Svg={SaveIcon} onClick={handleSubmit} />
    </div>
  );
};
