import { useEffect, useMemo, useState } from 'react';
import { CbnTreeView, CbnTreeViewDataItem } from 'controls/cbn-tree-view/cbn-tree-view';

const _LEVELS = 3;
const _AMOUNT_PER_LEVEL = 3;

export const DebugTreeView: React.FC = () => {
  const { treeItems, ids } = useMemo(() => {
    const { nodes, ids } = _generateNodeData(_LEVELS, _AMOUNT_PER_LEVEL);
    const treeItems = _nodeDataToTreeItems(nodes);
    return { treeItems, ids };
  }, []);

  const [selectedId, setSelectedId] = useState('');
  const [expandedIds, setExpandedIds] = useState<string[]>([]);

  useEffect(() => {
    setExpandedIds(ids);
  }, [ids]);

  return (
    <CbnTreeView
      data-cmptype="DebugTreeview-treeView"
      data={treeItems}
      selected={selectedId}
      expanded={expandedIds}
      onNodeSelect={(evt, nodeId): void => setSelectedId(nodeId)}
      onNodeToggle={(nodeIds): void => setExpandedIds(nodeIds)}
    ></CbnTreeView>
  );
};

type RawNodeData = {
  id: string;
  childs: RawNodeData[];
};

/**
 * Help function for generating node data for tree view.
 * Depth of the tree and amount per level can be adjusted on the input of the function
 */
function _generateNodeData(levels: number, amountPerLevel: number): { nodes: RawNodeData[]; ids: string[] } {
  const allIds: string[] = [];

  const createNodes = (level: number, amountPerLevel: number, parentId: string): RawNodeData[] => {
    const nodes: RawNodeData[] = [];
    for (let i = 0; i < amountPerLevel; i++) {
      const id = parentId ? `${parentId}_${i + 1}` : `${i + 1}`;
      allIds.push(id);

      nodes.push({
        id: id,
        childs: level < levels ? createNodes(level + 1, amountPerLevel, id) : [],
      });
    }

    return nodes;
  };

  const topLevelNodes = createNodes(1, amountPerLevel, '');

  return { nodes: topLevelNodes, ids: allIds };
}

/**
 * Converts node data from the `_generateNodeData` function into `TreeItems`.
 * The idea of splitting these 2 functions is to be able to test other tree view components as well, which can have
 * different APIs.
 */
function _nodeDataToTreeItems(nodes: RawNodeData[]): CbnTreeViewDataItem[] {
  const convertNode = (n: RawNodeData): CbnTreeViewDataItem => ({
    nodeId: n.id,
    label: n.id,
    children: n.childs ? n.childs.map(convertNode) : undefined,
  });

  const topLevelNodes = nodes.map(convertNode);

  return topLevelNodes;
}
