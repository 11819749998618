import { useLocation } from 'react-router';
import { LinkButton } from 'controls/button/link-button';
import { Card } from 'controls/card/card';
import { StateIcon } from 'controls/icon/state-icon';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { PATHS } from 'services/routes/paths.service';

type RequestLoginCardProps = {
  header: string;
  text: string;
  showRegisterButton?: boolean;
};

export const RequestLoginCard: React.FC<RequestLoginCardProps> = ({ header, text, showRegisterButton = false }) => {
  const location = useLocation();
  const { t } = useTypedTranslation();
  return (
    <section data-cmptype="RequestLoginCard" className="flex grow items-center justify-center">
      <Card headerText={header}>
        <div className="flex max-w-md flex-col gap-6">
          <div className="flex items-center gap-2 text-m-regular">
            <StateIcon variant="Info" />
            <span>{text}</span>
          </div>
          <div className="flex gap-2 self-center">
            <LinkButton text={t('Go to login')} href={PATHS.login} state={{ referrer: location.pathname }} />
            {showRegisterButton && (
              <LinkButton text={t('Register')} href={PATHS.registerUser} state={{ referrer: location.pathname }} />
            )}
          </div>
        </div>
      </Card>
    </section>
  );
};
