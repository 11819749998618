import { PopoverProps } from '@mui/material';
import { DateRange, DateRangeCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { CbnPopover } from 'controls/cbn-popover/cbn-popover';
import { getEndOfDay, getStartOfDay } from 'helper/date-and-time/date-and-time.helper';

type DateRangeCalendarProps = Omit<React.ComponentProps<typeof DateRangeCalendar<Dayjs>>, 'onChange'>;

type CbnDateRangePickerPopoverProps = Omit<PopoverProps, 'onSubmit' | 'onClose'> & {
  value?: DateRange<Dayjs>;

  onClose: () => void;
  /** Start date is always start of day (i.e. 00:00:00:000), end date always end of day (i.e. 23:59:59:999) */
  onSubmit: (value: DateRange<Dayjs>) => void;

  calendarProps?: DateRangeCalendarProps;
};

/**
 * Example usage:
 * ```tsx
 * const [calenderShown, setCalenderShown] = useState(false);
 * const showCalenderBtnRef = useRef<HTMLDivElement>(null);
 *
 * // ...
 *
 * <CbnDateRangePickerPopover
 *   anchorEl={showCalenderBtnRef.current}
 *   open={calenderShown}
 *   onClose={(): void => setCalenderShown(false)}
 *   onSubmit={(value: DateRange<Dayjs>): void => { ... }}
 * />
 *
 * // ...
 *
 * <div ref={showCalenderBtnRef}>
 *   <Button text="Show calendar" onClick={(): void => setCalenderShown(x => !x)} />
 * </div>
 *
 * ```
 */
export const CbnDateRangePickerPopover: React.FC<CbnDateRangePickerPopoverProps> = ({
  value,
  open,
  onClose,
  anchorEl,
  onSubmit,
  calendarProps,
  ...popoverProps
}) => {
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>(value ?? [null, null]);

  useEffect(() => {
    setDateRange(value ?? [null, null]);
  }, [open, value]);

  return (
    <CbnPopover
      data-cmptype="OptionsPopover"
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      {...popoverProps}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangeCalendar
          value={dateRange}
          onChange={(x: DateRange<Dayjs>): void => {
            const newStart = x[0] ? getStartOfDay(x[0]) : null;
            const newEnd = x[1] ? getEndOfDay(x[1]) : null;
            setDateRange([newStart, newEnd]);
            if (newStart && newEnd) {
              onSubmit([newStart, newEnd]);
            }
          }}
          {...calendarProps}
        />
      </LocalizationProvider>
    </CbnPopover>
  );
};
