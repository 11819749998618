import React, { useEffect, useState } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { DialogId, GlobalErrorDialog, GlobalErrorDialogProps } from 'components/error-handling/global-error-dialog';
import { GlobalErrorDialogNav } from 'components/error-handling/global-error-dialog-nav';
import { ErrorGuid, generateErrorGuid } from 'services/store/logger.service';

const ERROR_ELEMENT_ID = 'global-error-dialog';
const globalErrorDialogEvt = new CustomEvent('globalerrordialog');
let _globalErrors: GlobalErrorDialogProps[] = [];

/**
 * This dialog is rendered 'detached' from the rest of the app
 *
 * **It should ONLY be used for uncaught/unexpected/... errors
 * and even then only be called with the addError() function.**
 * Don't use it for 'expected' messages like "Login failed!"
 */
export function showErrorDialog(header: string, text: string, errorId?: ErrorGuid): void {
  _globalErrors.push({
    header: header,
    text: text,
    errorId: errorId,
    dialogId: errorId || generateErrorGuid(),
  });

  _renderErrors();
  dispatchEvent(globalErrorDialogEvt);
}

/**
 * Can be used to find a shown error dialog (e.g. to hide it programmatically as it's handled elsewhere)
 */
export function findMatchingErrorDialogs(errorToFind: Error): GlobalErrorDialogProps[] {
  const errorMatcher = (dialogError: GlobalErrorDialogProps): boolean => {
    const isMatch =
      errorToFind.message === dialogError.text ||
      errorToFind.message === dialogError.text.replace(/Uncaught \w*Error: /, '');
    return isMatch;
  };
  const matchingErrors = _globalErrors.filter(errorMatcher);
  return matchingErrors;
}

/**
 * Dismiss specific error dialogs programmatically
 */
export function dismissErrorsByDialogId(ids: DialogId[]): void {
  _globalErrors = _globalErrors.filter(e => ids.indexOf(e.dialogId) === -1);
  _renderErrors();
}

let errorDialogMgrRoot: Root;

/**
 * Render the errors 'outside' of the app so it can be called and rendered from almost anywhere
 * Otherwise there might be issues with the redux lifecycle which can break the whole app
 */
function _renderErrors(): void {
  let target = document.getElementById(ERROR_ELEMENT_ID);

  if (!target) {
    target = document.createElement('div');
    target.id = ERROR_ELEMENT_ID;

    document.getElementsByTagName('body')[0]?.appendChild(target);
    errorDialogMgrRoot = createRoot(target);
  }

  const onCloseErrorAt = (idx: number): void => {
    if (target) {
      _globalErrors.splice(idx, 1);
      _renderErrors();
    }
  };

  const onDismissAll = (): void => {
    if (target) {
      _globalErrors = [];
      _renderErrors();
    }
  };

  errorDialogMgrRoot.render(
    <GlobalErrorDialogManager errors={_globalErrors} onCloseErrorAt={onCloseErrorAt} onDismissAll={onDismissAll} />
  );
}

type GlobalErrorDialogManagerProps = {
  errors: GlobalErrorDialogProps[];
  onCloseErrorAt: (idx: number) => void;
  onDismissAll: () => void;
};
const GlobalErrorDialogManager: React.FC<GlobalErrorDialogManagerProps> = ({
  errors,
  onCloseErrorAt,
  onDismissAll,
}) => {
  const [shownErrorIdx, setShownErrorIdx] = useState(0);
  const [prevMaxIndex, setPrevMaxIndex] = useState(0);

  const maxIndex = Math.max(0, errors.length - 1);

  const nextClicked = (): void => {
    setShownErrorIdx(idx => Math.min(idx + 1, maxIndex));
  };
  const previousClicked = (): void => {
    setShownErrorIdx(idx => Math.max(idx - 1, 0));
  };

  useEffect(() => {
    if (maxIndex > prevMaxIndex) {
      // set to last index when a new error occurs
      setShownErrorIdx(maxIndex);
    } else {
      setShownErrorIdx(idx => Math.min(idx, maxIndex));
    }

    setPrevMaxIndex(maxIndex);
  }, [errors.length, maxIndex, prevMaxIndex]);

  const currentError = errors[shownErrorIdx];

  return currentError ? (
    <div
      data-cmptype="GlobalErrorDialogManager"
      className="fixed left-0 top-0 z-modal-global-error flex h-full w-full items-center justify-center bg-modal backdrop-blur-lg"
    >
      <div className="flex w-100 flex-col justify-center gap-1">
        {errors.length > 1 && (
          <GlobalErrorDialogNav
            currentItemIdx={shownErrorIdx + 1}
            itemCount={maxIndex + 1}
            onPreviousClick={previousClicked}
            onNextClick={nextClicked}
            onDismissAll={onDismissAll}
          ></GlobalErrorDialogNav>
        )}
        <GlobalErrorDialog
          header={currentError.header}
          text={currentError.text}
          errorId={currentError.errorId}
          dialogId={currentError.dialogId}
          onContinueClicked={(): void => onCloseErrorAt(shownErrorIdx)}
        />
      </div>
    </div>
  ) : null;
};
