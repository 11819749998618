import { DeleteIcon } from 'assets/icons';
import { StateIcon, StateIconVariants } from 'controls/icon/state-icon';
import { TextInput } from 'controls/input/text-input';
import { Select } from 'controls/select/select';
import { SelectOption, SelectValue } from 'controls/select/select';
import { AssetTypes } from 'generated/asset-types';
import { AssetFileValidity, isAssetFileValidityError } from 'helper/assets/assets.helper';
import { tw } from 'helper/css.helper';
import { INVALID_HIVE_IDENTIFIER_INFO } from 'helper/hive/hive.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type AssetFileValidityUIData = {
  icon: StateIconVariants;
  colorClass: string;
  tooltip: string;
};

const _VALIDITY_UI_MAP: Record<AssetFileValidity, AssetFileValidityUIData> = {
  valid: { icon: 'Success', colorClass: '', tooltip: '' },
  info_overwrite: { icon: 'Info', colorClass: '', tooltip: 'This file will overwrite an existing asset.' },
  warning_typechange: {
    icon: 'Warning',
    colorClass: '',
    tooltip: 'The existing asset will change its type when overwritten.',
  },
  error_overwriteFolder: {
    icon: 'Error',
    colorClass: tw`text-danger-main`,
    tooltip:
      'This file would overwrite an existing folder asset! Please rename the asset, choose a different folder or remove it.',
  },
  error_invalidHiveIdentifier: {
    icon: 'Error',
    colorClass: tw`text-danger-main`,
    tooltip: INVALID_HIVE_IDENTIFIER_INFO,
  },
  error_duplicate: {
    icon: 'Error',
    colorClass: tw`text-danger-main`,
    tooltip:
      'Other files within this upload have the same asset name! Please rename the asset, choose a different folder or remove it.',
  },
  error_breaksDatasourceLink: {
    icon: 'Error',
    colorClass: tw`text-danger-main`,
    tooltip:
      'The existing data source is based on a file asset. Update the file asset or remove this data source first.',
  },
  error_breaksAssetLink: {
    icon: 'Error',
    colorClass: tw`text-danger-main`,
    tooltip:
      'Existing links from/to this asset would break. If this action is still required change or remove the linked assets first.',
  },
};

type AssetUploadEntryProps = {
  fileName: string;
  assetName: string;
  selectedFolder: string;
  folderOptions: SelectOption[];
  selectedAssetType: AssetTypes;
  assetTypeOptions: SelectOption[];
  validity: AssetFileValidity;
  autoFocus?: boolean;
  onChangeAssetName: (assetName: string) => void;
  onChangeFolder: (folder: string) => void;
  onChangeAssetType: (assetType: AssetTypes) => void;
  onDeleteFile: () => void;
};

export const AssetUploadEntry: React.FC<AssetUploadEntryProps> = ({
  fileName,
  assetName,
  selectedFolder,
  folderOptions,
  selectedAssetType,
  assetTypeOptions,
  validity,
  autoFocus = false,
  onChangeAssetName,
  onChangeFolder,
  onChangeAssetType,
  onDeleteFile,
}) => {
  const { t } = useTypedTranslation();

  const validUIEntry = _VALIDITY_UI_MAP[validity];

  const onAssetNameChanged = (value: string): void => {
    onChangeAssetName(value);
  };
  const onFolderChanged = (value: SelectValue): void => {
    onChangeFolder(value as string);
  };
  const onAssetTypeChanged = (value: SelectValue): void => {
    onChangeAssetType(value as number);
  };
  const onFileDelete = (): void => {
    onDeleteFile();
  };

  // materials can only be created in the "Materials3d" folder, therefore the folder selection must be disabled
  const disableFolderSelection = selectedAssetType === AssetTypes.Material;

  const cellBaseClassName = tw`flex items-center border-t border-t-neutral-40 px-2 py-1`;

  return (
    // As already mentioned in the <AssetUploadSettingsModal> dialog we are in a GRID layout here, so we can't group
    // components here.
    // That's why each entry gets the required CSS classes, like top borders, etc...
    // Also we can't create a top level component here (with a `data-cmptype` attribute) as that would break the layout
    // as well.
    <>
      <div
        className={`${cellBaseClassName} ${validUIEntry.colorClass} overflow-hidden text-m-regular`}
        title={fileName}
      >
        <span className="overflow-hidden text-ellipsis">{fileName}</span>
      </div>

      <div className={`${cellBaseClassName}`}>
        <TextInput
          value={assetName}
          onValueChange={onAssetNameChanged}
          title={assetName}
          autoFocus={autoFocus}
          error={isAssetFileValidityError(validity)}
        />
      </div>

      <div className={`${cellBaseClassName}`}>
        <Select
          value={selectedFolder}
          options={folderOptions}
          onChange={onFolderChanged}
          disabled={disableFolderSelection}
        />
      </div>

      <div className={`${cellBaseClassName}`}>
        <Select value={selectedAssetType} options={assetTypeOptions} onChange={onAssetTypeChanged} />
      </div>

      <div className={`${cellBaseClassName} flex-row gap-1`}>
        <StateIcon variant={validUIEntry.icon} title={t(validUIEntry.tooltip)} noBckgr />
        {validUIEntry.icon === 'Error' && (
          // Optionally add a delete button for erroneous entries
          <button type="button" className="h-4 w-4" onClick={onFileDelete}>
            <DeleteIcon />
          </button>
        )}
      </div>
    </>
  );
};
