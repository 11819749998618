import styles from './loading-spinner.module.css';
import classNames from 'classnames';
import { tw } from 'helper/css.helper';

type LoadingSpinnerProps = {
  small?: boolean;
};

// NOTE: this component is styled with vanilla CSS instead of TailwindCSS, as the code is just copied from the net
export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ small }) => {
  const cssClasses = classNames(styles['lds-spinner'], {
    [styles.small]: small,
  });
  const spinnerClasses = tw`after:bg-neutral-50`;

  // HTML code is taken from https://loading.io/css/
  return (
    <div data-cmptype="LoadingSpinner" role="progressbar" className={cssClasses}>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
    </div>
  );
};
