import { useEffect, useRef, useState } from 'react';
import { Autocomplete } from 'controls/autocomplete/autocomplete';
import { SelectValue } from 'controls/select/select';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { getCompanyCfgrs } from 'slices/company-data/company-data.slice';

type SelectCfgrOfCompanyProps = {
  companyId: string;
  value: string;
  /** `useCallback` recommended as it's of a dependencyArray */
  onChange: (value: string) => void;
  excludeReadOnly?: boolean;
};

export const SelectCfgrOfCompany: React.FC<SelectCfgrOfCompanyProps> = ({
  companyId,
  value,
  onChange,
  excludeReadOnly = false,
}) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const cfgrsOfChosenCompany = useAppSelector(state => state.companyData.cfgrs[companyId]) ?? [];

  const [fetching, setFetching] = useState(false);
  const prevCompanyId = useRef(companyId);

  useEffect(
    function requestDataOnCompanyChange() {
      if (!companyId || companyId === prevCompanyId.current) {
        return;
      }
      const fetchData = async (): Promise<void> => {
        prevCompanyId.current = companyId;
        setFetching(true);
        await dispatch(getCompanyCfgrs({ companyId })).unwrap();
        setFetching(false);
      };
      fetchData();
    },
    [companyId, dispatch]
  );

  const availableCfgrs = cfgrsOfChosenCompany
    .filter(c => !c.deleteAfter && (!excludeReadOnly || !c.cfgrFeatures.readonly))
    .sort((a, b) => a.displayName.localeCompare(b.displayName));

  const cfgrOptions = availableCfgrs.map(c => ({ value: c.id, text: c.displayName }));
  const selectedCfgr = availableCfgrs.find(c => c.id === value);
  const isValidOption = !!selectedCfgr;
  const companyHasCfgrs = availableCfgrs.length > 0;

  const onSelectChange = (newValue: SelectValue): void => {
    onChange(newValue as string);
  };

  const errorMsg = !companyHasCfgrs ? t('Company has no configurators!') : '';

  return (
    <Autocomplete
      options={cfgrOptions}
      value={value}
      onChange={onSelectChange}
      disabled={fetching || !companyHasCfgrs}
      error={!isValidOption || !companyHasCfgrs}
      tooltip={errorMsg}
      disableClearable
    />
  );
};
