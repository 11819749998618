import { globalReset } from '../../actions/general.actions';
import { createSlice } from '@reduxjs/toolkit';
import { UserPermissions } from 'generated/user-permissions';
import { addError } from 'services/store/logger.service';
import { login } from 'slices/auth/auth.slice';
import { getCompaniesAndPermissionsIfNeeded } from 'slices/company-data/company-data.slice';

/**
 * The permissions are defined by bitwise flags.
 * It can be any combination of the `CompanyPermissions` and/or `SystemPermissions` enums.
 *
 * @example
 * const permission = CompanyPermissions.ViewDraft | CompanyPermissions.ManageUserRole;
 * // permission = 2 + 64 = 66;
 */
export type PermissionValue = number;

/**
 * Permissions which define access to features on a "per company basis"
 */
export enum CompanyPermissions {
  None = UserPermissions.None,
  ViewConfigurator = UserPermissions.ViewConfigurator,
  ManageDraft = UserPermissions.ManageDraft,
  ManageCbnPlugin = UserPermissions.ManageCbnPlugin,
  PublishCfgrDraft = UserPermissions.PublishCfgrDraft,
  WithdrawConfigurator = UserPermissions.WithdrawConfigurator,
  ManageUserRole = UserPermissions.ManageUserRole,
  ManageApiToken = UserPermissions.ManageApiToken,
  ManageConfigurator = UserPermissions.ManageConfigurator,
  ManageWhitelist = UserPermissions.ManageWhitelist,
  GetConfigurations = UserPermissions.GetConfigurations,
  ViewAssets = UserPermissions.ViewAssets,
  ManageAssets = UserPermissions.ManageAssets,
  ManageWorkflows = UserPermissions.ManageWorkflows,
  RunWorkflows = UserPermissions.RunWorkflows,
  ModifyCompanySettings = UserPermissions.ModifyCompanySettings,
}

/**
 * Permissions which define access to "system-wide features" unrelated to the selected company ("cbn admin page" etc.)
 */
export enum SystemPermissions {
  ManageCompaniesAndUsers = UserPermissions.ManageCompaniesAndUsers,
  ReplicateCrossEnv = UserPermissions.ReplicateCrossEnv,
  UpdateTheme = UserPermissions.UpdateTheme,
  ShowServerPanel = UserPermissions.ShowServerPanel,
  BroadcastMessage = UserPermissions.BroadcastMessage,
  RasterizeSvg = UserPermissions.RasterizeSvg,
}

export type SystemPermissionsKeys = keyof typeof SystemPermissions;

/**
 * Combined permissions for easier usage in the clientpages.
 * E.g.: A window with several tabs is only shown when at least 1 tab is permitted
 *
 * If one or all flags are required depends on the consuming code
 * @see custom hook `usePermission`
 */
export enum PermissionBundles {
  /**
   * Before creating a new bundle always check if it's only related to the UI.
   * If it somehow involves a backend request it should be a standalone permission which is also known by the backend.
   */

  ShowAdminPage = SystemPermissions.ManageCompaniesAndUsers |
    SystemPermissions.UpdateTheme |
    SystemPermissions.ShowServerPanel,

  ShowExportPage = CompanyPermissions.ManageConfigurator | CompanyPermissions.ManageAssets,
}

// ToAsk: Is there a reason why are the company and system permissions are having different typings?
// `CompanyPermission` could be something like `{ [companyId: string]: { [permissionName: string]: boolean } }`
// or `SystemPermission` could just be `PermissionValue` if I'm not mistaken
// I think that would be easier to understand and I guess some more code parts could be reused
export type CompanyPermission = { [companyId: string]: PermissionValue };
export type SystemPermission = { [permissionName in SystemPermissionsKeys]?: boolean };

export type PermissionsState = {
  companies: CompanyPermission;
  system: SystemPermission;
};
const emptyState: PermissionsState = { companies: {}, system: {} };
const initialState = emptyState;

/**
 * Returns a bitmask where only the system permission flags are set to 1
 */
const _getSystemPermissionsMask = (): number => {
  return Object.values(SystemPermissions).reduce((mask, permission) => {
    if (Number.isInteger(permission)) {
      mask |= Number(permission);
    }
    return mask;
  }, 0);
};

/**
 * Check if the system permissions, which are part of every company permission, are identical across all companies
 */
const _systemPermissionsAreIdentical = (
  companyPermissions: CompanyPermission,
  referencePermission: PermissionValue
): boolean => {
  const sysMask = _getSystemPermissionsMask();
  // use the mask to negate all company-specific bits
  // Example: 1100101 & 0000111 = 0000101
  const refSysPermission = referencePermission & sysMask;

  return Object.values(companyPermissions).every(companyPerm => {
    return (companyPerm & sysMask) === refSysPermission;
  });
};

const _retrieveSystemPermissions = (companyPermissions: CompanyPermission): SystemPermission => {
  const permissionOfFirstCompany = Object.values(companyPermissions)?.[0] || 0;

  if (_systemPermissionsAreIdentical(companyPermissions, permissionOfFirstCompany)) {
    const sysPerm = Object.keys(SystemPermissions).reduce<SystemPermission>((acc, key) => {
      // Enum contains keys & values as properties, so we have to exclude the 'value properties'
      if (isNaN(Number(key))) {
        const currentPermission = SystemPermissions[key as SystemPermissionsKeys];
        acc[key as SystemPermissionsKeys] = (permissionOfFirstCompany & currentPermission) > 0;
      }
      return acc;
    }, {});
    return sysPerm;
  } else {
    addError("System Permissions aren't identical!", {
      isCritical: true,
      customProperties: {
        permissionOfFirstCompany: permissionOfFirstCompany,
      },
      preventDialog: process.env.NODE_ENV !== 'development',
    });
    return {};
  }
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(globalReset, () => initialState)
      .addCase(getCompaniesAndPermissionsIfNeeded.fulfilled, (state, { payload }) => {
        // ToAsk: I would have expected that company permissions are also retrieved from the payload.
        // Because now the system permissions are also available in the company permissions
        state.companies = payload.permissions;
        state.system = _retrieveSystemPermissions(payload.permissions);
      })
      .addCase(getCompaniesAndPermissionsIfNeeded.rejected, () => initialState)
      .addCase(login.fulfilled, (state, { payload }) => {
        state.companies = payload.permissions;
        state.system = _retrieveSystemPermissions(payload.permissions);
      })
      .addCase(login.rejected, () => initialState);
  },
});

export default permissionsSlice.reducer;
