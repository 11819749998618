import {
  TreeItemProps as MuiTreeItemProps,
  TreeView as MuiTreeView,
  TreeViewProps as MuiTreeViewProps,
} from '@mui/x-tree-view';
import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { FolderAssetCollapsedIcon, FolderAssetExpandedIcon } from 'assets/icons';
import { CbnTreeItem, CbnTreeItemProps } from 'controls/cbn-tree-view/cbn-tree-item';
import {
  CbnTreeViewContextState,
  CbnTreeViewProvider,
  CbnTreeViewViewModes,
} from 'controls/cbn-tree-view/cbn-tree-view-provider';
import { Icon } from 'controls/icon/icon';
import { useLocalStorageState } from 'hooks/common/local-storage.hooks';

// Typing info: If the type `TNodeData` is known then `nodeData` exists as required prop.
//              Otherwise it's a basic item without additional `nodeData`.
export type CbnTreeViewDataItem<TNodeData = unknown> = {
  /**
   * Unique id of the node
   */
  nodeId: MuiTreeItemProps['nodeId'];
  /**
   * Displayed value if there's no custom rendering fn.\
   * This is also the default value when searching nodes.
   */
  label: string;
  children?: CbnTreeViewDataItem<TNodeData>[];
} & (unknown extends TNodeData
  ? {}
  : {
      /* Store any kind of additional data with the item */
      nodeData: TNodeData;
    });

export type CbnTreeViewItemRendererParams<TNodeData> = {
  item: CbnTreeViewDataItem<TNodeData>;
  key?: React.Key;
  children?: React.ReactNode;
};

export type CbnTreeViewApiRef = {
  setSearchInput: (value: string) => void;
  setViewMode: (viewMode: CbnTreeViewViewModes) => void;
  collapseAll: () => void;
  expandAll: () => void;
  getSearchInput: () => string;
  getViewMode: () => CbnTreeViewViewModes;
};

/**
 * See `_MUI_control_with_generics.md` for details about typing
 */
export type CbnTreeViewProps<TNodeData, Multiple extends boolean | undefined> = Omit<
  MuiTreeViewProps<Multiple>,
  'children' | 'onNodeToggle'
> & {
  'data': CbnTreeViewDataItem<TNodeData>[];
  'expandOnDoubleClick'?: boolean;
  'defaultViewMode'?: CbnTreeViewViewModes;
  'itemRenderer'?: (params: CbnTreeViewItemRendererParams<TNodeData>) => React.ReactElement<CbnTreeItemProps>;
  'filterFn'?: (item: CbnTreeViewDataItem<TNodeData>, searchInput: string) => boolean;
  'onNodeToggle'?: (nodeIds: string[]) => void;
  'apiRef'?: React.Ref<CbnTreeViewApiRef>;
  /** This is mandatory due to persisted states (e.g. viewMode) */
  'data-cmptype': string;
};

/**
 * Copied from original MUI type `TreeViewComponent` with custom props type
 */
type CbnTreeViewComponent = (<TNodeData, Multiple extends boolean | undefined = undefined>(
  props: CbnTreeViewProps<TNodeData, Multiple>
) => React.JSX.Element) & { propTypes?: any };

export const CbnTreeView = React.forwardRef(
  <TNodeData, Multiple extends boolean | undefined = undefined>(
    {
      data,
      itemRenderer,
      expandOnDoubleClick,
      defaultViewMode,
      'expanded': expandedInput,
      onNodeToggle,
      'data-cmptype': dataCmpType,
      filterFn,
      apiRef,
      ...props
    }: CbnTreeViewProps<TNodeData, Multiple>,
    ref: React.Ref<HTMLUListElement>
  ) => {
    const [viewMode, setViewMode] = useLocalStorageState<CbnTreeViewViewModes>(
      `viewMode__${dataCmpType}`,
      defaultViewMode ?? 'extended'
    );
    const [searchInput, setSearchInput] = useState('');
    const allExpandableNodeIds = useMemo(() => _getExpandableNodeIds(data), [data]);
    const isSearchMode = searchInput.length > 0;

    // during search all nodes are expanded and toggling is prevented
    // this temporarily overrules the controlled state of the consumer
    const expanded = isSearchMode ? allExpandableNodeIds : expandedInput;
    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]): void => {
      if (!isSearchMode) {
        onNodeToggle?.(nodeIds);
      }
    };

    // create control api
    // this is used by `CbnTreeViewCtrlMenu`
    useImperativeHandle(
      apiRef,
      () => ({
        setSearchInput: (value: string): void => {
          setSearchInput(value);
        },
        setViewMode: (value: CbnTreeViewViewModes): void => {
          setViewMode(value);
        },
        collapseAll: (): void => {
          onNodeToggle?.([]);
        },
        expandAll: (): void => {
          onNodeToggle?.(allExpandableNodeIds);
        },
        getSearchInput: (): string => searchInput,
        getViewMode: (): CbnTreeViewViewModes => viewMode,
      }),
      [searchInput, viewMode, setViewMode, onNodeToggle, allExpandableNodeIds]
    );

    const ctxState = useMemo<CbnTreeViewContextState>(
      () => ({
        searchInput,
        viewMode,
        expandOnDoubleClick,
        multiSelect: props.multiSelect,
      }),
      [searchInput, viewMode, expandOnDoubleClick, props.multiSelect]
    );

    const finalFilterFn = useMemo(
      () =>
        filterFn
          ? (item: CbnTreeViewDataItem<TNodeData>): boolean => filterFn(item, searchInput)
          : isSearchMode
            ? (item: CbnTreeViewDataItem): boolean => _defaultSearchFilter(item, searchInput)
            : undefined,
      [filterFn, isSearchMode, searchInput]
    );

    const renderTree = useCallback(
      (nodesData: CbnTreeViewDataItem<TNodeData>[] | undefined): React.ReactNode => {
        if (!nodesData || !nodesData.length) {
          return null;
        }

        return nodesData
          .filter(n => !finalFilterFn || _hasRecursiveFilterMatch(n, finalFilterFn))
          .map(nodeData =>
            itemRenderer ? (
              itemRenderer({
                key: nodeData.nodeId,
                item: nodeData,
                children: nodeData.children ? renderTree(nodeData.children) : undefined,
              })
            ) : (
              <CbnTreeItem key={nodeData.nodeId} nodeId={nodeData.nodeId} text={nodeData.label}>
                {nodeData.children && renderTree(nodeData.children)}
              </CbnTreeItem>
            )
          );
      },
      [finalFilterFn, itemRenderer]
    );

    return (
      <CbnTreeViewProvider value={ctxState}>
        <div
          data-cmptype={'CbnTreeView' + (dataCmpType ? ` ${dataCmpType}` : '')}
          className="flex grow flex-col overflow-x-hidden"
        >
          <div className="relative grow overflow-y-auto overflow-x-hidden pl-3">
            <MuiTreeView
              {...props}
              defaultExpandIcon={<Icon Svg={FolderAssetCollapsedIcon} className="w-5" />}
              defaultCollapseIcon={<Icon Svg={FolderAssetExpandedIcon} className="w-5" />}
              expanded={expanded}
              onNodeToggle={handleToggle}
            >
              {renderTree(data)}
            </MuiTreeView>
          </div>
        </div>
      </CbnTreeViewProvider>
    );
  }
) as CbnTreeViewComponent;

function _hasRecursiveFilterMatch<TNodeData = unknown>(
  item: CbnTreeViewDataItem<TNodeData>,
  filterFn: (i: CbnTreeViewDataItem<TNodeData>) => boolean
): boolean {
  const isMatch = filterFn(item);

  if (isMatch) {
    return true;
  } else {
    return item.children ? item.children.some(c => _hasRecursiveFilterMatch(c, filterFn)) : false;
  }
}

const _defaultSearchFilter = (item: CbnTreeViewDataItem, searchInput: string): boolean =>
  item.label.toLowerCase().includes(searchInput.toLowerCase());

function _getExpandableNodeIds(data: CbnTreeViewDataItem[]): string[] {
  const getRecursive = (childs: CbnTreeViewDataItem[]): string[] => {
    const nodesWithChild = childs.filter(c => c.children && c.children.length);
    const innerNodes = nodesWithChild.flatMap(n => getRecursive(n.children!));

    return [...nodesWithChild.map(n => n.nodeId), ...innerNodes];
  };

  return getRecursive(data);
}
