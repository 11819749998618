import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { OpenIcon, StopIcon, TrashIcon } from 'assets/icons';
import { IdentityUser } from 'components/identity/identity-user';
import { ReplicationStateIcon } from 'components/replicator/replication-state-icon';
import { ReplicationStateIconPublish } from 'components/replicator/replication-state-icon-publish';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { ReplicationStates } from 'generated/replication-states';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { ReplicationStatus, postReplicatorCancel } from 'services/replicator/replicator.service';
import { buildAssetEditorPath } from 'services/routes/asset-editor-routes.service';
import { buildCfgrsPath } from 'services/routes/cfgrs-routes.service';
import {
  ReplicationNotification,
  removeNotification,
  showNotificationMenu,
  updateReplicationStatus,
} from 'slices/notifications/notifications.slice';

type NotificationItemReplicatorProps = {
  notification: ReplicationNotification;
};

export const NotificationItemReplicator: React.FC<NotificationItemReplicatorProps> = ({ notification }) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const allPolledStatus = useAppSelector(state => state.notifications.cachedRequests.replications);

  const polledItem = allPolledStatus[notification.uid];
  const [isCancelling, setIsCancelling] = useState(false);

  useEffect(
    function fetchNonPollingNotifications() {
      if (notification.updateState === 'POLLING' || polledItem) {
        return;
      }

      dispatch(updateReplicationStatus(notification.uid));
    },
    [dispatch, notification.updateState, notification.uid, polledItem]
  );

  const cancelReplicationClicked = async (): Promise<void> => {
    setIsCancelling(true);
    await postReplicatorCancel(notification.replicationId, notification.targetCompany);
    dispatch(updateReplicationStatus(notification.uid));
  };

  const removeNotificationClicked = (): void => {
    dispatch(removeNotification(notification.uid));
  };

  const onTargetLinkClicked = (): void => {
    dispatch(showNotificationMenu(false));
  };

  if (!polledItem) {
    return (
      <div data-cmptype="NotificationItemReplicator-skeleton" className="flex flex-col gap-2">
        <Skeleton variant="rectangular" width={200} height={20} />
        <Skeleton variant="rectangular" width={300} height={20} />
        <Skeleton variant="rectangular" width={300} height={20} />
      </div>
    );
  }

  const targetLink = _getTargetLink(notification, polledItem);

  return (
    <div data-cmptype="NotificationItemReplicator">
      <div className="flex items-center gap-6">
        <div className="flex grow flex-col gap-1">
          <div className="flex gap-2 pb-1 text-s-regular">
            <RelativeDateTime unixTime={polledItem.startedAt} />
            {' | '}
            <IdentityUser userId={polledItem.startedBy} />
          </div>
          <ReplicationStateIcon status={polledItem} />
          <ReplicationStateIconPublish
            status={polledItem}
            hasAutoPublish={notification.hasAutoPublish}
            showOnlyIfFailed
          />
          <div>
            {targetLink ? (
              <LinkButton
                variant="Text"
                href={targetLink.url}
                Svg={OpenIcon}
                text={t('Go to') + ` "${targetLink.text}"`}
                onClick={onTargetLinkClicked}
              />
            ) : (
              <span className="text-s-regular">{`${t('Target company')}: ${notification.targetCompany}`}</span>
            )}
          </div>
        </div>
        <div className="flex gap-2">
          {notification.updateState === 'POLLING' && !polledItem.isCancelled && (
            <Button
              Svg={StopIcon}
              onClick={cancelReplicationClicked}
              variant="Outlined"
              color="Danger"
              title={t('Cancel copy')}
              disabled={polledItem.isCancelled || isCancelling}
            />
          )}
          <Button
            Svg={TrashIcon}
            onClick={removeNotificationClicked}
            variant="Outlined"
            title={
              notification.updateState === 'POLLING'
                ? t("Running operations can't be removed")
                : t('Remove notification')
            }
            disabled={notification.updateState === 'POLLING'}
          />
        </div>
      </div>
    </div>
  );
};

function _getTargetLink(
  notification: ReplicationNotification,
  status: ReplicationStatus | undefined
): { url: string; text: string } | undefined {
  if (
    notification.updateState !== 'FINISHED' ||
    !status ||
    status.isCancelled ||
    status.state === ReplicationStates.Failed
  ) {
    return;
  }

  if (Object.keys(notification.cfgrIds).length === 1) {
    const [id, name] = Object.entries(notification.cfgrIds)[0];
    return { url: buildCfgrsPath('drafts', notification.targetCompany, id), text: name };
  } else if (Object.keys(notification.assetBundleIds).length === 1) {
    const [id, name] = Object.entries(notification.assetBundleIds)[0];
    return { url: buildAssetEditorPath(notification.targetCompany, id), text: name };
  }
}
