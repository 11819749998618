import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { globalCompanySwitch, globalReset } from 'actions/general.actions';
import { MaterialPropertyKey } from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor-properties-def';
import { AssetLinkTypes } from 'generated/asset-link-types';
import { AssetTypes } from 'generated/asset-types';
import { distinctFilter } from 'helper/array/array.helper';
import {
  assetPathToKey,
  createSourceStringFromFolderAndAssetId,
  findFileLinkKeyOfDatasourceAsset,
  getAssetLinksOfAsset,
  getAssetParentFolderPath,
  isAssetOfSameBundleVersion,
  isUploadInProgress,
} from 'helper/assets/assets.helper';
import { FetchedState } from 'helper/route/route-sync.helper';
import { MaterialType } from 'services/3d/materials.service';
import {
  AssetErrorBag,
  AssetHintBag,
  AssetWarningBag,
  AssetsCompleteResponse,
  BabylonNodeTagAssignmentsDTO,
  CompleteAssetDto,
  DataSourceConfigDto,
  DeleteAssetRequestItems,
  ProtobufSourceConfigDto,
  RelocateAssetRequestItems,
  deleteAssetBundleVersion as deleteAssetBundleVersionOnServer,
  fetchAssetBundles,
  fetchBundleVersionData,
  fetchRelevantAssetBundleVersions,
  isCsvDataSourceConfigDto,
  isExcelDataSourceConfigDto,
  postAssetChangeCsvDataSource,
  postAssetChangeExcelDataSource,
  postAssetCopy,
  postAssetMove,
  postAssetsComplete,
  postAssetsCreateExcelDataSourceBasedOnFileAsset,
  postCreateFolderAsset,
  postDeleteAsset,
} from 'services/assets/assets.service';
import { LockStatus } from 'services/company-data/company-data.service';
import * as PersistenceMiddleware from 'services/store/persistence.middleware';
import { RootState } from 'services/store/store.service';

export const UPLOAD_IN_PROGRESS_STATES: AssetUploadState[] = [
  'Converting',
  'ToBeUploaded',
  'Uploading',
  'ToBeFinished',
];
export const TEXTURES_3D_FOLDER_NAME = 'Textures3d';
export const MATERIALS_3D_FOLDER_NAME = 'Materials3d';

const _LOAD_RELEVANT_VERSIONS_PAGE_SIZE = 5;

/**
 * - "FromServer": Asset is received from server, no further upload states to consider
 *
 * The rest covers upload states for local assets
 */
export type AssetUploadState =
  | 'FromServer'
  | 'Converting'
  | 'ToBeUploaded'
  | 'Uploading'
  | 'ToBeFinished'
  | 'Finished'
  | 'Failed';

/**
 * Assets with these states will return the same result when fetched again
 * from the server. (no local or temporary state)
 */
const _REFETCHABLE_ASSET_STATES: AssetUploadState[] = ['FromServer', 'Finished'];

// Typings for asset bundle and the "normalized" map for all asset bundles.
// Key of the `AssetBundles` map equals the unique id of the asset bundle for easier access.
export type AssetBundle = {
  id: string;
  companyId: string;
  name: string;
  displayName: string;
  hasPublishedVersions: boolean;
  hasLockedVersions: boolean;
};
export type AssetBundles = { [key: string]: AssetBundle };

export type AssetBundleVersionCommon = {
  id: string;
  companyId: string;
  bundleId: string;
  version: string;
  displayName: string;
  isDraft: boolean;
  basedOnVersion: string;
  deleteAfter?: string;
  lockStatus?: LockStatus;
};
export type AssetBundleVersionDraft = AssetBundleVersionCommon & {
  createdBy: string;
  createdAt: number;
  lastEditedBy: string;
  lastEditedAt: number;
};
export type AssetBundleVersionPublished = AssetBundleVersionCommon & {
  publishedBy: string;
  publishedAt: number;
};
export type AssetBundleVersion = AssetBundleVersionDraft | AssetBundleVersionPublished;
export type AssetBundleVersions = { [key: string]: AssetBundleVersion };

export type AssetShortPath = {
  folderId: string;
  assetId: string;
};

// Defines the exact location, or the path to an asset.
// The combination of all of these properties is unique and can be used as key for normalized maps.
export type AssetPath = AssetShortPath & {
  companyId: string;
  bundleId: string;
  bundleVersion: string;
};

export type AssetSelection = Omit<AssetPath, 'companyId'>;

// Basic asset typing, serves as base for more explicit asset types, like image or file
// Assets are also stored in a normalized map, whereas the key equals the dotted path to the asset
export type Asset = {
  path: AssetPath;
  type: AssetTypes;
  parentFolderKey?: string;
  existsOnServer: boolean;
  uploadState: AssetUploadState;
  uploadPercentage?: number;
  errorText?: string;
  fileSize?: number;
  errorBag: AssetErrorBag;
  warningBag: AssetWarningBag;
  hintBag: AssetHintBag;
  lastEditedAt?: number;
  lastEditedBy?: string;
};
export type Assets = { [key: string]: Asset };

type AssetsLUT = {
  [T in AssetTypes]: {
    [AssetTypes.DataSource]: DataSourceAsset;
    [AssetTypes.File]: FileAsset;
    [AssetTypes.Folder]: FolderAsset;
    [AssetTypes.Image]: ImageAsset;
    [AssetTypes.BabylonJs]: BabylonAsset;
    [AssetTypes.Material]: MaterialAsset;
    [AssetTypes.Text]: TextAsset;
  }[T];
};

export type AssetTypeObject = AssetsLUT[keyof AssetsLUT];

export type ImageAsset = Asset & {
  imageType: number;
  height: number;
  width: number;
  size: number;
  url: string;
};

export type FileAsset = Asset & {
  url: string;
  size: number;
};

export type DataSourceAsset = Asset & {
  config: ProtobufSourceConfigDto;
  importConfig: DataSourceConfigDto;
  size: number;
  rowCount?: number;
};

type BabylonNodeTagAssignment = BabylonNodeTagAssignmentsDTO;

export type BabylonAsset = FileAsset & {
  // this is a shortcut to get all tags from the babylon asset without having to parse each one
  // the exact node => tag assignment is not used ATM, since this will be taken from the parsed node when loading the
  // babylon file into the preview
  tagAssignments: BabylonNodeTagAssignment | undefined;
};

export type MaterialAsset = Asset & {
  size: number;
  materialType: MaterialType;
};

export type TextAsset = Asset & {
  url: string;
  size: number;
};

export type FolderAsset = Asset & {
  childKeys: string[];
};

export type AssetLink = {
  type: AssetLinkTypes;
  /** Dotted path: `folderName.assetName` */
  target: string;
  texture?: MaterialPropertyKey;
};
export type AssetLinks = { [key: string]: AssetLink[] };

export type AssetBundleVersionData = { assets: Assets; assetLinks: AssetLinks; bundleVersionInfo: AssetBundleVersion };

export type AssetsState = {
  bundles: AssetBundles;
  relevantBundleVersions: AssetBundleVersions;
  assets: Assets;
  assetLinks: AssetLinks;
  fetchState: {
    bundles: FetchedState;
    bundleVersions: FetchedState;
    assets: FetchedState;
  };
  selection: AssetSelection;
  ui: {
    /**
     * Keep track of all the selected assets in case of multi-selection (e.g. for bulk edit).\
     * This shouldn't interfere with the "main selection state" as it's only a temporary UI state.
     * (the main selection might not even be part of the nodeIds)
     */
    selectedTreeViewNodeIds: string[];
    /**
     * Indicates that the "Manage bundle versions" page should be shown instead of the asset details.
     * @reviewer reasons for choosing this approach over an individual route:
     * - no interference and special treatment in route synchroniziation logic
     * - no interference with asset editor header UI (e.g. breadcrumbs)
     * - no need for persisting the state in general (similar to dialogs)
     */
    showManageBundleVersionsPage: boolean;
  };
};

const initialState: AssetsState = {
  bundles: {},
  relevantBundleVersions: {},
  assets: {},
  assetLinks: {},
  fetchState: {
    bundles: 'None',
    bundleVersions: 'None',
    assets: 'None',
  },
  // represents the path of the selected asset data
  // this should always be in sync with the route of the asset editor!
  selection: {
    bundleId: '',
    bundleVersion: '',
    folderId: '',
    assetId: '',
  },
  ui: {
    selectedTreeViewNodeIds: [],
    showManageBundleVersionsPage: false,
  },
};

// Type predicate function for all asset types
export function isDataSourceAsset(asset: Asset): asset is DataSourceAsset {
  return asset.type === AssetTypes.DataSource;
}
export function isImageAsset(asset: Asset): asset is ImageAsset {
  return asset.type === AssetTypes.Image;
}
export function isFileAsset(asset: Asset): asset is FileAsset {
  return asset.type === AssetTypes.File;
}
export function isFolderAsset(asset: Asset): asset is FolderAsset {
  return asset.type === AssetTypes.Folder;
}
export function isBabylonAsset(asset: Asset): asset is BabylonAsset {
  return asset.type === AssetTypes.BabylonJs;
}
export function isMaterialAsset(asset: Asset): asset is MaterialAsset {
  return asset.type === AssetTypes.Material;
}
export function isTextAsset(asset: Asset): asset is TextAsset {
  return asset.type === AssetTypes.Text;
}

export function isPbrMaterialAsset(asset: Asset): asset is MaterialAsset {
  return isMaterialAsset(asset) && asset.materialType === 'BABYLON.PBRMaterial';
}

export function isAssetBundleVersionDraft(version: AssetBundleVersion): version is AssetBundleVersionDraft {
  return version.isDraft;
}

/**
 * Fetches all bundles for a certain company
 */
export const getAssetBundles = createAsyncThunk<
  AssetBundles,
  {
    companyId: string;
    /**
     * Fetch & store the bundle result without any further action\
     * E.g. To update the bundles dropdown without noticeable change in the UI
     */
    silentBundleUpdate?: boolean;
  }
>('assets/getAssetBundles', async ({ companyId }, thunkApi) => {
  if (!companyId) {
    return thunkApi.rejectWithValue(undefined);
  }

  const assetBundles = await fetchAssetBundles(companyId);
  return assetBundles;
});

/**
 * Fetches all bundle versions from a certain bundle
 */
export const getRelevantAssetBundleVersions = createAsyncThunk<
  AssetBundleVersions,
  { companyId: string; bundleId: string }
>('assets/getRelevantAssetBundleVersions', async ({ companyId, bundleId }, thunkApi) => {
  if (!companyId || !bundleId) {
    return thunkApi.rejectWithValue(undefined);
  }

  const assetBundleVersions = await fetchRelevantAssetBundleVersions(
    companyId,
    bundleId,
    _LOAD_RELEVANT_VERSIONS_PAGE_SIZE
  );
  return assetBundleVersions;
});

export const deleteAssetBundleVersion = createAsyncThunk<
  void,
  { companyId: string; bundleId: string; bundleVersion: string }
>('assets/deleteAssetBundleVersion', async ({ companyId, bundleId, bundleVersion }, thunkApi) => {
  if (!companyId || !bundleId || !bundleVersion) {
    return thunkApi.rejectWithValue(undefined);
  }

  await deleteAssetBundleVersionOnServer(companyId, bundleId, bundleVersion);
});

/**
 * Fetches all assets from a bundle version
 */
export const getBundleVersionData = createAsyncThunk<
  AssetBundleVersionData,
  { companyId: string; bundleId: string; bundleVersion: string }
>('assets/getBundleVersionData', async ({ companyId, bundleId, bundleVersion }, thunkApi) => {
  if (!companyId || !bundleId || !bundleVersion) {
    return thunkApi.rejectWithValue(undefined);
  }

  const bundleVersionData = await fetchBundleVersionData(companyId, bundleId, bundleVersion);
  if (!bundleVersionData) {
    return thunkApi.rejectWithValue(undefined);
  }

  return bundleVersionData;
});

export const finishAssetUpload = createAsyncThunk<
  AssetsCompleteResponse,
  { companyId: string; assetBundleId: string; bundleVersion: string; items: CompleteAssetDto[] }
>('assets/finishAssetUpload', async ({ companyId, assetBundleId, bundleVersion, items }, thunkApi) => {
  if (!companyId || !assetBundleId || !bundleVersion) {
    return thunkApi.rejectWithValue(undefined);
  }

  const postResult = await postAssetsComplete(companyId, assetBundleId, bundleVersion, items);

  return postResult;
});

export const moveAsset = createAsyncThunk<
  AssetsCompleteResponse,
  {
    companyId: string;
    bundleId: string;
    bundleVersion: string;
    moveItems: RelocateAssetRequestItems[];
  }
>('assets/moveAsset', async ({ companyId, bundleId, bundleVersion, moveItems }, thunkApi) => {
  const postResult = await postAssetMove(companyId, bundleId, bundleVersion, moveItems);
  // get assets if at least one asset was successful
  if (postResult.success || moveItems.length - postResult.failedAssets.length > 0) {
    await thunkApi.dispatch(getBundleVersionData({ companyId, bundleId, bundleVersion }));
  }

  return postResult;
});

export const copyAsset = createAsyncThunk<
  AssetsCompleteResponse,
  {
    companyId: string;
    bundleId: string;
    bundleVersion: string;
    copyItems: RelocateAssetRequestItems[];
  }
>('assets/copyAsset', async ({ companyId, bundleId, bundleVersion, copyItems }, thunkApi) => {
  const postResult = await postAssetCopy(companyId, bundleId, bundleVersion, copyItems);
  // get assets if at least one asset was successful
  if (postResult.success || copyItems.length - postResult.failedAssets.length > 0) {
    await thunkApi.dispatch(getBundleVersionData({ companyId, bundleId, bundleVersion }));
  }

  return postResult;
});

export const deleteAsset = createAsyncThunk<
  AssetsCompleteResponse,
  { companyId: string; bundleId: string; bundleVersion: string; deleteItems: DeleteAssetRequestItems[] }
>('assets/deleteAsset', async ({ companyId, bundleId, bundleVersion, deleteItems }, thunkApi) => {
  const postResult = await postDeleteAsset(companyId, bundleId, bundleVersion, deleteItems);
  // get assets if at least one asset was successful
  if (postResult.success || deleteItems.length - postResult.failedAssets.length > 0) {
    await thunkApi.dispatch(getBundleVersionData({ companyId, bundleId, bundleVersion }));
  }

  return postResult;
});

export const createFolderAsset = createAsyncThunk<
  AssetsCompleteResponse,
  { companyId: string; assetBundleId: string; bundleVersion: string; folderNames: string[] }
>('assets/createFolderAsset', async ({ companyId, assetBundleId, bundleVersion, folderNames }, thunkApi) => {
  if (!companyId || !assetBundleId || !bundleVersion) {
    return thunkApi.rejectWithValue(undefined);
  }

  const postResult = await postCreateFolderAsset(companyId, assetBundleId, bundleVersion, folderNames);

  return postResult;
});

export const changeDataSourceOfAsset = createAsyncThunk<
  boolean,
  {
    companyId: string;
    bundleId: string;
    bundleVersion: string;
    folderId: string;
    assetId: string;
    config: DataSourceConfigDto;
    useConfigAutoDetection: boolean;
  }
>(
  'assets/changeDataSourceOfAsset',
  async ({ companyId, bundleId, bundleVersion, folderId, assetId, config, useConfigAutoDetection }, thunkApi) => {
    if (!companyId || !bundleId || !bundleVersion) {
      return thunkApi.rejectWithValue(undefined);
    }
    let success = false;

    // Execute dedicated server call, depending on the exact data source type (CSV or Excel)
    if (isCsvDataSourceConfigDto(config)) {
      success = await postAssetChangeCsvDataSource(companyId, bundleId, bundleVersion, folderId, assetId, config);
    } else if (isExcelDataSourceConfigDto(config)) {
      success = await postAssetChangeExcelDataSource(
        companyId,
        bundleId,
        bundleVersion,
        folderId,
        assetId,
        config,
        useConfigAutoDetection
      );
    }

    // get assets if at least one asset was successful
    if (success) {
      await thunkApi.dispatch(getBundleVersionData({ companyId, bundleId, bundleVersion }));
    }

    return success;
  }
);

export const createDataSourceFromExcelFileAsset = createAsyncThunk<
  boolean,
  {
    companyId: string;
    bundleId: string;
    bundleVersion: string;
    sourceAsset: string;
    targetAsset: string;
  }
>(
  'assets/createDatasourceFromExcelFileAsset',
  async ({ companyId, bundleId, bundleVersion, sourceAsset, targetAsset }, thunkApi) => {
    const success = await postAssetsCreateExcelDataSourceBasedOnFileAsset(
      companyId,
      bundleId,
      bundleVersion,
      sourceAsset,
      targetAsset
    );

    if (success) {
      await thunkApi.dispatch(getBundleVersionData({ companyId, bundleId, bundleVersion }));
    }

    return success;
  }
);

export const updateBundleVersionLocks = createAsyncThunk<void>(
  'assets/updateBundleVersionLocks',
  async (_, thunkApi) => {
    const store = thunkApi.getState() as RootState;
    const companyId = store.companyData.selection.companyId;
    const { bundleId, bundleVersion } = store.assets.selection;

    // actually only update the data of the current bundle, as "hasLockedVersions" state could have been changed
    // maybe there will be a dedicated call in the future
    await thunkApi.dispatch(getAssetBundles({ companyId, silentBundleUpdate: true }));
    await thunkApi.dispatch(getBundleVersionData({ companyId, bundleId, bundleVersion }));
  }
);

const SLICE_NAME = 'assets';

PersistenceMiddleware.registerState<AssetsState, AssetsState>({
  state: SLICE_NAME,
  key: 'selection',
  selector: state => state,
  props: ['selection'],
});

const getRehydratedState = (): AssetsState => {
  const rehydratedState = PersistenceMiddleware.rehydrateState<AssetsState>(SLICE_NAME, initialState);
  return rehydratedState;
};

const assetsSlice = createSlice({
  name: SLICE_NAME,
  initialState: getRehydratedState,
  reducers: {
    resetAssetsSlice: () => initialState,
    setSelection: (state, { payload }: PayloadAction<Partial<AssetSelection>>) => {
      state.selection = { ...state.selection, ...payload };
    },
    addLocalAssets: (state, { payload }: PayloadAction<{ path: AssetPath; type: AssetTypes; fileSize: number }[]>) => {
      payload.forEach(entry => {
        const key = assetPathToKey(entry.path);

        // create new asset or expand already existing one
        // set the upload state to "ToBeUploaded" in any case
        if (state.assets[key]) {
          state.assets[key] = {
            ...state.assets[key],
            // also update path and type, since this could have been changed
            // eg: change casing of asset name (asset1 => aSsEt1)
            path: entry.path,
            type: entry.type,
            uploadState: 'ToBeUploaded',
          };
        } else {
          state.assets[key] = {
            path: entry.path,
            type: entry.type,
            uploadState: 'ToBeUploaded',
            existsOnServer: false,
            errorBag: { items: [], totalItems: 0 },
            warningBag: { items: [], totalItems: 0 },
            hintBag: { items: [], totalItems: 0 },
          };
        }

        const localAsset = state.assets[key];

        // update the file size from the input, this is required for the percentage spinner
        localAsset.fileSize = entry.fileSize;

        // check if new folder has to be created
        if (localAsset.path.folderId) {
          const parentFolderPath = getAssetParentFolderPath(localAsset.path);
          const parentFolderKey = assetPathToKey(parentFolderPath);
          localAsset.parentFolderKey = parentFolderKey;

          if (!state.assets[parentFolderKey]) {
            // parent folder not available yet, create one
            const newFolderAsset: FolderAsset = {
              path: parentFolderPath,
              type: AssetTypes.Folder,
              // there is no upload procedure for folders, so the upload state can immediately be set to "Finished" ...
              uploadState: 'Finished',
              // ... but it's not guarenteed that the folder got created, so we set this to `false`
              existsOnServer: false,
              childKeys: [],
              errorBag: { items: [], totalItems: 0 },
              warningBag: { items: [], totalItems: 0 },
              hintBag: { items: [], totalItems: 0 },
            };
            state.assets[parentFolderKey] = newFolderAsset;
          }

          const parentFolderAsset = state.assets[parentFolderKey] as FolderAsset;
          if (!parentFolderAsset.childKeys.includes(key)) {
            // also add child key if not already available
            parentFolderAsset.childKeys.push(key);
          }
        }

        if (isFolderAsset(localAsset) && !localAsset.childKeys) {
          localAsset.childKeys = [];
        }
      });
    },
    setAssetUploadState: (
      state,
      {
        payload,
      }: PayloadAction<
        { path: AssetPath; uploadState?: AssetUploadState; uploadPercentage?: number; errorText?: string }[]
      >
    ) => {
      payload.forEach(entry => {
        const key = assetPathToKey(entry.path);

        // assets may not exist in store, for example if the upload state is set from an error message
        if (!state.assets[key]) {
          state.assets[key] = {
            path: entry.path,
            // only temporary, this will be overwritten by the next server call (`getAssetsAndLinks`)
            type: AssetTypes.File,
            // only temporary, this will be overwritten a few lines beyond
            uploadState: 'Failed',
            existsOnServer: false,
            errorBag: { items: [], totalItems: 0 },
            warningBag: { items: [], totalItems: 0 },
            hintBag: { items: [], totalItems: 0 },
          };
        }

        const asset = state.assets[key];
        if (entry.uploadState !== undefined) {
          asset.uploadState = entry.uploadState;
        }
        if (entry.uploadPercentage !== undefined) {
          asset.uploadPercentage = entry.uploadPercentage;
        }
        if (entry.errorText !== undefined) {
          asset.errorText = entry.errorText;
        }
      });
    },
    setSelectedTreeViewNodeIds: (state, { payload }: PayloadAction<string[]>) => {
      state.ui.selectedTreeViewNodeIds = payload;
    },
    setShowManageBundleVersionsPage: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showManageBundleVersionsPage = payload;
    },
    clearUploadState: (state, { payload }: PayloadAction<AssetPath>) => {
      const key = assetPathToKey(payload);
      const asset = state.assets[key];
      if (asset.uploadState === 'Failed') {
        if (asset.existsOnServer) {
          asset.uploadState = 'FromServer';
          asset.uploadPercentage = undefined;
          asset.errorText = undefined;
        } else {
          delete state.assets[key];
          if (payload.folderId) {
            const folderKey = assetPathToKey(getAssetParentFolderPath(payload));
            const folder = state.assets[folderKey] as FolderAsset;
            folder.childKeys = folder.childKeys.filter(c => c !== key);
          }
        }
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(globalReset, () => initialState)
      .addCase(globalCompanySwitch, state => {
        state.selection = initialState.selection;
        state.fetchState = { bundles: 'Reset', bundleVersions: 'Reset', assets: 'Reset' };
      })
      .addCase(getAssetBundles.pending, (state, { meta }) => {
        if (!meta.arg.silentBundleUpdate) {
          state.fetchState.bundles = 'Pending';
          state.bundles = initialState.bundles;
        }
      })
      .addCase(getAssetBundles.fulfilled, (state, { payload }) => {
        state.fetchState.bundles = 'Fetched';
        state.bundles = payload;
      })
      .addCase(getAssetBundles.rejected, state => {
        state.fetchState.bundles = 'Aborted';
      })
      .addCase(getRelevantAssetBundleVersions.pending, state => {
        state.fetchState.bundleVersions = 'Pending';
        state.relevantBundleVersions = initialState.relevantBundleVersions;
      })
      .addCase(getRelevantAssetBundleVersions.fulfilled, (state, { payload }) => {
        state.fetchState.bundleVersions = 'Fetched';
        state.relevantBundleVersions = payload;
      })
      .addCase(getRelevantAssetBundleVersions.rejected, state => {
        state.fetchState.bundleVersions = 'Aborted';
      })
      .addCase(deleteAssetBundleVersion.fulfilled, (state, { meta }) => {
        // NOTE: we can't execute `getRelevantAssetBundleVersions` in the `deleteAssetBundleVersion` thunk as we might
        // have relevant versions that were requested directly by the route instead of the
        // `getRelevantAssetBundleVersions` call => such versions would get lost
        const bundleToDelete = Object.entries(state.relevantBundleVersions).find(
          ([key, version]) => version.id === meta.arg.bundleVersion
        );
        if (bundleToDelete) {
          delete state.relevantBundleVersions[bundleToDelete[0]];
        }
      })
      .addCase(getBundleVersionData.pending, state => {
        state.fetchState.assets = 'Pending';
      })
      .addCase(getBundleVersionData.fulfilled, (state, { payload, meta }) => {
        const newAssetsPath: AssetPath = {
          companyId: meta.arg.companyId,
          bundleId: meta.arg.bundleId,
          bundleVersion: meta.arg.bundleVersion,
          folderId: '',
          assetId: '',
        };

        // delete every fully uploaded assets (from server or in finished state)
        Object.entries(state.assets).forEach(([key, asset]) => {
          const belongsToFetchedBundleVersion = isAssetOfSameBundleVersion(asset.path, newAssetsPath);
          const isRefetchableState = _REFETCHABLE_ASSET_STATES.includes(asset.uploadState);

          if (belongsToFetchedBundleVersion && isRefetchableState) {
            delete state.assets[key];
          }
        });

        // at this point only local assets are available for that certain base path!
        Object.entries(payload.assets).forEach(([key, newAsset]) => {
          const localAsset = state.assets[key];
          if (localAsset) {
            // data from local asset (like the upload state) should have priority in general but type should be taken
            // from server!
            // this is the case if local assets are created from error messages, which don't have a asset type
            // information
            state.assets[key] = { ...newAsset, ...localAsset, existsOnServer: true, type: newAsset.type };
          } else {
            // asset not available yet, just add it here
            state.assets[key] = newAsset;
          }

          if (isFolderAsset(state.assets[key])) {
            (state.assets[key] as FolderAsset).childKeys = [];
          }
        });

        // all assets exist in the new state and are merged with the local state
        // now populate the childKeys-array for the folders
        const nonFolderAssets = Object.entries(state.assets).filter(([_, entry]) => !isFolderAsset(entry));
        nonFolderAssets.forEach(([key, asset]) => {
          if (!asset.parentFolderKey) {
            return;
          }
          const folderAsset = state.assets[asset.parentFolderKey];
          if (folderAsset && isFolderAsset(folderAsset) && !folderAsset.childKeys.includes(key)) {
            folderAsset.childKeys.push(key);
          }
        });

        state.assetLinks = payload.assetLinks;
        state.fetchState.assets = 'Fetched';

        state.relevantBundleVersions[payload.bundleVersionInfo.id] = payload.bundleVersionInfo;
      })
      .addCase(getBundleVersionData.rejected, state => {
        state.assets = initialState.assets;
        state.assetLinks = initialState.assetLinks;
        state.fetchState.assets = 'Aborted';
      })
      .addCase(moveAsset.fulfilled, (state, { payload }) => {
        // TODO: Directly change the state of the asset instead of retrieving all assets (in thunk)?
        //       This might be easy (easier) to accomplish once the normalization part is done
      });
  },
});

// Selectors for list entries (bundles, bundle names, assets)
export const selectAllBundles = createSelector(
  (state: RootState) => state.assets.bundles,
  // filter for "DeleteAfter" flag in a later step
  bundles => Object.values(bundles)
);

export const selectRelevantBundleVersions = createSelector(
  (state: RootState) => state.assets.relevantBundleVersions,
  bundleVersions => Object.values(bundleVersions)
);

export const selectSelectedBundle = createSelector(
  selectAllBundles,
  (state: RootState) => state.assets.selection.bundleId,
  (bundles, bundleId) => bundles.find(b => b.id === bundleId)
);

export const selectSelectedBundleVersion = createSelector(
  selectRelevantBundleVersions,
  (state: RootState) => state.assets.selection.bundleVersion,
  (bundleVersions, selectedBundleVersion) => bundleVersions.find(v => v.id === selectedBundleVersion)
);

export const selectSelectedAsset = createSelector(
  (state: RootState) => state.assets.assets,
  (state: RootState) => state.companyData.selection.companyId,
  (state: RootState) => state.assets.selection,
  (assets, companyId, selectedPath) => {
    const key = assetPathToKey({ companyId, ...selectedPath });

    return assets[key] as Asset | undefined;
  }
);

/**
 * @returns The name of the asset itself, if the selected asset is a folder asset, otherwise the name of the assets
 *          parent folder
 */
export const selectSelectedAssetFolderId = createSelector(
  (state: RootState) => selectSelectedAsset(state),
  selectedAsset => {
    const folder =
      selectedAsset && isFolderAsset(selectedAsset) ? selectedAsset.path.assetId : selectedAsset?.path.folderId || '';
    return folder;
  }
);

export const selectAssetKeys = createSelector(
  (state: RootState) => state.assets.assets,
  assets => Object.keys(assets)
);

/**
 * Selector for returning all assets that are located in a certain base path.
 * Assets are returned as normalized map.
 */
export const selectAssetsInSelectedBasePath = createSelector(
  (state: RootState) => state.assets.assets,
  (state: RootState) => state.companyData.selection.companyId,
  (state: RootState) => state.assets.selection,
  (assets, companyId, selectedPath) => {
    const assetsInBasePath = Object.entries(assets).filter(([_, asset]) => {
      const basePathFits = isAssetOfSameBundleVersion(asset.path, { companyId, ...selectedPath });
      return basePathFits;
    });

    return Object.fromEntries(assetsInBasePath) as Assets;
  }
);

/**
 * Selector for returning all childs of the selected asset.
 * If no folder asset is selected, the root of the base path (companyId, bundleId and bundleVersion) is used.
 */
export const selectChildAssetsInSelectedFolder = createSelector(
  selectAssetsInSelectedBasePath,
  selectSelectedAsset,
  (assets, selectedAsset) => {
    // check in root folder ('') if no folder asset is selected
    const targetFolder = selectedAsset && isFolderAsset(selectedAsset) ? selectedAsset.path.assetId : '';

    const childAssetsInFolder = Object.entries(assets).filter(([_, asset]) => {
      const isInSelectedFolder = asset.path.folderId === targetFolder;
      return isInSelectedFolder;
    });

    return Object.fromEntries(childAssetsInFolder);
  }
);

/**
 * Selector for indicating if the currently selected bundle version is a draft.
 * In this case different operations (eg: delete, publish) are possible.
 */
export const selectSelectedBundleVersionIsDraft = createSelector(
  (state: RootState) => state.assets.relevantBundleVersions,
  (state: RootState) => state.assets.selection.bundleVersion,
  (bundleVersions, selectedBundleVersion) => bundleVersions[selectedBundleVersion]?.isDraft ?? false
);

export const selectUploadingAssets = createSelector(
  (state: RootState) => state.assets.assets,
  assets => {
    const uploadingAssets = Object.values(assets).filter(asset => isUploadInProgress(asset));
    return uploadingAssets;
  }
);

export const selectTextureAssets = createSelector(selectAssetsInSelectedBasePath, assets => {
  const assetsInTextureFolder = Object.entries(assets).filter(([, asset]) => {
    const isInTextureFolder = asset.path.folderId === TEXTURES_3D_FOLDER_NAME;
    const assetIsImageAsset = isImageAsset(asset);
    return isInTextureFolder && assetIsImageAsset;
  }) as [string, ImageAsset][];

  return Object.fromEntries(assetsInTextureFolder);
});

export const selectMaterialAssets = createSelector(selectAssetsInSelectedBasePath, assets => {
  const assetsInMaterialsFolder = Object.entries(assets).filter(
    ([, asset]) => asset.path.folderId === MATERIALS_3D_FOLDER_NAME && isMaterialAsset(asset)
  );

  return Object.fromEntries(assetsInMaterialsFolder);
});

export const selectLinksOfSelectedAsset = createSelector(
  selectSelectedAsset,
  (state: RootState) => state.assets.assetLinks,
  (selectedAsset, assetLinks) => {
    if (!selectedAsset) {
      return [];
    }

    return getAssetLinksOfAsset(selectedAsset, assetLinks);
  }
);

export const selectFileLinkKeyOfSelectedDataSourceAsset = createSelector(
  selectSelectedAsset,
  (state: RootState) => state.assets.assetLinks,
  (selectedAsset, assetLinks) => {
    if (!selectedAsset || !isDataSourceAsset(selectedAsset)) {
      return undefined;
    }
    return findFileLinkKeyOfDatasourceAsset(selectedAsset, assetLinks);
  }
);

export const selectMaterialAssetPathsOfSelectedBabylonAsset = createSelector(
  selectLinksOfSelectedAsset,
  selectMaterialAssets,
  (assetLinks, materialAssets) => {
    const linkedMaterialAssets = Object.values(materialAssets).filter(matAsset => {
      const matKey = createSourceStringFromFolderAndAssetId(matAsset.path.folderId, matAsset.path.assetId);
      const linkFound = assetLinks.some(assetLink => assetLink.target === matKey);
      return linkFound;
    });

    const linkedMaterialAssetPaths = linkedMaterialAssets.map<AssetShortPath>(matAsset => ({
      folderId: matAsset.path.folderId,
      assetId: matAsset.path.assetId,
    }));

    return linkedMaterialAssetPaths;
  }
);

export const selectUsagesOfSelectedMaterialAsset = createSelector(
  selectSelectedAsset,
  (state: RootState) => state.assets.assetLinks,
  (selectedAsset, assetLinks) => {
    if (!selectedAsset) {
      return [];
    }

    const { folderId, assetId } = selectedAsset.path;
    const key = createSourceStringFromFolderAndAssetId(folderId, assetId);

    const linksToMaterial = Object.entries(assetLinks).filter(([, link]) => {
      return link.some(l => l.type === AssetLinkTypes.BabylonJsToMaterial && l.target === key);
    });

    return linksToMaterial.map(([key]) => key);
  }
);

export const selectAllDistinctBabylonTagsInBundle = createSelector(
  (state: RootState) => state.assets.assets,
  bundleAssets => {
    const assets = Object.values(bundleAssets);
    const allBundleTagsNested = assets.map(x => {
      const assetTags = !isBabylonAsset(x) || !x.tagAssignments ? [] : Object.values(x.tagAssignments);
      return assetTags;
    });

    // `allbundleTagsNested` = 2 levels of nested arrays -> Flatten with `flat(2)`.
    // E.g.:
    // ```
    // [
    //   [
    //     ['tag1', 'tag2' ],
    //     ['tag3', 'tag4' ],
    //   ],
    //   [
    //     ['tag1', 'tag3' ],
    //     ['tag5', 'tag6' ],
    //   ]
    // ]
    // ```
    const allBundleTags = allBundleTagsNested.flat(2);
    const allDistinctBundleTags = allBundleTags.filter(distinctFilter);
    return allDistinctBundleTags;
  }
);

export const selectSelectedAssetPath = createSelector(
  (state: RootState) => state.companyData.selection.companyId,
  (state: RootState) => state.assets.selection,
  (companyId, selectedPath) => {
    const fullPath: AssetPath = { companyId, ...selectedPath };
    return fullPath;
  }
);

export const selectAssetsOfTreeViewNodeIds = createSelector(
  (state: RootState) => state.assets.assets,
  (state: RootState) => state.assets.ui.selectedTreeViewNodeIds,
  (assets, selectNodeIds) =>
    Object.entries(assets)
      .filter(([key]) => selectNodeIds.includes(key))
      .map(([_, asset]) => asset)
);

export const {
  resetAssetsSlice,
  setSelection,
  addLocalAssets,
  setAssetUploadState,
  setSelectedTreeViewNodeIds,
  setShowManageBundleVersionsPage,
  clearUploadState,
} = assetsSlice.actions;
export default assetsSlice.reducer;
