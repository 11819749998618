export async function sleep(durationMs: number): Promise<void> {
  return new Promise(resolve => {
    window.setTimeout(resolve, durationMs);
  });
}

/**
 * Wait for a promise which shouldn't resolve until the given amount of time has passed\
 * E.g.: If an animation would result in an "unpleasant flicker", when the actual promise happens too fast
 */
export async function executeAtLeast<T>(promise: Promise<T>, timeInMS: number): Promise<T> {
  const timedPromise = new Promise(resolve => setTimeout(resolve, timeInMS));
  const result = await Promise.all([promise, timedPromise]);
  return result[0];
}
