import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateCfgrModal } from 'components/cfgrs/create-cfgr-modal';
import {
  CreateCfgrTemplatePickerCard,
  CreateCfgrTemplatePickerCardSkeleton,
} from 'components/create-cfgr-template-picker/create-cfgr-template-picker-card';
import { Button } from 'controls/button/button';
import { cn } from 'helper/css.helper';
import { useElementSize } from 'hooks/common/sizing.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { CfgrTemplate, GetCfgrTemplateResponse, getCfgrTemplates } from 'services/cfgrs/cfgrs.service';
import { buildCfgrsPath } from 'services/routes/cfgrs-routes.service';

type CreateCfgrTemplatePickerProps = {
  companyId: string;
  onCfgrCreated?: (cfgrName: string) => void;
};

export const CreateCfgrTemplatePicker: React.FC<CreateCfgrTemplatePickerProps> = ({ companyId, onCfgrCreated }) => {
  const { t } = useTypedTranslation();
  const navigate = useNavigate();

  const [cfgrTemplates, setCfgrTemplates] = useState<GetCfgrTemplateResponse>();
  const [selectedCfgrTemplate, setSelectedCfgrTemplate] = useState<CfgrTemplate>();
  const [loadingCnt, setLoadingCnt] = useState(0);
  const isLoading = loadingCnt > 0;

  const [isExpanded, setIsExpanded] = useState(false);
  const { ref: gridRef, element: gridEl, size: gridSize } = useElementSize();
  const [hasHiddenCards, setHasHiddenCards] = useState(false);

  useEffect(
    function updateHasHiddenCards() {
      const gridChildsWithZeroHeight = gridEl?.children && Array.from(gridEl.children).filter(x => x.clientHeight <= 0);
      const hasHiddenCards = !!gridChildsWithZeroHeight && gridChildsWithZeroHeight.length > 0;
      setHasHiddenCards(hasHiddenCards);
    },

    // The `isLoading` & `gridSize` deps are needed even if they are not used in the effect.
    // Without them, the "Show all" button will not appear in certain situation when it actually should.
    //
    // Concrete situations where this can happen:
    // Viewport width where the number of cards per row is equal to the number of skeleton cards which are shown during
    // loading the templates. Issue only happens when loading of cards takes some time.
    [gridEl?.children, isLoading, gridSize]
  );

  useEffect(() => {
    async function fetchCfgrTemplates(): Promise<void> {
      setLoadingCnt(x => x + 1);
      const resp = await getCfgrTemplates(companyId);
      setCfgrTemplates(resp);
      setLoadingCnt(x => x - 1);
    }
    fetchCfgrTemplates();
  }, [companyId]);

  const showCreateCfgrModal = (templateId: string): void => {
    const selectedTemplate = cfgrTemplates?.templates.find(x => x.id === templateId);
    setSelectedCfgrTemplate(selectedTemplate);
  };

  const redirectToCfgrDrafts = (cfgrName: string): void => {
    setSelectedCfgrTemplate(undefined);
    navigate(buildCfgrsPath('drafts', companyId, cfgrName));
  };

  return (
    <div data-cmptype="CreateCfgrTemplatePicker" className="flex flex-col gap-3">
      <div
        ref={gridRef}
        className={cn(
          'grid grid-cols-[repeat(auto-fill,theme(spacing.cfgr-template-card-width))] gap-x-cfgr-template-cards-x-gap gap-y-5',
          !isExpanded && 'auto-rows-[0] grid-rows-1 gap-y-0 overflow-hidden'
        )}
      >
        {isLoading && (
          <>
            <CreateCfgrTemplatePickerCardSkeleton />
            <CreateCfgrTemplatePickerCardSkeleton />
            <CreateCfgrTemplatePickerCardSkeleton />
          </>
        )}

        {!isLoading &&
          cfgrTemplates?.templates.map(x => (
            <CreateCfgrTemplatePickerCard key={x.id} template={x} onCreateClick={showCreateCfgrModal} />
          ))}
      </div>

      {!isLoading && (hasHiddenCards || isExpanded) && (
        <div className="flex justify-end">
          <Button
            variant="Outlined"
            text={t(isExpanded ? 'Show less' : 'Show all')}
            onClick={(): void => setIsExpanded(!isExpanded)}
          />
        </div>
      )}

      {cfgrTemplates && selectedCfgrTemplate && (
        <CreateCfgrModal
          targetCompanyName={companyId}
          template={selectedCfgrTemplate}
          existingCfgrsAndBundles={{
            cfgrNames: cfgrTemplates.existingCfgrNames,
            cfgrDisplayNames: cfgrTemplates.existingCfgrDisplayNames,
            assetBundleNames: cfgrTemplates.existingAssetBundleNames,
            assetBundleDisplayNames: cfgrTemplates.existingAssetBundleDisplayNames,
          }}
          onCfgrCreated={(cfgrName): void => {
            onCfgrCreated?.(cfgrName);
            redirectToCfgrDrafts(cfgrName);
          }}
          onCancel={(): void => setSelectedCfgrTemplate(undefined)}
        />
      )}
    </div>
  );
};
