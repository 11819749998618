import { Badge } from '@mui/material';
import { useRef } from 'react';
import { BellNotificationIcon } from 'assets/icons';
import { NotificationItemReplicator } from 'components/notifications/notification-item-replicator';
import { CbnMenu } from 'controls/cbn-menu/cbn-menu';
import { CbnMenuItem } from 'controls/cbn-menu/cbn-menu-item';
import { Icon } from 'controls/icon/icon';
import { Tooltip } from 'controls/tooltip/tooltip';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import {
  selectNotificationsCount,
  selectReplicatorNotifications,
  selectUnreadNotificationsCount,
  showNotificationMenu,
} from 'slices/notifications/notifications.slice';

export const NotificationCenter: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const preventReopen = useRef(false);
  const replicatorNotifications = useAppSelector(selectReplicatorNotifications);
  const sortedReplicatorNotifications = [...replicatorNotifications].sort((a, b) => b.timestamp - a.timestamp);
  const hasUnreadNotifications = replicatorNotifications.some(n => !n.markedAsRead);
  const notificationsCount = useAppSelector(selectNotificationsCount);
  const unreadNotificationsCount = useAppSelector(selectUnreadNotificationsCount);
  const { isMenuOpen } = useAppSelector(state => state.notifications.ui);
  const menuRef = useRef<HTMLButtonElement>(null);

  const menuAnchorEl = isMenuOpen ? menuRef.current : null;

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    if (preventReopen.current) {
      event.preventDefault();
      preventReopen.current = false;
      return;
    }

    dispatch(showNotificationMenu(true));
  };

  const handleMenuClose = (): void => {
    dispatch(showNotificationMenu(false));
  };

  const handleButtonMouseDown = (): void => {
    if (isMenuOpen) {
      // Prevents the menu from reopening right after closing
      // when clicking the button.
      preventReopen.current = true;
    }
  };

  return (
    <>
      <Tooltip title={t('Notifications')}>
        <button
          onMouseDown={handleButtonMouseDown}
          onClick={handleOpenMenu}
          ref={menuRef}
          data-cmptype="NotificationCenter"
        >
          <Badge color="error" invisible={!hasUnreadNotifications} badgeContent={unreadNotificationsCount}>
            <Icon Svg={BellNotificationIcon} className="h-6 w-6" />
          </Badge>
        </button>
      </Tooltip>
      <CbnMenu
        anchor={menuAnchorEl}
        placement="bottom-end"
        open={isMenuOpen}
        onClose={handleMenuClose}
        id="notification-center"
      >
        <div className="flex max-h-96 flex-col overflow-y-auto">
          {notificationsCount > 0 ? (
            sortedReplicatorNotifications.map(notification => (
              <div key={notification.uid} className="px-6 py-4 hover:bg-neutral-20">
                <NotificationItemReplicator notification={notification} />
              </div>
            ))
          ) : (
            <CbnMenuItem text={t('You have no new notifications')} />
          )}
        </div>
      </CbnMenu>
    </>
  );
};
