export const EXTJS_ROUTE_PREFIX = '/be#';

/**
 * @example
 * // check with a lookahead if the route is followed by a slash or it's the end of the string
 * searchPattern: /#dashboard(?=\/|$)/,
 * replacement: 'dashboard',
 */
type ExtJSRouteMapping = {
  /**
   * Regex pattern which should match and will be replaced
   *
   * Always consider special cases:
   * - case-sensitivity
   * - optional route parameters
   * - false-positive matches (e.g. company partially matches companysettings)
   */
  searchPattern: RegExp;

  /**
   * replacement for the found string (may contain capture groups from regex)
   */
  replacement: string;
};

/**
 * CAUTION WHEN REMOVING ENTRIES: Ensure that mails from the backend don't use this route anymore
 * It's recommended to add additional tests for each route!
 */
const extjsRouteMapping: ExtJSRouteMapping[] = [
  {
    searchPattern: /#dashboard(?=\/|$)/,
    replacement: 'dashboard',
  },
  {
    searchPattern: /#newpassword(?=\/|$)/,
    replacement: 'resetpwd',
  },
  {
    searchPattern: /#activate(?=\/|$)/,
    replacement: 'activate',
  },
  {
    searchPattern: /#acceptinvitation(?=\/|$)/,
    replacement: 'acceptinvitation',
  },
];

type ExtJsHistoryState = {
  extJs: true;
  referrer: string;
};

export function isExtJsHistoryState(state: ExtJsHistoryState | null): state is ExtJsHistoryState {
  return state !== null && state.extJs;
}

/**
 * When the url is from the 'legacy routing' with hashes we try to map it to it's new route in React
 * If it doesn't exist in React yet, a redirect to ExtJS happens -> [url]/be[#hash]
 */
export function mapOrRedirectExtJSRoute(): void {
  if (window.location.pathname === '/' && window.location.hash.length > 1) {
    const newRoute = _getNewRoute(window.location.hash);
    if (newRoute) {
      window.location.replace(newRoute);
    }
  }
}

function _getNewRoute(hashRoute: string): string | undefined {
  if (hashRoute[0] !== '#') {
    return undefined;
  }

  const foundMapping = extjsRouteMapping.find(mapping => {
    const pattern = new RegExp(mapping.searchPattern, 'i');
    return pattern.test(hashRoute);
  });

  if (foundMapping) {
    // stay in React with the mapped route from ExtJS to React
    const pattern = new RegExp(foundMapping.searchPattern, 'i');
    return hashRoute.replace(pattern, foundMapping.replacement);
  }
}

export const _testing = { _getNewRoute };
