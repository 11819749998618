import { ToggleButtonGroup as MuiToggleButtonGroup, styled, toggleButtonClasses } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { CbnToggleButton } from 'controls/cbn-toggle-button/cbn-toggle-button';
import { Icon, SvgComponent } from 'controls/icon/icon';
import { ForwardableTooltipProps } from 'controls/tooltip/tooltip';
import { cn } from 'helper/css.helper';

const StyledToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonClasses.root}`]: {
    'color': theme.palette.neutral[90],
    'borderColor': theme.palette.neutral[50],
    'borderRadius': '0.5rem',
    'padding': theme.spacing(2, 2),

    '&:hover': {
      backgroundColor: theme.palette.neutral[20],
      borderColor: theme.palette.neutral[50],
    },

    [`&.${toggleButtonClasses.selected}`]: {
      backgroundColor: theme.palette.primary.surface,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.border,
    },
  },
}));
export type CbnToggleButtonValue = string | number;

export type CbnToggleButtonOption = {
  value: CbnToggleButtonValue;
  text?: string;
  icon?: SvgComponent;
  title?: string;
  titleProps?: ForwardableTooltipProps;
};

type CbnToggleButtonGroupProps<TExclusive = boolean> = TExclusive extends true
  ? {
      /** If true, only one selection is allowed */
      exclusive: true;
      fullWidth?: boolean;
      big?: boolean;
      entries: CbnToggleButtonOption[];
      value: CbnToggleButtonValue;
      onValueChange?: (value: CbnToggleButtonValue) => void;
      onSelectedItemClick?: (value: CbnToggleButtonValue) => void;
    }
  : {
      exclusive: false;
      fullWidth?: boolean;
      big?: boolean;
      entries: CbnToggleButtonOption[];
      value: CbnToggleButtonValue[];
      onValueChange?: (value: CbnToggleButtonValue) => void;
      onSelectedItemClick?: (value: CbnToggleButtonValue) => void;
    };

export const CbnToggleButtonGroup = React.forwardRef<HTMLElement, CbnToggleButtonGroupProps>(
  ({ entries, value, exclusive, fullWidth, big, onValueChange, onSelectedItemClick }, ref) => {
    const onChange = (event: React.MouseEvent<HTMLElement>, newValue: CbnToggleButtonValue): void => {
      if (newValue === null) {
        // no change (same button clicked)
        return;
      }
      onValueChange?.(newValue);
    };

    // execute callback if already selected button has been clicked again
    const onToggleButtonClick = (event: React.MouseEvent<HTMLElement>, clickValue: CbnToggleButtonValue): void => {
      if (value === clickValue) {
        onSelectedItemClick?.(clickValue);
      }
    };

    return (
      <StyledToggleButtonGroup
        data-cmptype="CbnToggleButtonGroup"
        value={value}
        ref={ref}
        onChange={onChange}
        exclusive={exclusive}
        fullWidth={fullWidth}
        className={cn('h-8 text-m-medium', big && 'h-10')}
      >
        {entries.map(entry => (
          <CbnToggleButton
            key={entry.value}
            value={entry.value}
            title={entry.title}
            titleProps={entry.titleProps}
            onClick={onToggleButtonClick}
          >
            <div
              className={classNames('flex items-center gap-2', {
                'p-2': !!entry.text,
              })}
            >
              {entry.icon && <Icon Svg={entry.icon} className="w-5" />}
              {entry.text}
              {!entry.icon && !entry.text && entry.value}
            </div>
          </CbnToggleButton>
        ))}
      </StyledToggleButtonGroup>
    );
  }
);
