import classNames from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ErrorIcon, HintIcon, WarningIcon } from 'assets/icons';
import { ASSET_TYPE_MAP } from 'components/asset-editor/asset-type-map';
import { Icon } from 'controls/icon/icon';
import { PercentageLoader } from 'controls/loader/percentage-loader';
import { Tooltip } from 'controls/tooltip/tooltip';
import { cn } from 'helper/css.helper';
import { useGetAssetStats } from 'hooks/assets/assets.hooks';
import { PATHS } from 'services/routes/paths.service';
import { Asset, isImageAsset } from 'slices/assets/assets.slice';

type AssetBodyFolderItemProps = {
  asset: Asset;
};

export const AssetBodyFolderItem: React.FC<AssetBodyFolderItemProps> = ({ asset }) => {
  const assetStats = useGetAssetStats(asset);
  const uploadStats = assetStats.uploadStats;

  const [imgLoaded, setImgLoaded] = useState(false);
  const icon = ASSET_TYPE_MAP[asset.type].Icon;

  const path = PATHS.buildAssetEditorPath(
    asset.path.companyId,
    asset.path.bundleId,
    asset.path.bundleVersion,
    asset.path.folderId,
    asset.path.assetId
  );

  const imgSrc = isImageAsset(asset) ? asset.url : undefined;

  const isUploading = uploadStats.uploadPercentage || uploadStats.uploadInfinite;
  const showOverlay = assetStats.hasErrors || assetStats.hasWarnings || assetStats.hasHints || isUploading;

  return (
    <Tooltip title={`${asset.path.assetId} (${ASSET_TYPE_MAP[asset.type].displayName})`} delay={500}>
      <Link to={path} data-cmptype="AssetFolderItem" className="mr-[20px]">
        <div
          className={cn(
            'relative h-[120px] w-[120px] border border-neutral-40 text-neutral-90',
            assetStats.hasErrors && 'border-danger-main',
            assetStats.hasWarnings && 'border-warning-main',
            assetStats.hasHints && 'border-primary-main'
          )}
        >
          <div className="absolute inset-1 flex flex-col gap-1">
            <div className="relative flex grow items-center justify-center overflow-hidden">
              <>
                <Icon Svg={icon} className={classNames('h-12 w-12', { hidden: imgSrc && imgLoaded })}></Icon>
                {imgSrc && (
                  <div
                    className={classNames(
                      'absolute flex h-full w-full items-center justify-center transition-opacity',
                      {
                        'opacity-0': !imgLoaded,
                        'opacity-60': isUploading,
                        'opacity-100': imgLoaded && !isUploading,
                      }
                    )}
                  >
                    <img
                      src={imgSrc}
                      alt={asset.path.assetId}
                      loading="lazy"
                      onLoad={(): void => setImgLoaded(true)}
                    ></img>
                  </div>
                )}
              </>
            </div>
            <div className="h-4 w-full overflow-hidden overflow-ellipsis text-s-regular">{asset.path.assetId}</div>
            {showOverlay && (
              <div
                data-cmptype="AssetFolderItem-overlay"
                className="absolute right-0 top-0 flex items-center justify-center gap-1 bg-neutral-10"
              >
                {isUploading ? (
                  <PercentageLoader
                    percentage={uploadStats.uploadPercentage}
                    infinite={uploadStats.uploadInfinite}
                    size="small"
                    hoverText={uploadStats.uploadText}
                  />
                ) : assetStats.hasErrors ? (
                  <Icon Svg={ErrorIcon} className="h-5 text-danger-main" />
                ) : assetStats.hasWarnings ? (
                  <Icon Svg={WarningIcon} className="h-5 text-warning-main" />
                ) : assetStats.hasHints ? (
                  <Icon Svg={HintIcon} className="h-5 text-primary-main" />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </Link>
    </Tooltip>
  );
};
