// Generated by EnumsBuilderTypeScript (DO NOT change this file manually, change the builder if required)
export enum ReplicationStates {
  Uninitialized = 0,
  Preparing = 1,
  Ready = 2,
  Replicating = 3,
  Finishing = 4,
  Finished = 5,
  Failed = 10,
  UndoPreparation = 11,
  UndoReplication = 12
}