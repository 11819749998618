import { useState } from 'react';
import { CopyLinkIcon, UserDeleteMinusIcon } from 'assets/icons';
import { useCompanySettingsStaff } from 'components/company/company-settings-staff-provider';
import { Button } from 'controls/button/button';
import { CopyToClipboardButton } from 'controls/button/copy-to-clipboard-button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { CbnSearchInput } from 'controls/input/search-input';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'controls/table';
import { Tooltip } from 'controls/tooltip/tooltip';
import { UserRoles } from 'generated/user-role';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { postCompaniesRevokeInvite } from 'services/companies/companies.service';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';

export const CompanySettingsStaffInvitedUsersCard: React.FC = () => {
  const { t } = useTypedTranslation();
  const company = useAppSelector(selectSelectedCompany);
  const companyName = company?.displayName ?? '';
  const [searchInput, setSearchInput] = useState('');
  const [pendingInviteRevokes, setPendingInviteRevokes] = useState<string[]>([]);
  const { userInvites, companyId, fetchInvites } = useCompanySettingsStaff();

  const filteredUserInvites = userInvites
    .filter(x => (x.inviteRecipient || '').toLowerCase().includes(searchInput.toLowerCase()))
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

  const onRevokeInviteClicked = async (token: string): Promise<void> => {
    setPendingInviteRevokes([...pendingInviteRevokes, token]);
    await postCompaniesRevokeInvite(token, companyId);
    fetchInvites();
    setPendingInviteRevokes(pendingInviteRevokes.filter(x => x !== token));
  };

  return (
    <CbnCard data-cmptype="CompanySettingsStaffInvitedUsersCard">
      <CbnCardHeader
        title={t('Invited users')}
        subText={t(`These users are invited, but not yet added to the company ${companyName}.`)}
        actions={
          <div className="w-[12.5rem]">
            <CbnSearchInput
              placeholder={t('Find invited user')}
              value={searchInput}
              onValueChange={(val): void => setSearchInput(val)}
            />
          </div>
        }
      />

      <div className="flex flex-wrap gap-5">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Email or ID')}</TableCell>
              <TableCell>{t('Created')}</TableCell>
              <TableCell>{t('Valid until')}</TableCell>
              <TableCell>{t('Role')}</TableCell>
              <TableCell>{t('Invite link')}</TableCell>
              <TableCell>{t('Revoke invitation')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUserInvites.map(invite => (
              <TableRow key={invite.inviteToken} className={'hover:bg-neutral-20'}>
                <TableCell>
                  <Tooltip title={invite.inviteRecipient ? invite.inviteToken : ''}>
                    <span>{invite.inviteRecipient || invite.inviteToken}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <RelativeDateTime date={invite.createdAt} />
                </TableCell>
                <TableCell>
                  <RelativeDateTime date={invite.validTill} />
                </TableCell>
                <TableCell className="w-[8.75rem]">{UserRoles[invite.role]}</TableCell>
                <TableCell className="w-[9.5rem]">
                  <CopyToClipboardButton
                    text={invite.inviteLink}
                    variant="Text"
                    title={t('Copy invite link to clipboard')}
                    icon={CopyLinkIcon}
                  />
                </TableCell>
                <TableCell className="w-[11rem]">
                  <Button
                    Svg={UserDeleteMinusIcon}
                    hoverColor="Danger"
                    title={t('Revoke user invitation')}
                    titleProps={{ placement: 'right', delay: 500 }}
                    variant={'Text'}
                    onClick={(): Promise<void> => onRevokeInviteClicked(invite.inviteToken)}
                    isLoading={pendingInviteRevokes.includes(invite.inviteToken)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </CbnCard>
  );
};
