import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminIcon, LockIcon } from 'assets/icons';
import { ReplicatorBlueprintSelection } from 'components/replicator/replicator-blueprint-selection';
import { ReplicatorBlueprintVersions } from 'components/replicator/replicator-blueprint-versions';
import { ReplicatorTargets } from 'components/replicator/replicator-targets';
import { Accordion } from 'controls/accordion/accordion';
import { Button } from 'controls/button/button';
import { Icon } from 'controls/icon/icon';
import { Tooltip } from 'controls/tooltip/tooltip';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { buildReplicatorPath } from 'services/routes/replicator-routes.service';
import { addReplicationNotification } from 'slices/notifications/notifications.slice';
import { SystemPermissions } from 'slices/permission/permission.slice';
import {
  createBlueprint,
  createBlueprintCrossEnv,
  loadBlueprintTargetMapping,
  resetBlueprint,
  selectCurrentBlueprint,
  selectCurrentBlueprintHasSelections,
  selectTargetHasAutoPublishes,
  selectTargetHasErrors,
  selectTargetHasNamingClash,
  startReplicate,
  startReplicateCrossEnv,
} from 'slices/replicator/replicator.slice';

const _ACCORDION_KEYS = ['Source', 'Versions', 'Target'] as const;
type AccordionKeys = (typeof _ACCORDION_KEYS)[number];

export const ReplicatorCopy: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();
  const navigate = useNavigate();
  const blueprint = useAppSelector(selectCurrentBlueprint);
  const hasSelectedItems = useAppSelector(selectCurrentBlueprintHasSelections);
  const targetHasErrors = useAppSelector(selectTargetHasErrors);
  const targetNamingClash = useAppSelector(selectTargetHasNamingClash);
  const hasReplicateCrossEnvPermission = usePermission(SystemPermissions.ReplicateCrossEnv, blueprint.companyId);
  const targetHasAutoPublishes = useAppSelector(selectTargetHasAutoPublishes);

  const versionsPanelEnabled = hasSelectedItems;
  const targetPanelEnabled = !!blueprint.blueprintId || !!blueprint.blueprintSasUri;

  const [expandedPanel, setExpandedPanel] = useState<AccordionKeys | null>(
    targetPanelEnabled ? 'Target' : versionsPanelEnabled ? 'Versions' : 'Source'
  );
  const [isCreatingBlueprint, setIsCreatingBlueprint] = useState(false);
  const [crossEnvUri, setCrossEnvUri] = useState<string>();

  useEffect(
    function collapseDisabledAccordions() {
      setExpandedPanel(expanded => {
        let newExpandedPanel: AccordionKeys | null = expanded;
        if (expanded === 'Target' && !targetPanelEnabled) {
          newExpandedPanel = 'Versions';
        }
        if (newExpandedPanel === 'Versions' && !versionsPanelEnabled) {
          newExpandedPanel = 'Source';
        }
        return newExpandedPanel;
      });
    },
    [versionsPanelEnabled, targetPanelEnabled]
  );

  const onResetBlueprintClicked = (): void => {
    dispatch(resetBlueprint());
  };

  const createBlueprintClicked = async (): Promise<void> => {
    setIsCreatingBlueprint(true);
    await dispatch(createBlueprint());
    setIsCreatingBlueprint(false);
    setExpandedPanel('Target');
  };

  const createBlueprintCrossEnvClicked = async (): Promise<void> => {
    setIsCreatingBlueprint(true);
    const sasUri = await dispatch(createBlueprintCrossEnv()).unwrap();
    setCrossEnvUri(sasUri);
    navigator.clipboard.writeText(sasUri);
    setIsCreatingBlueprint(false);
  };

  const onStartReplicationClicked = async (): Promise<void> => {
    let replicationId = '';
    if (blueprint.blueprintSasUri) {
      replicationId = await dispatch(startReplicateCrossEnv()).unwrap();
    } else {
      replicationId = await dispatch(startReplicate()).unwrap();
    }

    if (replicationId) {
      dispatch(
        addReplicationNotification({
          replicationId: replicationId,
          sourceCompany: !blueprint.blueprintSasUri ? blueprint.companyId : undefined,
          isCrossEnv: !!blueprint.blueprintSasUri,
          targetCompany: blueprint.target.companyId,
          cfgrIds: Object.values(blueprint.target.cfgrs).reduce((arr, c) => ({ ...arr, [c.name]: c.displayName }), {}),
          assetBundleIds: Object.values(blueprint.target.assetBundles).reduce(
            (arr, b) => ({ ...arr, [b.bundleName]: b.bundleDisplayName }),
            {}
          ),
          hasAutoPublish: targetHasAutoPublishes,
        })
      );

      navigate(buildReplicatorPath(blueprint.companyId, 'progress'));
    }
  };

  const onResetTargetClicked = async (): Promise<void> => {
    dispatch(loadBlueprintTargetMapping(blueprint.target.companyId));
  };

  const lockInfoIcon = blueprint.blueprintId && (
    <Icon
      Svg={LockIcon}
      className="w-5 text-neutral-70 hover:text-primary-main"
      title={
        <TooltipContent
          header={t('The source selection is saved')}
          detail={t('Please proceed with defining the target or cancel and start again!')}
        />
      }
    />
  );

  return (
    <>
      {!blueprint.blueprintSasUri && (
        <Accordion
          summary={
            <div className="flex items-center gap-2">
              {_createSummary(1, t('Select source'))}
              {lockInfoIcon}
            </div>
          }
          expanded={expandedPanel === 'Source'}
          onChange={(isExpanded): void => setExpandedPanel(isExpanded ? 'Source' : null)}
        >
          <div className="flex flex-col gap-6">
            <ReplicatorBlueprintSelection />
            <div className="flex justify-between">
              <Button onClick={onResetBlueprintClicked} text={t('Reset selections')} variant="Secondary" />
              <Button
                onClick={(): void => setExpandedPanel('Versions')}
                text={t('Confirm selection')}
                disabled={!hasSelectedItems || !!blueprint.blueprintId}
              />
            </div>
          </div>
        </Accordion>
      )}
      {!blueprint.blueprintSasUri && (
        <Accordion
          summary={
            <div className="flex items-center gap-2">
              {_createSummary(2, t('Specify source versions'))}

              {lockInfoIcon}
            </div>
          }
          expanded={expandedPanel === 'Versions'}
          onChange={(isExpanded): void => setExpandedPanel(isExpanded ? 'Versions' : null)}
          disabled={!hasSelectedItems}
        >
          <div className="flex flex-col gap-6">
            <ReplicatorBlueprintVersions />
            <div className="flex justify-between">
              <Button text={t('Edit source')} onClick={(): void => setExpandedPanel('Source')} variant="Secondary" />
              {!blueprint.blueprintId ? (
                <div className="flex items-center gap-2">
                  {crossEnvUri && (
                    <div className="flex items-center gap-2 text-m-regular">
                      <span>{t('Copied')}</span>
                      <Tooltip title={crossEnvUri} interactive>
                        <span className="cursor-pointer text-primary-main underline">{t('URI')}</span>
                      </Tooltip>
                      <span>{t('to clipboard!')}</span>
                    </div>
                  )}
                  {hasReplicateCrossEnvPermission && (
                    <Button
                      Svg={AdminIcon}
                      text={t('Cross-Env Export')}
                      variant="Outlined"
                      onClick={createBlueprintCrossEnvClicked}
                      disabled={isCreatingBlueprint}
                    />
                  )}
                  <Button
                    text={t('Prepare for copy')}
                    onClick={createBlueprintClicked}
                    isLoading={isCreatingBlueprint}
                  />
                </div>
              ) : (
                <Button text={t('Define target')} onClick={(): void => setExpandedPanel('Target')} />
              )}
            </div>
          </div>
        </Accordion>
      )}
      <Accordion
        summary={_createSummary(3, t('Define target'))}
        expanded={expandedPanel === 'Target'}
        onChange={(isExpanded): void => setExpandedPanel(isExpanded ? 'Target' : null)}
        disabled={!targetPanelEnabled}
      >
        <div className="flex flex-col gap-6">
          <ReplicatorTargets />
          <div className="flex justify-between">
            {blueprint.target.companyId && (
              <>
                <Button text={t('Reset target changes')} variant="Secondary" onClick={onResetTargetClicked} />
                <Button
                  text={blueprint.blueprintSasUri ? t('Start cross-env copy') : t('Start copy')}
                  Svg={blueprint.blueprintSasUri ? AdminIcon : undefined}
                  onClick={onStartReplicationClicked}
                  disabled={!blueprint.blueprintId || targetHasErrors || targetNamingClash}
                  tooltipCmp={
                    targetNamingClash ? (
                      <TooltipContent
                        header={t('Naming conflict')}
                        detail={t('At least two items use the identical name or ID!')}
                      />
                    ) : undefined
                  }
                />
              </>
            )}
          </div>
        </div>
      </Accordion>
    </>
  );
};

const _createSummary = (pageNo: number, text: string): React.ReactNode => (
  <div className="flex gap-1">
    <span className="min-w-[10px]">{pageNo}</span>
    <span>|</span>
    <span>{text}</span>
  </div>
);
