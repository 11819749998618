import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { UserRoles } from 'generated/user-role';
import { useAppSelector } from 'hooks/store/store.hooks';
import { UserCustomFields, getAllCompanyUserCustomFields } from 'services/companies/companies-user-custom-fields';
import {
  CompanyUser,
  UserInvites,
  getCompaniesGetInvites,
  getCompaniesGetUser,
  getCompaniesGetUsers,
  postCompaniesSetRole,
} from 'services/companies/companies.service';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';

export type CompanySettingsStaffContextState = {
  companyId: string;
  users: CompanyUser[];
  userInvites: UserInvites;
  customFields: UserCustomFields;
  fetchUsers: () => Promise<void>;
  fetchUser: (userId: string) => Promise<void>;
  fetchInvites: () => Promise<void>;
  fetchCustomFields: () => Promise<void>;
  revokeUser: (userId: string) => Promise<void>;
  currentUserRole: UserRoles;
  maxUserCustomFields: number;
  isCustomFieldsEnabled: boolean;
};

const COMPANY_SETTINGS_STAFF_CONTEXT_INITIAL_STATE: CompanySettingsStaffContextState = {
  fetchUsers: async () => {},
  fetchUser: async () => {},
  fetchInvites: async () => {},
  fetchCustomFields: async () => {},
  revokeUser: async () => {},
  userInvites: [],
  users: [],
  customFields: {},
  currentUserRole: UserRoles.Guest,
  companyId: '',
  maxUserCustomFields: 0,
  isCustomFieldsEnabled: false,
};

const CompanySettingsStaffContext = createContext(COMPANY_SETTINGS_STAFF_CONTEXT_INITIAL_STATE);
export const useCompanySettingsStaff = (): CompanySettingsStaffContextState => useContext(CompanySettingsStaffContext);

type CompanySettingsStaffProviderProps = {
  children: React.ReactNode;
};

export const CompanySettingsStaffProvider: React.FC<CompanySettingsStaffProviderProps> = ({ children }) => {
  const company = useAppSelector(selectSelectedCompany);
  const companyId = company?.id ?? '';
  const currentUserRole = company?.role ?? UserRoles.Guest;

  const maxUserCustomFields = company?.companyFeatures.maxUserCustomFields ?? 0;
  const isCustomFieldsEnabled = maxUserCustomFields > 0;

  const [users, setUsers] = useState<CompanyUser[]>([]);
  const [userInvites, setUserInvites] = useState<UserInvites>([]);
  const [customFields, setCustomFields] = useState<UserCustomFields>({});

  const fetchUsers = useCallback(async () => {
    const res = await getCompaniesGetUsers(companyId);
    setUsers(res);
  }, [companyId]);

  const fetchUser = useCallback(
    async (userId: string) => {
      const fetchedUser = await getCompaniesGetUser(companyId, userId);
      setUsers(users => {
        const newUsers = [...users];
        const idx = users.findIndex(u => u.id === fetchedUser.id);
        if (idx > -1) {
          // keep the user at its index so there's no potential reordering in the DataGrid
          newUsers[idx] = fetchedUser;
        } else {
          newUsers.push(fetchedUser);
        }
        return newUsers;
      });
    },
    [companyId]
  );

  const fetchInvites = useCallback(async () => {
    const res = await getCompaniesGetInvites(companyId);
    const activeInvites = res.filter(x => x.maxUsageCount > x.usageCount && !x.revokedAt && x.validTill > new Date());
    setUserInvites(activeInvites);
  }, [companyId]);

  const fetchCustomFields = useCallback(async () => {
    if (isCustomFieldsEnabled) {
      const customFields = await getAllCompanyUserCustomFields(companyId);
      setCustomFields(customFields);
    } else {
      setCustomFields({});
    }
  }, [companyId, isCustomFieldsEnabled]);

  const revokeUser = useCallback(
    async (userId: string) => {
      await postCompaniesSetRole(companyId, userId, UserRoles.None);
      setUsers(oldUsers => oldUsers.filter(u => u.id !== userId));
    },
    [companyId]
  );

  /* Fetch definitions */
  useEffect(() => {
    fetchCustomFields();
  }, [fetchCustomFields]);

  /* Fetch data/content */
  useEffect(() => {
    fetchUsers();
    fetchInvites();
  }, [fetchUsers, fetchInvites]);

  const ctxState: CompanySettingsStaffContextState = {
    users,
    userInvites,
    customFields,
    fetchUsers,
    fetchUser,
    fetchInvites,
    fetchCustomFields,
    currentUserRole,
    companyId,
    maxUserCustomFields,
    isCustomFieldsEnabled,
    revokeUser,
  };

  return <CompanySettingsStaffContext.Provider value={ctxState}>{children}</CompanySettingsStaffContext.Provider>;
};
