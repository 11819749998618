/**
 * Some browser settings block access to the local storage in different ways.
 * E.g. Safari blocks read access when in private mode and chromes content setting "block sites from setting any data"
 * blocks access to storage objects completely.
 *
 * see:
 * http://stackoverflow.com/questions/14555347/html5-localstorage-error-with-safari-quota-exceeded-err-dom-exception-22-an)
 * http://stackoverflow.com/questions/24456891/iframe-in-chrome-error-uncaught-securityerror-failed-to-read-the-sessionstora
 *
 * This helper class simply doesn't store anything or returns undefined if access is denied so that we don't run into
 * unhandled exceptions.
 *
 * Also provides a feature to automatically prefix given keys to separate values from same keys on different sites
 * (Backend, Cfgr & Checkout) and environments (dev, clonebee, ...).
 */
import { defaultCookieConsentName } from 'react-cookie-consent';

type StorageTypes = 'local' | 'session';

type StorageData = {
  storage?: Storage;
  writeable: boolean;
  readable: boolean;
};

// Holds the references to the storage objects and defines if the storage is readable/writeable
// This data is just evaluated once
const _STORAGE_DATA_MAP: Record<StorageTypes, StorageData | undefined> = {
  local: undefined,
  session: undefined,
};

/**
 * Wrapper function for `setItem` function of the corresponding storage object.\
 * First checks if the storage is available and writable to avoid exceptions when executing the `setItem` call.
 */
export function setItem(key: string, value: string, useSessionStorage = false): void {
  const storageData = _getStorageData(useSessionStorage);

  if (storageData.writeable) {
    storageData.storage!.setItem(key, value);
  }
}

/**
 * Wrapper function for `getItem` function of the corresponding storage object.\
 * First checks if the storage is available and if data can be read.
 */
export function getItem(key: string, useSessionStorage = false): string | null {
  const storageData = _getStorageData(useSessionStorage);

  return storageData.readable ? storageData.storage!.getItem(key) : null;
}

/**
 * Wrapper function for `removeItem` function of the corresponding storage object
 */
export function removeItem(key: string, useSessionStorage = false): void {
  const storageData = _getStorageData(useSessionStorage);

  storageData.storage?.removeItem(key);
}

/**
 * Wrapper function for `clear` function of the corresponding storage object
 */
export function clear(useSessionStorage = false): void {
  const storageData = _getStorageData(useSessionStorage);

  storageData.storage?.clear();
}

/**
 * Clears all cookies (which are accessible with JS, e.g. none with `HttpOnly` flag etc.).
 *
 * @param keepCookieBanner If `true`, the cookie which holds the info about the users "cookie banner" decision will not
 *                         be deleted.
 *                         When this is deleted as well, the cookie banner will be shown again after every logout/login.
 */
export function clearCookies(keepCookieBanner: boolean): void {
  const cookies = document.cookie.split(';');
  cookies.forEach(cookie => {
    const name = cookie.trim().split('=')[0];
    if (keepCookieBanner && name === defaultCookieConsentName) return;
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  });
}

/**
 * Reset the data in the storage map to trigger a new evaulation of the readable/writeable flags
 */
export function resetStorageDataMap(): void {
  _STORAGE_DATA_MAP.local = undefined;
  _STORAGE_DATA_MAP.session = undefined;
}

/**
 * Help function for accessing the dedicated storage and evaluating whether it is available and readable/writeable
 */
function _getStorageData(useSessionStorage = false): StorageData {
  const testKey = 'CbnStorageTestKey';
  const testValue = 'CbnStorageTestValue';

  const storageType: StorageTypes = useSessionStorage ? 'session' : 'local';
  const existingStorageData = _STORAGE_DATA_MAP[storageType];

  if (existingStorageData !== undefined) {
    // data have already been evaluated, return existing data
    return existingStorageData;
  }

  const newStorageData: StorageData = {
    storage: useSessionStorage ? window.sessionStorage : window.localStorage,
    writeable: false,
    readable: false,
  };

  if (newStorageData.storage) {
    try {
      // try to call `setItem` with a test key/value pair
      // if it throws the `writable` flag will not be set to `true`
      newStorageData.storage.setItem(testKey, testValue);
      newStorageData.writeable = true;
    } catch (err) {}

    try {
      const itemValue = newStorageData.storage.getItem(testKey);

      // Don't care about the return value if not writable. If `getItem` does not throw,
      // it means that the storage is basically readable (I guess)...
      newStorageData.readable = newStorageData.writeable ? itemValue === testValue : true;
    } catch (err) {}

    // remove test key again
    if (newStorageData.writeable) {
      newStorageData.storage.removeItem(testKey);
    }
  }

  _STORAGE_DATA_MAP[storageType] = newStorageData;
  return newStorageData;
}
