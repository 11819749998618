import { FileExtensionGetterStrategy, getFileNameWithoutExtension } from 'helper/file/file.helper';

// CAUTION: Try to keep this file in sync with backend counterpart (Combeenation.Cbn.Common.Identifiers and
// Combeenation.Cbn.Common.HiveIdentifiers).

// TODO: Should be i18n as well but it's not possible to use `getTypedTranslation` "top level" ATM as that uses the
//       store which is not available at this point.
//       Not worth the effort ATM to adjust this. Just don't forget to translate once we're actually implementing I18n
//       or when `getTypedTranslation` is updated.
export const INVALID_HIVE_IDENTIFIER_INFO =
  'Invalid name!\nAllowed characters: 0-9, a-z, A-Z, umlauts, _\nFurther restrictions: No empty spaces, ß & numbers are not allowed at the beginning, maximum length 30 characters';

export const INVALID_SCHEMA_INFO =
  'Invalid name!\nAllowed characters: 0-9, a-z, A-Z\n' +
  'Further restrictions: First character must be a letter, minimum length 2 characters, maximum length 30 characters';

const _RESERVED_HIVE_IDENTIFIERS = [
  'true',
  'false',
  'empty',
  'if',
  'then',
  'else',
  'elif',
  'end',
  'mod',
  'and',
  'or',
  'not',
  'is',
  'as',
];
export const SCHEMA_NAME_MIN_LENGTH = 2;
const _HIVE_IDENTIFIER_MIN_LENGTH = 1;
export const COMPONENT_MAX_LENGTH = 30;
// Length checks are omitted from Regex on purpose as some use cases don't need it.
// `isValidHiveIdentifier` provides an option to perform the length check explicitly after the Regex passes.
const _VALID_SCHEMA_NAME_REGEX = new RegExp(/^[a-z][a-z0-9]{0,}$/i);
const _UNALLOWED_SCHEMA_CHARS_REGEX = new RegExp(/[^a-z0-9]+/gi);
const _VALID_HIVE_IDENTIFIER_REGEX = new RegExp(/^[a-z_äöü][a-z0-9_äöüß]{0,}$/i);
const _UNALLOWED_HIVE_CHARS_REGEX = new RegExp(/[^a-z0-9_äöüß]+/gi);

export function isValidSchemaName(name: string, ignoreLength = false): boolean {
  const validRegex = _VALID_SCHEMA_NAME_REGEX.test(name);
  const validLength = ignoreLength || (name.length >= SCHEMA_NAME_MIN_LENGTH && name.length <= COMPONENT_MAX_LENGTH);

  return validRegex && validLength;
}

export function generateValidSchemaName(name: string): string {
  return name.replace(_UNALLOWED_SCHEMA_CHARS_REGEX, '').substring(0, COMPONENT_MAX_LENGTH);
}

export function isValidHiveIdentifier(name: string, ignoreLength = false): boolean {
  const validRegex = _VALID_HIVE_IDENTIFIER_REGEX.test(name);
  // also check some group of some reserved hive identifiers
  const validHiveIdentifiers = !_RESERVED_HIVE_IDENTIFIERS.includes(name);
  const validLength =
    ignoreLength || (name.length >= _HIVE_IDENTIFIER_MIN_LENGTH && name.length <= COMPONENT_MAX_LENGTH);

  return validRegex && validHiveIdentifiers && validLength;
}

/**
 * Creates a valid hive name from the given input name (remove special characters, cut length, ...)\
 * Optionally removes file extension if required.
 *
 * @param fileExtensionRemovalStrategy - if set, file extension will be removed according to entry in
 *                                       {@link FileExtensionGetterStrategy}
 *                                     - if not set, file extension won't be removed
 */
export function generateValidHiveName(
  name: string,
  fileExtensionRemovalStrategy?: FileExtensionGetterStrategy
): string {
  name = fileExtensionRemovalStrategy ? getFileNameWithoutExtension(name, fileExtensionRemovalStrategy) : name;

  const validHiveName = _generateValidHiveName(name);
  return validHiveName;
}

/**
 * Generates a valid component name from the given text by simply removing unallowed characters,
 * constraining the length, ...\
 * Adds '_' as prefix, if the text (with all unallowed characters removed) starts with a number, or is an
 * invalid name
 */
function _generateValidHiveName(name: string): string {
  // Remove unallowed characters
  name = name.replace(_UNALLOWED_HIVE_CHARS_REGEX, '');

  // Prefix underscore if it's still no valid hive identifier
  // eg: starting with ß, number or if it matches a reserved keyword
  // Don't consider length, since this will be treated separately
  if (!isValidHiveIdentifier(name, true)) {
    name = '_' + name;
  }

  name = name.substring(0, COMPONENT_MAX_LENGTH);

  return name;
}
