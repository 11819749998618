import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ELEVATION_SHADOW } from 'styles/mui-theme';
import { CrossIcon } from 'assets/icons';
import { Icon } from 'controls/icon/icon';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    boxShadow: theme.shadows[ELEVATION_SHADOW['3XL']],
    borderRadius: '8px 0 0 8px',
  },
}));

type CbnDrawerProps = {
  open?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
};

/**
 * Drawer (or "side peek") which opens from the right side of the screen.
 *
 * Use with `CbnDrawerHeader` to get default spacings etc.
 *
 * @example
 * ```tsx
 * // Default drawer
 * <CbnDrawer>
 *   <CbnDrawerHeader
 *     title="Drawer title"
 *     subText="Drawer subtext"
 *     actions={<Button text="Submit" />}
 *   />
 *   <div>Drawer content</div>
 * </CbnDrawer>
 * ```
 */
export const CbnDrawer: React.FC<CbnDrawerProps> = ({ open, onClose, children }) => {
  return (
    <StyledDrawer
      ModalProps={{
        slotProps: {
          backdrop: {
            sx: { background: 'none' },
          },
        },
      }}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Icon Svg={CrossIcon} className="absolute right-2 top-2 h-6 cursor-pointer" onClick={onClose} />
      {children}
    </StyledDrawer>
  );
};
