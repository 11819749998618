import { CbnCollapseIcon } from 'controls/icon/cbn-collapse-icon';
import { Tooltip } from 'controls/tooltip/tooltip';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type AssetTreeCollapsedProps = {
  onExpand: () => void;
};

export const AssetTreeCollapsed: React.FC<AssetTreeCollapsedProps> = ({ onExpand }) => {
  const { t } = useTypedTranslation();

  return (
    <Tooltip title={t('Expand asset tree')} arrow placement="right">
      <div
        data-cmptype="AssetTreeCollapsed"
        className="ml-[1px] flex w-6 cursor-pointer items-center justify-center rounded-r-lg bg-neutral-10 shadow-sm"
        onClick={onExpand}
      >
        <CbnCollapseIcon collapsed={true} />
      </div>
    </Tooltip>
  );
};
