// Generated by EnumsBuilderTypeScript (DO NOT change this file manually, change the builder if required)
export enum UserPermissions {
  None = 0,
  ViewConfigurator = 1,
  ManageDraft = 4,
  ManageCbnPlugin = 8,
  PublishCfgrDraft = 16,
  WithdrawConfigurator = 32,
  ManageUserRole = 64,
  ManageApiToken = 128,
  ManageConfigurator = 256,
  ManageWhitelist = 512,
  GetConfigurations = 1024,
  ManageCompaniesAndUsers = 2048,
  ModifyCompanySettings = 4096,
  ReplicateCrossEnv = 8192,
  UpdateTheme = 16384,
  ShowServerPanel = 65536,
  BroadcastMessage = 131072,
  RasterizeSvg = 262144,
  ViewAssets = 1048576,
  ManageAssets = 2097152,
  ManageWorkflows = 4194304,
  RunWorkflows = 8388608
}