import { Avatar } from '@mui/material';
import { useRef, useState } from 'react';
import { AdminIcon, GridLayout13Icon, LogoutIcon, UserProfileIcon } from 'assets/icons';
import { CbnMenu } from 'controls/cbn-menu/cbn-menu';
import { CbnMenuDivider } from 'controls/cbn-menu/cbn-menu-divider';
import { CbnMenuItem } from 'controls/cbn-menu/cbn-menu-item';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { PATHS } from 'services/routes/paths.service';
import { UserInfo, logout } from 'slices/auth/auth.slice';
import { PermissionBundles } from 'slices/permission/permission.slice';

export const AppHeaderUserMenu: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(state => state.auth.userInfo);
  const inDevEnv = useAppSelector(state => state.debug.inDevEnv);
  const showAdminPage = usePermission(PermissionBundles.ShowAdminPage);
  const preventReopen = useRef(false);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpened = Boolean(menuAnchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    if (preventReopen.current) {
      event.preventDefault();
      preventReopen.current = false;
      return;
    }

    setMenuAnchorEl(menuAnchorEl ? null : event.currentTarget);
  };
  const onLogout = async (): Promise<void> => {
    handleMenuClose();
    await dispatch(logout({}));
  };

  const handleMenuClose = (): void => {
    setMenuAnchorEl(null);
  };
  const handleButtonMouseDown = (): void => {
    if (isMenuOpened) {
      // Prevents the menu from reopening right after closing
      // when clicking the button.
      preventReopen.current = true;
    }
  };

  return (
    <>
      <button
        type="button"
        onMouseDown={handleButtonMouseDown}
        onClick={handleOpenMenu}
        data-cmptype="AppHeaderUserMenu-menubutton"
      >
        <Avatar
          className="mx-1 h-9 w-9 cursor-pointer select-none bg-brand-green2"
          children={_getAvatarLetters(userInfo)}
        />
      </button>
      <CbnMenu
        anchor={menuAnchorEl}
        open={isMenuOpened}
        onClose={handleMenuClose}
        id="account-menu"
        placement="bottom-end"
        data-cmptype="AppHeaderUserMenu-menu"
      >
        <CbnMenuItem
          text={t('User settings')}
          icon={UserProfileIcon}
          to={PATHS.userProfile}
          handleClick={handleMenuClose}
        />
        {showAdminPage && (
          <CbnMenuItem text={t('Admin')} icon={AdminIcon} to={PATHS.admin} handleClick={handleMenuClose} />
        )}
        {inDevEnv && (
          <CbnMenuItem
            text={t('Control overview')}
            icon={GridLayout13Icon}
            to={PATHS.controlOverview}
            handleClick={handleMenuClose}
          />
        )}
        <CbnMenuItem text={t('Logout')} icon={LogoutIcon} handleClick={onLogout} />
        <CbnMenuDivider />
        <div className="m-3 flex flex-row items-center gap-3">
          <Avatar className="h-8 w-8 bg-brand-green2" children={_getAvatarLetters(userInfo)} />
          <div className="flex flex-col">
            {userInfo.firstname && (
              <span className="text-m-medium capitalize">{`${userInfo.firstname} ${userInfo.lastname}`.trim()}</span>
            )}
            <span className=" text-ellipsis text-s-regular">{userInfo.email}</span>
          </div>
        </div>
      </CbnMenu>
    </>
  );
};

function _getAvatarLetters(userInfo: UserInfo): string {
  if (userInfo.firstname) {
    return userInfo.firstname[0].toUpperCase() + (userInfo.lastname ? userInfo.lastname[0].toUpperCase() : '');
  }
  if (!userInfo.email) {
    return '';
  }

  const nameFromMail = userInfo.email.split('@')[0].split('.');
  return nameFromMail.length > 1
    ? nameFromMail[0][0].toUpperCase() + nameFromMail[1][0].toUpperCase()
    : nameFromMail[0][0].toUpperCase();
}
