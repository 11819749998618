import { Icon, SvgComponent } from 'controls/icon/icon';
import { cn } from 'helper/css.helper';

type CbnCardHeaderProps = {
  Svg?: SvgComponent;
  title?: React.ReactNode;
  subText?: React.ReactNode;
  variant?: 'default' | 'large-title';

  /**
   * This can be any React node, whereas the idea is that these are "action" buttons
   * It will be located on the right side of the header
   *
   * @example
   * ```tsx
   * <CbnCardHeader
   *   title="Card title"
   *   subText="Card subtext"
   *   actions={<Button text="Submit" />}
   * />
   * ```
   */
  actions?: React.ReactNode;
  actionsPlacement?: 'right' | 'left' | 'bottom';
};

/**
 * Usually used in conjunction with `CbnCard` to get default card spacings etc.
 * Also provides a line at the bottom to separate card header from content.
 *
 * See {@link CbnCard} for example usage.
 */
export const CbnCardHeader: React.FC<CbnCardHeaderProps> = ({
  Svg,
  title,
  subText,
  variant,
  actions,
  actionsPlacement = 'right',
}) => {
  const isLargeTitleHeader = variant === 'large-title';
  return (
    <div
      data-cmptype="CbnCardHeader"
      className={cn(
        'flex flex-row gap-5 border-b border-neutral-40 px-5 py-3',
        isLargeTitleHeader && 'py-5',
        actionsPlacement === 'bottom' && 'flex-col gap-2',
        actionsPlacement === 'left' && 'flex-row-reverse'
      )}
    >
      <div className="flex grow flex-col justify-center gap-1">
        {Svg && <Icon className="mb-5 h-10 w-10 text-brand-green2" Svg={Svg} />}
        {title && (
          <span
            className={cn(
              'text-m-regular font-semibold text-neutral-100',
              isLargeTitleHeader && 'font-normal text-heading-s'
            )}
          >
            {title}
          </span>
        )}
        {subText && <span className="text-m-regular text-neutral-70">{subText}</span>}
      </div>
      {actions && <div className="self-end">{actions}</div>}
    </div>
  );
};
