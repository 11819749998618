import { useEffect, useRef } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useHasPendingGlobalRouteChange } from 'hooks/app/route.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { buildCfgrsPath } from 'services/routes/cfgrs-routes.service';
import { CfgrRouteParams } from 'services/routes/common-routeparams.service';
import {
  Cfgr,
  selectAllCompanyCfgrs,
  selectSelectedCompany,
  selectSelectedConfigurator,
  setCfgrSelection,
} from 'slices/company-data/company-data.slice';

export const useConfiguratorsRouteSync = (): void => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasPendingRouteChange = useHasPendingGlobalRouteChange();
  const routeParams = useParams<CfgrRouteParams>();
  const dispatch = useAppDispatch();
  const navAfterDispatch = useRef(false);

  const companiesFetchedState = useAppSelector(state => state.companyData.companiesFetched);
  const cfgrsFetchedState = useAppSelector(state => state.companyData.cfgrsFetched);

  const companies = useAppSelector(state => state.companyData.companies);
  const companiesSorted = [...companies].sort((a, b) => a.displayName.localeCompare(b.displayName));
  const firstAvailableCompanyId = companiesSorted[0]?.id;
  const selectedCompany = useAppSelector(selectSelectedCompany);

  const cfgrs = useAppSelector(selectAllCompanyCfgrs);
  const cfgrsSorted = [...cfgrs].sort((a, b) => a.displayName.localeCompare(b.displayName));
  const firstAvailableCfgrId = cfgrsSorted[0]?.id;
  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const selectionCfgrId = useAppSelector(state => state.companyData.selection.cfgrId);

  const isInvalidCompany = !hasPendingRouteChange && companiesFetchedState === 'Fetched' && !selectedCompany;
  const isInvalidCfgr = !isInvalidCompany && !hasPendingRouteChange && cfgrsFetchedState === 'Fetched' && !selectedCfgr;

  useEffect(
    function redirectOnInvalidOrMissingCompany() {
      if (!isInvalidCompany) {
        return;
      }

      if (routeParams.companyId !== firstAvailableCompanyId) {
        navigate(buildCfgrsPath('dashboard', firstAvailableCompanyId), { replace: true });
      }
    },
    [firstAvailableCompanyId, isInvalidCompany, navigate, routeParams.companyId]
  );

  useEffect(
    function redirectOnInvalidOrMissingCfgr() {
      if (!isInvalidCfgr) {
        return;
      }

      const updatedRoute = _getRouteWithCorrectlyCasedCfgr(routeParams.cfgrId, location, cfgrs);
      if (updatedRoute) {
        navigate(updatedRoute, { replace: true });
        return;
      }

      if (selectedCompany && routeParams.cfgrId !== firstAvailableCfgrId) {
        if (!firstAvailableCfgrId) {
          dispatch(setCfgrSelection(''));
          navAfterDispatch.current = true;
          return;
        }

        navigate(buildCfgrsPath('dashboard', selectedCompany.id, firstAvailableCfgrId), { replace: true });
        return;
      }
    },
    [dispatch, firstAvailableCfgrId, isInvalidCfgr, navigate, routeParams, selectedCompany, location, cfgrs]
  );

  /**
   * effect to "await a dispatch"
   * Navigating right after the dispatch would execute components too early, which would use the "old" selection value
   */
  useEffect(() => {
    if (navAfterDispatch.current && selectedCompany && selectionCfgrId === '') {
      navigate(buildCfgrsPath('dashboard', selectedCompany.id), { replace: true });
      navAfterDispatch.current = false;
    }
  }, [navigate, selectedCompany, selectionCfgrId]);
};

function _getRouteWithCorrectlyCasedCfgr(
  cfgrName: string | undefined,
  location: Location,
  cfgrs: Cfgr[]
): Location | undefined {
  if (!cfgrName) {
    return undefined;
  }

  const correctedCfgrId = cfgrs.find(c => cfgrName && c.id.toLowerCase() === cfgrName.toLowerCase())?.id;
  if (correctedCfgrId && cfgrName) {
    return {
      ...location,
      pathname: location.pathname.replace(cfgrName, correctedCfgrId),
    };
  }

  return undefined;
}
