import { RadioGroupProps as MuiRadioGroupProps, Radio, RadioGroup, RadioProps } from '@mui/material';
import { styled } from '@mui/material';
import { FormControlLabel } from 'controls/form/form-control-label';

export type RadioButtonValue = string | number;

export type RadioButtonEntry = {
  value: RadioButtonValue;
  text?: string;
  disabled?: boolean;
};

type RadioButtonGroupProps = Pick<MuiRadioGroupProps, 'row'> & {
  entries: RadioButtonEntry[];
  name?: string;
  value: RadioButtonValue;
  onValueChange?: (value: RadioButtonValue | undefined) => void;
};

const BaseRadio: React.FC<{ checked?: boolean }> = ({ checked }) => {
  // all values set in `className` should be static
  return (
    <div className="CbnBaseRadio m-outline-2 flex h-5 w-5 items-center justify-center rounded-5-full-circle border">
      {checked && <div className={'CbnBaseRadio-checked h-2.5 w-2.5 rounded-2.5-full-circle'}></div>}
    </div>
  );
};

const StyledRadio = styled((props: RadioProps) => (
  <Radio {...props} disableRipple icon={<BaseRadio />} checkedIcon={<BaseRadio checked />} />
))(({ theme }) => ({
  'padding': '3px',
  'fontSize': theme.typography.mRegular.fontSize,

  '.CbnBaseRadio': {
    borderColor: theme.palette.neutral[50],
  },

  '& input': {
    ':hover ~ .CbnBaseRadio': {
      borderColor: theme.palette.primary.main,
    },

    ':focus ~ .CbnBaseRadio': {
      borderColor: theme.palette.primary.main,
      outline: theme.outlines.large,
    },

    ':disabled ~ .CbnBaseRadio': {
      background: theme.palette.neutral[30],
      borderColor: theme.palette.neutral[50],
    },
  },

  '& .CbnBaseRadio-checked': {
    background: theme.palette.primary.main,
  },

  '&.Mui-disabled .CbnBaseRadio-checked': {
    background: theme.palette.neutral[50],
  },
}));

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ entries, name, row, value, onValueChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const valueIsNumber = typeof value === 'number';

    const newValue = (event.target as HTMLInputElement).value;
    onValueChange?.(valueIsNumber ? Number(newValue) : newValue);
  };

  return (
    <div data-cmptype="RadioButtonGroup">
      <RadioGroup value={value} onChange={handleChange} name={name} row={row}>
        {entries.map(entry => (
          <FormControlLabel
            key={entry.value}
            value={entry.value}
            control={<StyledRadio />}
            label={entry.text}
            disabled={entry.disabled}
            className="pr-2 last:pr-0"
          />
        ))}
      </RadioGroup>
    </div>
  );
};
