import { COMPANYID_ROUTE_PARAMS, CompanyIdRouteParams } from 'services/routes/common-routeparams.service';
import { buildPath } from 'services/routes/routes.service';

export const STAFF_SETTINGS_BASE_PATH = '/companystaff';

export type StaffSettingsRouteParams = CompanyIdRouteParams;

export const STAFF_SETTINGS_PATH_VARIANTS = {
  staffSettings: `${STAFF_SETTINGS_BASE_PATH}/:${COMPANYID_ROUTE_PARAMS.companyId}`,
};

export function buildStaffSettingsPath(companyId: string): string {
  return buildPath(STAFF_SETTINGS_BASE_PATH, companyId);
}
