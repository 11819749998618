import { useEffect, useState } from 'react';
import { FilterIcon } from 'assets/icons';
import { Button } from 'controls/button/button';
import { Chip } from 'controls/chip/chip';
import { IdentityTypes } from 'generated/identity-types';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

// Filter type enumeration as bit field
enum BundleVersionFilterTypeUiState {
  User = 1,
  Workflows = 2,
  All = 3,
}

type AssetBundleVersionsTableFilterProps = {
  filter: IdentityTypes | undefined;
  filterTypeText: string;
  onApplyFilter: (value: IdentityTypes | undefined) => void;
  onChangeFilterApplied: (value: boolean) => void;
};

export const AssetBundleVersionsTableFilter: React.FC<AssetBundleVersionsTableFilterProps> = ({
  filter: appliedApiFilter,
  filterTypeText,
  onApplyFilter: onApplyApiFilter,
  onChangeFilterApplied,
}) => {
  const { t } = useTypedTranslation();

  const appliedUiFilter = _convertApiFilterToUiFilter(appliedApiFilter);
  const [pendingUiFilter, setPendingUiFilter] = useState<BundleVersionFilterTypeUiState>(appliedUiFilter);

  const filterChangePending = pendingUiFilter !== appliedUiFilter;

  useEffect(() => {
    const filterApplied = appliedUiFilter === pendingUiFilter;
    onChangeFilterApplied(filterApplied);
  }, [appliedUiFilter, pendingUiFilter, onChangeFilterApplied]);

  const onFilterOptionClicked = (filterType: BundleVersionFilterTypeUiState): void => {
    // filter type can only be "User" and "Workflow", which are represented as bits in the enumeration
    // "^" operator toggles this bit within the combined filter value
    const newFilterValue = pendingUiFilter ^ filterType;
    // disabling all filters (final value of 0) is not allowed
    if (newFilterValue) {
      setPendingUiFilter(curValue => curValue ^ filterType);
    }
  };

  const onApplyFilterClicked = async (): Promise<void> => {
    const apiFilter = _convertUiFilterToApiFilter(pendingUiFilter);
    onApplyApiFilter(apiFilter);
  };

  return (
    <div data-cmptype="AssetBundleVersionsTableFilter" className="flex flex-row items-center gap-6 p-5">
      <span className="text-m-medium text-neutral-90">{t('Filter versions')}</span>
      <div className="flex flex-row items-center gap-3">
        <span className="text-m-regular text-neutral-90">{filterTypeText}</span>
        <div className="flex flex-row items-center gap-2">
          <Chip
            variant="Primary"
            value={BundleVersionFilterTypeUiState.User}
            text={BundleVersionFilterTypeUiState[BundleVersionFilterTypeUiState.User]}
            selected={!!(pendingUiFilter & BundleVersionFilterTypeUiState.User)}
            onClick={(value): void => onFilterOptionClicked(value as BundleVersionFilterTypeUiState)}
          />
          <Chip
            variant="Primary"
            value={BundleVersionFilterTypeUiState.Workflows}
            text={BundleVersionFilterTypeUiState[BundleVersionFilterTypeUiState.Workflows]}
            selected={!!(pendingUiFilter & BundleVersionFilterTypeUiState.Workflows)}
            onClick={(value): void => onFilterOptionClicked(value as BundleVersionFilterTypeUiState)}
          />
        </div>
      </div>
      <Button
        variant="Outlined"
        text={t('Apply filter')}
        Svg={FilterIcon}
        onClick={onApplyFilterClicked}
        disabled={!filterChangePending}
        title={!filterChangePending ? t('Applied filter is up-to-date') : ''}
      />
    </div>
  );
};

function _convertUiFilterToApiFilter(uiFilter: BundleVersionFilterTypeUiState): IdentityTypes | undefined {
  switch (uiFilter) {
    case BundleVersionFilterTypeUiState.User:
      return IdentityTypes.User;
    case BundleVersionFilterTypeUiState.Workflows:
      return IdentityTypes.Workflow;
  }

  // "undefined" is valid, in this case no filter will be used for the server call
  return undefined;
}

function _convertApiFilterToUiFilter(apiFilter: IdentityTypes | undefined): BundleVersionFilterTypeUiState {
  switch (apiFilter) {
    case IdentityTypes.User:
      return BundleVersionFilterTypeUiState.User;
    case IdentityTypes.Workflow:
      return BundleVersionFilterTypeUiState.Workflows;
  }

  return BundleVersionFilterTypeUiState.All;
}
