import { createContext, useContext } from 'react';

export type CbnTreeViewViewModes = 'compact' | 'extended';
/**
 * Context is used to communicate with the child `CbnTreeItem` components.
 * Communication with the `CbnTreeViewCtrlMenu` is done via api ref, as this component can be located "outside" of the
 * tree view.
 */
export type CbnTreeViewContextState = {
  searchInput: string;
  viewMode: CbnTreeViewViewModes;
  expandOnDoubleClick?: boolean;
  multiSelect?: boolean;
};

const CBN_TREE_VIEW_CONTEXT_INITIAL_STATE: CbnTreeViewContextState = {
  searchInput: '',
  viewMode: 'extended',
};

const CbnTreeViewContext = createContext(CBN_TREE_VIEW_CONTEXT_INITIAL_STATE);
export const useCbnTreeView = (): CbnTreeViewContextState => useContext(CbnTreeViewContext);

type CbnTreeViewProviderProps = {
  value: CbnTreeViewContextState;
  children: React.ReactNode;
};

export const CbnTreeViewProvider: React.FC<CbnTreeViewProviderProps> = ({ value, children }) => {
  return <CbnTreeViewContext.Provider value={value}>{children}</CbnTreeViewContext.Provider>;
};
