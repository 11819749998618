import {
  CfgnInsightsFilterInputProps,
  NoSelectionChipValue,
} from 'components/cfgrs/cfgn-insights/cfgn-insights-filter-input';
import { LargeFontChip } from 'controls/chip/chip';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type BooleanFilterValue = [boolean | undefined] | [];

/**
 * The resulting array only holds 1 value or is empty when "not set".
 */
export const BooleanFilterInput: React.FC<CfgnInsightsFilterInputProps<BooleanFilterValue>> = ({ value, onCommit }) => {
  const { t } = useTypedTranslation();

  const onChipClicked = (x: BooleanFilterValue): void => {
    const selectedChipClicked = x.length && x[0] === value[0];
    const updatedValue = !x.length || selectedChipClicked ? ([] as BooleanFilterValue) : x;
    onCommit(updatedValue);
  };

  return (
    <div className="flex flex-wrap gap-3">
      <LargeFontChip
        value={NoSelectionChipValue}
        text={t('Any')}
        variant="Primary"
        selected={!value.length}
        onClick={(): void => onChipClicked([])}
      />
      <span className="border-r border-neutral-50" />
      <LargeFontChip
        value={NoSelectionChipValue}
        text={t('True')}
        variant="Primary"
        selected={value[0] === true}
        onClick={(): void => onChipClicked([true])}
      />
      <LargeFontChip
        value={NoSelectionChipValue}
        text={t('False')}
        variant="Primary"
        selected={value[0] === false}
        onClick={(): void => onChipClicked([false])}
      />
      <LargeFontChip
        value={NoSelectionChipValue}
        text={t('Unknown')}
        variant="Primary"
        selected={value.length === 1 && value[0] === undefined}
        onClick={(): void => onChipClicked([undefined])}
      />
    </div>
  );
};
