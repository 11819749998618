type HighlightedTextProps = {
  text: string;
  highlight: string;
  /**
   * Default: bg-primary-surface\
   * If there's no background set, the <mark> tag will apply a yellow background by default */
  className?: string;
};

export const HighlightedText: React.FC<HighlightedTextProps> = ({
  text,
  highlight,
  className = 'bg-primary-surface',
}) => {
  const idx = text.toLowerCase().indexOf(highlight.toLowerCase());

  if (!highlight.length || idx === -1) {
    return <>{text}</>;
  }

  const preHighlight = text.substring(0, idx);
  const highlightPart = text.substring(idx, idx + highlight.length);
  const postHighlight = text.substring(idx + highlight.length);

  return (
    <span aria-label={text}>
      {preHighlight}
      <mark className={className}>{highlightPart}</mark>
      {postHighlight}
    </span>
  );
};
