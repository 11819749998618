import classNames from 'classnames';
import { SingleUserCheckmark, SingleUserClock, SingleUserLock, UserDeleteCrossIcon } from 'assets/icons';
import { Icon, SvgComponent } from 'controls/icon/icon';
import { UserStates } from 'generated/user-states';
import { tw } from 'helper/css.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

const _USERSTATE_ICON_DEF: Record<UserStates, { icon: SvgComponent; tooltip: string; colorClass?: string }> = {
  [UserStates.Activated]: { icon: SingleUserCheckmark, tooltip: 'Activated' },
  [UserStates.NotActivated]: { icon: SingleUserClock, tooltip: 'Not activated', colorClass: tw`text-neutral-60` },
  [UserStates.MarkedForDeletion]: {
    icon: UserDeleteCrossIcon,
    tooltip: 'Marked for deletion',
    colorClass: tw`text-danger-main`,
  },
  [UserStates.Deactivated]: { icon: SingleUserLock, tooltip: 'Deactivated', colorClass: tw`text-danger-main` },
};

export const UserStateIcon: React.FC<{ userState: UserStates }> = ({ userState }) => {
  const { t } = useTypedTranslation();

  const def = _USERSTATE_ICON_DEF[userState];
  if (!def) {
    return null;
  }

  return (
    <Icon
      Svg={def.icon}
      title={t(def.tooltip)}
      titleProps={{ placement: 'left' }}
      className={classNames('w-5', def.colorClass)}
    />
  );
};
