import { Params, generatePath, matchPath } from 'react-router-dom';
import { getTypedTranslation } from 'hooks/i18n/i18n.hooks';
import {
  ASSETS_BASE_PATH,
  ASSET_EDITOR_PATH_VARIANTS,
  buildAssetEditorPath,
} from 'services/routes/asset-editor-routes.service';
import {
  CFGRS_PATH_VARIANTS,
  DRAFTS_BASE_PATH,
  EMBEDDING_BASE_PATH,
  buildCfgrsPath,
} from 'services/routes/cfgrs-routes.service';
import {
  COMPANY_INVITATION_PATH_VARIANTS,
  buildCompanyInvitationSubPagePath,
} from 'services/routes/company-invitation-routes.service';
import {
  COMPANY_SETTINGS_BASE_PATH,
  COMPANY_SETTINGS_PATH_VARIANTS,
  buildCompanySettingsPath,
} from 'services/routes/company-settings-routes.service';
import { HOME_PATH_VARIANTS, buildCompanyHomePath } from 'services/routes/home-routes.service';
import {
  REPLICATOR_BASE_PATH,
  REPLICATOR_PATH_VARIANTS,
  buildReplicatorPath,
} from 'services/routes/replicator-routes.service';
import {
  STAFF_SETTINGS_BASE_PATH,
  STAFF_SETTINGS_PATH_VARIANTS,
  buildStaffSettingsPath,
} from 'services/routes/staff-settings-routes.service';
import {
  WORKFLOWS_BASE_PATH,
  WORKFLOWS_PATH_VARIANTS,
  buildWorkflowsPath,
} from 'services/routes/workflows-routes.service';

export const PATHS = {
  login: '/login',
  embeddedLogin: '/embeddedlogin',
  forgotPassword: '/forgotpwd',
  registerUser: '/register',
  activateUser: '/activate/:token',
  resetPassword: '/resetpwd/:token',
  root: '/',
  userProfile: '/userprofile',
  admin: '/admin',
  controlOverview: '/controloverview',

  ...HOME_PATH_VARIANTS,
  buildCompanyHomePath,

  // Note: I'm not too happy with naming here but I think we can live with that for now and figure out some better
  //       strategy along the way, when we implement more of those "path builders"
  ...CFGRS_PATH_VARIANTS,
  buildConfiguratorsPath: buildCfgrsPath,

  ...STAFF_SETTINGS_PATH_VARIANTS,
  buildStaffSettingsPath,

  ...COMPANY_SETTINGS_PATH_VARIANTS,
  buildCompanySettingsPath,

  ...ASSET_EDITOR_PATH_VARIANTS,
  buildAssetEditorPath,

  ...WORKFLOWS_PATH_VARIANTS,
  buildWorkflowsPath,

  ...REPLICATOR_PATH_VARIANTS,
  buildReplicatorPath,

  ...COMPANY_INVITATION_PATH_VARIANTS,
  buildCompanyInvitationSubPagePath,
};

/**
 * Fallback document title text which is used on routes for which no specific title is defined in
 * `_ROUTE_TITLE_I18N_KEYS`.
 *
 * !!! IMPORTANT !!!
 * Also adjust title in `index.html` when changing this
 */
const _FALLBACK_TITLE = 'Combeenation Configurators';

/**
 * Map of routes for which we'd like to show explicit document titles.
 * The keys are the base paths of the routes without optional parameter like company id etc.
 * The values are not the actual texts which are shown but the i18n keys to the translated titles.
 */
const _ROUTE_TITLE_I18N_KEYS = {
  [PATHS.admin]: 'Admin',
  [PATHS.home]: 'Home',
  [PATHS.dashboard]: 'Dashboard',
  [DRAFTS_BASE_PATH]: 'Drafts',
  [EMBEDDING_BASE_PATH]: 'Embedding',
  [ASSETS_BASE_PATH]: 'Assets',
  [WORKFLOWS_BASE_PATH]: 'Workflows',
  [STAFF_SETTINGS_BASE_PATH]: 'Users',
  [REPLICATOR_BASE_PATH]: 'Export',
  [COMPANY_SETTINGS_BASE_PATH]: 'Settings',
  [PATHS.userProfile]: 'User settings',
};

/**
 * Generates a title to be used as `document.title` for the given `pathname` if a match in `_ROUTE_TITLE_I18N_KEYS` is
 * found.
 *
 * Returns `_FALLBACK_TITLE` if no match is found.
 *
 * The returned title can have one of the following forms depending on whether `companyName` and/or `cfgrName` are given
 * or not:
 *   - `Route name | CfgrName (CompanyName)`
 *   - `Route name | CompanyName`
 *   - `Route name`
 */
export const getRouteTitle = (pathname: string, companyName?: string, cfgrName?: string): string => {
  const { t } = getTypedTranslation();
  const pathnameLower = pathname.toLowerCase();
  const routeTitleKey = Object.keys(_ROUTE_TITLE_I18N_KEYS).find(x => pathnameLower.startsWith(x.toLocaleLowerCase()));

  if (!routeTitleKey) return _FALLBACK_TITLE;

  const routeI18NKey = _ROUTE_TITLE_I18N_KEYS[routeTitleKey];
  const routeName = t(routeI18NKey);
  const title =
    companyName && cfgrName
      ? `${routeName} | ${cfgrName} (${companyName})`
      : companyName
        ? `${routeName} | ${companyName}`
        : routeName;

  return title;
};

/**
 * Find a matching pattern in our global `PATHS` object
 * An optional dynamic segment gets added if it doesn't exist yet (and is also validated beforehand)
 *
 * E.g.: `findValidPathPattern('/workflows', ':workflowId');` will return `/workflows/:workflowId`
 * @param pathname
 * @param dynamicSegmentEnd optional segment which will be automatically added if a match is found
 * @returns
 */
export function findValidPathPattern(pathname: string, dynamicSegmentEnd?: string): string | undefined {
  const foundMatch = Object.values(PATHS).find(
    pattern => typeof pattern === 'string' && matchPath(pattern, pathname)
  ) as string | undefined;

  if (dynamicSegmentEnd && foundMatch && !foundMatch.includes(dynamicSegmentEnd)) {
    const dynamicMatch = findValidPathPattern(`${foundMatch}/:${dynamicSegmentEnd}`);
    if (dynamicMatch) {
      return dynamicMatch;
    }
  }

  return foundMatch;
}

/**
 * Generates a new route which gets rid of any dynamic segments which come after the given dynamic one
 * E.g.: If the `workflowId` in `/workflows/:workflowId/run/:runId` changes,
 * the `:runId` becomes invalid and has to be removed
 */
export function generateClosestDynamicRoute(
  pattern: string,
  params: Params,
  dynamicSegmentKey: string,
  dynamicValue: string
): string {
  const idx = pattern.indexOf(':' + dynamicSegmentKey);
  if (idx === -1) {
    return generatePath(pattern, params);
  }

  const nextDynParamIdx = pattern.indexOf('/:', idx);
  const newPattern = nextDynParamIdx > -1 ? pattern.substring(0, nextDynParamIdx) : pattern;

  return generatePath(newPattern, { ...params, [dynamicSegmentKey]: dynamicValue });
}
