import type { Monaco } from '@monaco-editor/react';
import debounce from 'lodash.debounce';
import { CancellationToken, Range, editor } from 'monaco-editor';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createProvideInlayHints } from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor-expert-inlay-hints';
import { MonacoEditor } from 'controls/monaco-editor/monaco-editor';
import { DUMMY_MONACO_PATH } from 'controls/monaco-editor/monaco-editor';
import { assetPathToKey } from 'helper/assets/assets.helper';
import { useIsAssetBundleEditable } from 'hooks/assets/assets.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { MaterialAsset, selectSelectedAsset } from 'slices/assets/assets.slice';
import { changeMaterialData, setMaterialAssetUIState } from 'slices/material-asset/material-asset.slice';

const _TAB_SIZE = 4;
const _DEBOUNCE_TIME_JSON_PARSE = 500;

export const MaterialAssetEditorExpert: React.FC = () => {
  const dispatch = useAppDispatch();
  const isAssetBundleEditable = useIsAssetBundleEditable();

  const { curMaterialData, curLinkedTextures } = useAppSelector(state => state.materialAsset);
  const selectedAsset = useAppSelector(selectSelectedAsset) as MaterialAsset;
  const { companyId, bundleId, bundleVersion, folderId, assetId } = selectedAsset.path;
  const assetFilePath = useRef('');

  // state variable is only used for forcing re-renders, the actual string value is stored in "syncedEditorText"
  // text value is very time critical to not inject erroneous values into the monaco editor history
  const [, setCurText] = useState('');
  const syncedEditorPath = useRef(DUMMY_MONACO_PATH);
  const syncedEditorText = useRef('');
  const editorIsFocused = useRef(false);

  // required for detecting change for different material assets with same text content
  useEffect(() => {
    assetFilePath.current = assetPathToKey({ companyId, bundleId, bundleVersion, folderId, assetId }) + '.json';
    setCurText('');
    syncedEditorText.current = '';
    syncedEditorPath.current = DUMMY_MONACO_PATH;
  }, [companyId, bundleId, bundleVersion, folderId, assetId]);

  // overwrite the text value if the input object changes
  // don't do that if the user is currently typing (editor focused)
  useEffect(() => {
    if (!editorIsFocused.current) {
      const strVal = JSON.stringify(curMaterialData, null, _TAB_SIZE);
      setCurText(strVal);

      // text is fetched and now fits to asset path
      syncedEditorText.current = strVal;
      syncedEditorPath.current = assetFilePath.current;

      dispatch(setMaterialAssetUIState({ editorState: 'Valid' }));
    }
  }, [curMaterialData, dispatch]);

  // validate the string every time the text value changes
  const validateJson = useCallback(
    (jsonText: string): void => {
      try {
        // this throws if the input text is not valid
        const jsonObj = JSON.parse(jsonText);
        dispatch(setMaterialAssetUIState({ editorState: 'Valid' }));

        const textValHasChanged = JSON.stringify(jsonObj) !== JSON.stringify(curMaterialData);

        if (textValHasChanged) {
          dispatch(changeMaterialData(jsonObj));
        }
      } catch (err) {
        dispatch(setMaterialAssetUIState({ editorState: 'Invalid' }));
      }
    },
    [curMaterialData, dispatch]
  );

  // `useCallback` can't be used here as it leads to unknown dependencies
  const debouncedValidateJson = useMemo(
    () => debounce((jsonString: string) => validateJson(jsonString), _DEBOUNCE_TIME_JSON_PARSE),
    [validateJson]
  );

  const onTextChanged = (value = ''): void => {
    dispatch(setMaterialAssetUIState({ editorState: 'Editing' }));
    setCurText(value);
    syncedEditorText.current = value;

    debouncedValidateJson(value);
  };

  const inlayHintDefinition = useMemo(
    () => ({
      language: 'json',
      provider: {
        provideInlayHints: (model: editor.ITextModel, range: Range, token: CancellationToken) =>
          createProvideInlayHints(selectedAsset, curLinkedTextures)(model),
      },
    }),
    [selectedAsset, curLinkedTextures]
  );

  const onEditorMount = (editor: editor.IStandaloneCodeEditor, monaco: Monaco): void => {
    editor.onDidFocusEditorText(() => (editorIsFocused.current = true));
    editor.onDidBlurEditorText(() => (editorIsFocused.current = false));
  };

  return (
    <div data-cmptype="MaterialAssetEditorExpert" className="m-4 flex grow">
      <MonacoEditor
        value={syncedEditorText.current}
        onChange={onTextChanged}
        onMount={onEditorMount}
        inlayHintProviderDefinition={inlayHintDefinition}
        path={syncedEditorPath.current}
        options={{ minimap: { enabled: false }, readOnly: !isAssetBundleEditable }}
      />
    </div>
  );
};
