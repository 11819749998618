import { EndpointDescription } from 'services/http/http.service';
import { WorkflowNotificationConfig, WorkflowParameters } from 'services/workflows/workflows.service';

export const WORKFLOWS_ENDPOINTS = {
  workflowsGetAllForCompany: (companyId: string): EndpointDescription => ({
    url: '/workflows/getAllForCompany',
    params: {
      companyName: companyId,
    },
  }),

  workflowsGet: (companyId: string, workflowName: string): EndpointDescription => ({
    url: '/workflows/get',
    params: {
      companyName: companyId,
      workflowName: workflowName,
    },
  }),

  workflowsCreate: (companyId: string, workflowName: string, displayName: string): EndpointDescription => ({
    url: '/workflows/create',
    params: {
      companyName: companyId,
      workflowName: workflowName,
      displayName: displayName,
    },
  }),

  workflowsDelete: '/workflows/delete',

  workflowsStart: (companyId: string, workflowName: string): EndpointDescription => ({
    url: '/workflows/start',
    params: {
      companyName: companyId,
      workflowName: workflowName,
    },
  }),

  workflowsUpdateContent: (companyId: string, workflowName: string, content: string): EndpointDescription => ({
    url: '/workflows/updateContent',
    params: {
      companyName: companyId,
      workflowName: workflowName,
      content: content,
    },
  }),

  workflowsUpdateConfig: (
    companyId: string,
    workflowName: string,
    displayName: string,
    parameters: WorkflowParameters,
    notificationConfig: WorkflowNotificationConfig,
    cronExpression?: string
  ): EndpointDescription => ({
    url: '/workflows/updateConfig',
    params: {
      companyName: companyId,
      workflowName: workflowName,
      displayName: displayName,
      parameters: parameters,
      notificationConfig: notificationConfig,
      cronExpression: cronExpression,
    },
  }),
};
