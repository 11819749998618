import { EndpointDescription } from 'services/http/http.service';

export const CONFIGURATORHISTORY_ENDPOINTS = {
  configuratorhistoryPublish: (companyId: string, configuratorName: string, version: string): EndpointDescription => ({
    url: '/configuratorhistory/publish',
    params: {
      companyName: companyId,
      configuratorName: configuratorName,
      id: version,
    },
  }),

  configuratorhistoryWithdraw: (companyId: string, configuratorName: string): EndpointDescription => ({
    url: '/configuratorhistory/withdraw',
    params: {
      companyName: companyId,
      configuratorName: configuratorName,
    },
  }),

  configuratorhistoryCreateDraft: (
    sourceCompanyId: string,
    sourceConfiguratorName: string,
    sourceDraftId: string,
    draftDisplayName: string,
    destinationCfgrName: string
  ): EndpointDescription => ({
    url: '/configuratorhistory/createDraft',
    params: {
      companyName: sourceCompanyId,
      configuratorName: sourceConfiguratorName,
      id: sourceDraftId,
      draftDisplayName: draftDisplayName,
      destinationCfgrName: destinationCfgrName,
    },
  }),

  configuratorhistoryDeleteDraft: (
    companyId: string,
    configuratorName: string,
    draftId: string
  ): EndpointDescription => ({
    url: '/configuratorhistory/deleteDraft',
    params: {
      companyName: companyId,
      configuratorName: configuratorName,
      id: draftId,
    },
  }),

  configuratorhistoryCreateStage: (
    companyId: string,
    configuratorName: string,
    stageName: string,
    version: string
  ): EndpointDescription => ({
    url: '/configuratorhistory/createStage',
    params: {
      companyName: companyId,
      configuratorName: configuratorName,
      stage: stageName,
      version,
    },
  }),

  configuratorhistoryDeleteStage: (
    companyId: string,
    configuratorName: string,
    stageName: string
  ): EndpointDescription => ({
    url: '/configuratorhistory/deleteStage',
    params: {
      companyName: companyId,
      configuratorName: configuratorName,
      stage: stageName,
    },
  }),

  configuratorhistoryPublishStage: (
    companyId: string,
    configuratorName: string,
    stageName: string
  ): EndpointDescription => ({
    url: '/configuratorhistory/publishStage',
    params: {
      companyName: companyId,
      configuratorName: configuratorName,
      stage: stageName,
    },
  }),

  configuratorhistoryGetFullHistory: (companyId: string, configuratorName: string): EndpointDescription => ({
    url: '/configuratorhistory/getFullHistory',
    params: {
      companyName: companyId,
      configuratorName: configuratorName,
    },
  }),

  configuratorhistoryGetRestorableDrafts: (companyId: string, configuratorName: string): EndpointDescription => ({
    url: '/configuratorhistory/getRestorableDrafts',
    params: {
      companyName: companyId,
      cfgrName: configuratorName,
    },
  }),

  configuratorhistoryPostRestoreDraft: (
    companyId: string,
    configuratorName: string,
    draftId: string
  ): EndpointDescription => ({
    url: '/configuratorhistory/restoreDraft',
    params: {
      companyName: companyId,
      configuratorName,
      id: draftId,
    },
  }),
};
