/**
 * Check if the given element accepts a ref
 *
 * Taken from MUI codebase (mui/material-ui/packages/mui-utils/src/elementTypeAcceptingRef.ts)\
 * The checks are based on "blacklisting instead of whitelisting" because theres no
 * official way to detect React.forwardRef
 */
export function elementTypeAcceptingRef(element: React.ReactElement): boolean {
  const propValue = element.type;
  return !(typeof propValue === 'function' && !_isClassComponent(propValue));
}

/**
 * Taken from MUI codebase (see {@link elementTypeAcceptingRef})
 */
function _isClassComponent(elementType: Function): boolean {
  // elementType.prototype?.isReactComponent
  const { prototype = {} } = elementType;

  return Boolean(prototype.isReactComponent);
}
