import { Switch as BaseSwitch, switchClasses as baseSwitchClasses } from '@mui/base/Switch';
import { styled } from '@mui/material';

export type SwitchProps = {
  id?: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  onValueChanged?: (value: boolean) => void;
};

const Root = styled('label')(({ theme }) => ({
  fontSize: 0,
  position: 'relative',
  display: 'inline-block',
  flexShrink: 0,
  width: 40,
  height: 22,
  margin: theme.spacing(1, 1),
  cursor: 'pointer',

  [`&.${baseSwitchClasses.disabled}`]: {
    cursor: 'default',
    pointerEvents: 'none',
    [`& .${baseSwitchClasses.track}`]: {
      background: theme.palette.neutral[30],
    },
    [`&.${baseSwitchClasses.checked} .${baseSwitchClasses.track}`]: {
      background: theme.palette.neutral[50],
    },
  },

  [`& .${baseSwitchClasses.track}`]: {
    background: theme.palette.primary.surface,
    borderRadius: 16,
    display: 'block',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },

  [`& .${baseSwitchClasses.thumb}`]: {
    display: 'block',
    width: 16,
    height: 16,
    top: 3,
    left: 3,
    borderRadius: 16,
    backgroundColor: theme.palette.neutral[10],
    position: 'relative',

    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  [`&.${baseSwitchClasses.focusVisible} .${baseSwitchClasses.thumb}, &:hover .${baseSwitchClasses.thumb}, &:focus .${baseSwitchClasses.thumb}`]:
    {
      backgroundColor: theme.palette.neutral[50],
      boxShadow: `0 0 1px 7px ${theme.palette.primary.focus}`,
    },

  [`&.${baseSwitchClasses.checked}`]: {
    [`.${baseSwitchClasses.thumb}`]: {
      left: 21,
      top: 3,
      backgroundColor: theme.palette.neutral[10],
    },

    [`.${baseSwitchClasses.track}`]: {
      background: theme.palette.primary.main,
    },
  },

  [`& .${baseSwitchClasses.input}`]: {
    cursor: 'inherit',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0,
    zIndex: 1,
    margin: 0,
  },
}));

export const Switch: React.FC<SwitchProps> = ({ id, disabled, defaultChecked, checked, onValueChanged }) => {
  return (
    <BaseSwitch
      checked={checked}
      onChange={(event): void => onValueChanged?.(event.target.checked)}
      slots={{
        root: Root,
      }}
      slotProps={{ input: { id: id } }}
      defaultChecked={defaultChecked}
      disabled={disabled}
    />
  );
};
