import React, { useState } from 'react';
import { DraftActions } from 'components/cfgrs/drafts/draft-actions';
import { DraftsTableNameCell } from 'components/cfgrs/drafts/drafts-table-name-cell';
import { IdentityUser } from 'components/identity/identity-user';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'controls/table';
import { TableSkeletonRows } from 'controls/table/table-skeleton-rows';
import { useElementSize } from 'hooks/common/sizing.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { Draft } from 'services/company-data/company-data.service';

type DraftTableProps = {
  drafts: Draft[];
  showLoadingSkeletons: boolean;
};

export const DraftTable: React.FC<DraftTableProps> = ({ drafts, showLoadingSkeletons }) => {
  const { t } = useTypedTranslation();
  const [hoveredDraftId, setHoveredDraftId] = useState<string>();
  const { ref: tableRef, size: tableSize } = useElementSize();

  const showAsMenu = tableSize ? tableSize.width < 1000 : false;

  return (
    <div data-cmptype="DraftTable" ref={tableRef}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="w-0">{t('Name')}</TableCell>
            <TableCell className="w-0"></TableCell>
            <TableCell>{t('Created')}</TableCell>
            <TableCell>{t('Last edited')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {showLoadingSkeletons && (
              <TableSkeletonRows numberOfCols={4} colSkeletonProps={{ 1: { className: 'w-[10rem]' } }} />
            )}

            {!showLoadingSkeletons &&
              drafts.map(draft => (
                <TableRow
                  key={draft.id}
                  className="hover:bg-neutral-20"
                  onMouseEnter={(): void => {
                    setHoveredDraftId(draft.id);
                  }}
                  onMouseLeave={(): void => {
                    setHoveredDraftId(undefined);
                  }}
                >
                  <DraftsTableNameCell draft={draft} />

                  <TableCell>
                    <DraftActions draft={draft} visible={hoveredDraftId === draft.id} showAsMenu={showAsMenu} />
                  </TableCell>

                  <TableCell>
                    <div className="just-center flex whitespace-pre">
                      <RelativeDateTime unixTime={draft.createdAt} />
                      <span> {t('by')} </span>
                      <IdentityUser userId={draft.createdBy} />
                    </div>
                  </TableCell>

                  <TableCell>
                    {draft.lastEditedAt ? (
                      <div className="just-center flex whitespace-pre">
                        <RelativeDateTime unixTime={draft.lastEditedAt} />
                        <span> {t('by')} </span>
                        <IdentityUser userId={draft.lastEditedBy} />
                      </div>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
          </>
        </TableBody>
      </Table>
    </div>
  );
};
