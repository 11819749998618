import { useState } from 'react';
import { CopyIcon, HorizontalDotsMenuIcon, TrashIcon } from 'assets/icons';
import { CopyBundleModal } from 'components/asset-editor/header/modals/copy-bundle-modal';
import { Button } from 'controls/button/button';
import { CbnMenu } from 'controls/cbn-menu/cbn-menu';
import { CbnMenuItem } from 'controls/cbn-menu/cbn-menu-item';
import { useNavigateToAssetEditor } from 'hooks/assets/assets.hooks';
import { useConfirmDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { deleteAssetBundle } from 'services/assets/assets.service';
import { getAssetBundles, selectSelectedBundle } from 'slices/assets/assets.slice';

export const AssetBundleMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t, tDeleteBundle } = useTypedTranslation();
  const navigateToAssetEditor = useNavigateToAssetEditor();
  const confirm = useConfirmDialog();

  const selectedBundle = useAppSelector(selectSelectedBundle);
  const companyId = selectedBundle?.companyId ?? '';
  const hasLockedBundleVersions = !!selectedBundle?.hasLockedVersions;
  const hasPublishedBundleVersions = !!selectedBundle?.hasPublishedVersions;

  const [copyBundleDialogActive, setCopyBundleDialogActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onMenuBtnClicked = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const onDeleteBundleItemClicked = async (): Promise<void> => {
    const deleteConfirmed = await confirm(tDeleteBundle(selectedBundle!.displayName), {
      variant: 'Danger',
      confirmBtnText: t('Delete bundle'),
    });

    if (deleteConfirmed) {
      // TODO: create async thunk which calls "getAssetBundles" right away
      await deleteAssetBundle(companyId, selectedBundle!.id);
      await dispatch(getAssetBundles({ companyId }));

      navigateToAssetEditor({ companyId });
    }
  };

  return (
    <>
      <Button Svg={HorizontalDotsMenuIcon} variant="Outlined" onClick={onMenuBtnClicked} />
      <CbnMenu
        data-cmptype="AssetBundleMenu-menu"
        anchor={anchorEl}
        open={open}
        onClose={handleClose}
        placement="bottom-start"
      >
        {selectedBundle && (
          <>
            <CbnMenuItem
              text={t('Copy bundle')}
              icon={CopyIcon}
              disabled={!hasPublishedBundleVersions}
              title={
                !hasPublishedBundleVersions ? t("Can't copy asset bundle without any published bundle version") : ''
              }
              handleClick={(): void => setCopyBundleDialogActive(true)}
            />
            <CbnMenuItem
              text={t('Delete selected bundle')}
              icon={TrashIcon}
              danger
              disabled={hasLockedBundleVersions}
              title={hasLockedBundleVersions ? t("Can't delete asset bundle that contains locked bundle versions") : ''}
              handleClick={onDeleteBundleItemClicked}
            />
          </>
        )}
      </CbnMenu>

      {copyBundleDialogActive && <CopyBundleModal onClose={(): void => setCopyBundleDialogActive(false)} />}
    </>
  );
};
