import { Fragment } from 'react';
import { LinkButton } from 'controls/button/link-button';
import { Tooltip } from 'controls/tooltip/tooltip';
import { CfgnCmpValue, isCfgnCmpValueImage } from 'helper/cfgn-insights.helper';
import { getTypedTranslation, useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { CfgnDetails } from 'services/cfgn/cfgn.service';

type CfgnInsightsDetailsTableProps = {
  values: CfgnDetails['input'] | CfgnDetails['result'];
};

export const CfgnInsightsDetailsTable: React.FC<CfgnInsightsDetailsTableProps> = ({ values }) => {
  const { t } = useTypedTranslation();
  const valuesArr = Object.entries(values) as [string, CfgnCmpValue][];

  return (
    // I chose a `div` + grid layout over our `Table` as the table makes it way harder to apply correct sizing rules.
    // E.g. I found no way to use auto cell width + max-width and ellipsis with our `Table`...
    <div
      data-cmptype="CfgnInsightsDetailsTable"
      className="grid grid-cols-[1fr,fit-content(230px)] overflow-auto text-m-regular"
    >
      <div className="bg-neutral-30 px-5 py-2 text-s-medium">{t('Component')}</div>
      <div className="bg-neutral-30 px-5 py-2 text-s-medium">{t('Value')}</div>

      {valuesArr
        .sort(([cmpNameA], [cmpNameB]) => cmpNameA.localeCompare(cmpNameB))
        .map(([cmpName, value]) => {
          const formattedValue = _getFormattedCfgnValue(value);
          const isImageLink = isCfgnCmpValueImage(value);

          return (
            <Fragment key={cmpName}>
              <Tooltip title={cmpName} interactive>
                <div className="overflow-hidden text-ellipsis whitespace-nowrap px-5 py-4 shadow-divider-line">
                  {cmpName}
                </div>
              </Tooltip>
              <Tooltip title={!isImageLink && formattedValue} interactive>
                <div className="overflow-hidden text-ellipsis whitespace-nowrap px-5 py-4 shadow-divider-line">
                  {formattedValue}
                </div>
              </Tooltip>
            </Fragment>
          );
        })}
    </div>
  );
};

function _getFormattedCfgnValue(value: CfgnCmpValue): React.ReactNode {
  const { t } = getTypedTranslation();

  if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
    return value.toString();
  }

  if (isCfgnCmpValueImage(value)) {
    return <LinkButton variant="Secondary" target="_blank" href={value.Value} text={t('Image')} />;
  }

  try {
    const stringifiedValue = JSON.stringify(value);
    return stringifiedValue;
  } catch (ignore) {}

  return '';
}
