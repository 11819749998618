import { Navigate, Outlet, useLocation, useMatches } from 'react-router-dom';
import { AppSidebar } from 'components/app-sidebar/app-sidebar';
import { UserNotActivated, UserWithoutCompany } from 'components/login';
import { useAppSelector } from 'hooks/store/store.hooks';
import { PATHS } from 'services/routes/paths.service';
import { LocationState } from 'services/routes/routes.service';
import { selectLoggedIn, selectUserIsActivated } from 'slices/auth/auth.slice';

export type ActivationRouteHandle = {
  allowForInactiveUsers: boolean;
};

export const LoggedInRequiredRouteWrapper: React.FC = () => {
  const location = useLocation();
  const loggedIn = useAppSelector(selectLoggedIn);
  const userIsActivated = useAppSelector(selectUserIsActivated);
  const companies = useAppSelector(state => state.companyData.companies);
  const hasCompanies = companies.length > 0;
  const matches = useMatches();
  // the last matching entry is always the "most specific" route
  const lastMatchHandle = matches[matches.length - 1].handle as ActivationRouteHandle | undefined;
  const allowForInactiveUsers = lastMatchHandle?.allowForInactiveUsers ?? false;

  const newLocationState: LocationState = { referrer: location.pathname };

  // return the "outlet" (which are the children underneath the route) if the user is logged in
  // otherwise navigate to the login page and store the current route as referrer, so that it can be restored if the
  // user is logged in
  if (!loggedIn) {
    return <Navigate to={PATHS.login} state={newLocationState} />;
  }

  if (!userIsActivated && !allowForInactiveUsers) {
    return <UserNotActivated />;
  }

  if (!hasCompanies && !allowForInactiveUsers) {
    return <UserWithoutCompany />;
  }

  return (
    <>
      {userIsActivated && hasCompanies && <AppSidebar />}
      <div data-cmptype="LoggedInRequiredRouteWrapper" className="flex flex-grow flex-col overflow-y-auto">
        <Outlet />
      </div>
    </>
  );
};
