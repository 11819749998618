import { QuestionIcon } from 'assets/icons';
import { CopyToClipboardButton } from 'controls/button/copy-to-clipboard-button';
import { Card } from 'controls/card/card';
import { Icon } from 'controls/icon/icon';
import { Tooltip } from 'controls/tooltip/tooltip';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectSelectedConfigurator } from 'slices/company-data/company-data.slice';

export const EmbeddingShopsCard: React.FC = () => {
  const { t } = useTypedTranslation();
  const selectedCfgr = useAppSelector(selectSelectedConfigurator);

  const cfgrName = selectedCfgr?.id ?? '';
  const companyName = selectedCfgr?.companyId ?? '';

  return (
    <Card headerText={t('Embedding Shopware / Shopify')} data-cmptype="EmbeddingShopsCard" grow>
      <EmbeddingShopsEntry caption={t('Configurator Name')} value={cfgrName} />
      <EmbeddingShopsEntry caption={t('Company')} value={companyName} />
      <div className="flex h-8 flex-row items-center gap-1">
        <span className="text-m-regular">{t('Hash Secret')}</span>
        <Tooltip
          title={
            <div>
              <p>{t('This info can be found inside the configurator editor.')}</p>
              <ul className="list-disc pl-6 pt-2">
                <li>{t('Select the "Checkout" component')}</li>
                <li>{t('Select (or create) the desired shop')}</li>
                <li>{t('Get the value from the "HashSecret" parameter')}</li>
              </ul>
            </div>
          }
        >
          <Icon Svg={QuestionIcon} className={`h-5`} />
        </Tooltip>
      </div>
    </Card>
  );
};

type EmbeddingShopsEntryProps = {
  caption: string;
  value: string;
};

const EmbeddingShopsEntry: React.FC<EmbeddingShopsEntryProps> = ({ caption, value }) => {
  const { t } = useTypedTranslation();

  return (
    <div className="flex flex-row items-center">
      <span className="w-40 text-m-regular">{caption}</span>
      <span className="w-48 text-m-medium">{value}</span>
      <CopyToClipboardButton text={value} buttonText={t('Copy')} />
    </div>
  );
};
