import {
  BabylonNodeUpdateDataMap,
  CompleteAssetDto,
  CsvDataSourceConfigDto,
  DeleteAssetRequestItems,
  EmptyAssetItem,
  ExcelDataSourceConfigDto,
  GetMaterialItem,
  RelocateAssetRequestItems,
} from 'services/assets/assets.service';
import { EndpointDescription } from 'services/http/http.service';

export const ASSETS_ENDPOINTS = {
  assetsInitiate: (companyId: string, numOfAssets: number): EndpointDescription => ({
    url: '/assets/initiate',
    params: { companyName: companyId, numOfAssets: numOfAssets },
  }),
  assetsComplete: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    items: CompleteAssetDto[]
  ): EndpointDescription => ({
    url: '/assets/complete',
    params: { companyName: companyId, bundleName: assetBundleId, bundleVersion: assetBundleVersion, items },
  }),
  assetsCreateFolder: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    folderNames: string[]
  ): EndpointDescription => ({
    url: '/assets/folder',
    params: { companyName: companyId, bundleName: assetBundleId, bundleVersion: assetBundleVersion, folderNames },
  }),
  assetsMove: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    items: RelocateAssetRequestItems[]
  ): EndpointDescription => ({
    url: '/assets/move',
    params: { companyName: companyId, bundleName: assetBundleId, bundleVersion: assetBundleVersion, items },
  }),
  assetsCopy: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    items: RelocateAssetRequestItems[]
  ): EndpointDescription => ({
    url: '/assets/copy',
    params: { companyName: companyId, bundleName: assetBundleId, bundleVersion: assetBundleVersion, items },
  }),
  assetsDelete: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    items: DeleteAssetRequestItems[]
  ): EndpointDescription => ({
    url: '/assets/delete',
    params: { companyName: companyId, bundleName: assetBundleId, bundleVersion: assetBundleVersion, items },
  }),
  assetsChangeCsvDataSource: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    folderName: string,
    assetId: string,
    config: CsvDataSourceConfigDto
  ): EndpointDescription => ({
    url: '/assets/changeCsvDataSource',
    params: {
      companyName: companyId,
      bundleName: assetBundleId,
      bundleVersion: assetBundleVersion,
      folderName: folderName,
      assetName: assetId,
      config: config,
    },
  }),
  assetsChangeExcelDataSource: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    folderName: string,
    assetId: string,
    config: ExcelDataSourceConfigDto,
    useConfigAutoDetection: boolean
  ): EndpointDescription => ({
    url: '/assets/changeExcelDataSource',
    params: {
      companyName: companyId,
      bundleName: assetBundleId,
      bundleVersion: assetBundleVersion,
      folderName: folderName,
      assetName: assetId,
      config: config,
      useConfigAutoDetection: useConfigAutoDetection,
    },
  }),
  assetsCreateExcelDataSourceBasedOnFileAsset: (
    companyId: string,
    bundleId: string,
    bundleVersion: string,
    sourceAsset: string,
    targetAsset: string
  ): EndpointDescription => ({
    url: '/assets/createExcelDataSourceBasedOnFileAsset',
    params: {
      companyName: companyId,
      assetBundleName: bundleId,
      assetBundleVersion: bundleVersion,
      sourceAsset: sourceAsset,
      targetAsset: targetAsset,
    },
  }),
  assetsGetMaterialContents: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    items: GetMaterialItem[]
  ): EndpointDescription => ({
    url: '/assets/getMaterialContents',
    params: {
      companyName: companyId,
      bundleName: assetBundleId,
      bundleVersion: assetBundleVersion,
      items: items,
    },
  }),
  assetsChangeMaterialContent: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    folderName: string,
    assetId: string,
    content: string
  ): EndpointDescription => ({
    url: '/assets/changeMaterialContent',
    params: {
      companyName: companyId,
      bundleName: assetBundleId,
      bundleVersion: assetBundleVersion,
      folderName: folderName,
      assetName: assetId,
      content: content,
    },
  }),
  assetsChangeBabylonJsNodes: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    folderName: string,
    assetId: string,
    nodes: BabylonNodeUpdateDataMap
  ): EndpointDescription => ({
    url: '/assets/changeBabylonJsNodes',
    params: {
      companyName: companyId,
      assetBundleName: assetBundleId,
      assetBundleVersion: assetBundleVersion,
      folderName: folderName,
      assetName: assetId,
      nodes: nodes,
    },
  }),
  assetsChangeTextContent: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    folderName: string,
    assetId: string,
    content: string
  ): EndpointDescription => ({
    url: '/assets/changeTextContent',
    params: {
      companyName: companyId,
      bundleName: assetBundleId,
      bundleVersion: assetBundleVersion,
      folderName: folderName,
      assetName: assetId,
      content: content,
    },
  }),
  assetsCreateEmpty: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    emptyAssetItems: EmptyAssetItem[]
  ): EndpointDescription => ({
    url: '/assets/createEmpty',
    params: {
      companyName: companyId,
      bundleName: assetBundleId,
      bundleVersion: assetBundleVersion,
      items: emptyAssetItems,
    },
  }),
  getDataSourceContent: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    assetFolderName: string,
    assetName: string,
    startRow: number,
    endRow: number
  ): EndpointDescription => ({
    url: '/assets/getDataSourceContent',
    params: {
      companyName: companyId,
      assetBundleName: assetBundleId,
      assetBundleVersion: assetBundleVersion,
      dataSourceAsset: {
        folderName: assetFolderName,
        assetName: assetName,
      },
      startRow: startRow,
      endRow: endRow,
    },
  }),
  requestDownloadSharedAccessForAsset: (
    companyId: string,
    assetBundleId: string,
    assetBundleVersion: string,
    assetFolderName: string,
    assetName: string,
    friendlyFileName: string
  ): EndpointDescription => ({
    url: '/assets/requestDownloadSharedAccessForAsset',
    params: {
      companyName: companyId,
      assetBundleName: assetBundleId,
      assetBundleVersion: assetBundleVersion,
      asset: {
        folderName: assetFolderName,
        assetName: assetName,
      },
      friendlyFileName: friendlyFileName,
    },
  }),
};
