import { Middleware, PayloadAction, isRejected } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { HttpStatusCode } from 'helper/http/http-status.helper';
import { addError, addLog } from 'services/store/logger.service';

export const loggerMiddleware: Middleware<
  {}, // Most middleware do not modify the dispatch return value
  /* Can't be typed correctly (RootState)
   * Suggested solution: https://github.com/reduxjs/redux/issues/4267
   * - Leads to another issue: https://github.com/reduxjs/redux-toolkit/issues/2068
   * - Also type `RootStateKeys` wouldn't work anymore
   */
  any
> = storeApi => next => (action: PayloadAction) => {
  if (!isRejected(action)) {
    return next(action);
  }

  const wasAborted = action.meta?.aborted;
  const wasRejected = action.error?.message === 'Rejected';

  const wasHttpError =
    action.error?.code &&
    (Object.prototype.hasOwnProperty.call(AxiosError, action.error?.code) ||
      Object.keys(HttpStatusCode).indexOf(action.error.code) > -1);

  if (wasAborted) {
    addLog(`Aborted action "${action.type}"`, 'Information', { action: action });
  } else if (wasRejected) {
    // Actions which have been "safely" rejected with `rejectWithValue()`
    addLog(`Rejected action "${action.type}"`, 'Information', { action: action });
  } else if (wasHttpError) {
    addError(`Http response ${action.error.code}`, { action: action });
  } else {
    // cases which might be actual errors/bugs
    if (process.env.NODE_ENV === 'development') {
      addLog(`[Middleware] Rejected action: "${action.type}"`, 'Verbose');
    }

    if (action.error) {
      addError(`Error in action "${action.type}"`, {
        action: action,
      });
    } else {
      // There are no known cases where this might happen, so it's reported as critical (for now)
      addError(`Rejected without error in action "${action.type}"`, {
        action: action,
        isCritical: true,
      });
    }
  }

  return next(action);
};

export default loggerMiddleware;
