type CbnNestedCardProps = {
  children: React.ReactNode;
};

/**
 * Can be used within a card; light border, no shadow, narrow paddings
 * For now this component provides only the very basic styling and might be extended/improved as needed
 */
export const CbnNestedCard: React.FC<CbnNestedCardProps> = ({ children }) => {
  return (
    <div data-cmptype="CbnNestedCard" className="flex flex-col rounded-lg border border-neutral-40 p-4">
      {children}
    </div>
  );
};
