import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { GearIcon, MoveDownIcon } from 'assets/icons';
import {
  TexturePropertiesDef,
  TextureProperty,
  TexturePropertyKey,
} from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor-properties-def';
import {
  TexturePropBooleanInput,
  TexturePropNumberInput,
  TexturePropSelectInput,
} from 'components/asset-editor/details/asset-body/material-assets/texture-input/material-prop-texture-prop-inputs';
import { FormControlLabel } from 'controls/form/form-control-label';
import { Icon } from 'controls/icon/icon';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { TextureObject } from 'services/3d/materials.service';
import { toggleExpandedTextureSetting } from 'slices/material-asset/material-asset.slice';

type MaterialPropTextureSettingsProps = {
  textureName: string;
  inputObj: TextureObject;
  disabled: boolean;
  onTexturePropChange: (key: TexturePropertyKey, value: boolean | number | undefined) => void;
};

export const MaterialPropTextureSettings: React.FC<MaterialPropTextureSettingsProps> = ({
  textureName,
  inputObj,
  disabled,
  onTexturePropChange,
}) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const { expandedTextureSettings } = useAppSelector(state => state.materialAsset.ui);
  const expanded = expandedTextureSettings.includes(textureName);

  const createPropElements = useCallback(
    (props: [string, TextureProperty][]) =>
      props.map(([key, propertyDef]) =>
        propertyDef.type === 'boolean' ? (
          <TexturePropBooleanInput
            key={key}
            objKey={key}
            inputObj={inputObj}
            disabled={disabled}
            displayName={t(propertyDef.displayName)}
            defaultValue={propertyDef.defaultValue as boolean}
            info={t(propertyDef.info ?? '')}
            onChange={onTexturePropChange}
          />
        ) : propertyDef.type === 'number' ? (
          <TexturePropNumberInput
            key={key}
            objKey={key}
            inputObj={inputObj}
            disabled={disabled}
            displayName={t(propertyDef.displayName)}
            defaultValue={propertyDef.defaultValue as number}
            onChange={onTexturePropChange}
          />
        ) : propertyDef.type === 'select' ? (
          <TexturePropSelectInput
            key={key}
            objKey={key}
            inputObj={inputObj}
            disabled={disabled}
            displayName={t(propertyDef.displayName)}
            defaultValue={propertyDef.defaultValue as number}
            options={propertyDef.options!}
            onChange={onTexturePropChange}
          />
        ) : (
          <></>
        )
      ),
    [disabled, inputObj, onTexturePropChange, t]
  );

  // NOTE: texture groups are treated individually since the UI is not that generic as it is for material properties
  const generalElements = useMemo(() => {
    const filteredProps = Object.entries(TexturePropertiesDef).filter(
      ([, propertyDef]) => propertyDef.group === 'General'
    );
    return createPropElements(filteredProps);
  }, [createPropElements]);

  const transformElements = useMemo(() => {
    const filteredProps = Object.entries(TexturePropertiesDef).filter(
      ([, propertyDef]) => propertyDef.group === 'Transform'
    );
    const elements = createPropElements(filteredProps);
    // NOTE: "wAngle" should be located exactly underneath "vAngle" in the grid, which is very hard to do with our
    // generic approach
    // for now we just add two invisible placeholder entries to achieve that => adjust the framework accordingly if
    // these kind of use cases occur more frequently in the future
    const wAngleEntry = elements.pop()!;
    elements.push(<div key={'placeholderWAngle1'}></div>, <div key={'placeholderWAngle2'}></div>);
    elements.push(wAngleEntry);
    return elements;
  }, [createPropElements]);

  const onTextureSettingsHeaderClicked = useCallback((): void => {
    dispatch(toggleExpandedTextureSetting(textureName));
  }, [dispatch, textureName]);

  return (
    <div
      data-cmptype="MaterialPropTextureSettings"
      // material group also ends with a 3px bottom border, so we don't add it for the last texture in the group as it
      // would result in a 6px border
      className={'border-b-[2px] border-neutral-40 group-last:border-b-0'}
    >
      <FormControlLabel
        className="flex h-8 items-center justify-between bg-neutral-30 pl-6 pr-4 text-m-medium"
        labelPlacement="start"
        label={
          <div className="flex items-center gap-2">
            <Icon className="h-5" Svg={GearIcon} />
            <span className="text-s-medium text-neutral-100">{t('Texture Settings')}</span>
          </div>
        }
        control={
          <Icon
            Svg={MoveDownIcon}
            className={classNames('w-3 text-neutral-90 transition-transform', { 'rotate-180': expanded })}
            onClick={onTextureSettingsHeaderClicked}
          />
        }
      />
      {expanded && (
        <div className="m-6 mr-4 flex flex-col gap-2">
          <span className="text-s-medium text-neutral-100">{t('General')}</span>
          <div className="grid grid-cols-[1fr_1fr] gap-x-8 gap-y-4">{generalElements}</div>
          <span className="mt-6 text-s-medium text-neutral-100">{t('Transform')}</span>
          <div className="grid grid-cols-[1fr_1fr_1fr] gap-x-8 gap-y-4">{transformElements}</div>
        </div>
      )}
    </div>
  );
};
