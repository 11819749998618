import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { RefreshIcon } from 'assets/icons';
import { ResendActivation } from 'components/login/common/resend-activation';
import { Button } from 'controls/button/button';
import { Card } from 'controls/card/card';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { PATHS } from 'services/routes/paths.service';
import { getUserInfo, selectUserIsActivated } from 'slices/auth/auth.slice';

export const UserNotActivated: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const userIsActivated = useAppSelector(selectUserIsActivated);

  useEffect(
    function checkForUpdatedUserStateOnMount() {
      dispatch(getUserInfo());
    },
    [dispatch]
  );

  if (userIsActivated) {
    // redirect if the user state changed by now
    return <Navigate to={PATHS.home} replace />;
  }

  return (
    <div data-cmptype="UserNotActivated" className="flex flex-grow flex-col items-center justify-center gap-6">
      <Card headerText={t('User is not activated')}>
        <div className="flex w-[600px] flex-col gap-6">
          <p>
            {t(
              "Refresh the page after you've activated your account using the link which has been sent to your email address."
            )}
          </p>

          <div className="flex gap-2">
            <ResendActivation />
            <Button
              text={t('Refresh page')}
              Svg={RefreshIcon}
              variant={'Secondary'}
              onClick={(): void => window.location.reload()}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
