import { Outlet } from 'react-router-dom';
import { Card } from 'controls/card/card';
import { StateIcon } from 'controls/icon/state-icon';
import { CompanyStates } from 'generated/company-states';
import { useConfiguratorsRouteSync } from 'hooks/cfgrs/cfgrs.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';

export const CompanyStateWrapper: React.FC = () => {
  const { t } = useTypedTranslation();
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const isSuspended = selectedCompany?.state === CompanyStates.Suspended;

  // Be careful when touching this hook. It being placed in the correct hierarchy in the route tree (`App.tsx`) is
  // rather crucial for the correct behavior of the app. E.g. see CB-9287 for what happens if it's not positioned
  // correctly.
  useConfiguratorsRouteSync();

  return isSuspended ? (
    <div className="flex p-6">
      <Card>
        <div className="flex items-center gap-4">
          <StateIcon variant="Info" />
          <span className="text-l-medium">{t('The selected company is suspended!')}</span>
        </div>
      </Card>
    </div>
  ) : (
    <Outlet />
  );
};
