import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import {
  CfgrWhitelistDomainsInfo,
  getCfgrWhitelistDomains,
  selectCfgrWhitelistDomainsInfo,
} from 'slices/cfgr-whitelist-domains.slice';

export const useCfgrWhitelistDomains = (companyId?: string, cfgrId?: string): CfgrWhitelistDomainsInfo => {
  const dispatch = useAppDispatch();
  const domainsInfo = useAppSelector(selectCfgrWhitelistDomainsInfo);

  useEffect(() => {
    if (companyId && cfgrId) {
      dispatch(getCfgrWhitelistDomains({ companyId, cfgrId }));
    }
  }, [companyId, cfgrId, dispatch]);

  return domainsInfo;
};
