import { AxiosError } from 'axios';
import { useState } from 'react';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { FileSelectButton } from 'controls/button/file-select-button';
import { StateIcon } from 'controls/icon/state-icon';
import { RadioButtonEntry, RadioButtonGroup } from 'controls/radio-button/radio-button-group';
import { postConfiguratorsRehauUploadDataPackage } from 'services/cfgrs/cfgrs.service';
import { ActionResult, isGenericActionResult } from 'services/http/http.service';

const _MERGE_MODES: RadioButtonEntry[] = [
  {
    value: 0,
    text: 'Bestehende Einträge überschreiben',
  },
  {
    value: 1,
    text: 'Nur neue Einträge erstellen',
  },
  {
    value: 2,
    text: 'Paket komplett ersetzen',
  },
];

export type RehauUploadDatapackageProps = {
  destination: {
    companyId: string;
    cfgrName: string;
    draftId: string;
  };
  onConfirm?: () => void;
  onCancel: () => void;
};

export const RehauUploadDatapackage: React.FC<RehauUploadDatapackageProps> = ({ destination, onConfirm, onCancel }) => {
  // NOT TRANSLATED
  // The whole dialog was german and we keep it this way, as it's customer specific anyways
  // const { t } = useTypedTranslation();
  const [importFile, setImportFile] = useState<File>();
  const [selectedMode, setSelectedMode] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorResult, setErrorResult] = useState<ActionResult>();

  const onFileSelected = (files: File[]): void => {
    const isValidFile = files.length === 1 && files[0].type === 'application/x-zip-compressed';
    setImportFile(isValidFile ? files[0] : undefined);
  };

  const onUploadConfirm = async (): Promise<void> => {
    if (!importFile) {
      throw new Error('Import file is missing!');
    }
    setIsProcessing(true);
    setErrorResult(undefined);

    let success = false;
    try {
      await postConfiguratorsRehauUploadDataPackage(
        destination.companyId,
        destination.cfgrName,
        destination.draftId,
        importFile,
        selectedMode
      );
      success = true;
    } catch (ex) {
      const errResult = (ex as AxiosError).response?.data;

      setIsProcessing(false);
      if (errResult && isGenericActionResult(errResult)) {
        setErrorResult(errResult);
      } else {
        throw ex;
      }
    }

    setIsProcessing(false);
    if (success) {
      onConfirm?.();
    }
  };

  return (
    <ModalDialog
      data-cmptype="RehauUploadDatapackage"
      variant="NoIcon"
      header="Daten-Paket hochladen"
      confirmText="Hochladen"
      confirmDisabled={!importFile}
      isProcessingConfirm={isProcessing}
      actions={{
        onConfirm: onUploadConfirm,
        onCancel: onCancel,
      }}
    >
      <div className="flex flex-col gap-4">
        <FileSelectButton
          text={importFile ? 'Datei ändern...' : 'Datei auswählen...'}
          variant={importFile ? 'Secondary' : 'Primary'}
          onSelect={onFileSelected}
        />
        {importFile && <span className="text-m-regular">{importFile.name}</span>}
        <RadioButtonGroup
          entries={_MERGE_MODES}
          value={selectedMode}
          onValueChange={(newVal): void => setSelectedMode(newVal as number)}
        />
        {errorResult && (
          <div className="flex items-center gap-2">
            <StateIcon variant="Error" />
            <div className="flex flex-col text-danger-main">
              <span className="text-m-medium">{errorResult.Id}</span>
              <span>{errorResult.Message}</span>
            </div>
          </div>
        )}
      </div>
    </ModalDialog>
  );
};
