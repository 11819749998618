import { WebhookTypes } from 'generated/webhook-types';
import { WorkflowParameterTypes } from 'generated/workflow-parameter-types';
import { WorkflowRunStates } from 'generated/workflow-run-states';
import { WorkflowRunTypes } from 'generated/workflow-run-types';
import { WorkflowStates } from 'generated/workflow-states';
import { HttpStatusCode } from 'helper/http/http-status.helper';
import { workflowPathToKey } from 'helper/workflows/workflows.helper';
import * as HttpService from 'services/http/http.service';
import { ActionResult, isGenericActionResult } from 'services/http/http.service';

export const EDIT_WORKFLOW_CONFIG_ERROR_RESPONSES = ['InvalidUri', 'InvalidMailAddress'] as const;
export type EditWorkflowConfigErrorResponse = (typeof EDIT_WORKFLOW_CONFIG_ERROR_RESPONSES)[number];

export const EDIT_WORKFLOW_CONFIG_ERROR_RESPONSE_MSGS: Record<EditWorkflowConfigErrorResponse, string> = {
  InvalidUri: 'Invalid Uri syntax',
  InvalidMailAddress: 'Invalid email address(es)',
};

export type WorkflowRunId = string;

export type SimpleWorkflowParameter = {
  type: Exclude<WorkflowParameterTypes, WorkflowParameterTypes.AssetBundleAssignedToCfgr | WorkflowParameterTypes.File>;
  defaultValue: boolean | number | string;
};

export type BundleWorkflowParameter = {
  type: WorkflowParameterTypes.AssetBundleAssignedToCfgr;
  cfgrName: string;
  bundleName: string;
};

export type FileWorkflowParameter = {
  type: WorkflowParameterTypes.File;
  url: string;
  authorizationScheme?: string;
  authorizationParameter?: string;
  overwriteContentType?: string;
};

export type WorkflowParseErrorDto = {
  message: string;
  line: number;
  column: number;
};

export type WorkflowParameter = SimpleWorkflowParameter | BundleWorkflowParameter | FileWorkflowParameter;
export type WorkflowParameters = { [key: string]: WorkflowParameter };

export type WritableWorkflowParameters<T extends WorkflowParameter> = Partial<Omit<T, 'type'>>;

export type WorkflowNotificationConfig = {
  emailAddresses?: string[];
  webhookConfig?: {
    url: string;
    type: WebhookTypes;
  };
};

export type WorkflowDto = {
  workflowName: string;
  displayName: string;
  createdBy: string;
  createdAt: number;
  lastEditedBy: string;
  lastEditedAt: number;
  state: WorkflowStates;
  parameters: WorkflowParameters;
  cronExpression?: string;
  notificationConfig?: WorkflowNotificationConfig;
  blobContentSasUri?: string;
  parseError?: WorkflowParseErrorDto;
};

export type Workflow = WorkflowDto & {
  /**
   * `blobContentSasUri` will only be set when fetching the data from a dedicated workflow (`fetchWorkflow`)
   * when fetching all workflows, the `blobContentSasUri` is not available in the data and will therefore be set to an
   * empty string
   */
  blobContentSasUri: string;
  companyId: string;
};

export type Workflows = {
  [key: string]: Workflow;
};

export type WorkflowRunDto = {
  workflowName: string;
  workflowRunId: WorkflowRunId;
  startedBy: string;
  startedAt: number;
  finishedAt: number;
  type: WorkflowRunTypes;
  state: WorkflowRunStates;
};

export type WorkflowRun = WorkflowRunDto & { runLogSasUri: string; companyId: string };

export type WorkflowRuns = {
  [key: string]: WorkflowRun;
};

export type UpdateWorkflowContentDto = {
  parseError: WorkflowParseErrorDto;
  workflowState: WorkflowStates;
};

export type UpdateWorkflowContent = UpdateWorkflowContentDto;

export function isSimpleWorkflowParameter(param: WorkflowParameter): param is SimpleWorkflowParameter {
  return (
    param.type === WorkflowParameterTypes.Bool ||
    param.type === WorkflowParameterTypes.Number ||
    param.type === WorkflowParameterTypes.String
  );
}

export function isBundleWorkflowParameter(param: WorkflowParameter): param is BundleWorkflowParameter {
  return param.type === WorkflowParameterTypes.AssetBundleAssignedToCfgr;
}

export function isFileWorkflowParameter(param: WorkflowParameter): param is FileWorkflowParameter {
  return param.type === WorkflowParameterTypes.File;
}

export async function fetchWorkflow(companyId: string, workflowName: string): Promise<Workflow> {
  const { url, params } = HttpService.endpoints.workflowsGet(companyId, workflowName);
  const res = await HttpService.httpClient.get<WorkflowDto>(url, { params });
  const workflow = _workflowResponseToWorkflow(companyId, res.data);

  return workflow;
}

export async function fetchAllWorkflows(companyId: string): Promise<Workflows> {
  const { url, params } = HttpService.endpoints.workflowsGetAllForCompany(companyId);
  const res = await HttpService.httpClient.get<WorkflowDto[]>(url, { params });
  const workflows = _workflowsResponseToWorkflows(companyId, res.data);

  return workflows;
}

function _workflowResponseToWorkflow(companyId: string, workflowDto: WorkflowDto): Workflow {
  const {
    workflowName,
    displayName,
    createdBy,
    createdAt,
    lastEditedBy,
    lastEditedAt,
    state,
    parameters,
    cronExpression,
    notificationConfig,
    parseError,
  } = workflowDto;
  // sas uri is only provided when fetching a single workflow, therefore it may be empty here
  const blobContentSasUri = workflowDto.blobContentSasUri ?? '';

  const workflow: Workflow = {
    companyId: companyId,
    workflowName,
    displayName,
    createdBy,
    createdAt,
    lastEditedBy,
    lastEditedAt,
    state,
    parameters,
    cronExpression,
    notificationConfig,
    blobContentSasUri,
    parseError,
  };
  return workflow;
}

function _workflowsResponseToWorkflows(companyId: string, resp: WorkflowDto[]): Workflows {
  const workflows = resp.reduce<Workflows>((acc, wfDto) => {
    const workflow = _workflowResponseToWorkflow(companyId, wfDto);
    const key = workflowPathToKey({ companyId, workflowName: wfDto.workflowName });

    acc[key] = workflow;
    return acc;
  }, {});

  return workflows;
}

export async function postCreateWorkflow(companyId: string, workflowName: string, displayName: string): Promise<void> {
  const { url, params } = HttpService.endpoints.workflowsCreate(companyId, workflowName, displayName);
  await HttpService.httpClient.post(url, params);
}

export async function postDeleteWorkflow(companyId: string, workflowName: string): Promise<void> {
  await HttpService.httpClient.delete(HttpService.endpoints.workflowsDelete, {
    data: {
      companyName: companyId,
      workflowName: workflowName,
    },
  });
}

/**
 * @returns `workflowRunId`
 */
export async function postStartWorkflow(
  companyId: string,
  workflowName: string
): Promise<WorkflowRunId | ActionResult> {
  const { url, params } = HttpService.endpoints.workflowsStart(companyId, workflowName);
  const response = await HttpService.httpClient.post<WorkflowRunId>(url, params, { validateStatus: () => true });

  if (response.status === HttpStatusCode.Ok_200) {
    return response.data;
  } else if (isGenericActionResult(response.data)) {
    return response.data;
  } else {
    throw new Error(JSON.stringify(response.data));
  }
}

export async function postUpdateWorkflowCode(
  companyId: string,
  workflowName: string,
  code: string
): Promise<UpdateWorkflowContent> {
  const { url, params } = HttpService.endpoints.workflowsUpdateContent(companyId, workflowName, code);
  const response = await HttpService.httpClient.post<UpdateWorkflowContentDto>(url, params);
  return response.data;
}

export async function postUpdateWorkflowConfig(
  companyId: string,
  workflowName: string,
  displayName: string,
  parameters: WorkflowParameters,
  notificationConfig: WorkflowNotificationConfig,
  cronExpression?: string
): Promise<ActionResult<EditWorkflowConfigErrorResponse> | true> {
  const { url, params } = HttpService.endpoints.workflowsUpdateConfig(
    companyId,
    workflowName,
    displayName,
    parameters,
    notificationConfig,
    cronExpression
  );
  const res = await HttpService.httpClient.post(url, params, { validateStatus: () => true });

  if (res.status === HttpStatusCode.Ok_200) {
    return true;
  } else if (isGenericActionResult(res.data, EDIT_WORKFLOW_CONFIG_ERROR_RESPONSES)) {
    return res.data;
  } else {
    throw new Error('Unexpected response status');
  }
}

export async function fetchWorkflowRun(
  companyId: string,
  workflowName: string,
  workflowRunId: WorkflowRunId
): Promise<WorkflowRun | null> {
  const { url, params } = HttpService.endpoints.workflowRunsGetWorkflowRun(companyId, workflowName, workflowRunId);
  const res = await HttpService.httpClient.get<WorkflowRunDto>(url, { params, validateStatus: () => true });

  if (res.status === HttpStatusCode.Ok_200) {
    const workflowRun = _workflowRunResponseToWorkflowRun(companyId, res.data);

    return workflowRun;
  } else {
    return null;
  }
}

export async function fetchAllWorkflowRunsForWorkflow(companyId: string, workflowName: string): Promise<WorkflowRuns> {
  const { url, params } = HttpService.endpoints.workflowRunsGetAllForWorkflow(companyId, workflowName);
  const res = await HttpService.httpClient.get<WorkflowRunDto[]>(url, { params });
  const workflowRuns = _workflowRunsResponseToWorkflowRun(companyId, res.data);

  return workflowRuns;
}

function _workflowRunResponseToWorkflowRun(companyId: string, resp: WorkflowRunDto): WorkflowRun {
  // sas uri is not provided in the `WorkflowRunDto` object, there is a dedicated call for it
  const workflowRun: WorkflowRun = { ...resp, companyId, runLogSasUri: '' };
  return workflowRun;
}

function _workflowRunsResponseToWorkflowRun(companyId: string, resp: WorkflowRunDto[]): WorkflowRuns {
  const workflowRuns = resp.reduce<WorkflowRuns>((acc, wfrDto) => {
    const workflowRun = _workflowRunResponseToWorkflowRun(companyId, wfrDto);
    const key = workflowPathToKey({
      companyId,
      workflowName: wfrDto.workflowName,
      workflowRunId: wfrDto.workflowRunId,
    });

    acc[key] = workflowRun;
    return acc;
  }, {});

  return workflowRuns;
}

export async function fetchWorkflowRunLog(
  companyId: string,
  workflowName: string,
  workflowRunId: WorkflowRunId
): Promise<string> {
  const { url, params } = HttpService.endpoints.workflowRunsGetWorkflowRunLog(companyId, workflowName, workflowRunId);
  const res = await HttpService.httpClient.get<string>(url, { params });

  return res.data;
}
