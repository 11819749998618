import { Button as BaseButton } from '@mui/base/Button';
import React, { MouseEventHandler, ReactElement, ReactNode } from 'react';
import { SelectIndicatorIcon } from 'assets/icons';
import { ConditionalWrapper } from 'components/common/conditional-wrapper';
import { Icon, SvgComponent } from 'controls/icon/icon';
import { LoaderSize, PercentageLoader } from 'controls/loader/percentage-loader';
import { ForwardableTooltipProps, Tooltip } from 'controls/tooltip/tooltip';
import { CtrlStateClassDefinition } from 'helper/controls/controls.helper';
import { cn, tw } from 'helper/css.helper';
import { useTimedActiveState } from 'hooks/controls/button.hooks';

export type ButtonVariants =
  | 'Primary'
  | 'Secondary'
  | 'Outlined'
  | 'Text'
  | 'TextInline'
  | 'OutlinedOnHover'
  | 'ChipMenu';
// custom button themes, can be used to overwrite button style depending on a state from outside
// extend as needed
export type ButtonColors =
  | 'Danger'
  | 'Warning'
  // emulate switch button behaviour, on: 'Pressed', off: default button styling (no custom button color)
  | 'Pressed';

export type BaseButtonProps = {
  variant?: ButtonVariants;
  color?: ButtonColors;
  hoverColor?: ButtonColors;
  big?: boolean;
  disabled?: boolean;
  text?: string;
  title?: string;
  titleProps?: ForwardableTooltipProps;
  tooltipCmp?: ReactNode;
  ariaLabel?: string;
  Svg?: SvgComponent;
  grow?: boolean;
  isLoading?: boolean;
  isDropdownButton?: boolean;
};

type ButtonProps = BaseButtonProps & {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: MouseEventHandler;
  onMouseOut?: MouseEventHandler;
};

// Style definitions for each individual button variant.
// Theoretically this could all be put into `default`, but it's easier to read if the individual pseudo
// classes are split up.
// This map mostly covers colors, since all base styles, like sizes are the same throughout all button variants.
// Automatically prefixing the pseudo classes doesn't see to work because tailwind classes may not be concatinated =>
// Eg: https://stackoverflow.com/q/69137344
const _buttonVariantStateMap: Record<ButtonVariants, CtrlStateClassDefinition> = {
  Primary: {
    default: tw`m-outline-2 bg-primary-main text-neutral-10`,
    hover: tw`hover:bg-primary-hover`,
    focus: tw`focus:bg-primary-hover focus:outline focus:outline-2 focus:outline-primary-focus`,
    active: tw`active:bg-primary-pressed`,
    disabled: tw`disabled:bg-neutral-30 disabled:text-neutral-60 disabled:shadow-ctrl-disabled disabled:shadow-neutral-50`,
  },
  Secondary: {
    default: tw`m-outline-2 bg-primary-surface text-primary-main`,
    hover: tw`hover:text-primary-hover`,
    focus: tw`focus:text-primary-hover focus:outline focus:outline-2 focus:outline-primary-focus`,
    active: tw`active:text-primary-pressed`,
    disabled: tw`disabled:bg-neutral-30 disabled:text-neutral-60 disabled:shadow-ctrl-disabled disabled:shadow-neutral-50`,
  },
  Outlined: {
    default: tw`m-outline-2 border border-neutral-50 bg-neutral-10 text-neutral-100`,
    hover: tw`hover:bg-neutral-20 hover:text-neutral-100`,
    focus: tw`focus:border-primary-main focus:bg-neutral-20 focus:text-neutral-100 focus:outline focus:outline-2 focus:outline-primary-focus`,
    active: tw`active:bg-neutral-30 active:text-neutral-100`,
    disabled: tw`disabled:bg-neutral-30 disabled:text-neutral-60`,
  },
  Text: {
    default: tw`m-outline-2 text-primary-main`,
    hover: tw`hover:text-primary-hover`,
    focus: tw`focus:text-primary-hover focus:outline focus:outline-2 focus:outline-primary-focus`,
    active: tw`active:text-primary-pressed`,
    disabled: tw`disabled:text-neutral-60`,
  },
  TextInline: {
    default: tw`m-outline-2 my-0 inline-flex h-auto min-h-0 px-0 text-primary-main`,
    hover: tw`hover:text-primary-hover hover:underline`,
    focus: tw`focus:text-primary-hover focus:outline focus:outline-2 focus:outline-primary-focus`,
    active: tw`active:text-primary-pressed`,
    disabled: tw`disabled:text-neutral-60`,
  },
  OutlinedOnHover: {
    default: tw`m-outline-2 text-neutral-100`,
    hover: tw`hover:text-primary-main hover:outline hover:outline-1 hover:outline-neutral-50`,
    focus: tw`focus:text-primary-main focus:outline focus:outline-2 focus:outline-primary-focus`,
    active: tw`active:text-primary-pressed active:outline active:outline-1 active:outline-neutral-50`,
    disabled: tw`disabled:text-neutral-60`,
  },
  ChipMenu: {
    default: tw`h-auto min-h-0 rounded-lg border border-primary-surface bg-neutral-10 px-3 py-0.5 text-m-regular text-neutral-90`,
    hover: tw`hover:text-primary-main hover:outline hover:outline-1 hover:outline-neutral-50`,
    focus: tw`focus:text-primary-main focus:outline focus:outline-2 focus:outline-primary-focus`,
    active: tw`active:text-primary-pressed active:outline active:outline-1 active:outline-neutral-50`,
    disabled: tw`disabled:text-neutral-60`,
  },
};

type ColorOverride = Record<ButtonColors, Partial<CtrlStateClassDefinition>>;
const _buttonColorMap: Record<ButtonVariants, ColorOverride> = {
  Primary: {
    Danger: {
      default: tw`m-outline-2 bg-danger-main text-neutral-10`,
      hover: tw`hover:bg-danger-hover`,
      focus: tw`focus:bg-danger-hover focus:outline focus:outline-2 focus:outline-danger-focus`,
      active: tw`active:bg-danger-pressed`,
    },
    Warning: {
      default: tw`m-outline-2 bg-warning-main text-neutral-10`,
      hover: tw`hover:bg-warning-hover`,
      focus: tw`focus:bg-warning-hover focus:outline focus:outline-2 focus:outline-warning-focus`,
      active: tw`active:bg-warning-pressed`,
    },
    // not implemented/designed yet
    Pressed: {},
  },
  Secondary: {
    Danger: {
      default: tw`m-outline-2 bg-danger-surface text-danger-main`,
      hover: tw`hover:bg-danger-surface hover:text-danger-hover`,
      focus: tw`focus:text-danger-hover focus:outline focus:outline-2 focus:outline-danger-focus`,
      active: tw`active:text-danger-pressed`,
    },
    Warning: {
      default: tw`m-outline-2 bg-warning-surface text-warning-main`,
      hover: tw`hover:bg-warning-surface hover:text-warning-hover`,
      focus: tw`focus:text-warning-hover focus:outline focus:outline-2 focus:outline-warning-focus`,
      active: tw`active:text-warning-pressed`,
    },
    // not implemented/designed yet
    Pressed: {},
  },
  Outlined: {
    Danger: {
      default: tw`m-outline-2 border border-neutral-50 bg-neutral-10 text-danger-main`,
      hover: tw`hover:border-danger-border hover:bg-danger-surface hover:text-danger-main`,
      focus: tw`focus:border-danger-main focus:bg-danger-surface focus:text-danger-main focus:outline focus:outline-2 focus:outline-danger-focus`,
      active: tw`active:bg-danger-surface active:text-danger-main`,
    },
    Warning: {
      default: tw`m-outline-2 border border-neutral-50 bg-neutral-10 text-warning-main`,
      hover: tw`hover:border-warning-border hover:bg-warning-surface hover:text-warning-main`,
      focus: tw`focus:border-warning-main focus:bg-warning-surface focus:text-warning-main focus:outline focus:outline-2 focus:outline-warning-focus`,
      active: tw`active:bg-warning-surface active:text-warning-main`,
    },
    Pressed: {
      default: tw`m-outline-2 border border-primary-border bg-primary-surface text-primary-main`,
      hover: tw`hover:text-neutral-100`,
      focus: tw`focus:border-primary-main focus:text-neutral-100 focus:outline focus:outline-2 focus:outline-primary-focus`,
      active: '',
    },
  },
  Text: {
    Danger: {
      default: tw`m-outline-2 text-danger-main`,
      hover: tw`hover:text-danger-hover`,
      focus: tw`focus:text-danger-hover focus:outline focus:outline-2 focus:outline-danger-focus`,
      active: tw`active:text-danger-pressed`,
    },
    Warning: {
      default: tw`m-outline-2 text-warning-main`,
      hover: tw`hover:text-warning-hover`,
      focus: tw`focus:text-warning-hover focus:outline focus:outline-2 focus:outline-warning-focus`,
      active: tw`active:text-warning-pressed`,
    },
    // not implemented/designed yet
    Pressed: {},
  },
  TextInline: {
    Danger: {
      default: tw`m-outline-2 text-danger-main`,
      hover: tw`hover:text-danger-hover`,
      focus: tw`focus:text-danger-hover focus:outline focus:outline-2 focus:outline-danger-focus`,
      active: tw`active:text-danger-pressed`,
    },
    Warning: {
      default: tw`m-outline-2 text-warning-main`,
      hover: tw`hover:text-warning-hover`,
      focus: tw`focus:text-warning-hover focus:outline focus:outline-2 focus:outline-warning-focus`,
      active: tw`active:text-warning-pressed`,
    },
    // not implemented/designed yet
    Pressed: {},
  },
  OutlinedOnHover: {
    Danger: {
      default: tw`m-outline-2 text-danger-main`,
      hover: tw`hover:text-danger-hover`,
      focus: tw`focus:text-danger-hover focus:outline focus:outline-2 focus:outline-danger-focus`,
      active: tw`active:text-danger-pressed`,
    },
    Warning: {
      default: tw`m-outline-2 text-warning-main`,
      hover: tw`hover:text-warning-hover`,
      focus: tw`focus:text-warning-hover focus:outline focus:outline-2 focus:outline-warning-focus`,
      active: tw`active:text-warning-pressed`,
    },
    // not implemented/designed yet
    Pressed: {},
  },
  ChipMenu: {
    Danger: {
      default: tw`m-outline-2 border border-neutral-50 bg-neutral-10 text-danger-main`,
      hover: tw`hover:border-danger-border hover:bg-danger-surface hover:text-danger-main`,
      focus: tw`focus:border-danger-main focus:bg-danger-surface focus:text-danger-main focus:outline focus:outline-2 focus:outline-danger-focus`,
      active: tw`active:bg-danger-surface active:text-danger-main`,
    },
    Warning: {
      default: tw`m-outline-2 border border-neutral-50 bg-neutral-10 text-warning-main`,
      hover: tw`hover:border-warning-border hover:bg-warning-surface hover:text-warning-main`,
      focus: tw`focus:border-warning-main focus:bg-warning-surface focus:text-warning-main focus:outline focus:outline-2 focus:outline-warning-focus`,
      active: tw`active:bg-warning-surface active:text-warning-main`,
    },
    Pressed: {
      default: tw`m-outline-2 border border-primary-border bg-primary-surface text-primary-main`,
      hover: tw`hover:text-neutral-100`,
      focus: tw`focus:border-primary-main focus:text-neutral-100 focus:outline focus:outline-2 focus:outline-primary-focus`,
      active: '',
    },
  },
};

// Individual classes for disabled link, because a <Link> (or <a> tag) doesn't support the "disabled" pseudo class
const _disabledLinkButtonVariantMap: Record<ButtonVariants, string> = {
  Primary: tw`bg-neutral-30 text-neutral-60 shadow-ctrl-disabled shadow-neutral-50`,
  Secondary: tw`bg-neutral-30 text-neutral-60 shadow-ctrl-disabled shadow-neutral-50`,
  Outlined: tw`bg-neutral-30 text-neutral-60 shadow-ctrl-disabled shadow-neutral-50`,
  Text: tw`text-neutral-60`,
  TextInline: tw`text-neutral-60`,
  OutlinedOnHover: tw`text-neutral-60`,
  ChipMenu: tw`text-neutral-60`,
};

/**
 * This function defines the base styles of the button component, which is not depending on the individual variants
 */
function _getDefaultBtnClasses(big?: boolean, withText?: boolean, useParentFontSize = false): string {
  const fontSize = useParentFontSize ? '' : big ? tw`text-l-medium` : tw`text-m-medium`;
  const height = big ? tw`h-10 min-h-[2.5rem]` : tw`h-8 min-h-[2rem]`;
  const xPadding = withText ? tw`px-4` : big ? 'px-2.5' : tw`px-1.5`;
  const gap = big ? tw`gap-2` : tw`gap-1.5`;

  const className = cn(
    tw`relative flex w-fit select-none items-center whitespace-nowrap rounded transition-[background-color]`,
    height,
    xPadding,
    gap,
    fontSize
  );
  return className;
}

/**
 * Sums up the tailwind css classes for a certain button configuration
 */
export function buttonStyleFactory(
  supportsDisabledAttribute: boolean,
  variant: ButtonVariants,
  color?: ButtonColors,
  hoverColor?: ButtonColors,
  big?: boolean,
  disabled?: boolean,
  withText?: boolean
): string {
  // default styles
  const useParentFontSize = variant === 'TextInline' && !big;
  const defaultClassName = _getDefaultBtnClasses(big, withText, useParentFontSize);

  const defaultRules = _buttonVariantStateMap[variant];
  const colorOverride = color ? _buttonColorMap[variant][color] : undefined;

  const hoverColorOverride = hoverColor ? { ..._buttonColorMap[variant][hoverColor] } : undefined;
  if (hoverColorOverride) {
    delete hoverColorOverride.default;
    delete hoverColorOverride.disabled;
  }
  const variantStateClassName = Object.values({ ...defaultRules, ...colorOverride, ...hoverColorOverride }).join(' ');

  return cn(
    defaultClassName,
    variantStateClassName,
    // Special handling for disabled link buttons
    disabled && !supportsDisabledAttribute && 'pointer-events-none ' + _disabledLinkButtonVariantMap[variant]
  );
}

/**
 * Default implementation of a "button" (html <button> tag) with an `onClick` function
 */
export const Button: React.FC<ButtonProps> = ({
  type = 'button',
  variant = 'Primary',
  color,
  hoverColor,
  big,
  disabled,
  text,
  title,
  titleProps,
  tooltipCmp,
  ariaLabel,
  Svg,
  grow,
  isLoading,
  isDropdownButton,
  onClick,
  onMouseOut,
}) => {
  const hasText = !!text?.length;
  const loaderSize: LoaderSize = hasText && Svg ? 'xsmall' : 'small';
  const showAsLoading = useTimedActiveState(isLoading, 150, 300);

  return (
    <ConditionalWrapper
      condition={!!tooltipCmp || !!title}
      wrapper={(wrapperChildren): ReactElement => (
        <Tooltip title={tooltipCmp ?? title} arrow childGrow={grow} {...titleProps}>
          {wrapperChildren}
        </Tooltip>
      )}
    >
      <BaseButton
        data-cmptype="Button"
        className={cn(buttonStyleFactory(true, variant, color, hoverColor, big, disabled, hasText), {
          'flex-grow justify-center': grow,
          'pointer-events-none': disabled, // Tooltip: required to properly detect leave-events
          'pr-1': isDropdownButton,
        })}
        type={type}
        disabled={isLoading || disabled}
        onClick={onClick}
        onMouseOut={onMouseOut}
        aria-label={ariaLabel || text || title}
      >
        {showAsLoading && (
          <div className={cn('absolute', { 'inset-0 grid items-center justify-center': !Svg })}>
            <PercentageLoader size={loaderSize} infinite />
          </div>
        )}
        {Svg && (
          <Icon
            Svg={Svg}
            className={cn('transition-opacity', {
              'h-4 w-4': hasText,
              'h-5 w-5': !hasText,
              'opacity-0': showAsLoading,
            })}
          />
        )}
        {hasText && (
          <span
            className={cn('flex items-center gap-1.5 transition-opacity', {
              'opacity-0': !Svg && showAsLoading,
            })}
          >
            {text}
            {isDropdownButton && <Icon Svg={SelectIndicatorIcon} className="size-6" />}
          </span>
        )}
      </BaseButton>
    </ConditionalWrapper>
  );
};
