import { useEffect } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { tw } from 'helper/css.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { aiInstance } from 'services/telemetry/telemetry.service';

export const CookieBanner: React.FC = () => {
  const { t } = useTypedTranslation();

  useEffect(() => {
    const consent = getCookieConsentValue();

    if (consent === 'true') {
      aiInstance.getCookieMgr().setEnabled(true);
    }
  }, []);

  const onAcceptClicked = (): void => {
    aiInstance.getCookieMgr().setEnabled(true);
  };

  const containerClasses = tw`text-l-regular opacity-90`;
  const btnClasses = tw`mr-4 rounded bg-brand-green2 px-4 py-1 text-neutral-10`;
  const btnDeclineClasses = tw`mr-4 rounded bg-transparent px-4 py-1 text-neutral-10`;

  return (
    <CookieConsent
      enableDeclineButton
      buttonText={t('Accept')}
      declineButtonText={t('Decline')}
      onAccept={onAcceptClicked}
      buttonClasses={btnClasses}
      declineButtonClasses={btnDeclineClasses}
      containerClasses={containerClasses}
      disableButtonStyles
    >
      {t('We use cookies on our website. ')}
      <span className="text-s-regular">
        {t(
          'Some of them are essential, while others help us improve this website and your experience. To learn more, please read our '
        )}
        <a
          href="https://www.combeenation.com/en/data-protection/"
          className="underline hover:no-underline"
          target="_blank"
          rel="noreferrer"
        >
          {t('privacy policy')}
        </a>
        .
      </span>
    </CookieConsent>
  );
};
