import classNames from 'classnames';

export type LoaderSize = 'xsmall' | 'small' | 'medium' | 'big';

const _INFINITE_PERCENTAGE = 60;

const _SIZE_MAP: Record<LoaderSize, { width: number; stroke: number }> = {
  big: { width: 90, stroke: 8 },
  medium: { width: 60, stroke: 4 },
  small: { width: 20, stroke: 2.5 },
  xsmall: { width: 16, stroke: 2 },
};

type PercentageLoaderProps = {
  size: LoaderSize;
  percentage?: number;
  /** Ignores the percentage and spins infinite */
  infinite?: boolean;
  hoverText?: string;
};

export const PercentageLoader: React.FC<PercentageLoaderProps> = ({ size, percentage, infinite, hoverText }) => {
  const sizeMap = _SIZE_MAP[size];
  const offset = sizeMap.width / 2;
  const radius = sizeMap.width / 2 - sizeMap.stroke / 2;
  const circumfence = 2 * radius * Math.PI;

  percentage = percentage ?? 0;
  const p = infinite ? _INFINITE_PERCENTAGE : percentage;
  const drawVal = (p * circumfence) / 100;

  const dashArrayVal = `${drawVal} 999`;

  return (
    <div data-cmptype="PercentageLoader" title={hoverText}>
      <svg
        role="progressbar"
        aria-valuenow={!infinite ? percentage : undefined}
        className={classNames('text-primary-main transition-all ease-in-out', {
          'rotate-[-90deg]': !infinite,
          'animate-spin-slow': infinite,
          'text-neutral-50': infinite,
        })}
        width={sizeMap.width}
        height={sizeMap.width}
        fill="none"
        strokeWidth={sizeMap.stroke}
        strokeLinecap="round"
        strokeDasharray={dashArrayVal}
      >
        <circle cx={offset} cy={offset} r={radius} stroke="currentColor"></circle>
      </svg>
    </div>
  );
};
