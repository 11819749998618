import { InfoIcon, WarningIcon } from 'assets/icons';
import { ReplicatorBlueprintAssetbundleVersionPicker } from 'components/replicator/replicator-blueprint-assetbundle-version-picker';
import { Icon } from 'controls/icon/icon';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectAllBundles } from 'slices/assets/assets.slice';
import { selectCurrentBlueprint } from 'slices/replicator/replicator.slice';

export const ReplicatorBlueprintAssetBundles: React.FC = () => {
  const { t } = useTypedTranslation();
  const blueprint = useAppSelector(selectCurrentBlueprint);

  const availableBundles = useAppSelector(selectAllBundles);

  const multipleVersionsInfoText = t(
    'Different versions of the same bundle are assigned! ' +
      'This will also result in two separate versions at the copied destination.'
  );

  return (
    <>
      {Object.values(blueprint.assetBundles)
        .filter(b => b.selected)
        .map(bundle => {
          const selectedVersions = Object.values(bundle.versions).filter(v => v.selected);
          const bundleRef = availableBundles.find(b => b.id === bundle.name);

          const brokenAssignmentVersions = Object.values(bundle.versions)
            .filter(
              v =>
                !v.selected &&
                Object.values(blueprint.cfgrs).some(cfgr => cfgr.assignedAssetBundles.some(b => b.version === v.id))
            )
            .map(v => v.displayName);

          return (
            <div
              key={bundle.name}
              className="rounded-lg border border-neutral-50 px-6 py-4"
              data-cmptype="ReplicatorBlueprintAssetBundles-item"
            >
              <h3>{bundleRef?.displayName}</h3>
              <div className="flex items-center justify-between gap-4">
                <div>
                  <div className="flex items-center gap-2">
                    <span>
                      {selectedVersions.length > 1 ? t('Versions') : t('Version')}
                      {`: ${selectedVersions.map(v => v.displayName).join(', ')}`}
                    </span>
                    {selectedVersions.length > 1 ? (
                      <Icon Svg={InfoIcon} title={multipleVersionsInfoText} className="w-5 text-primary-main" />
                    ) : selectedVersions.length === 0 ? (
                      <Icon Svg={WarningIcon} title={t('No version selected')} className="w-5 text-warning-main" />
                    ) : undefined}
                  </div>
                  {brokenAssignmentVersions.length > 0 && (
                    <div className="flex items-center gap-2">
                      <span>{t('Broken configurator assignments')}:</span>
                      {brokenAssignmentVersions.join(', ')}
                      <Icon
                        Svg={WarningIcon}
                        className="w-5 text-warning-main"
                        title={t(
                          "The mentioned versions are assigned to a selected configurator. If they aren't copied, the assignments have to be fixed in the configurator manually afterwards."
                        )}
                      />
                    </div>
                  )}
                </div>
                <div className="min-w-[200px]">
                  <ReplicatorBlueprintAssetbundleVersionPicker blueprintAssetBundle={bundle} />
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};
