import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  ReplicationStatus,
  ReplicationStatusResponse,
  getReplicationStatus,
} from 'services/replicator/replicator.service';
import * as PersistenceMiddleware from 'services/store/persistence.middleware';
import { RootState } from 'services/store/store.service';

type NotificationType = 'REPLICATION' | 'GENERIC';
type NotificationUpdateStates = 'POLLING' | 'FINISHING' | 'FINISHED';

type NotificationBase<T extends NotificationType> = {
  uid: string;
  timestamp: number;
  markedAsRead: boolean;
  type: T;
  updateState: NotificationUpdateStates;
};

/**
 * Similar to a `KeyValuePair`
 * - Key: Unique id
 * - Value: Display name
 */
type IdNamePairs = { [id: string]: string };

export type ReplicationNotificationInput = {
  replicationId: string;
  sourceCompany?: string;
  targetCompany: string;
  cfgrIds: IdNamePairs;
  assetBundleIds: IdNamePairs;
  hasAutoPublish?: boolean;
  isCrossEnv?: boolean;
};

export type ReplicationNotification = NotificationBase<'REPLICATION'> & ReplicationNotificationInput;

type Notification = ReplicationNotification | NotificationBase<'GENERIC'>;

export type NotificationsState = {
  notifications: Notification[];
  /** Holds temporary state for notifications which require to fetch data on demand */
  cachedRequests: {
    replications: { [uid: string]: ReplicationStatus };
  };
  ui: {
    isMenuOpen: boolean;
  };
};

const initialState: NotificationsState = {
  notifications: [],
  cachedRequests: {
    replications: {},
  },
  ui: {
    isMenuOpen: false,
  },
};

export function isReplicationNotification(notification: Notification): notification is ReplicationNotification {
  return notification.type === 'REPLICATION';
}

const SLICE_NAME = 'notifications';

PersistenceMiddleware.registerState<NotificationsState, NotificationsState>({
  state: SLICE_NAME,
  key: 'notifications',
  selector: state => state,
  props: ['notifications'],
});

const getRehydratedState = (): NotificationsState => {
  const rehydratedState = PersistenceMiddleware.rehydrateState<NotificationsState>(SLICE_NAME, initialState);
  return rehydratedState;
};

export const updateReplicationStatus = createAsyncThunk<
  ReplicationStatus,
  string,
  { rejectValue: ReplicationStatusResponse | undefined }
>('notifications/updateReplicationStatus', async (uid, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const notification = state.notifications.notifications.find(n => n.uid === uid);
  if (!notification || !isReplicationNotification(notification)) {
    return thunkApi.rejectWithValue(undefined);
  }

  const response = await getReplicationStatus(notification.replicationId, notification.targetCompany);
  if (response.result) {
    return response.result;
  } else {
    return thunkApi.rejectWithValue(response.responseStatus);
  }
});

const notificationsSlice = createSlice({
  name: SLICE_NAME,
  initialState: getRehydratedState,
  reducers: {
    markNotificationAsRead: (state, { payload }: PayloadAction<string>) => {
      const notification = state.notifications.find(n => n.uid === payload);
      if (notification) {
        notification.markedAsRead = true;
      }
    },
    removeNotification: (state, { payload }: PayloadAction<string>) => {
      const idx = state.notifications.findIndex(r => r.uid === payload);
      if (idx > -1) {
        state.notifications.splice(idx, 1);
      }
      if (state.notifications.length === 0) {
        state.ui.isMenuOpen = false;
      }
    },
    addReplicationNotification: (state, { payload }: PayloadAction<ReplicationNotificationInput>) => {
      state.notifications.push({
        type: 'REPLICATION',
        uid: 'replication-' + payload.replicationId,
        timestamp: Date.now(),
        replicationId: payload.replicationId,
        sourceCompany: payload.sourceCompany,
        targetCompany: payload.targetCompany,
        assetBundleIds: payload.assetBundleIds,
        cfgrIds: payload.cfgrIds,
        markedAsRead: false,
        updateState: 'POLLING',
        hasAutoPublish: payload.hasAutoPublish,
        isCrossEnv: payload.isCrossEnv,
      });
    },
    showNotificationMenu: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isMenuOpen = payload;
      if (payload) {
        state.notifications.forEach(n => {
          n.markedAsRead = true;
        });
      }
    },
    setNotificationToFinished: (state, { payload }: PayloadAction<string>) => {
      const notification = state.notifications.find(n => n.uid === payload);
      if (notification) {
        notification.updateState = 'FINISHED';
      }
    },
    clearOldNotifications: state => {
      // clear finished notifications which are older than yesterday
      const date = new Date();
      date.setDate(date.getDate() - 1);
      date.setHours(0, 0, 0);
      const tsBeginningOfYesterday = date.getTime();

      state.notifications = state.notifications.filter(
        n => !(n.markedAsRead && n.updateState === 'FINISHED' && n.timestamp < tsBeginningOfYesterday)
      );
    },
  },
  extraReducers: builder => {
    builder
      .addCase(updateReplicationStatus.fulfilled, (state, { payload, meta }) => {
        const uid = meta.arg;
        state.cachedRequests.replications[uid] = payload;
        if (payload.completedAt) {
          const notification = state.notifications.find(n => n.uid === uid);
          if (notification && notification.updateState !== 'FINISHED') {
            notification.updateState = 'FINISHING';
            notification.markedAsRead = state.ui.isMenuOpen;
          }
        }
      })
      .addCase(updateReplicationStatus.rejected, (state, { payload, meta }) => {
        if (payload === 'EntityNotFound') {
          // ReplicationId is invalid or gone, so we remove it from the notifications
          const uid = meta.arg;
          const idx = state.notifications.findIndex(n => n.uid === uid);
          state.notifications.splice(idx, 1);
        }
      });
  },
});

export const selectReplicatorNotifications = createSelector(
  (state: RootState) => state.notifications.notifications,
  notifications => notifications.filter(n => n.type === 'REPLICATION') as ReplicationNotification[]
);

export const selectNotificationsCount = createSelector(
  (state: RootState) => state.notifications.notifications,
  notifications => notifications.length
);

export const selectUnreadNotificationsCount = createSelector(
  (state: RootState) => state.notifications.notifications,
  notifications => notifications.filter(n => !n.markedAsRead).length
);

export const {
  markNotificationAsRead,
  addReplicationNotification,
  removeNotification,
  showNotificationMenu,
  setNotificationToFinished,
  clearOldNotifications,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
