import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import React, { ErrorInfo, ReactNode } from 'react';
import { GenericErrorBoundaryFallback } from 'components/error-handling/generic-error-boundary-fallback';
import {
  dismissErrorsByDialogId,
  findMatchingErrorDialogs,
} from 'components/error-handling/global-error-dialog-manager';
import { clearRouteInLocalStorage } from 'services/local-storage/ls-route.service';
import { generateErrorGuid } from 'services/store/logger.service';
import { ERROR_BOUNDARY_KEY } from 'services/telemetry/telemetry.service';

/**
 * Returns if the length or an item of the array changed (referential equality)
 */
const _changedArray = (a: unknown[] = [], b: unknown[] = []): boolean =>
  a.length !== b.length || a.some((item, idx) => !Object.is(item, b[idx]));

type ErrorBoundaryProps = {
  appInsights: ApplicationInsights;
  children: ReactNode;
  hideLogoutBtn?: boolean;
  /** The size of the fallback component is set to the window size */
  scaleToWindowSize?: boolean;
  /** Adds a 'Try again' button to the fallback component, which calls the given function */
  onReset?: () => void;
  /**
   * Reset the erroneous state if there are changes in one of the given objects.
   * Similar to a dependency array.
   */
  resetKeys?: unknown[];
};

type ErrorBoundaryState = {
  hasError: boolean;
  error?: Error;
  errorId?: string;
};

const initialState: ErrorBoundaryState = { hasError: false };

/**
 * TODO Upgrade AI: The basic behaviour is taken from `AppInsightsErrorBoundary`
 * Check for changes on major upgrades
 * https://github.com/microsoft/ApplicationInsights-JS -> AppInsightsErrorBoundary.tsx
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = initialState;
  }

  resetErrorBoundary(): void {
    this.props.onReset?.();
    this.setState(initialState);
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps, prevState: ErrorBoundaryState): void {
    const prevKeys = prevProps.resetKeys;
    const currKeys = this.props.resetKeys;

    if (prevState.hasError && _changedArray(prevKeys, currKeys)) {
      this.resetErrorBoundary();
    }
  }

  /**
   * Always send to tracker with the indicator that this is coming from the ErrorBoundary
   * Optionally links and hides already shown dialogs to prevent duplicate errors shown to the user
   */
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // shown error dialogs can't be identified better,
    // because they are reported in two different places (window.onerror & ErrorBoundary)
    const matchingErrorDialogs = findMatchingErrorDialogs(error);
    const matchingErrorDialoIds = matchingErrorDialogs.map(e => e.errorId);
    const errorGuid = generateErrorGuid();

    // Keep 'in sync' with AI code
    // see comment above -> TODO Upgrade AI
    this.props.appInsights.trackException(
      {
        error: error,
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: errorInfo,
        id: errorGuid,
      },
      { [ERROR_BOUNDARY_KEY]: true, linkedErrorIds: matchingErrorDialoIds.toString() }
    );
    this.setState({ errorId: errorGuid });

    dismissErrorsByDialogId(matchingErrorDialogs.map(e => e.dialogId));

    // clear the stored route so the user doesn't get redirected back to this "broken path",
    // if he simply clears the url in the address bar
    clearRouteInLocalStorage();
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <GenericErrorBoundaryFallback
          error={this.state.error!}
          errorId={this.state.errorId}
          scaleToWindowSize={this.props.scaleToWindowSize}
          hideLogoutBtn={this.props.hideLogoutBtn}
          resetErrorBoundary={this.props.onReset ? this.resetErrorBoundary.bind(this) : undefined}
        />
      );
    }

    return this.props.children;
  }
}
