import { editor } from 'monaco-editor';
import { useCallback, useEffect, useRef, useState } from 'react';
import { unstable_usePrompt } from 'react-router-dom';
import {
  ApiIcon,
  DeleteIcon,
  EditIcon,
  ExecuteIcon,
  LogIcon,
  ManualIcon,
  RefreshIcon,
  SaveIcon,
  ScheduledIcon,
} from 'assets/icons';
import { WorkflowCodeEditor } from 'components/workflows/details/workflow-code-editor';
import { WorkflowRunStateIcon } from 'components/workflows/details/workflow-run-state-icon';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { Icon, SvgComponent } from 'controls/icon/icon';
import { LoadMask } from 'controls/load-mask/load-mask';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'controls/table';
import { WorkflowRunStates } from 'generated/workflow-run-states';
import { WorkflowRunTypes } from 'generated/workflow-run-types';
import { WorkflowStates } from 'generated/workflow-states';
import { cn } from 'helper/css.helper';
import {
  getFormattedDateAndTime,
  getFormattedDateAndTimeFromSeconds,
  getFormattedTimeFromSeconds,
} from 'helper/date-and-time/date-and-time.helper';
import { getIfETagChanged } from 'helper/http/http-get.helper';
import { isSasUriExpired } from 'helper/sas-uri/sas-uri.helper';
import { useCronScheduler } from 'hooks/common/cron-expression.hooks';
import { useAlertDialog, useConfirmDialog } from 'hooks/common/dialog.hooks';
import { DefaultTranslationFn, useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { useNavigateToWorkflows } from 'hooks/workflows/workflows.hooks';
import { isGenericActionResult } from 'services/http/http.service';
import { buildWorkflowsPath } from 'services/routes/workflows-routes.service';
import { WorkflowRun, postStartWorkflow } from 'services/workflows/workflows.service';
import { CompanyPermissions } from 'slices/permission/permission.slice';
import {
  deleteWorkflow,
  getAllWorkflowRuns,
  getWorkflow,
  getWorkflowRun,
  selectSelectedWorkflow,
  selectSelectedWorkflowsPath,
  selectWorkflowRunOfSelectedWorkflow,
  updateWorkflowCode,
} from 'slices/workflows/workflows.slice';

const _RUNTYPE: Record<WorkflowRunTypes, { Icon: SvgComponent; Title: string }> = {
  [WorkflowRunTypes.Manual]: { Icon: ManualIcon, Title: 'Manual execution' },
  [WorkflowRunTypes.Scheduled]: { Icon: ScheduledIcon, Title: 'Scheduled execution' },
  [WorkflowRunTypes.Api]: { Icon: ApiIcon, Title: 'API execution' },
};

export const WorkflowDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();
  const confirm = useConfirmDialog();
  const alert = useAlertDialog();
  const navigateToWorkflows = useNavigateToWorkflows();
  const previousCodeETag = useRef('');

  // this page is only shown when a workflow is selected, so `selectSelectedWorkflow` returns a valid workflow for sure
  const {
    companyId,
    workflowName,
    displayName,
    blobContentSasUri,
    cronExpression,
    state: workflowState,
    parseError,
    parameters,
  } = useAppSelector(selectSelectedWorkflow)!;
  const workflowPath = useAppSelector(selectSelectedWorkflowsPath);
  const workflowRuns = useAppSelector(selectWorkflowRunOfSelectedWorkflow);
  const workflowRunsSorted = [...workflowRuns].sort(_sortStartedAt);

  const [lastFetchedCode, setLastFetchedCode] = useState('');
  const [codeContent, setCodeContent] = useState('');
  const [codeSavePending, setCodeSavePending] = useState(false);
  const [codeFetchPending, setCodeFetchPending] = useState(false);
  const [runsFetchPending, setRunsFetchPending] = useState(false);

  const hasWorkflowsManagePermission = usePermission(CompanyPermissions.ManageWorkflows, companyId);

  const codeHasChanged = lastFetchedCode !== codeContent;
  const runningWfRun = workflowRuns.find(r => r.state === WorkflowRunStates.Running);

  const [nextScheduledRun] = useCronScheduler(cronExpression);
  unstable_usePrompt({ when: codeHasChanged, message: t('Discard unsaved changes?') });

  // re-fetch the code whenever sas uri changes
  // currently this is only done when the selected workflow changes
  useEffect(() => {
    const fetchCode = async (): Promise<void> => {
      if (blobContentSasUri) {
        setCodeFetchPending(true);

        if (isSasUriExpired(blobContentSasUri)) {
          // re-fetch a new SAS URI, as the current one has timed out
          dispatch(getWorkflow({ companyId: workflowPath.companyId, workflowName: workflowPath.workflowName }));
        } else {
          const response = await getIfETagChanged<string>(blobContentSasUri, previousCodeETag.current);

          if (response) {
            previousCodeETag.current = response.headers['etag'];
            const fetchedCode = response.data;

            setLastFetchedCode(fetchedCode);
            setCodeContent(fetchedCode);
          }
          setCodeFetchPending(false);
        }
      }
    };

    fetchCode();
  }, [blobContentSasUri, dispatch, workflowPath]);

  const fetchRuns = useCallback(async () => {
    setRunsFetchPending(true);
    await dispatch(getAllWorkflowRuns({ companyId, workflowName }));
    setRunsFetchPending(false);
  }, [dispatch, companyId, workflowName]);

  useEffect(
    function fetchRunsIfCronTimePassed() {
      fetchRuns();
    },
    [nextScheduledRun, fetchRuns]
  );

  useEffect(
    function runningWfRunPolling() {
      let intervalId = 0;
      if (runningWfRun) {
        intervalId = window.setInterval(() => {
          dispatch(getWorkflowRun({ companyId, workflowName, workflowRunId: runningWfRun.workflowRunId }));
        }, 5000);
      }
      return () => window.clearInterval(intervalId);
    },
    [runningWfRun, companyId, workflowName, dispatch]
  );

  const onExecuteBtnClicked = async (): Promise<void> => {
    setRunsFetchPending(true);

    const result = await postStartWorkflow(companyId, workflowName);
    if (isGenericActionResult(result)) {
      await alert(result.Message ?? result.Id, { variant: 'Error' });
    } else {
      await dispatch(getWorkflowRun({ companyId, workflowName, workflowRunId: result }));
      navigateToWorkflows({ companyId, workflowName, workflowRunId: result }, 'run');
    }

    setRunsFetchPending(false);
  };

  const onDeleteBtnClicked = async (): Promise<void> => {
    const deleteConfirmed = await confirm(t(`Delete the workflow "${displayName}"?\nThis action is irreversible.`), {
      confirmBtnText: t('Delete workflow'),
      variant: 'Danger',
    });

    if (deleteConfirmed) {
      await dispatch(deleteWorkflow({ companyId, workflowName }));

      // navigate one step up, since no workflow is selected anymore
      navigateToWorkflows({ companyId });
    }
  };

  const onResetCodeBtnClicked = (): void => {
    setCodeContent(lastFetchedCode);
  };

  const onSaveCode = async (): Promise<void> => {
    if (codeHasChanged) {
      setCodeSavePending(true);
      await dispatch(updateWorkflowCode({ companyId, workflowName, codeContent }));
      // also store the code locally
      setLastFetchedCode(codeContent);
      setCodeSavePending(false);
    }
  };

  const onCodeChanged = (newValue: string | undefined, ev: editor.IModelContentChangedEvent): void => {
    setCodeContent(newValue ?? '');
  };

  const onRefreshRunsClicked = async (): Promise<void> => {
    fetchRuns();
  };

  const preventExecute = codeHasChanged || workflowState === WorkflowStates.NotReady;
  const executeTooltip = codeHasChanged
    ? t('Please save or discard your changes first!')
    : workflowState === WorkflowStates.NotReady
      ? t('The workflow is not ready to be executed, please review its code!')
      : '';

  // simple user feedback when reloading
  return (
    <CbnCard data-cmptype="WorkflowDetails">
      <CbnCardHeader
        title={displayName}
        subText={t('Manage the workflow and review its recent runs.')}
        variant="large-title"
        actions={
          !!nextScheduledRun && (
            <div className="flex grow items-center justify-end gap-2">
              <Icon Svg={ScheduledIcon} className="h-6" />
              <div>
                <div className="text-l-medium">{t('Scheduled workflow')}</div>
                <div className="text-l-regular">
                  {t('Next run')}: {getFormattedDateAndTime(nextScheduledRun)}
                </div>
              </div>
            </div>
          )
        }
      />

      <div data-cmptype="WorkflowDetails-actions" className="flex flex-col gap-3 border-b border-neutral-40 px-6 py-2">
        <div className="flex gap-2">
          <Button
            text={t('Execute')}
            variant="Secondary"
            Svg={ExecuteIcon}
            onClick={onExecuteBtnClicked}
            disabled={preventExecute}
            title={executeTooltip}
          />

          <LinkButton
            text={hasWorkflowsManagePermission ? t('Edit config') : t('View config')}
            variant="Secondary"
            Svg={hasWorkflowsManagePermission ? EditIcon : undefined}
            href={buildWorkflowsPath(companyId, workflowName, 'config')}
          />
          {hasWorkflowsManagePermission && (
            <Button
              text={t('Delete')}
              variant="Text"
              hoverColor="Danger"
              Svg={DeleteIcon}
              onClick={onDeleteBtnClicked}
            />
          )}
        </div>
      </div>

      <section data-cmptype="WorkflowDetails-codeEditor" className="flex grow flex-col gap-4 py-4">
        <div className="flex grow">
          <LoadMask active={codeFetchPending} spinner transparent="semi">
            <WorkflowCodeEditor
              value={codeContent}
              onChange={onCodeChanged}
              parseError={!codeHasChanged ? parseError : undefined}
              wfParameters={parameters}
              readOnly={!hasWorkflowsManagePermission}
              onKeyboardSave={onSaveCode}
            />
          </LoadMask>
        </div>

        {hasWorkflowsManagePermission && (
          <div className="flex items-center gap-2 px-6">
            <Button
              variant="Secondary"
              text={t('Discard')}
              onClick={onResetCodeBtnClicked}
              disabled={!codeHasChanged || codeSavePending}
            />
            <Button
              text={t('Save')}
              Svg={SaveIcon}
              onClick={onSaveCode}
              disabled={!codeHasChanged}
              isLoading={codeSavePending}
            />
          </div>
        )}
      </section>

      <section data-cmptype="WorkflowDetails-runsTable" className="flex h-[208px] flex-col">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width={50}>
                <div className={cn('flex items-center justify-center', runsFetchPending && 'animate-spin')}>
                  <Icon
                    Svg={RefreshIcon}
                    title={t('Refresh')}
                    className="h-4 w-4 cursor-pointer"
                    onClick={onRefreshRunsClicked}
                  />
                </div>
              </TableCell>
              <TableCell width={50}>{t('Type')}</TableCell>
              <TableCell>{t('Id')}</TableCell>
              <TableCell>{t('Started')}</TableCell>
              <TableCell>{t('Duration')}</TableCell>
              <TableCell width={50}>{t('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={cn('transition-opacity', runsFetchPending && 'opacity-20')}>
            {workflowRunsSorted.map(wfRun => (
              <TableRow key={wfRun.workflowRunId}>
                <TableCell>
                  <WorkflowRunStateIcon state={wfRun.state} />
                </TableCell>
                <TableCell>
                  {_RUNTYPE[wfRun.type] ? (
                    <Icon Svg={_RUNTYPE[wfRun.type].Icon} title={t(_RUNTYPE[wfRun.type].Title)} className="h-6" />
                  ) : (
                    <span title={t('Unkown run type')}>?</span>
                  )}
                </TableCell>
                <TableCell>{wfRun.workflowRunId}</TableCell>
                <TableCell>{_getStartedAtDisplayString(wfRun, t)}</TableCell>
                <TableCell>{getFormattedTimeFromSeconds(wfRun.finishedAt - wfRun.startedAt)}</TableCell>
                <TableCell>
                  {
                    <LinkButton
                      Svg={LogIcon}
                      variant={'Text'}
                      href={buildWorkflowsPath(wfRun.companyId, wfRun.workflowName, 'run', wfRun.workflowRunId)}
                      title={t('Open log')}
                    />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </section>
    </CbnCard>
  );
};

function _getStartedAtDisplayString(wfRun: WorkflowRun, t: DefaultTranslationFn): string {
  const isFutureDate = wfRun.startedAt * 1000 > Date.now();
  const isFutureScheduledRun = wfRun.type === WorkflowRunTypes.Scheduled && isFutureDate;

  return (isFutureScheduledRun ? t('Scheduled for ') : '') + getFormattedDateAndTimeFromSeconds(wfRun.startedAt);
}

function _sortStartedAt(a: WorkflowRun, b: WorkflowRun): number {
  return a.startedAt > b.startedAt ? -1 : 1;
}
