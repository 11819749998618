import { ExpandedIcon } from 'assets/icons';
import { useCbnCard } from 'controls/cbn-card/cbn-card-provider';
import { Icon, SvgComponent } from 'controls/icon/icon';
import { cn } from 'helper/css.helper';

type CbnCollapsibleCardHeaderBaseProps = {
  title: string;
  subText?: string;
  /** `true` to not react on clicks (i.e. don't toggle expanded/collapsed) */
  disabled?: boolean;
};

type CbnCollapsibleCardHeaderLargeProps = CbnCollapsibleCardHeaderBaseProps & {
  size?: 'large';
  icon?: SvgComponent;
  /** Active icons have a different color (`neutral-50`) then inactive ones (`brand-green2`) */
  iconActive?: boolean;
};

type CbnCollapsibleCardHeaderSmallAndMediumProps = CbnCollapsibleCardHeaderBaseProps & {
  size?: 'medium' | 'small';
  icon?: never;
  iconActive?: never;
};

type CbnCollapsibleCardHeaderProps = CbnCollapsibleCardHeaderLargeProps | CbnCollapsibleCardHeaderSmallAndMediumProps;

/**
 * Usually used in conjunction with `CbnCard` to get default card spacings etc.
 * Also provides a line at the bottom to separate card header from content.
 *
 * See {@link CbnCard} for example usage.
 */
export const CbnCollapsibleCardHeader: React.FC<CbnCollapsibleCardHeaderProps> = ({
  title,
  subText,
  size = 'large',
  disabled = false,
  icon,
  iconActive = false,
}) => {
  const { collapsed, setCollapsed } = useCbnCard();
  const isSmallOrMedium = size === 'small' || size === 'medium';

  return (
    <div
      data-cmptype="CbnCollapsibleCardHeader"
      className={cn(
        'flex cursor-pointer flex-row items-center gap-3 border-b border-neutral-40 px-6 py-5',
        collapsed && 'border-b-0',
        disabled && 'cursor-default',
        size === 'medium' && 'px-6 py-5',
        size === 'small' && 'px-5 py-4'
      )}
      onClick={(): void => {
        !disabled && setCollapsed(!collapsed);
      }}
    >
      {icon && <Icon Svg={icon} className={cn('mr-1 size-8 text-neutral-50', iconActive && 'text-brand-green2')} />}

      <span
        className={cn(
          'text-l-medium text-neutral-100',
          isSmallOrMedium && 'text-m-medium',
          disabled && 'text-neutral-60'
        )}
      >
        {title}
      </span>

      <span
        className={cn(
          'grow text-m-medium text-neutral-70',
          isSmallOrMedium && 'text-s-medium',
          disabled && 'text-neutral-60'
        )}
      >
        {subText}
      </span>

      <ExpandedIcon
        className={cn('size-6 transition-transform', !collapsed && 'rotate-180', disabled && 'text-neutral-60')}
      />
    </div>
  );
};
