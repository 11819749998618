import { useEffect, useRef, useState } from 'react';
import { CheckIcon, PlusIcon } from 'assets/icons';
import { useCompanySettingsStaff } from 'components/company/company-settings-staff-provider';
import { resetFieldValuesByType } from 'components/company/user-custom-fields/user-custom-field.helper';
import { UserCustomFieldsInput } from 'components/company/user-custom-fields/user-custom-fields-input';
import { Button } from 'controls/button/button';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnPopover } from 'controls/cbn-popover/cbn-popover';
import { FormItem } from 'controls/form-item/form-item';
import { StateIcon } from 'controls/icon/state-icon';
import { TextInput } from 'controls/input/text-input';
import { Select, SelectValue } from 'controls/select/select';
import { isValidHiveIdentifier } from 'helper/hive/hive.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { createUserCustomField } from 'services/companies/companies-user-custom-fields';
import {
  UserCustomFieldRequest,
  UserCustomFieldType,
  userCustomFieldTypes,
} from 'services/http/endpoints/companies.endpoints';

export const UserCustomFieldsNewButton: React.FC = () => {
  const { t } = useTypedTranslation();
  const { companyId, customFields, fetchCustomFields, maxUserCustomFields } = useCompanySettingsStaff();
  const nameInputRef = useRef<HTMLInputElement>(null);

  const btnRef = useRef<HTMLDivElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [fieldData, setFieldData] = useState<UserCustomFieldRequest>({
    companyName: companyId,
    type: 'Text',
    anonymous: '',
    default: '',
    name: '',
  });

  useEffect(() => {
    if (popoverOpen) {
      window.setTimeout(() => {
        nameInputRef.current?.focus();
      }, 0);
    }
  }, [popoverOpen]);

  const togglePopover = (): void => {
    setPopoverOpen(x => !x);
  };

  const onTypeChange = (val: SelectValue): void => {
    setFieldData(oldData => {
      const newData = { ...oldData, type: val as UserCustomFieldType };
      resetFieldValuesByType(newData);
      return newData;
    });
  };

  const onSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    await createUserCustomField(fieldData);
    await fetchCustomFields();
    setPopoverOpen(false);
  };

  const isNameDuplicate = !!customFields[fieldData.name];
  const hasInvalidInput = !isValidHiveIdentifier(fieldData.name) || isNameDuplicate;
  const maxFieldsReached = Object.keys(customFields).length >= maxUserCustomFields;

  return (
    <>
      <div data-cmptype="UserCustomFieldsNewButton" ref={btnRef}>
        <Button
          text={t('New user field')}
          Svg={PlusIcon}
          variant="Outlined"
          onClick={togglePopover}
          disabled={maxFieldsReached}
          title={
            maxFieldsReached
              ? 'The maximum number of custom fields for your subscription plan has been reached.'
              : undefined
          }
        />
      </div>
      <CbnPopover
        open={popoverOpen}
        anchorEl={btnRef.current}
        data-cmptype="UserCustomFieldsNewButton-popover"
        onClose={(): void => togglePopover()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <CbnCardBody>
          <form onSubmit={onSubmit} autoFocus>
            <div className="flex w-[330px] flex-col gap-4">
              <span className="text-m-regular">
                {t(
                  'Add a custom user field to assign values to users. The values are accessible in all configurators of the company.'
                )}
              </span>
              <div className="flex flex-col gap-5">
                <FormItem
                  labelContent={t('User field name')}
                  subLabelContent={t(
                    'unique name, used as identifier in the Configurator Editor - user.fields.MyUserField'
                  )}
                >
                  <TextInput
                    placeholder={t('MyUserField')}
                    value={fieldData.name}
                    onValueChange={(val): void => setFieldData(oldData => ({ ...oldData, name: val }))}
                    inputRef={nameInputRef}
                    error={isNameDuplicate}
                    endAdornment={
                      isNameDuplicate && <StateIcon variant="Error" noBckgr title={'Name already in use!'} />
                    }
                  />
                </FormItem>
                <FormItem labelContent={t('Type')}>
                  <Select
                    options={userCustomFieldTypes.map(c => ({ value: c }))}
                    value={fieldData.type}
                    onChange={onTypeChange}
                  />
                </FormItem>
                <FormItem
                  labelContent={t('Default value')}
                  subLabelContent={t('used for users without an explicitly set value')}
                >
                  <UserCustomFieldsInput
                    fieldType={fieldData.type}
                    value={fieldData.default}
                    onChange={(val): void => setFieldData(oldData => ({ ...oldData, default: val }))}
                  />
                </FormItem>
                <FormItem labelContent={t('Anonymous value')} subLabelContent={t('used for non identified users')}>
                  <UserCustomFieldsInput
                    fieldType={fieldData.type}
                    value={fieldData.anonymous}
                    onChange={(val): void => setFieldData(oldData => ({ ...oldData, anonymous: val }))}
                  />
                </FormItem>
              </div>
            </div>
            <div className="mt-5 flex gap-2">
              <Button variant="Outlined" text={t('Cancel')} onClick={togglePopover} />
              <div className="flex flex-1">
                <Button
                  variant="Primary"
                  text={t('Create user field')}
                  grow
                  Svg={CheckIcon}
                  type="submit"
                  disabled={hasInvalidInput}
                />
              </div>
            </div>
          </form>
        </CbnCardBody>
      </CbnPopover>
    </>
  );
};
