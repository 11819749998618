import React from 'react';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { Icon, SvgComponent } from 'controls/icon/icon';

type CbnInfoCardProps = { icon?: SvgComponent; title: string; subText: string; actions?: React.ReactNode };

export const CbnInfoCard: React.FC<CbnInfoCardProps> = ({ icon, title, subText, actions }) => {
  return (
    <CbnCard data-cmptype="CbnInfoCard" variant="nested">
      <div className="flex grow flex-col justify-between gap-2 p-3 pt-5">
        <div className="flex grow flex-col gap-1">
          <div className="flex flex-row items-center gap-2">
            {icon && <Icon Svg={icon} className="w-6" />}
            <span className="text-l-medium text-neutral-100">{title}</span>
          </div>
          {<span className="text-m-regular text-neutral-70">{subText}</span>}
        </div>
        {actions && <div className="flex flex-row-reverse">{actions}</div>}
      </div>
    </CbnCard>
  );
};
