import { COMPANYID_ROUTE_PARAMS, CompanyIdRouteParams } from 'services/routes/common-routeparams.service';
import { buildPath } from 'services/routes/routes.service';

export const COMPANY_SETTINGS_BASE_PATH = '/companysettings';

export type CompanySettingsRouteParams = CompanyIdRouteParams;

export const COMPANY_SETTINGS_PATH_VARIANTS = {
  companySettings: `${COMPANY_SETTINGS_BASE_PATH}/:${COMPANYID_ROUTE_PARAMS.companyId}`,
};

export function buildCompanySettingsPath(companyId: string): string {
  return buildPath(COMPANY_SETTINGS_BASE_PATH, companyId);
}
