import { NumberOrNull } from 'controls/input/number-input';
import { DataSourceColumnConfigDto, DataSourceConfigDto } from 'services/assets/assets.service';

export type DSConfigAction =
  | { type: 'CHANGE_SHEET_NAME'; payload: string }
  | { type: 'CHANGE_HEADER_ROW'; payload: NumberOrNull }
  | { type: 'CHANGE_START_ROW'; payload: NumberOrNull }
  | { type: 'CHANGE_LOCALE'; payload: string }
  | { type: 'CHANGE_SEPARATOR'; payload: string }
  | { type: 'CHANGE_SKIP_FIRST_ROW'; payload: boolean }
  | { type: 'CHANGE_STRICT_READ_MODE'; payload: boolean }
  | { type: 'CHANGE_LIST_SEPARATOR'; payload: string }
  | { type: 'CHANGE_TRUE_VALUES'; payload: string[] }
  | { type: 'CHANGE_FALSE_VALUES'; payload: string[] }
  | { type: 'UPDATE_COLUMNS_CONFIG'; payload: DataSourceColumnConfigDto[] };

export function importConfigReducer(state: DataSourceConfigDto, action: DSConfigAction): DataSourceConfigDto {
  switch (action.type) {
    // "Top level" properties
    case 'CHANGE_SHEET_NAME':
      return { ...state, sheetName: action.payload };

    case 'CHANGE_HEADER_ROW':
      return { ...state, headerRow: action.payload ?? undefined };

    case 'CHANGE_START_ROW':
      return { ...state, startRow: action.payload ?? undefined };

    case 'CHANGE_LOCALE':
      return { ...state, locale: action.payload };

    case 'CHANGE_SEPARATOR':
      return { ...state, separator: action.payload };

    case 'CHANGE_SKIP_FIRST_ROW':
      return { ...state, skipFirstRow: action.payload };

    // "Defaults" properties
    case 'CHANGE_STRICT_READ_MODE':
      return { ...state, defaults: { ...state.defaults, strictReadMode: action.payload } };

    case 'CHANGE_LIST_SEPARATOR':
      return { ...state, defaults: { ...state.defaults, listSeparator: action.payload } };

    case 'CHANGE_TRUE_VALUES':
      return { ...state, defaults: { ...state.defaults, boolTrueValues: action.payload } };

    case 'CHANGE_FALSE_VALUES':
      return { ...state, defaults: { ...state.defaults, boolFalseValues: action.payload } };

    case 'UPDATE_COLUMNS_CONFIG': {
      return { ...state, columnConfigs: action.payload };
    }

    default:
      return state;
  }
}
