import { LockIcon, TrashIcon } from 'assets/icons';
import { IdentityUser } from 'components/identity/identity-user';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { Icon } from 'controls/icon/icon';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'controls/table';
import { TableSkeletonRows } from 'controls/table/table-skeleton-rows';
import { useMinTimeActive } from 'hooks/common/timing.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch } from 'hooks/store/store.hooks';
import { buildAssetEditorPath } from 'services/routes/asset-editor-routes.service';
import { AssetBundleVersionDraft, setShowManageBundleVersionsPage } from 'slices/assets/assets.slice';

type AssetBundleVersionsTableDraftsProps = {
  draftVersions: AssetBundleVersionDraft[];
  isLoading: boolean;
  isLastVersion: boolean;
  onDeleteDraftVersionClicked: (draftToDelete: AssetBundleVersionDraft) => Promise<void>;
};

export const AssetBundleVersionsTableDrafts: React.FC<AssetBundleVersionsTableDraftsProps> = ({
  draftVersions,
  isLoading,
  isLastVersion,
  onDeleteDraftVersionClicked,
}) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const showLoadingSkeletons = useMinTimeActive(isLoading, 500);

  const hasNoVersions = !draftVersions.length;

  const onGoToVersionClicked = (): void => {
    dispatch(setShowManageBundleVersionsPage(false));
  };

  return (
    <div data-cmptype="AssetBundleVersionsTableDrafts" className="flex w-full flex-col overflow-y-auto">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{t('Version')}</TableCell>
            <TableCell width={200}>{t('created at')}</TableCell>
            <TableCell width={250}>{t('created by')}</TableCell>
            <TableCell width={200}>{t('last edited at')}</TableCell>
            <TableCell width={250}>{t('last edited by')}</TableCell>
            <TableCell className="w-0"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {showLoadingSkeletons && <TableSkeletonRows numberOfCols={5} numberOfRows={draftVersions.length} />}

            {!showLoadingSkeletons && (
              <>
                {draftVersions.map(draftVersion => (
                  <TableRow key={draftVersion.id}>
                    <TableCell>
                      <div className="flex flex-row items-center gap-1">
                        <LinkButton
                          variant="TextInline"
                          text={draftVersion.displayName + ` - ${t('Draft')}`}
                          href={buildAssetEditorPath(draftVersion.companyId, draftVersion.bundleId, draftVersion.id)}
                          title={t('Go to version')}
                          onClick={onGoToVersionClicked}
                        />
                        {!!draftVersion.lockStatus && (
                          <Icon Svg={LockIcon} className="h-5 text-warning-main" title={t('Version is locked')} />
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{<RelativeDateTime unixTime={draftVersion.createdAt} />}</TableCell>
                    <TableCell>{<IdentityUser userId={draftVersion.createdBy} />}</TableCell>
                    <TableCell>{<RelativeDateTime unixTime={draftVersion.lastEditedAt} />}</TableCell>
                    <TableCell>{<IdentityUser userId={draftVersion.lastEditedBy} />}</TableCell>
                    <TableCell>
                      <Button
                        variant="TextInline"
                        Svg={TrashIcon}
                        onClick={(): Promise<void> => onDeleteDraftVersionClicked(draftVersion)}
                        disabled={!!draftVersion.lockStatus || isLastVersion}
                        title={
                          draftVersion.lockStatus
                            ? t("Locked bundle versions can't be deleted")
                            : isLastVersion
                              ? t("Can't delete last version of bundle")
                              : ''
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}

                {hasNoVersions && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <span className="my-10 flex justify-center text-m-regular text-neutral-90">
                        {t('No bundle versions found')}
                      </span>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </>
        </TableBody>
      </Table>
    </div>
  );
};
