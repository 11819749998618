import { AssetMetadata } from 'components/asset-editor/details/asset-metadata';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { PercentageLoader } from 'controls/loader/percentage-loader';
import { AssetTypes } from 'generated/asset-types';
import { UploadStats } from 'helper/assets/assets.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectSelectedAsset } from 'slices/assets/assets.slice';

type AssetBodyUploadingProps = {
  uploadStats: UploadStats;
};

export const AssetBodyUploading: React.FC<AssetBodyUploadingProps> = ({ uploadStats }) => {
  const { t } = useTypedTranslation();
  const selectedAsset = useAppSelector(selectSelectedAsset)!;

  return (
    <CbnCard>
      <CbnCardHeader title={<h4>{t('Uploading...')}</h4>} />
      <AssetMetadata
        assetType={selectedAsset.type}
        metadata={[
          { text: t('Type'), value: AssetTypes[selectedAsset.type] },
          { text: t('Upload progress'), value: `${Math.round(uploadStats.uploadPercentage)}%` },
          { text: t('State'), value: uploadStats.uploadText },
        ]}
      />

      <CbnCardBody>
        <PercentageLoader percentage={uploadStats.uploadPercentage} infinite={uploadStats.uploadInfinite} size="big" />
      </CbnCardBody>
    </CbnCard>
  );
};
