import { Icon, SvgComponent } from 'controls/icon/icon';

export type MetricValueProps = {
  label: string;
  value: string;
  Svg: SvgComponent;
};

export const MetricValue: React.FC<MetricValueProps> = ({ label, value, Svg }) => {
  return (
    <div className="flex flex-col items-center justify-evenly">
      <div className="flex flex-row justify-evenly gap-2">
        <Icon className="w-6 text-brand-green2" Svg={Svg}></Icon>
        <span>{label}</span>
      </div>
      <span className="text-heading-s font-semibold">{value}</span>
    </div>
  );
};
