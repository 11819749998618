import { styled } from '@mui/material';
import TooltipMui, { TooltipProps as TooltipMuiProps, tooltipClasses } from '@mui/material/Tooltip';
import { ELEVATION_SHADOW, NAMED_SHADOW } from 'styles/mui-theme';
import { cn } from 'helper/css.helper';
import { elementTypeAcceptingRef } from 'helper/mui/mui.helper';

export type TooltipProps = Pick<TooltipMuiProps, 'placement' | 'arrow' | 'children' | 'title'> & {
  /**
   * - 100: small delay so it doesn't show up when the user moves across it (unintentionally)
   * - 500: Use only for elements which don't require explicit information to describe its purpose
   *
   * See also {@link https://www.nngroup.com/articles/timing-exposing-content/}
   * @default 100 // same as MUI `enterDelay`
   */
  delay?: 100 | 500;
  /** If true, the tooltip won't vanish when the user moves over it (e.g. to support text selection) */
  interactive?: boolean;
  childGrow?: boolean;
};

export type ForwardableTooltipProps = Omit<TooltipProps, 'title' | 'children'>;

const StyledTooltip = styled(({ className, ...props }: TooltipMuiProps) => (
  <TooltipMui {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.neutral[10],
    color: theme.palette.neutral[100],
    borderRadius: theme.shape.borderRadiusRoundedLg,
    fontSize: theme.typography.sMedium.fontSize,
    lineHeight: theme.typography.sMedium.lineHeight,
    fontWeight: theme.typography.sMedium.fontWeight,
    padding: theme.spacing(2, 3),
    boxShadow: theme.shadows[ELEVATION_SHADOW.LG],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.neutral[10],
  },

  [`& .${tooltipClasses.tooltipArrow}.${tooltipClasses.tooltipPlacementBottom}`]: {
    boxShadow: theme.shadows[NAMED_SHADOW.TooltipInverted],
  },
}));

export const Tooltip: React.FC<TooltipProps> = ({
  delay = 100,
  children,
  interactive = false,
  childGrow,
  ...props
}) => {
  // without a ref the tooltip can't find an `anchorEl` & disabled elements aren't allowed either
  // Known issue: When the wrapping changes while an tooltip is active a warning gets logged
  //              E.g.: Button with tooltip gets clicked and disables afterwards -> `anchorEl` changes -> warning
  const wrapInSpan = !elementTypeAcceptingRef(children) || children.props.disabled || children.props['aria-disabled'];

  return (
    <StyledTooltip {...props} disableInteractive={!interactive} enterDelay={delay}>
      {/**
       * `span` might alter the layout!
       * Flex display, `h-fit` and `w-fit` are small fixes for that, but not tested thoroughly
       * */}
      {wrapInSpan ? (
        <span data-cmptype="TooltipWrapper" className={cn('flex h-fit w-fit', childGrow && 'grow')}>
          {children}
        </span>
      ) : (
        children
      )}
    </StyledTooltip>
  );
};
