import type { editor } from 'monaco-editor';
import { useEffect, useState } from 'react';
import { MonacoEditor } from 'controls/monaco-editor/monaco-editor';
import { MonacoEditorFactoryProps } from 'controls/monaco-editor/monaco-editor-factory';
import { WORKFLOWS_JINT_DEFINITIONS } from 'generated/workflow-code-editor-jint-defs';
import { WorkflowParameterTypes } from 'generated/workflow-parameter-types';
import { tw } from 'helper/css.helper';
import {
  BundleWorkflowParameter,
  FileWorkflowParameter,
  SimpleWorkflowParameter,
  WorkflowParameters,
  WorkflowParseErrorDto,
} from 'services/workflows/workflows.service';

export type WorkflowCodeEditorProps = Pick<MonacoEditorFactoryProps, 'value' | 'onChange' | 'onKeyboardSave'> & {
  parseError?: WorkflowParseErrorDto;
  wfParameters?: WorkflowParameters;
  readOnly?: boolean;
};

export const WorkflowCodeEditor: React.FC<WorkflowCodeEditorProps> = ({
  value,
  onChange,
  parseError,
  wfParameters,
  readOnly = false,
  onKeyboardSave,
}) => {
  const [editorDecorations, setEditorDecorations] = useState<editor.IModelDeltaDecoration[]>([]);
  const [editorMarkers, setEditorMarkers] = useState<editor.IMarkerData[]>([]);
  const [editorExtraLibs, setEditorExtraLibs] = useState<MonacoEditorFactoryProps['extraLibs']>();

  useEffect(() => {
    if (value?.length === 0 || !parseError) {
      setEditorDecorations([]);
      setEditorMarkers([]);
      return;
    }

    setEditorDecorations([
      {
        range: { startLineNumber: parseError.line, startColumn: 0, endLineNumber: parseError.line, endColumn: 0 },
        options: {
          isWholeLine: true,
          className: tw`bg-danger-surface`,
          hoverMessage: { value: parseError.message },
        },
      },
    ]);

    setEditorMarkers([
      {
        message: parseError.message,
        severity: 8, // monaco.MarkerSeverity.Error
        startLineNumber: parseError.line,
        startColumn: parseError.column,
        endLineNumber: parseError.line,
        endColumn: -1,
      },
    ]);
  }, [parseError, value]);

  /**
   * Create declarations for workflow parameters
   * @example
   * declare module '@workflow/parameters' {
   *   export const myStrParam = 'myParamStrValue';
   *   export const myBoolParam = false;
   * }
   */
  useEffect(() => {
    if (!wfParameters) {
      setEditorExtraLibs([]);
      return;
    }

    const paramDef = Object.entries(wfParameters).reduce<string[]>((acc, [key, param]) => {
      acc.push('  ' + _PARAM_TO_DEFINITION_CONVERSION[param.type](key, param));
      return acc;
    }, []);

    setEditorExtraLibs([
      {
        content: WORKFLOWS_JINT_DEFINITIONS,
        filePath: 'inmemory://node_modules/workflow/parameters.d.ts', // @ (%40) in filepath leads to errors
      },
      {
        content: ["declare module '@workflow/parameters' {", `${paramDef.join('\r\n')}`, '}'].join('\r\n'),
        filePath: 'inmemory://node_modules/workflow/parameters.ts', // @ (%40) in filepath leads to errors
      },
    ]);
  }, [wfParameters]);

  return (
    <MonacoEditor
      language={'javascript'}
      value={value}
      onChange={onChange}
      decorations={editorDecorations}
      markers={editorMarkers}
      extraLibs={editorExtraLibs}
      options={{ readOnly: readOnly, detectIndentation: false }}
      onKeyboardSave={onKeyboardSave}
    />
  );
};

// There might be better typings so that `param` is one of `WorkflowParameter`
type ConversionFn = (key: string, param: any) => string;

/**
 * Simple helper functions to convert a param to how it will be used in code
 */
const _PARAM_TO_DEFINITION_CONVERSION: Record<WorkflowParameterTypes, ConversionFn> = {
  [WorkflowParameterTypes.Bool]: (key: string, param: SimpleWorkflowParameter) =>
    `export const ${key} = ${param.defaultValue ? 'true' : 'false'};`,
  [WorkflowParameterTypes.String]: (key: string, param: SimpleWorkflowParameter) =>
    `export const ${key} = '${(param.defaultValue as string).replaceAll("'", "\\'")}';`,
  [WorkflowParameterTypes.Number]: (key: string, param: SimpleWorkflowParameter) =>
    `export const ${key} = ${param.defaultValue.toString()};`,
  [WorkflowParameterTypes.File]: (key: string, param: FileWorkflowParameter) =>
    `/**Configured url: ${param.url} */\n  export const ${key}: Data;`,
  [WorkflowParameterTypes.AssetBundleAssignedToCfgr]: (key: string, param: BundleWorkflowParameter) =>
    `/** Selected bundle: ${param.bundleName} */\n  export const ${key}: AssetBundle;`,
};
