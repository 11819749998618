import classNames from 'classnames';
import { ErrorIcon, GearIcon, HourGlassIcon, InvalidCodeIcon, InvalidParamIcon, SuccessIcon } from 'assets/icons';
import { Icon, SvgComponent } from 'controls/icon/icon';
import { WorkflowRunStates } from 'generated/workflow-run-states';
import { tw } from 'helper/css.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type WorkflowRunStateKey = keyof typeof WorkflowRunStates;

const _RUN_STATE_MAP: Record<WorkflowRunStateKey, { Icon: SvgComponent; Title: string; Color?: string }> = {
  Queued: { Icon: HourGlassIcon, Title: 'Queued' },
  Running: { Icon: GearIcon, Title: 'Running' },
  CompletedSuccessfully: { Icon: SuccessIcon, Title: 'Completed successfully', Color: tw`text-success-main` },
  Failed: { Icon: ErrorIcon, Title: 'Failed', Color: tw`text-danger-main` },
  Canceled: { Icon: ErrorIcon, Title: 'Canceled' },
  InvalidScript: { Icon: InvalidCodeIcon, Title: 'Invalid script' },
  InvalidParameter: { Icon: InvalidParamIcon, Title: 'Invalid parameter' },
};

export const WorkflowRunStateIcon: React.FC<{ state: WorkflowRunStates }> = ({ state }) => {
  const { t } = useTypedTranslation();
  const stateKey = WorkflowRunStates[state] as WorkflowRunStateKey;
  const mapping = _RUN_STATE_MAP[stateKey];
  if (!mapping) {
    return null;
  }
  return (
    <Icon
      Svg={mapping.Icon}
      title={t(mapping.Title)}
      className={classNames('h-[24px] w-[24px]', mapping.Color, { 'animate-spin-slow': stateKey === 'Running' })}
      wrapperClassName="flex items-center justify-center"
    />
  );
};
