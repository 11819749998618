import { useEffect, useState } from 'react';
import { ArrowDoubleBackward, ArrowDoubleDownward, ArrowDoubleForward, ArrowDoubleUpward } from 'assets/icons';
import { Icon, SvgComponent } from 'controls/icon/icon';
import { ForwardableTooltipProps } from 'controls/tooltip/tooltip';

const _ICON_MAP: Record<
  CbnCollapseIconDirection,
  { collapsed: SvgComponent; expanded: SvgComponent; tooltipPlacement: ForwardableTooltipProps['placement'] }
> = {
  left: { collapsed: ArrowDoubleForward, expanded: ArrowDoubleBackward, tooltipPlacement: 'right' },
  right: { collapsed: ArrowDoubleBackward, expanded: ArrowDoubleForward, tooltipPlacement: 'left' },
  top: { collapsed: ArrowDoubleDownward, expanded: ArrowDoubleUpward, tooltipPlacement: 'bottom' },
  bottom: { collapsed: ArrowDoubleUpward, expanded: ArrowDoubleDownward, tooltipPlacement: 'top' },
};

export type CbnCollapseIconDirection = 'left' | 'right' | 'top' | 'bottom';

type CbnCollapseIconProps = {
  collapsed?: boolean;
  direction?: CbnCollapseIconDirection;
  titleExpand?: string;
  titleCollapse?: string;
  titleProps?: ForwardableTooltipProps;
  onClick?: () => void;
};

export const CbnCollapseIcon: React.FC<CbnCollapseIconProps> = ({
  collapsed,
  direction = 'left',
  titleExpand,
  titleCollapse,
  titleProps,
  onClick,
}) => {
  const [resetInput, setResetInput] = useState(false);
  const title = collapsed ? titleExpand : titleCollapse;
  const finalTitle = resetInput ? '' : title;

  // this is a workaround for cases where the tooltip doesn't disappear when switching the collapsed state or at least
  // the UI is flashing
  // the solution is to remove the tooltip right when clicking the collapse button and restore it one render cycle later
  useEffect(() => {
    if (resetInput) {
      setResetInput(false);
    }
  }, [resetInput]);

  const onIconClick = (): void => {
    onClick?.();
    setResetInput(true);
  };

  return (
    <Icon
      Svg={collapsed ? _ICON_MAP[direction].collapsed : _ICON_MAP[direction].expanded}
      className="w-6 cursor-pointer text-neutral-60 hover:text-neutral-100"
      title={finalTitle}
      titleProps={{ placement: _ICON_MAP[direction].tooltipPlacement, ...titleProps }}
      onClick={onIconClick}
    />
  );
};
