import { COMPANYID_ROUTE_PARAMS } from 'services/routes/common-routeparams.service';
import { buildPath } from 'services/routes/routes.service';

const _BASE_PATH = '/home';

const _HOME_ROUTE_PARAMS = {
  ...COMPANYID_ROUTE_PARAMS,
};
export type HomeRouteParams = keyof typeof _HOME_ROUTE_PARAMS;

export const HOME_PATH_VARIANTS = {
  home: _BASE_PATH,
  companyHome: `${_BASE_PATH}/:${_HOME_ROUTE_PARAMS.companyId}`,
};

export function buildCompanyHomePath(companyId: string): string {
  return buildPath(_BASE_PATH, companyId);
}
