import {
  useAppInsightsContext,
  useTrackEvent,
  useTrackMetric,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { useEffect, useState } from 'react';
import { InfoIcon } from 'assets/icons';
import { ErrorBoundary } from 'components/error-handling/cbn-error-boundary';
import { Button } from 'controls/button/button';
import { Card } from 'controls/card/card';
import { Icon } from 'controls/icon/icon';
import { addError, addLog } from 'services/store/logger.service';
import { aiInstance, aiReactPlugin } from 'services/telemetry/telemetry.service';

const BuiltToFail: React.FC = () => {
  useEffect(() => {
    throw new Error('Forced error on mount');
  }, []);

  return <button type="button"></button>;
};

const TrackedComponent: React.FC = () => {
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, 'MyTrackedComponent');

  return (
    <div onMouseOver={trackComponent} onClick={trackComponent} className="cursor-pointer border-2 p-2">
      <h3>useTrackMetric</h3>
      Mouse over & click as trigger
      <br />
      <span className="text-s-regular">Only tracked when "safely" unmounted?</span>
    </div>
  );
};

export const TelemetryTesting: React.FC = () => {
  const appInsights = useAppInsightsContext();
  const [counter, setCounter] = useState(0);

  const [showFullPageErrorCmp, setShowFullPageErrorCmp] = useState(false);
  const [showHandledErrorCmp, setShowHandledErrorCmp] = useState(false);

  const doRenderFullPageErrorCmp = async (): Promise<void> => setShowFullPageErrorCmp(true);
  const doRenderHandledErrorCmp = async (): Promise<void> => setShowHandledErrorCmp(true);

  const trackCounterUpdate = useTrackEvent(appInsights, 'Counter Updated', counter);
  useEffect(() => {
    trackCounterUpdate(counter);
  }, [counter, trackCounterUpdate]);

  const increaseCounter = (): void => {
    setCounter(counter + 1);
  };

  const sendError = async (): Promise<void> => {
    addError("Manual exception 'Foo' from Admin-Page");
  };
  const sendLog = async (): Promise<void> => {
    addLog("Manual trace 'Foo bar' from Admin-Page", 'Warning');
  };

  const throwError = async (event: any): Promise<void> => {
    console.log(event);
    throw new Error('Manually thrown error');
  };

  const HocTracking = withAITracking(
    aiReactPlugin,
    () => (
      <div className="cursor-pointer border-2 p-2">
        <h3>withAITracking()</h3>
        <div>
          No explicit triggers required
          <br />
          (already done by HOC)
          <br />
          <span className="text-s-regular">Only tracked when "safely" unmounted?</span>
        </div>
      </div>
    ),
    'MyAnonymousHocTracking'
  );

  return (
    <Card headerText="App Insights" subText={'Injected key: ' + window.appInsightKey}>
      <div className="mb-5 flex items-center gap-2 text-s-regular">
        <Icon Svg={InfoIcon} className="w-5" />
        <span>
          Key for local testing can be set manually under:
          Combeenation\Combeenation.Cbn.WebApp\appsettings.LocalDev???.json
        </span>
      </div>
      <div className="flex gap-10">
        <section className="flex flex-col gap-2">
          <h3>Error Boundary</h3>
          <Button text="Trigger render error (whole page)" onClick={doRenderFullPageErrorCmp} variant="Outlined" />
          <Button text="Trigger render error (handled)" onClick={doRenderHandledErrorCmp} variant="Outlined" />
          {showFullPageErrorCmp && <BuiltToFail />}
          {showHandledErrorCmp && (
            <ErrorBoundary appInsights={aiInstance}>
              <BuiltToFail />
            </ErrorBoundary>
          )}
        </section>

        <section className="flex flex-col gap-2">
          <h3>Hooks</h3>
          <div className="flex items-center gap-2">
            <Button text="useTrackEvent 'Counter Updated'" onClick={increaseCounter} variant={'Outlined'} />
            <span>Counter: {counter}</span>
          </div>
          <TrackedComponent></TrackedComponent>
        </section>

        <section className="flex flex-col gap-2">
          <h3>Custom</h3>
          <Button text="addError 'Foo' (trackException)" onClick={sendError} variant="Outlined" />
          <Button text="addLog 'Foo bar' as 'Warning' (trackTrace)" onClick={sendLog} variant="Outlined" />
          <Button text="Throw error manually" onClick={throwError} variant="Outlined" />
        </section>
        <section className="flex flex-col gap-2">
          <h3>HOC</h3>
          <HocTracking />
        </section>
      </div>
    </Card>
  );
};
