import React from 'react';
import { DraftLockState } from 'components/cfgrs/drafts/draft-lock-state';
import { LinkButton } from 'controls/button/link-button';
import { TableCell } from 'controls/table';
import { getEditorUrl } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useIsLockedByCurrentUser } from 'hooks/identity/identity.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { Draft } from 'services/company-data/company-data.service';
import { selectSelectedConfigurator } from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

type DraftsTableNameCellProps = {
  draft: Draft;
};

export const DraftsTableNameCell: React.FC<DraftsTableNameCellProps> = ({ draft }) => {
  const { t } = useTypedTranslation();
  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const readonly = selectedCfgr?.cfgrFeatures.readonly;
  const companyId = selectedCfgr?.companyId || '';
  const cfgrId = selectedCfgr?.id || '';

  const hasManageDraftPermission = usePermission(CompanyPermissions.ManageDraft, companyId);
  const isCurrentUserLock = useIsLockedByCurrentUser(draft.lockStatus);

  const editNotAllowed = readonly || (draft.lockStatus && !isCurrentUserLock);

  return (
    <TableCell>
      <div className="flex gap-1 py-2 text-m-medium">
        <LinkButton
          variant="TextInline"
          disabled={editNotAllowed}
          title={editNotAllowed ? undefined : hasManageDraftPermission ? t('Edit draft') : t('View draft in editor')}
          href={getEditorUrl(companyId, cfgrId, draft.id, hasManageDraftPermission ? 'edit' : 'view')}
          reloadDocument
          text={draft.displayName}
        />
        {draft.lockStatus && <DraftLockState draft={draft} companyId={companyId} cfgrId={cfgrId} />}
      </div>
    </TableCell>
  );
};
