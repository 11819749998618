import { CompanyStates } from 'generated/company-states';
import { LockStatusTypes } from 'generated/lock-status-types';
import { HttpStatusCode } from 'helper/http/http-status.helper';
import { CompanyFeatures } from 'services/companies/companies.service';
import { endpoints, httpClient } from 'services/http/http.service';
import { Cfgr, CfgrFeatures, Company } from 'slices/company-data/company-data.slice';
import { CompanyPermission } from 'slices/permission/permission.slice';

type DraftDto = {
  id: string;
  /** Timestamp */
  createdAt: number;
  createdBy: string;
  displayName: string;
  /** Timestamp */
  lastEditedAt: number | undefined;
  lastEditedBy: string;
  /** Timestamp */
  lastPreviewedAt: number | undefined;
  lastPreviewedBy: string;
  parent: string; // TODO: Describe & document etc.
  lockStatus?: LockStatus;
};

export type Draft = DraftDto;
export type CfgrDrafts = {
  [cfgrId: string]: Draft[];
};

type StageDto = {
  name: string;
  version: string;
  stagedBy: string;
  /** Timestamp */
  stagedAt: number;
};

export type Stage = StageDto;
export type CfgrStages = {
  [cfgrId: string]: Stage[];
};

export type CfgrsDraftsAndStages = {
  cfgrs: Cfgr[];
  drafts: CfgrDrafts;
  stages: CfgrStages;
};

export type ReplicatorLockStatus = {
  $type: LockStatusTypes.LockedByReplicator;
  replicationId: string;
  allowDelete?: boolean;
};
export type CfgrEditorDraftLockStatus = {
  $type: LockStatusTypes.CfgrEditorDraftLock;
  heldBy: string;
  expiryDate: number;
  allowDelete?: boolean;
};
export type LifecycleLockStatus = {
  $type: LockStatusTypes.LifecycleLock;
  allowDelete?: boolean;
};

type LockStatusLUT = {
  [T in LockStatusTypes]: {
    [LockStatusTypes.LockedByReplicator]: CfgrEditorDraftLockStatus;
    [LockStatusTypes.CfgrEditorDraftLock]: ReplicatorLockStatus;
    [LockStatusTypes.LifecycleLock]: LifecycleLockStatus;
  }[T];
};

export type LockStatus = LockStatusLUT[keyof LockStatusLUT];

export function isReplicatorLockStatus(lockStatus: LockStatus | undefined): lockStatus is ReplicatorLockStatus {
  return lockStatus ? lockStatus.$type === LockStatusTypes.LockedByReplicator : false;
}
export function isCfgrEditorDraftLockStatus(
  lockStatus: LockStatus | undefined
): lockStatus is CfgrEditorDraftLockStatus {
  return lockStatus ? lockStatus.$type === LockStatusTypes.CfgrEditorDraftLock : false;
}

export type ConfiguratorDto = {
  authToken: string;
  cfgrDisplayName: string;
  /** Timestamp */
  deleteAfter?: number;
  drafts?: DraftDto[];
  stages?: StageDto[];
  isPublished: boolean;
  /** Timestamp */
  lastPublishedAt: number | undefined;
  lastPublishedBy: string;
  productPlanName: string;
  version: string; // TODO: Describe & document etc.
  features: CfgrFeatures;
  lockStatus?: LockStatus;
};

type ConfiguratorDtoWithoutDraftsAndStages = Omit<ConfiguratorDto, 'drafts' | 'stages'>;

export type CompaniesResponse = {
  defaultCulture: string;
  displayName: string;
  name: string;
  permissions: number; // TODO: Describe & document etc.
  role: number; // TODO: Describe & document etc.
  state: CompanyStates;
  companyFeatures: CompanyFeatures;
}[];

/**
 * Converts companies array from "raw wire format" to "state format"
 */
export function companiesResponseToCompanies(resp: CompaniesResponse): Company[] {
  const companies: Company[] = resp.map(company => ({
    id: company.name,
    displayName: company.displayName,
    role: company.role,
    defaultCulture: company.defaultCulture,
    state: company.state,
    companyFeatures: company.companyFeatures,
  }));
  return companies;
}

export function companiesResponseToPermissions(resp: CompaniesResponse): CompanyPermission {
  const permissions = resp.reduce<CompanyPermission>((acc, company) => {
    acc[company.name] = company.permissions;
    return acc;
  }, {});

  return permissions;
}

export function cfgrResponseToCfgrsDraftsAndStages(resp: ConfiguratorDto[], companyId: string): CfgrsDraftsAndStages {
  const cfgrs = resp.map(cfgr => _cfgrDtoToCfgr(cfgr, companyId));

  const drafts = resp.reduce((acc, cfgr) => {
    if (cfgr.drafts) {
      acc[cfgr.productPlanName] = _draftsRespToDrafts(cfgr.drafts);
    }
    return acc;
  }, {} as CfgrDrafts);

  const stages = resp.reduce((acc, cfgr) => {
    if (cfgr.stages) {
      acc[cfgr.productPlanName] = cfgr.stages;
    }
    return acc;
  }, {} as CfgrStages);

  return { cfgrs, drafts, stages };
}

/**
 * Convert drafts array from "raw wire format" to "state format""
 */
function _draftsRespToDrafts(draftsResp: DraftDto[]): Draft[] {
  const drafts: Draft[] = draftsResp.map(draft => ({
    id: draft.id,
    createdAt: draft.createdAt,
    createdBy: draft.createdBy,
    displayName: draft.displayName,
    lastEditedAt: draft.lastEditedAt,
    lastEditedBy: draft.lastEditedBy,
    lastPreviewedAt: draft.lastPreviewedAt,
    lastPreviewedBy: draft.lastPreviewedBy,
    parent: draft.parent,
    lockStatus: draft.lockStatus,
  }));
  return drafts;
}

/**
 * Convert cfgr from "raw wire format" to "state format"
 */
function _cfgrDtoToCfgr(cfgrDto: ConfiguratorDtoWithoutDraftsAndStages, companyId: string): Cfgr {
  return {
    id: cfgrDto.productPlanName,
    companyId: companyId,
    authToken: cfgrDto.authToken,
    displayName: cfgrDto.cfgrDisplayName,
    deleteAfter: cfgrDto.deleteAfter,
    isPublished: cfgrDto.isPublished,
    lastPublishedAt: cfgrDto.lastPublishedAt,
    lastPublishedBy: cfgrDto.lastPublishedBy,
    version: cfgrDto.version,
    cfgrFeatures: cfgrDto.features,
    lockStatus: cfgrDto.lockStatus,
  };
}

export type FetchCompaniesResult = {
  companies: Company[];
  permissions: CompanyPermission;
};

export async function fetchCompanies(): Promise<FetchCompaniesResult> {
  const res = await httpClient.get<CompaniesResponse>(endpoints.getCompanies);
  const companies = companiesResponseToCompanies(res.data);
  const permissions = companiesResponseToPermissions(res.data);

  return { companies, permissions };
}

export async function fetchCfgrs(companyId: string): Promise<Cfgr[]> {
  const { url, params } = endpoints.configuratorsGetAll(companyId);
  const res = await httpClient.get<ConfiguratorDtoWithoutDraftsAndStages[]>(url, { params });

  const cfgrs = res.data.map(cfgr => _cfgrDtoToCfgr(cfgr, companyId));
  return cfgrs;
}

export async function fetchCfgr(companyId: string, cfgrName: string): Promise<CfgrsDraftsAndStages | null> {
  const { url, params } = endpoints.configuratorsGet(companyId, cfgrName);
  const res = await httpClient.get<ConfiguratorDto>(url, { params, validateStatus: () => true });

  if (res.status === HttpStatusCode.Ok_200) {
    const cfgrsAndDrafts = cfgrResponseToCfgrsDraftsAndStages([res.data], companyId);
    return cfgrsAndDrafts;
  } else {
    return null;
  }
}
