import React from 'react';
import { CbnToggleButtonGroup } from 'controls/cbn-toggle-button/cbn-toggle-button-group';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type CbnToggleBooleanProps = {
  value: boolean;
  onChange: (newValue: boolean) => void;
};

export const CbnToggleBoolean = React.forwardRef<HTMLElement, CbnToggleBooleanProps>(({ value, onChange }, ref) => {
  const { t } = useTypedTranslation();
  return (
    <CbnToggleButtonGroup
      exclusive
      ref={ref}
      value={Number(value)}
      entries={[
        { value: 1, text: t('true') },
        { value: 0, text: t('false') },
      ]}
      onValueChange={(newValue): void => onChange(Boolean(newValue))}
    />
  );
});
