import { Collapse } from '@mui/material';
import { DeletedDraftsTable } from 'components/cfgrs/drafts/deleted-drafts-table';
import { useCbnCard } from 'controls/cbn-card/cbn-card-provider';

type DeletedDraftsCardBodyProps = {};

export const DeletedDraftsCardBody: React.FC<DeletedDraftsCardBodyProps> = () => {
  const { collapsed } = useCbnCard();

  return (
    <Collapse data-cmptype="DeletedDraftsCardBody" in={!collapsed}>
      <DeletedDraftsTable suspendLoad={collapsed} />
    </Collapse>
  );
};
