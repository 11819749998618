import { throttle } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useInterval } from 'hooks/common/timing.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { getCompanyCfgr } from 'slices/company-data/company-data.slice';

const _THROTTLE_TIME_FETCH = 5000;
const _LOCK_POLLING_INTERVAL = 10000;

/**
 * Hook to fetch drafts, stages etc. for a given company and cfgr id.
 *
 * This hook will continuously fetch drafts **if there are locks** on the drafts.
 * It will also re-fetch drafts when the window regains focus.
 */
export const useCfgrDraftPolling = (companyId: string, cfgrId: string): void => {
  const dispatch = useAppDispatch();

  const currentDrafts = useAppSelector(state => state.companyData.drafts[companyId]?.[cfgrId]);
  const hasLocks = currentDrafts ? currentDrafts.some(d => d.lockStatus) : false;
  const intervalDelay = hasLocks ? _LOCK_POLLING_INTERVAL : null;

  // throttle the fetching of drafts because there are multiple "triggers"
  const fetchDraftsThrottled = useMemo(
    () =>
      throttle(() => {
        dispatch(getCompanyCfgr({ companyId: companyId, cfgrId: cfgrId }));
      }, _THROTTLE_TIME_FETCH),
    [dispatch, companyId, cfgrId]
  );

  // continuous update in case the user waits for a lock to vanish
  useInterval(() => {
    if (!hasLocks || document.visibilityState === 'hidden') {
      return;
    }

    fetchDraftsThrottled();
  }, intervalDelay);

  useEffect(() => {
    fetchDraftsThrottled();
    const onFocusFn = (): void => {
      fetchDraftsThrottled();
    };
    window.addEventListener('focus', onFocusFn);

    return () => {
      window.removeEventListener('focus', onFocusFn);
      fetchDraftsThrottled.cancel();
    };
  }, [fetchDraftsThrottled]);
};
