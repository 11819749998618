import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CbnTreeViewDataItem, CbnTreeViewProps } from 'controls/cbn-tree-view/cbn-tree-view';
import { assetPathToKey, getAssetParentFolderPath, sortAssets } from 'helper/assets/assets.helper';
import { useNavigateToAssetEditor } from 'hooks/assets/assets.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import {
  Asset,
  isFolderAsset,
  selectAssetsInSelectedBasePath,
  selectSelectedAsset,
  selectSelectedAssetPath,
  setSelectedTreeViewNodeIds,
} from 'slices/assets/assets.slice';

type UseAssetsTreeViewHook = () => {
  treeItems: CbnTreeViewDataItem<Asset>[];
  expandedItems: string[];
  handleSelect: CbnTreeViewProps<Asset, true>['onNodeSelect'];
  handleToggle: CbnTreeViewProps<Asset, true>['onNodeToggle'];
};

export const useAssetsTreeView: UseAssetsTreeViewHook = () => {
  const dispatch = useAppDispatch();
  const navigateToAssetEditor = useNavigateToAssetEditor();

  // holds the ids of all expanded items (folders)
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  // don't expand if user collapsed a folder where its child is selected
  const preventAutoExpand = useRef(false);

  const selectedAsset = useAppSelector(selectSelectedAsset);
  const selectedAssetPath = useAppSelector(selectSelectedAssetPath);
  const selectedNodeKey = selectedAsset ? assetPathToKey(selectedAssetPath) : null;
  const assets = useAppSelector(selectAssetsInSelectedBasePath);

  const treeItems = useMemo(() => {
    const assetsArr = Object.values(assets);
    const topLevelAssets = assetsArr.filter(asset => !asset.path.folderId);
    sortAssets(topLevelAssets);

    const items = topLevelAssets.reduce<CbnTreeViewDataItem<Asset>[]>((accTreeItems, curAsset) => {
      const dataItem: CbnTreeViewDataItem<Asset> = {
        nodeId: assetPathToKey(curAsset.path),
        label: curAsset.path.assetId,
        nodeData: curAsset,
      };

      if (isFolderAsset(curAsset)) {
        const childs = curAsset.childKeys.map(key => assets[key]);
        sortAssets(childs);
        dataItem.children = childs.map(childAsset => ({
          nodeId: assetPathToKey(childAsset.path),
          label: childAsset.path.assetId,
          nodeData: childAsset,
        }));
      }

      accTreeItems.push(dataItem);
      return accTreeItems;
    }, []);

    return items;
  }, [assets]);

  useEffect(
    function resetOnSelectionChange() {
      preventAutoExpand.current = false;
    },
    [selectedAssetPath.bundleVersion, selectedAssetPath.assetId]
  );

  useEffect(
    function expandFolderWhenChildSelected() {
      const folderKey = assetPathToKey(getAssetParentFolderPath(selectedAssetPath));
      const selectedChildNotVisible = selectedAssetPath.folderId && !expandedItems.includes(folderKey);
      if (selectedChildNotVisible && !preventAutoExpand.current) {
        setExpandedItems(oldExpandedItems => [...oldExpandedItems, folderKey]);
      }
    },
    [selectedAssetPath, expandedItems]
  );

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]): void => {
    dispatch(setSelectedTreeViewNodeIds(nodeIds));

    const isMultiselectAction = (event as React.MouseEvent).ctrlKey || (event as React.MouseEvent).shiftKey;
    if (isMultiselectAction && nodeIds.length === 1) {
      // switch back to single selection with navigation to the selected node
      const newSingleSelectAsset = assets[nodeIds[0]];
      if (newSingleSelectAsset) {
        navigateToAssetEditor(newSingleSelectAsset.path);
      }
    } else if (isMultiselectAction && nodeIds.length === 0) {
      // no selection -> navigate to the root
      navigateToAssetEditor({ ...selectedAssetPath, folderId: undefined, assetId: undefined });
    }
  };

  const handleToggle = (nodeIds: string[]): void => {
    const parentOfSelectedAssetIsCollapsed =
      selectedAsset?.parentFolderKey && !nodeIds.includes(selectedAsset.parentFolderKey);
    if (parentOfSelectedAssetIsCollapsed) {
      preventAutoExpand.current = true;
    }
    setExpandedItems(nodeIds);
  };

  useEffect(
    function updateTreeViewSelection() {
      dispatch(setSelectedTreeViewNodeIds(selectedNodeKey ? [selectedNodeKey] : []));
      return (): void => {
        dispatch(setSelectedTreeViewNodeIds([]));
      };
    },
    [dispatch, selectedNodeKey]
  );

  return { treeItems, expandedItems, handleSelect, handleToggle };
};
