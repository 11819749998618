import { AxiosError } from 'axios';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { ActionResult } from 'services/http/http.service';

type PublishErrorContentProps = {
  error: ActionResult;
};

/**
 * Checks if the given error is a known publish error (ID 'PublishFailed' or 'PublishValidationFailed')
 */
export function isKnownPublishError(error: unknown): error is AxiosError<ActionResult> {
  // !!! Update ExtJS code which handles those 2 errors as well if something changes here !!!
  const publishFailedIds = ['PublishFailed', 'PublishValidationFailed'];
  return (
    error instanceof AxiosError &&
    error.response?.data?.Id &&
    publishFailedIds.includes(error.response.data.Id) &&
    error.response?.data?.Causes
  );
}

function _replaceLineBreaks(string: string, replacement = ''): string {
  return string.replace(/(?:\r\n|\r|\n)/g, replacement);
}

/**
 * Renders data of known publish errors to be shown in a dialog.
 *
 * Such known publish errors can occur when a user publishes a cfgr stage or history version which ...
 *   - ... has an asset bundle assigned that has been deleted
 *   - ... uses old Hive features that aren't supported anymore or had breaking changes
 *
 * The second one is only hypothetical at the time of writing.
 */
export const PublishErrorDialogContent: React.FC<PublishErrorContentProps> = ({ error }) => {
  const { t } = useTypedTranslation();

  return (
    <ul className="list-disc pl-4">
      {error.Causes?.map((outerCause, idx) => {
        if ('ErrorsInPreset' === outerCause.Id) return <li>{_replaceLineBreaks(outerCause.Message, '<br>')}</li>;

        if ('AssetBundlesValidationFailed' === outerCause.Id) {
          const outerCauseMsg = _replaceLineBreaks(outerCause.Message, '<br>');
          const innerCauses =
            outerCause.Causes?.map(innerCause => <li>{_replaceLineBreaks(innerCause.Message, '<br>')}</li>) ?? [];

          return (
            <>
              <li>{outerCauseMsg}</li>
              <ul className="list-disc">{innerCauses}</ul>
            </>
          );
        }

        return <li>{t('Unknown error')}</li>;
      })}
    </ul>
  );
};
