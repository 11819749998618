import { useEffect, useState } from 'react';
import {
  HorizontalDotsMenuIcon,
  NewDraftIcon,
  OpenIcon,
  PublishIcon,
  StageIcon,
  ViewDraftInEditorIcon,
} from 'assets/icons';
import { hoverIconStyleFactory } from 'components/cfgrs/drafts/draft-actions';
import { DraftMenu } from 'components/cfgrs/drafts/draft-menu';
import { DraftMenuItem } from 'components/cfgrs/drafts/draft-menu-item';
import { CreateDraftDialog } from 'components/common/create-draft-dialog';
import { CreateStageDialog } from 'components/common/create-stage-dialog';
import { PublishErrorDialogContent, isKnownPublishError } from 'components/common/publish-error-dialog-content';
import { Icon } from 'controls/icon/icon';
import { sleep } from 'helper/async/async.helper';
import { getCfgrPreviewUrl, getEditorUrl } from 'helper/url/cfgr-url.helper';
import { useConfirmDialog, useErrorDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import {
  CfgrHistoryVersion,
  postConfiguratorHistoryPublish,
} from 'services/configuratorhistory/configuratorhistory.service';
import {
  getCompanyCfgr,
  selectCfgrStages,
  selectSelectedCompany,
  selectSelectedConfigurator,
} from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

type HistoryMenuButtonProps = {
  cfgrVersion: CfgrHistoryVersion;
  visible: boolean;
};

export const HistoryMenuButton: React.FC<HistoryMenuButtonProps> = ({ cfgrVersion, visible }) => {
  const { t } = useTypedTranslation();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [createDraftOpen, setCreateDraftOpen] = useState(false);
  const [createStageOpen, setCreateStageOpen] = useState(false);

  const dispatch = useAppDispatch();
  const confirm = useConfirmDialog();
  const showError = useErrorDialog();

  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const stages = useAppSelector(selectCfgrStages);

  const companyId = selectedCfgr?.companyId || '';
  const cfgrId = selectedCfgr?.id || '';

  const hasViewDraftPermission = usePermission(CompanyPermissions.ViewConfigurator, selectedCfgr?.companyId);
  const hasManageDraftPermission = usePermission(CompanyPermissions.ManageDraft, selectedCfgr?.companyId);
  const hasPublishCfgrPermission = usePermission(CompanyPermissions.PublishCfgrDraft, selectedCfgr?.companyId);

  const isReadOnlyCfgr = selectedCfgr?.cfgrFeatures.readonly;
  const maxNoOfStagesReached = (selectedCompany?.companyFeatures.maxCfgrStages ?? 0) <= stages.length;

  const closeMenu = (): void => setMenuAnchorEl(null);

  useEffect(
    function closeMenuOnParentHide() {
      if (menuAnchorEl && !visible) {
        closeMenu();
      }
    },
    [visible, menuAnchorEl]
  );

  const previewConfiguratorLink = getCfgrPreviewUrl(companyId, cfgrId, cfgrVersion.version);
  const viewDraftInEditorLink = getEditorUrl(companyId, cfgrId, cfgrVersion.version, 'view');

  const publishVersion = async (name: string, version: string): Promise<void> => {
    if (!selectedCfgr) return;

    const publishConfirmed = await confirm(t(`Publishing will overwrite the current live version.`), {
      headerText: t(`Publish version "${name}"`),
      confirmBtnText: 'Publish version',
      variant: 'Question',
    });

    if (publishConfirmed) {
      try {
        await postConfiguratorHistoryPublish(selectedCfgr.companyId, selectedCfgr.id, cfgrVersion.version);
      } catch (error) {
        if (isKnownPublishError(error)) {
          showError(<PublishErrorDialogContent error={error.response!.data} />, {
            headerText: t('Publish failed'),
            size: 'Large',
          });
        } else {
          // Re-throw unhandled errors to show generic "Ooops" dialog
          throw error;
        }
      }

      dispatch(getCompanyCfgr({ companyId: selectedCfgr.companyId, cfgrId: selectedCfgr.id }));
    }
  };

  return (
    <>
      <button
        type="button"
        className={hoverIconStyleFactory(visible, !!menuAnchorEl)}
        onClick={(event): void => setMenuAnchorEl(event.currentTarget)}
      >
        <Icon Svg={HorizontalDotsMenuIcon} className="h-8 text-primary-main" />
      </button>

      <DraftMenu open={!!menuAnchorEl} anchorEl={menuAnchorEl} onClose={closeMenu} closeOnClick>
        {hasViewDraftPermission && (
          <DraftMenuItem
            svg={ViewDraftInEditorIcon}
            text={t('View version in editor')}
            to={viewDraftInEditorLink}
            linkProps={{ reloadDocument: true }}
          />
        )}

        <DraftMenuItem
          svg={OpenIcon}
          text={t('Preview configurator in version')}
          to={previewConfiguratorLink}
          linkProps={{ target: '_blank' }}
        />

        {selectedCfgr && hasManageDraftPermission && !isReadOnlyCfgr && (
          <DraftMenuItem
            svg={NewDraftIcon}
            text={t('New draft based on this version')}
            onClick={async (): Promise<void> => {
              await sleep(0); // Auto focus in dialog doesn't work without this
              setCreateDraftOpen(true);
            }}
          />
        )}

        {selectedCfgr && hasManageDraftPermission && !isReadOnlyCfgr && !maxNoOfStagesReached && (
          <DraftMenuItem
            svg={StageIcon}
            text={t('Stage this version')}
            onClick={async (): Promise<void> => {
              await sleep(0); // Auto focus in dialog doesn't work without this
              setCreateStageOpen(true);
            }}
          />
        )}

        {selectedCfgr && hasPublishCfgrPermission && !isReadOnlyCfgr && (
          <DraftMenuItem
            svg={PublishIcon}
            text={t('Publish this version')}
            onClick={async (): Promise<void> => publishVersion(cfgrVersion.draftDisplayName, cfgrVersion.version)}
          />
        )}
      </DraftMenu>

      {createDraftOpen && (
        <CreateDraftDialog
          initialSourceCfgrVersionId={cfgrVersion.version}
          initialSourceCfgrVersionDisplayName={cfgrVersion.draftDisplayName}
          draftNameSuggestion={cfgrVersion.draftDisplayName}
          onCancel={(): void => setCreateDraftOpen(false)}
          onConfirm={(): void => setCreateDraftOpen(false)}
        />
      )}

      {selectedCfgr && createStageOpen && (
        <CreateStageDialog
          srcAndTargetCfgr={selectedCfgr}
          srcVersion={cfgrVersion.version}
          baseName={t(`version "${cfgrVersion.draftDisplayName}"`)}
          onCancel={(): void => setCreateStageOpen(false)}
          onConfirm={(): void => setCreateStageOpen(false)}
        />
      )}
    </>
  );
};
