import React, { useEffect, useState } from 'react';
import { MAX_DRAFTS_PER_CFGR } from 'components/common/create-draft-dialog';
import { IdentityUser } from 'components/identity/identity-user';
import { Button } from 'controls/button/button';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'controls/table';
import { TableSkeletonRows } from 'controls/table/table-skeleton-rows';
import { tw } from 'helper/css.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import {
  getCompanyDeletedDrafts,
  restoreDeletedDraft,
  selectCfgrDrafts,
  selectDeletedCfgrDrafts,
  selectSelectedConfigurator,
} from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

type DeletedDraftsTableProps = {
  suspendLoad: boolean;
};

export const DeletedDraftsTable: React.FC<DeletedDraftsTableProps> = ({ suspendLoad }) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const [isFetching, setIsFetching] = useState(true);
  const deletedDrafts = useAppSelector(selectDeletedCfgrDrafts);
  const sortedDeletedDrafts = [...deletedDrafts].sort((a, b) => b.deletedAt - a.deletedAt);

  const drafts = useAppSelector(selectCfgrDrafts);
  const maxNoOfDraftsReached = drafts.length >= MAX_DRAFTS_PER_CFGR;

  const [restoringDraftIds, setRestoringDraftIds] = useState<string[]>([]);

  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const companyId = selectedCfgr?.companyId || '';
  const cfgrId = selectedCfgr?.id || '';

  const hasManageDraftPermission = usePermission(CompanyPermissions.ManageDraft, selectedCfgr?.companyId);

  useEffect(() => {
    if (!companyId || !cfgrId || suspendLoad) return;

    const fetchDeletedDrafts = async (): Promise<void> => {
      setIsFetching(true);
      await dispatch(getCompanyDeletedDrafts({ companyId, cfgrId }));
      setIsFetching(false);
    };
    fetchDeletedDrafts();
  }, [cfgrId, companyId, dispatch, suspendLoad]);

  const restoreDraft = async (draftId: string): Promise<void> => {
    setRestoringDraftIds([...restoringDraftIds, draftId]);
    await dispatch(restoreDeletedDraft({ companyId, configuratorName: cfgrId, draftId }));
    setRestoringDraftIds(restoringDraftIds.filter(id => id !== draftId));
  };

  return (
    <>
      <Table data-cmptype="DeletedDraftsTable" stickyHeader containerClassName={tw`max-h-[500px] overflow-y-auto`}>
        <TableHead>
          <TableRow>
            <TableCell>{t('Name')}</TableCell>
            <TableCell>{t('Created')}</TableCell>
            <TableCell>{t('Deleted')}</TableCell>
            <TableCell>{t('Restorable until')}</TableCell>
            <TableCell className="w-0"></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <>
            {isFetching && <TableSkeletonRows numberOfCols={5} colSkeletonProps={{ 4: { className: 'w-[10rem]' } }} />}

            {!isFetching &&
              sortedDeletedDrafts.map(deletedDraft => (
                <TableRow key={deletedDraft.id} className="hover:bg-neutral-20">
                  <TableCell>{deletedDraft.displayName}</TableCell>

                  <TableCell>
                    <div className="just-center flex whitespace-pre">
                      <RelativeDateTime unixTime={deletedDraft.createdAt} />
                      <span> {t('by')} </span>
                      <IdentityUser userId={deletedDraft.createdBy} />
                    </div>
                  </TableCell>

                  <TableCell>
                    <div className="just-center flex whitespace-pre">
                      <RelativeDateTime unixTime={deletedDraft.deletedAt} />
                      <span> {t('by')} </span>
                      <IdentityUser userId={deletedDraft.deletedBy} />
                    </div>
                  </TableCell>

                  <TableCell>
                    <RelativeDateTime unixTime={deletedDraft.deleteAfter} />
                  </TableCell>

                  <TableCell>
                    {hasManageDraftPermission && (
                      <Button
                        variant="Secondary"
                        text={t('Restore')}
                        isLoading={restoringDraftIds.includes(deletedDraft.id)}
                        onClick={(): Promise<void> => restoreDraft(deletedDraft.id)}
                        disabled={maxNoOfDraftsReached}
                        title={
                          maxNoOfDraftsReached ? t(`Max number of drafts (${MAX_DRAFTS_PER_CFGR}) reached`) : undefined
                        }
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </>
        </TableBody>
      </Table>

      {deletedDrafts.length === 0 && !isFetching && (
        <div className="my-6 flex justify-center text-m-regular text-neutral-90">
          {t('There are no deleted drafts for this configurator')}
        </div>
      )}
    </>
  );
};
