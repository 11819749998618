import { EXTJS_ROUTE_PREFIX } from 'helper/extjs-route/extjs-route.helper';

export const CBN_MAIL_ADRESSES = {
  info: 'info@combeenation.com',
  placeholderMail: 'somebody@mail.com',
};

export function getPublishedCfgrLink(companyId: string, id: string, cfgnId: string | null, relative: boolean): string {
  const baseUrl = window.location.origin;
  const url = `/Cfgr/${companyId}/${id}` + (cfgnId ? `/${cfgnId}/copy` : '');

  return relative ? url : baseUrl + url;
}

export function getCbnPluginUrl(companyId: string, id: string): string {
  const baseUrl = window.location.origin;
  const url = `/plugin/${companyId}/${id}`;

  return baseUrl + url;
}

export function getEmbedExampleUrl(companyId: string, id: string): string {
  const baseUrl = window.location.origin;
  const url = `/embedexample/${companyId}/${id}`;

  return baseUrl + url;
}

export function getCfgrPreviewUrl(companyId: string, cfgrName: string, draftId: string): string {
  return `/preview/${companyId}/${cfgrName}/${draftId}`;
}

export function getCfgrStagingUrl(companyId: string, cfgrName: string, stageName: string): string {
  const baseUrl = window.location.origin;
  const url = `/staging/${companyId}/${cfgrName}/${stageName}`;
  return baseUrl + url;
}

export function getEditorUrl(companyId: string, cfgrName: string, id: string, type: 'edit' | 'view'): string {
  return `${EXTJS_ROUTE_PREFIX}editor/${companyId}/${cfgrName}/${id}/${type}`;
}
