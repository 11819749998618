import { useEffect, useState } from 'react';
import { AssetBundleVersionLockInfo } from 'components/asset-editor/asset-bundle-version-lock-info';
import { ManageAssetBundleVersions } from 'components/asset-editor/bundle-version-management/manage-asset-bundle-versions';
import { AssetBulkEdit } from 'components/asset-editor/details/asset-bulk-edit';
import { AssetDetails } from 'components/asset-editor/details/asset-details';
import { AssetEditorHeader } from 'components/asset-editor/header/asset-editor-header';
import { NoAssetBundlesFallback } from 'components/asset-editor/header/no-asset-bundles-fallback';
import { NoAssetsFallback } from 'components/asset-editor/no-assets-fallback';
import { AssetTreeCollapsed } from 'components/asset-editor/overview/asset-tree-collapsed';
import { AssetTreeView } from 'components/asset-editor/overview/asset-tree-view';
import { DragDropProvider } from 'components/dragdrop/drag-drop-provider';
import { ErrorBoundary } from 'components/error-handling/cbn-error-boundary';
import { CbnResizablePanel } from 'controls/cbn-resizeable-panel/cbn-resizeable-panel';
import { CbnResizablePanelGroup } from 'controls/cbn-resizeable-panel/cbn-resizeable-panel-group';
import { CbnResizablePanelSeparator } from 'controls/cbn-resizeable-panel/cbn-resizeable-panel-separator';
import { cn } from 'helper/css.helper';
import { useAssetEditorRouteSync } from 'hooks/assets/assets.hooks';
import { useLocalStorageState } from 'hooks/common/local-storage.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { aiInstance } from 'services/telemetry/telemetry.service';
import {
  resetAssetsSlice,
  selectAllBundles,
  selectAssetsInSelectedBasePath,
  selectSelectedAsset,
  selectSelectedBundleVersion,
} from 'slices/assets/assets.slice';

export const AssetEditor: React.FC = () => {
  const dispatch = useAppDispatch();

  useAssetEditorRouteSync();

  const bundles = useAppSelector(selectAllBundles);
  const assetsInSelectedPath = useAppSelector(selectAssetsInSelectedBasePath);
  const fetchState = useAppSelector(state => state.assets.fetchState);
  const selectedBundleVersion = useAppSelector(selectSelectedBundleVersion);
  const selectedAsset = useAppSelector(selectSelectedAsset);
  const selectedTreeViewNodeIds = useAppSelector(state => state.assets.ui.selectedTreeViewNodeIds);
  const showManageBundleVersionsPage = useAppSelector(state => state.assets.ui.showManageBundleVersionsPage);

  // NOTE: it's important to consider fetched states as the UI would flash in these loading state
  const isCompanyWithoutBundles = fetchState.bundles === 'Fetched' && !bundles.length;
  const assetsCnt = Object.keys(assetsInSelectedPath).length;
  const isBundleWithoutAssets = selectedBundleVersion && fetchState.assets === 'Fetched' && !assetsCnt;
  const isBundleWithAssets = !!assetsCnt;

  const isVersionLocked = !!selectedBundleVersion?.lockStatus;
  const isMultiSelection = selectedTreeViewNodeIds.length > 1;

  const [assetTreeCollapsed, setAssetTreeCollapsed] = useLocalStorageState('assetTreeCollapsed', false);
  // some UI changes should be deferred after the asset tree collapsed animation has been finished
  const [assetTreeCollapsedDelayed, setAssetTreeCollapsedDelayed] = useState(assetTreeCollapsed);

  /**
   * To discuss with @reviewer
   *
   * Keep or reset assets slice state when leaving asset editor:
   * Pro "keep":
   * - switching back to asset editor without loading bundles and assets from scratch
   *
   * Pro "reset"
   * - assets slice data should not be used outside asset editor
   * - assets may be outdated when switching back to asset editor anyway
   * - `useAssetEditorRouteSync` (maybe other hooks as well) only work consistently from an empty asset slice state
   */
  useEffect(() => {
    return () => {
      dispatch(resetAssetsSlice());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!assetTreeCollapsed) {
      setAssetTreeCollapsedDelayed(false);
    }
  }, [assetTreeCollapsed]);

  const onAssetTreeTransitionEnd = (ev: React.TransitionEvent<'div'>): void => {
    if (ev.propertyName === 'max-width' && assetTreeCollapsed) {
      setAssetTreeCollapsedDelayed(true);
    }
  };

  return (
    <DragDropProvider>
      <div data-cmptype="AssetEditor" className="flex grow flex-row">
        {assetTreeCollapsedDelayed && <AssetTreeCollapsed onExpand={(): void => setAssetTreeCollapsed(false)} />}

        <div className="flex grow flex-col px-6 pb-6">
          <AssetEditorHeader />

          {/* asset editor body should be limited to one screen size */}
          {/* we need to subtract the app header and asset breadcrumbs height though */}
          <div className="mt-5 max-h-[calc(100vh-140px)] grow">
            {isCompanyWithoutBundles ? (
              <NoAssetBundlesFallback />
            ) : showManageBundleVersionsPage ? (
              <ManageAssetBundleVersions />
            ) : isVersionLocked ? (
              <AssetBundleVersionLockInfo />
            ) : isBundleWithoutAssets ? (
              <NoAssetsFallback />
            ) : isBundleWithAssets ? (
              // this is the main main path, which shows the asset tree view and the detail pages
              <CbnResizablePanelGroup direction="horizontal" sizeMode="pixel" autoSaveId="asset-editor">
                <CbnResizablePanel
                  order={1}
                  className={cn(
                    'flex flex-col transition-[max-width] duration-300 ease-in-out',
                    // panel is rendered but "hidden" via max-width property to support animation
                    assetTreeCollapsed ? 'max-w-0' : 'max-w-[500px]',
                    assetTreeCollapsedDelayed ? 'p-0' : 'p-1'
                  )}
                  defaultSize={360}
                  minSize={290}
                  maxSize={450}
                  onTransitionEnd={onAssetTreeTransitionEnd}
                >
                  <AssetTreeView onCollapse={(): void => setAssetTreeCollapsed(true)} />
                </CbnResizablePanel>
                {!assetTreeCollapsedDelayed && <CbnResizablePanelSeparator />}
                <CbnResizablePanel order={2} className="relative flex flex-col p-1">
                  <ErrorBoundary
                    appInsights={aiInstance}
                    hideLogoutBtn
                    resetKeys={selectedAsset ? [selectedAsset.path.assetId] : []}
                  >
                    {isMultiSelection ? <AssetBulkEdit /> : <AssetDetails />}
                  </ErrorBoundary>
                </CbnResizablePanel>
              </CbnResizablePanelGroup>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </DragDropProvider>
  );
};
