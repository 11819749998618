import MuiTableHead from '@mui/material/TableHead';
import { TableRowProps } from 'controls/table/table-row';

export type TableHeadProps = {
  children: React.ReactElement<TableRowProps> | React.ReactElement<TableRowProps>[];
};

export const TableHead: React.FC<TableHeadProps> = ({ children }) => {
  return (
    <MuiTableHead data-cmptype="TableHead" className="text-l-medium">
      {children}
    </MuiTableHead>
  );
};
