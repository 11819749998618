import { AssetDetailsHeader } from 'components/asset-editor/details/asset-details-header';
import { AssetMetadataEntry } from 'components/asset-editor/details/asset-metadata';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { getExtensionFromAssetFilenameOrUrl } from 'helper/assets/assets.helper';
import { getReadableFileSize } from 'helper/file/file.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { FileAsset, ImageAsset, isImageAsset, selectSelectedAsset } from 'slices/assets/assets.slice';

export const AssetBodyImage: React.FC = () => {
  const { t } = useTypedTranslation();
  // asset can be file asset as well, since SVGs can not be stored as image asset yet, still we want to have the image
  // preview
  const selectedAsset = useAppSelector(selectSelectedAsset) as ImageAsset | FileAsset;

  const extension = getExtensionFromAssetFilenameOrUrl(selectedAsset.url);
  const fileSize = getReadableFileSize(selectedAsset.size);

  const metadata: AssetMetadataEntry[] = [
    { text: t('Format'), value: extension },
    { text: t('Size'), value: fileSize },
  ];

  if (isImageAsset(selectedAsset)) {
    metadata.push(
      { text: t('Width'), value: `${selectedAsset.width} px` },
      { text: t('Height'), value: `${selectedAsset.height} px` }
    );
  }

  return (
    <CbnCard data-cmptype="AssetBodyImage">
      <AssetDetailsHeader metadata={metadata} />

      <CbnCardBody>
        <a
          href={selectedAsset.url}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center"
        >
          {/* 40% of screen to make sure that the image is large but not resulting in unnecessary scroll */}
          <img src={selectedAsset.url} alt={selectedAsset.path.assetId} className="max-h-[40vh]"></img>
        </a>
      </CbnCardBody>
    </CbnCard>
  );
};
