import { useState } from 'react';
import { AssetDetailModalsErrorInfo } from 'components/asset-editor/details/modals/asset-detail-modals-error-info';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { useNavigateToAssetEditor } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { FailedAssetsInfo } from 'services/assets/assets.service';
import { deleteAsset, selectSelectedAsset } from 'slices/assets/assets.slice';

type AssetDeleteModalProps = {
  onClose: () => void;
};

export const AssetDeleteModal: React.FC<AssetDeleteModalProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { t, tDeleteAsset } = useTypedTranslation();

  const navigateToAssetEditor = useNavigateToAssetEditor();

  // NOTE: selected asset may shortly be "undefined", as the currently selected asset will be deleted
  const selectedAsset = useAppSelector(selectSelectedAsset);

  const [errorInfo, setErrorInfo] = useState<FailedAssetsInfo>();
  const [isProcessingDelete, setIsProcessingDelete] = useState(false);

  const onDeleteConfirmed = async (): Promise<void> => {
    const { companyId, bundleId, bundleVersion, folderId, assetId } = selectedAsset!.path;

    setIsProcessingDelete(true);
    if (errorInfo !== undefined) {
      setErrorInfo(undefined);
    }

    const result = await dispatch(
      deleteAsset({
        companyId,
        bundleId,
        bundleVersion,
        deleteItems: [{ assetName: assetId, folderName: folderId }],
      })
    ).unwrap();

    if (result.success) {
      navigateToAssetEditor(
        { companyId, bundleId, bundleVersion, assetId: folderId },
        // The current asset doesn't exist anymore -> Remove the current route from the history...
        true
      );
      onClose();
    } else {
      setErrorInfo(result.failedAssets);
    }

    setIsProcessingDelete(false);
  };

  return (
    <ModalDialog
      data-cmptype="DeleteAsset"
      variant="Danger"
      actions={{
        onConfirm: () => onDeleteConfirmed(),
        onCancel: () => onClose(),
      }}
      confirmText={t('Delete asset')}
      isProcessingConfirm={isProcessingDelete}
      confirmDisabled={!!errorInfo}
    >
      <div className="flex grow flex-col gap-4">
        <span>{tDeleteAsset(selectedAsset?.path.assetId ?? '')}</span>
        {errorInfo && <AssetDetailModalsErrorInfo errorInfo={errorInfo} />}
      </div>
    </ModalDialog>
  );
};
