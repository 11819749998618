import { Collapse } from '@mui/material';
import { useState } from 'react';
import { NewDraftIcon } from 'assets/icons';
import { DraftTable } from 'components/cfgrs/drafts/drafts-table';
import { CreateDraftDialog } from 'components/common/create-draft-dialog';
import { Button } from 'controls/button/button';
import { useCbnCard } from 'controls/cbn-card/cbn-card-provider';
import { cn } from 'helper/css.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { Draft } from 'services/company-data/company-data.service';
import {
  selectSelectedCfgrDetailsFetchState,
  selectSelectedConfigurator,
} from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

type DraftsCardBodyProps = {
  drafts: Draft[];
};

export const DraftsCardBody: React.FC<DraftsCardBodyProps> = ({ drafts }) => {
  const { t } = useTypedTranslation();
  const [createDraftOpen, setCreateDraftOpen] = useState(false);
  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const selectedCfgrDetailsFetchState = useAppSelector(selectSelectedCfgrDetailsFetchState);
  const isFetchingCfgrDetails = selectedCfgrDetailsFetchState === 'Pending';
  const { collapsed } = useCbnCard();
  const hasDrafts = drafts.length > 0;
  const hasManageDraftPermission = usePermission(CompanyPermissions.ManageDraft, selectedCfgr?.companyId);

  return (
    <Collapse data-cmptype="DraftsCardBody" in={!collapsed}>
      <div className="flex flex-col">
        {selectedCfgrDetailsFetchState === 'Fetched' && !hasDrafts && (
          <div className="flex flex-col items-center px-5 pb-2 pt-5">
            <div>{t('There are no drafts for this configurator.')}</div>
            <div>{t('Create a draft based on the live version or a staged version.')}</div>
          </div>
        )}

        {(hasDrafts || isFetchingCfgrDetails) && (
          <DraftTable drafts={drafts} showLoadingSkeletons={isFetchingCfgrDetails && !hasDrafts} />
        )}

        {hasManageDraftPermission && !selectedCfgr?.cfgrFeatures.readonly && (
          <div className={cn('px-5 py-3', !hasDrafts && !isFetchingCfgrDetails && 'mb-2 self-center')}>
            <Button
              variant="Secondary"
              text={t('New draft')}
              Svg={NewDraftIcon}
              onClick={(): void => setCreateDraftOpen(true)}
            />
          </div>
        )}
      </div>

      {selectedCfgr && createDraftOpen && (
        <CreateDraftDialog
          initialSourceCfgrVersionId={selectedCfgr.isPublished ? selectedCfgr.version : undefined}
          onCancel={(): void => setCreateDraftOpen(false)}
          onConfirm={(): void => setCreateDraftOpen(false)}
        />
      )}
    </Collapse>
  );
};
