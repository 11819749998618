import { AxiosResponse } from 'axios';

export async function abortableRequest<TResponseData>(
  responsePromise: Promise<AxiosResponse<TResponseData, any>>,
  abortSignal?: AbortSignal
): Promise<TResponseData | null> {
  try {
    const result = await responsePromise;
    return result.data;
  } catch (e) {
    if (abortSignal?.aborted) {
      return null;
    } else {
      throw e;
    }
  }
}
