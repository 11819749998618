import { Popper } from '@mui/material';
import { ReactElement } from 'react';
import { SelectIndicatorIcon } from 'assets/icons';
import { AutocompleteProps, StyledAutocomplete } from 'controls/autocomplete/autocomplete';
import { Checkbox } from 'controls/checkbox/checkbox';
import { Chip } from 'controls/chip/chip';
import { Icon } from 'controls/icon/icon';
import { Input } from 'controls/input/input';
import { StyledMenuItem } from 'controls/select/select';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type AutocompleteMultiSelectProps = AutocompleteProps<true> & {
  limitTags?: number;
  showMenuItemCheckbox?: boolean;
  hideChips?: boolean;
};

export const AutocompleteMultiSelect: React.FC<AutocompleteMultiSelectProps> = ({
  options,
  value,
  disabled,
  disableClearable,
  disableCloseOnSelect,
  limitTags,
  showMenuItemCheckbox,
  hideChips,
  fullWidth = true,
  onChange,
  error,
  warning,
  tooltip,
  placeholder,
  autoWidthDropdown = true,
}) => {
  const { t } = useTypedTranslation();
  const selOptions = options.filter(o => value?.includes(o.value));

  return (
    <StyledAutocomplete
      data-cmptype="AutocompleteMultiSelect"
      multiple
      limitTags={limitTags}
      disableCloseOnSelect={disableCloseOnSelect}
      disabled={disabled}
      fullWidth={fullWidth}
      PopperComponent={
        autoWidthDropdown
          ? (props): ReactElement => (
              <Popper
                {...props}
                style={{ width: 'fit-content', minWidth: (props.anchorEl as HTMLElement | null)?.clientWidth }}
              />
            )
          : undefined
      }
      popupIcon={<Icon Svg={SelectIndicatorIcon} className="w-6 text-neutral-90" />}
      renderInput={(params): React.ReactNode => (
        <Input
          startAdornment={params.InputProps.startAdornment}
          onKeyDown={
            hideChips
              ? (event: React.KeyboardEvent): void => {
                  // when the input is empty and backspace is pressed it would remove the selections/chips 1 by 1
                  // disable this behavior if chips are hidden (otherwise it's confusing for the user)
                  if (event.key === 'Backspace') {
                    event.stopPropagation();
                  }
                }
              : undefined
          }
          fullWidth={fullWidth}
          ref={params.InputProps.ref}
          endAdornment={params.InputProps.endAdornment}
          inputProps={params.inputProps}
          placeholder={placeholder ?? t('Search ...')}
          error={error}
          warning={warning}
        />
      )}
      renderTags={(value, getTagProps): React.ReactNode =>
        hideChips
          ? null
          : value.map((option, index) => (
              <Chip value={option.value.toString()} text={option.text?.toString()} {...getTagProps({ index })} />
            ))
      }
      renderOption={(params, option): React.ReactNode => (
        <StyledMenuItem {...params} key={option.value} disabled={option.disabled}>
          <div className="flex items-center gap-2">
            {showMenuItemCheckbox && (
              <Checkbox checked={params['aria-selected'] === true || params['aria-selected'] === 'true'} />
            )}
            <span>{option.text || option.value}</span>
          </div>
        </StyledMenuItem>
      )}
      getOptionLabel={(option): string => option.text?.toString() ?? option.value.toString()}
      options={options}
      getOptionDisabled={(option): boolean => !!option.disabled}
      value={selOptions ?? null}
      onChange={(evt, selectedOptions): void => {
        onChange?.(selectedOptions.map(o => o.value));
      }}
      disableClearable={disableClearable}
      noOptionsText={t('No options')}
      title={tooltip}
    />
  );
};
