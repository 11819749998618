import classNames from 'classnames';
import { ReplicatorBlueprintAssetBundles } from 'components/replicator/replicator-blueprint-assetbundles';
import { ReplicatorBlueprintCfgrs } from 'components/replicator/replicator-blueprint-cfgrs';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectCurrentBlueprint } from 'slices/replicator/replicator.slice';

export const ReplicatorBlueprintVersions: React.FC = () => {
  const { t } = useTypedTranslation();

  const blueprint = useAppSelector(selectCurrentBlueprint);
  const isSavedBlueprint = !!blueprint.blueprintId;

  return (
    <div data-cmptype="ReplicatorBlueprintVersions" className="flex flex-col gap-6">
      <div>{t('Confirm the selected configurators and bundle assets and assign versions.')}</div>
      <div
        className={classNames('grid grid-cols-2 gap-6', {
          'pointer-events-none select-none opacity-50': isSavedBlueprint,
        })}
      >
        <div className="flex flex-col gap-3">
          <h4>{t('Configurators')}</h4>
          <ReplicatorBlueprintCfgrs />
        </div>
        <div className="flex flex-col gap-3">
          <h4>{t('Asset bundles')}</h4>
          <ReplicatorBlueprintAssetBundles />
        </div>
      </div>
    </div>
  );
};
