import { useCallback, useRef } from 'react';
import {
  MaterialPropertyKey,
  NULL_VALUE,
  getValueByMaterialPropertyKey,
} from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor-properties-def';
import { FormControlLabel } from 'controls/form/form-control-label';
import { NumberInput, NumberOrNull } from 'controls/input/number-input';
import { Select, SelectOption, SelectValue } from 'controls/select/select';
import { Switch } from 'controls/switch/switch';
import { HighlightedText } from 'controls/text/highlighted-text';
import { cn } from 'helper/css.helper';
import { MaterialObject } from 'services/3d/materials.service';

export type MaterialPropBaseInputProps = {
  inputObj: MaterialObject;
  objKey: MaterialPropertyKey;
  disabled: boolean;
  displayName: string;
  searchValue: string;
};
type MaterialPropFormControlLabelProps = {
  'data-cmptype'?: string;
  'displayName': string;
  'searchValue': string;
  'setMaxWidth'?: boolean;
  'disabled'?: boolean;
  'disableLabelClick'?: boolean;
  'control': React.ReactElement;
};
type MaterialPropBooleanInputProps = MaterialPropBaseInputProps & {
  defaultValue: boolean;
  onChange: (key: MaterialPropertyKey, value: boolean) => void;
};
type MaterialPropNumberInputProps = MaterialPropBaseInputProps & {
  defaultValue?: number;
  onChange: (key: MaterialPropertyKey, value: number | undefined) => void;
};
type MaterialPropSelectInputProps = MaterialPropBaseInputProps & {
  defaultValue: number;
  options: SelectOption[];
  onChange: (key: MaterialPropertyKey, value: number | undefined) => void;
};

export const MaterialPropFormControlLabel: React.FC<MaterialPropFormControlLabelProps> = ({
  'data-cmptype': dataCmpType,
  displayName,
  searchValue,
  control,
  disabled,
  disableLabelClick,
  setMaxWidth,
}) => {
  const label = (
    <span className="text-m-regular">
      <HighlightedText text={displayName} highlight={searchValue} />
    </span>
  );
  // max width is useful for number or select controls, which should have a certain width in the grid
  const wrappedControl = <div className={cn('flex', { 'max-w-[250px] grow': setMaxWidth })}>{control}</div>;

  // some properties should not act on form control label clicks, so we use a standard span <=> control combination in
  // these case
  // eg: for boolean properties, as the label is very far away from the actual switch control
  return disableLabelClick ? (
    <div
      data-cmptype={dataCmpType}
      // handle "disabled" state explicitely, so that styling fits to disabled `FormControlLabel`
      className={cn(
        'flex h-12 w-full items-center justify-between border-t border-neutral-40 pl-6 pr-4 hover:bg-neutral-20',
        disabled && 'text-neutral-60'
      )}
    >
      {label}
      {wrappedControl}
    </div>
  ) : (
    <FormControlLabel
      data-cmptype={dataCmpType}
      className="flex h-12 w-full items-center justify-between border-t border-neutral-40 pl-6 pr-4 hover:bg-neutral-20"
      labelPlacement="start"
      label={label}
      disabled={disabled}
      control={wrappedControl}
    ></FormControlLabel>
  );
};

/**
 * Input component for boolean properties with a switch
 */
export const MaterialPropBooleanInput: React.FC<MaterialPropBooleanInputProps> = ({
  inputObj,
  objKey,
  disabled,
  displayName,
  searchValue,
  defaultValue,
  onChange,
}) => {
  const currentValue = getValueByMaterialPropertyKey<boolean>(inputObj, objKey);

  return (
    <MaterialPropFormControlLabel
      data-cmptype="MaterialPropBooleanInput"
      displayName={displayName}
      searchValue={searchValue}
      disabled={disabled}
      disableLabelClick
      control={
        <Switch
          checked={currentValue ?? defaultValue}
          onValueChanged={(value: boolean): void => onChange(objKey, value)}
          disabled={disabled}
        />
      }
    />
  );
};

/**
 * Input component for number properties with a html input element
 */
export const MaterialPropNumberInput: React.FC<MaterialPropNumberInputProps> = ({
  inputObj,
  objKey,
  disabled,
  displayName,
  searchValue,
  defaultValue,
  onChange,
}) => {
  const isEditing = useRef(false);
  const propValue = getValueByMaterialPropertyKey<number>(inputObj, objKey);
  const displayedValue = isEditing.current ? propValue : propValue ?? defaultValue;

  const onChangeInput = useCallback(
    (valueAsNumber: NumberOrNull, isInvalid: boolean): void => {
      if (!isInvalid) {
        isEditing.current = true;
        onChange(objKey, valueAsNumber ?? undefined);
      }
    },
    [objKey, onChange]
  );

  const onBlur = (): void => {
    if (!isEditing.current) {
      return;
    }

    if (defaultValue !== undefined && propValue === undefined) {
      onChange(objKey, defaultValue);
    }
    isEditing.current = false;
  };

  return (
    <MaterialPropFormControlLabel
      data-cmptype="MaterialPropNumberInput"
      displayName={displayName}
      searchValue={searchValue}
      setMaxWidth
      disabled={disabled}
      control={
        <NumberInput
          inputMode="decimal"
          value={displayedValue}
          onChange={onChangeInput}
          onBlur={onBlur}
          disabled={disabled}
          restoreValidValueOnBlur
        />
      }
    />
  );
};

export const MaterialPropSelectInput: React.FC<MaterialPropSelectInputProps> = ({
  inputObj,
  objKey,
  disabled,
  displayName,
  searchValue,
  defaultValue,
  options,
  onChange,
}) => {
  const currentValue = getValueByMaterialPropertyKey<number>(inputObj, objKey);

  const onInputChange = (value: SelectValue): void => {
    // exchange "NULL_VALUE" with undefined to remove the value from the object
    const nullableValue = value === NULL_VALUE ? undefined : (value as number);
    onChange(objKey, nullableValue);
  };

  return (
    <MaterialPropFormControlLabel
      data-cmptype="MaterialPropSelectInput"
      displayName={displayName}
      searchValue={searchValue}
      setMaxWidth
      disabled={disabled}
      disableLabelClick
      control={
        <Select options={options} value={currentValue ?? defaultValue} onChange={onInputChange} disabled={disabled} />
      }
    />
  );
};
