import { ReactElement } from 'react';
import { ConditionalWrapper } from 'components/common/conditional-wrapper';
import { Tooltip } from 'controls/tooltip/tooltip';
import {
  getFormattedDateAndTimeParts,
  getRelativeDate,
  unixTimestampToDate,
} from 'helper/date-and-time/date-and-time.helper';

type RelativeDateTimePropsFromTimestamp = { unixTime: number; date?: never };
type RelativeDateTimePropsFromDateTime = { date: Date; unixTime?: never };

type RelativeDateTimeProps = { onlyDate?: boolean; onlyTime?: boolean } & (
  | RelativeDateTimePropsFromTimestamp
  | RelativeDateTimePropsFromDateTime
);

export const RelativeDateTime: React.FC<RelativeDateTimeProps> = ({ onlyDate = false, onlyTime = false, ...rest }) => {
  const dateTimeSeparator = ' @ ';
  const unixTime = !rest.date ? rest.unixTime : rest.date.getTime() / 1000;
  const { date, time } = getFormattedDateAndTimeParts(unixTimestampToDate(unixTime));
  const tooltip = onlyDate ? date : onlyTime ? time : `${date}${dateTimeSeparator}${time}`;
  const relativeDate = getRelativeDate(unixTime, date);
  const showTooltip = relativeDate !== date;

  return (
    <ConditionalWrapper
      condition={showTooltip}
      wrapper={(children): ReactElement => (
        <Tooltip title={tooltip} delay={500} arrow interactive>
          {children}
        </Tooltip>
      )}
    >
      <span data-cmptype="RelativeDateTime">
        {!onlyTime && relativeDate}
        {!onlyDate && !onlyTime && dateTimeSeparator}
        {!onlyDate && time}
      </span>
    </ConditionalWrapper>
  );
};
