import { mapObject } from 'helper/object/object.helper';
import {
  CustomFieldValues,
  SetUserCustomFieldValuesRequest,
  UserCustomFieldRequest,
} from 'services/http/endpoints/companies.endpoints';
import { endpoints, httpClient } from 'services/http/http.service';

type UserCustomFieldDto = Omit<UserCustomFieldRequest, 'companyName'>;
export type UserCustomFields = { [fieldName: string]: UserCustomFieldDto };

export type UserCustomFieldValues = {
  [userId: string]: CustomFieldValues;
};

export async function getAllCompanyUserCustomFields(companyId: string): Promise<UserCustomFields> {
  const { url, params } = endpoints.companiesUserCustomFieldsGetAll(companyId);
  const res = await httpClient.get<UserCustomFields>(url, { params });

  return mapObject(res.data, (value, key) => ({ ...value, name: key }));
}

export async function createUserCustomField(customFieldRequest: UserCustomFieldRequest): Promise<void> {
  const { url, params } = endpoints.companiesUserCustomFieldsCreate(customFieldRequest);
  await httpClient.post<UserCustomFieldDto>(url, params);
}

export async function updateUserCustomField(customFieldRequest: UserCustomFieldRequest): Promise<void> {
  const { url, params } = endpoints.companiesUserCustomFieldsUpdate(customFieldRequest);
  await httpClient.post<UserCustomFieldDto>(url, params);
}

export async function deleteUserCustomField(companyId: string, fieldName: string): Promise<void> {
  const { url, params } = endpoints.companiesUserCustomFieldsDelete(companyId, fieldName);
  await httpClient.delete(url, { params });
}

export async function setUserCustomFieldValues(customFieldRequest: SetUserCustomFieldValuesRequest): Promise<void> {
  Object.entries(customFieldRequest.fields).forEach(([key, value]) => {
    // revert to default value in case of empty values (`0` or `false` are still valid values)
    if (value === null || value === undefined || value === '') {
      delete customFieldRequest.fields[key];
    }
  });
  const { url, params } = endpoints.companiesUserCustomFieldsSetValues(customFieldRequest);
  await httpClient.post<UserCustomFieldDto>(url, params);
}
