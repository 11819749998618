import { Menu, MenuProps, styled } from '@mui/material';

export type DraftMenuProps = Pick<MenuProps, 'children' | 'anchorEl' | 'open'> & {
  onClose: () => void;
  closeOnClick?: boolean;
  contextMenuItems?: any;
};

const StyledMenu = styled((props: MenuProps) => <Menu elevation={0} {...props} />)(({ theme }) => ({
  '& .MuiPaper-root': {
    'borderRadius': theme.shape.borderRadiusRoundedLg,
    'padding': theme.spacing(2, 0),
    'border': '1px solid ' + theme.palette.neutral[40],
    'color': theme.palette.neutral[70],
    'boxShadow': theme.shadows[6],
    '& .MuiMenu-list': {
      padding: '0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:hover': {
        backgroundColor: theme.palette.neutral[30],
      },
    },
  },
}));

export const DraftMenu: React.FC<DraftMenuProps> = ({ children, anchorEl, open, closeOnClick, onClose }) => {
  const handleClose = (): void => {
    onClose?.();
  };

  const handleClick = (): void => {
    if (closeOnClick) {
      handleClose();
    }
  };

  return (
    <StyledMenu
      open={open}
      onClose={handleClose}
      onClick={handleClick}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      className="mx-1"
    >
      {children}
    </StyledMenu>
  );
};
