import { useEffect, useState } from 'react';
import { Button } from 'controls/button/button';
import { Card } from 'controls/card/card';
import { FormItem } from 'controls/form-item/form-item';
import { FormControlLabel } from 'controls/form/form-control-label';
import { NumberInput, NumberOrNull } from 'controls/input/number-input';
import { TextInput } from 'controls/input/text-input';
import { Select, SelectOption } from 'controls/select/select';
import { Switch } from 'controls/switch/switch';
import { CompanyStates } from 'generated/company-states';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { CompanyFeatures, postCompaniesUpdateAdminDetails } from 'services/companies/companies.service';
import {
  getCompaniesAndPermissionsIfNeeded,
  getCompanyDetailsThunk,
  selectCompanyDetails,
  selectSelectedCompany,
} from 'slices/company-data/company-data.slice';

type CompanyFeaturesWithAllPropsRequired = Required<CompanyFeatures>;

type BoolKeys<T> = {
  [K in keyof T]: T[K] extends boolean ? K : never;
}[keyof T];

type BoolCompanyFeatures = Pick<CompanyFeatures, BoolKeys<CompanyFeaturesWithAllPropsRequired>>;
type BoolCompanyFeaturesConfig = Record<keyof BoolCompanyFeatures, { displayName: string; invert?: boolean }>;

const _BOOL_COMPANY_FEATURES_UI_CONFIG: BoolCompanyFeaturesConfig = {
  workflows: { displayName: 'Workflows' },
  adFree: { displayName: 'Remove Ads' },
  legacyStorage: { displayName: 'Legacy Store' },
  // flag is stored inverted on server, so that companies don't have to be migrated
  // still it should be shown as "positive feature flag" in the UI
  disableCustomCode: { displayName: 'Custom Code', invert: true },
  arBranding: { displayName: 'AR Branding' },
} as const;

type NumericKeys<T> = {
  [K in keyof T]: T[K] extends number ? K : never;
}[keyof T];

type NumericCompanyFeatures = Pick<CompanyFeatures, NumericKeys<CompanyFeaturesWithAllPropsRequired>>;
type NumericCompanyFeaturesConfig = Record<keyof NumericCompanyFeatures, { displayName: string }>;

const _NUMERIC_COMPANY_FEATURES_UI_CONFIG: NumericCompanyFeaturesConfig = {
  maxDomainsPerCfgr: { displayName: 'Max. number of whitelist domains, empty = unlimited' },
  maxCfgrStages: { displayName: 'Max. number of stages per configurator (0 - 5)' },
  maxUserCustomFields: { displayName: 'Max. number of user custom fields (0 - 20)' },
  maxCfgnInsightsValues: { displayName: 'Max. number of insights values (0 - 10)' },
} as const;

export const AdminCompanyFeatures: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const selectedCompanyDetails = useAppSelector(selectCompanyDetails);
  const companyId = selectedCompany?.id ?? '';
  const currentFeatures = selectedCompany?.companyFeatures;
  const currentState = selectedCompany?.state;

  const [isProcessing, setIsProcessing] = useState(false);
  const [editedFeatures, setEditedFeatures] = useState<CompanyFeatures>({
    adFree: false,
    legacyStorage: false,
    workflows: false,
    disableCustomCode: false,
    arBranding: false,
    maxDomainsPerCfgr: undefined,
    maxCfgrStages: undefined,
    maxUserCustomFields: undefined,
    maxCfgnInsightsValues: undefined,
  });
  const [editedState, setEditedState] = useState<CompanyStates>(CompanyStates.Free);
  const [subscriptionDisplayName, setSubscriptionDisplayName] = useState('');
  const [subscriptionFee, setSubscriptionFee] = useState(0);
  const [subscriptionInvoiceDay, setSubscriptionInvoiceDay] = useState(0);

  useEffect(
    function fetchCompanyDetailsOnLoadAndExternalChanges() {
      dispatch(getCompanyDetailsThunk({ companyId }));
    },
    [companyId, dispatch]
  );

  useEffect(
    function updateSubscriptionFormValues() {
      setSubscriptionDisplayName(selectedCompanyDetails?.billingInformation.subscriptionDisplayName ?? '');
      setSubscriptionFee(selectedCompanyDetails?.billingInformation.monthlySubscriptionFee ?? 0);
      setSubscriptionInvoiceDay(selectedCompanyDetails?.billingInformation.invoiceDay ?? 0);
    },
    [selectedCompanyDetails]
  );

  useEffect(
    function syncFeaturesWithStore() {
      if (!currentFeatures) {
        return;
      }
      setEditedFeatures(currentFeatures);
    },
    [currentFeatures]
  );

  useEffect(
    function syncStateWithStore() {
      if (!currentState) {
        return;
      }
      setEditedState(currentState);
    },
    [currentState]
  );

  const toggleBoolFeatureValue = (key: keyof BoolCompanyFeatures): void => {
    setEditedFeatures(features => {
      return { ...features, [key]: !features[key] };
    });
  };

  const setNumberFeatureValue = (key: keyof NumericCompanyFeatures, value: number | undefined): void => {
    setEditedFeatures(features => {
      return { ...features, [key]: value };
    });
  };

  const onSubmitForm = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    if (!selectedCompany) {
      throw new Error("Features can't be updated! No company selected.");
    }
    setIsProcessing(true);
    await postCompaniesUpdateAdminDetails(
      selectedCompany.id,
      editedFeatures,
      editedState,
      subscriptionDisplayName,
      subscriptionFee,
      subscriptionInvoiceDay
    );
    await Promise.all([
      dispatch(getCompaniesAndPermissionsIfNeeded({ forceFetch: true })),
      dispatch(getCompanyDetailsThunk({ companyId })),
    ]);
    setIsProcessing(false);
  };

  // only allow a subset of all company state (for now)
  const stateOptions: SelectOption[] = [CompanyStates.Free, CompanyStates.Active, CompanyStates.Suspended].map(
    state => ({ value: state, text: CompanyStates[state] })
  );

  if (!selectedCompany) {
    return <Card headerText={t('Company features and state')}>{t('Please select a company first!')}</Card>;
  }

  return (
    <Card
      headerText={t('Company features and state')}
      subText={`${selectedCompany.displayName} (${selectedCompany.id})`}
      data-cmptype="AdminCompanyFeatures"
    >
      <form className="flex min-w-[300px] flex-col gap-4" onSubmit={onSubmitForm}>
        <FormItem labelContent={t('Features')}>
          <div className="flex flex-col">
            {Object.entries(_BOOL_COMPANY_FEATURES_UI_CONFIG).map(([feature, uiConfig]) => {
              const featureName = feature as keyof BoolCompanyFeatures;
              const featureValue = editedFeatures[featureName];
              return (
                <FormControlLabel
                  key={feature}
                  label={t(uiConfig.displayName)}
                  control={
                    <Switch
                      checked={uiConfig.invert ? !featureValue : featureValue}
                      onValueChanged={(): void => toggleBoolFeatureValue(featureName)}
                    />
                  }
                />
              );
            })}
          </div>
        </FormItem>

        {Object.entries(_NUMERIC_COMPANY_FEATURES_UI_CONFIG).map(([feature, uiConfig]) => {
          const featureName = feature as keyof NumericCompanyFeatures;
          const featureValue = editedFeatures[featureName];
          return (
            <FormItem key={feature} labelContent={t(uiConfig.displayName)}>
              <NumberInput
                inputMode="numeric"
                value={featureValue}
                onChange={(value: NumberOrNull): void => setNumberFeatureValue(featureName, value ?? undefined)}
              />
            </FormItem>
          );
        })}

        <FormItem labelContent={t('State')}>
          <Select
            options={stateOptions}
            value={editedState}
            onChange={(value): void => setEditedState(value as CompanyStates)}
          />
        </FormItem>

        <FormItem labelContent={t('Subscription display name')}>
          <TextInput value={subscriptionDisplayName} onValueChange={setSubscriptionDisplayName} />
        </FormItem>

        <FormItem labelContent={t('Monthly subscription fee')}>
          <NumberInput
            inputMode="decimal"
            value={subscriptionFee}
            onChange={(value: NumberOrNull): void => setSubscriptionFee(value ?? 0)}
          />
        </FormItem>

        <FormItem labelContent={t('Invoice day (day in month, 1 - 28)')}>
          <NumberInput
            inputMode="numeric"
            value={subscriptionInvoiceDay}
            onChange={(value: NumberOrNull): void => setSubscriptionInvoiceDay(value ?? 0)}
            onlyPositive
          />
        </FormItem>

        <div className="flex items-center gap-2">
          <Button text={t(`Update "${selectedCompany.displayName}"`)} type="submit" isLoading={isProcessing} />
        </div>
      </form>
    </Card>
  );
};
