import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { PlusInCircleIcon, TrashIcon } from 'assets/icons';
import { Button } from 'controls/button/button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnCollapsibleCardHeader } from 'controls/cbn-card/cbn-collapsible-card-header';
import { CbnNestedCard } from 'controls/cbn-card/cbn-nested-card';
import { CbnToggleButtonGroup, CbnToggleButtonOption } from 'controls/cbn-toggle-button/cbn-toggle-button-group';
import { FormControlLabel } from 'controls/form/form-control-label';
import { TextInput } from 'controls/input/text-input';
import { Switch } from 'controls/switch/switch';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'controls/table';
import { Tooltip } from 'controls/tooltip/tooltip';
import { WebhookTypes } from 'generated/webhook-types';
import { CBN_MAIL_ADRESSES } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import {
  EDIT_WORKFLOW_CONFIG_ERROR_RESPONSE_MSGS,
  EditWorkflowConfigErrorResponse,
  WorkflowNotificationConfig,
} from 'services/workflows/workflows.service';

type WorkflowConfigNotificationsProps = {
  curNotificationConfig: WorkflowNotificationConfig;
  syncedNotificationConfig?: WorkflowNotificationConfig;
  onNotificationConfigChanged: (newConfig: WorkflowNotificationConfig) => void;
  errorId: EditWorkflowConfigErrorResponse | undefined;
  onResetError: () => void;
};

export const WorkflowConfigNotifications: React.FC<WorkflowConfigNotificationsProps> = ({
  curNotificationConfig,
  syncedNotificationConfig,
  onNotificationConfigChanged,
  errorId,
  onResetError,
}) => {
  const { t } = useTypedTranslation();

  const emailsActive = !!curNotificationConfig.emailAddresses;
  const curEmailAddresses = curNotificationConfig.emailAddresses ?? [];
  const syncedEmailAddresses = syncedNotificationConfig?.emailAddresses ?? [];
  const webhookActive = !!curNotificationConfig.webhookConfig;
  const url = curNotificationConfig.webhookConfig?.url;
  const webhookType = curNotificationConfig.webhookConfig?.type;
  const hasInvalidUrlError = errorId === 'InvalidUri';
  const hasInvalidMailAddressError = errorId === 'InvalidMailAddress';

  const [curEmailAddress, setCurEmailAddress] = useState('');
  const invalidEmailFormat = !curEmailAddress.includes('@');
  const emailAlreadyAdded = curEmailAddresses.includes(curEmailAddress);

  const webhookTypes = Object.values(WebhookTypes);
  webhookTypes.splice(0, webhookTypes.length / 2);
  const webhookTypeOptions: CbnToggleButtonOption[] = (webhookTypes as number[]).map(type => ({
    value: type,
    text: WebhookTypes[type],
  }));

  const onEmailsActiveChange = (emailsActive: boolean): void => {
    const newNotificationConfig = cloneDeep(curNotificationConfig);

    if (emailsActive) {
      newNotificationConfig.emailAddresses = [];
    } else {
      delete newNotificationConfig.emailAddresses;
    }

    onNotificationConfigChanged(newNotificationConfig);

    if (hasInvalidMailAddressError) {
      onResetError();
    }
  };

  const onAddMailAddress = (newMailAddress: string): void => {
    const newNotificationConfig = cloneDeep(curNotificationConfig);

    newNotificationConfig.emailAddresses = newNotificationConfig.emailAddresses ?? [];
    newNotificationConfig.emailAddresses.push(newMailAddress);

    onNotificationConfigChanged(newNotificationConfig);
    setCurEmailAddress('');
  };

  const onRemoveMailAddress = (idxToRemove: number): void => {
    const newNotificationConfig = cloneDeep(curNotificationConfig);

    newNotificationConfig.emailAddresses!.splice(idxToRemove, 1);
    onNotificationConfigChanged(newNotificationConfig);

    if (hasInvalidMailAddressError) {
      onResetError();
    }
  };

  const onWebhookActiveChange = (webhookActive: boolean): void => {
    const newNotificationConfig = cloneDeep(curNotificationConfig);

    if (webhookActive) {
      newNotificationConfig.webhookConfig = { url: '', type: WebhookTypes.Raw };
    } else {
      delete newNotificationConfig.webhookConfig;
    }

    onNotificationConfigChanged(newNotificationConfig);

    if (hasInvalidUrlError) {
      onResetError();
    }
  };

  const onChangeUrl = (newUrl: string): void => {
    const newNotificationConfig = cloneDeep(curNotificationConfig);

    newNotificationConfig.webhookConfig!.url = newUrl;
    onNotificationConfigChanged(newNotificationConfig);
    if (hasInvalidUrlError) {
      onResetError();
    }
  };

  const onChangeWebhookType = (newWebhookType: WebhookTypes): void => {
    const newNotificationConfig = cloneDeep(curNotificationConfig);

    newNotificationConfig.webhookConfig!.type = newWebhookType;
    onNotificationConfigChanged(newNotificationConfig);
  };

  return (
    <CbnCard data-cmptype="WorkflowConfigNotifications" isCollapsible>
      <CbnCollapsibleCardHeader title={t('Notifications')} subText={t('Get notified whenever a workflow fails.')} />
      <CbnCardBody>
        <div className="flex max-w-2xl flex-col gap-4">
          <CbnNestedCard>
            <FormControlLabel
              label={t('Email')}
              labelPlacement="start"
              control={<Switch checked={emailsActive} onValueChanged={onEmailsActiveChange} />}
              className="flex justify-between gap-2 text-l-medium"
            />
            {emailsActive && (
              <div className="flex flex-col gap-4 pt-4">
                {!!curEmailAddresses.length && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('Existing email addresses')}</TableCell>
                        <TableCell className="w-0 text-nowrap">{t('Remove email address')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {curEmailAddresses.map((email, idx) => {
                        const isUnsaved = !syncedEmailAddresses.includes(email);

                        return (
                          <TableRow key={idx}>
                            <TableCell>
                              <div className="my-1 flex flex-row items-center gap-2">
                                <span className="text-m-regular text-neutral-90">{email}</span>
                                {isUnsaved && (
                                  <Tooltip title={t('Email address is not saved yet')}>
                                    <i className="block h-2 w-2 rounded-full bg-warning-main" />
                                  </Tooltip>
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="flex justify-center">
                                <Button
                                  variant="TextInline"
                                  Svg={TrashIcon}
                                  hoverColor="Danger"
                                  onClick={(): void => onRemoveMailAddress(idx)}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}

                <form
                  className="flex flex-col gap-2"
                  onSubmit={(event: React.FormEvent): void => {
                    event.preventDefault();
                    onAddMailAddress(curEmailAddress);
                  }}
                >
                  <TextInput
                    value={curEmailAddress}
                    onValueChange={setCurEmailAddress}
                    placeholder={CBN_MAIL_ADRESSES.placeholderMail}
                  />
                  <div className="flex items-center gap-2">
                    <Button
                      variant="Secondary"
                      text={t('Add')}
                      Svg={PlusInCircleIcon}
                      type="submit"
                      disabled={invalidEmailFormat || emailAlreadyAdded}
                      title={
                        invalidEmailFormat
                          ? t('Enter valid email address')
                          : emailAlreadyAdded
                            ? t('Email address is already added')
                            : ''
                      }
                    />
                    {hasInvalidMailAddressError && (
                      <span className="mx-1 text-s-regular text-danger-main">
                        {t(EDIT_WORKFLOW_CONFIG_ERROR_RESPONSE_MSGS.InvalidMailAddress)}
                      </span>
                    )}
                  </div>
                </form>
              </div>
            )}
          </CbnNestedCard>
          <CbnNestedCard>
            <FormControlLabel
              label={t('Webhook | Slack')}
              labelPlacement="start"
              control={<Switch checked={webhookActive} onValueChanged={onWebhookActiveChange} />}
              className="flex justify-between gap-2 text-l-medium"
            />
            {webhookActive && (
              <div className="flex flex-col gap-3 pt-4">
                <FormControlLabel
                  label={t('Format')}
                  labelPlacement="start"
                  control={
                    <CbnToggleButtonGroup
                      exclusive
                      entries={webhookTypeOptions}
                      value={webhookType!}
                      onValueChange={(value): void => onChangeWebhookType(value as number)}
                    />
                  }
                  className="justify-end gap-2"
                />
                <FormControlLabel
                  label={t('Uri')}
                  labelPlacement="start"
                  control={
                    <TextInput
                      value={url}
                      onValueChange={onChangeUrl}
                      placeholder={t('Add webhook uri')}
                      error={hasInvalidUrlError}
                      helperText={hasInvalidUrlError ? t(EDIT_WORKFLOW_CONFIG_ERROR_RESPONSE_MSGS.InvalidUri) : ''}
                    />
                  }
                  className="gap-2"
                />
              </div>
            )}
          </CbnNestedCard>
        </div>
      </CbnCardBody>
    </CbnCard>
  );
};
