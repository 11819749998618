import classNames from 'classnames';

export type ListItemProps = {
  children: React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
  title?: string;
  onClick?: () => void;
};

export const ListItem: React.FC<ListItemProps> = ({ children, selected, disabled, title, onClick }) => {
  return (
    <li
      className={classNames(
        /* Special group selector: If the parent `group` has a border, then hide the border of the last child */
        'flex h-14 select-none items-center border-b border-neutral-40 px-4 text-s-medium font-semibold  group-default:border-b-0 group-[.border]:last:border-b-0',
        {
          'bg-primary-surface': selected,
          'cursor-pointer text-primary-main hover:bg-neutral-20': !disabled,
          'text-neutral-50': disabled,
        }
      )}
      onClick={!disabled ? onClick : undefined}
      title={title}
    >
      {children}
    </li>
  );
};
