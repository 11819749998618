import React from 'react';
import { CloudUploadIcon } from 'assets/icons';
import { FileSelectButton } from 'controls/button/file-select-button';
import { Icon } from 'controls/icon/icon';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type CbnDragAndDropPromptProps = {
  onUploadFilesSelected: (files: File[]) => void;
};

export const CbnDragAndDropPrompt: React.FC<CbnDragAndDropPromptProps> = ({ onUploadFilesSelected }) => {
  const { t } = useTypedTranslation();

  return (
    <div
      data-cmptype="CbnDragAndDropPrompt"
      className="flex flex-col items-center gap-2 rounded-lg border-2 border-dashed border-neutral-60 bg-neutral-20 p-4 pt-6"
    >
      <Icon Svg={CloudUploadIcon} className="w-9" />
      <span className="text-l-medium text-neutral-100">{t('Drag and Drop files')}</span>
      <span className="text-l-regular text-neutral-100">{t('or')}</span>
      <FileSelectButton variant="Outlined" text={t('Browse files')} onSelect={onUploadFilesSelected} multiple />
    </div>
  );
};
