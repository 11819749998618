import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { GridColumnMenu, GridColumnMenuItemProps, GridColumnMenuProps, useGridApiContext } from '@mui/x-data-grid-pro';
import { useCallback } from 'react';
import { EyeHiddenIcon } from 'assets/icons';
import { Icon } from 'controls/icon/icon';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

const ColumnMenuCustomHideItem: React.FC<GridColumnMenuItemProps> = ({ colDef }) => {
  const apiRef = useGridApiContext();
  const { t } = useTypedTranslation();

  const toggleColumn = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      apiRef.current.setColumnVisibility(colDef.field, false);
      //onClick(event);
    },
    [apiRef, colDef.field]
  );

  return (
    <MenuItem onClick={toggleColumn}>
      <ListItemIcon>
        <Icon Svg={EyeHiddenIcon} className="w-5" />
      </ListItemIcon>
      <ListItemText>{t('Hide column')}</ListItemText>
    </MenuItem>
  );
};

export const ColumnMenuWithHideColumn: React.FC<GridColumnMenuProps> = props => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuCustomHideItem: ColumnMenuCustomHideItem,
      }}
      slotProps={{}}
    />
  );
};
