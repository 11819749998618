import { CfgrMainStateCard } from 'components/cfgrs/cfgr-main-state-card';
import { EmbeddingCode } from 'components/cfgrs/embedding/embedding-code';
import { EmbeddingShopsCard } from 'components/cfgrs/embedding/embedding-shops-card';
import { EmbeddingWhiteListDomainsCard } from 'components/cfgrs/embedding/embedding-whitelist-domains-card';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectSelectedConfigurator } from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

export const Embedding: React.FC = () => {
  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const hasManageWhitelistPermission = usePermission(CompanyPermissions.ManageWhitelist, selectedCfgr?.companyId);

  return (
    <div className="flex flex-col gap-5">
      <CfgrMainStateCard variant="Dashboard" />

      <div className="flex flex-row flex-wrap gap-5">
        <div className="flex-1">
          <EmbeddingCode />
        </div>

        {hasManageWhitelistPermission && (
          <div className="flex-1">
            <EmbeddingWhiteListDomainsCard />
          </div>
        )}
      </div>

      <EmbeddingShopsCard />
    </div>
  );
};
