import { Checkbox as MuiCheckbox, Theme, styled } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { CheckIcon } from 'assets/icons';
import { Icon } from 'controls/icon/icon';

export type CheckboxProps = {
  id?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  checked?: boolean;
  onValueChanged?: (value: boolean) => void;
};

const BaseCbIcon: React.FC<{ checked?: boolean; indeterminate?: boolean }> = ({ checked, indeterminate, ...props }) => {
  return (
    <span {...props}>
      {indeterminate && <div className="h-2.5 w-2.5"></div>}
      {checked && <Icon Svg={CheckIcon} className="h-4 w-4 text-neutral-10" />}
    </span>
  );
};

const StyledCbIcon = styled(BaseCbIcon)(({ theme }) => ({
  'borderRadius': theme.shape.borderRadiusCtrls,
  'width': 20,
  'height': 20,
  'border': `1px solid ${theme.palette.neutral[50]}`,
  'input:focus ~ &': {
    borderColor: theme.palette.primary.main,
    outline: theme.outlines.large,
  },
  'input:hover ~ &': {
    borderColor: theme.palette.primary.main,
  },
  'input:disabled ~ &': {
    borderColor: theme.palette.neutral[50],
    backgroundColor: theme.palette.neutral[30],
  },
}));

const StyledCbIconChecked = styled(props => <BaseCbIcon checked {...props} />)(({ theme }) => ({
  'borderRadius': theme.shape.borderRadiusCtrls,
  'width': 20,
  'height': 20,
  'background': theme.palette.primary.main,
  'display': 'flex',
  'justifyContent': 'center',
  'alignItems': 'center',
  'input:focus ~ &': {
    outline: theme.outlines.large,
  },
  'input:disabled ~ &': {
    borderColor: theme.palette.neutral[50],
    backgroundColor: theme.palette.neutral[50],
  },
}));

const StyledCbIconIndeterminate = styled(props => <BaseCbIcon indeterminate {...props} />)(({ theme }) => ({
  'borderRadius': theme.shape.borderRadiusCtrls,
  'width': 20,
  'height': 20,
  'border': `1px solid ${theme.palette.neutral[50]}`,
  'display': 'flex',
  'justifyContent': 'center',
  'alignItems': 'center',
  'input ~ & > div': {
    background: theme.palette.primary.main,
  },
  'input:hover ~ &': {
    borderColor: theme.palette.primary.main,
  },
  'input:focus ~ &': {
    borderColor: theme.palette.primary.main,
    outline: theme.outlines.large,
  },
  'input:disabled ~ &': {
    borderColor: theme.palette.neutral[50],
    backgroundColor: theme.palette.neutral[30],
    div: {
      background: theme.palette.neutral[50],
    },
  },
}));

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  disabled,
  indeterminate,
  checked,
  onValueChanged,
  ...other
}) => {
  return (
    <MuiCheckbox
      id={id}
      disabled={disabled}
      sx={(theme): SystemStyleObject<Theme> => ({
        padding: '3px', // required for the outline when focused
      })}
      disableRipple
      checked={checked}
      onChange={(_, checked): void => onValueChanged?.(checked)}
      indeterminate={indeterminate}
      icon={<StyledCbIcon />}
      checkedIcon={<StyledCbIconChecked />}
      indeterminateIcon={<StyledCbIconIndeterminate />}
      {...other}
    />
  );
};
