import { LockIcon } from 'assets/icons';
import { ReplicatorLockStatusInfo } from 'components/common/replicator-lock-status-info';
import { Card } from 'controls/card/card';
import { Icon } from 'controls/icon/icon';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { isReplicatorLockStatus } from 'services/company-data/company-data.service';
import { selectSelectedConfigurator } from 'slices/company-data/company-data.slice';

export const CfgrLockInfo: React.FC = () => {
  const { t } = useTypedTranslation();
  const selectedCfgr = useAppSelector(selectSelectedConfigurator);

  return (
    <div className="flex" data-cmptype="CfgrLockInfo">
      <Card
        headerCmp={
          <div className="flex items-center gap-2 text-m-regular font-semibold">
            <Icon className="h-8 w-8 text-warning-main" Svg={LockIcon} />
            <div>{t('Configurator locked')}</div>
          </div>
        }
      >
        {selectedCfgr?.lockStatus && isReplicatorLockStatus(selectedCfgr.lockStatus) && (
          <ReplicatorLockStatusInfo
            key={selectedCfgr.id}
            lockStatus={selectedCfgr.lockStatus}
            lockedEntity="Configurator"
          />
        )}
      </Card>
    </div>
  );
};
