import * as cronParser from 'cron-parser';
import { CronExpression } from 'cron-parser';
import { useEffect, useState } from 'react';

export const useCronExpression = (
  rawInitialValue?: string
): [string, cronParser.StringResult | undefined, boolean, React.Dispatch<React.SetStateAction<string>>] => {
  const [rawValue, setRawValue] = useState(rawInitialValue ?? '');
  const [parsedValue, setParsedValue] = useState(rawInitialValue ? cronParser.parseString(rawInitialValue) : undefined);

  const isEmptyRawValue = rawValue.length === 0;
  const valid = isEmptyRawValue || (!!parsedValue && Object.keys(parsedValue.errors).length === 0);

  useEffect(() => {
    setParsedValue(!isEmptyRawValue ? cronParser.parseString(rawValue) : undefined);
  }, [rawValue, isEmptyRawValue]);

  return [rawValue, parsedValue, valid, setRawValue];
};

function _getNextScheduledCronTimes(
  cronExpression: string | undefined,
  numberOfNextIntervals = 1,
  utc = true
): Date[] | undefined {
  if (!cronExpression) {
    return undefined;
  }

  let parsed: CronExpression | undefined = undefined;
  try {
    parsed = cronParser.parseExpression(cronExpression, { utc: utc });
  } catch {
    return undefined;
  }

  if (!parsed || !parsed.hasNext()) {
    return undefined;
  }

  const intervals = new Array(numberOfNextIntervals).fill(undefined).map(_ => parsed!.next().toDate());
  return intervals;
}

/**
 * Returns the next scheduled time and automatically updates whenever this time is reached
 */
export const useCronScheduler = (
  cronExpression: string | undefined,
  numberOfNextIntervals?: number,
  utc = true
): [Date | undefined, Date[]] => {
  const [nextIntervals, setNextIntervals] = useState<Date[]>([]);
  const [timerToggle, setTimerToggle] = useState(false);
  const nextScheduledTime = nextIntervals ? nextIntervals[0] : undefined;

  useEffect(
    function updateOnToggleChange() {
      const intervals = _getNextScheduledCronTimes(cronExpression, numberOfNextIntervals, utc);
      setNextIntervals(intervals ?? []);
    },
    [cronExpression, timerToggle, numberOfNextIntervals, utc]
  );

  useEffect(
    function continuousScheduleUpdate() {
      let timeoutId = 0;

      if (nextScheduledTime) {
        const waitTime = nextScheduledTime.getTime() - new Date().getTime();
        if (waitTime > 0) {
          const additionalTime = 100; // add some time so that it's definitely past the scheduled time
          timeoutId = window.setTimeout(() => {
            setTimerToggle(t => !t);
          }, waitTime + additionalTime);
        }
      }

      return () => window.clearTimeout(timeoutId);
    },
    [nextScheduledTime]
  );

  return [nextScheduledTime, nextIntervals];
};
