import { useState } from 'react';
import { UserProfileInput } from 'components/user-profile/user-profile-input';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { Card } from 'controls/card/card';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { UpdatePasswordReponse, postAccountUpdatePassword } from 'services/auth/auth.service';

type ValidationMap = {
  errorText: string;
  errorField: 'Old' | 'New' | 'General' | 'None';
};

// map for converting change password responses from the server to UI data
const _RESPONSE_VALIDATION_MAP: Record<UpdatePasswordReponse, ValidationMap> = {
  Success: { errorText: '', errorField: 'None' },
  OldPasswordNotValid: { errorText: 'Old password is incorrect.', errorField: 'Old' },
  InvalidPassword: { errorText: 'New password is invalid, check password requirements.', errorField: 'New' },
  UnknownResponse: {
    errorText: 'Some error occurred. Try another password or contact an admin.',
    errorField: 'General',
  },
};

export const UserProfilePassword: React.FC = () => {
  const { t } = useTypedTranslation();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');

  const [oldPasswordErrorText, setOldPasswordErrorText] = useState('');
  const [newPasswordErrorText, setNewPasswordErrorText] = useState('');
  const [repeatNewPasswordErrorText, setRepeatNewPasswordErrorText] = useState('');
  const [generalErrorText, setGeneralErrorText] = useState('');
  const [passwordFieldsAdjusted, setPasswordFieldsAdjusted] = useState(false);

  const disableUpdatePassword =
    !oldPassword.length ||
    !newPassword.length ||
    !repeatNewPassword.length ||
    !!oldPasswordErrorText ||
    !!newPasswordErrorText ||
    !!repeatNewPasswordErrorText ||
    !!generalErrorText ||
    !passwordFieldsAdjusted;

  const onChangeOldPassword = (value: string): void => {
    setOldPassword(value);
    setOldPasswordErrorText('');
    setGeneralErrorText('');
    setPasswordFieldsAdjusted(true);
  };

  const onChangeNewPassword = (value: string): void => {
    setNewPassword(value);
    setNewPasswordErrorText('');
    setRepeatNewPasswordErrorText('');
    setGeneralErrorText('');
    setPasswordFieldsAdjusted(true);
  };

  const onChangeRepeatNewPassword = (value: string): void => {
    setRepeatNewPassword(value);
    setRepeatNewPasswordErrorText('');
    setGeneralErrorText('');
    setPasswordFieldsAdjusted(true);
  };

  const onUpdatePassword = async (): Promise<void> => {
    if (newPassword !== repeatNewPassword) {
      // this can't be checked by the server so return immediately
      setRepeatNewPasswordErrorText(t('Repeat password is different.'));
      return;
    }

    const resp = await postAccountUpdatePassword(oldPassword, newPassword);
    const respValidation = _RESPONSE_VALIDATION_MAP[resp];

    switch (respValidation.errorField) {
      case 'Old':
        setOldPasswordErrorText(t(respValidation.errorText));
        break;
      case 'New':
        setNewPasswordErrorText(t(respValidation.errorText));
        break;
      case 'General':
        setGeneralErrorText(t(respValidation.errorText));
        break;
    }

    setPasswordFieldsAdjusted(false);
  };

  return (
    <Card data-cmptype="UserProfilePassword" headerText={t('Password')}>
      <form className="flex flex-col gap-2">
        <UserProfileInput
          labelText={t('Old password')}
          type={'Password'}
          value={oldPassword}
          onValueChange={onChangeOldPassword}
          error={!!oldPasswordErrorText || !!generalErrorText}
          errorText={oldPasswordErrorText}
        />
        <UserProfileInput
          labelText={t('New password')}
          type={'Password'}
          value={newPassword}
          onValueChange={onChangeNewPassword}
          autoComplete="new-password"
          error={!!newPasswordErrorText || !!generalErrorText}
          errorText={newPasswordErrorText}
        />
        <UserProfileInput
          labelText={t('Repeat password')}
          type={'Password'}
          value={repeatNewPassword}
          autoComplete="new-password"
          onValueChange={onChangeRepeatNewPassword}
          error={!!repeatNewPasswordErrorText || !!generalErrorText}
          errorText={repeatNewPasswordErrorText}
        />

        <span className="text-s-regular">
          {t(
            `Password has to be longer than 10 characters and contain at least 6 unique characters.
          Furthermore, it mustn't contain the email address or a password which was `
          )}
          <LinkButton
            variant="TextInline"
            href="https://haveibeenpwned.com/Passwords"
            target="_blank"
            text="exposed in a data breach"
          />
          .
        </span>

        {!!generalErrorText && <span className="text-s-regular text-danger-main">{generalErrorText}</span>}

        <Button
          variant="Primary"
          text={t('Change password')}
          onClick={onUpdatePassword}
          disabled={disableUpdatePassword}
        />
      </form>
    </Card>
  );
};
