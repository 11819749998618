import styles from './cbn-loading-spinner.module.css';
import { cn, tw } from 'helper/css.helper';

type CbnLoadingSpinnerProps = {
  small?: boolean;
};

// NOTE: this component is styled with vanilla CSS instead of TailwindCSS, as the code is just copied from the net
export const CbnLoadingSpinner: React.FC<CbnLoadingSpinnerProps> = ({ small }) => {
  const cssClasses = cn(styles['lds-spinner'], [styles.small] && small);
  const spinnerClasses = tw`after:bg-neutral-50`;

  // HTML code is taken from https://loading.io/css/
  return (
    <div data-cmptype="CbnLoadingSpinner" role="progressbar" className={cssClasses}>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
      <div className={spinnerClasses}></div>
    </div>
  );
};
