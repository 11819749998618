import React from 'react';
import { cn } from 'helper/css.helper';

export type FormItemProps = {
  labelContent?: React.ReactNode;
  subLabelContent?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  labelClassName?: string;
  /** Displays the label in 1 line with the input box & makes the text a bit smaller */
  labelInline?: boolean;
};

export const FormItem: React.FC<FormItemProps> = ({
  labelContent,
  subLabelContent,
  children,
  className,
  labelClassName,
  labelInline = false,
}) => {
  return (
    <div className={cn('flex grow flex-col gap-1', labelInline && 'flex-row items-center', className)}>
      {labelContent && (
        <label className={cn('inline-block text-m-regular text-neutral-100', !labelInline && 'mb-1', labelClassName)}>
          {subLabelContent ? (
            <>
              <div>{labelContent}</div>
              <div className="text-s-regular text-neutral-70">{subLabelContent}</div>
            </>
          ) : (
            labelContent
          )}
        </label>
      )}

      <div className="flex grow flex-row gap-2">{children}</div>
    </div>
  );
};
