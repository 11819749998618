import { Button as BaseButton } from '@mui/base/Button';
import classNames from 'classnames';
import { ChangeEvent, useRef } from 'react';
import { TEST_IDS } from 'testing/test-ids';
import { BaseButtonProps, buttonStyleFactory } from 'controls/button/button';
import { Icon } from 'controls/icon/icon';
import { Tooltip } from 'controls/tooltip/tooltip';

export type FileSelectButtonProps = BaseButtonProps & {
  multiple?: boolean;
  accept?: string;
  onSelect?: (files: File[]) => void;
  title?: string;
};

export const FileSelectButton: React.FC<FileSelectButtonProps> = ({
  variant = 'Primary',
  color,
  hoverColor,
  big,
  disabled,
  text,
  ariaLabel,
  Svg,
  multiple,
  accept,
  onSelect,
  title,
  grow,
}) => {
  const uploadRef = useRef<HTMLInputElement>(null);
  const wrapInTooltip = !!title;

  const onBtnClick = (event: React.MouseEvent<HTMLLabelElement>): void => {
    if (event.type !== 'click') {
      // keyboard input (space, enter) have to be passed down
      uploadRef.current?.click();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      onSelect?.(Array.from(e.currentTarget.files));
    }
  };

  const finalButton = (
    <BaseButton<'label'>
      slots={{ root: 'label' }}
      data-cmptype="FileSelectButton"
      className={classNames(buttonStyleFactory(false, variant, color, hoverColor, big, disabled, !!text?.length), {
        'flex-grow justify-center': grow,
        'pointer-events-none': disabled, // Tooltip: required to properly detect leave-events
      })}
      disabled={disabled}
      onClick={onBtnClick}
      aria-label={ariaLabel || text || title}
    >
      {Svg && <Icon Svg={Svg} className={text?.length ? 'h-4 w-4' : 'h-5 w-5'} />}
      {text}
      <input
        type="file"
        ref={uploadRef}
        hidden
        onClick={(e): string => (e.currentTarget.value = '')} // selection of the same file should trigger an onChange again
        onChange={onChange}
        multiple={multiple}
        accept={accept}
        // input elements with type "file" don't have an implicit role, see
        // - https://www.w3.org/TR/html-aria/#docconformance
        // setting the role explicitly is also not suitable, since there isn't really a good existing role for it
        // and the element is hidden anyway
        // ...so we decided to identify it in the tests via test id
        data-testid={TEST_IDS.FileSelectButtonInput}
        disabled={disabled}
      />
    </BaseButton>
  );

  return wrapInTooltip ? (
    <Tooltip title={title} arrow>
      {finalButton}
    </Tooltip>
  ) : (
    finalButton
  );
};
