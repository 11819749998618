import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { getTypedTranslation } from 'hooks/i18n/i18n.hooks';

/**
 * @param seconds seconds since January 1, 1970, 00:00:00 UTC, this is very common for dates, whereas typically
 *                milliseconds are used instead of seconds, since these can be directly put into the `Date` constructor
 *
 * @returns a string in the following format "DD.MM.YYYY - HH:MM"
 */
export function getFormattedDateAndTimeFromSeconds(seconds = 0, delimiter?: string): string {
  const date = unixTimestampToDate(seconds);
  return getFormattedDateAndTime(date, delimiter);
}

export function getFormattedDateAndTimeParts(date: Date): { date: string; time: string } {
  const dayOfMonth = date.getDate();
  // month is zero indexed
  const monthOfYear = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return {
    date: `${_intTo2DigitStr(dayOfMonth)}.${_intTo2DigitStr(monthOfYear)}.${year}`,
    time: `${_intTo2DigitStr(hours)}:${_intTo2DigitStr(minutes)}`,
  };
}

/**
 * @returns a string in the following format "DD.MM.YYYY - HH:MM"
 */
export function getFormattedDateAndTime(date: Date, delimiter = ' @ '): string {
  const { date: formattedDate, time } = getFormattedDateAndTimeParts(date);
  return `${formattedDate}${delimiter}${time}`;
}

/**
 * @param seconds seconds since January 1, 1970, 00:00:00 UTC, this is very common for dates, whereas typically
 *                milliseconds are used instead of seconds, since these can be directly put into the `Date` constructor
 *
 * @returns a string in the following format "HH:MM"
 */
export function getFormattedTimeFromSeconds(seconds: number): string {
  const date = unixTimestampToDate(seconds);

  const minutes = date.getMinutes();
  const restSeconds = date.getSeconds();

  return `${_intTo2DigitStr(minutes)}:${_intTo2DigitStr(restSeconds)}`;
}

function _intTo2DigitStr(intVal: number): string {
  let strVal = intVal.toString();
  strVal = strVal.padStart(2, '0');

  return strVal;
}

export function unixTimestampToDate(ts: number): Date {
  return new Date(ts * 1000);
}

export function dateToUnixTimestamp(date: Date): number {
  return Math.floor(date.getTime() / 1000);
}

/**
 * Get the relative date\
 * ATM this only supports "today" and "yesterday" but might be extended in the future
 *
 * @param unixTime
 * @param [fallbackDate] Will be returned if the date is not in the "supported relative range".
 *                       If not provided, the date from `getFormattedDateAndTimeParts` will be returned.
 */
export function getRelativeDate(unixTime: number, fallbackDate?: string): string {
  const { t } = getTypedTranslation();
  const tempDate = new Date();
  tempDate.setHours(0, 0, 0, 0);
  const todayAsUnix = dateToUnixTimestamp(tempDate);
  tempDate.setDate(tempDate.getDate() - 1);
  const yesterdayAsUnix = dateToUnixTimestamp(tempDate);
  tempDate.setDate(tempDate.getDate() + 2);
  const tomorrowAsUnix = dateToUnixTimestamp(tempDate);
  tempDate.setDate(tempDate.getDate() + 1);
  const dayAfterTomorrowAsUnix = dateToUnixTimestamp(tempDate);

  const isToday = unixTime >= todayAsUnix && unixTime < tomorrowAsUnix;
  const isYesterday = unixTime >= yesterdayAsUnix && unixTime < todayAsUnix;
  const isTomorrow = unixTime >= tomorrowAsUnix && unixTime < dayAfterTomorrowAsUnix;

  return isToday
    ? t('today')
    : isYesterday
      ? t('yesterday')
      : isTomorrow
        ? t('tomorrow')
        : fallbackDate ?? getFormattedDateAndTimeParts(new Date(unixTime * 1000)).date;
}

/**
 * Help function for receiving the end (23:59:59:999) of the current or input day
 */
export function getEndOfDay(date?: Dayjs): Dayjs {
  // prettier-ignore
  const endOfDay = (date?? dayjs())
  .set('hour', 23)
  .set('minute', 59)
  .set('second', 59)
  .set('millisecond', 999);

  return endOfDay;
}

/**
 * Help function for receiving the start (00:00:00:000) of the current or input day
 */
export function getStartOfDay(date?: Dayjs): Dayjs {
  // prettier-ignore
  const startOfDay = (date ?? dayjs())
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

  return startOfDay;
}

export function getDayjsRangeFromPastDays(days: number): DateRange<Dayjs> {
  const startOfDay = getStartOfDay();

  const startDate = startOfDay.subtract(days, 'day');
  const endDate = startOfDay.subtract(1, 'millisecond');

  return [startDate, endDate];
}
