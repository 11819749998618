import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { LoadingSpinner } from 'controls/load-mask/loading-spinner/loading-spinner';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { getAccountInvite, getInviteResponses } from 'services/companies/companies.service';
import { isGenericActionResult } from 'services/http/http.service';
import { PATHS } from 'services/routes/paths.service';
import { selectLoggedIn } from 'slices/auth/auth.slice';

export type AcceptInvitationParams = {
  companyName: string;
  inviteToken: string;
  inviteRecipient?: string;
};

type InviteData = AcceptInvitationParams & { companyDisplayName: string };

export const AcceptCompanyInvitationWrapper: React.FC = () => {
  const { t } = useTypedTranslation();
  const loggedIn = useAppSelector(selectLoggedIn);
  const navigate = useNavigate();
  const { companyName, inviteToken } = useParams() as AcceptInvitationParams;
  const detailText = loggedIn
    ? t('You have been invited to join the company')
    : t('Sign in or create a new account to collaborate on the Combeenation configurator project');

  const [inviteData, setInviteData] = useState<InviteData>();

  useEffect(() => {
    async function loadInviteData(): Promise<void> {
      const data = await getAccountInvite(inviteToken, companyName);
      const isKnownError = isGenericActionResult(data, getInviteResponses);
      if (
        isKnownError ||
        data.validTill < new Date() ||
        (data.revokedAt && data.revokedAt < new Date()) ||
        data.consumed
      ) {
        navigate(PATHS.acceptCompanyInvitationInvalid);
      } else {
        const { inviteRecipient, companyDisplayName } = data;
        setInviteData({ companyName, inviteToken, inviteRecipient, companyDisplayName });
      }
    }
    loadInviteData();
  }, [companyName, inviteToken, navigate]);

  return (
    <div
      data-cmptype="AcceptCompanyInvitationWrapper"
      className="flex w-[30rem] flex-col items-center gap-3 text-m-regular"
    >
      <>
        <div className="text-heading-m">{t(`You have been invited.`)}</div>

        {!inviteData && (
          <>
            <div>{t('Loading more details...')}</div>
            <LoadingSpinner small />
          </>
        )}

        {inviteData && (
          <>
            <div className="text-center">{detailText}</div>
            <div className="text-heading-s">{inviteData.companyDisplayName}</div>
            <Outlet context={{ inviteData }} />
          </>
        )}
      </>
    </div>
  );
};

type OutletContextType = { inviteData: InviteData };
export function useInviteData(): OutletContextType {
  return useOutletContext<OutletContextType>();
}
