import { SelectChangeEvent } from '@mui/material';
import { GridEditSingleSelectCell, GridEditSingleSelectCellProps, useGridApiContext } from '@mui/x-data-grid-pro';
import { EDIT_GRID_FIELD_KEYS } from 'components/asset-editor/details/modals/datasource-edit/edit-grid/asset-datasource-edit-grid';
import { SelectOption } from 'controls/select/select';
import { getTypeFromSelectionKey, getUpdatedFolderSelection } from 'helper/assets/assets-datasource.helper';
import { getDefaultValueForDataSourceColumnType } from 'helper/assets/assets.helper';

type AssetDatasourceEditGridTypeDefCellProps = GridEditSingleSelectCellProps & { availableFolders: SelectOption[] };

export const AssetDatasourceEditGridTypeDefCell: React.FC<AssetDatasourceEditGridTypeDefCellProps> = ({
  availableFolders,
  ...props
}) => {
  const apiRef = useGridApiContext();
  const { id, row } = props;

  const handleValueChange = async (event: SelectChangeEvent, newValue: string): Promise<void> => {
    const currentTypeDef = row[EDIT_GRID_FIELD_KEYS.TypeDef];
    const newTypeDef = getTypeFromSelectionKey(newValue);

    const currentFolder = row[EDIT_GRID_FIELD_KEYS.LinkedAssetFolder];
    const newFolderSelection = getUpdatedFolderSelection(newTypeDef, currentFolder, availableFolders);
    if (currentFolder !== newFolderSelection) {
      // The `GridEditSingleSelectCell` would set the `newValue` already,
      // but we need the updated value earlier for the `renderEditCell` of the `LinkedAssetFolder`
      // (otherwise the edit-cell would always be "one behind" because we retrieve the old value)
      await apiRef.current.setEditCellValue({
        id: id,
        field: EDIT_GRID_FIELD_KEYS.TypeDef,
        value: newValue,
      });
      await apiRef.current.setEditCellValue({
        id: id,
        field: EDIT_GRID_FIELD_KEYS.LinkedAssetFolder,
        value: newFolderSelection,
      });
    }

    if (currentTypeDef.type !== newTypeDef.type) {
      await apiRef.current.setEditCellValue({
        id: id,
        field: EDIT_GRID_FIELD_KEYS.DefaultValue,
        value: getDefaultValueForDataSourceColumnType(newTypeDef.type),
      });
    }
  };

  return <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />;
};
