import MuiTableBody, { TableBodyProps as MuiTableBodyProps } from '@mui/material/TableBody';
import { TableRowProps } from 'controls/table/table-row';

export type TableBodyProps = Pick<MuiTableBodyProps, 'className'> & {
  children: React.ReactElement<TableRowProps> | React.ReactElement<TableRowProps>[];
};

export const TableBody: React.FC<TableBodyProps> = ({ children, ...props }) => {
  return (
    <MuiTableBody data-cmptype="TableBody" {...props}>
      {children}
    </MuiTableBody>
  );
};
