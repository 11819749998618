import { useEffect } from 'react';
import { CompanySettingsApiTokenCard } from 'components/company/company-settings-api-token-card';
import { CompanySettingsDetailsCard } from 'components/company/company-settings-details-card';
import { CompanySettingsPlanCard } from 'components/company/company-settings-plan-card';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { getCompanyDetailsThunk, selectSelectedCompany } from 'slices/company-data/company-data.slice';

export const CompanySettings: React.FC = () => {
  const dispatch = useAppDispatch();

  const selectedCompany = useAppSelector(selectSelectedCompany);
  const companyId = selectedCompany?.id ?? '';

  useEffect(
    function fetchCompanyDetailsOnLoadAndExternalChanges() {
      dispatch(getCompanyDetailsThunk({ companyId }));
    },
    [companyId, dispatch]
  );

  return (
    <div data-cmptype="CompanySettings" className="flex flex-wrap gap-6 p-6">
      <div className="flex min-w-[712px] flex-1 flex-col gap-6">
        <CompanySettingsPlanCard />
        <CompanySettingsDetailsCard />
      </div>
      <div className="w-[400px]">
        <CompanySettingsApiTokenCard />
      </div>
    </div>
  );
};
