import React, { useEffect, useState } from 'react';

type DragDropContextState = {
  dropZones: HTMLElement[];
  isDragging: boolean;
  addDropZone: (el: HTMLElement) => void;
  removeDropZone: (el: HTMLElement) => void;
};

function addDropZone(el: HTMLElement): void {
  if (!contextState.dropZones.includes(el)) {
    contextState.dropZones.push(el);
  }
}

function removeDropZone(el: HTMLElement): void {
  const idx = contextState.dropZones.indexOf(el);
  if (idx !== -1) {
    contextState.dropZones.splice(idx, 1);
  }
}

const contextState: DragDropContextState = {
  dropZones: [],
  isDragging: false,
  addDropZone,
  removeDropZone,
};

export const DragDropContext = React.createContext(contextState);

type DragDropProviderProps = {
  children: React.ReactNode;
};

/**
 * Context for <DragDropZone /> which listens on a global-level for drag-events
 * - pass down to the zones if a drag is happening
 * - controls the `dropEffect` based on registered zones & dragged data
 */
export const DragDropProvider: React.FC<DragDropProviderProps> = ({ children }) => {
  const [ctxState, setCtxState] = useState(contextState);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(
    /** Keep the drag-state in it's own state, so that the `ctxState` only changes when actually required */
    function passDraggingStateToContext() {
      setCtxState(ctx => ({ ...ctx, isDragging }));
    },
    [isDragging]
  );

  useEffect(
    function handleDragDrop() {
      /**
       * Combine enter & over events to prevent 'flickering' during movement in the UI
       * Also the `dropEffect` requires constant update
       */
      const dragEnterOrOver = (evt: DragEvent): void => {
        evt.preventDefault();

        if (!evt.dataTransfer) {
          return;
        }

        // Only 'activate' the drag zones when actual files are dragged (no url, etc.)
        // Due to security, only the property `types` is available before the drop-event
        // E.g.: It can't be determined if it's a folder or how many files are dragged
        if (!evt.dataTransfer.types.includes('Files')) {
          evt.dataTransfer.dropEffect = 'none';
          return;
        }

        setIsDragging(true);

        const targetIsNoDropZone = !ctxState.dropZones.some(zone => zone.contains(evt.target as Node));
        evt.dataTransfer.dropEffect = targetIsNoDropZone ? 'none' : 'copy';
      };

      const dragLeave = (evt: DragEvent): void => {
        evt.preventDefault();

        const draggedOutOrCanceled = !evt.relatedTarget;
        if (draggedOutOrCanceled) {
          setIsDragging(false);
        }
      };

      const drop = (evt: DragEvent): void => {
        // Simply end the drag state; The 'dropped data' is handled by the zone itself
        evt.preventDefault();
        setIsDragging(false);
      };

      window.addEventListener('dragenter', dragEnterOrOver);
      window.addEventListener('dragover', dragEnterOrOver);
      window.addEventListener('drop', drop);
      window.addEventListener('dragleave', dragLeave);

      return () => {
        window.removeEventListener('dragenter', dragEnterOrOver);
        window.removeEventListener('dragover', dragEnterOrOver);
        window.removeEventListener('drop', drop);
        window.removeEventListener('dragleave', dragLeave);
      };
    },
    [ctxState.dropZones]
  );

  return <DragDropContext.Provider value={ctxState}>{children}</DragDropContext.Provider>;
};
