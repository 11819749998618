import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { CbnDateRangePickerPopover } from 'controls/cbn-date-range-picker-popover/cbn-date-range-picker-popover';
import {
  CbnToggleButtonGroup,
  CbnToggleButtonOption,
  CbnToggleButtonValue,
} from 'controls/cbn-toggle-button/cbn-toggle-button-group';
import { executeAtLeast } from 'helper/async/async.helper';
import { getDayjsRangeFromPastDays, getEndOfDay } from 'helper/date-and-time/date-and-time.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { CfgnMetricResult, getCfgnMetrics } from 'services/cfgn/cfgn.service';
import { selectSelectedConfigurator } from 'slices/company-data/company-data.slice';

type CfgnDateRange = 'Yesterday' | 'Last7Days' | 'Last30Days' | 'All' | 'Custom';

type MetricsDateRangeProps = {
  onMetricsChange: (metrics: CfgnMetricResult | undefined) => void;
};

type RangeConfiguration = {
  displayName: string;
  getRangeCallback: () => DateRange<Dayjs>;
};

const _RANGE_MAP: Record<CfgnDateRange, RangeConfiguration> = {
  Yesterday: { displayName: 'Yesterday', getRangeCallback: () => getDayjsRangeFromPastDays(1) },
  Last7Days: { displayName: 'Last 7 days', getRangeCallback: () => getDayjsRangeFromPastDays(7) },
  Last30Days: { displayName: 'Last 30 days', getRangeCallback: () => getDayjsRangeFromPastDays(30) },
  All: { displayName: 'All', getRangeCallback: () => _getDayjsRangeForAll() },
  // range is not set on switch for "custom" range, instead the `DateRangeCalendar` is shown
  Custom: { displayName: 'Custom', getRangeCallback: () => [null, null] },
};

export const MetricsDateRange: React.FC<MetricsDateRangeProps> = ({ onMetricsChange }) => {
  const { t } = useTypedTranslation();

  const selectedCfgr = useAppSelector(selectSelectedConfigurator);

  const [selectedDateRangeKey, setSelectedDateRangeKey] = useState<CfgnDateRange>('Yesterday');
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const [showCalendar, setShowCalendar] = useState(false);
  const showCalenderBtnRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedDateRangeKey === 'Custom') {
      // range can't be updated right away, show calendar instead
      setShowCalendar(true);
    } else {
      // update range immediately, using the assigned callback
      setDateRange(_RANGE_MAP[selectedDateRangeKey].getRangeCallback());
    }
  }, [selectedDateRangeKey]);

  useEffect(() => {
    onMetricsChange(undefined);

    if (!selectedCfgr || selectedCfgr.deleteAfter || !dateRange[0] || !dateRange[1]) {
      return;
    }

    const fetchOnDataChange = async (): Promise<void> => {
      const fetchPromise = getCfgnMetrics(
        selectedCfgr.companyId,
        selectedCfgr.id,
        _dayjsToUnixTimestampSeconds(dateRange[0]!),
        _dayjsToUnixTimestampSeconds(dateRange[1]!)
      );

      const result = await executeAtLeast(fetchPromise, 500);
      onMetricsChange(result);
    };
    fetchOnDataChange();
  }, [selectedCfgr, dateRange, onMetricsChange]);

  // calendar should also be shown when clicking on "Custom" button again, even if it's already selected
  const onSelectedItemClick = (value: CbnToggleButtonValue): void => {
    if (value === 'Custom' && !showCalendar) {
      // timeout necessary to avoid interaction with outside click handler (`useOutsideClick`)
      setTimeout(() => setShowCalendar(true), 0);
    }
  };

  const dateRangeEntries: CbnToggleButtonOption[] = Object.entries(_RANGE_MAP).map(([key, mapping]) => ({
    value: key,
    text: t(mapping.displayName),
  }));

  return (
    <div>
      <CbnDateRangePickerPopover
        anchorEl={showCalenderBtnRef.current}
        value={dateRange}
        open={showCalendar}
        onClose={(): void => setShowCalendar(false)}
        onSubmit={(range): void => setDateRange(range)}
        calendarProps={{
          // `end` seems to be better, as otherwise 2015 would be shown when opening after selecting "All" cfgns
          defaultRangePosition: 'end',
        }}
      />

      <CbnToggleButtonGroup
        exclusive
        ref={showCalenderBtnRef}
        entries={dateRangeEntries}
        value={selectedDateRangeKey}
        onValueChange={(newVal): void => setSelectedDateRangeKey(newVal as CfgnDateRange)}
        onSelectedItemClick={onSelectedItemClick}
      />
    </div>
  );
};

function _getDayjsRangeForAll(): DateRange<Dayjs> {
  // this seems to be the start date of Combeenation :)
  const startDate = dayjs('2015-01-01');
  const endDate = getEndOfDay();

  return [startDate, endDate];
}

/**
 * Convert into unix timestamp SECONDS, as this is what the CBN server expects
 */
function _dayjsToUnixTimestampSeconds(date: Dayjs): number {
  return Math.floor(date.valueOf() / 1000);
}
