import { useId } from 'react';
import { PasswordInput } from 'controls/input/password-input';
import { TextInput } from 'controls/input/text-input';

export type UserProfileInputProps = {
  labelText: string;
  type?: 'Text' | 'Password';
  value: string;
  onValueChange: (value: string) => void;
  error?: boolean;
  errorText?: string;
  autoComplete?: string;
};

/**
 * (Yet another) wrapper component for form inputs.
 * This one distinguishes between "standard" text inputs and password fields.
 * Furthermore a horizontally aligned form label and an error indication field is implemented.
 */
export const UserProfileInput: React.FC<UserProfileInputProps> = ({
  labelText,
  type = 'Text',
  value,
  onValueChange,
  error,
  errorText,
  autoComplete,
}) => {
  const inputId = useId();

  const InputField = type === 'Password' ? PasswordInput : TextInput;

  return (
    <div data-cmptype="UserProfileInput" className="flex grow gap-1">
      <div className="flex h-9 w-36 shrink-0 items-center">
        <label className="text-m-medium text-neutral-90" htmlFor={inputId}>
          {labelText}
        </label>
      </div>

      <div className="grow">
        <InputField
          value={value}
          onValueChange={onValueChange}
          id={inputId}
          error={error}
          helperText={errorText}
          autoComplete={autoComplete}
        />
      </div>
    </div>
  );
};
