import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { UserProfileIcon } from 'assets/icons';
import { ConditionalWrapper } from 'components/common/conditional-wrapper';
import { Icon } from 'controls/icon/icon';
import { Tooltip } from 'controls/tooltip/tooltip';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { useIdentityUserDisplayParts } from 'hooks/identity/identity.hooks';

type IdentityUserProps = {
  userId: string;
  preventTooltip?: boolean;
};

/**
 * Shows the display name of an identity + some more info in a tooltip.
 * Either takes the info from the store or fetches it from the `/identity/get` API if not available.
 */
export const IdentityUser: React.FC<IdentityUserProps> = ({ userId, preventTooltip }) => {
  const result = useIdentityUserDisplayParts([userId]);
  const { displayParts, isFetching } = result[userId]!;
  const hasTooltipContent = !!displayParts?.tooltipHeader || !!displayParts?.tooltipDetail;

  if (isFetching) {
    return (
      <div className="flex items-center gap-1">
        <Icon Svg={UserProfileIcon} className="w-4" />
        <Skeleton width={100} height={16} />
      </div>
    );
  }

  return (
    <ConditionalWrapper
      condition={!preventTooltip && hasTooltipContent}
      wrapper={(children): ReactElement => (
        <Tooltip
          title={
            <div className="flex items-center gap-3">
              <Icon Svg={displayParts.icon} className="w-7" />
              <TooltipContent header={displayParts.tooltipHeader} detail={displayParts.tooltipDetail} />
            </div>
          }
          delay={500}
          interactive
        >
          {children}
        </Tooltip>
      )}
    >
      <span
        data-cmptype="IdentityUser"
        className={classNames('inline-flex items-center gap-1', {
          'cursor-pointer select-none': !preventTooltip && hasTooltipContent,
        })}
      >
        <Icon Svg={displayParts.icon} className="w-4" />
        {displayParts.name}
      </span>
    </ConditionalWrapper>
  );
};
