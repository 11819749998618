import {
  CfgnInsightsFilterInputProps,
  NoSelectionChipValue,
} from 'components/cfgrs/cfgn-insights/cfgn-insights-filter-input';
import { ChipValue, LargeFontChip } from 'controls/chip/chip';
import { CfgnInsightsBrowsers } from 'generated/cfgn-insights-browsers';
import { CfgnInsightsType } from 'generated/cfgn-insights-types';
import { ShopHiveTypes } from 'generated/shop-hive-types';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

type PotentialValueTypes = CfgnInsightsBrowsers[] | CfgnInsightsType[] | ShopHiveTypes[];

type StaticOptionsFilterInputProps<T extends PotentialValueTypes> = CfgnInsightsFilterInputProps<T> & {
  options: Record<ArrayElement<T>, { value: ArrayElement<T>; displayName: string }>;
};

export function StaticOptionsFilterInput<T extends PotentialValueTypes>({
  value,
  onCommit,
  options,
}: StaticOptionsFilterInputProps<T>): JSX.Element {
  const { t } = useTypedTranslation();

  const onChipClicked = (x: ChipValue): void => {
    const updatedValue =
      // @ts-ignore TODO WTT: See how to fix this...
      x === NoSelectionChipValue ? [] : value.includes(x) ? value.filter(y => y !== x) : [...value, x];

    onCommit(updatedValue);
  };

  return (
    <div className="flex flex-wrap gap-3">
      <LargeFontChip
        value={NoSelectionChipValue}
        text={t('Any')}
        variant="Primary"
        selected={!value.length}
        onClick={onChipClicked}
      />
      <span className="border-r border-neutral-50" />

      {Object.values(options).map(x => {
        const xCasted = x as { value: ArrayElement<T>; displayName: string };
        return (
          <LargeFontChip
            key={xCasted.value}
            value={xCasted.value}
            text={t(xCasted.displayName)}
            variant="Primary"
            // @ts-ignore TODO WTT: See how to fix this...
            selected={value.includes(xCasted.value)}
            onClick={onChipClicked}
          />
        );
      })}
    </div>
  );
}
