import { LockIcon } from 'assets/icons';
import { ReplicatorLockStatusInfo } from 'components/common/replicator-lock-status-info';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { Icon } from 'controls/icon/icon';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { isReplicatorLockStatus } from 'services/company-data/company-data.service';
import { selectSelectedBundleVersion } from 'slices/assets/assets.slice';

export const AssetBundleVersionLockInfo: React.FC = () => {
  const { t } = useTypedTranslation();
  const selectedVersion = useAppSelector(selectSelectedBundleVersion);

  return (
    <CbnCard data-cmptype="AssetBundleVersionLockInfo">
      <CbnCardHeader
        title={
          <div className="flex items-center gap-2 text-m-regular font-semibold">
            <Icon className="h-8 w-8 text-warning-main" Svg={LockIcon} />
            <div>{t('Version locked')}</div>
          </div>
        }
      />
      <CbnCardBody>
        {selectedVersion?.lockStatus && isReplicatorLockStatus(selectedVersion.lockStatus) && (
          <ReplicatorLockStatusInfo
            key={selectedVersion.id}
            lockStatus={selectedVersion.lockStatus}
            lockedEntity="BundleVersion"
          />
        )}
      </CbnCardBody>
    </CbnCard>
  );
};
