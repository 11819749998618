import {
  Tab as BaseTab,
  TabPanel as BaseTabPanel,
  Tabs as BaseTabs,
  TabsList as BaseTabsList,
  tabClasses as baseTabClasses,
  tabPanelClasses as baseTabPanelClasses,
} from '@mui/base';
import { styled } from '@mui/material/styles';
import React from 'react';
import { NAMED_SHADOW } from 'styles/mui-theme';
import { ForwardableTooltipProps, Tooltip } from 'controls/tooltip/tooltip';
import { cn } from 'helper/css.helper';

export type CbnTabPanelEntry = {
  name: React.ReactNode;
  key: number | string;
  component: JSX.Element;
  disabled?: boolean;
  title?: string;
  titleProps?: ForwardableTooltipProps;
};

export type CbnTabPanelProps = {
  'entries': CbnTabPanelEntry[];

  /**
   * Rendered inside the tab bar at the right corner.
   * Can be used to add custom action buttons etc.
   */
  'tabBarTool'?: JSX.Element;
  'data-cmptype'?: string;
  'value'?: number | string;
  'onTabValueChange'?: (selKey: number | string) => void;
  'addHorizontalHeaderMargin'?: boolean;
};

const StyledTab = styled(BaseTab)(({ theme }) => ({
  // Question HAR: Is there an easier to way to "just use" the whole `theme.typography.mMedium` without having to apply
  //               each single property (size, weight, ...)?
  //               E.g. by using the CSS `font` shorthand?
  'fontSize': theme.typography.mMedium.fontSize,
  'fontWeight': theme.typography.mMedium.fontWeight,
  'color': theme.palette.neutral[70],

  'padding': theme.spacing(3),

  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.neutral[30],
  },

  [`&.${baseTabClasses.disabled}`]: {
    color: theme.palette.neutral[60],
    backgroundColor: 'transparent',
  },

  [`&.${baseTabClasses.selected}`]: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.surface,
    boxShadow: theme.shadows[NAMED_SHADOW.UnderlineThick],
  },

  '&:focus-visible': {
    outline: theme.outlines.default,
  },
}));

const StyledTabPanel = styled(BaseTabPanel)(() => ({
  [`&.${baseTabPanelClasses.root}`]: {
    overflowY: 'auto',
  },

  [`:not(&.${baseTabPanelClasses.hidden})`]: {
    // strech the content of the tab panel
    // but don't overwrite the hidden panel CSS as it would break the internal MUI TabPanel implementation
    display: 'flex',
    flexGrow: '1',
  },
}));

export const CbnTabPanel: React.FC<CbnTabPanelProps> = ({
  entries,
  tabBarTool,
  onTabValueChange,
  value,
  addHorizontalHeaderMargin,
  'data-cmptype': dataCmpType,
}) => {
  const cmpTypes = ['CbnTabPanel', dataCmpType].filter(Boolean);
  const cmpTypesStr = cmpTypes.join(' ').trim();
  const cmpTypesTabBarStr = cmpTypes
    .map(x => x + '-tabbar')
    .join(' ')
    .trim();

  const onChange = (event: React.SyntheticEvent | null, value: number | string | null): void => {
    // We know that `value` is number since we're passing the `key` which is currently defined as `number` in
    // `TabPanelEntry`...
    onTabValueChange?.(value as number | string);
  };

  return (
    <BaseTabs
      data-cmptype={cmpTypesStr}
      value={value}
      onChange={onChange}
      className="flex grow flex-col overflow-y-auto"
    >
      <div data-cmptype={cmpTypesTabBarStr} className="flex flex-row shadow-divider-line">
        <BaseTabsList className={cn('flex grow', { 'mx-4': addHorizontalHeaderMargin })}>
          {/* TODO: Think of passing <StyledTabs> instead of entries for consistency (see <Table/>) */}
          {entries.map(x => (
            <Tooltip title={x.title} {...x.titleProps} key={x.key}>
              <StyledTab value={x.key} disabled={x.disabled}>
                {x.name}
              </StyledTab>
            </Tooltip>
          ))}
        </BaseTabsList>

        {tabBarTool}
      </div>

      {entries.map(x => (
        <StyledTabPanel key={x.key} value={x.key}>
          {x.component}
        </StyledTabPanel>
      ))}
    </BaseTabs>
  );
};
