import classNames from 'classnames';
import { Fragment, ReactNode, useEffect, useId, useReducer, useState } from 'react';
import { TEST_IDS } from 'testing/test-ids';
import { EyeShowVisibleIcon, QuestionIcon } from 'assets/icons';
import { publishConfigReducer } from 'components/asset-editor/header/modals/publish-asset-bundle-edit-reducer';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { Checkbox } from 'controls/checkbox/checkbox';
import { Icon } from 'controls/icon/icon';
import { tw } from 'helper/css.helper';
import { usePublishAssetBundle } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { AssignedCfgrVersions, CfgrsToUpdateAfterPublish } from 'services/assets/assets.service';
import { selectSelectedAssetPath } from 'slices/assets/assets.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

type PublishAssetBundleModalProps = {
  // assigned cfgr versions have to be fetched outside to decide if the dialog should be shown or not, that's why this
  // data is provided on the input
  assignedCfgrVersions: AssignedCfgrVersions;
  onClose: () => void;
};

export const PublishAssetBundleModal: React.FC<PublishAssetBundleModalProps> = ({ assignedCfgrVersions, onClose }) => {
  const { t } = useTypedTranslation();
  const publishAssetBundle = usePublishAssetBundle();

  const [publishConfig, dispatchPC] = useReducer(publishConfigReducer, []);

  const {
    companyId: selectedCompanyId,
    bundleId: selectedBundleId,
    bundleVersion: selectedBundleVersion,
  } = useAppSelector(selectSelectedAssetPath);

  // if the user doesn't have "manage cfgr draft" permission, all controls in the dialog should be disabled as the user
  // is not allowed to update the asset bundle in the corresponding cfgr drafts
  // => publishing the asset bundle is still valid!
  const hasManageCfgrDraftPermission = usePermission(CompanyPermissions.ManageDraft, selectedCompanyId);
  // if the user doesn't have "publish cfgr draft" permission, the "auto publish" options should be disabled
  // everything else is valid
  const hasPublishCfgrDraftPermission = usePermission(CompanyPermissions.PublishCfgrDraft, selectedCompanyId);

  const [uiSelectAll, setUiSelectAll] = useState(hasManageCfgrDraftPermission);
  const [isProcessing, setIsProcessing] = useState(false);

  const selectAllId = useId();

  const hasErroneousCfgrs = publishConfig.some(configEntry => configEntry.hasError);
  const cfgrAvailable = publishConfig.some(configEntry => !configEntry.isDraft);

  useEffect(() => {
    dispatchPC({
      type: 'INIT',
      payload: { cfgrVersions: assignedCfgrVersions, selectByDefault: hasManageCfgrDraftPermission },
    });
  }, [assignedCfgrVersions, hasManageCfgrDraftPermission]);

  const onSelectChanged = (value: boolean, idx: number): void => {
    dispatchPC({ type: 'CHANGE_SELECTION', payload: { select: value, idx: idx } });
  };

  const onSelectAllChanged = (value: boolean): void => {
    dispatchPC({ type: 'CHANGE_SELECTION_FOR_ALL', payload: value });
    // also update value for the select/deselect all checkbox
    setUiSelectAll(value);
  };

  const onAutoPublishChanged = (value: boolean, idx: number): void => {
    dispatchPC({ type: 'CHANGE_AUTO_PUBLISH', payload: { autoPublish: value, idx: idx } });
  };

  const onPublishConfirm = async (): Promise<void> => {
    if (hasErroneousCfgrs) {
      // don't try to publish again, just close the dialog in error mode
      onClose();
      return;
    }

    setIsProcessing(true);

    // filter out unselected configurators and map to corresponding data
    const cfgrsToUpdate: CfgrsToUpdateAfterPublish = publishConfig
      .filter(configEntry => configEntry.select)
      .map(configEntry => ({
        companyName: configEntry.companyName,
        cfgrName: configEntry.cfgrName,
        cfgrVersion: configEntry.cfgrVersion,
        autoPublish: configEntry.autoPublish,
      }));

    const failedCfgrUpdates = await publishAssetBundle(
      selectedCompanyId,
      selectedBundleId,
      selectedBundleVersion,
      cfgrsToUpdate
    );

    setIsProcessing(false);
    if (failedCfgrUpdates.length) {
      // re-draw the dialog in "error mode"
      // => show failed configurator updates but don't allow the user to do anything else than closing the dialog
      dispatchPC({ type: 'ADD_ERRONEOUS_CFGRS', payload: failedCfgrUpdates });
    } else {
      // publishing and updating assignments went fine, close dialog now
      onClose();
    }
  };

  return (
    <ModalDialog
      size="Medium"
      data-cmptype="PublishAssetBundleModal"
      header={t('Publish asset bundle')}
      variant="NoIcon"
      actionTypes={hasErroneousCfgrs ? 'ConfirmOnly' : 'ConfirmCancel'}
      confirmText={
        hasErroneousCfgrs
          ? t('Close')
          : !hasManageCfgrDraftPermission
            ? t('Publish')
            : t('Publish & update assignments')
      }
      isProcessingConfirm={isProcessing}
      childrenScrollable
      actions={{
        onConfirm: () => onPublishConfirm(),
        onCancel: () => onClose(),
      }}
    >
      <form className="flex grow flex-col gap-2">
        {hasErroneousCfgrs ? (
          <>
            <h4 className="text-danger-main">{t('Erroneous Configurator Updates!')}</h4>
            <span className="text-m-regular">
              {t(
                `Some of your configurators or drafts couldn't be updated automatically, you will have to check them manually.
                 Don't worry, your asset bundle was still published.`
              )}
            </span>
          </>
        ) : !hasManageCfgrDraftPermission ? (
          <>
            <h4 className="text-danger-main">{t('Missing Permission!')}</h4>
            <span className="text-m-regular">
              {t(
                `You are missing the permission to manage cfgr drafts, that's why you cannot update the published asset bundle in the assigned cfgrs.
                 You can still publish the asset bundle though.`
              )}
            </span>
          </>
        ) : (
          <span className="text-m-regular">{t('Select configurators for which the assignment should be updated')}</span>
        )}
        <div className="grid grow grid-cols-[40px_1fr_200px] overflow-y-auto rounded border border-neutral-40">
          <PublishAssetBundleHeaderEntry headerTitle="" />
          <PublishAssetBundleHeaderEntry headerTitle={t('Configurator')} />
          <PublishAssetBundleHeaderEntry headerTitle={cfgrAvailable ? t('Publish after assignment') : ''} />

          {publishConfig
            .sort((a, b) => a.fullDisplayName.localeCompare(b.fullDisplayName))
            .map((configEntry, idx) => {
              const colorClass = configEntry.hasError
                ? tw`text-danger-main`
                : hasErroneousCfgrs || !configEntry.select
                  ? tw`text-neutral-60`
                  : tw`text-neutral-100`;

              // find original cfgr version, as the publishConfig has been shuffled due to sorting
              const assignedVersion = assignedCfgrVersions.find(
                cfgrVersion => cfgrVersion.version === configEntry.cfgrVersion
              );
              const isReadOnly = !!assignedVersion?.isReadOnly;

              return (
                <Fragment key={idx}>
                  <PublishAssetBundleCell testId={TEST_IDS.PublishAssetDialogSelectCheckbox}>
                    <Checkbox
                      checked={configEntry.select}
                      onValueChanged={(value): void => onSelectChanged(value, idx)}
                      disabled={hasErroneousCfgrs || !hasManageCfgrDraftPermission}
                    />
                  </PublishAssetBundleCell>

                  <PublishAssetBundleCell>
                    <span className={`text-m-regular ${colorClass}`}>{configEntry.fullDisplayName}</span>
                    {isReadOnly && (
                      <Icon
                        Svg={EyeShowVisibleIcon}
                        className="w-5"
                        title={t('Read-only configurator')}
                        titleProps={{ delay: 500 }}
                      />
                    )}
                  </PublishAssetBundleCell>

                  <PublishAssetBundleCell
                    testId={configEntry.isDraft ? undefined : TEST_IDS.PublishAssetDialogAutoPublishCheckbox}
                  >
                    {configEntry.isDraft ? null : (
                      <div className="flex">
                        <Checkbox
                          checked={configEntry.autoPublish}
                          onValueChanged={(value): void => onAutoPublishChanged(value, idx)}
                          disabled={
                            !configEntry.select ||
                            hasErroneousCfgrs ||
                            !hasPublishCfgrDraftPermission ||
                            !hasManageCfgrDraftPermission
                          }
                        />
                        {hasManageCfgrDraftPermission && !hasPublishCfgrDraftPermission && (
                          <Icon
                            Svg={QuestionIcon}
                            title={t(
                              "You are missing the permission to publish cfgr drafts, that's why you use the auto publish functionality."
                            )}
                          />
                        )}
                      </div>
                    )}
                  </PublishAssetBundleCell>
                </Fragment>
              );
            })}
        </div>
        <div className="flex items-center gap-1">
          <Checkbox
            checked={uiSelectAll}
            onValueChanged={onSelectAllChanged}
            id={selectAllId}
            disabled={hasErroneousCfgrs || !hasManageCfgrDraftPermission}
          />
          <label
            htmlFor={selectAllId}
            className={classNames('text-m-regular', { 'cursor-pointer': hasManageCfgrDraftPermission })}
          >
            {t('Select/Unselect All')}
          </label>
        </div>
      </form>
    </ModalDialog>
  );
};

type PublishAssetBundleHeaderEntryProps = {
  headerTitle: string;
};

const PublishAssetBundleHeaderEntry: React.FC<PublishAssetBundleHeaderEntryProps> = ({ headerTitle }) => {
  return (
    <span data-cmptype="PublishAssetBundleHeaderEntry" className="bg-neutral-30 p-2 text-s-medium uppercase">
      {headerTitle}
    </span>
  );
};

type PublishAssetBundleCellProps = {
  children: ReactNode;
  testId?: string;
};

export const PublishAssetBundleCell: React.FC<PublishAssetBundleCellProps> = ({ children, testId }) => (
  <div
    data-cmptype="PublishAssetBundleCell"
    data-testid={testId}
    className="flex items-center gap-1 border-t border-t-neutral-40 px-2 py-1"
  >
    {children}
  </div>
);
