import { SelectOption } from 'controls/select/select';
import { number3 } from 'helper/color/color.helper';
import { MaterialObject } from 'services/3d/materials.service';

/*
 * NOTE: don't import anything from @babylonjs/core in here, as the definitions are used in places like assets.slice,
 * which can't be lazy loaded.
 * This is not ideal from a conceptual point of view, but it's also not painful enough to refactor it right away.
 * Babylon constants are added to the dedicated values to indicate the dependency
 */

export type MaterialGroups =
  | 'General'
  | 'Transparency'
  | 'Lighting & colors'
  | 'Channels'
  | 'Metallic texture properties (ORM)'
  | 'Metallic workflow'
  | 'Clear coat'
  | 'Iridescence'
  | 'Anisotropic'
  | 'Sheen'
  | 'Subsurface'
  | 'Advanced';
export type TextureGroups = 'General' | 'Transform';

export type MaterialPropertyKey = string;
export type TexturePropertyKey = string;

type PropertyType = 'boolean' | 'number' | 'select' | 'BaseTexture' | 'Color3';
type Property = {
  displayName: string;
  type: PropertyType;
  // default values are taken from Babylon.js repository
  // unfortunately there is no single source of truth which can be linked here, in most cases the defaults of the
  // corresponding class has to be checked
  defaultValue?: boolean | number | number3;
  options?: SelectOption[];
};

export type MaterialProperty = Property & {
  group: MaterialGroups;
  visibilityProp?: string;
};
export type TextureProperty = Property & {
  group: TextureGroups;
  info?: string;
};

export const NULL_VALUE = Number.MAX_SAFE_INTEGER;

const _SIDE_ORIENTATION_SELECT_OPTIONS: SelectOption[] = [
  { value: 0 /* Material.ClockWiseSideOrientation */, text: 'Clockwise' },
  { value: 1 /* Material.CounterClockWiseSideOrientation */, text: 'Counterclockwise' },
];

const _TRANSPARENCY_MODE_SELECT_OPTIONS: SelectOption[] = [
  { value: NULL_VALUE, text: '<Not Defined>' },
  { value: 0 /* PBRMaterial.PBRMATERIAL_OPAQUE */, text: 'Opaque' },
  { value: 1 /* PBRMaterial.PBRMATERIAL_ALPHATEST */, text: 'Alpha test' },
  { value: 2 /* PBRMaterial.PBRMATERIAL_ALPHABLEND */, text: 'Alpha blend' },
  { value: 3 /* PBRMaterial.PBRMATERIAL_ALPHATESTANDBLEND */, text: 'Alpha blend and test' },
];

const _ALPHA_MODE_SELECT_OPTIONS: SelectOption[] = [
  { value: 2 /*Constants.ALPHA_COMBINE */, text: 'Combine' },
  { value: 6 /*Constants.ALPHA_ONEONE */, text: 'One one' },
  { value: 1 /*Constants.ALPHA_ADD */, text: 'Add' },
  { value: 3 /*Constants.ALPHA_SUBTRACT */, text: 'Subtract' },
  { value: 4 /*Constants.ALPHA_MULTIPLY */, text: 'Multiply' },
  { value: 5 /*Constants.ALPHA_MAXIMIZED */, text: 'Maximized' },
  { value: 7 /*Constants.ALPHA_PREMULTIPLIED */, text: 'Pre-multiplied' },
];

// prettier-ignore
export const PbrMaterialPropertiesDef: Record<MaterialPropertyKey, MaterialProperty> = {
  // General
  'backFaceCulling':   { displayName: 'Backface culling',    group: 'General', type: 'boolean', defaultValue: true                                                                                        },
  'sideOrientation':   { displayName: 'Orientation',         group: 'General', type: 'select',  defaultValue: 1 /* Material.CounterClockWiseSideOrientation */, options: _SIDE_ORIENTATION_SELECT_OPTIONS },
  'disableLighting':   { displayName: 'Disable lighting',    group: 'General', type: 'boolean', defaultValue: false                                                                                       },
  'disableDepthWrite': { displayName: 'Disable depth write', group: 'General', type: 'boolean', defaultValue: false                                                                                       },

  // Transparency
  'alpha':                     { displayName: 'Alpha',                         group: 'Transparency', type: 'number',  defaultValue: 1                                                                           },
  'transparencyMode':          { displayName: 'Transparency mode',             group: 'Transparency', type: 'select',  defaultValue: NULL_VALUE,                      options: _TRANSPARENCY_MODE_SELECT_OPTIONS },
  'alphaMode':                 { displayName: 'Alpha mode',                    group: 'Transparency', type: 'select',  defaultValue: 2 /* Constants.ALPHA_COMBINE */, options: _ALPHA_MODE_SELECT_OPTIONS        },
  'useAlphaFromAlbedoTexture': { displayName: 'Use alpha from albedo texture', group: 'Transparency', type: 'boolean', defaultValue: false                                                                       },

  // Channels
  'albedoTexture':          { displayName: 'Albedo texture',             group: 'Channels', type: 'BaseTexture' },
  'metallicTexture':        { displayName: 'Metallic roughness texture', group: 'Channels', type: 'BaseTexture' },
  'reflectionTexture':      { displayName: 'Reflection texture',         group: 'Channels', type: 'BaseTexture' },
  'reflectivityTexture':    { displayName: 'Reflectivity texture',       group: 'Channels', type: 'BaseTexture' },
  'microSurfaceTexture':    { displayName: 'Micro surface texture',      group: 'Channels', type: 'BaseTexture' },
  'bumpTexture':            { displayName: 'Bump texture',               group: 'Channels', type: 'BaseTexture' },
  'emissiveTexture':        { displayName: 'Emissive texture',           group: 'Channels', type: 'BaseTexture' },
  'opacityTexture':         { displayName: 'Opacity texture',            group: 'Channels', type: 'BaseTexture' },
  'ambientTexture':         { displayName: 'Ambient texture',            group: 'Channels', type: 'BaseTexture' },
  'lightmapTexture':        { displayName: 'Lightmap texture',           group: 'Channels', type: 'BaseTexture' },
  'environmentBRDFTexture': { displayName: 'Environment BRDF texture',   group: 'Channels', type: 'BaseTexture' },

  // Metallic texture properties (ORM)
  'useAmbientOcclusionFromMetallicTextureRed': { displayName: 'Use ambient occlusion from red', group: 'Metallic texture properties (ORM)', type: 'boolean', defaultValue: false },
  'useMetallnessFromMetallicTextureBlue':      { displayName: 'Use metallness from blue',       group: 'Metallic texture properties (ORM)', type: 'boolean', defaultValue: false },
  'useRoughnessFromMetallicTextureGreen':      { displayName: 'Use roughness from green',       group: 'Metallic texture properties (ORM)', type: 'boolean', defaultValue: false },
  'useRoughnessFromMetallicTextureAlpha':      { displayName: 'Use roughness from alpha',       group: 'Metallic texture properties (ORM)', type: 'boolean', defaultValue: true },

  // Lighting & colors
  'albedo':       { displayName: 'Albedo',       group: 'Lighting & colors', type: 'Color3', defaultValue: [1, 1, 1] },
  'reflectivity': { displayName: 'Reflectivity', group: 'Lighting & colors', type: 'Color3', defaultValue: [1, 1, 1] },
  'emissive':     { displayName: 'Emissive',     group: 'Lighting & colors', type: 'Color3', defaultValue: [0, 0, 0] },
  'ambient':      { displayName: 'Ambient',      group: 'Lighting & colors', type: 'Color3', defaultValue: [0, 0, 0] },

  // Metallic workflow'
  'metallic':                     { displayName: 'Metallic',                     group: 'Metallic workflow', type: 'number'                         },
  'roughness':                    { displayName: 'Roughness',                    group: 'Metallic workflow', type: 'number'                         },
  'subSurface.indexOfRefraction': { displayName: 'Index of refraction',          group: 'Metallic workflow', type: 'number',      defaultValue: 1.5 },
  'metallicF0Factor':             { displayName: 'F0 factor',                    group: 'Metallic workflow', type: 'number',      defaultValue: 1   },
  'metallicReflectanceTexture':   { displayName: 'Metallic reflectance texture', group: 'Metallic workflow', type: 'BaseTexture' },
  'reflectanceTexture':           { displayName: 'Reflectance texture',          group: 'Metallic workflow', type: 'BaseTexture' },

  // Clear coat
  'clearCoat.isEnabled':        { displayName: 'Enabled',            group: 'Clear coat', type: 'boolean',     defaultValue: false },
  'clearCoat.roughness':        { displayName: 'Roughness',          group: 'Clear coat', type: 'number',      defaultValue: 0,     visibilityProp: 'clearCoat.isEnabled'     },
  'clearCoat.texture':          { displayName: 'Clear coat texture', group: 'Clear coat', type: 'BaseTexture',                      visibilityProp: 'clearCoat.isEnabled'     },
  'clearCoat.textureRoughness': { displayName: 'Roughness texture',  group: 'Clear coat', type: 'BaseTexture',                      visibilityProp: 'clearCoat.isEnabled'     },
  'clearCoat.bumpTexture':      { displayName: 'Bump texture',       group: 'Clear coat', type: 'BaseTexture',                      visibilityProp: 'clearCoat.isEnabled'     },
  'clearCoat.isTintEnabled':    { displayName: 'Tint',               group: 'Clear coat', type: 'boolean',     defaultValue: false, visibilityProp: 'clearCoat.isEnabled'     },
  'clearCoat.tintTexture':      { displayName: 'Tint texture',       group: 'Clear coat', type: 'BaseTexture',                      visibilityProp: 'clearCoat.isTintEnabled' },

  // 'Iridescence
  'iridescence.isEnabled':        { displayName: 'Enabled',             group: 'Iridescence', type: 'boolean',     defaultValue: false                                          },
  'iridescence.texture':          { displayName: 'Iridescence texture', group: 'Iridescence', type: 'BaseTexture',                      visibilityProp: 'iridescence.isEnabled' },
  'iridescence.thicknessTexture': { displayName: 'Thickness texture',   group: 'Iridescence', type: 'BaseTexture',                      visibilityProp: 'iridescence.isEnabled' },

  // Anisotropic
  'anisotropy.isEnabled': { displayName: 'Enabled',             group: 'Anisotropic', type: 'boolean',     defaultValue: false                                         },
  'anisotropy.texture':   { displayName: 'Anisotropic texture', group: 'Anisotropic', type: 'BaseTexture',                      visibilityProp: 'anisotropy.isEnabled' },

  // Sheen
  'sheen.isEnabled':        { displayName: 'Enabled',           group: 'Sheen', type: 'boolean',     defaultValue: false                                    },
  'sheen.texture':          { displayName: 'Sheen texture',     group: 'Sheen', type: 'BaseTexture',                      visibilityProp: 'sheen.isEnabled' },
  'sheen.textureRoughness': { displayName: 'Roughness texture', group: 'Sheen', type: 'BaseTexture',                      visibilityProp: 'sheen.isEnabled' },

  // Subsurface
  'subSurface.thicknessTexture':             { displayName: 'Thickness texture',              group: 'Subsurface', type: 'BaseTexture'                                                                          },
  'subSurface.isRefractionEnabled':          { displayName: 'Refraction enabled',             group: 'Subsurface', type: 'boolean',     defaultValue: false                                                     },
  'subSurface.refractionIntensityTexture':   { displayName: 'Refraction intensity texture',   group: 'Subsurface', type: 'BaseTexture',                      visibilityProp: 'subSurface.isRefractionEnabled'   },
  'subSurface.refractionTexture':            { displayName: 'Refraction texture',             group: 'Subsurface', type: 'BaseTexture',                      visibilityProp: 'subSurface.isRefractionEnabled'   },
  'subSurface.isTranslucencyEnabled':        { displayName: 'Translucency enabled',           group: 'Subsurface', type: 'boolean',     defaultValue: false                                                     },
  'subSurface.translucencyIntensityTexture': { displayName: 'Translucency intensity texture', group: 'Subsurface', type: 'BaseTexture',                      visibilityProp: 'subSurface.isTranslucencyEnabled' },

  // Advanced
  'unlit': { displayName: 'Unlit', group: 'Advanced', type: 'boolean', defaultValue: false },
};

const _INVERT_Y_INFO =
  'Indicates if textures should be flipped along the y axis. In general this value should be set to "false" if the associated 3d model has a "__root__" node with negative scaling in z, which is the case if the model is based on a GLB file.';

const _UV_SET_SELECT_OPTIONS: SelectOption[] = [
  { value: 0, text: '0' },
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' },
];

const _COORDINATES_MODE_SELECT_OPTIONS: SelectOption[] = [
  { value: 0 /* Texture.EXPLICIT_MODE */, text: 'Explicit' },
  { value: 3 /* Texture.CUBIC_MODE */, text: 'Cubic' },
  { value: 6 /* Texture.INVCUBIC_MODE */, text: 'Inverse cubic' },
  { value: 7 /* Texture.EQUIRECTANGULAR_MODE */, text: 'Equirectangular' },
  { value: 8 /* Texture.FIXED_EQUIRECTANGULAR_MODE */, text: 'Fixed equirectangular' },
  { value: 9 /* Texture.FIXED_EQUIRECTANGULAR_MIRRORED_MODE */, text: 'Fixed equirectangular mirrored' },
  { value: 2 /* Texture.PLANAR_MODE */, text: 'Planar' },
  { value: 4 /* Texture.PROJECTION_MODE */, text: 'Projection' },
  { value: 5 /* Texture.SKYBOX_MODE */, text: 'Skybox' },
  { value: 1 /* Texture.SPHERICAL_MODE */, text: 'Spherical' },
];

const _SAMPLING_MODE_SELECT_OPTIONS: SelectOption[] = [
  { value: 1 /* Texture.NEAREST_NEAREST */, text: 'Nearest' },
  { value: 2 /* Texture.LINEAR_LINEAR */, text: 'Linear' },
  { value: 3 /* Texture.LINEAR_LINEAR_MIPLINEAR */, text: 'Linear & linear mip' },
  { value: 11 /* Texture.LINEAR_LINEAR_MIPNEAREST */, text: 'Linear & nearest mip' },
  { value: 8 /* Texture.NEAREST_NEAREST_MIPLINEAR */, text: 'Nearest & linear mip' },
  { value: 4 /* Texture.NEAREST_NEAREST_MIPNEAREST */, text: 'Nearest & nearest mip' },
  { value: 7 /* Texture.NEAREST_LINEAR */, text: 'Nearest/Linear' },
  { value: 6 /* Texture.NEAREST_LINEAR_MIPLINEAR */, text: 'Nearest/Linear & linear mip' },
  { value: 5 /* Texture.NEAREST_LINEAR_MIPNEAREST */, text: 'Nearest/Linear & nearest mip' },
  { value: 12 /* Texture.LINEAR_NEAREST */, text: 'Linear/Nearest' },
  { value: 10 /* Texture.LINEAR_NEAREST_MIPLINEAR */, text: 'Linear/Nearest & linear mip' },
  { value: 9 /* Texture.LINEAR_NEAREST_MIPNEAREST */, text: 'Linear/Nearest & nearest mip' },
];

// prettier-ignore
export const TexturePropertiesDef: Record<TexturePropertyKey, TextureProperty> = {
  // General
  'coordinatesIndex': { displayName: 'UV set',   group: 'General', type: 'select',  defaultValue: 0,                                       options: _UV_SET_SELECT_OPTIONS                                 },
  'level':            { displayName: 'Level',    group: 'General', type: 'number',  defaultValue: 1                                                                                                        },
  'coordinatesMode':  { displayName: 'Mode',     group: 'General', type: 'select',  defaultValue: 0 /* Texture.EXPLICIT_MODE */,           options: _COORDINATES_MODE_SELECT_OPTIONS                       },
  'samplingMode':     { displayName: 'Sampling', group: 'General', type: 'select',  defaultValue: 3 /* Texture.LINEAR_LINEAR_MIPLINEAR */, options: _SAMPLING_MODE_SELECT_OPTIONS                          },
  'invertY':          { displayName: 'Invert Y', group: 'General', type: 'boolean', defaultValue: true,                                                                               info: _INVERT_Y_INFO },

  // Transform
  'uOffset':         { displayName: 'U offset', group: 'Transform', type: 'number', defaultValue: 0 },
  'uScale':          { displayName: 'U scale',  group: 'Transform', type: 'number', defaultValue: 1 },
  'uAng':            { displayName: 'U angle',  group: 'Transform', type: 'number', defaultValue: 0 },
  'vOffset':         { displayName: 'V offset', group: 'Transform', type: 'number', defaultValue: 0 },
  'vScale':          { displayName: 'V scale',  group: 'Transform', type: 'number', defaultValue: 1 },
  'vAng':            { displayName: 'V angle',  group: 'Transform', type: 'number', defaultValue: 0 },
  'wAng':            { displayName: 'W angle',  group: 'Transform', type: 'number', defaultValue: 0 }
}

export const getAllPbrMaterialPropertyKeys = (type?: PropertyType): MaterialPropertyKey[] =>
  Object.entries(PbrMaterialPropertiesDef)
    .filter(([key, value]) => !type || value.type === type)
    .map(([key]) => key);

export function getValueByMaterialPropertyKey<T>(obj: MaterialObject, key: MaterialPropertyKey): T | undefined {
  const [baseKey, subKey] = key.split('.');
  return subKey ? obj[baseKey]?.[subKey] : obj[baseKey];
}
