import { COMPANYID_ROUTE_PARAMS } from 'services/routes/common-routeparams.service';
import { buildPath } from 'services/routes/routes.service';

export const WORKFLOWS_BASE_PATH = '/workflows';

const _WORKFLOWS_ROUTE_PAGES = { detail: 'detail', config: 'config', run: 'run' };

const _WORKFLOWS_ROUTE_PARAMS = {
  ...COMPANYID_ROUTE_PARAMS,
  workflowName: 'workflowName',
  workflowRunId: 'workflowRunId',
  workflowPage: 'workflowPage',
} as const;

export type WorkflowsRouteParams = keyof typeof _WORKFLOWS_ROUTE_PARAMS;
export type WorkflowsRoutePages = keyof typeof _WORKFLOWS_ROUTE_PAGES;

function isWorkflowsRoutePages(str: string): str is WorkflowsRoutePages {
  return Object.keys(_WORKFLOWS_ROUTE_PAGES).some(p => p === str);
}

// prettier-ignore
export const WORKFLOWS_PATH_VARIANTS = {
  workflows:    `${WORKFLOWS_BASE_PATH}/:${_WORKFLOWS_ROUTE_PARAMS.companyId}`,
  workflow:     `${WORKFLOWS_BASE_PATH}/:${_WORKFLOWS_ROUTE_PARAMS.companyId}/:${_WORKFLOWS_ROUTE_PARAMS.workflowName}`,
  workflowPage: `${WORKFLOWS_BASE_PATH}/:${_WORKFLOWS_ROUTE_PARAMS.companyId}/:${_WORKFLOWS_ROUTE_PARAMS.workflowName}/:${_WORKFLOWS_ROUTE_PARAMS.workflowPage}`,
  workflowRun:  `${WORKFLOWS_BASE_PATH}/:${_WORKFLOWS_ROUTE_PARAMS.companyId}/:${_WORKFLOWS_ROUTE_PARAMS.workflowName}/${_WORKFLOWS_ROUTE_PAGES.run}/:${_WORKFLOWS_ROUTE_PARAMS.workflowRunId}`,
};

export function buildWorkflowsPath(
  companyId: string,
  workflowName?: string,
  workflowPage?: WorkflowsRoutePages | string,
  workflowRunId?: string
): string {
  if (workflowName) {
    const wfPage: WorkflowsRoutePages = workflowPage && isWorkflowsRoutePages(workflowPage) ? workflowPage : 'detail';
    return buildPath(WORKFLOWS_BASE_PATH, companyId, workflowName, wfPage, workflowRunId);
  } else {
    return buildPath(WORKFLOWS_BASE_PATH, companyId);
  }
}
