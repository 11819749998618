import type { editor } from 'monaco-editor';
import { useEffect, useState } from 'react';
import { SaveIcon } from 'assets/icons';
import { AssetDetailsHeader } from 'components/asset-editor/details/asset-details-header';
import { AssetMetadataEntry } from 'components/asset-editor/details/asset-metadata';
import { Button } from 'controls/button/button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { CbnLoadMask } from 'controls/cbn-load-mask/cbn-load-mask';
import { StateIcon } from 'controls/icon/state-icon';
import { MonacoEditor } from 'controls/monaco-editor/monaco-editor';
import { DUMMY_MONACO_PATH } from 'controls/monaco-editor/monaco-editor';
import { getExtensionFromAssetFilenameOrUrl } from 'helper/assets/assets.helper';
import { getReadableFileSize } from 'helper/file/file.helper';
import { getUnparsedText } from 'helper/http/http-get.helper';
import { useIsAssetBundleEditable } from 'hooks/assets/assets.hooks';
import { useMinTimeActive } from 'hooks/common/timing.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { postAssetsChangeTextContent } from 'services/assets/assets.service';
import { TextAsset, getBundleVersionData, selectSelectedAsset } from 'slices/assets/assets.slice';

export const AssetBodyText: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const isAssetBundleEditable = useIsAssetBundleEditable();

  const {
    path: { companyId, bundleId, bundleVersion, folderId, assetId },
    url,
    size,
  } = useAppSelector(selectSelectedAsset) as TextAsset;

  const [fetchedText, setFetchedText] = useState('');
  const [inputText, setInputText] = useState('');
  const [monacoFilePath, setMonacoFilePath] = useState(DUMMY_MONACO_PATH);

  const extension = getExtensionFromAssetFilenameOrUrl(url);
  const fileSize = getReadableFileSize(size);

  // text assets are loading very fast, since they can only have 0,5 MB ATM
  // therefore the loading mask would be just be "blinking", which we can avoid with the `useMinTimeActive` hook
  const [isLoading, setIsLoading] = useState(false);
  const showLoadMask = useMinTimeActive(isLoading, 500);
  const [isSaving, setIsSaving] = useState(false);
  const [hasSyntaxErrors, setHasSyntaxErrors] = useState(false);

  const textHasChanged = fetchedText !== inputText;

  const metadata: AssetMetadataEntry[] = [
    { text: t('Format'), value: extension },
    { text: t('Size'), value: fileSize },
  ];

  useEffect(() => {
    const fetchText = async (): Promise<void> => {
      setIsLoading(true);

      // change filepath too so that the editor doesn't register unwanted changes
      setMonacoFilePath(DUMMY_MONACO_PATH);
      setFetchedText('');
      setInputText('');

      // get the text content directly from the text asset url
      // url may be `undefined` when executing tests, therefore fall back to empty string, which can be consumed by the
      // mock server
      const textResponse = await getUnparsedText(url);

      setFetchedText(textResponse);
      setInputText(textResponse);
      // monaco would also support the url
      // but it would lose the history on every save, because the url changes on every save
      setMonacoFilePath(`${folderId}-${assetId}.${extension}`);
      setIsLoading(false);
    };
    fetchText();
    setHasSyntaxErrors(false);
  }, [url, extension, folderId, assetId, setIsLoading]);

  const onEditorChange = (newValue: string | undefined): void => {
    setInputText(newValue ?? '');
  };

  const onDiscardBtnClicked = (): void => {
    setInputText(fetchedText);
  };

  const onSaveText = async (): Promise<void> => {
    if (textHasChanged) {
      setIsSaving(true);
      // directly set in local state beforehand to prevent save-button from becoming active in between
      setFetchedText(inputText);

      // TODO: create async thunk which calls "getBundleVersionData" right away
      await postAssetsChangeTextContent(companyId, bundleId, bundleVersion, folderId, assetId, inputText);

      // fetch whole asset-data to update metadata too (there exists no 'more specific' API call (yet))
      dispatch(getBundleVersionData({ companyId, bundleId, bundleVersion }));

      setIsSaving(false);
    }
  };

  const onMonacoEditorValidate = (markers: editor.IMarker[]): void => {
    setHasSyntaxErrors(markers.length > 0);
  };

  return (
    <section data-cmptype="AssetBodyText" className="flex h-[100vh] flex-col gap-6">
      <CbnCard>
        <AssetDetailsHeader metadata={metadata} />
      </CbnCard>

      {/* start with maximum height immediately as we don't want the text editor to grow while typing.
            NOTE: load mask comes with flex row layout, so the "grow" attribute is used for stretching in horizontal
            space anyway
        */}

      <CbnCard grow>
        <CbnCardHeader
          variant="large-title"
          title={t('Text asset content')}
          subText={t('Manage the content of this text asset.')}
          actions={
            isAssetBundleEditable && (
              <div className="flex items-center gap-2">
                <Button
                  variant="Secondary"
                  text={t('Discard changes')}
                  onClick={onDiscardBtnClicked}
                  disabled={!textHasChanged || isSaving}
                />
                <Button
                  text={t('Save changes')}
                  Svg={SaveIcon}
                  onClick={onSaveText}
                  isLoading={isSaving}
                  disabled={!textHasChanged}
                />
                {hasSyntaxErrors && <StateIcon variant="Warning" title={t('Syntax errors detected!')} />}
              </div>
            )
          }
        />
        <div className="grow p-1">
          <MonacoEditor
            path={monacoFilePath} /** Path auto-detects the language based on the file extension */
            value={inputText}
            onChange={onEditorChange}
            options={{ readOnly: !isAssetBundleEditable, minimap: { enabled: false } }}
            onKeyboardSave={onSaveText}
            onValidate={onMonacoEditorValidate}
          />
        </div>

        <CbnLoadMask active={showLoadMask} spinner transparent="semi" text={t('Loading text asset data')} />
      </CbnCard>
    </section>
  );
};
