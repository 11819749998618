import { Divider, ListSubheader, MenuItem, PaperProps, inputClasses, styled } from '@mui/material';
import MuiSelect, { SelectProps as MuiSelectProps, SelectChangeEvent, selectClasses } from '@mui/material/Select';
import React from 'react';
import { ELEVATION_SHADOW } from 'styles/mui-theme';
import { SelectIndicatorIcon } from 'assets/icons';
import { Input } from 'controls/input/input';

export type SelectValue = number | string;

export type SelectOption<T extends React.ReactNode = React.ReactNode> = {
  value: SelectValue;
  text?: T;
  disabled?: boolean;
  group?: string;
  divider?: 'above' | 'below';
};

const _SHOW_DROPDOWN_ICON_PROPNAME = 'showDropdownIcon';
type StyledSelectProps = {
  [_SHOW_DROPDOWN_ICON_PROPNAME]?: boolean;
};

// Taken from `typeof MuiSelect` and customized to our needs
type MuiSelectType = ((props: MuiSelectProps<SelectValue> & StyledSelectProps) => JSX.Element) & {
  muiName: string;
};

export const StyledSelect = styled(MuiSelect, {
  shouldForwardProp: prop => prop !== _SHOW_DROPDOWN_ICON_PROPNAME,
})<StyledSelectProps>(({ showDropdownIcon, theme }) => ({
  [`& .${selectClasses.icon}`]: { width: '24px', height: '24px', top: '4px', right: '10px', color: 'currentColor' },
  [`& .${selectClasses.select}`]: {
    paddingLeft: theme.spacing(4),
  },
  [`& > .${selectClasses.select}.${inputClasses.input}`]: {
    ...(!showDropdownIcon && { paddingRight: '12px' }),
  },
})) as unknown as MuiSelectType;

export const SelectMenuProps: { PaperProps: Partial<PaperProps<'div', {}>> } = {
  PaperProps: {
    sx: theme => ({
      maxHeight: '500px', // prevent large dropdowns
      marginTop: '2px', // consider outline when focused
      border: `1px solid ${theme.palette.neutral[40]}`,
      boxShadow: ELEVATION_SHADOW.LG,
    }),
  },
};

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  'fontSize': theme.typography.mRegular.fontSize,
  'color': theme.palette.neutral[90],

  '&:hover': {
    backgroundColor: theme.palette.neutral[20],
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.neutral[30],
    color: theme.palette.neutral[100],
    fontWeight: theme.typography.mMedium.fontWeight,
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.neutral[30],
  },
  '&.Mui-focusVisible': {
    backgroundColor: theme.palette.neutral[20],
  },
  '&.Mui-selected.Mui-focusVisible': {
    backgroundColor: theme.palette.neutral[30],
  },
}));

export const StyledSelectBase = React.forwardRef<HTMLElement, MuiSelectProps<SelectValue> & StyledSelectProps>(
  ({ fullWidth = true, showDropdownIcon = true, ...other }, ref) => {
    return (
      <StyledSelect
        ref={ref}
        input={<Input fullWidth={fullWidth} />}
        showDropdownIcon={showDropdownIcon}
        IconComponent={showDropdownIcon ? SelectIndicatorIcon : (): null => null}
        MenuProps={SelectMenuProps}
        {...other}
      />
    );
  }
);

type SelectProps = StyledSelectProps & {
  options: SelectOption[];
  value?: SelectValue;
  onChange?: (value: SelectValue) => void;
  disabled?: boolean;
  error?: boolean;
  warning?: boolean;
  tooltip?: string;
  fullWidth?: boolean;

  /**
   * Can be used to render custom content into the "value are" (the thing that is always shown).
   * Defaults to simply rendering the text of the currently selected option.
   */
  valueRenderer?: (value: SelectValue) => React.ReactNode;
};

export const Select: React.FC<SelectProps> = ({
  options,
  value,
  disabled,
  error,
  warning,
  onChange,
  tooltip,
  valueRenderer,
  fullWidth = true,
  showDropdownIcon = true,
}) => {
  const onChangeSelection = (evt: SelectChangeEvent<SelectValue>): void => {
    const newValue = evt.target.value;
    onChange?.(newValue);
  };

  // make sure that the shown value is always available in the options
  const selOption = options.find(o => o.value === value);
  const hasGrouping = options.some(o => !!o.group);
  const groupedOptions = _groupItems(options);

  return (
    <StyledSelectBase
      value={selOption?.value ?? ''}
      input={<Input warning={warning} fullWidth={fullWidth} />}
      onChange={onChangeSelection}
      disabled={disabled}
      renderValue={
        valueRenderer ||
        ((): React.ReactNode => {
          if (selOption) {
            return selOption.text ?? selOption.value;
          } else {
            return value;
          }
        })
      }
      displayEmpty
      error={error}
      title={tooltip}
      showDropdownIcon={showDropdownIcon}
    >
      {hasGrouping
        ? Object.entries(groupedOptions).map(([groupName, options]) => [
            <ListSubheader className="mb-1 mt-2 text-m-medium text-primary-main">{groupName}</ListSubheader>,
            ...options.map(option => (
              <StyledMenuItem key={option.value} value={option.value} disabled={option.disabled} className="ml-2">
                {option.text || option.value}
              </StyledMenuItem>
            )),
          ])
        : options.reduce((acc, option) => {
            if (option.divider === 'above') {
              acc.push(<Divider key={option.value + '$$divider'} />);
            }
            acc.push(
              <StyledMenuItem key={option.value} value={option.value} disabled={option.disabled}>
                {option.text || option.value}
              </StyledMenuItem>
            );
            if (option.divider === 'below') {
              acc.push(<Divider key={option.value + '$$divider'} />);
            }
            return acc;
          }, [] as JSX.Element[])}
    </StyledSelectBase>
  );
};

type GroupedSelectOption = { [key: string]: SelectOption[] };

function _groupItems(options: SelectOption[]): GroupedSelectOption {
  const grouped = options.reduce<GroupedSelectOption>((acc, option) => {
    const groupName = option.group || '';
    acc[groupName] = (acc[groupName] ?? []).concat([option]);
    return acc;
  }, {});
  return grouped;
}
