import { useState } from 'react';
import {
  CopyIcon,
  DeleteIcon,
  EditDraftIcon,
  OpenIcon,
  UploadIcon,
  VerticalDotsMenuIcon,
  ViewDraftInEditorIcon,
} from 'assets/icons';
import { DraftMenu } from 'components/cfgrs/drafts/draft-menu';
import { DraftMenuItem } from 'components/cfgrs/drafts/draft-menu-item';
import { CreateDraftDialog } from 'components/common/create-draft-dialog';
import { RehauUploadDatapackage } from 'components/company-rehau/rehau-upload-datapackage';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { Icon } from 'controls/icon/icon';
import { cn, tw } from 'helper/css.helper';
import { getCfgrPreviewUrl, getEditorUrl } from 'helper/url/cfgr-url.helper';
import { useAlertDialog, useConfirmDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useIsLockedByCurrentUser } from 'hooks/identity/identity.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { Draft, isCfgrEditorDraftLockStatus } from 'services/company-data/company-data.service';
import { deleteDraft, selectSelectedConfigurator } from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

export function hoverIconStyleFactory(visible: boolean, active: boolean): string {
  return cn(
    tw`bg-prima-surface flex h-8 w-8 cursor-pointer flex-row items-center justify-center justify-self-end rounded-sm bg-primary-surface opacity-100 transition-opacity`,
    !visible && tw`pointer-events-none opacity-0`,
    active && tw`bg-primary-focus`
  );
}

type DraftActionKeys = 'Edit' | 'PreviewDraft' | 'ViewDraftEditor' | 'Duplicate' | 'Delete' | 'RehauUpload';
type DraftActionStates = Record<DraftActionKeys, { isVisible: boolean; isDisabled: boolean }>;

type DraftActionsProps = {
  draft: Draft;
  visible: boolean;
  showAsMenu: boolean;
};

export const DraftActions: React.FC<DraftActionsProps> = ({ draft, visible, showAsMenu }) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const confirm = useConfirmDialog();
  const alert = useAlertDialog();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [createDraftOpen, setCreateDraftOpen] = useState(false);
  const [uploadRehauOpen, setUploadRehauOpen] = useState(false);

  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const companyId = selectedCfgr?.companyId || '';
  const cfgrId = selectedCfgr?.id || '';
  const existingDrafts = useAppSelector(state => state.companyData.drafts[companyId][cfgrId]);
  const companyIsRehau = companyId.toUpperCase().includes('REHAU');
  const isLastDraft = existingDrafts.length === 1 && !selectedCfgr?.version;

  const hasViewDraftPermission = usePermission(CompanyPermissions.ViewConfigurator, selectedCfgr?.companyId);
  const hasManageDraftPermission = usePermission(CompanyPermissions.ManageDraft, selectedCfgr?.companyId);

  const isCurrentUserLock = useIsLockedByCurrentUser(draft.lockStatus);

  const isEditorLock = isCfgrEditorDraftLockStatus(draft.lockStatus);
  const isFullLock = !!draft.lockStatus && !isEditorLock; // everything except "cfgr editor lock" is a full lock

  const handleClose = (): void => {
    setMenuAnchorEl(null);
  };

  const onDeleteDraftClick = async (): Promise<void> => {
    if (existingDrafts.length === 1 && !selectedCfgr?.version) {
      alert("Can't delete the last draft of an unpublished configurator!", { variant: 'Error' });
      return;
    }

    const deleteConfirmed = await confirm(t(`Delete the draft "${draft.displayName}"?`), {
      confirmBtnText: 'Delete draft',
      variant: 'Danger',
    });

    if (deleteConfirmed) {
      await dispatch(
        deleteDraft({
          companyId,
          configuratorName: cfgrId,
          draftId: draft.id,
        })
      );
    }
  };

  const onDuplicateDraftClick = async (): Promise<void> => {
    // use timeout so that `autoFocus` in the modal works as expected
    window.setTimeout(() => setCreateDraftOpen(true), 0);
  };

  const onUploadRehauDataPackageClick = (): void => {
    setUploadRehauOpen(true);
  };

  const previewConfiguratorLink = getCfgrPreviewUrl(companyId, cfgrId, draft.id);
  const editDraftLink = getEditorUrl(companyId, cfgrId, draft.id, 'edit');
  const viewDraftInEditorLink = getEditorUrl(companyId, cfgrId, draft.id, 'view');

  const actionStates: DraftActionStates = {
    Edit: {
      isVisible: hasManageDraftPermission && !selectedCfgr?.cfgrFeatures.readonly,
      isDisabled: isFullLock || (isEditorLock && !isCurrentUserLock),
    },
    PreviewDraft: { isVisible: true, isDisabled: isFullLock },
    ViewDraftEditor: { isVisible: hasViewDraftPermission, isDisabled: isFullLock },
    Duplicate: { isVisible: hasManageDraftPermission, isDisabled: isFullLock },
    Delete: {
      isVisible: hasManageDraftPermission,
      isDisabled: isLastDraft ? true : draft.lockStatus ? !draft.lockStatus.allowDelete : false,
    },
    RehauUpload: { isVisible: companyIsRehau, isDisabled: isFullLock },
  };

  return (
    <>
      {showAsMenu && (
        <>
          <button
            type="button"
            className={hoverIconStyleFactory(visible, !!menuAnchorEl)}
            onClick={(event): void => setMenuAnchorEl(event.currentTarget)}
          >
            <Icon Svg={VerticalDotsMenuIcon} className="h-8 text-primary-main" />
          </button>

          <DraftMenu open={!!menuAnchorEl} anchorEl={menuAnchorEl} onClose={handleClose} closeOnClick>
            {actionStates.Edit.isVisible && (
              <DraftMenuItem
                svg={EditDraftIcon}
                text={t('Edit draft')}
                to={editDraftLink}
                linkProps={{ reloadDocument: true }}
                disabled={actionStates.Edit.isDisabled}
              />
            )}

            {actionStates.ViewDraftEditor.isVisible && (
              <DraftMenuItem
                svg={ViewDraftInEditorIcon}
                text={t('View draft in editor')}
                to={viewDraftInEditorLink}
                linkProps={{ reloadDocument: true }}
                disabled={actionStates.ViewDraftEditor.isDisabled}
              />
            )}

            {actionStates.PreviewDraft.isVisible && (
              <DraftMenuItem
                svg={OpenIcon}
                text={t('Preview draft')}
                to={previewConfiguratorLink}
                linkProps={{ target: '_blank' }}
                disabled={actionStates.PreviewDraft.isDisabled}
              />
            )}

            {actionStates.Duplicate.isVisible && (
              <DraftMenuItem
                svg={CopyIcon}
                text={t('Duplicate draft')}
                onClick={onDuplicateDraftClick}
                disabled={actionStates.Duplicate.isDisabled}
              />
            )}

            {actionStates.RehauUpload.isVisible && (
              <DraftMenuItem
                svg={UploadIcon}
                text={t('Upload Rehau Datenpaket')}
                onClick={onUploadRehauDataPackageClick}
                disabled={actionStates.RehauUpload.isDisabled}
              />
            )}

            {actionStates.Delete.isVisible && (
              <DraftMenuItem
                svg={DeleteIcon}
                text={t('Delete draft')}
                onClick={onDeleteDraftClick}
                variant="danger"
                disabled={actionStates.Delete.isDisabled}
              />
            )}
          </DraftMenu>
        </>
      )}

      {!showAsMenu && (
        <div className="flex items-center gap-3">
          {actionStates.Edit.isVisible && (
            <LinkButton
              variant="TextInline"
              href={editDraftLink}
              reloadDocument
              Svg={EditDraftIcon}
              title={t('Edit draft')}
              disabled={actionStates.Edit.isDisabled}
            />
          )}

          {actionStates.ViewDraftEditor.isVisible && (
            <LinkButton
              variant="TextInline"
              href={viewDraftInEditorLink}
              reloadDocument
              Svg={ViewDraftInEditorIcon}
              title={t('View draft in editor')}
              disabled={actionStates.ViewDraftEditor.isDisabled}
            />
          )}

          {actionStates.PreviewDraft.isVisible && (
            <LinkButton
              variant="TextInline"
              href={previewConfiguratorLink}
              target="_blank"
              Svg={OpenIcon}
              title={t('Preview draft')}
              disabled={actionStates.PreviewDraft.isDisabled}
            />
          )}

          {actionStates.Duplicate.isVisible && (
            <Button
              variant="TextInline"
              Svg={CopyIcon}
              title={t('Duplicate draft')}
              disabled={actionStates.Duplicate.isDisabled}
              onClick={onDuplicateDraftClick}
            />
          )}

          {actionStates.RehauUpload.isVisible && (
            <Button
              variant="TextInline"
              Svg={UploadIcon}
              title={t('Upload Rehau Datenpaket')}
              disabled={actionStates.RehauUpload.isDisabled}
              onClick={onUploadRehauDataPackageClick}
            />
          )}

          {actionStates.Delete.isVisible && (
            <Button
              variant="TextInline"
              hoverColor="Danger"
              Svg={DeleteIcon}
              title={
                isLastDraft
                  ? t("Can't delete the last draft of an unpublished configurator!")
                  : !actionStates.Delete.isDisabled
                    ? t('Delete draft')
                    : undefined
              }
              disabled={actionStates.Delete.isDisabled}
              onClick={onDeleteDraftClick}
            />
          )}
        </div>
      )}

      {createDraftOpen && (
        <CreateDraftDialog
          initialSourceCfgrVersionId={draft.id}
          draftNameSuggestion={draft.displayName}
          onCancel={(): void => setCreateDraftOpen(false)}
          onConfirm={(): void => setCreateDraftOpen(false)}
        />
      )}

      {companyIsRehau && uploadRehauOpen && (
        <RehauUploadDatapackage
          destination={{ companyId: companyId, cfgrName: cfgrId, draftId: draft.id }}
          onCancel={(): void => setUploadRehauOpen(false)}
          onConfirm={(): void => setUploadRehauOpen(false)}
        />
      )}
    </>
  );
};
