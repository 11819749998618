import { EndpointDescription } from 'services/http/http.service';
import { WorkflowRunId } from 'services/workflows/workflows.service';

export const WORKFLOWRUNS_ENDPOINTS = {
  workflowRunsGetAllForWorkflow: (companyId: string, workflowName: string): EndpointDescription => ({
    url: '/workflowruns/getAllForWorkflow',
    params: {
      companyName: companyId,
      workflowName: workflowName,
    },
  }),

  workflowRunsGetWorkflowRun: (
    companyId: string,
    workflowName: string,
    workflowRunId: string
  ): EndpointDescription => ({
    url: '/workflowruns/getWorkflowRun',
    params: {
      companyName: companyId,
      workflowName: workflowName,
      workflowRunId: workflowRunId,
    },
  }),

  workflowRunsGetWorkflowRunLog: (
    companyId: string,
    workflowName: string,
    workflowRunId: WorkflowRunId
  ): EndpointDescription => ({
    url: '/workflowruns/getWorkflowRunLog',
    params: {
      companyName: companyId,
      workflowName: workflowName,
      workflowRunId: workflowRunId,
    },
  }),
};
