// Generated by EnumsBuilderTypeScript (DO NOT change this file manually, change the builder if required)
export enum CfgnInsightsQueryableProperties {
  ID = 'id',
  ASSIGNED_TO = 'assignedTo',
  CREATED_AT = 'createdAt',
  CREATED_BY = 'createdBy',
  TYPE = 'type',
  CHECKOUT_DATA = 'checkoutData',
  METADATA = 'metadata',
  INSIGHTS_VALUES = 'insightsValues',
  CFGR_VERSION = 'cfgrVersion',
  PRESET_ID = 'presetId',
  BASED_ON_CFGN_ID = 'basedOnCfgnId',
  COMMENT = 'comment',
  TOTAL_TIME_SPENT = 'totalTimeSpent'
}