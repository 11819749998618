import { BooleanFilterInput } from 'components/cfgrs/cfgn-insights/filter-inputs/boolean-filter-input';
import { DateFilterInput } from 'components/cfgrs/cfgn-insights/filter-inputs/date-filter-input';
import { NumberRangeFilterInput } from 'components/cfgrs/cfgn-insights/filter-inputs/number-range-input';
import { StaticOptionsFilterInput } from 'components/cfgrs/cfgn-insights/filter-inputs/static-options-filter-input';
import { StringFilterInput } from 'components/cfgrs/cfgn-insights/filter-inputs/string-filter-input';
import { UserFilterInput } from 'components/cfgrs/cfgn-insights/filter-inputs/user-filter-input';
import { CfgnInsightsBrowsers } from 'generated/cfgn-insights-browsers';
import { CfgnInsightsType } from 'generated/cfgn-insights-types';
import { FilterItemValueType } from 'generated/filter-item-value-type';
import { ShopHiveTypes } from 'generated/shop-hive-types';
import {
  isNumberListQueryValue,
  isNumberRangeQueryValue,
  isSingleBoolValueQueryValue,
  isStringListQueryValue,
} from 'helper/cfgn-insights.helper';
import {
  BrowserDisplayNames,
  CfgnTypeDisplayNames,
  ShopTypeDisplayNames,
} from 'slices/cfgn-insisghts/cfgn-insights.slice';
import { CfgnInsightSpecificType } from 'slices/cfgn-insisghts/property-definitions';

export const NoSelectionChipValue = '';

/**
 * All items will be queried using `FilterOperators.In` (as in "has one of" or combined using logical `or`).
 * Empty array means "not set/don't query"
 */
export type CfgnInsightsListQueryValue = string[] | number[] | [];

/**
 * Array with one item which can explicitly be `undefined` as well.
 * Empty array means "not set/don't query", `[undefined]` means query using `FilterOperators.HasNoValue`.
 *
 * E.g. `[undefined]` is used for queries like "show all cfgns where we don't know whether it was a mobile or not"
 * which is represented as the `Unknown` option for boolean properties in `BooleanFilterInput`.
 */
export type CfgnInsightsSingleValueQueryValue = [string] | [number] | [boolean] | [undefined] | [null] | [];

/**
 * Array with two or no items. Empty array means "not set/don't query".
 * Undefined values inside the array are allowed and mean that the respective bound is not set.
 * E.g.:
 *   - `[5, 10]` means query for ">= 5 AND <= 10"
 *   - `[undefined, 5]` means query for "<= 5"
 *   - `[5, undefined]` means query for ">= 5"
 *
 * Used for "date range queries" as well where the values are ms since epoch (`date.getTime()`).
 */
export type CfgnInsightsNumberRangeQueryValue = [number | undefined, number | undefined] | [];

export type CfgnInsightsFilterInputValue =
  | CfgnInsightsListQueryValue
  | CfgnInsightsSingleValueQueryValue
  | CfgnInsightsNumberRangeQueryValue
  | CfgnInsightsType[]
  | ShopHiveTypes[]
  | CfgnInsightsBrowsers[];

export type CfgnInsightsFilterInputProps<T extends CfgnInsightsFilterInputValue> = {
  /**
   * Display name of the property at hand. Will be used in different places, e.g. as placeholder for the input field of
   * "add new option" dialog for free text filters etc.
   */
  subjectName: string;
  value: T;
  type: FilterItemValueType | CfgnInsightSpecificType;
  onCommit: (value: T) => void;
};

export const CfgnInsightsFilterInput: React.FC<CfgnInsightsFilterInputProps<CfgnInsightsFilterInputValue>> = props => {
  const { type, value } = props;

  if (type === FilterItemValueType.String && isStringListQueryValue(value)) {
    return <StringFilterInput {...props} value={value} />;
  } else if (type === FilterItemValueType.DateTime && isNumberRangeQueryValue(value)) {
    return <DateFilterInput {...props} value={value} />;
  } else if (type === FilterItemValueType.Double && isNumberRangeQueryValue(value)) {
    return <NumberRangeFilterInput {...props} value={value} />;
  } else if (type === FilterItemValueType.Boolean && isSingleBoolValueQueryValue(value)) {
    return <BooleanFilterInput {...props} value={value} />;
  } else if (type === 'identity' && isStringListQueryValue(value)) {
    return <UserFilterInput {...props} value={value} />;
  } else if (type === 'cfgn-type' && isNumberListQueryValue(value)) {
    return <StaticOptionsFilterInput {...props} value={value as CfgnInsightsType[]} options={CfgnTypeDisplayNames} />;
  } else if (type === 'checkout-type' && isStringListQueryValue(value)) {
    return <StaticOptionsFilterInput {...props} value={value as ShopHiveTypes[]} options={ShopTypeDisplayNames} />;
  } else if (type === 'browser' && isStringListQueryValue(value)) {
    return (
      <StaticOptionsFilterInput {...props} value={value as CfgnInsightsBrowsers[]} options={BrowserDisplayNames} />
    );
  } else {
    throw new Error(
      `CfgnInsightsFilterInput: Given type "${type}" is not supported yet or given value (${JSON.stringify(value)}) does not match the type.`
    );
  }
};
