import { createTheme } from '@mui/material/styles';
import type { TypographyStyleOptions } from '@mui/material/styles/createTypography';

const commonStyles = require('./common-styles');

declare module '@mui/material/styles' {
  interface CustomTheme {
    outlines: {
      [name: string]: string;
    };
  }

  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}

  interface SimplePaletteColorOptions {
    surface?: string;
    border?: string;
    hover?: string;
    pressed?: string;
    focus?: string;
  }

  interface PaletteColor {
    surface?: string;
    border?: string;
    hover?: string;
    pressed?: string;
    focus: string;
  }

  interface Palette {
    type: {
      primary: string;
      secondary: string;
      disabled: string;
    };
    neutral: {
      10: string;
      20: string;
      30: string;
      40: string;
      50: string;
      60: string;
      70: string;
      80: string;
      90: string;
      100: string;
    };
    brand: {
      blue1: string;
      blue2: string;
      green1: string;
      green2: string;
    };
    transparent: string;
  }

  interface PaletteOptions {
    type: {
      primary: string;
      secondary: string;
      disabled: string;
    };
    neutral: {
      10: string;
      20: string;
      30: string;
      40: string;
      50: string;
      60: string;
      70: string;
      80: string;
      90: string;
      100: string;
    };
    brand: {
      blue1: string;
      blue2: string;
      green1: string;
      green2: string;
    };
    transparent: string;
  }

  interface TypographyVariants {
    xsRegular: TypographyStyleOptions;
    xsMedium: TypographyStyleOptions;
    sRegular: TypographyStyleOptions;
    sMedium: TypographyStyleOptions;
    mRegular: TypographyStyleOptions;
    mMedium: TypographyStyleOptions;
    lRegular: TypographyStyleOptions;
    lMedium: TypographyStyleOptions;
    headingXs: TypographyStyleOptions;
    headingS: TypographyStyleOptions;
    headingM: TypographyStyleOptions;
    headingL: TypographyStyleOptions;
    tableHeading: TypographyStyleOptions;
    tableHeadingSub: TypographyStyleOptions;
    h1: undefined;
    h2: undefined;
    h3: undefined;
    h4: undefined;
    h5: undefined;
    h6: undefined;
    subtitle1: undefined;
    subtitle2: undefined;
    body1: undefined;
    body2: undefined;
    button: undefined;
    caption: undefined;
    overline: undefined;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    xsRegular?: TypographyStyleOptions;
    xsMedium?: TypographyStyleOptions;
    sRegular?: TypographyStyleOptions;
    sMedium?: TypographyStyleOptions;
    mRegular?: TypographyStyleOptions;
    mMedium?: TypographyStyleOptions;
    lRegular?: TypographyStyleOptions;
    lMedium?: TypographyStyleOptions;
    headingXs?: TypographyStyleOptions;
    headingS?: TypographyStyleOptions;
    headingM?: TypographyStyleOptions;
    headingL?: TypographyStyleOptions;
    tableHeading?: TypographyStyleOptions;
    tableHeadingSub?: TypographyStyleOptions;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    xsRegular: true;
    xsMedium: true;
    sRegular: true;
    sMedium: true;
    mRegular: true;
    mMedium: true;
    lRegular: true;
    lMedium: true;
    headingXs: true;
    headingS: true;
    headingM: true;
    headingL: true;
    tableHeading: true;
    tableHeadingSub: true;
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    h5: false;
    h6: false;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    button: false;
    caption: false;
    overline: false;
  }
}

declare module '@mui/system' {
  interface Shape {
    borderRadiusCtrls?: string | number;
    borderRadiusRoundedLg?: string | number;
  }
}

const typoHeadings: Record<'h1' | 'h2' | 'h3' | 'h4', TypographyStyleOptions> = {
  h1: {
    fontFamily: 'Inter',
    fontSize: '2.25rem',
    lineHeight: '2.75rem',
    fontWeight: 600,
    letterSpacing: '-0.022em',
  },
  h2: {
    fontFamily: 'Inter',
    fontSize: '1.75rem',
    lineHeight: '2.25rem',
    fontWeight: 600,
    letterSpacing: '-0.021em',
  },
  h3: {
    fontFamily: 'Inter',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontWeight: 600,
    letterSpacing: '-0.017em',
  },
  h4: {
    fontFamily: 'Inter',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    fontWeight: 600,
    letterSpacing: '-0.017em',
  },
};

export const muiTheme = createTheme({
  spacing: 4,
  typography: {
    fontFamily: 'Inter',
    xsRegular: {
      fontFamily: 'Inter',
      fontSize: '0.625rem',
      lineHeight: '1rem',
      fontWeight: 400,
    },
    xsMedium: {
      fontFamily: 'Inter',
      fontSize: '0.625rem',
      lineHeight: '1rem',
      fontWeight: 500,
    },
    sRegular: {
      fontFamily: 'Inter',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
    },
    sMedium: {
      fontFamily: 'Inter',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 500,
    },
    mRegular: {
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
      letterSpacing: '-0.006em',
    },
    mMedium: {
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 500,
      letterSpacing: '-0.006em',
    },
    lRegular: {
      fontFamily: 'Inter',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
      letterSpacing: '-0.011em',
    },
    lMedium: {
      fontFamily: 'Inter',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
      letterSpacing: '-0.011em',
    },
    tableHeading: {
      fontFamily: 'Inter',
      fontSize: '0.625rem',
      lineHeight: '0.875rem',
      fontWeight: 600,
      letterSpacing: '0.06em',
    },
    tableHeadingSub: {
      fontFamily: 'Inter',
      fontSize: '0.625rem',
      lineHeight: '0.875rem',
      fontWeight: 400,
    },
    headingXs: typoHeadings.h4,
    headingS: typoHeadings.h3,
    headingM: typoHeadings.h2,
    headingL: typoHeadings.h1,
    h1: typoHeadings.h1,
    h2: typoHeadings.h2,
    h3: typoHeadings.h3,
    h4: typoHeadings.h4,
    h5: undefined,
    h6: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    body1: undefined,
    body2: undefined,
    button: undefined,
    caption: undefined,
    overline: undefined,
  },
  palette: {
    primary: {
      main: '#0040A0',
      surface: '#E9EEF7',
      border: '#AABFDF',
      hover: '#003585',
      pressed: '#00205A',
      focus: '#0040A033',
    },
    type: {
      primary: '#001830', // neutral-100
      secondary: '#344054', // neutral-90
      disabled: '#98A2B3', // neutral-60
    },
    neutral: {
      '10': '#FFFFFF',
      '20': '#F9FAFB',
      '30': '#F2F4F7',
      '40': '#EAECF0',
      '50': '#D0D5DD',
      '60': '#98A2B3',
      '70': '#667085',
      '80': '#475467',
      '90': '#344054',
      '100': '#001830',
    },
    info: {
      main: '#0040A0',
      surface: '#E9EEF7',
      border: '#AABFDF',
      hover: '#003585',
      pressed: '#00205A',
      focus: '#0040A033',
    },
    error: {
      main: '#D92D20',
      surface: '#FFECEC',
      border: '#FFD7D7',
      hover: '#A20000',
      pressed: '#630000',
      focus: '#D92D2033', // 20% alpha
    },
    warning: {
      main: '#F79009',
      surface: '#FFF3E3',
      border: '#FCE6AD',
      hover: '#D16603',
      pressed: '#7C4805',
      focus: '#F7900933', // 20% alpha
    },
    success: {
      main: '#11B76A',
      surface: '#E0FFF1',
      border: '#B0E7CD',
      hover: '#0E9858',
      pressed: '#085C35',
      focus: '#11B76A33', // 20% alpha
    },
    brand: {
      blue1: '#0040A0',
      blue2: '#00205A',
      green1: '#00F0B4',
      green2: '#31C8AC',
    },
    transparent: 'transparent',
  },
  shape: {
    borderRadius: 4,
    borderRadiusCtrls: '0.25rem',
    borderRadiusRoundedLg: '0.5rem',
  },
  // Shadows are defined in `common-styles.js` file, as they are shared with the tailwind config
  shadows: [
    // Elevation shadows from `ELEVATION_SHADOW.None` to `ELEVATION_SHADOW.3XL`
    // Usage: theme.shadows[ELEVATION_SHADOW.LG]
    commonStyles.shadows['none'],
    commonStyles.shadows['xs'],
    commonStyles.shadows['sm'],
    commonStyles.shadows['md'],
    commonStyles.shadows['lg'],
    commonStyles.shadows['xl'],
    commonStyles.shadows['2xl'],
    commonStyles.shadows['3xl'],

    // Custom shadows
    // Usage: theme.shadows[NAMED_SHADOW.TableRow]
    commonStyles.shadows['sm-symmetric'],
    commonStyles.shadows['ctrl-disabled'],
    commonStyles.shadows['select-menu'],
    commonStyles.shadows['table-row'],
    commonStyles.shadows['divider-line'],
    commonStyles.shadows['underline-thick'],
    commonStyles.shadows['tooltip-inverted'],

    // Currently unused
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  outlines: {
    default: '2px solid #0040A033', // color primary.focus
    defaultWarning: '2px solid #F7900933', // color warning.focus
    defaultError: '2px solid #D92D2033', // color error.focus
    large: '3px solid #0040A033', // color primary.focus
  },
});

/**
 * Combeenation Elevation Levels
 * The values represent the index in the `shadows` array of the theme
 */
export const ELEVATION_SHADOW = {
  'None': 0,
  'XS': 1,
  'SM': 2,
  'MD': 3,
  'LG': 4,
  'XL': 5,
  '2XL': 6,
  '3XL': 7,
};

/**
 * Named Box Shadows
 * The values represent the index in the `shadows` array of the theme
 * See "common-styles.js" file for detailed description of the shadows
 */
export const NAMED_SHADOW = {
  SMSymmetric: 8,
  CtrlDisabled: 9,
  SelectMenu: 10,
  TableRow: 11,
  DividerLine: 12,
  UnderlineThick: 13,
  TooltipInverted: 14,
};
