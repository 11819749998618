import React from 'react';
import { MetadataRow } from 'components/metadata/metadata-row';

type MetadataColProps = {
  children:
    | React.ReactComponentElement<typeof MetadataRow>
    | boolean
    | (React.ReactComponentElement<typeof MetadataRow> | boolean)[];
};

export const MetadataCol: React.FC<MetadataColProps> = ({ children }) => {
  return (
    <div data-cmptype="MetadataCol" className="grid auto-rows-max grid-cols-[auto_1fr] gap-x-5 gap-y-1">
      {children}
    </div>
  );
};
