import {
  ICustomProperties,
  IExceptionTelemetry,
  ITelemetryItem,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { PREVENT_DIALOG_KEY, USER_MESSAGE_KEY, aiInstance } from 'services/telemetry/telemetry.service';

let _logToConsole = true;

export const printLogsToConsole = (doLog = true): void => {
  _logToConsole = doLog;
};

export type ErrorGuid = string;
export const randomThreeDigitString = (): string =>
  Math.floor(Math.random() * 999)
    .toString()
    .padStart(3, '0');
export const generateErrorGuid = (): ErrorGuid => Date.now().toString() + randomThreeDigitString();

// ISO: 2022-04-15T10:03:40.486Z -> Sliced: 10:03:40.486
const _getFormattedTime = (): string => new Date().toISOString().slice(11, 23);

function _getStackAsArray(): string[] {
  const stack = new Error().stack || '';
  const arr = stack.replace(/(\r\n|\n|\r)/gm, '').split(/\s{2,10}at /gm);
  arr.shift();
  return arr;
}

export type LogSeverity = keyof typeof SeverityLevel;
export type LogSeverityError = Pick<typeof SeverityLevel, 'Critical' | 'Error'>;

type LogSeverityFormat = {
  emoji: string;
  style: string;
};

const LOG_SEVERITY_FORMAT_MAP: Record<LogSeverity, LogSeverityFormat> = {
  Verbose: { emoji: '🧾', style: 'color: grey;' },
  Information: { emoji: '📘', style: 'color: #466FD5;' },
  Warning: { emoji: '⚠️', style: 'color: #ebc334;' },
  Error: { emoji: '🛑', style: 'color: red;' },
  Critical: { emoji: '💥', style: 'color: red;' },
};

// If all properties are optional it's possible to pass "wrong" types like it would be a { [key: string]: string }
// https://docs.microsoft.com/en-us/javascript/api/@azure/keyvault-certificates/requireatleastone?view=azure-node-latest
type RequireAtLeastOne<T> = { [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>> }[keyof T];

type _LogOptions = {
  /** Action object from Redux (middleware, etc.) */
  action?: PayloadAction<void, any, any, any>;
  /** Pass an existing or created "AI envelope" */
  envelope?: ITelemetryItem;
  /** Should contain any data which might be helpful to analyze the logs */
  customProperties?: ICustomProperties;
  /** Don't send to tracker but only log to console */
  sendToTracker?: boolean;
};

export type LogOptions = RequireAtLeastOne<_LogOptions>;

export type _ErrorOptions = {
  /** Action object from Redux (middleware, etc.) */
  action?: PayloadAction<void, any, any, any>;
  /** Should contain any data which might be helpful to analyze the error */
  customProperties?: ICustomProperties;
  preventDialog?: boolean;
  /**
   * Changes severity from {@link SeverityLevel.Error} to {@link SeverityLevel.Critical}.
   * Besides that it behaves like an Error
   */
  isCritical?: boolean;
  userMessage: string;
};

export type ErrorOptions = RequireAtLeastOne<_ErrorOptions>;

function _printLog(message: string, severity: LogSeverity, options?: LogOptions): void {
  const formatting = LOG_SEVERITY_FORMAT_MAP[severity];
  const additionalDataStr = options?.customProperties ? ' | data = %O' : '';
  const actionStr = options?.action ? ' | action = %O' : '';
  const envelopeStr = options?.envelope ? ' | envelope = %O' : '';

  const prefix = severity === 'Critical' ? '[Critical] ' : '';

  const consoleParams: any[] = [_getStackAsArray(), formatting.style];
  if (options?.action) {
    consoleParams.push(options.action);
  }
  if (options?.customProperties) {
    consoleParams.push(options.customProperties);
  }
  if (options?.envelope) {
    consoleParams.push(options.envelope);
  }

  console.log(
    `%O ${
      formatting.emoji
    } %c[${_getFormattedTime()}]        ${prefix}${message}${envelopeStr}${actionStr}${additionalDataStr}`,
    ...consoleParams
  );
}

export function addLog(message: string, severity: LogSeverity = 'Information', options?: LogOptions): void {
  if (options?.sendToTracker !== false) {
    // Tracked 'logs' are always logged to the console so we don't have to print them in the current context
    // In detail: The telemetry service calls `addLog` with `sendToTracker: false`
    aiInstance.trackTrace({
      message: message,
      severityLevel: SeverityLevel[severity],
      properties: {
        action: options?.action,
        ...options?.customProperties,
      },
    });
  } else if (_logToConsole && process.env.NODE_ENV !== 'test') {
    _printLog(message, severity, options);
  }
}

/**
 * Report an error which always gets reported to AI and shows an dialog by default
 * @param title The main title that is shown for the error
 * @param options Add data to the error, don't show dialog, etc.
 * @returns Unique ID of the error
 */
export function addError(title: string, options?: ErrorOptions): ErrorGuid {
  let errorGuid;

  if (options?.action) {
    const userMessage = (options.action.error as SerializedError).message || options.action.type;
    options = { ...options, userMessage: userMessage };
    errorGuid = _trackActionException(options.action!, title, options);
  } else {
    options = { ...options, userMessage: title };
    errorGuid = _trackDefaultException(title, options);
  }

  return errorGuid;
}

function _getGenericCustomProperties(options?: ErrorOptions): ICustomProperties | undefined {
  if (!options) {
    return undefined;
  }
  const props: ICustomProperties = {};
  if (options?.preventDialog) {
    props[PREVENT_DIALOG_KEY] = true;
  }
  if (options?.userMessage) {
    props[USER_MESSAGE_KEY] = options.userMessage;
  }
  return props;
}

function _trackActionException(
  action: PayloadAction<void, any, any, SerializedError>,
  title: string,
  options?: ErrorOptions
): ErrorGuid {
  const aiException: IExceptionTelemetry = {
    id: action.meta?.requestId || generateErrorGuid(),
    exception: action.error as Error,
    severityLevel: options?.isCritical ? SeverityLevel.Critical : SeverityLevel.Error,
    properties: {
      title: title,
      responseErrorId: action.error.code,
      ...options?.customProperties,
    },
  };

  aiInstance.trackException(aiException, { message: action.error.message, ..._getGenericCustomProperties(options) });
  return aiException.id as ErrorGuid;
}

function _trackDefaultException(title: string, options?: ErrorOptions): ErrorGuid {
  const errorGuid = generateErrorGuid();
  const aiException: IExceptionTelemetry = {
    id: errorGuid,
    exception: new Error(title),
    severityLevel: options?.isCritical ? SeverityLevel.Critical : SeverityLevel.Error,
    properties: {
      title: title,
      ...options?.customProperties,
    },
  };

  aiInstance.trackException(aiException, _getGenericCustomProperties(options));
  return errorGuid;
}
