import { SelectChangeEvent } from '@mui/material';
import { GridEditSingleSelectCell, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import {
  ConfigRow,
  EDIT_GRID_FIELD_KEYS,
} from 'components/asset-editor/details/modals/datasource-edit/edit-grid/asset-datasource-edit-grid';
import { DataSourceColumnTypes } from 'generated/datasource-column-types';

type AssetDatasourceEditGridLinkedAssetFolderCellProps = GridRenderEditCellParams<ConfigRow> & {};
export const AssetDatasourceEditGridLinkedAssetFolderCell: React.FC<
  AssetDatasourceEditGridLinkedAssetFolderCellProps
> = props => {
  const apiRef = useGridApiContext();
  const { id, row } = props;

  // reset default value if folder changes
  // default value can only be a linked asset in this case and this linked asset was meant to be located in the old
  // folder
  const handleLinkedAssetValueChange = async (event: SelectChangeEvent, newValue: string): Promise<void> => {
    await apiRef.current.setEditCellValue({
      id: id,
      field: EDIT_GRID_FIELD_KEYS.DefaultValue,
      value: '',
    });
  };

  const typeDef = row[EDIT_GRID_FIELD_KEYS.TypeDef];
  if (typeDef.type === DataSourceColumnTypes.LinkedAsset) {
    return <GridEditSingleSelectCell onValueChange={handleLinkedAssetValueChange} {...props} />;
  } else {
    return null;
  }
};
