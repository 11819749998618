import { useState } from 'react';
import {
  CfgnInsightsFilterInput,
  CfgnInsightsFilterInputValue,
} from 'components/cfgrs/cfgn-insights/cfgn-insights-filter-input';
import { Accordion } from 'controls/accordion/accordion';
import { distinctFilter } from 'helper/array/array.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import {
  getCfgrCfgnInsights,
  selectCfgrCfgnInsightsPropertyDefinitions,
} from 'slices/cfgn-insisghts/cfgn-insights.slice';
import {
  CfgnInsightPropertyDefinition,
  isQueryableBuiltInInsightsProperty,
} from 'slices/cfgn-insisghts/property-definitions';
import { selectSelectedConfigurator } from 'slices/company-data/company-data.slice';

type CfgnInsightsFiltersCollapsibleProps = {};

type FilterPropertyGroup = CfgnInsightPropertyDefinition['group'];

export const CfgnInsightsFiltersCollapsible: React.FC<CfgnInsightsFiltersCollapsibleProps> = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const { queryValues, stageName } = useAppSelector(state => state.cfgnInsights);

  const insightsColProperties = useAppSelector(selectCfgrCfgnInsightsPropertyDefinitions);
  const allPropertyGroups = insightsColProperties.map(x => x.group).filter(distinctFilter);
  const [openGroups, setOpenGroups] = useState<FilterPropertyGroup[]>(['Most used', 'Custom insights values']);

  const groupedProperties = allPropertyGroups
    .map(groupName => {
      const properties = insightsColProperties.filter(col => col.group === groupName && !!col.queryType);
      return { groupName, properties };
    })
    .filter(group => group.properties.length > 0);

  function toggleGroupCollapse(groupName: FilterPropertyGroup): void {
    const updatedOpenGroups = openGroups.includes(groupName)
      ? openGroups.filter(group => group !== groupName)
      : [...openGroups, groupName];

    setOpenGroups(updatedOpenGroups);
  }

  function applyNewFilterValue(propertyName: string, value: CfgnInsightsFilterInputValue): void {
    const isBuiltInProperty = isQueryableBuiltInInsightsProperty(propertyName);
    const updatedQueryValues = {
      builtIn: {
        ...queryValues.builtIn,
        ...(isBuiltInProperty && { [propertyName]: value }),
      },
      insightsValues: {
        ...queryValues.insightsValues,
        ...(!isBuiltInProperty && { [propertyName]: value }),
      },
    };

    if (selectedCfgr?.companyId && selectedCfgr?.id) {
      dispatch(
        getCfgrCfgnInsights({
          companyId: selectedCfgr.companyId,
          cfgrId: selectedCfgr.id,
          stageName,
          queryValues: updatedQueryValues,
        })
      );
    }
  }

  return (
    <div data-cmptype="CfgnInsightsFiltersCollapsible" className="rounded-[4px] border border-neutral-40 bg-neutral-20">
      {groupedProperties.map(property => (
        <Accordion
          key={property.groupName}
          className="bg-neutral-20"
          summary={t(property.groupName)}
          noBodyMargin
          expanded={openGroups.includes(property.groupName)}
          onChange={(): void => toggleGroupCollapse(property.groupName)}
        >
          <div className="flex flex-col gap-5 px-4 pb-4 pt-2">
            {property.properties.map(property => (
              <div key={property.name} className="flex flex-col gap-2 text-m-medium text-neutral-100">
                {property.label}
                <CfgnInsightsFilterInput
                  type={property.specificType ?? property.valueType}
                  subjectName={property.label}
                  value={
                    isQueryableBuiltInInsightsProperty(property.name)
                      ? queryValues.builtIn[property.name]
                      : queryValues.insightsValues[property.name] ?? []
                  }
                  onCommit={(x): void => applyNewFilterValue(property.name, x)}
                />
              </div>
            ))}
          </div>
        </Accordion>
      ))}
    </div>
  );
};
