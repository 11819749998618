import { useContext, useState } from 'react';
import {
  PlusIcon,
  TextAssetHtmlIcon,
  TextAssetJsonIcon,
  TextAssetSvgIcon,
  TextAssetTextIcon,
  TextAssetXmlIcon,
} from 'assets/icons';
import { AssetDetailsContext } from 'components/asset-editor/details/asset-details';
import {
  CreateTextAssetModal,
  PredefinedExtension,
} from 'components/asset-editor/overview/modals/create-text-asset-modal';
import { Button } from 'controls/button/button';
import { CbnInfoCard } from 'controls/cbn-card/implementations/cbn-info-card';
import { CbnDragAndDropPrompt } from 'controls/cbn-drag-and-drop-prompt/cbn-drag-and-drop-prompt';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

export const EmptyFolderFallback: React.FC = () => {
  const { t } = useTypedTranslation();

  const { onUploadFilesSelected } = useContext(AssetDetailsContext);

  const [createTextAssetModal, setCreateTextAssetModal] = useState(false);
  const [predefinedExtension, setPredefinedExtension] = useState<PredefinedExtension>();

  return (
    <>
      <div data-cmptype="EmptyFolderFallback" className="flex flex-col gap-3">
        <span className="text-m-regular text-neutral-100">{t('This folder is empty.')}</span>
        <CbnDragAndDropPrompt onUploadFilesSelected={onUploadFilesSelected} />
        <div className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-3">
          <CbnInfoCard
            icon={TextAssetTextIcon}
            title={t('Text')}
            subText={t('Create plain text content.')}
            actions={
              <Button
                variant="Secondary"
                text={t('Create')}
                Svg={PlusIcon}
                onClick={(): void => {
                  setPredefinedExtension('txt');
                  setCreateTextAssetModal(true);
                }}
              />
            }
          />
          <CbnInfoCard
            icon={TextAssetSvgIcon}
            title={t('SVG')}
            subText={t('Create SVG markup to access in your configurators.')}
            actions={
              <Button
                variant="Secondary"
                text={t('Create')}
                Svg={PlusIcon}
                onClick={(): void => {
                  setPredefinedExtension('svg');
                  setCreateTextAssetModal(true);
                }}
              />
            }
          />
          <CbnInfoCard
            icon={TextAssetXmlIcon}
            title={t('XML')}
            subText={t('Create XML markup to directly access in your configurators.')}
            actions={
              <Button
                variant="Secondary"
                text={t('Create')}
                Svg={PlusIcon}
                onClick={(): void => {
                  setPredefinedExtension('xml');
                  setCreateTextAssetModal(true);
                }}
              />
            }
          />
          <CbnInfoCard
            icon={TextAssetHtmlIcon}
            title={t('HTML')}
            subText={t('Create HTML markup to directly access in your configurators.')}
            actions={
              <Button
                variant="Secondary"
                text={t('Create')}
                Svg={PlusIcon}
                onClick={(): void => {
                  setPredefinedExtension('html');
                  setCreateTextAssetModal(true);
                }}
              />
            }
          />
          <CbnInfoCard
            icon={TextAssetJsonIcon}
            title={t('JSON')}
            subText={t('Create JSON data to access in your configurators.')}
            actions={
              <Button
                variant="Secondary"
                text={t('Create')}
                Svg={PlusIcon}
                onClick={(): void => {
                  setPredefinedExtension('json');
                  setCreateTextAssetModal(true);
                }}
              />
            }
          />
        </div>
      </div>

      {createTextAssetModal && (
        <CreateTextAssetModal
          predefinedExtension={predefinedExtension}
          onConfirm={(): void => setCreateTextAssetModal(false)}
          onCancel={(): void => setCreateTextAssetModal(false)}
        />
      )}
    </>
  );
};
