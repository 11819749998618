import {
  createSourceStringFromFolderAndAssetId,
  getAssetLinksOfAsset,
  isAssetOfSameBundleVersion,
} from 'helper/assets/assets.helper';
import { isSasUriExpired } from 'helper/sas-uri/sas-uri.helper';
import { fetchAssetMaterialContent, fetchSasUrisOfMaterials } from 'services/assets/assets.service';
import * as HttpService from 'services/http/http.service';
import { store } from 'services/store/store.service';
import {
  AssetLink,
  AssetShortPath,
  ImageAsset,
  TEXTURES_3D_FOLDER_NAME,
  isMaterialAsset,
} from 'slices/assets/assets.slice';

export type MaterialObject = { [key: string]: any } & { customType?: MaterialType };
export type TextureObject = { [key: string]: any };

// these are the currently supported material types of the platform
export type MaterialType =
  | 'BABYLON.PBRMaterial'
  | 'BABYLON.PBRMetallicRoughnessMaterial'
  | 'BABYLON.PBRSpecularGlossinessMaterial'
  | 'BABYLON.StandardMaterial'
  | 'BABYLON.BackgroundMaterial'
  | 'BABYLON.NodeMaterial';

// fallback material if associated material asset can't be found
// this one is completely black, just as if no material was set at all
const _MISSING_MATERIAL: MaterialObject = {
  customType: 'BABYLON.StandardMaterial',
  disableLighting: true,
};

// contains all SAS uris for materials that will be created for the selected babylon file
let _SAS_URIS: { [key: string]: string } = {};

/**
 * Saves all desired material SAS uris temporarily, so that they can be consumed in `getMaterial` calls.
 * This is typically done before loading a new babylon file.
 */
export async function saveMaterialSasUris(
  companyId: string,
  bundleId: string,
  bundleVersion: string,
  materialPaths: AssetShortPath[]
): Promise<void> {
  _SAS_URIS = await fetchSasUrisOfMaterials(companyId, bundleId, bundleVersion, materialPaths);
}

/**
 * Returns material data in JSON format from the material asset that belongs to the input id.
 * Returns undefined if desired material asset couldn't be found.
 *
 * This is automatically called by our 3d viewer whenever a materials gets loaded.
 */
export async function getMaterial(materialId: string): Promise<MaterialObject | undefined> {
  const { assets, companyData } = store.getState();

  // find material asset in currently selected bundle version
  const materialAsset = Object.values(assets.assets).find(asset => {
    const selectedPath = { companyId: companyData.selection.companyId, ...assets.selection };
    return (
      isAssetOfSameBundleVersion(selectedPath, asset.path) &&
      isMaterialAsset(asset) &&
      asset.path.assetId === materialId
    );
  });
  if (!materialAsset) {
    console.warn(`Material asset for "${materialId}" could not be found, a fallback material is used instead!`);
    // return data from missing material fallback, but use the original ids, as the viewer wouldn't be able to assign
    // the return value to the requested material otherwise
    return { ..._MISSING_MATERIAL, name: materialId, id: materialId };
  }

  const { companyId, bundleId, bundleVersion, folderId, assetId } = materialAsset.path;
  const dottedPath = createSourceStringFromFolderAndAssetId(folderId, assetId);
  const sasUri = _SAS_URIS[dottedPath];

  let matData: MaterialObject;
  const sasUriExpired = sasUri && isSasUriExpired(sasUri);
  // load the material from the previously stored SAS uri
  // fall back to "full" material fetch call if the SAS uri is not available or has timed out
  if (sasUri && !sasUriExpired) {
    const res = await HttpService.httpClient.get<MaterialObject>(sasUri);
    matData = res.data;
  } else {
    if (!sasUri) {
      console.warn(`SAS uri for "${materialId}" could not be found, re-fetching SAS uris is required!`);
    }

    matData = await fetchAssetMaterialContent(companyId, bundleId, bundleVersion, folderId, assetId);
  }

  // sync "id" and "name" with the asset name itself to avoid https://combeenation.youtrack.cloud/issue/CB-8897
  // the issue in the ticket is caused by the hive name conversion of the material asset
  // it could also happen that the user overwrites name and id in the expert editor, so it makes sense to handle it
  // here already
  // NOTE: server is doing the same thing when prepacking the assets for the usage in the configurator
  matData.name = assetId;
  matData.id = assetId;

  const assetLinksOfAsset = getAssetLinksOfAsset(materialAsset, assets.assetLinks);

  // texture data has to be loaded in an extra step, as only the links to the dedicated image assets are stored
  injectLinkedTextures(matData, assetLinksOfAsset);

  return matData;
}

/**
 * Add links to assigned texture assets in the material
 */
export function injectLinkedTextures(materialData: MaterialObject, linkedTextureAssets: AssetLink[]): void {
  const {
    assets: { assets },
  } = store.getState();

  linkedTextureAssets.forEach(textureLink => {
    const linkedTextureAsset = Object.values(assets).find(
      asset => asset.path.assetId === textureLink.target.replace(TEXTURES_3D_FOLDER_NAME + '.', '')
    );

    if (!linkedTextureAsset) {
      return;
    }

    const linkedTextureType = textureLink.texture!;

    // link texture image asset by overwriting name of texture
    // this also creates a new texture if not already available
    materialData[linkedTextureType] = {
      ...materialData[linkedTextureType],
      name: (linkedTextureAsset as ImageAsset).url,
    };

    // remove url and base64 string, so that they won't overrule the texture asset link
    // this code approach is taken from the server implementation! (see https://combeenation.youtrack.cloud/issue/CB-7780)
    delete materialData[linkedTextureType].url;
    delete materialData[linkedTextureType].base64String;
  });
}
