import { Collapse } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ArrowLeftIcon, FilterIcon } from 'assets/icons';
import { CfgnInsightsFiltersCollapsible } from 'components/cfgrs/cfgn-insights/cfgn-insights-filters-collapsible';
import { CfgnInsishgtsVersionSelectorBtn } from 'components/cfgrs/cfgn-insights/cfgn-insights-version-selector-btn';
import { CfgnInsightsViewSettingsBtn } from 'components/cfgrs/cfgn-insights/cfgn-insights-view-settings-btn';
import { CfgnsTable } from 'components/cfgrs/cfgn-insights/cfgns-table';
import { CfgrDeletedInfoWrapper } from 'components/cfgrs/cfgr-deleted-info-wrapper';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { Select } from 'controls/select/select';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { DASHBOARD_BASE_PATH } from 'services/routes/cfgrs-routes.service';
import {
  LOAD_MORE_PAGE_SIZES,
  loadMoreCfgrCfgnInsights,
  resetCfgnInsights,
  selectCfgrCfgnInsights,
  selectHasMoreData,
  setPageSize,
} from 'slices/cfgn-insisghts/cfgn-insights.slice';

export const CfgnInsights: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const hasMoreData = useAppSelector(selectHasMoreData);
  const {
    viewSettings: { pageSize },
  } = useAppSelector(state => state.cfgnInsights);
  const loadedInsightsCnt = useAppSelector(selectCfgrCfgnInsights).length;
  const matchingCfgnsCnt = useAppSelector(state => state.cfgnInsights.totalCount);

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [filtersCollapsed, setFiltersCollapsed] = useState(true);

  useEffect(
    function resetDataOnUnmount() {
      return () => {
        dispatch(resetCfgnInsights());
      };
    },
    [dispatch]
  );

  async function loadMoreInsights(): Promise<void> {
    setIsLoadingMore(true);
    await dispatch(loadMoreCfgrCfgnInsights({ pageSize }));
    setIsLoadingMore(false);
  }

  return (
    <CfgrDeletedInfoWrapper>
      <Outlet />
      <div data-cmptype="CfgnInsights" className="flex flex-col gap-5">
        <LinkButton href={DASHBOARD_BASE_PATH} variant="TextInline" text={t('back to dashboard')} Svg={ArrowLeftIcon} />

        <div className="flex">
          <div className="flex grow flex-col gap-2">
            <div className="text-heading-m">{t('Configuration insights')}</div>
            <div className="text-m-regular text-neutral-70">
              {t('View and filter all configurations created with your configurator.')}
            </div>
          </div>

          <div className="self-end">
            {/*
            TODO Now ORT: What do you think of the dropdown arrow here?
                          Without it I personally wouldn't have expected a dropdown/version selector behind this button.
                          What to do when there are no stages? Disable, hide or something else?
            */}
            <CfgnInsishgtsVersionSelectorBtn />
          </div>
        </div>

        <CbnCard>
          <div className="gap-5 border-b border-neutral-40">
            <div className="flex items-center gap-5 px-6 py-5">
              <div className="grow">
                <div className="text-m-medium">{t('Configurations')}</div>
                <div className="text-m-regular text-neutral-70">
                  {t('Browse and filter collected configuration data')}
                </div>
              </div>

              <Button
                text="Filter"
                Svg={FilterIcon}
                variant="Outlined"
                onClick={(): void => setFiltersCollapsed(x => !x)}
              />
            </div>

            <Collapse in={!filtersCollapsed}>
              <div className="px-6 pb-2.5 pt-0">
                <CfgnInsightsFiltersCollapsible />
              </div>
            </Collapse>
          </div>

          <div>
            <div className="flex items-center px-5 pb-5 pt-4">
              <div className="grow text-neutral-90">
                <span className="font-bold">{t(`${matchingCfgnsCnt} configurations`)}</span>
                {t(' match your filter setting')}
              </div>

              <CfgnInsightsViewSettingsBtn />
            </div>

            <CfgnsTable />

            <div className="flex items-center gap-4 p-5">
              <Button
                text={t('Load more')}
                variant="Outlined"
                disabled={!hasMoreData}
                title={!hasMoreData ? t('All available data already loaded') : ''}
                isLoading={isLoadingMore}
                onClick={loadMoreInsights}
              />

              <span className="grow text-neutral-90">
                {t(`Showing `)}
                <span className="font-bold">{t(`${loadedInsightsCnt} of ${matchingCfgnsCnt}`)}</span>
                {t(` configurations.`)}
              </span>

              <span className="text-neutral-90">{t('Rows per load')}</span>
              <span className="w-[76px]">
                <Select
                  value={pageSize}
                  onChange={(x): void => {
                    dispatch(setPageSize({ pageSize: x as number }));
                  }}
                  options={LOAD_MORE_PAGE_SIZES.map(value => ({ value, text: value }))}
                />
              </span>
            </div>
          </div>
        </CbnCard>
      </div>
    </CfgrDeletedInfoWrapper>
  );
};
