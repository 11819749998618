import { CompanyStates } from 'generated/company-states';
import { UserRoles } from 'generated/user-role';
import { UserStates } from 'generated/user-states';
import { unixTimestampToDate } from 'helper/date-and-time/date-and-time.helper';
import { HttpStatusCode } from 'helper/http/http-status.helper';
import { CustomFieldValues } from 'services/http/endpoints/companies.endpoints';
import { ActionResult, endpoints, httpClient, isGenericActionResult } from 'services/http/http.service';

// There are other known error responses as well, but we're only actively dealing with this one.
// The others will end in a generic "Ooops error".
export const inviteErrorResponses = ['InvalidMailAddress'] as const;
export type InviteErrorResponse = (typeof inviteErrorResponses)[number];

export const INVITE_ERROR_RESPONSE_MSGS: Record<InviteErrorResponse, string> = {
  InvalidMailAddress:
    'Please check the format of the email address you entered and ensure it includes both a username and a domain (e.g., username@example.com).\n\nMake sure there are no spaces or special characters, and try again.',
};

export const getInviteResponses = ['EntityNotFound'];
export type GetInviteResponse = (typeof getInviteResponses)[number];

const acceptInvitationResponses = [
  'Success',
  'TokenNoLongerValid',
  'WrongRecipient',
  'TokenNotFound',
  'TokenRevoked',
  'TokenConsumed',
  'UnknownResponse',
] as const;
export type AcceptInvitationResponse = (typeof acceptInvitationResponses)[number];

type CompanyAddressDto = {
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  phone?: string;
  email?: string;
};

type CompanyBillingInformationDto = {
  vatId?: string;
  invoiceAddress?: CompanyAddressDto;
  subscriptionDisplayName: string;
  monthlySubscriptionFee: number;
  /**
   * Specifies the numeric day of the month on which the invoice was issued. Accepted values range from 1 (representing
   * the first day of the month) to 28 (representing the last valid day for all months).
   *
   * (Copied from swagger)
   */
  invoiceDay: number;
};

type CompanyDetailsDto = {
  name: string;
  displayName: string;
  defaultCulture: string;
  /** Date in seconds (!) since 1970 */
  created: number;
  billingInformation: CompanyBillingInformationDto;
  postalAddress?: CompanyAddressDto;
  userRoles: { [userId: string]: UserRoles };
  state: CompanyStates;
  features: {
    workflows: boolean;
    adFree: boolean;
    legacyStorage: boolean;
    disableCustomCode: boolean;
    arBranding: boolean;
    maxDomainsPerCfgr?: number;
    /** Actually not optional but chances are that this will change in the future */
    maxCfgrStages?: number;
    maxUserCustomFields?: number;
    maxCfgnInsightsValues?: number;
  };
};

type UpdateCompanyDetailsAddressDto = {
  street: string;
  postalCode: string;
  city: string;
  country: string;
  phone: string;
  email: string;
};

export type UpdateCompanyDetailsDto = {
  name: string;
  displayName: string;
  vatId: string;
  defaultCulture: string;
  postalAddress: UpdateCompanyDetailsAddressDto;
  invoiceAddress: UpdateCompanyDetailsAddressDto;
};

export type CompanyBillingInformation = CompanyBillingInformationDto;

/**
 * Property `created` from `CompanyDetailsDto` is converted from seconds to milliseconds in `CompanyDetails` for easier
 * usage in JS.
 */
export type CompanyDetails = CompanyDetailsDto;
export type CompanyFeatures = CompanyDetails['features'];

type UserDto = {
  id: string;
  email: string;
  firstname: string | null;
  lastname: string | null;
  state: UserStates;
};

type CompanyUserDto = {
  role: UserRoles;
  user: UserDto;
  fields?: CustomFieldValues;
};

export type CompanyUser = UserDto & { role: UserRoles; fields: CustomFieldValues };

type CreateInviteDto = {
  inviteLink: string;
  inviteToken: string;
  userAddedWithoutInvite: true;
};

type CreateInviteResponse = CreateInviteDto;

type UserInviteDto = {
  role: UserRoles;
  inviteRecipient?: string;
  createdBy?: string;
  createdAt: number;
  validTill: number;
  revokedBy?: string;
  revokedAt?: number;
  usageCount: number;
  maxUsageCount: number;
  inviteToken: string;
  inviteLink: string;
};

type GetAccountInviteDto = {
  role: UserRoles;
  validTill: number;
  revokedAt: number;
  consumed: boolean;
  companyDisplayName: string;
  inviteRecipient: string;
};

export type GetAccountInviteData = Omit<GetAccountInviteDto, 'validTill' | 'revokedAt'> & {
  validTill: Date;
  revokedAt?: Date;
};

type UserInvitesDto = {
  [inviteToken: string]: UserInviteDto;
};

export type UserInvite = Omit<UserInviteDto, 'createdAt' | 'validTill' | 'revokedAt'> & {
  createdAt: Date;
  validTill: Date;
  revokedAt?: Date;
};

export type UserInvites = UserInvite[];

type CompanyApiTokenDto = {
  tokenValue: string;
  tokenId: string;
  displayName: string;
  createdBy: string;
  createdAt: number;
};

export type CompanyApiToken = CompanyApiTokenDto;

export async function getCompanyDetails(companyId: string): Promise<CompanyDetails> {
  const { url, params } = endpoints.companiesGetDetails(companyId);
  const res = await httpClient.get<CompanyDetailsDto>(url, { params });
  const details = {
    ...res.data,
    // Convert seconds to milliseconds for easier usage in JS
    created: res.data.created * 1000,
  };
  return details;
}

export async function postUpdateCompanyDetails(settings: UpdateCompanyDetailsDto): Promise<void> {
  const { url, params } = endpoints.companiesUpdateDetails(settings);
  await httpClient.post(url, params);
}

/**
 * @returns `true`: direct add (no invite)\
 *  `false`: invite\
 *  `InviteResponse`: Some error
 */
export async function postCompaniesInvite(
  companyId: string,
  userRole: UserRoles,
  email?: string
): Promise<CreateInviteResponse | ActionResult<InviteErrorResponse>> {
  const { url, params } = endpoints.companiesCreateInvite(companyId, userRole, email);
  const res = await httpClient.post<CreateInviteDto>(url, params, {
    validateStatus: () => true,
  });

  if (res.status !== HttpStatusCode.Ok_200 && !isGenericActionResult(res.data, inviteErrorResponses)) {
    throw new Error('Unexpected response status');
  }

  return res.data;
}

export async function getCompaniesGetInvites(companyId: string): Promise<UserInvites> {
  const { url, params } = endpoints.companiesGetInvites(companyId);
  const res = await httpClient.get<UserInvitesDto>(url, { params });
  const invites = Object.entries(res.data).map(([inviteToken, invite]) => ({
    ...invite,
    inviteToken,
    createdAt: unixTimestampToDate(invite.createdAt),
    validTill: unixTimestampToDate(invite.validTill),
    revokedAt: invite.revokedAt ? unixTimestampToDate(invite.revokedAt) : undefined,
  }));
  return invites;
}

export async function getCompaniesGetUsers(companyId: string): Promise<CompanyUser[]> {
  const { url, params } = endpoints.companiesGetUsers(companyId);
  const resUsers = await httpClient.get<CompanyUserDto[]>(url, { params });
  return resUsers.data.map(user => ({
    ...user.user,
    role: user.role,
    fields: user.fields ?? {},
  }));
}

export async function getCompaniesGetUser(companyId: string, userId: string): Promise<CompanyUser> {
  const { url, params } = endpoints.companiesGetUser(companyId, userId);
  const resUser = await httpClient.get<CompanyUserDto>(url, { params });
  return {
    ...resUser.data.user,
    role: resUser.data.role,
    fields: resUser.data.fields ?? {},
  };
}

export async function postCompaniesRevokeInvite(token: string, companyId: string): Promise<void> {
  const { url, params } = endpoints.companiesRevokeInvite(token, companyId);
  await httpClient.post(url, params);
}

export async function postCompaniesSetRole(companyId: string, userId: string, userRole: UserRoles): Promise<void> {
  const { url, params } = endpoints.companiesSetRole(companyId, userId, userRole);
  await httpClient.post(url, params);
}

export async function postCompaniesUpdateAdminDetails(
  companyId: string,
  features: CompanyFeatures,
  state: CompanyStates,
  subscriptionDisplayName: string,
  monthlySubscriptionFee: number,
  invoiceDay: number
): Promise<void> {
  const { url, params } = endpoints.companiesUpdateAdminDetails(
    companyId,
    features,
    state,
    subscriptionDisplayName,
    monthlySubscriptionFee,
    invoiceDay
  );
  await httpClient.post(url, params);
}

export async function getCompanyApiTokens(companyId: string): Promise<CompanyApiToken[]> {
  const { url, params } = endpoints.companiesGetApiTokens(companyId);
  const res = await httpClient.get<CompanyApiTokenDto[]>(url, { params });
  return res.data;
}

/**
 * @param tokenIdToReplace If not given, a completely new one will be created
 */
export async function createCompanyApiTokens(
  companyId: string,
  tokenDisplayName: string,
  tokenIdToReplace?: string
): Promise<CompanyApiToken> {
  const { url, params } = endpoints.companiesCreateApiToken(companyId, tokenDisplayName, tokenIdToReplace);
  const res = await httpClient.post<CompanyApiTokenDto>(url, params);
  return res.data;
}

export async function deleteCompanyApiToken(companyId: string, tokenId: string): Promise<void> {
  const { url, params } = endpoints.companiesDeleteApiToken(companyId, tokenId);
  await httpClient.delete(url, { data: params });
}

export async function getAccountInvite(
  inviteToken: string,
  companyName: string
): Promise<GetAccountInviteData | ActionResult<GetInviteResponse>> {
  const { url, params } = endpoints.accountGetInvite(inviteToken, companyName);
  const res = await httpClient.get<GetAccountInviteDto>(url, { params, validateStatus: () => true });

  if (isGenericActionResult(res.data, getInviteResponses)) {
    return res.data;
  } else if (res.status !== HttpStatusCode.Ok_200) {
    throw new Error('Unexpected response status');
  } else {
    const data = {
      ...res.data,
      validTill: unixTimestampToDate(res.data.validTill),
      revokedAt: res.data.revokedAt ? unixTimestampToDate(res.data.revokedAt) : undefined,
    };
    return data;
  }
}

export async function postAccountAcceptInvite(companyName: string, inviteToken: string): Promise<void> {
  const { url, params } = endpoints.accountPostAcceptInvite(companyName, inviteToken);
  await httpClient.post(url, params);
}
