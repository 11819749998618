import React, { useMemo, useState } from 'react';
import { AssetDetailModalsErrorInfo } from 'components/asset-editor/details/modals/asset-detail-modals-error-info';
import { AssetFolderSelection } from 'components/asset-editor/folders/asset-folder-selection';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { FormItem } from 'controls/form-item/form-item';
import { TextInput } from 'controls/input/text-input';
import { isUnusedAssetKey } from 'helper/assets/assets.helper';
import { isValidHiveIdentifier } from 'helper/hive/hive.helper';
import { NEW_FOLDER_KEY, useNavigateToAssetEditor } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { FailedAssetsInfo, RelocateAssetRequestItems } from 'services/assets/assets.service';
import { copyAsset, isFolderAsset, isMaterialAsset, moveAsset, selectSelectedAsset } from 'slices/assets/assets.slice';

export type AssetRelocateActions = 'Rename' | 'Copy' | 'Move';

type AssetRelocateModalProps = {
  action: AssetRelocateActions;
  onClose: () => void;
};

export const AssetRelocateModal: React.FC<AssetRelocateModalProps> = ({ action, onClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();
  const [errorInfo, setErrorInfo] = useState<FailedAssetsInfo>();

  const navigateToAssetEditor = useNavigateToAssetEditor();

  const assets = useAppSelector(state => state.assets.assets);

  // NOTE: selected asset may shortly be "undefined", as the currently selected asset will be deleted
  const selectedAsset = useAppSelector(selectSelectedAsset);
  const [inputName, setInputName] = useState(selectedAsset?.path.assetId ?? '');
  const [selectedFolderOfDD, setSelectedFolderOfDD] = useState(selectedAsset?.path.folderId ?? '');
  const [newFolderName, setNewFolderName] = useState('');
  const selectedFolder = selectedFolderOfDD === NEW_FOLDER_KEY ? newFolderName : selectedFolderOfDD;
  const [isProcessingAction, setIsProcessingAction] = useState(false);

  const textMapping: Record<AssetRelocateActions, { header: string; confirmBtn: string }> = {
    Rename: { header: t('Rename asset'), confirmBtn: t('Rename asset') },
    Copy: { header: t('Copy asset'), confirmBtn: t('Copy asset') },
    Move: { header: t('Move asset'), confirmBtn: t('Move asset') },
  };

  const onActionConfirmed = async (): Promise<void> => {
    const { companyId, bundleId, bundleVersion, folderId: curFolderId, assetId: curAssetId } = selectedAsset!.path;

    setIsProcessingAction(true);
    if (errorInfo !== undefined) {
      // reset errors if still pending from previous confirm clicks
      setErrorInfo(undefined);
    }

    const relocateItems: RelocateAssetRequestItems[] = [
      {
        sourceFolder: curFolderId,
        sourceName: curAssetId,
        targetFolder: selectedFolder,
        targetName: inputName,
        overwrite: false,
      },
    ];

    const baseParams = { companyId, bundleId, bundleVersion };
    let result;
    if (action === 'Copy') {
      const copyParams = { ...baseParams, copyItems: relocateItems };
      result = await dispatch(copyAsset(copyParams)).unwrap();
    } else {
      const moveParams = { ...baseParams, moveItems: relocateItems };
      result = await dispatch(moveAsset(moveParams)).unwrap();
    }

    if (result.success) {
      // Remove the current route from the history if the current asset doesn't exist anymore after move or rename...
      const removeCurrentRouteFromHistory = action !== 'Copy';
      navigateToAssetEditor(
        {
          companyId,
          bundleId,
          bundleVersion,
          folderId: selectedFolder,
          assetId: inputName,
        },
        removeCurrentRouteFromHistory
      );
      onClose();
    } else {
      setErrorInfo(result.failedAssets);
    }

    setIsProcessingAction(false);
  };

  // TODO: Proper "invalid state handling".
  //       I.e. don't just disable the submit button but tell the user why he can't submit...
  const inputIsValid = useMemo(() => {
    if (!selectedAsset?.path) {
      return false;
    }

    const assetPath = { ...selectedAsset.path, folderId: selectedFolder, assetId: inputName };
    const assetKeys = Object.keys(assets);
    const isUnused = isUnusedAssetKey(assetPath, assetKeys);
    // rename is still allowed if the same name with different casing is chosen
    // eg: asset1 => aSsEt1
    const casingChanged =
      selectedAsset.path.assetId.toLowerCase() === inputName.toLowerCase() && selectedAsset.path.assetId !== inputName;
    const isRename = action === 'Rename';

    const newAssetNameIsValid = isValidHiveIdentifier(inputName);
    const folderNameIsValid = !selectedFolder || isValidHiveIdentifier(selectedFolder);
    return (isUnused || (isRename && casingChanged)) && newAssetNameIsValid && folderNameIsValid;
  }, [selectedAsset?.path, selectedFolder, inputName, assets, action]);

  const inputNameChanged = (value: string): void => {
    setInputName(value);
    if (errorInfo !== undefined) {
      setErrorInfo(undefined);
    }
  };

  const onAssetFolderSelectionChange = (selectValue: string, newFolderInputValue: string): void => {
    setSelectedFolderOfDD(selectValue);
    setNewFolderName(newFolderInputValue);
  };

  const showRenameField = action === 'Copy' || action === 'Rename';
  // folders can only be re-located in root folder, therefore no folder field is required
  const showFolderField =
    selectedAsset &&
    !isMaterialAsset(selectedAsset) &&
    !isFolderAsset(selectedAsset) &&
    (action === 'Copy' || action === 'Move');

  return (
    <ModalDialog
      data-cmptype="RelocateAsset"
      variant="NoIcon"
      header={textMapping[action].header}
      confirmText={textMapping[action].confirmBtn}
      actions={{ onConfirm: () => onActionConfirmed(), onCancel: () => onClose() }}
      confirmDisabled={!inputIsValid}
      isProcessingConfirm={isProcessingAction}
    >
      <div className="flex grow flex-col gap-4">
        {showRenameField && (
          <FormItem labelContent={t('New name')}>
            <TextInput value={inputName} onValueChange={inputNameChanged} autoFocus />
          </FormItem>
        )}
        {showFolderField && (
          <FormItem labelContent={t('Folder')}>
            <AssetFolderSelection
              preSelectedFolderId={selectedAsset.path.folderId}
              showNewFolderOption
              onChange={onAssetFolderSelectionChange}
            />
          </FormItem>
        )}
        {errorInfo && <AssetDetailModalsErrorInfo errorInfo={errorInfo} />}
      </div>
    </ModalDialog>
  );
};
