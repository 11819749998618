import classNames from 'classnames';
import { Link, useMatch } from 'react-router-dom';
import { Icon, SvgComponent } from 'controls/icon/icon';
import { Tooltip } from 'controls/tooltip/tooltip';
import { tw } from 'helper/css.helper';

type AppSidebarLinkProps = {
  icon: SvgComponent;
  collapsed?: boolean;
  text: string;
  to?: string;
  onClick?: () => void;
  disabled?: boolean;
  disabledTooltip?: React.ReactNode;
};

type AppSidebarExclusiveProps = (AppSidebarLinkProps & { onClick?: never }) | (AppSidebarLinkProps & { to?: never });

const ItemContent: React.FC<AppSidebarLinkProps> = ({ icon, text, to, disabled, collapsed }) => {
  const routeMatch = useMatch({ path: to + '/*' || '' });
  const selected = to ? routeMatch : false;

  return (
    <div
      className={classNames('my-4 flex flex-grow items-center gap-5 px-6 ', 'transition duration-300 ease-in-out', {
        'text-primary-main shadow-[inset_4px_0_0] shadow-primary-main': selected,
        'group-hover:text-primary-hover group-hover:shadow-[inset_4px_0_0] group-hover:shadow-primary-main': !disabled,
        'text-neutral-60': disabled,
      })}
    >
      {icon && <Icon Svg={icon} className={classNames({ 'w-6': collapsed, 'w-5': !collapsed })} />}
      {!collapsed && (
        <span aria-disabled={disabled ? 'true' : 'false'} className="text-m-medium font-semibold">
          {text}
        </span>
      )}
    </div>
  );
};

export const AppSidebarLink: React.FC<AppSidebarExclusiveProps> = props => {
  const { to, onClick, disabled, disabledTooltip, text, collapsed } = props;
  const defaultClasses = tw`group flex select-none focus-visible:outline-primary-focus`;

  return to ? (
    <Tooltip
      title={disabled ? disabledTooltip : collapsed ? text : undefined}
      arrow
      placement="right"
      interactive={!!disabledTooltip}
    >
      <div data-cmptype="AppSidebarLink" className={defaultClasses}>
        <Link
          to={to}
          className={classNames('mr-1 flex-grow focus-visible:outline-primary-focus', {
            'pointer-events-none': !!disabled,
          })}
        >
          <ItemContent {...props}></ItemContent>
        </Link>
      </div>
    </Tooltip>
  ) : (
    <Tooltip
      title={disabled ? disabledTooltip : collapsed ? text : undefined}
      arrow
      placement="right"
      interactive={!!disabledTooltip}
    >
      <button
        data-cmptype="AppSidebarLink"
        type="button"
        onClick={onClick}
        className={defaultClasses}
        disabled={!!disabled}
      >
        <ItemContent {...props}></ItemContent>
      </button>
    </Tooltip>
  );
};
