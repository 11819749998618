import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { CrossIcon } from 'assets/icons';
import { Button, ButtonColors } from 'controls/button/button';
import { Icon, SvgComponent } from 'controls/icon/icon';
import { StateIcon, StateIconVariants } from 'controls/icon/state-icon';
import { tw } from 'helper/css.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

export type DialogSizes = 'Small' | 'Medium' | 'Large';
export type DialogVariants = 'NoIcon' | 'Success' | 'Info' | 'Warning' | 'Error' | 'Question' | 'Danger';
type DialogActionTypes = 'ConfirmCancel' | 'ConfirmOnly';

export type DialogActions = {
  onConfirm?: () => void;
  onCancel?: () => void;
};

export type ModalDialogProps = {
  'variant'?: DialogVariants;
  'actionTypes'?: DialogActionTypes;
  'header'?: string;
  'children': React.ReactNode;
  'actions'?: DialogActions;
  'confirmText'?: string;
  'confirmIcon'?: SvgComponent;
  'suppressConfirmOnEnter'?: boolean;
  'suppressCancelOnEsc'?: boolean;
  'confirmDisabled'?: boolean;
  'confirmDisabledInfo'?: React.ReactNode;
  'isProcessingConfirm'?: boolean;
  'data-cmptype'?: string;
  'size'?: DialogSizes;
  'childrenScrollable'?: boolean;
};

type ButtonAvailability = {
  confirm: boolean;
  cancel: boolean;
};

const _iconVariantMap: Record<DialogVariants, StateIconVariants | null> = {
  NoIcon: null,
  Success: 'Success',
  Info: 'Info',
  Warning: 'Warning',
  Error: 'Error',
  Question: 'Question',
  Danger: 'Danger',
};

const _buttonAvailabilityMap: Record<DialogActionTypes, ButtonAvailability> = {
  ConfirmCancel: { confirm: true, cancel: true },
  ConfirmOnly: { confirm: true, cancel: false },
};

export const ModalDialog: React.FC<ModalDialogProps> = ({
  variant = 'Info',
  actionTypes = 'ConfirmCancel',
  header,
  children,
  actions,
  confirmText,
  confirmIcon,
  suppressConfirmOnEnter,
  suppressCancelOnEsc,
  confirmDisabled,
  confirmDisabledInfo,
  isProcessingConfirm,
  'data-cmptype': dataCmpType,
  size = 'Small',
  childrenScrollable,
}) => {
  const { t } = useTypedTranslation();
  const [hadGlobalError, setHadGlobalError] = useState(false);

  const onModalConfirm = useCallback(() => {
    actions?.onConfirm?.();
  }, [actions]);

  useEffect(
    function globalKeyboardListener() {
      const keyDownListener = (event: KeyboardEvent): void => {
        if (!confirmDisabled && !suppressConfirmOnEnter && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
          event.preventDefault();
          onModalConfirm();
        } else if (!suppressCancelOnEsc && event.code === 'Escape') {
          event.preventDefault();
          actions?.onCancel?.();
        }
      };

      if (!isProcessingConfirm) {
        document.addEventListener('keyup', keyDownListener);
        return () => {
          document.removeEventListener('keyup', keyDownListener);
        };
      }
    },
    [actions, confirmDisabled, isProcessingConfirm, onModalConfirm, suppressConfirmOnEnter, suppressCancelOnEsc]
  );

  /**
   * Always give the user an option to close the dialog:
   * If a global error happens during the "isProcessingConfirm" it might never re-enable the buttons
   * In that case an exit-button will be shown in the top-right corner
   */
  useEffect(function globalErrorDialogListener() {
    const listener = (): void => {
      setHadGlobalError(true);
    };
    window.addEventListener('globalerrordialog', listener);
    return () => window.removeEventListener('globalerrordialog', listener);
  }, []);

  const iconVariant = _iconVariantMap[variant];
  const buttonConfig = _buttonAvailabilityMap[actionTypes];

  const cmpName = 'ModalDialog' + (dataCmpType ? ` ${dataCmpType}` : '');

  const sizeClass = size === 'Large' ? tw`w-[80%] max-w-[1600px]` : size === 'Medium' ? tw`w-[800px]` : tw`w-[400px]`;

  // `ConfirmOnly` dialog is expected to be only "informational" so it uses the default button.
  // The button should only be a warning/danger-color when there's also a cancel-button available.
  const confirmButtonColor: ButtonColors | undefined =
    actionTypes === 'ConfirmOnly'
      ? undefined
      : variant === 'Danger'
        ? 'Danger'
        : variant === 'Warning'
          ? 'Warning'
          : undefined;

  // Changes should be 'kept in sync' with the global-error-dialog as it has very similar markup
  return (
    <article
      className={`fixed left-0 top-0 z-modal flex h-full w-full items-center justify-center bg-modal`}
      data-cmptype={cmpName}
    >
      <div className={`flex max-h-[95%] ${sizeClass} relative flex-col rounded-lg bg-neutral-10 p-6`}>
        {hadGlobalError && (
          <div className="absolute right-2 top-2">
            <button type="button" onClick={actions?.onCancel}>
              <Icon Svg={CrossIcon} className="w-7" />
            </button>
          </div>
        )}
        {iconVariant && (
          <div className="mb-5">
            <StateIcon variant={iconVariant} />
          </div>
        )}
        {header && (
          <h3 data-cmptype={`${cmpName}-header`} className="mb-2">
            {header}
          </h3>
        )}
        <div
          data-cmptype={`${cmpName}-content`}
          className={classNames('mb-8 flex flex-grow', { 'overflow-y-auto': childrenScrollable })}
        >
          {children}
        </div>
        <div data-cmptype={`${cmpName}-actions`} className="flex flex-row justify-between gap-3">
          {buttonConfig.cancel && (
            <div className={`flex ${size === 'Small' ? 'grow' : 'min-w-[200px]'}`}>
              <Button
                variant="Outlined"
                text={t('Cancel')}
                disabled={isProcessingConfirm}
                onClick={actions?.onCancel}
                grow
              />
            </div>
          )}
          {buttonConfig.confirm && (
            <div
              className={classNames('flex items-center gap-4', {
                grow: size === 'Small',
              })}
            >
              {confirmDisabledInfo}
              <div className={`flex ${size === 'Small' ? 'grow' : 'min-w-[200px]'}`}>
                <Button
                  type="submit"
                  color={confirmButtonColor}
                  text={confirmText ?? t('Confirm')}
                  Svg={confirmIcon}
                  disabled={confirmDisabled}
                  onClick={(): void => onModalConfirm()}
                  grow
                  isLoading={isProcessingConfirm}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </article>
  );
};
