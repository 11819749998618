import {
  GridRowEditStopParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { GridRowModesModelProps } from '@mui/x-data-grid/models/api/gridEditingApi';
import { useEffect, useRef, useState } from 'react';
import { AssetDatasourceEditGridFooter } from 'components/asset-editor/details/modals/datasource-edit/edit-grid/asset-datasource-edit-grid-footer';
import {
  useDataGridTabNavigationWorkaround,
  useEditGridColumns,
} from 'components/asset-editor/details/modals/datasource-edit/edit-grid/asset-datasource-edit-grid.hooks';
import { DataGrid } from 'controls/datagrid/data-grid';
import { DataSourceColumnTypes } from 'generated/datasource-column-types';
import { DataSourceColumnTypesExtended, LinkableAssetTypes } from 'helper/assets/assets-datasource.helper';
import { mapObject } from 'helper/object/object.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { DataSourceColumnConfigDto, DataSourceColumnDefaultValue } from 'services/assets/assets.service';

export const EDIT_GRID_FIELD_KEYS = {
  Index: 'index',
  Name: 'name',
  TypeDef: 'type',
  LinkedAssetFolder: 'linkedAssetFolder',
  DefaultValue: 'defaultValue',
} as const;

export type ConfigRow = {
  id: number;
  $isNew?: boolean;
  [EDIT_GRID_FIELD_KEYS.Index]: number | undefined;
  [EDIT_GRID_FIELD_KEYS.Name]: string;
  [EDIT_GRID_FIELD_KEYS.TypeDef]: DataSourceColumnTypesExtended;
  [EDIT_GRID_FIELD_KEYS.LinkedAssetFolder]: string | undefined;
  [EDIT_GRID_FIELD_KEYS.DefaultValue]: DataSourceColumnDefaultValue;
};

type AssetDatasourceEditGridProps = {
  configData: DataSourceColumnConfigDto[];
  onChange: (data: DataSourceColumnConfigDto[]) => void;
  onRowEditModeChange: (uncomittedRowsCount: number) => void;
};
export const AssetDatasourceEditGrid: React.FC<AssetDatasourceEditGridProps> = ({
  configData,
  onChange,
  onRowEditModeChange,
}) => {
  const { t } = useTypedTranslation();
  const apiRef = useGridApiRef();

  const pendingStartRowEdit = useRef<GridRowId>();
  useDataGridTabNavigationWorkaround(apiRef);

  const [rows, setRows] = useState(_getRowsFromConfig(configData));
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const editModeCount = Object.values(rowModesModel).filter(m => m.mode === GridRowModes.Edit).length;

  const onEditClick = (id: GridRowId): void => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const onApplyClick = (id: GridRowId): void => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const onCancelClick = (id: GridRowId): void => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });

    const editedRow = rows.find(row => row.id === id);
    if (editedRow?.$isNew) {
      setRows(rows.filter(row => row.id !== id));
    }
  };
  const onDeleteClick = (id: GridRowId): void => {
    setRows(rows.filter(row => row.id !== id));
    postRowChanges({ removedId: id });
  };

  const columns = useEditGridColumns(
    rows,
    rowModesModel,
    apiRef,
    onEditClick,
    onApplyClick,
    onCancelClick,
    onDeleteClick
  );

  const processRowUpdate = (newRow: GridRowModel): GridRowModel => {
    (newRow as ConfigRow).$isNew = false;
    setRows(rows.map(row => (row.id === newRow.id ? (newRow as ConfigRow) : row)));
    return newRow;
  };

  const onBulkEditClicked = (): void => {
    setRowModesModel(_createRowModesModel(rows, { mode: GridRowModes.Edit }));
  };

  const onEndBulkEditClicked = (): void => {
    setRowModesModel(oldModel =>
      mapObject(oldModel, () => ({
        mode: GridRowModes.View,
      }))
    );
  };

  const onAddColumnClicked = (): void => {
    const allIds = rows.map(x => x.id ?? 0);
    const allIndices = rows.map(x => x[EDIT_GRID_FIELD_KEYS.Index] ?? 0);
    const newId = Math.max(...allIds) + 1;
    const nextIndex = Math.max(...allIndices) + 1;

    setRows(oldRows => [
      ...oldRows,
      {
        id: newId,
        index: nextIndex,
        name: 'Col_' + nextIndex,
        type: { type: DataSourceColumnTypes.String },
        defaultValue: '',
        linkedAssetFolder: '',
        $isNew: true,
      },
    ]);

    // Starting the row edit mode here doesn't work because the `setRows` isn't updated yet
    // Would work with `setRowModesModel()` but it doesn't focus the first input (MUI bug?)
    pendingStartRowEdit.current = newId;
  };

  useEffect(
    function startPendingRowEdits() {
      const id = pendingStartRowEdit.current;
      if (!id || !rows.find(r => r.id === id)) {
        return;
      }

      // Property `fieldToFocus` of `startRowEditMode` isn't used as it doesn't work properly with virtualization
      // Instead the scroll + focus is done manually
      apiRef.current.startRowEditMode({ id: id });
      window.setTimeout(() => {
        const rowIdx = apiRef.current.getRowIndexRelativeToVisibleRows(id);
        apiRef.current.scrollToIndexes({ rowIndex: rowIdx });
        apiRef.current.setCellFocus(id, EDIT_GRID_FIELD_KEYS.Index);
        pendingStartRowEdit.current = undefined;
      }, 0);
    },
    [rows, apiRef]
  );

  const postRowChanges = (actions?: { removedId: GridRowId }): void => {
    if (!apiRef.current) {
      return;
    }
    const pinnedRow = apiRef.current.getRow(0) as ConfigRow;
    const gridRows = [pinnedRow, ...(apiRef.current.getSortedRows() as GridRowModel<ConfigRow>[])];

    const newData = gridRows.filter(r => r.id !== actions?.removedId).map(_mapRowToConfig);

    onChange(newData);
  };

  const onRowModesModelChange = (rowModesModel: GridRowModesModel): void => {
    setRowModesModel(rowModesModel);
    postRowChanges();
  };

  useEffect(
    function updateEditState() {
      onRowEditModeChange(editModeCount);
    },
    [onRowEditModeChange, editModeCount]
  );

  const onRowEditStop = (params: GridRowEditStopParams<ConfigRow>, event: MuiEvent): void => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut && params.row.$isNew) {
      // * Prevent "auto commit" if multiple columns are added at once
      //   * otherwise it can lead to "random edit states" or laggy UI
      // * This means also that every new column has to be specifically commited (enter, action-button, etc.)
      event.defaultMuiPrevented = true;
    }
  };

  return (
    <DataGrid
      apiRef={apiRef}
      /** The first row is pinned to prevent reordering */
      pinnedRows={{ top: [rows[0]] }}
      className="rounded-md border border-solid border-neutral-50"
      customToolbarProps={{
        headerText: t('Columns'),
      }}
      customFooterProps={{
        fullContent: (
          <AssetDatasourceEditGridFooter
            editModeCount={editModeCount}
            onAddColumnClicked={onAddColumnClicked}
            onBulkEditClicked={onBulkEditClicked}
            onEndBulkEditClicked={onEndBulkEditClicked}
          />
        ),
      }}
      columns={columns}
      rows={rows}
      editMode="row"
      rowReordering
      disableRowSelectionOnClick
      disableMultipleRowSelection
      disableColumnMenu
      disableColumnFilter
      disableColumnReorder
      processRowUpdate={processRowUpdate}
      rowModesModel={rowModesModel}
      onRowModesModelChange={onRowModesModelChange}
      onRowOrderChange={(): void => postRowChanges()}
      onRowEditStop={onRowEditStop}
    />
  );
};

const _getRowsFromConfig = (configData: DataSourceColumnConfigDto[]): ConfigRow[] =>
  configData.map<ConfigRow>((config, listIdx) => ({
    id: listIdx,
    index: config.index,
    name: config.name,
    type:
      config.default.type === DataSourceColumnTypes.LinkedAsset
        ? { type: config.default.type, assetType: config.linkedAssetConfig!.assetType as LinkableAssetTypes }
        : { type: config.default.type },
    defaultValue: config.default.value,
    linkedAssetFolder: config.linkedAssetConfig?.assetFolderName ?? '',
  }));

const _createRowModesModel = (rows: ConfigRow[], model: GridRowModesModelProps): GridRowModesModel => {
  return rows.reduce<GridRowModesModel>(
    (arr, row) => ({
      ...arr,
      [row.id]: model,
    }),
    {}
  );
};

const _mapRowToConfig = (row: ConfigRow): DataSourceColumnConfigDto => {
  const typeDef = row[EDIT_GRID_FIELD_KEYS.TypeDef];
  const config: DataSourceColumnConfigDto = {
    index: row[EDIT_GRID_FIELD_KEYS.Index],
    name: row[EDIT_GRID_FIELD_KEYS.Name],
    default: {
      type: typeDef.type,
      value: row[EDIT_GRID_FIELD_KEYS.DefaultValue],
    },
  };

  if (typeDef.type === DataSourceColumnTypes.LinkedAsset) {
    config.linkedAssetConfig = {
      assetType: typeDef.assetType,
      assetFolderName: row[EDIT_GRID_FIELD_KEYS.LinkedAssetFolder] ?? '',
    };
  }

  return config;
};
