import { Skeleton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { PlusIcon } from 'assets/icons';
import {
  CfgnInsightsFilterInputProps,
  NoSelectionChipValue,
} from 'components/cfgrs/cfgn-insights/cfgn-insights-filter-input';
import { Button } from 'controls/button/button';
import { ChipValue, LargeFontChip } from 'controls/chip/chip';
import { OptionsPopover } from 'controls/options-popover/options-popover';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useIdentityUserDisplayParts } from 'hooks/identity/identity.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { getInsightsUsers, selectDistinctValues } from 'slices/cfgn-insisghts/cfgn-insights.slice';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';

export const UserFilterInput: React.FC<CfgnInsightsFilterInputProps<string[]>> = ({ value, onCommit }) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const selectedCompany = useAppSelector(selectSelectedCompany);
  const distinctUsers = useAppSelector(state => selectDistinctValues(state, 'createdBy'));
  const usersIdentityIds = distinctUsers.values;
  const usersDisplayParts = useIdentityUserDisplayParts(usersIdentityIds);
  const users = usersIdentityIds.map(x => ({
    value: x,
    displayName: usersDisplayParts[x]!.displayParts.name,
  }));
  const usersLoaded = usersIdentityIds.length > 0 || Object.values(usersDisplayParts).some(x => x?.isFetching);

  useEffect(() => {
    if (selectedCompany && distinctUsers.state !== 'Fetched' && distinctUsers.state !== 'Pending') {
      dispatch(getInsightsUsers({ companyId: selectedCompany.id }));
    }
  }, [dispatch, selectedCompany, distinctUsers.state]);

  const openPopoverBtnRef = useRef<HTMLDivElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [quickOptions, setQuickOptions] = useState(value);

  const onChipClicked = (x: ChipValue): void => {
    const updatedValue =
      x === NoSelectionChipValue ? [] : value.includes(x as string) ? value.filter(y => y !== x) : [...value, x];

    onCommit(updatedValue as string[]);
  };

  function commitValueSelectionChange(changedValue: string, selected: boolean): void {
    const newValues = selected ? [...value, changedValue] : value.filter(x => changedValue !== x);
    onCommit(newValues);

    if (selected && !quickOptions.includes(changedValue)) {
      setQuickOptions([...quickOptions, changedValue]);
    }
  }

  function toggleAllSelections(): void {
    const allOptionsSelected = users.every(x => value.includes(x.value));
    const newValues = allOptionsSelected ? [] : users.map(x => x.value);
    onCommit(newValues);
    if (newValues.length) {
      setQuickOptions(newValues);
    }
  }

  return (
    <div className="flex flex-wrap items-center gap-3">
      <OptionsPopover
        options={users}
        selectedValues={value}
        onValueSelectionChange={commitValueSelectionChange}
        onToggleAll={toggleAllSelections}
        open={popoverOpen && usersLoaded}
        onClose={(): void => setPopoverOpen(false)}
        anchorEl={openPopoverBtnRef.current}
      />

      <LargeFontChip
        value={NoSelectionChipValue}
        text={t('Any')}
        variant="Primary"
        selected={!value.length}
        onClick={onChipClicked}
      />
      <span className="self-stretch border-r border-neutral-50" />

      <div ref={openPopoverBtnRef}>
        <Button
          variant="ChipMenu"
          text={t('Select user...')}
          Svg={PlusIcon}
          onClick={(): void => setPopoverOpen(x => !x)}
          isLoading={popoverOpen && !usersLoaded}
        />
      </div>

      {!usersLoaded && quickOptions.length > 0 && <Skeleton className="w-[120px]" />}

      {usersLoaded &&
        quickOptions.map(x => {
          const displayName = users.find(user => user.value === x)?.displayName ?? x;
          return (
            <LargeFontChip
              key={x}
              value={x}
              text={displayName}
              variant="Primary"
              selected={value.includes(x)}
              onClick={onChipClicked}
            />
          );
        })}
    </div>
  );
};
