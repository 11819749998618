import { CompanySettingsStaffActiveUsersCard } from 'components/company/company-settings-staff-active-users-card';
import { CompanySettingsStaffCreateInviteButton } from 'components/company/company-settings-staff-create-invite-button';
import { CompanySettingsStaffInvitedUsersCard } from 'components/company/company-settings-staff-invited-users-card';
import { useCompanySettingsStaff } from 'components/company/company-settings-staff-provider';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';

export const CompanySettingsStaff: React.FC = () => {
  const { t } = useTypedTranslation();
  const { userInvites, users } = useCompanySettingsStaff();

  const selectedCompany = useAppSelector(selectSelectedCompany);

  return (
    <div data-cmptype="CompanySettingsStaff" className="flex flex-col gap-6 p-6">
      <div data-cmptype="CompanySettingsStaff-header" className="flex">
        <div className="flex-1">
          <div className="text-heading-m">{t('Users')}</div>
          <div className="text-m-regular">
            {t(
              `Manage and invite users to access and collaborate on the company ${selectedCompany?.displayName ?? ''}`
            )}
          </div>
        </div>
        <div className="self-end">
          {selectedCompany && (
            <div className="self-end">
              <CompanySettingsStaffCreateInviteButton />
            </div>
          )}
        </div>
      </div>

      {selectedCompany && (
        <>
          {userInvites.length > 0 && <CompanySettingsStaffInvitedUsersCard />}

          {users.length > 0 && <CompanySettingsStaffActiveUsersCard />}
        </>
      )}
    </div>
  );
};
