import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Identities,
  Identity,
  getIdentityGet,
  getIdentityGetRelevantForCompany,
} from 'services/identity/identity.service';
import * as PersistenceMiddleware from 'services/store/persistence.middleware';
import { RootState } from 'services/store/store.service';

export type IdentityState = {
  identities: { [identityId: string]: Identity };
  invalidIdentityIds: string[];
};

const initialState: IdentityState = {
  identities: {},
  invalidIdentityIds: [],
};

const SLICE_NAME = 'identity';

PersistenceMiddleware.registerState<IdentityState, IdentityState['identities']>({
  state: SLICE_NAME,
  key: 'identity',
  selector: state => state.identities,
});

export const fetchIdentity = createAsyncThunk<Identity, string>(
  'identity/fetchIdentity',
  async (identityId, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    if (!identityId || state.identity.invalidIdentityIds.includes(identityId)) {
      return thunkApi.rejectWithValue(undefined);
    }

    const identity = await getIdentityGet(identityId);
    if (!identity) {
      return thunkApi.rejectWithValue(undefined);
    }
    return identity;
  }
);

export const fetchIdentitiesOfCompany = createAsyncThunk<Identities, string>(
  'identity/fetchIdentitiesOfCompany',
  async (companyId, thunkApi) => {
    if (!companyId) {
      return thunkApi.rejectWithValue(undefined);
    }

    const identities = await getIdentityGetRelevantForCompany(companyId);
    return identities;
  }
);

const identitySlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchIdentity.fulfilled, (state, { payload, meta }) => {
        state.identities[meta.arg] = payload;
      })
      .addCase(fetchIdentity.rejected, (state, { payload, meta }) => {
        const id = meta.arg;
        if (!state.invalidIdentityIds.includes(id)) {
          state.invalidIdentityIds.push(id);
        }
      })
      .addCase(fetchIdentitiesOfCompany.fulfilled, (state, { payload, meta }) => {
        state.identities = { ...state.identities, ...payload };
      });
  },
});

export default identitySlice.reducer;
