import { useState } from 'react';
import { CopyIcon, CrossIcon, DeleteIcon, FolderAssetCollapsedIcon, MoveIcon } from 'assets/icons';
import { ASSET_TYPE_MAP } from 'components/asset-editor/asset-type-map';
import { AssetBulkDeleteModal } from 'components/asset-editor/details/modals/asset-bulk-delete-modal';
import { AssetBulkRelocateModal } from 'components/asset-editor/details/modals/asset-bulk-relocate-modal';
import { Button } from 'controls/button/button';
import { CbnAlert } from 'controls/cbn-alert/cbn-alert';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { Icon } from 'controls/icon/icon';
import { assetPathToKey } from 'helper/assets/assets.helper';
import { useIsAssetBundleEditable } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import {
  Asset,
  MATERIALS_3D_FOLDER_NAME,
  isFolderAsset,
  selectAssetsOfTreeViewNodeIds,
  selectSelectedAssetPath,
  setSelectedTreeViewNodeIds,
} from 'slices/assets/assets.slice';

export const AssetBulkEdit: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t, tSelectedAssets } = useTypedTranslation();
  const isAssetBundleEditable = useIsAssetBundleEditable();

  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const singleSelectionAssetPath = useAppSelector(selectSelectedAssetPath);
  const selectedAssets = useAppSelector(selectAssetsOfTreeViewNodeIds);
  const sortedAssets = [...selectedAssets];
  _sortFlatAssetList(sortedAssets);
  const folderKeys = selectedAssets.filter(a => isFolderAsset(a)).map(a => assetPathToKey(a.path));

  const hasSameNameAssets = selectedAssets.some((a1, idx1) =>
    selectedAssets.some((a2, idx2) => idx1 !== idx2 && a1.path.assetId === a2.path.assetId)
  );

  const assetsToRelocate = selectedAssets.filter(
    asset => !isFolderAsset(asset) && asset.path.folderId !== MATERIALS_3D_FOLDER_NAME
  );

  // exclude childs if the parent folder is selected
  const assetsToDelete = selectedAssets.filter(
    asset => !asset.parentFolderKey || !folderKeys.includes(asset.parentFolderKey)
  );

  const folderCount = selectedAssets.filter(a => isFolderAsset(a)).length;

  const clearSelection = (): void => {
    dispatch(setSelectedTreeViewNodeIds([assetPathToKey(singleSelectionAssetPath)]));
  };

  const hasMixedFolderChildSelection = selectedAssets.some(
    a => a.parentFolderKey && folderKeys.includes(a.parentFolderKey)
  );
  const hasMaterialsSelection = selectedAssets.some(a => a.path.folderId === MATERIALS_3D_FOLDER_NAME);
  const relocateDisabledInfo =
    folderCount > 0
      ? t("This action isn't available for folders in multi selection mode!")
      : assetsToRelocate.length === 0
        ? t('None of the selected assets can be copied or moved in multi selection mode!')
        : hasSameNameAssets
          ? t(
              "One or more selected assets share the same name and thus can't be copied or moved to the same destination!"
            )
          : '';

  return (
    <>
      <CbnCard data-cmptype="AssetBulkEdit">
        <CbnCardHeader
          title={t('Asset multi selection')}
          subText={tSelectedAssets(selectedAssets.length)}
          actions={<Button Svg={CrossIcon} title={t('Clear selection')} variant="Outlined" onClick={clearSelection} />}
          actionsPlacement="left"
        />
        <div className="flex flex-col overflow-y-auto">
          {isAssetBundleEditable && (
            <>
              <div className="flex gap-2 px-6 py-3.5">
                <Button
                  variant="Secondary"
                  Svg={CopyIcon}
                  text={t('Copy')}
                  disabled={!!relocateDisabledInfo.length}
                  title={relocateDisabledInfo}
                  onClick={(): void => setShowCopyModal(true)}
                />
                <Button
                  variant="Secondary"
                  Svg={MoveIcon}
                  text={t('Move to ...')}
                  disabled={!!relocateDisabledInfo.length}
                  title={relocateDisabledInfo}
                  onClick={(): void => setShowMoveModal(true)}
                />
                <Button
                  variant="Text"
                  Svg={DeleteIcon}
                  text={t('Delete')}
                  hoverColor="Danger"
                  onClick={(): void => setShowDeleteModal(true)}
                />
              </div>
              <div className="border-b border-neutral-40"></div>
            </>
          )}
          <div className="flex flex-col gap-4 overflow-y-auto p-6">
            {hasMixedFolderChildSelection && (
              <CbnAlert
                severity="info"
                caption={t('Mixed selection of folders and childs')}
                content={t(
                  'Please be aware that selected folders will be fully deleted and not only the individually selected childs.'
                )}
              />
            )}
            {hasMaterialsSelection && (
              <CbnAlert
                severity="info"
                caption={t('Materials selected')}
                content={t(
                  `Materials will be ignored when copying or moving assets, as they can only exist in "${MATERIALS_3D_FOLDER_NAME}".`
                )}
              />
            )}
            <div className="flex flex-col gap-4 overflow-y-auto">
              <h4>{t('Selected assets')}</h4>
              <ul className="flex flex-col gap-2 overflow-y-auto text-m-regular text-neutral-80">
                {sortedAssets.map(asset => {
                  const assetPath = assetPathToKey(asset.path);
                  return (
                    <li key={assetPath} className="flex items-center gap-2">
                      {asset.path.folderId && (
                        <>
                          {asset.path.folderId}
                          <Icon Svg={FolderAssetCollapsedIcon} className="w-3" />
                        </>
                      )}
                      <Icon Svg={ASSET_TYPE_MAP[asset.type].Icon} className="w-5" />
                      <span>{asset.path.assetId}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </CbnCard>

      {showCopyModal && (
        <AssetBulkRelocateModal assets={assetsToRelocate} action="Copy" onClose={(): void => setShowCopyModal(false)} />
      )}
      {showMoveModal && (
        <AssetBulkRelocateModal assets={assetsToRelocate} action="Move" onClose={(): void => setShowMoveModal(false)} />
      )}
      {showDeleteModal && (
        <AssetBulkDeleteModal assets={assetsToDelete} onClose={(): void => setShowDeleteModal(false)} />
      )}
    </>
  );
};

/**
 * Sort assets in a flat manner
 * - FolderA
 * - FolderA.child1
 * - FolderB.child15
 * - rootAssetA
 * - rootAssetB
 *
 * @param sortItems will be sorted in place
 */
function _sortFlatAssetList(assets: Asset[]): void {
  assets.sort((assetA, assetB) => {
    const nameA = isFolderAsset(assetA) ? assetA.path.assetId : `${assetA.path.folderId}.${assetA.path.assetId}`;
    const nameB = isFolderAsset(assetB) ? assetB.path.assetId : `${assetB.path.folderId}.${assetB.path.assetId}`;

    const isRootAssetA = nameA.startsWith('.');
    const isRootAssetB = nameB.startsWith('.');

    if (isRootAssetA && !isRootAssetB) {
      return 1;
    } else if (!isRootAssetA && isRootAssetB) {
      return -1;
    } else {
      return nameA.localeCompare(nameB);
    }
  });
}
