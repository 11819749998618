import { useParams } from 'react-router-dom';
import { AcceptInvitationParams } from 'components/invitation/accept-company-invitation-wrapper';
import { UserRegistrationCard } from 'components/login/user-registration-card';
import { buildCompanyInvitationSubPagePath } from 'services/routes/company-invitation-routes.service';

export const AcceptCompanyInvitationRegister: React.FC = () => {
  const { companyName, inviteToken, inviteRecipient } = useParams() as AcceptInvitationParams;

  return (
    <div data-cmptype="AcceptCompanyInvitationRegister">
      <UserRegistrationCard
        loginLinkPath={buildCompanyInvitationSubPagePath(companyName, inviteToken, 'login', inviteRecipient)}
        inviteData={{ companyName, inviteToken, inviteRecipient }}
      />
    </div>
  );
};
