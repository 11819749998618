import { EndpointDescription } from 'services/http/http.service';
import { AssetPath } from 'slices/assets/assets.slice';

export const CFGR_FEATURES = ['readonly'] as const;

export const CONFIGURATORS_ENDPOINTS = {
  configuratorsGetAll: (companyId: string): EndpointDescription => ({
    url: '/configurators/getAll',
    params: { companyName: companyId },
  }),

  configuratorsGet: (companyId: string, cfgrName: string): EndpointDescription => ({
    url: '/configurators/get',
    params: { companyName: companyId, cfgrName },
  }),

  /**
   * This endpoint is also used to create new configurators.\
   * This is done by copying a configurator from a template,
   * which is basically just a configurator in the company "Combeenation".
   */
  configuratorsCopy: (
    sourceCompanyName: string,
    sourceCfgrName: string,
    sourceCfgrVersion: string,
    destinationCompanyName: string,
    destinationCfgrName: string,
    destinationCfgrDisplayName: string,
    destinationDraftDisplayName?: string
  ): EndpointDescription => ({
    url: '/configurators/copy',
    params: {
      sourceCompanyName,
      sourceCfgrName,
      sourceCfgrVersion,
      destinationCompanyName,
      destinationCfgrName,
      destinationCfgrDisplayName,
      destinationDraftDisplayName,
    },
  }),

  configuratorsDelete: (companyId: string, configuratorName: string): EndpointDescription => ({
    url: '/configurators/delete',
    params: {
      companyName: companyId,
      configuratorName: configuratorName,
    },
  }),

  configuratorsRestore: (companyId: string, configuratorName: string): EndpointDescription => ({
    url: '/configurators/restore',
    params: {
      companyName: companyId,
      configuratorName: configuratorName,
    },
  }),

  configuratorsUploadRehauWindowDataPackage: (
    companyId: string,
    configuratorName: string,
    draftId: string,
    dataPackage: File,
    mergeMode: number
  ): EndpointDescription => ({
    url: '/configurators/uploadRehauWindowDataPackage',
    params: {
      CompanyName: companyId,
      ConfiguratorName: configuratorName,
      Id: draftId,
      DataPackage: dataPackage,
      MergeMode: mergeMode,
    },
  }),

  configuratorsCreateViewerOnlyCfgr: (
    assetPath: AssetPath,
    cfgrName: string,
    cfgrDisplayName: string
  ): EndpointDescription => ({
    url: '/configurators/createViewerOnlyCfgr',
    params: {
      companyName: assetPath.companyId,
      cfgrName: cfgrName,
      cfgrDisplayName: cfgrDisplayName,
      babylonAsset: {
        folderName: assetPath.folderId,
        assetName: assetPath.assetId,
      },
      assetBundleName: assetPath.bundleId,
      assetBundleVersion: assetPath.bundleVersion,
    },
  }),

  getCfgrTemplates: (companyId: string): EndpointDescription => ({
    url: '/configurators/getCfgrTemplates',
    params: {
      companyName: companyId,
    },
  }),

  getWhitelistedDomains: (companyName: string, configuratorName: string): EndpointDescription => ({
    url: '/configurators/getWhitelistedDomains',
    params: { companyName, configuratorName },
  }),

  updateWhitelistedDomains: (
    companyName: string,
    configuratorName: string,
    domains: string[]
  ): EndpointDescription => ({
    url: '/configurators/updateWhitelistedDomains',
    params: { companyName, configuratorName, domains },
  }),
};
