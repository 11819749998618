import { ClickAwayListener } from '@mui/base';
import { Dropdown as BaseDropdown, DropdownProps as BaseDropdownProps } from '@mui/base/Dropdown';
import { Menu as BaseMenu, MenuProps as BaseMenuProps, menuClasses } from '@mui/base/Menu';
import { PopperPlacementType } from '@mui/base/Popper';
import { styled } from '@mui/material';
import React from 'react';
import { tw } from 'helper/css.helper';

//toAsk: remove unused props + maybe add props for better generic use
export type CbnMenuProps = BaseMenuProps &
  Pick<BaseDropdownProps, 'open'> & {
    'onClose'?: () => void;
    'placement'?: PopperPlacementType;
    'data-cmptype'?: string;
  };

const StyledMenu = styled(BaseMenu)(({ theme }) => ({
  [`&.${menuClasses.root}`]: {
    zIndex: theme.zIndex.modal,
  },
}));

export const CbnMenu: React.FC<CbnMenuProps> = ({ 'data-cmptype': dataCmpType, children, open, onClose, ...props }) => {
  const onOpenChange = (
    event: React.MouseEvent | React.KeyboardEvent | React.FocusEvent | null,
    open: boolean
  ): void => {
    if (!open) {
      onClose?.();
    }
  };

  return (
    <BaseDropdown open={open} onOpenChange={onOpenChange}>
      <StyledMenu
        {...props}
        data-cmptype={'CbnMenu' + (dataCmpType ? ` ${dataCmpType}` : '')}
        slotProps={{
          listbox: {
            id: 'simple-menu',
            className: tw`border-1 my-3 min-w-[200px] rounded-lg border-solid border-neutral-40 bg-neutral-10 py-1.5 shadow-lg`,
          },
          root: {
            placement: props.placement ?? 'auto',
          },
        }}
      >
        <ClickAwayListener onClickAway={(): void => onClose?.()}>
          <div>{children}</div>
        </ClickAwayListener>
      </StyledMenu>
    </BaseDropdown>
  );
};
