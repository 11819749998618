import { useAppSelector } from 'hooks/store/store.hooks';
import { Company } from 'slices/company-data/company-data.slice';
import {
  CompanyPermissions,
  PermissionBundles,
  PermissionValue,
  SystemPermissions,
  SystemPermissionsKeys,
} from 'slices/permission/permission.slice';

/**
 * - All: Every flag must match
 * - Any: At least one flag must match
 */
export type PermissionCondition = 'All' | 'Any';

const _getAllowedSystemUserPermissions = (): PermissionValue => {
  const sysPermissions = useAppSelector(state => state.permissions.system);

  if (!sysPermissions) {
    return 0;
  }

  const result = Object.keys(sysPermissions).reduce<PermissionValue>((acc, key) => {
    acc |= sysPermissions[key as SystemPermissionsKeys] ? SystemPermissions[key as keyof typeof SystemPermissions] : 0;
    return acc;
  }, 0);

  return result;
};

const _hasPermission = (
  requestedPermission: PermissionValue,
  allowedPermissions: PermissionValue,
  condition: PermissionCondition
): boolean => {
  // one bit of the permission-flags has to match
  const hasAnyPermission = (allowedPermissions & requestedPermission) !== 0;
  // all bits of the requested flags must match the permission-flags of the company
  const hasAllPermissions = (allowedPermissions & requestedPermission) === requestedPermission;

  const hasRequestedPermission = 'Any' === condition ? hasAnyPermission : hasAllPermissions;
  return hasRequestedPermission;
};

/**
 * @param requestedPermission One or more combined permission-flags or a PermissionBundle
 * @param companyId Not required if only system-permissions are requested
 * @param condition Condition how the requested flags must match the authorized ones
 *
 * @example
 * const hasPermission = usePermission(CompanyPermissions.ViewCompany);
 * usePermission(PermissionBundles.ShowAdminPage);
 * usePermission(CompanyPermissions.ViewDraft | SystemPermissions.UpdateTheme, companyId);
 */
export const usePermission = (
  requestedPermission: CompanyPermissions | SystemPermissions | PermissionBundles,
  companyId?: string,
  condition: PermissionCondition = 'Any'
): boolean => {
  const companyPermissions = useAppSelector(state => state.permissions.companies);
  const debugOverride = useAppSelector(state => state.debug.permissionOverride);
  const systemPermissions = _getAllowedSystemUserPermissions();

  const companyPermission = companyId ? companyPermissions[companyId] : 0;
  const allowedPermissions = debugOverride || companyPermission | systemPermissions;

  return _hasPermission(requestedPermission, allowedPermissions, condition);
};

export const useCompaniesWithPermission = (
  requestedPermission: CompanyPermissions,
  condition: PermissionCondition = 'Any'
): Company[] => {
  const companies = useAppSelector(state => state.companyData.companies);
  const companyPermissions = useAppSelector(state => state.permissions.companies);

  const permittedCompanies = companies.filter(c =>
    _hasPermission(requestedPermission, companyPermissions[c.id], condition)
  );

  return permittedCompanies;
};
