import { Skeleton } from '@mui/material';
import { TableCell } from 'controls/table/table-cell';
import { TableRow } from 'controls/table/table-row';

type SkeletonProps = React.ComponentProps<typeof Skeleton>;

type TableSkeletonRowsProps = {
  numberOfRows?: number;
  numberOfCols: number;

  /** Can be used to set custom skeleton properties for dedicated columns */
  colSkeletonProps?: { [idx: number]: SkeletonProps };

  /**
   * Can be used to set custom skeleton properties for all columns.
   * Overruled by `colSkeletonProps` if both are given
   */
  allColsSkeletonProps?: SkeletonProps;
};

/**
 * @example
 * ```
 * <Table>
 *   <TableHead>
 *     <TableRow>
 *       <TableCell>Header 1</TableCell>
 *       <TableCell className="w-0">Header 2</TableCell>
 *     </TableRow>
 *   </TableHead>
 * <TableBody>
 *   <>
 *     {isLoading && (
 *       <TableSkeletonRows
 *         numberOfRows={3}
 *         numberOfCols={2}
 *         colSkeletonProps={{
 *           1: { className: 'w-[10rem]' }, // Fixed size `10rem` for second col
 *         }}
 *       />
 *     )}
 *
 *     {!isLoading && (
 *       <TableRow>
 *         <TableCell>Data cell 1</TableCell>
 *         <TableCell>Data cell 2</TableCell>
 *       </TableRow>
 *     )}
 *   </>
 * </TableBody>
 * <Table/>
 * ```
 */
export const TableSkeletonRows: React.FC<TableSkeletonRowsProps> = ({
  numberOfRows = 3,
  numberOfCols,
  colSkeletonProps,
  allColsSkeletonProps,
}) => {
  const rows = Array.from({ length: numberOfRows }, (_, index) => index);
  const columns = Array.from({ length: numberOfCols }, (_, index) => index);

  return (
    <>
      {rows.map(row => (
        <TableRow key={row}>
          {columns.map((col, colIdx) => {
            const colProps = colSkeletonProps?.[colIdx] ?? allColsSkeletonProps ?? {};
            return (
              <TableCell key={col}>
                <Skeleton {...colProps} />
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </>
  );
};
