import { EndpointDescription } from 'services/http/http.service';

export type CfgrDomainRequest = {
  companyName: string;
  configuratorName: string;
};
export type AssetBundleIdRequest = {
  companyName: string;
  assetBundleName: string;
  assetBundleVersion: string;
};

export type CfgrMappingDto = {
  name: string;
  displayName: string;
  AutoPublish?: boolean;
  /**
   * If true, it will be added to an existing cfgr\
   * Although this could be decided on-demand by the backend, we want to explicitly keep hold of the users intention
   */
  targetExistingCfgr?: boolean;
};

export type AssetBundleMappingDto = {
  bundleName: string;
  bundleDisplayName: string;
  displayName: string;
  AutoPublish?: boolean;
};

export type ReplicaMappingDto = {
  company: string;
  configurators: { [schemaName: string]: CfgrMappingDto };
  assetBundles: { [schemaName: string]: AssetBundleMappingDto };
};

export type MappingDto = {
  mapping: ReplicaMappingDto;
  existingAssetBundleNames: string[];
  existingAssetBundleDisplayNames: string[];
  existingCfgrNames: string[];
  existingCfgrDisplayNames: string[];
  blueprintId?: string;
};

export const REPLICATOR_ENDPOINTS = {
  replicatorCreateBlueprint: (
    companyName: string,
    configurators: CfgrDomainRequest[],
    assetBundles: AssetBundleIdRequest[]
  ): EndpointDescription => ({
    url: '/replicator/createBlueprint',
    params: {
      companyName: companyName,
      configurators: configurators,
      assetBundles: assetBundles,
    },
  }),
  replicatorCreateBlueprintCrossEnv: (
    companyName: string,
    configurators: CfgrDomainRequest[],
    assetBundles: AssetBundleIdRequest[]
  ): EndpointDescription => ({
    url: '/replicator/createBlueprintCrossEnv',
    params: {
      companyName: companyName,
      configurators: configurators,
      assetBundles: assetBundles,
    },
  }),

  replicatorReplicate: (
    blueprintId: string,
    companyName: string,
    configurators: { [schemaName: string]: CfgrMappingDto },
    assetBundles: { [schemaName: string]: AssetBundleMappingDto }
  ): EndpointDescription => ({
    url: '/replicator/replicate',
    params: {
      blueprintId: blueprintId,
    },
    data: {
      company: companyName,
      configurators: configurators,
      assetBundles: assetBundles,
    },
  }),

  replicatorReplicateCrossEnv: (
    blueprintId: string,
    companyName: string,
    configurators: { [schemaName: string]: CfgrMappingDto },
    assetBundles: { [schemaName: string]: AssetBundleMappingDto }
  ): EndpointDescription => ({
    url: '/replicator/replicateCrossEnv',
    params: {
      blueprintId: blueprintId,
    },
    data: {
      company: companyName,
      configurators: configurators,
      assetBundles: assetBundles,
    },
  }),

  replicatorGetReplicationStatus: (replicationId: string, targetCompany: string): EndpointDescription => ({
    url: '/replicator/getReplicationStatus',
    params: {
      replicationId: replicationId,
      targetCompany: targetCompany,
    },
  }),

  replicatorGenerateMapping: (blueprintId: string, companyName: string): EndpointDescription => ({
    url: '/replicator/generateMapping',
    params: {
      blueprintId: blueprintId,
      companyName: companyName,
    },
  }),
  replicatorGenerateMappingCrossEnv: (blueprintSasUri: string, companyName: string): EndpointDescription => ({
    url: '/replicator/generateMappingCrossEnv',
    params: {
      blueprintSasUri: blueprintSasUri,
      companyName: companyName,
    },
  }),

  replicatorCancel: (replicationId: string, companyName: string): EndpointDescription => ({
    url: '/replicator/cancel',
    params: {
      replicationId: replicationId,
      companyName: companyName,
    },
  }),
};
