import { useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { Card } from 'controls/card/card';
import { UserPasswordFormItem } from 'controls/form-item/user-password-form-item';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { NewPasswordResponse, postAccountCreateNewPassword as createNewPassword } from 'services/auth/auth.service';
import { PATHS } from 'services/routes/paths.service';

const _RESPONSE_MESSAGE: Record<NewPasswordResponse, string> = {
  Success: '',
  InvalidPassword:
    'Invalid Password (must be longer than 10 chars, at least 6 unique chars, doesn\'t contain the email address and not listed on "HaveIBeenPwned")',
  TokenConsumed: 'This lost password link has already been used.  Send a new lost password request.',
  TokenRevoked: 'The lostPassword link has been revoked. Please contact an admin.',
  TokenNoLongerValid: 'The lost password link is no longer valid. Send a new lost password request.',
  TokenNotFound:
    'Lost password link not valid for this email, maybe you have requested it for a different email address.',
  UnknownResponse: 'Some error occurred. Please send a new lost password request or contact an admin.',
};

export const ResetPassword: React.FC = () => {
  const { t } = useTypedTranslation();

  const notAllEnteredMsg = t('Please fill in all fields');

  const { token } = useParams();
  const [params] = useSearchParams();

  const email = params.get('mail');
  const [passwordInput, setPasswordInput] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseStatus, setResponseStatus] = useState<NewPasswordResponse>();
  const invalidUrl = !email || !token;

  const isNotAllEntered = !passwordInput;
  const errorMsg = isNotAllEntered ? notAllEnteredMsg : responseStatus ? t(_RESPONSE_MESSAGE[responseStatus]) : '';
  const pwError = isSubmitted && isNotAllEntered;

  const onReset = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    setIsSubmitted(true);

    if (isNotAllEntered) {
      setResponseStatus('InvalidPassword');
    }

    if (!email || !token) {
      setResponseStatus('UnknownResponse');
      return;
    }

    setIsSubmitting(true);
    const response = await createNewPassword(email, passwordInput, token);
    setIsSubmitting(false);
    setResponseStatus(response);
  };

  return (
    <div className="flex flex-grow flex-col items-center gap-6">
      <Card>
        <div className="flex w-100 flex-col gap-6 pt-3">
          <div className="flex flex-col gap-2">
            <h3>{t('Reset Password')}</h3>
            {!invalidUrl && (
              <span className="text-m-regular text-neutral-70">{t('Reset password for user ') + email}</span>
            )}
          </div>

          {invalidUrl && (
            <div className="flex flex-col gap-5">
              <span className="text-center text-m-regular text-danger-main">{t('Invalid URL!')}</span>
              <Link to={PATHS.login} className="text-center text-m-medium text-primary-main">
                {t('Back to login')}
              </Link>
            </div>
          )}

          {!invalidUrl && responseStatus !== 'Success' && (
            <form onSubmit={onReset} className="flex flex-col gap-6">
              <UserPasswordFormItem
                onValueChanged={(val): void => {
                  setPasswordInput(val);
                  setIsSubmitted(false);
                }}
                hasError={pwError}
              />

              {isSubmitted && errorMsg && (
                //toAsk - clear inputs after failed reset
                <span className=" text-s-regular text-danger-main">{errorMsg}</span>
              )}

              <div className="flex flex-grow">
                <Button
                  type="submit"
                  variant="Primary"
                  big
                  grow
                  text={t('Reset password')}
                  disabled={isNotAllEntered}
                  isLoading={isSubmitting}
                ></Button>
              </div>
            </form>
          )}

          {responseStatus === 'Success' && (
            <div className="flex flex-col gap-5">
              <span className="text-s-regular">{t('Your password has been successfully changed!')}</span>
              <LinkButton href={PATHS.login} variant="Primary" big text={t('Back to log in')}></LinkButton>
            </div>
          )}
        </div>
      </Card>

      {responseStatus !== 'Success' && (
        <div className="flex flex-row items-center gap-1">
          <span className="text-m-regular text-neutral-90">{t('Log in instead?')}</span>
          <LinkButton href={PATHS.login} variant="TextInline" text={t('Back to log in')}></LinkButton>
        </div>
      )}
    </div>
  );
};
