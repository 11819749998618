import { useRef, useState } from 'react';
import { SearchIcon } from 'assets/icons';
import { Button, ButtonVariants } from 'controls/button/button';
import { Icon } from 'controls/icon/icon';
import { TextInput, TextInputProps } from 'controls/input/text-input';
import { useControlledDebounce } from 'hooks/common/timing.hooks';

type CbnSearchInputProps = Pick<TextInputProps, 'value' | 'onValueChange' | 'placeholder' | 'autoFocus'> & {
  expandableButton?: boolean;
  debounceMs?: number;
  searchBtnVariant?: ButtonVariants;
};

export const CbnSearchInput: React.FC<CbnSearchInputProps> = ({
  value,
  onValueChange,
  placeholder,
  expandableButton,
  autoFocus,
  debounceMs,
  searchBtnVariant = 'Outlined',
}) => {
  const [localValue, onLocalValueChange] = useControlledDebounce(value, onValueChange, debounceMs);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isExpanded, setIsExpanded] = useState(expandableButton ? false : true);

  const onExpandClick = (): void => {
    setIsExpanded(!isExpanded);
  };

  const onInputBlur = (): void => {
    if (expandableButton && !value?.length) {
      setIsExpanded(false);
    }
  };

  // When the user clicks the "expand button" we can pretty much assume that he wants a focused input afterwards
  // Otherwise we don't predefine any value and can be overruled by consuming code
  const defaultAutoFocus = expandableButton ? true : undefined;
  return isExpanded ? (
    <TextInput
      ref={inputRef}
      type="search"
      autoFocus={autoFocus ?? defaultAutoFocus}
      autoComplete="off"
      placeholder={placeholder}
      startAdornment={<Icon Svg={SearchIcon} className="w-6" />}
      value={localValue}
      onValueChange={onLocalValueChange}
      onBlur={onInputBlur}
    />
  ) : (
    <Button Svg={SearchIcon} variant={searchBtnVariant} onClick={onExpandClick} />
  );
};
