import { EndpointDescription } from 'services/http/http.service';

export const IDENTITY_ENDPOINTS = {
  identityGet: (identityId: string): EndpointDescription => ({
    url: '/identity/get',
    params: {
      identityId: identityId,
    },
  }),
  identityGetRelevantForCompany: (companyName: string): EndpointDescription => ({
    url: '/identity/getRelevantForCompany',
    params: {
      companyName: companyName,
    },
  }),
};
