import { AutocompleteMultiSelect } from 'controls/autocomplete/autocomplete-multi-select';
import { SelectOption, SelectValue } from 'controls/select/select';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch } from 'hooks/store/store.hooks';
import { BlueprintAssetBundle, overwriteBlueprintAssetBundleSelections } from 'slices/replicator/replicator.slice';

type ReplicatorBlueprintAssetbundleVersionPickerProps = {
  blueprintAssetBundle: BlueprintAssetBundle;
};

export const ReplicatorBlueprintAssetbundleVersionPicker: React.FC<
  ReplicatorBlueprintAssetbundleVersionPickerProps
> = ({ blueprintAssetBundle }) => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();

  const options: SelectOption[] = Object.values(blueprintAssetBundle.versions).map(version => ({
    value: version.id,
    text: version.displayName,
  }));

  const selections = Object.values(blueprintAssetBundle.versions)
    .filter(v => v.selected)
    .map(v => v.id);

  const onMultiSelectChanged = (selectedVersions: SelectValue[]): void => {
    dispatch(
      overwriteBlueprintAssetBundleSelections({
        bundleName: blueprintAssetBundle.name,
        selectedVersions: selectedVersions.map(v => v.toString()),
      })
    );
  };

  return (
    <div data-cmptype="ReplicatorBlueprintAssetbundleVersionPicker" className="flex">
      <AutocompleteMultiSelect
        options={options}
        value={selections}
        onChange={onMultiSelectChanged}
        placeholder={t('Edit versions ...')}
        disableClearable
        disableCloseOnSelect
        showMenuItemCheckbox
        hideChips
      />
    </div>
  );
};
