import { useState } from 'react';
import { LockIcon, PlusIcon, PublishIcon } from 'assets/icons';
import { PublishAssetBundleModal } from 'components/asset-editor/header/modals/publish-asset-bundle-modal';
import { Button } from 'controls/button/button';
import { FormControlLabel } from 'controls/form/form-control-label';
import { Icon } from 'controls/icon/icon';
import { StateIcon, StateIconVariants } from 'controls/icon/state-icon';
import { Select, SelectOption, SelectValue } from 'controls/select/select';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { getNextAssetVersionDisplayName, sortBundlesByLastEditedDesc } from 'helper/assets/assets.helper';
import { useNavigateToAssetEditor, usePublishAssetBundle } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import {
  AssignedCfgrVersions,
  createAssetBundleDraftFrom,
  fetchCfgrVersionsAssignedToBundleDraft,
} from 'services/assets/assets.service';
import {
  getRelevantAssetBundleVersions,
  selectAssetsInSelectedBasePath,
  selectRelevantBundleVersions,
  selectSelectedAsset,
  selectSelectedBundle,
  selectSelectedBundleVersion,
  setShowManageBundleVersionsPage,
} from 'slices/assets/assets.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

type DisabledPublishReason = 'NoSelectedBundle' | 'HasErrors' | 'Locked';
const _DISABLED_PUBLISH_REASON_MAP: Record<
  DisabledPublishReason,
  { iconType: StateIconVariants; header: string; detail: string }
> = {
  NoSelectedBundle: { iconType: 'Info', header: 'No bundle version selected', detail: '' },
  HasErrors: {
    iconType: 'Error',
    header: 'Asset errors',
    detail: "Can't publish a version which contains one or more asset errors!",
  },
  Locked: { iconType: 'Error', header: 'Bundle version locked', detail: "Can't publish a locked bundle version!" },
};

const _MANAGE_VERSIONS_KEY = '-';

export const AssetBundleVersions: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();
  const navigateToAssetEditor = useNavigateToAssetEditor();
  const publishAssetBundle = usePublishAssetBundle();

  // we know that a bundle is selected here, otherwise this component wouldn't be rendered
  const selectedBundle = useAppSelector(selectSelectedBundle)!;
  const selectedBundleVersion = useAppSelector(selectSelectedBundleVersion);
  const assetBundleVersions = useAppSelector(selectRelevantBundleVersions);
  const assetBundleVersionsSorted = [...assetBundleVersions].sort(sortBundlesByLastEditedDesc);
  const assets = useAppSelector(selectAssetsInSelectedBasePath);
  const selectedAsset = useAppSelector(selectSelectedAsset);
  const showManageBundleVersionsPage = useAppSelector(state => state.assets.ui.showManageBundleVersionsPage);
  const companyId = selectedBundle.companyId;
  const selectedBundleVersionId = selectedBundleVersion?.id ?? '';

  const hasManageAssetsPermission = usePermission(CompanyPermissions.ManageAssets, companyId);
  const hasAssetErrors = Object.values(assets).some(asset => asset.errorBag.totalItems);
  const versionIsPublished = selectedBundleVersion && !selectedBundleVersion.isDraft;
  const versionIsLocked = !!selectedBundleVersion?.lockStatus;

  const [publishAssetDialogActive, setPublishAssetDialogActive] = useState(false);
  const [assignedCfgrVersions, setAssignedCfgrVersions] = useState<AssignedCfgrVersions>([]);

  const assetBundleVersionOptions: SelectOption[] = [
    {
      value: _MANAGE_VERSIONS_KEY,
      text: t('Manage versions'),
      divider: 'below',
    },
    ...assetBundleVersionsSorted.map<SelectOption>(bundleVersion => ({
      value: bundleVersion.id,
      text: (
        <div className="flex gap-1">
          <span>
            {bundleVersion.displayName}
            {bundleVersion.isDraft && ` - ${t('Draft')}`}
          </span>
          {!!bundleVersion.lockStatus && (
            <Icon Svg={LockIcon} className="h-5 text-warning-main" title={'Version is locked'} />
          )}
        </div>
      ),
    })),
  ];

  const disabledPublishReason: DisabledPublishReason | null = !selectedBundleVersion
    ? 'NoSelectedBundle'
    : hasAssetErrors
      ? 'HasErrors'
      : versionIsLocked
        ? 'Locked'
        : null;

  const onAssetBundleVersionSelectionChanged = (value: SelectValue): void => {
    if (value === _MANAGE_VERSIONS_KEY) {
      dispatch(setShowManageBundleVersionsPage(true));
    } else {
      dispatch(setShowManageBundleVersionsPage(false));
      navigateToAssetEditor({
        companyId,
        bundleId: selectedBundle.id,
        bundleVersion: value.toString(),
      });
    }
  };

  const onCreateDraftBtnClicked = async (): Promise<void> => {
    // CAUTION: this may lead to duplicate display names if drafts with higher display names are not available in the
    // relevant bundle versions call
    // we decided to skip this rare case as there are already plans to improve the bundle version naming in general
    // (e.g. dialog with custom display name)
    const newAssetVersionDisplayName = getNextAssetVersionDisplayName(assetBundleVersions);

    // TODO: create async thunk which calls "getRelevantAssetBundleVersions" right away
    const newBundleVersion = await createAssetBundleDraftFrom(
      companyId,
      selectedBundle.id,
      selectedBundleVersionId,
      newAssetVersionDisplayName
    );

    await dispatch(
      getRelevantAssetBundleVersions({
        companyId: companyId,
        bundleId: selectedBundle.id,
      })
    );

    // also directly navigate to the new bundle version
    navigateToAssetEditor({
      companyId: companyId,
      bundleId: selectedBundle.id,
      bundleVersion: newBundleVersion,
      folderId: selectedAsset?.path.folderId,
      assetId: selectedAsset?.path.assetId,
    });
  };

  const onPublishBundleBtnClicked = async (): Promise<void> => {
    const fetchedCfgrVersionsOfBundleDraft = await fetchCfgrVersionsAssignedToBundleDraft(
      companyId,
      selectedBundle.id,
      selectedBundleVersionId
    );

    if (fetchedCfgrVersionsOfBundleDraft.length) {
      // enter the publish dialog if there are assigned configurator versions and permissions are valid
      setAssignedCfgrVersions(fetchedCfgrVersionsOfBundleDraft);
      setPublishAssetDialogActive(true);
    } else {
      // publish the asset bundle immediatly without updating any configurator versions
      await publishAssetBundle(companyId, selectedBundle.id, selectedBundleVersionId, []);
    }
  };

  return (
    <>
      <div data-cmptype="AssetBundleVersions" className="flex flex-col gap-3">
        <div className="flex flex-row items-center justify-between gap-3">
          <span className="text-l-regular text-neutral-100">
            {t('Bundle')} <b className="text-l-medium">{selectedBundle.displayName}</b>
          </span>
          <div className="flex flex-row items-center gap-3">
            <FormControlLabel
              label={t('Version')}
              labelPlacement="start"
              className="gap-3"
              control={
                // NOTE: pr-1 is required as "Select" components width seems to be too low
                <div className="min-w-[200px] pr-1">
                  <Select
                    options={assetBundleVersionOptions}
                    value={showManageBundleVersionsPage ? _MANAGE_VERSIONS_KEY : selectedBundleVersionId}
                    onChange={onAssetBundleVersionSelectionChanged}
                  />
                </div>
              }
            />
            {hasManageAssetsPermission && (
              <>
                {/* fixed width for alignment with button above */}
                <div className="flex w-[170px]">
                  {versionIsPublished ? (
                    <Button
                      text={t('New draft')}
                      Svg={PlusIcon}
                      variant={versionIsPublished ? 'Primary' : 'Secondary'}
                      onClick={onCreateDraftBtnClicked}
                      grow
                    />
                  ) : (
                    <Button
                      text={t('Publish bundle')}
                      Svg={PublishIcon}
                      variant="Primary"
                      onClick={onPublishBundleBtnClicked}
                      disabled={!!disabledPublishReason}
                      grow
                      tooltipCmp={
                        disabledPublishReason && (
                          <TooltipContent
                            header={
                              <div className="flex items-center gap-1">
                                <StateIcon
                                  variant={_DISABLED_PUBLISH_REASON_MAP[disabledPublishReason].iconType}
                                  noBckgr
                                />
                                {t(_DISABLED_PUBLISH_REASON_MAP[disabledPublishReason].header)}
                              </div>
                            }
                            detail={t(_DISABLED_PUBLISH_REASON_MAP[disabledPublishReason].detail)}
                          />
                        )
                      }
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        {versionIsPublished && (
          <div className="m-1 flex items-center justify-center rounded bg-success-surface shadow-sm-symmetric">
            <StateIcon variant="Success" noBckgr />
            <div className="text-m-regular">
              <span>{t('This is a published version of the asset bundle.')}</span>

              {hasManageAssetsPermission && (
                <>
                  <span>{t(' If you want to edit, ')}</span>
                  <span
                    className="cursor-pointer text-primary-main underline hover:no-underline"
                    onClick={onCreateDraftBtnClicked}
                  >
                    {t('create a new draft.')}
                  </span>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {publishAssetDialogActive && (
        <PublishAssetBundleModal
          assignedCfgrVersions={assignedCfgrVersions}
          onClose={(): void => setPublishAssetDialogActive(false)}
        />
      )}
    </>
  );
};
