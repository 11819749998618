import { useState } from 'react';
import { AssetFolderSelection } from 'components/asset-editor/folders/asset-folder-selection';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { FormItem } from 'controls/form-item/form-item';
import { TextInput } from 'controls/input/text-input';
import { RadioButtonEntry, RadioButtonGroup } from 'controls/radio-button/radio-button-group';
import { AssetTypes } from 'generated/asset-types';
import { isUnusedAssetKey } from 'helper/assets/assets.helper';
import { isValidHiveIdentifier } from 'helper/hive/hive.helper';
import { NEW_FOLDER_KEY, useNavigateToAssetEditor } from 'hooks/assets/assets.hooks';
import { useAlertDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { postAssetsCreateEmpty } from 'services/assets/assets.service';
import {
  AssetPath,
  getBundleVersionData,
  isFolderAsset,
  selectAssetKeys,
  selectSelectedAsset,
  selectSelectedAssetPath,
} from 'slices/assets/assets.slice';

type CreateTextAssetModalProps = {
  predefinedExtension?: PredefinedExtension;
  onConfirm?: () => void;
  onCancel: () => void;
};

const _PREDEFINED_EXTENSIONS = {
  txt: 'Text',
  svg: 'SVG',
  html: 'HTML',
  xml: 'XML',
  json: 'JSON',
} as const;

export type PredefinedExtension = keyof typeof _PREDEFINED_EXTENSIONS;

export const CreateTextAssetModal: React.FC<CreateTextAssetModalProps> = ({
  onConfirm,
  onCancel,
  predefinedExtension,
}) => {
  const { t } = useTypedTranslation();
  const navigateToAssetEditor = useNavigateToAssetEditor();
  const dispatch = useAppDispatch();
  const alert = useAlertDialog();

  const selectedAssetPath = useAppSelector(selectSelectedAssetPath);
  const selectedAsset = useAppSelector(selectSelectedAsset);
  const assetKeys = useAppSelector(selectAssetKeys);

  const preselectedFolderId = !selectedAsset
    ? ''
    : isFolderAsset(selectedAsset)
      ? selectedAssetPath.assetId
      : selectedAssetPath.folderId;
  const [selectedFolderOfDD, setSelectedFolderOfDD] = useState(preselectedFolderId);
  const [isProcessing, setIsProcessing] = useState(false);
  const [newAssetName, setNewAssetName] = useState('');
  const [newFolderName, setNewFolderName] = useState('');
  const selectedFolder = selectedFolderOfDD === NEW_FOLDER_KEY ? newFolderName : selectedFolderOfDD;
  const [fileExtension, setFileExtension] = useState<PredefinedExtension>(predefinedExtension ?? 'txt');

  const onAssetFolderSelectionChange = (selectValue: string, newFolderInputValue: string): void => {
    setSelectedFolderOfDD(selectValue);
    setNewFolderName(newFolderInputValue);
  };

  const isValidAssetName = isValidHiveIdentifier(newAssetName);
  const isValidFolder = selectedFolderOfDD === NEW_FOLDER_KEY ? isValidHiveIdentifier(newFolderName) : true;
  const isValidInput = isValidAssetName && isValidFolder;

  const extensionOptions: RadioButtonEntry[] = Object.entries(_PREDEFINED_EXTENSIONS).map<RadioButtonEntry>(
    ([key, displayName]) => ({
      value: key,
      text: displayName,
    })
  );

  const onAssetConfirm = async (): Promise<void> => {
    const newAssetPath: AssetPath = { ...selectedAssetPath, folderId: selectedFolder, assetId: newAssetName };
    if (!isUnusedAssetKey(newAssetPath, assetKeys)) {
      // TODO: proper error handling
      alert(t('Asset name already used!'), { headerText: t('Duplicate name'), variant: 'Warning' });
      return;
    }

    setIsProcessing(true);
    const { companyId, bundleId, bundleVersion } = newAssetPath;

    await postAssetsCreateEmpty(companyId, bundleId, bundleVersion, [
      { assetName: newAssetName, folderName: selectedFolder, fileExtension, assetType: AssetTypes.Text },
    ]);

    // TODO: create async thunk which calls "getBundleVersionData" right away
    await dispatch(getBundleVersionData({ companyId, bundleId, bundleVersion }));

    navigateToAssetEditor(newAssetPath);

    setIsProcessing(false);
    onConfirm?.();
  };

  return (
    <ModalDialog
      variant="NoIcon"
      data-cmptype="CreateTextAsset"
      header={t('New text asset')}
      actions={{ onConfirm: onAssetConfirm, onCancel: onCancel }}
      confirmDisabled={!isValidInput}
      confirmText={t('Create asset')}
      isProcessingConfirm={isProcessing}
    >
      <div className="flex grow flex-col gap-4">
        <FormItem labelContent={t('Asset name')}>
          <TextInput
            value={newAssetName}
            onValueChange={(val): void => setNewAssetName(val)}
            autoFocus
            error={newAssetName.length > 0 && !isValidAssetName}
          />
        </FormItem>
        <FormItem labelContent={t('Folder')}>
          <AssetFolderSelection
            preSelectedFolderId={preselectedFolderId}
            showNewFolderOption
            onChange={onAssetFolderSelectionChange}
          />
        </FormItem>
        <FormItem labelContent={t('Format')}>
          <RadioButtonGroup
            entries={extensionOptions}
            row
            value={fileExtension}
            onValueChange={(val): void => setFileExtension(val as PredefinedExtension)}
          />
        </FormItem>
      </div>
    </ModalDialog>
  );
};
