import { EndpointDescription } from 'services/http/http.service';

export const ACCOUNT_ENDPOINTS = {
  accountLogout: '/account/logout',
  accountResendActivation: '/account/resendActivation',
  accountLogin: (email: string, password: string): EndpointDescription => ({
    url: '/account/login',
    params: { email, password },
  }),
  accountCreateNewPassword: (email: string, newPassword: string, newPasswordToken: string): EndpointDescription => ({
    url: '/account/createNewPassword',
    params: { email, newPassword, newPasswordToken },
  }),
  accountPutRegisterAndCreateCompany: (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    companyName: string,
    companyDisplayName: string,
    // The culture of the browser is used (not 100% reliable, ...)
    defaultCulture = window.navigator.language
  ): EndpointDescription => ({
    url: 'account/registerAndCreateCompany',
    params: { email, password, firstName, lastName, companyName, companyDisplayName, defaultCulture },
  }),
  accountLostPassword: (email: string): EndpointDescription => ({
    url: '/account/lostPassword',
    params: { email },
  }),
  accountUpdatePassword: (oldPassword: string, newPassword: string): EndpointDescription => ({
    url: '/account/updatePassword',
    params: { oldPassword, newPassword },
  }),
  accountActivate: (activateToken: string): EndpointDescription => ({
    url: '/account/activate',
    params: { activateToken },
  }),
  accountGetInvite: (inviteToken: string, companyName: string): EndpointDescription => ({
    url: '/account/getInvite',
    params: { inviteToken, companyName },
  }),
  accountPostAcceptInvite: (companyName: string, inviteToken: string): EndpointDescription => ({
    url: '/account/acceptInvite',
    params: { companyName, inviteToken },
  }),
  accountPostLoginAndJoinCompany: (
    email: string,
    password: string,
    companyName: string,
    inviteToken: string
  ): EndpointDescription => ({
    url: '/account/loginAndJoinCompany',
    params: { email, password, companyName, inviteToken },
  }),
  accountPutRegisterAndJoinCompany: (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    companyName: string,
    inviteToken: string
  ): EndpointDescription => ({
    url: '/account/registerAndJoinCompany',
    params: { email, password, firstName, lastName, companyName, inviteToken },
  }),
};
