import React, { useRef, useState } from 'react';
import { CloudUploadIcon, CreateFolderIcon } from 'assets/icons';
import { AssetTreeItem } from 'components/asset-editor/overview/asset-tree-item';
import { useAssetsTreeView } from 'components/asset-editor/overview/assets-tree-view.hooks';
import { AssetUploadSettingsModal } from 'components/asset-editor/overview/modals/asset-upload-settings-modal';
import { CreateFolderAssetModal } from 'components/asset-editor/overview/modals/create-folder-asset-modal';
import { NewAssetMenu } from 'components/asset-editor/overview/new-asset-menu';
import { DragDropZone } from 'components/dragdrop/drag-drop-zone';
import { Button } from 'controls/button/button';
import { FileSelectButton } from 'controls/button/file-select-button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { CbnTreeView, CbnTreeViewApiRef } from 'controls/cbn-tree-view/cbn-tree-view';
import { CbnTreeViewCtrlMenu } from 'controls/cbn-tree-view/cbn-tree-view-ctrl-menu';
import { CbnCollapseIcon } from 'controls/icon/cbn-collapse-icon';
import { useIsAssetBundleEditable } from 'hooks/assets/assets.hooks';
import { useElementSize } from 'hooks/common/sizing.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectSelectedAssetFolderId, selectSelectedBundleVersion } from 'slices/assets/assets.slice';

type AssetTreeViewProps = { onCollapse: () => void };

export const AssetTreeView: React.FC<AssetTreeViewProps> = ({ onCollapse }) => {
  const { t } = useTypedTranslation();
  const isAssetBundleEditable = useIsAssetBundleEditable();
  const { treeItems, handleSelect, handleToggle, expandedItems } = useAssetsTreeView();
  const { ref: sizingRef, size: treeViewSize } = useElementSize();
  // make UI smaller under 350px
  const showCreateFolderText = !treeViewSize || treeViewSize.width > 350;

  const selectedBundleVersion = useAppSelector(selectSelectedBundleVersion);
  const selectedAssetFolderId = useAppSelector(selectSelectedAssetFolderId);
  const selectedTreeViewNodeIds = useAppSelector(state => state.assets.ui.selectedTreeViewNodeIds);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [assetUploadSettingsModal, setAssetUploadSettingsModal] = useState(false);
  const [createFolderAssetModal, setCreateFolderAssetModal] = useState(false);

  const treeViewApiRef = useRef<CbnTreeViewApiRef>(null);

  const onUploadFilesSelected = async (files: File[]): Promise<void> => {
    setSelectedFiles(files);
    setAssetUploadSettingsModal(true);
  };

  return (
    <CbnCard data-cmptype="AssetTreeView">
      <div className="relative flex flex-col overflow-y-auto overflow-x-hidden">
        <div className="absolute right-1 top-[18px]">
          <CbnCollapseIcon collapsed={false} titleCollapse={t('Collapse asset tree')} onClick={onCollapse} />
        </div>

        <CbnCardHeader
          title={t('Assets')}
          actions={
            <div className="pr-3">
              <CbnTreeViewCtrlMenu treeViewApiRef={treeViewApiRef} />
            </div>
          }
        />

        {isAssetBundleEditable && selectedBundleVersion && !selectedBundleVersion.lockStatus && (
          <div className="flex flex-row justify-between gap-2 border-b border-neutral-40 px-5 py-3">
            <Button
              variant="Secondary"
              text={showCreateFolderText ? t('Folder') : ''}
              Svg={CreateFolderIcon}
              onClick={(): void => setCreateFolderAssetModal(true)}
              title={!showCreateFolderText ? t('New folder') : ''}
            />
            <NewAssetMenu
              menuBtnText={t('New')}
              onUploadFilesSelected={onUploadFilesSelected}
              hideUploadEntry
              menuBtnGrow
            />
            <FileSelectButton
              variant="Secondary"
              text={t('Upload')}
              Svg={CloudUploadIcon}
              multiple
              onSelect={onUploadFilesSelected}
              grow
            />
          </div>
        )}

        <div className="flex overflow-y-auto" ref={sizingRef}>
          <CbnTreeView
            data-cmptype="AssetTreeView-treeView"
            data={treeItems}
            itemRenderer={(params): React.ReactElement => <AssetTreeItem {...params} />}
            multiSelect
            expandOnDoubleClick
            expanded={expandedItems}
            selected={selectedTreeViewNodeIds}
            onNodeSelect={handleSelect}
            onNodeToggle={handleToggle}
            defaultViewMode="compact"
            apiRef={treeViewApiRef}
          />
        </div>

        {isAssetBundleEditable && selectedBundleVersion && <DragDropZone onFilesDrop={onUploadFilesSelected} />}

        {createFolderAssetModal && (
          <CreateFolderAssetModal
            onConfirm={(): void => setCreateFolderAssetModal(false)}
            onCancel={(): void => setCreateFolderAssetModal(false)}
          />
        )}
        {assetUploadSettingsModal && (
          <AssetUploadSettingsModal
            selectedFiles={selectedFiles}
            preSelectedFolderId={selectedAssetFolderId}
            onUploadStarted={(): void => setAssetUploadSettingsModal(false)}
            onCancel={(): void => setAssetUploadSettingsModal(false)}
          />
        )}
      </div>
    </CbnCard>
  );
};
