// Generated by EnumsBuilderTypeScript (DO NOT change this file manually, change the builder if required)
export enum CfgnInsightsBrowsers {
  CHROME = 'Chrome',
  FIREFOX = 'Firefox',
  SAFARI = 'Safari',
  EDGE = 'Edge',
  BOT = 'Bot',
  OPERA = 'Opera',
  INSTAGRAM = 'Instagram',
  FACEBOOK = 'Facebook',
  TIKTOK = 'TikTok',
  UNKNOWN = 'Unknown'
}