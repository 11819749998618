import { useRef, useState } from 'react';
import { CopyLinkIcon, MailIcon, UserAddIcon } from 'assets/icons';
import { useCompanySettingsStaff } from 'components/company/company-settings-staff-provider';
import { Button } from 'controls/button/button';
import { CopyToClipboardButton } from 'controls/button/copy-to-clipboard-button';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnPopover } from 'controls/cbn-popover/cbn-popover';
import { CbnToggleButtonGroup } from 'controls/cbn-toggle-button/cbn-toggle-button-group';
import { FormItem } from 'controls/form-item/form-item';
import { StateIcon } from 'controls/icon/state-icon';
import { TextInput } from 'controls/input/text-input';
import { Select, SelectOption } from 'controls/select/select';
import { UserRoles } from 'generated/user-role';
import { CBN_MAIL_ADRESSES } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import {
  INVITE_ERROR_RESPONSE_MSGS,
  inviteErrorResponses,
  postCompaniesInvite,
} from 'services/companies/companies.service';
import { isGenericActionResult } from 'services/http/http.service';

type InviteType = 'email' | 'link';

type InviteSubmitResult = { inviteToken: string; inviteLink: string };
type SubmitResult = 'not-submitted' | 'added-without-invite' | InviteSubmitResult | 'failed';
const _isInviteSubmitResult = (x: SubmitResult): x is InviteSubmitResult => typeof x !== 'string';

/**
 * A "Invite user" button which also comes with the whole "create invite" dialog + all API handling etc.
 */
export const CompanySettingsStaffCreateInviteButton: React.FC = () => {
  const { t } = useTypedTranslation();
  const { users, currentUserRole, companyId, fetchInvites, fetchUsers } = useCompanySettingsStaff();

  const inviteButtonRef = useRef<HTMLDivElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [inviteType, setInviteType] = useState<InviteType>('email');
  const [inviteMail, setInviteMail] = useState('');
  const [inviteRole, setInviteRole] = useState<UserRoles>(UserRoles.Guest);

  const userAlreadyExistsInCompany = users.some(x => x.email.toLowerCase() === inviteMail.toLowerCase());

  const [submitResult, setSubmitResult] = useState<SubmitResult>('not-submitted');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const userRoles = Object.entries(UserRoles);
  userRoles.splice(0, userRoles.length / 2);
  const userRoleOptions: SelectOption[] = userRoles
    .filter(([_, roleIdx]) => roleIdx !== UserRoles.None && currentUserRole >= (roleIdx as number))
    .map(([role, roleIdx]) => ({
      value: roleIdx,
      text: role.toString(),
    }));

  const onInviteSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    setIsLoading(true);
    const result = await postCompaniesInvite(
      companyId,
      inviteRole,
      inviteType === 'email' ? inviteMail.trim() : undefined
    );
    setIsLoading(false);

    if (isGenericActionResult(result, inviteErrorResponses)) {
      setErrorMsg(INVITE_ERROR_RESPONSE_MSGS[result.Id]);
      setSubmitResult('failed');
    } else if (result.userAddedWithoutInvite) {
      setSubmitResult('added-without-invite');
      fetchUsers();
    } else {
      setSubmitResult({ inviteToken: result.inviteToken, inviteLink: result.inviteLink });
      if (inviteType === 'link') {
        navigator.clipboard.writeText(result.inviteLink);
      }
      fetchInvites();
    }
  };

  const togglePopover = (): void => {
    if (!popoverOpen) {
      // Always reset to initial values when opening the popover.
      // Reseting on close leads to a bad UX as the user can see the state change while the popover fades out.
      setInviteType('email');
      setInviteRole(UserRoles.Guest);
      setInviteMail('');

      setSubmitResult('not-submitted');
      setIsLoading(false);
      setErrorMsg('');
    }

    setPopoverOpen(x => !x);
  };

  const wasSubmitted = submitResult !== 'not-submitted';
  const submitWasSuccess = submitResult !== 'not-submitted' && submitResult !== 'failed';

  return (
    <>
      <div data-cmptype="CompanySettingsStaffCreateInviteButton" ref={inviteButtonRef}>
        <Button variant="Primary" text={t('Invite user')} Svg={UserAddIcon} onClick={togglePopover} />
      </div>

      <CbnPopover
        open={popoverOpen}
        anchorEl={inviteButtonRef.current}
        data-cmptype="CompanySettingsStaffCreateInviteButton-popover"
        onClose={(): void => togglePopover()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <CbnCardBody>
          {/* Show form before new invite has been submitted */}
          {!wasSubmitted && (
            <form
              data-cmptype="CompanySettingsStaffCreateInviteButton-input-page"
              onSubmit={onInviteSubmit}
              autoFocus
              className="flex w-[18rem] flex-col gap-4 text-m-regular"
            >
              <FormItem labelInline labelContent={t('Invite as:')}>
                <Select
                  options={userRoleOptions}
                  value={inviteRole}
                  onChange={(x): void => setInviteRole(x as UserRoles)}
                />
              </FormItem>

              <div>{t('Invite new users to your company by email invitation or by creating an invitation link.')}</div>

              <CbnToggleButtonGroup
                exclusive
                fullWidth
                big
                entries={[
                  {
                    value: 'email',
                    text: t('Email'),
                  },
                  {
                    value: 'link',
                    text: t('Invite link'),
                  },
                ]}
                value={inviteType}
                onValueChange={(x): void => setInviteType(x as InviteType)}
              />

              {inviteType === 'link' && <div>{t('The created invitation link will be copied to your clipboard.')}</div>}

              {inviteType === 'email' && (
                <FormItem labelContent={t('Email address')}>
                  <TextInput
                    placeholder={CBN_MAIL_ADRESSES.placeholderMail}
                    value={inviteMail}
                    onValueChange={(x): void => setInviteMail(x)}
                    error={userAlreadyExistsInCompany}
                    helperText={userAlreadyExistsInCompany ? t('User is already in company.') : ''}
                  />
                </FormItem>
              )}

              <div className="flex gap-3">
                <Button variant="Outlined" text={t('Cancel')} onClick={togglePopover} />
                <div className="flex flex-1">
                  <Button
                    variant="Primary"
                    grow
                    text={inviteType === 'email' ? t('Send invitation') : t('Create link')}
                    Svg={inviteType === 'email' ? MailIcon : CopyLinkIcon}
                    type="submit"
                    disabled={inviteType === 'email' && (!inviteMail || userAlreadyExistsInCompany)}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </form>
          )}

          {/* Show success or error info after submitting the invite */}
          {wasSubmitted && (
            <div
              data-cmptype="CompanySettingsStaffCreateInviteButton-confirmation-page"
              className="flex flex-col gap-4"
            >
              <StateIcon variant={submitWasSuccess ? 'Success' : 'Error'} />
              <div className="text-heading-xs">{submitWasSuccess ? t('Success') : t('Error')}</div>

              {submitWasSuccess && submitResult === 'added-without-invite' && (
                <div className="flex flex-col gap-2 text-m-regular">
                  {t(`The user ${inviteMail} has been added to the company and was notified.`)}
                </div>
              )}

              {submitWasSuccess && _isInviteSubmitResult(submitResult) && (
                <div className="flex flex-col gap-2 text-m-regular">
                  <div>
                    {inviteType === 'email' && (
                      <>
                        <span>{t(`Your invite link with the `)}</span>
                        <span className="text-m-medium">ID {submitResult.inviteToken}</span>
                        <span>{t(` has been created and was sent to `)}</span>
                        <span className="whitespace-nowrap">{t(`${inviteMail}`)}</span>
                        <span>.</span>
                      </>
                    )}
                    {inviteType === 'link' && (
                      <>
                        <span>{t(`Your invite link with the `)}</span>
                        <span className="text-m-medium">{t(`ID ${submitResult.inviteToken}`)}</span>
                        <span>{t(` has been created and is now in your clipboard.`)}</span>
                      </>
                    )}
                  </div>

                  <CopyToClipboardButton
                    variant="TextInline"
                    text={submitResult.inviteLink}
                    buttonText={inviteType === 'email' ? t('Copy link') : t('Copy again')}
                    icon={CopyLinkIcon}
                  />
                </div>
              )}

              {!submitWasSuccess && <div className="whitespace-pre-line text-m-regular">{errorMsg}</div>}

              <div className="flex">
                <Button grow variant="Outlined" text={t('Close')} onClick={togglePopover} />
              </div>
            </div>
          )}
        </CbnCardBody>
      </CbnPopover>
    </>
  );
};
