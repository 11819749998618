import { CBN_MAIL_ADRESSES } from 'helper/url/cfgr-url.helper';

type GlobalErrorBodyProps = {
  header: string;
  text: string;
  errorId?: string;
};

export const GlobalErrorBody: React.FC<GlobalErrorBodyProps> = ({ header, text, errorId }) => {
  const mailSubject = `Error Report [${errorId}]`;
  const mailto = `mailto:${CBN_MAIL_ADRESSES.info}?subject=${mailSubject}`;

  return (
    <>
      {' '}
      <h3 className="mb-2">{header}</h3>
      <div className="flex flex-col gap-4 overflow-hidden">
        <p className="overflow-auto">{text}</p>
        <div className="text-s-regular text-neutral-70">
          {errorId && <p>ID: {errorId}</p>}
          {/*
          FYI, can't use `LinkButton` here as this is sometimes rendered outside of the router where we can't use the
          routers `Link` component...
          */}
          <a href={mailto} className="text-s-regular text-neutral-70 underline hover:no-underline">
            Contact us
          </a>
        </div>
      </div>
    </>
  );
};
