import React, { useState } from 'react';
import { CopyItemIcon } from 'assets/icons';
import { Button, ButtonVariants } from 'controls/button/button';
import { SvgComponent } from 'controls/icon/icon';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type CopyToClipboardButtonProps = {
  text: string;
  title?: string;
  buttonText?: string;
  icon?: SvgComponent;
  variant?: ButtonVariants;
  stopPropagation?: boolean;
};

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  text,
  title,
  buttonText,
  icon = CopyItemIcon,
  variant = 'Secondary',
  stopPropagation,
}) => {
  const { t } = useTypedTranslation();
  const [isTextCopied, setIsTextCopied] = useState(false);

  const onCopyLinkClicked = (evt: React.MouseEvent): void => {
    if (stopPropagation) {
      evt.stopPropagation();
    }
    navigator.clipboard.writeText(text);
    setIsTextCopied(true);
  };

  return (
    <Button
      Svg={icon}
      variant={variant}
      text={buttonText}
      title={isTextCopied ? t('Copied!') : title}
      onClick={onCopyLinkClicked}
      onMouseOut={(): void => setIsTextCopied(false)}
    />
  );
};
