import classNames from 'classnames';
import { ReactElement } from 'react';
import { ErrorIcon, HintIcon, InfoIcon, QuestionIcon, SuccessIcon, WarningIcon } from 'assets/icons';
import { ConditionalWrapper } from 'components/common/conditional-wrapper';
import { Icon, SvgComponent } from 'controls/icon/icon';
import { ForwardableTooltipProps, Tooltip, TooltipProps } from 'controls/tooltip/tooltip';
import { tw } from 'helper/css.helper';

export type StateIconVariants = 'Success' | 'Info' | 'Hint' | 'Warning' | 'Error' | 'Question' | 'Danger';

export interface StateIconProps {
  variant: StateIconVariants;
  title?: TooltipProps['title'];
  titleProps?: ForwardableTooltipProps;
  noBckgr?: boolean;
}

export type StateVariantSettings = {
  Icon: SvgComponent;
  IconColorClass: string;
  BgColorClass: string;
};

const _STATE_ICON_MAP: Record<StateIconVariants, StateVariantSettings> = {
  Success: { Icon: SuccessIcon, IconColorClass: tw`text-success-main`, BgColorClass: tw`bg-success-surface` },
  Info: { Icon: InfoIcon, IconColorClass: tw`text-primary-main`, BgColorClass: tw`bg-primary-surface` },
  Hint: { Icon: HintIcon, IconColorClass: tw`text-primary-main`, BgColorClass: tw`bg-primary-surface` },
  Question: { Icon: QuestionIcon, IconColorClass: tw`text-primary-main`, BgColorClass: tw`bg-primary-surface` },
  Warning: { Icon: WarningIcon, IconColorClass: tw`text-warning-main`, BgColorClass: tw`bg-warning-surface` },
  Danger: { Icon: WarningIcon, IconColorClass: tw`text-danger-main`, BgColorClass: tw`bg-danger-surface` },
  Error: { Icon: ErrorIcon, IconColorClass: tw`text-danger-main`, BgColorClass: tw`bg-danger-surface` },
};

export const StateIcon: React.FC<StateIconProps> = ({ variant, title, titleProps, noBckgr }) => {
  const variantMapping = _STATE_ICON_MAP[variant];

  return (
    <ConditionalWrapper
      condition={!!title}
      wrapper={(children): ReactElement => (
        <Tooltip title={title} arrow {...titleProps}>
          {children}
        </Tooltip>
      )}
    >
      <div
        className={classNames('flex h-9 w-9 shrink-0 items-center justify-center rounded-full', {
          [variantMapping.BgColorClass]: !noBckgr,
        })}
      >
        <Icon Svg={variantMapping.Icon} className={`h-6 w-6 ${variantMapping.IconColorClass}`} />
      </div>
    </ConditionalWrapper>
  );
};
