import debounce from 'lodash.debounce';
import { useEffect, useMemo, useRef, useState } from 'react';

// Source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
/**
 * Declarative usage of `setInterval`\
 * The callback doesn't interfere with the interval. It will only be recreated if the delay changes.
 * @param delay If `null`, the interval will be disabled
 */
export function useInterval<T extends Function>(callback: T, delay: number | null): void {
  const savedCallback = useRef<T>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = (): void => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return (): void => clearInterval(id);
    }
  }, [delay]);
}

type TValueChangeFn<T> = (value: T) => void;

/**
 * Call the given {@link onValueChange} function in a debounced manner,
 * whilst having a local state for immediate updates in the component.
 * Two main benefits:
 * - Keeps the control responsive as it doesn't rely on the "outside state"
 * - The outgoing {@link onValueChange} is always debounced
 *
 * @example
 * const SearchField = (value, onChange) => {
 *   const [localValue, onLocalValueChange] = useControlledDebounce(value, onChange, 300);
 *   return <Input value={localValue} onChange={onLocalValueChange} />;
 * }
 *
 * @param value controlled input prop
 * @param onValueChange function which **will be called debounced**
 * @param debounceMs The number of milliseconds to delay
 */
export const useControlledDebounce = <TValue>(
  value: TValue | undefined,
  onValueChange: TValueChangeFn<TValue> | undefined,
  debounceMs?: number
): [TValue | undefined, TValueChangeFn<TValue>] => {
  const [localValue, setLocalValue] = useState<TValue | undefined>(value);

  useEffect(
    function updateLocalValue() {
      // This is mandatory to keep the controlled behavior.
      // Otherwise it wouldn't react to "outside state changes".
      setLocalValue(value);
    },
    [value]
  );

  const debouncedValueChange = useMemo(
    () => debounce(value => onValueChange?.(value), debounceMs),
    [onValueChange, debounceMs]
  );

  const onLocalValueChange: TValueChangeFn<TValue> = newLocalValue => {
    // local state should always represent the most recent value (e.g. user types very fast)
    setLocalValue(newLocalValue);
    debouncedValueChange(newLocalValue);
  };

  return [localValue, onLocalValueChange];
};

/**
 * Function for keeping an input value `true` for a certain amount of time.
 * In the automation tech field this is called "Ausschaltverzögerung".
 * Can be used to avoid UI flashing like for load masks.
 */
export const useMinTimeActive = (rawValue: boolean, minTime: number = 500): boolean => {
  const [delayedValue, setDelayedValue] = useState(false);
  const [isInDeadTime, setIsInDeadTime] = useState(false);

  useEffect(() => {
    if (rawValue === delayedValue) {
      return;
    }

    if (rawValue) {
      setDelayedValue(rawValue);
      setIsInDeadTime(true);

      setTimeout(() => {
        setIsInDeadTime(false);
        if (!rawValue) {
          setDelayedValue(rawValue);
        }
      }, minTime);
    } else if (!isInDeadTime) {
      setDelayedValue(rawValue);
    }
  }, [delayedValue, isInDeadTime, minTime, rawValue]);

  return delayedValue;
};
