import { cloneDeep } from 'lodash';
import { Middleware } from 'redux';
import * as LocalStorageService from 'services/local-storage/local-storage.service';
import { RootState, RootStateKeys } from 'services/store/store.service';

const _VERSION_KEY = '$version';

export type PersistenceObject<T = any> = {
  [_VERSION_KEY]: number | null;
  value: T;
};

type PersistedState = { [key: string]: PersistenceObject };

/** Typing for objects which were manually parsed from LocalStorage */
export type PersistedLocalStorageState<TStateKey extends string, TObject> =
  | Record<TStateKey, PersistenceObject<TObject>>
  | undefined;

interface PersistenceDefinition<TState = unknown, TSelected = unknown> {
  state: RootStateKeys;
  /**
   * Keyname under which the data gets persisted
   */
  key: string;
  /**
   * Part of the state which gets 'observed' for changes
   */
  selector: (state: TState) => TSelected;
  /**
   * Only observe and store the values of the given properties
   */
  props?: (keyof TSelected)[];
  /**
   * Can be used to invalidate old states in `localStorage`
   * If the version differs from the `localStorage` one it is ignored during hydration
   */
  version?: number | null;
}

let _registeredCollection: PersistenceDefinition[] = [];

export function registerState<TState extends object, TSelected extends object = object>(
  definition: PersistenceDefinition<TState, TSelected>
): void {
  const isDuplicateKey =
    _registeredCollection.findIndex(r => r.state === definition.state && r.key === definition.key) !== -1;
  if (!isDuplicateKey) {
    definition.version = definition.version ?? null;
    _registeredCollection.push(definition as PersistenceDefinition);
  } else {
    if (process.env.NODE_ENV === 'development') {
      console.error(
        `Prevented registration in Persistence Middleware. Duplicate key "${definition.key}" in state "${definition.state}"`
      );
    }
  }
}

const _persistChangedStates = (rootState: RootState, previousRootState: RootState): void => {
  _registeredCollection.forEach(def => {
    const state = rootState[def.state];
    const previousState = previousRootState[def.state];

    // state AFTER the action was executed
    const selectedState = _getObservedObject(state, def) as any;
    // state BEFORE the action was executed
    const previousSelectedState = _getObservedObject(previousState, def) as any;

    // to follow the basic concept of redux and immutable data there's only a "shallow equality check"
    // the "selectedState" objects are created inside this middleware so the inner properties have to be compared
    const didChange = Object.keys(selectedState).some(key => selectedState[key] !== previousSelectedState[key]);

    if (didChange) {
      const existingState: PersistedState = JSON.parse(LocalStorageService.getItem(def.state) ?? '{}');
      const newState: PersistedState = {
        ...existingState,
        [def.key]: { $version: def.version ?? null, value: selectedState },
      };

      LocalStorageService.setItem(def.state, JSON.stringify(newState));
    }
  });
};

/**
 * Get the partial state based on the selector and reduced to the defined props
 * The returned object can't be used for shallow equality checks (only the properties inside)
 */
function _getObservedObject(
  state: RootState[RootStateKeys],
  definition: PersistenceDefinition
): Partial<RootState[RootStateKeys]> {
  const selectedState = definition.selector(state) as any;
  if (!definition.props?.length) {
    return selectedState;
  } else {
    return definition.props.reduce((acc, prop: string) => {
      return { ...acc, [prop]: selectedState[prop] };
    }, {});
  }
}

export function rehydrateState<TState = unknown>(stateName: RootStateKeys, initialState: TState): TState {
  const rehydratedState = cloneDeep(initialState);

  const persistedDataOfState: PersistedState = JSON.parse(LocalStorageService.getItem(stateName) ?? '{}');

  const definitionsOfState = _registeredCollection.filter(def => def.state === stateName);
  definitionsOfState.forEach(def => {
    const persistedData = persistedDataOfState[def.key];

    if (
      !persistedData ||
      !persistedData.value ||
      !Object.prototype.hasOwnProperty.call(persistedData, _VERSION_KEY) ||
      def.version !== persistedData.$version
    ) {
      // missing state or stale version
      return;
    }

    const selected = def.selector(rehydratedState) as any;
    Object.keys(persistedData.value).forEach(key => {
      // only restore data which actually exists in the state (no "new" properties based on the persisted data)
      const keyExistsInState = key in selected;
      if (keyExistsInState) {
        selected[key] = persistedData.value[key];
      }
    });
  });

  return rehydratedState;
}

export const persistenceMiddleware: Middleware<
  {}, // Most middleware do not modify the dispatch return value
  /* Can't be typed correctly (RootState)
   * Suggested solution: https://github.com/reduxjs/redux/issues/4267
   * - Leads to another issue: https://github.com/reduxjs/redux-toolkit/issues/2068
   * - Also type `RootStateKeys` wouldn't work anymore
   */
  any
> = store => next => action => {
  const previousState = store.getState() as RootState;
  const result = next(action);

  const state = store.getState() as RootState;
  if (state.auth.userInfo.id) {
    // Only persist the state if there is a logged in user...
    _persistChangedStates(state, previousState);
  }

  return result;
};

export const _TESTING = {
  _getRegisteredCollection: (): PersistenceDefinition[] => _registeredCollection,
  _clearRegisteredCollection: (): void => {
    _registeredCollection = [];
  },
};

export default persistenceMiddleware;
