import React from 'react';

type MetadataRowProps = {
  name: string;
  value: React.ReactNode;
};

export const MetadataRow: React.FC<MetadataRowProps> = ({ name, value }) => {
  return (
    <>
      <div>{name}:</div>
      <div>{value}</div>
    </>
  );
};
