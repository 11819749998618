import {
  UserCustomFieldInput,
  UserCustomFieldRequest,
  UserCustomFieldType,
} from 'services/http/endpoints/companies.endpoints';

export const formatCustomFieldValue = (value: UserCustomFieldInput, type: UserCustomFieldType): string => {
  if (value === undefined || value === null) {
    return '';
  }
  switch (type) {
    case 'List<Number>':
    case 'List<Text>':
      return `[${value.toString()}]`;
    default:
      return value.toString();
  }
};

export const resetFieldValuesByType = (request: UserCustomFieldRequest): void => {
  switch (request.type) {
    case 'Logic':
      request.default = false;
      request.anonymous = false;
      break;
    case 'Text':
      request.default = '';
      request.anonymous = '';
      break;
    case 'Number':
      request.default = 0;
      request.anonymous = 0;
      break;

    case 'List<Text>':
    case 'List<Number>':
      request.default = [];
      request.anonymous = [];
  }
};

export const isCustomFieldListType = (type: UserCustomFieldType): boolean =>
  type === 'List<Text>' || type === 'List<Number>';

export const convertTextToCustomFieldValue = (value: string, type: UserCustomFieldType): UserCustomFieldInput => {
  let finalValue: UserCustomFieldInput;

  if (value && isCustomFieldListType(type)) {
    // remove brackets if the user entered them
    const startIdx = value.startsWith('[') ? 1 : 0;
    const endIdx = value.endsWith(']') ? value.length - 1 : undefined;
    value = value.substring(startIdx, endIdx);

    const arrValues = value.split(',');

    if (type === 'List<Number>') {
      finalValue = arrValues.map(val => {
        const parsedNumber = parseFloat(val);
        return isNaN(parsedNumber) ? 0 : parsedNumber;
      });
    } else {
      finalValue = arrValues;
    }
  } else {
    finalValue = value;
  }
  return finalValue;
};
