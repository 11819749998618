import { useCallback, useEffect, useState } from 'react';
import { SchemaNameInput, SchemaNameInputResult } from 'components/common/schema-name-input';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { postConfiguratorsCreateViewerOnlyCfgr } from 'services/cfgrs/cfgrs.service';
import { BabylonAsset, selectSelectedAsset } from 'slices/assets/assets.slice';
import { getCompanyCfgrs, selectAllCompanyCfgrs, selectSelectedCompany } from 'slices/company-data/company-data.slice';

type CreateViewerOnlyCfgrModalProps = {
  // new cfgrName is needed for navigation after successful cfgr creation
  onClose: (cfgrName: string) => void;
};

export const CreateViewerOnlyCfgrModal: React.FC<CreateViewerOnlyCfgrModalProps> = ({ onClose }) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const selectedAsset = useAppSelector(selectSelectedAsset) as BabylonAsset;
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const cfgrs = useAppSelector(selectAllCompanyCfgrs);

  const [input, setInput] = useState<SchemaNameInputResult>({ uniqueId: '', displayName: selectedAsset.path.assetId });
  const [isProcessing, setIsProcessing] = useState(false);
  const [cfgrsFetched, setCfgrsFetched] = useState(false);

  useEffect(() => {
    // fetch company data so that it available cfgr names are up to date
    if (selectedCompany) {
      const fetchCfgrs = async (): Promise<void> => {
        await dispatch(getCompanyCfgrs({ companyId: selectedCompany?.id }));
        setCfgrsFetched(true);
      };
      fetchCfgrs();
    }
  }, [dispatch, selectedCompany]);

  const onSchemaInputChange = useCallback((result: SchemaNameInputResult): void => {
    setInput(result);
  }, []);

  const onConfirm = async (): Promise<void> => {
    setIsProcessing(true);

    await postConfiguratorsCreateViewerOnlyCfgr(selectedAsset.path, input.uniqueId, input.displayName);
    // update store, since a new configurator has been created
    await dispatch(getCompanyCfgrs({ companyId: selectedAsset.path.companyId }));

    setIsProcessing(false);
    onClose(input.uniqueId);
  };

  return (
    <ModalDialog
      data-cmptype="CreateViewerOnlyCfgrModal"
      variant="NoIcon"
      header={t('Create 3D/AR Viewer')}
      actions={{ onConfirm: () => onConfirm(), onCancel: () => onClose('') }}
      confirmText={t('Create viewer')}
      confirmDisabled={input.hasErrors}
      isProcessingConfirm={isProcessing}
    >
      <div className="flex grow flex-col gap-4">
        <SchemaNameInput
          isPreloading={!cfgrsFetched}
          input={input}
          onChange={onSchemaInputChange}
          unavailableIds={cfgrs.map(c => c.id)}
          unavailableNames={cfgrs.map(c => c.displayName)}
          startWithValidNameSuggestion
        />
      </div>
    </ModalDialog>
  );
};
