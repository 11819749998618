import classNames from 'classnames';
import React from 'react';
import { ArrowCircleLeftIcon, ArrowCircleRightIcon } from 'assets/icons';
import { Icon } from 'controls/icon/icon';

type GlobalErrorDialogNavProps = {
  currentItemIdx: number;
  itemCount: number;
  onPreviousClick: () => void;
  onNextClick: () => void;
  onDismissAll: () => void;
};

export const GlobalErrorDialogNav: React.FC<GlobalErrorDialogNavProps> = ({
  currentItemIdx,
  itemCount,
  onPreviousClick,
  onNextClick,
  onDismissAll,
}) => {
  return (
    <nav data-cmptype="GlobalErrorDialogNav" className="mx-2 flex items-center text-neutral-10">
      <button type="button" onClick={onPreviousClick}>
        <Icon
          Svg={ArrowCircleLeftIcon}
          className={classNames('h-[28px] w-[28px]', {
            'cursor-default text-neutral-60': currentItemIdx <= 1,
          })}
        />
      </button>
      <button type="button" onClick={onNextClick}>
        <Icon
          Svg={ArrowCircleRightIcon}
          className={classNames('h-[28px] w-[28px]', {
            'cursor-default text-neutral-60': currentItemIdx >= itemCount,
          })}
        />
      </button>
      <button
        type="button"
        className="ml-4 h-[24px] rounded-full border-2 px-2 text-m-regular"
        onClick={(): void => onDismissAll()}
      >
        Dismiss all
      </button>
      <div className="ml-auto">
        {currentItemIdx}/{itemCount}
      </div>
    </nav>
  );
};
