import { TableRow } from '@mui/material';
import { ReplicatorProgressTableRow } from 'components/replicator/replicator-progress-table-row';
import { StateIcon } from 'controls/icon/state-icon';
import { Table, TableBody, TableCell, TableHead } from 'controls/table';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';
import { selectReplicatorNotifications } from 'slices/notifications/notifications.slice';

export const ReplicatorProgress: React.FC = () => {
  const { t } = useTypedTranslation();
  const companyId = useAppSelector(selectSelectedCompany)?.id;

  const replicatorNotifications = useAppSelector(selectReplicatorNotifications);
  const sortedReplicatorNotifications = replicatorNotifications
    .filter(n => n.sourceCompany === companyId || n.isCrossEnv)
    .sort((a, b) => b.timestamp - a.timestamp);

  if (!sortedReplicatorNotifications.length) {
    return (
      <div className="flex items-center border-t-[1px] border-neutral-40 p-6 text-m-regular">
        <StateIcon variant="Success" noBckgr />
        {t('No active or completed copy processes were found for your current session.')}
      </div>
    );
  }

  return (
    <div data-cmptype="ReplicatorProgress">
      <p className="m-6">
        {t("You don't need to stay on this page.")}{' '}
        <span className="text-l-medium">{t('You will be notified as soon as the copy process is completed.')}</span>
      </p>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Target company')}</TableCell>
            <TableCell>{t('Started')}</TableCell>
            <TableCell>{t('Configurators')}</TableCell>
            <TableCell>{t('Asset bundles')}</TableCell>
            <TableCell>{t('Status copy')}</TableCell>
            <TableCell>{t('Status publish')}</TableCell>
            <TableCell className="w-0">{/* Actions */}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedReplicatorNotifications.map(n => (
            <ReplicatorProgressTableRow key={n.uid} notification={n} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
