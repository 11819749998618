import {
  CompaniesResponse,
  companiesResponseToCompanies,
  companiesResponseToPermissions,
} from '../company-data/company-data.service';
import { ActionResult, RequestConfig, isGenericActionResult } from '../http/http.service';
import { HttpStatusCode } from 'helper/http/http-status.helper';
import * as HttpService from 'services/http/http.service';
import { UserInfo } from 'slices/auth/auth.slice';
import { Company } from 'slices/company-data/company-data.slice';
import { CompanyPermission } from 'slices/permission/permission.slice';

export type ValidLoginDto = UserInfo & {
  isCompanyJoinSuccessful?: boolean;
  companyAssignments: CompaniesResponse;
};

export const loginFailedResponses = [
  'LoginFailed',
  'Deactivated',
  'UserMarkedForDeletion',
  'CompanyCreationFailed',
] as const;
export type LoginFailedResponse = (typeof loginFailedResponses)[number];

export const registerResponses = [
  'Success',
  'InvalidPassword',
  'RegistrationFailed',
  'MailAddressAlreadyTaken',
  'MailAddressBlacklisted',
  'MailAddressInvalid',
  'CompanyNameNotAvailable',
  'CompanyCreationFailed',
] as const;
export type RegisterResponse = (typeof registerResponses)[number];

const activateResponses = [
  'Success',
  'TokenConsumed',
  'TokenRevoked',
  'TokenNoLongerValid',
  'TokenNotFound',
  'UnknownResponse',
] as const;
export type ActivateResponse = (typeof activateResponses)[number];

const newPasswordResponses = [
  'Success',
  'InvalidPassword',
  'TokenConsumed',
  'TokenRevoked',
  'TokenNoLongerValid',
  'TokenNotFound',
  'UnknownResponse',
] as const;
export type NewPasswordResponse = (typeof newPasswordResponses)[number];

const updatePasswordResponses = ['Success', 'OldPasswordNotValid', 'InvalidPassword', 'UnknownResponse'] as const;
export type UpdatePasswordReponse = (typeof updatePasswordResponses)[number];

type UserInfoResponse = UserInfo;

/**
 * Converts user infor object from "raw wire format" to "state format"
 */
export function loginResponseToUserInfo(resp: ValidLoginDto): UserInfo {
  return {
    id: resp.id,
    firstname: resp.firstname ?? '',
    lastname: resp.lastname ?? '',
    email: resp.email,
    isCbnAdmin: resp.isCbnAdmin,
    isSuperAdmin: resp.isSuperAdmin,
    state: resp.state,
  };
}

export type LoginResult = {
  userInfo: UserInfo;
  companies: Company[];
  permissions: CompanyPermission;
  isCompanyJoinSuccessful?: boolean;
};

export async function login(
  email: string,
  password: string,
  inviteData?: { companyName: string; inviteToken: string }
): Promise<LoginResult | ActionResult<LoginFailedResponse>> {
  const { companyName, inviteToken } = inviteData ?? {};
  const { url, params } =
    companyName && inviteToken
      ? HttpService.endpoints.accountPostLoginAndJoinCompany(email, password, companyName, inviteToken)
      : HttpService.endpoints.accountLogin(email, password);

  const loginResp = await HttpService.httpClient.post<ValidLoginDto | ActionResult<LoginFailedResponse>>(url, params, {
    // Don't throw on 401 as we're handling those errors in the `isGenericActionResult` case down below.
    // This overrules the general axios validation, so the default check for status 2xx is required too.
    validateStatus: status => status === HttpStatusCode.Unauthorized_401 || (status >= 200 && status < 300),
  });

  // TODO: Ensure that not only form (types etc.) but also content of received data is correct (e.g. no empty strings
  //       for `id`, `email` etc.)
  if (isGenericActionResult(loginResp.data, loginFailedResponses)) {
    return loginResp.data;
  } else if (loginResp.status !== HttpStatusCode.Ok_200) {
    throw new Error('Unexpected response status');
  } else {
    const userInfo = loginResponseToUserInfo(loginResp.data);
    const companies = companiesResponseToCompanies(loginResp.data.companyAssignments);
    const permissions = companiesResponseToPermissions(loginResp.data.companyAssignments);
    return {
      userInfo,
      companies,
      permissions,
      ...(inviteData && { isCompanyJoinSuccessful: loginResp.data.isCompanyJoinSuccessful }),
    };
  }
}

export async function logout(): Promise<void> {
  const config: RequestConfig = {
    headers: { 'Content-Type': 'application/json' },
    isLogoutRequest: true,
  };
  await HttpService.httpClient.post(HttpService.endpoints.accountLogout, null, config);
}

export async function postAccountLostPassword(email: string): Promise<boolean> {
  const { url, params } = HttpService.endpoints.accountLostPassword(email);
  const lostPasswordResp = await HttpService.httpClient.post(url, params);
  if (lostPasswordResp.status !== HttpStatusCode.Ok_200) {
    return false;
  }
  return true;
}

export async function putAccountRegister(
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  companyName: string,
  companyDisplayName: string,
  inviteToken?: string
): Promise<LoginResult | ActionResult<RegisterResponse>> {
  const { url, params } = inviteToken
    ? HttpService.endpoints.accountPutRegisterAndJoinCompany(
        email,
        password,
        firstName,
        lastName,
        companyName,
        inviteToken
      )
    : HttpService.endpoints.accountPutRegisterAndCreateCompany(
        email,
        password,
        firstName,
        lastName,
        companyName,
        companyDisplayName
      );
  const registerResp = await HttpService.httpClient.put<ValidLoginDto | ActionResult<RegisterResponse>>(url, params, {
    validateStatus: () => true,
  });

  if (isGenericActionResult(registerResp.data, registerResponses)) {
    return registerResp.data;
  } else if (registerResp.status !== HttpStatusCode.Ok_200) {
    throw new Error('Unexpected response status');
  } else {
    const userInfo = loginResponseToUserInfo(registerResp.data);
    const companies = companiesResponseToCompanies(registerResp.data.companyAssignments);
    const permissions = companiesResponseToPermissions(registerResp.data.companyAssignments);
    return {
      userInfo,
      companies,
      permissions,
      ...(inviteToken && { isCompanyJoinSuccessful: registerResp.data.isCompanyJoinSuccessful }),
    };
  }
}

export async function postAccountCreateNewPassword(
  email: string,
  newPassword: string,
  newPasswordToken: string
): Promise<NewPasswordResponse> {
  const { url, params } = HttpService.endpoints.accountCreateNewPassword(email, newPassword, newPasswordToken);

  const createPasswordResp = await HttpService.httpClient.post<ActionResult>(url, params, {
    validateStatus: () => true,
  });
  if (createPasswordResp.status === HttpStatusCode.Ok_200) {
    return 'Success';
  }

  return isGenericActionResult(createPasswordResp.data, newPasswordResponses)
    ? createPasswordResp.data.Id
    : 'UnknownResponse';
}

export async function postAccountUpdatePassword(
  oldPassword: string,
  newPassword: string
): Promise<UpdatePasswordReponse> {
  const { url, params } = HttpService.endpoints.accountUpdatePassword(oldPassword, newPassword);

  const updatePasswordResp = await HttpService.httpClient.post(url, params, { validateStatus: () => true });
  if (updatePasswordResp.status === HttpStatusCode.Ok_200) {
    return 'Success';
  }

  return isGenericActionResult(updatePasswordResp.data, updatePasswordResponses)
    ? updatePasswordResp.data.Id
    : 'UnknownResponse';
}

export async function putUpdateUserInfo(firstname: string, lastname: string): Promise<void> {
  const { url, params } = HttpService.endpoints.updateUserInfo(firstname, lastname);

  await HttpService.httpClient.put(url, params);
}

export async function fetchUserInfo(): Promise<UserInfo> {
  const res = await HttpService.httpClient.get<UserInfoResponse>(HttpService.endpoints.getUserInfo);

  // currently no adidtional conversion to user info
  const userInfo = res.data as UserInfo;
  return userInfo;
}

export async function postAccountResendActivation(): Promise<void> {
  await HttpService.httpClient.post(HttpService.endpoints.accountResendActivation);
}

export async function accountActivate(token: string): Promise<ActivateResponse> {
  const { url, params } = HttpService.endpoints.accountActivate(token);
  const res = await HttpService.httpClient.get<ActivateResponse>(url, { params, validateStatus: () => true });

  if (res.status === HttpStatusCode.Ok_200) {
    return 'Success';
  }

  return isGenericActionResult(res.data, activateResponses) ? res.data.Id : 'UnknownResponse';
}
