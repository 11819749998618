import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useRef, useState } from 'react';
import { PlusIcon } from 'assets/icons';
import {
  CfgnInsightsFilterInputProps,
  CfgnInsightsNumberRangeQueryValue,
  NoSelectionChipValue,
} from 'components/cfgrs/cfgn-insights/cfgn-insights-filter-input';
import { Button } from 'controls/button/button';
import { CbnDateRangePickerPopover } from 'controls/cbn-date-range-picker-popover/cbn-date-range-picker-popover';
import { ChipValue, LargeFontChip } from 'controls/chip/chip';
import { getDayjsRangeFromPastDays, getRelativeDate } from 'helper/date-and-time/date-and-time.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

const _YESTERDAY_RANGE = getDayjsRangeFromPastDays(1);
const _LAST_7_DAYS_RANGE = getDayjsRangeFromPastDays(7);
const _LAST_30_DAYS_RANGE = getDayjsRangeFromPastDays(30);

const _DEFAULT_QUICK_OPTIONS: { label: string; start: number; end: number }[] = [
  {
    label: 'Yesterday',
    start: _YESTERDAY_RANGE[0]!.valueOf(),
    end: _YESTERDAY_RANGE[1]!.valueOf(),
  },
  {
    label: 'Last 7 days',
    start: _LAST_7_DAYS_RANGE[0]!.valueOf(),
    end: _LAST_7_DAYS_RANGE[1]!.valueOf(),
  },
  {
    label: 'Last 30 days',
    start: _LAST_30_DAYS_RANGE[0]!.valueOf(),
    end: _LAST_30_DAYS_RANGE[1]!.valueOf(),
  },
];

/**
 * The resulting array respresents `[min, max]` which will be queried like `min <= x <= max`. Empty means "not set".
 * See docs of `CfgnInsightsNumberRangeQueryValue` for more details.
 */
export const DateFilterInput: React.FC<CfgnInsightsFilterInputProps<CfgnInsightsNumberRangeQueryValue>> = ({
  value,
  onCommit,
}) => {
  const { t } = useTypedTranslation();

  const [calenderShown, setCalenderShown] = useState(false);
  const showCalenderBtnRef = useRef<HTMLDivElement>(null);
  const [quickOptions, setQuickOptions] = useState(_DEFAULT_QUICK_OPTIONS);

  const onChipClicked = (x: ChipValue): void => {
    const clickedValue = _valueStringToDateRange(x as string);
    const startDateMatches = value[0] === clickedValue[0];
    const endDateMatches = value[1] === clickedValue[1];
    const selectedChipClicked = startDateMatches && endDateMatches;

    const newValue =
      x === NoSelectionChipValue || selectedChipClicked ? ([] as CfgnInsightsNumberRangeQueryValue) : clickedValue;

    onCommit(newValue);
  };

  const commitRange = (newValue: DateRange<Dayjs>): void => {
    setCalenderShown(false);
    const [minValueDayJS, maxValueDayJS] = newValue;
    const minValue = minValueDayJS?.valueOf();
    const maxValue = maxValueDayJS?.valueOf();
    onCommit([minValue, maxValue]);

    const quickOptionAlreadyExists = quickOptions.some(x => x.start === minValue && x.end === maxValue);
    if (!quickOptionAlreadyExists) {
      const startFormatted = getRelativeDate(Math.floor(minValue! / 1000));
      const endFormatted = getRelativeDate(Math.floor(maxValue! / 1000));

      setQuickOptions([
        ...quickOptions,
        {
          label: `${startFormatted} - ${endFormatted}`,
          start: minValue!,
          end: maxValue!,
        },
      ]);
    }
  };

  return (
    <div className="flex flex-wrap items-center gap-3">
      <CbnDateRangePickerPopover
        open={calenderShown}
        onClose={(): void => setCalenderShown(false)}
        onSubmit={commitRange}
        anchorEl={showCalenderBtnRef.current}
      />

      <LargeFontChip
        value={NoSelectionChipValue}
        text={t('Any')}
        variant="Primary"
        selected={!value.length}
        onClick={onChipClicked}
      />
      <span className="self-stretch border-r border-neutral-50" />

      <div ref={showCalenderBtnRef}>
        <Button
          variant="ChipMenu"
          text={t('Select range...')}
          Svg={PlusIcon}
          onClick={(): void => setCalenderShown(x => !x)}
        />
      </div>

      {quickOptions.map(x => {
        const valueStr = _dateRangeToValueString(x.start, x.end);
        const isSelectedChip = value[0] === x.start && value[1] === x.end;

        return (
          <LargeFontChip
            key={valueStr}
            value={valueStr}
            text={x.label}
            variant="Primary"
            selected={isSelectedChip}
            onClick={onChipClicked}
          />
        );
      })}
    </div>
  );
};

function _dateRangeToValueString(start: number, end: number): string {
  return `${start}-${end}`;
}

function _valueStringToDateRange(value: string): CfgnInsightsNumberRangeQueryValue {
  const [start, end] = value.split('-');
  return [parseInt(start), parseInt(end)];
}
