import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { globalCompanySwitch, globalReset } from 'actions/general.actions';
import { CfgnInsightsBrowsers } from 'generated/cfgn-insights-browsers';
import { CfgnInsightsType } from 'generated/cfgn-insights-types';
import { FilterItemValueType } from 'generated/filter-item-value-type';
import { ShopHiveTypes } from 'generated/shop-hive-types';
import { AllInsightsProperties, AllQueryableInsightsProperties, QueryValues } from 'helper/cfgn-insights.helper';
import { FetchedState } from 'helper/route/route-sync.helper';
import {
  CfgnDetails,
  CfgnInsight,
  CfgnInsightValues,
  GetCfgnInsightResponse,
  getCfgn,
  getCfgnInsights,
  getCfgnInsightsValues,
} from 'services/cfgn/cfgn.service';
import {
  Identities,
  UserIdentity,
  getIdentityGetRelevantForCompany,
  isUserIdentity,
} from 'services/identity/identity.service';
import * as PersistenceMiddleware from 'services/store/persistence.middleware';
import { RootState } from 'services/store/store.service';
import {
  BUILT_IN_INSIGHTS_PROPERTIES_ARRAY,
  CfgnInsightPropertyDefinition,
  getFullInsightsValuePropertyName,
  isQueryableBuiltInInsightsProperty,
  sortInsightsPropertiesByGroup,
} from 'slices/cfgn-insisghts/property-definitions';

export const DEFAULT_PAGE_SIZE = 50;
export const LOAD_MORE_PAGE_SIZES = [25, 50, 100];

/** The values shall be used with i18n fns like `t(CfgnTypeDisplayNames[x].displayName)` */
export const CfgnTypeDisplayNames: Record<CfgnInsightsType, { value: CfgnInsightsType; displayName: string }> = {
  [CfgnInsightsType.Configuration]: { value: CfgnInsightsType.Configuration, displayName: 'Configuration' },
  [CfgnInsightsType.Finish]: { value: CfgnInsightsType.Finish, displayName: 'Finish' },
  [CfgnInsightsType.Shared]: { value: CfgnInsightsType.Shared, displayName: 'Shared' },
};

/** The values shall be used with i18n fns like `t(ShopTypeDisplayNames[x].displayName)` */
export const ShopTypeDisplayNames: Record<ShopHiveTypes, { value: ShopHiveTypes; displayName: string }> = {
  [ShopHiveTypes.DiyShop]: { value: ShopHiveTypes.DiyShop, displayName: 'Flex' },
  [ShopHiveTypes.FtpShop]: { value: ShopHiveTypes.FtpShop, displayName: 'FTP' },
  [ShopHiveTypes.MailShop]: { value: ShopHiveTypes.MailShop, displayName: 'Mail' },
  [ShopHiveTypes.RestShop]: { value: ShopHiveTypes.RestShop, displayName: 'REST' },
  [ShopHiveTypes.ShopifyShop]: { value: ShopHiveTypes.ShopifyShop, displayName: 'Shopify' },
  [ShopHiveTypes.Shopware6Shop]: { value: ShopHiveTypes.Shopware6Shop, displayName: 'Shopware 6' },
  [ShopHiveTypes.ShopwareShop]: { value: ShopHiveTypes.ShopwareShop, displayName: 'Shopware 5' },
  [ShopHiveTypes.WooCommerceShop]: { value: ShopHiveTypes.WooCommerceShop, displayName: 'WooCommerce' },
};

/** The values shall be used with i18n fns like `t(BrowserDisplayNames[x].displayName)` */
export const BrowserDisplayNames: Record<CfgnInsightsBrowsers, { value: CfgnInsightsBrowsers; displayName: string }> = {
  [CfgnInsightsBrowsers.CHROME]: { value: CfgnInsightsBrowsers.CHROME, displayName: 'Chrome' },
  [CfgnInsightsBrowsers.FIREFOX]: { value: CfgnInsightsBrowsers.FIREFOX, displayName: 'Firefox' },
  [CfgnInsightsBrowsers.SAFARI]: { value: CfgnInsightsBrowsers.SAFARI, displayName: 'Safari' },
  [CfgnInsightsBrowsers.EDGE]: { value: CfgnInsightsBrowsers.EDGE, displayName: 'Edge' },
  [CfgnInsightsBrowsers.BOT]: { value: CfgnInsightsBrowsers.BOT, displayName: 'Bot' },
  [CfgnInsightsBrowsers.OPERA]: { value: CfgnInsightsBrowsers.OPERA, displayName: 'Opera' },
  [CfgnInsightsBrowsers.INSTAGRAM]: { value: CfgnInsightsBrowsers.INSTAGRAM, displayName: 'Instagram' },
  [CfgnInsightsBrowsers.FACEBOOK]: { value: CfgnInsightsBrowsers.FACEBOOK, displayName: 'Facebook' },
  [CfgnInsightsBrowsers.TIKTOK]: { value: CfgnInsightsBrowsers.TIKTOK, displayName: 'TikTok' },
  [CfgnInsightsBrowsers.UNKNOWN]: { value: CfgnInsightsBrowsers.UNKNOWN, displayName: 'Unknown' },
};

export type CfgnInsightsState = {
  companyId?: string;
  cfgrId?: string;
  stageName?: string;
  queryValues: QueryValues;

  /**
   * Distinct values for a property are used to fill the "options popover" in the insights filter UI, so that the user
   * can only filter values for which actual configurations exist. E.g. don't let him filter "checkout type = Mail" when
   * we already know upfront that no configuration will match that query.
   *
   * Distinct values are fetched on demand when the user opens the popover or when display texts from the distinct
   * values are required for showing the correct display text in the "quick options" (the chips next to the
   * "+ Select ..." button).
   *
   * ATM we're only using the `distinctValues` for the `createdBy` property as we have no API for other properties yet.
   * See `getInsightsUsers` for some more details.
   */
  distinctValues: {
    /** The `propertyName` is one of `AllQueryableInsightsProperties` */
    [propertyName: string]: {
      state: FetchedState;
      values: string[];
    };
  };

  /**
   * Information about existing insight values properties including their type which is used to build the correct filter
   * UI, format values in cfgns table etc.
   *
   * Those definitions are automatically fetched with the first batch of insights data, i.e. with the
   * `getCfgrCfgnInsights` async thunk.
   */
  insightsValuesDefinition: CfgnInsightValues;

  state: FetchedState;
  insights: CfgnInsight[];
  /** Total number of cfgns which match the current query */
  totalCount: number;
  continuationToken?: string;

  /** Detail data for a given cfgn. Fetched on demand when details view is shown. */
  cfgnDetails: {
    loadingState: FetchedState;
    loadingCfgnId?: string;
    data?: CfgnDetails;
  };

  viewSettings: {
    /** One of `LOAD_MORE_PAGE_SIZES` */
    pageSize: number;

    /**
     * Unordered list of columns to be shown in the insights table. Order is defined by `BUILT_IN_INSIGHTS_PROPERTIES`.
     * Includes all statically known properties from `AllInsightsProperties` + the available insight values which can
     * differ for every cfgr.
     */
    shownColumns: string[];
  };
};

/**
 * See `BUILT_IN_INSIGHTS_PROPERTIES[x].queryType` + `BUILT_IN_INSIGHTS_PROPERTIES[x].valueType` for information on how
 * the individual values are interpreted, what data they can actually hold and how they are then translated to queries
 * for the server.
 */
export const emptyQueryValues: QueryValues = {
  builtIn: {
    'id': [],
    'createdAt': [],
    'createdBy': [],
    'type': [],
    'cfgrVersion': [],
    'presetId': [],
    'basedOnCfgnId': [],
    'metadata.country': [],
    'metadata.city': [],
    'metadata.browser': [],
    'metadata.isMobile': [],
    'checkoutData.name': [],
    'checkoutData.type': [],
    'checkoutData.price': [],
  },
  insightsValues: {},
};

const defaultState: CfgnInsightsState = {
  companyId: undefined,
  cfgrId: undefined,
  stageName: undefined,
  queryValues: emptyQueryValues,
  distinctValues: {},
  insightsValuesDefinition: {},
  state: 'None',
  insights: [],
  totalCount: 0,
  continuationToken: undefined,

  cfgnDetails: {
    loadingState: 'None',
  },

  viewSettings: {
    pageSize: DEFAULT_PAGE_SIZE,

    /**
     * Use `selectShownColumns` instead of directly accessing this property as that also returns a set of default
     * columns when this is not set (i.e. when the user made no explicit choice yet).
     */
    shownColumns: [],
  },
};

const SLICE_NAME = 'cfgnInsights';

// TODO: Remove LS persistence once we implement the "quick views" which will be persisted on the server and will also
//       contain the shown columns etc.
//       `pageSize` could maybe stay in local storage, not sure yet, maybe we don't want this persisted at all?
PersistenceMiddleware.registerState<CfgnInsightsState, CfgnInsightsState['viewSettings']>({
  state: SLICE_NAME,
  key: 'viewSettings',
  selector: state => state.viewSettings,
});

function _getRehydratedState(): CfgnInsightsState {
  const rehydratedState = PersistenceMiddleware.rehydrateState<CfgnInsightsState>(SLICE_NAME, defaultState);
  return rehydratedState;
}
const initialState = _getRehydratedState();

function _dataMatchesState(
  state: CfgnInsightsState,
  companyId: string,
  cfgrId: string,
  stageName: string | undefined,
  queryValues?: QueryValues
): boolean {
  return (
    state.companyId === companyId &&
    state.cfgrId === cfgrId &&
    state.stageName === stageName &&
    (!queryValues || isEqual(state.queryValues, queryValues))
  );
}

/**
 * Get initial set of data for a given cfgr with a given query.
 */
export const getCfgrCfgnInsights = createAsyncThunk<
  { insights: GetCfgnInsightResponse; insightsValuesDefinition: CfgnInsightValues } | undefined,
  {
    companyId: string;
    cfgrId: string;
    stageName?: string;
    queryValues?: QueryValues;
  }
>(
  `${SLICE_NAME}/getCfgrCfgnInsights`,
  async ({ companyId, cfgrId, stageName, queryValues = emptyQueryValues }, thunkApi) => {
    const { cfgnInsights: stateBefore } = thunkApi.getState() as RootState;

    const fetchParamsHaveChanged = !_dataMatchesState(stateBefore, companyId, cfgrId, stageName, queryValues);
    if (stateBefore.state === 'Reset' || fetchParamsHaveChanged) {
      thunkApi.dispatch(startFetching({ companyId, cfgrId, stageName, queryValues }));

      const propertyDefinitions = _getAllCfgnInsightPropertyDefinitions(stateBefore);
      const cfgnInsightsPromise = getCfgnInsights(
        companyId,
        cfgrId,
        propertyDefinitions,
        stageName,
        queryValues,
        stateBefore.viewSettings.pageSize
      );

      const needsInsightsValuesRefetch = !_dataMatchesState(stateBefore, companyId, cfgrId, stageName);
      const cfgnInsightsValuesPromise = needsInsightsValuesRefetch
        ? getCfgnInsightsValues(companyId, cfgrId, stageName)
        : Promise.resolve(stateBefore.insightsValuesDefinition);

      const [insights, insightsValuesDefinition] = await Promise.all([cfgnInsightsPromise, cfgnInsightsValuesPromise]);

      // Stale data shall be ignored by the fullfilled reducer
      const { cfgnInsights: stateAfter } = thunkApi.getState() as RootState;
      const isStaleResult = !_dataMatchesState(stateAfter, companyId, cfgrId, stageName, queryValues);
      return isStaleResult ? undefined : { insights, insightsValuesDefinition };
    }
  }
);

/**
 * Load more data for a given cfgr using the current query in the state.
 * Can only be used when we already have the first set of data loaded with `getCfgrCfgnInsights`, which can be checked
 * by `selectHasMoreData`.
 */
export const loadMoreCfgrCfgnInsights = createAsyncThunk<GetCfgnInsightResponse | undefined, {}>(
  `${SLICE_NAME}/loadMoreCfgrCfgnInsights`,
  async (_, thunkApi) => {
    const { cfgnInsights: stateBefore } = thunkApi.getState() as RootState;

    const { companyId, cfgrId, stageName, queryValues, continuationToken, viewSettings } = stateBefore;

    // Non of the following 2 cases should ever happen. At the time of writing we only have one button which calls this
    // thunk and it should be disabled when there is no continuation token or when we're already loading data.
    //
    // !!! Also adjust behavior of `loadMoreCfgrCfgnInsights.rejected` when changing any logic here !!!
    if (!companyId || !cfgrId || !continuationToken) {
      throw new Error('Cannot load more data as there is no selected configurator or no continuation token.');
    } else if (stateBefore.state !== 'Fetched') {
      throw new Error("Cannot load more data as we're not in idle state (e.g. already loading data).");
    }

    thunkApi.dispatch(setStatePending());

    const propertyDefinitions = _getAllCfgnInsightPropertyDefinitions(stateBefore);
    const resp = await getCfgnInsights(
      companyId,
      cfgrId,
      propertyDefinitions,
      stageName,
      queryValues,
      viewSettings.pageSize,
      continuationToken
    );

    // Stale data shall be ignored by the fullfilled reducer
    const { cfgnInsights: stateAfter } = thunkApi.getState() as RootState;
    const isStaleResult = !_dataMatchesState(stateAfter, companyId, cfgrId, stageName, queryValues);
    return isStaleResult ? undefined : resp;
  }
);

/**
 * Get detail data for a given cfgn
 */
export const getCfgnDetails = createAsyncThunk<
  CfgnDetails | undefined,
  {
    companyId: string;
    cfgrId: string;
    stageName?: string;
    cfgnId: string;
  }
>(`${SLICE_NAME}/getCfgnDetails`, async ({ companyId, cfgrId, stageName, cfgnId }, thunkApi) => {
  const { cfgnInsights: stateBefore } = thunkApi.getState() as RootState;

  const fetchParamsHaveChanged = stateBefore.cfgnDetails.loadingCfgnId !== cfgnId;
  if (stateBefore.cfgnDetails.loadingState === 'Reset' || fetchParamsHaveChanged) {
    thunkApi.dispatch(startFetchingCfgnDetails({ companyId, cfgnId }));

    const resp = await getCfgn(companyId, cfgrId, cfgnId, stageName);

    // Stale data shall be ignored by the fullfilled reducer
    const { cfgnInsights: stateAfter } = thunkApi.getState() as RootState;
    const isStaleResult = stateAfter.cfgnDetails.loadingCfgnId !== cfgnId;
    return isStaleResult ? undefined : resp;
  }
});

/**
 * Query all users which can have insights for a given configurator.
 * ATM we're using the `getIdentityGetRelevantForCompany` for this. Later there will be some dedicated, generic endpoint
 * which works for all insights properties (codename "distinct values").
 *
 * Possible issue with the current approach:
 *
 * If a user is removed from a company, he'll not be returned by `getIdentityGetRelevantForCompany` anymore and
 * therefore won't be shown in the filters UI etc.
 * This will be solved with the final "distinct values" approach which is why we accept this potential bug for now.
 *
 * To consider when implementing the final "distinct values" approach:
 * What name shall we show then in the cfgns table? The table is not meant to use data from the "distinct values" store
 * but uses our `IdentityUser` cmp which probably also won't be able to show the user anymore when he's removed from the
 * company.
 */
export const getInsightsUsers = createAsyncThunk<Identities | undefined, { companyId: string }>(
  `${SLICE_NAME}/getInsightsUsers`,
  async ({ companyId }, thunkApi) => {
    const { cfgnInsights: state } = thunkApi.getState() as RootState;
    const distinctValues = state.distinctValues.createdBy;

    // Only fetch users for a company once...
    if (
      state.companyId !== companyId ||
      !distinctValues ||
      distinctValues.state === 'None' ||
      distinctValues.state === 'Reset'
    ) {
      thunkApi.dispatch(setDistinctValuesStatePending({ propertyName: 'createdBy' }));
      thunkApi.dispatch(setCompanyId({ companyId }));
      const resp = await getIdentityGetRelevantForCompany(companyId);
      return resp;
    }
  }
);

const cfgnInsightsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetCfgnInsights: () => initialState,

    setCompanyId: (state, { payload }: PayloadAction<{ companyId: string }>) => {
      state.companyId = payload.companyId;
    },

    startFetching: (
      state,
      { payload }: PayloadAction<{ companyId: string; cfgrId: string; stageName?: string; queryValues?: QueryValues }>
    ) => {
      state.companyId = payload.companyId;
      state.cfgrId = payload.cfgrId;
      state.stageName = payload.stageName;
      state.queryValues = payload.queryValues ?? initialState.queryValues;
      state.insights = [];
      state.totalCount = 0;
      state.continuationToken = undefined;
      state.state = 'Pending';
    },

    startFetchingCfgnDetails: (state, { payload }: PayloadAction<{ companyId: string; cfgnId: string }>) => {
      state.cfgnDetails.loadingState = 'Pending';
      state.cfgnDetails.loadingCfgnId = payload.cfgnId;
      delete state.cfgnDetails.data;
    },

    setStatePending: state => {
      state.state = 'Pending';
    },

    setDistinctValuesStatePending: (state, { payload }: PayloadAction<{ propertyName: string }>) => {
      const { propertyName } = payload;
      if (!state.distinctValues[propertyName]) {
        state.distinctValues[propertyName] = { state: 'Pending', values: [] };
      } else {
        state.distinctValues[propertyName].state = 'Pending';
      }
    },

    setPageSize: (state, { payload }: PayloadAction<{ pageSize: number }>) => {
      state.viewSettings.pageSize = payload.pageSize;
    },

    setShownColumns: (state, { payload }: PayloadAction<{ shownColumns: AllInsightsProperties[] }>) => {
      state.viewSettings.shownColumns = payload.shownColumns;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(globalReset, () => initialState)
      .addCase(globalCompanySwitch, () => initialState)

      .addCase(getInsightsUsers.fulfilled, (state, { payload, meta }) => {
        const userIdentities = Object.entries(payload ?? {}).filter(([_, identity]) => isUserIdentity(identity)) as [
          string,
          UserIdentity,
        ][];

        const values = userIdentities.map(([identityId, identity]) => identityId);
        state.distinctValues.createdBy = { state: 'Fetched', values };
      })
      .addCase(getInsightsUsers.rejected, (state, { payload, meta }) => {
        state.distinctValues.createdBy = { state: 'Reset', values: [] };
      })

      .addCase(getCfgrCfgnInsights.fulfilled, (state, { payload, meta }) => {
        // `getCfgrCfgnInsights` returns `undefined` when it ignores the call e.g. because the data is already fetched
        // or fetching or when the received data is stale.
        if (payload === undefined) return;

        // Ensure that `queryValues.insightsValues` holds values for **all** properties which are defined in
        // `insightsValuesDefinition` but **nothing else**.
        const insightsValues = Object.keys(payload.insightsValuesDefinition)
          .map(x => getFullInsightsValuePropertyName(x))
          .reduce(
            (acc, name) => {
              acc[name] = state.queryValues.insightsValues[name] ?? [];
              return acc;
            },
            {} as QueryValues['insightsValues']
          );

        state.state = 'Fetched';
        state.queryValues.insightsValues = insightsValues;
        state.insights = payload.insights.value || [];
        state.totalCount = payload.insights.totalCount;
        state.continuationToken = payload.insights.continuationToken;

        state.insightsValuesDefinition = payload.insightsValuesDefinition;
      })
      .addCase(getCfgrCfgnInsights.rejected, (state, { payload, meta }) => {
        const { companyId, cfgrId, stageName, queryValues } = meta.arg;
        const isStaleResult = !_dataMatchesState(state, companyId, cfgrId, stageName, queryValues);
        if (!isStaleResult) {
          // Re-try on next call to `getCfgrCfgnInsights` even when given data (company id etc.) is the same as on last
          // call.
          state.state = 'Reset';
          state.insights = [];
          state.insightsValuesDefinition = {};
          state.totalCount = 0;
          state.continuationToken = undefined;
        }
      })

      .addCase(getCfgnDetails.fulfilled, (state, { payload, meta }) => {
        // `getCfgnDetails` returns `undefined` when it ignores the call e.g. because the data is already fetched or
        // fetching or when the received data is stale.
        if (payload === undefined) return;

        state.cfgnDetails.loadingState = 'Fetched';
        delete state.cfgnDetails.loadingCfgnId;
        state.cfgnDetails.data = payload;
      })
      .addCase(getCfgnDetails.rejected, (state, { payload, meta }) => {
        const { cfgnId } = meta.arg;
        const isStaleResult = state.cfgnDetails.loadingCfgnId !== cfgnId;
        if (!isStaleResult) {
          // Re-try on next call to `getCfgrCfgnInsights` even when given data (company id etc.) is the same as on last
          // call.
          state.cfgnDetails.loadingState = 'Reset';
          delete state.cfgnDetails.loadingCfgnId;
          delete state.cfgnDetails.data;
        }
      })

      .addCase(loadMoreCfgrCfgnInsights.fulfilled, (state, { payload, meta }) => {
        // `loadMoreCfgrCfgnInsights` returns `undefined` when the received data is stale.
        if (payload === undefined) return;

        state.state = 'Fetched';
        state.insights = state.insights.concat(payload.value || []);
        state.continuationToken = payload.continuationToken;
      })
      .addCase(loadMoreCfgrCfgnInsights.rejected, (state, { payload, meta }) => {
        // Let subsequent calls to `loadMoreCfgrCfgnInsights` simply retry.
        // Setting this to `Reset` would make future calls fail when not first calling `getCfgrCfgnInsights` again.
        state.state = 'Fetched';
      });
  },
});

export const selectCfgrCfgnInsights = createSelector(
  (state: RootState) => state.companyData.selection,
  (state: RootState) => state.cfgnInsights,
  ({ companyId, cfgrId }, cfgnInsights): CfgnInsight[] => {
    const dataMatchesState = _dataMatchesState(cfgnInsights, companyId, cfgrId, cfgnInsights.stageName);
    if (!companyId || !cfgrId || !dataMatchesState) return [];

    return cfgnInsights.insights;
  }
);

/**
 * Returns the property definitions for all insight values.
 *
 * This includes the statically known properties which are the same for every cfgr from
 * `BUILT_IN_INSIGHTS_PROPERTIES_ARRAY` and the dynamically fetched properties (i.e. the "insights values" defined by
 * Hives `Configuration.Insights`) which are different for every cfgr.
 */
function _getAllCfgnInsightPropertyDefinitions(cfgnInsights: CfgnInsightsState): CfgnInsightPropertyDefinition[] {
  const insightValuesDefinitions: CfgnInsightPropertyDefinition[] = Object.entries(
    cfgnInsights.insightsValuesDefinition
  ).map(([key, value]) => ({
    name: getFullInsightsValuePropertyName(key),
    label: key,
    group: 'Custom insights values',
    valueType:
      value.type === 'Text'
        ? FilterItemValueType.String
        : value.type === 'Logic'
          ? FilterItemValueType.Boolean
          : FilterItemValueType.Double,
    queryType: value.type === 'Text' ? 'list' : value.type === 'Logic' ? 'single-value' : 'min-max-range',
  }));

  const allDefinitions = [...BUILT_IN_INSIGHTS_PROPERTIES_ARRAY, ...insightValuesDefinitions].sort(
    sortInsightsPropertiesByGroup
  );
  return allDefinitions;
}

export const selectIsLoadingInitialData = createSelector(
  (state: RootState) => state.cfgnInsights,
  (insights): boolean => !insights.insights.length && insights.state === 'Pending'
);

/**
 * Returns the columns which are shown in the insights table.
 *
 * Returns default values which includes all custom insights values when `state.viewSettings.shownColumns` is empty,
 * which means that the user has not made a explicit choice yet.
 */
export const selectShownColumns = createSelector(
  (state: RootState) => state.cfgnInsights,
  (cfgnInsights): string[] => {
    const defaultBuiltInColsToShow = [
      'id',
      'createdAt',
      'createdBy',
      'type',
      'checkoutData.type',
      'checkoutData.price',
    ];
    const insightsValuesDefinitions = _getAllCfgnInsightPropertyDefinitions(cfgnInsights).filter(
      x => !isQueryableBuiltInInsightsProperty(x.name)
    );

    const shownColumns = cfgnInsights.viewSettings.shownColumns;
    const shownColumnsWithDefault = shownColumns.length
      ? shownColumns
      : [...defaultBuiltInColsToShow, ...insightsValuesDefinitions.map(x => x.name)];

    return shownColumnsWithDefault;
  }
);

export const selectHasMoreData = createSelector(
  (state: RootState) => state.cfgnInsights.continuationToken,
  (continuationToken): boolean => !!continuationToken
);

export const selectDistinctValues = createSelector(
  (state: RootState) => state.cfgnInsights.distinctValues,
  (distinctValues: unknown, propertyName: AllQueryableInsightsProperties) => propertyName,
  (distinctValues, propertyName): { state: FetchedState; values: string[] } => {
    return distinctValues[propertyName] ?? { state: 'None', values: [] };
  }
);

/**
 * Returns the property definitions for all insight values.
 *
 * This includes the statically known properties which are the same for every cfgr from
 * `BUILT_IN_INSIGHTS_PROPERTIES_ARRAY` and the dynamically fetched properties (i.e. the "insights values" defined by
 * Hives `Configuration.Insights`) which are different for every cfgr.
 */
export const selectCfgrCfgnInsightsPropertyDefinitions = createSelector(
  (state: RootState) => state.companyData.selection,
  (state: RootState) => state.cfgnInsights,
  ({ companyId, cfgrId }, cfgnInsights): CfgnInsightPropertyDefinition[] => {
    const dataMatchesState = _dataMatchesState(cfgnInsights, companyId, cfgrId, cfgnInsights.stageName);
    if (!companyId || !cfgrId || !dataMatchesState) return [];

    const allDefinitions = _getAllCfgnInsightPropertyDefinitions(cfgnInsights);
    return allDefinitions;
  }
);

export const {
  resetCfgnInsights,
  startFetching,
  setCompanyId,
  setStatePending,
  setDistinctValuesStatePending,
  startFetchingCfgnDetails,
  setPageSize,
  setShownColumns,
} = cfgnInsightsSlice.actions;

export default cfgnInsightsSlice.reducer;
