import { globalCompanySwitch, globalReset } from '../../actions/general.actions';
import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { CompanyStates } from 'generated/company-states';
import { FetchedState } from 'helper/route/route-sync.helper';
import { CompanyDetails, CompanyFeatures, getCompanyDetails } from 'services/companies/companies.service';
import {
  CfgrDrafts,
  CfgrStages,
  CfgrsDraftsAndStages,
  FetchCompaniesResult,
  LockStatus,
  fetchCfgr,
  fetchCfgrs,
  fetchCompanies,
} from 'services/company-data/company-data.service';
import {
  RestorableDraft,
  deleteConfiguratorHistoryDeleteDraft,
  deleteConfiguratorHistoryDeleteStage,
  getConfiguratorHistoryGetRestorableDrafts,
  postConfiguratorHistoryRestoreDraft,
} from 'services/configuratorhistory/configuratorhistory.service';
import { CFGR_FEATURES } from 'services/http/endpoints/configurators.endpoints';
import * as PersistenceMiddleware from 'services/store/persistence.middleware';
import { RootState } from 'services/store/store.service';
import { login } from 'slices/auth/auth.slice';

export type CfgrDetailsFetched = {
  [companyId: string]: {
    [cfgrId: string]: FetchedState;
  };
};

export type CompanyCfgrDrafts = {
  [companyId: string]: CfgrDrafts;
};

export type CompanyDeletedCfgrDrafts = {
  [companyId: string]: {
    [cfgrId: string]: RestorableDraft[];
  };
};

export type CompanyCfgrStages = {
  [companyId: string]: CfgrStages;
};

export type Cfgr = {
  id: string;
  companyId: string;
  authToken: string;
  displayName: string;
  /** Unix timestamp */
  deleteAfter: number | undefined;
  isPublished: boolean;
  /** Unix timestamp */
  lastPublishedAt: number | undefined;
  lastPublishedBy: string;
  version: string;
  cfgrFeatures: CfgrFeatures;
  lockStatus?: LockStatus;
};

export type CompanyCfgrs = {
  [companyId: string]: Cfgr[];
};

export type CfgrFeatures = Record<(typeof CFGR_FEATURES)[number], boolean>;

export type CompanyDataSelection = {
  companyId: string;
  cfgrId: string;
};

export type Company = {
  id: string;
  displayName: string;
  role: number; // TODO: Describe & document etc.
  defaultCulture: string;
  state: CompanyStates;
  companyFeatures: CompanyFeatures;
};

// Organizing the data like this as "independent lists" makes it easier to allow parallel fetching of data.
// E.g. it's no problem to request `/users/getCompanies` & `/configurators/getAll?companyName=...` at the same time.
//
// I actually started with a nested structure where cfgrs were stored inside a company and drafts inside a cfgr but that
// would have required `/configurators/getAll?companyName=...` to be called AFTER companies data was already present on
// the client...
export type CompanyDataState = {
  companies: Company[];
  companiesFetched: FetchedState;
  companiesDetails: { [companyId: string]: CompanyDetails };
  cfgrs: CompanyCfgrs;
  cfgrsFetched: FetchedState;

  /**
   * !!! Info !!!\
   * This is not populated by `getCompanyCfgrs` but only by `getCompanyCfgr` which is only called when actually on the
   * drafts page at the time of writing.
   */
  drafts: CompanyCfgrDrafts;

  /**
   * !!! Info !!!\
   * This is not populated by `getCompanyCfgrs` but only by `getCompanyDeletedCfgrs` which is only called when expanding
   * the "deleted drafts" card at the time of writing.
   */
  deletedDrafts: CompanyDeletedCfgrDrafts;

  /**
   * !!! Info !!!\
   * This is not populated by `getCompanyCfgrs` but only by `getCompanyCfgr` which is only called when actually on the
   * drafts page at the time of writing.
   */
  stages: CompanyCfgrStages;

  /**
   * Fetch status of cfgr details like `drafts` & `stages` data which is populated by `getCompanyCfgr` async thunk.
   */
  cfgrDetailsFetched: CfgrDetailsFetched;

  selection: CompanyDataSelection;
  ui: {
    selectionHistory: CompanyDataSelection[];
  };
};
const emptyState: CompanyDataState = {
  companies: [],
  companiesFetched: 'Pending',
  companiesDetails: {},
  cfgrs: {},
  cfgrsFetched: 'Pending',
  drafts: {},
  deletedDrafts: {},
  stages: {},
  cfgrDetailsFetched: {},
  selection: { companyId: '', cfgrId: '' },
  ui: {
    selectionHistory: [],
  },
};
const initialState = emptyState;

export const getCompanyDetailsThunk = createAsyncThunk<CompanyDetails, { companyId: string }>(
  'companies/get-details',
  async ({ companyId }, thunkApi) => {
    if (!companyId) {
      return thunkApi.rejectWithValue(undefined);
    }

    const companyResp = await getCompanyDetails(companyId);
    return companyResp;
  }
);

/**
 * Fetches user companies from server if the companies state is yet empty. If the companies state is not empty, data is
 * not updated.
 *
 * TODO: Talk about whether only loading the companies "on first bite" is really the way to go. This way a user only
 *       sees new companies (e.g. created by someone else "in the background") after F5 which is a bit inconsistent
 *       with other areas. E.g. info about company cfgrs & drafts is (re)loaded every time the selected company is
 *       changed.
 */
export const getCompaniesAndPermissionsIfNeeded = createAsyncThunk<
  FetchCompaniesResult,
  { forceFetch?: boolean } | undefined
>('companies/update-companies', async (options, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const companies = state.companyData.companies;
  const permissions = state.permissions.companies;

  if (state.companyData.companiesFetched === 'Fetched' && !options?.forceFetch) {
    // Only fetch companies once per session...
    return { companies: companies, permissions: permissions };
  }

  const companyResp = await fetchCompanies();
  return companyResp;
});

/**
 * Fetches info about company cfgrs + drafts. Overwrites existing data if already present in the store.
 */
export const getCompanyCfgrs = createAsyncThunk<Cfgr[], { companyId: string }>(
  'companies/getCompanyCfgrs',
  async ({ companyId }, thunkApi) => {
    if (!companyId) {
      return thunkApi.rejectWithValue(undefined);
    }

    const cfgrsResp = await fetchCfgrs(companyId);
    return cfgrsResp;
  }
);

/**
 * Fetches info about specific cfgr. Overwrites existing cfgr but keeps others untouched\
 * If the cfgr couldn't be retrieved all cfgrs are fetched (e.g. cfgr got deleted)
 */
export const getCompanyCfgr = createAsyncThunk<CfgrsDraftsAndStages, { companyId: string; cfgrId: string }>(
  'companies/getCompanyCfgr',
  async ({ companyId, cfgrId }, thunkApi) => {
    if (!companyId || !cfgrId) {
      return thunkApi.rejectWithValue(undefined);
    }

    const cfgrResp = await fetchCfgr(companyId, cfgrId);
    if (cfgrResp) {
      return cfgrResp;
    } else {
      // fetch all cfgrs in case of a failed request
      await thunkApi.dispatch(getCompanyCfgrs({ companyId }));
      return thunkApi.rejectWithValue(undefined);
    }
  }
);

/**
 * Fetches deleted drafts of specific cfgr. Overwrites deleted drafts of existing cfgr but keeps others untouched.
 */
export const getCompanyDeletedDrafts = createAsyncThunk<RestorableDraft[], { companyId: string; cfgrId: string }>(
  'companies/getCompanyDeletedDrafts',
  async ({ companyId, cfgrId }, thunkApi) => {
    if (!companyId || !cfgrId) {
      return thunkApi.rejectWithValue(undefined);
    }

    const cfgrResp = await getConfiguratorHistoryGetRestorableDrafts(companyId, cfgrId);
    return cfgrResp ?? thunkApi.rejectWithValue(undefined);
  }
);

export const deleteDraft = createAsyncThunk<void, { companyId: string; configuratorName: string; draftId: string }>(
  'companies/deleteDraft',
  async ({ companyId, configuratorName, draftId }, thunkApi) => {
    if (!companyId || !configuratorName || !draftId) {
      return thunkApi.rejectWithValue(undefined);
    }

    await deleteConfiguratorHistoryDeleteDraft(companyId, configuratorName, draftId);
    thunkApi.dispatch(getCompanyCfgr({ companyId, cfgrId: configuratorName }));
    thunkApi.dispatch(getCompanyDeletedDrafts({ companyId, cfgrId: configuratorName }));
  }
);

export const deleteStage = createAsyncThunk<void, { companyId: string; configuratorName: string; stageName: string }>(
  'companies/deleteStage',
  async ({ companyId, configuratorName, stageName }, thunkApi) => {
    if (!companyId || !configuratorName || !stageName) {
      return thunkApi.rejectWithValue(undefined);
    }

    await deleteConfiguratorHistoryDeleteStage(companyId, configuratorName, stageName);
    thunkApi.dispatch(getCompanyCfgr({ companyId, cfgrId: configuratorName }));
  }
);

export const restoreDeletedDraft = createAsyncThunk<
  void,
  { companyId: string; configuratorName: string; draftId: string }
>('companies/deleteDraft', async ({ companyId, configuratorName, draftId }, thunkApi) => {
  if (!companyId || !configuratorName || !draftId) {
    return thunkApi.rejectWithValue(undefined);
  }

  await postConfiguratorHistoryRestoreDraft(companyId, configuratorName, draftId);
  thunkApi.dispatch(getCompanyCfgr({ companyId, cfgrId: configuratorName }));
});

const SLICE_NAME = 'companyData';

PersistenceMiddleware.registerState<CompanyDataState, CompanyDataState['selection']>({
  state: SLICE_NAME,
  key: 'selection',
  selector: state => state.selection,
});

PersistenceMiddleware.registerState<CompanyDataState, CompanyDataState['ui']>({
  state: SLICE_NAME,
  key: 'ui',
  selector: state => state.ui,
  props: ['selectionHistory'],
});

const getRehydratedState = (): CompanyDataState => {
  const rehydratedState = PersistenceMiddleware.rehydrateState<CompanyDataState>(SLICE_NAME, initialState);
  return rehydratedState;
};

const companiesSlice = createSlice({
  name: SLICE_NAME,
  initialState: getRehydratedState,
  reducers: {
    setCfgrSelection: (state, { payload }: PayloadAction<string>) => {
      state.selection.cfgrId = payload;
      const entry = state.ui.selectionHistory.find(s => s.companyId === state.selection.companyId);
      if (entry) {
        entry.cfgrId = payload;
      } else {
        state.ui.selectionHistory.push({ companyId: state.selection.companyId, cfgrId: payload });
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(globalCompanySwitch, (state, { payload }) => {
        if (state.selection.companyId !== payload) {
          state.selection.companyId = payload;

          const historySelection = state.ui.selectionHistory.find(s => s.companyId === state.selection.companyId);
          state.selection.cfgrId = historySelection?.cfgrId ?? '';

          state.cfgrsFetched = 'Pending';
        }
      })
      .addCase(globalReset, () => emptyState)

      .addCase(getCompaniesAndPermissionsIfNeeded.fulfilled, (state, { payload }) => {
        state.companies = payload.companies;
        state.companiesFetched = 'Fetched';
      })
      .addCase(getCompaniesAndPermissionsIfNeeded.rejected, () => ({
        ...emptyState,
        companiesFetched: 'Aborted',
      }))

      .addCase(login.fulfilled, (state, { payload }) => {
        state.companies = payload.companies;
        state.companiesFetched = 'Fetched';
      })
      .addCase(login.rejected, () => emptyState)
      .addCase(getCompanyDetailsThunk.fulfilled, (state, { payload, meta }) => {
        const companyId = meta.arg.companyId;
        state.companiesDetails[companyId] = payload;
      })

      .addCase(getCompanyCfgrs.fulfilled, (state, { payload, meta }) => {
        // Add info about company cfgrs or replace already existing info with newly fetched data
        const companyId = meta.arg.companyId;
        state.cfgrs[companyId] = payload;
        state.cfgrsFetched = 'Fetched';

        // remove selection if invalid
        if (
          state.selection.companyId === companyId &&
          !state.cfgrs[companyId].some(c => c.id === state.selection.cfgrId)
        ) {
          state.selection.cfgrId = '';
        }
      })
      .addCase(getCompanyCfgrs.rejected, (state, { meta }) => {
        // Delete all already existing info about company cfgrs & drafts if fetching new info failed in an unhandled way
        const companyId = meta.arg.companyId;
        delete state.cfgrs[companyId];
        if (state.selection.companyId === companyId) {
          state.selection.cfgrId = '';
        }
        state.cfgrsFetched = 'Fetched';
      })

      .addCase(getCompanyCfgr.pending, (state, { payload, meta }) => {
        const companyId = meta.arg.companyId;
        const cfgrId = meta.arg.cfgrId;
        state.cfgrDetailsFetched[companyId] = state.cfgrDetailsFetched[companyId] ?? {};
        state.cfgrDetailsFetched[companyId][cfgrId] = 'Pending';
      })
      .addCase(getCompanyCfgr.fulfilled, (state, { payload, meta }) => {
        const companyId = meta.arg.companyId;
        const cfgr = payload.cfgrs[0];
        const previousCfgrs = state.cfgrs[companyId] ? state.cfgrs[companyId].filter(c => c.id !== cfgr.id) : [];
        state.cfgrs[companyId] = [...previousCfgrs, cfgr];

        state.drafts[companyId] = payload.drafts ?? [];
        state.stages[companyId] = payload.stages ?? [];

        state.cfgrDetailsFetched[companyId] = state.cfgrDetailsFetched[companyId] ?? {};
        state.cfgrDetailsFetched[companyId][cfgr.id] = 'Fetched';
      })
      .addCase(getCompanyCfgr.rejected, (state, { payload, meta }) => {
        const companyId = meta.arg.companyId;
        const cfgrId = meta.arg.cfgrId;

        delete state.drafts[companyId]?.[cfgrId];
        delete state.stages[companyId]?.[cfgrId];

        state.cfgrDetailsFetched[companyId] = state.cfgrDetailsFetched[companyId] ?? {};
        state.cfgrDetailsFetched[companyId][cfgrId] = 'Aborted';
      })

      .addCase(getCompanyDeletedDrafts.fulfilled, (state, { payload, meta }) => {
        const companyId = meta.arg.companyId;
        const cfgrId = meta.arg.cfgrId;
        state.deletedDrafts[companyId] = state.deletedDrafts[companyId] ?? {};
        state.deletedDrafts[companyId][cfgrId] = payload ?? [];
      })
      .addCase(getCompanyDeletedDrafts.rejected, (state, { payload, meta }) => {
        const companyId = meta.arg.companyId;
        const cfgrId = meta.arg.cfgrId;
        delete state.deletedDrafts[companyId]?.[cfgrId];
      })

      .addCase(deleteDraft.fulfilled, (state, { meta }) => {
        const drafts = state.drafts[meta.arg.companyId][meta.arg.configuratorName];
        state.drafts[meta.arg.companyId][meta.arg.configuratorName] = drafts.filter(d => d.id !== meta.arg.draftId);
      })
      .addCase(deleteStage.fulfilled, (state, { meta }) => {
        const stages = state.stages[meta.arg.companyId][meta.arg.configuratorName];
        state.stages[meta.arg.companyId][meta.arg.configuratorName] = stages.filter(d => d.name !== meta.arg.stageName);
      });
  },
});

export const selectSelectedCompany = createSelector(
  (state: RootState) => state.companyData.companies,
  (state: RootState) => state.companyData.selection.companyId,
  (companies, selectedCompanyId) => companies.find(c => c.id === selectedCompanyId)
);

export const selectAllCompanyCfgrs = createSelector(
  (state: RootState) => state.companyData.selection.companyId,
  (state: RootState) => state.companyData.cfgrs,
  (selCompanyId, cfgrs) => cfgrs[selCompanyId] || []
);

export const selectSelectedConfigurator = createSelector(
  selectAllCompanyCfgrs,
  (state: RootState) => state.companyData.selection,
  (cfgrs, selection) => cfgrs.find(c => c.id === selection.cfgrId)
);

export const selectCfgrDrafts = createSelector(
  (state: RootState) => state.companyData.selection,
  (state: RootState) => state.companyData.drafts,
  (selection, drafts) => drafts[selection.companyId]?.[selection.cfgrId] || []
);

export const selectDeletedCfgrDrafts = createSelector(
  (state: RootState) => state.companyData.selection,
  (state: RootState) => state.companyData.deletedDrafts,
  (selection, drafts) => drafts[selection.companyId]?.[selection.cfgrId] || []
);

export const selectCfgrStages = createSelector(
  (state: RootState) => state.companyData.selection,
  (state: RootState) => state.companyData.stages,
  (selection, stages) => stages[selection.companyId]?.[selection.cfgrId] || []
);

export const selectSelectedCfgrDetailsFetchState = createSelector(
  (state: RootState) => state.companyData.selection,
  (state: RootState) => state.companyData.cfgrDetailsFetched,
  (selection, detailsFetched) => detailsFetched[selection.companyId]?.[selection.cfgrId] || 'None'
);

export const selectCompaniesWithWorkflowsFeature = createSelector(
  (state: RootState) => state.companyData.companies,
  companies => companies.filter(company => company.companyFeatures.workflows)
);

export const selectCompanyDetails = createSelector(
  (state: RootState) => state.companyData.selection.companyId,
  (state: RootState) => state.companyData.companiesDetails,
  (companyId, companiesDetails) => {
    return companiesDetails[companyId];
  }
);

export const { setCfgrSelection } = companiesSlice.actions;

export default companiesSlice.reducer;
