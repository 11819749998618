import {
  BabylonAssetIcon,
  DataSourceAssetIcon,
  FileAssetIcon,
  FilesIcon,
  FolderAssetIcon,
  ImageAssetIcon,
  MaterialAssetIcon,
} from 'assets/icons';
import { SvgComponent } from 'controls/icon/icon';
import { AssetLinkTypes } from 'generated/asset-link-types';

type AssetLinkTypeDefs = {
  SourceIcon: SvgComponent;
  TargetIcon: SvgComponent;
};

export const ASSETLINK_TYPE_MAP: Record<AssetLinkTypes, AssetLinkTypeDefs> = {
  [AssetLinkTypes.BabylonJsToMaterial]: {
    SourceIcon: BabylonAssetIcon,
    TargetIcon: MaterialAssetIcon,
  },
  [AssetLinkTypes.DataSourceColumnToFolder]: {
    SourceIcon: DataSourceAssetIcon,
    TargetIcon: FolderAssetIcon,
  },
  [AssetLinkTypes.DataSourceColumnToAsset]: {
    SourceIcon: DataSourceAssetIcon,
    TargetIcon: FilesIcon,
  },
  [AssetLinkTypes.MaterialToImage]: {
    SourceIcon: MaterialAssetIcon,
    TargetIcon: ImageAssetIcon,
  },
  [AssetLinkTypes.FileAssetToExcelDataSource]: {
    SourceIcon: FileAssetIcon,
    TargetIcon: DataSourceAssetIcon,
  },
};
