import { AssetBodyFolderItem } from './asset-body-folder-item';
import { EmptyFolderFallback } from 'components/asset-editor/details/asset-body/folder-assets/empty-folder-fallback';
import { AssetDefaultActions } from 'components/asset-editor/details/asset-details-actions';
import { AssetDetailsHeader } from 'components/asset-editor/details/asset-details-header';
import { AssetMetadataEntry } from 'components/asset-editor/details/asset-metadata';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { sortAssets } from 'helper/assets/assets.helper';
import { useIsAssetBundleEditable } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import {
  MATERIALS_3D_FOLDER_NAME,
  selectChildAssetsInSelectedFolder,
  selectSelectedAsset,
} from 'slices/assets/assets.slice';

export const AssetBodyFolder: React.FC = () => {
  const { t } = useTypedTranslation();
  const isAssetBundleEditable = useIsAssetBundleEditable();

  const selectedAsset = useAppSelector(selectSelectedAsset);
  const selectedChildAssets = useAppSelector(selectChildAssetsInSelectedFolder);

  const sortedFolderItems = Object.values(selectedChildAssets);
  sortAssets(sortedFolderItems);
  const showEmptyFolderFallback = !sortedFolderItems.length && isAssetBundleEditable;

  const isMaterialsFolder = selectedAsset?.path.assetId === MATERIALS_3D_FOLDER_NAME;
  const isRootFolder = !selectedAsset;
  const actions: (AssetDefaultActions | React.ReactElement)[] =
    isRootFolder || !isAssetBundleEditable ? [] : isMaterialsFolder ? ['Delete'] : ['Rename', 'Copy', 'Delete'];

  const metadata: AssetMetadataEntry[] = [{ text: t('Items'), value: sortedFolderItems.length.toString() }];

  return (
    <CbnCard data-cmptype="AssetBodyFolder">
      <AssetDetailsHeader actions={actions} uploadMode={'Add'} metadata={metadata} />

      {(sortedFolderItems.length || showEmptyFolderFallback) && (
        <CbnCardBody>
          {showEmptyFolderFallback ? (
            <EmptyFolderFallback />
          ) : (
            <div className="grid grid-cols-[repeat(auto-fill,minmax(140px,1fr))] gap-y-5">
              {sortedFolderItems.map(child => (
                <AssetBodyFolderItem key={child.path.assetId} asset={child} />
              ))}
            </div>
          )}
        </CbnCardBody>
      )}
    </CbnCard>
  );
};
