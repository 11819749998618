import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { globalCompanySwitch } from 'actions/general.actions';
import { DropdownSearch } from 'controls/select/dropdown-search';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { CompanyIdRouteParams } from 'services/routes/common-routeparams.service';
import { findValidPathPattern, generateClosestDynamicRoute } from 'services/routes/paths.service';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';

export const GlobalCompanyPicker: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();

  const selectedCompany = useAppSelector(selectSelectedCompany);
  const routeParams = useParams<CompanyIdRouteParams>();
  const { pathname } = useLocation();

  const companies = useAppSelector(state => state.companyData.companies);
  const companiesSorted = [...companies].sort((a, b) => a.displayName.localeCompare(b.displayName));

  const dropdownRef = useRef<HTMLButtonElement>(null);

  const pathPattern = useMemo(() => {
    return findValidPathPattern(pathname, 'companyId') ?? pathname;
  }, [pathname]);

  const onCompanyChange = (selectedCompanyId: string): void => {
    // for routes which don't have a companyId-param it's set via the click-event
    if (!routeParams.companyId && selectedCompanyId) {
      dispatch(globalCompanySwitch(selectedCompanyId));
    }
  };

  /**
   * Global hotkey `Ctrl + K` to open the company picker
   * Small replacement for the missing quick launcher
   */
  useEffect(function registerGlobalHotkey() {
    const listener = (evt: KeyboardEvent): void => {
      if (evt.ctrlKey && evt.key === 'k' && dropdownRef.current) {
        // Simple check to prevent the hotkey if a modal is currently open
        const modalDetected = !!document.querySelector('article[data-cmptype^="ModalDialog"]');
        if (!modalDetected) {
          evt.preventDefault();
          dropdownRef.current.click();
        }
      }
    };
    window.addEventListener('keydown', listener);

    return () => window.removeEventListener('keydown', listener);
  }, []);

  return (
    <DropdownSearch
      data-cmptype="GlobalCompanyPicker"
      ref={dropdownRef}
      fullWidth
      items={companiesSorted.map(c => ({
        id: c.id,
        displayName: c.displayName,
        displayDetail: c.id,
        route: generateClosestDynamicRoute(pathPattern, routeParams, 'companyId', c.id),
      }))}
      showIcons
      dropdownHeader={t('Companies')}
      defaultButtonText={t('[Select company]')}
      selected={selectedCompany?.id}
      onChange={onCompanyChange}
    ></DropdownSearch>
  );
};
