import { DeletedDraftIcon, DraftIcon, HistoryIcon, StageIcon } from 'assets/icons';
import { CfgrMainStateCard } from 'components/cfgrs/cfgr-main-state-card';
import { CfgrVersionsCardBody } from 'components/cfgrs/cfgr-versions/cfgr-versions-card-body';
import { DeletedDraftsCardBody } from 'components/cfgrs/drafts/deleted-drafts-card-body';
import { DraftsCardBody } from 'components/cfgrs/drafts/drafts-card-body';
import { StagesCardBody } from 'components/cfgrs/stages/stages-card-body';
import { MAX_DRAFTS_PER_CFGR } from 'components/common/create-draft-dialog';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCollapsibleCardHeader } from 'controls/cbn-card/cbn-collapsible-card-header';
import { useCfgrDraftPolling } from 'hooks/cfgrs/cfgrs-draft.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import {
  selectCfgrDrafts,
  selectCfgrStages,
  selectSelectedCompany,
  selectSelectedConfigurator,
} from 'slices/company-data/company-data.slice';

export const Drafts: React.FC = () => {
  const { t } = useTypedTranslation();

  const drafts = useAppSelector(selectCfgrDrafts);
  const stages = useAppSelector(selectCfgrStages);
  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const maxNoOfStagesPerCfgr = selectedCompany?.companyFeatures.maxCfgrStages || 0;
  const selectedCfgrIsDeleted = !!selectedCfgr?.deleteAfter;
  const selectedCfgrIsReadOnly = !!selectedCfgr?.cfgrFeatures.readonly;

  useCfgrDraftPolling(selectedCfgr?.companyId ?? '', selectedCfgr?.id ?? '');

  return (
    <div data-cmptype="Drafts">
      <div className="flex flex-col gap-5">
        {selectedCfgr && (
          <>
            <CfgrMainStateCard variant="Draft" />

            {!selectedCfgrIsDeleted && (
              <>
                {!selectedCfgrIsReadOnly && (
                  <>
                    <CbnCard isCollapsible collapsed={stages.length === 0}>
                      <CbnCollapsibleCardHeader
                        title={t('Stages')}
                        subText={`${stages.length}/${maxNoOfStagesPerCfgr}`}
                        icon={StageIcon}
                        iconActive={stages.length > 0}
                      />
                      <StagesCardBody stages={stages} selectedCfgr={selectedCfgr} />
                    </CbnCard>

                    <CbnCard isCollapsible collapsed={drafts.length === 0}>
                      <CbnCollapsibleCardHeader
                        title={t('Drafts')}
                        subText={`${drafts.length}/${MAX_DRAFTS_PER_CFGR}`}
                        icon={DraftIcon}
                        iconActive={drafts.length > 0}
                      />
                      <DraftsCardBody drafts={drafts} />
                    </CbnCard>
                  </>
                )}

                <CbnCard isCollapsible collapsed>
                  <CbnCollapsibleCardHeader title={t('Versions')} icon={HistoryIcon} iconActive={true} />
                  <CfgrVersionsCardBody />
                </CbnCard>

                <CbnCard isCollapsible collapsed>
                  <CbnCollapsibleCardHeader title={t('Deleted drafts')} icon={DeletedDraftIcon} iconActive={false} />
                  <DeletedDraftsCardBody />
                </CbnCard>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
