import React, { useState } from 'react';
import { CopyLinkIcon, NewDraftIcon, PublishIcon } from 'assets/icons';
import { StageActions } from 'components/cfgrs/stages/stage-actions';
import { CreateDraftDialog } from 'components/common/create-draft-dialog';
import { PublishErrorDialogContent, isKnownPublishError } from 'components/common/publish-error-dialog-content';
import { IdentityUser } from 'components/identity/identity-user';
import { Button } from 'controls/button/button';
import { CopyToClipboardButton } from 'controls/button/copy-to-clipboard-button';
import { LinkButton } from 'controls/button/link-button';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'controls/table';
import { TableSkeletonRows } from 'controls/table/table-skeleton-rows';
import { getCfgrStagingUrl, getEditorUrl } from 'helper/url/cfgr-url.helper';
import { useConfirmDialog, useErrorDialog } from 'hooks/common/dialog.hooks';
import { useElementSize } from 'hooks/common/sizing.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppDispatch } from 'hooks/store/store.hooks';
import { Stage } from 'services/company-data/company-data.service';
import { postConfiguratorHistoryPublishStage } from 'services/configuratorhistory/configuratorhistory.service';
import { Cfgr, getCompanyCfgr } from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

type StagesTableProps = {
  stages: Stage[];
  selectedCfgr: Cfgr;
  showLoadingSkeletons: boolean;
};

export const StagesTable: React.FC<StagesTableProps> = ({ stages, selectedCfgr, showLoadingSkeletons }) => {
  const dispatch = useAppDispatch();
  const confirm = useConfirmDialog();
  const showError = useErrorDialog();

  const { t } = useTypedTranslation();
  const [hoveredStagetId, setHoveredStageId] = useState<string>();

  const [createDraftOpen, setCreateDraftOpen] = useState(false);
  const [initialSourceCfgrVersionId, setInitialSourceCfgrVersionId] = useState<string>();

  const [publishingStageVersions, setPublishingStageVersions] = useState<string[]>([]);

  const { ref: tableRef, size: tableSize } = useElementSize();
  const showAsMenu = tableSize ? tableSize.width < 1000 : false;

  const selectedCompanyId = selectedCfgr.companyId;
  const selectedCfgrId = selectedCfgr.id;

  const hasManageDraftPermission = usePermission(CompanyPermissions.ManageDraft, selectedCfgr.companyId);
  const hasPublishCfgrPermission = usePermission(CompanyPermissions.PublishCfgrDraft, selectedCfgr.companyId);

  const publishStage = async (stage: Stage): Promise<void> => {
    const publishConfirmed = await confirm(t('Publishing will overwrite the current live version.'), {
      headerText: t(`Publish stage "${stage.name}"`),
      confirmBtnText: 'Publish stage',
      variant: 'Question',
    });

    if (publishConfirmed) {
      setPublishingStageVersions([...publishingStageVersions, stage.version]);

      try {
        await postConfiguratorHistoryPublishStage(selectedCompanyId, selectedCfgrId, stage.name);
      } catch (error) {
        if (isKnownPublishError(error)) {
          showError(<PublishErrorDialogContent error={error.response!.data} />, {
            headerText: t('Publish failed'),
            size: 'Large',
          });
        } else {
          // Re-throw unhandled errors to show generic "Ooops" dialog
          throw error;
        }
      }

      await dispatch(getCompanyCfgr({ companyId: selectedCompanyId, cfgrId: selectedCfgrId }));
      setPublishingStageVersions(publishingStageVersions.filter(x => x !== stage.version));
    }
  };

  return (
    <div data-cmptype="StagesTable" ref={tableRef}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="w-0">{t('Name')}</TableCell>
            <TableCell className="w-0"></TableCell>
            <TableCell>{t('Staged')}</TableCell>
            <TableCell>{t('Link')}</TableCell>
            <TableCell className="w-0"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {showLoadingSkeletons && (
              <TableSkeletonRows numberOfCols={5} colSkeletonProps={{ 1: { className: 'w-[10rem]' } }} />
            )}

            {!showLoadingSkeletons &&
              stages.map(stage => {
                const stageLink = getCfgrStagingUrl(selectedCompanyId, selectedCfgrId, stage.name);
                const viewStageInEditorLink = getEditorUrl(selectedCompanyId, selectedCfgrId, stage.version, 'view');
                return (
                  <TableRow
                    key={stage.version}
                    className={'hover:bg-neutral-20'}
                    onMouseEnter={(): void => setHoveredStageId(stage.version)}
                    onMouseLeave={(): void => setHoveredStageId(undefined)}
                  >
                    <TableCell>
                      <div className="text-m-medium">
                        <LinkButton
                          variant="TextInline"
                          href={viewStageInEditorLink}
                          text={stage.name}
                          title={t('View stage in editor')}
                          reloadDocument
                        />
                      </div>
                    </TableCell>

                    <TableCell>
                      <StageActions stage={stage} visible={hoveredStagetId === stage.version} showAsMenu={showAsMenu} />
                    </TableCell>

                    <TableCell>
                      <div className="just-center flex whitespace-pre">
                        <RelativeDateTime unixTime={stage.stagedAt} />
                        <span> {t('by')} </span>
                        <IdentityUser userId={stage.stagedBy} />
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className="flex flex-row gap-1">
                        {/*
                    FYI, according to design, this text should crop with text ellipsis "from the left" when the table
                    gets too narrow. However, implementing the text ellipsis for a table cell without fixed width
                    doesn't seem to be possible (with reasonable effort), so I skipped it as this is an edge case and
                    the table still works on narrow screens by showing a horizontal scrollbar.
                    */}
                        <LinkButton variant="TextInline" href={stageLink} text={stageLink} target="_blank" />
                        <CopyToClipboardButton text={stageLink} title={t('Copy link')} icon={CopyLinkIcon} />
                      </div>
                    </TableCell>

                    <TableCell>
                      {!selectedCfgr.cfgrFeatures.readonly && (
                        <div className="flex gap-2">
                          {hasManageDraftPermission && (
                            <Button
                              variant="Secondary"
                              text={t('New draft')}
                              Svg={NewDraftIcon}
                              onClick={(): void => {
                                setInitialSourceCfgrVersionId(stage.version);
                                setCreateDraftOpen(true);
                              }}
                            />
                          )}

                          {hasPublishCfgrPermission && (
                            <Button
                              variant="Primary"
                              text={t('Publish')}
                              Svg={PublishIcon}
                              isLoading={publishingStageVersions.includes(stage.version)}
                              onClick={(): Promise<void> => publishStage(stage)}
                            />
                          )}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </>
        </TableBody>
      </Table>

      {selectedCfgr && createDraftOpen && (
        <CreateDraftDialog
          initialSourceCfgrVersionId={initialSourceCfgrVersionId}
          onCancel={(): void => setCreateDraftOpen(false)}
          onConfirm={(): void => setCreateDraftOpen(false)}
        />
      )}
    </div>
  );
};
