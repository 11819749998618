import React from 'react';
import { CompanySettingsPlanCardHeader } from 'components/company/company-settings-plan-card-header';
import { LinkButton } from 'controls/button/link-button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { dateToUnixTimestamp } from 'helper/date-and-time/date-and-time.helper';
import { CBN_MAIL_ADRESSES } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectCompanyDetails } from 'slices/company-data/company-data.slice';

export const CompanySettingsPlanCard: React.FC = () => {
  const { t, tSubscriptionExtendInfo } = useTypedTranslation();

  const selectedCompanyDetails = useAppSelector(selectCompanyDetails);
  const invoiceDay = selectedCompanyDetails?.billingInformation?.invoiceDay ?? 0;
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Today 00:00:00
  const invoiceDateInCurrentMonth = new Date(today.getFullYear(), today.getMonth(), invoiceDay);
  const invoiceDateInNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, invoiceDay);
  const nextInvoiceDate = invoiceDateInCurrentMonth < today ? invoiceDateInNextMonth : invoiceDateInCurrentMonth;
  const invoiceDateAfterNext = new Date(nextInvoiceDate.getFullYear(), nextInvoiceDate.getMonth() + 1, invoiceDay);
  const daysBetweenNextInvoiceDates = Math.round(
    (invoiceDateAfterNext.getTime() - nextInvoiceDate.getTime()) / (1000 * 60 * 60 * 24)
  );

  const cancelMailSubject = t('Cancel subscription');
  const cancelMailBody = t('I want to cancel my subscription. Please contact me to discuss further details.');

  return (
    <CbnCard data-cmptype="CompanySettingsPlanCard">
      <CompanySettingsPlanCardHeader billingInformation={selectedCompanyDetails?.billingInformation} />
      <CbnCardBody>
        <div className="flex text-m-medium ">
          <div className="flex-1 text-neutral-90">
            <div>{tSubscriptionExtendInfo(daysBetweenNextInvoiceDates)}</div>
            <div>
              {t(`You can`)}
              <LinkButton
                href={`mailto:${CBN_MAIL_ADRESSES.info}?subject=${cancelMailSubject}&body=${cancelMailBody}`}
                variant="TextInline"
                text={` ${t('cancel')} `}
              />
              {t('your subscription.')}
            </div>
          </div>
          <RelativeDateTime unixTime={dateToUnixTimestamp(nextInvoiceDate)} onlyDate={true} />
        </div>
      </CbnCardBody>
    </CbnCard>
  );
};
