import { useCallback, useContext } from 'react';
import {
  AlertDialogFn,
  ConfirmDialogFn,
  ConfirmDialogOptions,
  DialogContext,
  ErrorDialogFn,
} from 'components/common/dialog-context';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

export const useConfirmDialog = (): ConfirmDialogFn => {
  const { showDialog } = useContext(DialogContext);

  const confirmFn = useCallback(
    (content: React.ReactNode, options?: ConfirmDialogOptions): Promise<boolean> => {
      return showDialog(content, options);
    },
    [showDialog]
  );

  return confirmFn;
};

export const useAlertDialog = (): AlertDialogFn => {
  const { showDialog } = useContext(DialogContext);
  const { t } = useTypedTranslation();

  const confirmFn = useCallback(
    async (content: React.ReactNode, options?: ConfirmDialogOptions): Promise<void> => {
      await showDialog(content, { confirmBtnText: t('Ok'), hideCancelBtn: true, ...options });
    },
    [showDialog, t]
  );

  return confirmFn;
};

export const useErrorDialog = (): ErrorDialogFn => {
  const { showDialog } = useContext(DialogContext);
  const { t } = useTypedTranslation();

  const confirmFn = useCallback(
    async (content: React.ReactNode, options?: ConfirmDialogOptions): Promise<void> => {
      await showDialog(content, { variant: 'Error', confirmBtnText: t('Ok'), hideCancelBtn: true, ...options });
    },
    [showDialog, t]
  );

  return confirmFn;
};
