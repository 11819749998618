import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type DebugState = {
  translationCheckModeActive: boolean;
  inDevEnv: boolean;
  permissionOverride?: number;
};

const initialState: DebugState = {
  translationCheckModeActive: false,
  inDevEnv: process.env.NODE_ENV === 'development' ? true : false,
  permissionOverride: undefined,
};

const debugSlice = createSlice({
  name: 'debug',
  initialState,
  reducers: {
    toggleTranslationCheckMode: state => {
      state.translationCheckModeActive = !state.translationCheckModeActive;
    },
    toggleDevEnv: state => {
      state.inDevEnv = !state.inDevEnv;
    },
    setPermissionOverride: (state, { payload }: PayloadAction<number>) => {
      state.permissionOverride = payload;
    },
  },
});

export const debugActions = debugSlice.actions;

export default debugSlice.reducer;
