import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { Card } from 'controls/card/card';
import { FormItem } from 'controls/form-item/form-item';
import { PasswordInput } from 'controls/input/password-input';
import { TextInput } from 'controls/input/text-input';
import { isExtJsHistoryState } from 'helper/extjs-route/extjs-route.helper';
import { CBN_MAIL_ADRESSES } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch } from 'hooks/store/store.hooks';
import { loginFailedResponses } from 'services/auth/auth.service';
import { isGenericActionResult } from 'services/http/http.service';
import { PATHS } from 'services/routes/paths.service';
import { LocationProps } from 'services/routes/routes.service';
import { login } from 'slices/auth/auth.slice';

type LoginCardProps = {
  /** Path which is used as target for the "Create new account" link below the card */
  registerLinkPath: string;

  /**
   * If given, the API uses a different endpoint to login the user and add him to the given company in one step.
   */
  inviteData?: {
    companyName: string;
    inviteToken: string;
    inviteRecipient?: string;
  };
};

/**
 * Login card + link to register page below.\
 * Also performs the necessary API calls + redirect, update of redux state etc.
 */
export const LoginCard: React.FC<LoginCardProps> = ({ registerLinkPath, inviteData }) => {
  const dispatch = useAppDispatch();
  const location = useLocation() as LocationProps;
  const { t } = useTypedTranslation();

  const genericLoginFailedMsg = t('Login failed! Please check your credentials!');

  const [emailInput, setEmailInput] = useState(inviteData?.inviteRecipient ?? '');
  const [passwordInput, setPasswordInput] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [failedLoginMsg, setFailedLoginMsg] = useState('');
  const isNotAllEntered = !emailInput || !passwordInput;

  const onLogin = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    setIsProcessing(true);
    setFailedLoginMsg('');

    let isInvalidInvitation = false;
    try {
      const result = await dispatch(
        login({
          email: emailInput,
          password: passwordInput,
          ...(inviteData && { inviteData }),
        })
      ).unwrap();

      if (inviteData && !result.isCompanyJoinSuccessful) {
        isInvalidInvitation = true;
      }
    } catch (res: unknown) {
      const msg = (isGenericActionResult(res, loginFailedResponses) && res.Message) || genericLoginFailedMsg;
      setFailedLoginMsg(msg);

      setIsProcessing(false);
      setPasswordInput('');
      return;
    }

    const extJsTarget = isExtJsHistoryState(window.history.state) ? window.history.state.referrer : null;

    const target = isInvalidInvitation
      ? PATHS.acceptCompanyInvitationInvalid
      : extJsTarget || location.state?.referrer || PATHS.home;

    // WORKAROUND: with `useNavigate()` it can happen that the url changes but the login-dialog stays unchanged
    // This is probably self-inflicted (related to syncing with Redux or other navigation behavior) but changing
    // via `window.location` is the fastest fix ATM
    // See other places where `location.href` is assigned to when fixing this...
    //navigate(target, { state: null });
    window.location.href = target;
  };

  return (
    <div data-cmptype="LoginCard" className="flex flex-grow flex-col items-center gap-6">
      <Card>
        <div className="flex w-100 flex-col gap-6 pt-3">
          <div className="flex w-full flex-col gap-2">
            <h3>{t('Log in')}</h3>
            <span className="text-m-regular text-neutral-70">{t('Welcome back! Please enter your credentials.')}</span>
          </div>
          <form onSubmit={onLogin} className="flex flex-col gap-6">
            <FormItem labelContent={t('Your email')}>
              <TextInput
                type="text"
                autoComplete="username"
                error={!!failedLoginMsg}
                placeholder={CBN_MAIL_ADRESSES.placeholderMail}
                value={emailInput}
                onValueChange={(val): void => {
                  setEmailInput(val);
                }}
                autoFocus
              ></TextInput>
            </FormItem>
            <FormItem
              labelContent={
                <div className="flex w-full">
                  <span className="flex-1">{t('Your password')}</span>
                  <LinkButton variant="TextInline" href={PATHS.forgotPassword} text={t('Forgot password?')} />
                </div>
              }
            >
              <PasswordInput
                error={!!failedLoginMsg}
                value={passwordInput}
                onValueChange={(val): void => {
                  setPasswordInput(val);
                }}
              />
            </FormItem>
            {failedLoginMsg && <span className=" text-s-regular text-danger-main">{failedLoginMsg}</span>}
            <div className="flex flex-grow">
              <Button
                type="submit"
                variant="Primary"
                big
                grow
                text={t('Login')}
                disabled={isProcessing || isNotAllEntered}
                isLoading={isProcessing}
              />
            </div>
          </form>
        </div>
      </Card>

      <div className="flex flex-row items-center gap-1 text-m-regular text-neutral-90">
        <span>{t("Don't have an account?")}</span>
        <LinkButton
          variant="TextInline"
          href={registerLinkPath}
          text={t('Create new account')}
          state={location.state}
        />
      </div>
    </div>
  );
};
