import classNames from 'classnames';
import { SearchLoupQuestionIcon, StopIcon, SuccessIcon, WarningIcon } from 'assets/icons';
import { Icon, SvgComponent } from 'controls/icon/icon';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { PostProcessingStates } from 'generated/post-processing-states';
import { tw } from 'helper/css.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { PostProcessingResultDto, ReplicationStatus } from 'services/replicator/replicator.service';

const _REPLICATIONSTATE_ICON_DEF: Record<
  PostProcessingStates,
  { icon?: SvgComponent; text: string; colorClass?: string }
> = {
  [PostProcessingStates.Initialized]: { text: 'Publish in progress...' },
  [PostProcessingStates.Finished]: {
    icon: SuccessIcon,
    text: 'Publish successful',
    colorClass: tw`text-success-main`,
  },
  [PostProcessingStates.Failed]: {
    icon: WarningIcon,
    text: 'Publish failed',
    colorClass: tw`text-warning-main`,
  },
};

export const ReplicationStateIconPublish: React.FC<{
  status: ReplicationStatus;
  hasAutoPublish: boolean | undefined;
  showOnlyIfFailed?: boolean;
}> = ({ status, hasAutoPublish, showOnlyIfFailed }) => {
  const { t } = useTypedTranslation();

  const initializedCount = _getAutoPublishCount(status.autoPublishResult, PostProcessingStates.Initialized);
  const finishedCount = _getAutoPublishCount(status.autoPublishResult, PostProcessingStates.Finished);
  const failedCount = _getAutoPublishCount(status.autoPublishResult, PostProcessingStates.Failed);

  const def = !hasAutoPublish
    ? { text: t('No publish selected') }
    : failedCount > 0
      ? _REPLICATIONSTATE_ICON_DEF[PostProcessingStates.Failed]
      : initializedCount > 0
        ? _REPLICATIONSTATE_ICON_DEF[PostProcessingStates.Initialized]
        : finishedCount > 0
          ? _REPLICATIONSTATE_ICON_DEF[PostProcessingStates.Finished]
          : { text: t('Waiting...') };

  const isCancelledAndCompleted = status.isCancelled && status.completedAt;

  const statusText = isCancelledAndCompleted
    ? t('Cancelled')
    : status.isCancelled
      ? `${t('Cancelled')}: ${t(def.text)}`
      : t(def.text);

  const iconSvg = isCancelledAndCompleted ? StopIcon : def.icon;

  if (showOnlyIfFailed && failedCount === 0) {
    return null;
  }

  return (
    <div data-cmptype="ReplicationStateIcon" className={classNames('flex items-center gap-2', def.colorClass)}>
      <div className="flex items-center gap-1">
        {iconSvg && <Icon Svg={iconSvg} className="w-5" />}
        {statusText}
        {failedCount > 0 && (
          <Icon
            Svg={SearchLoupQuestionIcon}
            className="w-5 hover:text-warning-hover"
            title={
              <TooltipContent
                detail={t(
                  "Your configurations and assets were copied but one or more publish operations failed. This can happen if assigned asset bundles aren't available or an element was locked.\n\nYou can still publish the failed ones manually."
                )}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

/**
 * Get the sum of entities with the same state
 */
function _getAutoPublishCount(result: PostProcessingResultDto | null, state: PostProcessingStates): number {
  if (!result) {
    return 0;
  }
  return (
    Object.values(result.configurators).filter(c => c === state).length +
    Object.values(result.assetBundles).filter(c => c === state).length
  );
}
