import MuiTableRow, { TableRowProps as MuiTableRowProps } from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { NAMED_SHADOW } from 'styles/mui-theme';
import { TableCellProps } from 'controls/table/table-cell';

const StyledTableRow = styled(MuiTableRow)(({ theme }) => ({
  'boxShadow': theme.shadows[NAMED_SHADOW.TableRow],

  '&:last-child': {
    boxShadow: 'none',
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export type TableRowProps = Pick<MuiTableRowProps, 'onMouseEnter' | 'onMouseLeave' | 'onClick'> & {
  className?: string;
  children: React.ReactElement<TableCellProps> | boolean | (React.ReactElement<TableCellProps> | boolean)[];
};

export const TableRow: React.FC<TableRowProps> = ({ children, className, ...props }) => {
  return (
    <StyledTableRow data-cmptype="TableRow" className={className} {...props}>
      {children}
    </StyledTableRow>
  );
};
