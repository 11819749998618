import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-pro';
import { EditIconInBox } from 'assets/icons';
import { Icon } from 'controls/icon/icon';
import { cn } from 'helper/css.helper';
import { CompanyUser } from 'services/companies/companies.service';

export const renderCellWithEditButton = (
  params: GridRenderCellParams<CompanyUser, any, any, GridTreeNodeWithRender>,
  displayValue?: React.ReactNode
): JSX.Element => {
  const { value, api, field, id, isEditable } = params;
  return (
    <div className="flex min-w-0 grow items-center justify-between gap-2">
      <span className={cn('min-w-0 group-hover:overflow-hidden', { 'text-neutral-60': value === undefined })}>
        {displayValue !== undefined ? displayValue : params.value}
      </span>
      <Icon
        Svg={EditIconInBox}
        className={cn('hidden h-5 w-5 text-primary-main', { 'group-hover:block': isEditable })}
        onClick={(): void => {
          if (isEditable) {
            api.startCellEditMode({ field, id });
          }
        }}
      />
    </div>
  );
};
