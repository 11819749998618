import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { DeleteBinRefreshIcon, EyeShowVisibleIcon, LockIcon, PublishedCfgrIcon } from 'assets/icons';
import { Icon } from 'controls/icon/icon';
import { DropdownSearch } from 'controls/select/dropdown-search';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { isReplicatorLockStatus } from 'services/company-data/company-data.service';
import { CfgrRouteParams } from 'services/routes/common-routeparams.service';
import { PATHS, findValidPathPattern, generateClosestDynamicRoute } from 'services/routes/paths.service';
import {
  selectAllCompanyCfgrs,
  selectSelectedConfigurator,
  setCfgrSelection,
} from 'slices/company-data/company-data.slice';

export const CfgrPicker: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();
  const { pathname } = useLocation();

  const routeParams = useParams<CfgrRouteParams>();

  const cfgrs = useAppSelector(selectAllCompanyCfgrs);
  const cfgrsSorted = [...cfgrs].sort((a, b) => a.displayName.localeCompare(b.displayName));

  const selectedCfgr = useAppSelector(selectSelectedConfigurator);

  const pathPattern = useMemo(() => {
    const foundPattern = findValidPathPattern(pathname, 'cfgrId');
    return foundPattern ?? PATHS.dashboardCompanyCfgr;
  }, [pathname]);

  const onCfgrChange = (value: string): void => {
    dispatch(setCfgrSelection(value));
  };

  return (
    <DropdownSearch
      data-cmptype="CfgrPicker"
      items={cfgrsSorted.map(c => {
        // create icons depending on the state of the configurator
        const mainIcon = c.lockStatus ? (
          <Icon
            Svg={LockIcon}
            className="w-6"
            title={
              <TooltipContent
                header={t('Locked configurator')}
                detail={isReplicatorLockStatus(c.lockStatus) ? t('Replication operation is in progress...') : ''}
              />
            }
            titleProps={{ delay: 500, placement: 'right' }}
          />
        ) : c.isPublished ? (
          <Icon
            Svg={PublishedCfgrIcon}
            className="w-6 text-brand-green2"
            title={t('Configurator published')}
            titleProps={{ delay: 500, placement: 'right' }}
          />
        ) : c.deleteAfter ? (
          <Icon
            Svg={DeleteBinRefreshIcon}
            className="w-6 text-warning-main"
            title={t('Configurator deleted')}
            titleProps={{ delay: 500, placement: 'right' }}
          />
        ) : null;
        const readonlyIcon = c.cfgrFeatures.readonly ? (
          <Icon
            Svg={EyeShowVisibleIcon}
            className="w-6"
            title={t('Read-only configurator')}
            titleProps={{ delay: 500, placement: 'right' }}
          />
        ) : null;

        return {
          id: c.id,
          displayName: c.displayName,
          route: routeParams.companyId ? generateClosestDynamicRoute(pathPattern, routeParams, 'cfgrId', c.id) : '',
          endAdornment:
            mainIcon && readonlyIcon ? (
              // both icons have to be displayed, make sure to align them smoothly with some gap
              <div className="flex flex-row gap-1">
                {mainIcon}
                {readonlyIcon}
              </div>
            ) : (
              // only one icon shown (or none)
              mainIcon ?? readonlyIcon
            ),
        };
      })}
      dropdownHeader={t('Configurators')}
      defaultButtonText={cfgrs.length > 0 ? t('[Select configurator]') : t('No configurators')}
      disabled={cfgrs.length === 0}
      onChange={onCfgrChange}
      selected={selectedCfgr?.id}
    ></DropdownSearch>
  );
};
