import { ConfigurationsIcon, DraftsIcon } from 'assets/icons';
import { CfgrMainStateCard } from 'components/cfgrs/cfgr-main-state-card';
import { MetricsCard } from 'components/cfgrs/metrics/metrics-card';
import { LinkButton } from 'controls/button/link-button';
import { CbnCaptionCard } from 'controls/cbn-card/implementations/cbn-caption-card';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { buildCfgrsPath } from 'services/routes/cfgrs-routes.service';
import { selectSelectedConfigurator } from 'slices/company-data/company-data.slice';
import { CompanyPermissions } from 'slices/permission/permission.slice';

export const Dashboard: React.FC = () => {
  const { t } = useTypedTranslation();

  // get data to show in the dashboard from state
  const selectedCfgr = useAppSelector(selectSelectedConfigurator);
  const cfgrIsDeleted = !!selectedCfgr?.deleteAfter;
  const hasCfgnsPermission = usePermission(CompanyPermissions.GetConfigurations, selectedCfgr?.companyId);

  return (
    <div data-cmptype="Dashboard">
      <div className="flex flex-col justify-evenly gap-5">
        {selectedCfgr && (
          <>
            <MetricsCard />
            <div className="grid auto-rows-auto grid-cols-[2fr_1fr] gap-7">
              <div className="row-span-2 flex flex-col">
                <CfgrMainStateCard variant="Dashboard" />
              </div>

              {hasCfgnsPermission && (
                <CbnCaptionCard
                  headerText={t('Configurations')}
                  subText={t('View previous configurations')}
                  Svg={ConfigurationsIcon}
                  action={
                    <LinkButton
                      text={t('Open')}
                      href={buildCfgrsPath('cfgninsights', selectedCfgr.companyId, selectedCfgr.id)}
                      disabled={cfgrIsDeleted}
                      title={cfgrIsDeleted ? t('This configurator has been deleted') : ''}
                    />
                  }
                />
              )}
              <CbnCaptionCard
                headerText={t('Drafts')}
                subText={t('Edit your configurator')}
                Svg={DraftsIcon}
                action={
                  <LinkButton
                    text={t('Open')}
                    href={buildCfgrsPath('drafts', selectedCfgr.companyId, selectedCfgr.id)}
                  />
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
