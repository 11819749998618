import { AssetMetadata } from 'components/asset-editor/details/asset-metadata';
import { Button } from 'controls/button/button';
import { CbnAlert } from 'controls/cbn-alert/cbn-alert';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { StateIcon } from 'controls/icon/state-icon';
import { AssetTypes } from 'generated/asset-types';
import { useNavigateToAssetEditor } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { clearUploadState, selectSelectedAsset } from 'slices/assets/assets.slice';

export const AssetBodyUploadError: React.FC = () => {
  const { t } = useTypedTranslation();
  const navigateToAssetEditor = useNavigateToAssetEditor();
  const selectedAsset = useAppSelector(selectSelectedAsset)!;
  const dispatch = useAppDispatch();

  const onDismissClicked = (): void => {
    const { companyId, bundleId, bundleVersion, folderId } = selectedAsset.path;

    dispatch(clearUploadState(selectedAsset.path));

    if (!selectedAsset.existsOnServer) {
      navigateToAssetEditor({ companyId, bundleId, bundleVersion, assetId: folderId }, true);
    }
  };

  return (
    <CbnCard>
      <CbnCardHeader
        title={
          <div className="flex flex-row items-center gap-2">
            <StateIcon variant="Warning" noBckgr />
            <h4>{t('Upload failed')}</h4>
          </div>
        }
      />
      <AssetMetadata
        assetType={selectedAsset.type}
        metadata={[
          { text: t('Type'), value: AssetTypes[selectedAsset.type] },
          { text: t('Upload progress'), value: t('Cancelled') },
        ]}
      />

      <CbnCardBody>
        <CbnAlert
          severity="warning"
          caption={t('Upload error')}
          content={
            <div className="flex flex-col gap-5">
              {selectedAsset.errorText}
              <div className="flex justify-center gap-5">
                {<Button grow text={t('Dismiss')} variant="Secondary" onClick={onDismissClicked} />}
              </div>
            </div>
          }
        />
      </CbnCardBody>
    </CbnCard>
  );
};
