import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FileAssetIcon, FolderAssetCollapsedIcon, PlusIcon } from 'assets/icons';
import { ASSETLINK_TYPE_MAP } from 'components/asset-editor/assetlink-type-map';
import { AssetBodyImage } from 'components/asset-editor/details/asset-body/asset-body-image';
import { AssetDefaultActions } from 'components/asset-editor/details/asset-details-actions';
import { AssetDetailsHeader } from 'components/asset-editor/details/asset-details-header';
import { AssetMetadataEntry } from 'components/asset-editor/details/asset-metadata';
import { AssetCreateExcelDatasourceModal } from 'components/asset-editor/details/modals/asset-create-excel-datasource-modal';
import { Button } from 'controls/button/button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { Icon } from 'controls/icon/icon';
import {
  EXCEL_DATASOURCE_FILE_ENDINGS,
  getExtensionFromAssetFilenameOrUrl,
  splitFolderAndAssetIdFromSourceString,
} from 'helper/assets/assets.helper';
import { getReadableFileSize } from 'helper/file/file.helper';
import { useIsAssetBundleEditable } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { buildAssetEditorPath } from 'services/routes/asset-editor-routes.service';
import { FileAsset, selectLinksOfSelectedAsset, selectSelectedAsset } from 'slices/assets/assets.slice';

export const AssetBodyFile: React.FC = () => {
  const { t } = useTypedTranslation();
  const isAssetBundleEditable = useIsAssetBundleEditable();

  const {
    path: { companyId, bundleId, bundleVersion, assetId },
    url,
    size,
  } = useAppSelector(selectSelectedAsset) as FileAsset;

  const [showCreateDatasourceModal, setShowCreateDatasourceModal] = useState(false);

  const assetLinks = useAppSelector(selectLinksOfSelectedAsset);
  const sortedAssetLinks = [...assetLinks].sort((a, b) => a.target.localeCompare(b.target));

  const extension = getExtensionFromAssetFilenameOrUrl(url);
  const fileSize = getReadableFileSize(size);

  let actions: (AssetDefaultActions | React.ReactElement)[] | undefined;
  if (EXCEL_DATASOURCE_FILE_ENDINGS.includes(extension)) {
    const createDSButton = (
      <Button
        text={t('Create data source')}
        variant="Outlined"
        Svg={PlusIcon}
        onClick={(): void => setShowCreateDatasourceModal(true)}
      />
    );
    actions = isAssetBundleEditable ? ['Rename', createDSButton, 'Copy', 'Move', 'Download', 'Delete'] : ['Download'];
  }

  const metadata: AssetMetadataEntry[] = [
    { text: t('Type'), value: extension },
    { text: t('Size'), value: fileSize },
  ];

  // Provide a basic preview because SVGs can't be created as image assets (yet)
  if (extension === 'svg') {
    return <AssetBodyImage />;
  }

  return (
    <>
      <CbnCard data-cmptype="AssetBodyFile">
        <AssetDetailsHeader actions={actions} metadata={metadata} />

        <CbnCardBody>
          {assetLinks.length > 0 ? (
            <div className="flex flex-col gap-4 overflow-y-auto">
              <h4>{t('Asset Links')}</h4>
              <ul className="flex flex-col gap-2 overflow-y-auto text-m-regular text-neutral-80">
                {sortedAssetLinks.map(assetLink => {
                  const { folderId: linkedFolderId, assetId: linkedAssetId } = splitFolderAndAssetIdFromSourceString(
                    assetLink.target
                  );

                  // NOTE: styling re-used from asset bulk edit, maybe create a dedicated component for this
                  return (
                    <li key={assetLink.target} className="flex items-center gap-2">
                      {linkedFolderId && (
                        <>
                          {linkedFolderId}
                          <Icon Svg={FolderAssetCollapsedIcon} className="w-3" />
                        </>
                      )}
                      <Icon Svg={ASSETLINK_TYPE_MAP[assetLink.type].TargetIcon} className="w-5" />
                      <Link
                        to={buildAssetEditorPath(companyId, bundleId, bundleVersion, linkedFolderId, linkedAssetId)}
                        key={assetLink.target}
                        className="flex h-full w-full items-center underline hover:no-underline"
                      >
                        {linkedAssetId}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <div className="flex grow flex-col items-center">
              <Icon Svg={FileAssetIcon} className="h-[150px]"></Icon>
              <h2>{assetId}</h2>
            </div>
          )}
        </CbnCardBody>
      </CbnCard>

      {showCreateDatasourceModal && (
        <AssetCreateExcelDatasourceModal onClose={(): void => setShowCreateDatasourceModal(false)} />
      )}
    </>
  );
};
