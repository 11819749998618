import React, { useState } from 'react';
import * as AllIcons from 'assets/icons';
import { DebugTreeView } from 'components/debug/debug-tree-view';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { Autocomplete } from 'controls/autocomplete/autocomplete';
import { AutocompleteMultiSelect } from 'controls/autocomplete/autocomplete-multi-select';
import { Button } from 'controls/button/button';
import { FileSelectButton } from 'controls/button/file-select-button';
import { LinkButton } from 'controls/button/link-button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { CbnCollapsibleCardHeader } from 'controls/cbn-card/cbn-collapsible-card-header';
import { CbnResizablePanel } from 'controls/cbn-resizeable-panel/cbn-resizeable-panel';
import { CbnResizablePanelGroup } from 'controls/cbn-resizeable-panel/cbn-resizeable-panel-group';
import { CbnResizablePanelSeparator } from 'controls/cbn-resizeable-panel/cbn-resizeable-panel-separator';
import { Checkbox } from 'controls/checkbox/checkbox';
import { StateIcon } from 'controls/icon/state-icon';
import { NumberInput } from 'controls/input/number-input';
import { TextInput } from 'controls/input/text-input';
import { List } from 'controls/list/list';
import { ListItem } from 'controls/list/list-item';
import { RadioButtonGroup } from 'controls/radio-button/radio-button-group';
import { Select } from 'controls/select/select';
import { Switch } from 'controls/switch/switch';
import { Tooltip } from 'controls/tooltip/tooltip';
import { PATHS } from 'services/routes/paths.service';

const CoffeeIcon = AllIcons.CoffeeIcon;

const CodeMarker: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <mark className="bg-neutral-40 px-1 py-[2px] text-signal">{children}</mark>;
};

export const ControlOverview: React.FC = () => {
  const [activeDialogId, setActiveDialogId] = useState(0);

  return (
    <div className="flex flex-col gap-6 p-6">
      {/*
      Further ideas for this:
        - Somehow make this accessible for our designers as well on portal (maybe create dedicated page etc.)
        - Show icon names and original file name (if somehow possible).
          Not sure if needed as "always visible text" or better only as hover text.
        - Sort by original file name (if available at some point) to easily spot icons which we might have imported
          twice with different names.
        - Make it searchable (by icon name and original file name)
      */}
      <CbnCard isCollapsible>
        <CbnCollapsibleCardHeader title="All app icons" />
        <CbnCardBody>
          <div className="grid grid-cols-[repeat(auto-fill,32px)] gap-2">
            {Object.keys(AllIcons).map((iconName, index) => {
              const Icon = AllIcons[iconName as keyof typeof AllIcons];
              return (
                <Tooltip key={iconName} title={iconName}>
                  <Icon key={iconName} />
                </Tooltip>
              );
            })}
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="Headings" />
        <CbnCardBody>
          <div className="flex gap-5">
            <div>
              <h1>Heading / L [h1]</h1>
              <h2>Heading / M [h2]</h2>
              <h3>Heading / S [h3]</h3>
              <h4>Heading / XS [h4]</h4>
            </div>
            <div>
              <div className="text-l-medium">Text L / Medium</div>
              <div className="text-l-regular">Text L / Regular</div>
              <div className="text-m-medium">Text M / Medium</div>
              <div className="text-m-regular">Text M / Regular</div>
              <div className="text-s-medium">Text S / Medium</div>
              <div className="text-s-regular">Text S / Regular</div>
            </div>
          </div>
          <div className="mt-5 flex items-center text-s-regular">
            <StateIcon variant="Info" noBckgr />
            <span>
              Always prefer <CodeMarker>&lt;h1&gt; - &lt;h4&gt;</CodeMarker>&nbsp;over&nbsp;
              <CodeMarker>classname="text-heading-..."</CodeMarker>
            </span>
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="Buttons" />
        <CbnCardBody>
          <div className="grid w-full grid-cols-7 items-center gap-6">
            <span className="border-b text-l-regular">Small Text only</span>
            <span className="border-b text-l-regular">Small Icon and Text</span>
            <span className="border-b text-l-regular">Small Icon only</span>
            <span className="border-b text-l-regular">Big Text only</span>
            <span className="border-b text-l-regular">Big Icon and Text</span>
            <span className="border-b text-l-regular">Big Icon only</span>
            <span className="border-b text-l-regular">Disabled</span>

            <Button text="Primary" />
            <Button text="Primary" Svg={CoffeeIcon} />
            <Button ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <Button big text="Primary Big" />
            <Button big text="Primary Big" Svg={CoffeeIcon} />
            <Button big ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <Button disabled text="Primary Disabled" Svg={CoffeeIcon} />

            <Button variant="Secondary" text="Secondary" />
            <Button variant="Secondary" text="Secondary" Svg={CoffeeIcon} />
            <Button variant="Secondary" ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <Button variant="Secondary" big text="Secondary Big" />
            <Button variant="Secondary" big text="Secondary Big" Svg={CoffeeIcon} />
            <Button variant="Secondary" big ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <Button variant="Secondary" disabled text="Secondary Disabled" Svg={CoffeeIcon} />

            <Button variant="Outlined" text="Outlined" />
            <Button variant="Outlined" text="Outlined" Svg={CoffeeIcon} />
            <Button variant="Outlined" ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <Button variant="Outlined" big text="Outlined Big" />
            <Button variant="Outlined" big text="Outlined Big" Svg={CoffeeIcon} />
            <Button variant="Outlined" big ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <Button variant="Outlined" disabled text="Outlined Disabled" Svg={CoffeeIcon} />

            <Button variant="Text" text="Text" />
            <Button variant="Text" text="Text" Svg={CoffeeIcon} />
            <Button variant="Text" ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <Button variant="Text" big text="Text Big" />
            <Button variant="Text" big text="Text Big" Svg={CoffeeIcon} />
            <Button variant="Text" big ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <Button variant="Text" disabled text="Text Disabled" Svg={CoffeeIcon} />
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="Link Buttons" />
        <CbnCardBody>
          <div className="grid w-full grid-cols-7 items-center gap-6">
            <span className="border-b text-l-regular">Small Text only</span>
            <span className="border-b text-l-regular">Small Icon and Text</span>
            <span className="border-b text-l-regular">Small Icon only</span>
            <span className="border-b text-l-regular">Big Text only</span>
            <span className="border-b text-l-regular">Big Icon and Text</span>
            <span className="border-b text-l-regular">Big Icon only</span>
            <span className="border-b text-l-regular">Disabled</span>

            <LinkButton text="Primary (to configurators)" href={PATHS.configurators} />
            <LinkButton href="#" text="Primary" Svg={CoffeeIcon} />
            <LinkButton href="#" ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <LinkButton href="#" big text="Primary Big" />
            <LinkButton href="#" big text="Primary Big" Svg={CoffeeIcon} />
            <LinkButton href="#" big ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <LinkButton
              disabled
              text="Primary Disabled"
              ariaLabel={'Coffee'}
              Svg={CoffeeIcon}
              href={PATHS.configurators}
            />

            <LinkButton href="#" variant="Secondary" text="Secondary" />
            <LinkButton href="#" variant="Secondary" text="Secondary" Svg={CoffeeIcon} />
            <LinkButton href="#" variant="Secondary" ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <LinkButton href="#" variant="Secondary" big text="Secondary Big" />
            <LinkButton href="#" variant="Secondary" big text="Secondary Big" Svg={CoffeeIcon} />
            <LinkButton href="#" variant="Secondary" big ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <LinkButton href="#" variant="Secondary" disabled text="Secondary Disabled" Svg={CoffeeIcon} />

            <LinkButton href="#" variant="Outlined" text="Outlined" />
            <LinkButton href="#" variant="Outlined" text="Outlined" Svg={CoffeeIcon} />
            <LinkButton href="#" variant="Outlined" ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <LinkButton href="#" variant="Outlined" big text="Outlined Big" />
            <LinkButton href="#" variant="Outlined" big text="Outlined Big" Svg={CoffeeIcon} />
            <LinkButton href="#" variant="Outlined" big ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <LinkButton href="#" variant="Outlined" disabled text="Outlined Disabled" Svg={CoffeeIcon} />

            <LinkButton href="#" variant="Text" text="Text" />
            <LinkButton href="#" variant="Text" text="Text" Svg={CoffeeIcon} />
            <LinkButton href="#" variant="Text" ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <LinkButton href="#" variant="Text" big text="Text Big" />
            <LinkButton href="#" variant="Text" big text="Text Big" Svg={CoffeeIcon} />
            <LinkButton href="#" variant="Text" big ariaLabel={'Coffee'} Svg={CoffeeIcon} />
            <LinkButton href="#" variant="Text" disabled text="Text Disabled" Svg={CoffeeIcon} />
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="File Select Buttons" />
        <CbnCardBody>
          <div className="grid w-full grid-cols-7 items-center gap-6">
            <span className="border-b text-l-regular">Small Text only</span>
            <span className="border-b text-l-regular">Small Icon and Text</span>
            <span className="border-b text-l-regular">Small Icon only</span>
            <span className="border-b text-l-regular">Big Text only</span>
            <span className="border-b text-l-regular">Big Icon and Text</span>
            <span className="border-b text-l-regular">Big Icon only</span>
            <span className="border-b text-l-regular">Disabled</span>

            <FileSelectButton text="Primary" />
            <FileSelectButton text="Primary" Svg={CoffeeIcon} />
            <FileSelectButton ariaLabel="Coffee" Svg={CoffeeIcon} />
            <FileSelectButton big text="Primary Big" />
            <FileSelectButton big text="Primary Big" Svg={CoffeeIcon} />
            <FileSelectButton big ariaLabel="Coffee" Svg={CoffeeIcon} />
            <FileSelectButton disabled text="Primary Disabled" Svg={CoffeeIcon} />

            <FileSelectButton variant="Secondary" text="Secondary" />
            <FileSelectButton variant="Secondary" text="Secondary" Svg={CoffeeIcon} />
            <FileSelectButton variant="Secondary" ariaLabel="Coffee" Svg={CoffeeIcon} />
            <FileSelectButton variant="Secondary" big text="Secondary Big" />
            <FileSelectButton variant="Secondary" big text="Secondary Big" Svg={CoffeeIcon} />
            <FileSelectButton variant="Secondary" big ariaLabel="Coffee" Svg={CoffeeIcon} />
            <FileSelectButton variant="Secondary" disabled text="Secondary Disabled" Svg={CoffeeIcon} />

            <FileSelectButton variant="Outlined" text="Outlined" />
            <FileSelectButton variant="Outlined" text="Outlined" Svg={CoffeeIcon} />
            <FileSelectButton variant="Outlined" ariaLabel="Coffee" Svg={CoffeeIcon} />
            <FileSelectButton variant="Outlined" big text="Outlined Big" />
            <FileSelectButton variant="Outlined" big text="Outlined Big" Svg={CoffeeIcon} />
            <FileSelectButton variant="Outlined" big ariaLabel="Coffee" Svg={CoffeeIcon} />
            <FileSelectButton variant="Outlined" disabled text="Outlined Disabled" Svg={CoffeeIcon} />

            <FileSelectButton variant="Text" text="Text" />
            <FileSelectButton variant="Text" text="Text" Svg={CoffeeIcon} />
            <FileSelectButton variant="Text" ariaLabel="Coffee" Svg={CoffeeIcon} />
            <FileSelectButton variant="Text" big text="Text Big" />
            <FileSelectButton variant="Text" big text="Text Big" Svg={CoffeeIcon} />
            <FileSelectButton variant="Text" big ariaLabel="Coffee" Svg={CoffeeIcon} />
            <FileSelectButton variant="Text" disabled text="Text Disabled" Svg={CoffeeIcon} />
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="Switch" />
        <CbnCardBody>
          <div className="grid w-full grid-cols-5 items-center gap-6">
            <span className="border-b text-l-regular">Uncontrolled</span>
            <span className="border-b text-l-regular">Checked</span>
            <span className="border-b text-l-regular">Unchecked</span>
            <span className="border-b text-l-regular">Disabled</span>
            <span className="border-b text-l-regular">Disabled Checked</span>
            <Switch />
            <Switch checked />
            <Switch checked={false} />
            <Switch disabled />
            <Switch disabled checked />
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="Checkbox" />
        <CbnCardBody>
          <div className="grid w-full grid-cols-7 items-center gap-6">
            <span className="border-b text-l-regular">Uncontrolled</span>
            <span className="border-b text-l-regular">Checked</span>
            <span className="border-b text-l-regular">Unchecked</span>
            <span className="border-b text-l-regular">Indeterminate</span>
            <span className="border-b text-l-regular">Disabled</span>
            <span className="border-b text-l-regular">Disabled Indeterminate</span>
            <span className="border-b text-l-regular">Disabled Checked</span>
            <Checkbox />
            <Checkbox checked={true} />
            <Checkbox checked={false} />
            <Checkbox indeterminate />
            <Checkbox disabled />
            <Checkbox indeterminate disabled />
            <Checkbox checked={true} disabled />
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="Radio Buttons" />
        <CbnCardBody>
          <div className="grid w-full grid-cols-2 items-center gap-6">
            <span className="border-b text-l-regular">Default</span>
            <span className="border-b text-l-regular">Disabled</span>
            <RadioButtonGroup
              entries={[
                { value: 1, text: 'Entry 1' },
                { value: 2 },
                { value: 3, text: 'Entry 3', disabled: true },
                { value: 4, text: 'Entry 4' },
              ]}
              name="radioGroup1"
              value={1}
            />
            <RadioButtonGroup
              entries={[
                { value: 1, text: 'Entry 1', disabled: true },
                { value: 2, text: 'Entry 2', disabled: true },
                { value: 3, text: 'Entry 3', disabled: true },
                { value: 4, text: 'Entry 4', disabled: true },
              ]}
              name="radioGroup2"
              value={2}
            />
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="Text Inputs" />
        <CbnCardBody>
          <div className="grid w-full grid-cols-7 items-center gap-6">
            <span className="border-b text-l-regular">Default</span>
            <span className="border-b text-l-regular">With value</span>
            <span className="border-b text-l-regular">Disabled</span>
            <span className="border-b text-l-regular">Warning</span>
            <span className="border-b text-l-regular">Error</span>
            <span className="border-b text-l-regular">Error w/ adornment</span>
            <span className="border-b text-l-regular">Error w/ helperText</span>

            <TextInput placeholder="Default" />
            <TextInput value="My Text" />
            <TextInput value="My disabled text" disabled />
            <TextInput value="Warning" warning />
            <TextInput placeholder="Error" error />
            <TextInput placeholder="Error" error endAdornment={<StateIcon variant="Error" noBckgr />} />
            <TextInput placeholder="Error" error helperText="This is a helper text" />
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="Number Inputs" />
        <CbnCardBody>
          <div className="grid w-full grid-cols-7 items-center gap-6">
            <span className="border-b text-l-regular">Default</span>
            <span className="border-b text-l-regular">Disabled</span>
            <span className="border-b text-l-regular">Valid</span>
            <span className="border-b text-l-regular">Invalid pattern</span>
            <span className="border-b text-l-regular">Error</span>
            <span className="border-b text-l-regular">Error w/ adornment</span>
            <span className="border-b text-l-regular">Error w/ helpertext</span>

            <NumberInput placeholder="Default" inputMode="decimal" />
            <NumberInput placeholder="Disabled" inputMode="decimal" disabled />
            <NumberInput value={1.232} inputMode="decimal" />
            <NumberInput inputMode="decimal" value={-15} onlyPositive />
            <NumberInput placeholder="Error" inputMode="decimal" error />
            <NumberInput
              placeholder="Error"
              inputMode="decimal"
              error
              endAdornment={<StateIcon variant="Error" noBckgr />}
            />
            <NumberInput placeholder="Error" inputMode="decimal" error helperText="this is a helper text" />
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="Select" />
        <CbnCardBody>
          <div className="grid w-full grid-cols-4 items-center gap-6">
            <span className="border-b text-l-regular">Default</span>
            <span className="border-b text-l-regular">Disabled</span>
            <span className="border-b text-l-regular">Warning</span>
            <span className="border-b text-l-regular">Error</span>

            <Select
              options={[
                { value: 'val1', text: 'Option 1' },
                { value: 'Option 2' },
                { value: 'Option 3', disabled: true },
                { value: 'Option 4' },
                { value: 'Option 5, which has some really long text to check text overflow' },
              ]}
              value={'Option 2'}
            ></Select>
            <Select options={[{ value: 'Option 1' }, { value: 'Option 2' }, { value: 'Option 3' }]} disabled></Select>
            <Select
              value={'Dummy option'}
              options={[
                { value: 'Dummy option', disabled: true },
                { value: 'Option 1' },
                { value: 'Option 2' },
                { value: 'Option 3' },
              ]}
              warning
            ></Select>
            <Select
              value={'Invalid option'}
              options={[
                { value: 'Invalid option', disabled: true },
                { value: 'Option 1' },
                { value: 'Option 2' },
                { value: 'Option 3' },
              ]}
              error
            ></Select>
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="Autocomplete" />
        <CbnCardBody>
          <div className="grid w-full grid-cols-[1fr_1fr_1fr_250px] items-center gap-6">
            <span className="border-b text-l-regular">Default</span>
            <span className="border-b text-l-regular">Not clearable</span>
            <span className="border-b text-l-regular">Multi select</span>
            <span className="border-b text-l-regular">Narrow</span>

            <Autocomplete
              options={[
                { value: 'val1', text: 'Option 1' },
                { value: 'Option 2' },
                { value: 'Option 3', disabled: true },
                { value: 'Option 4' },
                { value: 'Option 5, which has some really long text to check text overflow' },
              ]}
              value={'Option 2'}
            ></Autocomplete>
            <Autocomplete
              options={[{ value: 'Option 1' }, { value: 'Option 2' }, { value: 'Option 3' }]}
              value={'Option 1'}
              disableClearable
            ></Autocomplete>
            <AutocompleteMultiSelect
              options={[
                { value: 'val1', text: 'Option 1' },
                { value: 'Option 2' },
                { value: 'Option 3', disabled: true },
                { value: 'Option 4' },
              ]}
              value={['val1', 'Option 2']}
            />
            <Autocomplete
              options={[
                { value: 'val1', text: 'Option 1' },
                { value: 'Option 2' },
                { value: 'Option 3', disabled: true },
                { value: 'Option 4' },
                { value: 'Option 5', text: 'Option 5, which has some really long text to check text overflow' },
              ]}
              value={'Option 5'}
            ></Autocomplete>
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="Cards" />
        <CbnCardBody>
          <div className="grid w-full grid-cols-3 items-center gap-6">
            <CbnCard>
              <CbnCardHeader title="Basic Card" />
              <CbnCardBody>Content</CbnCardBody>
            </CbnCard>

            <CbnCard>
              <CbnCardHeader title="Card with Sub text and Actions" subText="Sub" actions={<Button text="Action" />} />
              <CbnCardBody>Content</CbnCardBody>
            </CbnCard>

            <CbnCard>
              <div className="flex grow bg-primary-border p-4">Card with custom header</div>
              <CbnCardBody>Content</CbnCardBody>
            </CbnCard>

            <CbnCard isCollapsible>
              <CbnCollapsibleCardHeader
                title="Basic collapsible card"
                subText="Description text"
                icon={AllIcons.ScheduledIcon}
                iconActive={true}
              />
              <CbnCardBody>Collapsible content</CbnCardBody>
            </CbnCard>
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="Lists" />
        <CbnCardBody>
          <div className="grid w-full grid-cols-2 items-center gap-6">
            <span className="border-b text-l-regular">Basic List</span>
            <span className="border-b text-l-regular">List with Header and Actions</span>

            <List>
              <ListItem>List Entry 1</ListItem>
              <ListItem>List Entry 2</ListItem>
              <ListItem>List Entry 3</ListItem>
            </List>

            <List headerText="List Header" actions={<Button text="Action" />}>
              <ListItem>List Entry 1</ListItem>
              <ListItem>List Entry 2</ListItem>
              <ListItem>List Entry 3</ListItem>
            </List>
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="Modal Dialog" />
        <CbnCardBody>
          <div className="grid w-full grid-cols-4 items-center gap-6">
            <span className="border-b text-l-regular">Success Dialog</span>
            <span className="border-b text-l-regular">Info Dialog</span>
            <span className="border-b text-l-regular">Warning Dialog, Large Content</span>
            <span className="border-b text-l-regular">Error Dialog, Confirm Only</span>

            <Button text="Open Success Dialog" onClick={setActiveDialogId.bind(this, 1)} />
            <Button text="Open Info Dialog" onClick={setActiveDialogId.bind(this, 2)} />
            <Button text="Open Warning Dialog" onClick={setActiveDialogId.bind(this, 3)} />
            <Button text="Open Error Dialog" onClick={setActiveDialogId.bind(this, 4)} />

            {activeDialogId === 1 && (
              <ModalDialog
                data-cmptype="SuccessExample"
                variant="Success"
                header="Success"
                actions={{ onConfirm: setActiveDialogId.bind(this, 0), onCancel: setActiveDialogId.bind(this, 0) }}
              >
                Modal Dialog Content
              </ModalDialog>
            )}
            {activeDialogId === 2 && (
              <ModalDialog
                data-cmptype="InfoExample"
                variant="Info"
                header="Info"
                actions={{ onConfirm: setActiveDialogId.bind(this, 0), onCancel: setActiveDialogId.bind(this, 0) }}
              >
                Modal Dialog Content
              </ModalDialog>
            )}
            {activeDialogId === 3 && (
              <ModalDialog
                data-cmptype="WarningExample"
                variant="Warning"
                header="Warning"
                actions={{ onConfirm: setActiveDialogId.bind(this, 0), onCancel: setActiveDialogId.bind(this, 0) }}
              >
                <div className="flex h-32 w-full items-center justify-center">Some high div</div>
              </ModalDialog>
            )}
            {activeDialogId === 4 && (
              <ModalDialog
                data-cmptype="ErrorExample"
                variant="Error"
                actionTypes="ConfirmOnly"
                header="Error"
                actions={{ onConfirm: setActiveDialogId.bind(this, 0), onCancel: setActiveDialogId.bind(this, 0) }}
              >
                Modal Dialog Content
              </ModalDialog>
            )}
          </div>
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="TreeView" />
        <CbnCardBody>
          <DebugTreeView />
        </CbnCardBody>
      </CbnCard>

      <CbnCard isCollapsible collapsed>
        <CbnCollapsibleCardHeader title="Resizeable Panel" />
        <CbnCardBody>
          <div className="h-[300px]">
            <CbnResizablePanelGroup direction="vertical" sizeMode="pixel" autoSaveId="ctrlOverviewVerticalId">
              <CbnResizablePanel>
                <CbnResizablePanelGroup direction="horizontal" sizeMode="pixel">
                  <CbnResizablePanel defaultSize={300} minSize={100}>
                    <div className="flex h-full items-center justify-center border">Default 300 Min 100</div>
                  </CbnResizablePanel>
                  <CbnResizablePanelSeparator />
                  <CbnResizablePanel>
                    <div className="flex h-full items-center justify-center border">Rest</div>
                  </CbnResizablePanel>
                  <CbnResizablePanelSeparator />
                  <CbnResizablePanel defaultSize={200} maxSize={200}>
                    <div className="flex h-full items-center justify-center border">Default 200 Max 200</div>
                  </CbnResizablePanel>
                </CbnResizablePanelGroup>
                <div className="flex h-full items-center justify-center border">Top</div>
              </CbnResizablePanel>
              <CbnResizablePanelSeparator />
              <CbnResizablePanel defaultSize={120} minSize={100} collapsedSize={50} collapsible>
                <div className="flex h-full items-center justify-center border">
                  Default 120, Min 100, Collapsed 50, Persist height
                </div>
              </CbnResizablePanel>
            </CbnResizablePanelGroup>
          </div>
        </CbnCardBody>
      </CbnCard>
    </div>
  );
};
