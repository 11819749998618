import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { globalReset } from 'actions/general.actions';
import { FetchedState } from 'helper/route/route-sync.helper';
import { getTypedTranslation } from 'hooks/i18n/i18n.hooks';
import {
  WhitelistedDomains,
  getCfgrWhitelistedDomains,
  knownUpdateWhitelistDomainErrors,
  postUpdateWhitelistedDomains,
} from 'services/cfgrs/cfgrs.service';
import { isGenericActionResult } from 'services/http/http.service';
import { RootState } from 'services/store/store.service';

/**
 * `CompanyCfgrId` is a combination of company & cfgr id where both are separated by a dot.
 * E.g. `company1.cfgr1`
 */
type CompanyCfgrKey = `${string}.${string}`;

function _createCompanyCfgrKey(companyId: string, cfgrId: string): CompanyCfgrKey {
  return `${companyId}.${cfgrId}`;
}

export type CfgrWhitelistDomainsState = {
  cfgrDomains: { [cfgrId: CompanyCfgrKey]: WhitelistedDomains };
  cfgrDomainsFetched: { [cfgrId: CompanyCfgrKey]: FetchedState };
};

const emptyState: CfgrWhitelistDomainsState = {
  cfgrDomains: {},
  cfgrDomainsFetched: {},
};

const initialState: CfgrWhitelistDomainsState = emptyState;

const SLICE_NAME = 'cfgr-whitelist-domains';

/**
 * Fetches and returns domain data from the server.
 */
export const getCfgrWhitelistDomains = createAsyncThunk<WhitelistedDomains, { companyId: string; cfgrId: string }>(
  'cfgr-whitelist-domains/getCfgrWhitelistDomains',
  async ({ companyId, cfgrId }) => {
    const resp = await getCfgrWhitelistedDomains(companyId, cfgrId);
    return resp;
  }
);

/**
 * @returns Empty string if no error occurred, otherwise an "user ready" error message.
 */
export const addNewCfgrWhitelistDomain = createAsyncThunk<
  string,
  { companyId: string; cfgrId: string; domain: string }
>('cfgr-whitelist-domains/addNewCfgrWhitelistDomain', async ({ companyId, cfgrId, domain }, thunkApi) => {
  const { t } = getTypedTranslation();
  const { cfgrWhitelistDomains: state } = thunkApi.getState() as RootState;
  const companyCfgrId = _createCompanyCfgrKey(companyId, cfgrId);
  const updatedDomains = [...state.cfgrDomains[companyCfgrId].domains, domain];

  const res = await postUpdateWhitelistedDomains(companyId, cfgrId, updatedDomains);
  const wasKnownError = isGenericActionResult(res, knownUpdateWhitelistDomainErrors);
  const errorMsg = wasKnownError ? res.Message || t('An error occurred') : '';
  return errorMsg;
});

export const removeCfgrWhitelistDomain = createAsyncThunk<void, { companyId: string; cfgrId: string; domain: string }>(
  'cfgr-whitelist-domains/removeCfgrWhitelistDomain',
  async ({ companyId, cfgrId, domain }, thunkApi) => {
    const { cfgrWhitelistDomains: state } = thunkApi.getState() as RootState;
    const companyCfgrId = _createCompanyCfgrKey(companyId, cfgrId);
    const domains = state.cfgrDomains[companyCfgrId].domains;
    const updatedDomains = domains.filter(d => d !== domain);
    await postUpdateWhitelistedDomains(companyId, cfgrId, updatedDomains, true);
  }
);

const cfgrWhitelistDomainSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(globalReset, () => emptyState)
      .addCase(getCfgrWhitelistDomains.pending, (state, { payload, meta }) => {
        const uniqueCfgrId = _createCompanyCfgrKey(meta.arg.companyId, meta.arg.cfgrId);
        const fetchedState = state.cfgrDomainsFetched[uniqueCfgrId];
        if (fetchedState !== 'Fetched') {
          // Only set if not already fetched as `getCfgrWhitelistDomains` should not re-fetch when already in store.
          state.cfgrDomainsFetched[uniqueCfgrId] = 'Pending';
        }
      })
      .addCase(getCfgrWhitelistDomains.fulfilled, (state, { payload, meta }) => {
        const uniqueCfgrId = _createCompanyCfgrKey(meta.arg.companyId, meta.arg.cfgrId);
        state.cfgrDomains[uniqueCfgrId] = payload;
        state.cfgrDomainsFetched[uniqueCfgrId] = 'Fetched';
      })
      .addCase(getCfgrWhitelistDomains.rejected, (state, { payload, meta }) => {
        const uniqueCfgrId = _createCompanyCfgrKey(meta.arg.companyId, meta.arg.cfgrId);
        delete state.cfgrDomains[uniqueCfgrId];
        state.cfgrDomainsFetched[uniqueCfgrId] = 'Aborted';
      })
      .addCase(addNewCfgrWhitelistDomain.fulfilled, (state, { payload, meta }) => {
        if (payload) return; // There was an error when adding the domain
        const uniqueCfgrId = _createCompanyCfgrKey(meta.arg.companyId, meta.arg.cfgrId);
        state.cfgrDomains[uniqueCfgrId].domains.push(meta.arg.domain);
      })
      .addCase(removeCfgrWhitelistDomain.fulfilled, (state, { payload, meta }) => {
        const uniqueCfgrId = _createCompanyCfgrKey(meta.arg.companyId, meta.arg.cfgrId);
        const domains = state.cfgrDomains[uniqueCfgrId].domains;
        state.cfgrDomains[uniqueCfgrId].domains = domains.filter(d => d !== meta.arg.domain);
      });
  },
});

export type CfgrWhitelistDomainsInfo = {
  domains: string[];
  /** Undefined/null means no limit */
  maxNoOfDomains: number | undefined;
  maxNoOfDomainsCreated: boolean;
  /**
   * `true` when there is a limit for max allowed domains set **and** no domains has been specified yet.
   * Reason: When there is a limit, at least one domain is required, otherwise the cfgr cannot be embedded **anywhere**.
   */
  requiredDomainsMissing: boolean;
  /** Also `true` when fetch was not initialized yet */
  isLoading: boolean;
};

export const selectCfgrWhitelistDomainsInfo = createSelector(
  (state: RootState) => state.companyData.selection,
  (state: RootState) => state.cfgrWhitelistDomains,
  ({ companyId, cfgrId }, cfgrWhitelistDomains): CfgrWhitelistDomainsInfo => {
    const noInfo = {
      domains: [],
      maxNoOfDomains: 0,
      maxNoOfDomainsCreated: false,
      requiredDomainsMissing: false,
      isLoading: true,
    };

    if (!companyId || !cfgrId) return noInfo;

    const companyCfgrId = _createCompanyCfgrKey(companyId, cfgrId);
    const cfgrDomains = cfgrWhitelistDomains.cfgrDomains[companyCfgrId];
    if (!cfgrDomains) return noInfo;
    const { domains, maxDomains: maxNoOfDomains } = cfgrDomains;

    const cfgrDomainsFetched = cfgrWhitelistDomains.cfgrDomainsFetched[companyCfgrId];
    const isLoading = !cfgrDomainsFetched || cfgrDomainsFetched === 'Pending';

    const unlimitedNoOfDomainsAllowed = !maxNoOfDomains;
    const maxNoOfDomainsCreated = unlimitedNoOfDomainsAllowed ? false : domains.length >= maxNoOfDomains;
    const requiredDomainsMissing = !isLoading && !unlimitedNoOfDomainsAllowed && domains.length <= 0;

    return { domains, maxNoOfDomains, maxNoOfDomainsCreated, requiredDomainsMissing, isLoading };
  }
);

export default cfgrWhitelistDomainSlice.reducer;
