import { useCallback, useEffect } from 'react';
import { ReloadIcon } from 'assets/icons';
import { GlobalErrorBody } from 'components/error-handling/global-error-body';
import { Button } from 'controls/button/button';
import { StateIcon } from 'controls/icon/state-icon';
import { tryActionResultParsing } from 'services/http/http.service';
import { ErrorGuid } from 'services/store/logger.service';
import { trimErrorPrefixes } from 'services/telemetry/telemetry.service';

export type DialogId = string;
export type GlobalErrorDialogProps = {
  header: string;
  text: string;
  errorId?: ErrorGuid;
  /**
   * Unique ID for the dialog because the errorId is optional
   * (can be the same id as errorId as long as it's unique)
   */
  dialogId: DialogId;
  onContinueClicked?: () => void;
};

export const GlobalErrorDialog: React.FC<GlobalErrorDialogProps> = ({ header, text, errorId, onContinueClicked }) => {
  const continueClicked = useCallback(() => {
    onContinueClicked?.();
  }, [onContinueClicked]);

  const reloadPage = (): void => window.location.reload();

  useEffect(() => {
    const keyDownListener = (event: KeyboardEvent): void => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter' || event.code === 'Escape') {
        event.preventDefault();
        continueClicked();
      }
    };

    document.addEventListener('keyup', keyDownListener);
    return () => {
      document.removeEventListener('keyup', keyDownListener);
    };
  }, [continueClicked]);

  const genericActionResult = tryActionResultParsing(text);
  const simplifiedText = trimErrorPrefixes(text);

  return (
    <article className="flex w-full items-center justify-center" data-cmptype="GlobalErrorDialog">
      <div className="flex max-h-[90vh] grow flex-col rounded-lg bg-neutral-10 p-6">
        <div className="mb-5 flex h-9 w-9 items-center justify-center rounded-full bg-danger-surface">
          <StateIcon variant="Error" />
        </div>

        {genericActionResult ? (
          <GlobalErrorBody header={genericActionResult.Id} text={genericActionResult.Message || ''} errorId={errorId} />
        ) : (
          <GlobalErrorBody header={header} text={simplifiedText} errorId={errorId} />
        )}
        <div className="mt-8 flex gap-3">
          <Button type="submit" variant="Primary" text="Continue" onClick={continueClicked} />
          <Button variant="Secondary" Svg={ReloadIcon} text="Reload page" onClick={reloadPage} />
        </div>
      </div>
    </article>
  );
};
