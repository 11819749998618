import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { SchemaNameInput, SchemaNameInputResult } from 'components/common/schema-name-input';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { FormItem } from 'controls/form-item/form-item';
import { Select, SelectOption } from 'controls/select/select';
import { HighlightedText } from 'controls/text/highlighted-text';
import { sortBundlesByLastEditedDesc } from 'helper/assets/assets.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { startAssetBundleReplicateInSameCompany } from 'services/replicator/replicator.service';
import { buildReplicatorPath } from 'services/routes/replicator-routes.service';
import {
  selectAllBundles,
  selectRelevantBundleVersions,
  selectSelectedBundle,
  selectSelectedBundleVersion,
} from 'slices/assets/assets.slice';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';
import { addReplicationNotification, showNotificationMenu } from 'slices/notifications/notifications.slice';
import { setBlueprintPreselection } from 'slices/replicator/replicator.slice';

type CopyBundleModalProps = {
  onClose: () => void;
};

export const CopyBundleModal: React.FC<CopyBundleModalProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { t, tCopyBundleInfo } = useTypedTranslation();
  const navigate = useNavigate();

  const selectedCompany = useAppSelector(selectSelectedCompany)!;
  const selectedBundle = useAppSelector(selectSelectedBundle)!;
  const selectedBundleVersionInEditor = useAppSelector(selectSelectedBundleVersion);
  const assetBundleVersions = useAppSelector(selectRelevantBundleVersions);
  const publishedBundleVersions = [...assetBundleVersions]
    .filter(bundleVersion => !bundleVersion.isDraft)
    .sort(sortBundlesByLastEditedDesc);

  const versionOptions: SelectOption[] = publishedBundleVersions.map(bundleVersion => ({
    value: bundleVersion.id,
    text: bundleVersion.displayName,
  }));
  // we know that there is a valid bundle version for the selection, otherwise the dialog wouldn't be shown
  const preselectedBundleVersionId =
    selectedBundleVersionInEditor && !selectedBundleVersionInEditor.isDraft
      ? selectedBundleVersionInEditor.id
      : publishedBundleVersions[0].id;
  const [selectedBundleVersionIdInDropdown, setSelectedBundleVersionIdInDropdown] =
    useState(preselectedBundleVersionId);
  const selectedBundleVersionDisplayNameInDropdown = publishedBundleVersions.find(
    bundleVersion => bundleVersion.id === selectedBundleVersionIdInDropdown
  )!.displayName;

  const [isProcessing, setIsProcessing] = useState(false);
  const [input, setInput] = useState<SchemaNameInputResult>({ uniqueId: '', displayName: '' });
  const assetBundles = useAppSelector(selectAllBundles);
  const assetBundleNames = assetBundles.map(b => b.name);
  const assetBundleDisplayNames = assetBundles.map(b => b.displayName);

  const onSchemaInputChange = useCallback((result: SchemaNameInputResult): void => {
    setInput(result);
  }, []);

  const onCopyBundleAcrossCompanyClicked = async (): Promise<void> => {
    dispatch(
      setBlueprintPreselection({ bundleId: selectedBundle.id, bundleVersion: selectedBundleVersionIdInDropdown })
    );
    navigate(buildReplicatorPath(selectedCompany.id, 'copy'));
  };

  const onCopyBundleConfirm = async (): Promise<void> => {
    if (input.hasErrors) {
      return;
    }
    setIsProcessing(true);

    const { uniqueId, displayName } = input;
    const replicationId = await startAssetBundleReplicateInSameCompany(
      selectedCompany.id,
      {
        companyName: selectedCompany.id,
        assetBundleName: selectedBundle.id,
        assetBundleVersion: selectedBundleVersionIdInDropdown,
      },
      uniqueId,
      displayName
    );

    dispatch(
      addReplicationNotification({
        replicationId: replicationId,
        sourceCompany: selectedCompany.id,
        targetCompany: selectedCompany.id,
        cfgrIds: {},
        assetBundleIds: { [input.uniqueId]: input.displayName },
      })
    );
    dispatch(showNotificationMenu(true));
    onClose();
    setIsProcessing(false);
  };

  return (
    <ModalDialog
      data-cmptype="CopyBundle"
      variant="NoIcon"
      header={t('Copy bundle')}
      confirmDisabled={input.hasErrors}
      confirmText={t('Copy bundle')}
      actions={{ onConfirm: onCopyBundleConfirm, onCancel: (): void => onClose() }}
      isProcessingConfirm={isProcessing}
    >
      <div className="flex grow flex-col gap-4">
        <p className="text-m-regular text-neutral-70">
          <HighlightedText
            text={tCopyBundleInfo(
              selectedBundleVersionDisplayNameInDropdown,
              selectedBundle.displayName,
              selectedCompany.displayName
            )}
            highlight={selectedBundle.displayName}
            className="bg-transparent text-m-medium"
          />
        </p>
        <FormItem labelContent={t('Bundle version')}>
          <Select
            options={versionOptions}
            value={selectedBundleVersionIdInDropdown}
            onChange={(value): void => setSelectedBundleVersionIdInDropdown(value as string)}
          />
        </FormItem>
        <SchemaNameInput
          input={input}
          onChange={onSchemaInputChange}
          unavailableIds={assetBundleNames}
          unavailableNames={assetBundleDisplayNames}
          customLabels={{ name: t('Target name'), id: t('Target ID') }}
        />
        <p className="text-m-regular text-neutral-70">
          <span>{t('If you want to copy the bundle to another company, use the copy feature on the page')} </span>
          <button
            type="button"
            className="cursor-pointer text-primary-main underline"
            onClick={onCopyBundleAcrossCompanyClicked}
          >
            {t('Export')}
          </button>
          .
        </p>
      </div>
    </ModalDialog>
  );
};
