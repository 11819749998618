type CbnDrawerHeaderProps = {
  title: string;
  subText?: string;
};

/**
 * Default drawer header with title, subtitle and a close button.
 * See {@link CbnDrawer} for example usage.
 */
export const CbnDrawerHeader: React.FC<CbnDrawerHeaderProps> = ({ title, subText }) => {
  return (
    <div data-cmptype="CbnDrawerHeader" className="border-b border-neutral-40 px-5 pb-3 pt-6">
      <div className="text-heading-s">{title}</div>
      {subText && <div className="text-m-regular text-neutral-70">{subText}</div>}
    </div>
  );
};
