import React, { useMemo, useState } from 'react';
import { AssetFolderSelection } from 'components/asset-editor/folders/asset-folder-selection';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { FormItem } from 'controls/form-item/form-item';
import { Input } from 'controls/input/input';
import { isUnusedAssetKey } from 'helper/assets/assets.helper';
import { isValidHiveIdentifier } from 'helper/hive/hive.helper';
import { NEW_FOLDER_KEY, useNavigateToAssetEditor } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { createDataSourceFromExcelFileAsset, selectSelectedAsset } from 'slices/assets/assets.slice';

type AssetCreateExcelDatasourceModalProps = {
  onClose: () => void;
};

export const AssetCreateExcelDatasourceModal: React.FC<AssetCreateExcelDatasourceModalProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();

  const navigateToAssetEditor = useNavigateToAssetEditor();

  const assets = useAppSelector(state => state.assets.assets);
  const selectedAsset = useAppSelector(selectSelectedAsset)!;
  const preselectedFolderId = selectedAsset.path.folderId;
  const [inputName, setInputName] = useState(selectedAsset.path.assetId);
  const [selectedFolderOfDD, setSelectedFolderOfDD] = useState(preselectedFolderId);
  const [newFolderName, setNewFolderName] = useState('');
  const selectedFolder = selectedFolderOfDD === NEW_FOLDER_KEY ? newFolderName : selectedFolderOfDD;
  const [isProcessingAction, setIsProcessingAction] = useState(false);

  const onActionConfirmed = async (): Promise<void> => {
    setIsProcessingAction(true);
    const { companyId, bundleId, bundleVersion, folderId, assetId } = selectedAsset.path;

    const success = await dispatch(
      createDataSourceFromExcelFileAsset({
        companyId,
        bundleId,
        bundleVersion,
        sourceAsset: folderId ? `${folderId}.${assetId}` : assetId,
        targetAsset: selectedFolder ? `${selectedFolder}.${inputName}` : inputName,
      })
    ).unwrap();

    if (success) {
      navigateToAssetEditor({
        companyId,
        bundleId,
        bundleVersion,
        folderId: selectedFolder,
        assetId: inputName,
      });
      onClose();
    }

    setIsProcessingAction(false);
  };

  // TODO: Proper "invalid state handling".
  //       I.e. don't just disable the submit button but tell the user why he can't submit...
  const inputIsValid = useMemo(() => {
    const assetPath = { ...selectedAsset.path, folderId: selectedFolder, assetId: inputName };
    const assetKeys = Object.keys(assets);
    const isUnused = isUnusedAssetKey(assetPath, assetKeys);

    const newAssetNameIsValid = isValidHiveIdentifier(inputName);
    const folderNameIsValid = !selectedFolder || isValidHiveIdentifier(selectedFolder);
    return isUnused && newAssetNameIsValid && folderNameIsValid;
  }, [selectedAsset.path, inputName, selectedFolder, assets]);

  const inputNameChanged = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    setInputName(evt.currentTarget.value);
  };

  const onAssetFolderSelectionChange = (selectValue: string, newFolderInputValue: string): void => {
    setSelectedFolderOfDD(selectValue);
    setNewFolderName(newFolderInputValue);
  };

  return (
    <ModalDialog
      data-cmptype="AssetCreateExcelDatasourceModal"
      variant="NoIcon"
      header={t('Create data source')}
      actions={{ onConfirm: () => onActionConfirmed(), onCancel: () => onClose() }}
      confirmText={t('Create data source')}
      confirmDisabled={!inputIsValid}
      isProcessingConfirm={isProcessingAction}
    >
      <div className="flex grow flex-col gap-4">
        <FormItem labelContent={t('Name')}>
          <Input value={inputName} onChange={inputNameChanged} autoFocus />
        </FormItem>
        <FormItem labelContent={t('Folder')}>
          <AssetFolderSelection
            preSelectedFolderId={preselectedFolderId}
            showNewFolderOption
            onChange={onAssetFolderSelectionChange}
          />
        </FormItem>
      </div>
    </ModalDialog>
  );
};
