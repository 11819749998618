import { forwardRef } from 'react';
import { ReloadIcon } from 'assets/icons';
import { Button } from 'controls/button/button';
import { CbnAlert } from 'controls/cbn-alert/cbn-alert';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

/**
 * Fallback component with information about the cause of the chunk load error
 */
export const ChunkLoadErrorFallback = forwardRef(() => {
  const { t, tChunkLoadErrorDescription } = useTypedTranslation();
  const reloadPage = (): void => window.location.reload();

  // display name has to be overwritten from outside
  // in this way we can define the origin for the fallback component
  const componentName = ChunkLoadErrorFallback.displayName ?? '';

  return (
    <article data-cmptype="ChunkLoadErrorFallback" className="flex h-full grow">
      <CbnAlert
        severity="warning"
        caption={t('Warning')}
        content={
          <div className="flex grow flex-col gap-2">
            <span className="text-m-regular text-neutral-70">{tChunkLoadErrorDescription(componentName)}</span>
            <Button variant="Secondary" text="Reload page" Svg={ReloadIcon} onClick={reloadPage} grow></Button>
          </div>
        }
      />
    </article>
  );
});
