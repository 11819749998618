import { useState } from 'react';
import { CloudUploadIcon, MaterialAssetIcon, PlusIcon, TextAssetIcon } from 'assets/icons';
import { CreateMaterialAssetModal } from 'components/asset-editor/overview/modals/create-material-asset-modal';
import { CreateTextAssetModal } from 'components/asset-editor/overview/modals/create-text-asset-modal';
import { Button } from 'controls/button/button';
import { FileSelectButtonProps } from 'controls/button/file-select-button';
import { CbnMenu } from 'controls/cbn-menu/cbn-menu';
import { CbnMenuDivider } from 'controls/cbn-menu/cbn-menu-divider';
import { CbnMenuItem } from 'controls/cbn-menu/cbn-menu-item';
import { showFileUploadDialog } from 'helper/file/file.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

type NewAssetMenuProps = {
  menuBtnText: string;
  menuBtnGrow?: boolean;
  hideUploadEntry?: boolean;
  hideNewMaterialEntry?: boolean;
  onUploadFilesSelected: FileSelectButtonProps['onSelect'];
};

export const NewAssetMenu: React.FC<NewAssetMenuProps> = ({
  menuBtnText,
  menuBtnGrow,
  hideUploadEntry,
  hideNewMaterialEntry,
  onUploadFilesSelected,
}) => {
  const { t } = useTypedTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [createTextAssetModal, setCreateTextAssetModal] = useState(false);
  const [createMaterialAssetModal, setCreateMaterialAssetModal] = useState(false);

  const onOpenMenuClicked = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const onFileUploadBtnClick = async (): Promise<void> => {
    setAnchorEl(null);
    const files = await showFileUploadDialog({ multiple: true });
    if (files.length > 0) {
      onUploadFilesSelected?.(files);
    }
  };

  const onCreateTextAssetBtnClick = (): void => {
    setAnchorEl(null);
    window.setTimeout(() => setCreateTextAssetModal(true), 0);
  };

  const onCreateMaterialAssetBtnClick = (): void => {
    setAnchorEl(null);
    window.setTimeout(() => setCreateMaterialAssetModal(true), 0);
  };

  return (
    <>
      <Button Svg={PlusIcon} variant="Secondary" text={menuBtnText} grow={menuBtnGrow} onClick={onOpenMenuClicked} />
      <CbnMenu
        anchor={anchorEl}
        open={open}
        onClose={(): void => setAnchorEl(null)}
        placement="bottom"
        data-cmptype="NewAssetMenu-menu"
      >
        {!hideUploadEntry && (
          <>
            <CbnMenuItem text={t('Upload files...')} icon={CloudUploadIcon} handleClick={onFileUploadBtnClick} />
            <CbnMenuDivider />
          </>
        )}

        <CbnMenuItem text={t('Text asset')} icon={TextAssetIcon} handleClick={onCreateTextAssetBtnClick} />
        {!hideNewMaterialEntry && (
          <CbnMenuItem
            text={t('PBR material asset')}
            icon={MaterialAssetIcon}
            handleClick={onCreateMaterialAssetBtnClick}
          />
        )}
      </CbnMenu>

      {createTextAssetModal && (
        <CreateTextAssetModal
          onConfirm={(): void => setCreateTextAssetModal(false)}
          onCancel={(): void => setCreateTextAssetModal(false)}
        />
      )}
      {createMaterialAssetModal && (
        <CreateMaterialAssetModal
          onConfirm={(): void => setCreateMaterialAssetModal(false)}
          onCancel={(): void => setCreateMaterialAssetModal(false)}
        />
      )}
    </>
  );
};
