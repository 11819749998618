import { cn } from 'helper/css.helper';

export type CardProps = {
  'headerText'?: string;
  'subText'?: string;
  'headerCmp'?: React.ReactNode;
  'classNamesContent'?: string;
  'classNamesHeader'?: string;
  'grow'?: boolean;
  'contentOverflowHidden'?: boolean;
  'noBodyMargin'?: boolean;
  'noShadow'?: boolean;
  /**
   * This can be any React node, whereas the idea is that these are "action" buttons
   * It will be located on the right side of the header
   */
  'actions'?: React.ReactNode;
  /** This is the content of the card that will be appended in the body */
  'children': React.ReactNode;
  'data-cmptype'?: string;
};

export const Card: React.FC<CardProps> = ({
  headerText,
  subText,
  headerCmp,
  classNamesContent,
  classNamesHeader,
  grow,
  contentOverflowHidden,
  noBodyMargin,
  noShadow,
  actions,
  children,
  'data-cmptype': dataCmpType,
}) => {
  const showHeader = !!headerCmp || !!headerText || !!subText || !!actions;
  return (
    // flex-grow => Card should take full space in parent div
    <div
      data-cmptype={'Card' + (dataCmpType ? ` ${dataCmpType}` : '')}
      className={cn(
        'flex min-h-0 shrink-0 flex-col rounded-lg bg-neutral-10',
        grow && 'flex-grow',
        !noShadow && 'shadow-sm',
        noShadow && 'border border-neutral-40'
      )}
    >
      {/* Card header with a defined height */}
      {showHeader && (
        <div
          data-cmptype="Card-header"
          className={cn(
            'relative flex h-18 shrink-0 flex-row items-center justify-between gap-2 border-b border-neutral-40 px-6',
            classNamesHeader
          )}
        >
          {headerCmp ?? (
            <>
              <div className="flex h-11 flex-col justify-center">
                {headerText && <span className="text-m-regular font-semibold text-neutral-100">{headerText}</span>}
                {subText && <span className="text-m-regular text-neutral-70">{subText}</span>}
              </div>
              {actions}
            </>
          )}
        </div>
      )}
      {/* Children (= content) should take the rest of the space */}
      <div
        className={cn(
          'relative flex flex-grow flex-col',
          {
            'overflow-hidden': contentOverflowHidden,
            'm-6': !noBodyMargin,
          },
          classNamesContent
        )}
      >
        {children}
      </div>
    </div>
  );
};
