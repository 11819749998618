import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { globalReset } from 'actions/general.actions';
import * as PersistenceMiddleware from 'services/store/persistence.middleware';

export type DashboardState = {
  selectedCfgrId: string;
};

const initialState: DashboardState = {
  selectedCfgrId: '',
};

const SLICE_NAME = 'dashboard';

PersistenceMiddleware.registerState<DashboardState, DashboardState>({
  state: SLICE_NAME,
  key: 'selection',
  selector: state => state,
  props: ['selectedCfgrId'],
});

const getRehydratedState = (): DashboardState => {
  const rehydratedState = PersistenceMiddleware.rehydrateState<DashboardState>(SLICE_NAME, initialState);
  return rehydratedState;
};

const dashboardSlice = createSlice({
  name: SLICE_NAME,
  initialState: getRehydratedState,
  reducers: {
    setSelectedCfgrId: (state, { payload }: PayloadAction<string>) => ({ ...state, selectedCfgrId: payload }),
  },
  extraReducers: builder => {
    builder.addCase(globalReset, () => initialState);
  },
});

export const { setSelectedCfgrId } = dashboardSlice.actions;
export default dashboardSlice.reducer;
