import { LoginOrRegisterPicker } from 'components/common/login-or-register-picker';
import { AnyQuestionsBee } from 'controls/any-questions-bee/any-questions-bee';
import { LinkButton } from 'controls/button/link-button';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { buildCfgrsPath } from 'services/routes/cfgrs-routes.service';
import { selectLoggedIn } from 'slices/auth/auth.slice';

export const AcceptCompanyInvitationInvalid: React.FC = () => {
  const { t } = useTypedTranslation();
  const loggedIn = useAppSelector(selectLoggedIn);
  const companies = useAppSelector(state => state.companyData.companies);

  return (
    <div data-cmptype="AcceptCompanyInvitationInvalid" className="flex w-[25rem] flex-col gap-5 text-m-regular">
      <div className="w-full text-center text-heading-m">{t('Invitation is not valid.')}</div>

      <div className="text-center">
        {t(
          'You have been invited to collaborate on a Combeenation configurator project, but the invitation is not valid.'
        )}
      </div>

      <div className="text-center">{t('It may have been revoked, expired or consumed.')}</div>

      <div className="mt-5 whitespace-pre-wrap text-center text-heading-s">
        {t('Please contact the person\nwho shared this link with you.')}
      </div>

      {(!loggedIn || companies.length > 0) && (
        <div className="mt-28 text-center text-l-medium">{t('What else can you do?')}</div>
      )}

      {loggedIn && companies.length > 0 && (
        <div className="mb-6 flex flex-col gap-2">
          <div className="">{t('Keep collaborating to one of the companies you already joined.')}</div>
          <LinkButton href={buildCfgrsPath('dashboard', '')} variant="Secondary" text={t(`Go back to Dashboard`)} />
        </div>
      )}

      {!loggedIn && (
        <>
          <LoginOrRegisterPicker
            signInTitle={t('Sign in')}
            signInDetailText={t('Got an account?\nSign in to work on your configurator projects.')}
            hideRegisterCard
          />
        </>
      )}

      <div className="mt-6">
        <AnyQuestionsBee />
      </div>
    </div>
  );
};
