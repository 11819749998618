import { LoginCard } from 'components/login/login-card';
import { PATHS } from 'services/routes/paths.service';

export const Login: React.FC = () => {
  return (
    <div data-cmptype="Login">
      <LoginCard registerLinkPath={PATHS.registerUser} />
    </div>
  );
};
