import { useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  useDispatchGlobalRouteParamsToRedux,
  usePromptOnUnsavedChanges,
  useRouteTitle,
  useSyncIdentity,
} from 'components/app-body/app-body.hooks';
import { AppHeader } from 'components/app-header/app-header';
import { ErrorBoundary } from 'components/error-handling/cbn-error-boundary';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { getRouteFromLocalStorage, setRouteInLocalStorage } from 'services/local-storage/ls-route.service';
import { PATHS } from 'services/routes/paths.service';
import { aiInstance, setUserForTelemetry } from 'services/telemetry/telemetry.service';
import { selectLoggedIn, selectUserIsActivated } from 'slices/auth/auth.slice';
import { getCompaniesAndPermissionsIfNeeded } from 'slices/company-data/company-data.slice';

export const AppBody: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isInitialLoad = useRef(true);
  const curRoute = location.pathname;

  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector(selectLoggedIn);
  const loggedInUserMail = useAppSelector(state => state.auth.userInfo.email);
  const userIsActivated = useAppSelector(selectUserIsActivated);

  usePromptOnUnsavedChanges();
  useDispatchGlobalRouteParamsToRedux();
  useSyncIdentity();
  useRouteTitle();

  // Initially load all user companies if they're not already loaded
  useEffect(() => {
    if (loggedIn && userIsActivated) {
      dispatch(getCompaniesAndPermissionsIfNeeded());
    }
  }, [dispatch, loggedIn, userIsActivated]);

  useEffect(
    function updateUserForTelemetry() {
      if (loggedInUserMail) {
        setUserForTelemetry(loggedInUserMail);
      }
    },
    [loggedInUserMail]
  );

  // get the initial route from local storage
  useEffect(
    function redirectRootToLastStoredRoute() {
      if (isInitialLoad.current) {
        isInitialLoad.current = false;

        // Only apply saved route when entering on "home/root" page.
        // Otherwise entering via some saved bookmark and opening a new route via "open in new tab" etc. won't work.
        const initialRoute = getRouteFromLocalStorage();
        if (initialRoute && curRoute === PATHS.root && curRoute !== initialRoute) {
          if (process.env.NODE_ENV === 'development') {
            // log this to easier track down "confusing redirects" during dev
            console.log('[DEV] redirectRootToLastStoredRoute: ', initialRoute);
          }
          navigate(initialRoute);
        }
      }
    },
    [curRoute, navigate]
  );

  // store route on each location change
  useEffect(
    function storeCurrentRoute() {
      if (loggedIn && curRoute !== PATHS.login) {
        // only store routes inside a logged in state
        setRouteInLocalStorage(curRoute);
      }
    },
    [curRoute, loggedIn]
  );

  return (
    <ErrorBoundary appInsights={aiInstance} scaleToWindowSize>
      <AppHeader />
      <div className="flex flex-grow overflow-hidden bg-neutral-20">
        <Outlet />
      </div>
    </ErrorBoundary>
  );
};
