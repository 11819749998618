import { LockIcon } from 'assets/icons';
import { ReplicatorLockStatusInfo } from 'components/common/replicator-lock-status-info';
import { IdentityUser } from 'components/identity/identity-user';
import { Icon } from 'controls/icon/icon';
import { TooltipContent } from 'controls/tooltip/tooltip-content';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useIsLockedByCurrentUser } from 'hooks/identity/identity.hooks';
import { Draft, isCfgrEditorDraftLockStatus, isReplicatorLockStatus } from 'services/company-data/company-data.service';

type DraftLockStateProps = {
  draft: Draft;
  companyId: string;
  cfgrId: string;
};

export const DraftLockState: React.FC<DraftLockStateProps> = ({ draft, companyId, cfgrId }) => {
  const { t } = useTypedTranslation();
  const isCurrentUserLock = useIsLockedByCurrentUser(draft.lockStatus);

  if (!draft.lockStatus) {
    return null;
  }

  const tooltip = isCfgrEditorDraftLockStatus(draft.lockStatus) ? (
    isCurrentUserLock ? (
      t('Editor is opened by yourself')
    ) : (
      <TooltipContent
        header={
          <div className="flex items-center gap-1">
            {t('Editor opened by')}
            <IdentityUser userId={draft.lockStatus.heldBy} preventTooltip />
          </div>
        }
        detail={t('You can enter the editor as soon as the user is done or has been inactive for at least 12 minutes.')}
      />
    )
  ) : isReplicatorLockStatus(draft.lockStatus) ? (
    <ReplicatorLockStatusInfo lockStatus={draft.lockStatus} lockedEntity={'Configurator'} />
  ) : undefined;

  return (
    <Icon
      Svg={LockIcon}
      className="inline-block w-5"
      titleProps={{ interactive: true, placement: 'bottom-start' }}
      title={tooltip}
    />
  );
};
