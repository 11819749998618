import * as LocalStorageService from 'services/local-storage/local-storage.service';

const _ROUTE_KEY = 'route';

export function setRouteInLocalStorage(route: string): void {
  LocalStorageService.setItem(_ROUTE_KEY, route);
}

export function clearRouteInLocalStorage(): void {
  LocalStorageService.removeItem(_ROUTE_KEY);
}

export function getRouteFromLocalStorage(): string | null {
  const route = LocalStorageService.getItem(_ROUTE_KEY);

  return route;
}
