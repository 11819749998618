import { Autocomplete as MuiAutocomplete, Popper, autocompleteClasses, styled } from '@mui/material';
import { inputBaseClasses } from '@mui/material/InputBase';
import { ReactElement, RefObject } from 'react';
import { SelectIndicatorIcon } from 'assets/icons';
import { Icon } from 'controls/icon/icon';
import { Input } from 'controls/input/input';
import { InputAsSelectedChip } from 'controls/input/input-as-selected-chip';
import { SelectOption, SelectValue, StyledMenuItem } from 'controls/select/select';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

export type AutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  TValue = Multiple extends true ? SelectValue[] : SelectValue,
> = {
  options: SelectOption[];
  value?: TValue;
  onChange?: (value: TValue) => void;
  inputRef?: RefObject<HTMLInputElement>;
  disabled?: boolean;
  error?: boolean;
  warning?: boolean;
  tooltip?: string;
  fullWidth?: boolean;
  /** If true, the input can't be cleared (no "x-icon" on the right) */
  disableClearable?: boolean;
  disableCloseOnSelect?: boolean;
  placeholder?: string;
  /** The dropdown (popper) will grow with it's content. Min width is always the width of the input (`anchorEl`) */
  autoWidthDropdown?: boolean;
  /**
   * If true, the input box looks like a "Chip" component. This is a special use case for the asset bundle selection,
   * which should look similar to the tab navigation that is used for a smaller amount of asset bundles
   */
  asSelectedChip?: boolean;
};

export const StyledAutocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  [`.${inputBaseClasses.root}`]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(7),
  },
  [`&.${autocompleteClasses.hasClearIcon} .${inputBaseClasses.root}`]: {
    paddingRight: theme.spacing(13),
  },
  [`.${autocompleteClasses.popupIndicator}, .${autocompleteClasses.popupIndicatorOpen}`]: {
    marginRight: theme.spacing(2),
  },
  [`.${autocompleteClasses.popupIndicator}:hover`]: {
    background: 'none',
  },
})) as unknown as typeof MuiAutocomplete;

export const Autocomplete: React.FC<AutocompleteProps> = ({
  options,
  value,
  disabled,
  disableClearable,
  disableCloseOnSelect,
  fullWidth = true,
  onChange,
  inputRef,
  error,
  warning,
  tooltip,
  placeholder,
  autoWidthDropdown = true,
  asSelectedChip = false,
}) => {
  const { t } = useTypedTranslation();
  const selOption = options.find(o => o.value === value);

  const RenderInputCmp = asSelectedChip ? InputAsSelectedChip : Input;

  return (
    <StyledAutocomplete
      data-cmptype="Autocomplete"
      disabled={disabled}
      fullWidth={fullWidth}
      PopperComponent={
        autoWidthDropdown
          ? (props): ReactElement => (
              <Popper
                {...props}
                style={{ width: 'fit-content', minWidth: (props.anchorEl as HTMLElement | null)?.clientWidth }}
              />
            )
          : undefined
      }
      popupIcon={<Icon Svg={SelectIndicatorIcon} className="w-6 text-neutral-90" />}
      renderInput={(params): React.ReactNode => (
        <RenderInputCmp
          startAdornment={params.InputProps.startAdornment}
          fullWidth={fullWidth}
          ref={params.InputProps.ref}
          inputRef={inputRef}
          endAdornment={params.InputProps.endAdornment}
          inputProps={params.inputProps}
          placeholder={placeholder ?? t('Search ...')}
          error={error}
          warning={warning}
        />
      )}
      renderOption={(params, option): React.ReactNode => (
        <StyledMenuItem {...params} key={option.value} disabled={option.disabled}>
          {option.text || option.value}
        </StyledMenuItem>
      )}
      getOptionLabel={(option): string => option.text?.toString() ?? option.value.toString()}
      options={options}
      getOptionDisabled={(option): boolean => !!option.disabled}
      value={selOption ?? null}
      onChange={(evt, selectedOption): void => {
        onChange?.(selectedOption?.value as SelectValue);
      }}
      disableClearable={disableClearable}
      disableCloseOnSelect={disableCloseOnSelect}
      noOptionsText={t('No options')}
      title={tooltip}
    />
  );
};
