import { useState } from 'react';
import { isCustomFieldListType } from 'components/company/user-custom-fields/user-custom-field.helper';
import { UserCustomFieldsInputList } from 'components/company/user-custom-fields/user-custom-fields-input-list';
import { CbnToggleBoolean } from 'controls/cbn-toggle-button/cbn-toggle-boolean';
import { NumberInput, NumberOrNull } from 'controls/input/number-input';
import { TextInput } from 'controls/input/text-input';
import { UserCustomFieldInput, UserCustomFieldType } from 'services/http/endpoints/companies.endpoints';

type UserCustomFieldsInputProps = {
  fieldType: UserCustomFieldType;
  value: UserCustomFieldInput;
  onChange: (newValue: UserCustomFieldInput) => void;
};

export const UserCustomFieldsInput: React.FC<UserCustomFieldsInputProps> = ({ fieldType, value, onChange }) => {
  const [isErrorneous, setIsErrorneous] = useState(value === null);

  const onNumberChange = (value: NumberOrNull): void => {
    setIsErrorneous(value === null);
    if (value !== null) {
      onChange(value);
    }
  };

  return fieldType === 'Logic' ? (
    <CbnToggleBoolean value={value as boolean} onChange={onChange} />
  ) : fieldType === 'Number' ? (
    <NumberInput
      inputMode="decimal"
      value={value as number}
      onChange={onNumberChange}
      error={isErrorneous}
      restoreValidValueOnBlur
      onBlur={(): void => setIsErrorneous(false)}
    />
  ) : isCustomFieldListType(fieldType) ? (
    <UserCustomFieldsInputList
      fieldType={fieldType}
      value={value as any[]}
      onChange={(newValue): void => onChange(newValue)}
    />
  ) : (
    <TextInput value={value?.toString() ?? ''} onValueChange={(newValue): void => onChange(newValue)} />
  );
};
