import { Link, LinkProps } from 'react-router-dom';
import { BaseButtonProps, buttonStyleFactory } from 'controls/button/button';
import { Icon } from 'controls/icon/icon';
import { Tooltip } from 'controls/tooltip/tooltip';

export type LinkButtonProps = BaseButtonProps &
  Pick<LinkProps, 'reloadDocument' | 'target' | 'onClick'> & {
    href: string | undefined;
    /** Needs to be set when when navigating to an ExtJS page **without** using `target="blank"` */
    reloadDocument?: boolean;
    state?: {
      referrer?: string;
    };
  };

/**
 * Component that looks like a button but acts like a link.\
 * That means you can pass an `href` instead of an `onClick` function.
 */
export const LinkButton: React.FC<LinkButtonProps> = ({
  variant = 'Primary',
  color,
  hoverColor,
  big,
  disabled,
  text,
  title,
  ariaLabel,
  Svg,
  href = '',
  reloadDocument,
  target,
  state,
  tooltipCmp,
  onClick,
}) => {
  const tooltip = tooltipCmp || title;

  const finalButton = (
    <Link
      data-cmptype="LinkButton"
      // Empty strings disable click and hover events
      to={disabled ? '' : href}
      className={buttonStyleFactory(false, variant, color, hoverColor, big, disabled, !!text?.length)}
      aria-disabled={disabled ? 'true' : 'false'}
      tabIndex={disabled ? -1 : 0}
      aria-label={ariaLabel || text || title}
      reloadDocument={reloadDocument}
      target={target}
      state={state}
      onClick={onClick}
    >
      {Svg && <Icon Svg={Svg} className={text?.length ? 'h-4 w-4' : 'h-5 w-5'} />}
      {text}
    </Link>
  );

  return tooltip ? (
    <Tooltip title={tooltip} arrow>
      {finalButton}
    </Tooltip>
  ) : (
    finalButton
  );
};
