import React, { useCallback, useMemo } from 'react';
import { CollapseAllIcon, ExpandAllIcon, WarningIcon } from 'assets/icons';
import {
  MaterialGroups,
  MaterialProperty,
  MaterialPropertyKey,
  PbrMaterialPropertiesDef,
  getValueByMaterialPropertyKey,
} from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor-properties-def';
import {
  MaterialPropBooleanInput,
  MaterialPropNumberInput,
  MaterialPropSelectInput,
} from 'components/asset-editor/details/asset-body/material-assets/material-asset-editor-standard-prop-inputs';
import { MaterialPropColorInput } from 'components/asset-editor/details/asset-body/material-assets/material-prop-color-input';
import { MaterialPropTextureInput } from 'components/asset-editor/details/asset-body/material-assets/texture-input/material-prop-texture-input';
import { Accordion } from 'controls/accordion/accordion';
import { Button } from 'controls/button/button';
import { Icon } from 'controls/icon/icon';
import { CbnSearchInput } from 'controls/input/search-input';
import { HighlightedText } from 'controls/text/highlighted-text';
import { AssetWarningTypes } from 'generated/asset-warning-types';
import { number3 } from 'helper/color/color.helper';
import { useIsAssetBundleEditable } from 'hooks/assets/assets.hooks';
import { useConfirmDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { TEXTURES_3D_FOLDER_NAME, selectSelectedAsset } from 'slices/assets/assets.slice';
import {
  changeLinkedTexture,
  changeMaterialProperty,
  collapseTextureSetting,
  setMaterialAssetUIState,
  toggleExpandedMaterialGroup,
} from 'slices/material-asset/material-asset.slice';

export const MaterialAssetEditorBasic: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const confirm = useConfirmDialog();
  const isAssetBundleEditable = useIsAssetBundleEditable();

  // NOTE: don't cast selected asset to `MaterialAsset`, as it is undefined in the test suite
  // In praxis this component is only shown with a valid selected asset, but this would mean that the test has to be
  // adapted
  const selectedAsset = useAppSelector(selectSelectedAsset);
  const missingTextureNames =
    selectedAsset?.warningBag.items.filter(w => w.$type === AssetWarningTypes.MissingTextureImage).map(w => w.name) ??
    [];
  const {
    curMaterialData,
    curLinkedTextures,
    ui: { expandedMaterialGroups, searchValueMaterial },
  } = useAppSelector(state => state.materialAsset);

  const onMaterialPropertyChanged = useCallback(
    (key: string, value: any): void => {
      dispatch(changeMaterialProperty({ key, value }));
    },
    [dispatch]
  );

  const onLinkedTextureChanged = useCallback(
    (key: string, assetPath: string): void => {
      dispatch(changeLinkedTexture({ key, assetPath }));
    },
    [dispatch]
  );

  const onUploadImageAssetStarted = useCallback((): void => {
    dispatch(setMaterialAssetUIState({ isLoading: true }));
  }, [dispatch]);

  const onUploadImageAssetFinished = useCallback((): void => {
    dispatch(setMaterialAssetUIState({ isLoading: false }));
  }, [dispatch]);

  const onToggleTexture = useCallback(
    async (key: string, value: boolean) => {
      if (value) {
        // create texture entry
        // NOTE: "invertY" is initialized on "false" as this will work for GLB models, which are more common than
        // babylon models
        onMaterialPropertyChanged(key, { invertY: false });
      } else {
        // first ask the user for confirmation
        const confirmRemoval = await confirm(t('Do you really want to remove the current texture configuration?'), {
          confirmBtnText: t('Remove'),
          variant: 'Question',
        });

        if (confirmRemoval) {
          onMaterialPropertyChanged(key, null);
          onLinkedTextureChanged(key, '');
          dispatch(collapseTextureSetting(key));
        }
      }
    },
    [confirm, dispatch, onLinkedTextureChanged, onMaterialPropertyChanged, t]
  );

  const onGroupHeaderClicked = useCallback(
    (clickedGroup: MaterialGroups): void => {
      dispatch(toggleExpandedMaterialGroup(clickedGroup));
    },
    [dispatch]
  );

  // checks if certain property should be visible depending on the current state of the input object
  const checkRecursiveConfigVisibility = useCallback(
    (propertyDef: MaterialProperty): boolean => {
      if (!propertyDef.visibilityProp) {
        // not defined, visible by default
        return true;
      }

      const value = getValueByMaterialPropertyKey(curMaterialData, propertyDef.visibilityProp);
      if (!value) {
        // visibility prop is false => hide property
        return false;
      }

      // check parents as well, maybe the visibility prop is hidden itself (see `clearCoat.isTintEnabled` example)
      return checkRecursiveConfigVisibility(PbrMaterialPropertiesDef[propertyDef.visibilityProp]);
    },
    [curMaterialData]
  );

  const visibleGroupedProperties = useMemo(
    () =>
      // TODO: this is hardcoded for PBR materials ATM, use different configuration, once asset links are supported for
      // other material types as well
      Object.entries(PbrMaterialPropertiesDef).reduce<{
        [key in MaterialGroups]?: [MaterialPropertyKey, MaterialProperty][];
      }>((acc, entry) => {
        const [, propertyDef] = entry;

        const displayNameLowerCase = t(propertyDef.displayName).toLowerCase();
        const groupNameLowerCase = t(propertyDef.group).toLowerCase();

        const propertyVisible =
          // visibility from search
          (displayNameLowerCase.includes(searchValueMaterial.toLowerCase()) ||
            groupNameLowerCase.includes(searchValueMaterial.toLowerCase())) &&
          // visibility from property config
          checkRecursiveConfigVisibility(propertyDef);

        if (propertyVisible) {
          if (acc[propertyDef.group]) {
            acc[propertyDef.group]!.push(entry);
          } else {
            acc[propertyDef.group] = [entry];
          }
        }

        return acc;
      }, {}),
    [searchValueMaterial, t, checkRecursiveConfigVisibility]
  );

  const groupedPropertyCmps = Object.entries(visibleGroupedProperties).map(([groupName, matProperties]) => {
    const expanded = expandedMaterialGroups.includes(groupName as MaterialGroups) || searchValueMaterial.length > 0;
    const linksOfCurrentProps = curLinkedTextures.filter(link => matProperties.some(([key]) => link.texture === key));
    const groupHasWarning = linksOfCurrentProps.some(link =>
      missingTextureNames.includes(link.target.replace(`${TEXTURES_3D_FOLDER_NAME}.`, ''))
    );

    const props = matProperties.map(([key, propertyDef]) => {
      return propertyDef.type === 'boolean' ? (
        <MaterialPropBooleanInput
          key={key}
          objKey={key}
          inputObj={curMaterialData}
          disabled={!isAssetBundleEditable}
          displayName={t(propertyDef.displayName)}
          searchValue={searchValueMaterial}
          defaultValue={propertyDef.defaultValue as boolean}
          onChange={onMaterialPropertyChanged}
        />
      ) : propertyDef.type === 'number' ? (
        <MaterialPropNumberInput
          key={key}
          objKey={key}
          inputObj={curMaterialData}
          disabled={!isAssetBundleEditable}
          displayName={t(propertyDef.displayName)}
          searchValue={searchValueMaterial}
          defaultValue={propertyDef.defaultValue as number}
          onChange={onMaterialPropertyChanged}
        />
      ) : propertyDef.type === 'select' ? (
        <MaterialPropSelectInput
          key={key}
          objKey={key}
          inputObj={curMaterialData}
          disabled={!isAssetBundleEditable}
          displayName={t(propertyDef.displayName)}
          searchValue={searchValueMaterial}
          defaultValue={propertyDef.defaultValue as number}
          options={propertyDef.options!}
          onChange={onMaterialPropertyChanged}
        />
      ) : propertyDef.type === 'Color3' ? (
        <MaterialPropColorInput
          key={key}
          objKey={key}
          inputObj={curMaterialData}
          disabled={!isAssetBundleEditable}
          displayName={t(propertyDef.displayName)}
          searchValue={searchValueMaterial}
          defaultValue={propertyDef.defaultValue as number3}
          onChange={onMaterialPropertyChanged}
        />
      ) : propertyDef.type === 'BaseTexture' ? (
        <MaterialPropTextureInput
          key={key}
          objKey={key}
          inputObj={curMaterialData}
          disabled={!isAssetBundleEditable}
          displayName={t(propertyDef.displayName)}
          searchValue={searchValueMaterial}
          linkedTexture={curLinkedTextures.find(t => t.texture === key)?.target ?? ''}
          onLinkedTextureChange={(value): void => onLinkedTextureChanged(key, value)}
          onTexturePropChange={(textureKey, value): void => onMaterialPropertyChanged(`${key}.${textureKey}`, value)}
          onToggleTexture={(value): Promise<void> => onToggleTexture(key, value)}
          onUploadImageAssetStarted={onUploadImageAssetStarted}
          onUploadImageAssetFinished={onUploadImageAssetFinished}
        />
      ) : (
        <></>
      );
    });

    return (
      <Accordion
        key={groupName}
        summary={
          <div className="flex items-center gap-2">
            <HighlightedText text={t(groupName)} highlight={searchValueMaterial} />
            {groupHasWarning && <Icon Svg={WarningIcon} className="w-5 text-warning-main" />}
          </div>
        }
        noBodyMargin
        expanded={expanded}
        onChange={(): void => onGroupHeaderClicked(groupName as MaterialGroups)}
      >
        <div className="border-b-[3px] border-neutral-40">{props}</div>
      </Accordion>
    );
  });

  const onSearchChange = (value: string): void => {
    dispatch(setMaterialAssetUIState({ searchValueMaterial: value }));
  };

  const expandAllMaterialGroups = (): void => {
    const allGroupKeys = Object.keys(visibleGroupedProperties) as MaterialGroups[];
    dispatch(setMaterialAssetUIState({ expandedMaterialGroups: allGroupKeys }));
  };

  const collapseAllMaterialGroups = (): void => {
    dispatch(setMaterialAssetUIState({ expandedMaterialGroups: [] }));
  };

  return (
    <div data-cmptype="MaterialAssetEditorBasic" className="relative m-4 grow">
      <div className="absolute inset-0 flex flex-col gap-4">
        <div className="flex items-center gap-4">
          <CbnSearchInput placeholder={t('Find property')} value={searchValueMaterial} onValueChange={onSearchChange} />
          <div className="flex gap-1">
            <Button Svg={ExpandAllIcon} variant="Outlined" onClick={expandAllMaterialGroups} title={t('Expand all')} />
            <Button
              Svg={CollapseAllIcon}
              variant="Outlined"
              onClick={collapseAllMaterialGroups}
              title={t('Collapse all')}
            />
          </div>
        </div>
        <form className="mx-0.5 flex grow flex-col overflow-auto rounded border border-neutral-40">
          {groupedPropertyCmps}
        </form>
      </div>
    </div>
  );
};
