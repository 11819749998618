import { useState } from 'react';
import { convertTextToCustomFieldValue } from 'components/company/user-custom-fields/user-custom-field.helper';
import { TextInput } from 'controls/input/text-input';
import { UserCustomFieldInput, UserCustomFieldType } from 'services/http/endpoints/companies.endpoints';

type UserCustomFieldsInputListProps = {
  fieldType: UserCustomFieldType;
  value: Extract<UserCustomFieldInput, string[] | number[]>;
  onChange: (newValue: UserCustomFieldInput) => void;
};

/**
 * Text-based input for lists (arrays)
 * Note: This is a very basic implementation to technically support this field type and
 *       should be replaced by a more sophisticated version in the future (chips, etc.)
 */
export const UserCustomFieldsInputList: React.FC<UserCustomFieldsInputListProps> = ({ fieldType, value, onChange }) => {
  const valueAsText = value ? value.join(',') : '';
  // keep a local state so that the user can write "freely" without unexpected updates
  const [localValue, setLocalValue] = useState<string>(valueAsText);
  const [isErrorneous, setIsErrorneous] = useState(value === null);

  const onListChange = (valueList: string): void => {
    const values = valueList.split(',');
    if (fieldType === 'List<Number>') {
      const hasErrors = values.some(v => v.length > 0 && isNaN(parseFloat(v)));
      setIsErrorneous(hasErrors);
    }
    setLocalValue(valueList);

    onChange(convertTextToCustomFieldValue(valueList, fieldType));
  };

  const onBlur = (): void => {
    setLocalValue(valueAsText);
    setIsErrorneous(false);
  };

  return (
    <TextInput
      startAdornment={'['}
      endAdornment={']'}
      value={localValue}
      onValueChange={onListChange}
      error={isErrorneous}
      onBlur={onBlur}
    />
  );
};
