import {
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridApiPro,
  GridColDef,
  GridEditCellProps,
  GridRenderCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridValueOptionsParams,
  GridValueSetterParams,
} from '@mui/x-data-grid-pro';
import classNames from 'classnames';
import { useEffect } from 'react';
import { ReactElement } from 'react';
import { CheckIcon, CrossIcon, DeleteIcon, EditIcon, ErrorIcon, HintIcon } from 'assets/icons';
import {
  ConfigRow,
  EDIT_GRID_FIELD_KEYS,
} from 'components/asset-editor/details/modals/datasource-edit/edit-grid/asset-datasource-edit-grid';
import { AssetDatasourceEditGridDefaultValueCell } from 'components/asset-editor/details/modals/datasource-edit/edit-grid/asset-datasource-edit-grid-defaultvalue-cell';
import { AssetDatasourceEditGridLinkedAssetFolderCell } from 'components/asset-editor/details/modals/datasource-edit/edit-grid/asset-datasource-edit-grid-linkedassetfolder-cell';
import { AssetDatasourceEditGridTypeDefCell } from 'components/asset-editor/details/modals/datasource-edit/edit-grid/asset-datasource-edit-grid-typedef-cell';
import { Checkbox } from 'controls/checkbox/checkbox';
import { inputColumnType } from 'controls/datagrid/column-types/data-grid-edit-input-column';
import { Icon } from 'controls/icon/icon';
import { Tooltip } from 'controls/tooltip/tooltip';
import { AssetErrorTypes } from 'generated/asset-error-types';
import { AssetHintTypes } from 'generated/asset-hint-types';
import { DataSourceColumnTypes } from 'generated/datasource-column-types';
import {
  DataSourceColumnTypesExtended,
  createTypeSelectionKey,
  getColumnTypeOptions,
  getDefaultAssetOptions,
  getFolderOptions,
  getTypeFromSelectionKey,
  getTypeOptionLabel,
} from 'helper/assets/assets-datasource.helper';
import { defaultValueToString } from 'helper/assets/assets.helper';
import { tw } from 'helper/css.helper';
import { INVALID_HIVE_IDENTIFIER_INFO, isValidHiveIdentifier } from 'helper/hive/hive.helper';
import { useGetFolderAssetSelectOptions } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { isDataSourceParseError } from 'services/assets/assets.service';
import { DataSourceAsset, selectAssetsInSelectedBasePath, selectSelectedAsset } from 'slices/assets/assets.slice';

export const useEditGridColumns = (
  rows: ConfigRow[],
  rowModesModel: GridRowModesModel,
  apiRef: React.MutableRefObject<GridApiPro>,
  onEditClick: (id: GridRowId) => void,
  onApplyClick: (id: GridRowId) => void,
  onCancelClick: (id: GridRowId) => void,
  onDeleteClick: (id: GridRowId) => void
): GridColDef[] => {
  const { t } = useTypedTranslation();
  const {
    errorBag: { items: errors },
    hintBag: { items: hints },
  } = useAppSelector(selectSelectedAsset) as DataSourceAsset;
  const availableFolders = useGetFolderAssetSelectOptions(false, false);
  const availableAssets = useAppSelector(selectAssetsInSelectedBasePath);
  const typeOptions: DataSourceColumnTypesExtended[] = getColumnTypeOptions();

  const firstColumnTypeOptions: DataSourceColumnTypesExtended[] = [
    { type: DataSourceColumnTypes.String },
    { type: DataSourceColumnTypes.Double },
  ];

  const columns: GridColDef[] = [
    {
      field: EDIT_GRID_FIELD_KEYS.Index,
      ...inputColumnType,
      headerName: t('Index'),
      headerClassName: tw`text-m-medium`,
      sortable: false,
      editable: true,
      width: 100,
      align: 'right',
      preProcessEditCellProps: (params): GridEditCellProps => {
        const parsedNumber = parseInt(params.props.value);
        const errorText =
          isNaN(parsedNumber) || parsedNumber < 0 ? t('Invalid index!\nIndex must be not empty.') : undefined;
        return { ...params.props, error: errorText };
      },
    },
    {
      field: EDIT_GRID_FIELD_KEYS.Name,
      ...inputColumnType,
      headerName: t('Name'),
      headerClassName: tw`text-m-medium`,
      minWidth: 140,
      flex: 1,
      sortable: false,
      editable: true,
      preProcessEditCellProps: (params): GridEditCellProps => {
        const newValue = params.props.value as string;

        // don't validate for duplicate names here, as it would make it hard to swap names
        // Only after commiting a row it will be displayed as erroneous
        const errorText = !isValidHiveIdentifier(newValue) ? t(INVALID_HIVE_IDENTIFIER_INFO) : undefined;
        return { ...params.props, error: errorText };
      },
      renderCell: (params: GridRenderCellParams<ConfigRow>): React.ReactNode => {
        const isDuplicate = rows.some(
          r => r.id !== params.id && r[EDIT_GRID_FIELD_KEYS.Name].toLowerCase() === params.value.toLowerCase()
        );

        return (
          <Tooltip title={isDuplicate ? t('Duplicate name!') : undefined} arrow placement="right">
            <div className={classNames('flex items-center gap-2', { 'text-danger-main': isDuplicate })}>
              <span>{params.value}</span>
              {isDuplicate && <Icon Svg={ErrorIcon} className="w-5" />}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: EDIT_GRID_FIELD_KEYS.TypeDef,
      headerName: t('Type'),
      headerClassName: tw`text-m-medium`,
      width: 220,
      sortable: false,
      editable: true,
      type: 'singleSelect',
      valueOptions: (params: GridValueOptionsParams<ConfigRow>): DataSourceColumnTypesExtended[] => {
        return params.id === 0 ? firstColumnTypeOptions : typeOptions;
      },
      getOptionValue: (value): string => createTypeSelectionKey(value as DataSourceColumnTypesExtended),
      getOptionLabel: (value): string => {
        const typedValue = value as DataSourceColumnTypesExtended;
        const assetType = typedValue.type === DataSourceColumnTypes.LinkedAsset ? typedValue.assetType : undefined;

        return getTypeOptionLabel(typedValue.type, assetType);
      },
      valueGetter: params => createTypeSelectionKey(params.value),
      valueSetter: (params: GridValueSetterParams<ConfigRow>): ConfigRow => {
        const newType = getTypeFromSelectionKey(params.value);
        // check for valid type due to an MUI bug: https://github.com/mui/mui-x/issues/11221
        const isValidType = typeOptions.some(o => o.type === newType.type);

        return {
          ...params.row,
          type: isValidType ? newType : params.row[EDIT_GRID_FIELD_KEYS.TypeDef],
        };
      },
      renderCell: (params: GridRenderCellParams<ConfigRow>): React.ReactNode => {
        const hasError = errors.some(e => isDataSourceParseError(e) && e.columnName === params.row.name);
        const columnTypeExt = getTypeFromSelectionKey(params.value);
        const assetType =
          columnTypeExt.type === DataSourceColumnTypes.LinkedAsset ? columnTypeExt.assetType : undefined;

        return (
          <Tooltip
            title={hasError ? t("Can't parse certain values with this column type") : undefined}
            arrow
            placement="right"
          >
            <div className={classNames('flex items-center gap-2', { 'text-danger-main': hasError })}>
              <span>{getTypeOptionLabel(columnTypeExt.type, assetType)}</span>
              {hasError && <Icon Svg={ErrorIcon} className="w-5" />}
            </div>
          </Tooltip>
        );
      },
      renderEditCell: params => <AssetDatasourceEditGridTypeDefCell availableFolders={availableFolders} {...params} />,
    },
    {
      field: EDIT_GRID_FIELD_KEYS.LinkedAssetFolder,
      headerName: t('Linked asset folder'),
      headerClassName: tw`text-m-medium`,
      minWidth: 140,
      flex: 1,
      sortable: false,
      editable: true,
      type: 'singleSelect',
      renderCell: (params: GridRenderCellParams<ConfigRow>): React.ReactNode => {
        const hasError = errors.some(e => e.$type === AssetErrorTypes.MissingLinkedFolder && e.name === params.value);
        const hasHint = hints.some(h => h.$type === AssetHintTypes.MissingLinkedAsset && h.folderName === params.value);
        return (
          <Tooltip
            title={
              hasError
                ? t("Folder doesn't exist!")
                : hasHint
                  ? t('Linked assets are missing. Desired assets may be located in a different folder.')
                  : undefined
            }
            arrow
            placement="right"
          >
            <div
              className={classNames('flex items-center gap-2', {
                'text-danger-main': hasError,
                'text-primary-main': hasHint,
              })}
            >
              <span>{params.value}</span>
              {hasError ? (
                <Icon Svg={ErrorIcon} className="w-5" />
              ) : hasHint ? (
                <Icon Svg={HintIcon} className="w-5" />
              ) : (
                <></>
              )}
            </div>
          </Tooltip>
        );
      },
      valueOptions: params => getFolderOptions(params.row, availableFolders),
      renderEditCell: (params: GridRenderCellParams<ConfigRow>) => (
        <AssetDatasourceEditGridLinkedAssetFolderCell {...params} />
      ),
    },
    {
      field: EDIT_GRID_FIELD_KEYS.DefaultValue,
      headerName: t('Default value'),
      headerClassName: tw`text-m-medium`,
      minWidth: 140,
      flex: 1,
      sortable: false,
      editable: true,
      // "singleSelect" is required to receive the "valueOptions" property
      // the field can still have a different type, depending on the value set on the "type" column
      type: 'singleSelect',
      preProcessEditCellProps: (params): GridEditCellProps => {
        if (!params.otherFieldsProps) {
          return { ...params.props };
        }

        const typeDef = getTypeFromSelectionKey(params.otherFieldsProps[EDIT_GRID_FIELD_KEYS.TypeDef].value);
        const isNumberCol = typeDef.type === DataSourceColumnTypes.Double;
        const isLinkedCol = typeDef.type === DataSourceColumnTypes.LinkedAsset;
        if (isLinkedCol) {
          const errorText =
            params.props.value && !isValidHiveIdentifier(params.props.value)
              ? t(INVALID_HIVE_IDENTIFIER_INFO)
              : undefined;
          return { ...params.props, error: errorText };
        } else if (isNumberCol) {
          const parsedNumber = Number(params.props.value);
          const errorText = isNaN(parsedNumber) ? t('Invalid number!') : undefined;
          return { ...params.props, error: errorText };
        } else {
          // always return the error prop (otherwise it could remain in an erroneous state)
          return { ...params.props, error: undefined };
        }
      },

      valueSetter: (params: GridValueSetterParams<ConfigRow>): ConfigRow => {
        const isNumberCol = params?.row[EDIT_GRID_FIELD_KEYS.TypeDef].type === DataSourceColumnTypes.Double;

        return {
          ...params.row,
          [EDIT_GRID_FIELD_KEYS.DefaultValue]: isNumberCol ? Number(params.value) : params.value,
        };
      },
      valueFormatter: params =>
        params.value && params.id
          ? defaultValueToString(params.value, params.api.getCellValue(params.id, EDIT_GRID_FIELD_KEYS.TypeDef))
          : undefined,
      renderCell: (params: GridRenderCellParams<ConfigRow>): React.ReactNode => {
        if (params.row[EDIT_GRID_FIELD_KEYS.TypeDef].type === DataSourceColumnTypes.Bool) {
          return <Checkbox disabled checked={params.value} />;
        } else if (params.row[EDIT_GRID_FIELD_KEYS.TypeDef].type === DataSourceColumnTypes.LinkedAsset) {
          const hasError = errors.some(
            e =>
              e.$type === AssetErrorTypes.MissingLinkedDefaultAsset &&
              e.folderName === params.row[EDIT_GRID_FIELD_KEYS.LinkedAssetFolder] &&
              e.assetName === params.value
          );
          return (
            <Tooltip title={hasError ? t("Asset doesn't exist!") : undefined} arrow placement="right">
              <div className={classNames('flex items-center gap-2', { 'text-danger-main': hasError })}>
                <span>{params.value}</span>
                {hasError && <Icon Svg={ErrorIcon} className="w-5" />}
              </div>
            </Tooltip>
          );
        } else {
          return undefined; // render as string
        }
      },
      valueOptions: params => getDefaultAssetOptions(params.row, availableAssets),
      renderEditCell: (params: GridRenderCellParams<ConfigRow>) => (
        <AssetDatasourceEditGridDefaultValueCell {...params} />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerClassName: tw`text-m-medium`,
      width: 100,
      getActions: ({ id }): ReactElement<GridActionsCellItemProps>[] => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              label={t('Apply')}
              icon={<Icon Svg={CheckIcon} className="w-5 text-neutral-100" />}
              onClick={(): void => onApplyClick(id)}
            />,
            <GridActionsCellItem
              label={t('Cancel')}
              icon={<Icon Svg={CrossIcon} className="w-5 text-neutral-100" />}
              onClick={(): void => onCancelClick(id)}
            />,
          ];
        } else {
          const isPinnedRow = id === 0;
          const editCell = (
            <GridActionsCellItem
              label={t('Edit')}
              icon={<Icon Svg={EditIcon} className="w-5 text-neutral-100" />}
              onClick={(): void => onEditClick(id)}
            />
          );
          return isPinnedRow
            ? [editCell]
            : [
                editCell,
                <GridActionsCellItem
                  label={t('Delete')}
                  icon={<Icon Svg={DeleteIcon} className="w-5 text-neutral-100" />}
                  onClick={(): void => onDeleteClick(id)}
                />,
              ];
        }
      },
    },
  ];

  return columns;
};
/**
 * Hook to compensante for missing behavior when tabbing through cells.
 * It moves to next cell if the edit column is actually empty.
 * This can happen when columns depend on each other (and become empty eventually)
 */
export const useDataGridTabNavigationWorkaround = (apiRef: React.MutableRefObject<GridApiPro>): void => {
  useEffect(() => {
    if (!apiRef.current) {
      return;
    }

    const cleanupFn = apiRef.current.subscribeEvent('cellFocusIn', ({ id, field }) => {
      const el = apiRef.current.getCellElement(id, field);

      if (el && el.childElementCount === 0) {
        const visibleCols = apiRef.current.getVisibleColumns();
        const colIdx = visibleCols.findIndex(c => c.field === field);
        const isLastCol = colIdx === visibleCols.length - 1;

        if (colIdx > -1 && !isLastCol) {
          apiRef.current.setCellFocus(id, visibleCols[colIdx + 1].field);
        }
      }
    });

    return cleanupFn;
  }, [apiRef]);
};
