import React from 'react';
import { ASSET_TYPE_MAP } from 'components/asset-editor/asset-type-map';
import { IdentityUser } from 'components/identity/identity-user';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { AssetTypes } from 'generated/asset-types';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectSelectedAsset } from 'slices/assets/assets.slice';

export type AssetMetadataEntry = { text: string; value: React.ReactNode };

type AssetMetadataProps = { assetType: AssetTypes; metadata: AssetMetadataEntry[] };

export const AssetMetadata: React.FC<AssetMetadataProps> = ({ assetType, metadata }) => {
  const { t } = useTypedTranslation();
  const selectedAsset = useAppSelector(selectSelectedAsset);

  // last edited metadata can be available for all asset types, therefore we handle it here in a central place
  const lastEditedMetadata: AssetMetadataEntry[] = [];
  if (selectedAsset?.lastEditedAt && selectedAsset.lastEditedBy) {
    lastEditedMetadata.push({
      text: t('Last edited'),
      value: (
        <>
          <RelativeDateTime unixTime={selectedAsset.lastEditedAt} />
          <span>{t('by')}</span>
          <IdentityUser userId={selectedAsset.lastEditedBy} />
        </>
      ),
    });
  }

  return (
    <div data-cmptype="AssetMetadata" className="flex flex-row items-center gap-4 px-6 py-2">
      <span className="text-s-medium text-neutral-90">{ASSET_TYPE_MAP[assetType].displayName}</span>
      {[...metadata, ...lastEditedMetadata].map(md => (
        <div key={md.text} className="flex flex-row items-center gap-1 text-s-regular text-neutral-90">
          <span> {`${md.text}:`}</span>
          {React.isValidElement(md.value) ? md.value : <span>{md.value}</span>}
        </div>
      ))}
    </div>
  );
};
