import { ASSET_TYPE_MAP } from 'components/asset-editor/asset-type-map';
import {
  AssetDefaultActions,
  AssetDetailsActions,
  AssetUploadMode,
} from 'components/asset-editor/details/asset-details-actions';
import { AssetMetadata, AssetMetadataEntry } from 'components/asset-editor/details/asset-metadata';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { Icon } from 'controls/icon/icon';
import { AssetTypes } from 'generated/asset-types';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { selectSelectedAsset } from 'slices/assets/assets.slice';

type AssetDetailsHeaderProps = {
  actions?: (AssetDefaultActions | React.ReactElement)[];
  uploadMode?: AssetUploadMode;
  metadata?: AssetMetadataEntry[];
};

export const AssetDetailsHeader: React.FC<AssetDetailsHeaderProps> = ({ actions, uploadMode, metadata }) => {
  const { t } = useTypedTranslation();
  const selectedAsset = useAppSelector(selectSelectedAsset);

  // no asset selected => root folder
  const assetType = selectedAsset?.type ?? AssetTypes.Folder;
  const icon = ASSET_TYPE_MAP[assetType].Icon;
  const displayName = ASSET_TYPE_MAP[assetType].displayName;
  const headerText = selectedAsset?.path.assetId ?? t('Root');

  return (
    <div data-cmptype="AssetDetailsHeader" className="flex flex-col">
      <CbnCardHeader
        title={
          <div className="flex items-center gap-3">
            {icon && <Icon Svg={icon} className="h-7 w-7" title={t(displayName)} />}
            <h4>{headerText}</h4>
          </div>
        }
        actions={<AssetDetailsActions actions={actions} uploadMode={uploadMode} />}
      />
      {metadata && <AssetMetadata assetType={assetType} metadata={metadata} />}
    </div>
  );
};
