import React from 'react';
import { ChatIcon, DocsIcon, GearIcon, VideoIcon } from 'assets/icons';
import { LinkButton } from 'controls/button/link-button';
import { CbnCaptionCard } from 'controls/cbn-card/implementations/cbn-caption-card';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { buildCompanySettingsPath } from 'services/routes/company-settings-routes.service';
import { CompanyPermissions } from 'slices/permission/permission.slice';

type HomeQuickstartCardsProps = {
  companyId: string;
};

export const HomeQuickstartCards: React.FC<HomeQuickstartCardsProps> = ({ companyId }) => {
  const { t } = useTypedTranslation();
  const hasCompanySettingsPermission = usePermission(CompanyPermissions.ModifyCompanySettings, companyId);

  return (
    <div data-cmptype="HomeQuickstartCards" className="grid grid-cols-[repeat(auto-fill,minmax(350px,1fr))] gap-6">
      <CbnCaptionCard
        Svg={VideoIcon}
        headerText={t('Take a quick tour')}
        subText={t('Walk through Combeenations unique features.')}
        action={<LinkButton text={t('Open')} href="https://www.youtube.com/watch?v=YHRJFFfcAEs" target="_blank" />}
      />

      <CbnCaptionCard
        Svg={DocsIcon}
        headerText={t('Documentation')}
        subText={t('Dig into Combeenations features in detail.')}
        action={<LinkButton text={t('Open')} href="https://docs.combeenation.com" target="_blank" />}
      />

      <CbnCaptionCard
        Svg={GearIcon}
        headerText={t('My settings')}
        subText={t('Manage company and subscription settings.')}
        action={
          <LinkButton
            text={t('Open')}
            href={buildCompanySettingsPath(companyId)}
            target="_blank"
            disabled={!hasCompanySettingsPermission}
            title={t("You don't have permission to access this page. Contact an admin if you think this is a mistake.")}
          />
        }
      />

      <CbnCaptionCard
        Svg={ChatIcon}
        headerText={t('Community')}
        subText={t('Engage with our community forum.')}
        action={<LinkButton text={t('Open')} href="https://community.combeenation.com/" target="_blank" />}
      />
    </div>
  );
};
