import lexer from 'json-lexer';
import { editor, languages } from 'monaco-editor';
import { AssetLinkTypes } from 'generated/asset-link-types';
import { getTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { AssetLink, MaterialAsset } from 'slices/assets/assets.slice';

const _MATERIAL_TYPE_KEY = 'customType';
const _BASE64_KEY = 'base64String';
const _MATERIAL_NAMING_KEYS = ['name', 'id'];
const _TEXTURES_OVERRULED_BY_LINKED_ASSET_KEYS = ['name', 'url', _BASE64_KEY];

export function createProvideInlayHints(
  asset: MaterialAsset,
  assetLinks: AssetLink[]
): (model: editor.ITextModel) => languages.ProviderResult<languages.InlayHintList> {
  const {
    t,
    tOverruledByStoredMaterialTypeTooltip,
    tOverruledByMaterialAssetNameTooltip,
    tOverruledByLinkedImageAssetTooltip,
  } = getTypedTranslation();

  const provideInlayHints = (model: editor.ITextModel): languages.ProviderResult<languages.InlayHintList> => {
    // check if model is a valid JSON string
    // don't show any hints if this is not the case
    try {
      JSON.parse(model.getValue());
    } catch {
      return {
        hints: [],
        dispose: (): void => {},
      };
    }

    const hints: languages.InlayHint[] = [];
    let curKey = '';
    let curValue: string | number | boolean | null = null;
    // variables for keeping track of the nested objects like textures
    let level = 0;
    let activeTextureKey = '';

    const hasTextureLink = (link: AssetLink): boolean =>
      link.type === AssetLinkTypes.MaterialToImage && activeTextureKey === link.texture;

    for (let lineNumber = 1; lineNumber <= model.getLineCount(); lineNumber++) {
      const lineContent = model.getLineContent(lineNumber);
      let column = 0;

      let lineTokens: lexer.LexerTokens;
      try {
        // lexer throws if model is invalid
        lineTokens = lexer(lineContent);
      } catch {
        continue;
      }

      for (const token of lineTokens) {
        // ATM just "value" is used
        let tokenType: 'Any' | 'Value' = 'Any';
        column += token.raw.length;

        // token ,
        if (token.type === 'punctuator' && token.value === ',') {
          curKey = '';
          curValue = null;
        }
        // token {
        else if (token.type === 'punctuator' && token.value === '{') {
          curKey = '';
          level++;
        }
        // token }
        else if (token.type === 'punctuator' && token.value === '}') {
          level--;
          if (level === 1) {
            activeTextureKey = '';
          }
        }
        // token key
        else if (token.type === 'string' && curKey === '') {
          curKey = token.value;

          if (token.value.includes('Texture')) {
            activeTextureKey = token.value;
          }
        }
        // token value
        else if (token.type === 'literal' || token.type === 'number' || token.type === 'string') {
          curValue = token.value;
          tokenType = 'Value';
        }

        const activeTextureAssetLink = activeTextureKey && assetLinks.find(hasTextureLink);

        if (tokenType === 'Value') {
          if (level === 1) {
            if (_MATERIAL_TYPE_KEY === curKey) {
              hints.push({
                label: t('(readonly)'),
                // place the hint right after the value
                position: { lineNumber: lineNumber, column: column + 1 },
                tooltip: tOverruledByStoredMaterialTypeTooltip(asset.materialType),
                paddingLeft: true,
              });
            } else if (_MATERIAL_NAMING_KEYS.includes(curKey)) {
              hints.push({
                label: t('(ignored)'),
                position: { lineNumber: lineNumber, column: column + 1 },
                tooltip: tOverruledByMaterialAssetNameTooltip(asset.path.assetId),
                paddingLeft: true,
              });
            }
          } else if (!!activeTextureAssetLink && _TEXTURES_OVERRULED_BY_LINKED_ASSET_KEYS.includes(curKey)) {
            hints.push({
              label: t('(ignored)'),
              position: { lineNumber: lineNumber, column: column + 1 },
              tooltip: tOverruledByLinkedImageAssetTooltip(activeTextureAssetLink.target),
              paddingLeft: true,
            });
          } else if (activeTextureKey && curKey === _BASE64_KEY && curValue === '-') {
            hints.push({
              label: '(value cropped)',
              position: { lineNumber: lineNumber, column: column + 1 },
              tooltip: t(
                `Base64 string is not shown to improve editor performance.\nYou can still manually change or remove this value.`
              ),
              paddingLeft: true,
            });
          }
        }
      }
    }

    return {
      hints: hints,
      dispose: (): void => {},
    };
  };

  return provideInlayHints;
}
