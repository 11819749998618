import { Collapse, Skeleton, useTheme } from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CopyIcon } from 'assets/icons';
import { CfgnInsightsDetailsTable } from 'components/cfgrs/cfgn-insights/cfgn-insights-details-table';
import { IdentityUser } from 'components/identity/identity-user';
import { LinkButton } from 'controls/button/link-button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { useCbnCard } from 'controls/cbn-card/cbn-card-provider';
import { CbnCollapsibleCardHeader } from 'controls/cbn-card/cbn-collapsible-card-header';
import { CbnDrawer } from 'controls/cbn-drawer/cbn-drawer';
import { CbnDrawerHeader } from 'controls/cbn-drawer/cbn-drawer-header';
import { RelativeDateTime } from 'controls/datetime/relative-date-time';
import { sleep } from 'helper/async/async.helper';
import { cn } from 'helper/css.helper';
import { getPublishedCfgrLink } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { CfgnInsightsRouteParams, buildCfgrsPath } from 'services/routes/cfgrs-routes.service';
import { ShopTypeDisplayNames, getCfgnDetails } from 'slices/cfgn-insisghts/cfgn-insights.slice';

export const CfgnInsightDetailsDrawer: React.FC = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const muiTheme = useTheme();

  const navigate = useNavigate();
  const routeParams = useParams<CfgnInsightsRouteParams>();
  const { cfgnId } = routeParams;

  const { companyId, cfgrId } = useAppSelector(state => state.companyData.selection);
  const cfgnDetails = useAppSelector(state => state.cfgnInsights.cfgnDetails);
  const { stageName } = useAppSelector(state => state.cfgnInsights);
  const isLoading = cfgnDetails.loadingState === 'Pending' || !cfgnDetails.data;

  const hasResultData = Object.keys(cfgnDetails.data?.result ?? {}).length > 0;
  const hasInsightsValuesData = Object.keys(cfgnDetails.data?.insightsValues ?? {}).length > 0;
  const hasInputData = Object.keys(cfgnDetails.data?.input ?? {}).length > 0;

  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (cfgnId) {
      dispatch(getCfgnDetails({ companyId, cfgrId, stageName, cfgnId: cfgnId }));
    }
  }, [cfgnId, cfgrId, companyId, dispatch, stageName]);

  useLayoutEffect(() => {
    setDrawerOpen(true);
  }, []);

  const onClose = async (): Promise<void> => {
    setDrawerOpen(false);
    await sleep(muiTheme.transitions.duration.leavingScreen);
    // the navigate unmounts this component (as it's loaded via an <Outlet />)
    navigate(buildCfgrsPath('cfgninsights', companyId, cfgrId));
  };

  return (
    <CbnDrawer data-cmptype="CfgnInsightDetailsDrawer" open={drawerOpen} onClose={onClose}>
      <CbnDrawerHeader title={t('Configuration details')} subText={t(`of configuration ID ${cfgnId}`)} />
      <div className="w-[375px]">
        <div className="grid grid-cols-[auto_1fr] gap-x-2 border-b border-neutral-40 px-5 py-4 text-m-regular text-neutral-90">
          {cfgnDetails.data?.checkoutData?.type && (
            <HeaderRow
              emphasizedValue
              title={t('Checkout type')}
              value={t(
                ShopTypeDisplayNames[cfgnDetails.data.checkoutData.type].displayName ??
                  cfgnDetails.data.checkoutData.type
              )}
              isLoading={isLoading}
            />
          )}
          {cfgnDetails.data?.checkoutData?.price && (
            <HeaderRow
              emphasizedValue
              title={t('Price')}
              value={cfgnDetails.data.checkoutData.price.toFixed(2)}
              isLoading={isLoading}
            />
          )}
          <HeaderRow
            title={t('Created')}
            value={cfgnDetails.data && <RelativeDateTime onlyDate unixTime={cfgnDetails.data.createdAt} />}
            isLoading={isLoading}
          />
          <HeaderRow
            title={t('at')}
            value={cfgnDetails.data && <RelativeDateTime onlyTime unixTime={cfgnDetails.data.createdAt} />}
            isLoading={isLoading}
          />
          <HeaderRow
            title={t('by')}
            value={cfgnDetails.data?.createdBy ? <IdentityUser userId={cfgnDetails.data.createdBy} /> : t('Anonymous')}
            isLoading={isLoading}
          />
        </div>

        <div className="flex flex-col gap-3 px-2 py-4">
          {/*
          TODO Now ORT: Hide when no files or show disabled? If disabled: Refine "disabled state" styles.
                        Same for the other sections.
          */}
          {/* <CbnCard isCollapsible collapsed={isLoading || !hasFiles} variant="nested">
            <CbnCollapsibleCardHeader
              size="small"
              title={t('Files')}
              subText={t('created in the course of configuration')}
              disabled={isLoading || !hasFiles}
            />
            <DetailsCardBody>...</DetailsCardBody>
          </CbnCard> */}

          <CbnCard isCollapsible collapsed={true} variant="nested">
            <CbnCollapsibleCardHeader
              size="small"
              title={t('Insights')}
              subText={t('values of the defined insights fields')}
              disabled={isLoading || !hasInsightsValuesData}
            />
            <DetailsCardBody>
              <CfgnInsightsDetailsTable values={cfgnDetails.data?.insightsValues ?? {}} />
            </DetailsCardBody>
          </CbnCard>

          <CbnCard isCollapsible collapsed={true} variant="nested">
            <CbnCollapsibleCardHeader
              size="small"
              title={t('Results')}
              subText={t('values calculated by the configurator')}
              disabled={isLoading || !hasResultData}
            />
            <DetailsCardBody>
              <CfgnInsightsDetailsTable values={cfgnDetails.data?.result ?? {}} />
            </DetailsCardBody>
          </CbnCard>

          <CbnCard isCollapsible collapsed={true} variant="nested">
            <CbnCollapsibleCardHeader
              size="small"
              title={t('Inputs')}
              subText={t('values set by the user')}
              disabled={isLoading || !hasInputData}
            />
            <DetailsCardBody>
              <CfgnInsightsDetailsTable values={cfgnDetails.data?.input ?? {}} />
            </DetailsCardBody>
          </CbnCard>
        </div>

        {companyId && cfgrId && cfgnId && (
          <div className="flex gap-2 border-t border-t-neutral-50 p-5">
            <span className="flex-1 text-s-regular text-neutral-70">{t('To view or edit data, create a copy.')}</span>
            <span className="flex-1">
              <LinkButton
                variant="Secondary"
                text={t('Copy configuration')}
                Svg={CopyIcon}
                target="_blank"
                href={getPublishedCfgrLink(companyId, cfgrId, cfgnId, true)}
              />
            </span>
          </div>
        )}
      </div>
    </CbnDrawer>
  );
};

/**
 * Basically a plain card body but without the padding/margin. Needs a dedicated cmp instead of a simple `div` as we
 * couldn't use the `useCbnCard` hook otherwise...
 */
export const DetailsCardBody: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { collapsed } = useCbnCard();
  return <Collapse in={!collapsed}>{children}</Collapse>;
};

/**
 * To be used in 2-column grid layouts. The title is left-aligned, the value right-aligned.
 */
export const HeaderRow: React.FC<{
  title: React.ReactNode;
  value: React.ReactNode;
  emphasizedValue?: boolean;
  isLoading?: boolean;
}> = ({ title, value, emphasizedValue = false, isLoading = false }) => {
  return (
    <>
      <span className="text-neutral-70">{title}</span>
      <span
        className={cn('text-right text-m-regular text-neutral-90', emphasizedValue && 'text-m-medium text-neutral-100')}
      >
        {isLoading ? (
          <div className="flex justify-end">
            <Skeleton className="min-w-[94px]" />
          </div>
        ) : (
          value
        )}
      </span>
    </>
  );
};
