import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { CopyLinkIcon, OpenIcon, UploadIcon } from 'assets/icons';
import { AssetDownloadButton } from 'components/asset-editor/details/asset-body/asset-download-button';
import { AssetUploadSettingsModal } from 'components/asset-editor/overview/modals/asset-upload-settings-modal';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { Autocomplete } from 'controls/autocomplete/autocomplete';
import { buttonStyleFactory } from 'controls/button/button';
import { CopyToClipboardButton } from 'controls/button/copy-to-clipboard-button';
import { FileSelectButton } from 'controls/button/file-select-button';
import { LinkButton } from 'controls/button/link-button';
import { FormControlLabel } from 'controls/form/form-control-label';
import { SelectOption, SelectValue } from 'controls/select/select';
import { Tooltip } from 'controls/tooltip/tooltip';
import { AssetTypes } from 'generated/asset-types';
import {
  ASSET_TYPE_FILE_ENDINGS,
  AssetFile,
  OverwrittenAssetNames,
  createSourceStringFromFolderAndAssetId,
} from 'helper/assets/assets.helper';
import { cn } from 'helper/css.helper';
import { getFileExtension, getReadableFileSize } from 'helper/file/file.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { AssetsCompleteResponse } from 'services/assets/assets.service';
import { buildAssetEditorPath } from 'services/routes/asset-editor-routes.service';
import {
  TEXTURES_3D_FOLDER_NAME,
  clearUploadState,
  selectSelectedAssetPath,
  selectTextureAssets,
} from 'slices/assets/assets.slice';

type MaterialPropTextureImageProps = {
  disabled: boolean;
  linkedTexture?: string;
  onLinkedTextureChange: (value: string) => void;
  onUploadImageAssetStarted: () => void;
  onUploadImageAssetFinished: () => void;
};

export const MaterialPropTextureImage: React.FC<MaterialPropTextureImageProps> = ({
  disabled,
  linkedTexture,
  onLinkedTextureChange,
  onUploadImageAssetStarted,
  onUploadImageAssetFinished,
}) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();

  const [assetUploadSettingsModalActive, setAssetUploadSettingsModalActive] = useState(false);
  const [erroneousUploadText, setErroneousUploadText] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [predefinedAssetNames, setPredefinedAssetNames] = useState<OverwrittenAssetNames>({});

  const { companyId, bundleId, bundleVersion } = useAppSelector(selectSelectedAssetPath);
  const textureAssets = useAppSelector(selectTextureAssets);
  const sortedTextureAssets = Object.values(textureAssets).sort((a, b) => a.path.assetId.localeCompare(b.path.assetId));
  const linkedTextureAssetId = linkedTexture?.replace(TEXTURES_3D_FOLDER_NAME + '.', '');

  // get the linked asset with all it's data from the store
  const linkedAsset = Object.values(textureAssets).find(
    textureAsset => textureAsset.path.assetId.toLowerCase() === linkedTextureAssetId?.toLowerCase()
  );
  // NOTE: check is a bit redundant, but it helps with checking against `undefined` in the code below
  const assetIsMissing = !!linkedTexture && !!linkedTextureAssetId && !linkedAsset;
  // Needs to be "rebuilt" as given `value` could have different casing which would break the combobox selection
  const selectValue = linkedAsset
    ? createSourceStringFromFolderAndAssetId(linkedAsset.path.folderId, linkedAsset.path.assetId)
    : linkedTexture;

  // create link to the asset, if one is selected
  const linkedAssetUrl = linkedAsset
    ? buildAssetEditorPath(companyId, bundleId, bundleVersion, TEXTURES_3D_FOLDER_NAME, linkedTextureAssetId)
    : '';
  const linkedAssetText = linkedAsset ? `${bundleId}.${TEXTURES_3D_FOLDER_NAME}.${linkedTextureAssetId}` : '';

  const missingAssetOption: SelectOption[] = assetIsMissing
    ? [
        {
          value: linkedTexture,
          text: linkedTextureAssetId,
          disabled: true,
        },
      ]
    : [];

  // Add "-" option for removing a texture link
  const linkedTextureOptions: SelectOption[] = [
    ...missingAssetOption,
    { value: '', text: '-' },
    ...sortedTextureAssets.map<SelectOption>(asset => ({
      value: createSourceStringFromFolderAndAssetId(asset.path.folderId, asset.path.assetId),
      text: asset.path.assetId,
    })),
  ];

  const acceptedFileFormats = ASSET_TYPE_FILE_ENDINGS[AssetTypes.Image].map(entry => '.' + entry.key).join();

  const onUploadFilesSelected = useCallback(
    (files: File[]): void => {
      setAssetUploadSettingsModalActive(true);
      setSelectedFiles(files);

      // suggest name of the linked asset if it is not available yet, so that the name of the linked asset doesn't
      // change after the upload
      // also this is a single file upload, so we can be sure that there is only one file in the input files array
      const overwrittenAssetNames: OverwrittenAssetNames = assetIsMissing
        ? { [files[0].name]: linkedTextureAssetId }
        : {};
      setPredefinedAssetNames(overwrittenAssetNames);
    },
    [assetIsMissing, linkedTextureAssetId]
  );

  const onFileUploadStarted = useCallback(() => {
    setAssetUploadSettingsModalActive(false);
    // signalize upload start for load mask in parent component
    onUploadImageAssetStarted();
  }, [onUploadImageAssetStarted]);

  const onFileUploadFinished = useCallback(
    (fileBag: AssetFile[], result: AssetsCompleteResponse) => {
      onUploadImageAssetFinished();

      if (result.success) {
        // assign the new uploaded image asset as linked texture right away
        onLinkedTextureChange(`${TEXTURES_3D_FOLDER_NAME}.${fileBag[0].assetName}`);
      } else {
        // upload was not successful, show warning in texture UI
        setErroneousUploadText(result.failedAssets[0].failureReason);
        // reset upload warning state in linked image asset, so that we don't have to take care of this side effect
        // anymore
        dispatch(
          clearUploadState({
            companyId,
            bundleId,
            bundleVersion,
            folderId: TEXTURES_3D_FOLDER_NAME,
            assetId: fileBag[0].assetName,
          })
        );
      }
    },
    [bundleId, bundleVersion, companyId, dispatch, onLinkedTextureChange, onUploadImageAssetFinished]
  );

  return (
    <div data-cmptype="MaterialPropTextureImage" className="m-4 ml-6 flex flex-col gap-2">
      {assetIsMissing ? (
        <span className="text-s-regular text-warning-main">
          {t('Linked image asset is not available. Upload image or remove the link.')}
        </span>
      ) : !linkedTextureAssetId ? (
        <span className="text-s-regular">
          {t('Replace the original image data of the texture by selecting or uploading an image.')}
        </span>
      ) : null}

      <div className="flex gap-4">
        <FormControlLabel
          className="flex grow gap-4"
          labelPlacement="start"
          label={t('Image')}
          control={
            <Autocomplete
              options={linkedTextureOptions}
              value={selectValue}
              disableClearable
              disabled={disabled}
              onChange={(value: SelectValue): void => onLinkedTextureChange(value as string)}
              warning={assetIsMissing}
            />
          }
        />
        <span className="flex items-center text-m-regular">{t('or')}</span>
        <FileSelectButton
          variant="Outlined"
          text={t('Upload image')}
          Svg={UploadIcon}
          onSelect={onUploadFilesSelected}
          accept={acceptedFileFormats}
          multiple={false}
          disabled={disabled}
          title={t('Your image will be uploaded to the assets folder "Textures3d".')}
        />
      </div>
      {linkedAsset?.existsOnServer && (
        <div className="flex gap-4">
          <div className="flex min-w-0 grow flex-col">
            <div className="flex grow gap-2">
              <div className="flex items-center gap-2 overflow-hidden">
                <span className="text-m-regular">{t('Location:')}</span>
                <Tooltip title={t('Go to image asset')} arrow>
                  {/* Use plain <a> element as it is very cumbersome to integrate ellipsis overflow in
                      the `LinkButton` */}
                  <Link className={cn(buttonStyleFactory(false, 'Text'), 'mr-1 overflow-hidden')} to={linkedAssetUrl}>
                    <span className="overflow-hidden text-ellipsis">{linkedAssetText}</span>
                  </Link>
                </Tooltip>
              </div>
              <div className="flex items-center gap-1">
                <CopyToClipboardButton
                  variant="Outlined"
                  text={linkedAssetText}
                  title={t('Copy asset location')}
                  icon={CopyLinkIcon}
                />
                <AssetDownloadButton asset={linkedAsset} variant="Outlined" text="" title={t('Download image')} />
                <LinkButton variant="Outlined" href={linkedAssetUrl} Svg={OpenIcon} title={t('Go to image asset')} />
              </div>
            </div>
            <div className="flex grow items-center gap-4">
              <MaterialPropTextureImageData name={t('Type:')} value={getFileExtension(linkedAsset.url)} />
              <MaterialPropTextureImageData name={t('Size:')} value={getReadableFileSize(linkedAsset.size)} />
              <MaterialPropTextureImageData name={t('Height:')} value={linkedAsset.height + 'px'} />
              <MaterialPropTextureImageData name={t('Width:')} value={linkedAsset.width + 'px'} />
            </div>
          </div>
          <a href={linkedAsset.url} target="_blank" rel="noreferrer" className="flex h-16 border border-neutral-40">
            <img src={linkedAsset.url} alt={linkedAsset.path.assetId} loading="lazy" className="max-w-none" />
          </a>
        </div>
      )}
      {assetUploadSettingsModalActive && (
        <AssetUploadSettingsModal
          selectedFiles={selectedFiles}
          preSelectedFolderId={TEXTURES_3D_FOLDER_NAME}
          predefinedAssetNames={predefinedAssetNames}
          onUploadStarted={onFileUploadStarted}
          onUploadFinished={onFileUploadFinished}
          onCancel={(): void => setAssetUploadSettingsModalActive(false)}
        />
      )}
      {erroneousUploadText && (
        <UploadErrorModalDialog errorText={erroneousUploadText} onConfirm={(): void => setErroneousUploadText('')} />
      )}
    </div>
  );
};

type MaterialPropTextureImageDataProps = {
  name: string;
  value: string;
};

const MaterialPropTextureImageData: React.FC<MaterialPropTextureImageDataProps> = ({ name, value }) => {
  return (
    <div data-cmptype=" MaterialPropLinkedTextureImageData" className="flex items-center gap-2">
      <span className="text-s-medium text-neutral-90">{name}</span>
      <span className="text-s-medium text-neutral-100">{value}</span>
    </div>
  );
};

type UploadErrorModalDialogProps = {
  errorText: string;
  onConfirm: () => void;
};

const UploadErrorModalDialog: React.FC<UploadErrorModalDialogProps> = ({ errorText, onConfirm }) => {
  const { t } = useTypedTranslation();

  return (
    <ModalDialog
      data-cmptype="UploadErrorModalDialog"
      variant="Warning"
      actionTypes="ConfirmOnly"
      header={t('Upload failed')}
      confirmText={t('OK')}
      actions={{ onConfirm }}
    >
      <div className="flex flex-col gap-4">
        <span className="text-m-regular text-neutral-70">
          {t('The image you selected could not be uploaded and used for the texture. See error message below.')}
        </span>
        <span className="text-m-regular text-neutral-70">{errorText}</span>
      </div>
    </ModalDialog>
  );
};
