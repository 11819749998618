import { COMPANYID_ROUTE_PARAMS } from 'services/routes/common-routeparams.service';
import { buildPath } from 'services/routes/routes.service';

export const ASSETS_BASE_PATH = '/asseteditor';

const _ASSET_EDITOR_ROUTE_PARAMS = {
  ...COMPANYID_ROUTE_PARAMS,
  bundleId: 'bundleId',
  bundleVersion: 'bundleVersion',
  assetId: 'assetId',
  folderId: 'folderId',
};
export type AssetEditorRouteParams = keyof typeof _ASSET_EDITOR_ROUTE_PARAMS;

// prettier-ignore
export const ASSET_EDITOR_PATH_VARIANTS = {
  assetEditor:              `${ASSETS_BASE_PATH}/:${_ASSET_EDITOR_ROUTE_PARAMS.companyId}`,
  assetEditorBundle:        `${ASSETS_BASE_PATH}/:${_ASSET_EDITOR_ROUTE_PARAMS.companyId}/:${_ASSET_EDITOR_ROUTE_PARAMS.bundleId}`,
  assetEditorBundleVersion: `${ASSETS_BASE_PATH}/:${_ASSET_EDITOR_ROUTE_PARAMS.companyId}/:${_ASSET_EDITOR_ROUTE_PARAMS.bundleId}/:${_ASSET_EDITOR_ROUTE_PARAMS.bundleVersion}`,
  assetEditorAsset:         `${ASSETS_BASE_PATH}/:${_ASSET_EDITOR_ROUTE_PARAMS.companyId}/:${_ASSET_EDITOR_ROUTE_PARAMS.bundleId}/:${_ASSET_EDITOR_ROUTE_PARAMS.bundleVersion}/:${_ASSET_EDITOR_ROUTE_PARAMS.assetId}`,
  assetEditorNestedAsset:   `${ASSETS_BASE_PATH}/:${_ASSET_EDITOR_ROUTE_PARAMS.companyId}/:${_ASSET_EDITOR_ROUTE_PARAMS.bundleId}/:${_ASSET_EDITOR_ROUTE_PARAMS.bundleVersion}/:${_ASSET_EDITOR_ROUTE_PARAMS.folderId}/:${_ASSET_EDITOR_ROUTE_PARAMS.assetId}`,
};

export function buildAssetEditorPath(
  companyId: string,
  bundleId?: string,
  bundleVersion?: string,
  folderId?: string,
  assetId?: string
): string {
  return buildPath(
    ASSETS_BASE_PATH,
    companyId,
    bundleId,
    bundleVersion,
    folderId ? folderId : assetId,
    folderId ? assetId : undefined
  );
}
