import { useState } from 'react';
import { InfoIcon } from 'assets/icons';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { FormItem } from 'controls/form-item/form-item';
import { Icon } from 'controls/icon/icon';
import { TextInput } from 'controls/input/text-input';
import { AssetTypes } from 'generated/asset-types';
import { isUnusedAssetKey } from 'helper/assets/assets.helper';
import { isValidHiveIdentifier } from 'helper/hive/hive.helper';
import { useNavigateToAssetEditor } from 'hooks/assets/assets.hooks';
import { useAlertDialog } from 'hooks/common/dialog.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { postAssetsCreateEmpty } from 'services/assets/assets.service';
import {
  AssetPath,
  MATERIALS_3D_FOLDER_NAME,
  getBundleVersionData,
  selectAssetKeys,
  selectSelectedAssetPath,
} from 'slices/assets/assets.slice';

type CreateMaterialAssetModalProps = {
  onConfirm?: () => void;
  onCancel: () => void;
};

export const CreateMaterialAssetModal: React.FC<CreateMaterialAssetModalProps> = ({ onConfirm, onCancel }) => {
  const { t } = useTypedTranslation();
  const navigateToAssetEditor = useNavigateToAssetEditor();
  const dispatch = useAppDispatch();
  const alert = useAlertDialog();

  const selectedAssetPath = useAppSelector(selectSelectedAssetPath);
  const assetKeys = useAppSelector(selectAssetKeys);

  const [isProcessing, setIsProcessing] = useState(false);
  const [newAssetName, setNewAssetName] = useState('');

  const isValidAssetName = isValidHiveIdentifier(newAssetName);
  const isInMaterials3dFolder =
    selectedAssetPath.folderId === MATERIALS_3D_FOLDER_NAME || selectedAssetPath.assetId === MATERIALS_3D_FOLDER_NAME;

  const onAssetConfirm = async (): Promise<void> => {
    const newAssetPath: AssetPath = { ...selectedAssetPath, folderId: MATERIALS_3D_FOLDER_NAME, assetId: newAssetName };
    if (!isUnusedAssetKey(newAssetPath, assetKeys)) {
      // TODO: proper error handling
      alert(t('Asset name already used!'), { headerText: t('Duplicate name'), variant: 'Warning' });
      return;
    }

    setIsProcessing(true);
    const { companyId, bundleId, bundleVersion } = newAssetPath;

    await postAssetsCreateEmpty(companyId, bundleId, bundleVersion, [
      {
        assetName: newAssetName,
        folderName: MATERIALS_3D_FOLDER_NAME,
        fileExtension: '',
        assetType: AssetTypes.Material,
      },
    ]);

    // TODO: create async thunk which calls "getBundleVersionData" right away
    await dispatch(getBundleVersionData({ companyId, bundleId, bundleVersion }));

    navigateToAssetEditor(newAssetPath);

    setIsProcessing(false);
    onConfirm?.();
  };

  return (
    <ModalDialog
      variant="NoIcon"
      data-cmptype="CreateMaterialAsset"
      header={t('New material asset')}
      actions={{ onConfirm: onAssetConfirm, onCancel: onCancel }}
      confirmDisabled={!isValidAssetName}
      confirmText={t('Create asset')}
      isProcessingConfirm={isProcessing}
    >
      <div className="flex grow flex-col gap-3">
        <FormItem labelContent={t('Asset name')}>
          <TextInput
            value={newAssetName}
            onValueChange={(val): void => setNewAssetName(val)}
            autoFocus
            error={newAssetName.length > 0 && !isValidAssetName}
          />
        </FormItem>
        {!isInMaterials3dFolder && (
          <div className="flex items-center gap-2 text-s-regular">
            <Icon Svg={InfoIcon} className="w-5" />
            <span>{t('The material asset will be created in the "Materials3d" folder')}</span>
          </div>
        )}
      </div>
    </ModalDialog>
  );
};
