import { Navigate } from 'react-router-dom';
import { useHasPendingGlobalRouteChange } from 'hooks/app/route.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { CfgrsRoutePage, buildCfgrsPath } from 'services/routes/cfgrs-routes.service';
import { selectAllCompanyCfgrs } from 'slices/company-data/company-data.slice';

type NavigateToCfgrProps = {
  page: CfgrsRoutePage;
};

/**
 * REDIRECT COMPONENT\
 * Use this component if the route makes no sense without a selected cfgr and
 * therefore should instantly redirect to one
 * Any form of validation & error handling should happen in the redirected route
 */
export const NavigateToCfgr: React.FC<NavigateToCfgrProps> = ({ page }) => {
  const companiesFetchedState = useAppSelector(state => state.companyData.companiesFetched);
  const cfgrFetchedState = useAppSelector(state => state.companyData.cfgrsFetched);
  const selectedCompanyId = useAppSelector(state => state.companyData.selection.companyId);
  const selectedCfgrId = useAppSelector(state => state.companyData.selection.cfgrId);

  const hasPendingRouteChanges = useHasPendingGlobalRouteChange();

  const cfgrs = useAppSelector(selectAllCompanyCfgrs);
  const cfgrsSorted = [...cfgrs].sort((a, b) => a.displayName.localeCompare(b.displayName));
  const firstAvailableCfgr = cfgrsSorted[0];

  if (hasPendingRouteChanges) {
    return null;
  }

  if (selectedCompanyId && selectedCfgrId) {
    // restore the last known IDs
    return <Navigate to={buildCfgrsPath(page, selectedCompanyId, selectedCfgrId)} replace />;
  }

  if (companiesFetchedState === 'Fetched' && cfgrFetchedState === 'Fetched' && firstAvailableCfgr) {
    // redirect to the first known IDs
    return <Navigate to={buildCfgrsPath(page, firstAvailableCfgr.companyId, firstAvailableCfgr.id)} replace />;
  }

  return null;
};
