import { useEffect, useMemo } from 'react';
import { ApiIcon, UserProfileIcon, WorkflowsIcon } from 'assets/icons';
import { SvgComponent } from 'controls/icon/icon';
import { getTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { LockStatus, isCfgrEditorDraftLockStatus } from 'services/company-data/company-data.service';
import {
  Identity,
  isApiTokenIdentity,
  isServiceIdentity,
  isUserIdentity,
  isWorkflowIdentity,
} from 'services/identity/identity.service';
import { fetchIdentity } from 'slices/identity/identity.slice';

export const useIdentityIsCurrentUser = (identityId: string | undefined): boolean => {
  const currentUserId = useAppSelector(state => state.auth.userInfo.id);

  if (!identityId) {
    return false;
  }

  const identityParts = identityId.split('.');
  if (!identityId.startsWith('$') || identityParts.length !== 2) {
    return false;
  }

  return currentUserId === identityParts[1];
};

export const useIsLockedByCurrentUser = (lockStatus: LockStatus | undefined): boolean => {
  const lockIdentityId = isCfgrEditorDraftLockStatus(lockStatus) ? lockStatus.heldBy : undefined;
  const isCurrentUserLock = useIdentityIsCurrentUser(lockIdentityId);

  return isCurrentUserLock;
};

export type DisplayParts = {
  icon: SvgComponent;
  name: string;
  tooltipHeader?: string;
  tooltipDetail?: string;
};

export type IdentityUserDisplayPartsMap = {
  [identityId: string]: { isFetching: boolean; displayParts: DisplayParts } | undefined;
};

/**
 * Returns "display friendly", formatted values for a given list of identity IDs.
 * Either takes the info from the store or fetches it from the `/identity/get` API if not available.
 */
export const useIdentityUserDisplayParts = (identityIds: string[]): IdentityUserDisplayPartsMap => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(state => state.identity.identities);
  const invalidIds = useAppSelector(state => state.identity.invalidIdentityIds);

  const identitiesInfo = useMemo(
    () =>
      identityIds.reduce(
        (acc, id) => {
          // The $ is our main indicator for an identity
          // In case of false positives it still falls back to the input `identityId` (after a failed request)
          const isIdentityUser = id.startsWith('$') && !invalidIds.includes(id);
          const identityUser = isIdentityUser ? users[id] : null;
          const displayParts = getIdentityDisplayParts(identityUser, id);

          const requiresFetching = isIdentityUser && !identityUser;

          acc[id] = { isFetching: requiresFetching, displayParts };
          return acc;
        },
        {} as { [identityId: string]: { isFetching: boolean; displayParts: DisplayParts } }
      ),
    [identityIds, invalidIds, users]
  );

  useEffect(() => {
    const fetchUnknownIdentityUsers = async (): Promise<void> => {
      for (const id in identitiesInfo) {
        if (identitiesInfo[id].isFetching) {
          dispatch(fetchIdentity(id));
        }
      }
    };
    fetchUnknownIdentityUsers();
  }, [dispatch, identitiesInfo]);

  return identitiesInfo;
};

/**
 * Map identity object to how it will be displayed to the user
 */
export function getIdentityDisplayParts(identity: Identity | null, fallback: string): DisplayParts {
  const { t } = getTypedTranslation();

  if (!identity) {
    const name = fallback ? fallback.split('@')[0] : '';
    return {
      name: name,
      icon: UserProfileIcon,
      tooltipHeader: fallback,
    };
  }

  if (isUserIdentity(identity)) {
    const name = `${identity.firstname} ${identity.lastname}`;
    return {
      name: name,
      icon: UserProfileIcon,
      tooltipHeader: name,
      tooltipDetail: identity.email,
    };
  } else if (isWorkflowIdentity(identity)) {
    return {
      name: identity.displayName,
      icon: WorkflowsIcon,
      tooltipHeader: t('Automatic workflow service'),
      tooltipDetail: identity.displayName,
    };
  } else if (isApiTokenIdentity(identity)) {
    return {
      name: identity.displayName,
      icon: ApiIcon,
      tooltipHeader: t('API token'),
      tooltipDetail: identity.displayName,
    };
  } else if (isServiceIdentity(identity)) {
    return {
      name: 'CbnService',
      icon: WorkflowsIcon,
      tooltipHeader: 'Combeenation Service',
    };
  } else {
    return {
      name: fallback,
      icon: UserProfileIcon,
    };
  }
}
