import { CreateCfgrTemplatePicker } from 'components/create-cfgr-template-picker/create-cfgr-template-picker';
import { CreateCfgrTemplatePickerHeader } from 'components/create-cfgr-template-picker/create-cfgr-template-picker-header';
import { HomeQuickstartCards } from 'components/home/home-quickstart-cards';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { usePermission } from 'hooks/permission/permission.hooks';
import { useAppSelector } from 'hooks/store/store.hooks';
import { CompanyPermissions } from 'slices/permission/permission.slice';

export const Home: React.FC = () => {
  const { t } = useTypedTranslation();
  const { companyId } = useAppSelector(state => state.companyData.selection);
  const hasManageCfgrPermission = usePermission(CompanyPermissions.ManageConfigurator, companyId);

  return (
    <div data-cmptype="Home" className="flex flex-col gap-6 p-6">
      <div className="flex flex-col gap-1">
        <h1 className="pt-2.5">{t('Welcome to Combeenation!')}</h1>
        <div className="text-m-regular text-neutral-90">
          {t('This is your entry to creating awesome configurators.')}
        </div>
      </div>

      <HomeQuickstartCards companyId={companyId} />

      {hasManageCfgrPermission && (
        <CbnCard>
          <CreateCfgrTemplatePickerHeader />
          <CbnCardBody>
            <CreateCfgrTemplatePicker companyId={companyId} />
          </CbnCardBody>
        </CbnCard>
      )}
    </div>
  );
};
