import React, { useState } from 'react';
import { EyeHiddenIcon, EyeShowVisibleIcon } from 'assets/icons';
import { Icon } from 'controls/icon/icon';
import { Input, InputProps } from 'controls/input/input';

export type PasswordInputProps = Pick<
  InputProps,
  'error' | 'placeholder' | 'value' | 'autoFocus' | 'autoComplete' | 'helperText' | 'id'
> & {
  onValueChange?: (value: string) => void;
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  onValueChange,
  autoComplete = 'current-password',
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Input
      type={showPassword ? 'text' : 'password'}
      onChange={(evt): void => {
        onValueChange?.(evt.target.value);
      }}
      autoComplete={autoComplete}
      endAdornment={
        <Icon
          className="h-6 w-6 cursor-pointer"
          onClick={(): void => setShowPassword(showPassword => !showPassword)}
          Svg={showPassword ? EyeHiddenIcon : EyeShowVisibleIcon}
        ></Icon>
      }
      {...props}
    />
  );
};
