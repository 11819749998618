import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/store/store.hooks';
import { CfgrRouteParams } from 'services/routes/common-routeparams.service';

/**
 * Returns `true` if the route changes aren't applied in the redux-store yet
 * This is helpful if the router already renders a cmp although redux still has "work to do"
 * (basically like an `await` in components)\
 * E.g.: A new route changes the `companyId` but `globalCompanySwitch` is happening async
 */
export const useHasPendingGlobalRouteChange = (): boolean => {
  const routeParams = useParams<CfgrRouteParams>();

  const selection = useAppSelector(state => state.companyData.selection);

  const hasPendingCompanyChange = useMemo(() => {
    const isPending = !!routeParams.companyId && routeParams.companyId !== selection.companyId;
    return isPending;
  }, [routeParams.companyId, selection.companyId]);

  const hasPendingCfgrChange = useMemo(() => {
    const isPending = !!routeParams.cfgrId && routeParams.cfgrId !== selection.cfgrId;
    return isPending;
  }, [routeParams.cfgrId, selection.cfgrId]);

  return hasPendingCompanyChange || hasPendingCfgrChange;
};
