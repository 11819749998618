import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SchemaNameInput, SchemaNameInputResult } from 'components/common/schema-name-input';
import { Button } from 'controls/button/button';
import { LinkButton } from 'controls/button/link-button';
import { Card } from 'controls/card/card';
import { Checkbox } from 'controls/checkbox/checkbox';
import { FormItem } from 'controls/form-item/form-item';
import { UserPasswordFormItem } from 'controls/form-item/user-password-form-item';
import { TextInput } from 'controls/input/text-input';
import { CBN_MAIL_ADRESSES } from 'helper/url/cfgr-url.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch } from 'hooks/store/store.hooks';
import { RegisterResponse, registerResponses } from 'services/auth/auth.service';
import { isGenericActionResult } from 'services/http/http.service';
import { PATHS } from 'services/routes/paths.service';
import { LocationProps } from 'services/routes/routes.service';
import { register } from 'slices/auth/auth.slice';

type UserRegisterCardProps = {
  /** Path which is used as target for the "Already have an account" link below the card */
  loginLinkPath: string;

  /**
   * If given, the "company schema input" is not shown and the API uses a different endpoint to register the user and
   * add him to the given company in one step.
   */
  inviteData?: {
    companyName: string;
    inviteToken: string;
    inviteRecipient?: string;
  };
};

/**
 * Register card + link to register page below.\
 * Also performs the necessary API calls + redirect, update of redux state etc.
 */
export const UserRegistrationCard: React.FC<UserRegisterCardProps> = ({ loginLinkPath, inviteData }) => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation() as LocationProps;
  const navigate = useNavigate();

  const genericRegistrationFailedMsg = t('Registration failed');
  const notAllEnteredMsg = t('Please fill in all fields');

  const [emailInput, setEmailInput] = useState(inviteData?.inviteRecipient ?? '');
  const [companyNameInput, setCompanyNameInput] = useState<SchemaNameInputResult>({
    uniqueId: '',
    displayName: '',
  });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [acceptTermsChecked, setAcceptTermsChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseStatus, setResponseStatus] = useState<RegisterResponse>();
  const [responseMsg, setResponseMsg] = useState('');
  const isNotAllEntered =
    !emailInput || !passwordInput || !firstName || !lastName || companyNameInput.hasErrors || !acceptTermsChecked;
  const errorMsg = isNotAllEntered
    ? notAllEnteredMsg
    : responseStatus && responseStatus === 'RegistrationFailed'
      ? genericRegistrationFailedMsg
      : responseMsg;

  const mailError = isSubmitted && !emailInput;
  const pwError = isSubmitted && (responseStatus === 'InvalidPassword' || !passwordInput);

  const onRegister = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    setIsSubmitted(true);
    if (isNotAllEntered) {
      return;
    }

    let success = false;
    try {
      setIsSubmitting(true);
      const result = await dispatch(
        register({
          email: emailInput.trim(),
          password: passwordInput,
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          companyName: inviteData?.companyName ?? companyNameInput.uniqueId.trim(),
          ...(!inviteData && { companyDisplayName: companyNameInput.displayName.trim() }),
          ...(inviteData && { inviteToken: inviteData.inviteToken }),
        })
      ).unwrap();

      if (inviteData && !result.isCompanyJoinSuccessful) {
        navigate(PATHS.acceptCompanyInvitationInvalid);
        return;
      }

      setResponseStatus('Success');
      setResponseMsg('');
      success = true;
    } catch (res: unknown) {
      if (isGenericActionResult(res, registerResponses)) {
        setResponseStatus(res.Id);
        setResponseMsg(res.Message || genericRegistrationFailedMsg);
      } else {
        setResponseStatus('RegistrationFailed');
      }
    }

    setIsSubmitting(false);

    if (success) {
      const target = location.state?.referrer || '/';
      navigate(target, { state: null });
    }
  };

  return (
    <div data-cmptype="UserRegistrationCard" className="flex flex-grow flex-col items-center gap-6">
      <Card>
        <div className="flex w-100 flex-col gap-6 pt-3">
          <div className="flex flex-col gap-2">
            <h3>{t('Create account')}</h3>
            {responseStatus !== 'Success' && (
              <span className="text-m-regular text-neutral-70">
                {t('Welcome to Combeenation. Start by creating an account and filling in the form below.')}
              </span>
            )}
          </div>

          {responseStatus !== 'Success' && (
            <form onSubmit={onRegister}>
              <div className="flex flex-col gap-6">
                <div className="flex gap-4">
                  <FormItem labelContent={t('First name') + ' *'}>
                    <TextInput
                      value={firstName}
                      autoFocus
                      placeholder="John"
                      onValueChange={(val): void => {
                        setFirstName(val);
                        setIsSubmitted(false);
                      }}
                      error={isSubmitted && !firstName}
                    />
                  </FormItem>

                  <FormItem labelContent={t('Last name') + ' *'}>
                    <TextInput
                      value={lastName}
                      placeholder="Doe"
                      onValueChange={(val): void => {
                        setLastName(val);
                        setIsSubmitted(false);
                      }}
                      error={isSubmitted && !lastName}
                    />
                  </FormItem>
                </div>

                <FormItem labelContent={t('Business email') + ' *'}>
                  <TextInput
                    value={emailInput}
                    type="text"
                    error={mailError}
                    autoComplete="username"
                    placeholder={CBN_MAIL_ADRESSES.placeholderMail}
                    onValueChange={(val): void => {
                      setEmailInput(val);
                      setIsSubmitted(false);
                    }}
                  ></TextInput>
                </FormItem>

                <UserPasswordFormItem
                  onValueChanged={(val): void => {
                    setPasswordInput(val);
                    setIsSubmitted(false);
                  }}
                  hasError={pwError}
                />

                {!inviteData && (
                  <div className="flex flex-col gap-4">
                    <SchemaNameInput
                      input={companyNameInput}
                      placeholder={{ displayName: 'MyCompany', uniqueId: 'MYCOMPANY' }}
                      onChange={(x): void => setCompanyNameInput(x)}
                      unavailableIds={[]}
                      customLabels={{
                        name: (
                          <div>
                            <div>{t('Choose company name') + ' *'}</div>
                            <div className="text-s-regular text-neutral-70">
                              {t(
                                'This name will be used to create a unique ID that will be included in the URL of your configurator. '
                              )}
                              <LinkButton
                                variant="TextInline"
                                href="https://docs.combeenation.com/docs/getting-started-on-the-platform#create-a-new-company"
                                text={t('What does that mean?')}
                                target="_blank"
                              />
                            </div>
                          </div>
                        ),
                        id: <div className="text-s-regular">{t('Company ID:')}</div>,
                        idLabelInline: true,
                      }}
                      startWithValidNameSuggestion
                      autoFocus={false}
                    />
                  </div>
                )}
              </div>

              <label className="mt-5 flex items-center gap-2 text-m-regular">
                <Checkbox checked={acceptTermsChecked} onValueChanged={(val): void => setAcceptTermsChecked(val)} />
                <span>
                  {t('I have read and accept the')}
                  <LinkButton
                    variant="TextInline"
                    href={process.env.PUBLIC_URL + '/cbn_platform_terms_and_conditions_2024-04-03.pdf'}
                    text={t('terms and conditions')}
                    target="_blank"
                  />
                  {t('. *')}
                </span>
              </label>

              <div className="mt-8 flex flex-grow">
                <Button
                  type="submit"
                  variant="Primary"
                  big
                  grow
                  text={t('Create account')}
                  isLoading={isSubmitting}
                  disabled={isNotAllEntered}
                />
              </div>

              {isSubmitted && errorMsg && (
                //toAsk - clear inputs after failed registration
                <span className=" text-s-regular text-danger-main">{errorMsg}</span>
              )}
            </form>
          )}
        </div>
      </Card>

      <div className="flex flex-row items-center gap-1 text-m-regular text-neutral-90">
        <span>{t('Already have an account?')}</span>
        <LinkButton variant="TextInline" href={loginLinkPath} text={t('Log in')} state={location.state} />
      </div>
    </div>
  );
};
