import { useState } from 'react';
import {
  BabylonAssetIcon,
  CloudUploadIcon,
  DataSourceAssetIcon,
  FileAssetIcon,
  FolderAssetIcon,
  ImageAssetIcon,
  PlusIcon,
  TextAssetHtmlIcon,
  TextAssetJsonIcon,
  TextAssetSvgIcon,
  TextAssetTextIcon,
  TextAssetXmlIcon,
} from 'assets/icons';
import { AssetUploadSettingsModal } from 'components/asset-editor/overview/modals/asset-upload-settings-modal';
import { CreateFolderAssetModal } from 'components/asset-editor/overview/modals/create-folder-asset-modal';
import { CreateMaterialAssetModal } from 'components/asset-editor/overview/modals/create-material-asset-modal';
import {
  CreateTextAssetModal,
  PredefinedExtension,
} from 'components/asset-editor/overview/modals/create-text-asset-modal';
import { DragDropZone } from 'components/dragdrop/drag-drop-zone';
import { Button } from 'controls/button/button';
import { FileSelectButton } from 'controls/button/file-select-button';
import { CbnCard } from 'controls/cbn-card/cbn-card';
import { CbnCardBody } from 'controls/cbn-card/cbn-card-body';
import { CbnCardHeader } from 'controls/cbn-card/cbn-card-header';
import { CbnInfoCard } from 'controls/cbn-card/implementations/cbn-info-card';
import { CbnDragAndDropPrompt } from 'controls/cbn-drag-and-drop-prompt/cbn-drag-and-drop-prompt';
import { AssetTypes } from 'generated/asset-types';
import { ASSET_TYPE_FILE_ENDINGS } from 'helper/assets/assets.helper';
import { useIsAssetBundleEditable } from 'hooks/assets/assets.hooks';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';

export const NoAssetsFallback: React.FC = () => {
  const { t } = useTypedTranslation();
  const isAssetBundleEditable = useIsAssetBundleEditable();

  const [createFolderAssetModal, setCreateFolderAssetModal] = useState(false);
  const [createTextAssetModal, setCreateTextAssetModal] = useState(false);
  const [createMaterialAssetModal, setCreateMaterialAssetModal] = useState(false);
  const [assetUploadSettingsModal, setAssetUploadSettingsModal] = useState(false);
  const [predefinedExtension, setPredefinedExtension] = useState<PredefinedExtension>();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const dataSourceAssetFileEndings = ASSET_TYPE_FILE_ENDINGS[AssetTypes.DataSource]
    .map(entry => '.' + entry.key)
    .join();
  const imageAssetFileEndings = ASSET_TYPE_FILE_ENDINGS[AssetTypes.Image].map(entry => '.' + entry.key).join();
  const babylonAssetFileEndings = ASSET_TYPE_FILE_ENDINGS[AssetTypes.BabylonJs].map(entry => '.' + entry.key).join();

  const onUploadFilesSelected = async (files: File[]): Promise<void> => {
    setSelectedFiles(files);
    setAssetUploadSettingsModal(true);
  };

  return (
    <>
      <CbnCard>
        <CbnCardHeader title={t('Upload or create assets')} />

        <CbnCardBody>
          <div className="flex flex-col gap-3">
            <span className="text-m-regular text-neutral-100">{t('There is no data in this asset bundle.')}</span>
            {isAssetBundleEditable && (
              <>
                <CbnDragAndDropPrompt onUploadFilesSelected={onUploadFilesSelected} />
                <div className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-3">
                  <CbnInfoCard
                    icon={FolderAssetIcon}
                    title={t('Folder')}
                    subText={t('Use folders to structure your asset contents.')}
                    actions={
                      <Button
                        variant="Secondary"
                        text={t('Create')}
                        Svg={PlusIcon}
                        onClick={(): void => setCreateFolderAssetModal(true)}
                      />
                    }
                  />
                  <CbnInfoCard
                    icon={TextAssetTextIcon}
                    title={t('Text')}
                    subText={t('Create plain text content.')}
                    actions={
                      <div className="flex grow flex-row justify-between gap-2">
                        <Button
                          variant="Secondary"
                          text={t('Create')}
                          Svg={PlusIcon}
                          onClick={(): void => {
                            setPredefinedExtension('txt');
                            setCreateTextAssetModal(true);
                          }}
                        />
                        <FileSelectButton
                          variant="Secondary"
                          text={t('Upload')}
                          Svg={CloudUploadIcon}
                          accept=".txt"
                          onSelect={onUploadFilesSelected}
                        />
                      </div>
                    }
                  />
                  <CbnInfoCard
                    icon={TextAssetSvgIcon}
                    title={t('SVG')}
                    subText={t('Create SVG markup to access in your configurators.')}
                    actions={
                      <div className="flex grow flex-row justify-between gap-2">
                        <Button
                          variant="Secondary"
                          text={t('Create')}
                          Svg={PlusIcon}
                          onClick={(): void => {
                            setPredefinedExtension('svg');
                            setCreateTextAssetModal(true);
                          }}
                        />
                        <FileSelectButton
                          variant="Secondary"
                          text={t('Upload')}
                          Svg={CloudUploadIcon}
                          accept=".svg"
                          onSelect={onUploadFilesSelected}
                        />
                      </div>
                    }
                  />
                  <CbnInfoCard
                    icon={TextAssetXmlIcon}
                    title={t('XML')}
                    subText={t('Create XML markup to directly access in your configurators.')}
                    actions={
                      <div className="flex grow flex-row justify-between gap-2">
                        <Button
                          variant="Secondary"
                          text={t('Create')}
                          Svg={PlusIcon}
                          onClick={(): void => {
                            setPredefinedExtension('xml');
                            setCreateTextAssetModal(true);
                          }}
                        />
                        <FileSelectButton
                          variant="Secondary"
                          text={t('Upload')}
                          Svg={CloudUploadIcon}
                          accept=".xml"
                          onSelect={onUploadFilesSelected}
                        />
                      </div>
                    }
                  />
                  <CbnInfoCard
                    icon={TextAssetHtmlIcon}
                    title={t('HTML')}
                    subText={t('Create HTML markup to directly access in your configurators.')}
                    actions={
                      <div className="flex grow flex-row justify-between gap-2">
                        <Button
                          variant="Secondary"
                          text={t('Create')}
                          Svg={PlusIcon}
                          onClick={(): void => {
                            setPredefinedExtension('html');
                            setCreateTextAssetModal(true);
                          }}
                        />
                        <FileSelectButton
                          variant="Secondary"
                          text={t('Upload')}
                          Svg={CloudUploadIcon}
                          accept=".html"
                          onSelect={onUploadFilesSelected}
                        />
                      </div>
                    }
                  />
                  <CbnInfoCard
                    icon={TextAssetJsonIcon}
                    title={t('JSON')}
                    subText={t('Create JSON data to access in your configurators.')}
                    actions={
                      <div className="flex grow flex-row justify-between gap-2">
                        <Button
                          variant="Secondary"
                          text={t('Create')}
                          Svg={PlusIcon}
                          onClick={(): void => {
                            setPredefinedExtension('json');
                            setCreateTextAssetModal(true);
                          }}
                        />
                        <FileSelectButton
                          variant="Secondary"
                          text={t('Upload')}
                          Svg={CloudUploadIcon}
                          accept=".json"
                          onSelect={onUploadFilesSelected}
                        />
                      </div>
                    }
                  />
                  <CbnInfoCard
                    icon={DataSourceAssetIcon}
                    title={t('Spreadsheet')}
                    subText={t('Upload a xls, xslx or csv file to create a DataSource your configurator can access.')}
                    actions={
                      <FileSelectButton
                        variant="Secondary"
                        text={t('Upload')}
                        Svg={CloudUploadIcon}
                        onSelect={onUploadFilesSelected}
                        accept={dataSourceAssetFileEndings}
                      />
                    }
                  />
                  <CbnInfoCard
                    icon={ImageAssetIcon}
                    title={t('Image')}
                    subText={t('Upload graphics and images as png, jpg or gif.')}
                    actions={
                      <FileSelectButton
                        variant="Secondary"
                        text={t('Upload')}
                        Svg={CloudUploadIcon}
                        onSelect={onUploadFilesSelected}
                        accept={imageAssetFileEndings}
                      />
                    }
                  />
                  <CbnInfoCard
                    icon={BabylonAssetIcon}
                    title={t('3D file')}
                    subText={t('Upload glb or babylon files and access them with the Combeenation 3d viewer.')}
                    actions={
                      <FileSelectButton
                        variant="Secondary"
                        text={t('Upload')}
                        Svg={CloudUploadIcon}
                        onSelect={onUploadFilesSelected}
                        accept={babylonAssetFileEndings}
                      />
                    }
                  />
                  <CbnInfoCard
                    icon={FileAssetIcon}
                    title={t('Files')}
                    subText={t(
                      'Files for further use: to access them in the configurator, but also e.g. as a download source.'
                    )}
                    actions={
                      <FileSelectButton
                        variant="Secondary"
                        text={t('Upload')}
                        Svg={CloudUploadIcon}
                        onSelect={onUploadFilesSelected}
                      />
                    }
                  />
                </div>
              </>
            )}
          </div>
        </CbnCardBody>

        <DragDropZone onFilesDrop={onUploadFilesSelected} infoText={t('Drop file(s) to add new assets')} />
      </CbnCard>

      {createFolderAssetModal && (
        <CreateFolderAssetModal
          onConfirm={(): void => setCreateFolderAssetModal(false)}
          onCancel={(): void => setCreateFolderAssetModal(false)}
        />
      )}
      {createTextAssetModal && (
        <CreateTextAssetModal
          predefinedExtension={predefinedExtension}
          onConfirm={(): void => setCreateTextAssetModal(false)}
          onCancel={(): void => setCreateTextAssetModal(false)}
        />
      )}
      {createMaterialAssetModal && (
        <CreateMaterialAssetModal
          onConfirm={(): void => setCreateMaterialAssetModal(false)}
          onCancel={(): void => setCreateMaterialAssetModal(false)}
        />
      )}
      {assetUploadSettingsModal && (
        <AssetUploadSettingsModal
          selectedFiles={selectedFiles}
          preSelectedFolderId=""
          onUploadStarted={(): void => setAssetUploadSettingsModal(false)}
          onCancel={(): void => setAssetUploadSettingsModal(false)}
        />
      )}
    </>
  );
};
