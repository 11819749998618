import { Collapse } from '@mui/material';
import { ReactElement } from 'react';
import { ConditionalWrapper } from 'components/common/conditional-wrapper';
import { useCbnCard } from 'controls/cbn-card/cbn-card-provider';

type CbnCardBodyProps = {
  children: React.ReactNode;
};

/**
 * Usually used in conjunction with `CbnCard` to get default card spacings etc.
 *
 * See {@link CbnCard} for example usage.
 */
export const CbnCardBody: React.FC<CbnCardBodyProps> = ({ children }) => {
  const { isCollapsible, collapsed } = useCbnCard();

  return (
    <ConditionalWrapper
      condition={isCollapsible}
      wrapper={(wrappedChildren): ReactElement => (
        <Collapse in={!collapsed} data-cmptype="CbnCollapsibleCardBody">
          {wrappedChildren}
        </Collapse>
      )}
    >
      <div data-cmptype="CbnCardBody" className="overflow-auto p-6">
        {children}
      </div>
    </ConditionalWrapper>
  );
};
