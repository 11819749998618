import {
  CfgnInsightsFilterInputValue,
  CfgnInsightsListQueryValue,
  CfgnInsightsNumberRangeQueryValue,
  CfgnInsightsSingleValueQueryValue,
} from 'components/cfgrs/cfgn-insights/cfgn-insights-filter-input';
import { CfgnInsightsBrowsers } from 'generated/cfgn-insights-browsers';
import { CfgnInsightsType } from 'generated/cfgn-insights-types';
import { FilterGroupOperators } from 'generated/filter-group-operators';
import { FilterItemsType } from 'generated/filter-item-type';
import { FilterItemValueType } from 'generated/filter-item-value-type';
import { FilterOperators } from 'generated/filter-operators';
import { ShopHiveTypes } from 'generated/shop-hive-types';
import {
  CfgnCmpValue,
  CfgnInsightsQuery,
  CfgnInsightsQueryGroup,
  CfgnInsightsQueryItem,
  QueryValues,
  isListQueryValue,
  isNumberRangeQueryValue,
  isSingleValueQueryValue,
} from 'helper/cfgn-insights.helper';
import { endpoints, httpClient } from 'services/http/http.service';
import { DEFAULT_PAGE_SIZE } from 'slices/cfgn-insisghts/cfgn-insights.slice';
import { CfgnInsightPropertyDefinition } from 'slices/cfgn-insisghts/property-definitions';

/**
 * In theory, the API could return any Hive type but Hives `Configuration.Insights` is only supporting `string`,
 * `number` and `logic` ATM.
 *
 * This could change in the future and we'll most likely have to implement some more generic/broader support for Hive
 * types but for now it's IMO ok to have those 3 basic types hard coded here...
 *
 * The fn which receives the data from the API will check the returned type at runtime and throw if it differs from the
 * expected type so that we at least get info our bug tracker if we forget to keep those types in sync with the server.
 */
export type CfgnInsightValueType = 'Text' | 'Number' | 'Logic';

/**
 * !!! Note: Update `BUILT_IN_INSIGHTS_PROPERTIES` as well when this changes !!!
 */
type CfgnInsightDto = {
  id: string;
  /** Timestamp seconds since epoch */
  createdAt: number;
  /** Identity ID, not set for anonymous */
  createdBy?: string;
  /** Identity ID, not used ATM, for future features */
  assignedTo?: string;
  type: CfgnInsightsType;
  cfgrVersion: string;
  presetId?: string;
  basedOnCfgnId?: string;
  /** Not used ATM, for future features */
  comment?: string;

  metadata?: {
    country?: string;
    city?: string;
    /** [ lat, long ], Geo JSON format. */
    location?: [number, number];
    browser?: CfgnInsightsBrowsers;
    width?: number;
    height?: number;
    isMobile?: boolean;
  };

  checkoutData?: {
    name?: string;
    type?: ShopHiveTypes;
    price?: number;
  };

  insightsValues?: {
    [key: string]: CfgnInsightValueType;
  };

  /** Milliseconds, not set and used ATM */
  totalTimeSpent: number;
};
export type CfgnInsight = CfgnInsightDto;

type GetCfgnInsightsDto = {
  continuationToken: string;
  totalCount: number;
  value: CfgnInsightDto[];
};
export type GetCfgnInsightResponse = GetCfgnInsightsDto;

type GetCfgnDto = CfgnInsightDto & {
  input: { [cmpName: string]: CfgnCmpValue };
  result: { [cmpName: string]: CfgnCmpValue };
};
export type CfgnDetails = GetCfgnDto;

type GetCfgnInsightValuesDto = {
  [fieldName: string]: {
    type: CfgnInsightValueType;
  };
};
export type CfgnInsightValues = GetCfgnInsightValuesDto;

function _getListQueryItem(
  propertyName: string,
  queryValue: CfgnInsightsListQueryValue,
  valueType: FilterItemValueType
): CfgnInsightsQueryItem | undefined {
  if (queryValue.length <= 0) return undefined;

  return {
    type: FilterItemsType.Item,
    operator: FilterOperators.In,
    propertyName,
    value: {
      type:
        valueType === FilterItemValueType.String ? FilterItemValueType.ListOfString : FilterItemValueType.ListOfDouble,
      value: queryValue,
    },
  };
}

function _getSingleValueQueryItem(
  propertyName: string,
  queryValue: CfgnInsightsSingleValueQueryValue,
  valueType: FilterItemValueType
): CfgnInsightsQueryItem | undefined {
  if (queryValue.length <= 0) return undefined;

  const value = queryValue[0];
  const isHasNoValueQuery = value === undefined || value === null;

  return {
    type: FilterItemsType.Item,
    operator: isHasNoValueQuery ? FilterOperators.HasNoValue : FilterOperators.Equals,
    propertyName,
    ...(!isHasNoValueQuery && {
      value: {
        type: valueType,
        value,
      },
    }),
  };
}

function _getMinMaxRangeQueryItem(
  propertyName: string,
  queryValue: CfgnInsightsNumberRangeQueryValue,
  valueType: FilterItemValueType
): CfgnInsightsQueryGroup | undefined {
  const [minValueRaw, maxValueRaw] = queryValue;

  const minValue =
    minValueRaw === undefined
      ? undefined
      : valueType === FilterItemValueType.DateTime
        ? Math.floor(minValueRaw / 1000) // ms since epoch to seconds since epoch
        : minValueRaw;

  const maxValue =
    maxValueRaw === undefined
      ? undefined
      : valueType === FilterItemValueType.DateTime
        ? Math.floor(maxValueRaw / 1000) // ms since epoch to seconds since epoch
        : maxValueRaw;

  const hasMinValue = minValue !== undefined && minValue !== null;
  const hasMaxValue = maxValue !== undefined && maxValue !== null;

  if (!hasMinValue && !hasMaxValue) return undefined;

  const subQueryItems: CfgnInsightsQueryItem[] = [];
  if (hasMinValue) {
    subQueryItems.push({
      type: FilterItemsType.Item,
      operator: FilterOperators.GreaterThanOrEqual,
      propertyName,
      value: {
        type: valueType,
        value: minValue,
      },
    });
  }
  if (hasMaxValue) {
    subQueryItems.push({
      type: FilterItemsType.Item,
      operator: FilterOperators.LessThanOrEqual,
      propertyName,
      value: {
        type: valueType,
        value: maxValue,
      },
    });
  }

  return {
    type: FilterItemsType.Group,
    operator: FilterGroupOperators.And,
    items: subQueryItems,
  };
}

const _isQueryItemOrGroup = (
  item: CfgnInsightsQueryItem | CfgnInsightsQueryGroup | undefined
): item is CfgnInsightsQueryItem | CfgnInsightsQueryGroup => {
  return !!item;
};

function _queryValuesToQuery(
  queryValues: QueryValues,
  cfgnInsightsPropertyDefinitions: CfgnInsightPropertyDefinition[]
): CfgnInsightsQuery | undefined {
  const allQueryValues = { ...queryValues.builtIn, ...queryValues.insightsValues };
  const entries = Object.entries(allQueryValues) as [string, CfgnInsightsFilterInputValue][];
  const queryItems: (CfgnInsightsQueryItem | CfgnInsightsQueryGroup)[] = entries
    .filter(([, value]) => value.length > 0)
    .map(([propertyName, queryValue]) => {
      const propertyDefinition = cfgnInsightsPropertyDefinitions.find(x => x.name === propertyName);
      if (!propertyDefinition) return undefined;

      const { queryType, valueType } = propertyDefinition;
      const queryItem =
        queryType === 'list' && isListQueryValue(queryValue)
          ? _getListQueryItem(propertyName, queryValue, valueType)
          : queryType === 'single-value' && isSingleValueQueryValue(queryValue)
            ? _getSingleValueQueryItem(propertyName, queryValue, valueType)
            : queryType === 'min-max-range' && isNumberRangeQueryValue(queryValue)
              ? _getMinMaxRangeQueryItem(propertyName, queryValue, valueType)
              : undefined;

      return queryItem;
    })
    .filter(_isQueryItemOrGroup);

  const query: CfgnInsightsQuery = {
    ...(queryItems.length && {
      filter: {
        type: FilterItemsType.Group,
        operator: FilterGroupOperators.And,
        items: queryItems,
      },
    }),

    orderByProperties: [
      {
        propertyName: 'createdAt',
        ascendingOrder: false,
      },
    ],
  };

  return query;
}

/**
 * @param continuationToken Used for "load more/paging" functionality
 */
export async function getCfgnInsights(
  companyName: string,
  cfgrName: string,
  cfgnInsightsPropertyDefinitions: CfgnInsightPropertyDefinition[],
  stageName?: string,
  queryValues?: QueryValues,
  pageSize = DEFAULT_PAGE_SIZE,
  continuationToken?: string
): Promise<GetCfgnInsightResponse> {
  const query = queryValues ? _queryValuesToQuery(queryValues, cfgnInsightsPropertyDefinitions) : undefined;
  const { url, params } = endpoints.getCfgnInsights(
    companyName,
    cfgrName,
    stageName,
    query,
    pageSize,
    continuationToken
  );
  const res = await httpClient.post<GetCfgnInsightsDto>(url, params);
  return res.data;
}

export async function getCfgn(
  companyName: string,
  cfgrName: string,
  cfgnId: string,
  stageName?: string
): Promise<CfgnDetails> {
  const { url, params } = endpoints.getCfgn(companyName, cfgrName, cfgnId, stageName);
  const res = await httpClient.get<GetCfgnDto>(url, { params });
  return res.data;
}

/**
 * Despite its name, this doesn't return the actual values but the definitions/specification of the values.
 *
 * @returns Map of insights values defined by Hives `Configuration.Insights`
 */
export async function getCfgnInsightsValues(
  companyName: string,
  cfgrName: string,
  stageName?: string
): Promise<CfgnInsightValues> {
  const { url, params } = endpoints.getCfgnInsightsValues(companyName, cfgrName, stageName);
  const res = await httpClient.get<GetCfgnInsightValuesDto>(url, { params });

  // In theory, the API could return any Hive type but Hives `Configuration.Insights` is only supporting `string`,
  // `number` and `logic` ATM.
  //
  // With this check we'd at least get some info in our bug tracker if we forget to keep the types in sync with server.
  const knownTypes: CfgnInsightValueType[] = ['Text', 'Number', 'Logic'];
  const allTypesKnown = Object.values(res.data).every(x => knownTypes.includes(x.type));
  if (!allTypesKnown) {
    throw new Error('Unknown CfgnInsightValueType received from server');
  }

  return res.data;
}

type CfgnMetricDto = {
  CfgnCount: number;
  SharedCfgnCount: number;
  FinishCount: number;
  PriceSum: number;
};

export type CfgnMetricResult = CfgnMetricDto;

export async function getCfgnMetrics(
  companyId: string,
  configuratorName: string,
  start: number,
  end: number
): Promise<CfgnMetricResult> {
  const { url, params } = endpoints.getCfgnMetrics(companyId, configuratorName, start, end);
  const response = await httpClient.post<CfgnMetricDto>(url, params);
  return response.data;
}
