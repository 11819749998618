import { CfgnInsightsQueryableProperties } from 'generated/cfgn-insights-queryable-properties';
import { FilterItemValueType } from 'generated/filter-item-value-type';
import { AllQueryableInsightsProperties, AllShownInsightsProperties } from 'helper/cfgn-insights.helper';

export type CfgnInsightSpecificType = 'identity' | 'cfgn-type' | 'checkout-type' | 'browser';

export type CfgnInsightPropertyDefinition = {
  /**
   * This can either be a statically known property (a `AllShownInsightsProperties`) or an arbitrary name for a custom
   * insights value defined by Hives `Configuration.Insights`.
   */
  name: AllShownInsightsProperties | string;
  label: string;

  /** Not meant for direct display but for use with `t(group)` */
  group: 'Most used' | 'Custom insights values' | 'Metadata' | 'Others';

  /** Type used to format display values in cfgns table etc. */
  valueType: FilterItemValueType;

  /**
   * Defines how the values are queried from the server:
   *
   *   - `list`: Values are queried using `FilterOperators.IN`.
   *             See docs of `CfgnInsightsListQueryValue` for more details.
   *
   *   - `min-max-range`: Values are queried using a "group query" like `(x >= value[0]) AND (x <= value[1])`.
   *                      See docs of `CfgnInsightsNumberRangeQueryValue` for more details.
   *
   *   - `single-value`: Only the first value from the array is actually used for the query with
   *                     `FilterOperators.EQUALS` or `FilterOperators.HasNoValue`.
   *                     See docs of `CfgnInsightsSingleValueQueryValue` for more details.
   *
   * Don't set for properties which are not queryable.
   * If not set, the property will neither be shown in the filters UI, nor will it ever be included in the query.
   *
   * Has no default value.
   */
  queryType?: 'list' | 'min-max-range' | 'single-value';

  /**
   * Only set if the `valueType` is too unspecific.
   * E.g. `createdBy` has `valueType: string` which does not tell us that it is an identity ID. However, we need that
   * information for formatting the values in the table and for showing the correct filter options etc.
   */
  specificType?: CfgnInsightSpecificType;
};

/** Type guard to distinguish between a static/built in insights property & the name of a custom insights value. */
export function isQueryableBuiltInInsightsProperty(x: string): x is AllQueryableInsightsProperties {
  return x in BUILT_IN_INSIGHTS_PROPERTIES;
}

/**
 * Returns the full/prefixed property name for querying the insights values.
 * E.g. when the user creates a custom insights value with the name `productColor`, the full property name for querying
 * the insights value would be `insightsValues.productColor`.
 */
export function getFullInsightsValuePropertyName(unprefixedPropertyName: string): string {
  return `${CfgnInsightsQueryableProperties.INSIGHTS_VALUES}.${unprefixedPropertyName}`;
}

/**
 * Defines the order in which the groups are shown in the table, the "view settings" and the filters view.
 */
export const INSIGHTS_GROUPS_ORDER: CfgnInsightPropertyDefinition['group'][] = [
  'Most used',
  'Custom insights values',
  'Metadata',
  'Others',
];

/**
 * Properties to be shown in the insights table, the "view settings" & the filters view. Also holds info about whether
 * or not the user can filter by this property and what kind of filter we shall show the user (e.g. date picker, text
 * input, number range etc.).
 *
 * The order of this list is important as it defines in which order the properties are shown in the table, the "view
 * settings" and the filters view.
 *
 * The order of the groups is defined by INSIGHTS_GROUPS_ORDER. We can't bake that into this list as we're dynamically
 * merging the property for the `insightsValues` at runtime.
 *
 * At runtime, we also add properties for the `insightsValues` which are different for each cfgr and are therefore not
 * known statically at compile time (see `selectCfgrCfgnInsightsPropertyDefinitions`).
 *
 * !!! Note: Properties shall be kept in sync with `CfgnInsightDto` !!!
 */
type BuiltInCfgnInsightPropertyDefinition = Omit<CfgnInsightPropertyDefinition, 'propertyName'> & {
  name: AllShownInsightsProperties;
};
export const BUILT_IN_INSIGHTS_PROPERTIES: Record<AllShownInsightsProperties, BuiltInCfgnInsightPropertyDefinition> = {
  // =======================================================
  // ================== Group "Most used" ==================
  // =======================================================
  'id': {
    name: 'id',
    label: 'Configuration ID',
    group: 'Most used',
    valueType: FilterItemValueType.String,
    queryType: 'list',
  },
  'createdAt': {
    name: 'createdAt',
    label: 'Created at',
    group: 'Most used',
    valueType: FilterItemValueType.DateTime,
    queryType: 'min-max-range',
  },
  'createdBy': {
    name: 'createdBy',
    label: 'Created by',
    group: 'Most used',
    valueType: FilterItemValueType.String,
    queryType: 'list',
    specificType: 'identity',
  },
  'type': {
    name: 'type',
    label: 'Type',
    group: 'Most used',
    /** One of `CfgnInsightsType` */
    valueType: FilterItemValueType.Double,
    queryType: 'list',
    specificType: 'cfgn-type',
  },
  'checkoutData.type': {
    name: 'checkoutData.type',
    label: 'Checkout type',
    group: 'Most used',
    /** One of `ShopHiveTypes` */
    valueType: FilterItemValueType.String,
    queryType: 'list',
    specificType: 'checkout-type',
  },
  'checkoutData.price': {
    name: 'checkoutData.price',
    label: 'Price',
    group: 'Most used',
    valueType: FilterItemValueType.Double,
    queryType: 'min-max-range',
  },

  // ======================================================
  // ================== Group "Metadata" ==================
  // ======================================================
  'metadata.country': {
    name: 'metadata.country',
    label: 'Country',
    group: 'Metadata',
    valueType: FilterItemValueType.String,
    queryType: 'list',
  },
  'metadata.city': {
    name: 'metadata.city',
    label: 'City',
    group: 'Metadata',
    valueType: FilterItemValueType.String,
    queryType: 'list',
  },
  'metadata.browser': {
    name: 'metadata.browser',
    label: 'Browser',
    group: 'Metadata',
    /** One of `CfgnInsightsBrowsers` */
    valueType: FilterItemValueType.String,
    queryType: 'list',
    specificType: 'browser',
  },
  'metadata.width': {
    name: 'metadata.width',
    label: 'Width',
    group: 'Metadata',
    valueType: FilterItemValueType.Double,
  },
  'metadata.height': {
    name: 'metadata.height',
    label: 'Height',
    group: 'Metadata',
    valueType: FilterItemValueType.Double,
  },
  'metadata.isMobile': {
    name: 'metadata.isMobile',
    label: 'Is mobile',
    group: 'Metadata',
    valueType: FilterItemValueType.Boolean,
    queryType: 'single-value',
  },

  // ====================================================
  // ================== Group "Others" ==================
  // ====================================================
  'cfgrVersion': {
    name: 'cfgrVersion',
    label: 'Configurator version',
    group: 'Others',
    valueType: FilterItemValueType.String,
    queryType: 'list',
  },
  'presetId': {
    name: 'presetId',
    label: 'Preset',
    group: 'Others',
    valueType: FilterItemValueType.String,
    queryType: 'list',
  },
  'basedOnCfgnId': {
    // Link to details view
    name: 'basedOnCfgnId',
    label: 'Based on configuration',
    group: 'Others',
    valueType: FilterItemValueType.String,
    queryType: 'list',
  },
  'checkoutData.name': {
    name: 'checkoutData.name',
    label: 'Checkout name',
    group: 'Others',
    valueType: FilterItemValueType.String,
    queryType: 'list',
  },
};

/**
 * Sort given insights properties by group order based on `INSIGHTS_GROUP_ORDER`, keep order of properties within the
 * group untouched.
 */
export const sortInsightsPropertiesByGroup = (
  a: CfgnInsightPropertyDefinition,
  b: CfgnInsightPropertyDefinition
): number => {
  const groupOrderA = INSIGHTS_GROUPS_ORDER.indexOf(a.group);
  const groupOrderB = INSIGHTS_GROUPS_ORDER.indexOf(b.group);
  return groupOrderA - groupOrderB;
};

/** Helper for easier use of pre-sorted `BUILT_IN_INSIGHTS_PROPERTIES` in consuming code */
export const BUILT_IN_INSIGHTS_PROPERTIES_ARRAY: CfgnInsightPropertyDefinition[] = (
  Object.values(BUILT_IN_INSIGHTS_PROPERTIES) as CfgnInsightPropertyDefinition[]
).sort(sortInsightsPropertiesByGroup);
