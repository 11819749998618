import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalDialog } from 'components/modal-dialog/modal-dialog';
import { FormItem } from 'controls/form-item/form-item';
import { TextInput } from 'controls/input/text-input';
import { readClipboardText } from 'helper/clipboard/clipboard.helper';
import { useTypedTranslation } from 'hooks/i18n/i18n.hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store/store.hooks';
import { buildReplicatorPath } from 'services/routes/replicator-routes.service';
import { selectSelectedCompany } from 'slices/company-data/company-data.slice';
import { resetBlueprint, setBlueprintSasUri } from 'slices/replicator/replicator.slice';

type ReplicatorCrossEnvModalProps = {
  onClose: () => void;
};

export const ReplicatorCrossEnvModal: React.FC<ReplicatorCrossEnvModalProps> = ({ onClose }) => {
  const { t } = useTypedTranslation();
  const [inputUri, setInputUri] = useState('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(selectSelectedCompany)?.id ?? '';

  useEffect(() => {
    const initWithClipboardText = async (): Promise<void> => {
      const clipboardText = await readClipboardText();
      if (!clipboardText || !clipboardText.startsWith('https://')) {
        return;
      }

      setInputUri(clipboardText);
    };
    initWithClipboardText();
  }, []);

  const onConfirm = async (): Promise<void> => {
    dispatch(resetBlueprint());
    dispatch(setBlueprintSasUri(inputUri));
    navigate(buildReplicatorPath(companyId, 'copy'));
    onClose();
  };

  return (
    <ModalDialog
      header={t('Cross-Env Import')}
      variant="NoIcon"
      data-cmptype="ReplicatorCrossEnvModal"
      confirmText={t('Import blueprint')}
      actions={{ onConfirm: onConfirm, onCancel: onClose }}
    >
      <FormItem labelContent={t('Blueprint URI')}>
        <TextInput value={inputUri} onValueChange={(value): void => setInputUri(value)} autoFocus />
      </FormItem>
    </ModalDialog>
  );
};
