import { useContext, useEffect, useRef } from 'react';
import { Panel as BasePanel, PanelProps as BasePanelProps, ImperativePanelHandle } from 'react-resizable-panels';
import {
  CbnResizeablePanelGroupContext,
  CbnResizeablePanelSizeMode,
} from 'controls/cbn-resizeable-panel/cbn-resizeable-panel-group';

type CbnResizablePanelProps = Pick<
  BasePanelProps,
  | 'children'
  | 'order'
  | 'className'
  | 'defaultSize'
  | 'minSize'
  | 'maxSize'
  | 'collapsible'
  | 'collapsedSize'
  | 'onCollapse'
  | 'onExpand'
  | 'onTransitionEnd'
> & {
  /** prop for triggering collapsed state from "outside" (eg: collapse button) */
  collapsed?: boolean;
};

export const CbnResizablePanel: React.FC<CbnResizablePanelProps> = ({
  children,
  order,
  className,
  defaultSize,
  minSize,
  maxSize,
  collapsible,
  collapsed,
  collapsedSize,
  onCollapse,
  onExpand,
  onTransitionEnd,
}) => {
  const { sizeMode, availablePanelGroupSizePx } = useContext(CbnResizeablePanelGroupContext);
  const apiRef = useRef<ImperativePanelHandle>(null);

  useEffect(() => {
    if (collapsed === undefined) {
      return;
    }

    if (collapsed && !apiRef.current?.isCollapsed()) {
      apiRef.current?.collapse();
    } else if (!collapsed && apiRef.current?.isCollapsed()) {
      apiRef.current?.expand();
    }
  }, [collapsed]);

  const defaultSizePerc = _getSizePercentageValue(sizeMode, defaultSize, availablePanelGroupSizePx);
  const minSizePerc = _getSizePercentageValue(sizeMode, minSize, availablePanelGroupSizePx);
  const maxSizePerc = _getSizePercentageValue(sizeMode, maxSize, availablePanelGroupSizePx);
  const collapsedSizePerc = _getSizePercentageValue(sizeMode, collapsedSize, availablePanelGroupSizePx);

  // default size has to be correct at the first render of `BasePanel`, but `availablePanelGroupSizePx` may not be
  // correct at that time => see `useElementSize` hook
  const sizeDataValid = sizeMode === 'percentage' || availablePanelGroupSizePx;

  return sizeDataValid ? (
    <BasePanel
      data-cmptype="CbnResizablePanel"
      order={order}
      className={className}
      ref={apiRef}
      defaultSize={defaultSizePerc}
      minSize={minSizePerc}
      maxSize={maxSizePerc}
      collapsible={collapsible}
      collapsedSize={collapsedSizePerc}
      onCollapse={onCollapse}
      onExpand={onExpand}
      onTransitionEnd={onTransitionEnd}
    >
      {children}
    </BasePanel>
  ) : (
    <></>
  );
};

/**
 * Evaluates final size value in percentage for the input of the `BasePanel` component.
 * This is most likely required for the "pixel" mode.
 */
function _getSizePercentageValue(
  sizeMode: CbnResizeablePanelSizeMode,
  targetSize?: number,
  availableSizePx?: number
): number | undefined {
  if (sizeMode === 'percentage' || targetSize === undefined) {
    return targetSize;
  }

  // input values are given in pixels, calculate percentage value from it
  // check for division by 0 before
  // it's important to use rounded values, as the libraries internal collapse/expand logic doesn't work, if the target
  // values are not 100% accurate
  // eg: if (6.123456 === 6.123457)
  // vs: if (6.1 === 6.1)
  if (!availableSizePx) {
    return undefined;
  }

  const percRaw = (100 * targetSize) / availableSizePx;
  const percRounded = Math.round(percRaw * 10) / 10;
  return percRounded;
}
