import { useEffect, useRef, useState } from 'react';

/**
 * Loading state with
 * - start delay - to prevent loader in case of "almost instant actions"
 * - minimum active time - to prevent flickering
 *
 * @param isActive input state from consuming cmp
 * @param startDelay wait the given ms before setting `true` (unless it becomes `false` in the meantime)
 * @param minActiveTime state will stay at least ## ms in state `true`
 */
export function useTimedActiveState(
  isActive: boolean | undefined,
  startDelay: number,
  minActiveTime: number
): boolean | undefined {
  const [isTimedActive, setIsTimedActive] = useState(isActive);
  const startTime = useRef<number>();

  useEffect(() => {
    if (!isActive) {
      const delta = startTime.current ? minActiveTime - (Date.now() - startTime.current) : 0;
      if (delta > 0) {
        const id = window.setTimeout(() => {
          startTime.current = undefined;
          setIsTimedActive(isActive);
        }, delta);
        return () => window.clearTimeout(id);
      } else {
        startTime.current = undefined;
        setIsTimedActive(isActive);
      }
    } else {
      const id = window.setTimeout(() => {
        startTime.current = Date.now();
        setIsTimedActive(isActive);
      }, startDelay);
      return () => window.clearTimeout(id);
    }
  }, [isActive, startDelay, minActiveTime]);

  return isTimedActive;
}
